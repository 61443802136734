import React, { useEffect, useReducer, useCallback } from 'react';

import { useSelector } from 'react-redux';
import VisibilitySensor from 'react-visibility-sensor';

import Search from 'components/Search';
import HeaderBar from 'components/HeaderBar';
import Spinner from 'components/base/Spinner';

import * as api from '../../services/spark-api';
import { useResources } from 'lib/redux-resource/hooks';
import { selectSiteByDomain } from 'reducers/sitesReducer';
import { useProducts } from 'components/Storefront/hooks';

import styles from './ChooseProduct.module.scss';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

interface State {
  query: string;
  isCloning: boolean;
  debouncedQuery: string;
  page: number;
  results: Array<any>;
  isLoading: boolean;
  isLoadingMore: boolean;
}

interface Product {
  id: string;
  title: string;
  featured_media: any;
  formatted_price_range: string;
}

const initialState: State = {
  query: undefined,
  isCloning: false,
  debouncedQuery: undefined,
  page: 1,
  results: [],
  isLoading: true,
  isLoadingMore: false,
};

const TYPES: { [key: string]: string } = {
  SET_QUERY: 'SET_QUERY',
  SET_DEBOUNCED_QUERY: 'SET_DEBOUNCED_QUERY',
  INCREMENT_PAGE: 'INCREMENT_PAGE',
  SET_RESULT: 'SET_RESULT',
  SET_IS_CLONING: 'SET_IS_CLONING',
  RESET: 'RESET',
};

function reducer(state: any, action: { type: string; payload?: any }) {
  switch (action.type) {
    case TYPES.SET_QUERY:
      return {
        ...state,
        query: action.payload,
      };
    case TYPES.SET_DEBOUNCED_QUERY:
      return {
        ...state,
        debouncedQuery: action.payload,
        page: 1,
        results: [],
        isLoading: true,
        isLoadingMore: false,
      };
    case TYPES.INCREMENT_PAGE:
      const page = state.page;
      return {
        ...state,
        page: page + 1,
        isLoadingMore: true,
      };
    case TYPES.SET_RESULT:
      const results = state.results;
      return {
        ...state,
        results: results.concat(action.payload),
        isLoading: false,
        isLoadingMore: false,
      };
    case TYPES.SET_IS_CLONING:
      return {
        ...state,
        isCloning: true,
      };
    case TYPES.RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}

export default function () {
  const location = useLocation();
  const { urlSite } = useParams();
  const { templateId, pageType } = location.state;
  const navigate = useNavigate();

  const components = useResources('sectionTypes');
  const site = useSelector((state) =>
    // @ts-ignore
    selectSiteByDomain(state, urlSite)
  );
  const [state, dispatch] = useReducer(reducer, initialState);
  const [data, isLoadingData] = useProducts(state.debouncedQuery, 20, '', '-updated_at');

  useEffect(() => {
    dispatch({ type: TYPES.RESET });
  }, []);

  useEffect(() => {
    if (data.data && !isLoadingData) {
      dispatch({ type: TYPES.SET_RESULT, payload: data.data });
      if (data.data.length === 0 && !state.debouncedQuery) {
        clickSkip();
      }
    }
  }, [data, isLoadingData]);

  const onLoadMoreVisibilityChange = useCallback(
    (isVisible: boolean) => {
      // need to figure this out later for continuing to get results by page
      // should be looking at data.hasMoreDataTolOad
      // TODO: Fix this
      if (isVisible && data.data) dispatch({ type: TYPES.INCREMENT_PAGE });
    },
    [data]
  );

  function cloneTemplate(templateId: string, product?: Product) {
    dispatch({ type: TYPES.SET_IS_CLONING });
    api
      .cloneTemplate(templateId, site.id, pageType)
      .then((response) => {
        const newPageId = response.json.id;
        if (product) {
          const data = response.json;
          data.meta = {
            ...data.meta,
            showAddSectionTooltip: true,
          };
          data.content.sections.forEach((section: any) => {
            const component = components.find(
              (type: any) => type.id === section.component || type.parent === section.component
            );
            if (!!component?.tags.Product) {
              section.product = product;
            }
          });

          api
            .putPage(data)
            .then((response) => {
              if (response.ok) {
                navigate(`/${urlSite}/editor/${newPageId}`);
              }
            })
            .catch((errors) => {
              console.error('errors', errors);
            });
        } else {
          navigate(`/${urlSite}/editor/${newPageId}`);
        }
      })
      .catch((errors) => {
        console.error('errors', errors);
      });
  }

  function clickSkip() {
    dispatch({ type: TYPES.SET_IS_CLONING });
    if (templateId) {
      cloneTemplate(templateId);
    } else {
      navigate(`/${urlSite}/landing-pages/new`);
    }
  }

  function clickProduct(product: Product) {
    if (templateId && product) {
      cloneTemplate(templateId, product);
    }
  }

  return (
    <>
      {state.isCloning && <Spinner className={styles.spinner} />}
      {!state.isCloning && (
        <>
          <HeaderBar
            // fixed
            goBack={() => navigate(-1)}
            // backTo={`/${urlSite}/templates/${pageType}`}
            center={<div>Choose a product</div>}
            right={
              <div className={styles.headerRight}>
                <Search
                  value={state.query === undefined ? state.debouncedQuery : state.query}
                  onChange={(query: string) => dispatch({ type: TYPES.SET_QUERY, payload: query })}
                  placeholder="Search products"
                  debounceValue={(search: string) =>
                    dispatch({
                      type: TYPES.SET_DEBOUNCED_QUERY,
                      payload: search,
                    })
                  }
                  containerStyle={{
                    marginTop: '0rem',
                  }}
                  className={undefined}
                  autofocus={undefined}
                  enableDarkMode={undefined}
                />
                <button className={styles.button} onClick={clickSkip}>
                  Skip step
                </button>
              </div>
            }
          />

          <div className={styles.container}>
            {state.isLoading && <Spinner className={styles.spinner} />}
            {!state.isLoading && state.results && (
              <div className={styles.products}>
                {state.results.map((product: Product, i: number) => (
                  <div className={styles.productContainer} key={product.id}>
                    <div className={styles.product} onClick={() => clickProduct(product)}>
                      <div className={styles.thumbnailContainer}>
                        <img className={styles.thumbnail} src={product.featured_media.url} alt="" />
                      </div>
                      <div className={styles.caption}>
                        <span className={styles.name}>{product.title}</span>
                        <span className={styles.price}>{product.formatted_price_range}</span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </>
      )}
      {state.isLoadingMore && !state.isLoading && <Spinner className={styles.spinnerbottom} />}
      {!state.isLoading && (
        <VisibilitySensor onChange={(visible: boolean) => onLoadMoreVisibilityChange(visible)}>
          <span>&nbsp;</span>
        </VisibilitySensor>
      )}
    </>
  );
}
