import React, { useCallback, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { set, del, insert, push, assign } from 'object-path-immutable';
import { selectSectionArticles } from '../../../../../reducers/sectionArticlesReducer';
import EditDrawer from './ArticleGenerator/EditDrawer';

import { ArticlesTagArgs } from 'components/unstack-components/tag-types';

import styles from './ArticleGenerator/ArticleGenerator.module.scss';
import usePrevious from 'hooks/usePrevious';
import { SectionHandlersInterface, SectionUiHandlersInterface } from 'components/unstack-components/types';
import { SectionArticle } from 'types/SectionArticles';
import { USectionDevices } from 'types/USection';
import { getDeviceTypeToSaveTo } from '../../util/helpers/deviceHelper';
import { getDevice } from 'reducers/uiReducer';

interface ArticleGeneratorProps extends ValueOnChangeProps<Object | Array<Object | null>>, ArticlesTagArgs {
  sectionHandlers: SectionHandlersInterface;
  sectionUiHandlers: SectionUiHandlersInterface;
  contentKey: string;
  dataRef?: { pins: string[]; limit: number };
  content: USectionDevices;
}

function ArticleGenerator(props: ArticleGeneratorProps) {
  const {
    item,
    tagName,
    onChange,
    maxItems,
    className,
    sectionHandlers,
    contentKey,
    defaultItems,
    minItems = 1,
    dataRef,
    content,
  } = props;
  const dataRefArticles = props.dataRef
    ? Array.from({ length: props.dataRef.limit }, (_, i) => {
        if (props?.dataRef?.pins && i < props.dataRef.pins.length) {
          return props.dataRef.pins[i];
        }
        return null;
      })
    : [];
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const device = useSelector(getDevice);

  const handleChange = useCallback(
    (newArticles: string[]) => {
      const splitKey = contentKey.split('.');
      const articlesJSON = {
        pins: newArticles.filter((article) => !!article),
        limit: newArticles.length,
      };
      // FIXME assumption that articles will always write to base
      onChange(set(content as any, ['base', ...splitKey.slice(1)], articlesJSON), `content.${splitKey[0]}`);
    },
    [onChange]
  );

  // If the number of collection items is less than the minimum, then add them.
  useEffect(() => {
    const defaultCount = Math.max(defaultItems, minItems, 1);

    if (dataRefArticles.length >= minItems) return;
    let newArticles = [].concat(dataRefArticles);

    // If the raw value hasn't been initialized, use the defaultItems arg
    if (dataRef == null) {
      while (newArticles.length < defaultCount) {
        newArticles = push(newArticles, null, null);
      }
      handleChange(newArticles);
      return;
    }

    // Lastly, make sure the value has at least the minimum number of items.
    while (newArticles.length < minItems) {
      newArticles = push(newArticles, null, null);
    }
    handleChange(newArticles);
  }, [dataRefArticles, defaultItems, minItems]);

  const articles = useSelector((state) => {
    const sectionArticles: { [key: string]: SectionArticle[] } =
      selectSectionArticles(state)[sectionHandlers.containerId];
    const allArticles = sectionArticles ? sectionArticles[contentKey] : [];

    if (allArticles && allArticles.length) return allArticles;

    return dataRefArticles.map((_articleId) => {
      return {
        name: 'Lorem ipsum',
        slug: '/lorem-ipsum',
        title: 'Lorem ipsum',
        meta: {},
        content: {
          body: { ops: [{ insert: 'Lorem ipsum vanu iritae beep boop' }] },
          header: 'Lorem ipsum',
        },
      };
    });
  });

  //  @ts-ignore
  const renderedArticles = articles.map((article: any, i: number) => {
    // In the old version of article collections, we overwrote the `slug` so
    // that if the user clicked on the article, they'd be taken to the real
    // article in the editor. This is no longer needed because clicking on
    // articles opens the editor drawer instead, but I'm keeping it here
    // commented out just in case.
    //
    // if (article) {
    //   article = {
    //     ...article,
    //     slug: `editor/blog-post/${article.id}`,
    //   };
    // }

    // const itemContext = {
    //   ...context,
    //   [template.args.key]: article,
    // };

    return React.cloneElement(item(article.base), { key: i });
  });

  const ContainerTag = tagName as keyof React.ReactHTML;

  return (
    <ContainerTag className={className} style={{ position: 'relative' }}>
      {renderedArticles}
      <div
        className={styles.overlay}
        onClick={(e) => {
          e.preventDefault();
          setIsOpen(true);
        }}
      >
        <div className={styles.copy}>Click to edit article collection</div>
      </div>
      <EditDrawer
        isOpen={isOpen}
        close={() => setIsOpen(false)}
        dataRefArticles={dataRefArticles}
        onChange={handleChange}
        min={minItems}
        max={maxItems}
      />
    </ContainerTag>
  );
}

export default ArticleGenerator;
