import React from 'react';
import idx from 'lodash/get';

import Toolbar from '../../../Editor/Toolbars/HeaderFooterToolbar';
import ColorSelector from '../StyleGuideEditor/ColorSelector';
import BrightnessThemeButtons from '../../../Editor/Toolbars/Buttons/BrightnessTheme';
import LogoEditorButtons from './LogoEditorButtons';
import HeaderStyleOptions from './HeaderStyleOptions';
import TextAlign from '../../../Editor/Toolbars/Buttons/TextAlign';
import HeaderPaddingButtons from './HeaderPaddingButtons';

const HeaderLayoutButtons = ({ site, siteUpdaters }) => {
  const headerMenuLayout = idx(site, 'styles.headerMenuLayout', 'right');
  const items = idx(site, 'menus.header.items', []);
  const secondary_items = idx(site, 'menus.header.secondary_items', []);

  const handleChange = (value) => {
    siteUpdaters.set('styles.headerMenuLayout', value);

    if (value === 'center' || value === 'right') {
      const newItems = items.concat(secondary_items);
      siteUpdaters.set('menus.header.items', newItems);
      siteUpdaters.del('menus.header.secondary_items');
    }
  };

  return <TextAlign options={['left', 'center', 'right']} value={headerMenuLayout} onChange={handleChange} />;
};

const HeaderToolbar = ({ site, siteUpdaters }) => {
  const {
    styles: { headerBrightnessTheme, defaultColors },
  } = site;
  const defaultHeaderColor = defaultColors.find((color) => color.label === 'Light background');

  const headerBackgrounColor =
    !headerBrightnessTheme || headerBrightnessTheme === 'light-bg'
      ? 'styles.headerBackgroundColor'
      : 'styles.headerDarkBackgroundColor';
  return (
    <Toolbar type="header">
      <div className="toolbar">
        <ColorSelector
          name="header_1_color"
          onChange={(value) => siteUpdaters.set(headerBackgrounColor, value)}
          value={idx(site, headerBackgrounColor, defaultHeaderColor)}
          placeholder="Color"
          handleColorSelection={(value) => siteUpdaters.set(headerBackgrounColor, value)}
        />
        <div className="separator" />
        <HeaderStyleOptions
          value={idx(site, 'styles.headerStyle', 'solid')}
          onChange={(value) => siteUpdaters.set('styles.headerStyle', value)}
          data-test-id="header-style-options"
        />
        <div className="separator" />
        <BrightnessThemeButtons
          value={idx(site, 'styles.headerBrightnessTheme', 'light-bg')}
          onChange={(value) => {
            siteUpdaters.set('styles.headerBrightnessTheme', value);
            if (!site.styles.headerDarkBackgroundColor && value === 'dark-bg') {
              siteUpdaters.set('styles.headerDarkBackgroundColor', defaultHeaderColor);
            }
          }}
        />
        <div className="separator" />
        <HeaderLayoutButtons site={site} siteUpdaters={siteUpdaters} />
        <div className="separator" />
        <LogoEditorButtons target="header" site={site} siteUpdaters={siteUpdaters} />
        <div className="separator" />
        <HeaderPaddingButtons site={site} siteUpdaters={siteUpdaters} />
      </div>
    </Toolbar>
  );
};

export default HeaderToolbar;
