import { schema, normalize } from 'normalizr';

const normalizeColumns = (function () {
  const column = new schema.Entity('columns');
  const columns = [column];

  return function normalizeColumnList(data) {
    const normalized = normalize(data, columns);

    return {
      columns: {
        ...normalized.entities.columns,
      },
    };
  };
})();

const normalizeRows = (function () {
  const row = new schema.Entity('rows');
  const rows = [row];

  return function normalizeRowList(rowsData) {
    const normalized = normalize(rowsData, rows);
    return {
      rows: {
        ...normalized.entities.rows,
      },
    };
  };
})();

const normalizeRelatedRows = (function () {
  const relatedRow = new schema.Entity('relatedRows');
  const relatedRows = [relatedRow];

  return function normalizeRowList(relatedRowsData) {
    const normalized = normalize(relatedRowsData, relatedRows);
    return {
      relatedRows: {
        ...normalized.entities.relatedRows,
      },
    };
  };
})();

export { normalizeColumns, normalizeRows, normalizeRelatedRows };
