import { SITE_STATUS_PROXY } from '../constants';
import { selectSiteById, selectSiteByDomain, getIsDnsPendingFromSite } from './sitesReducer';
import { selectHasPermission } from './policyReducer';
import { selectHasSubscription } from './subscriptionReducer';
import { selectOwnerStatus } from './accountUsersReducer';

export const NOTIFICATIONS = {
  DOMAIN_PENDING: 'NOTIFICATIONS.DOMAIN_PENDING',
  SUBSCRIPTION_PENDING: 'NOTIFICATIONS.SUBSCRIPTION_PENDING',
  SUBSCRIPTION_EXPIRED: 'NOTIFICATIONS.SUBSCRIPTION_EXPIRED',
};

export function selectNotificationBySiteId(globalState, siteId) {
  const site = selectSiteById(globalState, siteId);
  return selectNotificationBySite(globalState, site);
}

export function selectNotificationBySiteDomain(globalState, urlSite) {
  const site = selectSiteByDomain(globalState, urlSite);
  return selectNotificationBySite(globalState, site);
}

export function selectNotificationBySite(globalState, site) {
  if (site) {
    const isDnsPending = getIsDnsPendingFromSite(site);
    const hasSubscription = selectHasSubscription(globalState);
    if (!site.domain) {
      return NOTIFICATIONS.DOMAIN_PENDING;
    }
  }
  return null;
}

export function selectNotificationBarIsOut(globalState, urlSite) {
  const notification = selectNotificationBySiteDomain(globalState, urlSite);
  const accountOwnerStatus = selectOwnerStatus(globalState);
  const hasPermission = selectHasPermission(globalState);
  return (
    notification &&
    (accountOwnerStatus === 'inactive' ||
      (notification === 'NOTIFICATIONS.DOMAIN_PENDING' && hasPermission('Site:set_domain')) ||
      notification === 'NOTIFICATIONS.SUBSCRIPTION_EXPIRED' ||
      notification === 'NOTIFICATIONS.SUBSCRIPTION_PENDING')
  );
}
