import * as types from './actionTypes';

export const createOrUpdateProduct = (payload, resolve, reject) => ({
  type: types.PAYMENTS_CREATE_PRODUCT,
  payload,
  resolve,
  reject,
});

export const deleteProduct = (payload, resolve, reject) => ({
  type: types.PAYMENTS_DELETE_PRODUCT,
  payload,
  resolve,
  reject,
});

export const deleteProductSuccess = (payload) => ({
  type: types.PAYMENTS_DELETE_PRODUCT_SUCCESS,
  payload,
});

export const createOrUpdateProductSuccess = (payload) => ({
  type: types.PAYMENTS_CREATE_PRODUCT_SUCCESS,
  payload,
});

export const createOrUpdateProductFailure = () => ({
  type: types.PAYMENTS_CREATE_PRODUCT_FAILURE,
});

export const fetchProductsSuccess = (payload) => ({
  type: types.PAYMENTS_FETCH_PRODUCTS_SUCCESS,
  payload,
});

export const requestProductsIfNeeded = () => ({
  type: types.PAYMENTS_FETCH_PRODUCTS_IF_NEEDED,
});

export const requestPaymentPeriodIfNeeded = () => ({
  type: types.PAYMENTS_FETCH_PERIODS_IF_NEEDED,
});

export const fetchPeriods = () => ({
  type: types.PAYMENTS_FETCH_PERIODS,
});

export const periodFetchSuccess = (payload) => ({
  type: types.PAYMENTS_FETCH_PERIODS_SUCCESS,
  payload,
});

export const fetchProducts = () => ({ type: types.PAYMENTS_FETCH_PRODUCTS });

export const createOrUpdatePlan = (payload, resolve, reject) => ({
  type: types.PAYMENTS_CREATE_PLAN,
  payload,
  resolve,
  reject,
});

export const createOrUpdatePlanFailure = () => ({
  type: types.PAYMENTS_CREATE_PLAN_FAILURE,
});

export const createOrUpdatePlanSuccess = (payload) => ({
  type: types.PAYMENTS_CREATE_PLAN_SUCCESS,
  payload,
});

export const deletePlan = (payload, resolve, reject) => ({
  type: types.PAYMENTS_DELETE_PLAN,
  payload,
  resolve,
  reject,
});

export const deletePlanSuccess = (payload) => ({
  type: types.PAYMENTS_DELETE_PLAN_SUCCESS,
  payload,
});
