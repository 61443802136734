import { WISTIA_URL_REGEXP } from '../constants';

const WISTIA_URL_ID_EXTRACTOR_REGEXP = /(?:https?:\/\/)[^.]+\.(?:wistia\.com|wi\.st)\/\S+\/(\w+)/;

export function isWistiaUrl(url) {
  return WISTIA_URL_REGEXP.test(url);
}

export const getWistiaVideoIdFromUrl = (url) => {
  if (isWistiaUrl(url)) {
    const match = url.match(WISTIA_URL_ID_EXTRACTOR_REGEXP);
    if (match && match.length) {
      return match[1];
    }
  }
};

// Examples
//
// https://fast.wistia.com/embed/medias/o9zg8jru1z?videoFoam=true&autoPlay=false
// https://sparkapp.wistia.com/medias/8xy9shtcey
