import { select, put, all, takeEvery, takeLatest, call } from 'redux-saga/effects';
import { getResources } from 'redux-resource';
import * as types from '../actions/actionTypes';
import * as api from '../services/spark-api';
import {
  receive as receiveSections,
  replace as replaceSection,
  remove as removeSection,
  updateContainerChildren,
} from '../actions/sectionActions';
import { selectSection, selectSections } from '../reducers/sectionsReducer';
import requireSiteId from './utils/requireSiteId';

function* initializeContainer(action) {
  const siteId = yield call(requireSiteId, action.payload.siteId);
  const { sectionId: originalSectionId, type } = action.payload;

  // Get the current section.
  const originalSection = yield select(selectSection, originalSectionId);

  // Get the carousel component from state
  const components = yield select((state) => getResources(state.sectionTypes, (component) => component.slug === type));

  const component = components && components.length && components[0];

  // converting existing section to container first slide
  const container = yield call(createSection, {
    site: siteId,
    item: originalSection.item || originalSection.pageId,
    location: originalSection.location,
    component: component.id,
    component_type_id: component.component_type,
  });

  const slide = originalSection;

  // Assemble new carousel section.
  container.children = [slide.id];

  const newSections = {
    sections: {
      [slide.id]: slide,
    },
  };

  // Replace section with the new test section.
  yield put(replaceSection(originalSection.id, container));
  yield put(receiveSections(newSections));

  action.callback(container.id);
}

function* createSection(payload) {
  const response = yield call(api.createSection, payload);

  if (response.ok) {
    return response.json;
  } else {
    console.error('Failed to create section', response);
    throw 'Failed to create section';
  }
}

function* addChildToContainer(action) {
  const siteId = yield call(requireSiteId, action.payload.siteId);
  const { containerId, component: newComponentID, type } = action.payload;

  // Get the current container.
  const originalContainer = yield select(selectSection, containerId);

  let component = yield select((state) =>
    getResources(
      state.sectionTypes,
      (component) => component.parent === newComponentID || component.id === newComponentID
    )
  );
  component = component[0];

  const newSlide = yield call(createSection, {
    site: siteId,
    item: originalContainer.item || originalContainer.pageId || action.payload.pageId,
    [type]: component?.id || newComponentID,
    location: originalContainer.location,
  });

  originalContainer.children = [...originalContainer.children, newSlide.id];

  // Receive the variant sections
  const newSections = {
    sections: {
      [newSlide.id]: newSlide,
    },
  };

  // Replace section with the new test section.
  yield put(receiveSections(newSections));
  yield put(updateContainerChildren(originalContainer.id, originalContainer.children));

  action.callback();
}

function* removeChild(action) {
  const { containerId, childIdx } = action.payload;

  // Get the current container.
  const originalContainer = yield select(selectSection, containerId);

  let children = yield select(selectSections, originalContainer.children);

  const removedChild = children.splice(childIdx, 1)[0];

  yield put(
    updateContainerChildren(
      originalContainer.id,
      children.map((child) => child.id)
    )
  );
  yield put(removeSection(removedChild.id));
}

// Root saga
// ----------------------------------------------------------------------------

function* sectionABTestSaga() {
  yield all([
    takeEvery(types.SECTIONS_INITIALIZE_CONTAINER, initializeContainer),
    takeEvery(types.SECTIONS_ADD_TO_CONTAINER, addChildToContainer),
    takeLatest(types.SECTIONS_REMOVE_CONTAINER_CHILD, removeChild),
  ]);
}

export default sectionABTestSaga;
