/* eslint-disable jsx-a11y/anchor-is-valid */

import React from 'react';
import { connect } from 'react-redux';
import * as pageActions from '../../../actions/pageActions';
import * as articleActions from '../../../actions/articleActions';

const mapDispatchToProps = {
  unpublishPage: pageActions.requestUnpublish,
  unpublishArticle: articleActions.requestUnpublish,
};

function Unpublish(props) {
  const { entityType, entityId, unpublishPage, unpublishArticle } = props;

  const handleClick = (e) => {
    e.preventDefault();

    switch (entityType) {
      case 'page':
        unpublishPage(entityId);
        break;
      case 'article':
        unpublishArticle(entityId);
        break;
      default:
    }
  };

  return (
    <a href="#" onClick={handleClick} data-test-id="unpublish">
      unpublish
    </a>
  );
}

export default connect(null, mapDispatchToProps)(Unpublish);
