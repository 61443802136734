import React from 'react';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './css-overrides.scss';

import isString from 'lodash/isString';
import parseDate from 'date-fns/parse';
import formatDate from 'date-fns/fp/lightFormat';

const isoDateStrTemplate = 'yyyy-MM-dd';
const formatIsoDate = formatDate(isoDateStrTemplate);

/**
 * DatePickerContainer
 *
 * An HOC for react-datepicker that adds reusable parsing functionality.
 * @param {function}
 * @param {(string|Date|null)} value
 * @param {boolean} [asIsoDateStr] - If true, the date picker parses and returns
 * the value as an ISO date-only string "yyyy-MM-dd" (e.g. 2018-12-18)
 */

class DatePickerContainer extends React.PureComponent {
  handleChange = (date) => {
    const { name, onChange } = this.props;

    const value = this.props.asIsoDateStr && date != null ? formatIsoDate(date) : date;

    if (name === undefined) onChange(value);
    else onChange(name, value);
  };

  render() {
    const { value, onChange, ...fieldProps } = this.props;

    // Parse value into a date if it is a string.
    // (If it's a Date object or null, don't parse it)
    let selected;
    if (isString(value)) {
      if (this.props.asIsoDateStr) selected = parseDate(value, isoDateStrTemplate, new Date());
      else selected = new Date(value);
    } else {
      selected = value;
    }

    return <DatePicker name="published_at" selected={selected} onChange={this.handleChange} {...fieldProps} />;
  }
}

export default DatePickerContainer;
