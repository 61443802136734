import React, { useEffect } from 'react';
import { compose } from 'redux';
import withSiteId from '../../../../containers/withSiteId';
import { connect } from 'react-redux';
import classnames from 'classnames';

import { Unstack, Video, Unsplash, Icons8, Pexels } from '../MediaLibrary2';
import { DataTable } from '../MediaLibrary2/DataTables';

import * as mediaActions from '../../../../actions/mediaActions';
import * as mediaSelectors from '../../../../reducers/mediaReducer';

import styles from './MediaDrawerBottom.module.scss';

function mapStateToProps(state, ownProps) {
  return {
    isLoading: mediaSelectors.selectIsFetching(state),
    allMedia: mediaSelectors.selectAll(state).filter((media) => media.site === ownProps.siteId),
    uploadProgress: mediaSelectors.selectUploadProgress(state),
    groupedMedia: mediaSelectors.selectAllCategoryGrouped(state),
    unstackSearchedMedia: mediaSelectors.selectUnstackSearchedMedia(state),
  };
}

const mapDispatchToProps = {
  requestMediaPage: mediaActions.requestPaginatedMediaList,
  deleteMedia: mediaActions.deleteMedia,
  searchUnstackMedia: mediaActions.searchUnstackMedia,
  clearUnstackSearch: mediaActions.clearUnstackSearch,
  createMedia: mediaActions.create,
};

function ContentViewer({
  category,
  selectedSource,
  entity,
  isUploading,
  onChange,
  requestMediaPage,
  deleteMedia,
  isLoading,
  enableLinking,
  showAll,
  uploadProgress,
  isErrorOnCreate,
  groupedMedia,
  searchUnstackMedia,
  unstackSearchedMedia,
  clearUnstackSearch,
  createMedia,
  showMediaContextOptions,
  dataTable,
  restrictMediaType = [],
  hideVideo,
  hideVideoOptions,
}) {
  const photoImageSize = {
    image: { width: 680, height: 400 },
    unsplash: { width: 680, height: 400 },
    pexels: { width: 680, height: 400 },
    icon: { width: 80, height: 80 },
    icons8: { width: 80, height: 80 },
  }[category] || { width: 80, height: 80 };

  const gridPhotoHeight = (280 * photoImageSize.height) / photoImageSize.width;

  const allMedia =
    groupedMedia[category]?.media?.filter(
      (media) => !restrictMediaType.some((type) => media?.mime_type_id?.includes(type))
    ) || [];

  return (
    <>
      {selectedSource === 'sparkMedia' && (
        <div className={styles.contentContainer}>
          <Unstack
            category={category}
            entity={entity}
            onChange={onChange}
            isUploading={isUploading}
            isErrorOnCreate={isErrorOnCreate}
            deleteMedia={deleteMedia}
            isLoading={isLoading}
            allMedia={allMedia}
            unstackSearchedMedia={unstackSearchedMedia}
            enableLinking={category !== 'video' && enableLinking}
            showMediaContextOptions={showMediaContextOptions}
            disableContextualOptions={showAll}
            uploadProgress={uploadProgress}
            requestMediaPage={requestMediaPage}
            categoryPageOptions={groupedMedia[category]}
            clearUnstackSearch={clearUnstackSearch}
            searchUnstackMedia={searchUnstackMedia}
            hideVideoOptions={hideVideoOptions}
          />
        </div>
      )}
      {selectedSource === 'data_table' && (
        <div className={styles.contentContainer} data-test-id="content-viewer">
          <DataTable onChange={onChange} dataTable={dataTable} entity={entity} />
        </div>
      )}
      {selectedSource === 'embedMedia' && (
        <div className={classnames(styles.contentContainer)} data-test-id="embed-media-content-container">
          <Video entity={entity} onChange={(entity) => onChange(entity)} />
        </div>
      )}

      {selectedSource === 'unsplash' && (
        <div className={classnames(styles.contentContainer, styles.searchEngine)}>
          <Unsplash
            photoImageSize={photoImageSize}
            gridPhotoHeight={gridPhotoHeight}
            category={category}
            onChange={(src, alt) => onChange({ src, ...(alt && { alt }) })}
            enableLinking={enableLinking}
            createMedia={createMedia}
          />
        </div>
      )}

      {selectedSource === 'icons8' && (
        <div className={classnames(styles.contentContainer, styles.searchEngine)}>
          <Icons8
            photoImageSize={photoImageSize}
            gridPhotoHeight={gridPhotoHeight}
            category={category}
            onChange={(src, alt) => onChange({ src, ...(alt && { alt }) })}
            enableLinking={enableLinking}
            createMedia={createMedia}
          />
        </div>
      )}
      {selectedSource === 'pexels' && (
        <div className={classnames(styles.contentContainer, styles.searchEngine)}>
          <Pexels
            photoImageSize={photoImageSize}
            gridPhotoHeight={gridPhotoHeight}
            category={category}
            onChange={(src, alt, options, isExternalSrc) =>
              onChange({
                src,
                ...(alt && { alt }),
                ...(options && options),
                ...(isExternalSrc && { isExternalSrc }),
              })
            }
            enableLinking={enableLinking}
            createMedia={createMedia}
            hideVideo={hideVideo}
            hideVideoOptions={hideVideoOptions}
          />
        </div>
      )}
    </>
  );
}

export default compose(withSiteId, connect(mapStateToProps, mapDispatchToProps))(ContentViewer);
