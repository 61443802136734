/* eslint-disable jsx-a11y/anchor-is-valid */

import React from 'react';
import { useDispatch } from 'react-redux';
import * as pageActions from '../../../actions/pageActions';

type Props = {
  pageId: string;
};

function UndeletePage(props: Props) {
  const dispatch = useDispatch();

  const handleClick = (e: any) => {
    e.preventDefault();
    const { pageId } = props;

    dispatch(
      pageActions.requestUndelete(pageId, function (pageId: string) {
        window.location.reload();
      })
    );
  };

  return (
    <a href="#" onClick={handleClick} data-test-id="undelete">
      undelete this
    </a>
  );
}

export default UndeletePage;
