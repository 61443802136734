import { keyBy } from 'lodash';
import * as types from './actionTypes';

import { IComponent } from 'types/Component';
import { ILayout } from 'types/SiteLayout';

export interface IFetchHeaderSections {
  type: 'SITE_LAYOUT_FETCH_HEADERS';
}
export function fetchHeaderSections() {
  return {
    type: types.SITE_LAYOUT_FETCH_HEADERS,
  };
}

export interface IReceiveHeaders {
  type: 'SITE_LAYOUT_RECEIVE_HEADERS';
  payload: { [key: string]: IComponent };
}
export function receiveHeaders(payload: Array<IComponent>) {
  const headersById = keyBy(payload, 'id');
  return {
    type: types.SITE_LAYOUT_RECEIVE_HEADERS,
    payload: headersById,
  };
}

export interface IFetchFooterSections {
  type: 'SITE_LAYOUT_FETCH_FOOTERS';
}
export function fetchFooterSections() {
  return {
    type: types.SITE_LAYOUT_FETCH_FOOTERS,
  };
}

export interface IReceiveFooters {
  type: 'SITE_LAYOUT_RECEIVE_FOOTERS';
  payload: { [key: string]: IComponent };
}
export function receiveFooters(payload: Array<IComponent>) {
  const footersById = keyBy(payload, 'id');
  return {
    type: types.SITE_LAYOUT_RECEIVE_FOOTERS,
    payload: footersById,
  };
}

export interface IFetchSiteLayout {
  type: 'SITE_LAYOUT_FETCH';
}
export function fetchSiteLayout() {
  return {
    type: types.SITE_LAYOUT_FETCH,
  };
}

export interface IFetchSiteLayouttIfNeeded {
  type: 'SITE_LAYOUT_FETCH_IF_NEEDED';
}
export function fetchSiteLayoutIfNeeded() {
  return {
    type: types.SITE_LAYOUT_FETCH_IF_NEEDED,
  };
}

export interface ISiteLayoutReceivedList {
  type: 'SITE_LAYOUT_RECEIVED_LIST';
  payload: { [key: string]: ILayout };
}
export function siteLayoutReceivedList(payload: Array<ILayout>) {
  const layoutsById = keyBy(payload, 'id');
  return {
    type: types.SITE_LAYOUT_RECEIVED_LIST,
    payload: layoutsById,
  };
}

export interface ISiteLayoutReceive {
  type: 'SITE_LAYOUT_RECEIVE';
  payload: { [key: string]: ILayout };
}
export function siteLayoutReceive(payload: ILayout) {
  return {
    type: types.SITE_LAYOUT_RECEIVE,
    payload: { [payload.id]: payload },
  };
}

export interface IGetLayoutComponents {
  type: 'SITE_LAYOUT_FETCH_SECTIONS';
  payload: { [key: string]: string };
}
export function getLayoutComponents(itemType: string) {
  return {
    type: types.SITE_LAYOUT_FETCH_SECTIONS,
    payload: { itemType },
  };
}

export interface ICreateLayout {
  type: 'SITE_LAYOUT_CREATE';
  payload: { [key: string]: string };
}
export function createLayout(payload: Partial<ILayout>) {
  return {
    type: types.SITE_LAYOUT_CREATE,
    payload,
  };
}

export interface IUpdateLayout {
  type: 'SITE_LAYOUT_UPDATE';
  payload: { [key: string]: ILayout };
}
export function updateLayout(layout: ILayout) {
  return {
    type: types.SITE_LAYOUT_UPDATE,
    payload: layout,
  };
}

export interface IBulkUpdateOrCreate {
  type: 'SITE_LAYOUT_BULK_UPDATE_OR_CREATE';
  payload: { [key: string]: ILayout };
}
export function bulkUpdateOrCreate(payload: { [key: string]: Partial<ILayout> }) {
  return {
    type: types.SITE_LAYOUT_BULK_UPDATE_OR_CREATE,
    payload,
  };
}

export type ISiteLayoutActions =
  | IFetchHeaderSections
  | IReceiveHeaders
  | IFetchFooterSections
  | IReceiveFooters
  | IFetchSiteLayout
  | IFetchSiteLayouttIfNeeded
  | ISiteLayoutReceivedList
  | IGetLayoutComponents
  | ICreateLayout
  | IUpdateLayout
  | ISiteLayoutReceive;
