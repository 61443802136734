import { call, all, takeLatest, put } from 'redux-saga/effects';
import * as api from '../services/spark-api';
import * as types from '../actions/actionTypes';
import { fetchSuccess } from '../actions/notesActions';
import requireSiteId from './utils/requireSiteId';
import { updateSubmittingState, resetSubmittingState } from '../actions/uiSubmitButtonsStateActions';

function* create(action) {
  const { payload, resolve, reject } = action;
  const siteId = yield call(requireSiteId, payload.site);
  const data = { ...payload, site: siteId };

  try {
    yield put(updateSubmittingState(true));
    const response = yield call(api.postNote, data);
    if (response.ok) resolve();
    else reject();
    yield put(resetSubmittingState());
  } catch (e) {
    yield put(resetSubmittingState());
    console.error(e);
    reject();
  }
}

function* fetchFiltered(action) {
  const filters = action.payload;
  const siteId = yield call(requireSiteId, filters.siteId);

  try {
    const response = yield call(api.getFilteredNotes, siteId, filters);
    if (response.ok) {
      yield put(fetchSuccess(response.json.results));
    }
  } catch (e) {
    console.error(e);
  }
}

function* update(action) {
  const { payload, resolve, reject } = action;
  try {
    yield put(updateSubmittingState(true));
    const response = yield call(api.putNote, payload);
    if (response.ok) resolve();
    else reject();
    yield put(resetSubmittingState());
  } catch (e) {
    yield put(resetSubmittingState());
    console.error(e);
    reject();
  }
}

function* deleteNote(action) {
  const { payload, resolve, reject } = action;
  try {
    yield put(updateSubmittingState(true));
    const response = yield call(api.deleteNote, payload);
    if (response.ok) resolve();
    else reject();
    yield put(resetSubmittingState());
  } catch (e) {
    yield put(resetSubmittingState());
    console.error(e);
    reject();
  }
}

// Root saga
// ----------------------------------------------------------------------------

function* notesSaga() {
  yield all([
    takeLatest(types.NOTES_CREATE_REQUEST, create),
    takeLatest(types.NOTES_FETCH_FILTERED_NOTES, fetchFiltered),
    takeLatest(types.NOTES_UPDATE_REQUEST, update),
    takeLatest(types.NOTES_DELETE_REQUEST, deleteNote),
  ]);
}

export default notesSaga;
