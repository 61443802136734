import { useProducts } from 'components/Storefront/hooks';
import { throttle } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectIntegrationBySlug } from 'reducers/integrationsReducer';
import { UProduct } from 'types/UProduct';

const withProducts = (WrappedComponent: React.FunctionComponent<any>) => {
  return (props: JSX.IntrinsicAttributes & { children?: React.ReactNode }) => {
    const [productQuery, setProductQuery] = useState('');
    // @ts-ignore
    const isShopifyConnected = useSelector((state) => selectIntegrationBySlug(state, props.siteId, 'shopify'));
    // @ts-ignore
    const isEPConnected = useSelector((state) => selectIntegrationBySlug(state, props.siteId, 'elastic_path'));
    const hasStorefront = !!isEPConnected || !!isShopifyConnected;

    let data, isLoading;
    if (hasStorefront) {
      [data, isLoading] = useProducts(productQuery.trim(), 10, '', '', true);
    } else {
      data = undefined;
      isLoading = false;
    }

    const setQueryThrottled = useCallback(
      throttle((query: string) => {
        setProductQuery(query);
      }, 2000),
      []
    );

    const [products, setProducts] = useState<UProduct[]>([]);

    useEffect(() => {
      if (data?.data?.length > 0 && !isLoading) {
        const newProducts = [...data.data, ...products].filter(
          (value, index, self) => index === self.findIndex((t) => t.id === value.id)
        );
        setProducts(newProducts);
      }
    }, [data, isLoading]);

    return (
      <WrappedComponent
        products={products}
        setProducts={setProducts}
        {...props}
        productsLoading={isLoading}
        setProductQuery={setQueryThrottled}
      />
    );
  };
};

export default withProducts;
