import { call, select, put, putResolve, all, throttle, takeLatest } from 'redux-saga/effects';
import get from 'lodash/get';
import * as api from '../services/spark-api';
import * as types from '../actions/actionTypes';
import * as accountActions from '../actions/accountActions';
import * as siteSelectors from '../reducers/sitesReducer';
import * as siteActions from '../actions/siteActions';
import requireSiteId from './utils/requireSiteId';
import { normalizeSiteList } from './normalizrs/site-normalizrs';

function* fetchSitesIfNeeded(_action) {
  const sites = yield select(siteSelectors.selectAllSites);
  if (sites == null || !sites.length) yield put(siteActions.requestAll());
}

function* fetchSites(_action) {
  try {
    const response = yield call(api.getSites);

    if (response.ok) {
      const normalizedData = normalizeSiteList(response.json.results);
      const { result: siteIds, entities } = normalizedData;

      yield put(siteActions.receiveList(siteIds, entities));
    } else if (response.unauthorized) {
      console.log('loging out from site saga');
      yield putResolve(accountActions.logout());
    } else {
      // yield put()
      console.error('Failed trying to fetch site', response.json);
    }
  } catch (err) {
    console.error(err);
  }
}

//
// function* fetchSiteIfNeeded(action) {
//   const site = yield select(siteSelectors.selectSite);
//   if (site.id == null) {
//     const { id } = action.payload;
//     yield put(siteActions.request(id));
//   }
// }
//
// function* fetchSite(action) {
//   try {
//     const { id } = action.payload;
//     const response = yield call(api.getSite, id);
//     if (response.ok) {
//       const site = response.json.results[0];
//       yield put(siteActions.receive(site));
//     } else if (response.unauthorized) {
//       yield put(accountActions.logout());
//     } else {
//       console.error('Failed trying to fetch site', response.json);
//     }
//   } catch (err) {
//     console.error(err);
//   }
// }
//
// function* afterReceiveSite(action) {
//   const site = { ...action.payload };
//
//   // When receiving a site, apply defaults to blank fields
//   if (isEmpty(site.styles)) {
//     site.styles = {
//       lightBackgroundColor: '#FFFFFF',
//       darkBackgroundColor: '#293241',
//     };
//     yield put(siteActions.update(site));
//   }
// }

function* updateSite(action) {
  const siteId = yield requireSiteId(action.payload.id);
  const site = yield select((state) => siteSelectors.selectSiteById(state, siteId));

  try {
    const response = yield call(api.updateSite, site);
    if (response.ok) {
      const site = response.json;
      yield put(siteActions.receive(site));
    } else if (response.unauthorized) {
      yield put(accountActions.logout());
    } else {
      console.warn('unhandled updateSite error', response);
    }
  } catch (err) {
    console.error(err);
  }
}

function* updateSiteOnboarding(action) {
  const { id, ...onboardingStep } = action.payload;
  const siteId = yield requireSiteId(id);
  try {
    const response = yield call(api.patchSiteOnboarding, {
      id: siteId,
      stepData: onboardingStep,
    });
    if (response.ok) {
      const site = response.json;
      yield put(siteActions.receive(site));
    } else if (response.unauthorized) {
      yield put(accountActions.logout());
    } else {
      console.warn('unhandled updateSite error', response);
    }
  } catch (err) {
    console.error(err);
  }
}

function* removeSiteOnboardingStep(action) {
  const { id, pendingStep, ...onboardingStep } = action.payload;
  const siteId = yield requireSiteId(id);
  try {
    const response = yield call(api.deleteSiteOnboardingStep, {
      id: siteId,
      stepData: onboardingStep,
    });
    if (response.ok) {
      const site = response.json;
      yield put(siteActions.receive(site));
    } else if (response.unauthorized) {
      yield put(accountActions.logout());
    } else {
      console.warn('unhandled updateSite error', response);
    }
  } catch (err) {
    console.error(err);
  } finally {
    if (!pendingStep) return;
    yield call(updateSiteOnboarding, {
      payload: {
        id: id,
        step: pendingStep,
      },
    });
  }
}

function* updateSiteUnthrottled(action) {
  try {
    const site = action.payload;
    const response = yield call(api.updateSite, site);
    if (response.ok) {
      const site = response.json;
      yield put(siteActions.receive(site));
    } else if (response.unauthorized) {
      put(accountActions.logout());
    } else {
      console.warn('Unhandled updateSite error', response);
    }
  } catch (err) {
    console.error(err);
  }
}

function* setDomain(action) {
  try {
    const { siteId, newDomain } = action.payload;
    const response = yield call(api.setDomain, siteId, newDomain);
    if (response.ok) {
      const site = response.json;
      yield put(siteActions.receive(site));
    } else if (response.status === 422 || response.status === 400) {
      yield put(siteActions.domainFailure(response.json.error || response.json.domain));
    } else if (response.unauthorized) {
      put(accountActions.logout());
    } else {
      console.warn('Unhandled error when setting site domain', response);
    }
  } catch (err) {
    console.error(err);
  }
}

function* removeDomain(action) {
  try {
    const { siteId } = action.payload;
    const response = yield call(api.removeDomain, siteId);
    if (response.ok) {
      const site = response.json;
      yield put(siteActions.receive(site));
    } else if (response.unauthorized) {
      put(accountActions.logout());
    } else {
      console.warn('Unhandled error when removing site domain', response);
    }
  } catch (err) {
    console.error(err);
  }
}

function* addSsl(action) {
  try {
    const { siteId } = action.payload;
    const response = yield call(api.addSsl, siteId);
    if (response.ok) {
      const site = response.json;
      yield put(siteActions.receive(site));
    } else if (response.unauthorized) {
      put(accountActions.logout());
    } else {
      console.warn('Unhandled error when adding SSL', response);
    }
  } catch (err) {
    console.error(err);
  }
}

function* createSite(action) {
  const { account, theme, domain, resolve, reject } = action.payload;
  const response = yield call(api.createSite, { account, theme, domain });
  if (response.ok) {
    const site = response.json;
    yield put(siteActions.receive(site));
    resolve();
  } else reject(response);
}

function* deleteSite() {
  const activeSite = yield select(siteSelectors.selectActiveSite);
  const siteId = get(activeSite, 'id');
  try {
    const response = yield call(api.deleteSite, siteId);
    if (response.ok) {
      window.location = '/';
    }
  } catch (e) {
    console.error(e);
  }
}

function* flushCache(action) {
  const { payload } = action;
  const siteId = yield call(requireSiteId, payload.siteId);
  try {
    const response = yield call(api.flushSiteCache, siteId);
    payload.callback(response.ok);
  } catch (e) {
    console.error(e);
    payload.callback(false);
  }
}

function* siteSaga() {
  yield all([
    takeLatest(types.SITE_REQUEST_ALL_IF_NEEDED, fetchSitesIfNeeded),
    takeLatest(types.SITE_REQUEST_ALL, fetchSites),
    // takeLatest(types.SITE_REQUEST_IF_NEEDED, fetchSiteIfNeeded),
    // takeLatest(types.SITE_REQUEST, fetchSite),
    // takeLatest(types.SITE_RECEIVE, afterReceiveSite),
    throttle(5000, types.SITE_UPDATE, updateSite),
    takeLatest(types.SITE_UPDATE_UNTHROTTLED, updateSiteUnthrottled),
    takeLatest(types.SITE_SET_DOMAIN, setDomain),
    takeLatest(types.SITE_REMOVE_DOMAIN, removeDomain),
    takeLatest(types.SITE_ADD_SSL, addSsl),
    takeLatest(types.SITE_CREATE_REQUEST, createSite),
    takeLatest(types.SITE_DELETE_REQUEST, deleteSite),
    takeLatest(types.SITE_FLUSH_CACHE, flushCache),
    takeLatest(types.SITE_UPDATE_ONBOARDING, updateSiteOnboarding),
    takeLatest(types.SITE_REMOVE_ONBOARDING_STEP, removeSiteOnboardingStep),
  ]);
}

export default siteSaga;
