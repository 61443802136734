import { IconError } from 'components/base/BaseIcons';
import React from 'react';
import styles from './EditorErrorBoundary.module.scss';

function EditorErrorBoundary() {
  return (
    <div className={styles.ErrorWrapper}>
      <div className={styles.ErrorCard}>
        <IconError />
        <div className={styles.ErrorText}>
          <div className={styles.ErrorTitle}>Whoops!</div>
          <div>There was an error loading this page. Please refresh this page to try again.</div>
        </div>
      </div>
    </div>
  );
}

export default EditorErrorBoundary;
