import React from 'react';
import { connect } from 'react-redux';
import { selectHasPermission } from '../reducers/policyReducer';

function mapStateToProps(state) {
  return {
    hasPermission: selectHasPermission(state),
  };
}

export default (WrappedComponent) => {
  class Container extends React.PureComponent {
    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  return connect(mapStateToProps, null)(Container);
};
