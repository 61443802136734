import React, { useState } from 'react';
import '../../../../services/spark-api';

import styles from './Card.module.scss';

import { ReactComponent as SvgIconVisa } from '../../../../assets/images/payment/icon-visa.svg';
import { ReactComponent as SvgIconMastercard } from '../../../../assets/images/payment/icon-mastercard.svg';
import { ReactComponent as SvgIconDiscover } from '../../../../assets/images/payment/icon-discover.svg';
import { ReactComponent as SvgIconAmex } from '../../../../assets/images/payment/icon-amex.svg';
import { ReactComponent as SvgIconUnionpay } from '../../../../assets/images/payment/icon-unionpay.svg';
import { ReactComponent as SvgIconJcb } from '../../../../assets/images/payment/icon-jcb.svg';
import { ReactComponent as SvgIconDinersClub } from '../../../../assets/images/payment/icon-diners-club.svg';
import PaymentUpdate from 'components/SubscriptionManager/PaymentUpdate';

const brandIcons = {
  Visa: SvgIconVisa,
  'American Express': SvgIconAmex,
  Discover: SvgIconDiscover,
  MasterCard: SvgIconMastercard,
  UnionPay: SvgIconUnionpay,
  JCB: SvgIconJcb,
  'Diners Club': SvgIconDinersClub,
};

export default function Card(props) {
  const [isOpen, setIsOpen] = useState(false);
  const { card } = props;

  if (!card) return <></>;

  const BrandIcon = brandIcons[card.brand];

  return (
    <span className={styles.container}>
      {BrandIcon && <BrandIcon className={styles.brand} />}
      <span className="ph-redact-text">
        **** **** **** {card.last4}{' '}
        <a
          href="/"
          onClick={(e) => {
            e.preventDefault();
            setIsOpen(true);
          }}
        >
          (edit card)
        </a>
      </span>
      <PaymentUpdate isOpen={isOpen} close={() => setIsOpen(false)} />
    </span>
  );
}
