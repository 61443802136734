import React from 'react';
import Table from '../../../react-table/Table';
import formatTime from '../../../../lib/formatters/formatTime';

// Columns
const columns = [
  {
    Header: 'Page',
    accessor: 'name',
    Cell: ({ value }) => (value !== null ? (value.toLocaleString ? value.toLocaleString() : value) : '--'),
  },
  {
    Header: 'Page views',
    accessor: 'views',
    Cell: ({ value }) => (value !== null ? (value.toLocaleString ? value.toLocaleString() : value) : '--'),
  },
  {
    Header: 'Uniques',
    accessor: 'users',
    Cell: ({ value }) => (value !== null ? (value.toLocaleString ? value.toLocaleString() : value) : '--'),
  },
  {
    Header: 'Avg. time on page',
    accessor: 'avg_time_on_page',
    Cell: ({ value }) => (value !== null ? `${formatTime(new Date(value))}` : '--'),
  },
];

export function ActivityReport(props) {
  const { data, isLoading } = props;

  return (
    <Table
      columns={columns}
      data={data}
      defaultSorted={[{ id: 'views', desc: true }]}
      loading={isLoading}
      showViewAll={true}
      defaultPageSize={10}
    />
  );
}
