import React, { useState, useEffect } from 'react';
import styles from './ZeroStateLandingPage.module.scss';

import { useRecentProduct } from '../Storefront/hooks';

import tabletDefaultLandingPage from '../../assets/images/landing-page-tablet-default.png';
import mobileDefaultLandingPage from '../../assets/images/landing-page-mobile-default.png';
import tabletEmptyLandingPage from '../../assets/images/landing-page-tablet-empty.png';
import mobileEmptyLandingPage from '../../assets/images/landing-page-mobile-empty.png';
import defaultLandingPage from '../../assets/images/landing-page-default.svg';
import rightLandingPage from '../../assets/images/landing-page-right.svg';
import leftLandingPage from '../../assets/images/landing-page-left.svg';

export default function ZeroStateLandingPage() {
  const [product] = useRecentProduct();

  return (
    <article className={styles.PageListZeroState}>
      <div className={styles.ImagesContainer}>
        {product ? (
          <>
            <img src={leftLandingPage} alt="left icon" className={styles.LandingPageLeftTab} />
            <img src={rightLandingPage} alt="right icon" className={styles.LandingPageRightTab} />
            <img src={leftLandingPage} alt="left icon" className={styles.LandingPageLeftMobile} />
            <img src={rightLandingPage} alt="right icon" className={styles.LandingPageRightMobile} />
            <img src={tabletEmptyLandingPage} alt="landing page tablet" className={styles.LandingPageTablet} />
            <img src={mobileEmptyLandingPage} alt="landing page mobile" className={styles.LandingPageMobile} />

            <div className={styles.ProductTablet}>
              <img src={product?.media[0]?.url || `${defaultLandingPage}`} alt="landing page tablet product" />
            </div>
            <div className={styles.ProductMobile}>
              <img src={product?.media[0]?.url || `${defaultLandingPage}`} alt="landing page mobile product" />
            </div>
            <div className={styles.ThumbnailContainerTablet}>
              {product.media.map((item, i) => (
                <div className={styles.ThumbnailTablet} key={`tablet_thumbnail_${i}`}>
                  <img src={item.url} alt="product thumbnail tablet" />
                </div>
              ))}
            </div>
            <div className={styles.ThumbnailContainerMobile}>
              {product.media.map((item, i) => (
                <div className={styles.ThumbnailMobile} key={`mobile_thumbnail_${i}`}>
                  <img src={item.url} alt="product thumbnail mobile" />
                </div>
              ))}
            </div>
          </>
        ) : (
          <>
            <img src={tabletDefaultLandingPage} alt="landing page tablet" className={styles.LandingPageTablet} />
            <img src={mobileDefaultLandingPage} alt="landing page mobile" className={styles.LandingPageMobile} />
          </>
        )}
      </div>
      <h2>Create your first landing page!</h2>
      <p>Click the new page button in the top right corner to begin.</p>
    </article>
  );
}
