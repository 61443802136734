import React, { ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { selectIsLoggedIn } from '../reducers/accountReducer';
import { useLocation, useNavigate } from 'react-router-dom';
import { OnMount } from 'hooks/mountUnmountHooks';

type Props = {
  children?: ReactNode;
  redirectTo?: string;
};

function RequireLogin(props: Props) {
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const navigate = useNavigate();
  const location = useLocation();
  const path = location.pathname;

  OnMount(() => {
    if (!isLoggedIn) {
      if (props.redirectTo) {
        navigate(props.redirectTo);
      } else if (path) {
        navigate(`/login?ref=${path}`);
      }
    }
  });

  if (isLoggedIn && props.children) {
    return <>{props.children}</>;
  } else {
    return <></>;
  }
}

export default RequireLogin;
