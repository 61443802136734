import { call, put, select, all, takeEvery, takeLatest } from 'redux-saga/effects';
import * as api from '../services/spark-api';
import * as actionTypes from '../actions/actionTypes';
import * as goalActions from '../actions/goalActions';
import * as goalSelectors from '../reducers/goalsReducer';
import requireSiteId from './utils/requireSiteId';
import { callSparkApi } from './utils/callSparkApi';
import { normalizeGoalsResponse as normalize } from './normalizrs/goal-normalizers';

function* getGoalsIfNeeded(action) {
  const hasFetched = yield select(goalSelectors.selectHasFetched);
  if (!hasFetched) yield put(goalActions.requestAll(action.payload.siteId));
}

function* getGoals(action) {
  const siteId = yield call(requireSiteId, action.payload.siteId);
  const response = yield call(callSparkApi, api.getGoals, siteId);

  if (response.ok) {
    const { entities, result: goalIds } = normalize(response.json.results);
    yield put(goalActions.receiveAll(goalIds, entities));
  } else {
    yield put(goalActions.receiveError(response.json));
  }
}

function* goalsSaga() {
  yield all([
    takeEvery(actionTypes.GOALS_REQUEST_ALL_IF_NEEDED, getGoalsIfNeeded),
    takeLatest(actionTypes.GOALS_REQUEST_ALL, getGoals),
  ]);
}

export default goalsSaga;
