import React from 'react';
import { connect } from 'react-redux';

import { set } from '../../lib/immutable-operations/operations';

import * as blogSelectors from '../../reducers/blogsReducer';
import { addAutosaveOps } from '../../actions/blogActions';

import BlogAside from './BlogAside';
import ArticleFooter from './ArticleFooter';
import BlogsGridLayoutSkeleton from './BlogsGridLayoutSkeleton';
import BlogsListLayoutSkeleton from './BlogsListLayoutSkeleton';

function mapStateToProps(state, ownProps) {
  return {
    blog: blogSelectors.selectDraft(state, ownProps.blogId),
  };
}

const mapDispatchToProps = {
  addAutosaveOps,
};

class BlogBody extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showCustomCodeBlock: props.blog.content.custom_code_enabled,
    };
  }

  render() {
    const { blogId, blog, showCategoryLayout } = this.props;
    const { layout = {} } = blog.content;

    return (
      <>
        <div className="container blog-container" data-test-id="blog-body-container">
          {showCategoryLayout ? (
            <>
              {layout.type === 'grid' ? (
                <BlogsGridLayoutSkeleton articlesCount={layout.size || 7} />
              ) : (
                <>
                  <BlogsListLayoutSkeleton articlesCount={layout.size || 7} />
                </>
              )}
            </>
          ) : (
            <>
              <div className="blog-listing">
                <BlogsListLayoutSkeleton articlesCount={1} />
              </div>

              <BlogAside blogId={blogId} />
            </>
          )}
        </div>
        {!showCategoryLayout && (
          <ArticleFooter
            blogId={blogId}
            content={blog.content.sections ? blog.content.sections[0] : {}}
            update={(value) => {
              this.props.addAutosaveOps(blogId, set('content.sections', value ? [value] : []));
            }}
          />
        )}
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BlogBody);
