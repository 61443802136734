export function createOp(type, path, value, position) {
  return { type, path, value, position };
}

export function set(...args) {
  return createOp('set', ...args);
}

export function update(...args) {
  return createOp('update', ...args);
}

export function push(...args) {
  return createOp('push', ...args);
}

export function del(...args) {
  return createOp('del', ...args);
}

export function assign(...args) {
  return createOp('assign', ...args);
}

export function insert(...args) {
  return createOp('insert', ...args);
}

export function merge(...args) {
  return createOp('merge', ...args);
}
