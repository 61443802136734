import React, { useState } from 'react';
import orderBy from 'lodash/orderBy';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import useUrlSite from '../../../../hooks/useUrlSite';
import { useGoogleConsoleData } from '../../Insights/report-hooks';

import ZeroStateView from './ZeroState';
import Spinner from '../../../base/Spinner';
import List from '../../../react-table/List';
import { Group } from 'components/AdminPanel/ZeroState';

import { selectSiteIntegrationsBySlug, selectIsFetching } from '../../../../reducers/integrationsReducer';
import { selectActiveSite, getTLDFromSite } from '../../../../reducers/sitesReducer';

import unstackMark from '../../../../assets/images/unstack-mark.svg';
import iconGoogleConsole from '../../../../assets/images/icon-google.svg';
import { ReactComponent as PrevSvg } from '../../../../assets/images/icon-prev-page.svg';
import { ReactComponent as NextSvg } from '../../../../assets/images/icon-next-page.svg';

import styles from './GoogleConsole.module.scss';

function isFloat(n) {
  return Number(n) === n && n % 1 !== 0;
}

const generateTabelColumns = (columns, headers) => {
  return Object.entries(columns).map((column) => ({
    Header: headers[column[0]] || column[0],
    accessor: column[0],
    Cell: ({ value }) => {
      return headers.keys === 'Page' && column[0] == 'keys' ? (
        <a href={value} target="_blank">
          {' '}
          {value}{' '}
        </a>
      ) : (
        <span>
          {isFloat(value) ? (column[0] === 'ctr' ? `${(value * 100).toFixed(2)}%` : value.toFixed(2)) : value}
        </span>
      );
    },
    sortable: !headers[column[0]],
  }));
};

const GoogleConsole = () => {
  const site = useSelector((state) => selectActiveSite(state));
  const siteDomain = getTLDFromSite(site);
  const isIntegrationsFetched = useSelector((state) => selectIsFetching(state));
  const googleConsoleIntegration = useSelector((state) =>
    selectSiteIntegrationsBySlug(state, site.id)
  ).google_search_console;
  const [data, error, isLoading] = useGoogleConsoleData(
    googleConsoleIntegration ? googleConsoleIntegration[0].id : '',
    siteDomain
  );
  const urlSite = useUrlSite();

  //  Setting up variable for query stats table
  const queryTableColumn = data && data.query && generateTabelColumns(data.query[0], { keys: 'Query', ctr: 'CTR' });
  const [activeQueryPage, setActiveQueryPage] = useState(1);
  const [activeQueryFilter, setActiveQueryFilter] = useState({
    id: 'clicks',
    desc: true,
  });
  const query =
    data && data.query && orderBy(data.query, [activeQueryFilter.id], [activeQueryFilter.desc ? 'desc' : 'acs']);

  //  Setting up variable for page stats table
  const pageTableColumn = data && data.query && generateTabelColumns(data.page[0], { keys: 'Page', ctr: 'CTR' });
  const [activePageStatsPage, setActivePageStatsPage] = useState(1);
  const [activePageFilter, setActivePageFilter] = useState({
    id: 'clicks',
    desc: true,
  });
  const page =
    data && data.query && orderBy(data.page, [activePageFilter.id], [activePageFilter.desc ? 'desc' : 'acs']);

  const limit = 10,
    offsetQuery = limit * (activeQueryPage - 1),
    offsetPage = limit * (activePageStatsPage - 1);

  return site.is_domain_active ? (
    <div className={styles.container}>
      {Boolean(isLoading && isIntegrationsFetched) && <Spinner className="fixed" />}
      {Boolean(isLoading && !isIntegrationsFetched && googleConsoleIntegration) && <Spinner className="fixed" />}
      {Boolean(isLoading && !isIntegrationsFetched && !googleConsoleIntegration) && (
        <ZeroStateView
          icon={iconGoogleConsole}
          header="Connect Google Search Console"
          message="See your SEO data directly in Unstack"
          redirection={
            <Group>
              <Link to={`/${urlSite}/integrations`} className="button button-primary">
                Connect GSC
              </Link>
            </Group>
          }
        />
      )}
      {Boolean(!isLoading && !isIntegrationsFetched && googleConsoleIntegration && error && !query) && (
        <ZeroStateView icon={iconGoogleConsole} header="There was an error processing the request" message={error} />
      )}
      {Boolean(
        !isLoading &&
          !isIntegrationsFetched &&
          googleConsoleIntegration &&
          query &&
          !error &&
          pageTableColumn &&
          queryTableColumn
      ) && (
        <>
          <section>
            <header className={styles.header}>
              <div>
                <h1>Top Keywords</h1>
                <small>Search terms driving SEO traffic for your business over last 90 days.</small>
              </div>
            </header>
          </section>
          <List
            data={query.slice(offsetQuery, limit + offsetQuery)}
            columns={queryTableColumn}
            count={query.length}
            defaultPageSize={10}
            loading={false}
            showViewAll={false}
            breakPageslabel={<span>...</span>}
            nextPageLabel={<NextSvg />}
            onPageChange={(page) => setActiveQueryPage(page)}
            pagesCount={query.length / 10}
            paginate
            activePage={activeQueryPage}
            previousPageLabel={<PrevSvg />}
            showHeader
            manual
            sorted={[activeQueryFilter]}
            onSortedChange={(newSort) => {
              setActiveQueryFilter(newSort[0]);
            }}
          />
          <section>
            <header className={styles.header}>
              <div>
                <h1>Top Content</h1>
                <small>Web pages driving organic search traffic for your business over last 90 days.</small>
              </div>
            </header>
          </section>
          <List
            data={page.slice(offsetPage, limit + offsetPage)}
            columns={pageTableColumn}
            count={page.length}
            defaultPageSize={10}
            loading={false}
            showViewAll={false}
            breakPageslabel={<span>...</span>}
            nextPageLabel={<NextSvg />}
            onPageChange={(page) => setActivePageStatsPage(page)}
            pagesCount={page.length / 10}
            paginate
            activePage={activePageStatsPage}
            previousPageLabel={<PrevSvg />}
            showHeader
            manual
            sorted={[activePageFilter]}
            onSortedChange={(newSort) => {
              console.log(newSort[0]);
              setActivePageFilter(newSort[0]);
            }}
          />
        </>
      )}
      {Boolean(
        !isLoading &&
          !isIntegrationsFetched &&
          googleConsoleIntegration &&
          !query &&
          !error &&
          !pageTableColumn &&
          !queryTableColumn
      ) && (
        <ZeroStateView
          icon={iconGoogleConsole}
          header="Please try again later"
          message="Your data is being processed. It may take a day or two"
        />
      )}
    </div>
  ) : (
    <div className={styles.container}>
      <ZeroStateView
        icon={unstackMark}
        header="Custom domain required"
        message="Please add custom domain to your site to view these stats"
      />
    </div>
  );
};

export default GoogleConsole;
