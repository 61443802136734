import React from 'react';
import isEmpty from 'lodash/isEmpty';

class FormControl extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      shouldImitate:
        (isEmpty(props.imitate) && isEmpty(props.value)) || (this.props.alwaysImitate && props.imitate == props.value),
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.alwaysImitate && this.props.imitate === this.props.value) {
      this.setState({ shouldImitate: true });
    }
    if (this.state.shouldImitate) {
      if (prevProps.imitate !== this.props.imitate) {
        if (!(this.props.name === 'slug' && this.props.status === 'published')) {
          this.props.onChange(this.props.name, this.props.imitate);
        }
      } else if (
        prevProps.value !== this.props.value &&
        this.props.value !== this.props.imitate &&
        !this.props.alwaysImitate
      ) {
        this.setState({ shouldImitate: false });
      }
    }
  }

  handleChange = (...args) => {
    if (this.props.alwaysImitate && args[0].currentTarget.value === this.props.imitate) {
      this.setState({ shouldImitate: true });
    } else if (this.state.shouldImitate) {
      this.setState({ shouldImitate: false });
    }
    this.props.onChange(...args);
  };

  render() {
    const { tag, className, errors, onChange, origValue, ...fieldProps } = this.props;

    const TagName = tag || 'input';

    const combinedClassName = errors == null ? className : `${className} fieldWithErrors`;

    let errorMessage;
    if (errors != null) {
      if (errors.join) errorMessage = errors.join(' ');
      else errorMessage = errors;
    }

    if (fieldProps?.type && fieldProps?.type === 'password' && origValue) {
      return <input disabled type="password" value={'xxxxxxxxxxxxxxxxxxxxxx'}></input>;
    }

    return (
      <>
        <TagName key="FormControl-input" className={combinedClassName} onChange={this.handleChange} {...fieldProps} />
        {errors && (
          <small key="FormControl-errorMessage" className="errorMessage" data-test-id="error">
            {errorMessage}
          </small>
        )}
      </>
    );
  }
}

export default FormControl;
