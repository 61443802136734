import * as types from '../actions/actionTypes';
import { combineReducers } from 'redux';
import { createSelector } from 'reselect';
import createCachedSelector from 're-reselect';

import { selectVariantIds, selectChildrenIds } from './sectionsReducer';
import { getUrlFromSite, selectSiteById } from './sitesReducer';

import { assign, del, get, insert, push, set, update, wrap } from 'object-path-immutable';
import forOwn from 'lodash/forOwn';
import idx from 'lodash/get';
import indexOf from 'lodash/indexOf';
import union from 'lodash/union';
import merge from 'lodash/merge';

// ----------------------------------------------------------------------------
// Reducer
// ----------------------------------------------------------------------------

function pagesByIdReducer(state = {}, action) {
  switch (action.type) {
    case types.PAGES_RECEIVE_LIST: {
      const newState = action.payload.entities.pages || {};

      // NOTE: Preserve page.content from old state because they've been
      // removed from the payload on GET /page/ index requests.
      forOwn(newState, (page, pageId) => {
        const pageContent = idx(state, [pageId, 'content']);
        if (pageContent) newState[pageId].content = pageContent;
      });
      return newState;
    }

    case types.PAGES_RECEIVE_LIST_ITEM: {
      const newState = action.payload.entities.pages || {};
      return merge(state, newState);
    }

    case types.PAGE_REQUEST:
      return set(state, [action.payload.pageId, 'isFetching'], true);

    case types.PAGE_RECEIVE: {
      return {
        ...state,
        ...action.payload.entities.pages,
      };
    }

    case types.PAGE_TOUCH: {
      const { id, meta } = action.payload;

      return {
        ...state,
        [id]: {
          ...state[id],
          touched: true,
          is_changed: true,
          meta: {
            ...state[id]?.meta,
            ...meta,
          },
        },
      };
    }

    case types.PAGE_REQUEST_PUBLISH:
      return set(state, [action.payload.pageId, 'isPublishing'], true);

    case types.PAGE_RECEIVE_PUBLISH_SUCCESS:
    case types.PAGE_RECEIVE_PUBLISH_ERROR:
    case types.PAGE_DYNAMIC_CONTENT_PUBLISH_ERROR:
      return set(state, [action.payload.pageId, 'isPublishing'], false);

    case types.PAGE_UPDATE_REQUEST: {
      const { pageId } = action.payload;

      return {
        ...state,
        [pageId]: {
          ...state[pageId],
          touched: false,
          isUpdating: true,
        },
      };
    }

    case types.PAGE_UPDATE_RECEIVE: {
      const { pageId } = action.payload;

      return {
        ...state,
        [pageId]: {
          ...state[pageId],
          isUpdating: false,
        },
      };
    }

    case types.PAGE_DELETE:
      return del(state, action.payload.pageId);

    case types.SECTIONS_SHOW_PLACEHOLDER:
      const { pageId, sectionIndex } = action.payload;
      return {
        ...state,
        [pageId]: {
          ...state[pageId],
          showPlaceholder: {
            pageId: pageId,
            sectionIdx: sectionIndex,
          },
        },
      };

    case types.SECTIONS_HIDE_PLACEHOLDER:
      return {
        ...state,
        [action.payload.pageId]: {
          ...state[action.payload.pageId],
          showPlaceholder: null,
        },
      };

    default:
      return state;
  }
}

function pageIdsReducer(state = [], action) {
  switch (action.type) {
    case types.PAGES_RECEIVE_LIST: {
      const pages = action.payload.entities.pages || {};
      return Object.keys(pages);
    }

    case types.PAGES_RECEIVE_LIST_ITEM: {
      return [...state, action.payload.pageId];
    }

    case types.PAGE_REQUEST:
      return union(state, [action.payload.pageId]);

    case types.PAGE_RECEIVE: {
      const pages = action.payload.entities.pages || {};
      const newIds = Object.keys(pages);
      return union(state, newIds);
    }

    case types.PAGE_DELETE: {
      const { pageId } = action.payload;
      const index = state.indexOf(pageId);
      return del(state, index);
    }

    default:
      return state;
  }
}

function contentByIdReducer(state = {}, action) {
  switch (action.type) {
    case types.PAGE_RECEIVE:
      return {
        ...action.payload.entities.content,
      };

    case types.PAGE_UPDATE_CONTENT: {
      const { id, path, value } = action.payload;
      const combinedPath = `${id}.${path}`;

      return set(state, combinedPath, value);
    }

    case types.PAGE_DELETE:
      return del(state, action.payload.pageId);

    case types.SECTIONS_ADD: {
      const pageId = action.payload.item;
      const sectionId = action.payload.id;

      return insert(state, [pageId, 'sections'], sectionId, action.payload.position);
    }

    case types.SECTIONS_ADD_BETWEEN: {
      const pageId = action.payload.sectionProps.pageId;
      const sectionId = action.payload.id;
      const idx = action.payload.idx;
      state[pageId].sections.splice(idx, 0, sectionId);

      return {
        ...state,
      };
    }

    case types.SECTIONS_DELETE: {
      const sectionId = action.payload.id;
      let newState = state;

      // Iterate over all pages and delete occurances of sectionId
      forOwn(state, (content, pageId) => {
        const sections = idx(content, 'sections');
        const index = indexOf(sections, sectionId);
        if (index > -1) {
          newState = del(newState, [pageId, 'sections', index]);
        }
      });

      return newState;
    }

    case types.SECTIONS_REPLACE: {
      const {
        id: sectionId,
        newSection: { id: newId },
      } = action.payload;
      let newState = state;

      // Iterate over all pages and replace occurances of sectionId
      forOwn(state, (content, pageId) => {
        const sections = idx(content, 'sections');
        const index = indexOf(sections, sectionId);
        if (index > -1) {
          newState = set(newState, [pageId, 'sections', index], newId);
        }
      });

      return newState;
    }

    case types.SECTIONS_REORDER: {
      const { pageId, sectionId, newIndex } = action.payload;
      const newSectionIds = state[pageId].sections.slice();
      const oldIndex = newSectionIds.indexOf(sectionId);
      newSectionIds.splice(oldIndex, 1);
      newSectionIds.splice(newIndex, 0, sectionId);
      return set(state, `${pageId}.sections`, newSectionIds);
    }

    default:
      return state;
  }
}

function isFetchingReducer(state = null, action) {
  switch (action.type) {
    case types.PAGES_REQUEST_LIST:
    case types.PAGES_REQUEST_LIST_BY_PAGE:
      return true;

    case types.PAGES_RECEIVE_LIST:
      return false;

    default:
      return state;
  }
}

// const initialState = null;
// function isFetchingAllReducer(state = initialState, action) {
//   switch (action.type) {
//     case types.PAGES_REQUEST_LIST:
//       return true;

//     case types.PAGES_REQUEST_LIST_BY_PAGE:
//       return initialState;

//     default:
//       return state;
//   }
// }

function countPagesReducer(state = null, action) {
  switch (action.type) {
    case types.PAGES_RECEIVE_LIST:
      return action.payload.count;
    default:
      return state;
  }
}

export default combineReducers({
  isFetching: isFetchingReducer,
  // isFetchingAll: isFetchingAllReducer,
  allIds: pageIdsReducer,
  byId: pagesByIdReducer,
  contentById: contentByIdReducer,
  count: countPagesReducer,
});

// ----------------------------------------------------------------------------
// Selectors
// ----------------------------------------------------------------------------

// ---- Raw state selectors ----

export function selectLocalState(globalState) {
  return globalState.pages;
}

export function selectIsFetching(globalState) {
  const state = selectLocalState(globalState);
  return state.isFetching;
}

// export function selectIsFetchingAll(globalState) {
//   const state = selectLocalState(globalState);
//   return state.isFetchingAll;
// }

export function selectAllIds(globalState) {
  const state = selectLocalState(globalState);
  return state.allIds;
}

export function selectById(globalState) {
  const state = selectLocalState(globalState);
  return state.byId;
}

export function selectContentById(globalState) {
  const state = selectLocalState(globalState);
  return state.contentById;
}

export const selectPagesCount = createSelector(selectLocalState, (pages) => pages.count);

// ---- Computed state selectors ----

// Computed combination of page lists with their content
export const selectAllById = createSelector(
  selectAllIds,
  selectById,
  selectContentById,
  (allIds, byId, contentById) => {
    return allIds.reduce((allById, id) => {
      const page = byId[id];
      const content = contentById[id];
      allById[id] = { ...page, content };
      return allById;
    }, {});
  }
);

export const selectAll = createSelector(selectAllIds, selectAllById, (allIds, allById) =>
  allIds.map((id) => allById[id])
);

export function selectPage(globalState, pageId) {
  return selectAllById(globalState)[pageId];
}

export function selectPageBySlug(globalState, matchSlug) {
  // const pages = selectAll(globalState);
  // return pages.find(page => page.slug === matchSlug);

  const allIds = selectAllIds(globalState);
  const allById = selectAllById(globalState);

  const pageId = allIds.find((id) => {
    const page = allById[id];
    return page.slug === matchSlug;
  });

  return allById[pageId];
}

export function selectHomePage(globalState) {
  return selectPageBySlug(globalState, '');
}

export function selectPageIdsReadyForUpdate(globalState) {
  const allIds = selectAllIds(globalState);
  const allById = selectAllById(globalState);

  return allIds.filter((id) => {
    const page = allById[id];
    return page.touched;
  });
}

export function selectSectionOrderIndex(globalState, pageId, sectionId) {
  const sectionIds = selectPageSectionIds(globalState, pageId);
  return sectionIds.indexOf(sectionId);
}

export const selectTotalPageViews = createSelector(selectAll, (allPages) =>
  allPages.reduce((accum, page) => accum + page.views, 0)
);

export const selectHasPublishedPages = createSelector(selectAll, (allPages) =>
  allPages.some((page) => page.status === 'published')
);

export const selectHasPublishedSitePages = createSelector(selectAll, (allPages) =>
  allPages.some((page) => page.status === 'published' && page.item_type === 'site_page')
);

export const selectHasPublishedLandingPages = createSelector(selectAll, (allPages) =>
  allPages.some((page) => page.status === 'published' && page.item_type === 'landing_page')
);

export const selectLandingPageCount = createSelector(
  selectIsFetching,
  selectAllIds,
  selectAllById,
  (isFetching, allIds, allById) => {
    if (isFetching == null) return null;

    let count = 0;
    allIds.forEach((id) => {
      if (allById[id].item_type === 'landing_page') count++;
    });
    return count;
  }
);

export function selectPageIsDirty(globalState, pageId) {
  const page = selectPage(globalState, pageId);
  return page.status === 'draft' || page.is_changed;
}

// ---- Section ids ----

export function selectPageSectionIds(globalState, pageId) {
  const page = selectPage(globalState, pageId);
  return idx(page, 'content.sections') || [];
}

export function selectPageSectionPlaceholder(globalState, pageId) {
  const page = selectPage(globalState, pageId);
  return page?.showPlaceholder;
}

export const selectPageSectionAndVariantIds = createCachedSelector(
  (globalState) => globalState,
  selectPage,
  (globalState, page) => {
    let allSectionIds = [];
    const parentIds = idx(page, 'content.sections') || [];

    parentIds.forEach((parentId) => {
      const childrenIds = selectChildrenIds(globalState, parentId);
      const variantIds = selectVariantIds(globalState, parentId);
      allSectionIds.push(parentId);
      allSectionIds = allSectionIds.concat(childrenIds, variantIds);
    });

    return allSectionIds;
  }
)((globalState, pageId) => pageId);

export const selectPageIdFromSectionId = createCachedSelector(
  (globalState) => globalState,
  selectAllIds,
  (_, sectionId) => sectionId,
  (globalState, pageIds, sectionId) => {
    for (let i = 0; i < pageIds.length; i++) {
      const pageId = pageIds[i];
      const sectionIds = selectPageSectionAndVariantIds(globalState, pageId);
      if (sectionIds.some((id) => id === sectionId)) return pageId;
    }
  }
)((globalState, sectionId) => sectionId);

export const selectRenderedPageUrl = createCachedSelector(selectSiteByPageId, selectPage, (site, page) => {
  if (page.status !== 'published') return '';

  const siteUrl = getUrlFromSite(site);
  return `${siteUrl}/${page.slug}`;
})((_, pageId) => pageId);

export const selectPagePreviewUrl = createCachedSelector(
  selectSiteByPageId,
  (globalState, pageId) => pageId,
  (globalState, pageId, version) => version,
  (site, pageId, version) => {
    const siteUrl = getUrlFromSite(site);
    const queryParams = version == null ? '' : `?v=${version}`;
    return `${siteUrl}/item-preview/${pageId}${queryParams}`;
  }
)((_, pageId, version) => `${pageId}-v${version}`);

// Sites
// ----------------------------------------------------------------------------

export function selectSiteByPageId(state, pageId) {
  const page = selectPage(state, pageId);
  if (page) return selectSiteById(state, page.site);
  return null;
}
