import React, { memo } from 'react';
import classNames from 'classnames';
import ReactTooltip from 'react-tooltip';
import { v4 as uuidv4 } from 'uuid';

import style from './ProductItem.module.scss';
import { sortableElement } from 'lib/SortListHoc';
import { DragHandle } from 'components/Editor/EmbeddedForm/Forms/SparkForm/SortableField';
import { get } from 'lodash';
import { selectSiteIntegrationsBySlug } from 'reducers/integrationsReducer';
import { useSelector } from 'react-redux';
import useActiveSite from 'hooks/useActiveSite';

interface ProductItemProps {
  image: string | React.ReactNode;
  title: string;
  price?: string;
  productTypes?: Array<string>;
  actionButton?: React.ReactNode;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  isSortable?: boolean;
  loading?: boolean;
}

const ProductItem: React.FC<ProductItemProps> = ({
  image,
  title,
  price,
  productTypes,
  actionButton,
  onClick,
  isSortable = false,
  loading = false,
}) => {
  const ref = React.useRef(null);
  const autosuggestId = uuidv4();
  const autosuggestId2 = uuidv4();
  const productType = productTypes ? productTypes[0] : undefined;

  const siteId = useActiveSite((site) => site.id);
  const integrationsBySlug = useSelector((state) =>
    //  @ts-ignore
    selectSiteIntegrationsBySlug(state, siteId)
  );

  const elasticPathIntegrationList = get(integrationsBySlug, 'elastic_path');
  const elasticPathIntegration = elasticPathIntegrationList && elasticPathIntegrationList[0];

  const [isTextOverflow, setIsTextOverflow] = React.useState(false);

  React.useLayoutEffect(() => {
    setIsTextOverflow(ref.current?.clientWidth < ref.current?.scrollWidth);
  }, [title, loading]);

  return (
    <div className={classNames(style.root, { [style.sortable]: isSortable })}>
      <div
        className={classNames({
          [style.productContainer]: true,
          [style.clickable]: Boolean(onClick),
          'product-item': true,
        })}
        onClick={onClick}
        tabIndex={0}
        data-test-id="product"
      >
        {isSortable && !loading && (
          <span className={style.dragHandle}>
            <DragHandle />
          </span>
        )}
        <ReactTooltip
          disable={!isTextOverflow}
          id={autosuggestId}
          className={style.tooltip}
          children={title}
          multiline={true}
          place="left"
          effect="solid"
          backgroundColor="#000233"
          arrowColor="#000233"
        />
        <div className={style.productDetails} data-tip data-for={autosuggestId}>
          <span className={style.imageContainer}>
            {typeof image === 'string' ? <img src={`${image}${elasticPathIntegration ? '' : '&height=120'}`} /> : image}
          </span>
          <div className={Boolean(actionButton) ? style.selectedProductInfoContainer : style.productInfoContainer}>
            <span className={style.productText}>
              <span data-test-id="title" ref={ref}>
                {title}
              </span>
              {Boolean(price) && (
                <span className={style.productPrice} data-test-id="price">
                  {price}
                </span>
              )}
            </span>
            <ReactTooltip
              id={autosuggestId2}
              className={style.tooltip}
              children={
                productType === 'standard' ? 'Product' : productType?.charAt(0).toUpperCase() + productType?.slice(1)
              }
              place="top"
              effect="solid"
              backgroundColor="#000233"
              arrowColor="#000233"
            />
            <span
              data-tip
              data-for={autosuggestId2}
              className={classNames(style.tagLabel, {
                [style.product]: productType === 'product' || productType === 'standard',
                [style.parent]: productType === 'parent',
                [style.child]: productType === 'child',
                [style.bundle]: productType === 'bundle',
                [style.none]: !productType,
              })}
            >
              {productType === 'standard'
                ? 'P'
                : productType === 'parent'
                ? 'P'
                : productType === 'bundle'
                ? 'B'
                : productType === 'child'
                ? 'C'
                : ''}
            </span>
          </div>
        </div>
        {Boolean(actionButton) && (
          <div className={style.actionContainer} data-test-id="clear-selection">
            {actionButton}
          </div>
        )}
      </div>
    </div>
  );
};

const SortableProductItem = sortableElement(ProductItem);

const ProductItemWrapper = (props: ProductItemProps) => {
  if (props.isSortable) {
    return <SortableProductItem {...props} />;
  } else {
    return <ProductItem {...props} />;
  }
};

export default memo(ProductItemWrapper);
