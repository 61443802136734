// Quick and dirty utility for printing errors or status directly on the page
// (for development purposes only)

import React, { FunctionComponent, ReactNode, useEffect } from 'react';
import * as Sentry from '@sentry/browser';

type DebugElementProps = {
  color?: string;
  label?: string;
  data?: any;
  centered?: boolean;
  children?: ReactNode;
};

export const DebugElement: FunctionComponent<DebugElementProps> = (props) => {
  const { color = '#08f', label, data, centered, children } = props;

  useEffect(() => {
    const message = `Component DebugElement${label ? `: ${label}` : ''}, type: ${data?.type}`;
    const error = new Error(message);
    // @ts-ignore
    error.data = data;
    console.log('Error', error);
    Sentry.captureException(error, { extra: data });
  }, []);

  if (process.env.NODE_ENV === 'development') {
    const styles = { outline: `solid 1px ${color}` };

    if (centered) {
      Object.assign(styles, {
        textAlign: 'center',
        padding: '2rem',
      });
    }

    if (label) {
      Object.assign(styles, {
        position: 'relative',
        paddingTop: '2rem',
        paddingBottom: '2rem',
      });
    }

    return (
      <div style={styles}>
        {label && (
          <pre
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              margin: '0',
              padding: '0 1em',
              fontSize: '12px',
              background: 'rgba(0,0,0,0.05)',
            }}
          >
            {label}
          </pre>
        )}
        {children}
      </div>
    );
  }

  return null;
};
