import React from 'react';

import styles from './NeedHelpPrompt.module.scss';

function NeedHelpPrompt({ onClick }: any) {
  return (
    <div>
      <div className={styles.cover} />
      <div className={styles.container}>
        <div className={styles.circle}>👋</div>
        <h1>Need a little help?</h1>
        <p>
          If you’re having trouble getting started we can help. Schedule time with us and we’ll take the guesswork out
          of getting started!
        </p>
        <button onClick={() => window.open('https://www.unstack.com/goto?need-help-modal', '_blank')}>
          Schedule time with us
        </button>
        <button className={styles.secondary} onClick={onClick}>
          Maybe later
        </button>
      </div>
    </div>
  );
}

export default NeedHelpPrompt;
