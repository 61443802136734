import styles from './EditorCanvas.module.scss';

import React from 'react';
import withSite from '../../containers/withSite';

import Header from './Layout/FullHeader';
import Footer from './Layout/SitePageFooter';
import ArticleBody from './ArticleBody';
import ArticleSettings from '../Articles/ArticleSettings';

function ArticleCanvas(props) {
  const { articleId, site } = props;

  return (
    <div className={styles.EditorCanvasContainer}>
      <div className={styles.EditorCanvas}>
        <div id="spark" className="eps sriracha article">
          <main class="content" data-test-id="main-sections-container" style={{ position: 'relative', paddingTop: 1 }}>
            {!site.is_liquid_enabled && <Header site={site} />}
            <ArticleBody articleId={articleId} site={site} />
            <div className="sticky-bottom-spacer" />
            {!site.is_liquid_enabled && <Footer site={site} />}
          </main>
        </div>
      </div>
      <ArticleSettings />
    </div>
  );
}

export default withSite(ArticleCanvas);
