import React from 'react';
import { QuantitySelectorArgs } from '../../../tag-types';

const QuantitySelector: React.FunctionComponent<QuantitySelectorArgs> = (props) => {
  return (
    <div className={props.className} data-quantity-selector data-id="productQuantitySelector">
      <button
        type="button"
        className={`button button-${props.defaultType || 'secondary'} quantity-selector-decrement`}
        data-click="quantity-selector:decrement"
      >
        -
      </button>
      <span className="quantity-selector-value" data-min={props.minValue} data-max={props.maxValue}>
        1
      </span>
      <button
        type="button"
        className={`button button-${props.defaultType || 'secondary'} quantity-selector-increment`}
        data-click="quantity-selector:increment"
      >
        +
      </button>
    </div>
  );
};

export default QuantitySelector;
