import React from 'react';
import { ProductDetailArgs } from '../../../tag-types';

const ProductInventory: React.FunctionComponent<ProductDetailArgs> = ({ product }) => {
  return (
    <span data-product={product?.id} data-product-inventory>
      {product?.stock?.available}
    </span>
  );
};

export default ProductInventory;
