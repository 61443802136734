import React from 'react';

import Select from '../../Select';
import Search from '../../Search';

import { ReactComponent as SvgIcon } from '../../../assets/images/icon-filter.svg';
import { ReactComponent as SvgIconAdd } from '../../../assets/images/icon-plus.svg';

import styles from './Tasks.module.scss';

export const TASKS_FILTER_OPTIONS = [
  { value: '', label: 'all tasks' },
  { value: 'owner', label: 'my tasks' },
];

export default ({ debounceValue, filter, onChange, searchTerm, setFilter, openDrawer }) => (
  <div className={styles.filtersContainer}>
    <div className={styles.filter}>
      <div className={styles.filterIcon}>
        <SvgIcon />
      </div>
      Show&nbsp;
      <Select
        options={TASKS_FILTER_OPTIONS}
        isInline
        defaultValue={TASKS_FILTER_OPTIONS[0]}
        value={filter.owner}
        onChange={(option) => {
          setFilter({ owner: option });
        }}
      />
    </div>
    <div className={styles.searchFieldContainer}>
      <Search
        value={searchTerm}
        onChange={(value) => {
          onChange(value);
        }}
        containerStyle={{
          marginTop: 0,
          marginRight: '1rem',
        }}
        placeholder="Search by title"
        debounceValue={debounceValue}
      />
      <div onClick={() => openDrawer()} className={styles.addButton}>
        <SvgIconAdd height={15} width={15} />
        <span>Add Task</span>
      </div>
    </div>
  </div>
);
