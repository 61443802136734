import React from 'react';
import { Group, Label, TextInput } from 'components/Editor/Toolbars2/Toolbar2';

import styles from './LayoutOption.module.scss';

const TextField: React.FunctionComponent = (props: any) => {
  return (
    <Group className={props.className}>
      {props.property.label && <Label>{props.property.label}:</Label>}
      <TextInput
        className={styles.TextInput}
        value={props.value}
        onChange={(e: any) => props.onChange(props.property.name, e.target.value)}
        placeholder={props.property.placeholder}
        border
        style={{ minWidth: '90px' }}
      />
    </Group>
  );
};

export default TextField;
