import { call, put, select, all, takeEvery, takeLatest, delay } from 'redux-saga/effects';
import * as api from '../services/spark-api';
import * as types from '../actions/actionTypes';
import * as uiUserSettingsActions from '../actions/uiUserSettingsActions';
import { updateSubmittingState, resetSubmittingState } from '../actions/uiSubmitButtonsStateActions';
import * as accountUserActions from '../actions/accountUserActions';
import * as accountUserSelectors from '../reducers/accountUsersReducer';
import requireSiteId from './utils/requireSiteId';
import { callSparkApi } from './utils/callSparkApi';
import { normalizeUsersResponse as normalize } from './normalizrs/user-normalizrs';

import { flattenAccountUser, unflattenAccountUser } from '../lib/formatters/formatUser';

function* getAccountUsersIfNeeded(action) {
  const hasFetched = yield select(accountUserSelectors.selectHasFetched);
  if (!hasFetched) yield put(accountUserActions.requestAll());
}

function* getAccountUsers(action) {
  const siteId = yield call(requireSiteId, action.payload.siteId);
  const response = yield call(callSparkApi, api.getAccountUsers, siteId);

  if (response.ok) {
    yield put(accountUserActions.getCurrent(siteId));
    let accountUsers = response.json.map((accountUser) => flattenAccountUser(accountUser));
    const { entities, result: userIds } = normalize(accountUsers);
    yield put(accountUserActions.receiveAll(userIds, entities));
  } else {
    yield put(accountUserActions.receiveError(response.json));
  }
}

function* getAccountUser(action) {
  const { userId } = action.payload;
  const response = yield call(callSparkApi, api.getAccountUser, userId);

  if (response.ok) {
    let user = flattenAccountUser(response.json);
    yield accountUserActions.receive(user);
  } else {
    yield put(accountUserActions.receiveError(response.json));
  }
}

function* createAccountUser(action) {
  try {
    const siteId = yield call(requireSiteId, action.payload.siteId);
    const accountUser = unflattenAccountUser(action.payload.accountUser);
    yield put(updateSubmittingState(true, { creating: true }));
    const response = yield call(api.createAccountUser, siteId, accountUser);
    yield put(resetSubmittingState());
    if (response.ok) {
      yield put(accountUserActions.receive(flattenAccountUser(response.json)));
      yield put(uiUserSettingsActions.close());
    } else {
      yield put(accountUserActions.receiveError(response.json));
    }
  } catch (err) {
    // console.error(err);
    yield put(resetSubmittingState());
  }
}

function* updateAccountUser(action) {
  const { resolve = () => {} } = action;
  try {
    const accountUser = unflattenAccountUser(action.payload);
    yield put(updateSubmittingState(true, { updating: true }));
    const response = yield call(api.updateAccountUser, accountUser);
    yield put(resetSubmittingState());
    if (response.ok) {
      yield put(accountUserActions.receive(flattenAccountUser(response.json)));
      resolve();
      yield put(uiUserSettingsActions.close());
    } else {
      yield put(accountUserActions.receiveError(response.json));
    }
  } catch (err) {
    yield put(resetSubmittingState());
    // console.error(err);
  }
}

function* updateAccountUserOnboarding(action) {
  try {
    const { payload } = action;
    const response = yield call(api.patchAccountUserOnboarding, payload);
    if (response.ok) {
      yield put({
        type: types.ACCOUNT_USER_ON_UPDATE_ONBOARDING,
        payload: payload,
      });
    }
  } catch (err) {
    console.error(err);
  }
}

function* deleteAccountUser(action) {
  try {
    yield put(updateSubmittingState(true, { removing: true }));
    const response = yield call(api.deleteAccountUser, action.payload);
    yield put(resetSubmittingState());
    if (response.ok) {
      yield put(accountUserActions.receiveDelete({ id: action.payload.id }));
      yield put(uiUserSettingsActions.close());
    } else {
      yield put(resetSubmittingState());
      yield put(accountUserActions.receiveError(response.json));
    }
  } catch (err) {
    // console.error(err);
  }
}

function* getCurrentUser(action) {
  const siteId = yield call(requireSiteId, action.payload.siteId);

  try {
    const response = yield call(api.getCurrentUser, siteId);
    if (response.ok) {
      yield put({
        type: types.ACCOUNT_USER_RECIEVE_CURRENT,
        payload: response.json.id,
      });
    }
  } catch (err) {
    // console.error(err);
  }
}

function* sendInvite(action) {
  try {
    yield call(api.sendInvite, action.payload);
    yield delay(1500);
    yield put(uiUserSettingsActions.close());
  } catch (err) {
    yield delay(1500);
    yield put(uiUserSettingsActions.close());
    console.log(err);
  }
}

function* sendVerficationEmail() {
  const user = yield select(accountUserSelectors.selectCurrentAccountUser);
  try {
    const response = yield call(api.resendVerificationEmail, user.id);
    if (response.ok) {
      yield put(accountUserActions.verificationEmailSent());
    }
  } catch (e) {
    console.error(e);
  }
}

function* accountUsersSaga() {
  yield all([
    takeEvery(types.ACCOUNT_USER_REQUEST_ALL_IF_NEEDED, getAccountUsersIfNeeded),
    takeLatest(types.ACCOUNT_USER_REQUEST_ALL, getAccountUsers),
    takeLatest(types.ACCOUNT_USER_REQUEST, getAccountUser),
    takeLatest(types.ACCOUNT_USER_CREATE, createAccountUser),
    takeEvery(types.ACCOUNT_USER_UPDATE, updateAccountUser),
    takeEvery(types.ACCOUNT_USER_REMOVE, deleteAccountUser),
    takeLatest(types.ACCOUNT_USER_REQUEST_CURRENT, getCurrentUser),
    takeLatest(types.ACCOUNT_USER_RESEND_INVITE, sendInvite),
    takeLatest(types.ACCOUNT_USER_RESEND_VERIFICATION_EMAIL, sendVerficationEmail),
    takeLatest(types.ACCOUNT_USER_UPDATE_ONBOARDING, updateAccountUserOnboarding),
  ]);
}

export default accountUsersSaga;
