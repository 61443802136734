import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import Drawer from '../AddSection/Drawer';
import StartABTestDrawer from './StartABTestDrawer';
import InProgressAbTest from './InProgressAbTestDrawer';
import Component from 'components/unstack-components/Component';
import * as componentActions from 'actions/componentActions';

import { ReactComponent as UnstackSvg } from '../../../assets/images/unstack-mark-inverse.svg';

import styles from './ArticleFooter.module.scss';

export default ({
  tests = [],
  value,
  remove,
  onChange,
  removeChild,
  createElement,
  updateTestContent,
  updateContainersProps,
  discardABTestContainer,
  initializeABTestContainer,
  initializeCarouselContainer,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  //  Toolbar UI controllers
  const [applyOpacity, shouldApplyOpacity] = useState(false);
  const [isRichTextToolbarActive, setIsRichTextToolbarActive] = useState(false);

  const dispatch = useDispatch();

  //  Section of container ID
  const containerId = value && value.id;

  const childSections = value.children || [];
  const containerLength = childSections.length;
  const [containerIndex, onContainerIndexChange] = useState(0);
  const activeChild = childSections[containerIndex];

  useEffect(() => {
    dispatch(componentActions.requestComponentDefaultsIfNeeded());
  }, []);

  //  Section handlers
  const handleChange = useCallback(
    (sectionProps) => {
      onChange(sectionProps);
    },
    [onChange]
  );
  const updateContainerProps = useCallback(
    (containerProps) => {
      updateContainersProps(containerProps);
    },
    [updateContainersProps]
  );
  useEffect(() => {
    onContainerIndexChange(
      containerLength && containerLength === containerIndex ? containerLength - 1 : containerIndex
    );
  }, [containerLength, childSections, containerIndex]);

  //  Carousels handlers
  const initializeCarousel = useCallback(() => {
    initializeCarouselContainer(value, 'carousel');
  }, [value, initializeCarouselContainer]);
  const addCarouselChild = useCallback(
    (type, component) => {
      createElement('component', component, true);
    },
    [createElement]
  );
  const removeCarouselChild = useCallback(
    (child) => {
      removeChild(child);
    },
    [removeChild]
  );

  //  AB Test handlers
  const [testStartIsOpen, setTestStartIsOpen] = useState(false);
  const activeTests = tests.filter((test) => test.status === 'active');
  const [testInProgressIsOpen, setTestProgressIsOpen] = useState(false);
  const initializeABTest = useCallback(() => {
    initializeABTestContainer(value, 'experiment');
  }, [
    value,
    // initializeABTestContainer
  ]);
  const discardABTest = useCallback(() => {
    if (activeChild) discardABTestContainer(activeChild);
  }, [activeChild, discardABTestContainer]);

  const sectionHandlers = useMemo(
    () => ({
      type: value && value.type,
      initializeCarousel,
      containerLength,
      containerId,
      addCarouselChild,
      initializeABTest,
      updateContainerProps,
      discardABTest,
      removeCarouselChild,
      activeTest: activeTests.length && activeTests[0],
      onContainerIndexChange,
      startABTestForBlog: () => setTestStartIsOpen(true),
      openBlogInProgressTestSettings: () => setTestProgressIsOpen(true),
      isBlogFooter: true,
      containerIndex,
      container: value,
      onRemove: () => {
        if (value) {
          handleChange(null);
        }
      },
    }),
    [
      containerIndex,
      value,
      activeTests,
      addCarouselChild,
      containerId,
      containerLength,
      discardABTest,
      handleChange,
      initializeABTest,
      initializeCarousel,
      remove,
      removeCarouselChild,
      updateContainerProps,
    ]
  );

  const sectionUiHandlers = useMemo(
    () => ({
      shouldApplyOpacity,
      applyOpacity,
      isRichTextToolbarActive,
      setIsRichTextToolbarActive,
      isOpen: true,
    }),
    [applyOpacity, isRichTextToolbarActive]
  );

  return value.id ? (
    <>
      <Component
        content={activeChild || value}
        onContentChange={handleChange}
        sectionHandlers={sectionHandlers}
        sectionUiHandlers={sectionUiHandlers}
      />
      <StartABTestDrawer
        isOpen={testStartIsOpen}
        close={() => setTestStartIsOpen(false)}
        blogId={value.item}
        variants={value.children}
        containerId={value.id}
      />
      <InProgressAbTest
        isOpen={testInProgressIsOpen}
        close={() => setTestProgressIsOpen(false)}
        contentTest={activeTests[0]}
        setWinningVariant={(variant) => updateTestContent(variant)}
        variants={value.children}
      />
    </>
  ) : (
    <>
      <div className={styles.container}>
        <div className={styles.button} onClick={() => setIsOpen(true)}>
          <UnstackSvg className={styles.addComponentButton} height={15} width={15} /> <small>Add Section</small>
        </div>
      </div>
      <Drawer
        isOpen={isOpen}
        close={() => {
          setIsOpen(false);
        }}
        addSection={(t, id) => {
          createElement(t, id);
          setIsOpen(false);
        }}
      />
    </>
  );
};
