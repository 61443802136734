import { call, select, put, all, takeLatest } from 'redux-saga/effects';
import * as api from '../services/spark-api';
import * as actionTypes from '../actions/actionTypes';
import * as templateActions from '../actions/templateActions';
import * as templateSelectors from '../reducers/templatesReducer';
import * as accountActions from '../actions/accountActions';
import requireSiteId from './utils/requireSiteId';
import { callSparkApi } from './utils/callSparkApi';

function* fetchAllIfNeeded(action) {
  const isFetching = yield select(templateSelectors.selectAll);
  if (isFetching == null) yield put(templateActions.requestAll());
}

function* fetchAll(action) {
  try {
    const siteId = yield call(requireSiteId, action.payload.siteId);
    const response = yield call(callSparkApi, api.getTemplates, siteId);
    if (response.ok) {
      const templates = response.json.results;
      yield put(templateActions.receiveAll(templates));
    } else if (response.unauthorized) {
      yield put(accountActions.logout());
    } else {
      console.error('Failed trying to fetch templates', response.json);
    }
  } catch (err) {
    console.error(err);
  }
}

function* create(action) {
  const { payload, res } = action;
  try {
    const response = yield call(api.createTemplate, payload);
    if (response.ok) {
      yield put(templateActions.createSuccess(response.json));
      res();
    }
  } catch (e) {
    console.error(e);
  }
}

function* remove(action) {
  const { payload: id } = action;
  const siteId = yield call(requireSiteId, action.payload.siteId);
  try {
    yield call(callSparkApi, api.removeTemplate, { id, siteId });
  } catch (e) {
    console.error(e);
  }
}

function* update(action) {
  const { payload } = action;
  try {
    const response = yield call(api.updateTemplate, payload);
    if (response.ok) {
      yield put(templateActions.updateSuccess(response.json));
    }
  } catch (e) {
    console.error(e);
  }
}

function* templatesSaga() {
  yield all([
    takeLatest(actionTypes.TEMPLATES_REQUEST_ALL, fetchAll),
    takeLatest(actionTypes.TEMPLATES_REQUEST_ALL_IF_NEEDED, fetchAllIfNeeded),
    takeLatest(actionTypes.TEMPLATES_REQUEST_CREATE, create),
    takeLatest(actionTypes.TEMPLATES_REQUEST_UPDATE, update),
    takeLatest(actionTypes.TEMPLATES_REQUEST_REMOVE, remove),
    // takeLatest(actionTypes.TEMPLATES_REQUEST_CLONE, ),
    // takeLatest(actionTypes.TEMPLAETS_RECEIVE_CLONE_SUCCESS, ),
    // takeLatest(actionTypes.TEMPLAETS_RECEIVE_CLONE_ERROR, ),
  ]);
}

export default templatesSaga;
