import React from 'react';

import { connect } from 'react-redux';
import { compose } from 'redux';
import * as uiPageSettingsActions from '../../actions/uiPageSettingsActions';
import * as addSectionActions from '../../actions/addSectionActions';

import SideDrawer from '../base/SideDrawer';
import { selectProps } from '../../reducers/uiPageSettingsReducer';
import { selectOne } from '../../reducers/mediaReducer';
import * as pageActions from '../../actions/pageActions';
import * as submitButtonStateActions from '../../actions/uiSubmitButtonsStateActions';
import EditPageForm from './EditPageForm';
import PageActionLinks from './PageSettings/PageActionLinks';
import VersionSelector from './PageSettings/VersionSelector';
import { patchOnboarding } from 'actions/accountActions';
import { selectAccount } from 'reducers/accountReducer';
import { CREATE_OR_EDIT_FIRST_PAGE_COMPLETE } from 'components/AdminPanel/Quickstart/constants';

function mapStateToProps(state) {
  const { page, ...restProps } = selectProps(state);
  const account = selectAccount(state);

  let featuredMedia;
  if (page && page.meta) {
    const img = page.meta.image;
    featuredMedia = img && selectOne(state, img.split(':')[1]);
  }

  return {
    account,
    page,
    featuredMedia,
    ...restProps,
  };
}

const mapDispatchToProps = {
  requestUpdate: pageActions.requestPageInfoUpdate,
  requestPublish: pageActions.requestPublish,
  updateSubmittingState: submitButtonStateActions.updateSubmittingState,
  resetSubmittingState: submitButtonStateActions.resetSubmittingState,
  requestDrawerOpen: addSectionActions.requestDrawerOpen,
  requestDrawerClose: addSectionActions.requestDrawerClose,
  patchOnboarding,
  ...uiPageSettingsActions,
};

class PageSettings extends React.PureComponent {
  state = {
    errors: null,
  };
  componentDidUpdate = () => {
    if (!this.props.isOpen && this.state.errors) {
      this.setState({ errors: null });
    }
  };
  handleSubmit = (publishAfter) => {
    const pageId = this.props.page.id;
    // this.setState({ isFetching: true });
    const promise = new Promise((resolve, reject) => {
      this.props.updateSubmittingState(true, {
        ...(publishAfter && { publish: true }),
      });
      this.props.requestUpdate(pageId, resolve, reject);
    });
    promise
      .then(() => {
        this.props.close();
        if (publishAfter) {
          this.props.requestPublish(pageId);
        }
        this.setState({ errors: null });
      })
      .catch((errors) =>
        this.setState({
          isFetching: false,
          errors,
        })
      )
      .finally(() => {
        const { account, patchOnboarding } = this.props;
        this.props.resetSubmittingState();
        if (!account?.metadata?.onboarding?.includes(CREATE_OR_EDIT_FIRST_PAGE_COMPLETE)) {
          patchOnboarding({
            id: account.id,
            step: CREATE_OR_EDIT_FIRST_PAGE_COMPLETE,
          });
        }
      });
  };

  render() {
    const { isOpen, close, updateQueryParams, pagesCount, requestDrawerOpen, requestDrawerClose } = this.props;
    if (isOpen) requestDrawerOpen();
    else requestDrawerClose();

    return (
      <SideDrawer
        isOpen={isOpen}
        close={close}
        title="Page settings"
        headerActions={() => {
          if (this.props.page && !this.props.page.is_deleted)
            return <VersionSelector itemId={this.props.page.id} onRestoreSuccess={close} itemType="page" />;
          return null;
        }}
        render={() => {
          if (this.props.page) {
            return (
              <div>
                {!this.props.page.is_deleted ? (
                  <>
                    <EditPageForm
                      page={this.props.page}
                      featuredMedia={this.props.featuredMedia}
                      errors={{ ...this.props.errors, ...this.state.errors }}
                      save={this.props.save}
                      publish={this.props.publish}
                      handleSubmit={this.handleSubmit}
                      addOps={this.props.addOps}
                      isFetching={this.props.isFetching}
                      fullDomain={this.props.siteDomain}
                    />
                    <PageActionLinks
                      entity={this.props.page}
                      entityType="page"
                      updateQueryParams={updateQueryParams}
                      pagesCount={pagesCount}
                      close={close}
                    />
                  </>
                ) : (
                  <div>
                    <PageActionLinks
                      entity={this.props.page}
                      is_deleted={this.props.page.is_deleted}
                      entityType="page"
                      updateQueryParams={updateQueryParams}
                      pagesCount={pagesCount}
                    />
                  </div>
                )}
              </div>
            );
          }
        }}
      />
    );
  }
}

export default compose(connect(mapStateToProps, mapDispatchToProps)(PageSettings));
