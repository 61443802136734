import { select, call, put, putResolve, all, takeEvery, takeLatest, delay } from 'redux-saga/effects';
import * as auth from '../services/spark-auth';
import * as types from '../actions/actionTypes';
import * as appActions from '../actions/appActions';
import * as accountActions from '../actions/accountActions';
import * as siteActions from '../actions/siteActions';
import * as accountSelectors from '../reducers/accountReducer';
import * as oauthState from '../services/oauth-state';

function* requestLogin(action) {
  try {
    const { username, password } = action.payload;
    const response = yield call(auth.obtainToken, username, password);

    if (response.ok) {
      const status = response.json.status;
      if (status === 'approved') {
        yield put(accountActions.loginSuccess(response.json.token));
        yield put(appActions.initializeApp());
      } else if (status === 'challenge') {
        yield put(accountActions.loginChallenge(response.json.token));
      }
    } else if (response.status === 429) {
      yield put(
        accountActions.loginFailure({
          non_field_errors: [
            'Your account has been temporarily locked due to too many consecutive failures. ' +
              'You can try to log in again in 1 hour, or reset your password using the link below.',
          ],
        })
      );
    } else {
      yield put(accountActions.loginFailure(response.json));
    }
  } catch (err) {
    console.error('Connection error.', err);
    yield put(
      accountActions.loginFailure({
        non_field_errors: 'Connection error',
      })
    );
  }
}

function* verifyLoginCode(action) {
  try {
    const {
      payload: { code },
    } = action;
    const token = yield select(accountSelectors.selectChallengedToken);
    const response = yield call(auth.obtainJWTAfterVerify, code, token);

    if (response.ok) {
      yield put(accountActions.loginSuccess(response.json.token));
      yield put(appActions.initializeApp());
    } else if ([400, 404, 410].includes(response.status)) {
      yield put(accountActions.verificationFailed({ code: response.json.message }));
    }
  } catch (e) {
    console.error(e);
  }
}

function* loginSuccess(action) {
  if (action.payload.token != null) {
    auth.storeToken(action.payload.token);
  }
  yield put({ type: types.ACCOUNT_SCHEDULE_TOKEN_REFRESH });
}

function* scheduleTokenRefresh(action) {
  const { timeToNextRefresh } = auth.getAuthStatus();
  yield delay(timeToNextRefresh);
  yield putResolve({ type: types.ACCOUNT_REFRESH_TOKEN });
}

function* refreshToken(action) {
  const { hasValidToken, timeToNextRefresh } = auth.getAuthStatus();

  if (timeToNextRefresh > 0) {
    yield put({ type: types.ACCOUNT_SCHEDULE_TOKEN_REFRESH });
  } else if (hasValidToken) {
    try {
      const response = yield call(auth.refreshToken);
      const isLoggedInState = yield select(accountSelectors.selectIsLoggedIn);

      if (response.ok && isLoggedInState) {
        yield put({ type: types.ACCOUNT_SCHEDULE_TOKEN_REFRESH });
      } else {
        yield put(accountActions.logout());
      }
    } catch (err) {
      console.error('Connection error.', err);
    }
  }
}

function* logout(action) {
  auth.clearToken();
  oauthState.clear();
  window.sprkfe.storage.clear();
  yield put(appActions.updateAppStatus({ isInitialized: true }));
}

function* accountSaga() {
  yield all([
    takeLatest(types.ACCOUNT_LOGIN_REQUEST, requestLogin),
    takeLatest(types.ACCOUNT_LOGIN_SUCCESS, loginSuccess),
    takeLatest(types.ACCOUNT_LOGIN_VERIFY_CODE, verifyLoginCode),
    takeLatest(types.ACCOUNT_REFRESH_TOKEN, refreshToken),
    takeLatest(types.ACCOUNT_SCHEDULE_TOKEN_REFRESH, scheduleTokenRefresh),
    takeEvery(types.ACCOUNT_LOGOUT, logout),
  ]);
}

export default accountSaga;
