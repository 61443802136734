import React from 'react';
import { connect } from 'react-redux';
import SideDrawer from 'components/base/SideDrawer';
import FormControl from 'components/base/FormHelpers/FormControl';
import SmartUrlInput from 'components/base/FormHelpers/SmartUrlInput';
import PrefixedInput from 'components/base/FormHelpers/PrefixedInput';
import NonFieldErrors from 'components/base/FormHelpers/NonFieldErrors';
import SlugFormControl from 'components/base/FormHelpers/SlugFormControl';

import * as itemsUrlActions from 'actions/itemsUrlActions';
import * as itemUrlSelectors from 'reducers/itemUrlReducer';

const mapStateToProps = (state, ownProps) => {
  return {
    url: itemUrlSelectors.selectById(state, ownProps.id),
  };
};

const mapDispatchToProps = {
  create: itemsUrlActions.updateOrCreate,
};

class CreateUrl extends React.Component {
  state = { data: {}, errors: {} };

  handleChange = (eventOrValue, target, itemType) => {
    if (target) {
      this.setState({
        ...this.state,
        data: { ...this.state.data, [target]: eventOrValue },
        ...(itemType && { itemType }),
      });
    } else
      this.setState({
        ...this.state,
        data: {
          ...this.state.data,
          [eventOrValue.target.name]: eventOrValue.target.value,
        },
      });
  };

  onSubmit = (e) => {
    e.preventDefault();
    const promise = new Promise((res, rej) => {
      this.props.create({ ...this.state.data, url: `/${this.state.data.url}` }, res, rej);
    });
    promise
      .then(() => {
        this.close();
      })
      .catch((errors) => {
        this.setState({ errors });
      });
  };

  close = () => {
    this.setState({ data: {}, errors: {}, itemType: null });
    this.props.close();
  };

  render() {
    const { isOpen } = this.props;
    return (
      <SideDrawer
        isOpen={isOpen}
        close={this.close}
        title="URL settings"
        render={() => {
          const { errors, itemType } = this.state;
          return (
            <>
              <form onSubmit={this.onSubmit}>
                <fieldset>
                  <label>Path</label>
                  <SlugFormControl
                    tag={PrefixedInput}
                    prefix={'/'}
                    errors={errors.url}
                    pageType={itemType}
                    type="text"
                    name="url"
                    value={this.state.data.url}
                    onChange={(name, value) => this.handleChange(value, name)}
                  />
                </fieldset>
                <fieldset>
                  <label>Type</label>
                  <FormControl tag="select" name="status" value={this.state.data.status} onChange={this.handleChange}>
                    <option value="" />
                    <option value={301}>Permanent Redirect</option>
                    <option value={302}>Temporary Redirect</option>
                  </FormControl>
                </fieldset>
                <fieldset>
                  <label>Target</label>
                  <SmartUrlInput
                    value={this.state.data.item || ''}
                    onChange={(id, itemType) => this.handleChange(id, 'item', itemType)}
                    placeholder="Type page or article name"
                  />
                </fieldset>
                <input
                  type="submit"
                  className="button button-full"
                  value="Create"
                  disabled={Object.keys(this.state.data).length < 3}
                />
              </form>
              <NonFieldErrors errors={errors} />
            </>
          );
        }}
      />
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateUrl);
