import React, { ReactNode, useState } from 'react';

import ErrorBoundary from 'components/ErrorBoundary';
import { SectionHandlersInterface, SectionUiHandlersInterface } from '../../../types';
import SectionError from './SectionError';
import { SectionTagArgs } from 'components/unstack-components/tag-types';
import withSite from 'containers/withSite';
import { USite } from 'types/USite';
import { UBoxInfo, USection, USectionDevices } from 'types/USection';
import { useDispatch, useSelector } from 'react-redux';
import { getCartData, templateGeneratorInitState } from 'reducers/templateGeneratorReducer';
import { changeCartData } from 'actions/templateGeneratorActions';
import cloneDeep from 'lodash/cloneDeep';
import Section from './Section';
import styles from './Cart.module.scss';
import FormControl from 'components/base/FormHelpers/FormControl';
import Box from '../block/Box';

interface Cart {
  products: { id: string }[];
}

interface CartElementProps extends SectionTagArgs {
  defaults: any;
  value: USection;
  children?: React.ReactChildren;
  sectionHandlers?: SectionHandlersInterface;
  sectionUiHandlers?: SectionUiHandlersInterface;
  site: USite;
  onChange: (content: USectionDevices, key?: string, multi?: boolean) => void;
  item: (cart: Cart) => React.ReactElement;
  empty: () => React.ReactElement;
  rootEl: string;
  dataRef: UBoxInfo;
  templateDataRef: string;
}

const DrawerWrapper = (props: { children?: ReactNode }) => {
  const dispatch = useDispatch();
  const cart = useSelector(getCartData);

  function hideCart() {
    dispatch(changeCartData({ ...cart, hidden: true }));
  }

  return (
    <section
      className="unstack__drawer unstack__drawer--left is-visible is-active"
      id="unstack__drawer_shopify"
      data-drawer-target
    >
      <div className="unstack__drawer__wrapper">
        <div id="unstack__drawer_loader" className="unstack__drawer_loader_wrapper">
          <span className="unstack__drawer_loader"></span>
        </div>
        <div className="unstack__drawer__header">
          <div></div>
          <div className="unstack__drawer__title">Your cart</div>
          <button
            onClick={hideCart}
            className="unstack__drawer__close"
            data-drawer-close
            aria-label="Close Drawer"
          ></button>
        </div>
        <div className="unstack__drawer__content section" id="unstack__drawer__content">
          {props.children}
        </div>
      </div>
    </section>
  );
};

const CartElement: React.FunctionComponent<CartElementProps> = (props) => {
  let { componentId } = props;

  const SectionErrorBoundary = (
    <SectionError
      sectionHandlers={props.sectionHandlers}
      sectionUiHandlers={props.sectionUiHandlers}
      componentId={componentId}
    />
  );

  const cart = useSelector(getCartData);
  const [cartState, setCartState] = useState('multipleProducts');
  const dispatch = useDispatch();

  function cartDataInitState() {
    dispatch(changeCartData(templateGeneratorInitState.cart));
  }

  function removeAllProducts() {
    const newCart = cloneDeep(cart);
    newCart.products = [];
    newCart.error = undefined;
    dispatch(changeCartData(newCart));
  }

  function showCart() {
    dispatch(changeCartData({ ...cart, hidden: false }));
  }

  function showCartError() {
    dispatch(changeCartData({ ...cart, error: 'Error with cart.' }));
  }

  function changeCartLayout() {
    if (cart.hidden) showCart();
    switch (cartState) {
      case 'multipleProducts':
        cartDataInitState();
        break;
      case 'emptyCart':
        removeAllProducts();
        break;
      case 'cartError':
        showCartError();
        break;
    }
  }

  return (
    <ErrorBoundary renderError={(_error) => SectionErrorBoundary}>
      <div>
        {props.rootEl === 'Cart' && (
          <>
            <Section {...props} contentKey={props.templateDataRef}>
              <div className={styles.optionsContainer}>
                <div className={styles.miniFrame}>
                  <div className={styles.labeledControl}>
                    <div className={styles.labelControl}>
                      <div className={styles.label}>Cart</div>
                      <FormControl
                        onChange={(ev: any) => setCartState(ev.target.value)}
                        autoComplete="off"
                        tag="select"
                        value={cartState}
                        class={styles.dropdown}
                      >
                        <option value="multipleProducts">Multiple Products</option>
                        <option value="emptyCart">Empty Cart</option>
                        <option value="cartError">Cart Error</option>
                      </FormControl>
                    </div>
                  </div>
                  <div className={styles.showButton} onClick={changeCartLayout}>
                    <span className={styles.showButtonText}>Show</span>
                  </div>
                </div>
              </div>
            </Section>
            {!cart.hidden && props.rootEl === 'Cart' && (
              <DrawerWrapper>{React.cloneElement(props.item(cart))}</DrawerWrapper>
            )}
          </>
        )}
        {props.rootEl !== 'Cart' && (
          <>
            <Box {...props} el={'div'}>
              {!cart.hidden && React.cloneElement(props.item(cart))}
            </Box>
          </>
        )}
      </div>
    </ErrorBoundary>
  );
};

export default withSite(CartElement);
