import React from 'react';
import isEqual from 'lodash/isEqual';
import { connect } from 'react-redux';
import SideDrawer from 'components/base/SideDrawer';
import FormControl from 'components/base/FormHelpers/FormControl';
import SmartUrlInput from 'components/base/FormHelpers/SmartUrlInput';
import SlugFormControl from 'components/base/FormHelpers/SlugFormControl';
import PrefixedInput from 'components/base/FormHelpers/PrefixedInput';
import NonFieldErrors from 'components/base/FormHelpers/NonFieldErrors';

import * as itemUrlSelectors from 'reducers/itemUrlReducer';
import * as itemsUrlActions from 'actions/itemsUrlActions';

import styles from './URLsSettings.module.scss';

const mapStateToProps = (state, ownProps) => {
  return {
    url: itemUrlSelectors.selectById(state, ownProps.id),
  };
};

const mapDispatchToProps = {
  flushCache: itemsUrlActions.flushCache,
  update: itemsUrlActions.updateOrCreate,
  remove: itemsUrlActions.remove,
};

class SettingsDrawer extends React.Component {
  state = { data: null, errors: {} };
  componentDidUpdate(prevProps) {
    const { url } = this.props;
    if (!isEqual(prevProps.url, url) && url) {
      const item = `${url.item_info.item_type.includes('page') ? 'page' : url.item_info.item_type}:${url.item}` || '';
      this.setState({
        data: { ...url, url: url.url.substring(1), item },
      });
    }
  }
  handleChange = (eventOrValue, target) => {
    if (target) this.setState({ data: { ...this.state.data, [target]: eventOrValue } });
    else
      this.setState({
        data: {
          ...this.state.data,
          [eventOrValue.target.name]: eventOrValue.target.value,
        },
      });
  };

  onSubmit = (e) => {
    e.preventDefault();
    const promise = new Promise((res, rej) => {
      this.props.update({ ...this.state.data, url: `/${this.state.data.url}` }, res, rej);
    });
    promise
      .then(() => {
        this.close();
      })
      .catch((errors) => {
        this.setState({ errors });
      });
  };

  close = () => {
    this.setState({ data: null, errors: {} });
    this.props.close();
  };

  render() {
    const { isOpen } = this.props;
    return (
      <SideDrawer
        isOpen={isOpen}
        close={this.close}
        title="URL settings"
        render={() => {
          if (this.state.data) {
            const { status, item_info: itemInfo } = this.state.data;
            const { errors } = this.state;
            return (
              <>
                <form onSubmit={this.onSubmit}>
                  <fieldset>
                    <label>Path</label>
                    <SlugFormControl
                      tag={PrefixedInput}
                      prefix={'/'}
                      type="text"
                      name="url"
                      errors={errors.url}
                      pageType={itemInfo.item_type}
                      value={this.state.data.url}
                      onChange={(name, value) => this.handleChange(value, name)}
                    />
                  </fieldset>
                  <fieldset>
                    <label>Type</label>
                    <FormControl tag="select" name="status" value={this.state.data.status} onChange={this.handleChange}>
                      <option value="" />
                      <option value={200}>Page Load</option>
                      <option value={301}>Permanent Redirect</option>
                      <option value={302}>Temporary Redirect</option>
                    </FormControl>
                  </fieldset>
                  <fieldset>
                    <label>Target</label>
                    <SmartUrlInput
                      value={this.state.data.item}
                      onChange={(id) => this.handleChange(id, 'item')}
                      placeholder="Type page or article name"
                    />
                  </fieldset>
                  <input
                    type="submit"
                    className="button button-full"
                    value="Update"
                    disabled={!this.state.data.url || !this.state.data.status || !this.state.data.item}
                  />
                  <input
                    readOnly
                    className="button button-full button-danger"
                    value="Delete"
                    onClick={() => this.props.remove(this.state.data.id, this.close)}
                    disabled={!this.state.data.url || !this.state.data.status || !this.state.data.item}
                  />
                </form>
                <p className={styles.actionLink}>
                  You can{' '}
                  <a
                    onClick={(e) => {
                      e.preventDefault();
                      this.props.flushCache(this.state.data.id, this.close);
                    }}
                  >
                    flush cache
                  </a>
                </p>
                <NonFieldErrors errors={errors} />
              </>
            );
          } else return null;
        }}
      />
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SettingsDrawer);
