import { call, put, all, takeEvery, takeLatest, select } from 'redux-saga/effects';
import * as api from '../services/spark-api';
import * as actionTypes from '../actions/actionTypes';
import * as categorySelector from '../reducers/categoriesReducer';
import { requestListSuccess, requestList } from '../actions/categoriesActions';
import requireSiteId from './utils/requireSiteId';
import { callSparkApi } from './utils/callSparkApi';

function* getListIfNeeded() {
  const isFetching = yield select(categorySelector.selectIsFetching);
  if (isFetching == null) yield put(requestList());
}

function* getCategoriesList() {
  try {
    const siteId = yield call(requireSiteId);
    const response = yield call(callSparkApi, api.getCategoryList, siteId);
    if (response.ok) yield put(requestListSuccess(response.json));
  } catch (err) {
    console.log(err);
  }
}

function* updateCategory(action) {
  const { payload, resolve, reject } = action;
  try {
    const response = yield call(api.updateCategory, payload);
    if (response.ok) {
      yield put({
        type: actionTypes.CATEGORY_UPDATE_REQUEST_SUCCESS,
        payload: response.json,
      });
      resolve();
    } else if (response.status === 409) reject(response.json.error);
  } catch (err) {
    console.log(err);
  }
}

function* removeCategory(action) {
  const {
    payload: { id },
    resolve,
    reject,
  } = action;
  try {
    const response = yield call(api.removeCategory, id);
    if (response.ok) {
      yield put({
        type: actionTypes.CATEGORY_DELETE_REQUEST_SUCCESS,
        payload: id,
      });
      resolve();
    } else if (response.status === 409) reject(response.json.error);
  } catch (err) {
    console.log(err);
  }
}

// Root saga
// ----------------------------------------------------------------------------

function* contactsSaga() {
  yield all([
    takeLatest(actionTypes.CATEGORY_FETCH_ALL_IF_NEEDED, getListIfNeeded),
    takeEvery(actionTypes.CATEGORY_FETCH_ALL_REQUEST, getCategoriesList),
    takeLatest(actionTypes.CATEGORY_UPDATE_REQUEST, updateCategory),
    takeLatest(actionTypes.CATEGORY_DELETE_REQUEST, removeCategory),
  ]);
}

export default contactsSaga;
