import { call, put, all, takeLatest } from 'redux-saga/effects';
import * as api from '../services/spark-api';
import * as types from '../actions/actionTypes';
import * as tagActions from '../actions/tagActions';
import requireSiteId from './utils/requireSiteId';
import omit from 'lodash/omit';

function* createTag(action) {
  const { payload, resolve, reject } = action;
  const data = {
    ...omit(payload, 'siteId'),
  };

  try {
    const response = yield call(api.createTag, data);
    if (response.ok) {
      resolve(response.json);
    } else reject();
    // yield put(resetSubmittingState());
  } catch (e) {
    console.error(e);
    reject();
    // yield put(resetSubmittingState());
  }
}

function* deleteTag(action) {
  const { payload, resolve, reject } = action;

  try {
    const response = yield call(api.deleteTag, payload);
    if (response.ok) {
      resolve(payload);
    } else reject();
    // yield put(resetSubmittingState());
  } catch (e) {
    console.error(e);
    reject();
    // yield put(resetSubmittingState());
  }
}

function* fetchTags(action) {
  const siteId = yield call(requireSiteId, action.payload.siteId);

  try {
    const response = yield call(api.getTags, siteId);
    if (response.ok) {
      yield put(tagActions.fetchAllSuccess(response.json.results));
    }
  } catch (e) {
    console.error(e);
  }
}

function* tagSaga() {
  yield all([
    takeLatest(types.TAG_CREATE_REQUEST, createTag),
    takeLatest(types.TAG_REMOVE_REQUEST, deleteTag),
    takeLatest(types.TAG_FETCH_ALL, fetchTags),
  ]);
}

export default tagSaga;
