import * as types from './actionTypes';
import isString from 'lodash/isString';

export function requestList(payload = {}) {
  if (isString(payload)) payload = { siteId: payload };

  return {
    type: types.PAGES_REQUEST_LIST,
    payload,
  };
}

export function requestListIfNeeded(siteId) {
  return {
    type: types.PAGES_REQUEST_LIST_IF_NEEDED,
    payload: { siteId },
  };
}

export function requestFilteredPagesList(payload = {}) {
  return {
    type: types.PAGES_REQUEST_LIST_BY_PAGE,
    payload,
  };
}

export function receiveList(pageIds, entities, count) {
  return {
    type: types.PAGES_RECEIVE_LIST,
    payload: { pageIds, entities, count },
  };
}

export function receiveListItem(pageId, entities) {
  return {
    type: types.PAGES_RECEIVE_LIST_ITEM,
    payload: { pageId, entities },
  };
}

export function requestPage(pageId) {
  return {
    type: types.PAGE_REQUEST,
    payload: { pageId },
  };
}

export function receivePage(entities) {
  return {
    type: types.PAGE_RECEIVE,
    payload: { entities },
  };
}

export function processAndReceiveRawResponse(responseBody) {
  return {
    type: types.PAGE_PROCESS_AND_RECEIVE_RAW_RESPONSE,
    payload: { responseBody },
  };
}

export function updatePageContent(id, path, value) {
  return {
    type: types.PAGE_UPDATE_CONTENT,
    payload: {
      id,
      path,
      value,
    },
  };
}

export function requestUpdate(pageId) {
  return {
    type: types.PAGE_UPDATE_REQUEST,
    payload: { pageId },
  };
}

export function receiveUpdate(pageId) {
  return {
    type: types.PAGE_UPDATE_RECEIVE,
    payload: { pageId },
  };
}

export function receiveSectionArticles(sectionArticles) {
  return {
    type: types.PAGE_RECEIVE_SECTION_ARTICLES,
    payload: { sectionArticles },
  };
}

export function requestPageInfoUpdate(pageId, resolve, reject) {
  return {
    type: types.PAGE_REQUEST_PAGE_INFO_UPDATE,
    payload: { pageId, resolve, reject },
  };
}

export function requestPublish(pageId) {
  return {
    type: types.PAGE_REQUEST_PUBLISH,
    payload: { pageId },
  };
}

export function receivePublishSuccess(pageId) {
  return {
    type: types.PAGE_RECEIVE_PUBLISH_SUCCESS,
    payload: { pageId },
  };
}

export function receivePublishError(pageId, errors) {
  return {
    type: types.PAGE_RECEIVE_PUBLISH_ERROR,
    payload: { pageId, errors },
  };
}

export function requestUnpublish(pageId) {
  return {
    type: types.PAGE_REQUEST_UNPUBLISH,
    payload: { pageId },
  };
}

export function receiveUnpublishSuccess(pageId) {
  return {
    type: types.PAGE_RECEIVE_UNPUBLISH_SUCCESS,
    payload: { pageId },
  };
}

export function requestClone(pageId, onSuccess) {
  return {
    type: types.PAGE_REQUEST_CLONE,
    payload: { pageId, onSuccess },
  };
}

export function receiveCloneSuccess(pageId, onSuccess) {
  return {
    type: types.PAGE_RECEIVE_CLONE_SUCCESS,
    payload: { pageId, onSuccess },
  };
}

export function requestUndelete(pageId, onSuccess) {
  return {
    type: types.PAGE_REQUEST_UNDELETE,
    payload: { pageId, onSuccess },
  };
}

export function receiveUndeleteSuccess() {
  return {
    type: types.PAGE_RECEIVE_UNDELETE_SUCCESS,
  };
}

export function requestDiscard(pageId) {
  return {
    type: types.PAGE_REQUEST_DISCARD,
    payload: { pageId },
  };
}

export function deletePage(pageId) {
  return {
    type: types.PAGE_DELETE,
    payload: { pageId },
  };
}

export function dynamicContentPageErrors(pageId, errors) {
  return {
    type: types.PAGE_DYNAMIC_CONTENT_PUBLISH_ERROR,
    payload: { errors, pageId },
  };
}
