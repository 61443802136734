import React from 'react';

import { Group, Label } from 'components/Editor/Toolbars2/Toolbar2';

import styles from './LayoutOption.module.scss';
import ColorPickerNew from 'components/Editor/Toolbars2/components/ColorPickerNew';
import { colorToRGBA } from 'components/Editor/Toolbars2/helpers';

const ColorField = (props: any) => {
  const handleOpacityChange = (value: number) => {
    if (value <= 100 && value >= 0) {
      const color = colorToRGBA(props.value);
      const updatedRGBA = color.replace(/[\d\.]+\)/, `${value / 100})`);
      props.onChange(props.property.name, updatedRGBA);
    }
  };

  return (
    <Group className={props.className}>
      {props.property.label && <Label className={styles.ColorLabel}>{props.property.label}:</Label>}
      <ColorPickerNew
        color={props.value || props.property?.default}
        onChange={(value: any) => {
          props.onChange(props.property.name, value);
        }}
        handleOpacityChange={handleOpacityChange}
      />
    </Group>
  );
};

export default ColorField;
