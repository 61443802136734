import * as actionTypes from './actionTypes';

export function requestAll(siteId) {
  return {
    type: actionTypes.TEMPLATE_TAGS_REQUEST_ALL,
    payload: { siteId },
  };
}

export function requestAllIfNeeded() {
  return {
    type: actionTypes.TEMPLATE_TAGS_REQUEST_ALL_IF_NEEDED,
  };
}

export function receiveAll(tags) {
  return {
    type: actionTypes.TEMPLATE_TAGS_RECEIVE_ALL,
    payload: { tags },
  };
}
