import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import classnames from 'classnames';

import useOnClickOutside from 'hooks/useClickOutside';
import * as addSectionActions from 'actions/addSectionActions';

import { ReactComponent as SvgIconShadowNone } from 'assets/images/icon-shadow-none.svg';
import { ReactComponent as SvgIconShadowSmall } from 'assets/images/icon-shadow-small.svg';
import { ReactComponent as SvgIconShadowMedium } from 'assets/images/icon-shadow-medium.svg';
import { ReactComponent as SvgIconShadowLarge } from 'assets/images/icon-shadow-large.svg';
import { ReactComponent as SvgIconShadowXLarge } from 'assets/images/icon-shadow-x-large.svg';
import { ReactComponent as SvgIconArrow } from 'assets/images/icon-cheveron-left.svg';
import { ReactComponent as SvgIconCheck } from 'assets/images/icon-check.svg';

import styles from './ShadowInput.module.scss';

type Props = {
  boxShadow: string;
  handleBoxShadowChange: (value: string) => void;
  type?: string;
  isOpen?: string;
  setIsOpen?: (value: string) => void;
};

type DrawerProps = {
  isOpen: boolean;
  onChange: (value: string) => void;
  shadowStyle: string;
  setDrawerIsOpen: (value: boolean) => void;
};

type ShadowProps = {
  style: string;
  onClick: () => void;
  current: string;
};

const BOX_SHADOW_OPTIONS: Array<string> = ['none', 'small', 'medium', 'large', 'x-large'];
const BOX_SHADOW_ICONS: { [key: string]: JSX.Element } = {
  '': <SvgIconShadowNone />,
  undefined: <SvgIconShadowNone />,
  none: <SvgIconShadowNone />,
  small: <SvgIconShadowSmall />,
  medium: <SvgIconShadowMedium />,
  large: <SvgIconShadowLarge />,
  'x-large': <SvgIconShadowXLarge />,
};

export default function ShadowInput(props: Props) {
  const { boxShadow, handleBoxShadowChange } = props;

  const [drawerIsOpen, setDrawerIsOpen] = useState(false);

  const handleDrawerItemClick = useCallback(
    (style: any) => {
      setDrawerIsOpen(false);
      handleBoxShadowChange(style);
      props.setIsOpen(undefined);
    },
    [handleBoxShadowChange]
  );

  const dispatch = useDispatch();
  useEffect(() => {
    if (drawerIsOpen) dispatch(addSectionActions.requestDrawerOpen());
    else dispatch(addSectionActions.requestDrawerClose());

    if (drawerIsOpen && props.setIsOpen) props.setIsOpen(props.type);
  }, [drawerIsOpen]);

  useEffect(() => {
    if (props.type && props.type === props.isOpen) setDrawerIsOpen(true);
    else setDrawerIsOpen(false);
  }, [props.isOpen]);

  return (
    <span className={styles.ShadowPicker}>
      <div
        className={classnames(styles.ShadowStyleInput, { [styles.IsOpen]: drawerIsOpen })}
        onClick={() => {
          setDrawerIsOpen(!drawerIsOpen);
          props.setIsOpen(undefined);
        }}
      >
        {BOX_SHADOW_ICONS[boxShadow]}
        <SvgIconArrow className={styles.ArrowDown} />
        <Drawer
          isOpen={drawerIsOpen}
          onChange={handleDrawerItemClick}
          shadowStyle={boxShadow}
          setDrawerIsOpen={setDrawerIsOpen}
        />
      </div>
    </span>
  );
}

function Drawer(props: DrawerProps) {
  const { isOpen, onChange, shadowStyle, setDrawerIsOpen } = props;

  const ref = useRef();
  useOnClickOutside(ref, () => setDrawerIsOpen(false));

  return (
    <CSSTransition in={isOpen} timeout={141.59} classNames={styles} mountOnEnter unmountOnExit>
      <div ref={ref} className={styles.Drawer}>
        <ul>
          {BOX_SHADOW_OPTIONS.map((name) => {
            return <ShadowChoice key={name} style={name} onClick={() => onChange(name)} current={shadowStyle} />;
          })}
        </ul>
      </div>
    </CSSTransition>
  );
}

function ShadowChoice(props: ShadowProps) {
  const { style, onClick, current } = props;
  return (
    <li className={styles.ShadowChoiceContainer} onClick={onClick}>
      <div className={styles.ShadowChoice}>
        {BOX_SHADOW_ICONS[style]}
        <span className={styles.inSwatchLabel}>{style ? (style === 'x-large' ? 'X Large' : style) : 'none'}</span>
      </div>
      {current === style && <SvgIconCheck />}
    </li>
  );
}
