import React, { useEffect, useMemo, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import get from 'lodash/get';

import ProductSideDrawer from './ProductSideDrawer';
import { useProduct } from 'components/Storefront/hooks';
import { ContainerInfo, SectionHandlersInterface, SectionUiHandlersInterface } from '../../../../types';
import { ReactComponent as IconEdit } from '../../../../../../assets/images/icons8-pencil.svg';
import styles from './ProductElement.module.scss';
import { ReinitPlugins } from 'components/unstack-components/Component/PluginsRefactored';
import usePrevious from 'hooks/usePrevious';
import { USectionDevices, USectionProps } from 'types/USection';
import { set, del, insert, push, assign } from 'object-path-immutable';
import { getDeviceTypeToSaveTo } from 'components/unstack-components/Component/util/helpers/deviceHelper';
import { useSelector } from 'react-redux';
import { getDevice } from 'reducers/uiReducer';
import Spinner from 'components/base/Spinner';
import { UProduct } from 'types/UProduct';

interface ProductProps extends ValueOnChangeProps<USectionProps> {
  sectionHandlers?: SectionHandlersInterface;
  sectionUiHandlers?: SectionUiHandlersInterface;
  children?: React.ReactNode;
  containerInfo?: ContainerInfo;
  dataRef?: any;
  contentKey?: string;
  item: any;
  defaultValues: Array<{ key: string; value: string; type: string }>;
  content: USectionDevices;
}

interface QuillOperation {
  ops: Array<{
    insert?: string;
    attributes?: any;
  }>;
}

const Product: React.FunctionComponent<ProductProps> = (props) => {
  const {
    onChange,
    dataRef,
    sectionHandlers,
    sectionUiHandlers,
    item,
    contentKey,
    content,
    defaultValues = [],
  } = props;

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [initPending, setInitPending] = useState<{ value: boolean; prev: any }>({ value: false, prev: null });
  const device = useSelector(getDevice);
  const isPLP = dataRef?.id === '{{ product.id }}';

  const { data, isLoading } = useProduct(dataRef?.id);

  const product = useMemo(() => {
    return dataRef?.id ? data : undefined;
  }, [dataRef?.id, data]);

  const prevIsLoading = usePrevious(isLoading);
  const childProps = useMemo(
    () => ({
      sectionHandlers: { ...sectionHandlers },
      sectionUiHandlers: { ...sectionUiHandlers },
    }),
    [sectionUiHandlers, sectionHandlers]
  );

  useEffect(() => {
    if (!isLoading && prevIsLoading) ReinitPlugins();
  }, [isLoading]);

  useEffect(() => {
    if (!isEmpty(product) && initPending.value && !isLoading && !isEqual(initPending.prev, product)) {
      const updateKeys: Array<string> = [];
      const updateValues: Array<QuillOperation> = [];

      defaultValues.forEach(({ key, value, type }) => {
        const formattedValue: QuillOperation = {
          ops: [{ insert: get({ ...data, product }, value) }],
        };

        if (type === 'h1') {
          formattedValue.ops.push({ attributes: { header: 1 }, insert: '\n' });
        }

        updateKeys.push(key);
        updateValues.push(formattedValue);
      });

      // TODO Daniel upgrade, is there a point to this code?
      // @ts-ignore
      // onChange(updateValues, updateKeys, true);
      // setInitPending({ value: false, prev: product });
    }
  }, [product, defaultValues, initPending, isLoading]);

  const handleChange: typeof onChange = (value, key) => {
    const splitKey = (key || contentKey).split('.');
    onChange(
      set(content as any, [getDeviceTypeToSaveTo(device, true, splitKey), ...splitKey.slice(1)], value),
      `content.${splitKey[0]}`
    );
    setInitPending({ value: true, prev: product });
  };

  return (
    <div>
      {React.cloneElement(item(product), childProps)}
      <div
        className={isPLP ? styles.editButtonDisabled : styles.editButton}
        onClick={(e) => {
          e.preventDefault();
          if (!isPLP) setIsOpen(true);
        }}
      >
        <IconEdit height={20} width={20} />
        Edit product
      </div>
      <ProductSideDrawer
        onChange={handleChange}
        isOpen={isOpen}
        close={() => setIsOpen(false)}
        product={product as UProduct}
        itemType={sectionUiHandlers?.itemType}
      />
    </div>
  );
};

export default Product;
