import React from 'react';
import { useSelector } from 'react-redux';
import { getUrlSiteFromSite } from '../reducers/sitesReducer';
import AuthenticatedMediaImg from '../containers/AuthenticatedMediaImg';
import get from 'lodash/get';
import classnames from 'classnames';
import { selectOwnerStatus } from 'reducers/accountUsersReducer';
import { selectHasPermission } from 'reducers/policyReducer';
import styles from './SiteList.module.scss';
import { ReactComponent as CheckMarkIcon } from 'assets/images/icon-checkmark-sitelist.svg';

export default function SiteList(props) {
  const { account, sites, urlSite: activeUrlSite, className, hideActive } = props;

  const ownerStatus = useSelector(selectOwnerStatus);
  const hasPermission = useSelector(selectHasPermission);

  return (
    <ul className={classnames(styles.SiteList, className)} data-test-id="sites">
      {sites.length > 1 && (
        <>
          {sites.map((site) => {
            const urlSite = getUrlSiteFromSite(site);
            const faviconSrc = get(site, 'elements.favicon.src');
            const isActive = urlSite === activeUrlSite;

            if (isActive && hideActive) return null;

            return (
              <li key={site.id} className={classnames({ active: isActive })}>
                <a href={`/${urlSite}`} className={classnames({ [styles.active]: isActive })}>
                  {isActive ? <CheckMarkIcon className={styles.checkmarkIcon} /> : null}
                  <span className={styles.faviconContainer}>
                    {faviconSrc && <AuthenticatedMediaImg className={styles.favicon} src={faviconSrc} />}
                  </span>
                  <span className={styles.nameContainer}>{site.name}</span>
                </a>
              </li>
            );
          })}
          {account && ownerStatus === 'active' && hasPermission('Site:create') && (
            <div className={styles.addSiteLinkDivider} />
          )}
        </>
      )}
      {account && ownerStatus === 'active' && hasPermission('Site:create') ? (
        <li>
          <a href={`/signup?uid=${account.id}`} className={styles.addSiteLink}>
            Add new site...
          </a>
        </li>
      ) : null}
    </ul>
  );
}
