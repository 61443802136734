import React, { useState } from 'react';
import * as PopperJS from '@popperjs/core';

import { ReactComponent as IconTriangle } from '../../assets/images/triangle-up.svg';
import { ReactComponent as IconError } from '../../assets/images/icon-error.svg';

import { usePopper } from 'react-popper';

import styles from './EditorAdminBarTitleError.module.css';

type Props = {
  ref: React.RefObject<any>;
  errorPath: string;
};

const EditorAdminBarTitleError: React.FunctionComponent<Props> = (props) => {
  const [referenceElement, setReferenceElement] = useState<Element | PopperJS.VirtualElement | null>(props.ref as any);
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
  const { styles: popperStyles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom',
  });

  return (
    <div
      ref={setReferenceElement}
      style={{ ...popperStyles.popper, zIndex: 9, position: 'absolute', transform: 'translate3d(20px, 67px, 0px)' }}
      {...attributes.popper}
    >
      <div ref={setPopperElement}>
        <IconTriangle className={styles.triangle} />
        <div className={styles.container}>
          <header className={styles.errorHeading}>
            <IconError className={styles.errorIcon} />
            <span className={styles.errorText}>Can’t create path with this title!</span>
          </header>
          <div>
            The path <span className={styles.errorPath}>/{props.errorPath}</span> already exists.{' '}
          </div>
          <div>Change the title here or edit the path in settings to continue.</div>
        </div>
      </div>
    </div>
  );
};

export default EditorAdminBarTitleError;
