import * as types from './actionTypes';

// Request

export function requestAllIfNeeded(siteId) {
  return {
    type: types.INTEGRATIONS_REQUEST_ALL_IF_NEEDED,
    payload: { siteId },
  };
}

export function requestAll(siteId) {
  return {
    type: types.INTEGRATIONS_REQUEST_ALL,
    payload: { siteId },
  };
}

export function requestHubspotData(siteId, hubspotIntegrationId) {
  return {
    type: types.INTEGRATION_REQUEST_HUBSPOT_DATA,
    payload: { siteId, hubspotIntegrationId },
  };
}

export function requestIntegrationTypesIfNeeded() {
  return {
    type: types.INTEGRATION_REQUEST_TYPES_IF_NEEDED,
  };
}

export function requestIntegrationTypes(payload) {
  return {
    type: types.INTEGRATION_REQUEST_TYPES,
    payload,
  };
}

export function requestIntegrationTypesSuccess(payload, isSearched) {
  return {
    type: types.INTEGRATION_REQUEST_TYPES_SUCCESS,
    payload,
    isSearched,
  };
}

// Receive

export function receiveAll(entities, receivedAt) {
  return {
    type: types.INTEGRATIONS_RECEIVE_ALL,
    payload: { entities, receivedAt },
  };
}

export function receiveUpdate(data) {
  return {
    type: types.INTEGRATIONS_RECEIVE_UPDATE,
    payload: data,
  };
}

export function receiveHubspotForms(payload) {
  return {
    type: types.INTEGRATION_RECEIVE_HUBSPOT_FORMS,
    payload,
  };
}

export function requestContactLists(siteId) {
  return {
    type: types.INTEGRATIONS_REQUEST_CONTACT_LIST,
    payload: { siteId },
  };
}

export function saveContactLists(payload) {
  return {
    type: types.INTEGRATIONS_REQUEST_CONTACT_LIST_SUCCESS,
    payload,
  };
}

// Create

export function create(data) {
  // Create a Promise, and expose the resolve & reject methods outside the
  // constructor scope to be used by the media saga. This is an antipattern,
  // but worth trying out. The alternatives - thunks, sagas, and state flags -
  // all add significant complexity.
  let resolve, reject;
  const promise = new Promise(function (scopedResolve, scopedReject) {
    resolve = scopedResolve;
    reject = scopedReject;
  });

  return {
    type: types.INTEGRATIONS_CREATE,
    payload: data,
    promise,
    resolve,
    reject,
  };
}

// Update

export function patch(data) {
  // Create a Promise, and expose the resolve & reject methods outside the
  // constructor scope to be used by the media saga. This is an antipattern,
  // but worth trying out. The alternatives - thunks, sagas, and state flags -
  // all add significant complexity.
  let resolve, reject;
  const promise = new Promise(function (scopedResolve, scopedReject) {
    resolve = scopedResolve;
    reject = scopedReject;
  });

  return {
    type: types.INTEGRATIONS_PATCH,
    payload: data,
    promise,
    resolve,
    reject,
  };
}

// Delete

export function deleteIntegration(id) {
  return {
    type: types.INTEGRATIONS_DELETE,
    payload: { id },
  };
}

export function deleteIntegrationRequest(id) {
  let resolve, reject;
  const promise = new Promise(function (scopedResolve, scopedReject) {
    resolve = scopedResolve;
    reject = scopedReject;
  });

  return {
    type: types.INTEGRATIONS_REQUEST_DELETE,
    payload: { id },
    promise,
    resolve,
    reject,
  };
}

export function bulkConnectIntegrations(payload, site) {
  return {
    type: types.INTEGRATIONS_REQUEST_BULK_CONNECT,
    payload,
    site,
  };
}

export function shopifyOAuthConnect(payload, resolve, reject) {
  return {
    type: types.INTEGRATIONS_SHOPIFY_OAUTH_FLOW,
    payload,
    resolve,
    reject,
  };
}

export function shopifyAppAuth(payload, resolve, reject, sessionToken = undefined) {
  return {
    type: types.INTEGRATIONS_SHOPIFY_AUTH,
    payload,
    resolve,
    reject,
    sessionToken,
  };
}
