import React from 'react';
import { set } from 'object-path-immutable';

import Navigation from './Navigation';
import ContentViewer from './ContentViewer';
import { isEmbedMediaEntity } from '../EmbedMedia';

import styles from './MediaDrawerBottom.module.scss';

class MediaDrawerBottom extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      selectedSource:
        props.dataTable || props?.entity?.type === 'data_table'
          ? 'data_table'
          : isEmbedMediaEntity(props.entity)
          ? 'embedMedia'
          : 'sparkMedia',
      category:
        props.entity.type === 'data_table'
          ? 'data_table'
          : props.entity.type
          ? 'embeds'
          : props.category === 'background_image'
          ? 'image'
          : props.category === 'all'
          ? 'image'
          : props.category,
      isUploading: false,
      isErrorOnCreate: null,
    };
  }

  handleChange = (entity) => {
    this.props.onChange(entity);
    this.props.close();
  };

  render() {
    const { entity, hideVideo, hideStockMedia, showAll, hideEmbeds, hideImage, dataTable, hidePdf, hideIcon } =
      this.props;
    const { category, selectedSource } = this.state;

    return (
      <div className={styles.mediaDrawerContainer}>
        <Navigation
          handleSourceChange={(selectedSource) => this.setState({ selectedSource })}
          selectedSource={selectedSource}
          updateCategory={(category) => this.setState({ category })}
          category={category}
          updateUploadingState={(isUploading, error = null) => this.setState({ isUploading, isErrorOnCreate: error })}
          hideVideo={hideVideo}
          hideEmbeds={hideEmbeds}
          hideStockMedia={hideStockMedia}
          showAll={showAll}
          hideImage={hideImage}
          hidePdf={hidePdf}
          hideIcon={hideIcon}
          isDataTable={dataTable}
          restrictMediaType={this.props.restrictMediaType}
        />
        <ContentViewer
          category={category}
          dataTable={dataTable}
          showAll={showAll}
          entity={entity}
          onChange={this.handleChange}
          selectedSource={selectedSource}
          isUploading={this.state.isUploading}
          isErrorOnCreate={this.state.isErrorOnCreate}
          enableLinking={this.props.enableLinking}
          showMediaContextOptions={this.props.showMediaContextOptions}
          restrictMediaType={this.props.restrictMediaType}
          onAltTextChange={(src) => this.handleChange(set(entity, 'alt', src))}
          hideVideo={hideVideo}
          hideVideoOptions={this.props.hideVideoOptions}
        />
      </div>
    );
  }
}

export default MediaDrawerBottom;
