import React from 'react';

import { FieldCard } from './FieldCard';
import * as constants from '../constants';

import styles from './FormBuilder.module.scss';

export const FieldBuilder = ({ addField, closeBuilder, selectedFields = [], addGenericField, subscription }) => (
  <>
    <div className={styles.container}>
      <label>Built-In Fields</label>
      <div className={styles.cardsContainer}>
        {constants.CONTACT_FIELDS.map((field, i) => (
          <FieldCard
            key={i}
            onClick={() => {
              closeBuilder();
              addField(field);
            }}
            name={field.default}
            isSelected={selectedFields.some((selected) => field.id === selected.id)}
          />
        ))}
      </div>
    </div>
    <div className={styles.container}>
      <label>
        Custom Fields{' '}
        {!subscription && (
          <small>
            (Custom fields are not allowed for free account. Please upgrade to add custom fields to your form)
          </small>
        )}
      </label>
      <div className={styles.cardsContainer}>
        {constants.GENERIC_FIELDS.map((field, i) => (
          <FieldCard
            key={i}
            onClick={() => {
              closeBuilder();
              addGenericField(field);
            }}
            name={field.default}
            disabled={!subscription}
          />
        ))}
      </div>
    </div>
  </>
);
