import * as actionTypes from '../actions/actionTypes';
import { createSelector } from 'reselect';
import { VideoActions } from 'actions/videosActions';
import { UVideo } from 'types/UVideo';

// ----------------------------------------------------------------------------
// Reducer
// ----------------------------------------------------------------------------

type VideosState = {
  isFetching: boolean;
  lastUpdated: Date;
  error?: string;
  byId: { [id: string]: UVideo };
  allIds: string[];
};

const initState: VideosState = {
  isFetching: false,
  lastUpdated: null,
  byId: {},
  allIds: [],
};

export default function videosReducer(state = initState, action: VideoActions) {
  switch (action.type) {
    case actionTypes.VIDEOS_REQUEST_ALL:
      return {
        ...state,
        isFetching: true,
      };

    case actionTypes.VIDEOS_RECEIVE_ALL: {
      const { allIds, byId, receivedAt } = action.payload;

      if (!byId) {
        return {
          isFetching: false,
          lastUpdated: receivedAt,
          error: 'No Elastic Path university videos found',
          allIds,
          byId,
        };
      }

      let newState = { ...state };
      Object.keys(byId).forEach((videoId) => {
        newState = {
          ...newState,
          byId: { ...newState.byId, [videoId]: { ...newState.byId[videoId], ...byId[videoId] } },
        };
      });

      return {
        isFetching: false,
        lastUpdated: receivedAt,
        error: undefined,
        allIds,
        byId: newState.byId,
      } as VideosState;
    }

    case actionTypes.VIDEOS_SET_VIDEO_WATCHED: {
      const video = { ...state.byId[action.id], watched: true };
      return {
        ...state,
        byId: { ...state.byId, [action.id]: video },
      };
    }

    case actionTypes.VIDEOS_SET_VIDEO_ELAPSED: {
      const video = { ...state.byId[action.id], elapsed: action.elapsed };
      return {
        ...state,
        byId: { ...state.byId, [action.id]: video },
      };
    }

    case actionTypes.UPDATE_VIDEOS_WITH_METADATA: {
      let newState = { ...state };
      Object.keys(action.metadata).forEach((videoId) => {
        newState = {
          ...newState,
          byId: { ...newState.byId, [videoId]: { ...newState.byId[videoId], ...action.metadata[videoId] } },
        };
      });
      return newState;
    }

    default:
      return state;
  }
}

// ----------------------------------------------------------------------------
// Selectors
// ----------------------------------------------------------------------------

export function selectLocalState(globalState: { videos: VideosState }) {
  return globalState.videos;
}

export function selectHasFetched(globalState: { videos: VideosState }): boolean {
  const state = selectLocalState(globalState);
  return state.lastUpdated !== null || state.isFetching;
}

export function selectAllIds(globalState: { videos: VideosState }) {
  const state = selectLocalState(globalState);
  return state.allIds;
}

export function selectById(globalState: { videos: VideosState }) {
  const state = selectLocalState(globalState);
  return state.byId;
}

export const selectAll = createSelector(selectAllIds, selectById, (allIds, byId) => allIds.map((id) => byId[id]));
