import isString from 'lodash/isString';
import { useEffect } from 'react';

type KeyboardEventTypes = 'keypress' | 'keydown' | 'keyup';
type KeyboardEventCallback = (e: KeyboardEvent) => void;

//  TODO: This should also work on multiple keycode in form of array
export default function useKeyBinding(
  eventName: KeyboardEventTypes,
  keyOrKeyCode: number | string,
  callback?: KeyboardEventCallback | false,
  eventOptions?: EventListenerOptions | boolean
) {
  useEffect(() => {
    if (!callback) return;

    function handler(e: KeyboardEvent) {
      const isMatch = isString(keyOrKeyCode) ? e.key === keyOrKeyCode : e.which === keyOrKeyCode;
      if (isMatch && callback) callback(e);
    }

    document.addEventListener(eventName, handler, eventOptions);
    return () => document.removeEventListener(eventName, handler, eventOptions);
  }, [eventName, keyOrKeyCode, callback, eventOptions]);
}

export function useEscapeKey(callback: KeyboardEventCallback) {
  useKeyBinding('keydown', 'Escape', callback);
}
