export const prettifyCode = async (code: string) => {
  const prettier = await import('prettier/standalone');
  const parserPlugin = await import('prettier/parser-babel');

  try {
    return prettier.format(code, {
      parser: 'babel',
      plugins: [parserPlugin],
      bracketSpacing: true,
      arrowParens: 'always',
      quoteProps: 'preserve',
      trailingComma: 'none',
      semi: false,
      printWidth: 1000,
    });
  } catch (e) {
    console.error(e);
    return code;
  }
};
