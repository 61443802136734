import React from 'react';
import styles from './ZeroState.module.scss';
import classnames from 'classnames';

export default function ZeroState(props) {
  const { children } = props;

  return (
    <div className={styles.ZeroState} data-test-id="zero-state">
      {children}
    </div>
  );
}

export const Image = createFancyTag('img', styles.Image);
export const Header = createFancyTag('h2', styles.Header);
export const Muted = createFancyTag('p', styles.Muted);
export const Group = createFancyTag('div');

function createFancyTag(TagName, fixedClassName) {
  return function (props) {
    const { className, ...restProps } = props;

    return <TagName className={classnames(fixedClassName, className)} {...restProps} />;
  };
}
