import React from 'react';
import { update } from 'object-path-immutable';

import SmartInput from './SmartInput';
import { Condition, Conditional } from './types';
import { BINARY_OPERATORS, TERNARY_OPERATORS } from './constants';

import styles from './Conditions.module.scss';

interface Props {
  operator: string;
  onChange: (arg0: any) => void;
  condition: Condition;
  idx: number;
  conditionValue: Conditional;
}

export default function (props: Props) {
  if (BINARY_OPERATORS.includes(props.operator))
    return !props.condition.smartInputType ? (
      <div className={styles.inputContainer}>
        <input
          className={styles.paramContainer}
          aria-label="value"
          onChange={(e) => {
            props.onChange(
              update(props.conditionValue, `conditional.conditions.${props.idx}`, (condition) => ({
                ...condition,
                value:
                  condition.data_type === 'int'
                    ? e.target.value.replaceAll(/[^0-9]/g, '')
                    : condition.data_type === 'number'
                    ? e.target.value.replace(/[^0-9.]/g, '')
                    : e.target.value,
              }))
            );
          }}
          value={props.condition?.value}
          type={
            props.condition.data_type === 'date'
              ? 'date'
              : props.condition.data_type === 'datetime'
              ? 'datetime-local'
              : 'text'
          }
          placeholder="Value"
        />
      </div>
    ) : (
      <SmartInput
        value={props.condition?.value}
        type={props.condition?.id}
        itemType={props.condition.smartInputType}
        onChange={(value, item) => {
          props.onChange(
            update(props.conditionValue, `conditional.conditions.${props.idx}`, (condition) => ({
              ...condition,
              value,
              item,
            }))
          );
        }}
      />
    );

  if (TERNARY_OPERATORS.includes(props.operator))
    return (
      <div className={styles.inputContainer}>
        <input
          className={styles.paramContainer}
          onChange={(e) => {
            props.onChange(
              update(props.conditionValue, `conditional.conditions.${props.idx}`, (condition) => ({
                ...condition,
                value1:
                  condition.data_type === 'int'
                    ? e.target.value.replaceAll(/[^0-9]/g, '')
                    : condition.data_type === 'number'
                    ? e.target.value.replace(/[^0-9.]/g, '')
                    : e.target.value,
              }))
            );
          }}
          value={props.condition?.value1}
          type={
            props.condition.data_type === 'date'
              ? 'date'
              : props.condition.data_type === 'datetime'
              ? 'datetime-local'
              : 'text'
          }
          placeholder="Value"
        />
        <input
          className={styles.paramContainer}
          onChange={(e) => {
            props.onChange(
              update(props.conditionValue, `conditional.conditions.${props.idx}`, (condition) => ({
                ...condition,
                value2:
                  condition.data_type === 'int'
                    ? e.target.value.replaceAll(/[^0-9]/g, '')
                    : condition.data_type === 'number'
                    ? e.target.value.replace(/[^0-9.]/g, '')
                    : e.target.value,
              }))
            );
          }}
          value={props.condition?.value2}
          type={
            props.condition.data_type === 'date'
              ? 'date'
              : props.condition.data_type === 'datetime'
              ? 'datetime-local'
              : 'text'
          }
          placeholder="Value"
        />
      </div>
    );

  return null;
}
