import React from 'react';
import classnames from 'classnames';
import VisibilitySensor from 'react-visibility-sensor';
import size from 'lodash/size';
import { connect } from 'react-redux';

import Spinner from '../../Spinner';
import Search from '../../../Search';
import MediaNoResults from './MediaNoResults';
import { MediaOptionsDrawer } from './MediaOptionsDrawer';
import { ReactComponent as SvgIcons8 } from '../../../../assets/images/icon-media-icons8.svg';

import * as mediaActions from '../../../../actions/mediaActions';
import * as mediaSelectors from '../../../../reducers/mediaReducer';

import styles from './Icons8.module.scss';

function mapStateToProps(state) {
  const icons8 = mediaSelectors.selectIcons8(state);
  const { pageResponse, isFetching } = icons8 || {
    pageResponse: { icons: [] },
  };

  return {
    pageResponse,
    isFetching,
  };
}

const mapDispatchToProps = {
  searchIcons8: mediaActions.searchIcons8,
  clearIcons8Search: mediaActions.clearIcons8Search,
  downloadIcons8Photo: mediaActions.downloadIcons8Photo,
};

class Icons8 extends React.PureComponent {
  state = {
    lastQuery: '',
    lastSeenPhotoRow: 0,
    lastPage: 1,
    selectedMedia: {},
    debouncedQuery: '',
  };

  componentDidMount() {
    this.clearIcons8Search();
  }

  componentDidUpdate(nextProp, nextState) {
    if (this.state.lastQuery !== nextState.lastQuery && !this.state.lastQuery) this.setState({ lastSeenPhotoRow: 0 });

    if (this.state.debouncedQuery !== nextState.debouncedQuery) {
      this.props.clearIcons8Search();
      this.state.debouncedQuery && this.props.searchIcons8(this.state.debouncedQuery, 1);
    }
  }

  clearIcons8Search() {
    this.props.clearIcons8Search();
    this.setState({
      lastQuery: '',
      lastSeenPhotoRow: 0,
      lastPage: 1,
    });
  }

  handleClick = (icon) => {
    const { photoImageSize } = this.props;

    let category = this.props.category === 'icons8' ? 'icon' : this.props.category;
    if (this.props.category === 'favicon') category = 'brand';
    else if (this.props.category === 'background_image') category = 'image';

    const iconsUrl = icon.url.replace('140px', photoImageSize.width);

    this.setState({ isDownloading: true });

    this.props
      .downloadIcons8Photo(icon.id, iconsUrl)
      .promise.then((blob) => {
        const fileExtension = /(?:\.([^.]+))?$/.exec(icon.url)[1];
        const file = new File([blob], `${icon.name}.${fileExtension}`);

        this.props
          .createMedia(file, category)
          .promise.then((media) => {
            const { id } = media;
            this.props.onChange(`media:${id}`);
          })
          .catch((errors) => {
            this.setState({
              errorMessage: errors.message,
            });
          })
          .finally(() => {
            if (this.props.category !== 'background_image') {
              this.setState({ isDownloading: false });
              this.clearIcons8Search();
            }
          });
      })
      .catch((errors) => {
        this.setState({
          errorMessage: errors.message,
        });
      })
      .finally(() => {
        if (this.props.category !== 'background_image') {
          this.clearIcons8Search();
        }
      });
  };

  onLoadMoreVisibilityChange(isVisible) {
    const {
      pageResponse: { icons },
    } = this.props;
    const { lastSeenPhotoRow, lastPage, lastQuery } = this.state;
    if (isVisible && lastQuery && size(icons) > lastSeenPhotoRow) {
      this.setState({
        lastSeenPhotoRow: size(icons),
        lastPage: lastPage + 1,
      });
      this.props.searchIcons8(lastQuery, lastPage + 1);
    }
  }

  render() {
    const {
      pageResponse: { icons },
      isFetching,
    } = this.props;
    const hasIcons = icons && icons.length > 0;

    return (
      <>
        <Search
          onChange={(value) => {
            this.setState({ lastQuery: value });
          }}
          containerStyle={{ position: 'absolute', top: '20px' }}
          placeholder="Search icons"
          debounceValue={(debouncedQuery) => this.setState({ debouncedQuery })}
          enableDarkMode
          value={this.state.lastQuery}
          autofocus={!this.state.selectedMedia.id}
        />
        <div className={styles.sourceLink}>
          Icons by{' '}
          <a href="https://icons8.com" target="_blank" rel="noopener noreferrer">
            Icons8
          </a>
        </div>
        {hasIcons && (
          <div
            className={classnames(styles.icons8container, {
              [styles.addPadding]: this.state.selectedMedia.id,
            })}
            data-test-id="icons8-photo-grid"
          >
            {icons.map((icon) => (
              <div
                className={classnames(styles.content, {
                  [styles.selected]: this.state.selectedMedia.id === icon.id,
                })}
                key={icon.id}
                data-test-id="icons8-icon-content"
              >
                <figure>
                  <img
                    src={icon.url}
                    onClick={() =>
                      this.setState({
                        selectedMedia: this.state.selectedMedia.id === icon.id ? {} : icon,
                      })
                    }
                    alt=""
                  />
                </figure>
              </div>
            ))}
            {this.state.selectedMedia.id && (
              <MediaOptionsDrawer
                updateEntityOptions={(e) => {
                  this.setState({
                    selectedMedia: {
                      ...this.state.selectedMedia,
                      alt_description: e.target.value,
                    },
                  });
                }}
                entityOptions={{
                  altText: this.state.selectedMedia.alt_description || '',
                }}
                onSave={() => (this.state.isDownloading ? null : this.handleClick(this.state.selectedMedia))}
                onRemove={() => {}}
                entity={this.props.entity}
                isSectionContent={false}
                addMargin
                showLoader={this.state.isDownloading}
              />
            )}
          </div>
        )}
        {hasIcons && !isFetching && (
          <VisibilitySensor onChange={(visible) => this.onLoadMoreVisibilityChange(visible)}>
            <span>&nbsp;</span>
          </VisibilitySensor>
        )}
        {!hasIcons && isFetching && <Spinner className={styles.spinner} />}
        {!hasIcons && !isFetching && (
          <MediaNoResults
            icon={<SvgIcons8 height="50px" width="50px" />}
            header="Find the perfect icon"
            content="Search thousands of professional icons that can help make your content stand out."
          />
        )}
      </>
    );
  }
}

Icons8 = connect(mapStateToProps, mapDispatchToProps)(Icons8);

export { Icons8 };
