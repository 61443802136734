export default function (color: string) {
  let a: string | number,
    rgb: RegExpMatchArray = color?.replace(/\s/g, '').match(/^rgba?\((\d+),(\d+),(\d+),?([^,\s)]+)?/i),
    alpha = ((rgb && rgb[4]) || '').trim(),
    hex = rgb
      ? ((rgb[1] as unknown as number) | (1 << 8)).toString(16).slice(1) +
        ((rgb[2] as unknown as number) | (1 << 8)).toString(16).slice(1) +
        ((rgb[3] as unknown as number) | (1 << 8)).toString(16).slice(1)
      : color;

  if (!rgb) return color;
  if (alpha) {
    a = alpha;
  } else {
    return '#' + hex;
  }
  // multiply before convert to HEX
  a = (((a as unknown as number) * 255) | (1 << 8)).toString(16).slice(1);
  hex = hex + a;

  return '#' + hex;
}
