import React from 'react';
import { connect } from 'react-redux';
import * as blogSelectors from '../reducers/blogsReducer';
import * as blogActions from '../actions/blogActions';

function mapStateToProps(state, ownProps) {
  return {
    blogsLoading: blogSelectors.selectIsFetching(state),
    blogs: blogSelectors.selectAll(state),
    blogsById: blogSelectors.selectById(state),
  };
}

const mapDispatchToProps = {
  requestBlogList: blogActions.requestListIfNeeded,
};

export default (WrappedComponent) => {
  class Container extends React.PureComponent {
    componentDidMount() {
      this.props.requestBlogList();
    }

    render() {
      const { requestBlogList, ...props } = this.props;
      return <WrappedComponent {...props} />;
    }
  }

  return connect(mapStateToProps, mapDispatchToProps)(Container);
};
