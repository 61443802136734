import React from 'react';

import iconTimelineVisit from '../../../assets/images/icon-contact-entered-site.svg';
import iconTimelineConversion from '../../../assets/images/icon-contact-form-fill.svg';
import iconTimelinePaid from '../../../assets/images/icon-timeline-paid.svg';
import iconTimelineViewedSite from '../../../assets/images/icon-contact-viewed-page.svg';
import { ReactComponent as IconMailchimpIcon } from '../../../assets/images/mailchimp_icon.svg';
// import iconTimelineChatted from '../../../assets/images/icon-timeline-chatted.svg';
// import iconTimelineDrift from '../../../assets/images/icon-timeline-drift@2x.png';
// import iconTimelineFilledForm from '../../../assets/images/icon-timeline-filled-form.svg';
// import iconTimelineIntercom from '../../../assets/images/icon-timeline-intercom@2x.png';
// import iconTimelineKlaviyo from '../../../assets/images/icon-timeline-klaviyo@2x.png';
// import iconTimelineOpenedEmail from '../../../assets/images/icon-timeline-opened-email.svg';
// import iconTimelinePaid from '../../../assets/images/icon-timeline-paid.svg';
// import iconTimelineStripe from '../../../assets/images/icon-timeline-stripe@2x.png';
// import iconTimelineSubscribed from '../../../assets/images/icon-timeline-subscribed.svg';
// import iconTimelineWatchedVideo from '../../../assets/images/icon-timeline-watched-video.svg';

import startCase from 'lodash/startCase';
// import lowerCase from 'lodash/lowerCase';

export default function renderActionDescription(a) {
  if (a.type === 'visit') {
    return (
      <>
        <img src={iconTimelineVisit} alt="" />{' '}
        <span>
          Visited from <b>{a.utm_source || 'Unknown'}</b> &nbsp;
          {/* <code className="muted">referrer: {a.http_referrer}</code> */}
        </span>
      </>
    );
  } else if (a.type === 'pageview') {
    return (
      <>
        <img src={iconTimelineViewedSite} alt="" />{' '}
        <span>
          Viewed {a.name} {a.value && a.value > 1 && `and ${a.value - 1} other pages`}
        </span>
      </>
    );
  } else if (a.action === 'upgrade') {
    return (
      <>
        <img src={iconTimelinePaid} alt="" /> Upgraded to {startCase(a.label)} &nbsp;
        <code className="muted">${a.value.toFixed(2)}</code>
      </>
    );
  } else if (a.action === 'publish') {
    return (
      <>
        <img src={iconTimelineConversion} alt="" /> Published{' '}
        {a.value && a.value > 1 ? `${a.value} pages` : `a page: ${a.label}`}
      </>
    );
  } else if (a.action === 'signup') {
    return (
      <>
        <img src={iconTimelineConversion} alt="" /> Completed signup
      </>
    );

    // Form
  } else if (a.action === 'submit') {
    if (a.category === 'form') {
      return (
        <>
          <img src={iconTimelineConversion} alt="" />{' '}
          <span>
            Submitted form &nbsp;
            <code className="muted">{a.form ? a.form : `id: ${a.label}`}</code>
            on the page &nbsp;<code className="muted">/{a.slug}</code>
          </span>
        </>
      );
    } else if (a.category === 'hubspot_form') {
      return (
        <>
          <img src={iconTimelineConversion} alt="" />{' '}
          <span>
            Submitted a Hubspot form &nbsp;
            <code className="muted">id: {a.label}</code>
          </span>
        </>
      );
    } else if (a.category === 'klaviyo_form') {
      return (
        <>
          <img src={iconTimelineConversion} alt="" />{' '}
          <span>
            Submitted a Klaviyo form &nbsp;
            <code className="muted">id: {a.label}</code>
          </span>
        </>
      );
    }
  } else if (a.category === 'subscription') {
    return (
      <>
        <img src={iconTimelinePaid} alt="" />{' '}
        <span>
          Subscribed to ${a.label} <code className="charge">${a.value.toFixed(2)}</code>
        </span>
      </>
    );
  } else if (a.category === 'transaction') {
    if (a.action === 'charge') {
      return (
        <>
          <img src={iconTimelinePaid} alt="" />{' '}
          <span>
            Stripe charge <code className="charge">${a.value.toFixed(2)}</code>
          </span>
        </>
      );
    } else if (a.action === 'refund') {
      return (
        <>
          <img src={iconTimelinePaid} alt="" />{' '}
          <span>
            Stripe refund <code className="refund">${a.value.toFixed(2)}</code>
          </span>
        </>
      );
    }
  } else if (a.source_id === 'mailchimp_integration') {
    if (a.action === 'click')
      return (
        <>
          <IconMailchimpIcon style={{ marginRight: '1.2rem' }} />{' '}
          <span>
            <b>Clicked</b> link in email{' '}
            <a href={a.url.replaceAll('"', '')} target="_blank">
              {a.label}
            </a>
          </span>
        </>
      );
    else if (a.action === 'open')
      return (
        <>
          <IconMailchimpIcon style={{ marginRight: '1.2rem' }} />
          <span>
            <b>Opened</b> email{' '}
            <a href={a.url.replaceAll('"', '')} target="_blank">
              {a.label}
            </a>
          </span>
        </>
      );
    else if (a.action === 'sent')
      return (
        <>
          <IconMailchimpIcon style={{ marginRight: '1.2rem' }} />{' '}
          <span>
            <b>Sent</b> email{' '}
            <a href={a.url.replaceAll('"', '')} target="_blank">
              {a.label}
            </a>
          </span>
        </>
      );
    else if (a.action === 'generic_signup')
      return (
        <>
          <IconMailchimpIcon style={{ marginRight: '1.2rem' }} />{' '}
          <span>
            <b>Added</b> to Mailchimp
          </span>
        </>
      );
    else if (a.action === 'unsub')
      return (
        <>
          <IconMailchimpIcon style={{ marginRight: '1.2rem' }} />{' '}
          <span>
            <b>Unsubscibed</b> from list from email{' '}
            <a href={a.url.replaceAll('"', '')} target="_blank">
              {a.label}
            </a>
          </span>
        </>
      );
  }

  // Default to debug renderer to view attributes
  return (
    <>
      <pre style={{ whiteSpace: 'pre' }}>{JSON.stringify(a, null, 2)}</pre>
    </>
  );
}
