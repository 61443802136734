import React from 'react';
import styles from './JSXParsingError.module.scss';

interface ParentProps {
  LastParsedJSX?: JSX.Element;
}

function JSXParsingError(props: ParentProps) {
  if (!props.LastParsedJSX) {
    return (
      <div className={styles.ErrorWrapper}>
        There was an error rendering the JSX. This likely due to the HTML for this component having an error. If it's
        blocking you from proceeding, go to Design/Sections to resolve the issue or contact a member from our Support
        Team.
      </div>
    );
  }
  return <></>;
}

export default JSXParsingError;
