import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import withSiteId from '../../containers/withSiteId';

import { CardElement, ElementsConsumer } from '@stripe/react-stripe-js';
import Elements from 'lazy/StripeElements';

import SideDrawer from '../base/SideDrawer';
import { requestUpdateCard as updateCard } from '../../actions/subscriptionActions';
import * as subscriptionSelectors from 'reducers/subscriptionReducer';

import styles from './PaymentForm.module.scss';
import stripeStyles from './Stripe.module.scss';

const mapStateToProps = (state) => {
  return {
    subscription: subscriptionSelectors.selectSubscription(state),
  };
};

const mapDispatchToProps = { updateCard };

class PaymentForm extends React.Component {
  state = {
    errorMessage: '',
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ isFetching: true, errorMessage: '' });

    this.props.stripe.createToken(this.props.elements.getElement(CardElement)).then((response) => {
      const { token, error } = response;
      if (error) {
        this.setState({
          errorMessage: error.message,
          isFetching: false,
        });
      } else {
        const promise = new Promise((resolve, reject) => {
          this.props.updateCard(this.props.subscription.id, token.card.id, token.id, resolve, reject);
        });
        promise
          .catch((errorMessage) => {
            this.setState({ errorMessage });
          })
          .finally(() => {
            this.setState({ isFetching: false });
            this.props.close();
          });
      }
    });
  };

  render() {
    const { isOpen, close } = this.props;
    const { errorMessage, isFetching } = this.state;

    return (
      <SideDrawer
        isOpen={isOpen}
        close={close}
        title="Update you credit card"
        render={() => (
          <form className={styles.PaymentForm} onSubmit={this.handleSubmit}>
            <fieldset>
              <label htmlFor="">Credit card</label>
              <CardElement classes={stripeStyles} style={{ invalid: { iconColor: '#CB52B0', color: '#CB52B0' } }} />
              {errorMessage && <small className="errorMessage">{errorMessage}</small>}
            </fieldset>
            <fieldset>
              <input
                type="submit"
                className="button button-primary button-full"
                readOnly
                value={isFetching ? 'Updating...' : 'Update'}
              />
            </fieldset>
          </form>
        )}
      />
    );
  }
}

const InjectedPaymentForm = compose(withSiteId, connect(mapStateToProps, mapDispatchToProps))(PaymentForm);

export default (props) => (
  <React.Suspense fallback={<div>Loading...</div>}>
    <Elements>
      <ElementsConsumer>
        {({ stripe, elements }) => <InjectedPaymentForm stripe={stripe} elements={elements} {...props} />}
      </ElementsConsumer>
    </Elements>
  </React.Suspense>
);
