import React, { useMemo } from 'react';

import { Group, Label, Button } from '../../../../../../Editor/Toolbars2/Toolbar2';
import { ReactComponent as SvgIconRemoveSection } from '../../../../../../../assets/images/icon-remove-section.svg';
import { ReactComponent as SvgIconChevronLeft } from '../../../../../../../assets/images/icon-cheveron-left.svg';
import { ReactComponent as SvgIconCloneSection } from '../../../../../../../assets/images/icon-clone-section.svg';
import { ReactComponent as SvgIconHide } from '../../../../../../../assets/images/icon-hide.svg';
import { SectionHandlersInterface } from '../../../../../types';

import CreateElementButton from './CreateElementButton';

import styles from './Toolbar.module.scss';

type Props = {
  sectionHandlers?: SectionHandlersInterface;
  locations?: Array<string> | undefined;
  close(event: React.MouseEvent<HTMLElement, MouseEvent>): void;
};

const SectionOptions: React.FunctionComponent<Props> = ({ sectionHandlers, close, locations = [] }) => {
  const { firstMainSectionIndex, lastHeaderSectionIndex, firstFooterSectionIndex, sectionIndex, lastMainSectionIndex } =
    sectionHandlers;

  const [isMoveUpAllowed, isMoveDownAllowed]: Array<boolean> = useMemo(() => {
    if (locations && typeof locations === 'object')
      return [
        !!(
          (locations.find((location) => location === 'header') && sectionIndex === firstMainSectionIndex) ||
          sectionIndex > firstMainSectionIndex ||
          (sectionHandlers?.container?.location === 'header' && sectionIndex > 0)
        ),
        !!(
          (locations.find((location) => location === 'footer') &&
            (sectionIndex === sectionHandlers.lastMainSectionIndex || sectionIndex !== sectionHandlers.lastIndex)) ||
          sectionIndex < sectionHandlers.lastMainSectionIndex
        ),
      ];
  }, [locations, sectionHandlers]);

  return (
    <Group className={styles.absoluteRight}>
      {sectionHandlers.onCreateElement && !sectionHandlers.isDeleted && (
        <CreateElementButton sectionType={sectionHandlers.type} onCreateElement={sectionHandlers.onCreateElement} />
      )}
      {sectionHandlers.onDuplicate && !sectionHandlers.isDeleted && (
        <Button onClick={sectionHandlers.onDuplicate} data-test-id="duplicate">
          <SvgIconCloneSection data-tip="Duplicate section" height={17} width={17} />
        </Button>
      )}

      <Button onClick={close} data-test-id="hide-section-control">
        <SvgIconHide data-tip="Hide Toolbar" height={17} width={17} />
      </Button>
      {sectionHandlers.onRemove && (
        <Button
          data-test-id="delete"
          onClick={() => {
            const confirmation = window.confirm('Are you sure you want to delete this section? This cannot be undone');
            // @ts-ignore: Cypress prop defination is not defined on window top object
            if (confirmation || window.top.Cypress) sectionHandlers.onRemove();
          }}
        >
          <SvgIconRemoveSection height={11} width={12} className={styles.remove} />
        </Button>
      )}
      {sectionHandlers.onMove && (
        <Label className={styles.move} data-test-id="move">
          <SvgIconChevronLeft
            data-test-id="up"
            onClick={() =>
              isMoveUpAllowed &&
              sectionHandlers.onMove(
                true,
                sectionIndex === firstFooterSectionIndex
                  ? 'main'
                  : sectionIndex === firstMainSectionIndex
                  ? 'header'
                  : ''
              )
            }
            height={16}
            width={16}
            className={`${styles.moveUp} ${!isMoveUpAllowed ? styles.isDisabled : ''}`}
          />

          <SvgIconChevronLeft
            data-test-id="down"
            height={16}
            onClick={() =>
              isMoveDownAllowed &&
              sectionHandlers.onMove(
                false,
                sectionIndex === lastHeaderSectionIndex ? 'main' : sectionIndex === lastMainSectionIndex ? 'footer' : ''
              )
            }
            width={16}
            className={`${styles.moveDown} ${!isMoveDownAllowed ? styles.isDisabled : ''}`}
          />
        </Label>
      )}
    </Group>
  );
};

export default SectionOptions;
