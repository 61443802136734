import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import * as routerActions from '../actions/routerActions';
import { locationHasChanged } from '../actions/routerActions';

const RouterToReduxDispatcher = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(locationHasChanged(location));
  }, [location]);

  return <></>;
};

const mapDispatchToProps = {
  locationHasChanged: routerActions.locationHasChanged,
};

export default RouterToReduxDispatcher;
