import React, { useEffect, useRef, useState } from 'react';

import debounce from 'lodash/debounce';

import { CSS_UNITS_REGEX } from '../../../../constants';

import { LayoutProps } from '.';

import styles from '../StyleGuideEditor.module.scss';

interface WidthProps extends LayoutProps {
  value: string;
  path: Array<string>;
}

export default function ({ value, handleChange, name, path }: WidthProps) {
  const [val, setVal] = useState<string>(value);
  const debounced = useRef<any>(
    debounce((value: number | string) => {
      let input = value,
        number = parseInt(input as string, 10),
        matched = (input as string).match(CSS_UNITS_REGEX) as unknown as string,
        output = isNaN(number) ? '' : number + (matched || 'px');
      handleChange(path, output);
    }, 1000)
  );

  useEffect(() => {
    debounced.current(val);
  }, [val]);

  return (
    <div>
      <input
        type="text"
        name={name}
        value={val}
        className={styles.inputField}
        onChange={(e) => setVal(e.target.value)}
      />
    </div>
  );
}
