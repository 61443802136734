import React from 'react';
import isObject from 'lodash/isObject';
import UsageChart from './ContactInfo/Chart';

import { ReactComponent as IconMailchimpIcon } from '../../../assets/images/mailchimp_icon.svg';

import styles from './ContactInfo.module.scss';

const ALLOWED_MAILCHIMP_PARAMS = ['GENDER', 'source', 'status', 'ADDRESS', 'mailchimp_tags', 'mailchimp_lists'];

export default function ContactInfo(props) {
  const { contact } = props;

  const formatKey = function (key) {
    key = key.replace(/_/g, ' ');
    return key[0].toUpperCase() + key.substring(1).toLowerCase();
  };

  const defaults = [
    ['Email', contact.email],
    ['Phone', contact.phone],
    ['Company', contact.organization],
    ['Life time value ($)', `$${contact.ltv.toString()}`],
    ['Location', contact],
    ['Last seen', new Date(contact.last_seen_at).toLocaleDateString()],
    ['First seen', new Date(contact.first_seen_at).toLocaleDateString()],
  ];
  const mailchimpParams = contact.params?.mailchimp_integration || {};

  const displayData = defaults
    .concat(
      Object.keys(contact.params).map((integration) => {
        if (integration === 'mailchimp_integration') return null;
        else if (typeof contact.params[integration] !== 'object') return [integration, contact.params[integration]];
        else if (contact.params[integration])
          return Object.keys(contact.params[integration]).map((key) => [
            formatKey(key),
            contact.params[integration][key],
          ]);
        else return null;
      })
    )
    .filter((data) => data);

  const mailchimpDisplayData = Object.keys(mailchimpParams)
    .map((key) => (ALLOWED_MAILCHIMP_PARAMS.includes(key) ? [formatKey(key), mailchimpParams[key]] : null))
    .filter((data) => data);

  return (
    <div className={styles.container}>
      <UsageChart contact={contact} />
      <dl className={styles.list}>
        {displayData.map((datum) => {
          if (datum[0] === 'Location') {
            const contact = datum[1];
            const hasAddress = Boolean(
              contact.address1 || contact.address2 || contact.city || contact.state || contact.zip || contact.country
            );
            return (
              <React.Fragment key={datum[0]}>
                <dt>{datum[0]}</dt>
                {hasAddress ? (
                  <>
                    {contact.address1 && <dd className="ph-redact-text">{contact.address1}</dd>}
                    {contact.address2 && <dd className="ph-redact-text">{contact.address2}</dd>}
                    {contact.zip ? (
                      <>
                        <dd className="ph-redact-text">
                          {contact.city ? contact.city + ', ' : ''} {contact.state ? contact.state + ' ' : ''}{' '}
                          {contact.zip ? contact.zip : ''}
                        </dd>
                        {contact.country && <dd>{contact.country}</dd>}
                      </>
                    ) : (
                      <>
                        <dd className="ph-redact-text">
                          {contact.city ? contact.city + ', ' : ''} {contact.state ? contact.state + ', ' : ''}{' '}
                          {contact.country ? contact.country : ''}
                        </dd>
                      </>
                    )}
                  </>
                ) : (
                  <dd>
                    <span className={styles.muted}>-</span>
                  </dd>
                )}
              </React.Fragment>
            );
          } else {
            if (isObject(datum[0])) {
              const reactFragments = [];
              datum.forEach((subArray) => {
                reactFragments.push(
                  <React.Fragment key={subArray[0]}>
                    <dt>{subArray[0]}</dt>
                    <dd className="ph-redact-text">
                      {typeof subArray[1] === 'object' && JSON.stringify(subArray[1])}
                      {subArray[1] === true && <span>true</span>}
                      {subArray[1] === false && <span>false</span>}
                      {subArray[1] === 0 && <span></span>}
                      {(subArray[1] === undefined || subArray[1] === null) && <span className={styles.muted}>-</span>}
                      {!!subArray[1] && <span>{subArray[1]}</span>}
                    </dd>
                  </React.Fragment>
                );
              });
              return reactFragments;
            }

            if (!isObject(datum[1]))
              return (
                <React.Fragment key={datum[0]}>
                  <dt>{datum[0]}</dt>
                  <dd className="ph-redact-text">
                    {datum[1] && Array.isArray(datum[1]) ? datum[1].join(',') : datum[1]}
                    {datum[1] === true && <span>true</span>}
                    {datum[1] === false ? (
                      <span>false</span>
                    ) : datum[1] === 0 ? (
                      <span></span>
                    ) : (
                      !datum[1] && <span className={styles.muted}>-</span>
                    )}
                  </dd>
                </React.Fragment>
              );
          }
        })}
        {mailchimpDisplayData.map((datum) => {
          const dataValue = datum[1];
          let dd;
          if (Array.isArray(dataValue)) {
            if (dataValue.length) {
              dd = dataValue.map((tag) => tag.name).join(', ');
            } else {
              dd = <span className={styles.muted}>-</span>;
            }
          } else if (typeof dataValue == 'string') {
            dd = dataValue;
          } else {
            dd = Object.values(dataValue)
              .map((listItem) => listItem)
              .join(', ');
          }
          return (
            <React.Fragment key={datum[0]}>
              <dt>
                <IconMailchimpIcon className={styles.icon} /> {datum[0]}
              </dt>
              <dd className="ph-redact-text">{dd}</dd>
            </React.Fragment>
          );
        })}
      </dl>
    </div>
  );
}

function location(contact) {
  const combined = [];
  if (contact.city) combined.push(contact.city);
  if (contact.state) combined.push(contact.state);
  if (contact.country) combined.push(contact.country);
  return combined.join(', ');
}

// address1: null
// address2: null
// avg_time_on_page: 342
// city: null
// country: null
// created_at: "2019-07-17T19:07:59.672524Z"
// email: "grant+tracking@unstack.com"
// first_name: null
// first_seen_at: "2019-06-17 14:57:25.778599"
// id: 3
// last_name: null
// last_seen_at: "2019-06-17 22:55:56.274678"
// meta: {}
// modified_at: "2019-07-17T19:08:45.215241Z"
// organization: "Unstack July 2019"
// pageviews: 4
// persona: null
// phone: null
// site: "f7e196de-5c9a-4ceb-b2bf-e7b13eac8983"
// source: "API"
// state: null
// status: null
// title: null
// type: null
// visits: 2
// website: null
// zip: null
