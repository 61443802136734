import React from 'react';
import { Button, Tray } from '../../Toolbars2/Toolbar2';

import {
  IconAnimateUp,
  IconAnimateRight,
  IconAnimateDown,
  IconAnimateLeft,
  IconAnimateZoomIn,
} from '../../Toolbars2/icons';
import { AnimationValues } from 'types/USection';

const icons = {
  'fade-up': IconAnimateUp,
  'fade-right': IconAnimateRight,
  'fade-down': IconAnimateDown,
  'fade-left': IconAnimateLeft,
  'zoom-in': IconAnimateZoomIn,
};

type Props = {
  value: AnimationValues | undefined;
  onChange: (value: string) => void;
};

function AnimateButton(props: Props) {
  const { value, onChange } = props;

  const handleChange = (newValue: AnimationValues) => (): void => {
    if (value === newValue) onChange(undefined);
    else onChange(newValue);
  };

  const DisplayIcon = icons[value] || icons['fade-up'];

  return (
    <Tray
      renderIcon={() => <DisplayIcon active={!!value} />}
      trayLabel="ANIMATE"
      renderTray={() => (
        <>
          <Button onClick={handleChange('fade-up')}>
            <IconAnimateUp active={value === 'fade-up'} />
          </Button>
          <Button onClick={handleChange('fade-right')}>
            <IconAnimateRight active={value === 'fade-right'} />
          </Button>
          <Button onClick={handleChange('fade-down')}>
            <IconAnimateDown active={value === 'fade-down'} />
          </Button>
          <Button onClick={handleChange('fade-left')}>
            <IconAnimateLeft active={value === 'fade-left'} />
          </Button>
          <Button onClick={handleChange('zoom-in')}>
            <IconAnimateZoomIn active={value === 'zoom-in'} />
          </Button>
        </>
      )}
    />
  );
}

export default AnimateButton;
