import React, { useMemo } from 'react';
import Box from '../../../../../Editor/Boxes/Box';
import BoxButtons from '../../../../../Editor/Boxes/Toolbar/BoxButtons';
import omit from 'lodash/omit';
import { UBoxInfo, USectionDevices } from 'types/USection';
import { SectionHandlersInterface } from 'components/unstack-components/types';

type SectionBoxButtonProps = {
  dataRef: UBoxInfo;
  onChange: (content: USectionDevices, key?: string, multi?: boolean) => void;
  defaults?: UBoxInfo;
  content: USectionDevices;
  contentKey: string;
};

export function SectionBoxButtons(props: SectionBoxButtonProps) {
  const { onChange, defaults = {} as UBoxInfo, content, dataRef, contentKey } = props;

  const valueWithDefaults = useMemo(() => ({ ...defaults, ...dataRef }), [defaults, dataRef]);

  return (
    <BoxButtons
      defaults={defaults}
      dataRef={valueWithDefaults}
      onChange={onChange}
      isSectionToolbar
      contentKey={contentKey}
      content={content}
      containerInfo={undefined}
    />
  );
}

type SectionBoxProps = {
  children: React.ReactNode;
  dataRef: UBoxInfo;
  sectionHandlers: SectionHandlersInterface;
  defaults?: UBoxInfo;
  boxUUid: string;
};

export function SectionBox(props: SectionBoxProps) {
  const { children, sectionHandlers, defaults, boxUUid } = props;

  const valueWithDefaults = useMemo(() => ({ ...defaults, ...props.dataRef }), [defaults, props.dataRef]);
  const re = /^\d+$/;
  const defaultVal = re.test(defaults?.maxWidth.toString()) ? defaults.maxWidth.toString() + 'px' : defaults.maxWidth;
  const defaultPadding = {
    paddingTop: defaults?.paddingTop,
    paddingRight: defaults?.paddingRight,
    paddingBottom: defaults?.paddingBottom,
    paddingLeft: defaults?.paddingLeft,
  };

  return (
    <Box
      className="section-box"
      sectionName={sectionHandlers.componentName}
      {...omit(valueWithDefaults, ['brightnessTheme'])}
      isProductComponent={sectionHandlers.isProductComponent}
      defaultMaxWidth={defaultVal}
      defaultPadding={defaultPadding}
      shouldTriggerToolbar={true}
      boxUUid={boxUUid}
    >
      {children}
    </Box>
  );
}
