export const normalizeFormData = (data) => {
  const result = data.map((form) => {
    const { type, value } = form.action;
    const {
      content: { fields, contact_lists = {} },
      name,
      id,
      zap_exists,
    } = form;
    const actionMessageOrUrl = type === 'redirect' ? 'url' : 'message';

    return {
      submissionAction: {
        type,
        [actionMessageOrUrl]: form.action[actionMessageOrUrl],
      },
      fields,
      submitValue: value,
      name,
      id,
      contactListsIds: contact_lists,
      zap_exists,
    };
  });
  return result;
};

export const parseFieldOptions = (fields) => {
  fields.forEach((f) => delete f.isNew);
  return fields.map((field) => {
    if (field.options) {
      field.options = field.options.split(',').map((option) => [option.trim(), option.trim()]);
    }
    return field;
  });
};
