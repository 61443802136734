import React from 'react';
import styles from './ValidationMessage.module.scss';
import { ReactComponent as SvgArrowUp } from '../../../assets/images/arrow-up.svg';
import isArray from 'lodash/isArray';

type Props = {
  message: string | string[] | null | undefined;
  success?: boolean;
};

function ValidationMessage(props: Props) {
  const { message, success } = props;
  if (!message || message.length === 0) return null;

  let displayMessage;
  if (isArray(message)) displayMessage = message.join(' ');
  else displayMessage = message;

  return (
    <div className={success ? styles.success : styles.error} data-test-id="error">
      <SvgArrowUp />
      <span>{displayMessage}</span>
    </div>
  );
}

export default ValidationMessage;
