import * as types from '../actions/actionTypes';
import { combineReducers } from 'redux';
import { createSelector } from 'reselect';
import createCachedSelector from 're-reselect';
import { assign, del, insert, merge, push, set, update, wrap } from 'object-path-immutable';
import get from 'lodash/get';
import union from 'lodash/union';

// ----------------------------------------------------------------------------
// Reducer
// ----------------------------------------------------------------------------

// sectionsById

function addEntry(state, action) {
  const { id } = action.payload;

  return {
    ...state,
    [id]: {
      ...action.payload,
      id,
    },
  };
}

function addEntryBetween(state, action) {
  const { id, sectionProps, idx } = action.payload;

  const result = (function (state, id, sectionProps, idx) {
    var temp = {};
    var i = 0;
    for (var prop in state) {
      if (state.hasOwnProperty(prop)) {
        if (i === idx && id && sectionProps) {
          temp[id] = { ...sectionProps, id };
        }
        temp[prop] = state[prop];
        i++;
      }
    }
    if (!idx && id && sectionProps) {
      temp[id] = { ...sectionProps, id };
    }

    return temp;
  })(state, id, sectionProps, idx);

  return result;
}

function updateEntry(state, action) {
  const { id, sectionProps } = action.payload;

  const oldSection = state[id];

  return {
    ...state,
    [id]: {
      ...oldSection,
      ...sectionProps,
    },
  };
}

function removeEntry(state, action) {
  const { id } = action.payload;
  const newState = { ...state };
  delete newState[id];
  return newState;
}

function replaceEntry(state, action) {
  state = removeEntry(state, action);
  const { newSection } = action.payload;

  return {
    ...state,
    [newSection.id]: newSection,
  };
}

function updateEntryProp(state, action) {
  const { id, path, value } = action.payload;
  const oldSection = state[id];

  return {
    ...state,
    [id]: {
      ...update(oldSection, path, (_) => value),
    },
  };
}

function pushEntryProp(state, action) {
  const { id, path, value } = action.payload;
  const oldSection = state[id];

  return {
    ...state,
    [id]: {
      ...push(oldSection, path, value),
    },
  };
}

function popEntryProp(state, action) {
  const { id, path } = action.payload;
  const oldSection = state[id];
  const oldArray = get(oldSection, path);
  const newArray = oldArray.slice(0, oldArray.length - 1);

  return {
    ...state,
    [id]: {
      ...set(oldSection, path, newArray),
    },
  };
}

function removeEntryProp(state, action) {
  const { id, path, index } = action.payload;
  const oldSection = state[id];
  const tempArray = get(oldSection, path);
  tempArray.splice(index, 1);
  return {
    ...state,
    [id]: {
      ...set(oldSection, path, tempArray),
    },
  };
}

function deleteEntryProp(state, action) {
  const { id, path } = action.payload;
  const oldSection = state[id];

  return {
    ...state,
    [id]: del(oldSection, path),
  };
}

function reorderNestedProps(state, action) {
  const { id, path, updatedProps } = action.payload;
  const oldSection = state[id];
  return { ...state, [id]: set(oldSection, path, updatedProps) };
}

function updateContainerChildren(state, action) {
  const { id, children } = action.payload;
  const oldSection = state[id];
  return {
    ...state,
    [id]: {
      ...update(oldSection, 'children', (_) => [...children]),
    },
  };
}

function sectionsById(state = {}, action) {
  switch (action.type) {
    case types.SECTIONS_ADD: {
      return addEntry(state, action);
    }

    case types.SECTIONS_ADD_BETWEEN:
      return addEntryBetween(state, action);

    case types.SECTIONS_UPDATE:
      return updateEntry(state, action);

    case types.SECTIONS_DELETE:
      return removeEntry(state, action);

    case types.SECTIONS_REPLACE:
      return replaceEntry(state, action);

    case types.SECTIONS_PUSH_NESTED_PROP:
      return pushEntryProp(state, action);

    case types.SECTIONS_POP_NESTED_PROP:
      return popEntryProp(state, action);

    case types.SECTIONS_UPDATE_NESTED_PROP:
      return updateEntryProp(state, action);

    case types.SECTIONS_DELETE_NESTED_PROP:
      return deleteEntryProp(state, action);

    case types.SECTIONS_REORDER_NESTED_PROP:
      return reorderNestedProps(state, action);

    case types.SECTIONS_REMOVE_NESTED_PROP:
      return removeEntryProp(state, action);

    case types.SECTIONS_UPDATE_CONTAINER:
      return updateContainerChildren(state, action);

    case types.SECTIONS_RECEIVE:
      return {
        ...state,
        ...action.payload.entities.sections,
      };

    case types.PAGE_RECEIVE: {
      return Object.values(action.payload.entities.sections || {}).reduce((acc, section) => {
        return { ...acc, [section.id]: { ...section, showAddSectionTooltip: section.showAddSectionTooltip ?? true } };
      }, {});
    }

    default:
      return state;
  }
}

// sectionIds

function addId(state, action) {
  const id = action.payload.id;
  return [...state, id];
}

function addIdBetween(state, action) {
  const { id } = action.payload;
  return union(state, [id]);
}

function removeId(state, action) {
  const id = action.payload.id;
  const index = state.indexOf(id);
  return [...state.slice(0, index), ...state.slice(index + 1)];
}

function replaceId(state, action) {
  const {
    id: oldId,
    newSection: { id: newId },
  } = action.payload;

  const index = state.indexOf(oldId);
  return set(state, index, newId);
}

function sectionIds(state = [], action) {
  switch (action.type) {
    case types.SECTIONS_ADD:
      return addId(state, action);

    case types.SECTIONS_ADD_BETWEEN:
      return addIdBetween(state, action);

    case types.SECTIONS_DELETE:
      return removeId(state, action);

    case types.SECTIONS_REPLACE:
      return replaceId(state, action);

    case types.SECTIONS_RECEIVE: {
      const newSections = action.payload.entities.sections || {};
      const newSectionIds = Object.keys(newSections);
      return union(state, newSectionIds);
    }

    case types.PAGE_RECEIVE: {
      const newSections = action.payload.entities.sections || {};
      const newSectionIds = Object.keys(newSections);
      return newSectionIds;
    }

    default:
      return state;
  }
}

export default combineReducers({
  byId: sectionsById,
  allIds: sectionIds,
});

// ----------------------------------------------------------------------------
// Selectors
// ----------------------------------------------------------------------------

export function selectLocalState(globalState) {
  return globalState.sections;
}

export function selectAllById(globalState) {
  const state = selectLocalState(globalState);
  return state.byId;
}

export function selectAllIds(globalState) {
  const state = selectLocalState(globalState);
  return state.allIds;
}

export const selectAllSections = createSelector(selectAllIds, selectAllById, (allIds, byId) =>
  allIds.map((id) => byId[id])
);

export function selectSection(globalState, id) {
  const byId = selectAllById(globalState);
  return byId[id];
}

export function selectSections(globalState, ids = []) {
  return ids.map((id) => selectSection(globalState, id));
}

export const selectTestSectionByVariantId = createSelector(selectAllSections, (sections) => {
  const testSectionIdsByVariantId = {};

  sections.forEach((section) => {
    if (section.type === 'test' && section.variants && section.variants.length) {
      section.variants.forEach((variant) => {
        if (variant) testSectionIdsByVariantId[variant] = section.id;
      });
    }
  });

  return testSectionIdsByVariantId;
});

export function selectSectionTestId(globalState, id) {
  const testSectionIdsByVariantId = selectTestSectionByVariantId(globalState);
  return testSectionIdsByVariantId[id];
}

export function selectTestOrSectionTestId(globalState, id) {
  const testSectionIdsByVariantId = selectTestSectionByVariantId(globalState);
  return testSectionIdsByVariantId[id] || id;
}

export function selectVariantIds(globalState, id) {
  const section = selectSection(globalState, id);
  const variantIds = [];

  if (section.type === 'experiment' && section.children) {
    section.children.forEach((variant) => {
      if (variant) variantIds.push(variant);
    });
  }

  return variantIds;
}

export function selectSectionContainerId(globalState, id) {
  const sectionContainerId = selectSectionByContainerId(globalState);
  return sectionContainerId[id];
}

export function selectChildrenIds(globalState, id) {
  const section = selectSection(globalState, id);
  const childrenIds = [];

  if ((section.type === 'carousel' || section.type === 'experiment') && section.children) {
    section.children.forEach((child) => {
      if (child) childrenIds.push(child);
    });
  }

  return childrenIds;
}

export const selectSectionByContainerId = createSelector(selectAllSections, (sections) => {
  const sectionByContainerId = {};

  sections.forEach((section) => {
    if ((section.type === 'carousel' || section.type === 'experiment') && section.children && section.children.length) {
      section.children.forEach((container) => {
        if (container) sectionByContainerId[container] = section.id;
      });
    }
  });

  return sectionByContainerId;
});

export const selectChildren = createCachedSelector(selectChildrenIds, selectAllById, (childrenIds, byId) => {
  return childrenIds.map((childId) => byId[childId]);
})((_, containerId) => containerId);
