import React, { useState } from 'react';

import styles from './Conditions.module.scss';

interface Props {
  onChange: (arg: string) => void;
  param: string;
  isSectionActive: boolean;
  placeholder: string;
}

export default function (props: Props) {
  const [param, setParam] = useState<string>(props.param || '');

  return (
    <div className={styles.inputContainer}>
      <input
        autoFocus={props.isSectionActive}
        className={styles.paramContainer}
        aria-label="param"
        onChange={(e) => {
          setParam(e.target.value);
          props.onChange(e.target.value);
        }}
        value={param}
        type="text"
        placeholder={props.placeholder || 'Param'}
      />
    </div>
  );
}
