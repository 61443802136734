import { UPluginController } from "./UPluginController"
import { applyDomTemplates, loadAOSAndHighlight } from "./pluginUtils";
import { Dom } from "../../utils/Dom";
import { Sprk } from "../../utils/Sprk";

interface IUPluginController {
  new (): UPluginController;
}

export class UPluginManager {
  instances: {[key: string]: UPluginController};
  pluginAttrList: string[];
  intialized: boolean;

  constructor() {
    this.pluginAttrList = [
      "accordion",
      "countdown",
      "product-price",
      "product-inventory",
      "product-option-value",
      "modal",
      "quantity-selector",
      "product-popup",
      "product-collection-popup",
      "unstack-form",
      "gallery",
      "sticky",
      "algolia",
      "purchase-options",
      "bundle",
      "add-coupon",
      "pagination",
      "shipping-options",
      "graphenehc",
      "klevu",
      "search-recommendation"
    ];
    this.instances = {}
    if(!Dom.isRenderedPage()){
      Sprk.initialize();
    }
    if(Dom.isRenderedPage()) {
      loadAOSAndHighlight();
    }
    applyDomTemplates()
    this.init();
  }

  getController(attr: string) {
    return attr
      .split('-')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join('') + 'Controller';
  }

  // initControllers(content?: HTMLElement) {
  //   // @ts-ignore
  //   const System = window.System;
  //   const promiseList: Promise<any>[] = []
  //   this.pluginAttrList.forEach((attr) => {
  //     let instanceId = this.getInstanceId(attr);
  //     if ((content && content.querySelector(`[data-${attr}]`) || Dom.getElByDataAttr(attr)) && !this.instances[instanceId]) {
  //       promiseList.push(System.import(this.getController(attr)).then((exports: {[key: string]: IUPluginController}) => {

  initControllers(content?: HTMLElement) {
    const promiseList: Promise<any>[] = []
    this.pluginAttrList.forEach((attr) => {
      const promise = this.initController(attr);
      promiseList.push(promise);
    });
    return Promise.all(promiseList);
  }

  initController(attr: string, content?: HTMLElement) {
    // @ts-ignore
    const System = window.System;
    let instanceId = this.getInstanceId(attr);
    if ((Dom.getElByDataAttr(attr) && (!content || content.querySelector(`[data-${attr}]`))) && !this.instances[instanceId]) {
      (this.instances[instanceId] as any) = "loading";
      return System.import(this.getController(attr)).then(
        (exports: { [key: string]: IUPluginController }) => {
          const Controller = Object.values(exports)[0];
          this.instances[instanceId] = new Controller();
        }
      );
    }
  }

  // support for legacy naming of sticky and baseModal
  getInstanceId(attr: string) {
    switch(attr){
      case("sticky"):
        return "stickyContent"
      case("modal"):
        return "baseModal"
    }
    return attr
      .split('-')
      .map((word, i) => i === 0 ? word : word.charAt(0).toUpperCase() + word.slice(1))
      .join('');
  }

  initInstances() {
    Object.values(this.instances).forEach((instance) => {
      if((instance as any) !== "loading") {
        instance.init();
      }
    })
  }

  init(content?: HTMLElement) {
    if(!Dom.isRenderedPage()) {
      (window as any).static_path = window.location.origin + "/assets/";
      (window as any).cache_buster = '';
    }
    Sprk.on("modules:mapimported", async () => {
      await this.initControllers(content ? content : undefined)
      this.initInstances()
      this.intialized = true;
    })
  }

  destructor() {
    Object.values(this.instances).forEach((controller) => controller.destructor())
  }

  isReady() {
    return this.intialized && Object.values(this.instances).every((controller) => controller.isReady)
  }
}
if(!Dom.isRenderedPage()) {
  (window as any).sprk = {};
}
(window as any).sprk.UPluginManager = new UPluginManager();
