/* eslint-disable jsx-a11y/anchor-is-valid, jsx-a11y/alt-text */

import React, { useMemo } from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Container from 'components/unstack-components/Component';
import { ReactComponent as SvgTextLinkArrow } from '../../../assets/images/icon-text-link-arrow.svg';
import * as siteLayoutSelector from 'reducers/siteLayoutReducer';
import * as pageSelectors from '../../../reducers/pagesReducer';

import SitePageFooter from './SitePageFooter';
import LandingPageFooter from './LandingPageFooter';
import { ILayout } from 'types/SiteLayout';
import { SectionHandlersInterface, SectionUiHandlersInterface } from 'components/unstack-components/types';
import { USection } from 'types/USection';
import styles from './HeaderFooterOverlay.module.scss';

function getDefaultFooter(layout: string, itemType: string) {
  switch (layout) {
    case 'full':
      return SitePageFooter;
    case 'minimal':
      return LandingPageFooter;
    case 'none':
      return null;
    default:
      return itemType === 'site_page' ? SitePageFooter : LandingPageFooter;
  }
}

type IProps = {
  layout: ILayout;
  pageId: string;
  itemType: string;
  siteDomain: string;
};

function FooterRenderer(props: IProps) {
  const { layout, pageId, itemType, siteDomain } = props;
  const page = useSelector((state) => pageSelectors.selectPage(state, pageId));
  const siteLevelCustomFooter = useMemo(() => page.footer || layout?.default_footer, [page, layout]);

  const { customFooter, builtInFooter } = useMemo(() => {
    const customFooter = page.footer;
    const builtInFooter = page.config.footer_layout;

    return { customFooter, builtInFooter };
  }, [page, itemType]);

  const hasCustomFooter = !builtInFooter && (customFooter || siteLevelCustomFooter);
  const renderableSection = useSelector((state) => siteLayoutSelector.selectFooterById(hasCustomFooter)(state));

  const DefaultFooter = useMemo(() => {
    return getDefaultFooter(builtInFooter, itemType);
  }, [builtInFooter, itemType]);

  if (renderableSection) {
    return (
      <div style={{ position: 'relative' }}>
        <Container
          content={renderableSection as unknown as USection}
          onContentChange={() => {}}
          sectionHandlers={{} as SectionHandlersInterface}
          sectionUiHandlers={{} as SectionUiHandlersInterface}
        />
        <div className={classnames('admin', styles.overlay)}>
          <Link to={`/${siteDomain}/elements`}>
            You can change your footer in Header & Footer <SvgTextLinkArrow />
          </Link>
        </div>
      </div>
    );
  }

  return DefaultFooter ? <DefaultFooter pageId={pageId} /> : null;
}

export default FooterRenderer;
