import React, { useState } from 'react';

import { Tray, Group, Label } from '../Toolbar2';
import { IconBackgroundColor } from '../icons';
import { colorToRGBA } from '../helpers';
import ColorPickerNew from '../../Toolbars2/components/ColorPickerNew';
import BorderInput from './BorderInput';
import ShadowInput from './ShadowInput';

import styles from './BackgroundStylesTray.module.scss';
import { UBoxInfo } from 'types/USection';

type Props = {
  dataRef: UBoxInfo;
  onChange: (value: string) => (value: string | number | any) => void;
  startOpen: boolean;
};

export default function BackgroundStylesTray(props: Props) {
  const { dataRef, onChange, startOpen } = props;
  const {
    backgroundColor,
    backgroundOpacity,
    borderColor,
    borderWidth,
    borderRadius,
    borderStyle,
    backgroundImage,
    boxShadow,
  } = dataRef;

  const backgroundOpacityWithDefault =
    backgroundOpacity != null ? backgroundOpacity : backgroundImage && backgroundImage.src ? 0 : 1;

  const handleColorChange = (value: string) => {
    onChange('backgroundColor')(colorToRGBA(value));
  };

  const handleBorderColorChange = (value: string) => {
    onChange('borderColor')(value);
  };

  const handleBorderStyleChange = (value: string) => {
    onChange('borderStyle')(value);
  };

  const handleBorderWidthChange = (value: number | string, e?: Event) => {
    onChange('borderWidth')(value + 'px');
  };

  const handleBorderRadiusChange = (value: number | string, e?: Event) => {
    onChange('borderRadius')(value + 'px');
  };

  const handleBoxShadowChange = (value: string) => {
    onChange('boxShadow')(value);
  };

  const handleOpacityChange = (value: number) => {
    if (value <= 100 && value >= 0) {
      const color = colorToRGBA(backgroundColor);
      const updatedRGBA = color.replace(/[\d\.]+\)/, `${value / 100})`);
      onChange('backgroundColor')(updatedRGBA);
    }
  };

  const [isOpen, setIsOpen] = useState(undefined);

  return (
    <Tray
      renderIcon={(trayIsOpen: boolean) => (
        <IconBackgroundColor
          style={{
            transition: 'transform 0.3s',
            transform: trayIsOpen ? 'rotate(30deg)' : 'rotate(0deg)',
          }}
          active={
            !!(backgroundColor || borderWidth || borderRadius || (boxShadow !== 'none' && boxShadow !== undefined))
          }
        />
      )}
      adjustSizeToTray={true}
      trayLabel="Styles"
      startOpen={startOpen}
      renderTray={() => (
        <>
          <Group className={styles.Fill}>
            <Label group={true}>Fill</Label>
            <ColorPickerNew
              color={backgroundColor}
              onChange={handleColorChange}
              handleOpacityChange={handleOpacityChange}
              opacity={backgroundOpacityWithDefault}
              type="fill"
              isOpen={isOpen}
              setIsOpen={setIsOpen}
            />
          </Group>
          <Group className={styles.Border}>
            <Label group={true}>Border</Label>
            <BorderInput
              borderColor={borderColor}
              borderWidth={
                !borderWidth ? 0 : typeof borderWidth === 'number' ? borderWidth : Number(borderWidth.split('px')[0])
              }
              borderRadius={
                !borderRadius
                  ? 0
                  : typeof borderRadius === 'number'
                  ? borderRadius
                  : Number(borderRadius.split('px')[0])
              }
              borderStyle={borderStyle}
              handleBorderColorChange={handleBorderColorChange}
              handleBorderWidthChange={handleBorderWidthChange}
              handleBorderRadiusChange={handleBorderRadiusChange}
              handleBorderStyleChange={handleBorderStyleChange}
              isOpen={isOpen}
              setIsOpen={setIsOpen}
            />
          </Group>
          <Group className={styles.Shadow}>
            <Label group={true}>Shadow</Label>
            <ShadowInput
              boxShadow={boxShadow}
              handleBoxShadowChange={handleBoxShadowChange}
              type="shadow"
              isOpen={isOpen}
              setIsOpen={setIsOpen}
            />
          </Group>
        </>
      )}
    />
  );
}
