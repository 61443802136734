import { assign, del, get, insert, merge, push, set, update, wrap } from 'object-path-immutable';

const VALID_OPERATIONS = {
  set: set,
  update: update,
  push: push,
  del: del,
  assign: assign,
  insert: insert,
  merge: merge,
};

function isValidOperation(type: string) {
  return VALID_OPERATIONS.hasOwnProperty(type);
}

export default function applyOp(src: string, op: any) {
  const { type, path, value, position } = op;

  if (isValidOperation(type)) {
    // @ts-ignore
    const operator = VALID_OPERATIONS[type];
    return operator(src, path, value, position);
  } else {
    return src;
  }
}
