import * as types from './actionTypes';

/**
 * Opens the domain manager
 * @param  {object} args All arguments
 * @param  {string} [args.requestedAction] the requested view/action
 * @return {object} The redux action.
 */
export function open(args = {}) {
  return {
    type: types.DOMAIN_SETTINGS_OPEN,
    payload: { ...args },
  };
}

/**
 * Closes the domain manager.
 * @return {object} The redux action.
 */
export function close() {
  return {
    type: types.DOMAIN_SETTINGS_CLOSE,
  };
}
