import {
  select,
  actionChannel,
  fork,
  call,
  put,
  putResolve,
  all,
  race,
  throttle,
  take,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import * as api from '../services/spark-api';
import { getResources } from 'redux-resource';
import * as types from '../actions/actionTypes';
import * as accountActions from '../actions/accountActions';
import * as mediaActions from '../actions/mediaActions';
import * as contentTestActions from '../actions/contentTestActions';
import * as blogActions from '../actions/blogActions';
import * as uiActions from '../actions/uiActions';
import * as blogSelectors from '../reducers/blogsReducer';
import requireSiteId from './utils/requireSiteId';
import { normalizeList } from './normalizrs/blog-normalizrs';

import { applyOps, hasOps } from '../lib/immutable-operations/';
import { callSparkApi } from './utils/callSparkApi';

// Create
// ----------------------------------------------------------------------------

function* create(action) {
  try {
    const response = yield call(api.createBlog, { slug: null });

    if (response.ok) {
      yield putResolve(blogActions.receive(response.json));
    } else if (response.unauthorized) {
      yield put(accountActions.logout());
    } else {
      console.error('Failed trying to create blog', response.json);
    }
  } catch (err) {
    yield put(uiActions.connectionError());
    console.error(err);
  }
}

// Fetch list
// ----------------------------------------------------------------------------

function* fetchListIfNeeded(action) {
  const isFetching = yield select(blogSelectors.selectIsFetching);

  if (isFetching == null) {
    yield put(blogActions.requestList(action.payload.siteId));
  }
}

function* fetchList(action) {
  try {
    const siteId = yield call(requireSiteId, action.payload.siteId);

    const response = yield call(api.getBlogs, siteId);
    if (response.status === 200) {
      const normalizedData = normalizeList(response.json.results);
      const { result: blogIds, entities } = normalizedData;
      const receivedAt = new Date();
      yield put(blogActions.receiveList(blogIds, entities, receivedAt));
    } else if (response.unauthorized) {
      yield put(accountActions.logout());
    } else {
      console.error('Failed trying to fetch blogs', response.json);
    }
  } catch (err) {
    yield put(uiActions.connectionError());
    console.error(err);
  }
}

// Request blog
// ----------------------------------------------------------------------------

function* requestIfNeeded(action) {
  const { blogId } = action.payload;

  const blog = yield select(blogSelectors.selectBlog, blogId);

  if (!blog || !blog.content) yield put(blogActions.request(blogId));
}

function* request(action) {
  const { blogId } = action.payload;

  try {
    const response = yield call(api.getBlog, blogId);

    if (response.status === 200) {
      const { media = [], experiments = [] } = response.json;

      if (media.length) yield put(mediaActions.receiveArray(media));

      if (experiments.length) {
        yield put(contentTestActions.receiveArray(experiments));
      }
      yield put(blogActions.receive(response.json));
    } else if (response.unauthorized) {
      yield put(accountActions.logout());
    } else {
      console.error('Failed trying to fetch blogs', response.json);
    }
  } catch (err) {
    yield put(uiActions.connectionError());
    console.error(err);
  }
}

// Update
// ----------------------------------------------------------------------------

function* watchUpdates() {
  const chan = yield actionChannel(types.BLOG_REQUEST_UPDATE);

  while (true) {
    const action = yield take(chan);
    yield call(update, action);
  }
}

function* update(action) {
  const { blogId, ops } = action.payload;

  const blog = yield select(blogSelectors.selectBlog, blogId);

  if (blog == null || ops == null) return;

  yield put(blogActions.willRequestUpdate(blogId, action));
  const payload = applyOps(blog, ops);

  try {
    const response = yield call(api.putBlog, payload);

    if (response.status === 200) {
      yield put(blogActions.receive(response.json));
      yield put(blogActions.receiveUpdateSuccess(blogId));
    } else if (response.status >= 400 && response.status < 500) {
      yield put(blogActions.receiveUpdateError(blogId, response.json));
    } else if (response.unauthorized) {
      yield put(accountActions.logout());
    } else {
      console.error('Failed trying to update blog', response.json);
    }
  } catch (err) {
    yield put(uiActions.connectionError());
    console.error(err);
  }
}

// Autosave
// ----------------------------------------------------------------------------

function* initiateAutosaves(action) {
  // select all existing autosaveOps
  const autosaveOpsById = yield select(blogSelectors.selectAutosaveOpsById);

  // start an autosave for each blog with autosaveOps
  const autosaves = [];
  Object.entries(autosaveOpsById).forEach(([blogId, ops]) => {
    if (hasOps(ops)) autosaves.push(call(autosave, blogId));
  });
  yield all(autosaves);
}

function* autosave(blogId) {
  // select ops
  const autosaveOpsById = yield select(blogSelectors.selectAutosaveOpsById);
  const ops = autosaveOpsById[blogId];

  // update blog
  const requestAction = yield put(blogActions.requestUpdate(blogId, ops));

  // watch for update to start
  while (true) {
    const action = yield take(types.BLOG_WILL_REQUEST_UPDATE);
    const { initiatorAction } = action.payload;
    if (requestAction === initiatorAction) break;
  }

  // - on success, remove ops from state since they're persisted.
  yield take(types.BLOG_RECEIVE_UPDATE_SUCCESS);
  yield put(blogActions.removeAutosaveOps(blogId, ops));

  // - on failure, do nothing(?)
}

// Publish
// ----------------------------------------------------------------------------

function* publish(action) {
  const { blogId } = action.payload;
  const blog = yield select(blogSelectors.selectBlog, blogId);

  // If blog is currently updating, wait for update to finish
  if (blog && blog.isFetching) {
    const { fail } = yield race({
      success: take(types.BLOG_RECEIVE_UPDATE_SUCCESS),
      fail: take(types.BLOG_RECEIVE_UPDATE_ERROR),
    });

    // if the update fails, cancel this task
    if (fail) return false;
  }

  // Publish blog
  try {
    const response = yield call(api.publishBlog, blogId);

    if (response.status === 200) {
      yield put(blogActions.receive(response.json));
      yield put(blogActions.receivePublishSuccess(blogId));
    } else if (response.status >= 400 && response.status < 500) {
      yield put(blogActions.receivePublishError(blogId, response.json));
    } else if (response.unauthorized) {
      yield put(accountActions.logout());
    } else {
      console.error('Failed trying to publish blog', response.json);
    }
  } catch (err) {
    yield put(uiActions.connectionError());
    console.error(err);
  }
}

// Discard changes
// ----------------------------------------------------------------------------

function* discard(action) {
  const { blogId } = action.payload;

  try {
    const response = yield api.discardBlog(blogId);

    if (response.ok) {
      // yield blogActions.receive(response.json);
      window.location.reload();
    } else {
      yield console.error('Discard-blog API request failed', response);
    }
  } catch (err) {
    yield put(uiActions.connectionError());
  }
}

// Delete
// ----------------------------------------------------------------------------

function* deleteBlog(action) {
  try {
    const { blogId } = action.payload;
    yield call(api.deleteBlog, blogId);
  } catch (err) {
    console.error(err);
  }
}

// Blog footer element sagas
// ----------------------------------------------------------------------------

function* createFooterElement(action) {
  const siteId = yield call(requireSiteId, action.payload.siteId);
  const { payload } = action;
  try {
    const response = yield call(api.createSection, {
      site: siteId,
      item: payload.item || payload.pageId,
      position: 1,
      component: payload.component,
      element: payload.element,
      location: 'main',
    });

    if (response.ok && action.callback) {
      action.callback(response.json);
    }
  } catch (e) {
    console.error(e);
  }
}

function* removeFooter(action) {
  const { payload } = action;
  try {
    yield call(api.deleteSection, payload);
  } catch (e) {
    console.error(e);
  }
}

function* initializeContainer(action) {
  const siteId = yield call(requireSiteId, action.payload.siteId);
  const { currentSection: originalSection, type } = action.payload;

  const components = yield select((state) => getResources(state.sectionTypes, (component) => component.slug === type));

  const component = components && components.length && components[0];

  const response = yield call(api.createSection, {
    site: siteId,
    item: originalSection.item || originalSection.pageId,
    component: component.id,
    component_type_id: component.component_type,
    location: originalSection.location || 'main',
  });

  if (response.ok && action.callback) {
    const container = response.json;
    container.children = [originalSection];
    action.callback(container);
  }
}

function* initializeABTestContainer(action) {
  const siteId = yield call(requireSiteId, action.payload.siteId);
  const { currentSection: originalSection, type } = action.payload;

  const components = yield select((state) => getResources(state.sectionTypes, (component) => component.slug === type));

  const component = components && components.length && components[0];

  const control = originalSection;

  // Create variant and container sections
  const [receivedVariant, receivedContainer] = yield all([
    call(api.createSection, {
      site: siteId,
      item: originalSection.item || originalSection.pageId,
      component: originalSection.component,
    }),
    call(api.createSection, {
      site: siteId,
      item: originalSection.item || originalSection.pageId,
      component: component.id,
      component_type_id: component.component_type,
    }),
  ]);
  const variant = receivedVariant.json;
  const container = receivedContainer.json;
  container.children = [control, variant];
  action.callback(container);
}

function* startExperiment(action) {
  // Get data
  const { sectionId, data, variants, blogId } = action.payload;
  const { selectedGoal, name, weight0, weight1, confidence } = data;

  const siteId = yield call(requireSiteId);

  // Build a new ContentTest object
  const contentTest = {
    section_id: sectionId,
    item: blogId,
    site: siteId,
    name: name,
    variants: [
      {
        name: 'Control',
        weight: weight0 || 0,
        id: variants[0].id,
      },
      {
        name: 'Variant',
        weight: weight1 || 0,
        id: variants[1].id,
      },
    ],
    pvalue: (1000 - (confidence || 0) * 10) / 1000,
    goal: selectedGoal,
  };

  const response = yield call(callSparkApi, api.createContentTest, {
    ...contentTest,
    site: siteId,
  });

  if (response.ok) {
    yield put(contentTestActions.receiveCreateSuccess(response.json));

    const startTestResponse = yield call(callSparkApi, api.startContentTest, response.json.id);

    contentTestActions.receiveStartSuccess(response.json.id, startTestResponse.json);
    yield put(blogActions.addContentTest(blogId, startTestResponse.json));
    action.callback(response.ok, []);
  } else {
    action.callback(response.ok, response.json);
  }
}

function* terminateExperiment(action) {
  const { contentTest } = action.payload;

  const response = yield call(callSparkApi, api.endContentTest, contentTest.id);
  yield put(blogActions.updateContentTest(contentTest.item, response.json));
}

// Root saga
// ----------------------------------------------------------------------------

function* blogsSaga() {
  yield all([
    takeLatest(types.BLOG_REQUEST_CREATE, create),
    takeLatest(types.BLOGS_REQUEST_LIST, fetchList),
    takeLatest(types.BLOGS_REQUEST_LIST_IF_NEEDED, fetchListIfNeeded),
    takeLatest(types.BLOG_REQUEST_IF_NEEDED, requestIfNeeded),
    takeEvery(types.BLOG_REQUEST, request),
    fork(watchUpdates),
    throttle(5000, types.BLOG_ADD_AUTOSAVE_OPS, initiateAutosaves),
    takeLatest(types.BLOG_REQUEST_PUBLISH, publish),
    takeEvery(types.BLOG_REQUEST_DISCARD, discard),
    takeEvery(types.BLOG_REQUEST_DELETE, deleteBlog),
    takeLatest(types.BLOG_REQEST_CREATE_FOOTER_ELEMENT, createFooterElement),
    takeLatest(types.BLOG_REQEST_REMOVE_FOOTER_ELEMENT, removeFooter),
    takeLatest(types.BLOG_REQEST_INITIALIZE_FOOTER_CONTAINER, initializeContainer),
    takeLatest(types.BLOG_FOOTER_INITIALIZE_ABTEST_CONTAINER, initializeABTestContainer),
    takeLatest(types.BLOG_FOOTER_START_AB_TEST, startExperiment),
    takeLatest(types.BLOG_FOOTER_END_AB_TEST, terminateExperiment),
  ]);
}

export default blogsSaga;
