import { compose } from 'redux';
import withUrlSite from './withUrlSite';
import { connect } from 'react-redux';
import { selectSiteByDomain } from '../reducers/sitesReducer';

export default (mapSiteToProps) => (WrappedComponent) => {
  function mapStateToProps(state, ownProps) {
    const { urlSite } = ownProps;
    const site = selectSiteByDomain(state, urlSite) || ownProps.site || {};
    return mapSiteToProps(site);
  }

  return compose(withUrlSite, connect(mapStateToProps))(WrappedComponent);
};
