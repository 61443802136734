import React from 'react';
// import { connect } from 'react-redux';

import styles from './HeaderFooterEditor.module.scss';

import HeaderEditor from './HeaderFooterEditor/HeaderEditor';
import FooterEditor from './HeaderFooterEditor/FooterEditor';

class HeaderFooterEditor extends React.PureComponent {
  render() {
    const { editableLogo } = this.props;

    return (
      <article className={styles.HeaderFooterEditor}>
        <div className="eps sriracha">
          <HeaderEditor site={this.props.site} siteUpdaters={this.props.siteUpdaters} editableLogo={editableLogo} />
          <div className={styles.pageContent} />
          <FooterEditor site={this.props.site} siteUpdaters={this.props.siteUpdaters} editableLogo={editableLogo} />
        </div>
      </article>
    );
  }
}

export default HeaderFooterEditor;
