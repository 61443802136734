import { call, put, select, all, takeEvery, takeLatest } from 'redux-saga/effects';
import * as api from '../services/spark-api';
import * as actionTypes from '../actions/actionTypes';
import * as contactsActions from '../actions/contactsActions';
import requireSiteId from './utils/requireSiteId';

function* getContactsCount(action) {
  const siteId = yield call(requireSiteId, action.payload.siteId);

  const params = {
    asyncFetch: () => {
      return api.getContactsCountSince(siteId);
    },
    callback: (response, stats) => {
      if (response.ok) {
        const { contacts, count } = response.json;
        if ('Notification' in window && window.Notification.permission === 'granted' && count) {
          const body = count === 1 ? `${contacts[0].email}` : `You have ${count} new customers`;
          new window.Notification('New customer added', {
            body,
            icon: require('../assets/images/unstack-profile-inverse@2x.png'),
          });
        }
      }
      return true;
    },
    delay: 600,
    retryOnFailure: true,
    retryAfter: 5,
    stopAfterRetries: 2,
  };

  yield put({
    type: 'CONTACTS_COUNT_START_POLLING',
    payload: { params, siteId },
  });

  try {
    const response = yield call(api.getContactsCount, siteId);
    yield put({
      type: actionTypes.CONTACTS_COUNT_RECEIVE,
      payload: response.json.count,
    });
  } catch (err) {
    console.error(err);
  }
}

function* deleteContact(action) {
  const { id, resolve, reject } = action;
  try {
    const response = yield call(api.deleteContact, id);
    if (response.ok) {
      yield put(contactsActions.getContactsCount());
      resolve();
    }
  } catch (err) {
    console.error(err);
    reject();
  }
}

function* fetchSearchResults(action) {
  const { payload } = action;
  const siteId = yield call(requireSiteId);

  try {
    const response = yield call(api.getSearchedContacts, siteId, payload);
    if (response.ok) {
      yield put(contactsActions.saveSearchedResults(response.json.results));
    }
  } catch (e) {
    console.error(e);
  }
}

function* createOrUpdateContact(action) {
  const { payload, resolve, reject } = action;
  try {
    const response = payload.id
      ? yield call(api.updateContact, payload.id, payload)
      : yield call(api.createContact, payload);
    if (response.ok) {
      yield put(contactsActions.getContactsCount());
      resolve();
    } else {
      reject(response.json);
    }
  } catch (err) {
    reject(err);
  }
}

// Root saga
// ----------------------------------------------------------------------------

function* contactsSaga() {
  yield all([
    takeEvery(actionTypes.CONTACTS_FETCH_COUNT, getContactsCount),
    takeLatest(actionTypes.CONTACTS_DELETE, deleteContact),
    takeLatest(actionTypes.CONTACTS_FETCH_SEARCH_RESULTS, fetchSearchResults),
    takeEvery(actionTypes.CONTACTS_ADD_OR_UPDATE_CONTACT, createOrUpdateContact),
  ]);
}

export default contactsSaga;
