import {
  select,
  actionChannel,
  fork,
  call,
  put,
  all,
  race,
  throttle,
  take,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import * as api from '../services/spark-api';
import * as types from '../actions/actionTypes';
import * as accountActions from '../actions/accountActions';
import * as articleActions from '../actions/articleActions';
import * as tagActions from '../actions/tagActions';
import * as mediaActions from '../actions/mediaActions';
import * as contentTestActions from '../actions/contentTestActions';
import * as uiActions from '../actions/uiActions';
import * as articleSelectors from '../reducers/articlesReducer';
import requireSiteId from './utils/requireSiteId';
import { normalizeList } from './normalizrs/article-normalizrs';

import { applyOps, hasOps } from '../lib/immutable-operations/';

import omit from 'lodash/omit';
import isEqual from 'lodash/isEqual';

// Fetch list
// ----------------------------------------------------------------------------

// function* fetchListIfNeeded(action) {
//   const isFetching = yield select(articleSelectors.selectIsFetchingAll);

//   if (isFetching == null) {
//     yield put(articleActions.requestList());
//   }
// }

// function* fetchList(action) {
//   try {
//     const response = yield call(api.getArticles);

//     // Responses are paginated. If there are more pages, fetch those too.
//     if (response.ok && response.json.next) {
//       let nextResponse = response;
//       do {
//         nextResponse = yield call(
//           api.fetchAuthorizedJSON,
//           nextResponse.json.next
//         );
//         if (nextResponse.ok)
//           response.json.results = response.json.results.concat(
//             nextResponse.json.results
//           );
//       } while (nextResponse.ok && nextResponse.json.next);
//     }

//     if (response.status === 200) {
//       const normalizedData = normalizeList(response.json.results);
//       const { result: articleIds, entities } = normalizedData;
//       yield put(articleActions.receiveList(articleIds, entities));
//     } else if (response.unauthorized) {
//       yield put(accountActions.logout());
//     } else {
//       console.error('Failed trying to fetch articles', response.json);
//     }
//   } catch (err) {
//     yield put(uiActions.connectionError());
//     console.error(err);
//   }
// }

// function* fetchPaginatedList(action) {
//   const { payload } = action;

//   try {
//     const response = yield yield call(api.getPaginatedArticles, payload);
//     if (response.ok) {
//       const normalizedData = normalizeList(response.json.results);
//       const { result: articleIds, entities } = normalizedData;
//       const { articles = [] } = entities;
//       const featuredMedias = Object.values(articles).reduce(
//         (accumulator, article) => {
//           article.featured_media &&
//             article.featured_media.id &&
//             accumulator.push(article.featured_media);
//           return accumulator;
//         },
//         []
//       );
//       yield put(mediaActions.receiveArray(featuredMedias));
//       yield put(
//         articleActions.receiveList(articleIds, entities, response.json.count)
//       );
//     }
//   } catch (e) {
//     console.log(e);
//   }
// }

// Request article
// ----------------------------------------------------------------------------

function* request(action) {
  try {
    const { articleId } = action.payload;
    const response = yield call(api.getArticle, articleId);

    if (response.status === 200) {
      yield processAndReceiveArticleResponse(response.json);
    } else if (response.unauthorized) {
      yield put(accountActions.logout());
    } else {
      console.error('Failed trying to fetch articles', response.json);
    }
  } catch (err) {
    yield put(uiActions.connectionError());
    console.error(err);
  }
}

function* processAndReceiveArticleResponse(actionOrResponseBody) {
  const responseBody =
    actionOrResponseBody.type === types.ARTICLE_PROCESS_AND_RECEIVE_RAW_RESPONSE
      ? actionOrResponseBody.payload.responseBody
      : actionOrResponseBody;

  const { media = [], experiments = [], ...article } = responseBody;

  const receivedMedia = [...media, ...(article.featured_media ? [article.featured_media] : [])];

  if (receivedMedia.length) yield put(mediaActions.receiveArray(receivedMedia));
  if (experiments) yield put(contentTestActions.receiveArray(experiments));

  const receivedArticle = omit(responseBody, ['media', 'experiments']);
  yield put(articleActions.receive(receivedArticle));
}

// Update
// ----------------------------------------------------------------------------

function* watchUpdates() {
  const chan = yield actionChannel(types.ARTICLE_REQUEST_UPDATE);

  while (true) {
    const action = yield take(chan);
    yield call(update, action);
  }
}

function* update(action) {
  const { articleId, ops } = action.payload;

  const article = yield select(articleSelectors.selectArticle, articleId);
  if (article == null || ops == null) return;

  yield put(articleActions.willRequestUpdate(articleId, action));
  try {
    let payload = applyOps(article, ops);
    if (payload.meta.image === null) {
      payload = {
        ...payload,
        featured_media: null,
      };
    }
    const response = yield call(api.putArticle, payload);

    if (response.status === 200) {
      yield put(articleActions.receive(response.json));
      yield put(articleActions.receiveUpdateSuccess(articleId));
      if (!isEqual(payload.tags, article.tags)) yield put(tagActions.fetchAll());
    } else if (response.status >= 400 && response.status < 500) {
      yield put(articleActions.receiveUpdateError(articleId, response.json));
    } else if (response.unauthorized) {
      yield put(accountActions.logout());
    } else {
      console.error('Failed trying to update article', response.json);
    }
  } catch (err) {
    yield put(uiActions.connectionError());
    console.error(err);
  }
}

// Autosave
// ----------------------------------------------------------------------------

function* initiateAutosaves(action) {
  // select all existing autosaveOps
  const autosaveOpsById = yield select(articleSelectors.selectAutosaveOpsById);

  // start an autosave for each article with autosaveOps
  const autosaves = [];
  Object.entries(autosaveOpsById).forEach(([articleId, ops]) => {
    if (hasOps(ops)) autosaves.push(call(autosave, articleId));
  });
  yield all(autosaves);
}

function* autosave(articleId) {
  // select ops
  const autosaveOpsById = yield select(articleSelectors.selectAutosaveOpsById);
  const ops = autosaveOpsById[articleId];

  // update article
  const requestAction = yield put(articleActions.requestUpdate(articleId, ops));

  // watch for update to start
  while (true) {
    const action = yield take(types.ARTICLE_WILL_REQUEST_UPDATE);
    const { initiatorAction } = action.payload;
    if (requestAction === initiatorAction) break;
  }

  // - on success, remove ops from state since they're persisted.
  yield take(types.ARTICLE_RECEIVE_UPDATE_SUCCESS);
  yield put(articleActions.removeAutosaveOps(articleId, ops));

  // - on failure, do nothing(?)
}

// Publish
// ----------------------------------------------------------------------------

function* publish(action) {
  const { articleId, isSchedule } = action.payload;
  const article = yield select(articleSelectors.selectArticle, articleId);
  const articleHasUnsavedChanges = yield select(articleSelectors.selectHasUnsavedChanges, articleId);

  if (article == null) return false;

  // If article has unsaved changes, force autosave
  if (articleHasUnsavedChanges) {
    yield autosave(articleId);
  }

  // If article is currently updating, wait for update to finish
  if (article.isUpdating) {
    const { fail } = yield race({
      success: take(types.ARTICLE_RECEIVE_UPDATE_SUCCESS),
      fail: take(types.ARTICLE_RECEIVE_UPDATE_ERROR),
    });

    // if the update fails, cancel this task
    if (fail) return false;
  }

  // Publish article
  try {
    const response = yield call(api.publishArticle, articleId, isSchedule);

    if (response.status === 200) {
      yield put(articleActions.receive(response.json));
      yield put(articleActions.receivePublishSuccess(articleId));
    } else if (response.status >= 400 && response.status < 500) {
      yield put(articleActions.receivePublishError(articleId, response.json));
    } else if (response.unauthorized) {
      yield put(accountActions.logout());
    } else {
      console.error('Failed trying to publish article', response.json);
    }
  } catch (err) {
    yield put(uiActions.connectionError());
    console.error(err);
  }
}

// Unschedule
// ----------------------------------------------------------------------------
function* Unschedule(action) {
  const { articleId } = action.payload;

  // If article has unsaved changes, force autosave
  const articleHasUnsavedChanges = yield select(articleSelectors.selectHasUnsavedChanges, articleId);
  if (articleHasUnsavedChanges) {
    yield autosave(articleId);
  }

  // Unschedule
  try {
    const response = yield call(api.unscheduleArticle, articleId);

    if (response.ok) {
      yield put(articleActions.receiveUnscheduleSuccess(articleId));
      yield put(articleActions.receive(response.json));
    } else if (response.unauthorized) {
      yield put(accountActions.logout());
    } else {
      console.error('Failed trying to unschedule article', response.json);
    }
  } catch (err) {
    yield put(uiActions.connectionError());
    console.error(err);
  }
}

// Unpublish
// ----------------------------------------------------------------------------

function* unpublish(action) {
  const { articleId } = action.payload;

  // If article has unsaved changes, force autosave
  const articleHasUnsavedChanges = yield select(articleSelectors.selectHasUnsavedChanges, articleId);
  if (articleHasUnsavedChanges) {
    yield autosave(articleId);
  }

  // Unpublish
  try {
    const response = yield call(api.unpublishArticle, articleId);

    if (response.ok) {
      yield put(articleActions.receiveUnpublishSuccess(articleId));
      yield put(articleActions.receive(response.json));
    } else if (response.unauthorized) {
      yield put(accountActions.logout());
    } else {
      console.error('Failed trying to unpublish article', response.json);
    }
  } catch (err) {
    yield put(uiActions.connectionError());
    console.error(err);
  }
}

// Delete
// ----------------------------------------------------------------------------

function* deleteArticle(action) {
  try {
    const { articleId } = action.payload;
    yield call(api.deleteArticle, articleId);
  } catch (err) {
    console.error(err);
  }
}

// Discard changes
// ----------------------------------------------------------------------------

function* discard(action) {
  const { articleId } = action.payload;

  try {
    const response = yield api.discardArticle(articleId);

    if (response.ok) {
      // yield put(articleActions.receive(response.json));
      window.location.reload();
    } else {
      yield console.error('Discard-article API request failed', response);
    }
  } catch (err) {
    yield put(uiActions.connectionError());
  }
}

function* recoverArticle(action) {
  const { articleId, onSuccess } = action.payload;

  try {
    const response = yield call(api.undeleteArticle, articleId);
    if (response.ok) {
      yield put(articleActions.receiveUndeleteSuccess());
      if (onSuccess) onSuccess(response.json.id);
    }
  } catch (e) {
    console.error(e);
  }
}

// Root saga
// ----------------------------------------------------------------------------

function* articlesSaga() {
  yield all([
    // takeLatest(types.ARTICLES_REQUEST_LIST, fetchList),
    // takeLatest(types.ARTICLES_REQUEST_LIST_IF_NEEDED, fetchListIfNeeded),
    takeEvery(types.ARTICLE_REQUEST, request),
    fork(watchUpdates),
    throttle(5000, types.ARTICLE_ADD_AUTOSAVE_OPS, initiateAutosaves),
    takeLatest(types.ARTICLE_REQUEST_PUBLISH, publish),
    takeLatest(types.ARTICLE_REQUEST_UNPUBLISH, unpublish),
    takeLatest(types.ARTICLE_REQUEST_UNSCHEDULE, Unschedule),
    takeEvery(types.ARTICLE_REQUEST_DISCARD, discard),
    takeEvery(types.ARTICLE_REQUEST_DELETE, deleteArticle),
    takeEvery(types.ARTICLE_REQUEST_UNDELETE, recoverArticle),
    // takeLatest(types.ARTICLES_RECEIVE_LIST_BY_PAGE, fetchPaginatedList),
    takeEvery(types.ARTICLE_PROCESS_AND_RECEIVE_RAW_RESPONSE, processAndReceiveArticleResponse),
  ]);
}

export default articlesSaga;
