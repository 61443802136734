import * as types from './actionTypes';

export const requestThemes = () => ({
  type: types.THEMES_REQUEST_LIST,
});

export const receivedThemes = (payload) => ({
  type: types.THEMES_RECEIVED_LIST,
  payload,
});
