interface ShippingOption {
  module_id: string;
  name: string;
  description?: string;
  price: string;
  subOptions?: { title: string; price?: string; description: string; id: string }[];
}

function getShippingOptionsData() {
  const options: ShippingOption[] = [
    {
      module_id: 'FedEx-ground',
      name: 'FedEx Ground',
      price: 'Free',
      description: 'Shipped next business day.',
    },
    {
      module_id: 'FedEx-3day',
      name: 'FedEx 3 Day',
      price: '$30.00',
      description: 'Ships guaranteed in three days.',
    },
  ];
  return { options };
}

export function getTemplateData(props: any) {
  if (props['data-shipping-options'] !== undefined) {
    return getShippingOptionsData();
  }
  return null;
}
