import * as types from './actionTypes';

export const requestIfNeeded = () => ({
  type: types.CURRENCY_REQUEST_IF_NEEDED,
});

export const request = () => ({
  type: types.CURRENCY_REQUEST_ALL,
});

export const requestSuccess = (payload) => ({
  type: types.CURRENCY_REQUEST_ALL_SUCCESS,
  payload,
});
