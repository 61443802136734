import React, { memo, useMemo } from 'react';
import { Button } from '../Toolbar2';
import { IconAlignMiddle, IconAlignTop, IconAlignBottom } from '../icons';
import { VertAlignmentOptions } from 'types/USection';

interface VerticalAlignButtonProps {
  value: VertAlignmentOptions;
  onChange: (newValue: VertAlignmentOptions) => void;
}

type ConfigMap = {
  [key in VertAlignmentOptions]: { Icon: React.FunctionComponent; tooltip: string; next: VertAlignmentOptions };
};

const VerticalAlignButton = (props: VerticalAlignButtonProps) => {
  const { value = 'middle', onChange } = props;

  const configMap: ConfigMap = useMemo(
    () => ({
      top: {
        Icon: IconAlignTop as React.FunctionComponent,
        tooltip: 'Vertical Align: Top',
        next: 'middle',
      },
      middle: {
        Icon: IconAlignMiddle as React.FunctionComponent,
        tooltip: 'Vertical Align: Middle',
        next: 'bottom',
      },
      bottom: {
        Icon: IconAlignBottom as React.FunctionComponent,
        tooltip: 'Vertical Align: Bottom',
        next: 'top',
      },
    }),
    []
  );

  const config = configMap[value];

  return (
    <Button data-tip={config.tooltip} onClick={() => onChange(config.next)}>
      <config.Icon />
    </Button>
  );
};

export default memo(VerticalAlignButton);
