import { call, put, select, all, takeEvery, takeLatest, StrictEffect } from 'redux-saga/effects';
import * as api from '../services/spark-api';
import * as actionTypes from '../actions/actionTypes';
import * as videosActions from '../actions/videosActions';
import * as videoSelectors from '../reducers/videosReducer';
import requireSiteId from './utils/requireSiteId';
import { callSparkApi } from './utils/callSparkApi';
import { normalizeVideosResponse as normalize } from './normalizrs/videos-normalizrs';
import { UVideo } from 'types/UVideo';

function* getVideosIfNeeded(action: videosActions.RequestAllIfNeeded): Generator<StrictEffect, any, boolean> {
  const hasFetched = yield select(videoSelectors.selectHasFetched);
  if (!hasFetched) yield put(videosActions.requestAll());
}

function* getVideos(): Generator<StrictEffect, any, { json: { results: UVideo[] }; ok: boolean }> {
  const response = yield call(callSparkApi, api.getVideos);

  if (response.ok) {
    const { entities: byId, result: allIds } = normalize(response.json.results);
    yield put(videosActions.receiveAll(byId.videos as { [id: string]: UVideo }, allIds));
  } else {
    yield put(videosActions.receiveError(response.json as unknown as string));
  }
}

function* videosSaga() {
  yield all([
    takeEvery(actionTypes.VIDEOS_REQUEST_ALL_IF_NEEDED, getVideosIfNeeded),
    takeLatest(actionTypes.VIDEOS_REQUEST_ALL, getVideos),
  ]);
}

export default videosSaga;
