import React, { MouseEvent, PropsWithChildren } from 'react';
import classnames from 'classnames';

import { ReactComponent as CaretUpIcon } from 'assets/images/caret-up-icon.svg';
import { ReactComponent as IncompleteCheckIcon } from 'assets/images/incomplete-check-icon.svg';
import { ReactComponent as CompleteCheckIcon } from 'assets/images/complete-check-icon.svg';
import styles from './ExpansionPanel.module.scss';

interface Props {
  title?: string;
  isComplete: boolean;
  onClick?: () => void;
  onIconClick?: () => void;
  open?: boolean;
  isAlert?: boolean;
}

const noop = () => {};

export default function ExpansionPanel({
  children,
  isComplete,
  title,
  onClick = noop,
  onIconClick = noop,
  open,
  isAlert,
}: PropsWithChildren<Props>) {
  return (
    <div
      className={classnames(styles.expansionPanelContainer, {
        [styles.isAlert]: isAlert,
      })}
      data-test-id="quickstart-expansion-panel"
    >
      <div className={styles.expansionPanelTitleContainer} onClick={onClick}>
        <span className={styles.expansionPanelTitle}>
          {isComplete ? (
            <CompleteCheckIcon
              data-test-id="marked-as-completed"
              className={styles.completeCheckIcon}
              onClick={(e: MouseEvent) => {
                e.stopPropagation();
                onIconClick();
              }}
            />
          ) : null}
          <IncompleteCheckIcon
            data-test-id="toggle"
            onClick={(e: MouseEvent) => {
              e.stopPropagation();
              onIconClick();
            }}
          />
          <span
            className={`${styles.title} ${isComplete ? styles.completedTitle : ''}`}
            data-test-id="expansion-panel-title"
          >
            {title}
          </span>
        </span>
        <CaretUpIcon
          className={open ? styles.caretOpen : styles.caretClose}
          data-test-id="panel-expand-collapsed-icon"
        />
      </div>
      <div
        className={`${styles.expansionPanelContent} ${open ? '' : styles.collapsed}`}
        data-test-id={open ? 'panel-expanded' : 'panel-collapsed'}
      >
        {children}
      </div>
    </div>
  );
}
