import { createSelector } from 'reselect';
import { selectActiveSite } from './sitesReducer';
import { selectAll as selectAllPages } from './allContentReducer';

import uniq from 'lodash/uniq';
import compact from 'lodash/compact';
import forOwn from 'lodash/forOwn';

const colorKeyRegexp = /color$/i;

export const selectColors = createSelector(selectActiveSite, selectAllPages, (site, pages) => {
  let colors = [];

  // Scrape site for colors
  const siteStyles = site.styles || {};
  forOwn(siteStyles, (value, key) => {
    if (colorKeyRegexp.test(key)) colors.push(value);
  });

  // Scrape all pages for colors
  // console.log(pages);
  pages.forEach((page) => {
    const sections = page.content.sections || [];
    return sections.forEach((section) => {
      colors.push(section.backgroundColor);
    });
  });

  // Prune list of colors so it is unique and compact.
  colors = uniq(colors);
  colors = compact(colors);
  // colors = orderBy(colors);

  return colors;
});

export const selectBackgroundColors = createSelector(selectAllPages, (pages) => {
  let colors = [];

  // Scrape all pages for colors
  // console.log(pages);
  pages.forEach((page) => {
    const sections = page.content.sections || [];
    return sections.forEach((section) => {
      colors.push(section.backgroundColor);
    });
  });

  // Prune list of colors so it is unique and compact.
  colors = uniq(colors);
  colors = compact(colors);
  // colors = orderBy(colors);

  return colors;
});

export const selectStylesColors = createSelector(selectActiveSite, (site) => [
  ...site.styles.defaultColors,
  ...site.styles.colors.sort((a, _b) => (a.label ? -1 : 1)),
]);
