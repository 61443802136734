import { select, put, call, all, race, take, takeLatest } from 'redux-saga/effects';
import * as types from '../actions/actionTypes';
import * as actions from '../actions/uiBlogSettingsActions';
import * as selectors from '../reducers/uiBlogSettingsReducer';
import * as blogActions from '../actions/blogActions';
import { hasOps } from '../lib/immutable-operations';

// Save
// ----------------------------------------------------------------------------

function* requestSave(action) {
  // select blogId and ops
  const blogId = yield select(selectors.selectBlogId);
  const ops = yield select(selectors.selectOps);

  // save
  const { success, fail } = yield call(save, blogId, ops);

  if (success) {
    // on success, close settings drawer
    yield put(blogActions.requestList());
    yield put(actions.close());
  } else {
    // on failure, show errors
    const { errors } = fail.payload;
    yield put(actions.open(blogId, ops, errors));
  }
}

function* save(blogId, ops) {
  // update blog
  const requestAction = yield put(blogActions.requestUpdate(blogId, ops));

  // watch for update to start
  while (true) {
    const action = yield take(types.BLOG_WILL_REQUEST_UPDATE);
    const { initiatorAction } = action.payload;
    if (requestAction === initiatorAction) break;
  }

  // watch for update to finish
  return yield race({
    success: take(types.BLOG_RECEIVE_UPDATE_SUCCESS),
    fail: take(types.BLOG_RECEIVE_UPDATE_ERROR),
  });
}

// Publish
// ----------------------------------------------------------------------------

function* publish(action) {
  // select blogId and ops
  const blogId = yield select(selectors.selectBlogId);
  const ops = yield select(selectors.selectOps);

  // if ops, then save (but don't close yet!)
  if (hasOps(ops)) {
    const { fail } = yield call(save, blogId, ops);

    if (fail) {
      // on failure, show errors
      const { errors } = fail.payload;
      yield put(actions.open(blogId, ops, errors));
      return false;
    }
  }

  // if the save succeeds, request publish.
  yield put(blogActions.requestPublish(blogId));

  // close on publish success
  const { success } = yield race({
    success: take(types.BLOG_RECEIVE_PUBLISH_SUCCESS),
    fail: take(types.BLOG_RECEIVE_PUBLISH_ERROR),
  });

  if (success) {
    yield put(actions.close());
  }
}

function* receivePublishError(action) {
  const { blogId, errors } = action.payload;
  yield put(actions.open(blogId, [], errors));
}

function* blogWasDeleted(action) {
  const deletedBlogId = action.payload.blogId;
  const currentProps = yield select(selectors.selectProps);

  if (currentProps.blogId === deletedBlogId) {
    yield put(actions.close());
  }
}

// Root saga
// ----------------------------------------------------------------------------

function* uiBlogsSettingsSaga() {
  yield all([
    takeLatest(types.BLOG_SETTINGS_SAVE, requestSave),
    takeLatest(types.BLOG_SETTINGS_PUBLISH, publish),
    takeLatest(types.BLOG_RECEIVE_PUBLISH_ERROR, receivePublishError),
    takeLatest(types.BLOG_REQUEST_DELETE, blogWasDeleted),
  ]);
}

export default uiBlogsSettingsSaga;
