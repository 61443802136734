import React, { useState } from 'react';
import { CSSTransition } from 'react-transition-group';

import { ReactComponent as IconBlur } from '../../../../assets/images/blur.svg';
import { ReactComponent as IconSolid } from '../../../../assets/images/solid.svg';
import { ReactComponent as IconTransparent } from '../../../../assets/images/transparent.svg';

import styles from './HeaderStyleOptions.module.scss';

const HEADER_STYLES = ['solid', 'transparent', 'blur'];

const SWATCH = {
  solid: <IconSolid />,
  transparent: <IconTransparent />,
  blur: <IconBlur />,
};

export default (props) => {
  const { value, onChange, ...rest } = props;
  const [isOpen, setIsOpen] = useState(false);
  return (
    <span onClick={() => setIsOpen(!isOpen)} className={styles.container} {...rest}>
      <label>Header Style:</label>
      {SWATCH[value]}
      <Drawer
        isOpen={isOpen}
        onChange={(value) => {
          onChange(value);
          setIsOpen(false);
        }}
      />
    </span>
  );
};

function Drawer(props) {
  const { isOpen, onChange } = props;

  return (
    <CSSTransition in={isOpen} timeout={141.59} classNames={styles} mountOnEnter unmountOnExit>
      <div className={styles.Drawer}>
        <ul>
          {HEADER_STYLES.map((style) => (
            <li onClick={() => onChange(style)} key={style} value={style}>
              {SWATCH[style]}
              <span>{style}</span>
            </li>
          ))}
        </ul>
      </div>
    </CSSTransition>
  );
}
