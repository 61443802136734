import { call, select, put, all, takeLatest } from 'redux-saga/effects';
import * as api from '../services/spark-api';
import * as actionTypes from '../actions/actionTypes';
import * as templateTagActions from '../actions/templatetagsActions';
import * as templateTagSelectors from '../reducers/templatetagsReducer';
import { callSparkApi } from './utils/callSparkApi';

function* fetchAllIfNeeded(action) {
  const isFetching = yield select(templateTagSelectors.selectIsFetching);
  if (isFetching == null) yield put(templateTagActions.requestAll());
}

function* fetchAll(action) {
  try {
    const response = yield call(callSparkApi, api.getTemplateTags);
    if (response.ok) {
      const tags = response.json.results;
      yield put(templateTagActions.receiveAll(tags));
    } else {
      console.error('Failed trying to fetch tags', response.json);
    }
  } catch (err) {
    console.error(err);
  }
}

function* templateTagsSaga() {
  yield all([
    takeLatest(actionTypes.TEMPLATE_TAGS_REQUEST_ALL, fetchAll),
    takeLatest(actionTypes.TEMPLATE_TAGS_REQUEST_ALL_IF_NEEDED, fetchAllIfNeeded),
  ]);
}

export default templateTagsSaga;
