import React from 'react';
import { connect } from 'react-redux';

import { ReactComponent as SvgUpload } from '../../../../../assets/images/icon-upload-media.svg';

import * as mediaActions from '../../../../../actions/mediaActions';

import styles from '../MediaDrawerBottom.module.scss';

const CATEGORY_BASED_FILE_TYPES = {
  video: '.mp4',
  pdf: '.pdf',
  image: '.png,.gif,.jpg,.jpeg,.bmp,.x-icon,.svg,.svg+xml,',
  icon: '.png,.gif,.jpg,.jpeg,.bmp,.x-icon,.svg,.svg+xml,',
};

const mapDispatchToProps = {
  createMedia: mediaActions.create,
};

class NativeMediaUploader extends React.PureComponent {
  uploaderRef = React.createRef();

  componentDidUpdate(prevProps) {
    if (prevProps.src !== this.props.src) {
      this.setState({ errorMessage: null });
    }
  }

  handleUploadClick = () => {
    if (this.uploaderRef.current) this.uploaderRef.current.click();
  };

  handleImageChange = (e) => {
    e.preventDefault();

    this.props.updateUploadingStatus(true);

    const file = e.target.files[0];
    let category;
    if (this.props.category === 'favicon') category = 'brand';
    else if (this.props.category === 'background_image') category = 'image';
    else category = this.props.category;
    if (this.props.disabledUploads.some((mime_type) => file.type.includes(mime_type))) {
      this.props.updateUploadingStatus(false, {
        message: 'Error: Please select a JPEG, PNG or GIF',
        invalidJson: true,
      });
      setTimeout(() => this.props.updateUploadingStatus(false), 10000);
    } else {
      const action = this.props.createMedia(file, category);
      action.promise
        .then((media) => {
          const { id } = media;
          this.props.onChange(`media:${id}`);
          this.props.updateUploadingStatus(false);
        })
        .catch((errors) => {
          this.props.updateUploadingStatus(false, errors);
          setTimeout(() => this.props.updateUploadingStatus(false), 10000);
        })
        .finally(() => {
          if (this.uploaderRef.current) {
            this.uploaderRef.current.value = '';
          }
        });
    }
  };

  render() {
    return (
      <fieldset className={styles.MediaUploader} data-test-id="native-uploader-section">
        <SvgUpload onClick={this.handleUploadClick} title={CATEGORY_BASED_FILE_TYPES[this.props.category]} />
        {/* Invisible upload form */}
        <form>
          <input
            data-test-id="hidden-upload"
            className="fileInput"
            ref={this.uploaderRef}
            type="file"
            onChange={this.handleImageChange}
            accept={CATEGORY_BASED_FILE_TYPES[this.props.category]
              .split(',')
              .filter((category) => !this.props.disabledUploads.includes(category.replace('.', '')))}
          />
        </form>
      </fieldset>
    );
  }
}

export default connect(null, mapDispatchToProps)(NativeMediaUploader);
