import React, { useState, useRef, useMemo, useEffect, isValidElement } from 'react';
import classnames from 'classnames';
import omit from 'lodash/omit';
import isNil from 'lodash/isNil';
import { v4 as uuidv4 } from 'uuid';

import FullHeader from 'components/Editor/Layout/FullHeader';
import MiniHeader from 'components/Editor/Layout/MiniHeader';
import AddSectionButton from 'components/Editor/AddSection/AddSectionButton';
import * as pageSelectors from 'reducers/pagesReducer';

import Toolbar from './SectionElement/Toolbar';
import { SectionBox } from './SectionElement/SectionBox';
import BackgroundLayer from './SectionElement/BackgroundLayer';

import { SectionHandlersInterface, SectionUiHandlersInterface } from '../../../types';
import Conditions from '../utilities/Conditions';
import { SectionTagArgs } from 'components/unstack-components/tag-types';

import withSite from 'containers/withSite';
import { USite } from 'types/USite';
import { useDispatch, useSelector } from 'react-redux';
import { getSiteLayoutStyles } from 'reducers/sitesReducer';
import ErrorBoundary from 'components/ErrorBoundary';
import SectionError from './SectionError';
import ReactDOM from 'react-dom';
import { getDevice } from 'reducers/uiReducer';
import { getActiveToolbar } from 'reducers/toolbarReducer';
import { setActiveToolbar } from 'actions/toolbarActions';
import { UBoxInfo, USectionDevices } from 'types/USection';
import { Device, deviceToSize } from '../../util/helpers/deviceHelper';
import moment from 'moment';
import { SectionHeaderBox } from './SectionHeaderBox';
import { isDeviceMode } from 'types/LegacyEditor';
import styles from './SectionElement/Toolbar/Toolbar.module.scss';

interface SectionElementProps extends SectionTagArgs {
  defaults: any;
  children?: React.ReactChildren;
  sectionHandlers?: SectionHandlersInterface;
  sectionUiHandlers?: SectionUiHandlersInterface;
  site: USite;
  onChange: (content: USectionDevices, key?: string, multi?: boolean) => void;
  content: USectionDevices;
  dataRef: UBoxInfo;
}

export function isBoxDeviceActive(isDeviceMode: boolean, isOpen: boolean, device: string, sectionDevice: string) {
  return (
    !isDeviceMode ||
    isOpen ||
    sectionDevice === 'all' ||
    (sectionDevice === 'desktop-only' && device === 'desktop') ||
    (sectionDevice === 'tablet-only' && device === 'tablet') ||
    (sectionDevice === 'mobile-only' && device === 'mobile')
  );
}

const HEADER_MAP: { [key: string]: React.FunctionComponent } = {
  full: FullHeader,
  minimal: MiniHeader,
};

const ATTR_MAP: { [key: string]: string } = {
  class: 'className',
};

let firstValidSectionTime = moment();

const SectionElement: React.FunctionComponent<SectionElementProps> = (props) => {
  let {
    children,
    onChange,
    className,
    properties = [],
    componentId,
    sectionHandlers,
    sectionUiHandlers,
    locations,
    content,
    site,
    dataRef,
    contentKey,
  } = props;
  const enableHeader = props.enableHeader || props.defaults?.enableHeader;
  const siteLayout = useSelector(() => getSiteLayoutStyles(site));
  const ref = useRef<HTMLDivElement>(null);
  const device: Device = useSelector(getDevice);
  const sectionHeaderBoxDeviceVisible =
    !content || content[deviceToSize(device)]?.section?.header?.textBox.visible !== false;

  const htmlAttrForLayout = useMemo(() => {
    return (
      properties.length &&
      properties
        .filter((property) => property.attribute || (dataRef && dataRef[property.name as keyof UBoxInfo]))
        .reduce((acc: any, property: any) => {
          const { suffix = '', prefix = '', name, attribute, type } = property;
          const attr = ATTR_MAP[attribute] || attribute;
          const hasValue = !isNil(dataRef?.properties?.[name]);

          let val = hasValue ? dataRef?.properties[name] : property.default;
          if (val instanceof Array) {
            val = val.join(' ');
          }
          if (acc[attr]) {
            acc[attr].push(`${prefix}${val}${suffix}`);
          } else {
            acc[attr] = hasValue ? [`${prefix}${val}${suffix}`] : [`${prefix}${val}${suffix}`];
          }

          return acc;
        }, {})
    );
  }, [dataRef?.properties, properties]);

  const defaults: UBoxInfo = useMemo(() => {
    return {
      align: 'center',
      maxWidth:
        device === 'desktop' || device === 'tablet'
          ? props.defaultMaxWidth || siteLayout.section.desktop_width || 'auto'
          : props.defaultMaxWidthMobile || siteLayout.section.desktop_width_mobile || 'auto',
      paddingTop:
        device === 'desktop' || device === 'tablet'
          ? props.defaultPaddingTop !== undefined
            ? props.defaultPaddingTop
            : siteLayout.section.desktop_padding[0]
          : siteLayout.section.mobile_padding[0],
      paddingLeft:
        device === 'desktop' || device === 'tablet'
          ? props.defaultPaddingLeft !== undefined
            ? props.defaultPaddingLeft
            : siteLayout.section.desktop_padding[3]
          : siteLayout.section.mobile_padding[3],
      paddingRight:
        device === 'desktop' || device === 'tablet'
          ? props.defaultPaddingRight !== undefined
            ? props.defaultPaddingRight
            : siteLayout.section.desktop_padding[1]
          : siteLayout.section.mobile_padding[1],
      paddingBottom:
        device === 'desktop' || device === 'tablet'
          ? props.defaultPaddingBottom !== undefined
            ? props.defaultPaddingBottom
            : siteLayout.section.desktop_padding[2]
          : siteLayout.section.mobile_padding[2],
      backgroundColor: props.defaultBackgroundColor || props.defaults?.defaultBackgroundColor,
      brightnessTheme: props.defaultTextTheme || 'light-bg',
      visibility: props.defaults?.defaultVisibility || 'all',
    };
  }, [props.defaults, device]);

  const dataRefWithDefaults = useMemo(() => ({ ...defaults, ...dataRef }), [dataRef, defaults]);

  useEffect(() => {
    if (
      !sectionHandlers.activeSection &&
      moment().diff(firstValidSectionTime) > 100 &&
      isBoxDeviceActive(isDeviceMode(), false, device, dataRefWithDefaults.visibility)
    ) {
      firstValidSectionTime = moment();
      sectionUiHandlers.open();
    }
  }, [sectionHandlers.activeSection, device, dataRefWithDefaults.visibility]);

  const [toolbarIsOpen, setToolbarIsOpen] = useState(false);
  const [trayState, setTrayState] = useState(false);
  const [backupBoxUUid, setBoxUUid] = useState(uuidv4());

  const dispatch = useDispatch();
  const activeToolbar = useSelector(getActiveToolbar);

  const { brightnessTheme, conditional, height, showHeader } = dataRefWithDefaults;
  const { classes } = content.base || {};

  const isConditionalContent = conditional?.enabled;

  const sectionClassNames = classnames(
    'section',
    className,
    brightnessTheme,
    { 'height-full': height === 'full' && !device },
    { 'height-full height-full-desktop': height === 'full' && device === 'desktop' },
    { 'height-full height-full-tablet': height === 'full' && device === 'tablet' },
    { 'height-full height-full-mobile': height === 'full' && device === 'mobile' },
    { 'is-not-editable': !!sectionHandlers.activeTest },
    'section',
    `component-${componentId}`,
    'editing',
    ...(htmlAttrForLayout?.className || []).flat(),
    { [classes]: Boolean(dataRef?.classes) }
  );

  const sectionAttributes: { [key: string]: string } = {};

  Object.entries(omit(htmlAttrForLayout, Object.values(ATTR_MAP))).forEach(([key, value]: [string, string[]]) => {
    sectionAttributes[key] = value[0];
  });

  if (dataRef?.anchor) {
    sectionAttributes.id = dataRef.anchor;
  }

  const open = () => {
    setToolbarIsOpen(true);
  };

  const close = () => {
    setToolbarIsOpen(false);
  };

  sectionUiHandlers = {
    open,
    close,
    isOpen: toolbarIsOpen,
    ...sectionUiHandlers,
  };
  const SectionErrorBoundary = (
    <SectionError
      enableHeader={enableHeader}
      sectionHandlers={props.sectionHandlers}
      sectionUiHandlers={props.sectionUiHandlers}
      componentId={componentId}
      valueWithDefaults={dataRefWithDefaults}
    />
  );

  const page = useSelector((state) => pageSelectors.selectPage(state, props.sectionHandlers.pageId));
  const [showAddSectionTooltip, setShowAddSectionTooltip] = useState(page?.meta?.showAddSectionTooltip || false);
  const SectionToolbar = (
    <Toolbar
      properties={properties}
      setTrayState={setTrayState}
      trayState={trayState}
      onChange={onChange}
      dataRef={dataRefWithDefaults}
      content={content}
      contentKey={contentKey}
      enableHeader={enableHeader}
      locations={locations}
      isOpen={sectionUiHandlers.isOpen || ['mobile', 'tablet'].includes(device)}
      close={(e) => {
        e.stopPropagation();
        dispatch(setActiveToolbar(undefined));
        sectionUiHandlers.close();
      }}
      defaults={defaults}
      sectionHandlers={sectionHandlers}
      applyOpacity={sectionUiHandlers && (sectionUiHandlers.applyOpacity || sectionUiHandlers.isRichTextToolbarActive)}
    />
  );

  let toolbarTop,
    toolbarLeft,
    toolbarHeight = 0;

  const toolbarRef = useRef<HTMLDivElement>(null);
  const [toolbarMinWidth, setToolbarMinWidth] = useState(1000);
  let toolbarMaxWidth = document.body.clientWidth - 40;

  useEffect(() => {
    if (['mobile', 'tablet'].includes(device) && isDeviceMode()) {
      let activeLeft = document.getElementsByClassName(classnames(styles.absoluteLeft, 'isActive'));
      if (activeLeft[0]) {
        setToolbarMinWidth(1000 + activeLeft[0].clientWidth);
      } else {
        setToolbarMinWidth(1000);
      }
    }
  }, [activeToolbar, device]);

  const currentUrl = window.location.href;
  if (!page && currentUrl.includes('/editor/blog')) {
    toolbarTop = ref?.current?.getBoundingClientRect().top + window.scrollY;
    toolbarHeight = toolbarRef?.current?.clientHeight || 0;
  } else if (['mobile', 'tablet'].includes(device) && isDeviceMode()) {
    toolbarLeft =
      document.body.clientWidth / 2 - (toolbarMinWidth < toolbarMaxWidth ? toolbarMinWidth : toolbarMaxWidth) / 2;
    toolbarTop = ref?.current?.getBoundingClientRect().top + 23;
  } else {
    toolbarTop = ref?.current?.getBoundingClientRect().top + 72;
    toolbarHeight = toolbarRef?.current?.clientHeight || 0;
  }

  const sectionStyle = {
    //  @ts-ignore
    ...props.style,
    paddingTop: toolbarHeight,
  };

  return (
    <ErrorBoundary renderError={(error) => SectionErrorBoundary}>
      {isBoxDeviceActive(isDeviceMode(), sectionUiHandlers.isOpen, device, dataRefWithDefaults.visibility) && (
        <>
          {props.sectionHandlers.sectionIndex === 0 &&
            props.sectionHandlers.activeSection === (props.sectionHandlers?.section?.id || backupBoxUUid) && (
              <AddSectionButton
                sections={props.sectionHandlers.sections}
                pageId={props.sectionHandlers.pageId}
                sectionIndex={-1}
                markSectionAsFresh={props.sectionHandlers.markSectionAsFresh}
                page={page}
                showAddSectionTooltip={showAddSectionTooltip}
                setShowAddSectionTooltip={setShowAddSectionTooltip}
                defaultDesktopWidth={props?.site?.styles?.layout?.section?.desktop_width}
              />
            )}
          <section
            className={sectionClassNames}
            data-test-id="section"
            onClick={() => sectionUiHandlers.open()}
            ref={ref}
            key={componentId}
            {...sectionAttributes}
            style={sectionStyle}
          >
            {!['mobile', 'tablet'].includes(device) || !isDeviceMode()
              ? ReactDOM.createPortal(
                  <div
                    ref={toolbarRef}
                    style={{
                      position: 'absolute',
                      width: '100%',
                      top: toolbarTop,
                      left: '0px',
                      display: 'block',
                      zIndex: 2,
                    }}
                    data-test-id="section-toolbar"
                  >
                    {SectionToolbar}
                  </div>,
                  document.body
                )
              : ReactDOM.createPortal(
                  <div
                    style={{
                      position: 'absolute',
                      minWidth: toolbarMinWidth < toolbarMaxWidth ? toolbarMinWidth : toolbarMaxWidth,
                      maxWidth: toolbarMaxWidth,
                      top: toolbarTop,
                      left: toolbarLeft,
                      display:
                        activeToolbar === (props.sectionHandlers?.section?.id || backupBoxUUid) ? 'block' : 'none',
                      zIndex: 4,
                    }}
                    data-test-id="section-toolbar"
                  >
                    {SectionToolbar}
                  </div>,
                  document.body
                )}

            {isConditionalContent && !['mobile', 'tablet'].includes(device) && (
              <Conditions
                conditional={dataRefWithDefaults?.conditional}
                table={sectionUiHandlers.pageType}
                isSectionActive={sectionUiHandlers.isOpen}
                value={dataRefWithDefaults}
                onChange={onChange}
                isDynamicContent={!!sectionUiHandlers.pageType}
                content={content}
                contentKey={contentKey}
              />
            )}
            <div
              style={{
                ...(isConditionalContent && {
                  border: '1rem solid #EFF3FB',
                  borderTop: 0,
                }),
              }}
            >
              <BackgroundLayer domRef={ref} value={dataRefWithDefaults} />
              <SectionBox
                defaults={defaults}
                dataRef={dataRefWithDefaults}
                sectionHandlers={sectionHandlers}
                boxUUid={sectionHandlers?.section?.id || backupBoxUUid}
              >
                {Boolean(showHeader && enableHeader && sectionHeaderBoxDeviceVisible) && (
                  <SectionHeaderBox
                    content={content}
                    contentKey={contentKey}
                    dataRef={dataRef}
                    onChange={onChange}
                    sectionHandlers={sectionHandlers}
                  />
                )}
                {React.Children.map(children, (child) => {
                  if (!isValidElement(child)) {
                    return null;
                  }
                  const childProps = { properties };
                  return React.cloneElement(child as React.ReactElement, childProps);
                })}
              </SectionBox>
            </div>
          </section>
        </>
      )}
    </ErrorBoundary>
  );
};

export default withSite(SectionElement);
