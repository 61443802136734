import React from 'react';
import ZeroState, { Image, Header, Muted, Group } from '../../../AdminPanel/ZeroState';
import icon from '../../../../assets/images/icon-color-palette-zero-state.svg';

import styles from './ColorStyles.module.scss';

export const ZeroColorState = ({ open }) => (
  <div className={styles.noColorState}>
    <ZeroState>
      <Image src={icon} />
      <Header>Create a color palette</Header>
      <Muted>We'll make it easy to choose colors from your palette when you're creating content.</Muted>
      <Group>
        <input type="submit" value="Add a color to your palette" className="button button-primary" onClick={open} />
      </Group>
    </ZeroState>
  </div>
);
