import * as types from './actionTypes';

export const createNote = (payload, resolve, reject) => ({
  type: types.NOTES_CREATE_REQUEST,
  payload,
  resolve,
  reject,
});

export const fetchFilteredNotes = (payload) => ({
  type: types.NOTES_FETCH_FILTERED_NOTES,
  payload,
});

export const fetchSuccess = (payload) => ({
  type: types.NOTES_FETCH_SUCCESS,
  payload,
});

export const updateNote = (payload, resolve, reject) => ({
  type: types.NOTES_UPDATE_REQUEST,
  payload,
  resolve,
  reject,
});

export const deleteNote = (payload, resolve, reject) => ({
  type: types.NOTES_DELETE_REQUEST,
  payload,
  resolve,
  reject,
});
