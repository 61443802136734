import { authorizedGet as authorizedGetUnwrapped } from './spark-api';

class FetchError extends Error {
  constructor(public res: Response, message?: string) {
    super(message);
  }
}

export function authorizedGet(...args: any[]) {
  return authorizedGetUnwrapped(...args).then((response) => {
    if (response.ok) {
      return response.json || response.text;
    } else {
      throw new FetchError(response);
    }
  });
}
