import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import throttle from 'lodash/throttle';

import withSiteProps from './withSiteProps';
import * as api from '../services/spark-api';
import * as itemsActions from '../actions/itemsActions';

import { selectPage } from '../reducers/pagesReducer';
import { selectArticle } from '../reducers/articlesReducer';
import { selectBlog } from '../reducers/blogsReducer';
import { ITEM_URL_REGEXP, UUID_REGEX } from '../constants';

function mapSiteToProps(site) {
  return { siteId: site.id };
}

const mapStateToProps = (state, ownProps) => {
  const { value } = ownProps;
  let item;
  if (ownProps.noPrefix) {
    item = selectPage(state, value) || selectArticle(state, value) || selectBlog(state, value);
  } else {
    const itemMatch = value.match(ITEM_URL_REGEXP);
    item = itemMatch
      ? selectPage(state, itemMatch[2]) || selectArticle(state, itemMatch[2]) || selectBlog(state, itemMatch[2])
      : {};
  }
  return {
    item,
  };
};

const mapDispatchToProps = {
  getItem: itemsActions.requestIfNeeded,
  saveItem: itemsActions.save,
};

export default (WrappedComponent) => {
  class Container extends React.PureComponent {
    state = {
      search: '',
      throttle: '',
      searchedItems: [],
      itemsLoading: false,
    };

    onSearchUpdate = (search, itemType) => {
      this.throttleSearch(search, itemType);
    };

    loadCurrentItem = (id) => {
      if (id.match(UUID_REGEX)) this.props.getItem(id);
    };

    throttleSearch = throttle(
      (throttle, itemType) => {
        this.setState({ throttle, itemType });
      },
      600,
      { leading: false }
    );

    componentDidUpdate(prevProps, prevState) {
      const { throttle, itemType } = this.state;
      if (throttle && prevState.throttle !== throttle) {
        this.setState({ itemsLoading: true });
        api
          .getItems({
            search: throttle,
            perpage: 20,
            item_type: itemType,
            site_id: this.props.siteId,
          })
          .then((res) => this.setState({ searchedItems: res.json.results, itemsLoading: false }))
          .catch((err) => console.log(err));
      }
    }

    render() {
      return (
        <WrappedComponent
          {...this.state}
          onSearchUpdate={this.onSearchUpdate}
          loadCurrentItem={this.loadCurrentItem}
          {...this.props}
        />
      );
    }
  }

  return compose(withSiteProps(mapSiteToProps), connect(mapStateToProps, mapDispatchToProps))(Container);
};
