import React from 'react';
import ReactDOM from 'react-dom';

import { connect } from 'react-redux';
import classnames from 'classnames';

import { CSSTransition } from 'react-transition-group';

import * as pageActions from '../../actions/pageActions';
import * as pageSelectors from '../../reducers/pagesReducer';
import { selectShowPublishSuccessMessage } from '../../reducers/editorReducer';

import { ReactComponent as SvgIconCheckmark } from '../../assets/images/icon-checkmark.svg';
import { ReactComponent as SvgIconPublish } from '../../assets/images/icon-publish.svg';
import { ReactComponent as SvgIconDiscard } from '../../assets/images/icon-discard-changes.svg';

import styles from './FloatingPublishButton.module.scss';

function mapStateToProps(state, ownProps) {
  const { pageId } = ownProps;
  const page = pageSelectors.selectPage(state, pageId);
  return {
    isChanged: page && page.is_changed,
    isPublishing: page && page.isPublishing,
    showPublishSuccessMsg: selectShowPublishSuccessMessage(state),
  };
}

const mapDispatchToProps = {
  publish: pageActions.requestPublish,
};

const HIDDEN = 'HIDDEN';
const READY = 'READY';
const PUBLISHING = 'PUBLISHING';
const SUCCESS = 'SUCCESS';

class FloatingPublishButton extends React.PureComponent {
  getVisibility() {
    const { isChanged, isPublishing, forceHide, showPublishSuccessMsg, pageId } = this.props;

    if (pageId) {
      if (forceHide) {
        return HIDDEN;
      } else if (showPublishSuccessMsg) {
        return SUCCESS;
      } else if (isPublishing) {
        return PUBLISHING;
      } else if (isChanged) {
        return READY;
      }
    } else {
      const { isEditing, showPublishSuccess } = this.props;
      if (isEditing) return READY;
      if (showPublishSuccess) return SUCCESS;
    }

    return HIDDEN;
  }

  handleClickPublish = (e) => {
    e.stopPropagation();
    const { pageId, publish, publishChanges } = this.props;
    pageId ? publish(this.props.pageId) : publishChanges();
  };

  render() {
    const visibility = this.getVisibility();

    return (
      <Portal>
        <CSSTransition
          in={visibility !== HIDDEN}
          classNames={styles.container}
          timeout={300}
          appear
          mountOnEnter
          unmountOnExit
        >
          <div className={classnames('admin', styles.container)}>
            <div className={classnames(styles.spinner, styles[visibility])}>
              <div
                className={styles.publish}
                onClick={visibility === 'PUBLISHING' ? null : this.handleClickPublish}
                data-test-id="publish"
              >
                <SvgIconPublish />
                Publish changes
              </div>
              <div className={styles.success}>
                <SvgIconCheckmark />
                Changes published
              </div>
            </div>
            {this.props.isEditing && (
              <div onClick={this.props.discardChanges} className={styles.discard}>
                <SvgIconDiscard />
                Discard changes
              </div>
            )}
          </div>
        </CSSTransition>
      </Portal>
    );
  }
}

function Portal(props) {
  return ReactDOM.createPortal(props.children, document.body);
}

export default connect(mapStateToProps, mapDispatchToProps)(FloatingPublishButton);
