import React from 'react';
import classnames from 'classnames';
import BottomDrawer from 'components/base/BottomDrawer';
import AuthenticatedMedia from 'containers/AuthenticatedMedia';
import MediaDrawerBottom from 'components/base/Media/Drawer/MediaDrawerBottom';

import styles from '../Sheet/Sheet.module.scss';

export class MediaInput extends React.Component {
  state = { isOpen: false };
  componentDidMount() {
    if (!this.props.removeSheetStyles) this.setState({ isOpen: true });
  }
  close = () => this.setState({ isOpen: false });
  render() {
    return (
      <>
        {this.props.value ? (
          <AuthenticatedMedia
            onClick={() => this.setState({ isOpen: true })}
            className={classnames(styles.mediaColumnStyles, {
              [styles.videoMedia]: this.props.category === 'video',
            })}
            src={this.props.value}
          />
        ) : (
          <span
            className={classnames(styles.mediaPicker, {
              [styles.disabled]: !this.props.category,
            })}
            data-test-id="add-default-media"
            onClick={this.props.category ? () => this.setState({ isOpen: true }) : () => {}}
          >
            Add media
          </span>
        )}
        <BottomDrawer extendBody hideScroll isOpen={this.state.isOpen} close={this.close}>
          <MediaDrawerBottom
            close={this.close}
            category={this.props.category}
            entity={this.props.value ? { src: this.props.value } : {}}
            onChange={(e) => {
              this.props.onChange(e ? e.src : '');
            }}
            hideVideo={this.props.category !== 'video'}
            hideStockMedia
            hideImage={this.props.category !== 'image'}
            hideIcon={this.props.category !== 'icon'}
            hidePdf
            hideEmbeds
          />
        </BottomDrawer>
      </>
    );
  }
}

// export default MediaInput
