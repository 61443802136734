import React from 'react';

import { connect } from 'react-redux';
import withSite from '../../../containers/withSite';
import FormControl from '../../base/FormHelpers/FormControl';
import * as siteActions from '../../../actions/siteActions';
import * as timezoneActions from '../../../actions/timezoneActions';
import * as currencyActions from '../../../actions/currencyActions';
import * as timezonesSelectors from '../../../reducers/timezoneReducer';
import * as currencySelectors from '../../../reducers/currencyReducer';
import { LANGUAGES } from './Languages';

function mapStateToProps(state) {
  return {
    timezones: timezonesSelectors.selectTimezones(state),
    currencies: currencySelectors.selectCurrencys(state),
  };
}

const mapDispatchToProps = {
  updateSite: siteActions.update,
  requestTimezones: timezoneActions.requestIfNeeded,
  requestCurrencies: currencyActions.requestIfNeeded,
};

class AccountForm extends React.Component {
  state = {};

  componentDidMount() {
    this.props.requestTimezones();
    this.props.requestCurrencies();
  }

  siteName() {
    if (this.state.siteName != null) {
      return this.state.siteName;
    }

    return this.props.site.name || '';
  }

  timezone() {
    if (this.state.timezone != null) {
      return this.state.timezone;
    }

    return this.props.site.timezone || '';
  }

  language() {
    if (this.state.language != null) {
      return this.state.language;
    }

    return this.props.site.metadata.language || 'en';
  }

  currency() {
    if (this.state.currency != null) {
      return this.state.currency;
    }

    return this.props.site.currency || 'USD';
  }

  supportURL() {
    if (this.state.supportURL != null) {
      return this.state.supportURL;
    }

    return this.props.site.metadata.supportURL || '';
  }

  isDirty() {
    return (
      this.state.siteName != null ||
      this.state.timezone != null ||
      this.state.language != null ||
      this.state.supportURL != null ||
      this.state.currency != null
    );
  }

  onChange = (e) => {
    const { name, value } = e.target;

    this.setState({ [name]: value });
  };

  onSubmit = (e) => {
    e.preventDefault();

    this.props.updateSite({
      name: this.siteName(),
      timezone: this.timezone(),
      currency: this.currency(),
      metadata: {
        ...this.props.site.metadata,
        language: this.language(),
        supportURL: this.supportURL(),
      },
    });
    this.setState({
      siteName: undefined,
      timezone: undefined,
      language: undefined,
      currency: undefined,
      supportURL: undefined,
    });
  };

  render() {
    const { timezones, currencies } = this.props;
    return (
      <form className="form-inline" onSubmit={this.onSubmit}>
        <fieldset>
          <label>Company name</label>
          <input type="text" name="siteName" value={this.siteName()} onChange={this.onChange} />
        </fieldset>
        <fieldset>
          <label htmlFor="timezone_input">Timezone</label>
          <FormControl
            id="timezone_input"
            type="text"
            name="timezone"
            tag="select"
            value={this.timezone()}
            onChange={this.onChange}
          >
            {timezones.map((timezone) => (
              <option key={timezone.id} value={timezone.id}>
                {timezone.id.replace('_', ' ')}
              </option>
            ))}
          </FormControl>
        </fieldset>
        <fieldset>
          <label htmlFor="language_input">Default language</label>
          <FormControl
            id="language_input"
            type="text"
            name="language"
            tag="select"
            value={this.language()}
            onChange={this.onChange}
          >
            <option value=""></option>
            {Object.entries(LANGUAGES).map((entry) => (
              <option key={entry[0]} value={entry[1]}>
                {entry[0]}
              </option>
            ))}
          </FormControl>
        </fieldset>
        <fieldset>
          <label htmlFor="currency_input">Default currency</label>
          <FormControl
            id="language_input"
            type="text"
            name="currency"
            tag="select"
            value={this.currency()}
            onChange={this.onChange}
          >
            <option value=""></option>
            {currencies.map((currency) => (
              <option key={currency.id} value={currency.id}>
                {currency.id}
              </option>
            ))}
          </FormControl>
        </fieldset>
        <fieldset>
          <label>Support URL</label>
          <input type="text" name="supportURL" value={this.supportURL()} onChange={this.onChange} />
        </fieldset>
        {/* <fieldset>
          <label>Email address</label>
          <input disabled type="email" />
        </fieldset> */}
        {this.isDirty() && (
          <fieldset>
            <label>&nbsp;</label>
            <input type="submit" value="Save" className="button button-primary button-sm" />
          </fieldset>
        )}
      </form>
    );
  }
}

export default withSite(connect(mapStateToProps, mapDispatchToProps)(AccountForm));
