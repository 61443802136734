import React from 'react';
import SectionRendererWrapper from './Sections/SectionRendererWrapper';
import isEqual from 'lodash/isEqual';
import { USite } from 'types/USite';
import { UPage } from 'types/UPage';
import { USection } from 'types/USection';

type Props = {
  pageId: string;
  page: UPage;
  showAddSectionTooltip: boolean;
  setShowAddSectionTooltip: (value: boolean) => void;
  key: string;
  sections: USection[];
  updateActiveSection: (sectionId: string | boolean) => void;
  freshSectionId: string;
  sectionInMotion: string;
  activeSection: string | boolean;
  markSectionAsFresh: (sectionId: string) => void;
  sectionIndex: number;
  pageHeaderLayout: string;
  pageSectionPlaceholder: string;
  site: USite;
  dataTable: string;
  pageFooterLayout: Element | string;
};

class RenderedSection extends React.Component<Props> {
  shouldComponentUpdate(nextProps: Props) {
    if (!isEqual(nextProps.sections, this.props.sections)) {
      return true;
    }

    const propList = Object.keys(nextProps).filter((key) => key !== 'sections');
    // @ts-ignore-next-line
    return propList.some((key) => !Object.is(nextProps[key], this.props[key]));
  }

  render() {
    const {
      freshSectionId,
      sectionInMotion,
      activeSection,
      updateActiveSection,
      markSectionAsFresh,
      sectionIndex,
      pageId,
      page,
      showAddSectionTooltip,
      setShowAddSectionTooltip,
      pageHeaderLayout,
      sections,
      pageSectionPlaceholder,
      site,
      dataTable,
      pageFooterLayout,
    } = this.props;

    const section = sections[sectionIndex];

    const { id } = section;
    const isFresh = freshSectionId === id;
    const isInMotion = sectionInMotion === id;

    return (
      <SectionRendererWrapper
        updateActiveSection={updateActiveSection}
        activeSection={activeSection as string}
        key={id}
        sectionIndex={sectionIndex}
        pageFooterLayout={pageFooterLayout}
        pageId={pageId}
        page={page}
        showAddSectionTooltip={showAddSectionTooltip}
        setShowAddSectionTooltip={setShowAddSectionTooltip}
        pageHeaderLayout={pageHeaderLayout}
        section={section}
        isFresh={isFresh}
        isInMotion={isInMotion}
        markSectionAsFresh={markSectionAsFresh}
        sections={sections}
        pageSectionPlaceholder={pageSectionPlaceholder}
        site={site}
        pageType={dataTable}
        itemType={page.item_type}
      />
    );
  }
}

export default RenderedSection;
