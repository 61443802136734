import * as types from '../actions/actionTypes';
import * as siteSelectors from './sitesReducer';
import * as pageSelectors from './pagesReducer';
import { createSelector } from 'reselect';
import { applyOps } from '../lib/immutable-operations';

// ----------------------------------------------------------------------------
// Reducer
// ----------------------------------------------------------------------------

const initState = {
  isOpen: false,
  pageId: '',
  ops: [],
  errors: [],
  dynamicContentPageErrors: [],
};

export default function (state = initState, action) {
  switch (action.type) {
    case types.PAGE_SETTINGS_OPEN:
      return {
        isOpen: true,
        ...action.payload,
      };

    case types.PAGE_SETTINGS_CLOSE:
      return {
        ...state,
        isOpen: false,
        ops: [],
      };
    case types.PAGE_SETTINGS_ADD_OPS:
      return {
        ...state,
        ops: state.ops.concat(action.payload.ops),
      };

    case types.PAGE_DYNAMIC_CONTENT_PUBLISH_ERROR:
      return {
        ...state,
        dynamicContentPageErrors: action.payload.errors,
      };

    case types.PAGE_SETTINGS_RESET:
      return { ...initState };

    default:
      return state;
  }
}

// ----------------------------------------------------------------------------
// Selectors
// ----------------------------------------------------------------------------

function selectLocalState(globalState) {
  return globalState.uiPageSettings;
}

export function selectPageId(globalState) {
  const localState = selectLocalState(globalState);
  return localState.pageId;
}

export function selectOps(globalState) {
  const localState = selectLocalState(globalState);
  return localState.ops;
}

export function selectPage(globalState) {
  const { pageId } = selectLocalState(globalState);
  return pageSelectors.selectPage(globalState, pageId);
}

export const selectPageDraft = createSelector(selectPage, selectOps, (page, ops) => applyOps(page, ops));

export const selectProps = createSelector(
  siteSelectors.selectAllSites,
  selectPageDraft,
  selectLocalState,
  (site, pageDraft, localState) => {
    return {
      siteDomain: siteSelectors.getFullDomainFromSite(site),
      page: pageDraft,
      ...localState,
    };
  }
);

export const selectDynamicContentErrors = createSelector(
  selectLocalState,
  (localState) => localState.dynamicContentPageErrors
);
