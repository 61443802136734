import React from 'react';
import { compose } from 'redux';

import { AreaClosed, Line, LinePath, Bar } from '@vx/shape';
import { AxisLeft, AxisBottom } from '@vx/axis';
import { GridRows } from '@vx/grid';
import { scaleTime, scaleLinear } from '@vx/scale';
import * as curves from '@vx/curve';
import { withTooltip, TooltipWithBounds as Tooltip } from '@vx/tooltip';
import { withParentSize } from '@vx/responsive';
import { localPoint } from '@vx/event';
import { bisector } from 'd3-array';
import { timeFormat } from 'd3-time-format';
import { format as formatNumber } from 'd3-format';

import isNumber from 'lodash/isNumber';

import iconLegendBlue from '../../assets/images/icon-legend-blue.svg';
import iconLegendRed from '../../assets/images/icon-legend-red.svg';
import iconLegendGray from '../../assets/images/icon-legend-gray.svg';
import iconLegendGreen from '../../assets/images/icon-legend-green.svg';

import styles from './Charts.module.scss';

// util
const formatDate = timeFormat('%b %d');
const min = (arr, fn) => Math.min(...arr.map(fn));
const max = (arr, fn) => Math.max(...arr.map(fn));

// accessors
const fx = (d) => d.date;
const fy = (d) => d.value;
const fyValue = (d) => d.value;
const bisectDate = bisector((d) => new Date(d.date)).left;

class Area extends React.Component {
  constructor(props) {
    super(props);
    this.handleTooltip = this.handleTooltip.bind(this);
  }
  handleTooltip({ event, data, fx, xScale, yScale }) {
    const { showTooltip, dateDomain } = this.props;
    const { x } = localPoint(event);
    const x0 = xScale.invert(x);
    let index = bisectDate(data, x0, 1);
    const d0 = data[index - 1];
    const d1 = data[index];
    // let   d = d0;
    if (d1 && d1.date) {
      // d = x0 - fx(d0) > fx(d1) - x0 ? d1 : d0;
      if (x0 - fx(d0) < fx(d1) - x0) index--;
    }
    const d = { current: data[index] };

    if (dateDomain.diff < index) {
      d.previous = data[index - dateDomain.diff];
    }

    showTooltip({
      tooltipData: d,
      tooltipLeft: xScale(fx(d.current)),
      tooltipTop: yScale(fy(d.current)),
    });
  }
  render() {
    const {
      data,
      parentWidth,
      parentHeight,
      hideTooltip,
      tooltipData,
      tooltipTop,
      tooltipLeft,
      colorScheme,
      dateDomain,
      selectedDateDomain,
      selectedDataset,
    } = this.props;

    const zeroState = data.length === 0;

    if (parentWidth < 10) return null;

    const width = parentWidth;
    const height = parentHeight;

    const margin = {
      top: 0,
      right: 0,
      bottom: 25,
      left: 0,
    };

    // bounds
    const xMax = width - margin.left - margin.right;
    const yMax = height - margin.top - margin.bottom;

    // scales
    const xScale = scaleTime({
      // domain: dateDomain === 'ALL_TIME' ? extent(data, fx) : dateDomain.current,
      domain: dateDomain.current,
      range: [0, xMax],
    });
    const xScalePrevious = scaleTime({
      domain: dateDomain.previous,
      range: [0, xMax],
    });

    const yScale = scaleLinear({
      domain: zeroState ? [0, 3] : [min(data, fy), max(data, fy)],
      range: [yMax, 8],
      nice: !zeroState,
    });

    // colorscheme
    let primaryLegendIcon;
    switch (colorScheme) {
      case 'green':
        primaryLegendIcon = iconLegendGreen;
        break;
      case 'red':
        primaryLegendIcon = iconLegendRed;
        break;
      default:
        primaryLegendIcon = iconLegendBlue;
    }

    // Circles
    const shouldShowCircles =
      (isNumber(selectedDateDomain) && selectedDateDomain < 31) ||
      selectedDateDomain === 'WEEK' ||
      selectedDateDomain === 'MONTH';

    return (
      <div>
        <svg ref={(s) => (this.svg = s)} width={width} height={height}>
          <defs>
            <linearGradient id="blue-gradient" x1="0%" y1="0%" x2="0%" y2="100%">
              <stop offset="0%" stopColor="rgb(20,149,205)" />
              <stop offset="100%" stopColor="rgb(143,157,245)" />
            </linearGradient>

            <linearGradient id="green-gradient" x1="0%" y1="0%" x2="0%" y2="100%">
              <stop offset="0%" stopColor="rgb(93,211,158)" />
              <stop offset="100%" stopColor="rgb(155,197,61)" />
            </linearGradient>

            <linearGradient id="red-gradient" x1="0%" y1="0%" x2="0%" y2="100%">
              <stop offset="0%" stopColor="rgb(255,154,139)" />
              <stop offset="55%" stopColor="rgb(255,106,136)" />
              <stop offset="100%" stopColor="rgb(255,153,172)" />
            </linearGradient>

            <linearGradient id="purple-gradient" x1="0%" y1="0%" x2="0%" y2="100%">
              <stop offset="0%" stopColor="rgb(111, 127, 226)" />
              <stop offset="100%" stopColor="rgb(150,162,239)" />
            </linearGradient>

            <filter id="shadow">
              <feDropShadow dx="0" dy="2" stdDeviation="6" floodColor="rgba(0,0,0,0.15)" />
            </filter>
          </defs>
          <GridRows lineStyle={{ pointerEvents: 'none' }} scale={yScale} numTicks={4} width={xMax} stroke="#DCE4F4" />
          {!zeroState && (
            <AxisLeft
              tickClassName={styles.leftAxisTick}
              tickFormat={formatNumber('~s')}
              scale={yScale}
              numTicks={3}
              hideAxisLine
              hideTicks
              hideZero
              left={10}
            />
          )}
          <AxisBottom
            tickClassName={styles.bottomAxisTick}
            tickFormat={formatDate}
            scale={xScale}
            numTicks={5}
            hideAxisLine
            left={0}
            top={height - margin.bottom}
          />

          <LinePath
            data={data}
            x={(d) => xScalePrevious(fx(d))}
            y={(d) => yScale(fy(d))}
            stroke="#B2C1DF"
            strokeDasharray="5 5"
            strokeWidth={2}
            style={{ pointerEvents: 'none' }}
            curve={shouldShowCircles ? null : curves.curveMonotoneX}
          />

          <AreaClosed
            data={data}
            x={(d) => xScale(fx(d))}
            y={(d) => yScale(fy(d))}
            yScale={yScale}
            fill={`url(#${colorScheme}-gradient)`}
            style={{ opacity: '0.3' }}
            curve={shouldShowCircles ? null : curves.curveMonotoneX}
          />
          <LinePath
            data={data}
            x={(d) => xScale(fx(d))}
            y={(d) => yScale(fy(d))}
            stroke={`url(#${colorScheme}-gradient)`}
            strokeWidth={3}
            style={{ pointerEvents: 'none' }}
            filter="url(#shadow)"
            curve={shouldShowCircles ? null : curves.curveMonotoneX}
          />
          {shouldShowCircles &&
            data.map((point, i) => {
              const cx = xScale(fx(point));
              const cy = yScale(fy(point));
              return (
                <circle
                  key={`${cx}${cy}`}
                  cx={cx}
                  cy={cy}
                  r={3.5}
                  strokeWidth={2.5}
                  fill="#FFFFFF"
                  stroke={`url(#${colorScheme}-gradient)`}
                />
              );
            })}
          {!zeroState && (
            <Bar
              x={0}
              y={0}
              width={width}
              height={height}
              fill="transparent"
              rx={14}
              data={data}
              onTouchStart={(event) =>
                this.handleTooltip({
                  event,
                  fx,
                  xScale,
                  yScale,
                  data: data,
                })
              }
              onTouchMove={(event) =>
                this.handleTooltip({
                  event,
                  fx,
                  xScale,
                  yScale,
                  data: data,
                })
              }
              onMouseMove={(event) =>
                this.handleTooltip({
                  event,
                  fx,
                  xScale,
                  yScale,
                  data: data,
                })
              }
              onMouseLeave={(event) => hideTooltip()}
            />
          )}
          {tooltipData && (
            <g>
              <Line
                from={{ x: tooltipLeft, y: 0 }}
                to={{ x: tooltipLeft, y: yMax }}
                stroke="#FCA311"
                strokeWidth={1}
                style={{ pointerEvents: 'none' }}
              />
              {/* { tooltipData.previous &&
                <circle
                  cx={xScalePrevious(fx(tooltipData.previous))}
                  cy={yScale(fy(tooltipData.previous))}
                  r={4}
                  fill="#fff"
                  stroke="#FCA311"
                  strokeWidth={3}
                  style={{ pointerEvents: 'none' }}
                />
              } */}
              <circle
                cx={tooltipLeft}
                cy={tooltipTop + 1}
                r={4}
                fill="black"
                fillOpacity={0.1}
                stroke="black"
                strokeOpacity={0.1}
                strokeWidth={2}
                style={{ pointerEvents: 'none' }}
              />
              <circle
                cx={tooltipLeft}
                cy={tooltipTop}
                r={4}
                fill="#fff"
                stroke="#FCA311"
                strokeWidth={3}
                style={{ pointerEvents: 'none' }}
              />
            </g>
          )}
        </svg>
        {tooltipData && (
          <div>
            <Tooltip
              // className={styles.tooltip}
              top={tooltipTop - 28}
              left={tooltipLeft + 12}
              style={{
                position: 'absolute',
                backgroundColor: 'rgba(20,33,61,0.85)',
                color: '#fff',
                padding: '0.5em',
                fontFamily: "'Inter', sans-serif",
                fontSize: '12px',
              }}
            >
              <div>
                <img className={styles.iconLegend} src={primaryLegendIcon} alt="" />
                <span className={styles.tooltipDate}>{formatDate(fx(tooltipData.current))}</span>
                <span className={styles.tooltipValue}>
                  {fyValue(tooltipData.current).toLocaleString()}{' '}
                  {selectedDataset === 'visits' ? 'sessions' : selectedDataset === 'uniques' ? 'users' : 'views'}
                </span>
              </div>
              {tooltipData.previous && (
                <div style={{ marginTop: '0.2em' }}>
                  <img className={styles.iconLegend} src={iconLegendGray} alt="" />
                  <span className={styles.tooltipDate}>{formatDate(fx(tooltipData.previous))}</span>
                  <span className={styles.tooltipValue}>
                    {fyValue(tooltipData.previous).toLocaleString()}{' '}
                    {selectedDataset === 'visits' ? 'sessions' : selectedDataset === 'uniques' ? 'users' : 'views'}
                  </span>
                </div>
              )}
            </Tooltip>
          </div>
        )}
      </div>
    );
  }
}

export default compose(withTooltip, withParentSize)(Area);
