import React from 'react';
import { compose } from 'redux';
import withIntegrations from 'containers/withIntegrations';
import { ReactComponent as SearchIcon } from 'assets/images/icon-search.svg';

const AlogliaSearchField = (props) => {
  const { integrationsBySlug } = props;
  const [algolia] = integrationsBySlug.algolia || [];
  return algolia && algolia.status === 'active' ? (
    <div class="search-input-container" data-algolia data-widget="searchBox">
      <SearchIcon />
      <form action>
        <input type="text" class="algolia-search-field" />
      </form>
    </div>
  ) : null;
};

export default compose(withIntegrations)(AlogliaSearchField);
