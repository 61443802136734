import mapValues from 'lodash/mapValues';
import groupBy from 'lodash/groupBy';

// Like Lodash's groupBy, but if multiple keys are provided, it returns an
// object that's nested by the groupings.
//
// Credit: https://gist.github.com/joyrexus/9837596
//
//   Example
//     var data = [
//       { type: "apple", color: "green", quantity: 1000 },
//       { type: "apple", color: "red", quantity: 2000 },
//       { type: "grape", color: "green", quantity: 1000 },
//       { type: "grape", color: "red", quantity: 4000 }
//     ];
//
//     nest(data, ['color', 'quantity']
//
//     // => {
//             green: {
//               "1000": [
//                 { type: 'apple', color: 'green', quantity: 1000 },
//                 { type: 'grape', color: 'green', quantity: 1000 }
//               ]
//             },
//             red: {
//               "2000": [
//                 { type: 'apple', color: 'red', quantity: 2000 }
//               ],
//               "4000": [
//                 { type: 'grape', color: 'red', quantity: 4000 }
//               ]
//             }
//           }
//
export default function nestBy(seq: ArrayLike<unknown>, keys: any): object {
  if (!keys.length) return seq;
  var first = keys[0];
  var rest = keys.slice(1);
  return mapValues(groupBy(seq, first), function (value) {
    return nestBy(value, rest);
  });
}
