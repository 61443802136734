import React, { memo } from 'react';
import classNames from 'classnames';

import styles from './ProductPlaceholder.module.scss';
import placeholderImage from '../../../../../../assets/images/placeholder.svg';

type Props = {
  alignment?: string;
};

const ProductPlaceholder: React.FunctionComponent<Props> = ({ alignment }) => {
  return (
    <div
      className={classNames(
        styles.container,
        { [styles.isFull]: alignment === 'full' },
        { [styles.isGrid]: alignment === 'grid' },
        { [styles.isOffset]: alignment === 'offset' },
        { [styles.isCollage]: alignment === 'collage' },
        { [styles.isList]: alignment === 'list' }
      )}
    >
      <div className={styles.thumbnailContainer}>
        <div className={styles.thumbnailWrapper}>
          <img src={placeholderImage} className="editor-placeholder" style={{ opacity: '0.5' }} />
          <img src={placeholderImage} className="editor-placeholder" />
          <img src={placeholderImage} className="editor-placeholder" />
          <img src={placeholderImage} className="editor-placeholder" />
        </div>
      </div>
      <div className={styles.imageGallery}>
        <div className={styles.imageContainer}>
          <img src={placeholderImage} className="editor-placeholder" />
          <img src={placeholderImage} className="editor-placeholder" />
          <img src={placeholderImage} className="editor-placeholder" />
          <img src={placeholderImage} className="editor-placeholder" />
          <div className="swiper-button-next"></div>
          <div className="swiper-button-prev"></div>
        </div>
      </div>
    </div>
  );
};

export default memo(ProductPlaceholder);
