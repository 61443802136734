import React from 'react';
import { compose } from 'redux';
import withSiteId from '../../../../containers/withSiteId';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import assign from 'lodash/assign';

import { selectSiteByDomain } from '../../../../reducers/sitesReducer';
import { selectIsUpdating } from '../../../../reducers/integrationsReducer';
import * as integrationActions from '../../../../actions/integrationActions';

import SideDrawer from '../../../base/SideDrawer';

import styles from './CustomCodeDrawer.module.scss';
import { CUSTOM_INTEGRATION_ID } from 'settings';

function mapStateToProps(state, ownProps) {
  return {
    isUpdating: selectIsUpdating(state),
  };
}

const mapDispatchToProps = {
  createIntegration: integrationActions.create,
  patchIntegration: integrationActions.patch,
};

class CustomCodeDrawer extends React.PureComponent {
  state = {
    isValid: false,
    name: '',
    location: 'header',
    content: null,
    isUploading: false,
    editIntegration: {},
  };

  handleChange = (nameValue) => {
    const { name, content } = this.state;

    const valueObject = assign({ name, content }, nameValue);

    this.setState((prevState) =>
      assign(
        {
          isValid: !isEmpty(valueObject.name) && !isEmpty(valueObject.content),
        },
        valueObject
      )
    );
  };

  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.handleChange({ [name]: value });
  };

  handleSubmit = (e) => {
    e.preventDefault();

    const {
      siteId,
      close,
      editIntegration: { id },
    } = this.props;
    const { name, content, location } = this.state;
    this.setState({
      isUploading: true,
    });
    const config = { name, content, location };

    const action = id
      ? this.props.patchIntegration({
          id,
          config,
        })
      : this.props.createIntegration({
          siteId: siteId,
          integration: CUSTOM_INTEGRATION_ID,
          config,
        });
    action.promise
      .then(() => {
        close();
      })
      .catch((errors) => {
        this.setState({
          errorMessage: errors.message,
        });
      })
      .finally(() => {
        this.setState({
          isUploading: false,
        });
      });
  };

  componentWillReceiveProps(nextProps) {
    const {
      editIntegration: { config },
    } = nextProps;
    this.setState({
      name: config.name || '',
      location: config.location || 'header',
      content: config.content,
    });
  }

  render() {
    const { isOpen, close } = this.props;
    const { isValid, name, content, location, errorMessage, isUploading } = this.state;

    return (
      <SideDrawer title="Custom integration options" isOpen={isOpen} close={close} noPadding>
        <form onSubmit={this.handleSubmit} className={styles.CustomCodeDrawer}>
          <fieldset>
            <label>Integration name</label>
            <input
              type="text"
              onChange={this.handleInputChange}
              name="name"
              value={name}
              autoComplete="off"
              autoFocus={true}
            />
          </fieldset>
          <fieldset>
            <label>Page placement</label>
            <select name="location" value={location} onChange={this.handleInputChange}>
              <option key="header" value="head">
                Header
              </option>
              <option key="footer" value="body_top">
                Top of Body
              </option>
              <option key="body" value="body_bottom">
                Bottom of Body
              </option>
            </select>
          </fieldset>
          <fieldset>
            <label>Script</label>
            <textarea
              onChange={this.handleInputChange}
              value={content || ''}
              placeholder="<script> <!-- Your code here --> </script>"
              name="content"
            />
          </fieldset>
          <fieldset>
            <input
              type="submit"
              className="button button-primary button-full"
              value="Save integration"
              disabled={!isValid || isUploading}
            />
            {/* Label and error message */}
            {errorMessage && <small className="errorMessage">{errorMessage}</small>}
          </fieldset>
        </form>
      </SideDrawer>
    );
  }
}

export default compose(withSiteId, connect(mapStateToProps, mapDispatchToProps))(CustomCodeDrawer);
