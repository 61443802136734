import React, { isValidElement } from 'react';

import classnames from 'classnames';

import ErrorBoundary from 'components/ErrorBoundary';
import { SectionHandlersInterface, SectionUiHandlersInterface } from '../../../types';
import SectionError from './SectionError';
import { SectionTagArgs } from 'components/unstack-components/tag-types';
import withSite from 'containers/withSite';
import { USite } from 'types/USite';
import { USection, USectionDevices } from 'types/USection';

interface FooterElementProps extends SectionTagArgs {
  defaults: any;
  value: USection;
  children?: React.ReactChildren;
  sectionHandlers?: SectionHandlersInterface;
  sectionUiHandlers?: SectionUiHandlersInterface;
  site: USite;
  onChange: (content: USectionDevices, key?: string, multi?: boolean) => void;
}

const FooterElement: React.FunctionComponent<FooterElementProps> = (props) => {
  let { children, onChange, className, componentId, sectionHandlers, containerInfo } = props;

  const footerClassNames = classnames('footer', className, `component-${componentId}`);

  const SectionErrorBoundary = (
    <SectionError
      sectionHandlers={props.sectionHandlers}
      sectionUiHandlers={props.sectionUiHandlers}
      componentId={componentId}
    />
  );

  return (
    <ErrorBoundary renderError={(_error) => SectionErrorBoundary}>
      <footer className={footerClassNames}>
        <>
          {React.Children.map(children, (child) => {
            if (!isValidElement(child)) {
              return child;
            }

            const childProps = containerInfo ? { onChange, containerInfo, sectionHandlers } : { sectionHandlers };
            return React.cloneElement(child as React.ReactElement, childProps);
          })}
        </>
      </footer>
    </ErrorBoundary>
  );
};

export default withSite(FooterElement);
