import React from 'react';
import { connect } from 'react-redux';

import List from '../../react-table/List';

import relativeDate from '../../../lib/relative-date';

import { roleMap } from './Users/Roles';
import * as uiUserSettingsActions from '../../../actions/uiUserSettingsActions';

import { selectAll, selectHasFetched, selectOwnerStatus } from '../../../reducers/accountUsersReducer';
import { selectHasSubscription } from '../../../reducers/subscriptionReducer';
import { selectHasPermission } from '../../../reducers/policyReducer';

import Spinner from '../../base/Spinner';

import { ReactComponent as SvgIconAdd } from '../../../assets/images/icon-plus.svg';
import { ReactComponent as SvgIconOptions } from '../../../assets/images/icon-options.svg';

import styles from './Users.module.scss';

const columns = [
  {
    id: 'name',
    accessor: (row) => ({
      initials: row.initials,
      name: row.name ? row.name : row.email,
      email: row.name && row.email ? row.email : '',
      profile_image: row.profile_image,
    }),
    Cell: ({ value }) => {
      return (
        <>
          {value.profile_image ? (
            <span
              className={styles.initials}
              style={{
                ...(value.profile_image && {
                  backgroundImage: `url(${value.profile_image.icon})`,
                }),
              }}
            />
          ) : (
            <span className={styles.initials}>{value.initials}</span>
          )}
          <span>{value.name}</span>
          <span className="muted">&nbsp; {value.email}</span>
        </>
      );
    },
    minWidth: 360,
  },
  {
    id: 'role',
    accessor: (row) => roleMap.get(row.role) || {},
    Cell: ({ value }) => <span>{value.label}</span>,
    minWidth: 200,
  },
  {
    id: 'last_login',
    accessor: (row) => ({
      message: row.status === 'active' ? 'Last logged in' : 'Invite sent',
      date: row.status === 'active' ? relativeDate(row.last_login, 'Never') : relativeDate(row.created_at, 'Just now'),
    }),
    Cell: ({ value }) => (
      <>
        <span className="muted">{value.message} </span>
        <span>{value.date}</span>
      </>
    ),
  },
  {
    id: 'options',
    maxWidth: 50,
    Cell: () => (
      <span className={styles.options} data-test-id="edit-user">
        <SvgIconOptions />
      </span>
    ),
  },
];

function mapDispatchToProps(dispatch) {
  return {
    addUser: () => {
      dispatch(uiUserSettingsActions.open({ accountUserId: null }));
    },
    editUser: (accountUser) => {
      dispatch(uiUserSettingsActions.open({ accountUserId: accountUser.id }));
    },
  };
}

function mapStateToProps(state) {
  return {
    hasSubscription: selectHasSubscription(state),
    accountUsers: selectAll(state),
    hasFetched: selectHasFetched(state),
    hasPermission: selectHasPermission(state),
    ownerStatus: selectOwnerStatus(state),
  };
}

class Users extends React.PureComponent {
  handleUserClicked = (accountUser) => {
    if (this.props.hasPermission('AccountUser:update')) {
      this.props.editUser(accountUser);
    }
  };

  handleAddUserClicked = () => {
    this.props.addUser();
  };

  getTdProps = (state, row) => {
    return {
      onClick: (e) => {
        this.handleUserClicked(row.original);
      },
      style: { cursor: 'pointer' },
    };
  };

  render() {
    const { accountUsers, hasFetched, hasSubscription, hasPermission, ownerStatus } = this.props;
    const count = accountUsers ? accountUsers.length : 0;

    // TODO - uncomment this
    if (!hasSubscription) {
      // return (<></>);
    }

    if (!hasPermission('AccountUser:update')) {
      columns.splice(-1, 1); // remove the last item
    }

    return (
      <section id="team">
        <header>
          <h1>Team</h1>
          {hasPermission('AccountUser:create') && (
            <button
              onClick={this.handleAddUserClicked}
              data-test-id="add-user"
              className="button button-primary button-icon"
              style={{ float: 'right' }}
              disabled={ownerStatus === 'inactive'}
            >
              <SvgIconAdd height={15} width={15} />
              <span>Add user</span>
            </button>
          )}
        </header>
        {count > 0 ? (
          <div className={styles.container}>
            <List
              className="-highlight"
              data={accountUsers}
              columns={columns}
              getTdProps={this.getTdProps}
              showAll={true}
              isSensitive={true}
            />
          </div>
        ) : (
          <Spinner />
        )}
      </section>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Users);
