import * as types from './actionTypes';

export function requestAll(siteId) {
  return {
    type: types.ACCOUNT_USER_REQUEST_ALL,
    payload: { siteId },
  };
}

export function requestAllIfNeeded(siteId) {
  return {
    type: types.ACCOUNT_USER_REQUEST_ALL_IF_NEEDED,
    payload: { siteId },
  };
}

export function receiveAll(accountUserIds, entities) {
  const receivedAt = new Date();

  return {
    type: types.ACCOUNT_USER_RECEIVE_ALL,
    payload: { accountUserIds, entities, receivedAt },
  };
}

export function request() {
  return {
    type: types.ACCOUNT_USER_REQUEST,
  };
}

export function receive(accountUserProps) {
  return {
    type: types.ACCOUNT_USER_RECEIVE,
    payload: accountUserProps,
  };
}

export function create(siteId, accountUserProps) {
  return {
    type: types.ACCOUNT_USER_CREATE,
    payload: {
      siteId,
      accountUser: accountUserProps,
    },
  };
}

export function update(accountUserProps, resolve, reject) {
  return {
    type: types.ACCOUNT_USER_UPDATE,
    payload: accountUserProps,
    resolve,
    reject,
  };
}

export function patchOnboarding(payload) {
  return {
    type: types.ACCOUNT_USER_UPDATE_ONBOARDING,
    payload,
  };
}

export function remove(accountUserProps) {
  return {
    type: types.ACCOUNT_USER_REMOVE,
    payload: accountUserProps,
  };
}

export function receiveDelete(accountUserProps) {
  return {
    type: types.ACCOUNT_USER_RECEIVE_DELETE,
    payload: accountUserProps,
  };
}

export function receiveError(errors) {
  return {
    type: types.ACCOUNT_USER_RECEIVE_ERROR,
    payload: errors,
  };
}

export function sendInvite(id) {
  return {
    type: types.ACCOUNT_USER_RESEND_INVITE,
    payload: id,
  };
}

export function getCurrent(siteId) {
  return {
    type: types.ACCOUNT_USER_REQUEST_CURRENT,
    payload: { siteId },
  };
}

export function resendVerificationEmail() {
  return {
    type: types.ACCOUNT_USER_RESEND_VERIFICATION_EMAIL,
  };
}

export function verificationEmailSent() {
  return {
    type: types.ACCOUNT_USER_VERIFICATION_EMAIL_SENT,
  };
}
