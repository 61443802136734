import * as types from './actionTypes';

export const getContactsCount = (siteId) => {
  return {
    type: types.CONTACTS_FETCH_COUNT,
    payload: { siteId },
  };
};

export const deleteContact = (id, resolve, reject) => {
  return {
    type: types.CONTACTS_DELETE,
    id,
    resolve,
    reject,
  };
};

export const updateNotificationPermission = (status) => {
  return {
    type: types.CONTACTS_NOTIFICATIONS_PERMISSION_UPDATE,
    payload: status,
  };
};

export const fetchSearchResults = (payload) => {
  return {
    type: types.CONTACTS_FETCH_SEARCH_RESULTS,
    payload,
  };
};

export const saveSearchedResults = (payload) => {
  return {
    type: types.CONTACTS_SAVE_SEARCHED_RESULTS,
    payload,
  };
};
export const createOrUpdateContact = (payload, resolve, reject) => {
  return {
    type: types.CONTACTS_ADD_OR_UPDATE_CONTACT,
    payload,
    resolve,
    reject,
  };
};
