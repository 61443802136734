import React from 'react';
import { connect } from 'react-redux';
import createFormatDate from 'date-fns/fp/format';

import ZeroState from './ZeroState';
import Spinner from 'components/base/Spinner';

import * as dataTablesActions from 'actions/dataTablesActions';

import iconUnstackSection from '../../../assets/images/thumbnail-custom-atf@2x.png';

import styles from './TableList.module.scss';

const formatDateWithYear = createFormatDate('d/L/yy');

function Table(props) {
  return (
    <div onClick={() => props.onClick()} className={styles.tableContainer}>
      <div style={{ backgroundImage: `url(${iconUnstackSection})` }} className={styles.imagePlaceholder} />
      <div className={styles.info}>
        <span>{props.slug}</span>
        <small>
          {props.total_rows} Data Table records. Update {formatDateWithYear(new Date(props.modified_at))}
        </small>
      </div>
    </div>
  );
}

const mapDispatchToProps = {
  saveSelection: dataTablesActions.saveSelectedTable,
};

export default connect(
  null,
  mapDispatchToProps
)((props) => {
  const { isFetching, data = [], saveSelection } = props;
  return (
    <div className={styles.container} data-test-id="sheet-table-list">
      {isFetching && <Spinner className="fixed" />}
      {!isFetching &&
        (data.length ? (
          data.map((table) => <Table key={table.id} onClick={() => saveSelection(table.id)} {...table} />)
        ) : (
          <ZeroState />
        ))}
    </div>
  );
});
