import React from 'react';

import styles from './BlogsListLayoutSkeleton.module.scss';

export default (props) => {
  const { articlesCount } = props;
  return (
    <div className={styles.container} data-test-id="blogs-list-layout-skeleton-container">
      {[...Array(articlesCount).keys()].map((item) => (
        <div key={item} className={styles.snippet}>
          <label>{item === 0 ? 'Latest story' : 'Next latest story'}</label>
          <div className={styles.imageHolder} />
          <div className={styles.largeTextSkeleton} />
          <div className={styles.mediumTextSkeleton} />
          <div className={styles.smallTextSkeleton} />
        </div>
      ))}
    </div>
  );
};
