const rgbaValidation = (value) => {
  const rgbRegex = /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/;
  return value.match(rgbRegex);
};

export const hexValidation = (value = '') => {
  const hexRegex = /^#?\b[0-9A-F]{3}\b|\b[0-9A-F]{4}\b|\b[0-9A-F]{6}\b|\b[0-9A-F]{8}\b/gi;

  return value.match(hexRegex);
};

export const hexAndRgbaValidation = (value = '') => {
  const result = hexValidation(value) || rgbaValidation(value);
  return result;
};
