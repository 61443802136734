import React, { memo, useMemo } from 'react';
import DatePicker from 'lib/react-datepicker';
import format from 'date-fns/format';
import addDays from 'date-fns/addDays';
import setHours from 'date-fns/setHours';
import setMinutes from 'date-fns/setMinutes';
import classNames from 'classnames';

import { Group, Label, TextInput } from 'components/Editor/Toolbars2/Toolbar2';
import { IconDate } from 'components/Editor/Toolbars2/icons';
import { IconTime } from 'components/Editor/Toolbars2/icons';

import styles from './LayoutOption.module.scss';

const DateTimeField = (props: any) => {
  const [datePlaceholder = '', timePlaceholder = ''] = props.property.placeholder || [];
  const isValidDate = (date: Date) => !Number.isNaN(Number(date));

  const dateValue = props.value ? new Date(props.value) : null;

  const defaultValue = useMemo(() => {
    const date = new Date();

    if (props.property.default) {
      date.setHours(0);
      date.setMinutes(0);

      if (props.property.default === 'today') {
        date.setHours(23);
        date.setMinutes(59);
        return date;
      } else if (props.property.default === 'tomorrow') {
        return addDays(date, 1);
      } else {
        const parsedDate = new Date(props.property.default);
        return isValidDate(parsedDate) ? parsedDate : new Date();
      }
    }

    return null;
  }, [props.property.default]);

  if (!props.value && defaultValue) {
    props.onChange(props.property.name, defaultValue.toISOString());
    return null;
  }

  const handleDateChange = (value: Date) => props.onChange(props.property.name, value.toISOString());
  const handleTimeChange = (value: string) => {
    const [hours, minutes] = value.split(':');

    props.onChange(props.property.name, setMinutes(setHours(dateValue, Number(hours)), Number(minutes)));
  };

  return (
    <Group className={props.className}>
      {props.property.label && <Label>{props.property.label}:</Label>}
      <DatePicker
        placeholderText={datePlaceholder}
        className={styles.DateInput}
        dateFormat="MMM d, yyyy"
        todayButton="Today"
        autoComplete="off"
        value={props.value || undefined}
        onChange={handleDateChange}
      />
      <IconDate className={styles.InputIcon} />
      <TextInput
        className={classNames(styles.TimeText, styles.DateInput)}
        value={dateValue ? format(dateValue, 'HH:mm') : undefined}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleTimeChange(e.target.value)}
        placeholder={timePlaceholder}
        border
        type="time"
        disabled={!dateValue || isNaN(dateValue as any)}
      />
      <span className={styles.coverNative}>
        <IconTime className={styles.InputIcon} />
      </span>
    </Group>
  );
};

export default memo(DateTimeField);
