import React from 'react';
import ColorSelector from './StyleGuideEditor/ColorSelector';

import styles from './ShadowInput.module.scss';

export default ({ value, handleChange, name, path }) => {
  let values = value;

  const handleValueChange = (index, value) => {
    values = [...values];
    values[index] = value;
    handleChange(path, values);
  };

  return (
    <div className={styles.container}>
      <input
        type="number"
        name={name}
        value={values[0]}
        min="0"
        max="5"
        step="0.1"
        className={styles.inputField}
        onChange={(e) => handleValueChange(0, e.target.value)}
      />
      <input
        type="number"
        name={name}
        value={values[1]}
        min="0"
        max="5"
        step="0.1"
        className={styles.inputField}
        onChange={(e) => handleValueChange(1, e.target.value)}
      />
      <input
        type="number"
        name={name}
        value={values[2]}
        min="0"
        max="5"
        step="0.1"
        className={styles.inputField}
        onChange={(e) => handleValueChange(2, e.target.value)}
      />
      <ColorSelector
        name={name}
        onChange={(value) => {
          values = [...values];
          values[3] = value;
          handleChange(path, values);
        }}
        value={values[3]}
        placeholder="Color"
        handleColorSelection={(value) => {
          values = [...values];
          values[3] = value;
          handleChange(path, values);
        }}
      />
    </div>
  );
};
