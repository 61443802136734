import React from 'react';

import Option from './Option';
import CheckPermissionOnClick from '../../../../SubscriptionManager/CheckPermissionOnClick';

import { ReactComponent as SvgIcons } from '../../../../../assets/images/icon-media-icons.svg';
import { ReactComponent as SvgImages } from '../../../../../assets/images/icon-media-images.svg';
import { ReactComponent as SvgPdf } from '../../../../../assets/images/icon-media-pdf.svg';
import { ReactComponent as SvgVideos } from '../../../../../assets/images/icon-media-videos.svg';
import { ReactComponent as SvgUnsplash } from '../../../../../assets/images/icon-media-unsplash.svg';
import { ReactComponent as SvgPexels } from '../../../../../assets/images/icon-media-pexels.svg';

import styles from '../MediaDrawerBottom.module.scss';

const NAV_OPTIONS = [
  {
    type: 'Images',
    Icon: <SvgImages />,
    source: 'sparkMedia',
    category: 'image',
  },
  {
    type: 'Products',
    Icon: <SvgImages />,
    source: 'sparkMedia',
    category: 'product',
  },
  {
    type: 'DataTables',
    Icon: <SvgImages />,
    source: 'data_table',
    category: 'data_table',
  },
  {
    type: 'Icons',
    Icon: <SvgIcons />,
    category: 'icon',
    source: 'sparkMedia',
  },
  {
    type: 'PDFs',
    Icon: <SvgPdf height={20} width={20} />,
    source: 'sparkMedia',
    category: 'pdf',
  },
  {
    type: 'Videos',
    Icon: <SvgVideos />,
    source: 'sparkMedia',
    category: 'video',
  },
  {
    type: 'Embeds',
    Icon: <SvgVideos />,
    source: 'embedMedia',
    category: 'embeds',
  },
];

const STOCK_OPTIONS = [
  {
    type: 'Unsplash',
    Icon: <SvgUnsplash />,
    category: 'unsplash',
    source: 'unsplash',
  },
  { type: 'Pexels', Icon: <SvgPexels />, category: 'pexels', source: 'pexels' },
];

export default React.memo((props) => {
  let toShow = props.showAll
    ? 'icon image pdf video product'
    : props.isDataTable
    ? 'data_table icon image video product'
    : 'image icon icons8 video embeds unsplash pexels product';
  toShow = props.hideVideo ? toShow.replace('video', '') : toShow;
  toShow = props.hidePdf ? toShow.replace('pdf', '') : toShow;
  toShow = props.hideEmbeds ? toShow.replace('embeds', '') : toShow;
  toShow =
    props.hideImage && (props.hideIcon === false || props.hideVideo === false) ? toShow.replace('image', '') : toShow;
  toShow = props.hideIcon ? ((toShow = toShow.replace('icon', '')), toShow.replace('icons8', '')) : toShow;
  return (
    <div className={styles.navigation} data-test-id="navigation">
      <label>My media</label>
      {NAV_OPTIONS.map((option) =>
        toShow.includes(option.category) ? (
          <CheckPermissionOnClick
            key={option.category}
            action={`Media:create:::${option.category}`}
            onClick={() => {
              props.handleSourceChange(option.source);
              props.updateCategory(option.category);
            }}
            render={(handleClick) => (
              <Option
                key={option.type}
                option={option}
                active={props.category === option.category}
                onSelect={handleClick}
                updateUploadingStatus={props.updateUploadingState}
                disabledUploads={props.restrictMediaType}
              />
            )}
          />
        ) : null
      )}

      {Boolean(!props.hideStockMedia && !props.isDataTable) && (
        <>
          <label>Stock media</label>
          {STOCK_OPTIONS.map((option) =>
            toShow.includes(option.category) ? (
              <Option
                option={option}
                key={option.type}
                active={props.category === option.category}
                onSelect={() => {
                  props.handleSourceChange(option.source);
                  props.updateCategory(option.category);
                }}
                updateUploadingStatus={props.updateUploadingState}
              />
            ) : null
          )}
        </>
      )}
    </div>
  );
});
