import React, { Component } from 'react';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';

import styles from './MultiSelect.module.scss';

export default class MultiSelect extends Component {
  render() {
    const { options, onTagCreate, onChange, value, placeholder, isCreatable } = this.props;
    const SelectTag = isCreatable ? CreatableSelect : Select;

    return (
      <SelectTag
        isMulti
        placeholder={placeholder}
        maxMenuHeight={129}
        isClearable={false}
        isDisabled={false}
        isLoading={false}
        onChange={(newValue, actionMeta) => {
          onChange(newValue, actionMeta);
        }}
        onCreateOption={onTagCreate}
        options={options}
        value={value}
        className={styles.TagSelect}
        classNamePrefix={styles.prefix}
      />
    );
  }
}
