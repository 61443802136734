import React from 'react';
import styles from './PrefixedInput.module.scss';

class PrefixedInput extends React.PureComponent {
  state = {
    prefixWidth: 0,
    valueWidth: 0,
  };

  componentDidMount() {
    this.updateSizing();

    this.onWindowResize = () => this.updateSizing();
    window.addEventListener('resize', this.onWindowResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onWindowResize);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.value !== this.props.value || prevProps.prefix !== this.props.prefix) {
      this.updateSizing();
    }
  }

  prefixRef = React.createRef();
  postfixRef = React.createRef();
  valueRef = React.createRef();

  prefixWidth = () => {
    if (this.prefixRef.current) return this.prefixRef.current.offsetWidth;
  };

  valueWidth = () => {
    if (this.valueRef.current) return this.valueRef.current.offsetWidth;
  };

  updateSizing = () => {
    this.setState({
      prefixWidth: this.prefixWidth() || 0,
      valueWidth: this.valueWidth() || 3,
    });
  };

  render() {
    const { prefix, postfix, containerClassName, style, value, ...inputProps } = this.props;

    const { prefixWidth, valueWidth } = this.state;

    let inputStyle;
    if (prefix) {
      inputStyle = {
        paddingLeft: `${prefixWidth}px`,
        ...style,
      };
    } else {
      inputStyle = style;
    }

    const postfixStyle = {
      left: `${valueWidth}px`,
    };

    return (
      <div className={styles.container}>
        {value && (
          <span className={styles.valueSizer} ref={this.valueRef}>
            {value.replace(/\s/g, '\u00A0')}
          </span>
        )}
        <input style={inputStyle} value={value} {...inputProps} />
        {prefix && (
          <span className={styles.prefix} ref={this.prefixRef} data-test-id="prefix">
            {prefix}
          </span>
        )}
        {postfix && (
          <span className={styles.prefix} style={postfixStyle} ref={this.postfixRef}>
            {postfix}
          </span>
        )}
      </div>
    );
  }
}

export default PrefixedInput;
