import * as types from '../actions/actionTypes';

import { selectBlogArticles } from './articlesReducer';

import { createSelector } from 'reselect';
import { applyOps, hasOps } from '../lib/immutable-operations/';
import { set, update, del } from '../lib/immutable-operations/operations';

import union from 'lodash/union';
import concat from 'lodash/concat';
import isArray from 'lodash/isArray';
import without from 'lodash/without';
import keyBy from 'lodash/keyBy';

// ----------------------------------------------------------------------------
// Reducer
// ----------------------------------------------------------------------------

const initState = {
  isFetching: null,
  byId: {},
  allIds: [],
  autosaveOpsById: {},
};

export default function blogsReducer(state = initState, action) {
  switch (action.type) {
    // Blog list
    // ------------------------------------------------------------------------

    case types.BLOGS_REQUEST_LIST:
      return applyOps(state, {
        type: 'set',
        path: 'isFetching',
        value: true,
      });

    case types.BLOGS_RECEIVE_LIST: {
      const { blogIds, entities, receivedAt } = action.payload;
      const blogs = entities.blogs || {};

      const newState = applyOps(state, [
        set('isFetching', false),
        set('byId', blogs),
        set('allIds', blogIds),
        set('lastUpdated', receivedAt),
      ]);

      // Loop over pre-existing blogs and replace their content which was just
      // overwritten
      state.allIds.forEach((id) => {
        const oldBlog = state.byId[id];
        const newBlog = newState.byId[id];

        if (oldBlog && newBlog) {
          if (oldBlog.content) newBlog.content = oldBlog.content;
          if (oldBlog.experiments) newBlog.experiments = oldBlog.experiments;
          if (oldBlog.articles) newBlog.articles = oldBlog.articles;
        }
      });

      return newState;
    }

    // Request & receive blog
    // ------------------------------------------------------------------------

    case types.BLOG_REQUEST: {
      const { blogId } = action.payload;
      return applyOps(state, [set(['byId', blogId, 'isFetching'], true)]);
    }

    case types.BLOG_RECEIVE: {
      const { blog } = action.payload;
      return applyOps(state, [set(['byId', blog.id], blog), update(['allIds'], (ids) => union(ids, [blog.id]))]);
    }

    // Update
    // ------------------------------------------------------------------------

    case types.BLOG_WILL_REQUEST_UPDATE:
      return applyOps(state, [set(['byId', action.payload.blogId, 'isFetching'], true)]);

    case types.BLOG_RECEIVE_UPDATE_SUCCESS:
    case types.BLOG_RECEIVE_UPDATE_ERROR:
      return applyOps(state, [set(['byId', action.payload.blogId, 'isFetching'], false)]);

    // Autosave
    // ------------------------------------------------------------------------

    case types.BLOG_ADD_AUTOSAVE_OPS: {
      const { blogId, ops } = action.payload;

      return applyOps(state, [
        update(['autosaveOpsById', blogId], (oldOps) => {
          if (isArray(oldOps)) return concat(oldOps, ops);
          else return concat(ops);
        }),
        set(['byId', blogId, 'is_changed'], true),
      ]);
    }

    case types.BLOG_REMOVE_AUTOSAVE_OPS: {
      const { blogId, ops } = action.payload;
      return applyOps(
        state,
        update(['autosaveOpsById', blogId], (oldOps) => {
          return without(oldOps, ...ops);
        })
      );
    }

    // Delete
    // ------------------------------------------------------------------------

    case types.BLOG_REQUEST_DELETE: {
      const { blogId } = action.payload;
      return applyOps(state, [
        del(['byId', blogId]),
        update('allIds', (allIds) => allIds.filter((id) => id !== blogId)),
      ]);
    }

    case types.BLOG_REQUEST_AB_TEST_STARTED: {
      const { blogId, content } = action.payload;
      state.byId[blogId].experiments = [...state.byId[blogId].experiments, content];
      const newState = { ...state };
      return newState;
    }

    case types.BLOG_REQUEST_AB_TEST_UPDATE: {
      const { blogId, content } = action.payload;
      state.byId[blogId].experiments = state.byId[blogId].experiments.map((e) => (e.id === content.id ? content : e));

      const newState = { ...state };
      return newState;
    }

    // Default
    // ------------------------------------------------------------------------

    default:
      return state;
  }
}

// ----------------------------------------------------------------------------
// Selectors
// ----------------------------------------------------------------------------

// Raw selectors
// ----------------------------------------------------------------------------

function selectLocalState(globalState) {
  return globalState.blogs;
}

export function selectHasLoaded(globalState) {
  const state = selectLocalState(globalState);
  return !!state.lastUpdated;
}

export function selectIsFetching(globalState) {
  const state = selectLocalState(globalState);
  return state.isFetching;
}

export function selectAllIds(globalState) {
  const state = selectLocalState(globalState);
  return state.allIds;
}

export function selectById(globalState) {
  const state = selectLocalState(globalState);
  return state.byId;
}

export function selectAutosaveOpsById(globalState) {
  const state = selectLocalState(globalState);
  return state.autosaveOpsById;
}

// Composit selectors
// ----------------------------------------------------------------------------

export function selectBlog(globalState, blogId) {
  const byId = selectById(globalState);
  return byId[blogId];
}

export const selectAll = createSelector(selectAllIds, selectById, (allIds, byId) => allIds.map((id) => byId[id]));

export const selectAllDrafts = createSelector(selectAll, selectAutosaveOpsById, (all, autosaveOpsById) =>
  all.map((blog) => {
    const autosaveOps = autosaveOpsById[blog.id];
    if (autosaveOps == null || autosaveOps.length === 0) return blog;
    else return applyOps(blog, autosaveOps);
  })
);

export const selectDraftsById = createSelector(selectAllDrafts, (drafts) => keyBy(drafts, 'id'));

export function selectDraft(globalState, blogId) {
  const draftsById = selectDraftsById(globalState);
  return draftsById[blogId];
}

export function selectExperiments(globalState, blogId) {
  const draftsById = selectDraftsById(globalState);
  return draftsById[blogId].experiments;
}

export function selectHasUnsavedChanges(globalState, blogId) {
  const autosaveOpsById = selectAutosaveOpsById(globalState);
  return hasOps(autosaveOpsById[blogId]);
}

export function selectStatus(globalState, blogId) {
  const blogArticles = selectBlogArticles(globalState, blogId);
  if (blogArticles && blogArticles.some((article) => article.status === 'published')) {
    return 'published';
  }

  return 'draft';
}
