import React, { MouseEventHandler, useCallback, useEffect, useState } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { animated, useSpring } from '@react-spring/web';

import useMeasure from 'hooks/useMeasure';
import SectionLabel from './NavSectionLabel';
import CheckPermissionOnClick from 'components/SubscriptionManager/CheckPermissionOnClick';

import styles from './Nav.module.scss';
import { useSelector } from 'react-redux';
import { selectHasPermission } from 'reducers/policyReducer';

export interface NavOptionItem {
  panel: string;
  permissions: Array<{ action: string }>;
  requiredIntegrations?: Array<string>;
  nestedLinks?: string[];
  children: Array<NavOptionChild>;
  icon?: React.ReactNode;
  link?: string;
  render?: (props: any) => React.ReactElement;
}

export interface NavOptionChild {
  panel: string;
  permissions: Array<{ action: string }>;
  requiredIntegrations?: Array<string>;
  link: string;
  icon?: React.ReactNode;
}

interface NavOptionProps {
  item: NavOptionItem;
  setPanel: (panel: string) => void;
  panel: string;
  handleNavigationItemClick: (event: React.MouseEvent, path: string) => void;
  forceActive?: boolean;
}

const NavOption = (props: NavOptionProps) => {
  const { item, setPanel, panel, handleNavigationItemClick, forceActive = false } = props;

  const hasPermission = useSelector(selectHasPermission);
  const { urlSite } = useParams<{ urlSite: string }>();

  const defaultHeight = 0;
  const [contentHeight, setContentHeight] = useState(defaultHeight);
  const [ref, { height }] = useMeasure();

  const resize = useCallback(
    (e: any) => {
      if (e.key === 'Escape' && contentHeight) {
        setContentHeight(height);
      }
    },
    [setContentHeight, contentHeight]
  );

  useEffect(() => {
    setContentHeight(height);
    document.addEventListener('resize', resize);
    return () => document.removeEventListener('resize', resize);
  }, [height]);

  return (
    <React.Fragment>
      <SectionLabel
        value={item.panel}
        icon={item.icon}
        link={item.link}
        render={item.render}
        urlSite={urlSite}
        onClick={(e) => {
          setPanel(item.panel);

          if (item.children.length) {
            handleNavigationItemClick(e, `/${urlSite}/${item.children[0].link}`);
          }
        }}
        isActive={item.panel === panel}
      />
      {Boolean(item.children.length) && (
        <animated.div
          className={styles.animatedDiv}
          style={useSpring({
            height: panel === item.panel ? `${contentHeight}px` : `${defaultHeight}px`,
            marginBottom: panel === item.panel && !item.link ? '20px' : '0px',
          })}
        >
          <div ref={ref}>
            {item.children.map((child) => {
              if (
                (child.link === 'dynamic-pages' && !hasPermission('DataTable')) ||
                (child.link === 'data-tables' && !hasPermission('DataTable:create'))
              ) {
                return null;
              }
              return (
                <CheckPermissionOnClick
                  key={child.panel}
                  action={child.permissions.map((permission) => permission.action)}
                  onClick={(e: React.MouseEvent) => handleNavigationItemClick(e, `/${urlSite}/${child.link}`)}
                  render={(handleClick: MouseEventHandler<HTMLAnchorElement>) => {
                    return (
                      <NavLink
                        to={`/${urlSite}/${child.link}`}
                        data-test-id={`${child.link}-child`}
                        onClick={handleClick}
                        className={({ isActive }) => styles.links + ' ' + (isActive ? styles.active : '')}
                      >
                        {child.icon} {child.panel}
                      </NavLink>
                    );
                  }}
                />
              );
            })}
          </div>
        </animated.div>
      )}
    </React.Fragment>
  );
};

export default NavOption;
