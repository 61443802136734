import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { usePostHog } from 'posthog-js/react';
import { logout } from '../../actions/accountActions';
import { Navigate } from 'react-router-dom';

function Logout() {
  const dispatch = useDispatch();
  const posthog = usePostHog();

  useEffect(() => {
    posthog?.reset();
    dispatch(logout());
  }, [dispatch]);

  return <Navigate to="/login" />;
}

export default Logout;
