import React from 'react';
import { connect } from 'react-redux';

import * as dataTablesActions from '../actions/dataTablesActions';
import * as dataTablesSelectors from '../reducers/dataTablesReducer';

function mapStateToProps(state) {
  return {
    isFetching: dataTablesSelectors.selectIsFetching(state),
    tables: dataTablesSelectors.selectTables(state),
    selectedTable: dataTablesSelectors.selectSelectedTable(state),
  };
}

const mapDispatchToProps = {
  fetchIfNeeded: dataTablesActions.requestIfNeeded,
};

export default (WrappedComponent) => {
  class Container extends React.PureComponent {
    componentDidMount() {
      this.props.fetchIfNeeded();
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  return connect(mapStateToProps, mapDispatchToProps)(Container);
};
