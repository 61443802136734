import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as actions from '../../actions/uiInProgressContentTestSettingsActions';
import * as selectors from '../../reducers/uiInProgressContentTestSettingsReducer';
import { selectWinningVariantId, selectVariantMetaDataById } from '../../reducers/contentTestsReducer';

import { IconCheckedCircleGreen } from './Toolbars2/icons';

import SideDrawer from '../base/SideDrawer';

import idx from 'lodash/get';

export default function NewContentTestSettings() {
  const { isOpen, contentTest } = useSelector(selectors.selectProps);

  const dispatch = useDispatch();

  // Fetch content tests
  // useEffect(() => {
  //   dispatch(requestAllContentTestsIfNeeded());
  // }, [dispatch]);

  const close = useCallback(() => {
    dispatch(actions.close());
  }, [dispatch]);

  return (
    <SideDrawer title="A/B test in progress" isOpen={isOpen} close={close}>
      {contentTest && <Form />}
    </SideDrawer>
  );
}

function Form(props) {
  const { contentTest, selectedVariantId } = useSelector(selectors.selectProps);

  const dispatch = useDispatch();

  function terminateAndPublish(e) {
    e.preventDefault();
    if (!selectedVariantId) return;
    dispatch(actions.requestEnd(contentTest.id, selectedVariantId, true));
  }

  function terminateAndDontPublish(e) {
    e.preventDefault();
    if (!selectedVariantId) return;
    dispatch(actions.requestEnd(contentTest.id, selectedVariantId, false));
  }

  function handleUpdate(payload) {
    dispatch(actions.update(payload));
  }

  const contentTestName = contentTest ? contentTest.name : '';
  const winningVariantId = useSelector((state) => selectWinningVariantId(state, contentTest.id));
  const variantMetadataById = useSelector((state) => selectVariantMetaDataById(state, contentTest.id));

  return (
    <form>
      <fieldset>
        <label>Name</label>
        <p>{contentTestName}</p>
      </fieldset>
      <fieldset>
        <label>Pick winner</label>
        <div className="radio-group">
          {contentTest.variants.map((variant) => {
            const conversionRate = idx(variantMetadataById, [variant.id, 'conversion_rate'], 0);
            const conversionRatePercent = (conversionRate * 100).toFixed(1);

            return (
              <label key={variant.id}>
                <input
                  type="radio"
                  checked={variant.id === selectedVariantId}
                  onChange={() => handleUpdate({ selectedVariantId: variant.id })}
                  readOnly
                />
                <div className="title">
                  {variant.name} &nbsp;
                  <small>{conversionRatePercent}%</small> &nbsp;
                  {variant.id === winningVariantId && <IconCheckedCircleGreen />}
                </div>
              </label>
            );
          })}
        </div>
      </fieldset>
      <fieldset>
        <input
          type="submit"
          data-test-id="end-test-and-publish-page"
          className="button button-primary button-full"
          onClick={terminateAndPublish}
          value="End test and publish page"
          disabled={!selectedVariantId}
        />
        <button
          type="submit"
          data-test-id="end-test-and-dont-publish-page"
          className="button button-full"
          onClick={terminateAndDontPublish}
          disabled={!selectedVariantId}
        >
          End test and don't publish page
        </button>
      </fieldset>
      <p>
        <small>Pick a winner to end the test.</small>
      </p>
    </form>
  );
}
