import Quill from '../quill-1.3.7/quill';

import noMediaSvg from '../../../assets/images/icon-media-removed.svg';

const BlockEmbed = Quill.import('blots/block/embed');

class ImageBlot extends BlockEmbed {
  static create(value) {
    let node = super.create();
    let img = document.createElement('img');

    // Wrap img element in node (<figure>)
    node.appendChild(img);

    // Parse smart url
    const parsedUrl = window.parseSmartUrl ? window.parseSmartUrl(value.src) : value.src;

    // Preserve the smartUrl by storing it on the data-raw-src
    // attribute so it can be retrieved properly in Blot#value.
    node.dataset.rawSrc = value.src;
    node.dataset.optimization = value.optimization;
    if (value.link) node.foobarLink = value.link;

    // Set src as parsed url.
    img.setAttribute('src', parsedUrl);

    img.setAttribute('onerror', `this.src="${noMediaSvg}"`);

    // Set `alt` tag
    if (value.alt) img.setAttribute('alt', value.alt);

    return node;
  }

  static value(node) {
    const value = {};

    const img = node.firstElementChild;

    value.src = node.dataset.rawSrc === 'undefined' ? undefined : node.dataset.rawSrc;

    value.optimization =
      node.dataset.optimization === 'undefined' ? 'resize-compress-nextgen' : node.dataset.optimization;

    if (node.foobarLink) value.link = node.foobarLink;

    const alt = img?.getAttribute('alt');
    if (alt) value.alt = alt;

    return value;
  }
}
ImageBlot.blotName = 'image';
ImageBlot.tagName = 'figure';

export default ImageBlot;
