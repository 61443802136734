import React, { useState, useEffect } from 'react';

import styles from './StyleGuideEditor.module.scss';

interface LetterSpacingFieldProps {
  name: string;
  value: string;
  handleChange: (value: string) => void;
}

const MarginField = ({ value, handleChange, name }: LetterSpacingFieldProps) => {
  const [marginInput, setMarginInput] = useState(value.replace(/rem\s*/g, ' ').trim());

  useEffect(() => {
    setMarginInput(value.replace(/rem\s*/g, ' ').trim());
  }, [value]);

  const handleSetMargin = (input: string) => {
    const marginArray = input.trim().split(/\s+/g);
    switch (marginArray.length) {
      case 0:
        handleChange(value);
        break;
      case 1:
        handleChange(`${Number(marginArray[0]) ? Number(marginArray[0]) : 0}rem`);
        break;
      case 2:
        if (Number(marginArray[0]) === Number(marginArray[1])) {
          handleChange(`${Number(marginArray[0]) ? Number(marginArray[0]) : 0}rem`);
        } else {
          handleChange(
            `${Number(marginArray[0]) ? Number(marginArray[0]) : 0}rem ${
              Number(marginArray[1]) ? Number(marginArray[1]) : 0
            }rem`
          );
        }
        break;
      case 3:
        if (Number(marginArray[0]) === Number(marginArray[1]) && Number(marginArray[0]) === Number(marginArray[2])) {
          handleChange(`${Number(marginArray[0]) ? Number(marginArray[0]) : 0}rem`);
        } else if (Number(marginArray[0]) === Number(marginArray[2])) {
          handleChange(
            `${Number(marginArray[0]) ? Number(marginArray[0]) : 0}rem ${
              Number(marginArray[1]) ? Number(marginArray[1]) : 0
            }rem`
          );
        } else {
          handleChange(
            `${Number(marginArray[0]) ? Number(marginArray[0]) : 0}rem ${
              Number(marginArray[1]) ? Number(marginArray[1]) : 0
            }rem ${Number(marginArray[2]) ? Number(marginArray[2]) : 0}rem`
          );
        }
        break;
      case 4:
        if (
          Number(marginArray[0]) === Number(marginArray[1]) &&
          Number(marginArray[0]) === Number(marginArray[2]) &&
          Number(marginArray[0]) === Number(marginArray[3])
        ) {
          handleChange(`${Number(marginArray[0]) ? Number(marginArray[0]) : 0}rem`);
        } else if (
          Number(marginArray[0]) === Number(marginArray[2]) &&
          Number(marginArray[1]) === Number(marginArray[3])
        ) {
          handleChange(
            `${Number(marginArray[0]) ? Number(marginArray[0]) : 0}rem ${
              Number(marginArray[1]) ? Number(marginArray[1]) : 0
            }rem`
          );
        } else if (Number(marginArray[1]) === Number(marginArray[3])) {
          handleChange(
            `${Number(marginArray[0]) ? Number(marginArray[0]) : 0}rem ${
              Number(marginArray[1]) ? Number(marginArray[1]) : 0
            }rem ${Number(marginArray[2]) ? Number(marginArray[2]) : 0}rem`
          );
        } else {
          handleChange(
            `${Number(marginArray[0]) ? Number(marginArray[0]) : 0}rem ${
              Number(marginArray[1]) ? Number(marginArray[1]) : 0
            }rem ${Number(marginArray[2]) ? Number(marginArray[2]) : 0}rem ${
              Number(marginArray[3]) ? Number(marginArray[3]) : 0
            }rem`
          );
        }
        break;
      default:
        handleChange(
          `${Number(marginArray[0]) ? Number(marginArray[0]) : 0}rem ${
            Number(marginArray[1]) ? Number(marginArray[1]) : 0
          }rem ${Number(marginArray[2]) ? Number(marginArray[2]) : 0}rem ${
            Number(marginArray[3]) ? Number(marginArray[3]) : 0
          }rem`
        );
        break;
    }
    // handleChange(`${marginInput.trim().replace(/\s+/g, 'rem ')}rem`);
    setMarginInput(value.replace(/rem\s*/g, ' ').trim());
  };

  const handleKeyDown = (e: any) => {
    switch (e.which) {
      case 13:
        handleSetMargin(marginInput);
        break;
      case 27:
        handleChange(value);
        setMarginInput(value.replace(/rem\s*/g, ' ').trim());
        break;
      default:
    }
  };

  const handleMarginInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setMarginInput(e.target.value);
  };

  const handleBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleSetMargin(marginInput);
  };

  return (
    <div className={styles.styleOption}>
      <span>Margin</span>
      <input
        type="text"
        name={name}
        value={marginInput}
        onChange={handleMarginInputChange}
        onKeyDown={handleKeyDown}
        onBlur={handleBlur}
      />
    </div>
  );
};

export default MarginField;
