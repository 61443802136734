import { useState, useEffect } from 'react';
import { batch } from 'react-redux';
import useActiveSite from '../../../hooks/useActiveSite';
import {
  getContacts,
  getContact,
  authorizedGetUrl,
  getContactsPerPage,
  getFilteredContacts,
} from '../../../services/spark-api';

const initData = {
  results: [],
  count: null,
};

export function useContactsData(shouldFetchAll = false) {
  const [isFetching, setIsFetching] = useState();
  const [data, setData] = useState(initData);
  const isLoading = isFetching === true || isFetching === undefined;

  useEffect(() => {
    let isMounted = true;

    async function fetchData(url) {
      setIsFetching(true);

      const isFirstFetch = !url;

      const response = url ? await authorizedGetUrl(url) : await getContacts();

      if (isMounted && response.ok) {
        const willFetchNextPage = shouldFetchAll && !!response.json.next;

        const newResults = isFirstFetch ? response.json.results : [...data.results, ...response.json.results];

        batch(() => {
          setIsFetching(willFetchNextPage);
          setData({
            count: response.json.count,
            results: newResults,
          });
        });

        if (willFetchNextPage) fetchData(response.json.next);
      }
    }

    fetchData();

    return () => (isMounted = false);
  }, [shouldFetchAll]); // eslint-disable-line react-hooks/exhaustive-deps

  return [data, isLoading];
}

export function useContactDetailData(contact) {
  const [isFetching, setIsFetching] = useState();
  const [data, setData] = useState();
  const isLoading = isFetching === true || isFetching === undefined;

  useEffect(() => {
    async function fetchData() {
      setIsFetching(true);

      const response = await getContact(contact.id);

      if (response.ok) {
        batch(() => {
          setIsFetching(false);
          setData(response.json);
        });
      }
    }

    fetchData();
  }, [contact]);

  return [data, isLoading];
}

export function useContactsDataPerPage({
  pageNumber = 1,
  searchTerm = '',
  filter = '',
  searchResultPageNumber = 1,
  refreshFlag,
}) {
  const [isFetching, setIsFetching] = useState();
  const [data, setData] = useState(initData);
  const isLoading = isFetching === true || isFetching === undefined;
  const siteId = useActiveSite((site) => site.id);

  useEffect(() => {
    async function fetchData() {
      setIsFetching(true);

      const response =
        searchTerm || filter
          ? await getFilteredContacts(siteId, searchTerm, filter, searchResultPageNumber)
          : await getContactsPerPage(siteId, pageNumber);

      (searchTerm || filter) &&
        response.json.results &&
        response.json.results.forEach((result) => (result.query = searchTerm));

      setIsFetching(false);
      setData({
        count: response.json.count,
        results: response.json.results,
      });
    }

    fetchData();
  }, [pageNumber, searchTerm, filter, searchResultPageNumber, refreshFlag]); // eslint-disable-line react-hooks/exhaustive-deps

  return [data, isLoading];
}
