import * as types from '../actions/actionTypes';
import { createSelector } from 'reselect';

// ----------------------------------------------------------------------------
// Reducer
// ----------------------------------------------------------------------------

const INITIAL_STATE = {
  fetched: [],
  isLoading: false,
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.NOTES_FETCH_FILTERED_NOTES:
      return {
        ...state,
        isLoading: true,
      };
    case types.NOTES_FETCH_SUCCESS:
      return {
        ...state,
        fetched: action.payload,
        isLoading: false,
      };

    default:
      return state;
  }
}

// ----------------------------------------------------------------------------
// Selectors
// ----------------------------------------------------------------------------

const selectLocalState = (globalState) => globalState.notes;

export const selectAllNotes = createSelector([selectLocalState], (notes) => notes.fetched);

export const selectIsLoading = createSelector([selectLocalState], (notes) => notes.isLoading);
