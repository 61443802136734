import React from 'react';
import { MediaInstanceTagArgs } from '../../../tag-types';
import Placeholder from './MediaElement/Placeholder';
import AuthenticatedMedia from 'containers/AuthenticatedMedia';
import PlaceholderProduct from './MediaElement/ProductPlaceholder';
import placeholderProductImage from '../../../../../assets/images/placeholder-product.svg';
import styles from './MediaInstance.module.scss';
interface MediaInstanceInterface extends ValueOnChangeProps<MediaProps>, MediaInstanceTagArgs {
  value: any;
  type?: string;
  content?: any;
}

const MediaInstanceElement: React.FunctionComponent<MediaInstanceInterface> = ({ dataRef, ...props }) => {
  const value = dataRef || {};
  const thumbnail = value.featured_image || value.image || value.src || value.url;
  const { aspectRatio, className, layout, type } = props;
  return thumbnail ? (
    <AuthenticatedMedia className={className} src={thumbnail} layout={layout || 'responsive'} />
  ) : type === 'product-collection' ? (
    <div className={styles.productImage}>
      <img className={styles.productImageIcon} src={placeholderProductImage} />
    </div>
  ) : type === 'product' ? (
    <PlaceholderProduct alignment={props?.content?.base?.section?.properties?.layout} />
  ) : (
    <Placeholder
      aspectRatio={aspectRatio}
      label={'image'}
      // brightnessTheme={context.brightnessTheme}
    />
  );
};

export default MediaInstanceElement;
