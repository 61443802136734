import applyOp from './applyOp';

import isArray from 'lodash/isArray';
import forEach from 'lodash/forEach';

export function hasOps(ops) {
  return isArray(ops) && ops.length !== 0;
}

export function applyOps(src, ops) {
  if (!isArray(ops)) {
    ops = [ops];
  }

  let dest = src;

  forEach(ops, (op) => {
    dest = applyOp(dest, op);
  });

  return dest;
}

export default {
  hasOps,
  applyOps,
};
