/* eslint-disable jsx-a11y/anchor-is-valid, jsx-a11y/alt-text */

import React from 'react';
import { Link } from 'react-router-dom';
import { compose } from 'redux';
import withPermissions from '../../../containers/withPermissions';
import { connect } from 'react-redux';
import * as siteSelectors from '../../../reducers/sitesReducer';
import * as uiSelectors from '../../../reducers/uiReducer';

import SiteLogo from '../SiteLogo';
import {
  TYPES as SOCIAL_MEDIA_TYPES,
  ICONS as SOCIAL_MEDIA_ICONS,
} from '../../AdminPanel/Elements/HeaderFooterEditor/SocialMediaIcons';
import { ReactComponent as SvgTextLinkArrow } from '../../../assets/images/icon-text-link-arrow.svg';
import RichTextEditor from '../../../lib/quill/components/RichTextEditor';

import styles from './HeaderFooterOverlay.module.scss';

import idx from 'lodash/get';
import classnames from 'classnames';
import withSite from 'containers/withSite';

function mapStateToProps(state, ownProps) {
  const { site } = ownProps;

  return {
    urlSite: siteSelectors.getUrlSiteFromSite(site),
    homePageUrl: uiSelectors.selectHomePageUrl(state),
    parseSmartUrl: uiSelectors.selectParseSmartUrl(state),
    hideSparkBranding: site.elements.hideSparkBranding,
  };
}

function Footer(props) {
  const { site, urlSite, homePageUrl, parseSmartUrl, hideSparkBranding, hasPermission } = props;

  const footerMenu = site.menus.footer;
  const contactInfo = site.content.contact_info;
  const siteStyles = site.styles;

  const footerBrightnessTheme = idx(siteStyles, 'footerBrightnessTheme');

  const items = footerMenu.items || [];
  const renderedMenuItems = items.map((item, index) => (
    <div key={index} className="menu">
      <h4>{item.text}</h4>
      <ul>
        {item.items &&
          item.items.map((childItem, childIndex) => (
            <li key={childIndex}>
              <a href={parseSmartUrl(childItem.url)}>{childItem.text}</a>
            </li>
          ))}
      </ul>
    </div>
  ));

  const social = footerMenu.social || {};
  const renderedSocialIcons = [];
  SOCIAL_MEDIA_TYPES.forEach((type, index) => {
    const item = social[type];
    const icon = SOCIAL_MEDIA_ICONS[type];
    if (item) {
      renderedSocialIcons.push(
        <li key={index}>
          <a href={item.url} target="_blank" rel="noopener noreferrer">
            {icon}
          </a>
        </li>
      );
    }
  });

  return (
    <footer className={classnames('main-footer', footerBrightnessTheme, styles.withOverlay)}>
      <section className="primary-footer">
        <div className="container">
          <div className="info">
            <Link
              className="logo"
              to={homePageUrl || '#'}
              disabled={homePageUrl == null}
              data-tip="You can update your logo in Header & Footer"
            >
              <SiteLogo placement="footer" site={site} />
            </Link>
            <div className="contact-info">
              <RichTextEditor value={contactInfo} readOnly />
            </div>
          </div>
          <div className="menus">{renderedMenuItems}</div>
        </div>
      </section>
      <section className="secondary-footer">
        <div className="container">
          <div className="copyright">
            © {site.name}
            {(hasPermission('Site:debadge') && hideSparkBranding) || (
              <>
                {' '}
                &bull; Made with{' '}
                <a href="https://www.elasticpath.com/products/cx-studio" target="_blank" rel="noopener noreferrer">
                  Elastic Path Studio
                </a>
              </>
            )}
          </div>
          {renderedSocialIcons && (
            <div className="social-icons">
              <ul>{renderedSocialIcons}</ul>
            </div>
          )}
        </div>
      </section>
      <div className={classnames('admin', styles.overlay)}>
        <Link to={`/${urlSite}/elements`}>
          You can change your footer in Header & Footer <SvgTextLinkArrow />
        </Link>
      </div>
    </footer>
  );
}

export default compose(withPermissions, withSite, connect(mapStateToProps))(Footer);
