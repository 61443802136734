import * as Acorn from 'acorn';
import * as AcornJSX from 'acorn-jsx';
import React, { Fragment } from 'react';
import parseHtmlToReact from 'html-react-parser';
import { parseStyle } from './helpers/parseStyle';
import { resolvePath } from './helpers/resolvePath';
import ATTRIBUTES from './constants/attributeNames';
import { canHaveChildren, canHaveWhitespace } from './constants/specialTags';

import get from 'lodash/get';
import range from 'lodash/range';
import { set, del, insert, push, assign } from 'object-path-immutable';

import Box from '../elements/block/Box';
import Item from '../elements/block/Item';
import Cart from '../elements/block/Cart';
import Header from '../elements/block/Header';
import Footer from '../elements/block/Footer';
import Section from '../elements/block/Section';
import SearchResults from '../elements/block/SearchResults';
import SearchFilter from '../elements/block/SearchFilter';
import SearchSort from '../elements/block/SearchSort';
import SearchTags from '../elements/block/SearchTags';

import Code from '../elements/inline/Code';
import Media from '../elements/inline/Media';
import Actions from '../elements/inline/Actions';
import RichText from '../elements/inline/RichText';
import PlainText from '../elements/inline/PlainText';
import MediaInstance from '../elements/inline/MediaInstance';
import Product from '../elements/block/ProductElement/Product';
import Button from '../elements/block/Button';

import OverrideElement from './OverrideElement';
import Article from '../elements/generator/Article';
import Collection from '../elements/generator/Collection';
import Generator from '../elements/generator/Generator';
import JSXParsingError from './JSXParsingError';
import ProductCollection from '../elements/generator/ProductCollection';
import ProductPrice from '../elements/inline/ProductPrice';
import ProductInventory from '../elements/inline/ProductInventory';
import ProductOptions from '../elements/inline/ProductOptions';
import QuantitySelector from '../elements/inline/QuantitySelector';
import UElement from '../elements/block/UElement';
import { UContext } from 'types/UContext';
import { SectionHandlersInterface, SectionUiHandlersInterface } from 'components/unstack-components/types';
import { Device, getCombinedDeviceContent } from './helpers/deviceHelper';
import { getDevice } from 'reducers/uiReducer';
import { connect } from 'react-redux';
import { UBoxInfo } from 'types/USection';
import CartLineItems from '../elements/block/CartLineItems';
import SystemForm from '../elements/inline/SystemForm';
import JsonParser from '../elements/inline/JsonParser';
import Template from '../elements/block/Template';

const BLACKLISTED_ATTR: Array<string | RegExp> = [/^on.+/i];
let LastParsedJSX: JSX.Element = null;

const components = {
  Section,
  Header,
  Footer,
  Cart,
  CartLineItems,
  Banner: Section,
  Box,
  Item,
  Code,
  PlainText,
  RichText,
  Media,
  Actions,
  Collection,
  ArticleCollection: Article,
  MediaInstance,
  Product,
  Generator,
  ProductCollection,
  ProductPrice,
  ProductInventory,
  ProductOptions,
  QuantitySelector,
  Element: UElement,
  Template,
  Button,
  CartButton: Button,
  CartCheckoutButton: Button,
  CartError: Template,
  Checkout: Section,
  Modal: Section,
  Form: SystemForm,
  JsonParser,
  SearchResults,
  SearchFilter,
  SearchTags,
  SearchSort,
  GDPRButton: Button,
};

type ParsedJSX = JSX.Element | boolean | string;
type ParsedTree = ParsedJSX | ParsedJSX[] | null;
export type ParserProps = {
  jsx?: string;
  context: UContext;
  sectionHandlers: SectionHandlersInterface;
  sectionUiHandlers: SectionUiHandlersInterface;
  onContextChange: (context: UContext) => void;
  componentsDefaults: any;
  jsxErrorCallback?: (error: ParserError) => void;
  device: Device;
  combinedContent: { content: UBoxInfo };
};

export interface ParserError extends Error {
  loc: { line: number; column: number };
}

type Scope = Record<string, any>;

function mapStateToProps(state: any, props: ParserProps) {
  const device = getDevice(state);
  const content = props.context.content.content
    ? getCombinedDeviceContent(props.context.content.content, device)
    : ({} as UBoxInfo);
  return {
    device,
    combinedContent: { content },
  };
}

class JsxParser extends React.Component<ParserProps> {
  static displayName = 'JsxParser';

  private ParsedChildren: ParsedTree = null;
  private properties: any = [];

  parseJSX = (jsx: string) => {
    const parser = Acorn.Parser.extend(
      AcornJSX.default({
        // @ts-ignore
        autoCloseVoidElements: this.props.autoCloseVoidElements,
      })
    );
    const wrappedJsx = `<root>${jsx}</root>`;

    let parsed: AcornJSX.Expression[] = [];
    try {
      // @ts-ignore
      parsed = parser.parse(wrappedJsx, { ecmaVersion: 'latest' });

      // @ts-ignore
      parsed = parsed.body[0].expression.children || [];

      this.ParsedChildren = parsed
        .map((p) => this.parseExpression(p, { rootEl: (parsed[0] as any).openingElement.name.name }))
        .filter(Boolean);
      LastParsedJSX = <>{this.ParsedChildren}</>;
      if (this.props.jsxErrorCallback) this.props.jsxErrorCallback(undefined);
      return LastParsedJSX;
    } catch (e) {
      if (this.props.jsxErrorCallback) this.props.jsxErrorCallback(e as ParserError);
      return <JSXParsingError LastParsedJSX={LastParsedJSX} />;
    }
  };

  parseExpression = (expression: AcornJSX.Expression, scope?: Scope): any => {
    switch (expression.type) {
      case 'JSXAttribute':
        if (expression.value === null) return true;
        return this.parseExpression(expression.value, scope);
      case 'JSXElement':
      case 'JSXFragment':
        return this.parseElement(expression, scope);
      case 'JSXExpressionContainer':
        return this.parseExpression(expression.expression, scope);
      case 'JSXText':
        return expression.value;
      case 'ArrayExpression':
        return expression.elements.map((ele: any) => this.parseExpression(ele, scope)) as ParsedTree;
      case 'ExpressionStatement':
        return this.parseExpression(expression.expression);
      case 'Identifier':
        if (scope && expression.name in scope) {
          return scope[expression.name];
        }
        return get(this.props.combinedContent, expression.name, '');

      case 'Literal':
        return expression.value;
      case 'MemberExpression':
        return this.parseMemberExpression(expression, scope);
      case 'ObjectExpression':
        const object: Record<string, any> = {};
        expression.properties.forEach((prop: any) => {
          object[prop.key.name! || prop.key.value!] = this.parseExpression(prop.value);
        });
        return object;
      case 'TemplateElement':
        return expression.value.cooked;
      case 'TemplateLiteral':
        return [...expression.expressions, ...expression.quasis]
          .sort((a, b) => {
            if (a.start < b.start) return -1;
            return 1;
          })
          .map((item) => this.parseExpression(item))
          .join('');
      case 'ArrowFunctionExpression':
        return (...args: any[]): any => {
          const functionScope: Record<string, any> = {};

          expression.params.forEach((param: any, idx: number) => {
            functionScope[param.name] = args[idx];
          });
          return this.parseExpression(expression.body, functionScope);
        };
      case 'LogicalExpression':
        const leftSide = this.parseExpression(expression.left, scope);
        const rightSide = this.parseExpression(expression.right, scope);

        switch (expression.operator) {
          case '&&':
            return leftSide && rightSide;

          case '||':
            return leftSide || rightSide;

          default:
            return null;
        }
      case 'BinaryExpression':
        switch (expression.operator) {
          case '!=':
            return this.parseExpression(expression.left, scope) != this.parseExpression(expression.right, scope);
          case '!==':
            return this.parseExpression(expression.left, scope) !== this.parseExpression(expression.right, scope);
          case '<':
            return this.parseExpression(expression.left, scope) < this.parseExpression(expression.right, scope);
          case '<=':
            return this.parseExpression(expression.left, scope) <= this.parseExpression(expression.right, scope);
          case '==':
            return this.parseExpression(expression.left, scope) == this.parseExpression(expression.right, scope);
          case '===':
            return this.parseExpression(expression.left, scope) === this.parseExpression(expression.right, scope);
          case '>':
            return this.parseExpression(expression.left, scope) > this.parseExpression(expression.right, scope);
          case '>=':
            return this.parseExpression(expression.left, scope) >= this.parseExpression(expression.right, scope);
          default:
            return null;
        }
      case 'UnaryExpression':
        switch (expression.operator) {
          case '!':
            return !this.parseExpression(expression.argument, scope);
          default:
            return null;
        }
      case 'CallExpression':
        const parsedCallee = this.parseExpression(expression.callee, scope);
        if (parsedCallee) {
          const { value = [], callee = () => {} } = parsedCallee;
          return value[callee](...expression.arguments.map((arg: any) => this.parseExpression(arg, expression.callee)));
        }
        return true;
      case 'ConditionalExpression':
        const isTrue = !!eval(this.parseExpression(expression.test));
        if (isTrue) {
          return this.parseExpression(expression.consequent, scope);
        } else {
          return this.parseExpression(expression.alternate, scope);
        }
    }
  };

  parseMemberExpression = (expression: AcornJSX.MemberExpression, scope?: Scope): any => {
    let { object } = expression;
    const path = [expression.property?.name ?? JSON.parse(expression.property?.raw ?? '""')];

    if (expression.object.type !== 'Literal') {
      while (object && ['MemberExpression', 'Literal'].includes(object?.type)) {
        const { property } = object as AcornJSX.MemberExpression;

        if ((object as AcornJSX.MemberExpression).computed) {
          path.unshift(this.parseExpression(property!, scope));
        } else {
          path.unshift(property?.name ?? JSON.parse(property?.raw ?? '""'));
        }

        object = (object as AcornJSX.MemberExpression).object;
      }
    }

    const target = this.parseExpression(object, scope);
    if (target === null) return '';
    try {
      let member;
      let parent = target;
      if (path.includes('properties')) {
        let callee;
        if (path.some((p) => ['includes', 'indexOf'].includes(p))) {
          callee = path.pop();
        }
        let value = get(target, path);
        const propertyName: string = path.pop();
        const dataRefPath = (object as any).name + '.' + path[0];
        const property = this.properties[dataRefPath].find((p: any) => p.name === propertyName);
        if (value === undefined) {
          value = property.default;
        }
        const { prefix = '', suffix = '' } = property;
        if (callee) return { value, callee };
        return `${prefix}${value}${suffix}`;
      } else {
        member = path.reduce((value, next) => {
          parent = value;
          return value[next];
        }, target);
      }
      if (typeof member === 'function') return member.bind(parent);
      //  To keep things moving used this old package we already had.
      //  But would love to have our own custom parser for this as well
      else if (typeof member === 'string') return scope && scope.stringContent ? member : parseHtmlToReact(member);
      return member;
    } catch {
      //  Ignoring catch statement to not overwhelm the browsr console
      // const name = (object as AcornJSX.MemberExpression)?.name || 'unknown';
      // console.error(`Unable to parse ${name}["${path.join('"]["')}"]}`);
    }
  };

  parseName = (element: AcornJSX.JSXIdentifier | AcornJSX.JSXMemberExpression): string => {
    if (element.type === 'JSXIdentifier') {
      return element.name;
    }
    return `${this.parseName(element.object)}.${this.parseName(element.property)}`;
  };

  parseElement = (
    element: AcornJSX.JSXElement | AcornJSX.JSXFragment,
    scope?: Scope
  ): JSX.Element | JSX.Element[] | null => {
    const { children: childNodes = [] } = element;
    const openingTag = element.type === 'JSXElement' ? element.openingElement : element.openingFragment;
    const { attributes = [] } = openingTag;
    const name = element.type === 'JSXElement' ? this.parseName(openingTag.name) : '';

    const blacklistedAttrs = (BLACKLISTED_ATTR || []).map((attr) =>
      attr instanceof RegExp ? attr : new RegExp(attr, 'i')
    );
    if (/^(html|head|body)$/i.test(name)) {
      return childNodes.map((c) => this.parseElement(c, scope)) as JSX.Element[];
    }

    const props: { [key: string]: any } = {};
    attributes.sort(function (x, y) {
      return x.name.name == 'properties' ? -1 : y.name.name == 'properties' ? 1 : 0;
    });

    let properties = {};
    attributes.forEach(
      // eslint-disable-next-line max-len
      (expr: AcornJSX.JSXAttribute | AcornJSX.JSXAttributeExpression | AcornJSX.JSXSpreadAttribute) => {
        if (expr.type === 'JSXAttribute') {
          const rawName = expr.name.name;
          const notCartElement = name !== 'Cart' || (name === 'Cart' && scope.rootEl !== 'Cart');
          if (rawName === 'dataRef' && notCartElement) {
            //  @ts-ignore
            if (expr.value.expression.object) {
              //  @ts-ignore
              let object = expr.value.expression;
              const path = [];
              while (object && ['MemberExpression', 'Literal', 'Identifier'].includes(object?.type)) {
                const { property } = object as AcornJSX.MemberExpression;
                if (property) path.unshift(property?.name ?? JSON.parse(property?.raw ?? '""'));
                else path.unshift(object?.name ?? JSON.parse(property?.raw ?? '""'));

                object = (object as AcornJSX.MemberExpression).object;
              }
              props.contentKey = path.join('.');
            }
          } else if (rawName === 'dataRef' && !notCartElement) {
            if (scope) {
              Object.keys(scope).forEach((id) => {
                props[id] = scope[id];
              });
            }
            props.templateDataRef =
              (expr as any).value?.expression?.object?.name + '.' + (expr as any).value?.expression?.property?.name;
          }
          if (rawName === 'dataRef' && !notCartElement) {
          } else {
            const attributeName = ATTRIBUTES[rawName] || rawName;
            // if the value is null, this is an implicitly "true" prop, such as readOnly
            const value = this.parseExpression(expr, name === 'Code' ? { ...scope, stringContent: true } : scope);

            const matches = blacklistedAttrs.filter((re) => re.test(attributeName));
            if (matches.length === 0) {
              props[attributeName] = value;
            }
            if (attributeName === 'properties') {
              properties = value;
            }
          }
        } else if (
          (expr.type === 'JSXSpreadAttribute' && expr.argument.type === 'Identifier') ||
          expr.argument!.type === 'MemberExpression'
        ) {
          const value = this.parseExpression(expr.argument!, scope);
          if (typeof value === 'object') {
            Object.keys(value).forEach((rawName) => {
              const attributeName: string = ATTRIBUTES[rawName] || rawName;
              const matches = blacklistedAttrs.filter((re) => re.test(attributeName));
              if (matches.length === 0) {
                props[attributeName] = value[rawName];
              }
            });
          }
        }
      }
    );

    if (props.contentKey) {
      this.properties[props.contentKey] = properties;
    }
    props['content'] = this.props.context.content.content;

    let children;
    const customElement = resolvePath(components, name);
    let component = element.type === 'JSXElement' ? customElement : Fragment;

    const isStaticElement = !component;

    if (isStaticElement) {
      component = OverrideElement;
    }

    if (component || canHaveChildren(name)) {
      children = childNodes.map((node) => this.parseExpression(node, scope));
      if (!component && !canHaveWhitespace(name)) {
        children = children.filter((child) => typeof child !== 'string' || !/^\s*$/.test(child));
      }

      if (children.length === 0) {
        children = undefined;
      } else if (children.length === 1) {
        [children] = children;
      } else if (children.length > 1) {
        // Add `key` to any child that is a react element (by checking if it has `.type`) if one
        // does not already exist.

        children = children.map((child, key) =>
          child?.type && !child?.key ? { ...child, key: child.key || key } : child
        );
      }
    }

    if (isStaticElement) {
      props['name'] = name;
    } else {
      props['sectionHandlers'] = this.props.sectionHandlers;
      props['sectionUiHandlers'] = this.props.sectionUiHandlers;
      props['onChange'] = (value: any, key: string, init = false) => {
        if (init) {
          let updatedContext = this.props.context;
          range(0, value.length).forEach((index) => {
            updatedContext = set(updatedContext, key?.[index] || props.contentKey, value?.[index]);
          });

          this.props.onContextChange(updatedContext);
        } else {
          this.props.onContextChange(set(this.props.context, key || props.contentKey, value));
        }
      };
    }

    props['componentId'] = this.props.context.componentId;
    props['defaults'] = this.props.componentsDefaults[name];

    if (typeof props.style === 'string') {
      props.style = parseStyle(props.style);
    }

    const lowerName = name.toLowerCase();
    if (lowerName === 'option' && children.props) {
      children = children.props.children;
    }

    return React.createElement(component || lowerName, props, children);
  };

  render = (): JSX.Element => {
    const jsx = (this.props.jsx || '').trim();
    return this.parseJSX(jsx);
  };
}

export default connect(mapStateToProps, () => {})(JsxParser);
