import { useEffect, useRef } from 'react';

type Callback<T> = (previousValue: T | undefined, currentValue: T) => void;

export default function usePrevious<T>(value: T, callback?: Callback<T>) {
  const ref = useRef<T | undefined>();

  useEffect(() => {
    if (callback && ref.current !== value) callback(ref.current, value);
    ref.current = value;
  }, [value]);

  return ref.current;
}
