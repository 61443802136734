import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { storeToken } from 'services/spark-auth';

export function ShopifyLogin() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  storeToken(searchParams.get('jwt'));
  const newpath = location.pathname.replace('/shopify-login', '').split('?')[0];
  return <Navigate to={newpath} />;
}

export default ShopifyLogin;
