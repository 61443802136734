import React from 'react';
import { connect } from 'react-redux';
import omit from 'lodash/omit';
import { selectParseSmartUrl } from '../reducers/uiReducer';

function mapStateToProps(state, ownProps) {
  const parseSmartUrl = selectParseSmartUrl(state);

  return {
    src: parseSmartUrl(ownProps.src),
  };
}

export default connect(
  mapStateToProps,
  {}
)((props) => <video {...omit(props, ['controls', 'borderRadius', 'optimization'])} />);
