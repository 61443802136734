import React, { useMemo, useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import classnames from 'classnames';
import { CSSTransition } from 'react-transition-group';
import { Search as SearchEngine, AllSubstringsIndexStrategy } from 'js-search';
import sortBy from 'lodash/sortBy';
import { actionTypes } from 'redux-resource';

import Search from 'components/Search';
import useKeyBinding from 'hooks/useKeyBinding';
import { shallowEqualArray } from './Thumbnails';
import usePrevious from '../../../hooks/usePrevious';
import { useResources } from '../../../lib/redux-resource/hooks';
import ComponentThumbnail from 'components/ComponentThumbnail';
import iconUnstackSection from '../../../assets/images/thumbnail-custom-atf@2x.png';
import { ReactComponent as IconArrowRight } from '../../../assets/images/arrow-right.svg';

import styles from './Drawer.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { selectSiteIntegrationsBySlug } from 'reducers/integrationsReducer';
import useActiveSite from 'hooks/useActiveSite';

export default function Drawer(props) {
  useKeyBinding('keydown', 27, props.isOpen && props.close);
  const [left, setLeft] = useState(0);
  const siteId = useActiveSite((site) => site.id);

  const dispatch = useDispatch();

  const { isOpen, addSection, allowedTypes, theme, isCarousel, location } = props;
  const [activeCategory, setActiveCategory] = useState('recent');
  const [hoveredComponent, setHoveredComponent] = useState('');

  const integrationsBySlug = useSelector((state) => selectSiteIntegrationsBySlug(state, siteId));
  const hasAlgolia =
    integrationsBySlug.algolia && integrationsBySlug.algolia[0] && integrationsBySlug.algolia[0].status === 'active';

  const hasGraphene =
    integrationsBySlug.graphenehc &&
    integrationsBySlug.graphenehc[0] &&
    integrationsBySlug.graphenehc[0].status === 'active';

  useEffect(() => {
    dispatch({
      type: actionTypes.READ_RESOURCES_PENDING,
      resourceType: 'recentSections',
      requestKey: 'fetch',
    });
  }, []);

  useEffect(() => {
    setActiveCategory('recent');
  }, [props.isOpen]);

  // Get components (aka "section types")
  const allSectionTypes = useResources('sectionTypes');
  const recentComponents = useResources('recentSections');
  const sectionTypes = useMemo(() => {
    if (allSectionTypes != null && allSectionTypes.filter)
      return allSectionTypes.filter((c) => {
        if (!hasAlgolia && c.slug.includes('algolia')) return false;
        if (!hasGraphene && c.slug.includes('graphenehc')) return false;
        return c.component_type === 'section' || c.component_type === 'custom' || c.component_type === 'checkout';
      });
    return [];
  }, [allSectionTypes]);
  const prevSectionTypes = usePrevious(sectionTypes);

  // Get elements
  const elements = useResources('elements');
  const prevElements = usePrevious(elements);

  //  Get categories
  const categories = useResources('categories');

  //  Hooks for query and search engine state management
  const [allItems, setAllItems] = useState([]);
  const [elementsById, setElementsById] = useState([]);
  const [query, setQuery] = useState('');
  const [engine, setEngine] = useState('');

  useEffect(() => {
    const sectionTypesHaveChanged = !shallowEqualArray(prevSectionTypes, sectionTypes);
    const elementsHaveChanged = !shallowEqualArray(prevElements, elements);
    const recentOutOfSync = recentComponents.length !== allItems?.recent?.length && !allowedTypes;

    if (sectionTypesHaveChanged || elementsHaveChanged || recentOutOfSync) {
      // Combine components and elements
      const allItems = [];
      const categorisedItems = {};
      const elementsById = {};
      if (!allowedTypes) {
        sectionTypes.forEach((resource) => {
          if (!(isCarousel && !!resource.tags.Product) && !resource.is_deleted)
            allItems.push({
              name: resource.name,
              resource: resource,
              type: 'component',
            });
        });

        elements.forEach((resource) =>
          allItems.push({
            name: resource.name,
            resource: resource,
            type: 'element',
          })
        );

        categorisedItems.recent = recentComponents.map((c) =>
          allItems.find((i) => c.id === i.resource.id || c.id === i.resource.parent)
        );

        sortBy(allItems, [(item) => item.name.toLowerCase()]).forEach((item) => {
          const { resource } = item;
          if (categorisedItems[resource.category]) categorisedItems[resource.category].push(item);
          else categorisedItems[resource.category] = [item];
        });
        elements.forEach((el) => {
          if (elementsById[el.component]) elementsById[el.component].push(el);
          else elementsById[el.component] = [el];
        });

        categorisedItems.all = [...sortBy(allItems, [(item) => item.name.toLowerCase()])];
        setElementsById(elementsById);
        setAllItems(categorisedItems);

        // Create the search engine
        let engine = new SearchEngine('name');
        engine.indexStrategy = new AllSubstringsIndexStrategy();
        engine.addIndex('name');
        engine.addDocuments(allItems);
        setEngine(engine);
      } else {
        sectionTypes
          .filter((type) => allowedTypes.includes(type.slug))
          .forEach((resource) =>
            allItems.push({
              name: resource.name,
              resource: resource,
              type: 'element',
            })
          );
        sortBy(allItems, [(item) => item.name.toLowerCase()]).forEach((item) => {
          const { resource } = item;
          if (categorisedItems[resource.category]) categorisedItems[resource.category].push(item);
          else categorisedItems[resource.category] = [item];
        });
        setAllItems(categorisedItems);
      }
    }
  }, [sectionTypes, elements, recentComponents]);

  const filteredItems = useMemo(() => {
    let items = query ? engine.search(query) : allItems[activeCategory]?.filter((item) => !!item) || [];
    if (location === 'header' || location === 'footer' || location === null) {
      items = items.filter(({ resource }) => {
        //  Keeping this check just in case there comes a scenerio for this.
        //  If everything looks good we will get rid of this
        // if (!resource.locations) return true;
        if (location === null) {
          return !resource.locations?.length || resource.locations.includes('main');
        }
        return resource.locations?.length && resource.locations.includes(location);
      });
    }
    return items;
  }, [query, engine, allItems, categories, location]);

  const filteredElements = useMemo(() => {
    return elementsById[hoveredComponent] || [];
  }, [elementsById, hoveredComponent]);

  const close = () => {
    props.close();
    setQuery();
    setActiveCategory('all');
    setHoveredComponent('');
  };

  const handleAddSection = (type, id) => {
    addSection(type, id);

    dispatch({
      type: actionTypes.DELETE_RESOURCES,
      resources: {
        recentSections: recentComponents.map((c) => c.id),
      },
    });
  };

  return (
    <Portal>
      <CSSTransition
        in={isOpen}
        timeout={{
          enter: 200,
          exit: 100,
        }}
        classNames={styles}
        mountOnEnter
        unmountOnExit
      >
        {() => (
          <div>
            <div className={styles.backdrop} onClick={close} />
            <div className={styles.container} data-test-id="add-section-container">
              <div className={styles.categoriesContainer} data-test-id="categories">
                <Search
                  value={query}
                  onChange={(query) => setQuery(query)}
                  placeholder="Search"
                  containerStyle={{
                    width: '200px',
                    marginTop: 0,
                    border: 'none',
                  }}
                  debounceValue={() => {}}
                />
                <div
                  className={classnames(styles.categoriesList, {
                    [styles.disableList]: query,
                  })}
                >
                  <span
                    className={classnames({
                      [styles.activeCategory]: 'recent' === activeCategory,
                    })}
                    onClick={
                      query
                        ? () => {}
                        : () => {
                            setHoveredComponent('');
                            setActiveCategory('recent');
                          }
                    }
                  >
                    Recently Used
                    {activeCategory === 'recent' && <IconArrowRight />}
                  </span>
                  {categories.map((category) => (
                    <span
                      className={classnames({
                        [styles.activeCategory]: category.id === activeCategory,
                      })}
                      onClick={
                        query
                          ? () => {}
                          : () => {
                              setHoveredComponent('');
                              setActiveCategory(category.id);
                            }
                      }
                    >
                      {category.name}
                      {category.id === activeCategory && <IconArrowRight />}
                    </span>
                  ))}
                  <span
                    className={classnames({
                      [styles.activeCategory]: null === activeCategory,
                    })}
                    onClick={
                      query
                        ? () => {}
                        : () => {
                            setHoveredComponent('');
                            setActiveCategory(null);
                          }
                    }
                  >
                    Uncategorized
                    {activeCategory === null && <IconArrowRight />}
                  </span>
                  <span
                    className={classnames({
                      [styles.activeCategory]: 'all' === activeCategory,
                    })}
                    onClick={
                      query
                        ? () => {}
                        : () => {
                            setHoveredComponent('');
                            setActiveCategory('all');
                          }
                    }
                  >
                    All
                    {activeCategory === 'all' && <IconArrowRight />}
                  </span>
                </div>
              </div>
              <div className={styles.componentsContainer} data-test-id="drawer" onClick={close}>
                {!!filteredItems.length && (
                  <div className={styles.componentsList}>
                    <div style={{ height: '102px' }} />
                    {filteredItems.map(({ resource: { name, id, parent, thumbnail }, type }) => (
                      <div className={styles.componentCardContainer} data-test-id="component-card-container">
                        <div
                          onMouseEnter={() => {
                            setHoveredComponent(parent || id);
                            setLeft(0);
                          }}
                          className={classnames(styles.componentCard, {
                            [styles.stacked]: !!elementsById[parent || id],
                            [styles.hovered]: hoveredComponent === (parent || id),
                          })}
                          data-test-id="component-card"
                        >
                          {elementsById[parent || id] && (
                            <>
                              <ComponentThumbnail
                                key={`${id}-1`}
                                onClick={() => {}}
                                src={thumbnail || iconUnstackSection}
                                label={name}
                                theme={theme}
                              />
                              <ComponentThumbnail
                                key={`${id}-2`}
                                onClick={() => {}}
                                src={thumbnail || iconUnstackSection}
                                label={name}
                                theme={theme}
                              />
                            </>
                          )}
                          <ComponentThumbnail
                            key={id}
                            onClick={(e) => {
                              e.stopPropagation();
                              if (!!!elementsById[parent || id]) handleAddSection(type, parent || id);
                            }}
                            src={thumbnail || iconUnstackSection}
                            label={name}
                            theme={theme}
                            extended={hoveredComponent === (parent || id)}
                          />
                        </div>
                        {!!elementsById[parent || id] && (
                          <div
                            className={classnames(styles.iconExtend, {
                              [styles.transition]: hoveredComponent === (parent || id),
                            })}
                            data-test-id="icon-extend"
                          >
                            <span>
                              <IconArrowRight />
                            </span>
                          </div>
                        )}
                        <CSSTransition
                          in={!!filteredElements.length && hoveredComponent === (parent || id)}
                          timeout={{
                            enter: 250,
                            exit: 100,
                          }}
                          classNames={styles}
                          mountOnEnter
                          unmountOnExit
                        >
                          {() => (
                            <div
                              onMouseMove={(e) => {
                                e.preventDefault();
                                //  Need to find a way to avoid document access
                                //  Use ref but figure out how to maintain multiple ref entries
                                const containerWidth = document.getElementById(id).offsetWidth;
                                const SLIDER_WIDTH = window.outerWidth - 500;
                                const scrollWidth = containerWidth - SLIDER_WIDTH;
                                if (containerWidth < SLIDER_WIDTH) return;
                                const mousePositionStarter = 500;
                                var mouseX = e.pageX - mousePositionStarter,
                                  percentMouse = (mouseX * 100) / SLIDER_WIDTH + (left < -750 ? 10 : 0),
                                  offset = (percentMouse / 100) * scrollWidth;
                                (percentMouse / 100) * SLIDER_WIDTH;
                                if (percentMouse < 100) {
                                  setLeft(Math.min(-offset - 0, 0));
                                }
                              }}
                              className={styles.elementsContainer}
                              data-test-id="extended-drawer-container"
                              id={id}
                              style={{ left: `${left}px` }}
                            >
                              {/* <div style={{ width: '28px' }} /> */}
                              <div>
                                <ComponentThumbnail
                                  key={id}
                                  onClick={() => handleAddSection(type, parent || id)}
                                  isElement
                                  src={thumbnail || iconUnstackSection}
                                  label="Default"
                                  theme={theme}
                                />
                              </div>
                              {filteredElements.map(({ name, id, parent, thumbnail, type = 'element' }) => (
                                <div>
                                  <ComponentThumbnail
                                    key={id}
                                    onClick={() => handleAddSection(type, parent || id)}
                                    isElement
                                    src={thumbnail || iconUnstackSection}
                                    label={name}
                                    theme={theme}
                                  />
                                </div>
                              ))}
                            </div>
                          )}
                        </CSSTransition>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </CSSTransition>
    </Portal>
  );
}

const Portal = (props) => {
  return ReactDOM.createPortal(props.children, document.body);
};
