import { getDeviceTypeToSaveTo } from 'components/unstack-components/Component/util/helpers/deviceHelper';
import React, { useCallback } from 'react';
import { UBoxInfo, USectionDevices } from 'types/USection';
import BackgroundImageUploader from './SectionBackgroundImageUploader';
import { useSelector } from 'react-redux';
import { getDevice } from 'reducers/uiReducer';
import { set } from 'object-path-immutable';
import get from 'lodash/get';

type Props = {
  value: UBoxInfo;
  closeTray?: () => {};
  onChange: (content: USectionDevices, key?: string, multi?: boolean) => void;
  contentKey: string;
  content: USectionDevices;
};

type IContent = {
  backgroundImage: { [key: string]: string };
  parallax?: number | null;
  backgroundSize: string;
  backgroundRepeat: string;
  backgroundPositionX: string;
  backgroundPositionY: string;
  backgroundOptimization: string;
};

export default function MediaBackgroundImageUploader(props: Props) {
  const device = useSelector(getDevice);

  const processOnChange = useCallback(
    (newValues: { [key: string]: any }) => {
      const splitKey = props.contentKey.split('.');
      const partialContent: IContent = newValues
        ? {
            backgroundImage: {
              url: newValues.src,
              optimization: newValues.optimization,
            },
            parallax: newValues.parallax,
            backgroundSize: newValues.backgroundSize,
            backgroundRepeat: newValues.backgroundRepeat,
            backgroundPositionX: newValues.backgroundPositionX,
            backgroundPositionY: newValues.backgroundPositionY,
            backgroundOptimization: newValues.optimization,
          }
        : {
            backgroundImage: {},
            parallax: null,
            backgroundSize: '',
            backgroundRepeat: '',
            backgroundPositionX: '',
            backgroundPositionY: '',
            backgroundOptimization: '',
          };
      let dataRef = get(props.content, [getDeviceTypeToSaveTo(device, true), ...splitKey.slice(1)]);
      dataRef = {
        ...dataRef,
        ...partialContent,
      };
      props.onChange(
        set(props.content, [getDeviceTypeToSaveTo(device, true), ...splitKey.slice(1)], dataRef),
        `content.${splitKey[0]}`
      );
    },
    [props.content]
  );
  return <BackgroundImageUploader hideParallax={false} {...props} onChange={processOnChange} />;
}
