/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useCallback, useEffect } from 'react';
import { ReactComponent as SvgIconAdd } from '../../../assets/images/icon-add-section.svg';
import { set as setOauthState } from '../../../services/oauth-state';
import { createIntegration } from 'services/spark-api';
import styles from '../Integrations.module.scss';

import CheckPermissionOnClick from '../../SubscriptionManager/CheckPermissionOnClick';

export const getCenteredModalPosition = (width, height) => {
  let w = (window.innerWidth || 0) > (window.outerWidth || 0) ? window.innerWidth || 0 : window.outerWidth || 0,
    h = (window.innerHeight || 0) > (window.outerHeight || 0) ? window.innerHeight || 0 : window.outerHeight || 0,
    x = window.screenX || 0,
    y = window.screenY || 0;
  let top = y + (h - height) / 2,
    left = x + (w - width) / 2;
  return `width=${width},height=${height},top=${top},left=${left}`;
};

export default (props) => {
  const {
    integration,
    type,
    name,
    icon,
    siteId,
    integrationSlug,
    authenticator: { id: authenticatorId, authorization_url, authorization_success_message },
  } = props;

  let openedWindow;
  const isEmpty = !integration;

  useEffect(() => {
    if (integrationSlug === 'semrush')
      window.addEventListener(
        'message',
        (event) => {
          if (event.origin === 'https://oauth.semrush.com') {
            const urlSearchParams = new URLSearchParams(new URL(event.data.url).search);
            createIntegration({
              config: {
                auth_code: urlSearchParams.get('code'),
              },
              integration: integrationSlug,
              site: siteId,
            })
              .then(() => {
                window.location.reload();
              })
              .finally(() => openedWindow.close());
          }
        },
        false
      );
  }, [integrationSlug]);

  const openOauthModal = useCallback(() => {
    const modalPosition = getCenteredModalPosition(1020, 520);
    const stateString = setOauthState(type, {
      siteId,
      icon,
      authorization_success_message,
      authenticatorId,
      integrationSlug,
      name,
    });
    const url = `${authorization_url}&state=${stateString}`;

    openedWindow = window.open(url, `${type}_oauth`, `location=no,${modalPosition}`);
  }, [siteId]);

  return (
    <>
      <img className={styles.icon} src={icon} alt="" />
      <div className={styles.title}>{name}</div>
      <div className={styles.config}>
        {isEmpty && (
          <CheckPermissionOnClick
            action={`SiteIntegration:create:::${integrationSlug}`}
            onClick={openOauthModal}
            render={(handleClick, hasPermission) =>
              hasPermission ? (
                <SvgIconAdd className={styles.add} onClick={handleClick} role="button" />
              ) : (
                <button onClick={handleClick} className="button button-primary button-sm">
                  Upgrade
                </button>
              )
            }
          />
        )}
        {!isEmpty && (
          <>
            <label>
              <span className={styles.statusIndicator} />
              Connected
            </label>{' '}
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                const remove = window.confirm(`Are you sure you want to disconnect from ${name}?`);
                remove && props.deleteIntegrationRequest(integration.id);
              }}
            >
              Disconnect
            </a>
          </>
        )}
      </div>
    </>
  );
};
