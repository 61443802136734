/* eslint-disable jsx-a11y/anchor-is-valid */

import React from 'react';
import MenuItem from '../MenuItem';

import omit from 'lodash/omit';
import { ReactSortable } from 'react-sortablejs';
import { arrayMove } from '../../../../../lib/SortListHoc';
import SectionItemsOptions from '../../../../Editor/Sections/SectionItemsOptions';
import { ReactComponent as SvgIconDrag } from '../../../../../assets/images/arrow-all-thick.svg';
import { ReactComponent as SvgAddItem } from '../../../../../assets/images/icon-add-section.svg';
import { ReactComponent as SvgCheveronLeft } from '../../../../../assets/images/icon-cheveron-left.svg';
import { ReactComponent as SvgAddChildLinks } from '../../../../../assets/images/icon-add-child-links.svg';

import styles from '../../HeaderFooterEditor.module.scss';

class HeaderMenuItems extends React.PureComponent {
  state = {
    autoFocus: false,
    secondaryNavIsFocused: false,
    secondaryNavIsHovered: false,
    navIndex: 0,
  };

  get propPath() {
    return this.props.propPath;
  }

  componentDidUpdate(prevProps, prevState) {
    // Remove autofocus after it's been used
    if (this.state.autoFocus && this.state.autoFocus === prevState.autoFocus) this.setState({ autoFocus: false });
  }

  addItem = () => {
    const { siteUpdaters, direction } = this.props;

    if (direction === 'ltr') siteUpdaters.push(this.propPath, {});
    else siteUpdaters.unshift(this.propPath, {});

    this.setState({ autoFocus: 'items' });
  };

  addSecondaryItem = (index) => {
    this.props.siteUpdaters.push(this.propPath.concat([index.toString(), 'items']), {});
    this.setState({ autoFocus: 'subitems' });
  };

  handleFocus = (e) => {
    this.setState({ secondaryNavIsFocused: true });
  };
  handleBlur = (e) => {
    this.setState({ secondaryNavIsFocused: false });
  };
  handleMouseEnter = (e) => {
    this.setState({
      secondaryNavIsHovered: true,
      navIndex: e.currentTarget.getAttribute('data-index'),
    });
  };
  handleMouseLeave = (e) => {
    this.setState({ secondaryNavIsHovered: false });
  };

  applyFocusHandlers = {
    onFocus: this.handleFocus,
    onBlur: this.handleBlur,
  };

  applyMouseHandlers = {
    onMouseEnter: this.handleMouseEnter,
    onMouseLeave: this.handleMouseLeave,
  };

  secondaryLinks = (items, index) => {
    const secondaryLinks = items.items.map((subitem, subindex) => (
      <li {...this.applyFocusHandlers} key={subindex}>
        <MenuItem
          item={subitem}
          propPath={this.propPath.concat(index, 'items', subindex)}
          siteUpdaters={this.props.siteUpdaters}
          placeholder="Link"
          autoFocus={this.state.autoFocus === 'subitems' && subindex === 0}
        />
      </li>
    ));

    return secondaryLinks;
  };

  render() {
    const { items, maxItems, direction, brightnessTheme } = this.props;

    const { secondaryNavIsFocused, secondaryNavIsHovered, navIndex } = this.state;

    let isOpen = secondaryNavIsFocused || secondaryNavIsHovered;

    return (
      <>
        {direction === 'rtl' && (!maxItems || items.length < maxItems) && (
          <li>
            <SvgAddItem className={styles.AddHeaderMenuItem} role="button" onClick={this.addItem} />
          </li>
        )}
        <li>
          <ReactSortable
            animation={150}
            tag="ul"
            style={{ display: 'flex', marginLeft: 0 }}
            chosenClass="sortable-chosen"
            ghostClass="sortable-ghost"
            dragClass="sortable-drag"
            handle=".drag-handle"
            setList={() => {}}
            onEnd={(event) => {
              const data = items.map((item) => omit(item, 'chosen'));
              this.props.siteUpdaters.set(this.propPath, arrayMove(data, event.oldIndex, event.newIndex));
            }}
            list={items}
          >
            {items.map((item, index) => (
              <li
                onClick={this.openSecondaryMenu}
                key={direction === 'ltr' ? index : items.length - index}
                data-index={index}
                {...this.applyMouseHandlers}
              >
                <MenuItem
                  key={items.length - index}
                  className="link"
                  item={item}
                  propPath={this.propPath.concat(index)}
                  siteUpdaters={this.props.siteUpdaters}
                  placeholder="Link"
                  autoFocus={
                    this.state.autoFocus === 'items' &&
                    ((direction === 'rtl' && index === 0) || (direction === 'ltr' && index === items.length - 1))
                  }
                />

                {items[index].items && items[index].items.length > 0 ? (
                  <>
                    {isOpen && index === Number.parseInt(navIndex) && (
                      <SectionItemsOptions
                        brightnessTheme={brightnessTheme}
                        DragHandle={
                          <div className="drag-handle">
                            <SvgIconDrag width={11} height={11} />
                          </div>
                        }
                        iconHeight={7}
                        iconWidth={8}
                        showTrash={false}
                        decreaseDimensions
                        style={{ top: '-18px' }}
                      />
                    )}
                    <SvgCheveronLeft className="dropdown-icon" />
                    <div
                      className="secondary-navigation"
                      onClick={this.openSecondaryMenu}
                      style={{
                        display:
                          (isOpen && index === Number.parseInt(navIndex)) ||
                          (items[index].items.length === 1 && items[index].items[0] === '')
                            ? 'initial'
                            : 'none',
                      }}
                    >
                      <ul>
                        {this.secondaryLinks(item, index)}
                        <li>
                          <SvgAddItem
                            className={styles.AddHeaderMenuItem}
                            role="button"
                            onClick={() => this.addSecondaryItem(index)}
                          />
                        </li>
                      </ul>
                    </div>
                  </>
                ) : (
                  <>
                    {isOpen && index === Number.parseInt(navIndex) && (
                      <SectionItemsOptions
                        brightnessTheme={brightnessTheme}
                        DragHandle={
                          <div className="drag-handle">
                            <SvgIconDrag width={11} height={11} />
                          </div>
                        }
                        iconHeight={7}
                        iconWidth={8}
                        showTrash={false}
                        decreaseDimensions
                        style={{ top: '-18px' }}
                      />
                    )}
                    <SvgAddChildLinks className="add-child-icon" onClick={() => this.addSecondaryItem(index)} />
                  </>
                )}
              </li>
            ))}
          </ReactSortable>
        </li>
        {direction === 'ltr' && items.length < maxItems && (
          <li>
            <SvgAddItem className={styles.AddHeaderMenuItem} role="button" onClick={this.addItem} />
          </li>
        )}
      </>
    );
  }
}

export default HeaderMenuItems;
