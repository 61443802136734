import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import QueryString from 'query-string';
import { useLocation } from 'react-router';

import UnstackSplash from 'components/UnstackSplash';
import * as auth from 'services/spark-auth';
import { initializeApp } from 'actions/appActions';
import { useNavigate } from 'react-router-dom';

export default () => {
  const { search } = useLocation();
  const navigate = useNavigate();
  const dispath = useDispatch();
  const searchParams = QueryString.parse(search);
  useEffect(() => {
    if (searchParams.jwt) {
      auth.storeToken(searchParams.jwt);
      dispath(initializeApp());
      const ref = searchParams.ref as string;
      navigate(ref || '/');
    }
  }, []);

  return <UnstackSplash isLoading />;
};
