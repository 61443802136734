import * as actionTypes from '../actions/actionTypes';
import { AddSectionActions } from 'actions/addSectionActions';

// Reducer
// ----------------------------------------------------------------------------

type AddSectionState = {
  isDrawerOpen: boolean;
  error?: string;
};

const initState: AddSectionState = {
  isDrawerOpen: false,
};

export default function (state = initState, action: AddSectionActions) {
  switch (action.type) {
    case actionTypes.ADD_SECTION_SET_DRAWER_OPEN:
      return {
        ...state,
        isDrawerOpen: true,
      };

    case actionTypes.ADD_SECTION_SET_DRAWER_CLOSE:
      return {
        ...state,
        isDrawerOpen: false,
      };

    default:
      return state;
  }
}

// Raw selectors
// ----------------------------------------------------------------------------

export function selectLocalState(globalState: { addSection?: AddSectionState }) {
  return globalState.addSection;
}

export function getIsDrawerOpen(globalState: { addSection?: AddSectionState }) {
  const state = selectLocalState(globalState);
  return state.isDrawerOpen;
}
