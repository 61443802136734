import React, { useCallback, useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { addAutosaveOps, requestIfNeeded as requestBlog } from '../../actions/blogActions';
import { selectDraft } from '../../reducers/blogsReducer';

import RichTextEditor from '../../lib/quill/components/RichTextEditor';
import Actions from './Actions';

import { set, del } from '../../lib/immutable-operations/operations';
import get from 'lodash/get';

export default React.memo(function BlogAside(props) {
  const { blogId, readOnly } = props;

  // const blog = useSelector(state => selectDraft(state, blogId));
  // const body = get(blog, 'content.aside.body');
  // const actions = get(blog, 'content.aside.actions');

  const body = useSelector((state) => get(selectDraft(state, blogId), 'content.aside.body'));

  const actions = useSelector((state) => get(selectDraft(state, blogId), 'content.aside.actions'));

  const dispatch = useDispatch();

  useEffect(() => {
    if (blogId) dispatch(requestBlog(blogId));
  }, [blogId]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleBodyChange = useCallback(
    (value) => {
      const isEmpty =
        !value || !value.ops || value.ops.length === 0 || (value.ops.length === 1 && value.ops[0].insert === '\n');

      const op = isEmpty ? del('content.aside.body') : set('content.aside.body', value);

      dispatch(addAutosaveOps(blogId, op));
    },
    [blogId, dispatch]
  );

  const handleActionsChange = useCallback(
    (newActions) => {
      const isEmpty = !newActions || Object.values(newActions).every((v) => v == null);

      const op = isEmpty ? del(`content.aside.actions`) : set('content.aside.actions', newActions);

      dispatch(addAutosaveOps(blogId, op));
    },
    [actions, blogId, dispatch]
  );

  const hasActions = actions && Object.values(actions).some((v) => v != null);

  if (readOnly && !body && !hasActions) return null;

  return (
    <aside className="blog-aside-sidebar" data-test-id="blog-sidebar">
      <div className="container">
        {(!readOnly || body) && (
          <RichTextEditor
            placeholder="Add rich text"
            onChange={handleBodyChange}
            value={body}
            hideAddItemMenu
            hideAlignButtons
            readOnly={readOnly}
            isBlog
          />
        )}
        {(!readOnly || hasActions) && (
          <Actions actions={actions} updateAction={handleActionsChange} enableForms readOnly={readOnly} />
        )}
      </div>
    </aside>
  );
});
