import { combineReducers } from 'redux';
import { createSelector } from 'reselect';
import createCachedSelector from 're-reselect';
import * as types from '../actions/actionTypes';
import { selectActiveSite } from './sitesReducer';
import nestBy from '../lib/nestBy';
import get from 'lodash/get';

function integrationTypeReducer(state = {}, action) {
  switch (action.type) {
    case types.INTEGRATION_REQUEST_TYPES:
      return { ...state, isFetching: true, searchedList: [] };
    case types.INTEGRATION_REQUEST_TYPES_SUCCESS: {
      const { isSearched } = action;
      return {
        ...state,
        isFetching: false,
        list: action.payload,
        ...(isSearched ? { searchedList: action.payload } : {}),
      };
    }
    default:
      return state;
  }
}

function lastUpdatedReducer(state = null, action) {
  switch (action.type) {
    case types.INTEGRATIONS_RECEIVE_ALL:
      return action.payload.receivedAt;

    default:
      return state;
  }
}

function isFetchingReducer(state = null, action) {
  switch (action.type) {
    case types.INTEGRATIONS_REQUEST_ALL:
      return true;

    case types.INTEGRATIONS_RECEIVE_ALL:
      return false;

    default:
      return state;
  }
}

function isUpdatingReducer(state = false, action) {
  switch (action.type) {
    case types.INTEGRATIONS_CREATE:
    case types.INTEGRATIONS_PATCH:
      return true;

    case types.INTEGRATIONS_RECEIVE_UPDATE:
      return false;

    default:
      return state;
  }
}

function byIdReducer(state = {}, action) {
  switch (action.type) {
    case types.INTEGRATIONS_RECEIVE_ALL:
      return action.payload.entities.integrations || {};

    case types.INTEGRATIONS_RECEIVE_UPDATE:
      return {
        ...state,
        [action.payload.id]: action.payload,
      };

    case types.INTEGRATIONS_DELETE: {
      const id = action.payload.id;
      const newState = { ...state };
      delete newState[id];
      return newState;
    }

    default:
      return state;
  }
}

function hubspotFormsList(state = {}, action) {
  switch (action.type) {
    case types.INTEGRATION_RECEIVE_HUBSPOT_FORMS:
      return {
        ...action.payload,
      };
    default:
      return state;
  }
}

function contactLists(state = {}, action) {
  switch (action.type) {
    case types.INTEGRATIONS_REQUEST_CONTACT_LIST_SUCCESS:
      return action.payload;
    default:
      return state;
  }
}

export default combineReducers({
  lastUpdated: lastUpdatedReducer,
  isFetching: isFetchingReducer,
  byId: byIdReducer,
  isUpdating: isUpdatingReducer,
  hubspotForms: hubspotFormsList,
  contactLists: contactLists,
  integrationTypes: integrationTypeReducer,
});

// ----------------------------------------------------------------------------
// Selectors
// ----------------------------------------------------------------------------

export function selectIntegrations(globalState) {
  return globalState.integrations;
}

export function selectLastUpdated(globalState) {
  const state = selectIntegrations(globalState);
  return state.lastUpdated;
}

export function selectIsFetching(globalState) {
  const state = selectIntegrations(globalState);
  return state.isFetching;
}

export function selectIsUpdating(globalState) {
  const state = selectIntegrations(globalState);
  return state.isUpdating;
}

export function selectAllById(globalState) {
  const state = selectIntegrations(globalState);
  return state.byId;
}

function selectFormsByName(globalState) {
  const state = selectIntegrations(globalState);
  return state.hubspotForms;
}

export const selectAll = createSelector(selectAllById, (integrationsById) => Object.values(integrationsById));

export const selectGroupedIntegrations = createSelector(selectAll, (integrations) => {
  return nestBy(integrations, ['site', 'integration']);
});

export const selectGroupedIntegrationsBySlug = createSelector(selectAll, (integrations) => {
  return nestBy(integrations, ['site', 'slug']);
});

export const selectSiteIntegrationsByType = createCachedSelector(
  selectGroupedIntegrations,
  (state, siteId) => siteId || selectActiveSite(state),
  (state, siteId, type) => type,
  (groups, siteId, type) => {
    if (type) return get(groups, [siteId, type]);
    return get(groups, siteId, {});
  }
)((state, siteId, type) => `${siteId}${type}`);

export const selectSiteIntegrationsBySlug = createCachedSelector(
  selectGroupedIntegrationsBySlug,
  (state, siteId) => siteId || selectActiveSite(state),
  (state, siteId, type) => type,
  (groups, siteId, type) => {
    if (type) return get(groups, [siteId, type]);
    return get(groups, siteId, {});
  }
)((state, siteId, type) => `${siteId}${type}`);

export const selectIntegrationBySlug = createCachedSelector(
  selectSiteIntegrationsBySlug,
  (state, siteId) => siteId || selectActiveSite(state),
  (_state, _siteId, type) => type,
  (groups, _siteId, _type) => {
    return groups ? groups[0] : null;
  }
)((state, siteId, type) => `${siteId}${type}`);

export const selectHubspotForms = createSelector(selectFormsByName, (forms) => forms);

export const selectIntegrationLists = createSelector(selectIntegrations, (integrations) => integrations.contactLists);

function selectTypes(globalState) {
  return globalState.integrations.integrationTypes;
}

export const selectIntegrationTypeIsFetching = createSelector(
  selectTypes,
  (integrationTypes) => integrationTypes.isFetching
);

export const selectIntegrationTypes = createSelector(selectTypes, (integrationTypes) => integrationTypes.list);

export const selectSearchedIntegrationTypes = createSelector(
  selectTypes,
  (integrationTypes) => integrationTypes.searchedList || []
);
