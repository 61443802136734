import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import SideDrawer from '../../../base/SideDrawer';
import FormControl from '../../../base/FormHelpers/FormControl';
import ValidationMessage from '../../../base/FormHelpers/ValidationMessage';

import { createNote, updateNote, deleteNote } from '../../../../actions/notesActions';
import { selectSubmissionState } from '../../../../reducers/uiSubmitButtonsStateReducer';

import useKeyBinding from '../../../../hooks/useKeyBinding';

import styles from './ContactDetail.module.scss';

const validateFields = (data) => Boolean(data.details && data.owner);

export default ({ contact, isOpen, setDrawerType, selectedNote, setSelectedNote, refetchList, owner }) => {
  const isSubmitting = useSelector((state) => selectSubmissionState(state));
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [notesData, setNotesData] = useState({
    owner,
    importance: 'normal',
    ...(selectedNote.id ? { ...selectedNote } : {}),
  });
  const dispatch = useDispatch();

  const handleSubmit = isSubmitting
    ? () => {}
    : () => {
        if (validateFields(notesData)) {
          const promise = new Promise((resolve, reject) => {
            dispatch(
              selectedNote.id
                ? updateNote(
                    {
                      ...selectedNote,
                      ...notesData,
                      contact: contact.id,
                    },
                    resolve,
                    reject
                  )
                : createNote(
                    {
                      ...notesData,
                      contact: contact.id,
                    },
                    resolve,
                    reject
                  )
            );
          });
          promise
            .then(() => {
              setDrawerType('');
              refetchList();
            })
            .catch((e) => console.log(e));
        } else setNotesData({ ...notesData, error: true });
      };

  useKeyBinding('keydown', 13, () => {
    handleSubmit();
  });

  return (
    <SideDrawer
      isOpen={isOpen}
      title={selectedNote.id ? 'Update note' : 'Add a note'}
      close={() => {
        setSelectedNote({});
        setDrawerType('');
      }}
    >
      <fieldset className={styles.fieldsetOptionalStyles}>
        <label htmlFor="note">Note</label>
        <FormControl
          id="note"
          name="details"
          onChange={(e) => setNotesData({ ...notesData, [e.target.name]: e.target.value })}
          value={notesData.details || ''}
          autoComplete="off"
          tag="textarea"
          placeholder="Add note"
          style={{
            minHeight: '350px',
          }}
        />
        {!!(notesData.error && !notesData.details) && <ValidationMessage message="This field is required" />}
      </fieldset>
      <fieldset className={styles.fieldsetOptionalStyles}>
        <label htmlFor="importance">Importance</label>
        <FormControl
          id="importance"
          name="importance"
          onChange={(e) => setNotesData({ ...notesData, [e.target.name]: e.target.value })}
          value={notesData.importance || ''}
          tag="select"
        >
          <option value="">Select importance</option>
          <option value="normal">Normal</option>
          <option value="high">High</option>
        </FormControl>
      </fieldset>
      <fieldset className={styles.fieldsetOptionalStyles}>
        <input
          readOnly
          value={
            selectedNote.details
              ? `${isSubmitting ? 'Updating' : 'Update'} note`
              : `${isSubmitting ? 'Adding' : 'Add'} note`
          }
          className="button button-primary button-full"
          onClick={() => handleSubmit()}
        />
      </fieldset>
      {selectedNote.details && (
        <p className={styles.colorDeleteActionText}>
          You can also <span onClick={() => setConfirmDelete(true)}>delete this note</span>.
          {confirmDelete && (
            <p className={styles.colorDeleteActionText}>
              Are you sure? Deleting a note cannot be undone. &nbsp;&nbsp;
              {/* eslint-disable-next-line  jsx-a11y/anchor-is-valid */}
              <a
                onClick={() => {
                  const promise = new Promise((resolve, reject) => {
                    dispatch(deleteNote(selectedNote.id, resolve, reject));
                  });
                  promise
                    .then(() => {
                      setDrawerType('');
                      refetchList();
                    })
                    .catch((e) => console.log(e));
                }}
              >
                Yes
              </a>
            </p>
          )}
        </p>
      )}
    </SideDrawer>
  );
};
