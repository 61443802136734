import { call, put, all, takeLatest } from 'redux-saga/effects';
import * as themesActions from '../actions/themesActions';
import * as types from '../actions/actionTypes';
import { getThemes } from '../services/spark-api';

function* requestThemes() {
  const response = yield call(getThemes);
  if (response.ok) {
    yield put(themesActions.receivedThemes(response.json.results));
  }
}

function* themesSaga() {
  yield all([takeLatest(types.THEMES_REQUEST_LIST, requestThemes)]);
}

export default themesSaga;
