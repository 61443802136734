import React, { useState, useEffect, useMemo } from 'react';
import useActiveSite from '../../../hooks/useActiveSite';
import { Search as SearchEngine, AllSubstringsIndexStrategy } from 'js-search';

import Search from '../../Search';
import iconUnstackSection from '../../../assets/images/thumbnail-custom-atf@2x.png';
// import getSectionType from './getSectionType';
import { useResources } from '../../../lib/redux-resource/hooks';
import usePrevious from '../../../hooks/usePrevious';

import styles from './Thumbnails.module.scss';
import isArray from 'lodash/isArray';
import sortBy from 'lodash/sortBy';
import ComponentThumbnail from 'components/ComponentThumbnail';
import classnames from 'classnames';
import { useSelector } from 'react-redux';
import { selectSiteIntegrationsBySlug } from 'reducers/integrationsReducer';
// import { CAROUSEL_SECTIONS_REGES } from '../../../constants';

//  Disabled carousel checks for now
export default function Thumbnails(props) {
  const { addSection, layout, theme, allowedTypes } = props;

  const siteId = useActiveSite((site) => site.id);

  const integrationsBySlug = useSelector((state) => selectSiteIntegrationsBySlug(state, siteId));
  const hasAlgolia =
    integrationsBySlug.algolia && integrationsBySlug.algolia[0] && integrationsBySlug.algolia[0].status === 'active';

  // Get components (aka "section types")
  const allSectionTypes = useResources('sectionTypes');
  const sectionTypes = useMemo(() => {
    if (allSectionTypes != null && allSectionTypes.filter)
      return allSectionTypes.filter((c) => {
        if (!hasAlgolia && c.slug.includes('algolia')) return false;
        return c.component_type === 'section' || c.component_type === 'custom' || c.component_type === 'checkout';
      });
    return [];
  }, [allSectionTypes]);
  const prevSectionTypes = usePrevious(sectionTypes);

  //  Hooks for query and search engine state management
  const [allItems, setAllItems] = useState([]);
  const [query, setQuery] = useState('');
  const [engine, setEngine] = useState('');
  useEffect(() => {
    const sectionTypesHaveChanged = !shallowEqualArray(prevSectionTypes, sectionTypes);

    // Create the search engine
    let engine = new SearchEngine('name');
    engine.indexStrategy = new AllSubstringsIndexStrategy();
    engine.addIndex('name');

    if (sectionTypesHaveChanged) {
      // Combine components and elements
      const allItems = [];
      if (!allowedTypes) {
        sectionTypes.forEach((resource) =>
          allItems.push({
            name: resource.name,
            resource: resource,
            type: 'component',
          })
        );
        setAllItems(sortBy(allItems, [(item) => item.name.toLowerCase()]));
      } else {
        sectionTypes
          .filter((type) => allowedTypes.includes(type.slug))
          .forEach((resource) =>
            allItems.push({
              name: resource.name,
              resource: resource,
              type: 'element',
            })
          );
        setAllItems(sortBy(allItems, [(item) => item.name.toLowerCase()]));
      }
      engine.addDocuments(allItems);
      setEngine(engine);
    }
  }, [sectionTypes]);

  const filteredItems = useMemo(
    () =>
      (query ? engine.search(query) : allItems).filter((item) => {
        return !item?.resource?.is_deleted;
      }),
    [query, engine, allItems]
  );

  return (
    <div
      className={classnames(styles.container, {
        [styles.fullWidth]: layout === 'fullWidth',
      })}
      data-test-id="thumbnails"
    >
      <div className={styles.header} data-test-id="header">
        {layout === 'fullWidth' && (
          <span className={styles.title} data-test-id="title">
            What would you like to start with?
          </span>
        )}
        <Search
          className={styles.search}
          debounceValue={() => {}}
          enableDarkMode={layout !== 'fullWidth'}
          onChange={(query) => {
            setQuery(query);
          }}
          placeholder={'Search sections'}
          value={query}
        />
      </div>
      {query && filteredItems.length === 0 ? (
        <header className={styles.searchZeroState}>
          <h3>No sections match your search criteria</h3>
        </header>
      ) : (
        <div class={styles.components} data-test-id="components">
          {filteredItems.map(({ resource: { name, id, parent, thumbnail }, type }) => (
            <div className={styles.component}>
              <ComponentThumbnail
                key={id}
                onClick={() => addSection(type, parent || id)}
                src={thumbnail || iconUnstackSection}
                label={name}
                theme={theme}
                isList
              />
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export function shallowEqualArray(a = [], b = []) {
  if (isArray(a) && isArray(b)) {
    if (a.length === b.length) {
      return a.every((e, i) => e === b[i]);
    }
    return false;
  }
}
