/* eslint-disable jsx-a11y/anchor-is-valid */

import React from 'react';
import { connect } from 'react-redux';
import * as articleActions from '../../../actions/articleActions';

const mapDispatchToProps = {
  unscheduleArticle: articleActions.requestUnschedule,
};

function Unschedule(props) {
  const { unscheduleArticle, entityId } = props;

  const handleClick = (e) => {
    e.preventDefault();
    unscheduleArticle(entityId);
  };

  return (
    <a href="#" onClick={handleClick}>
      unschedule
    </a>
  );
}

export default connect(null, mapDispatchToProps)(Unschedule);
