import React from 'react';
import List from './List';

const ListWithServerSorting = ({ orderBy, history, onSortChange, ...restProps }: any) => {
  return (
    <List
      {...restProps}
      sorted={
        orderBy
          ? [
              {
                id: orderBy.replace('-', ''),
                desc: orderBy.includes('-'),
              },
            ]
          : []
      }
      manual
      onSortedChange={(newSort: any) => {
        newSort = newSort[0];
        onSortChange(newSort);
      }}
    />
  );
};

export default ListWithServerSorting;
