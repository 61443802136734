import * as types from './actionTypes';

export function receiveSectionCollection(payload) {
  return {
    type: types.SHOPIFY_STOREFRONT_RECEIVE_COLLECTIONS,
    payload,
  };
}

export function saveSelectedSectionCollection(payload) {
  return {
    type: types.SHOPIFY_STOREFRONT_SAVE_COLLECTIONS_MANUALLY,
    payload,
  };
}
