/**
 * <AuthenticatedMediaImg />
 *
 * Returns an <img /> tag with all props passed to it. If the `src` prop matches
 * the form "media:{uuid}", then it replaces it with an authenticated media
 * url of the form "https://api.unstack.com/api/v1/media/{uuid}/?jwt={jwt}".
 */

import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import omit from 'lodash/omit';

import { requestIfNeeded } from '../actions/mediaActions';

import { selectOne } from '../reducers/mediaReducer';
import { MEDIA_URL_REGEXP } from '../constants';
import noMediaSvg from '../assets/images/icon-media-removed.svg';

const mapDispatchToProps = {
  requestIfNeeded,
};

function mapStateToProps(state, ownProps) {
  let mediaMatch, mediaId, media;
  if (ownProps.src && (mediaMatch = ownProps.src.match(MEDIA_URL_REGEXP))) {
    mediaId = mediaMatch[1];
    media = mediaId ? selectOne(state, mediaId) : null;
  }
  return {
    mediaId,
    src: media ? (media.file ? media.file : noMediaSvg) : null,
  };
}

function AuthenticatedMediaImg(props) {
  const { dispatch, mediaId, requestIfNeeded, style, ...imgProps } = props;

  const imgStyles = imgProps.borderRadius
    ? {
        borderRadius: `${imgProps.borderRadius}px`,
      }
    : {};

  useEffect(() => {
    if (mediaId) requestIfNeeded(mediaId);
  }, [requestIfNeeded, mediaId]);

  return (
    <img
      {...omit(imgProps, ['controls', 'autoplay', 'borderRadius', 'optimization'])}
      style={{ ...imgStyles, ...style }}
    />
  ); // eslint-disable-line jsx-a11y/alt-text
}

export default connect(mapStateToProps, mapDispatchToProps)(AuthenticatedMediaImg);
