import React, { useState, useEffect } from 'react';
import { TextInput } from '../Toolbar2';
import UpDownButtons from './UpDownButtons';
import toInteger from 'lodash/toInteger';
import clamp from 'lodash/clamp';
import styles from './UpDownCounter.module.scss';
import classnames from 'classnames';

type Props = {
  min?: number;
  max?: number;
  onChange: (newVal: string | number, event: any) => void;
  autoFocus?: boolean;
  hideButtons?: boolean;
  formatValue?: (val: string) => string;
  step?: number;
  value: string | number;
  className?: string;
  placeholder?: string;
  border?: boolean;
  innerRef?: any;
  setWidth?: any;
  handleDown?: any;
  handleUp?: any;
  cursor?: any;
  handleEnter?: any;
};

export function getNumAndSuffix(value: string | number) {
  if (value && typeof value === 'string') {
    const re = /^[0-9]+[^[0-9]]*/;
    const hasSuffix = (value as string).search(re);
    if (hasSuffix !== -1) {
      const suffixIdx = value.search(/[^[0-9]]*/);
      return { numericValue: toInteger(Number(value.substring(0, suffixIdx))), suffix: value.substring(suffixIdx) };
    } else if (!isNaN(value as any)) {
      return { numericValue: toInteger(Number(value)), suffix: '' };
    }
  } else if (value && typeof value === 'number') {
    return { numericValue: toInteger(Number(value)), suffix: '' };
  }
  return { numericValue: 0, suffix: '' };
}

export default function UpDownCounter(props: Props) {
  const { min, max, onChange, autoFocus, hideButtons = false, formatValue, className, innerRef, setWidth } = props;

  const formattedValue = typeof formatValue === 'function' ? formatValue(props.value as string) : props.value || '0';
  const { numericValue, suffix } = getNumAndSuffix(props.value);
  const step = props.step || 1;

  const [counterInput, setCounterInput] = useState(formattedValue);

  useEffect(() => {
    setCounterInput(formattedValue);
  }, [formattedValue]);

  // Event handlers
  // --------------------------------------------------------------------------

  const handleChange = (newValue: number, newSuffix: string, e?: any) => {
    const clampedValue = clamp(newValue, min, max);
    onChange(clampedValue + newSuffix, e);
  };

  const handleKeyDown = (e: MouseEvent) => {
    switch (e.which) {
      case 13:
        if (props.cursor || props.cursor === 0) {
          props.handleEnter();
        } else {
          if (counterInput === 'auto' || counterInput === '') {
            onChange('auto', e);
          } else if (counterInput === 'none') {
            onChange('none', e);
          } else {
            const { numericValue: int, suffix: s } = getNumAndSuffix(counterInput);
            if (int < min) {
              handleChange(min, s, e);
            } else if (int > max) {
              handleChange(max, s, e);
            } else {
              handleChange(int, s, e);
            }
            setCounterInput(numericValue + s);
          }
        }
        break;
      case 27:
        const { numericValue: newVal, suffix: newSuffix } = getNumAndSuffix(counterInput);
        handleChange(newVal, newSuffix);
        setCounterInput(newVal + newSuffix);
        break;
      case 38:
        props.handleUp ? props.handleUp() : handleChange(numericValue + step, suffix);
        break;
      case 40:
        props.handleDown ? props.handleDown() : handleChange(numericValue - step, suffix);
        break;
      default:
    }
  };

  const handleTextInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCounterInput(e.target.value);
    if (setWidth) setWidth(e.target.value.length);
  };

  const handleBlur = (e: React.FocusEvent) => {
    if (counterInput === 'auto' || counterInput === '') {
      onChange('auto', e);
    } else if (counterInput === 'none') {
      onChange('none', e);
    } else {
      const { numericValue: newNumericVal, suffix: newSuffix } = getNumAndSuffix(counterInput);
      if (newNumericVal < min) {
        handleChange(min, newSuffix, e);
      } else if (newNumericVal > max) {
        handleChange(max, newSuffix, e);
      } else {
        handleChange(newNumericVal, newSuffix, e);
      }
    }
  };

  const handleClickIncrement = (e: React.MouseEvent) => {
    e.stopPropagation();
    const { numericValue: int } = getNumAndSuffix(counterInput);
    const stepCustom = suffix === 'px' ? 10 : step;
    handleChange(int + stepCustom, suffix);
  };

  const handleClickDecrement = (e: React.MouseEvent) => {
    e.stopPropagation();
    const { numericValue: int } = getNumAndSuffix(counterInput);
    const stepCustom = suffix === 'px' ? 10 : step;
    handleChange(int - stepCustom, suffix);
  };

  // Render
  // --------------------------------------------------------------------------

  return (
    <div className={styles.UpDownCounter}>
      <TextInput
        className={classnames(styles.TextInput, className)}
        value={counterInput}
        onChange={handleTextInputChange}
        onKeyDown={handleKeyDown}
        onBlur={handleBlur}
        onFocus={(e: React.FocusEvent<HTMLInputElement>) => {
          e.target.select();
        }}
        autoFocus={autoFocus}
        border
        innerRef={innerRef}
      />
      {hideButtons ? null : (
        <UpDownButtons
          upProps={{
            onClick: handleClickIncrement,
            disabled: numericValue >= max,
          }}
          downProps={{
            onClick: handleClickDecrement,
            disabled: numericValue <= min,
          }}
        />
      )}
    </div>
  );
}
