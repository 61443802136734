import React from 'react';
import classnames from 'classnames';

import FormControl from '../../../../base/FormHelpers/FormControl';
import SmartUrlInput from '../../../../base/FormHelpers/SmartUrlInput';
import withSubscription from '../../../../../containers/withSubscription.js';

import styles from './Form.module.scss';

const SUBMISSION_OPTIONS = [
  {
    title: 'Show message',
    type: 'message',
    allowFreeSubscription: true,
  },
  {
    title: 'Redirect to page',
    type: 'redirect',
    allowFreeSubscription: true,
  },
  {
    title: 'POST to URL',
    type: 'submit',
    method: 'post',
    helpMessage: 'Submit your form to a url via the POST method',
  },
  {
    title: 'GET to URL',
    type: 'submit',
    method: 'get',
    helpMessage: 'Submit your form to a url via the GET method',
  },
];

const SubmissionOptions = ({ label, onUpdate, submissionAction, validationMessage, hasSubscription }) => {
  return (
    <div className={styles.SparkForm}>
      <fieldset>
        <label>{label || 'On submit'}</label>
        <div className={styles.radioGroup}>
          {SUBMISSION_OPTIONS.map(({ title, type, method, helpMessage, allowFreeSubscription }, idx) => {
            if (!hasSubscription && !allowFreeSubscription) return null;

            const isSelected = type === submissionAction.type && method === submissionAction.method;
            return (
              <label key={idx} className={classnames({ [styles.selected]: isSelected })}>
                <input type="radio" checked={isSelected} onChange={() => onUpdate({ type, method })} />
                <div className={styles.title}>
                  {title}
                  {isSelected &&
                    (type !== 'redirect' ? (
                      <FormControl
                        type={type === 'message' ? 'textarea' : 'text'}
                        name="name"
                        value={submissionAction.message || submissionAction.url}
                        onChange={(e) => {
                          onUpdate({
                            type,
                            method,
                            [type === 'submit' ? 'url' : 'message']: e.target.value,
                          });
                        }}
                      />
                    ) : (
                      <SmartUrlInput
                        onChange={(value) => {
                          onUpdate({
                            type,
                            url: value,
                          });
                        }}
                        value={submissionAction.url || ''}
                      />
                    ))}
                  {isSelected && helpMessage && <small>{helpMessage}</small>}
                </div>
              </label>
            );
          })}
        </div>
        {validationMessage}
      </fieldset>
    </div>
  );
};

export default withSubscription(SubmissionOptions);
