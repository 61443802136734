import React from 'react';
import classnames from 'classnames';
import get from 'lodash/get';

import { addAutosaveOps } from '../../actions/blogActions';
import { set } from '../../lib/immutable-operations/operations';

import { ReactComponent as IconAddItem } from '../../assets/images/icon-add-item.svg';
import { ReactComponent as IconRemoveItem } from '../../assets/images/icon-remove-item.svg';

import styles from './BlogLayoutConfig.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { selectDraft } from '../../reducers/blogsReducer';

export default (props) => {
  const dispatch = useDispatch();

  const { showCategoryLayout, setShowCategoryLayout } = props;
  const { type, size = 7 } = useSelector((state) => get(selectDraft(state, props.blogId), 'content.layout')) || {};

  return (
    <div className={styles.container}>
      <div className={styles.layoutOptions}>
        <div className={styles.tabs}>
          <span
            onClick={() => setShowCategoryLayout(true)}
            data-test-id="blog-category-layout-tab"
            className={classnames({
              [styles.active]: showCategoryLayout,
            })}
          >
            Category layout
          </span>
          <span
            className={classnames({
              [styles.active]: !showCategoryLayout,
            })}
            data-test-id="blog-article-layout-tab"
            onClick={() => setShowCategoryLayout(false)}
          >
            Article layout
          </span>
        </div>
        {showCategoryLayout && (
          <div className={classnames(styles.rightTabs)}>
            <span
              onClick={() => {
                dispatch(addAutosaveOps(props.blogId, set('content.layout.type', 'grid')));
              }}
              className={classnames({ [styles.active]: type === 'grid' })}
              data-test-id="blog-layout-type-grid"
            >
              Grid
            </span>
            <span
              onClick={() => {
                dispatch(addAutosaveOps(props.blogId, set('content.layout.type', 'list')));
              }}
              className={classnames({
                [styles.active]: type === 'list' || !type,
              })}
              data-test-id="blog-layout-type-list"
            >
              List
            </span>
          </div>
        )}
      </div>

      {showCategoryLayout && (
        <div className={styles.numberOptions} data-test-id="blog-layout-number-options">
          <IconRemoveItem
            className={classnames({ [styles.disabled]: size === 7 })}
            data-test-id="remove-item"
            onClick={
              size === 7
                ? null
                : () => {
                    dispatch(addAutosaveOps(props.blogId, set('content.layout.size', size - 1)));
                  }
            }
          />
          <span data-test-id="size">{size}</span>
          <IconAddItem
            data-test-id="add-item"
            onClick={() => {
              dispatch(addAutosaveOps(props.blogId, set('content.layout.size', size + 1)));
            }}
          />
        </div>
      )}
    </div>
  );
};
