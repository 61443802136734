import React from 'react';
import classnames from 'classnames';
import { ReactComponent as SvgIconDarkBg } from '../../../../assets/images/icon-dark-bg.svg';
import { ReactComponent as SvgIconLightBg } from '../../../../assets/images/icon-light-bg.svg';

export default function BrightnessThemeButtons({ onChange, value }) {
  return (
    <>
      <button
        data-tip="Dark text for a light background"
        className={classnames({
          active: value === 'light-bg' || value == null,
        })}
        onClick={() => onChange('light-bg')}
      >
        <SvgIconLightBg className="brightnessThemeButton" />
      </button>
      <button
        data-tip="Light text for a dark background"
        className={classnames({ active: value === 'dark-bg' })}
        onClick={() => onChange('dark-bg')}
      >
        <SvgIconDarkBg className="brightnessThemeButton" />
      </button>
    </>
  );
}
