/* eslint-disable jsx-a11y/anchor-is-valid */

import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';

import classnames from 'classnames';
import throttle from 'lodash/throttle';
import truncate from 'lodash/truncate';
import { CSSTransition } from 'react-transition-group';

import AdminBar from '../AdminBar';
import EditorAdminBarTitleError from './EditorAdminBarTitleError';

import * as api from 'services/spark-api';
import withSite from '../../containers/withSite';
import * as toolbarActions from '../../actions/toolbarActions';
import * as pageActions from '../../actions/pageActions';
import * as siteSelectors from '../../reducers/sitesReducer';
import * as pageSelectors from '../../reducers/pagesReducer';
import * as editorSelectors from '../../reducers/editorReducer';
import * as accountSelectors from '../../reducers/accountReducer';
import * as uiActions from '../../actions/uiActions';
import * as uiSelectors from '../../reducers/uiReducer';
import { selectHasPermission } from '../../reducers/policyReducer';
import * as uiPageSettingsActions from '../../actions/uiPageSettingsActions';
import * as uiTemplateSettings from '../../actions/uiTemplateSettingsActions';
import { updateSubmittingState, resetSubmittingState } from '../../actions/uiSubmitButtonsStateActions';

import spinnerCircle from '../../assets/images/spinner-circle.png';
import { ReactComponent as SvgIconLink } from '../../assets/images/icon-link.svg';
import { ReactComponent as SvgIconEdit } from '../../assets/images/icon-edit-field.svg';
import { ReactComponent as SvgIconHelp } from '../../assets/images/icon-help-circle.svg';
import { ReactComponent as SvgIconSettings } from '../../assets/images/icons8-settings.svg';
import { ReactComponent as SvgIconUnstackles } from '../../assets/images/icon-sparkles.svg';

import slugify from 'lib/slugify';
import { PROTOCOL } from 'settings';
import { promoptConformation } from 'lib/promp-confirmation';
import { DNS_ACTIVE, SITE_STATUS_PROXY } from '../../constants';

import styles from './EditorAdminBar.module.scss';
import adminBarStyles from '../AdminBar.module.scss';
import DevicePicker from './DevicePicker';
import { isDeviceMode } from 'types/LegacyEditor';

const SMALL_BAR_WIDTH = 995;
const MEDIUM_BAR_WIDTH = 1121;

function mapStateToProps(state, ownProps) {
  const { site, pageId } = ownProps;
  const fullDomain = siteSelectors.getFullDomainFromSite(site);
  const urlSite = siteSelectors.getUrlSiteFromSite(site);
  const page = pageSelectors.selectPage(state, pageId);

  if (page == null || page.id == null) {
    return {
      isLoading: true,
      urlSite: urlSite,
    };
  } else {
    return {
      isLoading: false,
      urlSite: urlSite,
      scheme: site.scheme,
      paths: site.proxy_paths,
      fullDomain: fullDomain,
      dnsStatus: site.dns_status,
      siteStatus: site.site_status,
      domain: site.domain,
      pageName: page.name,
      pageSlug: page.slug,
      pageUrl: page.absolute_url,
      pageTitle: page.meta.title,
      pageDescription: page.meta.description,
      pageType: page.item_type,
      pageStatus: page.status,
      pageIsChanged: page.is_changed,
      pageTouched: page.touched,
      pageIsPublishing: page.isPublishing,
      isUpdating: page.isUpdating,
      showPublishSuccessMessage: editorSelectors.selectShowPublishSuccessMessage(state),
      hasPermission: selectHasPermission(state),
      dynamicObjectSlug: page.dynamic_obj,
      device: uiSelectors.getDevice(state),
    };
  }
}

const mapDispatchToProps = {
  publishPage: pageActions.requestPublish,
  discardPage: pageActions.requestDiscard,
  openPageSettings: uiPageSettingsActions.open,
  openTemplateSettings: uiTemplateSettings.open,
  addOps: uiPageSettingsActions.addOps,
  updateSubmittingState,
  requestPageInfoUpdate: pageActions.requestPageInfoUpdate,
  resetSubmittingState,
};

const BACK_TO_PATHS = {
  landing_page: 'landing-pages',
  site_page: 'pages',
  system_page: 'pages',
  product: 'product-templates',
  collection: 'product-templates',
  data_page: 'dynamic-pages',
};

class EditorAdminBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = { text: props.pageTitle, barWidth: window.innerWidth - props.width, hasProducts: 'init' };
    this.escFunction = this.escFunction.bind(this);
    this.inputField = React.createRef();
    this.updateBarWidth = this.updateBarWidth.bind(this);
  }
  checkProductsExist = async () => {
    this.setState({ hasProducts: 'loading' });
    const res = await api.getProductsInfo('', 10, '', '', this.props.site.id, this.props.site.is_shopify);
    const hasProducts = res?.json?.data && res?.json?.data?.length > 0;
    this.setState({ hasProducts });
  };
  handleClickInfo = (e) => {
    e.stopPropagation();
    const { pageId, openPageSettings } = this.props;
    openPageSettings(pageId);
  };

  handleClickPublish = (e) => {
    const { pageId, pageStatus, publishPage, openPageSettings } = this.props;

    if (pageStatus === 'draft') openPageSettings(pageId);
    else publishPage(pageId);
  };

  handleClickDiscard = () => {
    const { discardPage, pageId } = this.props;
    promoptConformation('discardChanges', () => discardPage(pageId));
  };

  handleClickTemplate = (e) => {
    const { pageId, openTemplateSettings } = this.props;
    e.stopPropagation();
    openTemplateSettings(pageId);
  };

  escFunction(event) {
    if (event.key === 'Escape') {
      if (this.state.editing) {
        this.inputField.current.blur();
        this.setState({ text: this.state.initText });
        this.updateName(this.state.initText);
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (!this.props.isLoading && prevProps.isLoading) {
      this.setState({ text: this.props.pageTitle || 'New page' });
    }
    if (this.props.pageTitle !== prevProps.pageTitle && !this.state.editing && this.props.pageTitle) {
      this.setState({ text: this.props.pageTitle });
    }
    if (!this.props.isUpdating && prevProps.isUpdating) {
      this.props.resetSubmittingState();
    }
    if (this.props.pageType === 'product' && this.state.hasProducts === 'init') {
      this.checkProductsExist();
    }
  }
  componentDidMount() {
    if (this.props.pageTitle === null) this.setState({ text: 'New page' });
    document.addEventListener('keydown', this.escFunction, false);
    window.addEventListener('resize', this.updateBarWidth);
  }
  componentWillUnmount() {
    document.removeEventListener('keydown', this.escFunction, false);
    window.removeEventListener('resize', this.updateBarWidth);
  }

  updateBarWidth() {
    this.setState({ barWidth: window.innerWidth - this.props.width });
  }

  updateName = throttle((text) => {
    if (this.state.initName === this.state.initText || this.state.initText === 'New page' || this.state.hasTitleError) {
      this.props.addOps({
        type: 'set',
        path: 'name',
        value: text || this.state.text,
      });
    }
    if (
      this.state.initSlug === slugify(this.state.initText) ||
      this.state.initText === 'New page' ||
      this.state.hasTitleError
    ) {
      if (this.props.pageStatus !== 'published') {
        this.props.addOps({
          type: 'set',
          path: 'slug',
          value: slugify(text || this.state.text),
        });
      }
    }
    this.props.addOps({
      type: 'set',
      path: 'meta.title',
      value: text || this.state.text,
    });
    this.props.updateSubmittingState(true, {});
    this.props.requestPageInfoUpdate(
      this.props.pageId,
      () => {
        this.setState({ hasTitleError: false, errorPath: '' });
      },
      (_json) => {
        if (this.inputField.current.focus === document.activeElement) {
        }
        this.setState({ hasTitleError: true, errorPath: slugify(text) });
      }
    );
  }, 2000);

  render() {
    const {
      isLoading,
      urlSite,
      isUpdating,
      scheme,
      paths,
      fullDomain,
      pageId,
      pageSlug,
      pageUrl,
      pageStatus,
      pageIsChanged,
      pageTouched,
      pageIsPublishing,
      pageType,
      showPublishSuccessMessage,
      dynamicObjectSlug,
      hasPermission,
      dnsStatus,
      siteStatus,
      domain,
      isLoadingPage,
      width,
    } = this.props;

    let pageTitle, pageLink, displayUrl, linkUrl, previewUrl;
    let autoSaveMessage = '';
    const isPublished = pageStatus === 'published';

    if (!isLoading) {
      // Page name
      if (this.props.pageTitle) pageTitle = this.props.pageTitle;
      else if (pageType === 'landing_page') pageTitle = 'New Landing Page';
      else pageTitle = 'New page';
      pageTitle = truncate(pageTitle, { length: 30 });

      // Page URL
      if (!isLoadingPage) {
        if (pageUrl) {
          linkUrl = pageUrl;
          displayUrl = pageUrl.replace(/^https?:\/\//, '');
        } else {
          if (pageSlug === '') {
            displayUrl = fullDomain;
          } else if (pageSlug != null) {
            const proxyPath = paths ? paths.page : '';
            displayUrl = `${fullDomain}${proxyPath}/${pageSlug}`;
          }
          if (isPublished) {
            linkUrl = (scheme || 'https') + '://' + displayUrl;
          }
        }
      }
      displayUrl = truncate(displayUrl, { length: 40 });

      let proxyPath = '';
      if (dnsStatus === DNS_ACTIVE && siteStatus === SITE_STATUS_PROXY && domain) proxyPath = paths ? paths.page : '';
      previewUrl = `${PROTOCOL}//${fullDomain}${proxyPath ? `${proxyPath}/` : '/'}item-preview/${pageId}${
        dynamicObjectSlug ? `?dynamic_obj=${dynamicObjectSlug}` : ''
      }`;

      linkUrl = linkUrl ? linkUrl.replace('%', dynamicObjectSlug) : '#';

      if (displayUrl)
        pageLink = (
          <a
            data-test-id="site-page-url"
            href={linkUrl}
            target="_blank"
            rel="noopener noreferrer"
            className={styles.url}
          >
            {displayUrl}
          </a>
        );

      // Autosave message
      if (pageTouched === undefined) autoSaveMessage = '';
      else if (pageTouched || isUpdating) autoSaveMessage = 'Saving changes...';
      else autoSaveMessage = 'Changes saved';
    }

    const siteTypePath = BACK_TO_PATHS[pageType] || 'pages';
    const backTo = `/${urlSite}/${siteTypePath}`;

    const autoSaveColor = autoSaveMessage === 'Publishing...' ? '#647FB4' : '#7D8FB2';

    const isWideBar = this.state.barWidth > SMALL_BAR_WIDTH || width === undefined;
    const isMediumWidthBar = this.state.barWidth > MEDIUM_BAR_WIDTH || width === undefined;

    const showSaveMessage = isMediumWidthBar && autoSaveMessage !== 'Changes saved' && !pageIsPublishing;
    const showDiscardChanges =
      isMediumWidthBar &&
      pageIsChanged &&
      (!autoSaveMessage || autoSaveMessage === 'Changes saved') &&
      isPublished &&
      hasPermission('Page:discard') &&
      !pageIsPublishing;
    const inputVal = isLoading ? '' : this.state?.text;
    let publishText = !isPublished ? 'Publish' : pageIsChanged ? 'Publish changes' : 'Published';
    let publishWidth = pageIsPublishing ? 60 : publishText == 'Published' || publishText === 'Publish' ? 120 : 180;

    const onTitleFormSubmit = (ev) => {
      ev.preventDefault();
      ev.target.children[0].blur();
      this.updateName(inputVal);
    };

    return (
      <>
        <AdminBar
          fixed
          backTo={backTo}
          width={width}
          isSaving={isUpdating}
          center={isDeviceMode() && <DevicePicker pageId={pageId} />}
          left={
            <>
              <div
                className={styles.leftText}
                onMouseEnter={() => this.setState({ pageTitleHover: true })}
                onMouseLeave={() => this.setState({ pageTitleHover: false })}
                style={{ cursor: this.state.editing ? 'initial' : 'pointer' }}
                onClick={() => {
                  if (!this.state.edting) {
                    this.inputField.current.focus();
                  }
                }}
              >
                <span className={styles.inputWrap}>
                  <span
                    class={styles.widthControl}
                    style={{ maxWidth: isWideBar ? 450 : 450 - (SMALL_BAR_WIDTH - this.state.barWidth) }}
                  >
                    {inputVal}
                  </span>
                  <form className={styles.titleForm} onSubmit={onTitleFormSubmit}>
                    <input
                      ref={this.inputField}
                      onBlur={() => {
                        this.setState({ editing: false });
                      }}
                      onFocus={(e) => {
                        e.target.select();
                        this.setState({
                          editing: true,
                          initText: this.state.text,
                          initName: this.props.pageName,
                          initSlug: this.props.pageSlug,
                        });
                      }}
                      onChange={(e) => {
                        this.setState({ text: e.target.value });
                        if (this.state.errorPath && slugify(e.target.value) !== this.state.errorPath) {
                          this.setState({ hasTitleError: false, errorPath: '' });
                        }
                        this.updateName(e.target.value);
                      }}
                      onMouseEnter={() => this.setState({ inputHovered: true })}
                      onMouseLeave={() => this.setState({ inputHovered: false })}
                      spellCheck="false"
                      value={inputVal}
                      disabled={isLoading}
                      tabIndex={2}
                      className={classnames({ [styles.errorTitle]: this.state.hasTitleError })}
                      placeholder="Add title"
                    />
                    {this.state.hasTitleError && this.state.inputHovered && (
                      <EditorAdminBarTitleError errorPath={this.state.errorPath} ref={this.inputField.current} />
                    )}
                  </form>
                </span>
              </div>
              {isMediumWidthBar && (
                <SvgIconEdit
                  className={classnames(styles.editIcon, {
                    [styles.shownIcon]: this.state.pageTitleHover && !this.state.editing,
                  })}
                />
              )}
              {!isLoading && isPublished && pageType !== 'product' && (
                <a
                  href={linkUrl}
                  target="_blank"
                  className={styles.iconLink}
                  style={{ marginLeft: isMediumWidthBar ? 19 : -5 }}
                >
                  <SvgIconLink data-tip="Open published URL" data-place="bottom" />
                </a>
              )}
              {!isLoading && !isPublished && pageType !== 'product' && (
                <a href={previewUrl} target="_blank" rel="noopener noreferrer">
                  <span className={styles.draft}>DRAFT</span>
                </a>
              )}
            </>
          }
          right={
            <section className={styles.right} data-test-id="editor-admin-bar-right">
              {showSaveMessage && (
                <div class={styles.saveLabel} style={{ color: autoSaveColor }}>
                  {pageIsPublishing ? 'Publishing...' : autoSaveMessage}
                </div>
              )}
              {showDiscardChanges && (
                <div onClick={this.handleClickDiscard} className={styles.discardChanges}>
                  <span>↺</span> Discard changes
                </div>
              )}
              {(this.props.pageType !== 'product' || this.state.hasProducts !== 'loading') && (
                <a
                  href={`${previewUrl}${
                    this.props.pageType === 'product' && !this.state.hasProducts ? '?dynamic_obj' : ''
                  }`}
                  data-test-id="preview"
                  rel="noopener noreferrer"
                  tabIndex={-1}
                  className={styles.previewAnchor}
                  target="_blank"
                >
                  <button className={`${styles.preview}`}>Preview</button>
                </a>
              )}
              <button
                data-test-id="editor-admin-bar-publish"
                className={`${styles.publish}`}
                onClick={!pageIsPublishing ? this.handleClickPublish : null}
                disabled={(hasPermission && !hasPermission('Page:publish')) || !pageIsChanged}
                style={{ width: publishWidth }}
              >
                {pageIsPublishing ? <img src={spinnerCircle} className={styles.spinner} /> : <span>{publishText}</span>}
              </button>
              <button className={adminBarStyles.circleIcon} onClick={this.handleClickInfo} aria-label="settings">
                <SvgIconSettings />
              </button>
              <button
                className={classnames(adminBarStyles.circleIcon, styles.helpIcon, {
                  [adminBarStyles.active]: this.props.sidebarOpen,
                })}
                onClick={this.props.toggleUniversity}
                aria-label="help"
              >
                <SvgIconHelp />
              </button>
            </section>
          }
          foreGround={
            <CSSTransition
              in={showPublishSuccessMessage}
              classNames={styles.successMsg}
              timeout={300}
              mountOnEnter
              unmountOnExit
              appear
            >
              {(state) => (
                <div className={styles.successMsg} data-test-id="success-message">
                  <div>
                    <SvgIconUnstackles />
                    <strong>Success! </strong> Published to {pageLink}
                  </div>
                </div>
              )}
            </CSSTransition>
          }
        />
      </>
    );
  }
}

export default compose(withSite, connect(mapStateToProps, mapDispatchToProps))(EditorAdminBar);
