import React from 'react';
import idx from 'lodash/get';

import UpDownButtons from '../../../Editor/Toolbars/Buttons/UpDownButtons';

export default function LogoEditorButtons(props) {
  const { site, siteUpdaters } = props;

  const header_padding = idx(site, 'styles.header_padding');

  function handleIncrement() {
    if (header_padding < 10) siteUpdaters.set('styles.header_padding', header_padding + 1);
  }

  function handleDecrement() {
    if (header_padding > 0) siteUpdaters.set('styles.header_padding', header_padding - 1);
  }

  return (
    <>
      <label>Padding:</label>
      <span>{header_padding}</span>
      <UpDownButtons
        upProps={{
          onClick: handleIncrement,
          disabled: header_padding >= 10,
        }}
        downProps={{
          onClick: handleDecrement,
          disabled: header_padding <= 0,
        }}
      />
    </>
  );
}
