import { buffers, eventChannel, END } from 'redux-saga';
import { assembleUrl } from '../services/spark-api';

export function createUploadFileChannel(endpoint: string, file: File, category: string) {
  return eventChannel((emitter) => {
    const xhr = new XMLHttpRequest();
    const onProgress = (e: ProgressEvent) => {
      if (e.lengthComputable) {
        const progress = e.loaded / e.total;
        emitter({ progress });
      }
    };
    const onFailure = (e: ProgressEvent) => {
      emitter({ err: new Error('Upload failed') });
      emitter(END);
    };
    xhr.upload.addEventListener('progress', onProgress);
    xhr.upload.addEventListener('error', onFailure);
    xhr.upload.addEventListener('abort', onFailure);
    xhr.onreadystatechange = (e) => {
      try {
        const { readyState, status } = xhr;
        if (readyState === XMLHttpRequest.DONE) {
          const response = JSON.parse(xhr.responseText);
          if (status === 201) {
            emitter({ success: true, response });
            emitter(END);
          } else if ([400, 415].includes(status)) {
            emitter({ success: false, response });
            emitter(END);
          } else if (response.unauthorized) {
            emitter({ success: false, response, unauthorized: true });
            emitter(END);
          }
        }
      } catch (err) {
        emitter({
          success: false,
          response: {
            message: 'Something went wrong. There was an error while processing your request',
            invalidJson: true,
          },
        });
        emitter(END);
      }
    };
    xhr.open('POST', assembleUrl(endpoint), true);
    xhr.setRequestHeader(
      'Authorization',
      //  @ts-ignore next-line
      `JWT ${window.sprkfe.storage.getItem('JWT-TOKEN')}`
    );
    const formData = new FormData();
    formData.append('media', file);
    formData.append('category', category);
    xhr.send(formData);
    return () => {
      xhr.upload.removeEventListener('progress', onProgress);
      xhr.upload.removeEventListener('error', onFailure);
      xhr.upload.removeEventListener('abort', onFailure);
      xhr.onreadystatechange = null;
      xhr.abort();
    };
  }, buffers.sliding(2));
}
