import React, { useState } from 'react';
import classnames from 'classnames';

import styles from '../Sheet/Sheet.module.scss';

export const TextInput = ({ onChange, value, removeSheetStyles, associatedLabelId }) => {
  const [inputValue, setInputValue] = useState(value);
  return (
    <input
      onKeyDown={(e) => {
        if (e.keyCode === 9 || e.keyCode === 13) onChange(inputValue);
      }}
      onChange={(e) => setInputValue(e.target.value)}
      value={inputValue}
      onBlur={() => {
        onChange(inputValue);
      }}
      type="text"
      name="text"
      id={associatedLabelId}
      autoFocus={!removeSheetStyles}
      className={classnames({ [styles.textInput]: !removeSheetStyles })}
    />
  );
};
