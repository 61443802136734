import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { DndProvider } from 'react-dnd';
import ReactDataGrid from 'react-data-grid';
import { HTML5Backend } from 'react-dnd-html5-backend';

import ZeroState, { Group, Header, Muted } from 'components/AdminPanel/ZeroState';
import DataTypeInput from '../DataTypes';
import useUrlSite from 'hooks/useUrlSite';
import DataFormatters from '../Formatters';
import RowCreatingBar from './NewRowEditingBar';
import DraggableRowRenderer from './DraggableRow';
import DraggableHeaderRenderer from './DraggableHeader';
import ColumnSettingDrawer from './ColumnSettingDrawer';

import * as dataTablesActions from 'actions/dataTablesActions';
import * as dataTableSelector from 'reducers/dataTablesReducer';

import { ReactComponent as SvgIconAdd } from '../../../../assets/images/icon-add-item.svg';
import { ReactComponent as SvgIconBack } from '../../../../assets/images/icon-admin-panel-back.svg';

import styles from './Sheet.module.scss';
import Spinner from 'components/base/Spinner';
import ImportModal from './ImportModal';
import { selectActiveSite } from 'reducers/sitesReducer';
import Toast from 'components/base/Toast';
import { SPARK_API_BASE_URL } from 'settings';

const mapStateToProps = (state, ownProps) => {
  return {
    rows: dataTableSelector.selectTableRow(state),
    tables: dataTableSelector.selectTables(state),
    columns: dataTableSelector.selectTableColumn(state),
    table: dataTableSelector.selectTableById(state, ownProps.tableId),
    isFetching: dataTableSelector.selectIsFetching(state),
  };
};
const mapDispatchToProps = {
  request: dataTablesActions.requestTableData,
  updateCell: dataTablesActions.updateCell,
  reorderColumns: dataTablesActions.reorderColumns,
  reorderRows: dataTablesActions.reorderRows,
  updateRowSlug: dataTablesActions.updateRowSlug,
  updateColumnSlug: dataTablesActions.updateColumnSlug,
  addRow: dataTablesActions.createRow,
  addColumn: dataTablesActions.createColumn,
  updateColumn: dataTablesActions.updateColumn,
  removeColumn: dataTablesActions.deleteColumn,
  clearSelectedTable: dataTablesActions.clearSelectedTable,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)((props) => {
  const [isOpen, setOpen] = useState(false);
  const [errors, setErrors] = useState({});
  const [showPlaceholder, setPlaceholderState] = useState(false);
  const [placeholder, updatePlaceholder] = useState({ row_uuid: null });
  const [showImportModal, setShowImportModal] = useState(false);
  const currentSite = useSelector(selectActiveSite);
  const [toastInfo, setToastInfo] = useState({ messages: [], type: 'success' });

  const downloadElementRef = useRef();

  useEffect(() => {
    props.request(props.tableId);
  }, []);

  const rowPlaceholder = useMemo(() => {
    const placeholder = {};
    props.columns.forEach((column) => (placeholder[column.key] = ''));
    return placeholder;
  }, [props.columns]);

  useEffect(() => {
    updatePlaceholder({ ...rowPlaceholder });
  }, [rowPlaceholder]);

  const siteUrl = useUrlSite();

  const columns = useMemo(() => {
    return props.columns.map((c) => {
      const updater = (key, value) => {
        updatePlaceholder({ ...rowPlaceholder, ...placeholder, [key]: value });
      };
      return {
        ...c,
        headerCellClass: styles.cellHeaders,
        width: c.data_type ? 200 : 135,
        ...([
          'media',
          'foreign_key',
          'datetime',
          'date',
          undefined, // undefined represents the slug column
        ].includes(c.data_type) && {
          formatter: ({ row }) => {
            const InputType = DataFormatters[c.data_type || 'slug'];
            return (
              <InputType
                formatting
                value={row[c.id || 'row_slug']}
                id={row.row_uuid}
                category={c.media_category}
                onChange={(src) => {
                  if (row.row_uuid) props.updateCell(row.row_uuid, { [c.id]: src });
                  else {
                    updatePlaceholder({
                      ...rowPlaceholder,
                      ...placeholder,
                      [c.id]: src,
                    });
                  }
                }}
                table={c.related_table}
              />
            );
          },
        }),
        editor: ({ row }) => {
          const InputType = DataTypeInput[c.data_type || 'text'];
          return (
            <InputType
              formatting={false}
              category={c.media_category}
              value={c.data_type ? row[c.id] : row.row_slug}
              table={c.related_table}
              onChange={(value) => {
                if (row.row_uuid)
                  c.data_type
                    ? props.updateCell(row.row_uuid, { [c.id]: value })
                    : props.updateRowSlug(row.row_uuid, value);
                else updater(c.id || 'row_slug', value);
              }}
            />
          );
        },
      };
    });
  }, [props.columns, placeholder]);

  const draggableColumns = useMemo(() => {
    function HeaderRenderer(props) {
      return <DraggableHeaderRenderer {...props} onColumnsReorder={handleColumnsReorder} setOpen={setOpen} />;
    }
    function handleColumnsReorder(sourceKey, targetKey) {
      const sourceColumnIndex = columns.findIndex((c) => c.key === sourceKey);
      const targetColumnIndex = columns.findIndex((c) => c.key === targetKey);
      props.reorderColumns(sourceKey, targetColumnIndex, sourceColumnIndex);
    }
    return columns.map((c) => {
      if (c.key === 'row_slug') return c;
      return { ...c, headerRenderer: HeaderRenderer };
    });
  }, [props.columns, placeholder]);

  const exportTable = async (filename) => {
    const exportUrl = `${SPARK_API_BASE_URL}data/table/${props.tableId}/export_csv/?site_id=${currentSite.id}`;

    const response = await fetch(exportUrl, {
      method: 'post',
      headers: {
        Authorization: `JWT ${window.sprkfe.storage.getItem('JWT-TOKEN')}`,
      },
    });

    if (!response.ok) {
      setToastInfo({ messages: Object.values(await response.json()), type: 'error' });
    }

    const blob = await response.blob();
    const url = URL.createObjectURL(blob);

    const downloaderElement = downloadElementRef.current;
    downloaderElement.setAttribute('href', url);
    downloaderElement.setAttribute('download', filename || 'export.csv');

    setTimeout(() => {
      downloaderElement.click();
      URL.revokeObjectURL(url);
    }, 150);
  };

  return (
    <section>
      <header className={styles.header}>
        <span style={{ alignItems: 'center' }}>
          <SvgIconBack onClick={() => props.clearSelectedTable()} />
          <span>
            <small>Data Table</small>
            {props.table.name ? <small>{props.table.name}</small> : null}
          </span>
          <span style={{ flexDirection: 'column' }}>
            <small>Connected to:</small>
            <small>
              <Link to={`/${siteUrl}/dynamic-pages`}>Connect to a page</Link> <a>(what's this?)</a>
            </small>
          </span>
        </span>
        {props.columns.length > 1 && (
          <span style={{ gap: '8px' }}>
            <a
              href="/"
              data-test-id="import"
              onClick={(e) => {
                e.preventDefault();
                setShowImportModal(true);
              }}
              className="button button-secondary"
            >
              Import
            </a>
            <a
              href="/"
              data-test-id="export"
              onClick={(e) => {
                e.preventDefault();
                exportTable();
              }}
              className="button button-secondary"
            >
              Export
            </a>
          </span>
        )}
      </header>
      {props.isFetching && <Spinner className="fixed" />}
      {!props.isFetching &&
        (props.columns.length === 1 ? (
          <ZeroState>
            {/** Need to enable it when data sheet icon is available */}
            {/* <Image src={icon} /> */}
            <Header>No data available</Header>
            <Muted>Start by adding a new column to you data sheet</Muted>
            <Group>
              <a
                href="/"
                data-test-id="create-column"
                onClick={(e) => {
                  e.preventDefault();
                  setOpen(true);
                }}
                className="button button-primary"
              >
                Create column
              </a>
            </Group>
          </ZeroState>
        ) : (
          <>
            <div style={{ width: '96%', position: 'relative' }} data-test-id="datatable-sheet-canvas">
              <DndProvider backend={HTML5Backend}>
                <ReactDataGrid
                  style={{ height: '75vh' }}
                  columns={draggableColumns}
                  className={styles.dataGrid}
                  rowHeight={52}
                  headerRowHeight={38}
                  rows={[...props.rows, ...(showPlaceholder ? [placeholder] : [])]}
                  rowKeyGetter={(row) => {
                    return row.id;
                  }}
                  rowRenderer={(p) => {
                    return (
                      <DraggableRowRenderer
                        key={p.rowIdx}
                        {...p}
                        onRowReorder={(id, oldIdx, newIdx) => {
                          props.reorderRows(id, newIdx, oldIdx);
                        }}
                      />
                    );
                  }}
                />
                <RowCreatingBar
                  isEditing={showPlaceholder}
                  discardChanges={() => {
                    setPlaceholderState(false);
                    updatePlaceholder({ ...rowPlaceholder });
                    setErrors({});
                  }}
                  createRow={() => {
                    const data = { ...placeholder };
                    const payload = {};
                    payload.slug = data.row_slug;
                    delete data.row_slug;
                    payload.content = data;

                    const promise = new Promise((resolve, reject) => {
                      props.addRow({ ...payload, data_table: props.tableId }, resolve, reject);
                    });
                    promise
                      .then(() => {
                        setPlaceholderState(false);
                        updatePlaceholder({ row_uuid: null });
                        setErrors({});
                      })
                      .catch((e) => {
                        setErrors(e);
                      });
                  }}
                  errors={errors}
                />
              </DndProvider>
              <SvgIconAdd
                data-test-id="create-column"
                height={30}
                width={30}
                className={styles.newColumn}
                onClick={() => setOpen(true)}
              />
              <SvgIconAdd
                data-test-id="create-row"
                height={30}
                width={30}
                className={styles.newRow}
                onClick={() => setPlaceholderState(true)}
              />
            </div>
          </>
        ))}
      <ColumnSettingDrawer
        addColumn={props.addColumn}
        updateColumn={props.updateColumn}
        isOpen={isOpen}
        close={() => setOpen(false)}
        table={props.tableId}
        tables={props.tables.filter((t) => t.id !== props.tableId)}
        removeColumn={props.removeColumn}
      />
      <ImportModal
        open={showImportModal}
        handleClose={() => setShowImportModal(false)}
        table={props.tableId}
        refresh={() => props.request(props.tableId)}
        setToastInfo={setToastInfo}
        exportTable={exportTable}
        hasData={Boolean(props.rows?.length)}
      />
      <Toast messages={toastInfo.messages} type={toastInfo.type} timeout={5000} />
      <a id="download-hook" ref={downloadElementRef} />
    </section>
  );
});
