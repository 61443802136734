import * as types from './actionTypes';

export function open(pageId) {
  return {
    type: types.TEMPLATES_SETTINGS_OPEN,
    payload: pageId,
  };
}

export function close() {
  return {
    type: types.TEMPLATES_SETTINGS_CLOSE,
  };
}
