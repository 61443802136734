import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import LUID from 'lib/luid';
import EmbeddedForm from 'components/Editor/EmbeddedForm';
import * as Sentry from '@sentry/browser';

export default function FormEmbedManager(props) {
  const { value, quill } = props;

  const ops = get(value, 'ops');

  if (ops && ops.filter) {
    const formOps = ops.filter((op) => get(op, 'insert.form_embed'));

    return formOps.map((op, i) => <FormEmbed key={i} i={i} value={op} quill={quill} />);
  }

  return null;
}

window.LUID = LUID;

function FormEmbed(props) {
  const { value, i, quill } = props;

  window.quill = quill;

  if (!quill || !quill.container || !quill.container.querySelectorAll) return null;

  const nodes = quill.container.querySelectorAll('.quill-form-embed-container');
  const node = nodes[i];

  if (node) {
    window.node = node;
    return ReactDOM.createPortal(
      <EmbeddedForm
        unstackOnly
        value={value.insert.form_embed.formEntity}
        onChange={(value) => {
          const blot = quill.constructor.find(node);
          const index = quill.getIndex(blot);

          if (isEmpty(value)) {
            quill.deleteText(index, 1, 'user');
          } else {
            quill.deleteText(index, 1, 'silent');
            quill.insertEmbed(index, 'form_embed', { formEntity: value }, 'user');
          }
        }}
      />,
      node
    );
  } else {
    const msg = "FormEmbedManager: Can't find a matching FormEmbedBlot in the DOM for Quill delta";
    console.error(msg, value);

    const error = new Error(msg);
    Sentry.captureException(error, { extra: { formEntity: value } });
  }

  return null;
}
