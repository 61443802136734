import * as types from '../actions/actionTypes';
import { createSelector } from 'reselect';

// ----------------------------------------------------------------------------
// Reducer
// ----------------------------------------------------------------------------

const INITIAL_STATE = {
  fetched: [],
  isLoading: false,
  count: 0,
  contactTasksCount: 0,
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.TASKS_FETCH_ALL:
      return {
        ...state,
        isLoading: true,
      };
    case types.TASKS_FETCH_FILTERED_TASKS:
      return {
        ...state,
        isLoading: true,
      };
    case types.TASKS_DELETE_REQUEST:
      return {
        ...state,
        fetched: state.fetched.filter((task) => action.payload !== task.id),
      };
    case types.TASKS_FETCH_SUCCESS:
      return {
        ...state,
        fetched: action.payload,
        isLoading: false,
      };
    case types.TASKS_MARK_AS_DONE:
      return {
        ...state,
        fetched: state.fetched.map((task) => (task.id === action.payload.id ? { ...task, status: 'done' } : task)),
      };
    case types.TASKS_FETCH_COUNT_SUCCESS:
      return {
        ...state,
        count: action.payload,
      };
    case types.TASKS_FETCH_COUNT_FOR_CONTACT_SUCCESS:
      return {
        ...state,
        contactTasksCount: action.payload,
      };

    default:
      return state;
  }
}

// ----------------------------------------------------------------------------
// Selectors
// ----------------------------------------------------------------------------

const selectLocalState = (globalState) => globalState.tasks;

export const selectAllTasks = createSelector([selectLocalState], (tasks) => [
  ...tasks.fetched.filter((task) => task.status === 'pending'),
  ...tasks.fetched.filter((task) => task.status === 'done'),
]);

export const selectIsLoading = createSelector([selectLocalState], (tasks) => tasks.isLoading);

export const selectTasksCount = createSelector([selectLocalState], (tasks) => tasks.count);

export const selectContactTaskCount = createSelector([selectLocalState], (tasks) => tasks.contactTasksCount);
