import React from 'react';
import { Routes, Route } from 'react-router-dom';
import FormsList from './Forms/FormsList';
import FormEditor from './Forms/FormEditor';

export default function Forms() {
  return (
    <Routes>
      <Route path="/" element={<FormsList />} />
      <Route path="/:formId?" element={<FormEditor />} />
    </Routes>
  );
}
