import React from 'react';

import { Button } from '../../../../../../Editor/Toolbars2/Toolbar2';
import { IconConditionalContent } from '../../../../../../Editor/Toolbars2/icons';
import { UBoxInfo, USectionDevices } from 'types/USection';
import { set, del, insert, push, assign } from 'object-path-immutable';
import { getDeviceTypeToSaveTo } from 'components/unstack-components/Component/util/helpers/deviceHelper';
import { useSelector } from 'react-redux';
import { getDevice } from 'reducers/uiReducer';

type Props = {
  dataRef: UBoxInfo;
  onChange: (content: USectionDevices, key?: string, multi?: boolean) => void;
  contentKey: string;
  content: USectionDevices;
};

const INITIAL_VALUE: any = {
  enabled: true,
  logical_operator: 'all',
  conditions: [{}],
};

const ConditionalContent: React.FunctionComponent<Props> = ({ dataRef, onChange, content, contentKey }) => {
  const device = useSelector(getDevice);
  return (
    <Button
      onMouseDown={() => {
        const splitKey = contentKey.split('.');
        const newValue = {
          ...INITIAL_VALUE,
          ...content.base?.conditional,
          enabled: !dataRef.conditional?.enabled,
        };
        onChange(
          set(content, [getDeviceTypeToSaveTo(device, true), ...splitKey.slice(1), 'conditional'], newValue),
          `content.${splitKey[0]}`
        );
      }}
      data-tip="Conditional content"
      data-test-id="conditional-content"
      style={{ width: '40px', padding: '9px' }}
    >
      <IconConditionalContent active={dataRef?.conditional?.enabled} />
    </Button>
  );
};

export default ConditionalContent;
