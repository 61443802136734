import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import List from '../../react-table/List';
import createDateFormater from 'date-fns/fp/format';
import differenceInHours from 'date-fns/fp/differenceInHours';
import styles from './ContactsList.module.scss';
import defaultAvatar from '../../../assets/images/icon-profile-default.svg';
import concatenateName from '../../../lib/formatters/concatenate-name';
import { ReactComponent as PrevSvg } from '../../../assets/images/icon-prev-page.svg';
import { ReactComponent as NextSvg } from '../../../assets/images/icon-next-page.svg';
import getHighlightedText from '../../../lib/get-highlighted-text';
import { selectAccountUser } from '../../../reducers/accountUsersReducer';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

const formatDate = createDateFormater('MMMM do, yyyy');

const columns = [
  {
    id: 'name',
    accessor: (row: any) => {
      let nameOrOrganization;

      if (row.first_name || row.last_name) {
        nameOrOrganization = concatenateName(row.first_name, row.last_name);
      } else if (row.organization) {
        nameOrOrganization = `${row.organization}`;
      } else {
        nameOrOrganization = undefined;
      }

      const name = row.query ? getHighlightedText(nameOrOrganization, row.query) : nameOrOrganization;

      const email = row.query ? getHighlightedText(row.email, row.query) : row.email;

      return {
        name,
        email,
        showNewTag: differenceInHours(new Date(row.created_at))(new Date()) < 24,
      };
    },
    Cell: ({ value }: { value: any }) => (
      <>
        <img className={styles.avatar} src={defaultAvatar} alt="" />
        {value.name && (
          <>
            <span className="ph-redact-text">{value.name}</span>
            <span className="muted ph-redact-text">&nbsp; {value.email}</span>
          </>
        )}
        {!value.name && <span>{value.email}</span>}
        {value.showNewTag && <span className={styles.tag}>New</span>}
      </>
    ),
    minWidth: 360,
  },
  {
    accessor: 'owner',
    Cell: ({ value }: { value: any }) => {
      const owner = useSelector((state) => selectAccountUser(state, value));
      return owner ? (
        <>
          <span className="muted">Owner </span>
          <span>{owner.name}</span>
        </>
      ) : null;
    },
    minWidth: 100,
  },
  {
    id: 'last_seen_at',
    accessor: (row: any) => new Date(row.last_seen_at),
    Cell: ({ value }: { value: any }) => (
      <>
        <span className="muted">Last seen </span>
        <span>{formatDate(value)}</span>
      </>
    ),
    minWidth: 100,
  },
  {
    accessor: 'visits',
    Cell: ({ value }: { value: any }) => (
      <>
        <span>{value.toLocaleString()}</span>
        <span className="muted"> web sessions</span>
      </>
    ),
    minWidth: 100,
  },
];

function ContactsList(props: any) {
  const { data, isLoading, count, activePage } = props;
  const location = useLocation();
  const { search } = location;
  const { urlSite } = useParams();
  const navigate = useNavigate();

  const getTdProps = useCallback(
    (state: any, row: any) => {
      return {
        onClick: (e: any) => {
          navigate(`/${urlSite}/customers/${row.original.id}/`, {
            state: {
              search,
            },
          });
        },
        style: { cursor: 'pointer' },
      };
    },
    [history, search]
  );

  return (
    <div className={styles.container}>
      <List
        className="-highlight"
        data={data}
        columns={columns}
        count={count}
        defaultPageSize={50}
        getTdProps={getTdProps}
        loading={isLoading}
        showViewAll={false}
        breakPageslabel={<span>...</span>}
        nextPageLabel={<NextSvg />}
        onPageChange={props.setPageNumber}
        pagesCount={count / 50}
        paginate
        activePage={activePage}
        previousPageLabel={<PrevSvg />}
      />
    </div>
  );
}

export default ContactsList;
