import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

import SiteList from './SiteList';
import UnstackSplash from './UnstackSplash';

import { selectAccountIsFetching } from '../reducers/accountReducer';
import { selectAllSites, getUrlSiteFromSite } from '../reducers/sitesReducer';

import styles from './Home.module.scss';

const Home = () => {
  const isFetching = useSelector(selectAccountIsFetching);
  const sites: any[] = useSelector(selectAllSites);
  const siteUrl = sites.length === 1 ? getUrlSiteFromSite(sites[0]) : null;

  if (siteUrl) {
    return <Navigate to={siteUrl} />;
  }
  return (
    <UnstackSplash isLoading={isFetching}>
      <div className={styles.container}>
        <h3 className={styles.header}>Select a site to continue</h3>
        <SiteList sites={sites} className={styles.SiteList} />
      </div>
    </UnstackSplash>
  );
};

export default Home;
