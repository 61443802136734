import * as types from '../actions/actionTypes';

import { createSelector } from 'reselect';
import createCachedSelector from 're-reselect';
import { hasOps, applyOps } from '../lib/immutable-operations/';
import { set, update, del } from '../lib/immutable-operations/operations';

import union from 'lodash/union';
import concat from 'lodash/concat';
import isArray from 'lodash/isArray';
import without from 'lodash/without';
import keyBy from 'lodash/keyBy';
import groupBy from 'lodash/groupBy';
import transform from 'lodash/transform';
import { selectCurrentAccountUser } from './accountUsersReducer';
import { getUrlFromSite, selectSiteById } from './sitesReducer';

// ----------------------------------------------------------------------------
// Reducer
// ----------------------------------------------------------------------------

const initState = {
  isFetching: null,
  isFetchingAll: null,
  byId: {},
  allIds: [],
  searchedIds: [],
  autosaveOpsById: {},
  count: undefined,
};

export default function articlesReducer(state = initState, action) {
  switch (action.type) {
    // Article list
    // ------------------------------------------------------------------------

    case types.ARTICLES_REQUEST_LIST:
      return applyOps(state, {
        type: 'set',
        path: 'isFetchingAll',
        value: true,
      });

    case types.ARTICLES_RECEIVE_LIST: {
      const { articleIds, entities, count } = action.payload;
      const articles = entities.articles || {};
      return applyOps(state, [
        set('isFetching', false),
        update('byId', (oldById) => {
          // Keep existing article.content. If none exists, delete
          // article.content. This is because draft content is only available
          // on the `GET article/{uuid}/` endpoint.
          return transform(articles, (newById, article, id) => {
            newById[id] = { ...article };
            if (oldById[id] && oldById[id].content) {
              newById[id].content = oldById[id].content;
            } else {
              delete newById[id].content;
            }
          });
        }),
        set('allIds', articleIds),
        set('count', count),
      ]);
    }

    case types.ARTICLES_RECEIVE_LIST_BY_PAGE:
      return { ...state, isFetching: true, isFetchingAll: null };

    case types.ARTICLES_RECEIVE_SEARCHED_LIST:
      return {
        ...state,
        searchedIds: [...action.payload.articleIds],
        isFetching: false,
      };

    // Request & receive article
    // ------------------------------------------------------------------------

    case types.ARTICLE_REQUEST: {
      const { articleId } = action.payload;
      return applyOps(state, [set(['byId', articleId, 'isFetching'], true)]);
    }

    case types.ARTICLE_RECEIVE: {
      const { article } = action.payload;
      return applyOps(state, [set(['byId', article.id], article), update('allIds', (ids) => union(ids, [article.id]))]);
    }

    // Update
    // ------------------------------------------------------------------------

    case types.ARTICLE_WILL_REQUEST_UPDATE:
      return applyOps(state, [set(['byId', action.payload.articleId, 'isUpdating'], true)]);

    case types.ARTICLE_RECEIVE_UPDATE_SUCCESS:
    case types.ARTICLE_RECEIVE_UPDATE_ERROR:
      return applyOps(state, [set(['byId', action.payload.articleId, 'isUpdating'], false)]);

    case types.ARTICLE_RECEIVE_PUBLISH_SUCCESS:
      return applyOps(state, [set(['byId', action.payload.articleId, 'isPublishing'], false)]);

    case types.ARTICLE_REQUEST_PUBLISH:
      return applyOps(state, [set(['byId', action.payload.articleId, 'isPublishing'], true)]);

    // Autosave
    // ------------------------------------------------------------------------

    case types.ARTICLE_ADD_AUTOSAVE_OPS: {
      const { articleId, ops } = action.payload;
      return applyOps(state, [
        update(['autosaveOpsById', articleId], (oldOps) => {
          if (isArray(oldOps)) return concat(oldOps, ops);
          else return concat(ops);
        }),
        set(['byId', articleId, 'is_changed'], true),
      ]);
    }

    case types.ARTICLE_REMOVE_AUTOSAVE_OPS: {
      const { articleId, ops } = action.payload;
      return applyOps(
        state,
        update(['autosaveOpsById', articleId], (oldOps) => {
          return without(oldOps, ...ops);
        })
      );
    }

    // Delete
    // ------------------------------------------------------------------------

    case types.ARTICLE_REQUEST_DELETE: {
      const { articleId } = action.payload;
      return applyOps(state, [
        del(['byId', articleId]),
        update('allIds', (allIds) => allIds.filter((id) => id !== articleId)),
      ]);
    }

    // Receive via other entities
    // ------------------------------------------------------------------------
    case types.PAGE_RECEIVE: {
      const pages = Object.values(action.payload.entities.pages);

      let ids = [];
      let byId = {};
      pages.forEach((page) => {
        if (page.section_articles) {
          const sectionsById = Object.values(page.section_articles);
          sectionsById.forEach((keydSections) => {
            const articlesByKey = Object.values(keydSections);
            articlesByKey.forEach((keydArticles) => {
              const articles = Object.values(keydArticles);
              articles.forEach((article) => {
                ids.push(article.id);
                byId[article.id] = article;
              });
            });
          });
        }
      });

      if (ids.length) {
        const newState = {
          ...state,
          allIds: union(state.allIds, ids),
          byId: Object.assign({}, state.byId, byId),
        };

        return newState;
      }

      return state;
    }

    // Default
    // ------------------------------------------------------------------------

    default:
      return state;
  }
}

// ----------------------------------------------------------------------------
// Selectors
// ----------------------------------------------------------------------------

// Raw selectors
// ----------------------------------------------------------------------------

function selectLocalState(globalState) {
  return globalState.articles;
}

export function selectIsFetching(globalState) {
  const state = selectLocalState(globalState);
  return state.isFetching;
}

export function selectIsFetchingAll(globalState) {
  const state = selectLocalState(globalState);
  return state.isFetchingAll;
}

export function selectAllIds(globalState) {
  const state = selectLocalState(globalState);
  return state.allIds;
}

export function selectById(globalState) {
  const state = selectLocalState(globalState);
  return state.byId;
}

export function selectAutosaveOpsById(globalState) {
  const state = selectLocalState(globalState);
  return state.autosaveOpsById;
}

export function selectSearchedIds(globalState) {
  const state = selectLocalState(globalState);
  return state.searchedIds;
}

// Composit selectors
// ----------------------------------------------------------------------------

export function selectArticle(globalState, articleId) {
  const byId = selectById(globalState);
  return byId[articleId];
}

export const selectAll = createSelector(selectAllIds, selectById, (allIds, byId) => allIds.map((id) => byId[id]));

const selectSearchArticlesById = createSelector(selectSearchedIds, selectById, (searchedIds, byId) =>
  searchedIds.map((id) => byId[id])
);

export const selectAllDrafts = createSelector(selectAll, selectAutosaveOpsById, (all, autosaveOpsById) =>
  all.map((article) => {
    const autosaveOps = autosaveOpsById[article.id];
    if (hasOps(autosaveOps)) return applyOps(article, autosaveOps);
    else return article;
  })
);

export const selectDraftsById = createSelector(selectAllDrafts, (drafts) => keyBy(drafts, 'id'));

export function selectDraft(globalState, articleId) {
  const draftsById = selectDraftsById(globalState);
  return draftsById[articleId];
}

export function selectHasUnsavedChanges(globalState, articleId) {
  const autosaveOpsById = selectAutosaveOpsById(globalState);
  return hasOps(autosaveOpsById[articleId]);
}

export const selectGroupsByBlogId = createSelector(selectAll, (allArticles) => groupBy(allArticles, 'parent'));

export const selectSearchArticlesByBlogId = createSelector(selectSearchArticlesById, (searchedArticles) =>
  groupBy(searchedArticles, 'parent')
);

export function selectBlogArticles(globalState, blogId) {
  const articlesByBlogId = selectGroupsByBlogId(globalState);
  return articlesByBlogId[blogId];
}

export const selectArticlesCount = createSelector(selectLocalState, (articles) => articles.count);

export const selectHasPublished = createSelector(selectAll, (articles) =>
  articles.some((article) => article.status === 'published')
);

export const selectFilters = createSelector(selectCurrentAccountUser, (user) =>
  user
    ? user.role === 'contributor'
      ? [
          { value: 'owner', label: 'my articles' },
          { value: 'draft', label: 'my drafts' },
          { value: 'scheduled', label: 'my scheduled articles' },
        ]
      : [
          { value: '', label: 'all articles' },
          { value: 'owner', label: 'my articles' },
          { value: 'draft', label: 'all drafts' },
          { value: 'scheduled', label: 'scheduled articles' },
          { value: 'published', label: 'published articles' },
          { value: 'is_deleted', label: 'Deleted articles' },
        ]
    : []
);

export const selectArticlePreviewUrl = createCachedSelector(
  selectSiteByArticleId,
  (globalState, articleId) => articleId,
  (globalState, articleId, version) => version,
  (site, articleId, version) => {
    const siteUrl = getUrlFromSite(site);
    const queryParams = version == null ? '' : `?v=${version}`;
    return `${siteUrl}/item-preview/${articleId}${queryParams}`;
  }
)((_, articleId, version) => `${articleId}-v${version}`);

// Sites
// ----------------------------------------------------------------------------

export function selectSiteByArticleId(state, articleId) {
  const article = selectArticle(state, articleId);
  if (article) return selectSiteById(state, article.site);
  return null;
}
