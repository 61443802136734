import { getYoutubeVideoIdFromUrl } from './youtube-urls';
import { getVimeoVideoId } from './vimeo-urls';
import { getWistiaVideoIdFromUrl } from './wistia-urls';

const SOURCES = {
  youtube: 'youtube',
  vimeo: 'vimeo',
  wistia: 'wistia',
};

export const isUrlValid = (url?: String): boolean | undefined =>
  url && url.match && !!url.match(/(vimeo|wistia|youtube|youtu)(\.com|\.be|:)/);

export const getSourceType = (url: String): String | undefined => {
  if (url) {
    const match = url.match(/(youtube|vimeo|wistia|youtu\.be)/i);
    if (match && match.length) return match[0].replace('.', '');
  }
};

const extractIdFromUrl = (url: String, src: String): String => {
  switch (src) {
    case SOURCES.youtube:
      return `youtube:${getYoutubeVideoIdFromUrl(String(url))}`;
    case SOURCES.vimeo:
      return `vimeo:${getVimeoVideoId(url)}`;
    case SOURCES.wistia:
      return `wistia:${getWistiaVideoIdFromUrl(url)}`;
    default:
      return '';
  }
};

export const processUrl = (url: String): Array<String | Boolean | null> => {
  url = url.trim();
  if (isUrlValid(url)) {
    const urlSourceType: String = getSourceType(url) as String;
    const smartUrl = extractIdFromUrl(url, urlSourceType);
    return [urlSourceType, smartUrl, true];
  } else return [null, null, false];
};
