import React, { useState } from 'react';
import classnames from 'classnames';

import styles from '../Sheet/Sheet.module.scss';

export const IntInput = ({ onChange, value, removeSheetStyles, associatedLabelId }) => {
  const [inputValue, setInputValue] = useState(value);
  return (
    <input
      onKeyDown={(e) => {
        if (e.keyCode === 9 || e.keyCode === 13) onChange(inputValue);
      }}
      onChange={(e) => {
        const value = e.target.value;
        setInputValue(value.replaceAll(/[^0-9]/g, ''));
      }}
      onBlur={() => onChange(inputValue)}
      value={inputValue}
      type="text"
      name="Int"
      id={associatedLabelId}
      autoFocus={!removeSheetStyles}
      className={classnames({ [styles.textInput]: !removeSheetStyles })}
    />
  );
};
