/* eslint-disable jsx-a11y/anchor-is-valid */

import React from 'react';
import { compose } from 'redux';
import classnames from 'classnames';
import { Link } from 'react-router-dom';

import SiteLogo from '../SiteLogo';
import withSite from 'containers/withSite';

import * as siteSelectors from '../../../reducers/sitesReducer';

import { ReactComponent as SvgTextLinkArrow } from '../../../assets/images/icon-text-link-arrow.svg';

import styles from './HeaderFooterOverlay.module.scss';
import { useSelector } from 'react-redux';

export default compose(withSite)(function MiniHeader(props) {
  const { brightnessTheme, site } = props;
  const urlSite = useSelector((_state) => siteSelectors.getUrlSiteFromSite(site));

  return (
    <header
      className={classnames('landing-page-header', 'is-editor', brightnessTheme)}
      style={{ position: 'relative' }}
    >
      <div className="landing-header-container">
        <span />
        <SiteLogo
          site={site}
          inverted={brightnessTheme === 'dark-bg'}
          imgProps={{
            className: 'logo',
            'data-tip': 'You can update your logo in Header & Footer',
          }}
        />
        <span />
      </div>
      <div className={classnames('admin', styles.overlay)}>
        <Link to={`/${urlSite}/elements`} data-test-id={'header-footer-styles-navigator'}>
          You can change your header in Header & Footer
          <SvgTextLinkArrow />
        </Link>
      </div>
    </header>
  );
});
