import React from 'react';
import withSite from '../../containers/withSite';

import styles from './EditorCanvas.module.scss';

import Footer from './Layout/SitePageFooter';
import Sections from './Sections';

import { FramePortal } from 'components/unstack-components/Component/FramePortal';
import classnames from 'classnames';

function SitePageCanvas(props) {
  const { pageId, site, pageType, dataTable, setIFrameLoaded, loadedIframe } = props;

  const isDeviceMode = window.location.href.includes('editor') && !window.location.href.includes('legacyeditor');
  const PageContent = (
    <div id="spark" className="eps sriracha">
      <Sections
        dataTable={dataTable}
        pageType={pageType}
        pageId={pageId}
        site={site}
        pageHeaderLayout="full"
        pageFooterLayout={<Footer site={site} />}
      />
      <div className="sticky-bottom-spacer" />
    </div>
  );

  return (
    <div className={classnames(styles.EditorCanvasContainer, { [styles.hidden]: !loadedIframe })}>
      <div className={classnames(styles.EditorCanvas, { [styles.DeviceMode]: isDeviceMode && loadedIframe })}>
        {isDeviceMode ? <FramePortal setIFrameLoaded={setIFrameLoaded}>{PageContent}</FramePortal> : PageContent}
      </div>
    </div>
  );
}

export default withSite(SitePageCanvas);
