import React from 'react';
import styles from './ProgressBar.module.scss';

interface Props {
  progress: number;
}
export default function ProgressBar({ progress }: Props) {
  return (
    <div className={styles.progressBarContainer}>
      <div
        style={{ width: `${progress}%` }}
        data-test-id={'quickstart-progressbar'}
        className={styles.progressBar}
      ></div>
    </div>
  );
}
