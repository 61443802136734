// An interface for Google Tag Manager's JavaScript API.

declare global {
  interface Window {
    gtag: any;
  }
}

export function gtag_report_conversion() {
  window.gtag('event', 'conversion', {
    send_to: 'AW-767729861/saOrCKzZzNoBEMXBiu4C',
  });
}
