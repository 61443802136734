import React, { FunctionComponent } from 'react';
import { ReactComponent as SvgIconOptions } from 'assets/images/icon-options.svg';
import classnames from 'classnames';
import styles from './three-dot-button.module.scss';

interface IThreeDotButtonProps {
  isActive?: boolean;
}

interface Props extends React.HTMLAttributes<HTMLButtonElement>, IThreeDotButtonProps {}

export default (props: Props) => {
  return (
    <button
      {...props}
      className={classnames(styles.options, props.className, {
        [styles.isActive]: props.isActive,
      })}
    >
      <SvgIconOptions />
    </button>
  );
};
