import React from 'react';
import SubmissionOptions from 'components/Editor/EmbeddedForm/Forms/SparkForm/SubmissionOptions';
import FormContactList from './FormContactList';
import { set } from 'object-path-immutable';
import { FormData, NewFormData } from './types';
import styles from './FormSettings.module.scss';
import MaxWidthOptions from './MaxWidthOptions';

type FormSettingsProps = {
  value: NewFormData | FormData;
  onChange: (value: NewFormData | FormData) => void;
};

function FormSettings(props: FormSettingsProps) {
  const form = props.value;

  if (!form) return null;

  return (
    <div className={styles.container}>
      <SubmissionOptions
        submissionAction={form.action}
        onUpdate={(action: FormData['action']) => props.onChange(set(form, 'action', action))}
        validationMessage={false}
        // validationMessage={
        //   showErrors &&
        //   !(submissionAction.message || submissionAction.url) && (
        //     <ValidationMessage message="Select an option" />
        //   )
        // }
        label=""
      />
      <MaxWidthOptions
        styles={form?.content?.styles}
        onUpdate={(value: FormData['content']['styles']) => props.onChange(set(form, `content.styles`, value))}
      />
      <FormContactList
        // @ts-ignore
        onUpdate={(path, value) => props.onChange(set(form, `content.${path}`, value))}
        form={form}
      />
    </div>
  );
}

export default FormSettings;
