import {
  hslToRgba,
  hslaToRgba,
  colorToString,
  isHslString,
  isHslaString,
  isRgbString,
  isRgbaString,
  colorToRgba,
  stringToColor,
} from 'colors-convert';

export function colorToRGBA(color: any) {
  color = color ? (typeof color === 'string' ? color.trim() : color[0]) : color;
  if (/^rgba\((\d+),\s*(\d+),\s*(\d+)\)?/.test(color)) {
    return color;
  } else if (isHslString(color) || isHslaString(color) || isRgbString(color) || isRgbaString(color)) {
    return `rgba(${colorToString(colorToRgba(stringToColor(color)))})`;
  } else if (/hsla?/.test(color)) {
    const hslArray = color.match(/\d.?\d+/gi, '');
    if (hslArray.length === 4) {
      let opacity = 1;
      if (Number(hslArray[3]) > 1) opacity = Number(hslArray[3]) / 100;
      else opacity = Number(hslArray[3]);
      const hslaObject = { h: Number(hslArray[0]), s: Number(hslArray[1]), l: Number(hslArray[2]), a: opacity };
      return `rgba(${colorToString(hslaToRgba(hslaObject))})`;
    } else {
      // 0px 10px 20px 15px hsl(200 20% 30% / 0.9)
      const hslObject = { h: Number(hslArray[0]), s: Number(hslArray[1]), l: Number(hslArray[2]) };
      return `rgba(${colorToString(hslToRgba(hslObject))})`;
    }
  } else if (/rgba?/.test(color)) {
    const rgbArray = color.match(/\d*\.?\d+/gi, '');
    if (rgbArray.length === 4) {
      let opacity = 1;
      if (Number(rgbArray[3]) > 1) opacity = Number(rgbArray[3]) / 100;
      else opacity = Number(rgbArray[3]);
      return `rgba(${Number(rgbArray[0])},${Number(rgbArray[1])},${Number(rgbArray[2])},${opacity})`;
    } else {
      return `rgba(${Number(rgbArray[0])},${Number(rgbArray[1])},${Number(rgbArray[2])},1)`;
    }
  } else if (/^rgb\((\d+),\s*(\d+),\s*(\d+)\)?/.test(color)) {
    return `rgba${color.slice(3, -1)},1)`;
  } else if (/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/.test(color)) {
    color = color.slice(1);
    color.length === 3
      ? (color = '0x' + color[0] + color[0] + color[1] + color[1] + color[2] + color[2])
      : (color = '0x' + color);
    let r = (Number(color) >> 16) & 0xff;
    let g = (Number(color) >> 8) & 0xff;
    let b = Number(color) & 0xff;
    return `rgba(${r},${g},${b},1)`;
  } else if (/^#([A-Fa-f0-9]{8}|[A-Fa-f0-9]{4})$/.test(color)) {
    color = color.slice(1);
    color.length === 4
      ? (color = '0x' + color[0] + color[0] + color[1] + color[1] + color[2] + color[2] + color[3] + color[3])
      : (color = '0x' + color);
    let r = (Number(color) >> 24) & 0xff;
    let g = (Number(color) >> 16) & 0xff;
    let b = (Number(color) >> 8) & 0xff;
    let a = Math.round(((Number(color) & 0xff) / 255) * 100) / 100;
    return `rgba(${r},${g},${b},${a})`;
  }
  return '';
}

export function getRGBAOpacity(rgba: string) {
  rgba = rgba ? (typeof rgba === 'string' ? rgba.replace(/ /g, '') : rgba[0]) : rgba;
  const rgbArray: any[] | RegExpMatchArray = rgba
    ? rgba.match(/^rgba?[\s+]?\([\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?,[\s+]?(\d+\.?\d*)[\s+]?/i)
    : [];
  return rgbArray && rgbArray.length === 5 ? Number(rgbArray[4]) : 1;
}

export function rgbTohex(rgb: string) {
  rgb = rgb ? (typeof rgb === 'string' ? rgb.replace(/ /g, '') : rgb[0]) : rgb;
  const rgbArray: any[] | RegExpMatchArray = rgb
    ? rgb.match(/^rgba?[\s+]?\([\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?/i)
    : [];
  return rgbArray && rgbArray.length === 4
    ? '#' +
        ('0' + parseInt(rgbArray[1], 10).toString(16)).slice(-2) +
        ('0' + parseInt(rgbArray[2], 10).toString(16)).slice(-2) +
        ('0' + parseInt(rgbArray[3], 10).toString(16)).slice(-2)
    : '';
}
