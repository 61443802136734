/* eslint-disable jsx-a11y/anchor-is-valid */

import React from 'react';
import CheckPermissionOnClick from '../../../SubscriptionManager/CheckPermissionOnClick';
import styles from './CustomCodeSection.module.scss';

class CustomCodesZeroState extends React.PureComponent {
  handleClickAddNewIntegration = () => {
    this.props.addNewIntegration();
  };

  render() {
    return (
      <article className={styles.ListZeroState}>
        <CheckPermissionOnClick
          action="SiteIntegration:create:::custom"
          onClick={this.handleClickAddNewIntegration}
          render={(onClick, hasPermission) => {
            const display = hasPermission
              ? {
                  h2: 'Add a custom integration',
                  small: 'Inject code into your page headers and body.',
                  button: 'Add Integration',
                }
              : {
                  h2: 'Upgrade to add custom integrations',
                  small: 'Upgrade your account to Growth or above to inject code into your page headers and body.',
                  button: 'Upgrade',
                };

            return (
              <header className={styles.title}>
                <h2>{display.h2}</h2>
                <small>{display.small}</small>
                <div>
                  <button onClick={onClick} className="button button-primary" style={{ width: '15em' }}>
                    {display.button}
                  </button>
                </div>
              </header>
            );
          }}
        />
      </article>
    );
  }
}

export default CustomCodesZeroState;
