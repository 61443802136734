import React from 'react';

import styles from './ChartControls.module.scss';

import iconMore from '../../assets/images/icon-more.svg';
import iconLess from '../../assets/images/icon-less.svg';

import classnames from 'classnames';

class ChartControls extends React.PureComponent {
  render() {
    const { data, selectedDataset, selectedDateDomain, selectedItemType } = this.props;

    return (
      <div className={styles.ChartControls}>
        <div className={styles.datasetButtons}>
          <div
            className={classnames(styles.buttonContainer, {
              active: selectedDataset === 'pageviews',
            })}
          >
            <button onClick={(e) => this.props.onSelectDataset('pageviews')} type="button">
              <div className={styles.datasetLabel}>Page views</div>
              <div>
                <span className={styles.datasetValue}>{data.pageviews.currentTotal.toLocaleString()}</span>
                {Number.isFinite(data.pageviews.percentChange) && (
                  <span
                    className={classnames(styles.percentChange, {
                      less: data.pageviews.percentChange < 0,
                    })}
                  >
                    {data.pageviews.percentChange > 0 && <img src={iconMore} alt="increase" />}
                    {data.pageviews.percentChange < 0 && <img src={iconLess} alt="descrease" />}{' '}
                    {Math.abs(data.pageviews.percentChange)}%
                  </span>
                )}
              </div>
            </button>
          </div>
          {data.uniques && (
            <div
              className={classnames(styles.buttonContainer, {
                active: selectedDataset === 'uniques',
              })}
            >
              <button onClick={(e) => this.props.onSelectDataset('uniques')} type="button">
                <div className={styles.datasetLabel}>Uniques</div>
                <div>
                  <span className={styles.datasetValue}>{data.uniques.currentTotal.toLocaleString()}</span>
                  {Number.isFinite(data.uniques.percentChange) && (
                    <span
                      className={classnames(styles.percentChange, {
                        less: data.uniques.percentChange < 0,
                      })}
                    >
                      {data.uniques.percentChange > 0 && <img src={iconMore} alt="increase" />}
                      {data.uniques.percentChange < 0 && <img src={iconLess} alt="descrease" />}{' '}
                      {Math.abs(data.uniques.percentChange)}%
                    </span>
                  )}
                </div>
              </button>
            </div>
          )}
          <div
            className={classnames(styles.buttonContainer, {
              active: selectedDataset === 'visits',
            })}
          >
            <button onClick={(e) => this.props.onSelectDataset('visits')} type="button">
              <div className={styles.datasetLabel}>Sessions</div>
              <div>
                <span className={styles.datasetValue}>{data.visits.currentTotal.toLocaleString()}</span>
                {Number.isFinite(data.visits.percentChange) && (
                  <span
                    className={classnames(styles.percentChange, {
                      less: data.visits.percentChange < 0,
                    })}
                  >
                    {data.visits.percentChange > 0 && <img src={iconMore} alt="increase" />}
                    {data.visits.percentChange < 0 && <img src={iconLess} alt="descrease" />}{' '}
                    {Math.abs(data.visits.percentChange)}%
                  </span>
                )}
              </div>
            </button>
          </div>
        </div>
        <div className={styles.dateDomainButtons}>
          <button
            className={classnames({ active: selectedItemType === 'all' })}
            onClick={(e) => this.props.onSelectItemType('all')}
          >
            All
          </button>
          <button
            className={classnames({ active: selectedItemType === 'site_page' })}
            onClick={(e) => this.props.onSelectItemType('site_page')}
          >
            Web
          </button>
          <button
            className={classnames({
              active: selectedItemType === 'landing_page',
            })}
            onClick={(e) => this.props.onSelectItemType('landing_page')}
          >
            Landing
          </button>
          <button
            className={classnames({ active: selectedItemType === 'blog' })}
            onClick={(e) => this.props.onSelectItemType('blog')}
          >
            Blog
          </button>
        </div>
        <div className={styles.dateDomainButtons}>
          <button
            className={classnames({ active: selectedDateDomain === 90 })}
            onClick={(e) => this.props.onSelectDateDomain(90)}
          >
            90d
          </button>
          <button
            className={classnames({ active: selectedDateDomain === 30 })}
            onClick={(e) => this.props.onSelectDateDomain(30)}
          >
            30d
          </button>
          <button
            className={classnames({ active: selectedDateDomain === 7 })}
            onClick={(e) => this.props.onSelectDateDomain(7)}
          >
            7d
          </button>
          {/* <button
            className={classnames({active: selectedDateDomain === 'MONTH'})}
            onClick={(e) => this.props.onSelectDateDomain('MONTH')}
          >
            Mo
          </button>
          <button
            className={classnames({active: selectedDateDomain === 'WEEK'})}
            onClick={(e) => this.props.onSelectDateDomain('WEEK')}
          >
            Wk
          </button> */}
        </div>
      </div>
    );
  }
}

export default ChartControls;
