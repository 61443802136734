import React, { useState, useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';
import styles from './Aside.module.scss';

import createLandingPage from '../../../assets/images/signup/create-landing-page.png';
import createLandingPage2x from '../../../assets/images/signup/create-landing-page@2x.png';
import runABTest from '../../../assets/images/signup/run-a-b-test.png';
import runABTest2x from '../../../assets/images/signup/run-a-b-test@2x.png';
import captureLeads from '../../../assets/images/signup/capture-leads.png';
import captureLeads2x from '../../../assets/images/signup/capture-leads@2x.png';

const items = [
  {
    text: 'Publish a great looking landing page',
    image: <img src={createLandingPage} style={{ width: '240px' }} srcSet={`${createLandingPage2x} 2x`} alt="" />,
  },
  {
    text: 'Run an A/B test',
    image: <img src={runABTest} style={{ width: '100%' }} srcSet={`${runABTest2x} 2x`} alt="" />,
  },
  {
    text: 'Convert visitors into leads',
    image: <img src={captureLeads} style={{ width: '100%' }} srcSet={`${captureLeads2x} 2x`} alt="" />,
  },
];

function CreateAccountAside(props) {
  const [pos, setPos] = useState(0);

  useEffect(() => {
    const nextPos = (pos + 1) % items.length;

    const timeout = setTimeout(() => {
      setPos(nextPos);
    }, 5000);

    return () => clearTimeout(timeout);
  }, [pos, setPos]);

  return (
    <aside className={styles.aside}>
      <div className={styles.firstLine}>In the next few minutes, you will…</div>
      <div className={styles.secondLine}>{items[pos].text}</div>
      <figure className={styles.figure}>
        {items.map((item, i) => (
          <CSSTransition key={i} in={i === pos} timeout={{ enter: 800, exit: 400 }} classNames={styles} appear>
            {item.image}
          </CSSTransition>
        ))}
      </figure>
    </aside>
  );
}

export default CreateAccountAside;
