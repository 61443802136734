import React from 'react';
import Table from './Table';
import './List.scss';

export default function List(props) {
  const { showHeader, ...restProps } = props;
  return (
    <Table
      {...(!showHeader && { TheadComponent: () => null })}
      getProps={() => ({ className: props.isSensitive ? 'ReactTableList ph-no-capture' : 'ReactTableList' })}
      sortable={false}
      {...restProps}
    />
  );
}
