import * as actionTypes from '../actions/actionTypes';
import { TemplateGeneratorActions } from 'actions/templateGeneratorActions';
import { UCart } from 'types/UCart';

// Reducer
// ----------------------------------------------------------------------------

type TemplateGeneratorState = {
  cart: UCart;
};

export const templateGeneratorInitState: TemplateGeneratorState = {
  cart: {
    i18n: {
      price: '$42.00',
      sub_total: '$42.00',
    },
    products: [
      {
        id: 'product1',
        name: 'Logoless T-shirt',
        quantity: '4',
        i18n: {
          price: '$20.00',
        },
        meta: {
          display_price: {
            with_tax: {
              unit: {
                formatted: '$20.00',
              },
            },
          },
        },
        options: [
          { label: 'Color', name: 'Black', description: 'Black' },
          { label: 'Size', name: 'Large', description: 'Large' },
        ],
        media: [{ src: 'https://moodle.com/wp-content/uploads/2021/06/22087-11.jpg' }],
      },
      {
        id: 'product1',
        name: 'Logoless T-shirt',
        quantity: '4',
        i18n: {
          price: '$20.00',
        },
        meta: {
          display_price: {
            with_tax: {
              unit: {
                formatted: '$20.00',
              },
            },
          },
        },
        options: [
          { label: 'Color', name: 'Black', description: 'Black' },
          { label: 'Size', name: 'Large', description: 'Large' },
        ],
        media: [{ src: 'https://moodle.com/wp-content/uploads/2021/06/22087-11.jpg' }],
      },
      {
        id: 'product1',
        name: 'Logoless T-shirt',
        quantity: '4',
        i18n: {
          price: '$20.00',
        },
        meta: {
          display_price: {
            with_tax: {
              unit: {
                formatted: '$20.00',
              },
            },
          },
        },
        options: [
          { label: 'Color', name: 'Black', description: 'Black' },
          { label: 'Size', name: 'Large', description: 'Large' },
        ],
        media: [{ src: 'https://moodle.com/wp-content/uploads/2021/06/22087-11.jpg' }],
      },
      {
        id: 'product1',
        name: 'Logoless T-shirt',
        quantity: '4',
        i18n: {
          price: '$20.00',
        },
        meta: {
          display_price: {
            with_tax: {
              unit: {
                formatted: '$20.00',
              },
            },
          },
        },
        options: [
          { label: 'Color', name: 'Black', description: 'Black' },
          { label: 'Size', name: 'Large', description: 'Large' },
        ],
        media: [{ src: 'https://moodle.com/wp-content/uploads/2021/06/22087-11.jpg' }],
      },
      {
        id: 'product1',
        name: 'Logoless T-shirt',
        quantity: '4',
        i18n: {
          price: '$20.00',
        },
        meta: {
          display_price: {
            with_tax: {
              unit: {
                formatted: '$20.00',
              },
            },
          },
        },
        options: [
          { label: 'Color', name: 'Black', description: 'Black' },
          { label: 'Size', name: 'Large', description: 'Large' },
        ],
        media: [{ src: 'https://moodle.com/wp-content/uploads/2021/06/22087-11.jpg' }],
      },
    ],
    // @ts-ignore
    // products: [],
    currency: '$',
    subtotals: [
      { label: 'Item Total', value: '$100.00' },
      { label: 'Shipping', value: '$22.00' },
      { label: 'Total', value: '$122.00' },
    ],
    meta: {
      display_price: {
        with_tax: {
          amount: 9000,
          currency: 'USD',
          formatted: '$90.00',
        },
        without_tax: {
          amount: 10000,
          currency: 'USD',
          formatted: '$100.00',
        },
        tax: {
          amount: 1000,
          currency: 'USD',
          formatted: '$10.00',
        },
        discount: {
          amount: 0,
          currency: 'USD',
          formatted: '$0.00',
        },
        without_discount: {
          amount: 10000,
          currency: 'USD',
          formatted: '$100.00',
        },
      },
      timestamps: {
        created_at: '2023-04-19T12:07:33Z',
        updated_at: '2023-04-19T12:08:04Z',
        expires_at: '2023-04-26T12:08:04Z',
      },
    },
    // error: 'ERROR TEXT',
    hidden: false,
  },
};

export default function (state = templateGeneratorInitState, action: TemplateGeneratorActions) {
  switch (action.type) {
    case actionTypes.TEMPLATE_GENERATOR_CHANGE_CART_DATA:
      return {
        ...state,
        cart: action.cart,
      };

    default:
      return state;
  }
}

// Raw selectors
// ----------------------------------------------------------------------------

export function getCartData(globalState: { templateGenerator?: TemplateGeneratorState }) {
  return globalState.templateGenerator.cart;
}
