// import { initializeApp } from 'actions/appActions';
import '../assets/styles/admin/main.scss';
import '../assets/styles/editor/main.scss';

import React from 'react';
import { useDispatch } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import { OnMount } from 'hooks/mountUnmountHooks';
import { initializeApp } from 'actions/appActions';
import Invitation from './Signup/Invitation';
import EmailVerification from './Signup/EmailVerification';
import ResetPassword from './Login/ResetPassword';
import ForgotPassword from './Login/ForgotPassword';
import Logout from './Login/Logout';
import SuperUserLogin from './Login/SuperUserLogin';
import JWTLogin from './Login/JWTLogin';
import IntegrationReroute from './AdminPanel/Integrations/IntegrationReroute';
import ShopifyLogin from './ShopifyLogin';
import ShopifyAppAdd from './AdminPanel/Integrations/ShopifyAppAdd';
import Tooltip from './Tooltip';
import RouterToReduxDispatcher from './RouterToReduxDispatcher';
import BuildChangeMonitor from './BuildChangeMonitor';
import SitesLoadedRoutes from './SitesLoadedRoutes';

export const App: React.FC = () => {
  const dispatch = useDispatch();

  OnMount(() => {
    dispatch(initializeApp());
  });

  return (
    <>
      <Routes>
        <Route path="/invitation/:token" element={<Invitation />} />
        <Route path="/verification/:token" element={<EmailVerification />} />
        <Route path="/password/:token" element={<ResetPassword />} />
        <Route path="/password" element={<ForgotPassword />} />
        <Route path="/logout" element={<Logout />} />
        <Route path="/login/token/:token" element={<SuperUserLogin />} />
        <Route path="/auth" element={<JWTLogin />} />
        <Route path="/integrations/oauth" element={<IntegrationReroute />} />
        <Route path="/shopify-login/*" element={<ShopifyLogin />} />
        <Route path="/shopify/*" element={<ShopifyAppAdd />} />
        <Route path="/shopify_klevu/*" element={<ShopifyAppAdd />} />

        {/* These routes need the sites and account loaded */}
        <Route path="/*" element={<SitesLoadedRoutes />} />
      </Routes>
      <Tooltip />
      <RouterToReduxDispatcher />
      {process.env.NODE_ENV === 'production' && <BuildChangeMonitor />}
    </>
  );
};
