import React, { useState, useEffect } from 'react';

import ReactTable from 'react-table';
import withFixedColumns from 'react-table-hoc-fixed-columns';

import VisibilitySensor from 'react-visibility-sensor';
import './react-table.scss';
import './react-table-hoc-fixed-columns.scss';

import idx from 'lodash/get';

import Paginate from '../react-paginate';

import styles from './Table.module.scss';
import usePrevious from 'hooks/usePrevious';

const ReactTableWithFixedColumns = withFixedColumns(ReactTable);

function getTheadThProps(state, row, column, tableInstance) {
  const sortable = column.sortable != null ? column.sortable : state.sortable;

  if (sortable) return { className: '-sortable' };
  else return {};
}

function getTdProps(state, row, column, tableInstance) {
  const sortedId = idx(state, ['sorted', 0, 'id']);
  const columnId = idx(column, ['id']);
  const sorted = sortedId !== undefined && sortedId === columnId;
  const className = [];

  if (sorted || column.highlight) className.push('-highlight');
  if (column.wrap) className.push('-wrap');

  return { className };
}

export default function Table(props) {
  const { defaultPageSize, showViewAll, paginate, activePage, infiniteScroll, updateCursor, loading, ...restProps } =
    props;

  // Use showAll and setShowAll from props if provided. Otherwise use stateful.
  const [statefulShowAll, setStatefulShowAll] = useState(false);
  const [visibleSensorShown, setVisibleSensorShown] = useState(false);
  const showAll = props.showAll !== undefined ? props.showAll : statefulShowAll;
  const setShowAll = props.setShowAll !== undefined ? props.setShowAll : setStatefulShowAll;

  const count = props.count || idx(props, ['data', 'length']);
  const pageSize = showAll ? count : defaultPageSize || 5;

  const wasLoading = usePrevious(loading);

  useEffect(() => {
    if (!loading && wasLoading && visibleSensorShown) {
      updateCursor();
    }
  }, [loading, wasLoading]);

  return (
    <>
      <div className={styles.container} data-test-id="table-list">
        <ReactTableWithFixedColumns
          minRows={0}
          resizable={false}
          multiSort={false}
          showPagination={false}
          pageSize={pageSize}
          getTheadThProps={getTheadThProps}
          getTdProps={getTdProps}
          {...restProps}
        />
        {showViewAll && !showAll && pageSize < count && (
          <div className={styles.viewAll} onClick={() => setShowAll(true)}>
            View all ({count})
          </div>
        )}
      </div>
      {paginate && (
        <Paginate
          pageCount={props.pagesCount}
          onPageChange={props.onPageChange}
          nextLabel={props.nextPageLabel}
          previousLabel={props.previousPageLabel}
          breaklabel={props.breakPagesLabel}
          activePage={activePage}
        />
      )}
      {infiniteScroll && (
        <VisibilitySensor
          onChange={(visible) => {
            if (visible) {
              setVisibleSensorShown(true);
              updateCursor();
            } else {
              setVisibleSensorShown(false);
            }
          }}
        >
          <span>&nbsp;</span>
        </VisibilitySensor>
      )}
    </>
  );
}
