// TODO: Move state to reducer. Explore redux toolkit.

import ReactDOM from 'react-dom';
import React, { useState, useEffect } from 'react';
import classNames from 'classnames';

import styles from './Toast.module.scss';

interface ToastProps {
  messages: string[];
  type: 'error' | 'success';
  timeout?: number;
}

export interface ToastInfo {
  messages: string[];
  type: 'success' | 'error';
}

const Toast = (props: ToastProps) => {
  const { messages = [], type, timeout = 2000 } = props;
  const [showErrors, setShowErrors] = useState(false);

  useEffect(() => {
    if (!showErrors && messages.length) {
      setShowErrors(true);
      setTimeout(() => setShowErrors(false), timeout);
    }
  }, [messages]);

  const toastClasses = classNames(styles.toast, {
    [styles.show]: showErrors,
    [styles.error]: type === 'error',
    [styles.success]: type === 'success',
  });

  if (!messages) return null;

  return ReactDOM.createPortal(
    <div className={toastClasses}>
      <div>{messages[0] && <div>{messages[0]}</div>}</div>
    </div>,
    document.body
  );
};

export default Toast;
