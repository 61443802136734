import React from 'react';
import Table from '../../../react-table/Table';
import iconHelp from '../../../../assets/images/icon-help.svg';
// import iconUsers from '../../../assets/images/icon-users.svg';
import styles from './ConversionPathReport.module.scss';

export function ConversionPathReport(props) {
  const { data, isLoading } = props;

  const maxNumPages = data.reduce((memo, row) => {
    return row.items.length > memo ? row.items.length : memo;
  }, 4);

  const pageColumns = [];

  for (let i = 0; i < maxNumPages; i++) {
    pageColumns.push({
      Header: `Step ${i + 1}`,
      accessor: `items.${i}`,
      Cell: ({ value }) => {
        if (!value) return '--';

        return (
          <Cell
            label={value.name}
            detail={value.path}
            showUserIcon={true}
            value={value.views}
            rate={value.success_rate}
          />
        );
      },
      getProps: (state, row, col) => {
        const value = col.accessor(row.original);
        const props = {
          className: [],
        };

        if (!value) {
          props.className.push('-muted');
          props.className.push('-highlight');
        }

        return props;
      },
      minWidth: 125,
    });
  }

  const columns = [
    {
      Header: () => (
        <>
          Source
          <Help text="Where visitors are coming from" />
        </>
      ),
      accessor: 'utms',
      Cell: ({ value }) => (
        <div>
          <div className={styles.label}>{value.source}</div>
          <div className={styles.value}>
            <span data-tip={value.term}>{value.term}</span>
          </div>
        </div>
      ),
      highlight: false,
      minWidth: 360,
      fixed: 'left',
    },
    {
      Header: () => (
        <>
          Visits
          <Help text="Number of visits representing this conversion path" />
        </>
      ),
      accessor: 'visits',
      Cell: ({ value }) => (value.toLocaleString ? value.toLocaleString() : value),
      highlight: false,
      minWidth: 80,
      sortable: true,
      fixed: 'right',
    },
    ...pageColumns,
    {
      Header: () => (
        <>
          Conversion
          <Help text="Number of conversions" />
        </>
      ),
      accessor: 'goal',
      Cell: ({ value }) => (
        <Cell
          label={
            <span>
              {value.action.category}
              &nbsp;&nbsp;&ndash;&nbsp;&nbsp;
              {value.action.action}
            </span>
          }
          showUserIcon={true}
          value={value.conversions}
          rate={value.conversion_rate}
        />
      ),
      highlight: false,
      minWidth: 100,
      sortable: true,
      sortMethod: (a, b) => a.conversion_rate - b.conversion_rate,
      fixed: 'right',
    },
  ];

  return (
    <Table
      data={data}
      columns={columns}
      sortable={false}
      defaultSorted={[{ id: 'goal', desc: true }]}
      loading={isLoading}
      showViewAll={true}
    />
  );
}

// Components
// -------------------------------------------------

function Help({ text }) {
  return <img src={iconHelp} className={styles.help} data-tip={text} alt={text} />;
}

function Cell(props) {
  const {
    label,
    detail,
    // showUserIcon,
    value,
    rate,
  } = props;

  var has_value = !isNaN(value);
  var rate_pct = isNaN(rate) ? '0' : Math.round(rate * 1000) / 10;

  return (
    <div>
      <div className={styles.label}>{label}</div>
      <div>
        {has_value && (
          <span className={styles.rate}>
            {value} ({rate_pct}%)
          </span>
        )}
        {detail && <span className={styles.rate}>{detail}</span>}
      </div>
    </div>
  );
}
