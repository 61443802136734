// Default site styles
// ----------------------------------------------------------------------------

import merge from 'lodash/merge';

const defaultStyles = {
  fontFamilies: ['Roboto', 'Roboto Slab'],
  typography: {
    header_1: {
      font_family_index: 0,
      font_weight: '700',
      font_size: '4',
      color: { code: '#4A4A4A' },
      line_height: '1.3',
      margin: '0rem 0rem 2rem',
      dark_bg_color: { code: '#FFFFFF' },
      letter_spacing: 'normal',
      mobile: {
        font_size: '3',
      },
    },
    header_2: {
      font_family_index: 0,
      font_weight: '700',
      font_size: '3',
      color: { code: '#4A4A4A' },
      line_height: '1.3',
      margin: '0rem 0rem 2rem',
      dark_bg_color: { code: '#FFFFFF' },
      letter_spacing: 'normal',
      mobile: {
        font_size: '2.5',
      },
    },
    header_3: {
      font_family_index: 0,
      font_weight: '500',
      font_size: '2',
      color: { code: '#4A4A4A' },
      line_height: '1.3',
      margin: '0rem 0rem 2rem',
      dark_bg_color: { code: '#FFFFFF' },
      mobile: {
        font_size: '2.0',
      },
    },
    header_4: {
      font_family_index: 0,
      font_weight: '400',
      font_size: '1.5',
      color: { code: '#4A4A4A' },
      line_height: '1.3',
      margin: '0rem 0rem 2rem',
      dark_bg_color: { code: '#FFFFFF' },
      mobile: {
        font_size: '1.6',
      },
    },
    header_5: {
      font_family_index: 0,
      font_weight: '300',
      font_size: '1.25',
      color: { code: '#4A4A4A' },
      line_height: '1.3',
      margin: '0rem 0rem 2rem',
      dark_bg_color: { code: '#FFFFFF' },
      mobile: {
        font_size: '1.5',
      },
    },
    header_6: {
      font_family_index: 0,
      font_weight: '300',
      font_size: '1',
      color: { code: '#4A4A4A' },
      line_height: '1.3',
      margin: '0rem 0rem 2rem',
      dark_bg_color: { code: '#FFFFFF' },
      mobile: {
        font_size: '1.5',
      },
    },
    paragraph: {
      font_family_index: 0,
      font_weight: '400',
      font_size: '1.7',
      color: 'rgba(0, 0, 0, 0.7)',
      line_height: '1.6',
      margin: '0rem 0rem 2.5rem',
      dark_bg_color: '#FFFFFF',
      mobile: {
        font_size: '1',
      },
    },
    blog_header_1: {
      font_family_index: 1,
      font_weight: '700',
      font_size: '4',
      color: { code: '#4A4A4A' },
      line_height: '1.3',
      margin: '4rem 0rem 0rem',
      dark_bg_color: { code: '#FFFFFF' },
      mobile: {
        font_size: '3',
      },
    },
    blog_header_2: {
      font_family_index: 1,
      font_weight: '700',
      font_size: '3',
      color: { code: '#4A4A4A' },
      line_height: '1.3',
      margin: '4rem 0rem 0rem',
      dark_bg_color: { code: '#FFFFFF' },
      mobile: {
        font_size: '2.5',
      },
    },
    blog_header_3: {
      font_family_index: 1,
      font_weight: '700',
      font_size: '2',
      color: { code: '#4A4A4A' },
      line_height: '1.3',
      margin: '4rem 0rem 0rem',
      dark_bg_color: { code: '#FFFFFF' },
      mobile: {
        font_size: '2.0',
      },
    },
    blog_header_4: {
      font_family_index: 1,
      font_weight: '700',
      font_size: '2',
      color: { code: '#4A4A4A' },
      line_height: '1.3',
      margin: '4rem 0rem 0rem',
      dark_bg_color: { code: '#FFFFFF' },
      mobile: {
        font_size: '1.6',
      },
    },
    blog_header_5: {
      font_family_index: 1,
      font_weight: '700',
      font_size: '2',
      color: { code: '#4A4A4A' },
      line_height: '1.3',
      margin: '4rem 0rem 0rem',
      dark_bg_color: { code: '#FFFFFF' },
      mobile: {
        font_size: '1.5',
      },
    },
    blog_header_6: {
      font_family_index: 1,
      font_weight: '700',
      font_size: '2',
      color: { code: '#4A4A4A' },
      line_height: '1.3',
      margin: '4rem 0rem 0rem',
      dark_bg_color: { code: '#FFFFFF' },
      mobile: {
        font_size: '1.5',
      },
    },
    blog_paragraph: {
      font_family_index: 1,
      font_weight: '400',
      font_size: '1.7',
      color: 'rgba(0, 0, 0, 0.7)',
      line_height: '1.3',
      margin: '2.5rem 0rem 0rem',
      dark_bg_color: { code: '#FFFFFF' },
      mobile: {
        font_size: '1',
      },
    },
    highlight_color: 'rgba(255,169,0,1)',
    highlight_color_dark_bg: '#FFFFFF',
    big_quote: {
      font_family_index: 0,
      font_weight: '400',
      font_style: 'italic',
      font_size: '2',
      color: 'rgba(0, 0, 0, 0.7)',
      line_height: '1.3',
      mark_color: '#4a90e2',
      mark_size: '4',
      dark_bg_color: '#FFFFFF',
      dark_bg_mark_color: '#FFFFFF',
      mobile: {
        font_size: '2',
      },
    },
  },
  list: {
    check_list: {
      bottom_spacing: 0,
      font_family_index: 0,
      font_weight: '400',
      font_size: '1.6',
      font_style: 'normal',
      line_height: '1.3',
      color: 'rgba(0, 0, 0, 0.7)',
      icon_color: 'rgba(0, 0, 0, 0.7)',
      icon_size: '0.8',
      dark_bg_color: 'rgba(0, 0, 0, 0.7)',
      dark_bg_icon_color: 'rgba(255, 255, 255, 0.7)',
      mobile: {
        font_size: '1.6',
      },
    },
    header_menu: {
      font_family_index: 0,
      font_weight: '400',
      font_size: '1.5',
      color: '#000000',
      current_color: { code: '#4a90e2' },
      current_opacity: 1,
      action_color: '#4a90e2',
      action_font_family_index: 0,
      action_font_weight: '400',
      action_font_size: '1.5',
      hover_color: '#4a90e2',
      hover_opacity: '0.8',
      dark_bg_color: '#FFFFFF',
      dark_bg_current_color: { code: '#ffffff' },
      dark_bg_current_opacity: 1,
      dark_bg_action_color: '#4a90e2',
      dark_bg_hover_color: '#4a90e2',
      dark_bg_hover_opacity: '0.8',
    },
    footer_menu: {
      font_family_index: 0,
      font_weight: '400',
      font_size: '1.5',
      color: '#000000',
      category_font_family_index: 0,
      category_font_weight: '400',
      category_font_size: '1.5',
      category_color: '#000000',
      link_hover_color: '#000000',
      link_hover_opacity: '1',
      dark_bg_color: '#000000',
      dark_bg_category_color: '#000000',
      dark_bg_link_hover_color: '#000000',
      dark_bg_link_hover_opacity: '1',
    },
  },
  button: {
    primary: {
      font_family_index: 1,
      font_weight: '400',
      font_size: '1.5',
      text_color: { code: '#FFFFFF' },
      text_shadow: '0 1 3 #CCCCCC',
      box_background_color: '#2569E6',
      box_border_color: '#2569E6',
      box_border_width: '2',
      box_border_radius: '2',
      box_line_height: '1.8',
      box_shadow: '0 1 2 #CCCCCC',
      hover_background_color: '#2569E6',
      hover_border_color: '#2569E6',
      hover_shadow: '0 1 2 #CCCCCC',
      hover_opacity: '0.75',
      hover_text_color: { code: '#FFFFFF' },
      hover_text_shadow: [0, 0, 0, { code: '#CCCCCC' }],
      dark_bg_hover_text_color: { code: '#000000' },
      dark_bg_text_color: '#FFFFFF',
      dark_bg_text_shadow: '0 1 3 #CCCCCC',
      dark_bg_box_background_color: '#2569E6',
      dark_bg_box_shadow: '0 1 2 #CCCCCC',
      dark_bg_box_border_color: '#2569E6',
      dark_bg_box_border_width: '2',
      dark_bg_hover_background_color: '#2569E6',
      dark_bg_hover_border_color: '#2569E6',
      dark_bg_hover_shadow: '0 1 2 #CCCCCC',
      dark_bg_hover_opacity: '0.75',
      dark_bg_hover_text_shadow: [0, 0, 0, { code: '#CCCCCC' }],
      mobile: {
        font_size: '1.5',
      },
    },
    secondary: {
      font_family_index: 1,
      font_weight: '400',
      font_size: '1.5',
      text_color: '#000000',
      text_shadow: '0 1 3 #CCCCCC',
      box_background_color: '#2569E6',
      box_border_color: '#2569E6',
      box_border_width: '2',
      box_border_radius: '2',
      box_line_height: '1.8',
      box_shadow: '0 1 2 #CCCCCC',
      hover_background_color: '2569E6',
      hover_border_color: '#2569E6',
      hover_shadow: '0 1 2 #CCCCCC',
      hover_opacity: '0.75',
      hover_text_color: { code: '#000000' },
      dark_bg_text_color: '#FFFFFF',
      dark_bg_text_shadow: '0 1 3 #CCCCCC',
      dark_bg_box_background_color: '#2569E6',
      dark_bg_box_shadow: '0 1 2 #CCCCCC',
      dark_bg_box_border_color: '#2569E6',
      dark_bg_box_border_width: '2',
      dark_bg_hover_background_color: '#2569E6',
      dark_bg_hover_border_color: '#2569E6',
      dark_bg_hover_shadow: '0 1 2 #CCCCCC',
      dark_bg_hover_opacity: '0.75',
      dark_bg_hover_text_color: { code: '#000000' },
      hover_text_shadow: [0, 0, 0, { code: '#00000000' }],
      dark_bg_hover_text_shadow: [0, 0, 0, { code: '#00000000' }],
      mobile: {
        font_size: '1.5',
      },
    },
    action_link: {
      font_family_index: 1,
      font_weight: '400',
      font_size: '1.5',
      text_color: '#4a90e2',
      arrow_color: '#4a90e2',
      arrow_size: '0.8',
      hover_color: '#4a90e2',
      hover_opacity: '0.8',
      dark_bg_text_color: '#4a90e2',
      dark_bg_arrow_color: '#4a90e2',
      dark_bg_hover_color: '#4a90e2',
      dark_bg_hover_opacity: '0.8',
      mobile: {
        font_size: '1.5',
      },
    },
  },
  // form_field_font_family_index: 0,
  form_field_font_weight: '400',
  form_field_font_color: { code: '#444444' },
  form_field_font_size: '1.5',
  form_field_placeholder_color: { code: 'rgba(0, 0, 0, 0.5)' },
  form_field_padding_x: '1.0',
  form_field_padding_y: '.8',
  form_field_border_radius: '2',
  form_field_background_color: { code: 'rgba(0, 0, 0, 0.03)' },
  form_field_border_color: { code: '#dce4f4' },
  form_field_focus_border_color: { code: '#4A90E2' },

  // form_label_font_family_index: 0,
  form_label_font_weight: '400',
  form_label_font_size: '1.5',
  form_label_font_color: { code: '#1B314B' },

  // form_help_font_family_index: 0,
  form_help_font_weight: '400',
  form_help_font_size: '1.4',
  form_help_font_color: { code: '#647fb4' },

  form_width: 400,
  form_spacing_margin_x: 20,
  form_spacing_margin_y: 20,

  // MOBILE FORM FIELDS
  form_field_font_size_mobile: '1.5',
  form_field_padding_x_mobile: '1.0',
  form_field_padding_y_mobile: '0.8',

  form_label_font_size_mobile: '1.5',

  form_help_font_size_mobile: '1.4',

  form_spacing_margin_x_mobile: 20,
  form_spacing_margin_y_mobile: 20,

  box_shadow: 'none',
  box_shadows: {
    '': 'none',
    none: 'none',
    small: '0px 1px 3px rgba(0, 0, 0, 0.12)',
    medium: '0px 5px 20px -5px rgba(0, 0, 0, 0.15)',
    large: '0px 10px 25px -8px rgba(0, 0, 0, 0.25)',
    x_large: '0px 20px 40px -10px rgba(0, 0, 0, 0.25)',
  },

  media_queries: {
    small: '768',
    medium: '1024',
    large: '1440',
  },
};

// StyleGuide
// ----------------------------------------------------------------------------

class StyleGuide {
  constructor(site, fonts) {
    // Merge defaultStyles with site.styles
    const mergedStyles = merge({}, defaultStyles, site.styles);

    // Define the merged styles on the StyleGuide instance as read only
    Object.defineProperty(this, 'styles', {
      value: mergedStyles,
      writable: false,
    });

    // Define the font families
    Object.defineProperty(this, 'fonts', {
      value: fonts,
      writable: false,
    });
    const fontsByName = {};
    fonts.forEach((font) => (fontsByName[font.family] = font));
    Object.defineProperty(this, 'fontsByName', {
      value: fontsByName,
      writable: false,
    });
  }

  // Font families
  // --------------------------------------------------------------------------

  get fontFamilies() {
    return this.styles.fontFamilies;
  }

  getFontFamilyWithFallback(family, key) {
    if (family == null) {
      console.error('Font family was undefined:', key);
      return '';
    }

    if (Number.isInteger(family)) family = this.fontFamilies[family];

    const fontDefinition = this.fontsByName[family];
    return fontDefinition ? `'${fontDefinition.family}', ${fontDefinition.default}` : `'${family}', 'sans-serif'`;
  }

  // TODO: Collect all font weights for each font family

  get selectFontImportUrl() {
    const allFonts = this.fonts;
    const selectedFonts = this.fontFamilies;
    const selectedGoogleFonts = allFonts.filter(
      (font) => font.font_source === 'google' && selectedFonts.includes(font.family)
    );
    const selectedGoogleFontsFamiles = selectedGoogleFonts
      .map((font) => `${font.family}:300,400,500,700`.replace(/\s/g, '+'))
      .join('|');
    const selectedGoogleFontsImportUrl = `https://fonts.googleapis.com/css?family=${selectedGoogleFontsFamiles}`;
    const selectedCustomFonts = allFonts.filter(
      (font) => font.font_source === 'custom' && selectedFonts.includes(font.family)
    );
    const selectedCustomFontsFamiles = selectedCustomFonts.map((font) => font.font_face_css).join(' ');
    return `@import url('${selectedGoogleFontsImportUrl}'); ${selectedCustomFontsFamiles}`;
  }

  get fontImportUrl() {
    const families = this.fonts
      .filter((font) => font.font_source === 'google')
      .map((font) => `${font.family}:300,400,500,700`.replace(/\s/g, '+'))
      .join('|');
    return `https://fonts.googleapis.com/css?family=${families}`;
  }

  get customFontImportUrl() {
    const families = this.fonts
      .filter((font) => font.font_source === 'custom')
      .map((font) => font.font_face_css)
      .join(' ');
    return families;
  }

  // Header 1
  // --------------------------------------------------------------------------

  get typography() {
    return this.styles.typography;
  }

  get header_count() {
    return this.styles.typography.header_count;
  }

  get header_1_font_family_index() {
    return this.styles.typography.header_1.font_family_index;
  }
  get header_1_font_family() {
    const index = this.header_1_font_family_index;
    return this.fontFamilies[index];
  }
  get header_1_font_css_family() {
    return this.getFontFamilyWithFallback(this.header_1_font_family, 'this.header_1_font_family');
  }
  get header_1_font_weight() {
    return this.styles.typography.header_1.font_weight;
  }
  get header_1_font_size() {
    return this.styles.typography.header_1.font_size;
  }
  get header_1_line_height() {
    return this.styles.typography.header_1.line_height;
  }
  get header_1_color() {
    return this.styles.typography.header_1.color;
  }
  get header_1_dark_bg_color() {
    return this.styles.typography.header_1.dark_bg_color;
  }

  get header_2_font_family_index() {
    return this.styles.typography.header_2.font_family_index;
  }
  get header_2_font_family() {
    const index = this.header_2_font_family_index;
    return this.fontFamilies[index];
  }
  get header_2_font_css_family() {
    return this.getFontFamilyWithFallback(this.header_2_font_family, 'this.header_2_font_family');
  }
  get header_2_font_weight() {
    return this.styles.typography.header_2.font_weight;
  }
  get header_2_font_size() {
    return this.styles.typography.header_2.font_size;
  }
  get header_2_line_height() {
    return this.styles.typography.header_2.line_height;
  }
  get header_2_color() {
    return this.styles.typography.header_2.color;
  }
  get header_2_dark_bg_color() {
    return this.styles.typography.header_2.dark_bg_color;
  }

  get header_3_font_family_index() {
    return this.styles.typography.header_3.font_family_index;
  }
  get header_3_font_family() {
    const index = this.header_3_font_family_index;
    return this.fontFamilies[index];
  }
  get header_3_font_css_family() {
    return this.getFontFamilyWithFallback(this.header_3_font_family, 'this.header_3_font_family');
  }
  get header_3_font_weight() {
    return this.styles.typography.header_3.font_weight;
  }
  get header_3_font_size() {
    return this.styles.typography.header_3.font_size;
  }
  get header_3_line_height() {
    return this.styles.typography.header_3.line_height;
  }
  get header_3_color() {
    return this.styles.typography.header_3.color;
  }
  get header_3_dark_bg_color() {
    return this.styles.typography.header_3.dark_bg_color;
  }

  get header_4_font_family_index() {
    return this.styles.typography.header_4.font_family_index;
  }
  get header_4_font_family() {
    const index = this.header_4_font_family_index;
    return this.fontFamilies[index];
  }
  get header_4_font_css_family() {
    return this.getFontFamilyWithFallback(this.header_4_font_family, 'this.header_4_font_family');
  }
  get header_4_font_weight() {
    return this.styles.typography.header_4.font_weight;
  }
  get header_4_font_size() {
    return this.styles.typography.header_4.font_size;
  }
  get header_4_line_height() {
    return this.styles.typography.header_4.line_height;
  }
  get header_4_color() {
    return this.styles.typography.header_4.color;
  }
  get header_4_dark_bg_color() {
    return this.styles.typography.header_4.dark_bg_color;
  }

  get header_5_font_family_index() {
    return this.styles.typography.header_5.font_family_index;
  }
  get header_5_font_family() {
    const index = this.header_5_font_family_index;
    return this.fontFamilies[index];
  }
  get header_5_font_css_family() {
    return this.getFontFamilyWithFallback(this.header_5_font_family, 'this.header_5_font_family');
  }
  get header_5_font_weight() {
    return this.styles.typography.header_5.font_weight;
  }
  get header_5_font_size() {
    return this.styles.typography.header_5.font_size;
  }
  get header_5_line_height() {
    return this.styles.typography.header_5.line_height;
  }
  get header_5_color() {
    return this.styles.typography.header_5.color;
  }
  get header_5_dark_bg_color() {
    return this.styles.typography.header_5.dark_bg_color;
  }

  get header_6_font_family_index() {
    return this.styles.typography.header_6.font_family_index;
  }
  get header_6_font_family() {
    const index = this.header_6_font_family_index;
    return this.fontFamilies[index];
  }
  get header_6_font_css_family() {
    return this.getFontFamilyWithFallback(this.header_6_font_family, 'this.header_6_font_family');
  }
  get header_6_font_weight() {
    return this.styles.typography.header_6.font_weight;
  }
  get header_6_font_size() {
    return this.styles.typography.header_6.font_size;
  }
  get header_6_line_height() {
    return this.styles.typography.header_6.line_height;
  }
  get header_6_color() {
    return this.styles.typography.header_6.color;
  }
  get header_6_dark_bg_color() {
    return this.styles.typography.header_6.dark_bg_color;
  }

  get big_quote_font_family_index() {
    return this.styles.typography.big_quote.font_family_index;
  }
  get big_quote_font_family() {
    const index = this.big_quote_font_family_index;
    return this.fontFamilies[index];
  }
  get big_quote_font_css_family() {
    return this.getFontFamilyWithFallback(this.big_quote_font_family, 'this.big_quote_font_family');
  }
  get big_quote_font_weight() {
    return this.styles.typography.big_quote.font_weight;
  }
  get big_quote_font_style() {
    return this.styles.typography.big_quote.font_style;
  }
  get big_quote_font_size() {
    return this.styles.typography.big_quote.font_size;
  }
  get big_quote_color() {
    return this.styles.typography.big_quote.color;
  }
  get big_quote_line_height() {
    return this.styles.typography.big_quote.line_height;
  }
  get big_quote_mark_color() {
    return this.styles.typography.big_quote.mark_color;
  }
  get big_quote_mark_size() {
    return this.styles.typography.big_quote.mark_size;
  }
  get big_quote_dark_bg_color() {
    return this.styles.typography.big_quote.dark_bg_color;
  }
  get big_quote_dark_bg_mark_color() {
    return this.styles.typography.big_quote.dark_bg_mark_color;
  }

  get check_list_font_family_index() {
    return this.styles.list.check_list.font_family_index;
  }
  get check_list_font_family() {
    const index = this.check_list_font_family_index;
    return this.fontFamilies[index];
  }
  get check_list_font_css_family() {
    return this.getFontFamilyWithFallback(this.check_list_font_family, 'this.check_list_font_family');
  }
  get check_list_font_weight() {
    return this.styles.list.check_list.font_weight;
  }
  get check_list_font_style() {
    return this.styles.list.check_list.font_style;
  }
  get check_list_font_size() {
    return this.styles.list.check_list.font_size;
  }
  get check_list_color() {
    return this.styles.list.check_list.color;
  }
  get check_list_bottom_spacing() {
    return this.styles.list.check_list.bottom_spacing;
  }
  get check_list_dark_bg_color() {
    return this.styles.list.check_list.dark_bg_color;
  }
  get check_list_icon_color() {
    return this.styles.list.check_list.icon_color;
  }
  get check_list_line_height() {
    return this.styles.list.check_list.line_height;
  }
  get check_list_icon_size() {
    return this.styles.list.check_list.icon_size;
  }
  get check_list_dark_bg_icon_color() {
    return this.styles.list.check_list.dark_bg_icon_color;
  }

  get paragraph_font_family_index() {
    return this.styles.typography.paragraph.font_family_index;
  }
  get paragraph_font_family() {
    const index = this.paragraph_font_family_index;
    return this.fontFamilies[index];
  }
  get paragraph_font_css_family() {
    return this.getFontFamilyWithFallback(this.paragraph_font_family, 'this.paragraph_font_family');
  }
  get paragraph_font_weight() {
    return this.styles.typography.paragraph.font_weight;
  }
  get paragraph_font_size() {
    return this.styles.typography.paragraph.font_size;
  }
  get paragraph_line_height() {
    return this.styles.typography.paragraph.line_height;
  }
  get paragraph_color() {
    return this.styles.typography.paragraph.color;
  }
  get paragraph_dark_bg_color() {
    return this.styles.typography.paragraph.dark_bg_color;
  }

  get highlight_color() {
    return this.styles.typography.highlight_color;
  }

  get highlight_color_dark_bg() {
    return this.styles.typography.highlight_color_dark_bg;
  }

  get blog_header_count() {
    return this.styles.typography.blog_header_count;
  }

  get blog_header_1_font_family_index() {
    return this.styles.typography.blog_header_1.font_family_index;
  }
  get blog_header_1_font_family() {
    const index = this.blog_header_1_font_family_index;
    return this.fontFamilies[index];
  }
  get blog_header_1_font_css_family() {
    return this.getFontFamilyWithFallback(this.blog_header_1_font_family, 'this.blog_header_1_font_family');
  }
  get blog_header_1_font_weight() {
    return this.styles.typography.blog_header_1.font_weight;
  }
  get blog_header_1_font_size() {
    return this.styles.typography.blog_header_1.font_size;
  }
  get blog_header_1_line_height() {
    return this.styles.typography.blog_header_1.line_height;
  }
  get blog_header_1_color() {
    return this.styles.typography.blog_header_1.color;
  }
  get blog_header_1_dark_bg_color() {
    return this.styles.typography.blog_header_1.dark_bg_color;
  }

  get blog_header_2_font_family_index() {
    return this.styles.typography.blog_header_2.font_family_index;
  }
  get blog_header_2_font_family() {
    const index = this.blog_header_2_font_family_index;
    return this.fontFamilies[index];
  }
  get blog_header_2_font_css_family() {
    return this.getFontFamilyWithFallback(this.blog_header_2_font_family, 'this.blog_header_2_font_family');
  }
  get blog_header_2_font_weight() {
    return this.styles.typography.blog_header_2.font_weight;
  }
  get blog_header_2_font_size() {
    return this.styles.typography.blog_header_2.font_size;
  }
  get blog_header_2_line_height() {
    return this.styles.typography.blog_header_2.line_height;
  }
  get blog_header_2_color() {
    return this.styles.typography.blog_header_2.color;
  }
  get blog_header_2_dark_bg_color() {
    return this.styles.typography.blog_header_2.dark_bg_color;
  }

  get blog_header_3_font_family_index() {
    return this.styles.typography.blog_header_3.font_family_index;
  }
  get blog_header_3_font_family() {
    const index = this.blog_header_3_font_family_index;
    return this.fontFamilies[index];
  }
  get blog_header_3_font_css_family() {
    return this.getFontFamilyWithFallback(this.blog_header_3_font_family, 'this.blog_header_3_font_family');
  }
  get blog_header_3_font_weight() {
    return this.styles.typography.blog_header_3.font_weight;
  }
  get blog_header_3_font_size() {
    return this.styles.typography.blog_header_3.font_size;
  }
  get blog_header_3_line_height() {
    return this.styles.typography.blog_header_3.line_height;
  }
  get blog_header_3_color() {
    return this.styles.typography.blog_header_3.color;
  }
  get blog_header_3_dark_bg_color() {
    return this.styles.typography.blog_header_3.dark_bg_color;
  }

  get blog_header_4_font_family_index() {
    return this.styles.typography.blog_header_4.font_family_index;
  }
  get blog_header_4_font_family() {
    const index = this.blog_header_4_font_family_index;
    return this.fontFamilies[index];
  }
  get blog_header_4_font_css_family() {
    return this.getFontFamilyWithFallback(this.blog_header_4_font_family, 'this.blog_header_4_font_family');
  }
  get blog_header_4_font_weight() {
    return this.styles.typography.blog_header_4.font_weight;
  }
  get blog_header_4_font_size() {
    return this.styles.typography.blog_header_4.font_size;
  }
  get blog_header_4_line_height() {
    return this.styles.typography.blog_header_4.line_height;
  }
  get blog_header_4_color() {
    return this.styles.typography.blog_header_4.color;
  }
  get blog_header_4_dark_bg_color() {
    return this.styles.typography.blog_header_4.dark_bg_color;
  }

  get blog_header_5_font_family_index() {
    return this.styles.typography.blog_header_5.font_family_index;
  }
  get blog_header_5_font_family() {
    const index = this.blog_header_5_font_family_index;
    return this.fontFamilies[index];
  }
  get blog_header_5_font_css_family() {
    return this.getFontFamilyWithFallback(this.blog_header_5_font_family, 'this.blog_header_5_font_family');
  }
  get blog_header_5_font_weight() {
    return this.styles.typography.blog_header_5.font_weight;
  }
  get blog_header_5_font_size() {
    return this.styles.typography.blog_header_5.font_size;
  }
  get blog_header_5_line_height() {
    return this.styles.typography.blog_header_5.line_height;
  }
  get blog_header_5_color() {
    return this.styles.typography.blog_header_5.color;
  }
  get blog_header_5_dark_bg_color() {
    return this.styles.typography.blog_header_5.dark_bg_color;
  }

  get blog_header_6_font_family_index() {
    return this.styles.typography.blog_header_6.font_family_index;
  }
  get blog_header_6_font_family() {
    const index = this.blog_header_6_font_family_index;
    return this.fontFamilies[index];
  }
  get blog_header_6_font_css_family() {
    return this.getFontFamilyWithFallback(this.blog_header_6_font_family, 'this.blog_header_6_font_family');
  }
  get blog_header_6_font_weight() {
    return this.styles.typography.blog_header_6.font_weight;
  }
  get blog_header_6_font_size() {
    return this.styles.typography.blog_header_6.font_size;
  }
  get blog_header_6_line_height() {
    return this.styles.typography.blog_header_6.line_height;
  }
  get blog_header_6_color() {
    return this.styles.typography.blog_header_6.color;
  }
  get blog_header_6_dark_bg_color() {
    return this.styles.typography.blog_header_6.dark_bg_color;
  }

  get blog_paragraph_font_family_index() {
    return this.styles.typography.blog_paragraph.font_family_index;
  }
  get blog_paragraph_font_family() {
    const index = this.blog_paragraph_font_family_index;
    return this.fontFamilies[index];
  }
  get blog_paragraph_font_css_family() {
    return this.getFontFamilyWithFallback(this.blog_paragraph_font_family, 'this.blog_paragraph_font_family');
  }
  get blog_paragraph_font_weight() {
    return this.styles.typography.blog_paragraph.font_weight;
  }
  get blog_paragraph_font_size() {
    return this.styles.typography.blog_paragraph.font_size;
  }
  get blog_paragraph_line_height() {
    return this.styles.typography.blog_paragraph.line_height;
  }
  get blog_paragraph_color() {
    return this.styles.typography.blog_paragraph.color;
  }
  get blog_paragraph_dark_bg_color() {
    return this.styles.typography.blog_paragraph.dark_bg_color;
  }

  get header_menu_font_family_index() {
    return this.styles.list.header_menu.font_family_index;
  }
  get header_menu_font_family() {
    const index = this.header_menu_font_family_index;
    return this.fontFamilies[index];
  }
  get header_menu_font_css_family() {
    return this.getFontFamilyWithFallback(this.header_menu_font_family, 'this.header_menu_font_family');
  }
  get header_menu_font_weight() {
    return this.styles.list.header_menu.font_weight;
  }
  get header_menu_font_size() {
    return this.styles.list.header_menu.font_size;
  }
  get header_menu_color() {
    return this.styles.list.header_menu.color;
  }
  get header_menu_opacity() {
    return this.styles.list.header_menu.opacity;
  }
  get header_menu_dark_bg_opacity() {
    return this.styles.list.header_menu.dark_bg_opacity;
  }
  get header_menu_dark_bg_color() {
    return this.styles.list.header_menu.dark_bg_color;
  }
  get header_menu_action_color() {
    return this.styles.list.header_menu.action_color;
  }
  get header_menu_action_font_family_index() {
    return this.styles.list.header_menu.action_font_family_index;
  }
  get header_menu_action_font_family() {
    const index = this.header_menu_action_font_family_index;
    return this.fontFamilies[index];
  }
  get header_menu_action_font_css_family() {
    return this.getFontFamilyWithFallback(this.header_menu_action_font_family, 'this.header_menu_action_font_family');
  }
  get header_menu_action_font_weight() {
    return this.styles.list.header_menu.action_font_weight;
  }
  get header_menu_action_font_size() {
    return this.styles.list.header_menu.action_font_size;
  }
  get header_menu_action_style() {
    return this.styles.list.header_menu.action_style;
  }
  get header_menu_hover_color() {
    return this.styles.list.header_menu.hover_color;
  }
  get header_menu_hover_opacity() {
    return this.styles.list.header_menu.hover_opacity;
  }
  get header_menu_dark_bg_action_color() {
    return this.styles.list.header_menu.dark_bg_action_color;
  }
  get header_menu_dark_bg_hover_color() {
    return this.styles.list.header_menu.dark_bg_hover_color;
  }
  get header_menu_dark_bg_hover_opacity() {
    return this.styles.list.header_menu.dark_bg_hover_opacity;
  }
  get header_menu_current_color() {
    return this.styles.list.header_menu.current_color;
  }
  get header_menu_dark_bg_current_color() {
    return this.styles.list.header_menu.dark_bg_current_color;
  }
  get header_menu_current_opacity() {
    return this.styles.list.header_menu.current_opacity;
  }
  get header_menu_dark_bg_current_opacity() {
    return this.styles.list.header_menu.dark_bg_current_opacity;
  }

  get footer_menu_font_family_index() {
    return this.styles.list.footer_menu.font_family_index;
  }
  get footer_menu_font_family() {
    const index = this.footer_menu_font_family_index;
    return this.fontFamilies[index];
  }
  get footer_menu_font_css_family() {
    return this.getFontFamilyWithFallback(this.footer_menu_font_family, 'this.footer_menu_font_family');
  }
  get footer_menu_font_weight() {
    return this.styles.list.footer_menu.font_weight;
  }
  get footer_menu_font_size() {
    return this.styles.list.footer_menu.font_size;
  }
  get footer_menu_color() {
    return this.styles.list.footer_menu.color;
  }
  get footer_menu_opacity() {
    return this.styles.list.footer_menu.opacity;
  }
  get footer_menu_dark_bg_opacity() {
    return this.styles.list.footer_menu.dark_bg_opacity;
  }
  get footer_menu_dark_bg_color() {
    return this.styles.list.footer_menu.dark_bg_color;
  }
  get footer_category_font_family_index() {
    return this.styles.list.footer_menu.category_font_family_index;
  }
  get footer_category_font_family() {
    const index = this.footer_category_font_family_index;
    return this.fontFamilies[index];
  }
  get footer_category_font_css_family() {
    return this.getFontFamilyWithFallback(this.footer_category_font_family, 'this.footer_category_font_family');
  }
  get footer_category_font_weight() {
    return this.styles.list.footer_menu.category_font_weight;
  }
  get footer_category_font_size() {
    return this.styles.list.footer_menu.category_font_size;
  }
  get footer_category_color() {
    return this.styles.list.footer_menu.category_color;
  }
  get footer_dark_bg_category_color() {
    return this.styles.list.footer_menu.dark_bg_category_color;
  }
  get footer_link_hover_color() {
    return this.styles.list.footer_menu.link_hover_color;
  }
  get footer_dark_bg_link_hover_color() {
    return this.styles.list.footer_menu.dark_bg_link_hover_color;
  }
  get footer_link_hover_opacity() {
    return this.styles.list.footer_menu.link_hover_opacity;
  }
  get footer_dark_bg_link_hover_opacity() {
    return this.styles.list.footer_menu.dark_bg_link_hover_opacity;
  }

  get primary_button_font_family_index() {
    return this.styles.button.primary.font_family_index;
  }
  get primary_button_font_family() {
    const index = this.primary_button_font_family_index;
    return this.fontFamilies[index];
  }
  get primary_button_font_weight() {
    return this.styles.button.primary.font_weight;
  }
  get primary_button_font_size() {
    return this.styles.button.primary.font_size;
  }
  get primary_button_text_color() {
    return this.styles.button.primary.text_color;
  }
  get primary_button_text_shadow() {
    return this.styles.button.primary.text_shadow;
  }
  get primary_button_text_shadow_with_unit() {
    return this.primary_button_text_shadow.map((v, i) => (i === 3 ? v.code : `${v}px`)).join(' ');
  }
  get primary_button_box_background_color() {
    return this.styles.button.primary.box_background_color;
  }
  get primary_button_box_border_color() {
    return this.styles.button.primary.box_border_color;
  }
  get primary_button_dark_bg_box_border_color() {
    return this.styles.button.primary.dark_bg_box_border_color;
  }
  get primary_button_dark_bg_box_border_width() {
    return this.styles.button.primary.dark_bg_box_border_width;
  }
  get primary_button_box_border_radius() {
    return this.styles.button.primary.box_border_radius;
  }
  get primary_button_box_border_width() {
    return this.styles.button.primary.box_border_width;
  }
  get primary_button_box_line_height() {
    return this.styles.button.primary.box_line_height;
  }
  get primary_button_box_shadow() {
    return this.styles.button.primary.box_shadow;
  }
  get primary_button_box_shadow_with_unit() {
    return this.primary_button_box_shadow.map((v, i) => (i === 3 ? v.code : `${v}px`)).join(' ');
  }
  get primary_button_hover_background_color() {
    return this.styles.button.primary.hover_background_color;
  }
  get primary_button_dark_bg_hover_background_color() {
    return this.styles.button.primary.dark_bg_hover_background_color;
  }
  get primary_button_hover_border_color() {
    return this.styles.button.primary.hover_border_color;
  }
  get primary_button_hover_text_color() {
    return this.styles.button.primary.hover_text_color;
  }
  get primary_button_dark_bg_hover_border_color() {
    return this.styles.button.primary.dark_bg_hover_border_color;
  }
  get primary_button_dark_bg_hover_text_color() {
    return this.styles.button.primary.dark_bg_hover_text_color;
  }
  get primary_button_hover_shadow() {
    return this.styles.button.primary.hover_shadow;
  }
  get primary_button_hover_shadow_with_unit() {
    return this.primary_button_hover_shadow.map((v, i) => (i === 3 ? v.code : `${v}px`)).join(' ');
  }
  get primary_button_hover_text_shadow() {
    return this.styles.button.primary.hover_text_shadow;
  }
  get primary_button_hover_text_shadow_with_unit() {
    return this.primary_button_hover_text_shadow.map((v, i) => (i === 3 ? v.code : `${v}px`)).join(' ');
  }
  get primary_button_dark_bg_hover_shadow() {
    return this.styles.button.primary.dark_bg_hover_shadow;
  }
  get primary_button_dark_bg_hover_shadow_unit() {
    return this.primary_button_dark_bg_hover_shadow.map((v, i) => (i === 3 ? v.code : `${v}px`)).join(' ');
  }
  get primary_button_dark_bg_hover_text_shadow() {
    return this.styles.button.primary.dark_bg_hover_text_shadow;
  }
  get primary_button_dark_bg_hover_text_shadow_unit() {
    return this.primary_button_dark_bg_hover_text_shadow.map((v, i) => (i === 3 ? v.code : `${v}px`)).join(' ');
  }
  get primary_button_hover_opacity() {
    return this.styles.button.primary.hover_opacity;
  }
  get primary_button_dark_bg_hover_opacity() {
    return this.styles.button.primary.dark_bg_hover_opacity;
  }
  get primary_button_dark_bg_text_color() {
    return this.styles.button.primary.dark_bg_text_color;
  }
  get primary_button_dark_bg_text_shadow() {
    return this.styles.button.primary.dark_bg_text_shadow;
  }
  get primary_button_dark_bg_text_shadow_unit() {
    return this.primary_button_dark_bg_text_shadow.map((v, i) => (i === 3 ? v.code : `${v}px`)).join(' ');
  }
  get primary_button_dark_bg_box_background_color() {
    return this.styles.button.primary.dark_bg_box_background_color;
  }
  get primary_button_dark_bg_box_shadow() {
    return this.styles.button.primary.dark_bg_box_shadow;
  }
  get primary_button_dark_bg_box_shadow_unit() {
    return this.primary_button_dark_bg_box_shadow.map((v, i) => (i === 3 ? v.code : `${v}px`)).join(' ');
  }

  get secondary_button_font_family_index() {
    return this.styles.button.secondary.font_family_index;
  }
  get secondary_button_font_family() {
    const index = this.secondary_button_font_family_index;
    return this.fontFamilies[index];
  }
  get secondary_button_font_weight() {
    return this.styles.button.secondary.font_weight;
  }
  get secondary_button_font_size() {
    return this.styles.button.secondary.font_size;
  }
  get secondary_button_text_color() {
    return this.styles.button.secondary.text_color;
  }
  get secondary_button_dark_bg_text_color() {
    return this.styles.button.secondary.dark_bg_text_color;
  }
  get secondary_button_text_shadow() {
    return this.styles.button.secondary.text_shadow;
  }
  get secondary_button_hover_text_color() {
    return this.styles.button.secondary.hover_text_color;
  }
  get secondary_button_text_shadow_with_unit() {
    return this.secondary_button_text_shadow.map((v, i) => (i === 3 ? v.code : `${v}px`)).join(' ');
  }
  get secondary_button_dark_bg_text_shadow() {
    return this.styles.button.secondary.dark_bg_text_shadow;
  }
  get secondary_button_dark_bg_text_shadow_with_unit() {
    return this.secondary_button_dark_bg_text_shadow.map((v, i) => (i === 3 ? v.code : `${v}px`)).join(' ');
  }
  get secondary_button_box_background_color() {
    return this.styles.button.secondary.box_background_color;
  }
  get secondary_button_dark_bg_box_background_color() {
    return this.styles.button.secondary.dark_bg_box_background_color;
  }
  get secondary_button_box_border_color() {
    return this.styles.button.secondary.box_border_color;
  }
  get secondary_button_dark_bg_box_border_color() {
    return this.styles.button.secondary.dark_bg_box_border_color;
  }
  get secondary_button_box_border_radius() {
    return this.styles.button.secondary.box_border_radius;
  }
  get secondary_button_dark_bg_box_border_width() {
    return this.styles.button.secondary.dark_bg_box_border_width;
  }
  get secondary_button_box_border_width() {
    return this.styles.button.secondary.box_border_width;
  }
  get secondary_button_box_line_height() {
    return this.styles.button.secondary.box_line_height;
  }
  get secondary_button_box_shadow() {
    return this.styles.button.secondary.box_shadow;
  }
  get secondary_button_box_shadow_with_unit() {
    return this.secondary_button_box_shadow.map((v, i) => (i === 3 ? v.code : `${v}px`)).join(' ');
  }
  get secondary_button_dark_bg_box_shadow() {
    return this.styles.button.secondary.dark_bg_box_shadow;
  }
  get secondary_button_dark_bg_box_shadow_with_unit() {
    return this.secondary_button_dark_bg_box_shadow.map((v, i) => (i === 3 ? v.code : `${v}px`)).join(' ');
  }
  get secondary_button_hover_background_color() {
    return this.styles.button.secondary.hover_background_color;
  }
  get secondary_button_dark_bg_hover_background_color() {
    return this.styles.button.secondary.dark_bg_hover_background_color;
  }
  get secondary_button_hover_border_color() {
    return this.styles.button.secondary.hover_border_color;
  }
  get secondary_button_dark_bg_hover_border_color() {
    return this.styles.button.secondary.dark_bg_hover_border_color;
  }
  get secondary_button_dark_bg_hover_text_color() {
    return this.styles.button.secondary.dark_bg_hover_text_color;
  }
  get secondary_button_hover_shadow() {
    return this.styles.button.secondary.hover_shadow;
  }
  get secondary_button_hover_shadow_with_unit() {
    return this.secondary_button_hover_shadow.map((v, i) => (i === 3 ? v.code : `${v}px`)).join(' ');
  }
  get secondary_button_hover_text_shadow() {
    return this.styles.button.secondary.hover_text_shadow;
  }
  get secondary_button_hover_text_shadow_with_unit() {
    return this.secondary_button_hover_text_shadow.map((v, i) => (i === 3 ? v.code : `${v}px`)).join(' ');
  }
  get secondary_button_dark_bg_hover_shadow() {
    return this.styles.button.secondary.dark_bg_hover_shadow;
  }
  get secondary_button_dark_bg_hover_shadow_with_unit() {
    return this.secondary_button_dark_bg_hover_shadow.map((v, i) => (i === 3 ? v.code : `${v}px`)).join(' ');
  }
  get secondary_button_dark_bg_hover_text_shadow() {
    return this.styles.button.secondary.dark_bg_hover_text_shadow;
  }
  get secondary_button_dark_bg_hover_text_shadow_with_unit() {
    return this.secondary_button_dark_bg_hover_text_shadow.map((v, i) => (i === 3 ? v.code : `${v}px`)).join(' ');
  }
  get secondary_button_hover_opacity() {
    return this.styles.button.secondary.hover_opacity;
  }
  get secondary_button_dark_bg_hover_opacity() {
    return this.styles.button.secondary.dark_bg_hover_opacity;
  }

  get action_link_button_font_family_index() {
    return this.styles.button.action_link.font_family_index;
  }
  get action_link_button_font_family() {
    const index = this.action_link_button_font_family_index;
    return this.fontFamilies[index];
  }
  get action_link_button_font_weight() {
    return this.styles.button.action_link.font_weight;
  }
  get action_link_button_font_size() {
    return this.styles.button.action_link.font_size;
  }
  get action_link_button_text_color() {
    return this.styles.button.action_link.text_color;
  }
  get action_link_button_dark_bg_text_color() {
    return this.styles.button.action_link.dark_bg_text_color;
  }
  get action_link_button_arrow_color() {
    return this.styles.button.action_link.arrow_color;
  }
  get action_link_button_dark_bg_arrow_color() {
    return this.styles.button.action_link.dark_bg_arrow_color;
  }
  get action_link_button_arrow_size() {
    return this.styles.button.action_link.arrow_size;
  }
  get action_link_button_hover_color() {
    return this.styles.button.action_link.hover_color;
  }
  get action_link_button_hover_opacity() {
    return this.styles.button.action_link.hover_opacity;
  }
  get action_link_button_dark_bg_hover_color() {
    return this.styles.button.action_link.dark_bg_hover_color;
  }
  get action_link_button_dark_bg_hover_opacity() {
    return this.styles.button.action_link.dark_bg_hover_opacity;
  }

  get form_field_font_family() {
    const index = this.styles.form_field_font_family_index;
    if (index != null) return this.fontFamilies[index];
    else return this.paragraph_font_family;
  }

  get form_label_font_family() {
    const index = this.styles.form_label_font_family_index;
    if (index != null) return this.fontFamilies[index];
    else return this.paragraph_font_family;
  }

  get form_help_font_family() {
    const index = this.styles.form_help_font_family_index;
    if (index != null) return this.fontFamilies[index];
    else return this.paragraph_font_family;
  }

  // TEXT TRANSFORM GETTERS

  get header_1_text_transform() {
    return this.styles.typography.header_1.text_transform;
  }

  get header_2_text_transform() {
    return this.styles.typography.header_2.text_transform;
  }

  get header_3_text_transform() {
    return this.styles.typography.header_3.text_transform;
  }

  get header_4_text_transform() {
    return this.styles.typography.header_4.text_transform;
  }

  get header_5_text_transform() {
    return this.styles.typography.header_5.text_transform;
  }

  get header_6_text_transform() {
    return this.styles.typography.header_6.text_transform;
  }

  get blog_header_1_text_transform() {
    return this.styles.typography.blog_header_1.text_transform;
  }

  get blog_header_2_text_transform() {
    return this.styles.typography.blog_header_2.text_transform;
  }

  get blog_header_3_text_transform() {
    return this.styles.typography.blog_header_3.text_transform;
  }

  get blog_header_4_text_transform() {
    return this.styles.typography.blog_header_4.text_transform;
  }

  get blog_header_5_text_transform() {
    return this.styles.typography.blog_header_5.text_transform;
  }

  get blog_header_6_text_transform() {
    return this.styles.typography.blog_header_6.text_transform;
  }

  get big_quote_text_transform() {
    return this.styles.typography.big_quote.text_transform;
  }

  get primary_button_text_transform() {
    return this.styles.button.primary.text_transform;
  }

  get secondary_button_text_transform() {
    return this.styles.button.secondary.text_transform;
  }

  get action_link_button_text_transform() {
    return this.styles.button.action_link.text_transform;
  }

  get header_menu_text_transform() {
    return this.styles.list.header_menu.text_transform;
  }

  get header_menu_action_text_transform() {
    return this.styles.list.header_menu.action_text_transform;
  }

  get footer_menu_text_transform() {
    return this.styles.list.footer_menu.text_transform;
  }

  get footer_menu_category_text_transform() {
    return this.styles.list.footer_menu.category_text_transform;
  }

  get form_label_text_transform() {
    return this.styles.form_label_text_transform;
  }

  // LETTER SPACING GETTERS

  get header_1_letter_spacing() {
    return this.styles.typography.header_1.letter_spacing;
  }

  get header_2_letter_spacing() {
    return this.styles.typography.header_2.letter_spacing;
  }

  get header_3_letter_spacing() {
    return this.styles.typography.header_3.letter_spacing;
  }

  get header_4_letter_spacing() {
    return this.styles.typography.header_4.letter_spacing;
  }

  get header_5_letter_spacing() {
    return this.styles.typography.header_5.letter_spacing;
  }

  get header_6_letter_spacing() {
    return this.styles.typography.header_6.letter_spacing;
  }

  get blog_header_1_letter_spacing() {
    return this.styles.typography.blog_header_1.letter_spacing;
  }

  get blog_header_2_letter_spacing() {
    return this.styles.typography.blog_header_2.letter_spacing;
  }

  get blog_header_3_letter_spacing() {
    return this.styles.typography.blog_header_3.letter_spacing;
  }

  get blog_header_4_letter_spacing() {
    return this.styles.typography.blog_header_4.letter_spacing;
  }

  get blog_header_5_letter_spacing() {
    return this.styles.typography.blog_header_5.letter_spacing;
  }

  get blog_header_6_letter_spacing() {
    return this.styles.typography.blog_header_6.letter_spacing;
  }

  get big_quote_letter_spacing() {
    return this.styles.typography.big_quote.letter_spacing;
  }

  get primary_button_letter_spacing() {
    return this.styles.button.primary.letter_spacing;
  }

  get secondary_button_letter_spacing() {
    return this.styles.button.secondary.letter_spacing;
  }

  get action_link_button_letter_spacing() {
    return this.styles.button.action_link.letter_spacing;
  }

  get header_menu_letter_spacing() {
    return this.styles.list.header_menu.letter_spacing;
  }

  get header_menu_action_letter_spacing() {
    return this.styles.list.header_menu.action_letter_spacing;
  }

  get footer_menu_letter_spacing() {
    return this.styles.list.footer_menu.letter_spacing;
  }

  get footer_menu_category_letter_spacing() {
    return this.styles.list.footer_menu.category_letter_spacing;
  }

  get form_label_letter_spacing() {
    return this.styles.form_label_letter_spacing;
  }

  //  LAYOUTS
  get layout_section_desktop_padding() {
    return this.styles.layout?.section?.desktop_padding;
  }

  get layout_section_mobile_padding() {
    return this.styles.layout?.section?.mobile_padding;
  }

  get layout_section_desktop_width() {
    return this.styles.layout?.section?.desktop_width;
  }

  get layout_box_desktop_padding() {
    return this.styles.layout?.box?.desktop_padding;
  }

  get layout_box_desktop_width() {
    return this.styles.layout?.box?.desktop_width;
  }

  get layout_box_mobile_padding() {
    return this.styles.layout?.box?.mobile_padding;
  }

  // MARGIN GETTERS
  get header_1_margin() {
    return this.styles.typography.header_1.margin;
  }

  get header_2_margin() {
    return this.styles.typography.header_2.margin;
  }

  get header_3_margin() {
    return this.styles.typography.header_3.margin;
  }

  get header_4_margin() {
    return this.styles.typography.header_4.margin;
  }

  get header_5_margin() {
    return this.styles.typography.header_5.margin;
  }

  get header_6_margin() {
    return this.styles.typography.header_6.margin;
  }

  get paragraph_margin() {
    return this.styles.typography.paragraph.margin;
  }

  get blog_header_1_margin() {
    return this.styles.typography.blog_header_1.margin;
  }

  get blog_header_2_margin() {
    return this.styles.typography.blog_header_2.margin;
  }

  get blog_header_3_margin() {
    return this.styles.typography.blog_header_3.margin;
  }

  get blog_header_4_margin() {
    return this.styles.typography.blog_header_4.margin;
  }

  get blog_header_5_margin() {
    return this.styles.typography.blog_header_5.margin;
  }

  get blog_header_6_margin() {
    return this.styles.typography.blog_header_6.margin;
  }

  get blog_paragraph_margin() {
    return this.styles.typography.blog_paragraph.margin;
  }

  // SHADOW GETTERS

  get box_shadows() {
    return this.styles.box_shadows;
  }

  get box_shadow_small() {
    return this.styles.box_shadows.small;
  }

  get box_shadow_medium() {
    return this.styles.box_shadows.medium;
  }

  get box_shadow_large() {
    return this.styles.box_shadows.large;
  }

  get box_shadow_x_large() {
    return this.styles.box_shadows.x_large;
  }

  // MOBILE GETTERS
  get header_1_font_size_mobile() {
    return this.styles.typography.header_1.mobile.font_size;
  }
  get header_1_line_height_mobile() {
    return this.styles.typography.header_1.mobile.line_height;
  }
  get header_1_letter_spacing_mobile() {
    return this.styles.typography.header_1.mobile.letter_spacing;
  }
  get header_1_margin_mobile() {
    return this.styles.typography.header_1.mobile.margin;
  }

  get header_2_font_size_mobile() {
    return this.styles.typography.header_2.mobile.font_size;
  }
  get header_2_line_height_mobile() {
    return this.styles.typography.header_2.mobile.line_height;
  }
  get header_2_letter_spacing_mobile() {
    return this.styles.typography.header_2.mobile.letter_spacing;
  }
  get header_2_margin_mobile() {
    return this.styles.typography.header_2.mobile.margin;
  }

  get header_3_font_size_mobile() {
    return this.styles.typography.header_3.mobile.font_size;
  }
  get header_3_line_height_mobile() {
    return this.styles.typography.header_3.mobile.line_height;
  }
  get header_3_letter_spacing_mobile() {
    return this.styles.typography.header_3.mobile.letter_spacing;
  }
  get header_3_margin_mobile() {
    return this.styles.typography.header_3.mobile.margin;
  }

  get header_4_font_size_mobile() {
    return this.styles.typography.header_4.mobile.font_size;
  }
  get header_4_line_height_mobile() {
    return this.styles.typography.header_4.mobile.line_height;
  }
  get header_4_letter_spacing_mobile() {
    return this.styles.typography.header_4.mobile.letter_spacing;
  }
  get header_4_margin_mobile() {
    return this.styles.typography.header_4.mobile.margin;
  }

  get header_5_font_size_mobile() {
    return this.styles.typography.header_5.mobile.font_size;
  }
  get header_5_line_height_mobile() {
    return this.styles.typography.header_5.mobile.line_height;
  }
  get header_5_letter_spacing_mobile() {
    return this.styles.typography.header_5.mobile.letter_spacing;
  }
  get header_5_margin_mobile() {
    return this.styles.typography.header_5.mobile.margin;
  }

  get header_6_font_size_mobile() {
    return this.styles.typography.header_6.mobile.font_size;
  }
  get header_6_line_height_mobile() {
    return this.styles.typography.header_6.mobile.line_height;
  }
  get header_6_letter_spacing_mobile() {
    return this.styles.typography.header_6.mobile.letter_spacing;
  }
  get header_6_margin_mobile() {
    return this.styles.typography.header_6.mobile.margin;
  }

  get paragraph_font_size_mobile() {
    return this.styles.typography.paragraph.mobile.font_size;
  }
  get paragraph_line_height_mobile() {
    return this.styles.typography.paragraph.mobile.line_height;
  }
  get paragraph_letter_spacing_mobile() {
    return this.styles.typography.paragraph.mobile.letter_spacing;
  }
  get paragraph_margin_mobile() {
    return this.styles.typography.paragraph.mobile.margin;
  }

  get big_quote_font_size_mobile() {
    return this.styles.typography.big_quote.mobile.font_size;
  }
  get big_quote_line_height_mobile() {
    return this.styles.typography.big_quote.mobile.line_height;
  }
  get big_quote_mark_size_mobile() {
    return this.styles.typography.big_quote.mobile.mark_size;
  }
  get big_quote_letter_spacing_mobile() {
    return this.styles.typography.big_quote.mobile.letter_spacing;
  }

  get blog_header_1_font_size_mobile() {
    return this.styles.typography.blog_header_1.mobile.font_size;
  }
  get blog_header_1_line_height_mobile() {
    return this.styles.typography.blog_header_1.mobile.line_height;
  }
  get blog_header_1_letter_spacing_mobile() {
    return this.styles.typography.blog_header_1.mobile.letter_spacing;
  }
  get blog_header_1_margin_mobile() {
    return this.styles.typography.blog_header_1.mobile.margin;
  }

  get blog_header_2_font_size_mobile() {
    return this.styles.typography.blog_header_2.mobile.font_size;
  }
  get blog_header_2_line_height_mobile() {
    return this.styles.typography.blog_header_2.mobile.line_height;
  }
  get blog_header_2_letter_spacing_mobile() {
    return this.styles.typography.blog_header_2.mobile.letter_spacing;
  }
  get blog_header_2_margin_mobile() {
    return this.styles.typography.blog_header_2.mobile.margin;
  }

  get blog_header_3_font_size_mobile() {
    return this.styles.typography.blog_header_3.mobile.font_size;
  }
  get blog_header_3_line_height_mobile() {
    return this.styles.typography.blog_header_3.mobile.line_height;
  }
  get blog_header_3_letter_spacing_mobile() {
    return this.styles.typography.blog_header_3.mobile.letter_spacing;
  }
  get blog_header_3_margin_mobile() {
    return this.styles.typography.blog_header_3.mobile.margin;
  }

  get blog_header_4_font_size_mobile() {
    return this.styles.typography.blog_header_4.mobile.font_size;
  }
  get blog_header_4_line_height_mobile() {
    return this.styles.typography.blog_header_4.mobile.line_height;
  }
  get blog_header_4_letter_spacing_mobile() {
    return this.styles.typography.blog_header_4.mobile.letter_spacing;
  }
  get blog_header_4_margin_mobile() {
    return this.styles.typography.blog_header_4.mobile.margin;
  }

  get blog_header_5_font_size_mobile() {
    return this.styles.typography.blog_header_5.mobile.font_size;
  }
  get blog_header_5_line_height_mobile() {
    return this.styles.typography.blog_header_5.mobile.line_height;
  }
  get blog_header_5_letter_spacing_mobile() {
    return this.styles.typography.blog_header_5.mobile.letter_spacing;
  }
  get blog_header_5_margin_mobile() {
    return this.styles.typography.blog_header_5.mobile.margin;
  }

  get blog_header_6_font_size_mobile() {
    return this.styles.typography.blog_header_6.mobile.font_size;
  }
  get blog_header_6_line_height_mobile() {
    return this.styles.typography.blog_header_6.mobile.line_height;
  }
  get blog_header_6_letter_spacing_mobile() {
    return this.styles.typography.blog_header_6.mobile.letter_spacing;
  }
  get blog_header_6_margin_mobile() {
    return this.styles.typography.blog_header_6.mobile.margin;
  }

  get blog_paragraph_font_size_mobile() {
    return this.styles.typography.blog_paragraph.mobile.font_size;
  }
  get blog_paragraph_line_height_mobile() {
    return this.styles.typography.blog_paragraph.mobile.line_height;
  }
  get blog_paragraph_letter_spacing_mobile() {
    return this.styles.typography.blog_paragraph.mobile.letter_spacing;
  }
  get blog_paragraph_margin_mobile() {
    return this.styles.typography.blog_paragraph.mobile.margin;
  }

  get primary_button_font_size_mobile() {
    return this.styles.button.primary.mobile.font_size;
  }
  get primary_button_letter_spacing_mobile() {
    return this.styles.button.primary.mobile.letter_spacing;
  }
  get primary_button_box_line_height_mobile() {
    return this.styles.button.primary.mobile.box_line_height;
  }
  get primary_button_box_border_radius_mobile() {
    return this.styles.button.primary.mobile.box_border_radius;
  }

  get secondary_button_font_size_mobile() {
    return this.styles.button.secondary.mobile.font_size;
  }
  get secondary_button_letter_spacing_mobile() {
    return this.styles.button.secondary.mobile.letter_spacing;
  }
  get secondary_button_box_line_height_mobile() {
    return this.styles.button.secondary.mobile.box_line_height;
  }
  get secondary_button_box_border_radius_mobile() {
    return this.styles.button.secondary.mobile.box_border_radius;
  }

  get action_link_button_font_size_mobile() {
    return this.styles.button.action_link.mobile.font_size;
  }
  get action_link_button_letter_spacing_mobile() {
    return this.styles.button.action_link.mobile.letter_spacing;
  }
  get action_link_button_arrow_size_mobile() {
    return this.styles.button.action_link.mobile.arrow_size;
  }

  get check_list_font_size_mobile() {
    return this.styles.list.check_list.mobile.font_size;
  }
  get check_list_bottom_spacing_mobile() {
    return this.styles.list.check_list.mobile.bottom_spacing;
  }
  get check_list_line_height_mobile() {
    return this.styles.list.check_list.mobile.line_height;
  }
  get check_list_icon_size_mobile() {
    return this.styles.list.check_list.mobile.icon_size;
  }

  get form_label_letter_spacing_mobile() {
    return this.styles.form_label_letter_spacing_mobile;
  }
}

export default StyleGuide;
