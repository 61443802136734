import React, { useCallback, useState, useMemo, useEffect, useRef, ReactElement } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import SettingsDrawer from './ProductGenerator/ProductCollectionDrawer';

import { selectCollections } from 'reducers/storefrontReducer';
import { saveSelectedSectionCollection } from 'actions/storefrontActions';

import { ReactComponent as IconEdit } from '../../../../../assets/images/icons8-pencil.svg';
import { ReactComponent as IconLeft } from '../../../../../assets/images/icon-chevron-left.svg';
import { ReactComponent as IconRight } from '../../../../../assets/images/icon-chevron-right.svg';

import { ShopifyCollection, ShopifyProduct } from 'types/ShopifyProduct';
import { CollectionTagArgs } from 'components/unstack-components/tag-types';
import { SectionHandlersInterface, SectionUiHandlersInterface } from 'components/unstack-components/types';

import styles from './ProductGenerator/ProductGenerator.module.scss';
import { USectionDevices } from 'types/USection';
import { set, del, insert, push, assign } from 'object-path-immutable';
import { getDeviceTypeToSaveTo } from '../../util/helpers/deviceHelper';
import { getDevice } from 'reducers/uiReducer';
import Toolbar from 'components/Editor/Boxes/Toolbar';
import ProductCollectionToolbar from './ProductCollectionToolbar';
import { getActiveToolbar } from 'reducers/toolbarReducer';
import { setActiveSection, setActiveToolbar } from 'actions/toolbarActions';
import { useStorefrontCollections } from 'components/Storefront/hooks';

interface IProps extends CollectionTagArgs {
  children?: React.ReactChildren;
  sectionHandlers?: SectionHandlersInterface;
  sectionUiHandlers?: SectionUiHandlersInterface;
  onChange: (content: USectionDevices, key?: string, multi?: boolean) => void;
  content: USectionDevices;
  contentKey: string;
  dataRef: {
    filters?: { collection: string };
    limit?: string;
    paginationStyle?: string;
    itemsPerPage?: string;
    paginationTransition?: string;
    order_by?: string;
    visibility?: string;
    pins?: string[];
  };
}

interface ICollectionOrProducts extends ShopifyCollection, Array<ShopifyProduct> {}

const ProductCollection = ({ ...props }: IProps) => {
  const dispatch = useDispatch();
  const {
    item,
    tagName,
    minItems,
    maxItems,
    onChange,
    className,
    dataRef,
    defaultItems,
    sectionHandlers,
    sectionUiHandlers,
    content,
    contentKey,
    enablePagination,
    defaultPaginationStyle,
    defaultPaginationTransition,
    defaultItemsPerPage,
    defaults,
  } = props;
  const device = useSelector(getDevice);
  const splitKey = contentKey.split('.');
  const isPDP = dataRef?.filters?.collection === '{{ category.id }}';

  const { data, collectionInfo }: any = useSelector((state): any => {
    try {
      const collections: any = selectCollections(state)[sectionHandlers.containerId];
      if (Array.isArray(collections?.['content.products'])) {
        return { data: collections?.['content.products'].map((item: any) => item.base || item) };
      } else if (collections?.['content.products']?.products) {
        return {
          data: collections?.['content.products']?.products,
          collectionInfo: collections?.['content.products']?.attributes,
        };
      } else {
        return [] as ICollectionOrProducts;
      }
    } catch (e) {
      console.error(e);
      return {} as ICollectionOrProducts;
    }
  });

  const { data: memoizedData, collectionInfo: memoizedCollectionInfo }: any = useMemo(
    () => ({ data, collectionInfo }),
    [data, collectionInfo]
  );

  const [isOpen, setIsOpen] = useState(false);
  const [paginationStyle, setPaginationStyle] = useState(
    content?.base?.products?.paginationStyle
      ? content.base.products.paginationStyle
      : defaultPaginationStyle
      ? defaultPaginationStyle
      : defaults.defaultPaginationStyle
  );
  const [paginationTransition, setPaginationTransition] = useState(
    content?.base?.products?.paginationTransition
      ? content.base.products.paginationTransition
      : defaultPaginationTransition
      ? defaultPaginationTransition
      : defaults.defaultPaginationTransition
  );
  const [itemsPerPage, setItemsPerPage] = useState(
    content?.base?.products?.itemsPerPage
      ? content.base.products.itemsPerPage
      : defaultItemsPerPage
      ? defaultItemsPerPage
      : defaults.defaultItemsPerPage
  );

  useEffect(() => {
    if (content?.base?.products) {
      handleChange({
        ...content.base.products,
        paginationStyle: paginationStyle,
        paginationTransition: paginationTransition,
        itemsPerPage: itemsPerPage,
      });
    }
  }, [paginationStyle, paginationTransition, itemsPerPage]);

  const placeholders: any = useMemo(() => {
    if (!dataRef) return [...Array(defaultItems)];
    if (dataRef?.filters) {
      if (dataRef?.filters?.collection === '{{ category.id }}') return [...Array(12)];
      if (!memoizedData?.products) {
        if (memoizedData?.length) return [...memoizedData].slice(0, Number(dataRef?.limit));
        return [...Array(Number(dataRef.limit) || defaultItems)];
      } else if (memoizedData.products) return memoizedData.products.slice(0, Number(dataRef?.limit));
    } else if (dataRef?.pins) {
      if (memoizedData?.length) return [...memoizedData];
      return [...Array(Number(dataRef?.limit) || defaultItems)];
    }
  }, [defaultItems, dataRef, memoizedData]);

  const handleChange = useCallback(
    (newValue: any, type?: any, limitOrProducts?: any, isDynamic?: any) => {
      switch (type) {
        case undefined:
          onChange(
            set(content, [getDeviceTypeToSaveTo(device, true), ...splitKey.slice(1)], newValue),
            `content.${splitKey[0]}`
          );
          break;

        case 'filtered':
          let newContent = {};
          if (enablePagination) {
            newContent = {
              filters: { collection: isDynamic ? '{{ category.id }}' : newValue.id || dataRef.filters.collection },
              limit: limitOrProducts,
              paginationStyle: paginationStyle,
              paginationTransition: paginationTransition,
              itemsPerPage: itemsPerPage,
              order_by: '',
            };
          } else {
            newContent = {
              filters: { collection: isDynamic ? '{{ category.id }}' : newValue.id || dataRef.filters.collection },
              limit: limitOrProducts,
              order_by: '',
            };
          }
          onChange(
            set(content, [getDeviceTypeToSaveTo(device, true), ...splitKey.slice(1)], newContent),
            `content.${splitKey[0]}`
          );
          break;

        default:
          if (limitOrProducts) {
            dispatch(
              saveSelectedSectionCollection({ [sectionHandlers.containerId]: { 'content.products': limitOrProducts } })
            );
          }
          if (enablePagination) {
            onChange(
              set(content, [getDeviceTypeToSaveTo(device, true), ...splitKey.slice(1)], {
                pins: newValue,
                paginationStyle: paginationStyle,
                paginationTransition: paginationTransition,
                itemsPerPage: itemsPerPage,
              }),
              `content.${splitKey[0]}`
            );
          } else {
            onChange(
              set(content, [getDeviceTypeToSaveTo(device, true), ...splitKey.slice(1)], { pins: newValue }),
              `content.${splitKey[0]}`
            );
          }
          break;
      }
    },
    [dataRef, onChange, content]
  );

  const renderedProducts = placeholders?.map((placeholder: any, i: number) => {
    return React.cloneElement(item(placeholder, i), { key: i });
  });

  let pages = 0;
  if (enablePagination) {
    pages = Math.ceil((dataRef?.pins?.length || renderedProducts.length) / itemsPerPage);
  }
  const pagesHtml =
    pages > 6
      ? [
          ...Array.from({ length: 3 }, (_, i) => (
            <a key={i} className={i === 0 ? 'is-current' : ''}>
              {i + 1}
            </a>
          )),
          <a key="ellipsis" className="paginationEllipses">
            ...
          </a>,
          <a key={pages - 1} className="">
            {pages}
          </a>,
        ]
      : Array.from({ length: pages }, (_, i) => (
          <a key={i} className={i === 0 ? 'is-current' : ''}>
            {i + 1}
          </a>
        ));
  const dotsHtml = Array.from({ length: pages }, (_, i) => <a key={i} className={i === 0 ? 'is-current' : ''}></a>);

  const ContainerTag = tagName as keyof React.ReactHTML;
  const containerClassName = `${className} ${styles.container}`;

  const [collectionUUid, setCollectionUUid] = useState<string>(uuidv4());
  const [toolbarHovered, setToolbarHovered] = useState(false);
  const activeToolbar = useSelector(getActiveToolbar);
  const toolbarActive = activeToolbar === collectionUUid;

  const labels: ReactElement[] = [];
  if (['mobile', 'tablet'].includes(device) && toolbarActive) {
    const elUUid = props.sectionHandlers.containerId;
    labels.push(
      <span
        className={styles.labelElement}
        onClick={(e) => {
          e.stopPropagation();
          dispatch(setActiveToolbar(elUUid));
        }}
      >
        Product Collection
      </span>
    );
    labels.push(<span> -{'>'} </span>);
  }
  labels.push(
    <span
      className={styles.labelElement}
      onClick={(e) => {
        e.stopPropagation();
        dispatch(setActiveToolbar(collectionUUid));
      }}
    >
      Collection
    </span>
  );
  var label = <>{labels}</>;

  // CODE TO ADD PADDING
  var renderedProductsFinal = renderedProducts;
  if (enablePagination) {
    if (paginationStyle === 'numbers' || paginationStyle === 'dots') {
      const renderedProductsPagination = renderedProducts.slice(0, itemsPerPage);
      const columns = props?.content?.base?.section?.properties?.columns;
      const columnNumber = columns ? parseInt(columns.split('-')[1], 10) : null;
      const productsOnLastRow = ['mobile', 'tablet'].includes(device)
        ? renderedProductsPagination.length % columnNumber || 2
        : renderedProductsPagination.length % columnNumber || columnNumber;
      const updatedElements = renderedProductsPagination.map((renderedProduct: ReactElement, index: number) => {
        const isLastRow = index >= renderedProductsPagination.length - productsOnLastRow;
        if (isLastRow) {
          return React.cloneElement(renderedProduct, { style: { paddingBottom: '40px' } });
        }
        return renderedProduct;
      });
      renderedProductsFinal = updatedElements;
    } else {
      renderedProductsFinal = renderedProducts.slice(0, itemsPerPage);
    }
  }

  const arrowsRef = useRef(null);
  useEffect(() => {
    if (arrowsRef?.current) {
      const parentNode = arrowsRef?.current?.parentNode;
      const productDetails = parentNode?.querySelector('.product-details');
      const productsHeight = parentNode?.offsetHeight;
      const productDetailsHeight = productDetails?.offsetHeight ? productDetails.offsetHeight : 40;
      const arrowHeight = (productsHeight - productDetailsHeight) / 2 - 20;
      arrowsRef.current.style.top = `calc(${arrowHeight}px)`;
    }
  }, [renderedProductsFinal]);

  return (
    <>
      <ContainerTag
        className={containerClassName}
        style={{ position: 'relative' }}
        onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => {
          e.preventDefault();
          if (enablePagination) {
            e.stopPropagation();
            dispatch(setActiveSection(props.sectionHandlers.containerId));
            dispatch(setActiveToolbar(collectionUUid));
          } else {
            if (!isPDP) setIsOpen(true);
          }
        }}
      >
        {renderedProductsFinal}
        {!enablePagination && !isPDP && (
          <div className={styles.overlay}>
            <div className={styles.copy}>
              <IconEdit width={20} height={20} /> <span>Edit section</span>
            </div>
          </div>
        )}
        {!enablePagination && isPDP && (
          <div className={styles.overlayDisabled}>
            <div className={styles.copy}>
              <IconEdit width={20} height={20} /> <span>Edit section</span>
            </div>
          </div>
        )}
        {enablePagination && (
          <div
            className={styles.overlay}
            onMouseEnter={() => setToolbarHovered(true)}
            onMouseLeave={() => setToolbarHovered(false)}
          ></div>
        )}
        {enablePagination && (
          <Toolbar
            hideTrayTop={false}
            isBorderShown={toolbarHovered}
            mobileLayout={content?.base?.section?.properties?.mobileLayout}
            renderItems={() => (
              <ProductCollectionToolbar
                setIsOpen={setIsOpen}
                paginationStyle={paginationStyle}
                setPaginationStyle={setPaginationStyle}
                paginationTransition={paginationTransition}
                setPaginationTransition={setPaginationTransition}
                itemsPerPage={itemsPerPage}
                setItemsPerPage={setItemsPerPage}
                isPDP={isPDP}
              />
            )}
            toolbarActive={toolbarActive}
            label={label}
            drawToolbar={true}
            shouldOnlyShowHover={false}
            toolbarType="collection"
          />
        )}
        <SettingsDrawer
          isOpen={isOpen}
          close={() => setIsOpen(false)}
          value={dataRef}
          {...(dataRef?.filters
            ? { collection: memoizedData, collectionInfo: memoizedCollectionInfo }
            : { products: memoizedData })}
          onChange={handleChange}
          min={minItems}
          max={maxItems}
          defaultCount={defaultItems}
          itemType={sectionUiHandlers.itemType}
        />
      </ContainerTag>
      {enablePagination &&
        paginationStyle === 'numbers' &&
        !(device === 'mobile' && content.base.section.properties.mobileLayout === 'slider') && (
          <div className="pagination-numbers">
            <a className="arrow-disabled">
              <IconLeft />
            </a>
            {pagesHtml}
            <a className={pages === 1 ? 'arrow-disabled' : ''}>
              <IconRight />
            </a>
          </div>
        )}
      {enablePagination &&
        paginationStyle === 'arrows' &&
        !(device === 'mobile' && content.base.section.properties.mobileLayout === 'slider') && (
          <div className="pagination-arrows" ref={arrowsRef}>
            <a rel="prev" className="arrow-disabled">
              <IconLeft />
            </a>
            <a rel="next" className={pages === 1 ? 'arrow-disabled' : ''}>
              <IconRight />
            </a>
          </div>
        )}
      {enablePagination && paginationStyle === 'dots' && <div className="pagination-dots">{dotsHtml}</div>}
    </>
  );
};

export default ProductCollection;
