import { delay, call, put, select, all, race, take, takeEvery, takeLatest } from 'redux-saga/effects';
import * as api from '../services/spark-api';
import * as types from '../actions/actionTypes';
import * as accountActions from '../actions/accountActions';
import * as accountSelectors from '../reducers/accountReducer';
import * as uiActions from '../actions/uiActions';
import * as siteActions from '../actions/siteActions';

// function* createAccount(action) {
//   const payload = {
//     name: action.payload.name,
//     subdomain: action.payload.subdomain,
//     owner: {
//       email: action.payload.email,
//       password: action.payload.password
//     },
//   };
//
//   try {
//     const response = yield call(api.createAccount, payload);
//
//     if (response.ok) {
//       // Store account token synchronously
//       auth.storeToken(response.json.token);
//       yield put({ type: types.ACCOUNT_SCHEDULE_TOKEN_REFRESH });
//
//       // Site doesn't need to be stored at the moment because it is
//       // automatically scoped with api.authorizedGet('site/')
//
//       // Register as logged in
//       yield put(accountActions.loginSuccess());
//     }
//     else {
//       yield put(accountActions.receiveErrors(response.json));
//     }
//   } catch(err) {
//     console.error(err);
//   }
// }

// function* afterLoginSuccess(action) {
//   yield delay(0);
//   yield put(accountActions.requestIfNeeded());
// }

function* requestAccountIfNeeded(action) {
  const { accountId } = action.payload;
  const hasAccount = yield select(accountSelectors.selectHasAccount);
  if (!hasAccount) yield put(accountActions.request(accountId));
}

function* requestAccount(action) {
  try {
    const { accountId } = action.payload;
    const { response, cancel } = yield race({
      response: call(api.getAccount, accountId),
      cancel: take(types.ACCOUNT_LOGOUT),
    });

    if (cancel) return;

    const account = response.json;

    if (response.ok && account) {
      yield put(accountActions.receive(account));
    } else if (response.unauthorized) {
      yield put(accountActions.logout());
    } else if (response.status >= 400 && response.status < 500) {
      yield put(accountActions.receiveErrors(response.json));
    } else {
      console.error('Failed trying to fetch account', response.json);
    }
  } catch (err) {
    yield put(uiActions.connectionError());
    console.error(err);
  }
}

function forbidAction(action) {
  console.log('Unauthorized action attempt');
}

function* updateAccountOnboardingStep(action) {
  /*  Only updating the meta for accounts, not using the fetched response
      as it has information missing */
  const response = yield call(api.updateAccount, action.payload);
  if (response.ok) {
    yield put({
      type: types.ACCOUNT_ON_ONBOARDING_UPDATE,
      payload: action.payload.meta,
    });
  }
}

function* removeAccountOnboardingStep(action) {
  const response = yield call(api.deleteAccountOnboardingStep, action.payload);
  if (response.ok) {
    const account = response.json;
    yield put(accountActions.receive({ meta: account.meta, metadata: account.metadata }));
  }
}

function* updateAccountOnboarding(action) {
  const response = yield call(api.patchAccountOnboarding, action.payload);
  if (response.ok) {
    const account = response.json;
    yield put(accountActions.receive({ meta: account.meta, metadata: account.metadata }));
  }
}

function* requestEnableMFA(action) {
  const { payload: contact } = action;
  try {
    const response = yield call(api.createMFA, { contact });
    if (response.ok) yield put(accountActions.MFAStepSuccess(response.json));
    else if (response.status === 400) yield put(accountActions.MFAStepFailed(response.json));
  } catch (e) {
    console.error(e);
  }
}

function* verifyMFACode(action) {
  const { payload } = action;
  const mfa = yield select(accountSelectors.selectAccountMFAInfo);
  try {
    const response = yield call(api.verifyMFACode, mfa.id, payload);
    if (response.ok) yield put(accountActions.MFAStepSuccess(response.json));
    else if (response.status === 400) yield put(accountActions.MFAStepFailed(response.json));
  } catch (e) {
    console.error(e);
  }
}

function* fetchMFAIfNeeded() {
  const mfa = yield select(accountSelectors.selectAccountMFAInfo);
  if (mfa === undefined) yield put(accountActions.getMFA());
}

function* fetchMFA() {
  try {
    const response = yield call(api.getMFA);
    if (response.ok) yield put(accountActions.MFAStepSuccess(response.json[0]));
  } catch (e) {
    console.error(e);
  }
}

function* disableMFA(action) {
  const { payload: id } = action;
  try {
    const response = yield call(api.disableMFA, id);
    if (response.ok) yield put(accountActions.disableMFASuccess());
  } catch (e) {
    console.error(e);
  }
}

//  TODO: This should be a part of Site Saga
function* createMultiSite(action) {
  const { payload } = action;
  const { domain, resolve, reject } = payload;
  try {
    const response = yield call(api.createMultiSite, payload);
    if (response.ok) {
      const { site } = response.json;
      yield put(siteActions.receive(site));
      resolve(site);
    } else {
      reject(response);
    }
  } catch (e) {
    console.error(e);
  }
}

function* accountSaga() {
  yield all([
    // takeLatest(types.ACCOUNT_CREATE, createAccount),
    // takeLatest(types.ACCOUNT_LOGIN_SUCCESS, afterLoginSuccess),
    takeEvery(types.ACCOUNT_REQUEST_IF_NEEDED, requestAccountIfNeeded),
    takeLatest(types.ACCOUNT_REQUEST, requestAccount),
    takeEvery(types.ACCOUNT_FORBID_ACTION, forbidAction),
    takeEvery(types.ACCOUNT_UPDATE_ONBOARDING_STEP, updateAccountOnboardingStep),
    takeLatest(types.ACCOUNT_MFA_ENABLE_REQUEST, requestEnableMFA),
    takeLatest(types.ACCOUNT_MFA_GET_IF_NEEDED, fetchMFAIfNeeded),
    takeLatest(types.ACCOUNT_MFA_GET, fetchMFA),
    takeLatest(types.ACCOUNT_MFA_DISABLE, disableMFA),
    takeLatest(types.ACCOUNT_MFA_VERIFY_CODE, verifyMFACode),
    takeLatest(types.ACCOUNT_MULTI_SITE_CREATE_REQUEST, createMultiSite),
    takeLatest(types.ACCOUNT_UPDATE_ONBOARDING, updateAccountOnboarding),
    takeLatest(types.ACCOUNT_REMOVE_ONBOARDING_STEP, removeAccountOnboardingStep),
  ]);
}

export default accountSaga;
