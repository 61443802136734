import React, { useState, useEffect } from 'react';

import SideDrawer from 'components/base/SideDrawer';
import { colorToRGBA, getRGBAOpacity, rgbTohex } from '../../../Editor/Toolbars2/helpers';

import { ReactComponent as SvgIconTransparent } from 'assets/images/icon-transparent.svg';

import styles from './Shadows.module.scss';

type ColorOpacityInputProps = {
  label: string;
  name: string;
  value: string;
  setSpread: React.Dispatch<React.SetStateAction<any>>;
};

type ShadowDrawer = {
  title: string;
  isOpen: boolean;
  close: () => void;
  shadow: string;
  handleChange: (shadow: [string], value: string) => void;
  styleGuide: any;
};

function shadowToStyles(shadow: string | any) {
  if (shadow) {
    const singleShadow = shadow.split(/,\s*-?\d+.?\d*[a-zA-Z]+|,\s*[a-zA-Z]+/i)[0];
    const color = singleShadow.match(
      /hsla?\((.*?)\)|rgba?\((.*?)\)|#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3}|[A-Fa-f0-9]{8}|[A-Fa-f0-9]{4})$/gi
    );
    const rgba = color ? colorToRGBA(color[0]) : '';
    const boxShadows = singleShadow.replace(
      /hsla?\((.*?)\)|rgba?\((.*?)\)|#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3}|[A-Fa-f0-9]{8}|[A-Fa-f0-9]{4})$/gi,
      ''
    );
    const boxShadowsArray = boxShadows.split(' ');
    const [x_offset, y_offset, blur, spread] = boxShadowsArray ? boxShadowsArray : [0, 0, 0, 0];
    return [
      x_offset ? x_offset.replace(/[a-zA-Z]+/i, '').trim() : '0',
      y_offset ? y_offset.replace(/[a-zA-Z]+/i, '').trim() : '0',
      blur ? blur.replace(/[a-zA-Z]+/i, '').trim() : '0',
      spread ? spread.replace(/[a-zA-Z]+/i, '').trim() : '0',
      rgba ? rgba : 'rgba(0,0,0,1)',
    ];
  }
}

function ColorOpacityInput(props: ColorOpacityInputProps) {
  return (
    <div>
      <label>{props.label}</label>
      <div className={styles.inputContainer}>
        <input
          className={styles.colorOpacityInput}
          type="text"
          name={props.name}
          value={props.value}
          onChange={(e) => props.setSpread(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === 'ArrowUp') {
              props.setSpread(Number(props.value) + 1);
            } else if (e.key === 'ArrowDown') {
              props.setSpread(Number(props.value) - 1);
            }
          }}
        />
      </div>
    </div>
  );
}

export const ShadowDrawer = ({ title, isOpen, close, shadow, handleChange, styleGuide }: ShadowDrawer) => {
  const boxShadowStored = eval(`styleGuide.box_shadow_${shadow === 'x-large' ? 'x_large' : shadow}`);
  const [toggle, setToggle] = useState(
    boxShadowStored?.split(/,\s*-?\d+[a-zA-Z]+|,\s*[a-zA-Z]+/i)?.length === 1 ? 'basic' : 'advanced'
  );
  const [shadowCss, setShadowCss] = useState<string>();
  const [boxShadow, setBoxShadow] = useState<string>();
  const [xOffset, setXOffset] = useState<string>();
  const [yOffset, setYOffset] = useState<string>();
  const [blur, setBlur] = useState<string>();
  const [spread, setSpread] = useState<string>();
  const [hex, setHex] = useState<string>();
  const [opacity, setOpacity] = useState<string>();
  const [rgba, setRGBA] = useState();

  useEffect(() => {
    setToggle(boxShadowStored?.split(/,\s*-?\d+[a-zA-Z]+|,\s*[a-zA-Z]+/i)?.length === 1 ? 'basic' : 'advanced');
  }, [isOpen]);

  useEffect(() => {
    if (shadowCss) {
      setShadowCss(shadowCss.replace(/;+\s*$/i, '').replace(';', ','));
    }
  }, [shadowCss]);

  useEffect(() => {
    if (hex && opacity) {
      const color = colorToRGBA(hex);
      const updatedRGBA = color.replace(/[\d\.]+\)/, `${Number(opacity.replace('%', '')) / 100})`);
      setRGBA(updatedRGBA);
      setBoxShadow(`${xOffset}px ${yOffset}px ${blur}px ${spread}px ${updatedRGBA}`);
    }
  }, [hex, opacity]);

  useEffect(() => {
    setBoxShadow(`${xOffset}px ${yOffset}px ${blur}px ${spread}px ${rgba}`);
  }, [xOffset, yOffset, blur, spread]);

  useEffect(() => {
    if (boxShadowStored) {
      setShadowCss(boxShadowStored);
      setBoxShadow(boxShadowStored.split(/,\s*-?\d+[a-zA-Z]+/i)[0]);
      const boxShadowsArray = shadowToStyles(boxShadowStored);
      setXOffset(boxShadowsArray[0]);
      setYOffset(boxShadowsArray[1]);
      setBlur(boxShadowsArray[2]);
      setSpread(boxShadowsArray[3]);
      setHex(rgbTohex(boxShadowsArray[4]));
      const value = Number((getRGBAOpacity(boxShadowsArray[4]) * 100).toFixed());
      setOpacity(`${value.toString()}%`);
      setRGBA(boxShadowsArray[4]);
      if (!hex) setToggle('advanced');
    }
  }, [boxShadowStored]);

  const handleKeyDown = (e: React.KeyboardEvent) => {
    const value = Number((getRGBAOpacity(shadowToStyles(boxShadow)[4]) * 100).toFixed());
    switch (e.key) {
      case 'Enter':
        const int = Number(opacity.replace('%', ''));
        if (int < 0) {
          setOpacity(`${(0).toString()}%`);
        } else if (int > 100) {
          setOpacity(`${(100).toString()}%`);
        } else {
          setOpacity(`${int.toString()}%`);
        }
        break;
      case 'Escape':
        setOpacity(`${value.toString()}%`);
        break;
      case 'ArrowUp':
        if (value < 100) setOpacity(`${(value + 1).toString()}%`);
        break;
      case 'ArrowDown':
        if (value > 0) setOpacity(`${(value - 1).toString()}%`);
        break;
      default:
    }
  };

  const submitBasic = () => {
    handleChange(
      [`${shadow === 'x-large' ? 'x_large' : shadow}`],
      `${xOffset}px ${yOffset}px ${blur}px ${spread}px ${rgba}`
    );
    close();
  };

  const submitAdvanced = () => {
    handleChange(
      [`${shadow === 'x-large' ? 'x_large' : shadow}`],
      shadowCss.replace(';', ',').replace(/,?;?\s*$/i, '')
    );
    close();
  };

  return (
    <SideDrawer isOpen={isOpen} title={title} close={close}>
      <fieldset>
        <div className={styles.drawerShadowContainer}>
          <span
            className={styles.circle}
            style={{
              boxShadow: toggle === 'basic' ? (boxShadow ? boxShadow : '') : shadowCss ? shadowCss : '',
            }}
          ></span>
          <span className={styles.label}>{shadow === 'x-large' ? 'X Large' : shadow}</span>
        </div>
        <div className={styles.tabs}>
          {['basic', 'advanced'].map((tab) => (
            <>
              <input id={tab} name="toggle" type="radio" checked={toggle === tab} onChange={() => setToggle(tab)} />
              <label htmlFor={tab} onClick={() => setToggle(tab)}>
                {tab}
              </label>
            </>
          ))}
        </div>
        {toggle === 'basic' && (
          <div className={styles.basic}>
            <div className={styles.inputRow}>
              <div>
                <label>Color</label>
                <div className={styles.inputContainer}>
                  <div className={styles.SwatchCheckeredContainer}>
                    <div className={styles.ColorSwatch} style={{ backgroundColor: rgba }} />
                    <SvgIconTransparent className={styles.CheckeredIcon} />
                  </div>
                  <input
                    className={styles.colorValueInput}
                    type="text"
                    name="color"
                    placeholder="Hex color"
                    value={hex}
                    onChange={(e) => setHex(e.target.value)}
                  />
                </div>
              </div>
              <div>
                <label>Opacity</label>
                <div className={styles.inputContainer}>
                  <input
                    className={styles.colorOpacityInput}
                    type="text"
                    name="opacity"
                    placeholder="100%"
                    value={opacity}
                    onChange={(e) => setOpacity(e.target.value)}
                    onKeyDown={handleKeyDown}
                  />
                </div>
              </div>
            </div>
            <div className={styles.inputRow}>
              <ColorOpacityInput label="X offset" name="x-offset" value={xOffset} setSpread={setXOffset} />
              <ColorOpacityInput label="Y offset" name="y-offset" value={yOffset} setSpread={setYOffset} />
            </div>
            <div className={styles.inputRow}>
              <ColorOpacityInput label="Blur radius" name="blur" value={blur} setSpread={setBlur} />
              <ColorOpacityInput label="Spread radius" name="spread" value={spread} setSpread={setSpread} />
            </div>
            <div>
              <input
                type="submit"
                value="Update shadow"
                className="button button-primary button-full"
                onClick={submitBasic}
              />
            </div>
          </div>
        )}
        {toggle === 'advanced' && (
          <div className={styles.advanced}>
            <div className={styles.inputRow}>
              <div>
                <label>Box Shadow CSS (code)</label>
                <div className={styles.inputContainer}>
                  <textarea
                    className={styles.shadowCssInput}
                    placeholder="CSS"
                    value={shadowCss}
                    onChange={(e) => setShadowCss(e.target.value)}
                  />
                </div>
                <p>
                  You may use any valid CSS for the <b>box-shadow</b> property. Separate multiple shadow settings with
                  commas.
                </p>
                <div>
                  <input
                    type="submit"
                    value="Update shadow"
                    className="button button-primary button-full"
                    onClick={submitAdvanced}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </fieldset>
    </SideDrawer>
  );
};
