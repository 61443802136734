import React from 'react';
import { compose } from 'redux';
import withUrlSite from '../../containers/withUrlSite';
import { connect } from 'react-redux';
import * as siteSelectors from '../../reducers/sitesReducer';
import AuthenticatedMediaImg from '../../containers/AuthenticatedMediaImg';
import LogoPlaceholder from '../../assets/images/placeholder-logo-light@2x.png';
import LogoInvertedPlaceholder from '../../assets/images/placeholder-logo-dark@2x.png';

import omit from 'lodash/omit';

function mapStateToProps(state, ownProps) {
  const site = ownProps.site || siteSelectors.selectSiteByDomain(state, ownProps.urlSite);
  const elements = site.elements || {};
  const siteStyles = site.styles || {};

  return {
    logo: elements.logo,
    logo_inverted: elements.logo_inverted,
    siteStyles,
  };
}

function SiteLogo(props) {
  const { logo, logo_inverted, siteStyles, placement, imgProps, site } = props;

  let inverted = false;
  if (props.inverted != null) {
    inverted = props.inverted;
  } else if (placement === 'header') {
    inverted = siteStyles.headerBrightnessTheme === 'dark-bg';
  } else if (placement === 'footer') {
    inverted = siteStyles.footerBrightnessTheme !== 'light-bg';
  }

  let logoSrc;
  if (inverted) logoSrc = logo_inverted && logo_inverted.src;
  else logoSrc = logo && logo.src;

  return logoSrc ? (
    <AuthenticatedMediaImg src={logoSrc} alt="Logo" {...imgProps} />
  ) : (
    site && (
      <div className={`sriracha h1 logo`}>
        <h1>{site.name}</h1>
      </div>
    )
  );
}

export default compose(withUrlSite, connect(mapStateToProps))(SiteLogo);
