import React from 'react';
import { ProductDetailArgs } from '../../../tag-types';

const ProductOptions: React.FunctionComponent<ProductDetailArgs> = ({ product }) => {
  return (
    <div className="product-options">
      {product?.meta?.variations &&
        product?.meta?.variations.map((optionGroup, _idx, variationsArray) => {
          if (
            variationsArray.length === 1 &&
            optionGroup.name === 'Title' &&
            optionGroup.options.length === 1 &&
            optionGroup.options[0].name === 'Default Title'
          )
            return null;
          return (
            <div className="product-option">
              <p className="label">{optionGroup.name}</p>
              <ul className="product-option-values">
                {optionGroup.options.map((value) => (
                  <li className="product-option-value">
                    <a
                      data-product={product.id}
                      data-product-option={optionGroup.name}
                      data-product-option-value={value.description}
                    >
                      {value.description}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          );
        })}

      {!product && (
        <div className="product-option">
          <p className="label">Color:</p>
          <ul className="product-option-values">
            <li className="product-option-value">
              <a>Red</a>
            </li>
            <li className="product-option-value">
              <a>Green</a>
            </li>
            <li className="product-option-value">
              <a>Blue</a>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};

export default ProductOptions;
