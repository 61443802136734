import { put, select, all, takeLatest } from 'redux-saga/effects';
import * as types from '../actions/actionTypes';
import * as actions from '../actions/uiPageSettingsActions';
import { selectProps } from '../reducers/uiPageSettingsReducer';

function* close(action) {
  yield put(actions.close());
}

function* receivePublishError(action) {
  const { pageId, errors } = action.payload;
  yield put(actions.open(pageId, [], errors));
}

function* closeIfPageIdMatches(action) {
  const { pageId } = action.payload;
  const currentProps = yield select(selectProps);

  if (currentProps.pageId === pageId) {
    yield put(actions.close());
  }
}

// Root saga
// ----------------------------------------------------------------------------

function* uiPagesSettingsSaga() {
  yield all([
    takeLatest(types.PAGE_RECEIVE_PUBLISH_SUCCESS, close),
    takeLatest(types.PAGE_RECEIVE_PUBLISH_ERROR, receivePublishError),
    takeLatest(types.PAGE_RECEIVE_CLONE_SUCCESS, close),
    takeLatest(types.PAGE_RECEIVE_UNDELETE_SUCCESS, close),
    takeLatest(types.PAGE_DELETE, closeIfPageIdMatches),
    takeLatest(types.PAGE_RECEIVE_UNPUBLISH_SUCCESS, closeIfPageIdMatches),
  ]);
}

export default uiPagesSettingsSaga;
