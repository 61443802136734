import React from 'react';

import styles from '../../Integrations.module.scss';

export default () => {
  return (
    <div className={styles.failureDialoge}>
      <div>
        <h3>We're sorry, but token verification step failed.</h3>
        <p>Your authentication token is invalid or expired.</p>
      </div>
      <p>
        Please try to <a href="/login">login</a> or try again later
      </p>
    </div>
  );
};
