import { call, put, all, takeLatest, select, StrictEffect } from 'redux-saga/effects';
import * as api from '../services/spark-api';
import * as types from '../actions/actionTypes';
import * as siteLayoutActions from '../actions/siteLayoutActions';
import requireSiteId from './utils/requireSiteId';
import * as siteLayoutSelector from 'reducers/siteLayoutReducer';
import { IComponent } from 'types/Component';
import { ILayout } from 'types/SiteLayout';

function* fetchHeaders(): Generator<StrictEffect, any, { json: { results: Array<IComponent> }; ok: boolean }> {
  const siteId = yield call(requireSiteId as any);
  try {
    const res = yield call(api.getLayoutSections, siteId, 'header');
    if (res.ok) {
      yield put(siteLayoutActions.receiveHeaders(res.json.results));
    }
  } catch (e) {
    console.error(e);
  }
}

function* fetchFooters(): Generator<StrictEffect, any, { json: { results: Array<IComponent> }; ok: boolean }> {
  const siteId = yield call(requireSiteId as any);
  try {
    const res = yield call(api.getLayoutSections, siteId, 'footer');
    if (res.ok) {
      yield put(siteLayoutActions.receiveFooters(res.json.results));
    }
  } catch (e) {
    console.error(e);
  }
}

function* fetchIfNeeded(): Generator<StrictEffect, any, { json: { results: Array<IComponent> }; ok: boolean }> {
  const isLoading = yield select(siteLayoutSelector.selectLayoutIsFetching);
  if (isLoading === null) yield put(siteLayoutActions.fetchSiteLayout());
}

function* fetchLayout(): Generator<StrictEffect, any, { json: { results: Array<ILayout> }; ok: boolean }> {
  const siteId = yield call(requireSiteId as any);
  try {
    const res = yield call(api.getSiteLayout, siteId);
    yield put(siteLayoutActions.siteLayoutReceivedList(res.json.results));
  } catch (e) {
    console.error(e);
  }
}

function* updateLayout(
  action: siteLayoutActions.IUpdateLayout
): Generator<StrictEffect, any, { json: ILayout; ok: boolean }> {
  const siteId = yield call(requireSiteId as any);
  try {
    const res = yield call(api.updateLayout, siteId, action.payload);
    if (res.ok) yield put(siteLayoutActions.siteLayoutReceive(res.json));
  } catch (e) {
    console.error(e);
  }
}

function* createLayout(
  action: siteLayoutActions.ICreateLayout
): Generator<StrictEffect, any, { json: ILayout; ok: boolean }> {
  const siteId = yield call(requireSiteId as any);
  try {
    const res = yield call(api.createLayout, siteId, action.payload);
    if (res.ok) yield put(siteLayoutActions.siteLayoutReceive(res.json));
  } catch (e) {
    console.error(e);
  }
}

function* bulkUpdateOrCreate(
  action: siteLayoutActions.IBulkUpdateOrCreate
): Generator<StrictEffect, any, { json: ILayout; ok: boolean }> {
  const siteId = yield call(requireSiteId as any);
  const itemTypes = Object.keys(action.payload);
  const { payload } = action;
  const apiCalls = [];
  try {
    for (const item of itemTypes) {
      const layout = yield select((state) => siteLayoutSelector.selectLayoutByItemType(item)(state));
      if (!layout && payload[item]) apiCalls.push(call(api.createLayout, siteId, payload[item]));
      else if (
        layout &&
        ((layout as unknown as ILayout).default_header !== payload[item].default_header ||
          (layout as unknown as ILayout).default_footer !== payload[item].default_footer)
      )
        apiCalls.push(call(api.updateLayout, siteId, payload[item]));
    }

    const responses = yield all(apiCalls);
    const results = (responses as unknown as Array<{ json: ILayout; ok: boolean }>).map((res) => {
      if (res.ok) return res.json;
    });
    yield put(siteLayoutActions.siteLayoutReceivedList(results));
  } catch (e) {
    console.error(e);
  }
}

// Root saga
// ----------------------------------------------------------------------------

function* siteLayoutSaga() {
  yield all([
    takeLatest(types.SITE_LAYOUT_FETCH_HEADERS, fetchHeaders),
    takeLatest(types.SITE_LAYOUT_FETCH_FOOTERS, fetchFooters),
    takeLatest(types.SITE_LAYOUT_FETCH, fetchLayout),
    takeLatest(types.SITE_LAYOUT_FETCH_IF_NEEDED, fetchIfNeeded),
    takeLatest(types.SITE_LAYOUT_UPDATE, updateLayout),
    takeLatest(types.SITE_LAYOUT_CREATE, createLayout),
    takeLatest(types.SITE_LAYOUT_BULK_UPDATE_OR_CREATE, bulkUpdateOrCreate),
  ]);
}

export default siteLayoutSaga;
