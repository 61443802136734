/**
 * VideoEmbedBlot
 *
 * Renders any kind of supported video embed. Detects the video type by its
 * smartUrl.
 *
 * The Quill delta looks like this:
 *
 * ```
 * {
 *   "insert": {
 *     "video_embed": {
 *       "src": "youtube:1xxsgnEvEfE"
 *     }
 *   }
 * }
 * ```
 *
 * Current list of supported video types:
 * - Youtube
 *
 */

import Quill from '../quill-1.3.7/quill';
const BlockEmbed = Quill.import('blots/block/embed');

const SOURCES_URL = {
  youtube: 'https://www.youtube.com/embed',
  vimeo: 'https://player.vimeo.com/video',
  wistia: '//fast.wistia.net/embed/iframe',
};

class VideoEmbedBlot extends BlockEmbed {
  static create(value) {
    let node = super.create();

    // Store the raw source (e.g. smartUrl) in the element's dataset so it can
    // be retrieved in the blot's value function.
    const rawSrc = value.src || '';
    node.dataset.rawSrc = rawSrc;

    const match = value.src.match(/^(youtube|vimeo|wistia):(.+)/);
    if (match) {
      const source = match[1];
      const id = match[2];
      const iframe = document.createElement('iframe');
      iframe.setAttribute('src', `${SOURCES_URL[source]}/${id}`);
      iframe.setAttribute('frameborder', '0');
      iframe.setAttribute('allow', 'accelerometer; encrypted-media; gyroscope; picture-in-picture');
      iframe.setAttribute('allowfullscreen', true);
      node.appendChild(iframe);
    }

    return node;
  }

  static value(node) {
    const value = {};

    // Retrieve the raw source (e.g. smartUrl) from the element's dataset.
    value.src = node.dataset.rawSrc;

    return value;
  }
}

VideoEmbedBlot.blotName = 'video_embed';
VideoEmbedBlot.tagName = 'div';
VideoEmbedBlot.className = 'ql-youtube';

export default VideoEmbedBlot;
