/* eslint-disable jsx-a11y/anchor-is-valid */

import React from 'react';
import { compose } from 'redux';
import withSiteProps from '../../../../containers/withSiteProps';
import { connect } from 'react-redux';
import map from 'lodash/map';

import * as siteSelectors from '../../../../reducers/sitesReducer';
import { selectSiteIntegrationsByType } from '../../../../reducers/integrationsReducer';
import * as integrationActions from '../../../../actions/integrationActions';

import styles from './CustomCodes.module.scss';
import { CUSTOM_INTEGRATION_ID } from 'settings';

function mapSiteToProps(site) {
  return {
    siteId: site.id,
    siteName: site.name,
  };
}

function mapStateToProps(state, ownProps) {
  return {
    integrations: selectSiteIntegrationsByType(state, ownProps.siteId, CUSTOM_INTEGRATION_ID),
  };
}

const mapDispatchToProps = {
  deleteIntegration: integrationActions.deleteIntegrationRequest,
};

class CustomCodes extends React.PureComponent {
  handleClickAddNewIntegration = () => {
    this.props.addNewIntegration();
  };

  editIntegration = (integration) => {
    this.props.editIntegration(integration);
  };

  deleteIntegration = (id) => {
    this.props.deleteIntegration(id);
  };

  customIntegrations = (integrations) =>
    map(integrations, (integration) => {
      const { id, config } = integration;

      return (
        <li className={styles.listItem} key={id}>
          <div className={styles.name}>{config.name}</div>
          <label>
            <span className={styles.statusIndicator} />
            Live
          </label>
          <a className={styles.edit} onClick={(e) => this.editIntegration(integration)} role="button">
            Edit
          </a>
          <a
            className={styles.remove}
            onClick={(e) => {
              if (window.confirm('Are you sure you want to delete this integration? This cannot be undone.'))
                this.deleteIntegration(id);
            }}
            role="button"
          >
            Remove
          </a>
        </li>
      );
    });

  render() {
    const { integrations } = this.props;

    const integrationsList = this.customIntegrations(integrations);

    return <div className={styles.CustomCode}>{integrationsList}</div>;
  }
}

export default compose(withSiteProps(mapSiteToProps), connect(mapStateToProps, mapDispatchToProps))(CustomCodes);
