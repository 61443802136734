import { ContainerInfo } from 'components/unstack-components/types';
import React, { isValidElement } from 'react';
import { getItemKey } from './utils';

import omit from 'lodash/omit';

interface OverrideElementProps {
  name: string;
  children: React.ReactNode;
  containerInfo?: ContainerInfo;
  onChange: any;
}

const OverrideElement = (props: OverrideElementProps) => {
  const { name, children, containerInfo, onChange, ...otherProps } = props;
  const relatedKey = containerInfo ? getItemKey(containerInfo) : undefined;

  const discardedKeys = ['onChange', 'componentId'];

  return React.createElement(name, {
    children: React.Children.map(children, (child) => {
      if (typeof child === 'string') {
        return child;
      }
      if (!isValidElement(child)) {
        return null;
      }

      return React.cloneElement(child as React.ReactElement, { containerInfo });
    }),
    'data-related-key': relatedKey,
    ...omit(otherProps, discardedKeys),
  });
};

export default OverrideElement;
