import { call, put, all, takeLatest, select } from 'redux-saga/effects';
import * as api from '../services/spark-api';
import * as types from '../actions/actionTypes';
import * as timezoneActions from '../actions/timezoneActions';
import * as timezoneSelectors from '../reducers/timezoneReducer';

function* requestIfNeeded(action) {
  const isFetching = yield select(timezoneSelectors.selectIsFetching);
  if (isFetching === null) {
    yield put(timezoneActions.request());
  }
}

function* request(action) {
  try {
    const response = yield call(api.getTimezones);
    if (response.ok) yield put(timezoneActions.requestSuccess(response.json.results));
  } catch (e) {
    console.error(e);
  }
}

function* tasksSaga() {
  yield all([
    takeLatest(types.TIMEZONE_REQUEST_ALL_IF_NEEDED, requestIfNeeded),
    takeLatest(types.TIMEZONE_REQUEST_ALL, request),
  ]);
}

export default tasksSaga;
