import * as types from '../actions/actionTypes';
import { createSelector } from 'reselect';

// ----------------------------------------------------------------------------
// Reducer
// ----------------------------------------------------------------------------

export default function (state = {}, action) {
  switch (action.type) {
    case types.COMPONENTS_SAVE_DEFAULTS:
      return { ...state, defaults: action.payload };
    default:
      return state;
  }
}

// ----------------------------------------------------------------------------
// Selectors
// ----------------------------------------------------------------------------

function selectLocalState(state) {
  return state.components;
}

export const selectComponentDefaults = createSelector(selectLocalState, (components) => components.defaults);
