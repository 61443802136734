import React, { useState, useRef } from 'react';
import { createPortal } from 'react-dom';
import { useDispatch } from 'react-redux';

import { usePopper } from 'react-popper';

import useOnClickOutside from 'hooks/useClickOutside';
import { useEscapeKey } from 'hooks/useKeyBinding';

import * as dataTablesActions from 'actions/dataTablesActions';

import { ReactComponent as SvgIconOptions } from '../../../../assets/images/icon-options.svg';

import styles from '../Sheet/Sheet.module.scss';

export default ({ value, id }) => {
  return (
    <div className={styles.slugInput}>
      <span>{value}</span> {id && <PopoverOptions id={id} />}
    </div>
  );
};

//  TODO: Must have a reusable popover dropdown component
const PopoverOptions = ({ id }) => {
  const dispatch = useDispatch();
  const ref = useRef();
  const [opened, setOpened] = useState(false);
  const [reference, setReference] = useState(null);
  useOnClickOutside(ref, () => setOpened(false));
  useEscapeKey(() => setOpened(false));
  const [popper, setPopper] = useState(null);
  const { styles: popperStyles } = usePopper(reference, popper, {
    placement: 'bottom-start',
    modifiers: [{ name: 'offset', options: { offset: [0, 0] } }],
  });
  return (
    <button onClick={() => setOpened(true)} className={styles.options} data-test-id="options">
      <SvgIconOptions />
      {opened && (
        <div>
          <div ref={setReference} />
          {createPortal(
            <div ref={setPopper} style={{ ...popperStyles.popper, zIndex: 3 }}>
              <div ref={ref} className={styles.dropDownMenu} data-test-id="sheet-dropdown-menu">
                <span onClick={() => dispatch(dataTablesActions.deleteRow(id))} data-test-id="delete-row">
                  Delete...
                </span>
              </div>
            </div>,
            document.body
          )}
        </div>
      )}
    </button>
  );
};
