import React from 'react';
import List from '../../react-table/List';
import renderActionDescription from './renderActionDescription';
import { ReactComponent as IconGhost } from '../../../assets/images/icon-ghost.svg';
import styles from './ContactActions.module.scss';

import createIsBefore from 'date-fns/fp/isAfter';
import subMonths from 'date-fns/subMonths';
import createFormatDate from 'date-fns/fp/format';
import orderBy from 'lodash/orderBy';

const formatDateWithYear = createFormatDate('MMM d, yyyy');
const formatDateWithoutYear = createFormatDate("MMM d 'at' p");
const isRecent = createIsBefore(subMonths(new Date(), 8));

export function formatDate(date) {
  return isRecent(date) ? formatDateWithoutYear(date) : formatDateWithYear(date);
}

const columns = [
  {
    id: 'description',
    accessor: (row) => row,
    Cell: ({ value }) => renderActionDescription(value),
    className: styles.description,
  },
  {
    id: 'created_at',
    accessor: (row) => row,
    Cell: ({ value }) => (
      <span className="muted">
        {value.created_at ? formatDate(new Date(value.created_at.replace(' ', 'T'))) : '---'}
      </span>
    ),
    // getProps: () => ({ style: { textAlign: 'right' } }),
    maxWidth: 150,
  },
];

export default function ContactActions(props) {
  const { actions, email, name, sourceId } = props;

  const noHistoryReason = sourceId
    ? `${name} was imported from another system and has not yet interacted with your content.`
    : `${name} was just added to your customers. Check again soon for updates.`;

  const sortedActions = orderBy(actions, [(action) => new Date(action.created_at)], ['desc']);

  return (
    <>
      <div className={styles.container}>
        {actions.length ? (
          <List data={sortedActions} columns={columns} defaultPageSize={20} isSensitive={true} />
        ) : (
          <div className={styles.noActions}>
            <IconGhost />
            <p>
              <label>No customer history</label>
              {noHistoryReason}
            </p>

            <a target="_blank" rel="noopener noreferrer" href={`mailto:${email}`}>
              <input type="button" value={`Message ${name}`} />
            </a>
          </div>
        )}
      </div>
    </>
  );
}
