import React from 'react';
import classNames from 'classnames';

import styles from './IconButton.module.scss';

interface IconButtonProps {
  children: React.ReactNode;
  onClick?: () => void;
  variant?: 'small' | 'medium' | 'large';
}

const IconButton: React.FC<IconButtonProps> = ({ children, variant = 'small', onClick }) => {
  return (
    <span tabIndex={0} className={classNames(styles.iconContainer, styles[variant])} onClick={onClick}>
      {children}
    </span>
  );
};

export default IconButton;
