import { OnMount } from 'hooks/mountUnmountHooks';
import UnstackSplash from './UnstackSplash';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getKlevuRedirectUrl } from 'services/spark-api';
import { useDispatch, useSelector } from 'react-redux';
import * as accountActions from 'actions/accountActions';
import { initializeApp } from 'actions/appActions';
import * as accountSelectors from 'reducers/accountReducer';

export default function KlevuOnboarding() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(accountSelectors.selectIsLoggedIn);

  OnMount(async () => {
    if (isLoggedIn) {
      navigate('/');
    }

    const accountId = searchParams.get('accountId');
    const apiKey = searchParams.get('apiKey');
    const userId = searchParams.get('userId');

    const res = await getKlevuRedirectUrl(accountId, apiKey, userId);
    if (res.status === 200) {
      if (res.json?.platform === 'shopify') {
        navigate('/klevu/shopify', { state: { authorize_url: res.json.authorize_url } });
      } else if (res.json?.platform === 'magento' && res.json?.flow === 'create') {
        navigate('/klevu/magento', { state: res.json });
      } else if (res.json?.platform === 'magento' && res.json?.flow === 'login') {
        dispatch(accountActions.loginSuccess(res.json.token));
        dispatch(initializeApp());
      }
    }
  });
  return <UnstackSplash isLoading />;
}
