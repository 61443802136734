import * as api from './spark-api';

// ----------------------------------------------------------------------------
// Config
// ----------------------------------------------------------------------------

const tokenExpireDuration = 1000 * 60 * 60 * 24 * 7; // 7 days
const tokenRefreshDuration = 1000 * 60 * 60 * 24 * 4; // 4 days

// ----------------------------------------------------------------------------
// API calls to obtain and refresh token
// ----------------------------------------------------------------------------

export function obtainToken(...args) {
  return api.obtainToken(...args).then((response) => {
    if (response.ok && response.json.status === 'approved') storeToken(response.json.token);
    return response;
  });
}

export function obtainJWTAfterVerify(...args) {
  return api.obtainTokenAfterVerification(...args).then((response) => {
    if (response.ok) storeToken(response.json.token);
    return response;
  });
}

export function refreshToken() {
  console.log('refreshing JWT token');

  const oldToken = window.sprkfe.storage.getItem('JWT-TOKEN');
  return api.refreshToken(oldToken).then((response) => {
    if (response.ok) storeToken(response.json.token);
    return response;
  });
}

// ----------------------------------------------------------------------------
// localStorage management to store and clear the token
// ----------------------------------------------------------------------------

export function storeToken(token) {
  try {
    window.sprkfe.storage.setItem('JWT-TOKEN', token);
    window.sprkfe.storage.setItem('JWT-TOKEN-SET-AT', new Date());
  } catch (err) {
    console.error("Couldn't set localStorage.", err);
  }
}

export function clearToken() {
  try {
    window.sprkfe.storage.removeItem('JWT-TOKEN');
    window.sprkfe.storage.removeItem('JWT-TOKEN-SET-AT');
  } catch (err) {
    console.error("Couldn't set localStorage.", err);
  }
}

// ----------------------------------------------------------------------------
// Helpers to check the status of the token (getAuthStatus)
// ----------------------------------------------------------------------------

export function getToken() {
  return window.sprkfe.storage.getItem('JWT-TOKEN');
}

function hasToken() {
  const token = getToken();
  return token != null;
}

function tokenAge() {
  const setTime = new Date(window.sprkfe.storage.getItem('JWT-TOKEN-SET-AT'));
  const now = new Date();

  return now - setTime;
}

function tokenIsFresh() {
  return tokenAge() < tokenExpireDuration;
}

/**
 * Returns an AuthStatus object which represents the current status of the JWT
 * token.
 *
 * @return {object}
 *   - hasValidToken: `true` if localStorage has a JWT token
 *     stored and it was stored recently enough to be valid.
 *   - timeToNextRefresh: Time in miliseconds until the JWT
 *     token should be refreshed.
 *
 * FIXME: 'hasValidToken' may be missleading because it does not perorm a call
 * to the API to actually confirm that the token is valid. It is not the
 * responsibility of this method to make the API call, so we should consider
 * renaming the property.
 */
export function getAuthStatus() {
  const hasValidToken = hasToken() && tokenIsFresh();
  const timeToNextRefresh = tokenRefreshDuration - tokenAge();
  const token = hasValidToken ? getToken() : null;

  return {
    hasValidToken,
    timeToNextRefresh,
    token,
  };
}
