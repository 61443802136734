import React from 'react';
import { Button } from '../Toolbar2';
import classnames from 'classnames';
import styles from './ButtonWithUnderline.module.scss';

export default function ButtonWithUnderline(props) {
  const { className, active, ...restProps } = props;

  return (
    <Button
      className={classnames(className, styles.button, {
        [styles.active]: active,
      })}
      {...restProps}
    />
  );
}
