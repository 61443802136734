/* eslint-disable jsx-a11y/anchor-is-valid */

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectSiteIntegrationsByType } from '../../../reducers/integrationsReducer';
import { receiveUpdate, deleteIntegrationRequest, patch, create } from '../../../actions/integrationActions';
import get from 'lodash/get';
import OAuthIntegrations from './OAuthIntegrations';
import KeyBasedIntegrations from './KeyBasedIntegrations';
import InlineIntegration from './InlineIntegration';
import scrollToElement from 'scroll-to-element';

import styles from '../Integrations.module.scss';
import { OnMount } from 'hooks/mountUnmountHooks';
import { useLocation } from 'react-router-dom';
import withSiteId from 'containers/withSiteId';
import { compose } from 'redux';

type Props = {
  authenticator: any;
  integrationSlug: string;
  showTableContent: boolean;
};

function Integration(props: Props) {
  // @ts-ignore
  const integrations = useSelector((state) => selectSiteIntegrationsByType(state, props.siteId, props.type));
  const integration = get(integrations, 0);
  const location = useLocation();
  const dispatch = useDispatch();

  function updateComponents(payload: Promise<any>) {
    payload
      .then(() => {
        dispatch({
          type: 'READ_RESOURCES_REQUEST',
          resourceType: 'sectionTypes',
          requestKey: 'fetch',
        });
      })
      .catch((err) => {
        console.error('Error:', err);
      });
  }

  const integrationProps = {
    integration,
    receiveUpdate: (props: any) => {
      return dispatch(receiveUpdate(props));
    },
    deleteIntegrationRequest: (props: any) => {
      const request = dispatch(deleteIntegrationRequest(props));
      updateComponents(request.promise);
      return request;
    },
    create: (props: any) => {
      const request = dispatch(create(props));
      updateComponents(request.promise);
      return request;
    },
    patch: (props: any) => {
      const request = dispatch(patch(props));
      updateComponents(request.promise);
      return request;
    },
    location,
  };

  const { authenticator } = props;
  const ref = React.createRef<HTMLLIElement>();

  OnMount(() => {
    if (location.state && location.state.slug === props.integrationSlug) {
      scrollToElement(ref.current, {
        offset: -32,
        align: 'top',
        duration: 500,
      });
    }
  });

  if (!authenticator) {
    return <></>;
  }
  return (
    <li className={styles.integration} ref={ref}>
      {authenticator.authenticator_type === 'api' && authenticator.form_info.form_type === 'inline' && (
        <InlineIntegration {...props} {...integrationProps} />
      )}
      {authenticator.authenticator_type === 'oauth' && <OAuthIntegrations {...props} {...integrationProps} />}
      {authenticator.authenticator_type === 'api' && authenticator.form_info.form_type === 'drawer' && (
        <KeyBasedIntegrations {...props} {...integrationProps} />
      )}
    </li>
  );
}

export default compose(withSiteId)(Integration);
