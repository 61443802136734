import { schema, normalize } from 'normalizr';

// Define schema

const integrations = new schema.Entity('integrations');

const results = {
  results: [integrations],
};

// Normalize & denormalize functions

export function normalizeResponse(response) {
  return normalize(response, results);
}

export function normalizeHubspotForms(response) {
  if (response.json) {
    return Object.assign({}, ...response.json.map((form) => ({ [form.name]: form })));
  }
  return {};
}
