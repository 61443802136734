import { schema, normalize } from 'normalizr';

const normalizeUrlList = (function () {
  // urls
  const url = new schema.Entity('urls');
  const urls = [url];

  return function normalizePageList(data) {
    const normalized = normalize(data, urls);

    return {
      ...normalized,
      entities: {
        ...normalized.entities,
      },
    };
  };
})();

export { normalizeUrlList };
