import React, { isValidElement, useMemo } from 'react';

import Box from '../../../../Editor/Boxes/Box';

import { ContainerInfo, SectionUiHandlersInterface, SectionHandlersInterface } from '../../../types';

import { UElementTagArgs } from 'components/unstack-components/tag-types';
import { getItemKey } from '../../util/utils';
import isNil from 'lodash/isNil';
import classnames from 'classnames';
import PropertiesButton from 'components/Editor/Boxes/Toolbar/PropertiesButton';
import { UBoxInfo, USectionDevices } from 'types/USection';

interface UElementProps extends UElementTagArgs {
  children?: React.ReactChildren;
  sectionUiHandlers?: SectionUiHandlersInterface;
  containerInfo?: ContainerInfo;
  defaults: any;
  sectionHandlers?: SectionHandlersInterface;
  isTextSelected: boolean;
  properties?: { [key: string]: any }[];
  dataRef: UBoxInfo;
  onChange: (content: USectionDevices, key?: string, multi?: boolean) => void;
  content: USectionDevices;
}

const UElement: React.FunctionComponent<UElementProps> = (props) => {
  const {
    dataRef: value = {},
    onChange,
    className,
    children,
    sectionHandlers,
    sectionUiHandlers,
    contentKey,
    containerInfo,
    properties,
    dataRef,
    content,
  } = props;

  const key = containerInfo ? getItemKey(containerInfo, contentKey) : '';
  const ATTR_MAP: { [key: string]: string } = {
    class: 'className',
  };

  const htmlAttrForLayout = useMemo(() => {
    return (
      properties?.length &&
      properties
        ?.filter((property) => property.attribute || dataRef[property.name as keyof UBoxInfo])
        ?.reduce((acc: any, property: any) => {
          const { suffix = '', prefix = '', name, attribute, type } = property;
          const attr = ATTR_MAP[attribute] || attribute;
          const hasValue = !isNil(dataRef?.properties?.[name]);

          let val = hasValue ? dataRef.properties[name] : property.default;
          if (val instanceof Array) {
            val = val.join(' ');
          }
          if (acc[attr]) {
            acc[attr].push(`${prefix}${val}${suffix}`);
          } else {
            acc[attr] = hasValue ? [`${prefix}${val}${suffix}`] : [`${prefix}${val}${suffix}`];
          }

          return acc;
        }, {})
    );
  }, [dataRef.properties, properties]);

  const elClassNames = classnames(...(htmlAttrForLayout?.className || []).flat(), className);

  const renderToolbarItems = () => (
    <div style={{ width: 548 }}>
      <PropertiesButton
        dataRef={dataRef}
        properties={properties}
        onChange={onChange}
        hideIcon={true}
        content={content}
        contentKey={contentKey}
      />
    </div>
  );

  return (
    <Box
      className={elClassNames}
      renderToolbarItems={renderToolbarItems}
      hideTrayTop={true}
      updateOpacity={
        sectionUiHandlers && sectionUiHandlers.shouldApplyOpacity
          ? (value: any) => sectionUiHandlers.shouldApplyOpacity(value)
          : () => {}
      }
      shouldTriggerToolbar={sectionUiHandlers && sectionUiHandlers.isOpen}
      shouldOnlyShowHover={sectionUiHandlers && sectionUiHandlers.isRichTextToolbarActive}
      contentKey={contentKey}
      showBackgroundLayers
      overrideTag={props.tagName}
      {...value}
    >
      {React.Children.map(children, (child) => {
        if (!isValidElement(child)) {
          return null;
        }

        const childProps: any = containerInfo ? { onChange, containerInfo, sectionHandlers } : { sectionHandlers };
        // @ts-ignore
        return React.cloneElement(child as React.ReactElement, childProps);
      })}
    </Box>
  );
};

export default UElement;
