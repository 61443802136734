// An interface for Klaviyo's JavaScript API.
// see https://www.klaviyo.com/docs/getting-started

window._learnq = window._learnq || [];

export function identify(props) {
  window._learnq.push(['identify', props]);
}

export function track(name, props) {
  window._learnq.push(['track', name, props]);
}

// export default { identify, track };
