import React, { useState, useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';

import classNames from 'classnames';
import KeyboardHandledList from '../../../../../base/KeyboardHandledList';

import styles from './Conditions.module.scss';

const VISIT_TYPES = ['any', 'first', 'previous', 'current'];

const UTMs = ['source', 'medium', 'campaign', 'term', 'content'];

interface Props {
  setUtm: (arg0: string) => void;
  setVisit: (arg0: string) => void;
  visit: string | undefined;
  utm: string | undefined;
  isSectionActive: boolean;
}

export default function (props: Props) {
  const [isOpen, setIsOpen] = useState<string>('');
  const [hovered, setHovered] = useState(undefined);
  const [temporaryUtm, setTemporaryUtm] = useState(undefined);
  const [temporaryVisit, setTemporaryVisit] = useState(undefined);
  const [cursor, setCursor] = useState(0);

  useEffect(() => {
    if (isOpen === 'utm' && hovered) {
      setCursor(UTMs.indexOf(hovered));
    }
    if (isOpen === 'visit' && hovered) {
      setCursor(VISIT_TYPES.indexOf(hovered));
    }
  }, [hovered]);

  useEffect(() => {
    if (isOpen === 'utm') {
      setTemporaryUtm(UTMs[cursor]);
    }
    if (isOpen === 'visit') {
      setTemporaryVisit(VISIT_TYPES[cursor]);
    }
  }, [cursor]);

  const handleOpenUTM = () => {
    setIsOpen('utm');
    setCursor(UTMs.indexOf(props.utm) !== -1 ? UTMs.indexOf(props.utm) : 0);
  };

  const handleOpenVisit = () => {
    setIsOpen('visit');
    setCursor(VISIT_TYPES.indexOf(props.visit) !== -1 ? VISIT_TYPES.indexOf(props.visit) : 0);
  };

  return (
    <>
      <div className={classNames(styles.inputContainer, styles.isDropdown)}>
        <input
          autoFocus={props.isSectionActive}
          onChange={(_e) => {}}
          className={styles.utm}
          value={temporaryUtm || props.utm || ''}
          type="text"
          placeholder="UTM"
          onFocus={(e) => {
            // @ts-ignore
            if (isOpen !== 'utm' && e?.nativeEvent?.sourceCapabilities) handleOpenUTM();
          }}
          onBlur={() => {
            setIsOpen('');
            setTemporaryUtm(undefined);
          }}
          onClick={() => {
            handleOpenUTM();
          }}
          onKeyDown={(e) => {
            if (e.keyCode === 13) {
              if (isOpen === 'utm') {
                props.setUtm(UTMs[cursor]);
                setIsOpen('');
                setTemporaryUtm(undefined);
              } else handleOpenUTM();
              // props.setUtm(UTMs[cursor]);
              //  @ts-ignore
              // e.target.blur();
            } else if (e.keyCode === 9) {
              if (isOpen === 'utm' && UTMs[cursor]) {
                props.setUtm(UTMs[cursor]);
                setTemporaryUtm(undefined);
              }
            } else if (e.keyCode === 27) {
              setIsOpen('');
              setTemporaryUtm(undefined);
            } else if (e.keyCode === 32) {
              if (isOpen !== 'utm') handleOpenUTM();
            } else if (e.keyCode === 35) {
              if (isOpen === 'utm') {
                e.preventDefault();
                setCursor(UTMs.length - 1);
              }
            } else if (e.keyCode === 36) {
              if (isOpen === 'utm') {
                e.preventDefault();
                setCursor(0);
              }
            } else if (e.keyCode === 38) {
              if (isOpen !== 'utm') handleOpenUTM();
              else if (cursor > 0) setCursor(cursor - 1);
              else e.preventDefault();
            } else if (e.keyCode === 40) {
              if (isOpen !== 'utm') handleOpenUTM();
              else if (cursor < UTMs.length - 1) setCursor(cursor + 1);
              else e.preventDefault();
            }
          }}
        />
        <CSSTransition in={isOpen === 'utm'} timeout={141.59} classNames={styles} mountOnEnter unmountOnExit>
          <div className={classNames(styles.Drawer, styles.operator)} data-test-id="operator-input-drawer">
            <KeyboardHandledList
              items={UTMs}
              cursor={cursor}
              setHovered={setHovered}
              onChange={props.setUtm}
              setIsOpen={setIsOpen}
            />
          </div>
        </CSSTransition>
      </div>
      <div className={classNames(styles.inputContainer, styles.isDropdown)}>
        <input
          onChange={(_e) => {}}
          className={styles.operator}
          value={temporaryVisit || props.visit || ''}
          type="text"
          placeholder="Visit"
          onFocus={() => {
            if (isOpen !== 'visit') handleOpenVisit();
          }}
          onBlur={() => {
            setIsOpen('');
            setTemporaryVisit(undefined);
          }}
          onClick={() => {
            handleOpenVisit();
          }}
          onKeyDown={(e) => {
            if (e.keyCode === 13) {
              if (isOpen === 'visit') {
                props.setVisit(VISIT_TYPES[cursor]);
                setIsOpen('');
                setTemporaryVisit(undefined);
              } else handleOpenVisit();
            } else if (e.keyCode === 9 && VISIT_TYPES[cursor]) {
              if (isOpen === 'visit') {
                props.setVisit(VISIT_TYPES[cursor]);
                setTemporaryVisit(undefined);
              }
            } else if (e.keyCode === 27) {
              setIsOpen('');
              setTemporaryVisit(undefined);
            } else if (e.keyCode === 32) {
              if (isOpen !== 'visit') handleOpenVisit();
            } else if (e.keyCode === 35) {
              if (isOpen === 'visit') {
                e.preventDefault();
                setCursor(VISIT_TYPES.length - 1);
              }
            } else if (e.keyCode === 36) {
              if (isOpen === 'visit') {
                e.preventDefault();
                setCursor(0);
              }
            } else if (e.keyCode === 38) {
              if (isOpen !== 'visit') handleOpenVisit();
              else if (cursor > 0) setCursor(cursor - 1);
              else e.preventDefault();
            } else if (e.keyCode === 40) {
              if (isOpen !== 'visit') handleOpenVisit();
              else if (cursor < VISIT_TYPES.length - 1) setCursor(cursor + 1);
              else e.preventDefault();
            }
          }}
        />
        <CSSTransition in={isOpen === 'visit'} timeout={141.59} classNames={styles} mountOnEnter unmountOnExit>
          <div className={classNames(styles.Drawer, styles.operator)} data-test-id="operator-input-drawer">
            <KeyboardHandledList
              items={VISIT_TYPES}
              cursor={cursor}
              setHovered={setHovered}
              onChange={props.setVisit}
              setIsOpen={setIsOpen}
            />
          </div>
        </CSSTransition>
      </div>
    </>
  );
}
