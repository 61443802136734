import { applyMiddleware, compose } from 'redux';
import { legacy_createStore as createStore } from 'redux';

import createSagaMiddleware from 'redux-saga';
import { thunk } from 'redux-thunk';
import rootSaga from './sagas/rootSaga';
import rootReducer from 'reducers/reducers';
import isLocalStorageAllowed from 'lib/isLocalStorageAllowed';

// Assemble middlewares
const middlewares = [];

// Apply redux-thunk
middlewares.push(thunk);

// Apply saga middleware
const sagaMiddleware = createSagaMiddleware();
middlewares.push(sagaMiddleware);

// Configure redux-logger
(window as any).sprkfe = (window as any).sprkfe || {};
(window as any).sprkfe.storage = isLocalStorageAllowed() ? localStorage : new Storage();
// window.sprkfe.setReduxLoggerConfig = function (config) {
//   if (config) window.sprkfe.storage.setItem('reduxLoggerConfig', JSON.stringify(config));
//   else window.sprkfe.storage.removeItem('reduxLoggerConfig');
//   window.location.reload();
// };
// window.sprkfe.getReduxLoggerConfig = function (config) {
//   const rawConfig = window.sprkfe.storage.getItem('reduxLoggerConfig');
//   if (rawConfig) return JSON.parse(rawConfig);
// };
// const reduxLoggerConfig = window.sprkfe.getReduxLoggerConfig();
// if (reduxLoggerConfig) {
//   const { grep, ...config } = reduxLoggerConfig;

//   if (grep) {
//     const regexp = new RegExp(...grep);
//     config.predicate = (getState, action) => regexp.test(action.type);
//   }

//   if (config.diff) {
//     console.warn(
//       'Redux logger diff is set to true. This is useful for debugging Redux actions, but can significantly harm performance.'
//     );
//   }

//   const logger = createLogger(config);
//   middlewares.push(logger);
// }

// const reduxDevtoolsCompose = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
// const isDev = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';
// const reduxFuncs = reduxDevtoolsCompose && isDev ? [reduxDevtoolsCompose()] : [];

// Create Store
const store = createStore(rootReducer, undefined, compose(applyMiddleware(...middlewares)));

// Run sagas
sagaMiddleware.run(rootSaga);

// Subscribe to store changes in order to save state from local storage
// store.subscribe(throttle(() => {
//   saveState(store.getState());
// }, 1000));

// window.sprkfe = window.sprkfe || {};
// window.sprkfe.store = store;

export default store;

// function loadState() {
//   try {
//     const serializedState = localStorage.getItem('state-v1');
//     if (serializedState == null) {
//       return undefined;
//     }
//     return JSON.parse(serializedState);
//   } catch (err) {
//     return undefined;
//   }
// }
//
// function saveState(state) {
//   try {
//     const serializedState = JSON.stringify(state);
//     localStorage.setItem('state-v1', serializedState);
//   } catch (err) {
//     console.warn('Cannot save state to localStorage')
//   }
// }
