import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import classnames from 'classnames';

import Loading from './ThemeSelection/Loading';

import { ReactComponent as SvgIconPreview } from '../../assets/images/icon-live-preview.svg';
import { ReactComponent as SvgIconCheckmark } from '../../assets/images/icon-checked-circle.svg';

import { updateLocalAccountMeta } from '../../actions/accountActions';

import styles from './ThemeSelection.module.scss';

export const THEMES_RECOMMENDATION_MAP = {
  software: 'polygon',
  ecommerce: 'coach',
  'agency/consultant': 'koali',
};

export default (props: any) => {
  const dispatch = useDispatch();

  useEffect(() => {
    props.updateRecommendedTheme();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const [isLoading, setIsLoading] = useState(false);

  if (isLoading) return <Loading />;
  return (
    <>
      <div className={styles.container}>
        <h2>Choose your theme</h2>
        <p>Each theme comes with templates to help you get started.</p>
      </div>
      {props.availableThemes &&
        props.availableThemes.map((theme: any) => (
          <ThemeCard
            key={theme.id}
            theme={theme}
            isRecommended={props.recommendedTheme && props.recommendedTheme.id === theme.id}
            onSelect={(theme: any) => {
              const promise = new Promise((resolve, reject) => {
                setIsLoading(true);

                props.createSite({
                  account: props.accountId,
                  domain: props.domain,
                  theme,
                  resolve,
                  reject,
                });
              });
              promise
                .then(() => {
                  dispatch(
                    updateLocalAccountMeta({
                      onboarding_step: 3,
                    })
                  );
                  // props.history.push('/');
                  window.location.href = '/';
                })
                .catch((error) => console.log('Error', error));
            }}
          />
        ))}
    </>
  );
};

const ThemeCard = ({
  theme: { thumbnail, benefits, description, name, id, subdomain },
  onSelect,
  isRecommended,
}: any) => (
  <div className={styles.cardContainer}>
    <div className={classnames(styles.thumbnailContainer, styles[name.toLowerCase()])}>
      <div className={styles.imageFrame}>
        <a href={`https://${subdomain.toLowerCase()}.unstack.website/`} target="_blank" rel="noopener noreferrer">
          <img src={thumbnail} alt="" />
          <label>
            <SvgIconPreview height="14" width="14" />
            Live preview
          </label>
        </a>
      </div>
    </div>
    <div className={styles.aboutTheme}>
      <div>
        <h2>{name}</h2>
        {isRecommended && <label>Recommended for you</label>}
      </div>
      <p>{description}</p>
      <div className={styles.listContainer}>
        {benefits &&
          benefits.map((item: any, i: number) => (
            <div key={i} className={styles.listItem}>
              <SvgIconCheckmark height="20" width="20" />
              <span>{item}</span>
            </div>
          ))}
      </div>
      <input
        type="submit"
        aria-label="submit"
        className="button button-success button-full"
        value={`Start with ${name.charAt(0).toUpperCase() + name.slice(1)}`}
        onClick={() => onSelect(id)}
      />
    </div>
  </div>
);
