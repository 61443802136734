import React from 'react';
import { Action } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { HashLink as Link } from 'react-router-hash-link';

import { selectNotificationBySiteDomain, NOTIFICATIONS } from '../reducers/notificationReducer';
import * as accountUsersSelector from 'reducers/accountUsersReducer';
import { selectHasPermission } from 'reducers/policyReducer';
import * as uiDomainSettingsActions from '../actions/uiDomainSettingsActions';
import * as accountUserActions from '../actions/accountUserActions';
import * as uiSubscriptionManagerActions from '../actions/uiSubscriptionManagerActions';

import styles from './NotificationBar.module.scss';
import classnames from 'classnames';

import { ReactComponent as SvgIconActionRequired } from '../assets/images/icon-action-required.svg';
import { ReactComponent as SvgIconUpgradeSuccess } from '../assets/images/icon-upgrade-success.svg';
import { ReactComponent as SvgIconUpgrade } from '../assets/images/icon-upgrade.svg';
import { ReactComponent as SvgIconChevron } from '../assets/images/icon-cheveron-left.svg';
import { useParams } from 'react-router-dom';

function NotificationBar() {
  const { urlSite } = useParams();
  const dispatch = useDispatch();
  const notificationByDomain = useSelector((state) => selectNotificationBySiteDomain(state, urlSite));
  const accountOwnerStatus = useSelector(accountUsersSelector.selectOwnerStatus);
  const emailStatus = useSelector(accountUsersSelector.selectVerificationEmailStatus);
  const hasPermission = useSelector(selectHasPermission);

  const getNotification = () => {
    if (accountOwnerStatus === 'inactive') {
      return emailStatus
        ? {
            message: `Verification email sent!`,
            msgClass: 'success',
          }
        : {
            message: `Please check your inbox and verify your email address.`,
            msgClass: 'primary',
            event: (
              <input
                type="button"
                value="Re-send email"
                readOnly
                className={styles.eventButton}
                onClick={() => dispatch(accountUserActions.resendVerificationEmail())}
              />
            ),
          };
    }
    switch (notificationByDomain) {
      case NOTIFICATIONS.SUBSCRIPTION_EXPIRED:
        return {
          message: `Restart your subscription to reconnect to your site.`,
          msgClass: 'warning',
          action: 'settings#subscription',
          icon: <SvgIconActionRequired className={styles.icon} />,
        };
      case NOTIFICATIONS.DOMAIN_PENDING:
        if (hasPermission('Site:set_domain'))
          return {
            message: `Connect a custom domain to get your site live.`,
            msgClass: 'success',
            // call: 'openDomainSettings',
            action: 'settings#domain',
            icon: <SvgIconUpgradeSuccess className={styles.icon} />,
          };
        break;
      case NOTIFICATIONS.SUBSCRIPTION_PENDING:
        const message = hasPermission('Site:add_ssl')
          ? 'Upgrade to a paid plan to connect SSL.'
          : 'Upgrade to get your site live.';
        return {
          message: message,
          msgClass: 'info',
          call: 'openSubscriptionManager',
          icon: <SvgIconUpgrade className={styles.icon} />,
        };
    }
    return null;
  };
  const notification = getNotification();

  const doClick = (call: string) => {
    switch (call) {
      case 'openDomainManager':
        dispatch(uiDomainSettingsActions.open({}) as Action);
        break;
      case 'openSubscriptionManager':
        dispatch(uiSubscriptionManagerActions.open({}) as Action);
        break;
      case 'resendVerifyEmail':
        dispatch(accountUserActions.resendVerificationEmail());
        break;
    }
  };
  if (notification && notification.message) {
    const notificationClass = classnames(styles.Notification, styles[notification.msgClass], {
      [styles.Link]: notification.action,
      [styles.Call]: notification.call,
    });

    const Wrapper = (props: any) => {
      if (notification.action) {
        return <Link to={`/${urlSite}/${notification.action}`} {...props} />;
      }

      if (notification.call) {
        return (
          <div
            onClick={() => {
              doClick(notification.call);
            }}
            {...props}
          />
        );
      }

      return <div {...props} />;
    };

    return (
      <>
        <Wrapper className={notificationClass}>
          {notification.icon}
          {notification.message}
          {notification.event}
          <SvgIconChevron className={styles.chevron} />
        </Wrapper>
        <div className={styles.NotificationSubstrate} />
      </>
    );
  }
  return null;
}

export default NotificationBar;
