import React from 'react';

import styles from './StyleGuideEditor.module.scss';

interface LetterSpacingFieldProps {
  name: string;
  value: string;
  handleChange: (value: string) => void;
}

const LetterSpacingField = ({ value, handleChange, name }: LetterSpacingFieldProps) => (
  <div className={styles.styleOption}>
    <span>Letter Spacing</span>
    <input
      type="number"
      name={name}
      value={value && value.includes('px') ? Number(value.split('px')[0]) : 0}
      max="10"
      min="-10"
      step="0.1"
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
        const value = Number(e.target.value);

        if (Number.isNaN(value)) {
          return;
        }

        handleChange(`${value}px`);
      }}
    />
  </div>
);

export default LetterSpacingField;
