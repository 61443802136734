import React, { useState, useEffect, useRef, useMemo } from 'react';
import ReactDOM from 'react-dom';
import { batch } from 'react-redux';
import Quill from '../';

import { Toolbar2 as Toolbar, Button, ButtonGroup } from '../../../components/Editor/Toolbars2/Toolbar2';
import FontPicker from './FontPicker';
import SizePicker from './SizePicker';
import HeaderPicker from './HeaderPicker';

import {
  IconRteBold,
  IconRteBulletList,
  IconRteChecklist,
  IconRteCode,
  IconRteHighlight,
  IconRteItalic,
  IconRteNumberedList,
  IconRteQuote,
  IconAlignLeft,
  IconAlignCenter,
  IconAlignRight,
  IconRteClearFormat,
  IconRteColor,
  IconRteStrikeThrough,
} from '../../../components/Editor/Toolbars2/icons';

import { CSSTransition } from 'react-transition-group';
import LinkButton from './QuillToolbar/LinkButton';
import AnchorButton from './QuillToolbar/AnchorButton';

import getDocumentOffset, { getIFrameDocumentOffset } from '../../get-document-offset';

import styles from './QuillToolbar.module.scss';
import ColorPickerNew from 'components/Editor/Toolbars2/components/ColorPickerNew';
import { isDeviceMode } from 'types/LegacyEditor';
import isBlogPage from 'components/Editor/ArticleFooter/isBlogPage';

const BLOCK_FORMATS = ['blockquote', 'header', 'indent', 'list', 'align', 'direction', 'code-block', 'id-attribute'];

function isBlockFormat(format: string) {
  return BLOCK_FORMATS.some((f) => f === format);
}

function QuillToolbar(props: any) {
  const { quill, hideAlignButtons, siteStyles, isBlog } = props;
  const [formats, setFormats] = useState<{ [key: string]: any }>({});
  const [lastRange, setLastRange] = useState<{ index: number; length: number }>();
  const [bounds, setBounds] = useState<{ left: number; right: number; top: number; bottom: number; width: number }>();
  const toolbarRef = useRef<HTMLDivElement>();
  const deviceMode = !(isBlog || isBlogPage()) && isDeviceMode();
  const iframeDocument = (document.getElementById('editorIframe') as HTMLIFrameElement)?.contentWindow?.document;
  const [iframeScroll, setIframeScroll] = useState<number>();

  if (deviceMode && iframeDocument && iframeScroll === undefined) {
    setIframeScroll(0);
    iframeDocument.addEventListener('scroll', () => {
      setIframeScroll(iframeDocument?.body?.scrollTop);
    });
  }

  let headerCount: number;
  if (isBlog) {
    if (siteStyles.typography.blog_header_count) {
      headerCount = siteStyles.typography.blog_header_count;
    } else {
      headerCount = 3;
    }
  } else {
    if (siteStyles.typography.header_count) {
      headerCount = siteStyles.typography.header_count;
    } else {
      headerCount = 3;
    }
  }

  // isOpen state management
  // --------------------------------------------------------------------------

  const [hasSelection, setHasSelection] = useState(false);
  const [hasFocus, setHasFocus] = useState(false);
  const isOpen = !!(hasSelection || hasFocus);

  // When the toolbar is open, attach click, touch, and focus event handlers on
  // the  `document`. If the event target is inside of the toolbar, then the
  // toolbar has "direct focus". If it is outside the toolbar, then it does not.
  useEffect(() => {
    if (!isOpen) return;

    function handler(e: any) {
      if (toolbarRef!.current.contains(e.target)) setHasFocus(true);
      else setHasFocus(false);
    }

    const doc = deviceMode ? iframeDocument : document;

    doc.addEventListener('mousedown', handler, false);
    doc.addEventListener('touchstart', handler, false);
    doc.addEventListener('focusin', handler, false);

    return () => {
      doc.removeEventListener('mousedown', handler, false);
      doc.removeEventListener('touchstart', handler, false);
      doc.removeEventListener('focusin', handler, false);
    };
  }, [isOpen, setHasFocus, toolbarRef, iframeDocument]);

  // Quill editor initialization
  // --------------------------------------------------------------------------

  useEffect(() => {
    if (!quill) return;

    // Watch quill editor for all changes so we can monitor the current
    // selection and its formatting.
    const handleChange = (eventType: any, ...args: any[]) => {
      // Batch all state changes into one render
      batch(() => {
        // If the selection has changed...
        // @ts-ignore
        if (eventType === Quill.events.SELECTION_CHANGE) {
          const [newRange] = args;

          // If there's a range update component state with the latest quill
          // editor state.
          if (newRange) {
            setFormats(quill.getFormat(newRange));
            setBounds(quill.getBounds(newRange));
            setLastRange(newRange);
          }

          // If the range length is greater than 0, that means that the user has
          // selected text, so show the toolbar.
          if (newRange && newRange.length) setHasSelection(true);
          else setHasSelection(false);
        }

        // Otherwise it was a text change, so get the updated format from the
        // last selected range we had.
        else {
          setFormats(quill.getFormat(lastRange));
        }
      });
    };

    // @ts-ignore
    quill.on(Quill.events.EDITOR_CHANGE, handleChange);
    // @ts-ignore
    return () => quill.off(Quill.events.EDITOR_CHANGE, handleChange);
  }, [quill, setHasSelection, lastRange]);

  // Format handlers
  // --------------------------------------------------------------------------

  function applyFormat(format: string, value: string | number | boolean) {
    if (isBlockFormat(format)) {
      // @ts-ignore
      quill.formatLine(lastRange.index, lastRange.length, format, value, Quill.sources.USER);
    } else {
      // @ts-ignore
      quill.format(format, value, Quill.sources.USER);
    }
  }

  const handleFormatValue = (format: string, value: string | number | boolean) => () => {
    const newValue = formats[format] === value ? false : value;
    applyFormat(format, newValue);
  };

  function createFormatValueButton(format: string, value: string | number | boolean, Icon?: any) {
    if (format === 'font') {
      const currentValue = formats[format] || value;
      return (
        <Button>
          <FontPicker
            onSelect={(value: string) => {
              applyFormat(format, value);
            }}
            fonts={siteStyles.fontFamilies}
            value={currentValue}
          />
        </Button>
      );
    } else if (format === 'header') {
      const currentValue = formats[format] || value;
      return (
        <Button>
          <HeaderPicker
            onSelect={(value) => {
              applyFormat(format, value);
            }}
            headerCount={headerCount}
            value={currentValue}
            isBlog={isBlog}
          />
        </Button>
      );
    } else if (format === 'color') {
      const currentValue = formats[format] || value;
      return <ColorPickerNew onChange={(value) => applyFormat(format, value)} color={currentValue} isQuill />;
    } else if (format === 'size') {
      const customValue = formats[format];
      const defaultValue = Number(siteStyles.paragraph_font_size || '1.6');
      let currentValue = customValue || value;
      if (currentValue && currentValue.replace) currentValue = currentValue.replace('rem', '');
      currentValue = Number(currentValue);

      return (
        <SizePicker
          format={format}
          applyFormat={applyFormat}
          currentValue={currentValue}
          customValue={Boolean(customValue)}
          defaultValue={defaultValue}
        />
      );
    } else {
      const isActive = value === false ? !formats[format] : formats[format] === value;

      return (
        <Button
          onClick={handleFormatValue(format, value)}
          style={
            format === 'highlight'
              ? { width: '40px', padding: '9px' }
              : format === 'blockquote'
              ? { width: '40px', padding: '13px' }
              : format === 'code-block'
              ? { width: '40px', padding: '11px' }
              : {}
          }
        >
          <Icon active={isActive} />
        </Button>
      );
    }
  }

  const handleRemoveFormat = () => {
    // @ts-ignore
    quill.removeFormat(lastRange.index, lastRange.length, Quill.sources.USER);
  };

  // Calculate toolbar position
  // --------------------------------------------------------------------------

  const pageWidth = usePageWidth(isOpen);
  const { left, top } = useMemo(() => {
    if (isOpen && toolbarRef.current && quill) {
      const windowPadding = 10;

      const containerOffset = deviceMode
        ? getIFrameDocumentOffset(quill.container)
        : getDocumentOffset(quill.container);
      const toolbarHeight = toolbarRef.current.offsetHeight;
      const toolbarWidth = toolbarRef.current.offsetWidth;
      const rawLeft = containerOffset.left + bounds.left + bounds.width / 2 - toolbarWidth / 2;

      return {
        top: containerOffset.top + bounds.top - toolbarHeight,
        left: Math.max(windowPadding, Math.min(pageWidth - toolbarWidth - windowPadding, rawLeft)),
      };
    }

    return { left: undefined, top: undefined };
  }, [isOpen, toolbarRef.current, quill, pageWidth, bounds, iframeScroll]);

  // Render
  // --------------------------------------------------------------------------

  const [, setIsEntering] = useState<boolean>();

  const rendered = (
    <CSSTransition
      in={isOpen}
      classNames={styles}
      timeout={{
        appear: 0,
        enter: 200,
        exit: 150,
      }}
      mountOnEnter
      unmountOnExit
      onEntering={() => setIsEntering(true)}
      onEntered={() => setIsEntering(false)}
    >
      <div className={`${styles.container} quill-toolbar`} style={{ top, left }} ref={toolbarRef}>
        <Toolbar>
          <ButtonGroup>{createFormatValueButton('header', '')}</ButtonGroup>
          <ButtonGroup>{createFormatValueButton('font', '')}</ButtonGroup>
          <ButtonGroup>{createFormatValueButton('size', '')}</ButtonGroup>
          <ButtonGroup>
            {createFormatValueButton('color', '')}
            {createFormatValueButton('bold', true, IconRteBold)}
            {createFormatValueButton('italic', true, IconRteItalic)}
            {createFormatValueButton('strike', true, IconRteStrikeThrough)}
          </ButtonGroup>
          {!hideAlignButtons && (
            <ButtonGroup>
              {createFormatValueButton('align', 'justify', IconAlignLeft)}
              {createFormatValueButton('align', 'center', IconAlignCenter)}
              {createFormatValueButton('align', 'right', IconAlignRight)}
            </ButtonGroup>
          )}
          {createFormatValueButton('highlight', true, IconRteHighlight)}
          {createFormatValueButton('blockquote', true, IconRteQuote)}
          {createFormatValueButton('code-block', true, IconRteCode)}
          <ButtonGroup>
            {createFormatValueButton('list', 'checked', IconRteChecklist)}
            {createFormatValueButton('list', 'bullet', IconRteBulletList)}
            {createFormatValueButton('list', 'ordered', IconRteNumberedList)}
          </ButtonGroup>
          <Button onClick={handleRemoveFormat} style={{ width: '40px', padding: '12px' }}>
            <IconRteClearFormat />
          </Button>
          <AnchorButton quill={quill} formats={formats} applyFormat={applyFormat} />
          <LinkButton quill={quill} formats={formats} applyFormat={applyFormat} />
        </Toolbar>
      </div>
    </CSSTransition>
  );

  return document?.body ? ReactDOM.createPortal(rendered, document?.body) : <></>;
}

export default React.memo(QuillToolbar);

// Return the document body width. If `shouldUpdate` is true, then update the
// value when the window resizes.
function usePageWidth(shouldUpdate: boolean) {
  const [pageWidth, setPageWidth] = useState(document.body.offsetWidth);

  useEffect(() => {
    if (!shouldUpdate) return;

    function handler(e: any) {
      setPageWidth(document.body.offsetWidth);
    }

    window.addEventListener('resize', handler, false);
    return () => window.removeEventListener('resize', handler, false);
  }, [shouldUpdate, setPageWidth, document]);

  return pageWidth;
}
