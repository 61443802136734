import * as actionTypes from './actionTypes';

export interface RequestDrawerOpen {
  type: 'TOOLBAR_SET_DRAWER_OPEN';
}
export function requestDrawerOpen() {
  return {
    type: actionTypes.TOOLBAR_SET_DRAWER_OPEN,
  };
}

export interface RequestDrawerClose {
  type: 'TOOLBAR_SET_DRAWER_CLOSE';
}
export function requestDrawerClose() {
  return {
    type: actionTypes.TOOLBAR_SET_DRAWER_CLOSE,
  };
}

export interface SetActiveToolbar {
  type: 'TOOLBAR_ACTIVE_CHANGED';
  activeToolbar: string;
}
export function setActiveToolbar(activeToolbar: string) {
  return {
    type: actionTypes.TOOLBAR_ACTIVE_CHANGED,
    activeToolbar,
  };
}

export interface SetActiveSection {
  type: 'SECTION_ACTIVE_CHANGED';
  activeSection: string;
}
export function setActiveSection(activeSection: string) {
  return {
    type: actionTypes.SECTION_ACTIVE_CHANGED,
    activeSection,
  };
}

export interface SetHoverToolbar {
  type: 'TOOLBAR_HOVER_CHANGED';
  hoverToolbar: string;
}
export function setHoverToolbar(hoverToolbar: string) {
  return {
    type: actionTypes.TOOLBAR_HOVER_CHANGED,
    hoverToolbar,
  };
}

export interface SetCursorOnToolbar {
  type: 'TOOLBAR_CURSOR_ON_CHANGED';
  cursorOnToolbar: string;
}
export function setCursorOnToolbar(cursorOnToolbar: boolean) {
  return {
    type: actionTypes.TOOLBAR_CURSOR_ON_CHANGED,
    cursorOnToolbar,
  };
}

export interface SetActionsMenuExpanded {
  type: 'TOOLBAR_ACTIONS_MENU_EXPANDED';
  expandedUUID?: string;
}
export function setActionsMenuExpanded(expandedUUID?: string) {
  return {
    type: actionTypes.TOOLBAR_ACTIONS_MENU_EXPANDED,
    expandedUUID,
  };
}

export type ToolbarActions =
  | RequestDrawerOpen
  | RequestDrawerClose
  | SetActiveToolbar
  | SetHoverToolbar
  | SetCursorOnToolbar
  | SetActionsMenuExpanded
  | SetActiveSection;
