import * as types from './actionTypes';

export const updateSubmittingState = (payload, eventDispatcher) => ({
  type: types.BUTTONS_SUBMITTING_STATE,
  payload,
  event: eventDispatcher,
});

export const resetSubmittingState = () => ({
  type: types.BUTTONS_RESET_STATE,
});
