import React, { memo } from 'react';

import { JsonParserTagArgs } from 'components/unstack-components/tag-types';
import { SectionHandlersInterface, SectionUiHandlersInterface } from 'components/unstack-components/types';

interface JsonParserProps extends ValueOnChangeProps<Array<Object>>, JsonParserTagArgs {
  children?: React.ReactChildren;
  sectionHandlers?: SectionHandlersInterface;
  sectionUiHandlers?: SectionUiHandlersInterface;
}

const JsonParser = (props: JsonParserProps) => {
  const { sectionHandlers, sectionUiHandlers, onChange, item, dataRef } = props;

  const childProps = {
    onChange,
    sectionHandlers,
    sectionUiHandlers,
  };

  try {
    return React.cloneElement(item(JSON.parse(dataRef)), childProps);
  } catch (e) {
    return React.cloneElement(item(dataRef), childProps);
  }
};

export default memo(JsonParser);
