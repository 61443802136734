import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { requestList, requestListIfNeeded } from '../actions/pageActions';

export default function usePages(forceRequest) {
  const dispatch = useDispatch();
  const forceRequestBool = !!forceRequest;

  useEffect(() => {
    if (forceRequestBool) dispatch(requestList());
    else dispatch(requestListIfNeeded());
  }, [dispatch, forceRequestBool]);
}
