import React from 'react';

import { SectionTagArgs } from 'components/unstack-components/tag-types';
import withSite from 'containers/withSite';
import { SectionHandlersInterface, SectionUiHandlersInterface } from 'components/unstack-components/types';
import { USection, USectionDevices } from 'types/USection';
import { USite } from 'types/USite';
import { getTemplateData } from '../utilities/Conditions/templateHelper';

interface TemplateProps extends SectionTagArgs {
  defaults: any;
  value: USection;
  children?: React.ReactChildren;
  sectionHandlers?: SectionHandlersInterface;
  sectionUiHandlers?: SectionUiHandlersInterface;
  site: USite;
  onChange: (content: USectionDevices, key?: string, multi?: boolean) => void;
  item: (el: any) => React.ReactElement;
  dataRef: { [key: string]: any }[];
  templateContent: { [key: string]: any };
  templateDataRef: string;
}

const Template: React.FunctionComponent<TemplateProps> = (props) => {
  const dataRef = props.dataRef || getTemplateData(props);
  return dataRef ? React.cloneElement(props.item(dataRef)) : <></>;
};

export default withSite(Template);
