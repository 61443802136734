/* eslint-disable jsx-a11y/anchor-is-valid */

import React from 'react';
import Popover from '../../../base/Popover';
import SmartUrlToolbar from '../../../Editor/SmartUrlToolbar/SmartUrlToolbar';

import classnames from 'classnames';

// Light
import { ReactComponent as IconFooterFacebook } from '../../../../assets/images/icon-footer-facebook.svg';
import { ReactComponent as IconFooterTwitter } from '../../../../assets/images/icon-footer-twitter.svg';
import { ReactComponent as IconFooterLinkedIn } from '../../../../assets/images/icon-footer-linkedin.svg';
import { ReactComponent as IconFooterInstagram } from '../../../../assets/images/icon-footer-instagram.svg';
import { ReactComponent as IconFooterYouTube } from '../../../../assets/images/icon-footer-youtube.svg';

export const TYPES = ['facebook', 'twitter', 'linkedin', 'instagram', 'youtube'];
export const ICONS = {
  facebook: <IconFooterFacebook />,
  twitter: <IconFooterTwitter />,
  linkedin: <IconFooterLinkedIn />,
  instagram: <IconFooterInstagram />,
  youtube: <IconFooterYouTube />,
};

class SocialMediaIcons extends React.PureComponent {
  render() {
    const { siteIcons, siteUpdaters, propPath, brightnessTheme } = this.props;

    const renderedIcons = TYPES.map((type, index) => {
      return (
        <Icon key={type} propPath={propPath.concat(type)} item={siteIcons[type]} siteUpdaters={siteUpdaters}>
          {ICONS[type]}
        </Icon>
      );
    });

    return (
      <div className="social-icons">
        <ul>{renderedIcons}</ul>
      </div>
    );
  }
}

export default SocialMediaIcons;

class Icon extends React.PureComponent {
  constructor(props) {
    super(props);
    this.inputRef = React.createRef();
    this.state = { isOpen: false };
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevState.isOpen && this.state.isOpen) {
      this.focusInput();
    }
  }

  // NOTE: No-op until we can integrate this with SmartUrlToolbar
  focusInput() {
    // clearTimeout(this.focusInputTimeout);
    // this.focusInputTimeout = setTimeout(() => {
    //   if (this.inputRef.current)
    //     this.inputRef.current.focus();
    //   else if (this.state.isOpen)
    //     this.focusInput();
    // }, 50);
  }

  handleClick = (e) => {
    this.setState({ isOpen: true });
  };

  handleBlur = (e) => {
    this.setState({ isOpen: false });
  };

  handleChange = (name, value) => {
    const { siteUpdaters } = this.props;
    siteUpdaters.set(this.props.propPath.concat(name), value);
  };

  handleRemove = () => {
    if (this.props.item) this.props.siteUpdaters.del(this.props.propPath);
    this.setState({ isOpen: false });
  };

  render() {
    const { item = {}, children } = this.props;
    const { isOpen } = this.state;

    const hasItem = item != null;
    const isActive = hasItem || isOpen;
    return (
      <Popover
        isOpen={isOpen}
        target={(props, ref) => (
          <li ref={ref}>
            <a className={classnames({ active: isActive })} onClick={this.handleClick} role="button">
              {children}
            </a>
          </li>
        )}
        toolbar={() => (
          <div>
            <SmartUrlToolbar
              onChange={this.handleChange}
              onRemove={this.handleRemove}
              link={item}
              value={item.url || ''}
              placeholder="Insert URL"
              setOpenedFalse={() => this.setState({ isOpen: false })}
              disablePageAutocomplete
              autoFocus
            />
          </div>
        )}
      />
    );
  }
}
