import { takeEvery, call, put, select, all, spawn, takeLatest, take } from 'redux-saga/effects';
import * as types from '../actions/actionTypes';
import * as api from '../services/spark-api';
import * as unsplashApi from '../services/unsplash-api';
import * as icons8Api from '../services/icons8-api';
import * as pexelsApi from '../services/pexels-api';
import { downloadFile, downloadFileWithProgress } from '../services/download';
import * as mediaActions from '../actions/mediaActions';
import * as mediaSelectors from '../reducers/mediaReducer';
import * as accountActions from '../actions/accountActions';
import requireSiteId from './utils/requireSiteId';
import { ICONS8_IMG_DOWNLOAD_URL } from '../settings';
import { createUploadFileChannel } from './createFileUploadChannel';
import { formatBytes } from 'lib/byte-to-megabytes';

function* request(action) {
  const id = action.payload;
  try {
    const response = yield call(api.getMediaById, id);
    if (response.status === 200) {
      yield put(mediaActions.receiveOne(response.json));
    } else if (response.status === 404) {
      yield put(mediaActions.receiveOne({ id, is_deleted: true }));
    } else if (response.unauthorized) {
      yield put(accountActions.logout());
    } else {
      console.error('Failed trying to fetch media', response.json);
    }
  } catch (err) {
    console.error(err);
  }
}

function* requestIfNeeded(action) {
  const id = action.payload;
  const byId = yield select(mediaSelectors.selectAllById);
  if (!byId[id]) yield put(mediaActions.request(id));
}

function* requestAll(action) {
  const siteId = yield call(requireSiteId, action.payload.siteId);

  try {
    const response = yield call(api.getMedia, siteId);
    if (response.status === 200) {
      yield put(mediaActions.receiveArray(response.json, new Date()));
    } else if (response.unauthorized) {
      yield put(accountActions.logout());
    } else {
      console.error('Failed trying to fetch media', response.json);
    }
  } catch (err) {
    console.error(err);
  }
}

function* requestAllIfNeeded(action) {
  const isFetching = yield select(mediaSelectors.selectIsFetching);
  const lastUpdated = yield select(mediaSelectors.selectLastUpdated);
  if (lastUpdated == null && !isFetching) {
    yield put(mediaActions.requestAll(action.payload.siteId));
  }
}

export function* create(action) {
  const {
    payload: { file, category },
    resolve,
    reject,
  } = action;
  const siteId = yield call(requireSiteId, action.payload.siteId);
  if (formatBytes(file.size) >= 50) {
    yield spawn(reject, {
      message: 'Size limit exceeded. Make sure media is under 50MB',
    });
    return;
  }
  const channel = yield call(createUploadFileChannel, `media/?site_id=${siteId}`, file, category, siteId);
  while (true) {
    const { progress = 0, success, response, unauthorized } = yield take(channel);
    if (success == true) {
      yield put(mediaActions.receiveOne(response));
      if (resolve) yield spawn(resolve, response);
      return;
    }
    if (success == false) {
      if (unauthorized) yield put(accountActions.logout());
      else if (reject) {
        yield put({ type: types.MEDIA_CREATION_FAILED });
        yield spawn(reject, response);
      } else if (reject) {
        yield spawn(reject, response.json);
        console.error('Failed trying to fetch media', response.json);
      }
      return;
    }
    yield put(mediaActions.uploadProgress(progress));
  }
}

function* downloadUnsplashPhoto(action) {
  const {
    payload: { id, url },
    resolve,
    reject,
  } = action;

  try {
    const response = yield call(unsplashApi.downloadPhotoCounter, id);
    const downloadResponse = yield call(downloadFile, url);
    if (response.status === 200) {
      if (resolve) yield spawn(resolve, downloadResponse);
    } else {
      if (reject) yield spawn(reject, { message: 'Unknown error' });
      console.error('Failed trying to download unsplash media', response.json);
      console.error('Failed trying to download unsplash media', downloadResponse);
    }
  } catch (err) {
    if (reject) yield spawn(reject, { message: err });
    console.error(err);
  }
}

function* searchUnsplash(action) {
  try {
    const {
      payload: { query, page },
    } = action;
    const response = yield call(unsplashApi.searchPhotos, query, page);
    if (response.status === 200) {
      yield put(mediaActions.unsplashResults(response.json));
    } else {
      console.error('Failed trying to fetch unsplash media', response.json);
    }
  } catch (err) {
    console.error(err);
  }
}

function* searchPexels(action) {
  try {
    const {
      payload: { query, page, category },
    } = action;
    const response = yield call(pexelsApi.searchPhotos, query, page, category);
    if (response.ok) yield put(mediaActions.pexelResults(response.json));
  } catch (err) {
    console.error(err);
  }
}

function* downloadPexelMedia(action) {
  const {
    payload: { url, category },
    resolve,
    reject,
  } = action;
  try {
    const response = yield call(category === 'image' ? downloadFile : downloadFileWithProgress, url);
    if (resolve) yield spawn(resolve, response);
  } catch (err) {
    if (reject) yield spawn(reject, { message: err });
    console.error(err);
  }
}

function* downloadIcons8Photo(action) {
  const {
    payload: { url },
    resolve,
    reject,
  } = action;

  try {
    const response = yield call(downloadFile, url);
    if (resolve) yield spawn(resolve, response);
  } catch (err) {
    if (reject) yield spawn(reject, { message: err });
    console.error(err);
  }
}

function* searchIcons8(action) {
  try {
    const {
      payload: { query, page },
    } = action;
    const response = yield call(icons8Api.searchPhotos, query, page);
    if (response.status === 200) {
      response.json.icons.forEach((icon) => {
        icon.url = `${ICONS8_IMG_DOWNLOAD_URL}${icon.platform}/240/${icon.commonName}.svg`;
      });
      yield put(mediaActions.icons8Results(response.json));
    } else {
      console.error('Failed trying to fetch icons8 media', response.json);
    }
  } catch (err) {
    console.error(err);
  }
}

function* deleteMedia(action) {
  const { resolve, reject } = action;
  try {
    const response = yield api.deleteMedia(action.payload);
    if (response.ok) {
      yield put(mediaActions.removeOne(action.payload));
      resolve();
    } else {
      reject({
        msg: response.json.message || 'This image cannot be deleted because it is being used in a page',
        id: action.payload,
      });
    }
  } catch (err) {
    console.error(err);
  }
}

function* requestPDFs(action) {
  const siteId = yield call(requireSiteId, action.payload.siteId);

  try {
    const response = yield call(api.getMedia, siteId, 'pdf');
    if (response.ok) {
      yield put(mediaActions.receiveArray(response.json.results, new Date()));
    }
  } catch (e) {
    console.log(e);
  }
}

function* requestPaginatedList(action) {
  const { payload } = action;
  const siteId = yield call(requireSiteId, action.payload.siteId);

  try {
    const response = yield call(api.getMediaPerPage, { ...payload, siteId });
    if (response.status === 200) {
      yield put(mediaActions.receivePage(response.json, payload));
    } else if (response.unauthorized) {
      yield put(accountActions.logout());
    } else {
      console.error('Failed trying to fetch media', response.json);
    }
  } catch (e) {
    console.error(e);
  }
}

function* searchUnstackMedia(action) {
  const { payload } = action;
  const siteId = yield call(requireSiteId, action.payload.siteId);

  try {
    const response = yield call(api.getFilteredMedia, { ...payload, siteId });
    if (response.status === 200) {
      yield put(mediaActions.receiveSearchedResult(response.json.results));
    } else if (response.unauthorized) {
      yield put(accountActions.logout());
    } else {
      console.error('Failed trying to fetch media', response.json);
    }
  } catch (e) {
    console.error(e);
  }
}

function* requestColumns(action) {
  const { payload } = action;
  const siteId = yield call(requireSiteId, payload.siteId);
  try {
    const response = yield call(api.getTableColumns, siteId, payload.tableId);
    if (response.ok) {
      const allColumns = response.json.results;
      const mediaColumns = allColumns.filter((column) => column.data_type === 'media');
      yield put(mediaActions.saveDataTableColumns(mediaColumns));
    }
  } catch (e) {
    console.error(e);
  }
}

function* mediaSaga() {
  yield all([
    takeEvery(types.MEDIA_REQUEST, request),
    takeEvery(types.MEDIA_REQUEST_IF_NEEDED, requestIfNeeded),
    takeEvery(types.MEDIA_REQUEST_ALL, requestAll),
    takeEvery(types.MEDIA_REQUEST_ALL_IF_NEEDED, requestAllIfNeeded),
    takeEvery(types.MEDIA_CREATE, create),
    takeEvery(types.MEDIA_SEARCH_UNSPLASH, searchUnsplash),
    takeEvery(types.MEDIA_DOWNLOAD_UNSPLASH_PHOTO, downloadUnsplashPhoto),
    takeEvery(types.MEDIA_SEARCH_ICONS8, searchIcons8),
    takeEvery(types.MEDIA_DOWNLOAD_ICONS8_PHOTO, downloadIcons8Photo),
    takeEvery(types.MEDIA_DELETE, deleteMedia),
    takeLatest(types.MEDIA_REQUEST_PDFS, requestPDFs),
    takeLatest(types.MEDIA_REQUEST_PAGINATED_LIST, requestPaginatedList),
    takeEvery(types.MEDIA_SEARCH_UNSTACK, searchUnstackMedia),
    takeLatest(types.MEDIA_SEARCH_PEXELS_MEDIA, searchPexels),
    takeLatest(types.MEDIA_PEXELS_DOWNLOAD_MEDIA, downloadPexelMedia),
    takeLatest(types.MEDIA_REQUEST_DATA_TABLE_COLUMNS, requestColumns),
  ]);
}

export default mediaSaga;
