import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import * as subscriptionSelectors from '../reducers/subscriptionReducer';
import * as subscriptionActions from '../actions/subscriptionActions';
import withSite from './withSite';

function mapStateToProps(state) {
  return {
    subscriptionLoading: subscriptionSelectors.selectIsFetching(state),
    hasSubscription: subscriptionSelectors.selectHasSubscription(state),
    subscription: subscriptionSelectors.selectSubscription(state),
  };
}

const mapDispatchToProps = {
  requestIfNeeded: subscriptionActions.requestIfNeeded,
};

export default (WrappedComponent) => {
  class Container extends React.PureComponent {
    componentDidUpdate(prevProps) {
      if (this.props.site && prevProps.site !== this.props.site) {
        this.props.requestIfNeeded();
      }
    }

    render() {
      const { requestIfNeeded, ...props } = this.props;
      return <WrappedComponent {...props} />;
    }
  }

  return compose(withSite, connect(mapStateToProps, mapDispatchToProps))(Container);
};
