import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import { useSelector } from 'react-redux';
import createFormatDate from 'date-fns/fp/format';
import isSameYear from 'date-fns/isSameYear';

import Spinner from '../../../base/Spinner';
import List from '../../../react-table/List';
import { ReactComponent as SvgIconEdit } from '../../../../assets/images/icon-edit-field.svg';
import { ReactComponent as SvgIconTasks } from '../../../../assets/images/icon-today.svg';

import { selectAllTasks, selectIsLoading } from '../../../../reducers/tasksReducer';
import { selectAccountUser } from '../../../../reducers/accountUsersReducer';

import styles from './ContactDetail.module.scss';

const formatDateWithoutYear = createFormatDate('EEEE, LLL d');
const formatDateWithYear = createFormatDate('EEEE, LLL d, yyyy');
const isRecent = (date) => isSameYear(date, new Date());

export function formatDate(date) {
  return isRecent(date) ? formatDateWithoutYear(date) : formatDateWithYear(date);
}

export default ({ fetchTasks, contactId, refetchList, openDrawer, update, contactName }) => {
  const columns = [
    {
      id: 'details',
      accessor: (row) => row,
      Cell: ({ value }) => {
        const owner = useSelector((state) => selectAccountUser(state, value.owner));
        const [onHover, setOnHover] = useState(false);
        const date = value.due_on.split('-');
        return (
          <div
            onMouseEnter={() => setOnHover(true)}
            onMouseLeave={() => setOnHover(false)}
            className={classnames(styles.description, {
              [styles.completedTask]: value.status === 'done',
            })}
            data-test-id="details"
          >
            {owner && (
              <strong>
                <SvgIconTasks className={styles.heading} height={15} width={15} />
                {owner.name || owner.email} added task due on {formatDate(new Date(date[0], date[1] - 1, date[2]))}
                {onHover && (
                  <SvgIconEdit
                    onClick={() => {
                      openDrawer(value);
                    }}
                    data-test-id="edit"
                    className={styles.edit}
                    height={13}
                    width={13}
                  />
                )}
              </strong>
            )}
            <span>{value.title}</span>
            <span className={styles.details}>{value.details}</span>
          </div>
        );
      },
    },
    {
      accessor: '',
      Header: '',
      Cell: ({ value }) =>
        value.status === 'done' ? (
          <div className={styles.completed}>Completed</div>
        ) : (
          <div onClick={() => update(value)} data-test-id="mark-as-done">
            Mark as done
          </div>
        ),
      width: 150,
      className: styles.markButtonContainer,
    },
  ];

  useEffect(() => {
    fetchTasks({ contactId });
  }, [refetchList]); // eslint-disable-line react-hooks/exhaustive-deps

  const allTasks = useSelector((state) => selectAllTasks(state));
  const isLoading = useSelector((state) => selectIsLoading(state));
  return (
    <div className={styles.tasksListContainer}>
      {isLoading ? (
        <Spinner className="fixed" />
      ) : allTasks.length ? (
        <List data={allTasks} columns={columns} showAll />
      ) : (
        <div data-test-id="empty-task-state">
          There are no open tasks for {contactName}.{' '}
          <span className={styles.linkText} onClick={() => openDrawer({})} data-test-id="create-new-task">
            Create a new task
          </span>
        </div>
      )}
    </div>
  );
};
