/**
 * NOTE: this description is outdated.
 * BaseEditor - a basic component interface for the QuillJS rich text editor
 * @module
 * An abstracted component interface for the quill editor.
 * @extends React.Component
 * @param {Object} props
 * @param {string|Object} props.value - Accepts either a plain-text string or a [Quill Delta]{@link https://quilljs.com/docs/delta/}.
 * @param {onChange} props.onChange - Will return a Quill Delta object. (e.g. onChange={(value) => doSomething(value)}).
 * @param {string} [props.placeholder] - Placeholder text.
 */

/**
 * onChange
 * @callback
 * @param {*} value
 */

import {
  YOUTUBE_URL_REGEXP,
  VIMEO_URL_REGEXP,
  WISTIA_URL_REGEXP,
  TRANSISTOR_URL_REGEXP,
  BUZZSPROUT_URL_REGEXP,
  SOUNDCLOUD_URL_REGEXP,
  TWITTER_URL_REGEXP,
} from '../../../constants';
import { getYoutubeVideoIdFromUrl } from '../../youtube-urls';
import { getVimeoVideoId } from '../../vimeo-urls';
import { getWistiaVideoIdFromUrl } from '../../wistia-urls';

import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import withSiteProps from '../../../containers/withSiteProps';

import Quill from '../index';
import Toolbar from './QuillToolbar';
import AddItem from './AddItem';
import BottomDrawer from '../../../components/base/BottomDrawer';
import MediaDrawerBottom from '../../../components/base/Media/Drawer/MediaDrawerBottom';

import isEmpty from 'lodash/isEmpty';
import debounce from 'lodash/debounce';
import isString from 'lodash/isString';
import isObjectLike from 'lodash/isObjectLike';
import isEqual from 'lodash/isEqual';
import QueryString from 'query-string';
import classnames from 'classnames';

import * as mediaSelectors from '../../../reducers/mediaReducer';
import { extractIdFromSrc } from '../../../lib/media-utils';
import FormEmbedManager from './RichTextEditor/FormEmbedManager';

// Keyboard module
const Keyboard = Quill.import('modules/keyboard');

const WhitelistedSize = [];
for (let i = 1; i <= 10; i = i + 0.1) {
  WhitelistedSize.push(`${Number(i.toFixed(1))}rem`);
}

// Fonts & Size attributor
const Fonts = Quill.import('attributors/style/font');
const Size = Quill.import('attributors/style/size');
Size.whitelist = WhitelistedSize;
Quill.register(Size, true);

// Tab regexp
const TAB_REGEX = /^\t\s*$/;

function mapSiteToProps(site) {
  return {
    siteStyles: site.styles,
  };
}

const CLIPBOARD_EMBED_DENYLIST = ['image', 'video', 'video_embed', 'podcast_embed', 'twitter_embed', 'form_embed'];

function removeEmbedsFromClipboard(node, delta, ...otherArgs) {
  const Delta = Quill.import('delta');
  const newDelta = new Delta(
    delta.ops.filter((op) => {
      if (op.insert && op.insert.hasOwnProperty) {
        return !CLIPBOARD_EMBED_DENYLIST.some((prop) => op.insert.hasOwnProperty(prop));
      }
    })
  );
  return newDelta;
}

function strikethrough(node, delta) {
  const Delta = Quill.import('delta');
  return delta.compose(new Delta().retain(delta.length(), { strike: true }));
}

class RichTextEditor extends React.Component {
  state = {
    hasFocus: false,
    mediaDrawerIsOpen: false,
    mediaDrawer: {},
    selectionRange: null,
    selectionBounds: null,
  };

  Quill = Quill;
  containerRef = React.createRef();
  // toolbarId = getToolbarId();

  componentDidMount() {
    Fonts.whitelist = this.props.siteStyles.fontFamily;
    Quill.register(Fonts, true);

    const quillOptions = {
      readOnly: this.props.readOnly,
      formats: [
        'header',
        'list',
        'blockquote',
        'bold',
        'font',
        'color',
        'size',
        'italic',
        'highlight',
        'align',
        'strike',
        'link',
        'image',
        'divider',
        'video',
        'video_embed',
        'podcast_embed',
        'twitter_embed',
        'code-block',
        'id-attribute',
        'block',
        'form_embed',
      ],
      modules: {
        clipboard: {
          matchers: [
            ['del, strike', strikethrough],
            [Node.ELEMENT_NODE, removeEmbedsFromClipboard],
          ],
        },
        toolbar: false,
        // theme: false,
        syntax: true,
        keyboard: {
          bindings: {
            // NOTE: This binding should be added as a QuillJS custom module instead, but
            // Quill's core keyboard binding for the ENTER key prevents us from using
            // `quill.addBinding(...)`
            // see https://github.com/quilljs/quill/issues/1647#issuecomment-322693849
            vimeo: {
              key: Keyboard.keys.ENTER,
              prefix: VIMEO_URL_REGEXP,
              handler: (range, context) => {
                const [oldBlot] = this.quill.getLine(range.index - 1);

                const videoId = getVimeoVideoId(context.prefix);

                if (videoId) {
                  const smartUrl = 'vimeo:' + videoId;

                  const newBlot = oldBlot.replaceWith('video_embed', {
                    src: smartUrl,
                  });

                  const index = this.quill.getIndex(newBlot);
                  this.quill.setSelection(index + 1);

                  return this.quill.getLength() <= index + 1;
                }

                return true;
              },
            },
            wistia: {
              key: Keyboard.keys.ENTER,
              prefix: WISTIA_URL_REGEXP,
              handler: (range, context) => {
                const [oldBlot] = this.quill.getLine(range.index - 1);

                const videoId = getWistiaVideoIdFromUrl(context.prefix);

                if (videoId) {
                  const smartUrl = 'wistia:' + videoId;

                  const newBlot = oldBlot.replaceWith('video_embed', {
                    src: smartUrl,
                  });

                  const index = this.quill.getIndex(newBlot);
                  this.quill.setSelection(index + 1);

                  return this.quill.getLength() <= index + 1;
                }

                return true;
              },
            },
            youtube: {
              key: Keyboard.keys.ENTER,
              prefix: YOUTUBE_URL_REGEXP,
              handler: (range, context) => {
                // Get the blot
                const [oldBlot] = this.quill.getLine(range.index - 1);

                const videoId = getYoutubeVideoIdFromUrl(context.prefix);

                if (videoId) {
                  const smartUrl = 'youtube:' + videoId;

                  // Replace with a video_embed blot
                  const newBlot = oldBlot.replaceWith('video_embed', {
                    src: smartUrl,
                  });

                  // set the cursor after the new blot
                  const index = this.quill.getIndex(newBlot);
                  this.quill.setSelection(index + 1);

                  // If the new embed is followed by a newline, return false
                  // to prevent the ENTER key from creating another one.
                  // Otherwise return true to ensure that the document ends
                  // in a newline.
                  return this.quill.getLength() <= index + 1;
                }

                return true;
              },
            },
            transistor: {
              key: Keyboard.keys.ENTER,
              prefix: TRANSISTOR_URL_REGEXP,
              handler: (range, context) => {
                // Get the blot
                const [oldBlot] = this.quill.getLine(range.index - 1);

                const podcastId = context.prefix.split('/').pop();

                if (podcastId) {
                  const smartUrl = 'transistor:' + podcastId;

                  const newBlot = oldBlot.replaceWith('podcast_embed', {
                    src: smartUrl,
                  });

                  // set the cursor after the new blot
                  const index = this.quill.getIndex(newBlot);
                  this.quill.setSelection(index + 1);

                  return this.quill.getLength() <= index + 1;
                }

                return true;
              },
            },
            buzzsprout: {
              key: Keyboard.keys.ENTER,
              prefix: BUZZSPROUT_URL_REGEXP,
              handler: (range, context) => {
                // Get the blot
                const [oldBlot] = this.quill.getLine(range.index - 1);

                const contextArray = context.prefix.split('/');
                const podcastId = `${contextArray[contextArray.length - 2]}-${contextArray[contextArray.length - 1]}`;

                if (podcastId) {
                  const smartUrl = 'buzzsprout:' + podcastId;

                  const newBlot = oldBlot.replaceWith('podcast_embed', {
                    src: smartUrl,
                  });

                  const index = this.quill.getIndex(newBlot);
                  this.quill.setSelection(index + 1);

                  return this.quill.getLength() <= index + 1;
                }

                return true;
              },
            },
            soundcloud: {
              key: Keyboard.keys.ENTER,
              prefix: SOUNDCLOUD_URL_REGEXP,
              handler: (range, context) => {
                // Get the blot
                const [oldBlot] = this.quill.getLine(range.index - 1);

                const querystrings = QueryString.extract(context.prefix);
                const params = QueryString.parse(querystrings);

                if (context.prefix) {
                  const smartUrl = `soundcloud:${params.url || context.prefix}`;

                  // Replace with a video_embed blot
                  const newBlot = oldBlot.replaceWith('podcast_embed', {
                    src: smartUrl,
                  });

                  const index = this.quill.getIndex(newBlot);
                  this.quill.setSelection(index + 1);

                  return this.quill.getLength() <= index + 1;
                }

                return true;
              },
            },
            twitter: {
              key: Keyboard.keys.ENTER,
              prefix: TWITTER_URL_REGEXP,
              handler: (range, context) => {
                // Get the blot
                const [oldBlot] = this.quill.getLine(range.index - 1);

                if (context.prefix) {
                  const smartUrl = `twitter:${context.prefix.split('/').pop()}-${context.prefix.split('/')[3]}`;

                  const newBlot = oldBlot.replaceWith('twitter_embed', {
                    src: smartUrl,
                  });

                  const index = this.quill.getIndex(newBlot);
                  this.quill.setSelection(index + 1);

                  return this.quill.getLength() <= index + 1;
                }

                return true;
              },
            },
          },
        },
      },
      scrollingContainer: 'iframe',
    };

    if (this.props.placeholder != null) quillOptions.placeholder = this.props.placeholder;

    // Initialize
    const quill = new Quill(this.containerRef.current, quillOptions);
    this.quill = quill;

    // Set contents if value is present
    const { value } = this.props;
    this.setContents(value);

    // If initialized with empty content, apply any formats that were provided
    // in props.initialFormats.
    if (this.props.initialFormats && this.isQuillEmpty(value)) {
      this.quill.formatLine(0, 0, this.props.initialFormats, 'silent');
    }

    this.preserveFormattedPlaceholder();
    if (this.props.autoFocus) quill.setSelection(quill.getLength(), 0);

    // Add event handler to fire onChange event
    quill.on('text-change', (delta, oldContents, source) => {
      if (source === 'user') {
        this.stripEmptyTabs();
        this.quillDidChange();
        this.triggerPropsOnChange();
        this.preserveFormattedPlaceholder();
      }
    });

    // Update hasFocus state
    this.setState({ hasFocus: quill.hasFocus() });
    quill.on('selection-change', (range, oldrange, source) => {
      this.setState({ hasFocus: this.quill.hasFocus() });
    });

    // Listen for image clicks to open media side drawer
    this.quill.root.addEventListener('click', this.hanldeQuillItemClick);

    // Watch selection changes to store last known selection and to check if
    // the selected line is empty.
    this.quill.on('editor-change', (eventName, ...args) => {
      if (eventName === 'selection-change') this.handleSelectionChange(...args);
    });

    // Clear undo/redo history so that users can't undo their way back to an
    // empty editor before the content was loaded.
    this.quill.history.clear();

    if (this.props.updateTableOfContents) {
      const tableOfContents = [];
      this.quill.editor.delta.eachLine((line, attributes, i) => {
        if (attributes.header === 2 && line.ops.length) tableOfContents.push(line);
      });
      this.props.updateTableOfContents(tableOfContents);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    // Trigger onFocus or onBlur event if state.hasFocus changes
    if (!prevState.hasFocus && this.state.hasFocus && this.props.onFocus) {
      this.props.onFocus();
    } else if (prevState.hasFocus && !this.state.hasFocus && this.props.onBlur) {
      this.props.onBlur();
    }

    // Remove quill text alignment when box text alignment changed
    if (prevProps.boxTextAlign !== this.props.boxTextAlign) {
      let contents = this.getContents();
      this.props.onChange(removeAlign(contents));
    }

    function removeAlign(obj) {
      if (Array.isArray(obj)) return obj.map((item) => removeAlign(item));
      if (typeof obj === 'object' && obj !== null) {
        return Object.keys(obj).reduce((previousValue, key) => {
          return key === 'align' ? previousValue : { ...previousValue, [key]: removeAlign(obj[key]) };
        }, {});
      }
      return obj;
    }

    // Update contents if prop changes
    if (!this.isEqualValue(prevProps.value, this.props.value) && !this.state.hasFocus) {
      // console.log('incoming');
      // console.log('incoming prop.value', this.contentCache, prevProps.value, this.props.value);

      // Only replace content if the new prop differs from the contentCache
      if (this.isEqualValue(this.contentCache, this.props.value)) {
        this.contentCache = null;
      } else {
        // quill.setContents causes the editor to focus at index 0, so first
        // let's get the current selection so we can preserve it.
        const selection = this.quill.getSelection();

        // Set the contents.
        this.setContents(this.props.value, 'api');

        // Restore the selection state.
        this.quill.setSelection(selection, 'api');
      }
    }

    if (this.props.updateTableOfContents && this.quill && !isEqual(this.props.value, prevProps.value)) {
      const tableOfContents = [];
      this.quill.editor.delta.eachLine((line, attributes, i) => {
        const linkOp = line.ops.find((op) => typeof op.insert === 'string');
        if (attributes.header === 2 && linkOp) {
          tableOfContents.push(line);
        }
      });
      this.props.updateTableOfContents(tableOfContents);
    }
  }

  /*
	True if the value is a Delta instance or a Delta look-alike.
	*/
  isDelta(value) {
    return value && value.ops;
  }

  /*
	Special comparison function that knows how to compare Deltas.
	*/
  isEqualValue(value, nextValue) {
    if (this.isDelta(value) && this.isDelta(nextValue)) {
      return isEqual(value.ops, nextValue.ops);
    } else {
      return isEqual(value, nextValue);
    }
  }

  componentWillUnmount() {
    this.quill?.off('text-change');
    this.quill?.off('selection-change');
    this.quill?.root.removeEventListener('click', this.hanldeQuillItemClick);
    this.quillWillUnmount();
  }

  /**
   * Quill "Lifecycle" hooks.
   * Noops that may be overridden by the inheriting class. Guarunteed to fire
   * after initialization and after every user-intiated text-change event.
   * @see {@link https://quilljs.com/docs/api/#events}
   */
  quillDidChange() {}
  quillWillUnmount() {}

  setContents(value) {
    if (isString(value)) {
      this.quill.setText(value);
    } else if (isObjectLike(value)) {
      this.quill.setContents(value);
    }
  }

  getContents() {
    return this.quill.getContents();
  }

  /**
   * Call `this.props.onChange(value)`. Debounce to reduce the load when user is
   * typing quickly.
   */
  triggerPropsOnChange = debounce(() => {
    if (this.quill) {
      if (this.props.onChange) {
        const contents = this.getContents();
        // console.log('dispatch change');
        // console.log('dispatch change', contents);
        this.contentCache = contents;
        this.props.onChange(contents);
      }
      if (this.props.onTextChange) {
        const text = this.quill.getText();
        this.props.onTextChange(text);
      }
    }
  }, 100);

  // triggerPropsOnChange = () => {
  //   if (this.quill) {
  //     if (this.props.onChange) {
  //       const contents = this.getContents();
  //       this.contentCache.push(contents);
  //       this.props.onChange(contents);
  //     }
  //     if (this.props.onTextChange) {
  //       const text = this.quill.getText();
  //       this.props.onTextChange(text);
  //     }
  //   }
  // };

  // contentCache = [];

  // matchesPreviousValue = (value) => {
  //   return this.contentCache.some(prevValue => prevValue === value);
  // }

  /**
   * If the text contents of the quill editor is a tab followed by white space,
   * replace the contents as empty. This fixes a bug where the user would press
   * `tab` and the placeholder and container would collapse and disappear.
   */
  stripEmptyTabs() {
    if (TAB_REGEX.test(this.quill.getText())) {
      this.quill.setText('', 'api');
    }
  }

  /**
   * Quill's default behavior is to hide the placeholder when a format has been
   * selected, even if the content is otherwise empty. This method undoes that
   * behavior. It checks to see if those conditions are met, and then adds a
   * [data-placeholder] attribute to the first line's domNode when necessary.
   */
  preserveFormattedPlaceholder() {
    const quill = this.quill;

    // Remove any [data-placeholder] attributes from editor children domNodes.
    quill.root.querySelectorAll('[data-placeholder]').forEach((domNode) => {
      domNode.removeAttribute('data-placeholder');
    });

    // If the editor is empty and has an active block-level format, add the
    // [data-placeholder] attribute to force a formatted placeholder.
    if (this.props.placeholder && quill.getLength() === 1 && quill.getFormat(0, Number.MAX_VALUE)) {
      const [firstLine] = quill.getLine(0);
      firstLine.domNode.setAttribute('data-placeholder', this.props.placeholder);
    }
  }

  // Handle selection changes and detect empty lines
  // --------------------------------------------------------------------------

  handleSelectionChange = (range, oldRange, source) => {
    let bounds;

    if (range) {
      bounds = this.quill.getBounds(range);
      this.lastDefinedSelectionRange = range;
      this.lastDefinedSelectionBounds = bounds;
    }

    this.setState({
      selectionRange: range,
      selectionBounds: bounds,
    });

    this.props.setIsTextSelected && this.props.setIsTextSelected(!!(range && range.length));
  };

  isEmptyLineSelected = () => {
    const { selectionRange: range } = this.state;

    if (range && range.length === 0) {
      const [line, offset] = this.quill.getLine(range.index);
      const length = line.length();
      const text = this.quill.getText(range.index - offset, length);

      return text.length === 1 && text.charCodeAt(0) === 10;
    }

    return false;
  };

  // Media drawer
  // --------------------------------------------------------------------------

  openMediaDrawer = (category, entity, callback) => {
    this.setState({
      mediaDrawerIsOpen: true,
      mediaDrawer: { category, entity, callback },
    });
  };

  closeMediaDrawer = () => {
    this.setState({
      mediaDrawerIsOpen: false,
    });
  };

  handleMediaDrawerChange = (entity) => {
    const { mediaDrawer } = this.state;
    if (mediaDrawer && mediaDrawer.callback) {
      mediaDrawer.callback(entity);
    }
  };

  // Add item
  // --------------------------------------------------------------------------

  getMediaCategory = (mediaUrl) => {
    const { allMedia } = this.props;
    const id = extractIdFromSrc(mediaUrl);
    const category = allMedia.find((item) => item.id === id).category_id || 'image';
    return category;
  };

  handleAddItem = (type, value) => {
    if (type === 'media') {
      this.openMediaDrawer('image', {}, (entity) => {
        const mediaType = entity && this.getMediaCategory(entity.src);
        this.addItem(mediaType, entity);
      });
    } else {
      this.addItem(type, value);
    }
  };

  addItem = (type, value) => {
    switch (type) {
      case 'cancel': {
        const insertIndex = this.lastDefinedSelectionRange.index;
        this.quill.setSelection(insertIndex);
        break;
      }

      case 'image': {
        const insertIndex = this.lastDefinedSelectionRange.index;
        const imageValue = value || {};

        if (!isEmpty(imageValue)) {
          // Delete the line that the image should replace.
          this.quill.deleteText(insertIndex, 1, 'silent');

          // Insert image
          this.quill.insertEmbed(insertIndex, 'image', imageValue, 'user');

          // If the image was inserted at the end of the document, add a newline.
          // Then advance the cursor to the next line.
          if (insertIndex === this.quill.getLength() - 1) {
            this.quill.insertText(this.quill.getLength(), '\n', 'user');
            this.quill.setSelection(insertIndex + 2);
          } else {
            this.quill.setSelection(insertIndex + 1);
          }
        }
        break;
      }

      case 'video': {
        const insertIndex = this.lastDefinedSelectionRange.index;

        if (!isEmpty(value)) {
          // Delete the line that the image should replace.
          this.quill.deleteText(insertIndex, 1, 'silent');

          // Insert video
          this.quill.insertEmbed(insertIndex, 'video', value, 'user');
          // If the image was inserted at the end of the document, add a newline.
          // Then advance the cursor to the next line.
          if (insertIndex === this.quill.getLength() - 1) {
            this.quill.insertText(this.quill.getLength(), '\n', 'user');
            this.quill.setSelection(insertIndex + 2);
          } else {
            this.quill.setSelection(insertIndex + 1);
          }
        }
        break;
      }

      case 'embedded-video': {
        const youtubeId = getYoutubeVideoIdFromUrl(value);
        const wistiaId = getWistiaVideoIdFromUrl(value);
        const videoId = getVimeoVideoId(value);
        const insertIndex = this.lastDefinedSelectionRange.index;

        // Youtube
        let smartUrl;

        if (youtubeId) smartUrl = 'youtube:' + youtubeId;
        else if (wistiaId) smartUrl = 'wistia:' + wistiaId;
        else if (videoId) smartUrl = 'vimeo:' + videoId;

        if (smartUrl) {
          const mediaValue = { src: smartUrl };

          this.quill.insertEmbed(insertIndex, 'video_embed', mediaValue, 'user');

          // If the image was inserted at the end of the document, add a newline.
          // Then advance the cursor to the next line.
          if (insertIndex === this.quill.getLength() - 1) {
            this.quill.insertText(this.quill.getLength(), '\n', 'user');
            this.quill.setSelection(insertIndex + 2);
          } else {
            this.quill.setSelection(insertIndex + 1);
          }
        } else console.log("Didn't recognize video");

        break;
      }

      case 'divider': {
        const insertIndex = this.lastDefinedSelectionRange.index;

        // Delete the line that the divider should replace.
        this.quill.deleteText(insertIndex, 1, 'silent');

        // Insert divider
        this.quill.insertEmbed(insertIndex, 'divider', true, 'user');

        // If the image was inserted at the end of the document, add a newline.
        // Then advance the cursor to the next line.
        if (insertIndex === this.quill.getLength() - 1) {
          this.quill.insertText(this.quill.getLength(), '\n', 'user');
          this.quill.setSelection(insertIndex + 2);
        } else {
          this.quill.setSelection(insertIndex + 1);
        }
        break;
      }

      case 'form_embed': {
        const insertIndex = this.lastDefinedSelectionRange.index;

        if (!isEmpty(value)) {
          // Delete the line that the image should replace.
          this.quill.deleteText(insertIndex, 1, 'silent');

          // Insert video
          this.quill.insertEmbed(insertIndex, 'form_embed', value, 'user');
          // If the image was inserted at the end of the document, add a newline.
          // Then advance the cursor to the next line.
          if (insertIndex === this.quill.getLength() - 1) {
            this.quill.insertText(this.quill.getLength(), '\n', 'user');
            this.quill.setSelection(insertIndex + 2);
          } else {
            this.quill.setSelection(insertIndex + 1);
          }
        }
        break;
      }

      default:
        return;
    }
  };

  // Edit image
  // --------------------------------------------------------------------------

  hanldeQuillItemClick = (e) => {
    // Act only if an IMG or FIGURE was clicked. Otherwise return undefined.
    let quillItem;
    let type;

    if (e.target.tagName === 'FIGURE' || (e.target.tagName === 'IMG' && e.target.parentElement.tagName === 'FIGURE')) {
      quillItem = e.target.parentElement;
      type = 'image';
    } else if (
      e.target.tagName === 'VIDEO' ||
      (e.target.tagName === 'IMG' && e.target.parentElement.tagName === 'DIV')
    ) {
      quillItem = e.target.parentElement;
      type = 'video';
    } else {
      return;
    }

    const blot = this.Quill.find(quillItem);
    this.quill.blur();
    const blotValue = blot.value();
    const oldEntity = blotValue[type];
    this.openMediaDrawer(type, oldEntity, (entity) => {
      const mediaType = entity && this.getMediaCategory(entity.src);
      this.updateMedia(mediaType, blot, entity);
    });
  };

  updateMedia(type, blot, value) {
    const index = this.quill.getIndex(blot);

    // Check to see if the blot still exists in the editor.
    if (index === -1 || this.Quill.find(blot.domNode) == null) return undefined;

    this.quill.deleteText(index, 1, 'silent');
    !isEmpty(value) ? this.quill.insertEmbed(index, type, value, 'user') : this.triggerPropsOnChange();
  }

  isQuillEmpty = () => {
    return this.quill && !this.quill.getText().trim().length;
  };

  render() {
    const { value } = this.props;
    const { mediaDrawerIsOpen, mediaDrawer } = this.state;
    const emptyLineIsSelected = this.isEmptyLineSelected();

    return (
      <>
        {!this.props.readOnly && (
          <Toolbar
            quill={this.quill}
            hideAlignButtons={this.props.hideAlignButtons}
            siteStyles={this.props.siteStyles}
            isBlog={this.props.isBlog}
          />
        )}
        <div className={classnames('rich-text', this.props.className)} ref={this.containerRef} />
        {this.props.allowEmbeds && !this.isQuillEmpty() && (
          <AddItem
            quill={this.quill}
            emptyLineIsSelected={emptyLineIsSelected}
            bounds={this.lastDefinedSelectionBounds}
            addItem={this.handleAddItem}
            darkBg={this.props.darkBg}
            isBlog={this.props.isBlog}
          />
        )}
        <FormEmbedManager quill={this.quill} value={value} />
        <BottomDrawer extendBody isOpen={mediaDrawerIsOpen} close={this.closeMediaDrawer} hideScroll>
          <MediaDrawerBottom
            close={this.closeMediaDrawer}
            category={(mediaDrawer && mediaDrawer.category) || 'image'}
            entity={mediaDrawer && mediaDrawer.entity}
            onChange={this.handleMediaDrawerChange}
            enableLinking
            hideEmbeds
          />
        </BottomDrawer>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  allMedia: mediaSelectors.selectAll(state),
});

export default compose(withSiteProps(mapSiteToProps), connect(mapStateToProps))(RichTextEditor);
