import React from 'react';
import { useSelector } from 'react-redux';
import withSite from '../../containers/withSite';
import useUrlSite from '../../hooks/useUrlSite';
import { selectNotificationBarIsOut } from '../../reducers/notificationReducer';
import classnames from 'classnames';

import styles from './FixedHeader.module.scss';

function FixedHeader(props) {
  const urlSite = useUrlSite();
  const { children, useColumnFlex, withSearchField, tabs, site } = props;
  const notificationBarIsOut = useSelector((state) => selectNotificationBarIsOut(state, urlSite));

  if (!site) return <></>;

  return (
    <header
      className={classnames(styles.header, {
        [styles.notificationBarIsOut]: notificationBarIsOut,
        [styles.useColumnFlex]: useColumnFlex,
        [styles.withSearchField]: withSearchField,
        [styles.tabs]: tabs,
      })}
    >
      {children}
    </header>
  );
}

export default withSite(FixedHeader);
