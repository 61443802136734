import React, { useRef, useEffect } from 'react';
import classnames from 'classnames';
import debounce from 'lodash/debounce';

import { ReactComponent as SvgIconSearch } from '../assets/images/icon-search.svg';
import { ReactComponent as SvgIconClear } from '../assets/images/icon-clear-search.svg';

import styles from './Search.module.scss';

export default ({
  className = undefined,
  autofocus = undefined,
  containerStyle = undefined,
  debounceValue,
  enableDarkMode = undefined,
  onChange,
  placeholder,
  value,
}) => {
  const debounced = useRef(
    debounce((value) => {
      if (value !== undefined) debounceValue(value);
    }, 400)
  );
  useEffect(() => debounced.current(value), [value]);

  return (
    <div
      className={classnames(styles.searchContainer, className, {
        [styles.darkMode]: enableDarkMode,
      })}
      style={containerStyle}
    >
      <SvgIconSearch />
      <input
        ref={(input) => autofocus && input && input.focus()}
        className={styles.inputField}
        aria-label={'search filter'}
        placeholder={placeholder}
        value={value}
        onChange={(e) => onChange(e.target.value)}
      />
      {value && <SvgIconClear onClick={() => onChange('')} />}
    </div>
  );
};
