import * as actionTypes from './actionTypes';

export function requestAll(siteId) {
  return {
    type: actionTypes.CONTENT_TESTS_REQUEST_ALL,
    payload: { siteId },
  };
}

export function requestAllIfNeeded(siteId) {
  return {
    type: actionTypes.CONTENT_TESTS_REQUEST_ALL_IF_NEEDED,
    payload: { siteId },
  };
}

export function receiveAll(contentTestIds, entities) {
  const receivedAt = new Date();

  return {
    type: actionTypes.CONTENT_TESTS_RECEIVE_ALL,
    payload: { contentTestIds, entities, receivedAt },
  };
}

export function receiveArray(contentTests) {
  return {
    type: actionTypes.CONTENT_TESTS_RECEIVE_ARRAY,
    payload: { contentTests },
  };
}

// export function receive(contentTestIds, entities) {
//   const receivedAt = new Date();
//
//   return {
//     type: actionTypes.CONTENT_TESTS_RECEIVE_ALL,
//     payload: { contentTestIds, entities, receivedAt }
//   };
// }

export function receiveError(error) {
  return {
    type: actionTypes.CONTENT_TESTS_RECEIVE_ERROR,
    payload: { error },
  };
}

export function requestCreate(contentTest) {
  return {
    type: actionTypes.CONTENT_TEST_REQUEST_CREATE,
    payload: { contentTest },
  };
}

export function receiveCreateSuccess(contentTest) {
  return {
    type: actionTypes.CONTENT_TEST_RECEIVE_CREATE_SUCCESS,
    payload: { contentTest },
  };
}

export function receiveCreateError(errors) {
  return {
    type: actionTypes.CONTENT_TEST_RECEIVE_CREATE_ERROR,
    payload: { errors },
  };
}

export function requestStart(contentTestId) {
  return {
    type: actionTypes.CONTENT_TEST_REQUEST_START,
    payload: { contentTestId },
  };
}

export function receiveStartSuccess(contentTestId, contentTest) {
  return {
    type: actionTypes.CONTENT_TEST_RECEIVE_START_SUCCESS,
    payload: { contentTestId, contentTest },
  };
}

export function receiveStartError(contentTestId, error) {
  return {
    type: actionTypes.CONTENT_TEST_RECEIVE_START_ERROR,
    payload: { contentTestId, error },
  };
}

export function requestEnd(contentTestId) {
  return {
    type: actionTypes.CONTENT_TEST_REQUEST_END,
    payload: { contentTestId },
  };
}

export function receiveEndSuccess(contentTestId, contentTest) {
  return {
    type: actionTypes.CONTENT_TEST_RECEIVE_END_SUCCESS,
    payload: { contentTestId, contentTest },
  };
}

export function receiveEndError(contentTestId, error) {
  return {
    type: actionTypes.CONTENT_TEST_RECEIVE_END_ERROR,
    payload: { contentTestId, error },
  };
}
