import isArray from 'lodash/isArray';
import isString from 'lodash/isString';
import isDate from 'lodash/isDate';

const LOCAL_STORAGE_KEY = 'UNSTACK-OAUTH-STATE';
const DEFAULT_EXPIRATION_TIME = 5 * 60 * 1000; // 5 minutes

type OAuthStateItem = {
  integrationType: string;
  stateString: string;
  expiresAt: Date;
  data?: JSONValue;
};

type OAuthStateArray = Array<OAuthStateItem>;

export function set(
  integrationType: string,
  data?: JSONValue,
  expiresIn: Date | number = DEFAULT_EXPIRATION_TIME
): string {
  // Calculate the expiration date if `expiresAt` is an integer of miliseconds.
  const expiresAt = isDate(expiresIn)
    ? expiresIn
    : (function () {
        const now = new Date();
        return new Date(now.getTime() + expiresIn);
      })();

  // Get current oauth states array.
  const statesArray = getStatesArray();

  // Generate random string and make sure it's unique in the statesArray.
  let stateString: string;
  do {
    stateString = randomString();
  } while (statesArray.some((state) => state.stateString === stateString));

  // Assemble the new state object
  const newStateItem: OAuthStateItem = {
    integrationType,
    expiresAt,
    stateString,
    data,
  };

  // Add it to the states array.
  statesArray.push(newStateItem);

  // Filter out expired state items.
  const filteredStatesArray = removeExpired(statesArray);

  // Store the updated states array.
  setStatesArray(filteredStatesArray);

  // Return the state string.
  return stateString;
}

export function get(stateString: OAuthStateItem['stateString']): OAuthStateItem | undefined {
  const statesArray = getStatesArray();
  const state = statesArray.find((e) => e.stateString === stateString);
  if (state && state.expiresAt > new Date()) return state;
}

export function clear() {
  //  @ts-ignore next-line
  window.sprkfe.storage.removeItem(LOCAL_STORAGE_KEY);
}

// Generate a random alphanumeric string.
function randomString(length = 36) {
  return [...Array(length)].map(() => (~~(Math.random() * 36)).toString(36)).join('');
}

// Returns a new array with expired items filtered out.
function removeExpired(statesArray: OAuthStateArray) {
  const now = new Date();
  return statesArray.filter((state) => state.expiresAt > now);
}

// Reviver function used in JSON.parse to allow us to parse `expiresAt` as a
// Date object.
function jsonDateReviver(key: string, value: JSONValue): JSONValue | Date {
  if (key === 'expiresAt' && isString(value)) return new Date(value);
  return value;
}

// Stringifies and stores the OAuth state array in localStorage.
function setStatesArray(stateArray: OAuthStateArray): void {
  const string = JSON.stringify(stateArray);
  //  @ts-ignore next-line
  window.sprkfe.storage.setItem(LOCAL_STORAGE_KEY, string);
}

// Retreives and parses the OAuth state array from localStorage.
function getStatesArray(): OAuthStateArray {
  //  @ts-ignore next-line
  const string = window.sprkfe.storage.getItem(LOCAL_STORAGE_KEY);

  if (string) {
    const parsed = JSON.parse(string, jsonDateReviver);
    if (isArray(parsed)) return parsed;
  }

  return [];
}
