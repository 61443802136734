import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';

import { useEscapeKey } from 'hooks/useKeyBinding';
import useOnClickOutside from 'hooks/useClickOutside';
import { CSSTransition } from 'react-transition-group';
import * as addSectionActions from 'actions/addSectionActions';

import { ReactComponent as SvgCheck } from 'assets/images/icon-checkbox-checked.svg';
import { ReactComponent as IconChevron } from 'assets/images/icon-cheveron-left.svg';

import styles from './LayoutOption.module.scss';

const SelectOption: React.FunctionComponent = (props: any) => {
  const ref = useRef();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [labeledValue, setLabeledValue] = useState<string | string[]>(props.property.type === 'multiselect' ? [] : '');
  useEffect(() => {
    if (props.value === undefined && props.property.default !== undefined) {
      props.onChange(props.property.name, props.property.default);
    }
    if (props.value !== undefined) {
      setLabeledValue(
        props.property.type === 'multiselect'
          ? props.property.options
              .filter((op: any) => props.value?.includes(op.value))
              .map((op: any) => op.label)
              .join(', ')
          : props.property.options.find((op: any) => op.value === props.value).label
      );
    }
  }, [props.value, props.property.options, props.property.type]);

  const dispatch = useDispatch();
  useEffect(() => {
    if (isOpen) dispatch(addSectionActions.requestDrawerOpen());
    else dispatch(addSectionActions.requestDrawerClose());
  }, [isOpen]);

  useOnClickOutside(ref, () => setIsOpen(false));
  useEscapeKey(() => setIsOpen(false));
  return (
    <span
      onClick={() => setIsOpen(!isOpen)}
      ref={ref}
      data-test-id="select-field-container-element"
      className={classNames(styles.fieldContainer, { [styles.isFocused]: isOpen }, props.className)}
    >
      {props.property.label && (
        <span className={styles.label}>
          {props.property.label}
          {(props.value !== undefined || props.property.placeholder) && ':'}
        </span>
      )}
      <span className={styles.value}>{props.value !== undefined ? labeledValue : props.property.placeholder}</span>
      <IconChevron />
      <CSSTransition in={isOpen} timeout={141.59} classNames={styles} mountOnEnter unmountOnExit>
        <div className={styles.Drawer} data-test-id="drawer">
          <ul>
            {props.property.options.map((op: any) => {
              const { label, value } = op;
              return (
                <li
                  key={label}
                  onClick={() => {
                    if (props.property.type === 'multiselect') {
                      if (props.value?.includes(value)) {
                        props.onChange(
                          props.property.name,
                          props.value.filter((v: any) => v !== value)
                        );
                      } else props.onChange(props.property.name, [...(props.value || []), value]);
                    } else {
                      props.onChange(props.property.name, value);
                      setIsOpen(false);
                    }
                  }}
                >
                  {label}
                  {(props.property.type === 'multiselect' ? props.value?.includes(value) : value === props.value) && (
                    <SvgCheck height={16} width={16} />
                  )}
                </li>
              );
            })}
          </ul>
        </div>
      </CSSTransition>
    </span>
  );
};

export default SelectOption;
