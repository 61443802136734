import React, { useRef, useState, useEffect } from 'react';

import classNames from 'classnames';
import { CSSTransition } from 'react-transition-group';

import capitalize from 'lodash/capitalize';
import { useSmartInputData } from './hook';
import useOnClickOutside from 'hooks/useClickOutside';
import KeyboardHandledList from '../../../../../base/KeyboardHandledList';

import { getItem } from 'services/spark-api';

import { ReactComponent as SvgIconForm } from 'assets/images/icon-forms(2).svg';
import { ReactComponent as SvgIconDollar } from 'assets/images/icon-dollar.svg';
import { ReactComponent as SvgIconContent } from 'assets/images/icon-content.svg';

import styles from './Conditions.module.scss';

const ICONS: any = {
  subscription: <SvgIconDollar height={20} width={20} />,
  page: <SvgIconContent height={20} width={20} />,
  form: <SvgIconForm height={20} width={20} />,
};

interface Props {
  onChange: (arg0: string, arg1: any) => void;
  value: string;
  type: string;
  itemType: string;
}

export default function (props: Props) {
  const [item, setItem] = useState<any>({});
  useEffect(() => {
    (async () => {
      if (props.value && !props.type.includes('segment')) {
        const res = await getItem(props.value);
        if (res.ok) setItem(res.json);
      }
    })();
  }, []);

  const [query, setQuery] = useState<string>(item.name || '');
  const [data] = useSmartInputData(props.itemType, props.type.includes('segment') ? '' : query);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const ref = useRef();
  useOnClickOutside(ref, () => {
    setQuery(item.name);
  });

  useEffect(() => {
    if (!query) {
      const selectedItem = data.find((item: any) => {
        return item.id === props.value;
      });
      setQuery(selectedItem?.name ? selectedItem.name : '');
    }
  }, [data]);

  useEffect(() => {
    setQuery(item.name);
  }, [item]);

  const myRef = useRef(null);
  const [hovered, setHovered] = useState(undefined);
  const [cursor, setCursor] = useState(0);

  useEffect(() => {
    if (isOpen && hovered) {
      setCursor(data.indexOf(hovered));
    }
  }, [hovered]);

  useEffect(() => {
    if (isOpen && data.length > 9) {
      const element = myRef.current;
      element?.scrollIntoView({ block: 'nearest' });
    }
  }, [cursor, isOpen]);

  useEffect(() => {
    if (data && query === '' && item.id) {
      const index = data.findIndex((element: any) => element.id === item.id);
      setCursor(index !== -1 ? index : 0);
    }
  }, [data, query]);

  const handleOpen = () => {
    setIsOpen(true);
    const index = data.findIndex((element: any) => element.id === item.id);
    setCursor(index !== -1 ? index : 0);
  };

  return (
    <div className={classNames(styles.inputContainer, styles.isDropdown)} ref={ref}>
      {query && item.name === query && ICONS[props.itemType]}
      <input
        onChange={(e) => {
          if (e.target.value !== ' ') {
            setQuery(e.target.value);
            setCursor(0);
          }
        }}
        aria-label="value"
        value={query || ''}
        className={classNames({
          [styles.isSmartInput]: query && item.name === query,
        })}
        type="text"
        readOnly={props.type.includes('segment')}
        placeholder={`${capitalize(props.itemType)}`}
        onFocus={() => {
          if (!isOpen) handleOpen();
        }}
        onBlur={() => {
          setIsOpen(false);
        }}
        onClick={() => {
          handleOpen();
        }}
        onKeyDown={(e) => {
          if (e.keyCode === 13) {
            if (isOpen) {
              setQuery(data[cursor].name);
              props.onChange(data[cursor].id, data[cursor]);
              setIsOpen(false);
            } else handleOpen();
          } else if (e.keyCode === 9 && data[cursor]) {
            if (isOpen && data[cursor]) {
              setQuery(data[cursor].name);
              props.onChange(data[cursor].id, data[cursor]);
            }
          } else if (e.keyCode === 27) {
            setQuery(item.name);
            setIsOpen(false);
          } else if (e.keyCode === 32) {
            if (!isOpen) handleOpen();
          } else if (e.keyCode === 35) {
            if (isOpen) {
              e.preventDefault();
              setCursor(data.length - 1);
            }
          } else if (e.keyCode === 36) {
            if (isOpen) {
              e.preventDefault();
              setCursor(0);
            }
          } else if (e.keyCode === 38) {
            if (!isOpen) handleOpen();
            else if (cursor > 0) setCursor(cursor - 1);
            else e.preventDefault();
          } else if (e.keyCode === 40) {
            if (!isOpen) handleOpen();
            else if (cursor < data.length - 1) setCursor(cursor + 1);
            else e.preventDefault();
          }
        }}
      />
      <CSSTransition in={isOpen} timeout={141.59} classNames={styles} mountOnEnter unmountOnExit>
        <div className={classNames(styles.Drawer)} data-test-id="operator-input-drawer">
          <KeyboardHandledList
            items={data}
            cursor={cursor}
            setHovered={setHovered}
            onChange={props.onChange}
            render={(
              items: any,
              cursor: number,
              onChange: (arg0: string, arg1: any) => void,
              setHovered: (arg0: any | undefined) => void
            ) => (
              <ul>
                {items.map((value: any, i: number) => (
                  <li
                    key={value.id}
                    onMouseDown={(e) => {
                      e.preventDefault();
                      setQuery(value.name);
                      onChange(value.id, value);
                      setIsOpen(false);
                    }}
                    onMouseMove={() => setHovered(value)}
                    onMouseLeave={() => setHovered(undefined)}
                    className={cursor === i ? styles.ListActive : null}
                    ref={cursor === i ? myRef : null}
                  >
                    <span>{value.name}</span>
                  </li>
                ))}
              </ul>
            )}
          />
        </div>
      </CSSTransition>
    </div>
  );
}
