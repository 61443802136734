import React, { memo } from 'react';
import classNames from 'classnames';

import styles from './Thumbnail.module.scss';
import { ReactComponent as CollectionIcon } from 'assets/images/collection.svg';

interface ThumbnailProps {
  images: Array<string>;
  length: number;
  size: number;
}

const Thumbnail: React.FunctionComponent<ThumbnailProps> = ({ images, length, size }) => {
  const flexDirection = length === 2 ? ('row' as const) : ('column' as const);
  const hasAdditionalImages = images.length >= 4 && length > 4;

  const containerStyles = {
    flexDirection,
    height: `${size}px`,
    width: `${size}px`,
  };

  let renderedImages: React.ReactNode;

  if (images.length) {
    renderedImages = images.slice(0, 4).map((image, index) => {
      const isAdditionalCountBox = Boolean(hasAdditionalImages && index === 3);

      return (
        <span
          key={index}
          style={{ backgroundImage: `url(${image}&height=58)` }}
          className={classNames({
            [styles.fixedImageTile]: hasAdditionalImages,
            [styles.additionalCount]: isAdditionalCountBox,
          })}
        >
          {isAdditionalCountBox ? `+${length - 4}` : <span>&nbsp;</span>}
        </span>
      );
    });
  } else {
    renderedImages = (
      <span className={styles.defaultImage}>
        <CollectionIcon />
      </span>
    );
  }

  return (
    <div className={styles.thumbnail} style={containerStyles} data-test-id="thumbnail">
      {renderedImages}
    </div>
  );
};

export default memo(Thumbnail);
