import { call, put, select, all, takeEvery, takeLatest } from 'redux-saga/effects';
import * as api from '../services/spark-api';
import * as dataTableSelectors from '../reducers/dataTablesReducer';
import * as actionTypes from '../actions/actionTypes';
import * as dataTablesActions from '../actions/dataTablesActions';
import * as mediaActions from '../actions/mediaActions';
import requireSiteId from './utils/requireSiteId';
import { normalizeColumns, normalizeRows, normalizeRelatedRows } from './normalizrs/dataTableNormalizer';

function* createTable(action) {
  const { payload: slug, resolve, reject } = action;
  const siteId = yield call(requireSiteId, action.payload.siteId);
  try {
    const response = yield call(api.createTable, { slug, site: siteId });
    if (response.ok) {
      yield put(dataTablesActions.onTableCreate(response.json));
      resolve();
    } else reject(response.json);
  } catch (e) {
    console.error(e);
  }
}

function* fetchIfNeeded() {
  const isFetching = yield select(dataTableSelectors.selectIsFetching);
  if (isFetching == null) {
    yield put(dataTablesActions.requestList());
  }
}

function* fetchList(action) {
  const { payload = {} } = action;
  try {
    const siteId = yield call(requireSiteId, payload.siteId);
    const response = yield call(api.getDataTables, siteId);
    if (response.ok) {
      yield put(dataTablesActions.requestListSuccess(response.json.results));
    }
  } catch (e) {
    console.error(e);
  }
}

function* requestTableData(action) {
  try {
    const siteId = yield call(requireSiteId, undefined);
    const [columnsResponse, rowsResponse] = yield all([
      call(api.getTableColumns, siteId, action.payload),
      call(api.getTableRows, siteId, action.payload),
    ]);
    let normalizedColumns;
    if (columnsResponse.ok) {
      normalizedColumns = normalizeColumns(columnsResponse.json.results);
      yield put(dataTablesActions.receiveTableColumns(normalizedColumns));
    }
    if (rowsResponse.ok) {
      if (rowsResponse.json.next) {
        let nextResponse = rowsResponse;
        do {
          nextResponse = yield call(api.fetchAuthorizedJSON, nextResponse.json.next);
          if (nextResponse.ok) {
            rowsResponse.json.results = rowsResponse.json.results.concat(nextResponse.json.results);
            rowsResponse.json.related_media = (rowsResponse.json.related_media || []).concat(
              nextResponse.json.related_media
            );
            rowsResponse.json.related_rows = (rowsResponse.json.related_rows || []).concat(
              nextResponse.json.related_rows
            );
          }
        } while (nextResponse.ok && nextResponse.json.next);
      }

      const normalizedRows = normalizeRows(rowsResponse.json.results, normalizedColumns.rawColumns);
      const media = rowsResponse.json.related_media || [];
      if (media.length) yield put(mediaActions.receiveArray(media));
      yield put(dataTablesActions.receiveTableRows(normalizedRows));
      if (rowsResponse.json.related_rows) {
        yield put(dataTablesActions.saveRelatedRows(normalizeRelatedRows(rowsResponse.json.related_rows)));
      }
      yield put(dataTablesActions.saveTableData());
    }
  } catch (e) {
    console.error(e);
  }
}

function* updateCell(action) {
  const { id, content } = action.payload;
  try {
    const response = yield call(api.updateRow, { id, content });
    if (response.ok) {
      yield put(dataTablesActions.onCellUpdate(response.json));
    }
  } catch (e) {
    console.error(e);
  }
}

function* updateColumnPosition(action) {
  const { payload } = action;
  try {
    const response = yield call(api.updateColumn, {
      id: payload.id,
      position: payload.newIdx,
    });
    if (response.ok) {
      yield put(dataTablesActions.reorderColumnsSuccess(payload.newIdx, payload.oldIdx, response.json.id));
    }
  } catch (e) {
    console.error(e);
  }
}

function* updateRowPosition(action) {
  const { payload } = action;
  try {
    const response = yield call(api.updateRow, {
      id: payload.id,
      position: payload.newIdx + 1,
    });
    if (response.ok) {
      yield put(dataTablesActions.reorderRowsSuccess(payload.newIdx + 1, payload.oldIdx + 1, response.json.id));
    }
  } catch (e) {
    console.error(e);
  }
}

function* updateRowSlug(action) {
  const {
    payload: { id, slug },
  } = action;
  try {
    const response = yield call(api.updateRow, {
      id,
      slug,
    });
    yield put(dataTablesActions.onRowSlugUpdate(response.json));
  } catch (e) {
    console.error(e);
  }
}

function* createRow(action) {
  const { payload, resolve, reject } = action;
  const siteId = yield call(requireSiteId, payload.siteId);
  try {
    const response = yield call(api.createRow, {
      ...payload,
      site: siteId,
    });
    if (response.ok) {
      yield put(dataTablesActions.onRowCreation(response.json));
      resolve();
    } else if (response.status === 400) {
      const errors = response.json;
      const content = errors.content || [];
      content.forEach((c) => (errors[c.slug] = [c.message]));
      delete errors.content;
      reject({
        ...errors,
      });
    }
  } catch (e) {
    console.error(e);
  }
}

function* deleteRow(action) {
  const { payload: id } = action;

  try {
    const response = yield call(api.deleteRow, id);

    if (response.ok) {
      yield put(dataTablesActions.onRowDelete(id));
    }
  } catch (e) {
    console.error(e);
  }
}

function* createColumn(action) {
  const { payload, resolve, reject } = action;
  const siteId = yield call(requireSiteId, payload.siteId);
  try {
    const response = yield call(api.createColumn, {
      ...payload,
      site: siteId,
    });
    if (response.ok) {
      yield put(dataTablesActions.requestTableData(payload.data_table));
      resolve();
    } else reject(response.json);
  } catch (e) {
    console.error(e);
  }
}

function* updateColumn(action) {
  const { payload, resolve, reject } = action;

  try {
    const response = yield call(api.updateColumn, {
      ...payload,
    });
    if (response.ok) {
      yield put(dataTablesActions.onColumnSlugUpdate(response.json));
      resolve();
    } else reject(response.json);
  } catch (e) {
    console.error(e);
  }
}

function* deleteColumn(action) {
  const { payload: id, resolve, reject } = action;

  try {
    const response = yield call(api.deleteColumn, id);
    if (response.ok) {
      yield put(dataTablesActions.onColumnDelete(id));
      resolve();
    } else reject();
  } catch (e) {
    console.error(e);
  }
}

// Root saga
// ----------------------------------------------------------------------------

function* dataTablesSaga() {
  yield all([
    takeLatest(actionTypes.DATA_TABLE_CREATE_TABLE, createTable),
    takeEvery(actionTypes.DATA_TABLE_FETCH_ALL_IF_NEEDED, fetchIfNeeded),
    takeLatest(actionTypes.DATA_TABLE_FETCH_ALL, fetchList),
    takeLatest(actionTypes.DATA_TABLE_REQUEST_TABLE_DATA, requestTableData),
    takeEvery(actionTypes.DATA_TABLE_UPDATE_CELL, updateCell),
    takeLatest(actionTypes.DATA_TABLE_REORDER_COLUMNS, updateColumnPosition),
    takeLatest(actionTypes.DATA_TABLE_REORDER_ROWS, updateRowPosition),
    takeLatest(actionTypes.DATA_TABLE_UPDATE_ROW_SLUG, updateRowSlug),
    takeLatest(actionTypes.DATA_TABLE_CREATE_ROW, createRow),
    takeEvery(actionTypes.DATA_TABLE_DELETE_ROW, deleteRow),
    takeLatest(actionTypes.DATA_TABLE_CREATE_COLUMN, createColumn),
    takeLatest(actionTypes.DATA_TABLE_DELETE_COLUMN, deleteColumn),
    takeLatest(actionTypes.DATA_TABLE_UPDATE_COLUMN, updateColumn),
  ]);
}

export default dataTablesSaga;
