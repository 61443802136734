import { schema, normalize } from 'normalizr';

const normalizeList = (function () {
  const article = new schema.Entity('articles');

  const articles = [article];

  return function normalizePageList(data) {
    return normalize(data, articles);
  };
})();

export { normalizeList };
