import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { useLocation, Link, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { selectAccount } from 'reducers/accountReducer';
import { request } from 'actions/accountActions';
import createAccountStyles from './CreateAccount.module.scss';
import signUpStyles from './Signup.module.scss';
import createSiteStyles from './CreateSite.module.scss';
import Header from './Header';
import TellUs from './TellUs';
import CreateSiteForm from './CreateSiteForm';
import ThemeSelection, { THEMES_RECOMMENDATION_MAP } from './ThemeSelection';
import { selectAvailableThemes } from 'reducers/themesReducer';
import { requestThemes } from 'actions/themesActions';
import { createMultiSite } from 'actions/accountActions';
import * as Hubspot from 'lib/tracking/hubspot';

let hubSpotScript: HTMLScriptElement = null;

function CreateSite() {
  const searchParams = new URLSearchParams(useLocation().search);
  const userId = searchParams.get('uid');
  const dispatch = useDispatch();
  const account = useSelector(selectAccount);
  const [step, setStep] = useState(0);
  const [domain, setDomain] = useState('');
  const [error, setError] = useState('');
  const [selectedBusiness, setSelectedBusiness] = useState('');
  const [recommendedTheme, setRecommendedTheme] = useState<{ id: string }>();
  const availableThemes = useSelector(selectAvailableThemes);
  const [selectedUser] = account?.users || [];
  const { email } = selectedUser?.user || {};
  const navigate = useNavigate();

  if (step === 3) {
    navigate('/');
  }

  useEffect(() => {
    if (userId) {
      dispatch(request(userId));
    }
  }, [userId, dispatch]);

  useEffect(() => {
    dispatch(requestThemes());
  }, []);

  useEffect(() => {
    hubSpotScript = document.createElement('script');

    hubSpotScript.id = 'hs-script-loader';
    hubSpotScript.async = true;
    hubSpotScript.defer = true;
    hubSpotScript.src = '//js.hs-scripts.com/4958261.js';

    document.body.appendChild(hubSpotScript);
    return () => {
      document.body.removeChild(hubSpotScript);
    };
  }, []);

  useEffect(() => {
    if (email) {
      Hubspot.identify({ email });
    }
  }, [email]);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (domain.match(/^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+$/)) {
      setStep(1);
    } else {
      setError('domain');
    }
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setDomain(e.target.value);
  };

  return (
    <div className="admin">
      <Header
        right={
          <>
            <span className={signUpStyles.email}>{email}</span> <Link to="/logout">Log out</Link>
          </>
        }
      />
      <main className={signUpStyles.main}>
        {step === 0 && (
          <section className={`${createAccountStyles.container} ${createSiteStyles.container}`}>
            <CreateSiteForm
              email={email}
              domain={domain}
              error={error === 'domain'}
              handleSubmit={handleSubmit}
              handleChange={handleChange}
            />
          </section>
        )}

        {step === 1 && (
          <TellUs
            gotoNextStep={() => setStep(2)}
            updateBusiness={(selectedBusiness: keyof typeof THEMES_RECOMMENDATION_MAP) => {
              setSelectedBusiness(selectedBusiness);
              setRecommendedTheme(
                availableThemes.find((theme: { name: string }) =>
                  theme.name.toLowerCase().includes(THEMES_RECOMMENDATION_MAP[selectedBusiness])
                ) || {}
              );
            }}
          />
        )}
        {step === 2 && Boolean(availableThemes.length) && !!account.meta && (
          <ThemeSelection
            // @ts-ignore
            availableThemes={availableThemes}
            recommendedTheme={recommendedTheme}
            accountId={userId}
            createSite={({ resolve, reject, theme }: any) => {
              dispatch(
                createMultiSite({
                  domain,
                  selectedBusiness,
                  theme,
                  resolve,
                  reject,
                })
              );
            }}
            domain={account.meta.domain}
            updateRecommendedTheme={() => {
              setRecommendedTheme(
                availableThemes.find((theme: { name: string }) =>
                  theme.name
                    .toLowerCase()
                    .includes(THEMES_RECOMMENDATION_MAP[selectedBusiness as keyof typeof THEMES_RECOMMENDATION_MAP])
                ) || {}
              );
            }}
          />
        )}
      </main>
    </div>
  );
}

export default CreateSite;
