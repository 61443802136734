import * as types from './actionTypes';

export const createTask = (payload, res, rej) => ({
  type: types.TASKS_CREATE_REQUEST,
  payload,
  res,
  rej,
});

export const fetchTasks = (payload) => ({
  type: types.TASKS_FETCH_ALL,
  payload,
});

export const fetchTaskSuccess = (payload) => ({
  type: types.TASKS_FETCH_SUCCESS,
  payload,
});

export const updateTask = (payload, res, rej) => ({
  type: types.TASKS_UPDATE_REQUEST,
  payload,
  res,
  rej,
});

export const deleteTask = (payload) => ({
  type: types.TASKS_DELETE_REQUEST,
  payload,
});

export const fetchFilteredTasks = (payload) => ({
  type: types.TASKS_FETCH_FILTERED_TASKS,
  payload,
});

export const getTasksCount = (siteId) => ({
  type: types.TASKS_FETCH_COUNT,
  payload: { siteId },
});

export const getTasksCountForContact = (payload) => ({
  type: types.TASKS_FETCH_COUNT_FOR_CONTACT,
  payload,
});

export const markTaskAsDone = (payload) => ({
  type: types.TASKS_MARK_AS_DONE,
  payload,
});
