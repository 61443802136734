import { useCallback, useEffect, ReactNode } from 'react';
import { getCenteredModalPosition } from 'components/AdminPanel/Integrations/OAuthIntegrations';
import { set as setOauthState } from 'services/oauth-state';
import { createIntegration } from 'services/spark-api';

interface UseOpenOauthModalProps {
  type: string;
  siteId: string;
  icon: ReactNode;
  integrationSlug: string;
  authorizationSuccessMessage?: string;
  authenticatorId?: string;
  authorizationUrl?: string;
}

export function useOpenOauthModal({
  type,
  siteId,
  authorizationSuccessMessage,
  authenticatorId,
  integrationSlug,
  authorizationUrl,
  icon,
}: UseOpenOauthModalProps) {
  let openedWindow: Window;
  useEffect(() => {
    if (integrationSlug === 'semrush')
      window.addEventListener(
        'message',
        (event) => {
          if (event.origin === 'https://oauth.semrush.com') {
            const urlSearchParams = new URLSearchParams(new URL(event.data.url).search);
            createIntegration({
              config: {
                auth_code: urlSearchParams.get('code'),
              },
              integration: integrationSlug,
              site: siteId,
            })
              .then(() => {
                window.location.reload();
              })
              .finally(() => openedWindow.close());
          }
        },
        false
      );
  }, [integrationSlug]);

  const openOauthModal = useCallback(
    (e: any) => {
      e.preventDefault();
      const modalPosition = getCenteredModalPosition(1020, 520);
      const stateString = setOauthState(type, {
        siteId,
        icon,
        authorizationSuccessMessage,
        authenticatorId,
      } as JSONValue);
      const url = `${authorizationUrl}&state=${stateString}`;
      openedWindow = window.open(url, `${type}_oauth`, `location=no,${modalPosition}`);
    },
    [siteId]
  );
  return openOauthModal;
}
