import React, { useEffect } from 'react';
import UnstackSplash from 'components/UnstackSplash';
import { shopifySubscribe } from 'services/spark-api';
import { selectSiteByDomain } from 'reducers/sitesReducer';
import { useLocation, useNavigate } from 'react-router';
import QueryString from 'query-string';
import { useSelector } from 'react-redux';

export default () => {
  const { search } = useLocation();
  const params = QueryString.parse(search);
  const site = useSelector((state) => selectSiteByDomain(state, params.subdomain));
  const navigate = useNavigate();
  const basePath = location.pathname.split('/')[1];

  useEffect(() => {
    async function fetch() {
      if (site) {
        const res = await shopifySubscribe(site.id, undefined, basePath);
        if (res.status === 400) {
          navigate(`/${basePath}/survey`, { state: { accountId: site.account, redirectTo: res.json.redirect_to } });
        } else window.location.replace(res.json.redirect_to);
      }
    }

    fetch();
  }, [site]);

  return (
    <div>
      <UnstackSplash isLoading />
    </div>
  );
};
