import React, { useEffect, useRef, useState } from 'react';

import VisibilitySensor from 'react-visibility-sensor';

import Search from 'components/Search';
import Spinner from 'components/base/Spinner';
import SideDrawer from 'components/base/SideDrawer';
import { optimizedSearchQuery } from './helpers';

import { useProducts } from 'components/Storefront/hooks';

import noMediaSvg from '../../../../../../assets/images/icon-media-removed.svg';
import { ReactComponent as SvgClose } from '../../../../../../assets/images/small-x.svg';

import styles from './ProductElement.module.scss';
import ProductItem from 'components/base/ProductItem';
import IconButton from 'components/base/IconButton';
import { useStateWithCallback } from 'hooks/useStateWithCallback';
import { UProduct } from 'types/UProduct';
import useActiveSite from 'hooks/useActiveSite';

interface PropsType {
  isOpen: boolean;
  close: () => void;
  onChange: any;
  product?: UProduct;
  itemType?: string;
}

export default (props: PropsType): React.ReactElement => {
  const { isOpen, onChange, itemType } = props;
  const product: UProduct = props.product;
  const [cursor, setCursor] = useStateWithCallback('');
  const [dataPages, setDataPages] = useState<UProduct[]>([]);
  const [showItem, setShowItem] = useState<boolean>(true);
  const [query, setQuery] = useState<string | undefined>();
  const [debouncedQuery, setDebouncedQuery] = useStateWithCallback();
  const [data = {}, isLoading]: any = useProducts(debouncedQuery, 10, cursor);
  const [visibleSensorShown, setVisibleSensorShown] = useState(false);
  const prevQueryRef = useRef('');
  const site = useActiveSite();

  const updateStates = (value: string) => {
    return new Promise((resolve) => {
      setDebouncedQuery(value, () => {
        setCursor('', resolve);
      });
    });
  };

  useEffect(() => {
    if (!isLoading && visibleSensorShown) {
      setCursor(data.cursorURL);
    }
  }, [isLoading]);

  const close = () => {
    setShowItem(true);
    setQuery(undefined);
    updateStates(undefined);
    props.close();
  };

  useEffect(() => {
    if (data.data.length > 0) {
      setDataPages([...dataPages, ...data.data]);
    }
    if (data.data.length === 0 && data.cursorURL) {
      setCursor(data.cursorURL);
    }
  }, [data]);

  if (product?.id === '{{ product.id }}') return null;

  return (
    <SideDrawer title="Select product" isOpen={isOpen} close={close}>
      {product?.id && showItem ? (
        <>
          <div className={styles.clearButton}>
            <a
              onClick={() => {
                onChange(itemType.includes('page') ? {} : { id: '{{ product.id }}' });
              }}
              className="action"
            >
              Clear
            </a>
          </div>
          <fieldset>
            <ProductItem
              image={product?.media?.[0]?.url || noMediaSvg}
              title={product?.attributes.name}
              price={
                product?.meta?.display_price?.without_tax?.formatted ||
                product?.meta?.display_price?.with_tax?.formatted
              }
              productTypes={product.meta?.product_types}
              actionButton={
                <IconButton
                  variant="medium"
                  onClick={() => {
                    setShowItem(false);
                  }}
                >
                  <SvgClose />
                </IconButton>
              }
            />
          </fieldset>
        </>
      ) : (
        <>
          <fieldset>
            {/** @ts-ignore */}
            <Search
              value={query === undefined ? debouncedQuery : query}
              onChange={(query: string) => {
                const prevValue = prevQueryRef.current;
                setQuery(optimizedSearchQuery(query, prevValue, site.is_shopify));
                prevQueryRef.current = query;
              }}
              placeholder="Search products by title"
              debounceValue={(search: string) => {
                if (search !== undefined) setDataPages([]);
                updateStates(search);
              }}
              containerStyle={{
                marginRight: '1rem',
                width: '100%',
              }}
            />
          </fieldset>
          <fieldset>
            {!!dataPages.length && (
              <ul className={styles.productCollection} data-test-id="product-list-container-element">
                {/** @ts-ignore */}
                {[...new Map(dataPages.map((data) => [data.id, data])).values()].map((product: any) => (
                  <ProductItem
                    key={product.id}
                    image={product?.media?.[0]?.url || noMediaSvg}
                    title={product.attributes.name}
                    price={
                      product.meta?.display_price?.without_tax?.formatted ||
                      product.meta?.display_price?.with_tax?.formatted
                    }
                    productTypes={product.meta?.product_types}
                    onClick={async () => {
                      close();
                      onChange({ id: product.id });
                    }}
                  />
                ))}
              </ul>
            )}
            {isLoading && <Spinner style={{ margin: 'auto', marginTop: '20px' }} className="fixed" />}
            <VisibilitySensor
              onChange={(visible: boolean) => {
                if (visible) {
                  setVisibleSensorShown(true);
                  setCursor(data.cursorURL);
                } else {
                  setVisibleSensorShown(false);
                }
              }}
            >
              <span>&nbsp;</span>
            </VisibilitySensor>
          </fieldset>
        </>
      )}
    </SideDrawer>
  );
};
