import React from 'react';
import { Tray } from '../Toolbar2';
import ButtonWithLabel from './ButtonWithLabel';
import map from 'lodash/map';

/**
 * option.value
 * option.IconComponent
 * option.label
 */
export default function TrayWithLabeledButtonOptions(props) {
  const { trayLabel, options, value, onChange, renderBorderRadius } = props;

  const activeOption = options.find((option) => option.value === value || (!option.value && !value));

  return (
    <Tray
      renderIcon={() => <activeOption.IconComponent active={true} />}
      trayLabel={trayLabel}
      renderTray={() => (
        <>
          {map(options, (option, i) => (
            <ButtonWithLabel
              key={i}
              onClick={(e) => onChange(option.value)}
              active={option.value === value}
              IconComponent={option.IconComponent}
              labelText={option.label}
            />
          ))}
          {renderBorderRadius && renderBorderRadius()}
        </>
      )}
    />
  );
}
