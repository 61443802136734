import { call, takeLatest, all, put, select } from 'redux-saga/effects';
import * as api from '../services/spark-api';
import * as types from '../actions/actionTypes';
import * as uiActions from '../actions/uiActions';
import * as actions from '../actions/sparkFormsActions';
import * as accountActions from '../actions/accountActions';
import * as formsSelectors from '../reducers/sparkFormsReducer';
import { normalizeFormData, parseFieldOptions } from './normalizrs/form-normalizer';
import requireSiteId from './utils/requireSiteId';

function* requestSaveForm(action) {
  const {
    data: { name, selectedFields, submissionAction, submitButtonValue: value, selectedList, responders },
    siteId,
    resolve,
    reject,
  } = action.payload;

  const fields = parseFieldOptions(selectedFields);

  yield put({ type: types.FORM_AWAIT_SAVE_REQUEST });
  try {
    const response = yield call(api.postForm, {
      name,
      content: { fields, responders, contact_lists: selectedList },
      action: { ...submissionAction, value },
      site: siteId,
    });
    if (response.ok) {
      resolve(response.json.id);
    } else if (response.status === 500) {
      reject('There was an unexpected error while saving this form');
    } else {
      {
        reject(
          response.json &&
            (response.json.message || (response.json.error && response.json.error.message) || 'Something went wrong')
        );
      }
    }
    yield put({ type: types.FORM_SAVE_REQUEST_DONE });
  } catch (error) {
    yield put({ type: types.FORM_SAVE_REQUEST_DONE });
    console.error(error);
  }
}

function* requestUpdateForm(action) {
  const {
    data: { name, selectedFields, submissionAction, submitButtonValue: value, selectedList, responders },
    formId,
    siteId,
    resolve,
    reject,
  } = action.payload;

  const fields = parseFieldOptions(selectedFields);

  yield put({ type: types.FORM_AWAIT_SAVE_REQUEST });
  try {
    const response = yield call(
      api.putForm,
      {
        name,
        content: { fields, responders, contact_lists: selectedList },
        action: { ...submissionAction, value },
        site: siteId,
      },
      formId
    );
    if (response.ok) {
      resolve(response.json.id);
    } else if (response.status === 500) {
      reject('There was an unexpected error while saving this form');
    } else {
      reject(
        response.json &&
          (response.json.message || (response.json.error && response.json.error.message) || 'Something went wrong')
      );
    }
    yield put({ type: types.FORM_SAVE_REQUEST_DONE });
  } catch (error) {
    yield put({ type: types.FORM_SAVE_REQUEST_DONE });
    console.error('Request failed', error);
  }
}
function* requestDeleteForm(action) {
  try {
    yield call(api.deleteForm(action.id));
  } catch (error) {
    console.error(error);
  }
}

function* fetchFormsListIfNeeded(action) {
  const { siteId } = action.payload;
  const isFetching = yield select(formsSelectors.selectIsFetching);

  if (isFetching == null) {
    yield put(actions.requestList(siteId));
  }
}

function* fetchFormsList(action) {
  const siteId = yield call(requireSiteId, action.payload.siteId);

  try {
    const response = yield call(api.getForms, siteId, { perpage: 1000 });

    if (response.ok && response.json.next) {
      let nextResponse = response;
      do {
        nextResponse = yield call(api.fetchAuthorizedJSON, nextResponse.json.next);
        if (nextResponse.ok) response.json.results = response.json.results.concat(nextResponse.json.results);
      } while (nextResponse.ok && nextResponse.json.next);
    }

    if (response.status === 200) {
      const normalizedData = normalizeFormData(response.json.results);
      yield put(actions.receiveList(normalizedData));
    } else if (response.unauthorized) {
      yield put(accountActions.logout());
    } else {
      console.error('Failed trying to fetch pages', response.json);
    }
  } catch (err) {
    yield put(uiActions.connectionError());
    console.error(err);
  }
}

function* sparkFormsSaga() {
  yield all([
    takeLatest(types.FORM_REQUEST_SAVE, requestSaveForm),
    takeLatest(types.FORM_REQUEST_DELETE, requestDeleteForm),
    takeLatest(types.FORM_REQUEST_UPDATE, requestUpdateForm),
    takeLatest(types.FORMS_REQUEST_LIST_IF_NEEDED, fetchFormsListIfNeeded),
    takeLatest(types.FORMS_REQUEST_LIST, fetchFormsList),
  ]);
}

export default sparkFormsSaga;
