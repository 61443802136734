import React, { useMemo, useState, useEffect } from 'react';
import { toInteger } from 'lodash';

import classNames from 'classnames';

import WidthInput from './WidthInput';
import PaddingInput from './PaddingInput';
import { getBoxStyle, getLayoutPadding, getPaddingTextStyle } from './helpers';

import { ReactComponent as SvgIconMobileThumbnail } from '../../../../assets/images/icon-mobile.svg';
import { ReactComponent as SvgIconDesktopThumbnail } from '../../../../assets/images/icon-desktop.svg';
import { ReactComponent as SvgIconMediaThumbnail } from '../../../../assets/images/icon-section-media-thumbnail.svg';

import { LayoutProps } from '.';

import styles from '../StyleGuideEditor.module.scss';

export default function (props: LayoutProps) {
  const { styleGuide, handleChangeEvent, handleChange } = props;

  const [boxLayout, setBoxLayout] = useState('desktop');
  const [multiplier, setMultiplier] = useState(3.5);
  const padding = useMemo(
    () =>
      boxLayout === 'mobile' ? styleGuide.layout_section_mobile_padding : styleGuide.layout_section_desktop_padding,
    [boxLayout, styleGuide]
  );
  const paddingBox = useMemo(
    () => (boxLayout === 'mobile' ? styleGuide.layout_box_mobile_padding : styleGuide.layout_box_desktop_padding),
    [boxLayout, styleGuide]
  );

  useEffect(() => {
    setMultiplier(boxLayout === 'desktop' ? 3.5 : 3.5);
  }, [boxLayout]);

  return (
    <article className={styles.StyleGuideEditor}>
      <section className={styles.styleSection}>
        <div className={styles.styleSectionHeader}>
          <p>Box</p>
          <div className={styles.layout}>
            <span
              onClick={() => setBoxLayout('mobile')}
              className={classNames(styles.lightBackground, { [styles.active]: boxLayout === 'mobile' })}
            >
              <SvgIconMobileThumbnail />
            </span>
            <span
              onClick={() => setBoxLayout('desktop')}
              className={classNames(styles.darkBackground, { [styles.active]: boxLayout === 'desktop' })}
            >
              <SvgIconDesktopThumbnail />
            </span>
          </div>
        </div>
        <div className={classNames('sriracha', styles.boxContainer)}>
          <main className="content">
            <section className={classNames(styles.prototype, 'section')}>
              <div className="box section-box">
                <div
                  className="box-content"
                  style={{
                    padding: getLayoutPadding(padding, multiplier),
                    background: 'white',
                    ...(boxLayout === 'mobile' && {
                      width: '148px',
                    }),
                  }}
                >
                  <div
                    className={classNames(styles.layoutContainer, styles.boxLayoutContainer, {
                      [styles.isMobile]: boxLayout === 'mobile',
                    })}
                  >
                    <div
                      className="box"
                      style={getBoxStyle(boxLayout, styleGuide.layout_section_desktop_width, padding, 'media')}
                    >
                      <SvgIconMediaThumbnail />
                    </div>
                    <div
                      className="box"
                      style={getBoxStyle(boxLayout, styleGuide.layout_section_desktop_width, padding, 'text')}
                    >
                      <div className={styles.svgContainer} style={{ margin: getLayoutPadding(paddingBox, 3) }}>
                        <div className={styles.line1}></div>
                        <div className={styles.line2}></div>
                        <div className={styles.line3}></div>
                        <div className={styles.buttonContainer}>
                          <div className={styles.buttonLine}></div>
                        </div>
                      </div>
                      <span
                        className={styles.layoutPaddingTop}
                        style={getPaddingTextStyle(toInteger(paddingBox[0]), 3, 'top')}
                      >
                        {paddingBox[0]}
                      </span>
                      <span
                        className={styles.layoutPaddingRight}
                        style={getPaddingTextStyle(toInteger(paddingBox[1]), 3, 'right')}
                      >
                        {paddingBox[1]}
                      </span>
                      <span
                        className={styles.layoutPaddingBottom}
                        style={getPaddingTextStyle(toInteger(paddingBox[2]), 3, 'bottom')}
                      >
                        {paddingBox[2]}
                      </span>
                      <span
                        className={styles.layoutPaddingLeft}
                        style={getPaddingTextStyle(toInteger(paddingBox[3]), 3, 'left')}
                      >
                        {paddingBox[3]}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </main>
        </div>

        <div className={styles.styleSectionOptions}>
          <div className={styles.styleOption}>
            <span>Default Padding</span>
            <PaddingInput
              value={paddingBox}
              handleChange={handleChange}
              handleChangeEvent={handleChangeEvent}
              name={boxLayout === 'mobile' ? 'layout_box_mobile_padding' : 'layout_box_desktop_padding'}
              path={['box', ...(boxLayout === 'mobile' ? ['mobile_padding'] : ['desktop_padding'])]}
            />
          </div>
          {false && boxLayout === 'desktop' && (
            <div className={styles.styleOption}>
              <span>Default Max Width</span>
              <WidthInput
                value={styleGuide.layout_box_desktop_width}
                handleChange={handleChange}
                handleChangeEvent={handleChangeEvent}
                name="layout_box_desktop_width"
                path={['box', 'desktop_width']}
              />
            </div>
          )}
        </div>
      </section>
    </article>
  );
}
