import React, { useMemo } from 'react';
import format from 'date-fns/format';
import classNames from 'classnames';

import { Group, Label, TextInput } from 'components/Editor/Toolbars2/Toolbar2';
import { IconTime } from 'components/Editor/Toolbars2/icons';

import styles from './LayoutOption.module.scss';

const TimeField = (props: any) => {
  const defaultTime = useMemo(
    () => (props.property.default === 'now' ? format(new Date(), 'HH:mm') : props.property.default),
    [props.property.default]
  );

  return (
    <Group className={props.className}>
      {props.property.label && <Label>{props.property.label}:</Label>}
      <TextInput
        className={classNames(styles.TextInput, styles.DateInput)}
        value={typeof props.value === 'string' ? props.value.substring(0, 5) : defaultTime}
        onChange={(e: any) => props.onChange(props.property.name, `${e.target.value}:00`)}
        placeholder={props.property.placeholder}
        border
        type="time"
      />
      <span className={styles.coverNative}>
        <IconTime />
      </span>
    </Group>
  );
};

export default TimeField;
