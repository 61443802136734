import React from 'react';
import classnames from 'classnames';
import styles from './HeaderFooterToolbar.module.scss';

const SectionToolbar = ({ type, children }) => {
  return (
    <>
      <div className={classnames('admin', styles.layoutToolbar, styles[type])}>{children}</div>
    </>
  );
};

export default SectionToolbar;
