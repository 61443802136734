module.exports = {
  align: {
    '': require('../assets/icons/align-left.svg'),
    center: require('../assets/icons/align-center.svg'),
    right: require('../assets/icons/align-right.svg'),
    justify: require('../assets/icons/align-justify.svg'),
  },
  background: require('../assets/icons/background.svg'),
  blockquote: require('../assets/icons/blockquote.svg'),
  bold: require('../assets/icons/bold.svg'),
  clean: require('../assets/icons/clean.svg'),
  code: require('../assets/icons/code.svg'),
  'code-block': require('../assets/icons/code.svg'),
  color: require('../assets/icons/color.svg'),
  direction: {
    '': require('../assets/icons/direction-ltr.svg'),
    rtl: require('../assets/icons/direction-rtl.svg'),
  },
  float: {
    center: require('../assets/icons/float-center.svg'),
    full: require('../assets/icons/float-full.svg'),
    left: require('../assets/icons/float-left.svg'),
    right: require('../assets/icons/float-right.svg'),
  },
  formula: require('../assets/icons/formula.svg'),
  header: {
    1: require('../assets/icons/header.svg'),
    2: require('../assets/icons/header-2.svg'),
  },
  italic: require('../assets/icons/italic.svg'),
  image: require('../assets/icons/image.svg'),
  indent: {
    '+1': require('../assets/icons/indent.svg'),
    '-1': require('../assets/icons/outdent.svg'),
  },
  link: require('../assets/icons/link.svg'),
  list: {
    ordered: require('../assets/icons/list-ordered.svg'),
    bullet: require('../assets/icons/list-bullet.svg'),
    check: require('../assets/icons/list-check.svg'),
  },
  script: {
    sub: require('../assets/icons/subscript.svg'),
    super: require('../assets/icons/superscript.svg'),
  },
  strike: require('../assets/icons/strike.svg'),
  underline: require('../assets/icons/underline.svg'),
  video: require('../assets/icons/video.svg'),
};
