import React from 'react';
import styles from './PreviewParsingError.module.scss';

function PreviewParsingError() {
  return (
    <div className={styles.ErrorWrapper}>
      Something went wrong. Please refresh this page in your browser and try again.
    </div>
  );
}

export default PreviewParsingError;
