import { useCallback, useEffect, useState } from 'react';
import usePrevious from './usePrevious';

type Options = {
  discardIfOriginalChanges?: boolean;
};

const defaultOptions: Options = {
  discardIfOriginalChanges: false,
};

export function useLocalDraft<T>(original: T, options: Options = {}) {
  const mergedOptions = Object.assign({}, defaultOptions, options);

  const [draft, setDraft] = useState<T>();

  const update = useCallback(
    (newValue: T) => {
      setDraft(newValue);
    },
    [setDraft]
  );
  const discard = useCallback(() => setDraft(undefined), [setDraft]);

  usePrevious(original, (prevOriginal) => {
    if (mergedOptions.discardIfOriginalChanges && prevOriginal !== original) {
      console.log('ORIGINAL HAS CHANGED');
      discard();
    }
  });

  return {
    draft: draft || original,
    isDirty: !!draft,
    update: update,
    discard: discard,
  };
}
