import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from 'reportWebVitals';

import { PostHogProvider } from 'posthog-js/react';

import { QueryClient } from '@tanstack/react-query';
import { Provider } from 'react-redux';
import store from 'store';
import QueryClientWrapper from 'lib/QueryClientWrapper';

import './lib/appLoadScripts/analytics';
import './lib/appLoadScripts/sentry';
import { App } from 'components/App';
import SupportButton from 'components/base/support-button/SupportButton';
import { BrowserRouter } from 'react-router-dom';
import ScrollToTop from 'ScrollToTop/ScrollToTop';

(window as any).sprkfe = (window as any).sprkfe || {};

const options = {
  api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
  session_recording: {
    maskAllInputs: true,
    maskInputFn: (text: string, element: HTMLElement | undefined) => {
      if (
        element?.attributes?.getNamedItem('class')?.value.includes('ph-redact-input') ||
        element?.attributes?.getNamedItem('type')?.baseURI?.includes('/integrations')
      ) {
        return '*'.repeat(text.length);
      }
      return text;
    },
    maskTextSelector: '*',
    maskTextFn: (text: string, element: HTMLElement | undefined) => {
      if (
        element?.attributes?.getNamedItem('class')?.value.includes('ph-redact-text') ||
        (element?.attributes?.getNamedItem('class')?.value.includes('Integrations_value') &&
          element?.attributes?.getNamedItem('class')?.baseURI?.includes('/integrations'))
      ) {
        return '*'.repeat(text.length);
      }
      return text;
    },
  },
};

const queryClient = new QueryClient();
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <BrowserRouter>
    <ScrollToTop>
      <Provider store={store}>
        <QueryClientWrapper client={queryClient}>
          <PostHogProvider apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY} options={options}>
            <SupportButton />
            <App />
          </PostHogProvider>
        </QueryClientWrapper>
      </Provider>
    </ScrollToTop>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
