import { put, take, race } from 'redux-saga/effects';
import { call, delay } from 'redux-saga/effects';
import * as types from '../actions/actionTypes';
import * as contactsActions from '../actions/contactsActions';

function* poll(action) {
  const { params, siteId } = action.payload;
  const stats = {
    isPolling: false,
    fetching: false,
    nextPollEta: null,
    retries: null,
    lastResponseStatus: null,
  };

  while (true) {
    stats.isPolling = true;

    try {
      stats.fetching = true;
      const response = yield call(params.asyncFetch);

      stats.fetching = false;
      stats.nextPollEta = params.delay;
      const shouldContinue = params.callback(response, stats);
      yield put(contactsActions.getContactsCount(siteId));

      if (shouldContinue) {
        stats.retries = 0;
        stats.lastResponseStatus = 'success';
      } else {
        throw new Error('Error while fetching data.');
      }
      yield delay(params.delay * 1000);
    } catch (e) {
      console.log(e);
      const shouldRetry = params.retryOnFailure && stats.retries < params.stopAfterRetries;

      stats.fetching = false;
      stats.lastResponseStatus = 'error';
      stats.nextPollEta = shouldRetry ? params.retryAfter : null;
      params.callback(e, stats);

      if (shouldRetry) {
        for (let i = 1; i <= params.retryAfter; ++i) {
          yield call(delay, 1000);
          stats.nextPollEta = params.retryAfter - i;
        }

        ++stats.retries;
        yield put({
          type: types.CONTACTS_COUNT_START_POLLING,
          payload: { params, siteId },
        });
      } else {
        stats.isPolling = false;
        yield put({ type: types.CONTACTS_COUNT_STOP_POLLING });
      }
    }
  }
}

export default function* watchPollingTasks() {
  while (true) {
    const action = yield take(types.CONTACTS_COUNT_START_POLLING);
    yield race([call(poll, action), take(types.CONTACTS_COUNT_STOP_POLLING)]);
  }
}
