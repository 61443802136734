import React from 'react';
import { ReactComponent as SvgCheckboxChecked } from '../assets/images/icon-checkbox-checked.svg';
import { ReactComponent as SvgCheckbox } from '../assets/images/icon-checkbox.svg';

type Props = {
  checked: boolean;
  height?: number;
  onClick?: () => void;
};

export const Checkbox = ({ checked, height, onClick }: Props) => {
  return checked ? (
    <SvgCheckboxChecked onClick={onClick} style={{ height }} />
  ) : (
    <SvgCheckbox onClick={onClick} style={{ height }} />
  );
};
