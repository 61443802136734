import React, { useEffect, useState } from 'react';

import DataTypeInput from '../DataTypes';
import { Checkbox } from 'components/Checkbox';
import SideDrawer from 'components/base/SideDrawer';
import FormControl from 'components/base/FormHelpers/FormControl';

const DATA_TYPES = [
  { value: 'currency', label: 'Currency' },
  { value: 'date', label: 'Date' },
  { value: 'datetime', label: 'Datetime' },
  { value: 'int', label: 'Integer' },
  { value: 'media', label: 'Media' },
  { value: 'number', label: 'Numeric' },
  { value: 'text', label: 'Text' },
  { value: 'time', label: 'Time' },
  { value: 'foreign_key', label: 'Foreign key' },
];

const MEDIA_CATEGORIES = [
  { value: 'image', label: 'Image' },
  { value: 'icon', label: 'Icon' },
  { value: 'video', label: 'Video' },
];

export default ({ isOpen, close, addColumn, table, tables, updateColumn, removeColumn }) => {
  const [data, setData] = useState(isOpen ? { ...isOpen } : {});
  const [errors, setErrors] = useState({});

  useEffect(() => {
    setData(isOpen);
  }, [isOpen]);

  const handleChange = (e) => {
    let value = e.target.value;
    const name = e.target.name;
    if (name === 'is_nullable') value = e.target.checked;
    if (name === 'data_type') setData({ ...data, [name]: value, default: '' });
    else setData({ ...data, [name]: value });
  };

  const InputField = DataTypeInput[data.data_type || 'text'];
  const dataTypes = tables.length ? DATA_TYPES : DATA_TYPES.filter((type) => type.value !== 'foreign_key');

  return (
    <SideDrawer
      isOpen={isOpen}
      title="Add a new column"
      close={() => {
        setData({});
        close();
      }}
    >
      <fieldset>
        <label htmlFor="slug_input">Column Name</label>
        <FormControl
          autoFocus
          id="slug_input"
          name="slug"
          onChange={handleChange}
          value={data.slug}
          autoComplete="off"
          type="text"
          placeholder="Enter column name"
          errors={errors.slug}
        />
      </fieldset>
      <fieldset>
        <label className="checkbox" htmlFor="column_required_input">
          <FormControl
            id="column_required_input"
            aria-label="Mark column not required"
            type="checkbox"
            name="is_nullable"
            checked={data.is_nullable}
            onChange={handleChange}
          />
          <Checkbox checked={data.is_nullable} />
          <span data-test-id="column_required_input">Make this column not required</span>
        </label>
      </fieldset>
      <fieldset>
        <label htmlFor="data_type_input">Data type</label>
        <FormControl
          name="data_type"
          id="data_type_input"
          onChange={handleChange}
          value={data.data_type || ''}
          autoComplete="off"
          tag="select"
          errors={errors.data_type}
          disabled={data.id}
        >
          <option value="">Select data type</option>
          {dataTypes.map((type) => (
            <option key={type.value} value={type.value}>
              {type.label}
            </option>
          ))}
        </FormControl>
      </fieldset>
      {data.data_type === 'media' && (
        <fieldset>
          <label htmlFor="media_category_input">Media category</label>
          <FormControl
            onChange={(e) => {
              handleChange({
                target: {
                  value: e.target.value,
                  name: e.target.name,
                },
              });
            }}
            disable={data.id}
            name="media_category"
            id="media_category_input"
            tag="select"
            value={data.media_category}
            errors={errors.media_category}
          >
            <option value=""></option>
            {MEDIA_CATEGORIES.map((category) => (
              <option key={category.value} value={category.value}>
                {category.label}
              </option>
            ))}
          </FormControl>
        </fieldset>
      )}
      <fieldset>
        {data.data_type !== 'foreign_key' ? (
          <>
            <label htmlFor="default_value_input">Default value</label>
            <InputField
              removeSheetStyles
              onChange={(value) => {
                handleChange({
                  target: {
                    value,
                    name: 'default',
                  },
                });
              }}
              value={data.default}
              associatedLabelId="default_value_input"
              options={tables}
              category={data.media_category}
            />
            {errors.default && (
              <small data-test-id="default-input-error" className="errorMessage">
                {errors.default}
              </small>
            )}
          </>
        ) : (
          <>
            <label htmlFor="related_table_input">Related table</label>
            <FormControl
              onChange={(e) => {
                handleChange({
                  target: {
                    value: e.target.value,
                    name: e.target.name,
                  },
                });
              }}
              disable={data.id}
              name="related_table"
              id="related_table_input"
              tag="select"
              value={data.related_table}
              errors={errors.related_table}
            >
              <option value=""></option>
              {tables.map((table) => (
                <option key={table.id} value={table.id}>
                  {table.slug}
                </option>
              ))}
            </FormControl>
            {errors.default && <small className="errorMessage">{errors.default}</small>}
          </>
        )}
      </fieldset>
      <fieldset>
        <input
          readOnly
          value={data.id ? 'Update' : 'Save'}
          data-test-id="save-column"
          className="button button-primary button-full"
          onClick={() => {
            const promise = new Promise((resolve, reject) => {
              data.id
                ? updateColumn({ ...data }, resolve, reject)
                : addColumn(
                    {
                      ...data,
                      data_table: table,
                    },
                    resolve,
                    reject
                  );
            });
            promise
              .then(() => {
                setData({});
                close();
              })
              .catch((e) => {
                setErrors(e);
              });
          }}
        />
        {data.id && (
          <input
            readOnly
            value="Delete"
            className="button button-danger button-full"
            data-test-id="delete-column"
            onClick={() => {
              const promise = new Promise((resolve, reject) => {
                removeColumn(data.id, resolve, reject);
              });
              promise
                .then(() => {
                  setData({});
                  close();
                })
                .catch((e) => {
                  setErrors(e);
                });
            }}
          />
        )}
      </fieldset>
    </SideDrawer>
  );
};
