import React from 'react';
import FormControl from './FormControl';

import slugify from '../../../lib/slugify';

class SlugFormControl extends React.PureComponent {
  handleChange = (nameOrEvent, value) => {
    // Accept (name, value) argument pair or event
    if (nameOrEvent.target) {
      value = nameOrEvent.target.value;
    }

    // Strip all unwanted characters.

    const replaceRegex = new RegExp(
      ['product', 'collection', 'data_page'].includes(this.props.pageType) ? /[^%\w-/]/ : /[^\w-/]/
    );

    // Strip all unwanted characters.
    value = value.replace(/-?\s+-?/, '-').replace(replaceRegex, '');

    // Empty string indicates home page, so if the text box is empty,
    // store a null value instead of empty string.
    if (value === '') value = null;

    this.props.onChange(this.props.name, value);
  };

  handleHomePageCheck = (e) => {
    if (e.target.checked) {
      this.props.onChange(this.props.name, '');
    } else {
      this.props.onChange(this.props.name, null);
    }
  };

  render() {
    const { onChange, value, status, allowHomePage, imitate, entityType, ...fieldProps } = this.props;

    const isHomePage = value === '' && status === 'published' && allowHomePage;

    return (
      <>
        <FormControl
          onChange={this.handleChange}
          value={value || ''}
          imitate={slugify(imitate)}
          disabled={isHomePage}
          status={status}
          {...fieldProps}
        />
      </>
    );
  }
}

export default SlugFormControl;
