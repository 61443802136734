/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useCallback, useState } from 'react';
import { ReactComponent as SvgIconShopify } from '../../../../assets/images/icon-shopify.svg';
import { set as setOauthState } from '../../../../services/oauth-state';
import modalStyles from './Shopify.module.scss';

import Modal from 'components/base/Modal';
import FormControl from 'components/base/FormHelpers/FormControl';

const getCenteredModalPosition = (width, height) => {
  let w = (window.innerWidth || 0) > (window.outerWidth || 0) ? window.innerWidth || 0 : window.outerWidth || 0,
    h = (window.innerHeight || 0) > (window.outerHeight || 0) ? window.innerHeight || 0 : window.outerHeight || 0,
    x = window.screenX || 0,
    y = window.screenY || 0;
  let top = y + (h - height) / 2,
    left = x + (w - width) / 2;
  return `width=${width},height=${height},top=${top},left=${left}`;
};

const validateDomain = (domain) => domain.match(/[\w-]+\.myshopify\.com/);

export default (props) => {
  const {
    isOpen,
    close,
    siteId,
    icon,
    type,
    name,
    integrationSlug,
    authenticator: { id: authenticatorId, authorization_url, authorization_success_message },
  } = props;
  const [domain, setDomain] = useState('');

  const connectShopify = useCallback(() => {
    const modalPosition = getCenteredModalPosition(1020, 520);
    const stateString = setOauthState(type, {
      siteId,
      icon,
      authorization_success_message,
      authenticatorId,
      name,
      integrationSlug,
    });
    const url = `${authorization_url.replace('{shop}', domain)}&state=${stateString}`;

    window.open(url, `${type}_oauth`, `location=no,${modalPosition}`);
  }, [siteId, domain]);

  const isCustomApp = authorization_url.indexOf('{shop}') == -1;

  return (
    <Modal isOpen={isOpen} close={close}>
      <div className={modalStyles.modal}>
        <h3>Connect your shopify account</h3>
        <div className={modalStyles.contentContainer}>
          <div className={modalStyles.inputContainer}>
            {!isCustomApp && (
              <fieldset>
                <label>Store Domain</label>
                <FormControl
                  value={domain}
                  onChange={(e) => setDomain(e.target.value)}
                  type="text"
                  placeholder="e.g. shop.myshopify.com"
                />
              </fieldset>
            )}
            <SvgIconShopify width={99} height={99} />
          </div>
          <div>
            <button onClick={() => close()} className="button button-secondary">
              Cancel
            </button>
            <button
              onClick={() => {
                connectShopify();
              }}
              disabled={!isCustomApp && !validateDomain(domain)}
              className="button button-primary"
            >
              Connect Store
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
