// ----------------------------------------------------------------------------
// App actions
// ----------------------------------------------------------------------------

export const APP_INITIALIZE = 'APP_INITIALIZE';
export const APP_UPDATE_STATUS = 'APP_UPDATE_STATUS';

// ----------------------------------------------------------------------------
// UI actions
// ----------------------------------------------------------------------------

export const CONNECTION_ERROR = 'CONNECTION_ERROR';
export const CLEAR_CONNECTION_ERROR = 'CLEAR_CONNECTION_ERROR';
export const DEVICE = 'DEVICE';
export const DEVICE_DIMENSIONS = 'DEVICE_DIMENSIONS';
export const DEVICE_ROTATION = 'DEVICE_ROTATION';

export const PAGE_SETTINGS_OPEN = 'PAGE_SETTINGS_OPEN';
export const PAGE_SETTINGS_CLOSE = 'PAGE_SETTINGS_CLOSE';
export const PAGE_SETTINGS_ADD_OPS = 'PAGE_SETTINGS_ADD_OPS';
export const PAGE_SETTINGS_SAVE = 'PAGE_SETTINGS_SAVE';
export const PAGE_SETTINGS_PUBLISH = 'PAGE_SETTINGS_PUBLISH';
export const PAGE_SETTINGS_RESET = 'PAGE_SETTINGS_RESET';

export const BLOG_SETTINGS_OPEN = 'BLOG_SETTINGS_OPEN';
export const BLOG_SETTINGS_CLOSE = 'BLOG_SETTINGS_CLOSE';
export const BLOG_SETTINGS_ADD_OPS = 'BLOG_SETTINGS_ADD_OPS';
export const BLOG_SETTINGS_SAVE = 'BLOG_SETTINGS_SAVE';
export const BLOG_SETTINGS_PUBLISH = 'BLOG_SETTINGS_PUBLISH';

export const ARTICLE_SETTINGS_OPEN = 'ARTICLE_SETTINGS_OPEN';
export const ARTICLE_SETTINGS_CLOSE = 'ARTICLE_SETTINGS_CLOSE';
export const ARTICLE_SETTINGS_ADD_OPS = 'ARTICLE_SETTINGS_ADD_OPS';
export const ARTICLE_SETTINGS_SAVE = 'ARTICLE_SETTINGS_SAVE';
export const ARTICLE_SETTINGS_PUBLISH = 'ARTICLE_SETTINGS_PUBLISH';

export const ROUTER_LOCATION_HAS_CHANGED = 'ROUTER_LOCATION_HAS_CHANGED';

export const DOMAIN_SETTINGS_OPEN = 'DOMAIN_SETTINGS_OPEN';
export const DOMAIN_SETTINGS_CLOSE = 'DOMAIN_SETTINGS_CLOSE';

export const SUBSCRIPTION_MANAGER_OPEN = 'SUBSCRIPTION_MANAGER_OPEN';
export const SUBSCRIPTION_MANAGER_CLOSE = 'SUBSCRIPTION_MANAGER_CLOSE';

export const ACCOUNT_USER_SETTINGS_OPEN = 'ACCOUNT_USER_SETTINGS_OPEN';
export const ACCOUNT_USER_SETTINGS_CLOSE = 'ACCOUNT_USER_SETTINGS_CLOSE';

// ----------------------------------------------------------------------------
// Tag actions
// ----------------------------------------------------------------------------

export const TAG_CREATE_REQUEST = 'TAG_CREATE_REQUEST';
export const TAG_REMOVE_REQUEST = 'TAG_REMOVE_REQUEST';
export const TAG_FETCH_ALL = 'TAG_FETCH_ALL';
export const TAG_FETCH_ALL_SUCCESS = 'TAG_FETCH_ALL_SUCCESS';

// ----------------------------------------------------------------------------
// Account actions
// ----------------------------------------------------------------------------

export const ACCOUNT_REQUEST_IF_NEEDED = 'ACCOUNT_REQUEST_IF_NEEDED';
export const ACCOUNT_REQUEST = 'ACCOUNT_REQUEST';
export const ACCOUNT_RECEIVE = 'ACCOUNT_RECEIVE';
export const ACCOUNT_RECEIVE_ERRORS = 'ACCOUNT_RECEIVE_ERRORS';
export const ACCOUNT_CREATE = 'ACCOUNT_CREATE';
export const ACCOUNT_UPDATE = 'ACCOUNT_UPDATE';
export const ACCOUNT_UPDATE_ONBOARDING_STEP = 'ACCOUNT_UPDATE_ONBOARDING_STEP';
export const ACCOUNT_ON_ONBOARDING_UPDATE = 'ACCOUNT_ON_ONBOARDING_UPDATE';
export const ACCOUNT_UPDATE_ONBOARDING = 'ACCOUNT_UPDATE_ONBOARDING';
export const ACCOUNT_REMOVE_ONBOARDING_STEP = 'ACCOUNT_REMOVE_ONBOARDING_STEP';
export const ACCOUNT_ON_UPDATE_ONBOARDING = 'ACCOUNT_ON_UPDATE_ONBOARDING';

export const ACCOUNT_USER_REQUEST_CURRENT = 'ACCOUNT_USER_REQUEST_CURRENT';
export const ACCOUNT_USER_RECIEVE_CURRENT = 'ACCOUNT_USER_RECIEVE_CURRENT';

export const ACCOUNT_LOGIN_REQUEST = 'ACCOUNT_LOGIN_REQUEST';
export const ACCOUNT_LOGIN_CHALLENGE = 'ACCOUNT_LOGIN_CHALLENGE';
export const ACCOUNT_LOGIN_VERIFY_CODE = 'ACCOUNT_LOGIN_VERIFY_CODE';
export const ACCOUNT_LOGIN_VERIFICATION_FAILED = 'ACCOUNT_LOGIN_VERIFICATION_FAILED';
export const ACCOUNT_LOGIN_SUCCESS = 'ACCOUNT_LOGIN_SUCCESS';
export const ACCOUNT_LOGIN_FAILURE = 'ACCOUNT_LOGIN_FAILURE';
export const ACCOUNT_LOGOUT = 'ACCOUNT_LOGOUT';
export const ACCOUNT_FORBID_ACTION = 'ACCOUNT_FORBID_ACTION';
export const ACCOUNT_REFRESH_TOKEN = 'ACCOUNT_REFRESH_TOKEN';
export const ACCOUNT_SCHEDULE_TOKEN_REFRESH = 'ACCOUNT_SCHEDULE_TOKEN_REFRESH';

export const ACCOUNT_MFA_ENABLE_REQUEST = 'ACCOUNT_MFA_ENABLE_REQUEST';
export const ACCOUNT_MFA_VERIFY_CODE = 'ACCOUNT_MFA_VERIFY_CODE';
export const ACCOUNT_MFA_ENABLE_STEP_SUCCESS = 'ACCOUNT_MFA_ENABLE_STEP_SUCCESS';
export const ACCOUNT_MFA_ENABLE_STEP_FAILED = 'ACCOUNT_MFA_ENABLE_STEP_FAILED';
export const ACCOUNT_MFA_GET = 'ACCOUNT_MFA_GET';
export const ACCOUNT_MFA_GET_IF_NEEDED = 'ACCOUNT_MFA_GET_IF_NEEDED';
export const ACCOUNT_MFA_DISABLE = 'ACCOUNT_MFA_DISABLE';
export const ACCOUNT_MFA_DISABLE_SUCCESS = 'ACCOUNT_MFA_DISABLE_SUCCESS';
export const ACCOUNT_MFA_RESET_PENDING_STATE = 'ACCOUNT_MFA_RESET_PENDING_STATE';

export const ACCOUNT_USER_RESEND_VERIFICATION_EMAIL = 'ACCOUNT_USER_RESEND_VERIFICATION_EMAIL';
export const ACCOUNT_USER_VERIFICATION_EMAIL_SENT = 'ACCOUNT_USER_VERIFICATION_EMAIL_SENT';
export const ACCOUNT_MULTI_SITE_CREATE_REQUEST = 'ACCOUNT_MULTI_SITE_CREATE_REQUEST';

// ----------------------------------------------------------------------------
// User actions
// ----------------------------------------------------------------------------

export const ACCOUNT_USER_REQUEST_ALL = 'ACCOUNT_USER_REQUEST_ALL';
export const ACCOUNT_USER_REQUEST_ALL_IF_NEEDED = 'ACCOUNT_USER_REQUEST_ALL_IF_NEEDED';
export const ACCOUNT_USER_REQUEST = 'ACCOUNT_USER_REQUEST';
export const ACCOUNT_USER_CREATE = 'ACCOUNT_USER_CREATE';
export const ACCOUNT_USER_UPDATE = 'ACCOUNT_USER_UPDATE';
export const ACCOUNT_USER_REMOVE = 'ACCOUNT_USER_REMOVE';
export const ACCOUNT_USER_RECEIVE = 'ACCOUNT_USER_RECEIVE';
export const ACCOUNT_USER_RECEIVE_ALL = 'ACCOUNT_USER_RECEIVE_ALL';
export const ACCOUNT_USER_RECEIVE_DELETE = 'ACCOUNT_USER_RECEIVE_DELETE';
export const ACCOUNT_USER_RECEIVE_ERROR = 'ACCOUNT_USER_RECEIVE_ERROR';
export const ACCOUNT_USER_RESEND_INVITE = 'ACCOUNT_USER_RESEND_INVITE';
export const ACCOUNT_USER_UPDATE_ONBOARDING = 'ACCOUNT_USER_UPDATE_ONBOARDING';
export const ACCOUNT_USER_ON_UPDATE_ONBOARDING = 'ACCOUNT_USER_ON_UPDATE_ONBOARDING';

// Own-user actions allow individuals to manage their own accounts
export const OWN_USER_REQUEST = 'OWN_USER_REQUEST';
export const OWN_USER_UPDATE = 'OWN_USER_UPDATE';
export const OWN_USER_RECEIVE = 'OWN_USER_RECEIVE';
export const OWN_USER_RECEIVE_ERROR = 'OWN_USER_RECEIVE_ERROR';

// ----------------------------------------------------------------------------
// Subscription
// ----------------------------------------------------------------------------

export const SUBSCRIPTION_REQUEST_IF_NEEDED = 'SUBSCRIPTION_REQUEST_IF_NEEDED';
export const SUBSCRIPTION_REQUEST_PRODUCTS = 'SUBSCRIPTION_REQUEST_PRODUCTS';
export const SUBSCRIPTION_REQUEST_PRODUCTS_IF_NEEDED = 'SUBSCRIPTION_REQUEST_PRODUCTS_IF_NEEDED';
export const SUBSCRIPTION_PLANS_RECEIVE = 'SUBSCRIPTION_PLANS_RECEIVE';
export const SUBSCRIPTION_REQUEST = 'SUBSCRIPTION_REQUEST';
export const SUBSCRIPTION_RECEIVE = 'SUBSCRIPTION_RECEIVE';
export const SUBSCRIPTION_RECEIVE_HISTORY = 'SUBSCRIPTION_RECEIVE_HISTORY';
export const SUBSCRIPTION_REQUEST_CREATE = 'SUBSCRIPTION_REQUEST_CREATE';
export const SUBSCRIPTION_RECEIVE_CREATE_SUCCESS = 'SUBSCRIPTION_RECEIVE_CREATE_SUCCESS';
export const SUBSCRIPTION_RECEIVE_CREATE_ERROR = 'SUBSCRIPTION_RECEIVE_CREATE_ERROR';
export const SUBSCRIPTION_UPDATE_CREDIT_CARD = 'SUBSCRIPTION_UPDATE_CREDIT_CARD';

// ----------------------------------------------------------------------------
// Section actions
// ----------------------------------------------------------------------------

export const SECTIONS_ADD = 'SECTIONS_ADD';
export const SECTIONS_ADD_BETWEEN = 'SECTIONS_ADD_BETWEEN';
export const SECTIONS_SHOW_PLACEHOLDER = 'SECTIONS_SHOW_PLACEHOLDER';
export const SECTIONS_HIDE_PLACEHOLDER = 'SECTIONS_HIDE_PLACEHOLDER';
export const SECTIONS_UPDATE = 'SECTIONS_UPDATE';
export const SECTIONS_DELETE = 'SECTIONS_DELETE';
export const SECTIONS_REPLACE = 'SECTIONS_REPLACE';
export const SECTIONS_RECEIVE = 'SECTIONS_RECEIVE';
export const SECTIONS_PUSH_NESTED_PROP = 'SECTIONS_PUSH_NESTED_PROP';
export const SECTIONS_UPDATE_NESTED_PROP = 'SECTIONS_UPDATE_NESTED_PROP';
export const SECTIONS_REORDER_NESTED_PROP = 'SECTIONS_REORDER_NESTED_PROP';
export const SECTIONS_DELETE_NESTED_PROP = 'SECTIONS_DELETE_NESTED_PROP';
export const SECTIONS_POP_NESTED_PROP = 'SECTIONS_POP_NESTED_PROP';
export const SECTIONS_REORDER = 'SECTIONS_REORDER';
export const SECTIONS_INITIALIZE_A_B_TEST = 'SECTIONS_INITIALIZE_A_B_TEST';
export const SECTIONS_DISCARD_A_B_TEST = 'SECTIONS_DISCARD_A_B_TEST';
export const SECTIONS_REMOVE_NESTED_PROP = 'SECTIONS_REMOVE_NESTED_PROP';
export const SECTIONS_INITIALIZE_CONTAINER = 'SECTIONS_INITIALIZE_CONTAINER';
export const SECTIONS_ADD_TO_CONTAINER = 'SECTIONS_ADD_TO_CONTAINER';
export const SECTIONS_UPDATE_CONTAINER = 'SECTIONS_UPDATE_CONTAINER';
export const SECTIONS_REMOVE_CONTAINER_CHILD = 'SECTIONS_REMOVE_CONTAINER_CHILD';
export const SECTIONS_REQUEST_CREATE = 'SECTIONS__REQUEST_CREATE';
export const SECTIONS_CREATE_ELEMENT = 'SECTIONS_CREATE_ELEMENT';

// ----------------------------------------------------------------------------
// Item actions
// ----------------------------------------------------------------------------

export const ITEM_REQUEST = 'ITEM_REQUEST';
export const ITEM_REQUEST_IF_NEEDED = 'ITEM_REQUEST_IF_NEEDED';
export const ITEM_SAVE = 'ITEM_SAVE';
export const ITEM_REQUEST_FILTERED_LIST = 'ITEM_REQUEST_FILTERED_LIST';

//  Item URLs
export const ITEM_URL_CREATE_OR_UPDATE = 'ITEM_URL_CREATE_OR_UPDATE';
export const ITEM_URLS_REQUEST = 'ITEM_URLS_REQUEST';
export const ITEM_URLS_REQUEST_SUCCESS = 'ITEM_URLS_REQUEST_SUCCESS';
export const ITEM_URL_FLUSH_CACHE = 'ITEM_URL_FLUSH_CACHE';
export const ITEM_URL_REMOVE = 'ITEM_URL_REMOVE';
export const ITEM_URL_REMOVE_SUCCESS = 'ITEM_URL_REMOVE_SUCCESS';
export const ITEM_URL_CREATE_SUCCESS = 'ITEM_URL_CREATE_SUCCESS';
export const ITEM_URL_UPDATE_SUCCESS = 'ITEM_URL_UPDATE_SUCCESS';

// ----------------------------------------------------------------------------
// Page actions
// ----------------------------------------------------------------------------

export const PAGES_REQUEST_LIST = 'PAGES_REQUEST_LIST';
export const PAGES_REQUEST_LIST_BY_PAGE = 'PAGES_REQUEST_LIST_BY_PAGE';
export const PAGES_REQUEST_LIST_IF_NEEDED = 'PAGES_REQUEST_LIST_IF_NEEDED';
export const PAGES_RECEIVE_LIST = 'PAGES_RECEIVE_LIST';
export const PAGES_RECEIVE_LIST_ITEM = 'PAGES_RECEIVE_LIST_ITEM';

export const PAGE_REQUEST = 'PAGE_REQUEST';
export const PAGE_RECEIVE = 'PAGE_RECEIVE';
export const PAGE_PROCESS_AND_RECEIVE_RAW_RESPONSE = 'PAGE_PROCESS_AND_RECEIVE_RAW_RESPONSE';
export const PAGE_UPDATE_CONTENT = 'PAGE_UPDATE_CONTENT';
export const PAGE_RECEIVE_SECTION_ARTICLES = 'PAGE_RECEIVE_SECTION_ARTICLES';

export const PAGE_TOUCH = 'PAGE_TOUCH';
export const AUTO_SAVE_PAGE = 'AUTO_SAVE_PAGE';

export const PAGE_UPDATE_REQUEST = 'PAGE_UPDATE_REQUEST';
export const PAGE_UPDATE_RECEIVE = 'PAGE_UPDATE_RECEIVE';
export const PAGE_RECEIVE_ERROR = 'PAGE_RECEIVE_ERROR';

export const PAGE_REQUEST_PUBLISH = 'PAGE_REQUEST_PUBLISH';
export const PAGE_RECEIVE_PUBLISH_SUCCESS = 'PAGE_RECEIVE_PUBLISH_SUCCESS';
export const PAGE_RECEIVE_PUBLISH_ERROR = 'PAGE_RECEIVE_PUBLISH_ERROR';

export const PAGE_REQUEST_UNPUBLISH = 'PAGE_REQUEST_UNPUBLISH';
export const PAGE_RECEIVE_UNPUBLISH_SUCCESS = 'PAGE_RECEIVE_UNPUBLISH_SUCCESS';

export const PAGE_REQUEST_CLONE = 'PAGE_REQUEST_CLONE';
export const PAGE_RECEIVE_CLONE_SUCCESS = 'PAGE_RECEIVE_CLONE_SUCCESS';
export const PAGE_RECEIVE_CLONE_ERROR = 'PAGE_RECEIVE_CLONE_ERROR';

export const PAGE_REQUEST_UNDELETE = 'PAGE_REQUEST_UNDELETE';
export const PAGE_RECEIVE_UNDELETE_ERROR = 'PAGE_RECEIVE_UNDELETE_ERROR';
export const PAGE_RECEIVE_UNDELETE_SUCCESS = 'PAGE_RECEIVE_UNDELETE_SUCCESS';

export const PAGE_REQUEST_DISCARD = 'PAGE_REQUEST_DISCARD';
export const PAGE_RECEIVE_DISCARD = 'PAGE_RECEIVE_DISCARD';

export const PAGE_DELETE = 'PAGE_DELETE';

export const PAGE_REQUEST_PAGE_INFO_UPDATE = 'PAGE_REQUEST_PAGE_INFO_UPDATE';
export const PAGE_REQUEST_PAGE_INFO_UPDATE_SUCCESS = 'PAGE_REQUEST_PAGE_INFO_UPDATE_SUCCESS';
export const PAGE_REQUEST_PAGE_INFO_UPDATE_FAILURE = 'PAGE_REQUEST_PAGE_INFO_UPDATE_FAILURE';

export const PAGE_DYNAMIC_CONTENT_PUBLISH_ERROR = 'PAGE_DYNAMIC_CONTENT_PUBLISH_ERROR';

// ----------------------------------------------------------------------------
// Blog actions
// ----------------------------------------------------------------------------

// Retrieve list
export const BLOGS_REQUEST_LIST = 'BLOGS_REQUEST_LIST';
export const BLOGS_REQUEST_LIST_IF_NEEDED = 'BLOGS_REQUEST_LIST_IF_NEEDED';
export const BLOGS_RECEIVE_LIST = 'BLOGS_RECEIVE_LIST';

// Create
export const BLOG_REQUEST_CREATE = 'BLOG_REQUEST_CREATE';
export const BLOG_RECEIVE_CREATE_SUCCESS = 'BLOG_RECEIVE_CREATE_SUCCESS';

// Retrieve
export const BLOG_REQUEST_IF_NEEDED = 'BLOG_REQUEST_IF_NEEDED';
export const BLOG_REQUEST = 'BLOG_REQUEST';
export const BLOG_RECEIVE = 'BLOG_RECEIVE';

// Update
export const BLOG_REQUEST_UPDATE = 'BLOG_REQUEST_UPDATE';
export const BLOG_WILL_REQUEST_UPDATE = 'BLOG_WILL_REQUEST_UPDATE';
export const BLOG_RECEIVE_UPDATE_SUCCESS = 'BLOG_RECEIVE_UPDATE_SUCCESS';
export const BLOG_RECEIVE_UPDATE_ERROR = 'BLOG_RECEIVE_UPDATE_ERROR';

// Autosave
export const BLOG_ADD_AUTOSAVE_OPS = 'BLOG_ADD_AUTOSAVE_OPS';
export const BLOG_REMOVE_AUTOSAVE_OPS = 'BLOG_REMOVE_AUTOSAVE_OPS';

// RESET
export const BLOG_REQUEST_PUBLISH = 'BLOG_REQUEST_PUBLISH';
export const BLOG_RECEIVE_PUBLISH_SUCCESS = 'BLOG_RECEIVE_PUBLISH_SUCCESS';
export const BLOG_RECEIVE_PUBLISH_ERROR = 'BLOG_RECEIVE_PUBLISH_ERROR';

// Discard changes
export const BLOG_REQUEST_DISCARD = 'BLOG_REQUEST_DISCARD';
export const BLOG_RECEIVE_DISCARD = 'BLOG_RECEIVE_DISCARD';

// Delete
export const BLOG_REQUEST_DELETE = 'BLOG_REQUEST_DELETE';

export const BLOG_REQEST_CREATE_FOOTER_ELEMENT = 'BLOG_REQEST_CREATE_FOOTER_ELEMENT';
export const BLOG_REQEST_REMOVE_FOOTER_ELEMENT = 'BLOG_REQEST_REMOVE_FOOTER_ELEMENT';
export const BLOG_REQEST_INITIALIZE_FOOTER_CONTAINER = 'BLOG_REQEST_INITIALIZE_FOOTER_CONTAINER';
export const BLOG_FOOTER_INITIALIZE_ABTEST_CONTAINER = 'BLOG_FOOTER_INITIALIZE_ABTEST_CONTAINER';
export const BLOG_FOOTER_START_AB_TEST = 'BLOG_FOOTER_START_AB_TEST';
export const BLOG_FOOTER_END_AB_TEST = 'BLOG_FOOTER_END_AB_TEST';
export const BLOG_REQUEST_AB_TEST_CREATED = 'BLOG_REQUEST_AB_TEST_CREATED';
export const BLOG_REQUEST_AB_TEST_STARTED = 'BLOG_REQUEST_AB_TEST_STARTED';
export const BLOG_REQUEST_AB_TEST_UPDATE = 'BLOG_REQUEST_AB_TEST_UPDATE';

// ----------------------------------------------------------------------------
// Article actions
// ----------------------------------------------------------------------------

// Retrieve list
export const ARTICLES_REQUEST_LIST = 'ARTICLES_REQUEST_LIST';
export const ARTICLES_REQUEST_LIST_IF_NEEDED = 'ARTICLES_REQUEST_LIST_IF_NEEDED';
export const ARTICLES_RECEIVE_LIST = 'ARTICLES_RECEIVE_LIST';
export const ARTICLES_RECEIVE_LIST_BY_PAGE = 'ARTICLES_RECEIVE_LIST_BY_PAGE';
export const ARTICLES_RECEIVE_SEARCHED_LIST = 'ARTICLES_RECEIVE_SEARCHED_LIST';
export const ARTICLES_REQUEST_SEARCH = 'ARTICLES_REQUEST_SEARCH';

// Create
export const ARTICLE_REQUEST_CREATE = 'ARTICLE_REQUEST_CREATE';
export const ARTICLE_RECEIVE_CREATE_SUCCESS = 'ARTICLE_RECEIVE_CREATE_SUCCESS';

// Retrieve
export const ARTICLE_REQUEST = 'ARTICLE_REQUEST';
export const ARTICLE_RECEIVE = 'ARTICLE_RECEIVE';
export const ARTICLE_PROCESS_AND_RECEIVE_RAW_RESPONSE = 'ARTICLE_PROCESS_AND_RECEIVE_RAW_RESPONSE';

// Update
export const ARTICLE_REQUEST_UPDATE = 'ARTICLE_REQUEST_UPDATE';
export const ARTICLE_WILL_REQUEST_UPDATE = 'ARTICLE_WILL_REQUEST_UPDATE';
export const ARTICLE_RECEIVE_UPDATE_SUCCESS = 'ARTICLE_RECEIVE_UPDATE_SUCCESS';
export const ARTICLE_RECEIVE_UPDATE_ERROR = 'ARTICLE_RECEIVE_UPDATE_ERROR';

// Autosave
export const ARTICLE_ADD_AUTOSAVE_OPS = 'ARTICLE_ADD_AUTOSAVE_OPS';
export const ARTICLE_REMOVE_AUTOSAVE_OPS = 'ARTICLE_REMOVE_AUTOSAVE_OPS';

// Publish
export const ARTICLE_REQUEST_PUBLISH = 'ARTICLE_REQUEST_PUBLISH';
export const ARTICLE_RECEIVE_PUBLISH_SUCCESS = 'ARTICLE_RECEIVE_PUBLISH_SUCCESS';
export const ARTICLE_RECEIVE_PUBLISH_ERROR = 'ARTICLE_RECEIVE_PUBLISH_ERROR';

// Unpublish
export const ARTICLE_REQUEST_UNPUBLISH = 'ARTICLE_REQUEST_UNPUBLISH';
export const ARTICLE_RECEIVE_UNPUBLISH_SUCCESS = 'ARTICLE_RECEIVE_UNPUBLISH_SUCCESS';

//  Unschedule
export const ARTICLE_REQUEST_UNSCHEDULE = 'ARTICLE_REQUEST_UNSCHEDULE';
export const ARTICLE_RECEIVE_UNSCHEDULE_SUCCESS = 'ARTICLE_RECEIVE_UNSCHEDULE_SUCCESS';

// Discard changes
export const ARTICLE_REQUEST_DISCARD = 'ARTICLE_REQUEST_DISCARD';
export const ARTICLE_RECEIVE_DISCARD = 'ARTICLE_RECEIVE_DISCARD';

// Delete
export const ARTICLE_REQUEST_DELETE = 'ARTICLE_REQUEST_DELETE';

// Undelete
export const ARTICLE_REQUEST_UNDELETE = 'ARTICLE_REQUEST_UNDELETE';
export const ARTICLE_RECEIVE_UNDELETE_SUCCESS = 'ARTICLE_RECEIVE_UNDELETE_SUCCESS';

// ----------------------------------------------------------------------------
// Article Category actions
// ----------------------------------------------------------------------------

export const CATEGORY_FETCH_ALL_REQUEST = 'CATEGORY_FETCH_ALL_REQUEST';
export const CATEGORY_FETCH_ALL_IF_NEEDED = 'CATEGORY_FETCH_ALL_IF_NEEDED';
export const CATEGORY_FETCH_ALL_SUCCESS = 'CATEGORY_FETCH_ALL_SUCCESS';
export const CATEGORY_UPDATE_REQUEST = 'CATEGORY_UPDATE_REQUEST';
export const CATEGORY_UPDATE_REQUEST_SUCCESS = 'CATEGORY_UPDATE_REQUEST_SUCCESS';
export const CATEGORY_DELETE_REQUEST = 'CATEGORY_DELETE_REQUEST';
export const CATEGORY_DELETE_REQUEST_SUCCESS = 'CATEGORY_DELETE_REQUEST_SUCCESS';

// ----------------------------------------------------------------------------
// Site actions
// ----------------------------------------------------------------------------

export const SITE_REQUEST_ALL = 'SITE_REQUEST_ALL';
export const SITE_REQUEST_ALL_IF_NEEDED = 'SITE_REQUEST_ALL_IF_NEEDED';
export const SITE_RECEIVE_LIST = 'SITE_RECEIVE_LIST';

export const SITE_REQUEST = 'SITE_REQUEST';
export const SITE_REQUEST_IF_NEEDED = 'SITE_REQUEST_IF_NEEDED';
export const SITE_RECEIVE = 'SITE_RECEIVE';
export const SITE_RECEIVE_ERROR = 'SITE_RECEIVE_ERROR';

export const SITE_UPDATE = 'SITE_UPDATE';
export const SITE_UPDATE_UNTHROTTLED = 'SITE_UPDATE_UNTHROTTLED';
export const SITE_UPDATE_ONBOARDING = 'SITE_UPDATE_ONBOARDING';
export const SITE_REMOVE_ONBOARDING_STEP = 'SITE_REMOVE_ONBOARDING_STEP';
export const SITE_ON_UPDATE_ONBOARDING = 'SITE_ON_UPDATE_ONBOARDING';

export const SITE_SET_DOMAIN = 'SITE_SET_DOMAIN';
export const SITE_REMOVE_DOMAIN = 'SITE_REMOVE_DOMAIN';
export const SITE_REFRESH_DOMAIN = 'SITE_REFRESH_DOMAIN';
export const SITE_DOMAIN_FAILURE = 'SITE_DOMAIN_FAILURE';
export const SITE_ADD_SSL = 'SITE_ADD_SSL';

export const SITE_CREATE_REQUEST = 'SITE_CREATE_REQUEST';

export const SITE_DELETE_REQUEST = 'SITE_DELETE_REQUEST';

export const SITE_ACTIVE_URL_SITE_CHANGED = 'SITE_ACTIVE_URL_SITE_CHANGED';

export const SITE_FLUSH_CACHE = 'SITE_FLUSH_CACHE';
export const SITE_EXTRACT_DATA = 'SITE_EXTRACT_DATA';

// ----------------------------------------------------------------------------
// Site layout
// ----------------------------------------------------------------------------

export const SITE_LAYOUT_FETCH = 'SITE_LAYOUT_FETCH';
export const SITE_LAYOUT_RECEIVED_LIST = 'SITE_LAYOUT_RECEIVED_LIST';
export const SITE_LAYOUT_RECEIVE = 'SITE_LAYOUT_RECEIVE';
export const SITE_LAYOUT_FETCH_HEADERS = 'SITE_LAYOUT_FETCH_HEADERS';
export const SITE_LAYOUT_FETCH_FOOTERS = 'SITE_LAYOUT_FETCH_FOOTERS';
export const SITE_LAYOUT_FETCH_SECTIONS = 'SITE_LAYOUT_FETCH_SECTIONS';
export const SITE_LAYOUT_FETCH_IF_NEEDED = 'SITE_LAYOUT_FETCH_IF_NEEDED';
export const SITE_LAYOUT_RECEIVE_HEADERS = 'SITE_LAYOUT_RECEIVE_HEADERS';
export const SITE_LAYOUT_RECEIVE_FOOTERS = 'SITE_LAYOUT_RECEIVE_FOOTERS';
export const SITE_LAYOUT_CREATE = 'SITE_LAYOUT_CREATE';
export const SITE_LAYOUT_UPDATE = 'SITE_LAYOUT_UPDATE';
export const SITE_LAYOUT_BULK_UPDATE_OR_CREATE = 'SITE_LAYOUT_BULK_UPDATE_OR_CREATE';

// ----------------------------------------------------------------------------
// Themes actions
// ----------------------------------------------------------------------------

export const THEMES_REQUEST_LIST = 'THEMES_REQUEST_LIST';
export const THEMES_RECEIVED_LIST = 'THEMES_RECEIVED_LIST';

// ----------------------------------------------------------------------------
// Integration actions
// ----------------------------------------------------------------------------

export const INTEGRATIONS_REQUEST_ALL_IF_NEEDED = 'INTEGRATIONS_REQUEST_ALL_IF_NEEDED';
export const INTEGRATIONS_REQUEST_ALL = 'INTEGRATIONS_REQUEST_ALL';
export const INTEGRATIONS_RECEIVE_ALL = 'INTEGRATIONS_RECEIVE_ALL';
export const INTEGRATIONS_RECEIVE_UPDATE = 'INTEGRATIONS_RECEIVE_UPDATE';
export const INTEGRATIONS_CREATE = 'INTEGRATIONS_CREATE';
export const INTEGRATIONS_PATCH = 'INTEGRATIONS_PATCH';
export const INTEGRATIONS_REQUEST_DELETE = 'INTEGRATIONS_REQUEST_DELETE';
export const INTEGRATIONS_DELETE = 'INTEGRATIONS_DELETE';
export const INTEGRATION_REQUEST_TYPES_IF_NEEDED = 'INTEGRATION_REQUEST_TYPES_IF_NEEDED';
export const INTEGRATION_REQUEST_TYPES = 'INTEGRATION_REQUEST_TYPES';
export const INTEGRATION_REQUEST_TYPES_SUCCESS = 'INTEGRATION_REQUEST_TYPES_SUCCESS';
export const INTEGRATIONS_REQUEST_BULK_CONNECT = 'INTEGRATIONS_REQUEST_BULK_CONNECT';
export const INTEGRATIONS_REQUEST_CONTACT_LIST = 'INTEGRATIONS_REQUEST_CONTACT_LIST';
export const INTEGRATIONS_REQUEST_CONTACT_LIST_SUCCESS = 'INTEGRATIONS_REQUEST_CONTACT_LIST_SUCCESS';

export const INTEGRATIONS_SHOPIFY_OAUTH_FLOW = 'INTEGRATIONS_SHOPIFY_OAUTH_FLOW';
export const INTEGRATIONS_SHOPIFY_AUTH = 'INTEGRATIONS_SHOPIFY_AUTH';

/**** Hubspot Integration Forms ****/
export const INTEGRATION_REQUEST_HUBSPOT_DATA = 'INTEGRATION_REQUEST_HUBSPOT_DATA';
export const INTEGRATION_RECEIVE_HUBSPOT_FORMS = 'INTEGRATION_RECEIVE_HUBSPOT_FORMS';

// ----------------------------------------------------------------------------
// Media actions
// ----------------------------------------------------------------------------

export const MEDIA_REQUEST = 'MEDIA_REQUEST';
export const MEDIA_REQUEST_IF_NEEDED = 'MEDIA_REQUEST_IF_NEEDED';
export const MEDIA_REQUEST_ALL = 'MEDIA_REQUEST_ALL';
export const MEDIA_REQUEST_DATA_TABLE_COLUMNS = 'MEDIA_REQUEST_DATA_TABLE_COLUMNS';
export const MEDIA_SAVE_DATA_TABLE_COLUMNS = 'MEDIA_SAVE_DATA_TABLE_COLUMNS';
export const MEDIA_REQUEST_ALL_IF_NEEDED = 'MEDIA_REQUEST_ALL_IF_NEEDED';
export const MEDIA_REQUEST_PAGINATED_LIST = 'MEDIA_REQUEST_PAGINATED_LIST';
export const MEDIA_RECEIVED_PAGE = 'MEDIA_RECEIVED_PAGE';
export const MEDIA_SEARCH_UNSTACK = 'MEDIA_SEARCH_UNSTACK';
export const MEDIA_SEARCH_UNSTACK_SUCCESS = 'MEDIA_SEARCH_UNSTACK_SUCCESS';
export const MEDIA_CLEAR_UNSTACK_SEARCH = 'MEDIA_CLEAR_UNSTACK_SEARCH';
export const MEDIA_RECEIVE = 'MEDIA_RECEIVE';
export const MEDIA_REQUEST_PDFS = 'MEDIA_REQUEST_PDFS';
export const MEDIA_CREATE = 'MEDIA_CREATE';
export const MEDIA_CREATION_FAILED = 'MEDIA_CREATION_FAILED';
export const MEDIA_UPLOAD_PROGRESS = 'MEDIA_UPLOAD_PROGRESS';
export const MEDIA_DOWNLOAD_PROGRESS = 'MEDIA_DOWNLOAD_PROGRESS';
export const MEDIA_SEARCH_UNSPLASH = 'MEDIA_SEARCH_UNSPLASH';
export const MEDIA_SEARCH_PEXELS_MEDIA = 'MEDIA_SEARCH_PEXELS_MEDIA';
export const MEDIA_PEXELS_RESULTS = 'MEDIA_PEXELS_RESULTS';
export const MEDIA_CLEAR_PEXELS_RESULTS = 'MEDIA_CLEAR_PEXELS_RESULTS';
export const MEDIA_PEXELS_DOWNLOAD_MEDIA = 'MEDIA_PEXELS_DOWNLOAD_MEDIA';
export const MEDIA_CLEAR_UNSPLASH_RESULTS = 'MEDIA_CLEAR_UNSPLASH_RESULTS';
export const MEDIA_UNSPLASH_RESULTS = 'MEDIA_UNSPLASH_RESULTS';
export const MEDIA_DOWNLOAD_UNSPLASH_PHOTO = 'MEDIA_DOWNLOAD_UNSPLASH_PHOTO';
export const MEDIA_SEARCH_ICONS8 = 'MEDIA_SEARCH_ICONS8';
export const MEDIA_ICONS8_RESULTS = 'MEDIA_ICONS8_RESULTS';
export const MEDIA_CLEAR_ICONS8_RESULTS = 'MEDIA_CLEAR_ICONS8_RESULTS';
export const MEDIA_DOWNLOAD_ICONS8_PHOTO = 'MEDIA_DOWNLOAD_ICONS8_PHOTO';
export const MEDIA_DELETE = 'MEDIA_DELETE';
export const MEDIA_DELETE_SUCCESS = 'MEDIA_DELETE_SUCCESS';

// ----------------------------------------------------------------------------
// Site statistics
// ----------------------------------------------------------------------------

export const SITE_STATISTICS_SET_TYPE = 'SITE_STATISTICS_SET_TYPE';
export const SITE_STATISTICS_REQUEST_IF_NEEDED = 'SITE_STATISTICS_REQUEST_IF_NEEDED';
export const SITE_STATISTICS_REQUEST = 'SITE_STATISTICS_REQUEST';
export const SITE_STATISTICS_RECEIVE = 'SITE_STATISTICS_RECEIVE';

// ----------------------------------------------------------------------------
// Templates
// ----------------------------------------------------------------------------

export const TEMPLATES_REQUEST_ALL = 'TEMPLATES_REQUEST_ALL';
export const TEMPLATES_REQUEST_ALL_IF_NEEDED = 'TEMPLATES_REQUEST_ALL_IF_NEEDED';
export const TEMPLATES_RECEIVE_ALL = 'TEMPLATES_RECEIVE_ALL';
export const TEMPLATES_REQUEST_CLONE = 'TEMPLATES_REQUEST_CLONE';
export const TEMPLAETS_RECEIVE_CLONE_SUCCESS = 'TEMPLAETS_RECEIVE_CLONE_SUCCESS';
export const TEMPLAETS_RECEIVE_CLONE_ERROR = 'TEMPLAETS_RECEIVE_CLONE_ERROR';
export const TEMPLATES_SETTINGS_OPEN = 'TEMPLATES_SETTINGS_OPEN';
export const TEMPLATES_SETTINGS_CLOSE = 'TEMPLATES_SETTINGS_CLOSE';
export const TEMPLATES_REQUEST_CREATE = 'TEMPLATES_REQUEST_CREATE';
export const TEMPLATES_REQUEST_UPDATE = 'TEMPLATES_REQUEST_UPDATE';
export const TEMPLATES_REQUEST_REMOVE = 'TEMPLATES_REQUEST_REMOVE';
export const TEMPLATES_REQUEST_CREATE_SUCCESS = 'TEMPLATES_REQUEST_CREATE_SUCCESS';
export const TEMPLATES_REQUEST_UPDATE_SUCCESS = 'TEMPLATES_REQUEST_UPDATE_SUCCESS';

// ----------------------------------------------------------------------------
// Template Tags
// ----------------------------------------------------------------------------

export const TEMPLATE_TAGS_REQUEST_ALL = 'TEMPLATE_TAGS_REQUEST_ALL';
export const TEMPLATE_TAGS_REQUEST_ALL_IF_NEEDED = 'TEMPLATE_TAGS_REQUEST_ALL_IF_NEEDED';
export const TEMPLATE_TAGS_RECEIVE_ALL = 'TEMPLATE_TAGS_RECEIVE_ALL';

// ----------------------------------------------------------------------------
// Toolbar
// ----------------------------------------------------------------------------

export const TOOLBAR_SET_DRAWER_OPEN = 'TOOLBAR_SET_DRAWER_OPEN';
export const TOOLBAR_SET_DRAWER_CLOSE = 'TOOLBAR_SET_DRAWER_CLOSE';
export const TOOLBAR_ACTIVE_CHANGED = 'TOOLBAR_ACTIVE_CHANGED';
export const TOOLBAR_HOVER_CHANGED = 'TOOLBAR_HOVER_CHANGED';
export const TOOLBAR_CURSOR_ON_CHANGED = 'TOOLBAR_CURSOR_ON_CHANGED';
export const TOOLBAR_ACTIONS_MENU_EXPANDED = 'TOOLBAR_ACTIONS_MENU_EXPANDED';

export const SECTION_ACTIVE_CHANGED = 'SECTION_ACTIVE_CHANGED';

// ----------------------------------------------------------------------------
// Template Generator
// ----------------------------------------------------------------------------

export const TEMPLATE_GENERATOR_CHANGE_CART_DATA = 'TEMPLATE_GENERATOR_CHANGE_CART_DATA';

// ----------------------------------------------------------------------------
// Add Section
// ----------------------------------------------------------------------------

export const ADD_SECTION_SET_DRAWER_OPEN = 'ADD_SECTION_SET_DRAWER_OPEN';
export const ADD_SECTION_SET_DRAWER_CLOSE = 'ADD_SECTION_SET_DRAWER_CLOSE';

// ----------------------------------------------------------------------------
// Content Tests (a/b testing)
// ----------------------------------------------------------------------------

export const CONTENT_TESTS_REQUEST_ALL = 'CONTENT_TESTS_REQUEST_ALL';
export const CONTENT_TESTS_REQUEST_ALL_IF_NEEDED = 'CONTENT_TESTS_REQUEST_ALL_IF_NEEDED';
export const CONTENT_TESTS_RECEIVE_ALL = 'CONTENT_TESTS_RECEIVE_ALL';
export const CONTENT_TESTS_RECEIVE_ARRAY = 'CONTENT_TESTS_RECEIVE_ARRAY';
export const CONTENT_TESTS_RECEIVE_ERROR = 'CONTENT_TESTS_RECEIVE_ERROR';

export const CONTENT_TEST_REQUEST_CREATE = 'CONTENT_TEST_REQUEST_CREATE';
export const CONTENT_TEST_RECEIVE_CREATE_SUCCESS = 'CONTENT_TEST_RECEIVE_CREATE_SUCCESS';
export const CONTENT_TEST_RECEIVE_CREATE_ERROR = 'CONTENT_TEST_RECEIVE_CREATE_ERROR';

export const CONTENT_TEST_REQUEST_START = 'CONTENT_TEST_REQUEST_START';
export const CONTENT_TEST_RECEIVE_START_SUCCESS = 'CONTENT_TEST_RECEIVE_START_SUCCESS';
export const CONTENT_TEST_RECEIVE_START_ERROR = 'CONTENT_TEST_RECEIVE_START_ERROR';

export const CONTENT_TEST_REQUEST_END = 'CONTENT_TEST_REQUEST_END';
export const CONTENT_TEST_RECEIVE_END_SUCCESS = 'CONTENT_TEST_RECEIVE_END_SUCCESS';
export const CONTENT_TEST_RECEIVE_END_ERROR = 'CONTENT_TEST_RECEIVE_END_ERROR';

// ----------------------------------------------------------------------------
// Content test settings
// ----------------------------------------------------------------------------

export const UI_NEW_CONTENT_TEST_SETTINGS_OPEN = 'UI_NEW_CONTENT_TEST_SETTINGS_OPEN';
export const UI_NEW_CONTENT_TEST_SETTINGS_CLOSE = 'UI_NEW_CONTENT_TEST_SETTINGS_CLOSE';
export const UI_NEW_CONTENT_TEST_SETTINGS_UPDATE = 'UI_NEW_CONTENT_TEST_SETTINGS_UPDATE';
export const UI_NEW_CONTENT_TEST_SETTINGS_UPDATE_WEIGHT = 'UI_NEW_CONTENT_TEST_SETTINGS_UPDATE_WEIGHT';
export const UI_NEW_CONTENT_TEST_SETTINGS_UPDATE_CONFIDENCE = 'UI_NEW_CONTENT_TEST_SETTINGS_UPDATE_CONFIDENCE';
export const UI_NEW_CONTENT_TEST_SETTINGS_REQUEST_START = 'UI_NEW_CONTENT_TEST_SETTINGS_REQUEST_START';
export const UI_NEW_CONTENT_TEST_SETTINGS_RECEIVE_START_SUCCESS = 'UI_NEW_CONTENT_TEST_SETTINGS_RECEIVE_START_SUCCESS';
export const UI_NEW_CONTENT_TEST_SETTINGS_RECEIVE_START_ERROR = 'UI_NEW_CONTENT_TEST_SETTINGS_RECEIVE_START_ERROR';

export const UI_IN_PROGRESS_CONTENT_TEST_SETTINGS_OPEN = 'UI_IN_PROGRESS_CONTENT_TEST_SETTINGS_OPEN';
export const UI_IN_PROGRESS_CONTENT_TEST_SETTINGS_CLOSE = 'UI_IN_PROGRESS_CONTENT_TEST_SETTINGS_CLOSE';
export const UI_IN_PROGRESS_CONTENT_TEST_SETTINGS_UPDATE = 'UI_IN_PROGRESS_CONTENT_TEST_SETTINGS_UPDATE';
export const UI_IN_PROGRESS_CONTENT_TEST_SETTINGS_REQUEST_END = 'UI_IN_PROGRESS_CONTENT_TEST_SETTINGS_REQUEST_END';
export const UI_IN_PROGRESS_CONTENT_TEST_SETTINGS_RECEIVE_END_SUCCESS =
  'UI_IN_PROGRESS_CONTENT_TEST_SETTINGS_RECEIVE_END_SUCCESS';
export const UI_IN_PROGRESS_CONTENT_TEST_SETTINGS_RECEIVE_END_ERROR =
  'UI_IN_PROGRESS_CONTENT_TEST_SETTINGS_RECEIVE_END_ERROR';

// ----------------------------------------------------------------------------
// Editor UI
// ----------------------------------------------------------------------------

export const EDITOR_SHOW_PUBLISH_SUCCESS_MESSAGE = 'EDITOR_SHOW_PUBLISH_SUCCESS_MESSAGE';
export const EDITOR_HIDE_PUBLISH_SUCCESS_MESSAGE = 'EDITOR_HIDE_PUBLISH_SUCCESS_MESSAGE';

// ----------------------------------------------------------------------------
// Submit Buttons UI
// ----------------------------------------------------------------------------

export const BUTTONS_SUBMITTING_STATE = 'BUTTONS_SUBMITTING_STATE';
export const BUTTONS_RESET_STATE = 'BUTTONS_RESET_STATE';

// ----------------------------------------------------------------------------
// Fonts
// ----------------------------------------------------------------------------

export const FONTS_REQUEST_ALL = 'FONTS_REQUEST_ALL';
export const FONTS_REQUEST_ALL_IF_NEEDED = 'FONTS_REQUEST_ALL_IF_NEEDED';
export const FONTS_RECEIVE_ALL = 'FONTS_RECEIVE_ALL';

// ----------------------------------------------------------------------------
// Goals
// ----------------------------------------------------------------------------

export const GOALS_REQUEST_ALL = 'GOALS_REQUEST_ALL';
export const GOALS_REQUEST_ALL_IF_NEEDED = 'GOALS_REQUEST_ALL_IF_NEEDED';
export const GOALS_RECEIVE_ALL = 'GOALS_RECEIVE_ALL';
export const GOALS_RECEIVE_ERROR = 'GOALS_RECEIVE_ERROR';

// ----------------------------------------------------------------------------
// Spark Forms
// ----------------------------------------------------------------------------

export const FORM_UPDATE_SUBMISSION_ACTION = 'FORM_UPDATE_SUBMISSION_ACTION';
export const FORM_UPDATE_SUBMIT_BUTTON_VALUE = 'FORM_UPDATE_SUBMIT_BUTTON_VALUE';
export const FORM_FIELD_ADD_OR_UPDATE = 'FORM_FIELD_ADD_OR_UPDATE';
export const FORM_REMOVE_FIELD = 'FORM_REMOVE_FIELD';
export const FORM_UPDATE_FIELDS = 'FORM_UPDATE_FIELDS';
export const FORM_UPDATE_ZAP_STATUS = 'FORM_UPDATE_ZAP_STATUS';
export const FORM_UPDATE_NAME = 'FORM_UPDATE_NAME';
export const FORM_CLEAR_DATA = 'FORM_CLEAR_DATA';
export const FORM_REQUEST_SAVE = 'FORM_REQUEST_SAVE';
export const FORM_AWAIT_SAVE_REQUEST = 'FORM_AWAIT_SAVE_REQUEST';
export const FORM_SAVE_REQUEST_DONE = 'FORM_SAVE_REQUEST_DONE';
export const FORM_REQUEST_DELETE = 'FORM_REQUEST_DELETE';
export const FORM_REQUEST_UPDATE = 'FORM_REQUEST_UPDATE';
export const FORMS_REQUEST_LIST_IF_NEEDED = 'FORMS_REQUEST_LIST_IF_NEEDED';
export const FORMS_RECEIVE_LIST = 'FORMS_RECEIVE_LIST';
export const FORMS_REQUEST_LIST = 'FORMS_REQUEST_LIST';
export const FORM_STORE_SUGGESTED_PICKED_ID = 'FORM_STORE_SUGGESTED_PICKED_ID';
export const FORM_UPDATE_LIST = 'FORM_UPDATE_LIST';
export const FORM_UPDATE_MAILCHIMP_LIST = 'FORM_UPDATE_MAILCHIMP_LIST';
export const FORM_UPDATE_RESPONDERS = 'FORM_UPDATE_RESPONDERS';
export const STORE_SUGGESTED_PICKED_FORM_ID = 'STORE_SUGGESTED_PICKED_FORM_ID';

// ----------------------------------------------------------------------------
// Contacts
// ----------------------------------------------------------------------------

export const CONTACTS_FETCH_COUNT = 'CONTACTS_FETCH_COUNT';
export const CONTACTS_COUNT_RECEIVE = 'CONTACTS_COUNT_RECEIVE';
export const CONTACTS_DELETE = 'CONTACTS_DELETE';
export const CONTACTS_COUNT_START_POLLING = 'CONTACTS_COUNT_START_POLLING';
export const CONTACTS_COUNT_STOP_POLLING = 'CONTACTS_COUNT_STOP_POLLING';
export const CONTACTS_NOTIFICATIONS_PERMISSION_UPDATE = 'CONTACTS_NOTIFICATIONS_PERMISSION_UPDATE';
export const CONTACTS_FETCH_SEARCH_RESULTS = 'CONTACTS_FETCH_SEARCH_RESULTS';
export const CONTACTS_SAVE_SEARCHED_RESULTS = 'CONTACTS_SAVE_SEARCHED_RESULTS';
export const CONTACTS_ADD_OR_UPDATE_CONTACT = 'CONTACTS_ADD_OR_UPDATE_CONTACT';

// ----------------------------------------------------------------------------
// Tasks
// ----------------------------------------------------------------------------

export const TASKS_FETCH_ALL = 'TASKS_FETCH_ALL';
export const TASKS_FETCH_COUNT = 'TASKS_FETCH_COUNT';
export const TASKS_MARK_AS_DONE = 'TASKS_MARK_AS_DONE';
export const TASKS_FETCH_SUCCESS = 'TASKS_FETCH_SUCCESS';
export const TASKS_CREATE_REQUEST = 'TASKS_CREATE_REQUEST';
export const TASKS_UPDATE_REQUEST = 'TASKS_UPDATE_REQUEST';
export const TASKS_DELETE_REQUEST = 'TASKS_DELETE_REQUEST';
export const TASKS_FETCH_COUNT_SUCCESS = 'TASKS_FETCH_COUNT_SUCCESS';
export const TASKS_FETCH_FILTERED_TASKS = 'TASKS_FETCH_FILTERED_TASKS';
export const TASKS_FETCH_COUNT_FOR_CONTACT = 'TASKS_FETCH_COUNT_FOR_CONTACT';
export const TASKS_FETCH_COUNT_FOR_CONTACT_SUCCESS = 'TASKS_FETCH_COUNT_FOR_CONTACT_SUCCESS';

// ----------------------------------------------------------------------------
// Notes
// ----------------------------------------------------------------------------

export const NOTES_CREATE_REQUEST = 'NOTES_CREATE_REQUEST';
export const NOTES_FETCH_FILTERED_NOTES = 'NOTES_FETCH_FILTERED_NOTES';
export const NOTES_FETCH_SUCCESS = 'NOTES_FETCH_SUCCESS';
export const NOTES_UPDATE_REQUEST = 'NOTES_UPDATE_REQUEST';
export const NOTES_DELETE_REQUEST = 'NOTES_DELETE_REQUEST';

// ----------------------------------------------------------------------------
// Payments and Subscriptions
// ----------------------------------------------------------------------------

export const PAYMENTS_CREATE_PRODUCT = 'PAYMENTS_CREATE_PRODUCT';
export const PAYMENTS_CREATE_PRODUCT_SUCCESS = 'PAYMENTS_CREATE_PRODUCT_SUCCESS';
export const PAYMENTS_CREATE_PRODUCT_FAILURE = 'PAYMENTS_CREATE_PRODUCT_FAILURE';
export const PAYMENTS_FETCH_PRODUCTS = 'PAYMENTS_FETCH_PRODUCTS';
export const PAYMENTS_FETCH_PRODUCTS_IF_NEEDED = 'PAYMENTS_FETCH_PRODUCTS_IF_NEEDED';
export const PAYMENTS_FETCH_PRODUCTS_SUCCESS = 'PAYMENTS_FETCH_PRODUCTS_SUCCESS';
export const PAYMENTS_FETCH_PERIODS = 'PAYMENTS_FETCH_PERIODS';
export const PAYMENTS_FETCH_PERIODS_SUCCESS = 'PAYMENTS_FETCH_PERIODS_SUCCESS';
export const PAYMENTS_FETCH_PERIODS_IF_NEEDED = 'PAYMENTS_FETCH_PERIODS_IF_NEEDED';
export const PAYMENTS_DELETE_PRODUCT = 'PAYMENTS_DELETE_PRODUCT';
export const PAYMENTS_DELETE_PRODUCT_SUCCESS = 'PAYMENTS_DELETE_PRODUCT_SUCCESS';
export const PAYMENTS_CREATE_PLAN = 'PAYMENTS_CREATE_PLAN';
export const PAYMENTS_CREATE_PLAN_SUCCESS = 'PAYMENTS_CREATE_PLAN_SUCCESS';
export const PAYMENTS_CREATE_PLAN_FAILURE = 'PAYMENTS_CREATE_PLAN_FAILURE';
export const PAYMENTS_DELETE_PLAN = 'PAYMENTS_DELETE_PLAN';
export const PAYMENTS_DELETE_PLAN_SUCCESS = 'PAYMENTS_DELETE_PLAN_SUCCESS';

// ----------------------------------------------------------------------------
// Timezone
// ----------------------------------------------------------------------------

export const TIMEZONE_REQUEST_ALL_IF_NEEDED = 'TIMEZONE_REQUEST_ALL_IF_NEEDED';
export const TIMEZONE_REQUEST_ALL = 'TIMEZONE_REQUEST_ALL';
export const TIMEZONE_REQUEST_ALL_SUCCESS = 'TIMEZONE_REQUEST_ALL_SUCCESS';

// ----------------------------------------------------------------------------
// Data Tables
// ----------------------------------------------------------------------------

export const DATA_TABLE_CREATE_TABLE = 'DATA_TABLE_CREATE_TABLE';
export const DATA_TABLE_CREATE_TABLE_SUCCESS = 'DATA_TABLE_CREATE_TABLE_SUCCESS';
export const DATA_TABLE_FETCH_ALL_IF_NEEDED = 'DATA_TABLE_FETCH_ALL_IF_NEEDED';
export const DATA_TABLE_FETCH_ALL = 'DATA_TABLE_FETCH_ALL';
export const DATA_TABLE_FETCH_ALL_SUCCESS = 'DATA_TABLE_FETCH_ALL_SUCCESS';
export const DATA_TABLE_ON_TABLE_SELECTION = 'DATA_TABLE_ON_TABLE_SELECTION';
export const DATA_TABLE_CLEAR_TABLE_SELECTION = 'DATA_TABLE_CLEAR_TABLE_SELECTION';
export const DATA_TABLE_REQUEST_TABLE_DATA = 'DATA_TABLE_REQUEST__TABLE_DATA';
export const DATA_TABLE_RECEIVE_TABLE_DATA = 'DATA_TABLE_RECEIVE_TABLE_DATA';
export const DATA_TABLE_RECEIVE_TABLE_COLUMNS = 'DATA_TABLE_RECEIVE_TABLE_COLUMNS';
export const DATA_TABLE_RECEIVE_TABLE_ROWS = 'DATA_TABLE_RECEIVE_TABLE_ROWS';
export const DATA_TABLE_SAVE_RELATED_ROWS = 'DATA_TABLE_SAVE_RELATED_ROWS';
export const DATA_TABLE_UPDATE_CELL = 'DATA_TABLE_UPDATE_CELL';
export const DATA_TABLE_ON_CELL_UPDATE = 'DATA_TABLE_ON_CELL_UPDATE';
export const DATA_TABLE_REORDER_COLUMNS = 'DATA_TABLE_REORDER_COLUMNS';
export const DATA_TABLE_REORDER_COLUMNS_SUCCESS = 'DATA_TABLE_REORDER_COLUMNS_SUCCESS';
export const DATA_TABLE_REORDER_ROWS = 'DATA_TABLE_REORDER_ROWS';
export const DATA_TABLE_REORDER_ROWS_SUCCESS = 'DATA_TABLE_REORDER_ROWS_SUCCESS';
export const DATA_TABLE_UPDATE_ROW_SLUG = 'DATA_TABLE_UPDATE_ROW_SLUG';
export const DATA_TABLE_UPDATE_ROW_SLUG_SUCCESS = 'DATA_TABLE_UPDATE_ROW_SLUG_SUCCESS';
export const DATA_TABLE_UPDATE_COLUMN_SLUG = 'DATA_TABLE_UPDATE_COLUMN_SLUG';
export const DATA_TABLE_UPDATE_COLUMN_SLUG_SUCCESS = 'DATA_TABLE_UPDATE_COLUMN_SLUG_SUCCESS';
export const DATA_TABLE_CREATE_ROW = 'DATA_TABLE_CREATE_ROW';
export const DATA_TABLE_DELETE_ROW = 'DATA_TABLE_DELETE_ROW';
export const DATA_TABLE_DELETE_ROW_SUCCESS = 'DATA_TABLE_DELETE_ROW_SUCCESS';
export const DATA_TABLE_CREATE_ROW_SUCCESS = 'DATA_TABLE_CREATE_ROW_SUCCESS';
export const DATA_TABLE_CREATE_COLUMN = 'DATA_TABLE_CREATE_COLUMN';
export const DATA_TABLE_UPDATE_COLUMN = 'DATA_TABLE_UPDATE_COLUMN';
export const DATA_TABLE_DELETE_COLUMN = 'DATA_TABLE_DELETE_COLUMN';
export const DATA_TABLE_DELETE_COLUMN_SUCCESS = 'DATA_TABLE_DELETE_COLUMN_SUCCESS';
export const DATA_TABLE_CREATE_COLUMN_SUCCESS = 'DATA_TABLE_CREATE_COLUMN_SUCCESS';
export const DATA_TABLE_ADD_RELATED_ROW = 'DATA_TABLE_ADD_RELATED_ROW';

// ----------------------------------------------------------------------------
// Components
// ----------------------------------------------------------------------------

export const COMPONENTS_REQUEST_DEFAULTS_IF_NEEDED = 'COMPONENTS_REQUEST_DEFAULTS_IF_NEEDED';
export const COMPONENTS_REQUEST_DEFAULTS = 'COMPONENTS_REQUEST_DEFAULTS';
export const COMPONENTS_SAVE_DEFAULTS = 'COMPONENTS_SAVE_DEFAULTS';

// ----------------------------------------------------------------------------
// Currency
// ----------------------------------------------------------------------------

export const CURRENCY_REQUEST_IF_NEEDED = 'CURRENCY_REQUEST_IF_NEEDED';
export const CURRENCY_REQUEST_ALL = 'CURRENCY_REQUEST_ALL';
export const CURRENCY_REQUEST_ALL_SUCCESS = 'CURRENCY_REQUEST_ALL_SUCCESS';

// ----------------------------------------------------------------------------
// SHOPIFY - STOREFRONT
// ----------------------------------------------------------------------------

export const SHOPIFY_STOREFRONT_RECEIVE_COLLECTIONS = 'SHOPIFY_STOREFRONT_RECEIVE_COLLECTIONS';
export const SHOPIFY_STOREFRONT_SAVE_COLLECTIONS_MANUALLY = 'SHOPIFY_STOREFRONT_SAVE_COLLECTIONS_MANUALLY';

// ----------------------------------------------------------------------------
// Videos
// ----------------------------------------------------------------------------
export const VIDEOS_REQUEST_ALL = 'VIDEOS_REQUEST_ALL';
export const VIDEOS_REQUEST_ALL_IF_NEEDED = 'VIDEOS_REQUEST_ALL_IF_NEEDED';
export const VIDEOS_RECEIVE_ALL = 'VIDEOS_RECEIVE_ALL';
export const VIDEOS_RECEIVE_ERROR = 'VIDEOS_RECEIVE_ERROR';
export const VIDEOS_SET_VIDEO_WATCHED = 'VIDEOS_SET_VIDEO_WATCHED';
export const UPDATE_VIDEOS_WITH_METADATA = 'UPDATE_VIDEOS_WITH_METADATA';
export const VIDEOS_SET_VIDEO_ELAPSED = 'VIDEOS_SET_VIDEO_ELAPSED';

// ----------------------------------------------------------------------------
// API Keys
// ----------------------------------------------------------------------------
export const API_KEYS_FETCH_KEYS = 'API_KEYS_FETCH_KEYS';
export const API_KEYS_RECEIVE_KEYS = 'API_KEYS_RECEIVE_KEYS';
export const API_KEYS_RECEIVE_KEYS_ERROR = 'API_KEYS_RECEIVE_KEYS_ERROR';
export const API_KEYS_DELETE_KEY = 'API_KEYS_DELETE_KEY';
export const API_KEYS_ADD_KEY = 'API_KEYS_ADD_KEY';
export const API_KEYS_TOGGLE_ADD_API_KEY_DRAWER = 'API_KEYS_TOGGLE_ADD_API_KEY_DRAWER';
export const API_KEYS_CLOSE_ADD_API_KEY_DRAWER = 'API_KEYS_CLOSE_ADD_API_KEY_DRAWER';
