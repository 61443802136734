import React from 'react';
import Spinner from '../../base/Spinner';
import styles from './Loading.module.scss';

export default function Loading(props) {
  return (
    <div className={styles.Loading}>
      <h3 className={styles.header}>Building your site. Hold tight!</h3>
      <Spinner className={styles.spinner} />
    </div>
  );
}
