import { call, put, all, takeLatest } from 'redux-saga/effects';
import * as api from '../services/spark-api';
import * as types from '../actions/actionTypes';
import requireSiteId from './utils/requireSiteId';
import { updateSubmittingState, resetSubmittingState } from '../actions/uiSubmitButtonsStateActions';
import { fetchTaskSuccess } from '../actions/tasksActions';
import omit from 'lodash/omit';
import get from 'lodash/get';

function* createTask(action) {
  const { payload, res, rej } = action;
  const siteId = yield call(requireSiteId, payload.siteId);
  const data = {
    ...omit(payload, 'siteId'),
    site: siteId,
  };

  try {
    yield put(updateSubmittingState(true));
    const response = yield call(api.postTask, data);
    if (response.ok) {
      yield call(fetchCount);
      res();
    } else rej();
    yield put(resetSubmittingState());
  } catch (e) {
    console.error(e);
    rej();
    yield put(resetSubmittingState());
  }
}

function* fetchAll(action) {
  const siteId = yield call(requireSiteId, action.payload.siteId);
  const filter = { ...action.payload, siteId };

  try {
    const response = yield call(api.getTasks, siteId, filter);
    if (response.ok && response.json.next) {
      let nextResponse = response;
      do {
        nextResponse = yield call(api.fetchAuthorizedJSON, nextResponse.json.next);
        if (nextResponse.ok) response.json.results = response.json.results.concat(nextResponse.json.results);
      } while (nextResponse.ok && nextResponse.json.next);
    }
    if (response.ok) {
      yield put(fetchTaskSuccess(response.json.results));
    }
  } catch (e) {
    console.error(e);
  }
}

function* updateTask(action) {
  const { payload, res = () => {}, rej = () => {} } = action;
  try {
    yield put(updateSubmittingState(true));
    const response = yield call(api.putTask, payload);
    if (response.ok) {
      res();
    } else rej();
    yield put(resetSubmittingState());
  } catch (e) {
    yield put(resetSubmittingState());
    console.error(e);
    rej();
  }
}

function* deleteTask(action) {
  const { payload } = action;
  try {
    yield put(updateSubmittingState(true));
    yield call(api.deleteTask, payload);
    yield put(resetSubmittingState());
  } catch (e) {
    console.error(e);
    yield put(resetSubmittingState());
  }
}

function* fetchFiltered(action) {
  const { payload } = action;
  try {
    const response = yield call(api.getFilteredTasks, payload);
    if (response.ok) {
      yield put(fetchTaskSuccess(response.json.results));
    }
  } catch (e) {
    console.error(e);
  }
}

function* fetchCount(action) {
  const siteId = yield call(requireSiteId, get(action, 'payload.siteId'));

  try {
    const response = yield call(api.getTasksCount, siteId);
    if (response.ok) {
      yield put({
        type: types.TASKS_FETCH_COUNT_SUCCESS,
        payload: response.json.count,
      });
    }
  } catch (e) {
    console.error(e);
  }
}

function* markTaskAsDone(action) {
  const { payload } = action;
  try {
    const response = yield call(api.putTask, {
      ...payload,
      status: 'done',
      contact: payload.contact.id,
    });
    if (response.ok) {
      yield call(fetchCount);
    }
  } catch (e) {
    console.error(e);
  }
}

function* fetchCountForContact(action) {
  try {
    const response = yield call(api.getContactTaskCount, action.payload);
    if (response.ok) {
      yield put({
        type: types.TASKS_FETCH_COUNT_FOR_CONTACT_SUCCESS,
        payload: response.json.count,
      });
    }
  } catch (e) {
    console.error(e);
  }
}

function* tasksSaga() {
  yield all([
    takeLatest(types.TASKS_CREATE_REQUEST, createTask),
    takeLatest(types.TASKS_FETCH_ALL, fetchAll),
    takeLatest(types.TASKS_UPDATE_REQUEST, updateTask),
    takeLatest(types.TASKS_DELETE_REQUEST, deleteTask),
    takeLatest(types.TASKS_FETCH_FILTERED_TASKS, fetchFiltered),
    takeLatest(types.TASKS_FETCH_COUNT, fetchCount),
    takeLatest(types.TASKS_MARK_AS_DONE, markTaskAsDone),
    takeLatest(types.TASKS_FETCH_COUNT_FOR_CONTACT, fetchCountForContact),
  ]);
}

export default tasksSaga;
