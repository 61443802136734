import isArray from 'lodash/isArray';
import { insert } from 'object-path-immutable';
import { Field, FieldsList, Row } from './types';

export function insertItemIntoFieldsList(list: any[][], coords: [number] | [number, number], item: any) {
  const [i, j] = coords;

  // Make sure that the coordinates fall within the current bounds of
  // existing list.
  if (i > list.length || (j !== undefined && (!list[i] || j > list[i].length))) return list;

  if (j === undefined) return insert(list, null, item, i);
  return insert(list, i.toString(), item, j);
}

export function forEachField(fieldsList: FieldsList, callback: (field: Field) => void) {
  fieldsList.forEach((fieldOrRow) => {
    if (isArray(fieldOrRow)) {
      fieldOrRow.forEach((field) => callback(field));
    } else {
      callback(fieldOrRow);
    }
  });
}

export function someFields(fieldsList: FieldsList, callback: (field: Field) => boolean) {
  return fieldsList.some((fieldOrRow) => {
    if (isArray(fieldOrRow)) {
      return fieldOrRow.some((field) => callback(field));
    } else {
      return callback(fieldOrRow);
    }
  });
}
