import React from 'react';
import { ButtonGroup, Button } from '../Toolbar2';
import { IconDarkBg, IconLightBg } from '../icons';

interface Props extends ValueOnChangeProps<BrightnessThemeValue> {}

const BrightnessThemeButtons: React.FunctionComponent<Props> = ({ value, onChange }) => {
  return (
    <ButtonGroup compact>
      <Button
        data-tip="Dark text for a light background"
        data-test-id="dark-text-light-background"
        onClick={() => onChange('light-bg')}
      >
        <IconLightBg active={value === 'light-bg'} />
      </Button>
      <Button
        data-tip="Light text for a dark background"
        data-test-id="light-text-dark-background"
        onClick={() => onChange('dark-bg')}
      >
        <IconDarkBg active={value === 'dark-bg'} />
      </Button>
    </ButtonGroup>
  );
};

export default React.memo(BrightnessThemeButtons);
