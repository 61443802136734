import { select, StrictEffect } from 'redux-saga/effects';
import { selectActiveSite } from '../../reducers/sitesReducer';
import isString from 'lodash/isString';
import isObject from 'lodash/isObject';

// Verifies that a valid siteId or site object was passed to it and returns the
// siteId. If there is no siteId, then the generator attempts to select the
// active siteId from state, and throws an error if it isn't available.
export default function* requireSiteId(
  siteOrSiteId: SiteType['id'] | SiteType | undefined
): Generator<StrictEffect, any, any> {
  if (siteOrSiteId) {
    if (isString(siteOrSiteId)) return siteOrSiteId;
    else if (isObject(siteOrSiteId) && siteOrSiteId.id) return siteOrSiteId.id;
  }

  const activeSite = yield select(selectActiveSite);
  if (activeSite) return activeSite.id;

  throw new TypeError('Missing siteId');
}
