import * as types from './actionTypes';

export const updateSubmissionAction = (payload) => ({
  type: types.FORM_UPDATE_SUBMISSION_ACTION,
  payload,
});

export const updateSubmitButtonValue = (payload) => ({
  type: types.FORM_UPDATE_SUBMIT_BUTTON_VALUE,
  payload,
});

export const updateFields = (payload) => ({
  type: types.FORM_UPDATE_FIELDS,
  payload,
});

export const updateZapStatus = (payload) => ({
  type: types.FORM_UPDATE_ZAP_STATUS,
  payload,
});

export const removeField = (payload) => ({
  type: types.FORM_REMOVE_FIELD,
  payload,
});

export const updateFormName = (payload) => ({
  type: types.FORM_UPDATE_NAME,
  payload,
});

export const clearForm = () => ({ type: types.FORM_CLEAR_DATA });

export const saveForm = (payload, siteId, resolve, reject) => ({
  type: types.FORM_REQUEST_SAVE,
  payload: { data: payload, resolve, reject, siteId },
});

export const deleteForm = (id) => ({
  type: types.FORM_REQUEST_DELETE,
  id,
});

export const updateForm = (payload, formId, siteId, resolve, reject) => ({
  type: types.FORM_REQUEST_UPDATE,
  payload: { data: payload, resolve, reject, formId, siteId },
});

export const requestListIfNeeded = (siteId) => ({
  type: types.FORMS_REQUEST_LIST_IF_NEEDED,
  payload: { siteId },
});

export const requestList = (siteId) => ({
  type: types.FORMS_REQUEST_LIST,
  payload: { siteId },
});

export const receiveList = (payload) => ({
  type: types.FORMS_RECEIVE_LIST,
  payload,
});

export const storeSuggestedFormOnSelectId = (payload) => ({
  type: types.FORM_STORE_SUGGESTED_PICKED_ID,
  payload,
});

export const updateList = (payload) => ({
  type: types.FORM_UPDATE_LIST,
  payload,
});

export const updateResponders = (payload) => ({
  type: types.FORM_UPDATE_RESPONDERS,
  payload,
});
