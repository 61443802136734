import { ContainerInfo } from 'components/unstack-components/types';

export const getItemKey = (containerInfo: ContainerInfo, contentKey?: string) => {
  const { contentKey: containerKey, index } = containerInfo;

  if (contentKey) {
    if (index !== undefined) {
      return `${containerKey}.${index}.${contentKey.split('.').pop()}`;
    } else {
      return `${containerKey}.${contentKey.split('.').pop()}`;
    }
  } else {
    return `${containerKey}.${index}`;
  }
};
