import * as types from './actionTypes';

import keyBy from 'lodash/keyBy';

export function request(id) {
  return {
    type: types.MEDIA_REQUEST,
    payload: id,
  };
}

export function requestIfNeeded(id) {
  return {
    type: types.MEDIA_REQUEST_IF_NEEDED,
    payload: id,
  };
}

export function requestAll(siteId) {
  return {
    type: types.MEDIA_REQUEST_ALL,
    payload: { siteId },
  };
}

export function requestAllIfNeeded(siteId) {
  return {
    type: types.MEDIA_REQUEST_ALL_IF_NEEDED,
    payload: { siteId },
  };
}

export function receiveById(mediaById, receivedAt) {
  return {
    type: types.MEDIA_RECEIVE,
    payload: mediaById,
    receivedAt: receivedAt,
  };
}

export function requestPDFs(siteId) {
  return {
    type: types.MEDIA_REQUEST_PDFS,
    payload: { siteId },
  };
}

export function receiveArray(mediaArray, receivedAt) {
  const mediaById = keyBy(mediaArray, 'id');
  return receiveById(mediaById, receivedAt);
}

export function receiveOne(media) {
  const mediaById = { [media.id]: media };
  return receiveById(mediaById);
}

export function requestPaginatedMediaList(page, category) {
  return {
    type: types.MEDIA_REQUEST_PAGINATED_LIST,
    payload: { page, category },
  };
}

export function receivePage(media, data) {
  media.byId = keyBy(media.results, 'id');
  return {
    type: types.MEDIA_RECEIVED_PAGE,
    payload: { media, ...data },
  };
}

export function searchUnstackMedia(query, category) {
  return { type: types.MEDIA_SEARCH_UNSTACK, payload: { query, category } };
}

export function receiveSearchedResult(mediaArray) {
  const mediaById = keyBy(mediaArray, 'id');
  return { type: types.MEDIA_SEARCH_UNSTACK_SUCCESS, payload: mediaById };
}

export function clearUnstackSearch() {
  return { type: types.MEDIA_CLEAR_UNSTACK_SEARCH };
}

export function create(file, category) {
  // Create a Promise, and expose the resolve & reject methods outside the
  // constructor scope to be used by the media saga. This is an antipattern,
  // but worth trying out. The alternatives - thunks, sagas, and state flags -
  // all add significant complexity.
  let resolve, reject;
  const promise = new Promise(function (scopedResolve, scopedReject) {
    resolve = scopedResolve;
    reject = scopedReject;
  });

  return {
    type: types.MEDIA_CREATE,
    payload: { file, category },
    promise,
    resolve,
    reject,
  };
}

export function uploadProgress(progress) {
  return {
    type: types.MEDIA_UPLOAD_PROGRESS,
    payload: progress,
  };
}

export function downloadProgress(progress) {
  return {
    type: types.MEDIA_DOWNLOAD_PROGRESS,
    payload: progress,
  };
}

export function searchUnsplash(query, page) {
  return {
    type: types.MEDIA_SEARCH_UNSPLASH,
    payload: { query, page },
  };
}

export function searchPexels(query, page, category) {
  return {
    type: types.MEDIA_SEARCH_PEXELS_MEDIA,
    payload: { query, page, category },
  };
}

export function pexelResults(payload) {
  return {
    type: types.MEDIA_PEXELS_RESULTS,
    payload,
  };
}

export function clearPexels() {
  return {
    type: types.MEDIA_CLEAR_PEXELS_RESULTS,
  };
}

export function downloadPexelsMedia(url, category) {
  let resolve, reject;
  const promise = new Promise(function (scopedResolve, scopedReject) {
    resolve = scopedResolve;
    reject = scopedReject;
  });
  return {
    type: types.MEDIA_PEXELS_DOWNLOAD_MEDIA,
    payload: { url, category },
    promise,
    resolve,
    reject,
  };
}

export function unsplashResults(pageResponse) {
  return {
    type: types.MEDIA_UNSPLASH_RESULTS,
    payload: { pageResponse },
  };
}

export function clearUnsplashSearch() {
  return {
    type: types.MEDIA_CLEAR_UNSPLASH_RESULTS,
  };
}

export function downloadUnsplashPhoto(id, url) {
  // Create a Promise, and expose the resolve & reject methods outside the
  // constructor scope to be used by the media saga. This is an antipattern,
  // but worth trying out. The alternatives - thunks, sagas, and state flags -
  // all add significant complexity.
  let resolve, reject;
  const promise = new Promise(function (scopedResolve, scopedReject) {
    resolve = scopedResolve;
    reject = scopedReject;
  });

  return {
    type: types.MEDIA_DOWNLOAD_UNSPLASH_PHOTO,
    payload: { id, url },
    promise,
    resolve,
    reject,
  };
}

export function searchIcons8(query, page) {
  return {
    type: types.MEDIA_SEARCH_ICONS8,
    payload: { query, page },
  };
}

export function icons8Results(pageResponse) {
  return {
    type: types.MEDIA_ICONS8_RESULTS,
    payload: { pageResponse },
  };
}

export function clearIcons8Search() {
  return {
    type: types.MEDIA_CLEAR_ICONS8_RESULTS,
  };
}

export function downloadIcons8Photo(id, url) {
  // Create a Promise, and expose the resolve & reject methods outside the
  // constructor scope to be used by the media saga. This is an antipattern,
  // but worth trying out. The alternatives - thunks, sagas, and state flags -
  // all add significant complexity.
  let resolve, reject;
  const promise = new Promise(function (scopedResolve, scopedReject) {
    resolve = scopedResolve;
    reject = scopedReject;
  });

  return {
    type: types.MEDIA_DOWNLOAD_ICONS8_PHOTO,
    payload: { id, url },
    promise,
    resolve,
    reject,
  };
}

export function deleteMedia(id, resolve, reject) {
  return {
    type: types.MEDIA_DELETE,
    payload: id,
    resolve,
    reject,
  };
}

export function removeOne(id) {
  return {
    type: types.MEDIA_DELETE_SUCCESS,
    payload: id,
  };
}

export function requestDataTableColumns(tableId) {
  return {
    type: types.MEDIA_REQUEST_DATA_TABLE_COLUMNS,
    payload: { tableId },
  };
}

export function saveDataTableColumns(payload) {
  return {
    type: types.MEDIA_SAVE_DATA_TABLE_COLUMNS,
    payload,
  };
}
