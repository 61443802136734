import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import SideDrawer from 'components/base/SideDrawer';
import FormControl from 'components/base/FormHelpers/FormControl';
import ValidationMessage from 'components/base/FormHelpers/ValidationMessage';
import * as accountActions from 'actions/accountActions';

export default ({ mfa = {} }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [phone, setPhone] = useState('');
  const [code, setCode] = useState('');
  const dispatch = useDispatch();

  const isInactive = !mfa.status || mfa.status === 'error';
  const isPending = ['inactive', 'pending', 'denied'].includes(mfa.status);
  const isActive = mfa.status === 'approved' || mfa.status === 'active';

  const close = () => {
    if (isInactive) dispatch(accountActions.resetMFAState());
    if (isPending) dispatch(accountActions.resetMFAPendingState());
    setPhone('');
    setCode('');
    setIsOpen(false);
  };

  useEffect(() => {
    if (mfa.status === 'approved')
      setTimeout(() => {
        close();
      }, 2500);
  }, [mfa.status]);

  return (
    <>
      <a
        href="/"
        onClick={(e) => {
          e.preventDefault();
          if (isActive && window.confirm('Are you sure about disabling Two-factor auth?'))
            dispatch(accountActions.disableMFA(mfa.id));
          else if (isInactive) setIsOpen(true);
        }}
      >
        {isActive ? 'Disable two-factor auth' : 'Enable two-factor auth'}
      </a>
      <SideDrawer
        isOpen={isOpen}
        title="Enable two-factor authentication"
        close={close}
        titleTheme={mfa.status === 'approved' ? 'success' : null}
      >
        <form
          onSubmit={(e) => {
            e.preventDefault();
            isPending ? dispatch(accountActions.verifyMFACode(code)) : dispatch(accountActions.requestEnableMFA(phone));
          }}
        >
          {isInactive && (
            <fieldset>
              <label htmlFor="phone_input">Mobile phone number</label>
              <FormControl
                id="phone_input"
                name="phone"
                onChange={(e) => setPhone(e.target.value)}
                value={phone}
                autoComplete="off"
                type="tel"
                placeholder="+1 (000) 000-0000"
              />
              {Boolean(isInactive && mfa.errors) && <ValidationMessage message={mfa.message} />}
            </fieldset>
          )}
          {isPending && (
            <fieldset>
              <label htmlFor="code_input">Confirmation code</label>
              <FormControl
                id="code_input"
                name="code"
                onChange={(e) => setCode(e.target.value)}
                value={code}
                autoComplete="off"
                type="tel"
                placeholder="123456"
              />
              {Boolean(isPending && mfa.errors) && <ValidationMessage message={mfa.message} />}
            </fieldset>
          )}
          {isActive ? (
            <fieldset>
              <input readOnly onClick={() => close()} className="button button-default button-full" value="Close" />
            </fieldset>
          ) : (
            <fieldset>
              <input
                type="submit"
                className="button button-primary button-full"
                value={mfa.isFetching ? 'Sending...' : isInactive ? 'Send confirmation' : 'Send and enable'}
                disabled={!phone}
              />
            </fieldset>
          )}
        </form>
      </SideDrawer>
    </>
  );
};
