import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { set, del } from 'object-path-immutable';
import { NewFormData, FormData } from './types';

import { useEscapeKey } from 'hooks/useKeyBinding';
import useOnClickOutside from 'hooks/useClickOutside';
import { ReactComponent as SvgIconForms } from 'assets/images/icon-forms.svg';
import { ReactComponent as SvgIconEdit } from 'assets/images/icons8-pencil.svg';

import styles from './FormTitle.module.scss';

type FormTitleProps = {
  value: NewFormData | FormData;
  onChange: (value: NewFormData | FormData) => void;
  isCopy: boolean;
  titleRef: any;
};

export default function (props: FormTitleProps) {
  const { value, onChange, isCopy, titleRef } = props;
  //  Manage state of form name whether in edit mode or not
  const [name, setName] = useState<string | undefined>(value?.name);
  const [isEditingName, setIsEditingName] = useState<boolean>(isCopy);
  const ref = useRef();
  const blackListedNodes = ['svg', 'path'];

  useEscapeKey(() => {
    setIsEditingName(false);
    setName(value.name);
  });

  useEffect(() => {
    setName(value.name);
  }, [value.name]);

  useOnClickOutside(
    ref,
    () => {
      setIsEditingName(false);
      onChange(set(value, 'name', name));
    },
    blackListedNodes
  );
  const handleKeyDown = (e: { key: string }) => {
    if (e.key === 'Enter') {
      setIsEditingName(false);
      onChange(set(value, 'name', name));
    }
  };
  return isEditingName || !value.name ? (
    <div className={styles.editingName} ref={ref}>
      <input
        autoFocus
        onFocus={(e) => e.target.select()}
        type="text"
        aria-label="form name"
        value={name}
        onChange={(e) => setName(e.target.value)}
        ref={titleRef}
        onKeyPress={handleKeyDown}
      />
      {value.name && (
        <span
          onClick={
            name
              ? () => {
                  setIsEditingName(false);
                  setName(value.name);
                }
              : () => {}
          }
          className={classNames({ [styles.disabled]: !name })}
        >
          Cancel
        </span>
      )}
    </div>
  ) : (
    <div onClick={() => setIsEditingName(true)} className={styles.nameField}>
      <SvgIconForms />
      <span>{value?.name}</span>
      <SvgIconEdit data-test-id="edit-icon" height={15} width={15} id="pencil" />
    </div>
  );
}
