import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as actions from '../../actions/uiNewContentTestSettingsActions';
import * as selectors from '../../reducers/uiNewContentTestSettingsReducer';
import { requestAllIfNeeded as requestAllGoalsIfNeeded } from '../../actions/goalActions';
import { selectAll as selectAllGoals } from '../../reducers/goalsReducer';

import PageViewGoal from './NewContentTestSettings/PageViewGoal';

import SideDrawer from '../base/SideDrawer';

import classnames from 'classnames';

function hasErrors(errors, key) {
  return errors && errors[key];
}

function errorClassName(errors, key) {
  return hasErrors(errors, key) ? 'fieldWithErrors' : null;
}

function renderErrorMessages(errors, key) {
  if (hasErrors(errors, key)) {
    return errors[key].map((msg, i) => (
      <small key={i} className="errorMessage">
        {msg}
      </small>
    ));
  }
}

export default function NewContentTestSettings() {
  const { isOpen, formDisabled, errors, selectedGoal, name, weights, confidence } = useSelector(
    selectors.selectLocalState
  );

  const dispatch = useDispatch();

  // Fetch goals and content tests
  useEffect(() => {
    // dispatch(requestAllContentTestsIfNeeded());
    dispatch(requestAllGoalsIfNeeded());
  }, [dispatch]);

  const goals = useSelector(selectAllGoals);

  const close = useCallback(() => {
    dispatch(actions.close());
  }, [dispatch]);

  const update = useCallback(
    (obj) => {
      if (!formDisabled) dispatch(actions.update(obj));
    },
    [dispatch, formDisabled]
  );

  const handleChangeName = (e) => {
    update({ name: e.target.value });
  };

  const handleClickStart = useCallback(
    (e) => {
      e.preventDefault();
      dispatch(actions.requestStart());
    },
    [dispatch]
  );

  return (
    <SideDrawer title="A/B test options" isOpen={isOpen} close={close}>
      <form>
        <fieldset>
          <label htmlFor="goal_input">Goal</label>
          {renderErrorMessages(errors, 'goal')}
          <div className="radio-group">
            <PageViewGoal
              goal={selectedGoal}
              onChange={(goal) => update({ selectedGoal: goal })}
              disabled={formDisabled}
            />
            {goals.map((goal) => {
              const isSelected = goal.id === selectedGoal;
              const handleChange = (e) => {
                update({ selectedGoal: goal.id });
              };
              const className = classnames({
                selected: isSelected,
                disabled: formDisabled,
              });

              return (
                <label key={goal.id} className={className}>
                  <input type="radio" value={goal.id} checked={isSelected} onChange={handleChange} readOnly />
                  <div className="title">{goal.name}</div>
                </label>
              );
            })}
          </div>
        </fieldset>
        <fieldset>
          <label>Segmentation</label>
          <div className="bordered-group">
            <label className="label-right-text">
              <div className="title">Control</div>
              <input
                type="text"
                value={weights[0]}
                onChange={(e) => dispatch(actions.updateWeight(0, e.target.value))}
                disabled={formDisabled}
              />
              %
            </label>
            <label className="label-right-text">
              <div className="title">Variant A</div>
              <input
                type="text"
                value={weights[1]}
                onChange={(e) => dispatch(actions.updateWeight(1, e.target.value))}
                disabled={formDisabled}
              />
              %
            </label>
          </div>
          <small>
            Set the relative frequency with which the control and variant will be shown. Most people set this to 50/50.
          </small>
        </fieldset>
        <fieldset>
          <label>Result certainty</label>
          {renderErrorMessages(errors, 'pvalue')}
          <div className="bordered-group">
            <label className="label-right-text">
              <div className="title">Winning variant</div>
              <input
                type="text"
                value={confidence}
                onChange={(e) => dispatch(actions.updateConfidence(e.target.value))}
                disabled={formDisabled}
              />
              %<small className="label-block">The p-value will be {((100 - (confidence || 0)) / 100).toFixed(3)}</small>
            </label>
          </div>
        </fieldset>
        <fieldset>
          <label htmlFor="name_input">Name</label>
          <input
            id="name_input"
            type="text"
            className={errorClassName(errors, 'name')}
            value={name}
            onChange={handleChangeName}
            disabled={formDisabled}
          />
          {renderErrorMessages(errors, 'name')}
          <small>Give your test a name so you can recognize it elsewhere. For example: "Home page hero copy""</small>
        </fieldset>
        <fieldset>
          <input
            type="submit"
            className="button button-primary button-full"
            onClick={handleClickStart}
            value="Publish changes and start test"
            disabled={formDisabled}
          />
          {renderErrorMessages(errors, 'general')}
        </fieldset>
        <p>
          <small>This section will not be editable while the test is running. You can end the test at any time.</small>
        </p>
      </form>
    </SideDrawer>
  );
}
