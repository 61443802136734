import React, { useState, useCallback } from 'react';
import ReactDOM from 'react-dom';
import { CSSTransition } from 'react-transition-group';
import useKeyBinding from '../../hooks/useKeyBinding';

import styles from './BottomDrawer.module.scss';
import { ReactComponent as SvgIconClose } from '../../assets/images/icon-cancel.svg';
import iconMoreSections from '../../assets/images/icon-more-sections@2x.png';

import classnames from 'classnames';

// interface BottomDrawerProps {
//   isOpen: boolean;
//   close?: () => void;
//   title?: string;
//   noPadding?: boolean;
//   render?: () => void;
//   children: React.ReactChildren;
// }

const SCROLL_TOLERANCE = 30;

function BottomDrawer(props) {
  useKeyBinding('keydown', 27, props.isOpen && props.close);

  // The `scrollPosition` indicates which side the drawer body is scrolled to:
  //   scrollPosition = -1 if the body is scrolled all the way to the left.
  //   scrollPosition =  0 if the body is scrolled somewhere in the middle.
  //   scrollPosition =  1 if the body is scrolled all the way to the right.
  const [scrollPosition, setScrollPosition] = useState(-1);
  const handleScroll = useCallback(
    (e) => {
      const el = e.target;

      if (el.scrollLeft < SCROLL_TOLERANCE) {
        setScrollPosition(-1);
      } else if (el.scrollLeft + el.offsetWidth < el.scrollWidth - SCROLL_TOLERANCE) {
        setScrollPosition(0);
      } else {
        setScrollPosition(1);
      }
    },
    [setScrollPosition]
  );

  return (
    <Portal>
      <CSSTransition
        in={props.isOpen}
        timeout={{
          enter: 200,
          exit: 100,
        }}
        classNames={styles}
        mountOnEnter
        unmountOnExit
      >
        {() => (
          <div>
            {props.close && <div className={styles.backdrop} onClick={props.close && props.close} />}
            <div
              data-test-id="drawer"
              className={classnames(
                styles.BottomDrawer,
                {
                  [styles.verticalAligned]: props.verticalAligned,
                  [styles.isAdminPanel]: props.isAdminPanel,
                  [styles.notificationBarActive]: props.notificationBarActive,
                },
                'admin'
              )}
            >
              <div
                className={classnames(styles.header, {
                  [styles.extendBody]: props.extendBody,
                  [styles.verticalAligned]: props.verticalAligned,
                  [styles.isAdminPanel]: props.isAdminPanel,
                })}
              >
                <span className={styles.title}>{props.title}</span>
                {props.close && (
                  <button className={styles.closeButton} onClick={props.close} data-test-id="close-drawer">
                    <SvgIconClose />
                  </button>
                )}
              </div>
              <div
                className={classnames(styles.body, {
                  [styles.padding]: !props.noPadding,
                  [styles.extendBody]: props.extendBody,
                  [styles.notificationBarActive]: props.notificationBarActive,
                })}
                onScroll={handleScroll}
              >
                <div
                  className={classnames({
                    [styles.verticalAligned]: props.verticalAligned,
                  })}
                >
                  {props.render ? props.render() : props.children}
                </div>
              </div>
              {!props.hideScroll && (
                <>
                  {/* eslint-disable-next-line jsx-a11y/alt-text */}
                  <img
                    className={classnames(styles.moreToTheLeft, {
                      [styles.isVisible]: scrollPosition >= 0,
                    })}
                    src={iconMoreSections}
                  />
                  {/* eslint-disable-next-line jsx-a11y/alt-text */}
                  <img
                    className={classnames(styles.moreToTheRight, {
                      [styles.isVisible]: scrollPosition <= 0,
                    })}
                    src={iconMoreSections}
                  />
                </>
              )}
            </div>
          </div>
        )}
      </CSSTransition>
    </Portal>
  );
}

export default BottomDrawer;

const Portal = (props) => {
  return ReactDOM.createPortal(props.children, document.body);
};
