import * as actionTypes from '../actions/actionTypes';

// ----------------------------------------------------------------------------
// Reducer
// ----------------------------------------------------------------------------

const initState = {
  showPublishSuccessMsg: false,
};

export default function (state = initState, action) {
  switch (action.type) {
    case actionTypes.EDITOR_SHOW_PUBLISH_SUCCESS_MESSAGE:
      return {
        ...state,
        showPublishSuccessMsg: true,
      };

    case actionTypes.EDITOR_HIDE_PUBLISH_SUCCESS_MESSAGE:
      return {
        ...state,
        showPublishSuccessMsg: false,
      };

    default:
      return state;
  }
}

// ----------------------------------------------------------------------------
// Selectors
// ----------------------------------------------------------------------------

export function selectLocalState(globalState) {
  return globalState.editor;
}

export function selectShowPublishSuccessMessage(globalState) {
  const state = selectLocalState(globalState);
  return state.showPublishSuccessMsg;
}
