import React from 'react';
import classnames from 'classnames';
import { NavLink } from 'react-router-dom';

import styles from './Nav.module.scss';

interface NavSectionLabelProps {
  onClick: (e?: React.MouseEvent) => void;
  value: string;
  isActive?: boolean;
  icon?: React.ReactNode;
  link?: string;
  urlSite?: string;
  render?: React.FunctionComponent<{ onClick: () => void }>;
}

const NavSectionLabel = (props: NavSectionLabelProps) => {
  const { onClick, value, isActive = false, icon, link, urlSite, render: Render } = props;

  return link ? (
    <NavLink
      onClick={onClick}
      className={({ isActive }) => (isActive ? classnames(styles.activePanel, styles.activeLink) : '')}
      to={`/${urlSite}/${link}`}
    >
      {icon} {value}
    </NavLink>
  ) : (
    (Render && <Render onClick={onClick} />) || (
      <a
        className={classnames({
          [styles.activePanel]: isActive,
          'active-panel': isActive,
        })}
        onClick={onClick}
        data-test-id={value}
      >
        {icon} {value}
      </a>
    )
  );
};

export default NavSectionLabel;
