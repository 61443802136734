import React from 'react';
import Spinner from '../../../base/Spinner';
import icon from '../../../../assets/images/icon-conversions-zero-state.svg';
import { ConversionPathReport } from '../Reports';
import ZeroInsightsState from '../ZeroInsightsState';
import Controls from '../Controls';
import { useFilterState, useConversionPathReportData } from '../report-hooks';

export default function ConversionsTab() {
  const filterState = useFilterState();
  const [data, isLoading] = useConversionPathReportData(filterState);
  return (
    <section>
      <Controls filterState={filterState} />
      {isLoading && <Spinner className="fixed" />}
      {!isLoading && !Boolean(data.length) && (
        <ZeroInsightsState
          icon={icon}
          header="No conversions to report"
          body="There are no conversion metrics that match your filter settings. Publish a form or change your settings to view conversions."
        />
      )}
      {!isLoading && Boolean(data.length) && (
        <>
          <header>
            <h1>Conversion paths</h1>
            <small>See how visitors experience your content before converting.</small>
          </header>
          <ConversionPathReport data={data} isLoading={isLoading} />
        </>
      )}
    </section>
  );
}
