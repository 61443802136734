import React, { useEffect, useRef } from 'react';

import classnames from 'classnames';
import isObject from 'lodash/isObject';

const regexp = /^(\w+):(.*)$/;

export default function FormIdGoal(props) {
  const { goal, onChange, disabled } = props;

  const isSelected = isObject(goal) && goal.type === 'form';

  let match, formType, formId;
  if (isSelected) {
    match = goal.id.match(regexp);
    if (match) [, formType, formId] = match;
    else formType = formId = '';
  }

  const isSelectedRef = useRef(isSelected);

  useEffect(() => {
    isSelectedRef.current = isSelected;
  }, [isSelected]);

  function handleClick() {
    onChange({ type: 'form', id: '' });
  }

  function handleTypeChange(e) {
    const newValue = `${e.target.value}:${formId}`;
    onChange({ type: 'form', id: newValue });
  }

  function handleIdChange(e) {
    const newValue = `${formType}:${e.target.value}`;
    onChange({ type: 'form', id: newValue });
  }

  if (!isSelected) {
    return (
      <label
        className={classnames({
          selected: false,
          disabled: disabled,
        })}
      >
        <div>
          <input type="radio" checked={false} onChange={handleClick} readOnly />
          <div className="title">Submitted a form</div>
        </div>
      </label>
    );
  } else
    return (
      <div
        className={classnames({
          selected: true,
          disabled: disabled,
        })}
      >
        <label>
          <div>
            <input type="radio" checked={true} readOnly />
            <div className="title">Submitted a form</div>
          </div>
        </label>
        <div className="label-block">
          <select onChange={handleTypeChange} value={formType}>
            <option value="" disabled>
              Select a form type
            </option>
            <option value="klaviyo">Klaviyo</option>
            <option value="hubspot">HubSpot</option>
          </select>
          {formType && <input type="text" onChange={handleIdChange} placeholder={`Form ID`} value={formId} autoFocus />}
        </div>
      </div>
    );
}
