import AOS from 'aos';
import 'aos/dist/aos.css';

AOS.init({
  easing: 'ease-out',
  duration: 500,
  once: true,
});

export default AOS;
