import { set, del, insert, push, assign } from 'object-path-immutable';
import React from 'react';
import { useSelector } from 'react-redux';
import { getDevice } from 'reducers/uiReducer';
import { USectionDevices } from 'types/USection';

import RichTextEditor from '../../../../../lib/quill/components/RichTextEditor';

import { RichtextTagArgs } from '../../../tag-types';
import { SectionUiHandlersInterface, SectionHandlersInterface } from '../../../types';
import { getDeviceTypeToSaveTo } from '../../util/helpers/deviceHelper';
import { getItemKey } from '../../util/utils';

interface IRichTextElement extends RichtextTagArgs {
  defaults: any;
  sectionUiHandlers?: SectionUiHandlersInterface;
  sectionHandlers?: SectionHandlersInterface;
  readOnly?: boolean;
  content: USectionDevices;
  onChange: (content: USectionDevices, key?: string, multi?: boolean) => void;
}

const INITIAL_FORMATS = {
  h1: { header: 1 },
  h2: { header: 2 },
  h3: { header: 3 },
};

const RichtextElement: React.FunctionComponent<IRichTextElement> = (props) => {
  const {
    // getValue,
    startTag,
    onChange,
    className,
    defaults = {},
    dataRef: value = '',
    contentKey,
    placeholder,
    enableEmbeds,
    sectionUiHandlers,
    enableTextAlignment,
    maxWords: _maxWords,
    enableHtml: _enableHtml,
    sectionHandlers,
    containerInfo,
    readOnly = false,
    content,
  } = props;

  const key = containerInfo ? getItemKey(containerInfo, contentKey) : contentKey;
  const splitKey = key.split('.');
  const device = useSelector(getDevice);

  return readOnly ? (
    <RichTextEditor
      key={`${contentKey}-read-only`}
      // @ts-ignore
      readOnly={true}
      // @ts-ignore
      initialFormats={INITIAL_FORMATS[startTag]}
      className={className}
      allowEmbeds={enableEmbeds || defaults.enableEmbeds}
      value={value}
      onChange={(value: QuillTextInterface) => {
        onChange(
          set(content, [getDeviceTypeToSaveTo(device, true), ...splitKey.slice(1)], value),
          `content.${splitKey[0]}`
        );
      }}
      hideAlignButtons={!(enableTextAlignment || defaults.enableTextAlignment)}
      boxTextAlign={content.base.textBox?.textAlign} // FIXME
      setIsTextSelected={
        sectionUiHandlers ? (value: boolean) => sectionUiHandlers.setIsRichTextToolbarActive(value) : () => {}
      }
    />
  ) : (
    <RichTextEditor
      key={`${contentKey}-editable`}
      // @ts-ignore
      initialFormats={INITIAL_FORMATS[startTag]}
      className={className}
      allowEmbeds={enableEmbeds || defaults.enableEmbeds}
      value={value}
      onChange={(value: QuillTextInterface) => {
        onChange(
          set(content, [getDeviceTypeToSaveTo(device, true), ...splitKey.slice(1)], value),
          `content.${splitKey[0]}`
        );
      }}
      placeholder={placeholder || defaults.placeholder}
      hideAlignButtons={!(enableTextAlignment || defaults.enableTextAlignment)}
      boxTextAlign={content?.base?.textBox?.textAlign} // FIXME
      setIsTextSelected={
        sectionUiHandlers ? (value: boolean) => sectionUiHandlers.setIsRichTextToolbarActive(value) : () => {}
      }
    />
  );
};

export default RichtextElement;
