import React, { useEffect, useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { compose } from 'redux';
import * as PopperJS from '@popperjs/core';
import { v4 as uuidv4 } from 'uuid';

import useOnClickOutside from 'hooks/useClickOutside';
import FormEditor from './EmbeddedForm/FormEditor';
import AddPayment from './EmbeddedForm/AddPaymentAction';
import withIntegrations from '../../containers/withIntegrations';
import withStripeProducts from '../../containers/withStripeProducts';
import * as addSectionActions from 'actions/addSectionActions';
import { getSparkRenderedForm } from 'services/spark-api';

import { ReactComponent as SvgIconExpand } from '../../assets/images/icons8-plus.svg';
import { ReactComponent as PrimaryButtonIcon } from '../../assets/images/primary-button-icon.svg';
import { ReactComponent as SecondaryButtonIcon } from '../../assets/images/secondary-button-icon.svg';
import { ReactComponent as ActionLinkIcon } from '../../assets/images/action-link-icon.svg';
import { ReactComponent as FormIcon } from '../../assets/images/form-icon.svg';
import { ReactComponent as IconTriangle } from '../../assets/images/triangle-up.svg';

import { usePopper } from 'react-popper';

import styles from './AddActions.module.scss';
import ActionItem from './ActionItem';
import { setActionsMenuExpanded } from 'actions/toolbarActions';
import usePrevious from 'hooks/usePrevious';
import isEqual from 'lodash/isEqual';

const ACTION_BUTTON_POPPER_WIDTH = 240;

type Props = {
  update: (type: string, value: {}) => void;
  addAutoFocus: (type: string) => void;
  actions: {
    primary: string;
    secondary: string;
    checked: string;
    form: { id: string; background: string; type: string };
    text: string;
    payment: string;
  };
  showIfEmpty: boolean;
  enableForms: boolean;
  centered: boolean;
  integrationsBySlug: { stripe: string };
  products: any[];
  textAlign: string;
};
/**
 * TODO:
 *  Disable payments form if there is already one payment form
 *  added to page
 */
const AddActions: React.FunctionComponent<Props> = React.memo((props) => {
  const { actions, showIfEmpty, enableForms, centered, integrationsBySlug, products, textAlign } = props;
  const [isLoading, setLoader] = useState<boolean>(false);
  const [isFormDeleted, setFormDeleted] = useState<boolean>(false);
  const prevForm = usePrevious(actions.form);

  useEffect(() => {
    async function fetch() {
      if (actions.form && !isEqual(prevForm, actions.form)) {
        setLoader(true);
        const res = await getSparkRenderedForm(actions.form?.id, actions.form?.background);
        if (res.ok) setFormDeleted(false);
        else setFormDeleted(true);
        setLoader(false);
      }
    }

    fetch();
  }, [actions.form]);

  const newRef = useRef();

  const dispatch = useDispatch();
  useOnClickOutside(newRef, () => {
    setExpanded(false);
    dispatch(setActionsMenuExpanded(undefined));
  });
  const [uuid] = useState(uuidv4());
  const [isExpanded, setExpanded] = useState(false);
  const [showFormEditor, setShowFormEditor] = useState(false);
  const [showPaymentEditor, setShowPaymentEditor] = useState(false);

  const [referenceElement, setReferenceElement] = useState<Element | PopperJS.VirtualElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
  const [isActionAlignRight, setIsActionAlignRight] = useState(false);
  const [isActionAlignLeft, setIsActionAlignLeft] = useState(false);

  const handleActionAlignment = React.useCallback(() => {
    const { left: leftPos, right: rightPos } = referenceElement?.getBoundingClientRect() || {};
    const isActionAlignRight = rightPos && window.innerWidth - rightPos < ACTION_BUTTON_POPPER_WIDTH;

    setIsActionAlignRight(isActionAlignRight);
    setIsActionAlignLeft(leftPos < ACTION_BUTTON_POPPER_WIDTH);
  }, [referenceElement, textAlign, actions]);

  React.useLayoutEffect(() => {
    handleActionAlignment();
  }, [referenceElement, textAlign, actions]);

  const { styles: popperStyles, attributes } = usePopper(referenceElement, popperElement, {
    placement: isActionAlignRight
      ? 'bottom-end'
      : isActionAlignLeft
      ? 'bottom-start'
      : textAlign === 'left'
      ? 'bottom-start'
      : textAlign === 'right'
      ? 'bottom-end'
      : 'bottom',
  });

  React.useEffect(() => {
    window.addEventListener('resize', handleActionAlignment);

    return () => window.removeEventListener('resize', handleActionAlignment);
  }, [referenceElement]);

  const add = (type: string, value = {}) => {
    props.update(type, value);
    setExpanded(false);
    dispatch(setActionsMenuExpanded(undefined));
    props.addAutoFocus(type);
  };

  const addForm = (value: string) => {
    add('form', value);
  };

  const isFull =
    actions.primary !== null &&
    actions.primary !== undefined &&
    actions.secondary !== null &&
    actions.secondary !== undefined &&
    actions.text !== null &&
    actions.text !== undefined &&
    (!enableForms || (actions.form !== null && actions.form !== undefined));
  const isEmpty = !isFull;

  const forceExpanded = showIfEmpty && isEmpty;
  const forceCollapsed = isFull;

  const hardIsExpanded = forceExpanded || (isExpanded && !forceCollapsed);

  useEffect(() => {
    if (hardIsExpanded) dispatch(addSectionActions.requestDrawerOpen());
    else dispatch(addSectionActions.requestDrawerClose());
  }, [hardIsExpanded]);

  const isStripeMissing = !!integrationsBySlug.stripe;

  const paymentsEnabled = isStripeMissing !== undefined && Boolean(products.length);

  const FormEditorProps = {
    isOpen: showFormEditor,
    close: () => setShowFormEditor(false),
    onChange: addForm,
  };
  const AddPaymentProps = {
    isOpen: showPaymentEditor,
    close: () => setShowPaymentEditor(false),
    onChange: (value: { [key: string]: any }) => add('payment', value),
  };

  const popperLocation = attributes?.popper ? attributes?.popper['data-popper-placement'] : 'bottom';

  return (
    <div
      data-test-id="actions"
      className={`add-actions admin ${hardIsExpanded ? 'expanded' : 'collapsed'} ${
        centered ? 'add-actions-centered' : ''
      }`}
      ref={newRef}
    >
      <SvgIconExpand
        ref={setReferenceElement}
        className="expand-button"
        role="button"
        aria-label="Actions expand"
        onClick={() => {
          setExpanded(!isExpanded);
          dispatch(setActionsMenuExpanded(isExpanded ? undefined : uuid));
        }}
      />
      {hardIsExpanded && (
        <div ref={setPopperElement} style={{ ...popperStyles.popper, zIndex: 9 }} {...attributes.popper}>
          <div
            className={styles.container}
            style={{
              alignItems: isActionAlignRight
                ? 'flex-end'
                : isActionAlignLeft
                ? 'flex-start'
                : textAlign === 'left'
                ? 'flex-start'
                : textAlign === 'right'
                ? 'flex-end'
                : 'center',
            }}
          >
            <IconTriangle
              style={{
                display:
                  popperLocation === 'top' || popperLocation === 'top-start' || popperLocation === 'top-end'
                    ? 'none'
                    : 'initial',
                transform: 'rotate(180deg)',
              }}
            />
            <div className={styles.menu}>
              <ActionItem
                checked={Boolean(actions.primary)}
                disabled={Boolean(actions.primary || actions.form)}
                onClick={() => add('primary')}
                icon={<PrimaryButtonIcon />}
                label="Primary Button"
              />
              <ActionItem
                checked={Boolean(actions.secondary)}
                disabled={Boolean(actions.secondary || actions.payment || actions.form)}
                onClick={() => add('secondary')}
                icon={<SecondaryButtonIcon />}
                label="Secondary Button"
              />
              <ActionItem
                checked={Boolean(actions.text)}
                disabled={Boolean(actions.text)}
                onClick={() => add('text')}
                icon={<ActionLinkIcon />}
                label="Action Link"
              />
              {Boolean(enableForms) && (
                <ActionItem
                  checked={Boolean(actions.form && !isFormDeleted)}
                  disabled={Boolean(
                    actions.primary || actions.secondary || actions.payment || (actions.form && !isFormDeleted)
                  )}
                  onClick={() => setShowFormEditor(true)}
                  icon={<FormIcon />}
                  label="Form"
                />
              )}
            </div>
            <IconTriangle
              style={{
                display:
                  popperLocation === 'bottom' || popperLocation === 'bottom-start' || popperLocation === 'bottom-end'
                    ? 'none'
                    : 'initial',
                transform: 'rotate(0deg)',
              }}
            />
          </div>
        </div>
      )}
      <FormEditor {...FormEditorProps} />
      {/* @ts-ignore */}
      <AddPayment {...AddPaymentProps} />
    </div>
  );
});

export default compose<Props>(withIntegrations, withStripeProducts)(AddActions);
