import * as actionTypes from '../actions/actionTypes';
import { createSelector } from 'reselect';
import union from 'lodash/union';
import sortBy from 'lodash/sortBy';

// ----------------------------------------------------------------------------
// Reducer
// ----------------------------------------------------------------------------

const initState = {
  isFetching: false,
  lastUpdated: null,
  error: null,
  byId: {},
  allIds: [],
  verificationEmailSent: false,
};

export default function accountUsersReducer(state = initState, action) {
  switch (action.type) {
    case actionTypes.ACCOUNT_USER_SETTINGS_CLOSE:
    case actionTypes.ACCOUNT_USER_SETTINGS_OPEN:
      return {
        ...state,
        error: null,
      };

    case actionTypes.ACCOUNT_USER_REQUEST_ALL:
    case actionTypes.ACCOUNT_USER_REQUEST:
    case actionTypes.ACCOUNT_USER_CREATE:
    case actionTypes.ACCOUNT_USER_UPDATE:
    case actionTypes.ACCOUNT_USER_REMOVE:
      return {
        ...state,
        error: null,
        isFetching: true,
      };

    case actionTypes.ACCOUNT_USER_RECEIVE_ALL:
      const { accountUserIds, entities, receivedAt } = action.payload;

      return {
        isFetching: false,
        lastUpdated: receivedAt,
        allIds: accountUserIds,
        byId: entities.accountUsers || {},
      };

    case actionTypes.ACCOUNT_USER_RECEIVE: {
      const accountUser = action.payload;

      return {
        ...state,
        isFetching: false,
        allIds: union(state.allIds, [accountUser.id]),
        byId: { ...state.byId, [accountUser.id]: accountUser },
      };
    }

    case actionTypes.ACCOUNT_USER_RECEIVE_DELETE: {
      const accountUser = action.payload;

      if (accountUser) {
        let byId = {};
        let allIds = [];
        for (let id of state.allIds) {
          if (id !== accountUser.id) {
            byId[id] = state.byId[id];
            allIds.push(id);
          }
        }

        return {
          ...state,
          isFetching: false,
          allIds: allIds,
          byId: byId,
        };
      } else {
        return state;
      }
    }

    case actionTypes.ACCOUNT_USER_RECEIVE_ERROR:
      return {
        ...state,
        error: action.payload,
      };

    case actionTypes.ACCOUNT_USER_RECIEVE_CURRENT:
      return {
        ...state,
        currentUser: action.payload,
      };

    case actionTypes.ACCOUNT_USER_VERIFICATION_EMAIL_SENT:
      return { ...state, verificationEmailSent: true };

    case actionTypes.ACCOUNT_USER_ON_UPDATE_ONBOARDING:
      const { id, ...onboardingStep } = action.payload;
      return {
        ...state,
        byId: {
          ...state.byId,
          [id]: { ...state.byId[id], metadata: onboardingStep },
        },
      };

    default:
      return state;
  }
}

// ----------------------------------------------------------------------------
// Selectors
// ----------------------------------------------------------------------------

function selectLocalState(globalState) {
  return globalState.accountUsers;
}

export function selectHasFetched(globalState) {
  const state = selectLocalState(globalState);
  return state.lastUpdated !== null || state.isFetching;
}

export function selectAllIds(globalState) {
  const state = selectLocalState(globalState);
  return state.allIds;
}

export function selectById(globalState) {
  const state = selectLocalState(globalState);
  return state.byId;
}

export function selectAccountUser(globalState, id) {
  return selectById(globalState)[id];
}

export function selectError(globalState) {
  const state = selectLocalState(globalState);
  return state.error;
}

export const selectAll = createSelector(selectAllIds, selectById, (allIds, byId) => allIds.map((id) => byId[id]));

export const selectAllById = createSelector(selectLocalState, (accountUsers) => accountUsers.byId);

export const selectAllSorted = createSelector(selectAllIds, selectById, (allIds, byId) =>
  sortBy(
    allIds.map((id) => byId[id]),
    'name'
  )
);

export const selectCurrentAccountUser = createSelector(
  selectLocalState,
  (accountUsers) => accountUsers.byId[accountUsers.currentUser]
);

export const selectOwnerStatus = createSelector(selectLocalState, (accountUsers) => {
  const users = Object.values(accountUsers.byId);
  return users.find((user) => user.role === 'owner')?.status;
});

export const selectVerificationEmailStatus = createSelector(
  selectLocalState,
  (accountUsers) => accountUsers.verificationEmailSent
);
