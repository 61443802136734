import * as actionTypes from '../actions/actionTypes';
import { createSelector } from 'reselect';
import union from 'lodash/union';
import { combineReducers } from 'redux';
import { ILayout } from 'types/SiteLayout';
import { ISiteLayoutActions } from 'actions/siteLayoutActions';
import { omit } from 'lodash';

// ----------------------------------------------------------------------------
// Reducer
// ----------------------------------------------------------------------------

type IState = {
  isFetching: boolean | null;
  byId: { [key: string]: ILayout };
  allIds: Array<string>;
};

const initState: IState = {
  isFetching: null,
  byId: {},
  allIds: [],
};

function siteLayoutReducer(state: IState = initState, action: ISiteLayoutActions) {
  switch (action.type) {
    case actionTypes.SITE_LAYOUT_FETCH:
      return {
        ...state,
        isFetching: true,
      };

    case actionTypes.SITE_LAYOUT_RECEIVED_LIST: {
      return {
        isFetching: false,
        byId: Object.assign({}, state.byId, action.payload),
        allIds: union(state.allIds, Object.keys(action.payload)),
      };
    }

    case actionTypes.SITE_LAYOUT_RECEIVE: {
      return {
        isFetching: false,
        byId: Object.assign({}, state.byId, action.payload),
        allIds: union(state.allIds, Object.keys(action.payload)),
      };
    }

    default:
      return state;
  }
}

function headerSections(state: IState = initState, action: ISiteLayoutActions) {
  switch (action.type) {
    case actionTypes.SITE_LAYOUT_RECEIVE_HEADERS:
      return {
        isFetching: false,
        byId: Object.assign({}, state.byId, action.payload),
        allIds: union(state.allIds, Object.keys(action.payload)),
      };

    default:
      return state;
  }
}

function footerSections(state: IState = initState, action: ISiteLayoutActions) {
  switch (action.type) {
    case actionTypes.SITE_LAYOUT_RECEIVE_FOOTERS:
      return {
        isFetching: false,
        byId: Object.assign({}, state.byId, action.payload),
        allIds: union(state.allIds, Object.keys(action.payload)),
      };

    default:
      return state;
  }
}

export default combineReducers({
  layout: siteLayoutReducer,
  headers: headerSections,
  footers: footerSections,
});

// ----------------------------------------------------------------------------
// Selectors
// ----------------------------------------------------------------------------

type IGlobalState = {
  siteLayout?: IState;
  layout?: IState;
  headers?: IState;
  footers?: IState;
};

export function selectLocalState(globalState: IGlobalState) {
  return globalState.siteLayout;
}

export function selectLayoutIsFetching(globalState: IGlobalState) {
  const state = selectLocalState(globalState);
  return (state as IGlobalState).layout.isFetching;
}

export function selectLayoutAllIds(globalState: IGlobalState) {
  const state = selectLocalState(globalState);
  return (state as IGlobalState).layout.allIds;
}

export function selectLayoutById(globalState: IGlobalState) {
  const state = selectLocalState(globalState);
  return (state as IGlobalState).layout.byId;
}

export const selectAll = createSelector(selectLayoutAllIds, selectLayoutById, (allIds, byId) =>
  allIds.map((id) => byId[id])
);

export const selectLayoutByItemType = (itemType: string) =>
  createSelector(selectAll, (layouts: Array<ILayout>) => {
    const layout = layouts.find((layout) => layout.item_type === itemType);
    return layout && omit(layout, ['modified_at']);
  });

export const selectHeaderInstance = createSelector(selectLocalState, (state) => (state as IGlobalState).headers);

export const selectAllHeadersById = createSelector(selectHeaderInstance, (header) => header.byId);

export const selectHeadersId = createSelector(selectHeaderInstance, (header) => header.allIds);

export const selectHeaderById = (id: string) =>
  createSelector(selectAllHeadersById, (headers) => {
    return headers[id];
  });

export const selectHeaderSectionsList = createSelector(selectAllHeadersById, selectHeadersId, (headers, ids) => {
  return ids.map((id) => headers[id]);
});

export const selectFooterSections = createSelector(
  selectLocalState,
  (localState) => (localState as IGlobalState).footers
);

export const selectFooterInstance = createSelector(selectLocalState, (state) => (state as IGlobalState).footers);

export const selectAllFootersById = createSelector(selectFooterInstance, (header) => header.byId);

export const selectFootersId = createSelector(selectFooterInstance, (header) => header.allIds);

export const selectFooterById = (id: string) =>
  createSelector(selectAllFootersById, (footers) => {
    return footers[id];
  });

export const selectFooterSectionsList = createSelector(selectAllFootersById, selectFootersId, (footers, ids) => {
  return ids.map((id) => footers[id]);
});
