import React, { useMemo } from 'react';

import BoxLayout from './BoxLayout';
import SectionLayout from './SectionLayout';

import { StyleProps, ValueOnChangeProps } from '../types';

import styles from '../StyleGuideEditor.module.scss';

export interface LayoutProps extends ValueOnChangeProps<any>, StyleProps {}

export default React.memo((props: LayoutProps) => {
  const { styleGuide, handleChangeEvent, handleChange } = props;

  return (
    <>
      <header ref={props.scrollRef} className={styles.topSpacing}>
        <h1>Layout</h1>
        <small>Set the layout styles used across your site.</small>
      </header>
      <SectionLayout styleGuide={styleGuide} handleChangeEvent={handleChangeEvent} handleChange={handleChange} />
      <BoxLayout styleGuide={styleGuide} handleChangeEvent={handleChangeEvent} handleChange={handleChange} />
    </>
  );
});
