import React, { useState, useCallback } from 'react';
import ErrorBoundary from '../../ErrorBoundary';
import Renderer from '../Component/Renderer';
import PreviewParsingError from './PreviewParsingError';

import { useDispatch, useSelector } from 'react-redux';
import { selectComponentDefaults } from 'reducers/componentReducer';
import { FramePortal } from '../Component/FramePortal';
import editorCanvasStyles from '../../../components/Editor/EditorCanvas.module.scss';
import classnames from 'classnames';
import { setActiveToolbar, setHoverToolbar } from 'actions/toolbarActions';
import { isDeviceMode } from 'types/LegacyEditor';
import { getDevice } from 'reducers/uiReducer';

// quotes: ['first quote', 'second quote'];

// hard-code sectionHandlers because we're not using it here, so it will never
// change.
const sectionHandlers = {};
const sectionUiHandlers = {};

export default function (props) {
  const { component, editorView, jsxErrorCallback } = props;
  const componentsDefaults = useSelector(selectComponentDefaults);
  const device = useSelector(getDevice);

  //  Declaring handlers to control toolbar state
  const [applyOpacity, shouldApplyOpacity] = useState(false);
  const [isRichTextToolbarActive, setIsRichTextToolbarActive] = useState(false);

  const [content, setContent] = useState({ content: { base: {} } });

  const context = {
    content,
    componentId: component && component.id,
  };

  const handleChange = useCallback(
    (context) => {
      setContent({ ...content, ...context.content });
    },
    [content, setContent]
  );

  const PageContent = (
    <div className="eps sriracha" id={'spark'} style={{ background: '#fff' }}>
      <Renderer
        jsx={component.jsx}
        template={component.rendered_template}
        context={context}
        onContextChange={handleChange}
        sectionHandlers={sectionHandlers}
        sectionUiHandlers={{
          ...sectionUiHandlers,
          applyOpacity,
          shouldApplyOpacity,
          isRichTextToolbarActive,
          setIsRichTextToolbarActive,
          isOpen: true,
        }}
        componentsDefaults={componentsDefaults}
        jsxErrorCallback={jsxErrorCallback}
      />
      {component.rendered_css && <style>{component.rendered_css}</style>}
    </div>
  );

  const dispatch = useDispatch();
  const [loadedIframe, setLoadedIframe] = useState(false);

  function clearToolbarStates() {
    if (isDeviceMode()) {
      dispatch(setHoverToolbar(undefined));
      dispatch(setActiveToolbar(undefined));
    }
  }

  if (editorView !== 'full-code' && editorView !== 'full-code-2' && component) {
    return (
      <ErrorBoundary key={component.jsx} renderError={(error) => <PreviewParsingError />}>
        <div
          style={{ padding: 0 }}
          className={classnames(editorCanvasStyles.EditorCanvasContainer, {
            [editorCanvasStyles.hidden]: !loadedIframe && isDeviceMode(),
          })}
        >
          <div
            onClick={clearToolbarStates}
            className={classnames(editorCanvasStyles.EditorCanvas, {
              [editorCanvasStyles.DeviceMode]: isDeviceMode() && loadedIframe,
            })}
          >
            {isDeviceMode() ? (
              <FramePortal style={{ minHeight: '100vh' }} noHeader setIFrameLoaded={() => setLoadedIframe(true)}>
                {PageContent}
              </FramePortal>
            ) : (
              PageContent
            )}
          </div>
        </div>
      </ErrorBoundary>
    );
  }

  return null;
}
