/* eslint-disable jsx-a11y/anchor-is-valid */

import React from 'react';
import { compose } from 'redux';
import withSite from '../../containers/withSite';
import { connect } from 'react-redux';
import classnames from 'classnames';
import * as siteSelectors from '../../reducers/sitesReducer';
import * as blogActions from '../../actions/blogActions';
import * as blogSelectors from '../../reducers/blogsReducer';
import * as articleActions from '../../actions/articleActions';
import * as articleSelectors from '../../reducers/articlesReducer';
import * as uiArticleSettingsActions from '../../actions/uiArticleSettingsActions';
import * as editorSelectors from '../../reducers/editorReducer';
import { selectHasPermission } from '../../reducers/policyReducer';
import { selectById } from '../../reducers/categoriesReducer';

import styles from './EditorAdminBar.module.scss';
import adminBarStyles from '../AdminBar.module.scss';

import { ReactComponent as SvgIconSettings } from '../../assets/images/icons8-settings.svg';
import { ReactComponent as SvgIconUnstackles } from '../../assets/images/icon-sparkles.svg';
import { ReactComponent as SvgIconDiscard } from '../../assets/images/icon-discard-changes.svg';
import { ReactComponent as SvgIconHelp } from '../../assets/images/icon-help-circle.svg';
import { ReactComponent as SvgIconEdit } from '../../assets/images/icon-edit-field.svg';
import { ReactComponent as SvgIconLink } from '../../assets/images/icon-link.svg';

import spinnerCircle from '../../assets/images/spinner-circle.png';

import { CSSTransition } from 'react-transition-group';
import AdminBar from '../AdminBar';
import FloatingPublishButton from './FloatingPublishButton';
import { promoptConformation } from '../../lib/promp-confirmation';

import truncate from 'lodash/truncate';
import { DNS_ACTIVE, SITE_STATUS_PROXY } from '../../constants';
import { PROTOCOL } from '../../settings';

function mapStateToProps(state, ownProps) {
  const { site, articleId } = ownProps;
  const article = articleSelectors.selectArticle(state, articleId);
  const articleDraft = articleSelectors.selectDraft(state, articleId);
  const urlSite = siteSelectors.getUrlSiteFromSite(site);

  let blog;
  if (article) blog = blogSelectors.selectBlog(state, article.blog);

  if (blog == null || article == null || (article && article.content == null) || article.id == null) {
    return {
      isLoading: true,
      urlSite: urlSite,
    };
  } else {
    return {
      isLoading: false,
      urlSite: urlSite,
      scheme: site.scheme,
      siteDomain: siteSelectors.getFullDomainFromSite(site),
      articleName: articleDraft.name,
      articleSlug: articleDraft.slug,
      articleDescription: article.meta.description,
      articleStatus: article.status,
      articleIsChanged: article.is_changed,
      articleHasUnsavedChanges: articleSelectors.selectHasUnsavedChanges(state, articleId),
      articleUrlStructure: blog.config && blog.config.urls.article,
      blogSlug: blog.slug,
      categorySlug: selectById(state, article.categories[0]).slug,
      articleIsPublishing: article.isPublishing,
      isUpdating: article.isUpdating,
      showPublishSuccessMessage: editorSelectors.selectShowPublishSuccessMessage(state),
      hasPermission: selectHasPermission(state),
      dnsStatus: site.dns_status,
      siteStatus: site.site_status,
      domain: site.domain,
      paths: site.proxy_paths,
    };
  }
}

const mapDispatchToProps = {
  publishArticle: articleActions.requestPublish,
  discardArticle: articleActions.requestDiscard,
  openArticleSettings: uiArticleSettingsActions.open,
  requestBlogList: blogActions.requestListIfNeeded,
  save: uiArticleSettingsActions.save,
  addOps: uiArticleSettingsActions.addOps,
};

class EditorAdminBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = { editorIsFresh: true, text: 'New Article' };
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.articleName && (!this.state.text || this.state.text === 'New Article')) {
      this.setState({ text: this.props.articleName });
    }
    if (this.props.articleName !== prevProps.articleName && !this.state.editing && this.props.articleName) {
      this.setState({ text: this.props.articleName });
    }
    if (prevProps.articleId === this.props.articleId) {
      if (prevProps.articleIsChanged && !this.props.articleIsChanged) {
        // Mark the editor as fresh, meaning that there are no draft edits.
        this.setState({ editorIsFresh: true });
      } else if (!prevProps.articleHasUnsavedChanges && this.props.articleHasUnsavedChanges) {
        // Mark the editor as dirty (i.e. not fresh) when the user starts editing.
        this.setState({ editorIsFresh: false });
      }
    } else {
      // The articleId has changed, so reset the editor.
      this.setState({
        editorIsFresh: false,
      });
    }
  }

  handleClickInfo = (e) => {
    e.stopPropagation();
    const { articleId, openArticleSettings } = this.props;
    openArticleSettings(articleId);
  };

  handleClickPublish = (e) => {
    const { articleId, articleStatus, publishArticle, openArticleSettings } = this.props;

    if (articleStatus === 'draft') openArticleSettings(articleId);
    else publishArticle(articleId);
  };

  handleClickDiscard = () => {
    const { discardArticle, articleId } = this.props;
    promoptConformation('discardChanges', () => discardArticle(articleId));
  };

  updateName() {
    this.props.addOps({
      type: 'set',
      path: 'name',
      value: this.state.text,
    });
    this.props.save(this.props.articleId);
  }

  render() {
    const {
      isLoading,
      urlSite,
      isUpdating,
      scheme,
      siteDomain,
      articleSlug,
      articleId,
      articleStatus,
      articleIsChanged,
      articleHasUnsavedChanges,
      articleIsPublishing,
      showPublishSuccessMessage,
      hasPermission,
      articleUrlStructure,
      categorySlug,
      blogSlug,
      dnsStatus,
      siteStatus,
      domain,
      paths,
    } = this.props;

    const { editorIsFresh } = this.state;

    let articleName, articleLink, displayUrl, linkUrl, previewUrl;
    let autoSaveMessage = '';

    if (!isLoading) {
      // Article name
      articleName = this.props.articleName || 'New blog post';
      articleName = truncate(articleName, { length: 30 });

      const mergedSlug =
        articleUrlStructure === 'blog/category/article'
          ? `${blogSlug}/${categorySlug || 'uncategorized'}/`
          : articleUrlStructure === 'blog/article'
          ? `${blogSlug}/`
          : articleUrlStructure === 'category/article'
          ? `${categorySlug || 'uncategorized'}/`
          : '';

      // Article URL
      if (articleSlug === '') {
        if (mergedSlug !== '') displayUrl = siteDomain + '/' + mergedSlug;
        else displayUrl = siteDomain;
      } else if (articleSlug != null) {
        const proxyPath = paths ? paths.blog : '';
        displayUrl = siteDomain + (proxyPath ? `${proxyPath}/` : '/') + mergedSlug + articleSlug;
      }
      if (articleStatus === 'published') linkUrl = (scheme || 'https') + '://' + displayUrl;
      displayUrl = truncate(displayUrl, { length: 40 });

      let proxyPath = '';
      if (dnsStatus === DNS_ACTIVE && siteStatus === SITE_STATUS_PROXY && domain) proxyPath = paths ? paths.blog : '';
      previewUrl = `${PROTOCOL}//${siteDomain}${proxyPath ? `${proxyPath}/` : '/'}item-preview/${articleId}`;

      if (displayUrl)
        articleLink = (
          <a
            data-test-id="site-page-url"
            href={linkUrl}
            target="_blank"
            rel="noopener noreferrer"
            className={styles.url}
          >
            {displayUrl}
          </a>
        );
    }
    const inputVal = isLoading ? '' : this.state?.text;

    // Autosave message
    if (articleHasUnsavedChanges || isUpdating) autoSaveMessage = 'Saving changes...';
    else if (editorIsFresh) autoSaveMessage = '';
    else autoSaveMessage = 'Changes saved';
    const autoSaveColor = autoSaveMessage === 'Publishing...' ? '#647FB4' : '#7D8FB2';
    const showSaveMessage = autoSaveMessage !== 'Changes saved' || articleIsPublishing;
    let publishText = articleStatus !== 'published' ? 'Publish' : articleIsChanged ? 'Publish changes' : 'Published';
    let publishWidth = articleIsPublishing ? 60 : publishText == 'Published' || publishText === 'Publish' ? 120 : 180;

    return (
      <>
        <AdminBar
          fixed
          backTo={`/${urlSite}/blogs`}
          left={
            <>
              <div className={styles.leftText}>
                <span className={styles.inputWrap}>
                  <span class={styles.widthControl}>{inputVal}</span>
                  <input
                    onBlur={() => {
                      this.setState({ editing: false });
                    }}
                    onMouseEnter={() => this.setState({ pageNameHover: true })}
                    onMouseLeave={() => this.setState({ pageNameHover: false })}
                    onFocus={(e) => {
                      e.target.select();
                      this.setState({ editing: true });
                    }}
                    onChange={(e) => {
                      this.setState({ text: e.target.value });
                      this.updateName(e.target.value);
                    }}
                    value={inputVal}
                    disabled={isLoading}
                  />
                </span>
              </div>
              <SvgIconEdit
                className={classnames(styles.editIcon, {
                  [styles.shownIcon]: this.state.pageNameHover && !this.state.editing,
                })}
              />
              {!isLoading && articleStatus === 'published' && (
                <a href={linkUrl} target="_blank" className={styles.iconLink} style={{ marginLeft: 19 }}>
                  <SvgIconLink data-tip="Open published URL" data-place="bottom" />
                </a>
              )}
              {!isLoading && articleStatus === 'draft' && (
                <a href={articleLink} className={styles.draft}>
                  DRAFT
                </a>
              )}
            </>
          }
          right={
            <section className={styles.right} data-test-id="editor-admin-bar-right">
              {showSaveMessage && (
                <div class={styles.saveLabel} style={{ color: autoSaveColor }}>
                  {articleIsPublishing ? 'Publishing...' : autoSaveMessage}
                </div>
              )}
              {articleIsChanged && articleStatus === 'published' && hasPermission('Article:discard') && (
                <div onClick={this.handleClickDiscard} className={styles.discardChanges}>
                  <span>↺</span> Discard changes
                </div>
              )}
              <a
                href={previewUrl}
                target="_blank"
                rel="noopener noreferrer"
                tabIndex={-1}
                className={styles.previewAnchor}
              >
                <button className={`${styles.preview}`}>Preview</button>
              </a>
              <button
                className={`${styles.publish} button`}
                data-test-id="editor-admin-bar-publish"
                onClick={!articleIsPublishing ? this.handleClickPublish : null}
                disabled={(hasPermission && !hasPermission('Article:publish')) || !articleIsChanged}
                style={{ width: publishWidth }}
              >
                {articleIsPublishing ? <img src={spinnerCircle} className={styles.spinner} /> : publishText}
              </button>
              <button className={adminBarStyles.circleIcon} onClick={this.handleClickInfo} aria-label="settings">
                <SvgIconSettings />
              </button>
              <button
                className={classnames(adminBarStyles.circleIcon, styles.helpIcon)}
                onClick={() => window.open('https://support.elasticpath.com', '_blank')}
                aria-label="help"
              >
                <SvgIconHelp />
              </button>
            </section>
          }
          foreGround={
            <CSSTransition
              in={showPublishSuccessMessage}
              classNames={styles.successMsg}
              timeout={300}
              mountOnEnter
              unmountOnExit
              appear
            >
              {(state) => (
                <div className={styles.successMsg} data-test-id="success-message">
                  <div>
                    <SvgIconUnstackles />
                    <strong>Success! </strong> Published to {articleLink}
                  </div>
                </div>
              )}
            </CSSTransition>
          }
        />
      </>
    );
  }
}

export default compose(withSite, connect(mapStateToProps, mapDispatchToProps))(EditorAdminBar);
