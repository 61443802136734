import React, { useCallback, useState } from 'react';
import FormEditor from 'components/Editor/EmbeddedForm/FormEditor';
import { ReactComponent as SvgIconRteAddForm } from '../../../assets/images/icon-rte-add-form.svg';

export default function AddForm(props) {
  const { onChange } = props;
  const [isOpen, setIsOpen] = useState(false);

  const handleChange = useCallback(
    (value) => {
      onChange({ formEntity: value });
    },
    [onChange]
  );

  return (
    <>
      <button onClick={() => setIsOpen(true)} data-tip="Form">
        <SvgIconRteAddForm />
      </button>
      <FormEditor isOpen={isOpen} close={() => setIsOpen(false)} value={null} onChange={handleChange} unstackOnly />
    </>
  );
}
