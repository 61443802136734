/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import * as paymentSelectors from '../../../reducers/paymentReducer';

import SideDrawer from '../../base/SideDrawer';
import FormControl from '../../base/FormHelpers/FormControl';
import SubmissionOptions from './Forms/SparkForm/SubmissionOptions';
import { FormStyling } from './FormStyling';

import styles from './PaymentEmbeds.module.scss';

function mapStateToProps(state) {
  return {
    products: paymentSelectors.selectAllActiveProducts(state),
  };
}

export default connect(mapStateToProps)((props) => {
  const { isOpen, close, products, onChange, value = {} } = props;
  const [data, setData] = useState({ background: '' });

  useEffect(() => {
    if (isOpen && value) setData({ ...value });
  }, [isOpen]);

  const product = products.find((p) => p.id === data.product) || {};

  return (
    <SideDrawer title="Add a payment to this page" isOpen={isOpen} close={close}>
      <form>
        <fieldset>
          <label>Choose which product</label>
          <FormControl
            name="product"
            type="text"
            tag="select"
            value={data.product || ''}
            onChange={(e) => setData({ ...data, product: e.target.value })}
            placeholder="e.g. Insider Plus Member"
          >
            <option value=""></option>
            {products.map((product) => (
              <option value={product.id} key={product.id}>
                {product.name}
              </option>
            ))}
          </FormControl>
        </fieldset>
        {product.id && (
          <fieldset className={styles.productInfo}>
            <small>Product name: {product.name}</small>
            {product.plans.some((p) => p.period === 'one_time') && (
              <>
                <small>Frequency: {product.plans[0].period}</small>
                <small>Price: ${product.plans[0].amount}</small>
              </>
            )}
            {/* <small>Requires a logged in account: No</small> */}
          </fieldset>
        )}
        <SubmissionOptions
          label="What happens after they purchase?"
          onUpdate={(value) => setData({ ...data, onSubmit: value })}
          submissionAction={data.onSubmit || {}}
        />
        <FormStyling onUpdate={(value) => setData({ ...data, background: value })} background={data.background || ''} />
        <fieldset>
          <input
            onClick={() => {
              onChange(data);
              close();
            }}
            value={value.product ? 'Update' : 'Save'}
            className="button button-primary button-full"
            readOnly="readonly"
            disabled={!data.product || !data.onSubmit}
          />
          <button
            onClick={() => {
              close();
            }}
            className="button button-secondary button-full"
          >
            Discard Changes
          </button>
        </fieldset>
        {value.product && (
          <fieldset>
            <a
              href="#"
              role="button"
              className={styles.remove}
              onClick={() => {
                onChange(null);
                close();
              }}
            >
              Remove
            </a>
          </fieldset>
        )}
      </form>
    </SideDrawer>
  );
});
