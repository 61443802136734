import * as actionTypes from '../actions/actionTypes';
import { createSelector } from 'reselect';
import createCachedSelector from 're-reselect';

import union from 'lodash/union';
import map from 'lodash/map';
import keyBy from 'lodash/keyBy';

// Reducer
// ----------------------------------------------------------------------------

const initState = {
  isFetching: false,
  lastUpdated: null,
  error: null,
  byId: {},
  allIds: [],
};

export default function contentTestsReducer(state = initState, action) {
  switch (action.type) {
    case actionTypes.CONTENT_TESTS_REQUEST_ALL:
      return {
        ...state,
        isFetching: true,
      };

    case actionTypes.CONTENT_TESTS_RECEIVE_ALL: {
      const { contentTestIds, entities, receivedAt } = action.payload;

      return {
        isFetching: false,
        lastUpdated: receivedAt,
        error: null,
        allIds: contentTestIds,
        byId: entities.contentTests || {},
      };
    }

    case actionTypes.CONTENT_TESTS_RECEIVE_ARRAY: {
      const { contentTests } = action.payload;

      const allIds = map(contentTests, (ct) => ct.id);
      const byId = keyBy(contentTests, 'id');

      return {
        ...state,
        allIds: union(state.allIds, allIds),
        byId: Object.assign({}, state.byId, byId),
      };
    }

    case actionTypes.CONTENT_TEST_RECEIVE_CREATE_SUCCESS:
    case actionTypes.CONTENT_TEST_RECEIVE_START_SUCCESS:
    case actionTypes.CONTENT_TEST_RECEIVE_END_SUCCESS: {
      const { contentTest } = action.payload;

      return {
        ...state,
        allIds: union(state.allIds, [contentTest.id]),
        byId: {
          ...state.byId,
          [contentTest.id]: contentTest,
        },
      };
    }

    default:
      return state;
  }
}

// Selectors
// ----------------------------------------------------------------------------

export function selectLocalState(globalState) {
  return globalState.contentTests;
}

export function selectHasFetched(globalState) {
  const state = selectLocalState(globalState);
  return state.lastUpdated !== null || state.isFetching;
}

export function selectAllIds(globalState) {
  const state = selectLocalState(globalState);
  return state.allIds;
}

export function selectById(globalState) {
  const state = selectLocalState(globalState);
  return state.byId;
}

export function selectContentTest(globalState, contentTestId) {
  const byId = selectById(globalState);
  return byId[contentTestId];
}

export const selectAll = createSelector(selectAllIds, selectById, (allIds, byId) => allIds.map((id) => byId[id]));

// Select content tests by page id

export const createActiveByPageIdSelector = createSelector(selectAll, (contentTests) => (pageId) => {
  return contentTests.reduce((results, contentTest) => {
    if (contentTest.status === 'active' && contentTest.page === pageId) results.push(contentTest);
    return results;
  }, []);
});

export const selectActiveByPageId = createCachedSelector(
  selectAll,
  (_, pageId) => pageId,
  (contentTests, pageId) => {
    return contentTests.reduce((results, contentTest) => {
      if (contentTest.status === 'active' && contentTest.page === pageId) results.push(contentTest);
      return results;
    }, []);
  }
)((_, pageId) => pageId);

// Select content tests by section id

export const selectActiveBySectionId = createCachedSelector(
  selectAll,
  (_, sectionId) => sectionId,
  (contentTests, sectionId) => {
    return contentTests.reduce((results, contentTest) => {
      if (contentTest.status === 'active' && contentTest.section_id === sectionId) results.push(contentTest);
      return results;
    }, []);
  }
)((_, sectionId) => sectionId);

// Select result metadata

export function selectMetadata(globalState, contentTestId) {
  const contentTest = selectContentTest(globalState, contentTestId);
  if (contentTest) return contentTest.metadata;
}

export function selectCompleteness(globalState, contentTestId) {
  const contentTest = selectContentTest(globalState, contentTestId);
  if (!contentTest) return;

  const metadata = selectMetadata(globalState, contentTestId);

  return Math.min((1 - metadata.pvalue) / (1 - contentTest.pvalue), 1);
}

export function selectWinningVariantId(globalState, contentTestId) {
  const metadata = selectMetadata(globalState, contentTestId);
  return metadata.winner;
}

export function selectVariantMetaDataById(globalState, contentTestId) {
  const metadata = selectMetadata(globalState, contentTestId);

  if (!metadata.variants) return;

  const variantMetadata = {};
  for (let variant of metadata.variants) {
    variantMetadata[variant.id] = variant;
  }

  return variantMetadata;
}
