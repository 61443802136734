import React, { useMemo } from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import * as siteLayoutSelector from 'reducers/siteLayoutReducer';
import Container from 'components/unstack-components/Component';
import * as pageSelectors from '../../../reducers/pagesReducer';

import { ReactComponent as SvgTextLinkArrow } from '../../../assets/images/icon-text-link-arrow.svg';
import SitePageHeader from './FullHeader';
import LandingPageHeader from './MiniHeader';
import { ILayout } from 'types/SiteLayout';
import { SectionHandlersInterface, SectionUiHandlersInterface } from 'components/unstack-components/types';
import { USection } from 'types/USection';

import styles from './HeaderFooterOverlay.module.scss';

function getDefaultHeader(layout: string, itemType: string) {
  switch (layout) {
    case 'full':
      return SitePageHeader;
    case 'minimal':
      return LandingPageHeader;
    case 'none':
      return null;
    default:
      return itemType === 'landing_page' ? LandingPageHeader : SitePageHeader;
  }
}

type IProps = {
  layout: ILayout;
  pageId: string;
  itemType: string;
  siteDomain: string;
};

function HeaderRenderer(props: IProps) {
  const { layout, pageId, itemType, siteDomain } = props;
  const page = useSelector((state) => pageSelectors.selectPage(state, pageId));
  const siteLevelCustomHeader = useMemo(() => layout?.default_header, [page, layout]);

  const { customHeader, builtInHeader } = useMemo(() => {
    const customHeader = page.header;

    const builtInHeader = page.config.header_layout;

    return { customHeader, builtInHeader };
  }, [page, itemType]);

  const hasCustomHeader = !builtInHeader && (customHeader || siteLevelCustomHeader);
  const renderableSection = useSelector((state) => siteLayoutSelector.selectHeaderById(hasCustomHeader)(state));

  const DefaultHeader = useMemo(() => {
    return getDefaultHeader(builtInHeader, itemType);
  }, [builtInHeader, itemType]);

  if (renderableSection) {
    return (
      <div style={{ position: 'relative' }}>
        <Container
          content={renderableSection as unknown as USection}
          onContentChange={() => {}}
          sectionHandlers={{} as SectionHandlersInterface}
          sectionUiHandlers={{} as SectionUiHandlersInterface}
        />
        <div className={classnames('admin', styles.overlay)}>
          <Link to={`/${siteDomain}/elements`} data-test-id={'header-footer-styles-navigator'}>
            You can change your header in Header & Footer
            <SvgTextLinkArrow />
          </Link>
        </div>
      </div>
    );
  }

  return DefaultHeader ? <DefaultHeader pageId={pageId} /> : null;
}

export default HeaderRenderer;
