import createCachedSelector from 're-reselect';
import kebabCase from 'lodash/kebabCase';

import { selectActiveSite } from './sitesReducer';
import * as actionTypes from '../actions/actionTypes';

// Reducer
// ----------------------------------------------------------------------------

const initState = {
  isFetching: null,
  tags: [],
};

export default function (state = initState, action) {
  switch (action.type) {
    case actionTypes.TEMPLATE_TAGS_REQUEST_ALL:
      return {
        ...state,
        isFetching: true,
      };

    case actionTypes.TEMPLATE_TAGS_RECEIVE_ALL:
      return {
        ...state,
        isFetching: false,
        tags: action.payload.tags,
      };

    default:
      return state;
  }
}

// Raw selectors
// ----------------------------------------------------------------------------

export function selectLocalState(globalState) {
  return globalState.templateTags;
}

export function selectIsFetching(globalState) {
  const state = selectLocalState(globalState);
  return state.isFetching;
}

export function selectTags(globalState) {
  const state = selectLocalState(globalState);
  return state.tags;
}

export const selectTabOptions = createCachedSelector(
  selectTags,
  selectActiveSite,
  (_state, pageType) => pageType,
  (_state, _pageType, isShopifyConnected) => isShopifyConnected,
  (tags, site, pageType, isShopifyConnected) => {
    const filteredTags = !isShopifyConnected
      ? tags.filter((tag) => tag.for_ecommerce === false).filter((tag) => tag.item_type.includes(pageType))
      : tags.filter((tag) => tag.item_type.includes(pageType));
    var tabOptions = {};
    tabOptions.all = 'All';
    filteredTags.map((tag) => (tabOptions[kebabCase(tag.id)] = tag.id));
    tabOptions.custom = site ? `${site.name}'s templates` : 'Custom Templates';
    return tabOptions;
  }
)((state, pageType) => pageType || '');
