import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { selectOne } from 'reducers/mediaReducer';
import { IS_VIDEO_REGEX, MEDIA_URL_REGEXP } from '../../../../../../constants';

import { requestIfNeeded } from 'actions/mediaActions';

import noMediaSvg from 'assets/images/icon-media-removed.svg';

import ParallaxUpdater from '../../../../ParallaxUpdater';

type Props = {
  value: any;
  domRef: React.RefObject<HTMLElement>;
};

const mapDispatchToProps = {
  requestIfNeeded,
};

//  @ts-ignore
function mapStateToProps(state, ownProps) {
  let mediaMatch, mediaId, media;
  if (
    ownProps?.value?.backgroundImage?.url &&
    (mediaMatch = ownProps?.value?.backgroundImage?.url.match(MEDIA_URL_REGEXP))
  ) {
    mediaId = mediaMatch[1];
    media = mediaId ? selectOne(state, mediaId) : null;
  }
  return {
    mediaId,
    parsedSrc: media ? (media.file ? media.file : noMediaSvg) : null,
    category: media ? media.category_id : 'image',
    poster: media ? (media.poster ? media.poster : noMediaSvg) : null,
  };
}

const BackgroundLayer: React.FunctionComponent<Props> = ({ value, domRef, ...props }) => {
  const {
    backgroundColor,
    backgroundImage,
    parallax,
    backgroundSize,
    backgroundRepeat,
    backgroundPositionX,
    backgroundPositionY,
    backgroundOptimization,
  } = value;

  const { isExternalSrc } = backgroundImage || {};
  //  @ts-ignore
  const { mediaId, parsedSrc, media, requestIfNeeded } = props;
  const isVideo = IS_VIDEO_REGEX.test(parsedSrc) || isExternalSrc;

  useEffect(() => {
    if (mediaId) requestIfNeeded(mediaId);
  }, [requestIfNeeded, mediaId]);

  return (
    <div className="section-background" style={{ overflow: 'hidden' }}>
      <ParallaxUpdater value={backgroundImage && parallax} el={domRef.current} />
      {backgroundImage &&
        (isVideo ? (
          <div
            key="background-video"
            className="section-background section-background-media"
            data-parallax-speed={parallax}
            style={{
              height: parallax ? `${(1 + parallax) * 100}%` : null,
            }}
          >
            <div style={{ width: '100%', height: '100%' }}>
              <video
                src={parsedSrc}
                preload="auto"
                autoPlay
                playsInline
                muted
                loop
                webkit-playsinline
                x5-playsinline
                style={{ width: '100%', height: '100%' }}
              ></video>
            </div>
            <style>{`
            .section .section-background-media video {
              object-fit: ${backgroundSize};
              object-position: ${backgroundPositionX} ${backgroundPositionY};
            }
          `}</style>
          </div>
        ) : (
          <>
            <div
              key="background-image"
              className="section-background section-background-media"
              data-test-id="section-background-image"
              data-parallax-speed={parallax}
              style={{
                backgroundImage: `url(${parsedSrc})`,
                backgroundSize: backgroundSize,
                backgroundRepeat: backgroundRepeat,
                backgroundPositionX: backgroundPositionX,
                backgroundPositionY: backgroundPositionY,
                height: parallax ? `${(1 + parallax) * 100}%` : null,
              }}
            />
          </>
        ))}
      {backgroundColor && (
        <div
          key="background-color"
          className="section-background"
          data-test-id="background-color-layer"
          style={{
            backgroundColor: backgroundColor,
          }}
        />
      )}
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(BackgroundLayer);
