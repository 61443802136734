import * as actionTypes from './actionTypes';

export const request = (id) => ({
  type: actionTypes.ITEM_REQUEST,
  payload: id,
});

export const save = (item) => ({
  type: actionTypes.ITEM_SAVE,
  item,
});

export const requestIfNeeded = (id) => ({
  type: actionTypes.ITEM_REQUEST_IF_NEEDED,
  payload: id,
});

export const requestItemsFilteredList = (payload) => ({
  type: actionTypes.ITEM_REQUEST_FILTERED_LIST,
  payload,
});

export const fetchList = (payload) => ({
  type: actionTypes.ITEM_REQUEST_FILTERED_LIST,
  payload,
});
