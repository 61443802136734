import React from 'react';

import { connect } from 'react-redux';
import { compose } from 'redux';
import isEqual from 'lodash/isEqual';
import withSiteId from '../../containers/withSiteId';

import { getCoupon } from '../../services/spark-api';
import { selectHasPermission } from 'reducers/policyReducer';
import { selectSubscriptionProducts } from 'reducers/subscriptionReducer';
import { requestCreate as createSubscription } from '../../actions/subscriptionActions';

import { CardElement, ElementsConsumer } from '@stripe/react-stripe-js';
import Elements from 'lazy/StripeElements';

import styles from './PaymentForm.module.scss';
import stripeStyles from './Stripe.module.scss';
import { ReactComponent as SvgIconPopOut } from '../../assets/images/icon-pop-out.svg';
import { ReactComponent as SvgCheckmark } from '../../assets/images/icon-checked-circle.svg';

import classnames from 'classnames';

const mapStateToProps = (state) => {
  return {
    products: selectSubscriptionProducts(state),
    hasPermission: selectHasPermission(state),
  };
};

const mapDispatchToProps = { createSubscription };

function formatMoney(amount, coupon) {
  if (coupon && coupon.trial_period_days) {
    return '0 today';
  }
  return amount.toFixed(0).replace(/\d(?=(\d{3})+(\.|$))/g, '$&,');
}

function getPlanPrice(plan, frequency) {
  return plan[frequency + '_amount'];
}

class PaymentForm extends React.Component {
  constructor(props) {
    super();

    this.state = {
      selectedPlan: props?.products?.find((product) => product.product_id === 'solopreneur'),
      selectedFrequency: 'month',
      couponCode: '',
      coupon: null,
      errorMessage: null,
      isFetching: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.products, this.props.products)) {
      this.setState({
        selectedPlan: this.props.products.find((product) => product.product_id === 'solopreneur'),
      });
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ isFetching: true });

    this.props.stripe.createToken(this.props.elements.getElement(CardElement)).then((response) => {
      const { token, error } = response;

      if (error) {
        this.setState({
          errorMessage: error.message,
          isFetching: false,
        });
      } else {
        const { selectedPlan, selectedFrequency, coupon, couponCode } = this.state;
        const plan_id = `${selectedPlan.product_id}_${selectedFrequency}`;

        const promise = new Promise((resolve, reject) => {
          this.props.createSubscription(
            this.props.siteId,
            plan_id, // Plan ID
            token.id, // Token ID
            token.card.id, // Card ID
            coupon ? coupon.id : couponCode,
            resolve,
            reject
          );
        });
        promise
          .catch((errorMessage) => {
            this.setState({ errorMessage });
          })
          .finally(() => this.setState({ isFetching: false }));
      }
    });
  };

  handleChange = (e) => {
    let update = {};
    update[e.target.name] = e.target.value;
    this.setState(update);
  };

  checkCoupon = (e) => {
    const { couponCode } = this.state;
    this.setState({ coupon: { isFetching: true } });
    getCoupon(couponCode).then((response) => {
      if (response.ok) {
        this.setState({ coupon: response.json });
      } else if (response.status === 404) {
        this.setState({
          coupon: { error: 'Code not found. Please try again.' },
        });
      } else if (response.status === 429) {
        this.setState({
          coupon: { error: 'Too many attempts. Try again later.' },
        });
      }
    });
  };

  render() {
    const { selectedPlan, selectedFrequency, couponCode, coupon, errorMessage, isFetching } = this.state;

    const selectedPrice = getPlanPrice(selectedPlan, selectedFrequency);
    const anualSavings = selectedPlan.month_amount * 12 - selectedPlan.year_amount;

    return (
      <form className={styles.PaymentForm} onSubmit={this.handleSubmit} data-test-id="payment-form">
        <fieldset>
          <label>
            Plan
            <a
              className={styles.comparePlans}
              href="https://www.unstack.com/websites/pricing"
              target="_blank"
              rel="noopener noreferrer"
            >
              Compare plans <SvgIconPopOut />
            </a>
          </label>
          <div className={styles.radioGroup} data-test-id="radiogroup">
            {this.props.products?.map((product) => {
              const price = getPlanPrice(product, selectedFrequency);
              const abbrPeriod = selectedFrequency === 'month' ? 'mo' : 'year';
              const isSelected = selectedPlan.product_id === product.product_id;

              return (
                <label key={product.product_id} className={classnames({ [styles.selected]: isSelected })}>
                  <input type="radio" checked={isSelected} onChange={() => this.setState({ selectedPlan: product })} />
                  <div className={styles.right}>
                    <small>
                      ${formatMoney(price)}/{abbrPeriod}
                    </small>
                  </div>
                  <div className={styles.title}>
                    {product.name}
                    {product.product_id === 'professional' && ' (most popular)'}
                  </div>
                  <div className={styles.description}>{product.description}</div>
                </label>
              );
            })}
          </div>
        </fieldset>
        <fieldset>
          <label>Billing frequency</label>
          <div className={styles.radioGroup}>
            <label
              className={classnames({
                [styles.selected]: selectedFrequency === 'month',
              })}
              data-test-id="monthly"
            >
              <input
                type="radio"
                checked={selectedFrequency === 'month'}
                onChange={() => this.setState({ selectedFrequency: 'month' })}
              />
              <div className={styles.title}>Monthly</div>
            </label>
            <label
              className={classnames({
                [styles.selected]: selectedFrequency === 'year',
              })}
              data-test-id="annually"
            >
              <input
                type="radio"
                checked={selectedFrequency === 'year'}
                onChange={() => this.setState({ selectedFrequency: 'year' })}
              />
              <div className={styles.right}>
                <span className={styles.tag}>Save ${formatMoney(anualSavings)}</span>
              </div>
              <div className={styles.title}>Annually</div>
            </label>
          </div>
        </fieldset>
        <fieldset>
          <label htmlFor="">Credit card</label>
          <CardElement classes={stripeStyles} style={{ invalid: { iconColor: '#CB52B0', color: '#CB52B0' } }} />
          {errorMessage && <small className="errorMessage" dangerouslySetInnerHTML={{ __html: errorMessage }}></small>}
        </fieldset>

        {coupon && coupon.id ? (
          <fieldset>
            <input type="hidden" name="coupon" value={coupon.id} />
            <p className={styles.coupon}>
              <SvgCheckmark />
              <b>Coupon applied:</b> {coupon.name}
            </p>
          </fieldset>
        ) : (
          <fieldset>
            <input
              type="text"
              name="couponCode"
              maxLength="65"
              placeholder="Reference code (optional)"
              className={styles.couponCode + ' ph-redact-input'}
              value={couponCode}
              onChange={this.handleChange}
              disabled={coupon && (coupon.isFetching || coupon.id)}
            />
            <button
              type="button"
              className="button button-secondary"
              onClick={this.checkCoupon}
              disabled={coupon && (coupon.isFetching || coupon.id)}
            >
              Check
            </button>
            {coupon && coupon.error && <small className="errorMessage">{coupon.error}</small>}
          </fieldset>
        )}
        <fieldset>
          {!this.props.hasPermission('AccountSubscription:create', 'role') && (
            <small className="errorMessage">
              You don't have permission to upgrade. Please contact your account owner.
            </small>
          )}
          <input
            disabled={!this.props.hasPermission('AccountSubscription:create', 'role')}
            type="submit"
            className="button button-primary button-full"
            value={isFetching ? 'Loading...' : `Upgrade and pay $${formatMoney(selectedPrice, coupon)}`}
          />
        </fieldset>
      </form>
    );
  }
}

export default compose(
  withSiteId,
  connect(mapStateToProps, mapDispatchToProps)
)((props) => (
  <React.Suspense fallback={<div>Loading...</div>}>
    <Elements>
      <ElementsConsumer>
        {({ stripe, elements }) => <PaymentForm stripe={stripe} elements={elements} {...props} />}
      </ElementsConsumer>
    </Elements>
  </React.Suspense>
));
