import React from 'react';

import styles from './ProgressBar.module.scss';

export default ({ progress }) => {
  return (
    <div className={styles.tracker}>
      <div style={{ width: `${progress * 100}%` }} className={styles.progressedTrack} />
      <div className={styles.progressText}>{(progress * 100).toFixed(0)}%</div>
    </div>
  );
};
