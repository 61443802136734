import React from 'react';
import Spinner from '../../../base/Spinner';
import icon from '../../../../assets/images/icon-tests-zero-state.svg';
import ZeroInsightsState from '../ZeroInsightsState';
import { ContentTestReport } from '../Reports';
import { useFilterState, useContentTestReportData } from '../report-hooks';

export default function ABTestTab() {
  const filter = useFilterState();
  const [data, isLoading] = useContentTestReportData(filter);

  return (
    <section>
      {isLoading && <Spinner className="fixed" />}
      {!isLoading && !data.length && (
        <ZeroInsightsState
          icon={icon}
          header="No test results to report"
          body="There are no A/B test results to report. You can create and publish tests on your website or landing pages."
        />
      )}
      {!isLoading && Boolean(data.length) && (
        <>
          <header>
            <h1>A/B Tests</h1>
            <small>See how your content tests are performing.</small>
          </header>
          <ContentTestReport data={data} isLoading={isLoading} />
        </>
      )}
    </section>
  );
}
