/* eslint-disable jsx-a11y/anchor-is-valid */

import React from 'react';
import { compose } from 'redux';
import { useDispatch } from 'react-redux';
import * as articleActions from '../../../actions/articleActions';

type Props = {
  articleId: string;
};

function UndeleteArticle(props: Props) {
  const dispatch = useDispatch();

  const handleClick = (e: any) => {
    e.preventDefault();
    const { articleId } = props;

    dispatch(
      articleActions.requestUndelete(articleId, function (articleId: string) {
        window.location.reload();
      })
    );
  };

  return (
    <a href="#" onClick={handleClick} data-test-id="undelete">
      undelete this
    </a>
  );
}

export default UndeleteArticle;
