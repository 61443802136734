/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { v4 as uuidv4 } from 'uuid';

import classNames from 'classnames';
import VisibilitySensor from 'react-visibility-sensor';

import Spinner from 'components/base/Spinner';

import { useStorefrontCollections, useStorefrontProducts } from 'components/Storefront/hooks';
import useActiveSite from 'hooks/useActiveSite';

import * as integrationSelection from 'reducers/integrationsReducer';

import { IconChevronDownSmall } from 'components/Editor/Toolbars2/icons';

import styles from './ProductGenerator.module.scss';
import { ProductLabel } from './ProductLabel';

export const PaginatedList = ({
  setPaginate,
  type,
  previousParent,
  nextPage,
  searchData,
  setSearchData,
  ...listViewProps
}) => {
  const View = type === 'categories' ? CategoryView : ProductView;
  const [results, setResults] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const [completeList, setCompleteList] = useState([]);

  useEffect(() => {
    if (results?.data) setCompleteList([...completeList, ...results.data]);
  }, [results]);

  return (
    <>
      <a
        className="action"
        onClick={() => {
          setPaginate('');
          setSearchData({});
        }}
      >
        Back {previousParent.length > 1 ? `to ${previousParent[1]}` : ''}
      </a>
      <View
        isPaginated
        previousParent={previousParent}
        completeList={completeList}
        setPaginate={setPaginate}
        nextPage={nextPage}
        setResults={setResults}
        setIsLoading={setIsLoading}
        setCompleteList={setCompleteList}
        searchData={searchData}
        setSearchData={setSearchData}
        {...listViewProps}
      />
      {isLoading && <Spinner style={{ margin: 'auto' }} className="fixed" />}
      {!isLoading && (
        <VisibilitySensor
          onChange={(visible) => {
            visible && results?.links?.next && setSearchData({ ...searchData, nextPage: results.links.next });
          }}
        >
          <span>&nbsp;</span>
        </VisibilitySensor>
      )}
    </>
  );
};

export const ProductView = ({
  hierarchy = [],
  onChange,
  isBaseView,
  setPaginate,
  setResults,
  setIsLoading,
  isPaginated,
  completeList,
  setCompleteList,
  setIsProductsLoading,
  isDataLoading,
  searchData,
  value = {},
  setIsSelecting,
  isTemplateSettings,
}) => {
  const { search, nextPage } = searchData;
  const { pins = [] } = value;
  const [products, isLoading] = useStorefrontProducts(search, isBaseView && !search ? 5 : 10, hierarchy[0], nextPage);
  const { data = [], links, meta } = products;
  const showMore = links?.next && (!isBaseView || search) && !isPaginated;

  const list = isPaginated ? completeList : data;
  useEffect(() => {
    isPaginated && setResults(products);
  }, [products]);

  useEffect(() => {
    isPaginated && setIsLoading(isLoading);
  }, [isLoading]);

  useEffect(() => {
    if (isPaginated) setCompleteList([]);
  }, [search]);

  useEffect(() => {
    setIsProductsLoading && setIsProductsLoading(isLoading);
  }, [isLoading]);

  return (
    !isDataLoading &&
    !!list.length && (
      <div className={styles.collectionItem}>
        {isBaseView && !search && <small className={styles.label}>recently updated</small>}
        {!!list.length && (
          <ul className={styles.itemsList}>
            {list
              .filter((l) => !pins.find((p) => p === l.id))
              .map((item) => {
                const autosuggestId = uuidv4();
                const autosuggestId2 = uuidv4();
                return (
                  <>
                    <ReactTooltip
                      disable={item.attributes.name.length < 25}
                      id={autosuggestId}
                      className={styles.tooltip}
                      children={item.attributes.name}
                      multiline={true}
                      place="left"
                      effect="solid"
                      backgroundColor="#000233"
                      arrowColor="#000233"
                    />
                    <li
                      data-tip
                      data-for={autosuggestId}
                      className={styles.item}
                      onClick={() => {
                        if (isTemplateSettings) onChange(item, 'pinned');
                        else onChange([...new Set([...pins, item.id])], 'pinned');
                        setIsSelecting(false);
                      }}
                    >
                      <span className={styles.itemThumbnail}>
                        <img className={styles.itemImage} src={item.media && item.media[0]?.url} />
                      </span>
                      <span className={styles.itemLabel}>{item.attributes.name}</span>
                      <ProductLabel autosuggestId={autosuggestId2} item={item} />
                    </li>
                  </>
                );
              })}
          </ul>
        )}
        {showMore && (
          <center>
            <a onClick={() => setPaginate('products')} className="action">
              {meta?.results.total ? meta?.results.total - 10 : 'Show'} more products
            </a>
          </center>
        )}
      </div>
    )
  );
};

export const CategoryView = ({
  hierarchy,
  previousParent,
  setPreviousParent,
  setHierarchy,
  setPaginate,
  setResults,
  setIsLoading,
  isPaginated,
  completeList,
  setCompleteList,
  setIsCollectionLoading,
  isDataLoading,
  searchData,
  setFullHierarchyData,
  setSearchData,
}) => {
  const { search, nextPage } = searchData;
  const [collections, isLoading] = useStorefrontCollections(search, isPaginated && 10, hierarchy[0], nextPage);
  const { data = [], links, meta } = collections;
  const showMore = links?.next && !isPaginated;
  const activeSite = useActiveSite();

  const isShopifyConnected = useSelector((state) =>
    integrationSelection.selectIntegrationBySlug(state, activeSite.id, 'shopify')
  );

  const list = isPaginated ? completeList : data;
  useEffect(() => {
    isPaginated && setResults(collections);
  }, [collections]);

  useEffect(() => {
    isPaginated && setIsLoading(isLoading);
  }, [isLoading]);

  useEffect(() => {
    if (isPaginated) setCompleteList([]);
  }, [search]);

  useEffect(() => {
    setIsCollectionLoading && setIsCollectionLoading(isLoading);
  }, [isLoading]);

  return isDataLoading ? (
    <Spinner className="fixed" style={{ margin: 'auto' }} />
  ) : (
    !!list.length && (
      <div className={styles.collectionItem}>
        {!hierarchy.length && !search && !isPaginated && (
          <small className={styles.label}>{isShopifyConnected ? 'Collections' : 'Categories'}</small>
        )}

        {!!list.length && (
          <ul className={styles.itemsList}>
            {list.map((item) => (
              <li
                className={styles.item}
                onClick={() => {
                  isPaginated && (setPaginate(''), setSearchData({}));
                  setFullHierarchyData(item);
                  setPreviousParent([item.attributes.name, ...previousParent]);
                  setHierarchy([item.id, ...hierarchy]);
                }}
              >
                <EPThumbnail />
                <span className={styles.itemLabel}>{item.attributes.name}</span>
                <span className={styles.iconContainer}>
                  <IconChevronDownSmall />
                </span>
              </li>
            ))}
          </ul>
        )}
        {!!showMore && (
          <center>
            <a onClick={() => setPaginate('categories')} className="action">
              {meta?.results.total ? meta?.results.total - 5 : 'Show'} more categories
            </a>
          </center>
        )}
      </div>
    )
  );
};

export const EPThumbnail = ({ isAdminPanel }) => (
  <span className={classNames(styles.itemThumbnail, { [styles.styleForAdminPanel]: isAdminPanel })}>
    <span className={styles.thumbnailPlaceholderContainer}>
      {[...Array(4)].map((i) => (
        <span className={styles.thumbnailPlaceholder}>{i}</span>
      ))}
    </span>
  </span>
);
