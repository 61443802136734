import { YOUTUBE_URL_REGEXP, YOUTUBE_SHORT_URL_REGEXP } from '../constants';
import QueryString from 'query-string';

export function isYoutubeUrl(url: string) {
  return YOUTUBE_URL_REGEXP.test(url);
}

export function getYoutubeVideoIdFromUrl(url: string) {
  if (isYoutubeUrl(url)) {
    const shortUrlMatch = url.match(YOUTUBE_SHORT_URL_REGEXP);
    if (shortUrlMatch) {
      // Get video id from short url format
      return shortUrlMatch[1];
    } else {
      // Get video id from query string
      const querystring = QueryString.extract(url);
      const params = QueryString.parse(querystring);
      return params.v;
    }
  }

  return null;
}
