import React, { useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import { OnMount, OnUnmount } from 'hooks/mountUnmountHooks';
import Drawer from '../AddSection/Drawer';

import * as accountUserSelectors from '../../../reducers/accountUsersReducer';
import * as accountUserActions from '../../../actions/accountUserActions';
import * as addSectionSelectors from '../../../reducers/addSectionReducer';
import * as sectionSelectors from '../../../reducers/sectionsReducer';
import * as sectionActions from '../../../actions/sectionActions';
import * as api from '../../../services/spark-api';

import { ReactComponent as AddSectionSvgProperties } from '../../../assets/images/icon-properties.svg';
import { ReactComponent as SvgProperties } from '../../../assets/images/icon-properties1.svg';
import { ReactComponent as SvgIconCancel } from '../../../assets/images/icon-cancel.svg';

import styles from './AddSectionButton.module.scss';
import { USection } from 'types/USection';
import { UPage } from 'types/UPage';
import { isDeviceMode } from 'types/LegacyEditor';
type Props = {
  sections: USection[];
  pageId: number | string;
  sectionIndex: number;
  markSectionAsFresh: (sectionId: string) => void;
  sectionId?: string;
  section?: USection;
  page: UPage;
  showAddSectionTooltip: boolean;
  setShowAddSectionTooltip: (value: boolean) => void;
  activeSection?: string;
  defaultDesktopWidth: string;
  comp?: JSX.Element;
  location?: string | null;
};
const AddSectionsButton: React.FunctionComponent<Props> = (props) => {
  const { page, showAddSectionTooltip, setShowAddSectionTooltip, activeSection, defaultDesktopWidth } = props;
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [showAddSection, setShowAddSection] = React.useState(false);
  const accountUser = useSelector((state) => accountUserSelectors.selectCurrentAccountUser(state));
  const sections = useSelector((state) => sectionSelectors.selectSections(state, page.content.sections));
  const isDrawerOpen = useSelector((state) => addSectionSelectors.getIsDrawerOpen(state));

  var index = sections
    .map((section) => {
      return section.id;
    })
    .indexOf(activeSection);
  const isActiveSection =
    index - 1 === props.sectionIndex || (props.sectionIndex === index && props.sectionIndex === -1);

  const isInActiveBanner = props.section?.type === 'banner' && index !== props.sectionIndex;
  const isActive = index === props.sectionIndex && index !== -1 && props.section?.type !== 'banner';

  const dismissAddSectionTooltipDate = accountUser?.metadata?.dismissAddSectionTooltipDate;
  const createdAt = page.created_at;
  let dismissed = false;
  if (dismissAddSectionTooltipDate && createdAt) {
    const dateCreated = new Date(createdAt);
    const dateDismiss = new Date(dismissAddSectionTooltipDate);
    dismissed = dateDismiss.getTime() - dateCreated.getTime() > 0 ? true : false;
  }

  const isFirstSection = props.sectionIndex === -1;

  const shouldShowTooltip = !dismissed && !isFirstSection && !showAddSection && showAddSectionTooltip && isActive;

  const ref = React.useRef(null);

  function addSection(type: string, id: string) {
    const { pageId, sectionIndex, comp, location } = props;
    if (!comp) {
      dispatch(sectionActions.hidePlaceholderSection(props.pageId));

      dispatch(
        sectionActions.add(
          {
            item: pageId,
            [type]: id,
            position: sectionIndex + 1,
            location,
          },
          (id: string) => props.markSectionAsFresh(id)
        )
      );

      if (setShowAddSectionTooltip) setShowAddSectionTooltip(false);
    }
    setIsOpen(false);
  }

  const handleMouseMove = React.useCallback((event: MouseEvent) => {
    const { top: sectionButtonTop, height: sectionButtonHeight } = ref.current?.getBoundingClientRect() || {};
    const cursorTop = event.clientY;
    const offsetTopDiff = sectionButtonTop - cursorTop;
    const offsetBottomDiff = cursorTop - sectionButtonTop + sectionButtonHeight;

    let shouldShowAddSection;
    if (ref?.current?.id === 'isActiveSection') {
      shouldShowAddSection = offsetTopDiff <= 32 && offsetTopDiff >= 0;
    } else {
      if (ref?.current?.id !== 'isInActiveBanner') {
        shouldShowAddSection =
          (offsetTopDiff <= 32 && offsetTopDiff >= 0) ||
          (offsetBottomDiff <= 32 && offsetBottomDiff >= 0 && !isFirstSection);
      } else {
        shouldShowAddSection =
          (offsetTopDiff <= 20 && offsetTopDiff >= 0) ||
          (offsetBottomDiff <= 32 && offsetBottomDiff >= 0 && !isFirstSection);
      }
    }

    setShowAddSection(shouldShowAddSection);
  }, []);

  React.useEffect(() => {
    isFirstSection &&
      showAddSection &&
      setTimeout(() => {
        setShowAddSection(false);
        if (ref.current) ref.current.style.border = 'none';
      }, 3000);
  }, [showAddSection, ref]);

  OnMount(() => {
    const iframeEl = document.getElementById('editorIframe') as HTMLIFrameElement;
    if (isDeviceMode()) iframeEl?.contentWindow.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('mousemove', handleMouseMove);
  });

  OnUnmount(() => {
    const iframeEl = document.getElementById('editorIframe') as HTMLIFrameElement;
    if (isDeviceMode()) iframeEl?.contentWindow.removeEventListener('mousemove', handleMouseMove);
    window.removeEventListener('mousemove', handleMouseMove);
  });

  return (
    <>
      {props.comp ? (
        <div
          onClick={() => {
            setIsOpen(true);
          }}
        >
          {props.comp}
        </div>
      ) : (
        !isOpen &&
        !isDrawerOpen && (
          <div
            ref={ref}
            className={styles.container}
            id={isActiveSection ? 'isActiveSection' : isInActiveBanner ? 'isInActiveBanner' : ''}
          >
            <div className={classNames(styles.buttonLineContainer, { [styles.showSectionButton]: showAddSection })}>
              <div
                className={styles.button}
                data-test-id="add-section-button"
                onClick={() => {
                  dispatch(sectionActions.showPlaceholder(props.pageId, props.sectionIndex));
                  setIsOpen(true);
                }}
              >
                <AddSectionSvgProperties />
                <span>Add section</span>
              </div>
              <div
                className={styles.line}
                style={{ width: defaultDesktopWidth ? defaultDesktopWidth : '100%', margin: 'auto' }}
              ></div>
            </div>
            <div className={classNames(styles.tooltipWrapper, { [styles.showTooltip]: shouldShowTooltip })}>
              <div className={styles.tooltip}>
                <div className={styles.buttonContainer}>
                  <div className={styles.emptyDiv} />
                  <SvgProperties />
                  <SvgIconCancel
                    onClick={() => {
                      api.putPage({
                        ...page,
                        content: {
                          ...page.content,
                          sections: sections,
                        },
                        meta: {
                          ...page.meta,
                          showAddSectionTooltip: false,
                        },
                      });
                      if (setShowAddSectionTooltip) setShowAddSectionTooltip(false);

                      dispatch(
                        accountUserActions.update({
                          ...accountUser,
                          metadata: {
                            ...accountUser.metadata,
                            dismissAddSectionTooltipDate: new Date(),
                          },
                        })
                      );
                    }}
                    className={styles.icon}
                  />
                </div>
                <div className={styles.tooltipText}>Add more blocks with this button!</div>
              </div>
            </div>
            <div className={classNames(styles.buttonLineContainer, { [styles.showSectionButton]: isActive })}>
              <div
                className={styles.button}
                data-test-id="add-section-button"
                onClick={() => {
                  dispatch(sectionActions.showPlaceholder(props.pageId, props.sectionIndex));
                  setIsOpen(true);
                }}
              >
                <AddSectionSvgProperties />
                <span>Add section</span>
              </div>
            </div>
          </div>
        )
      )}
      <Drawer
        isOpen={isOpen}
        close={() => {
          !props.comp && dispatch(sectionActions.hidePlaceholderSection(props.pageId));
          setIsOpen(false);
        }}
        location={props.location}
        addSection={addSection}
      />
    </>
  );
};

export default AddSectionsButton;
