import React from 'react';
import ReactSelect from 'react-select';

import styles from './StyleGuideEditor.module.scss';

const theme = (theme) => ({
  ...theme,
  boxShadow: 'none',
  borderRadius: 2,
  colors: {
    ...theme.colors,
    primary: '#1495CD',
    primary25: 'rgba(220, 228, 244, 0.4)',
  },
});

export default ({ options, defaultValue, value, onChange }) => (
  <ReactSelect
    theme={theme}
    className={styles.Select}
    classNamePrefix={styles.prefix}
    isSearchable={false}
    options={options}
    defaultValue={defaultValue}
    value={value}
    onChange={(option) => onChange(option)}
  />
);
