import * as types from './actionTypes';

export function requestSubscriptionProductsIfNeeded() {
  return {
    type: types.SUBSCRIPTION_REQUEST_PRODUCTS_IF_NEEDED,
  };
}

export function requestSubscriptionProducts() {
  return {
    type: types.SUBSCRIPTION_REQUEST_PRODUCTS,
  };
}

export function receiveSubscriptionPlans(payload) {
  return {
    type: types.SUBSCRIPTION_PLANS_RECEIVE,
    payload,
  };
}

export function requestIfNeeded(siteId) {
  return {
    type: types.SUBSCRIPTION_REQUEST_IF_NEEDED,
    payload: { siteId },
  };
}

export function request(siteId) {
  return {
    type: types.SUBSCRIPTION_REQUEST,
    payload: { siteId },
  };
}

export function receive(subscription) {
  const fetchedAt = Date.now();

  return {
    type: types.SUBSCRIPTION_RECEIVE,
    payload: { subscription, fetchedAt },
  };
}

export function receiveHistory(payload) {
  return {
    type: types.SUBSCRIPTION_RECEIVE_HISTORY,
    payload,
  };
}

export function requestCreate(site, plan_id, token_id, card_id, coupon, resolve, reject) {
  return {
    type: types.SUBSCRIPTION_REQUEST_CREATE,
    payload: { site, plan_id, token_id, card_id, coupon, resolve, reject },
  };
}

export function receiveCreateSuccess() {
  return {
    type: types.SUBSCRIPTION_RECEIVE_CREATE_SUCCESS,
  };
}

export function receiveCreateError(errors) {
  return {
    type: types.SUBSCRIPTION_RECEIVE_CREATE_ERROR,
    payload: { errors },
  };
}

export function requestUpdateCard(subscription_id, card_id, token_id, resolve, reject) {
  return {
    type: types.SUBSCRIPTION_UPDATE_CREDIT_CARD,
    payload: { subscription_id, card_id, token_id },
    resolve,
    reject,
  };
}
