import { all, takeEvery, put, delay } from 'redux-saga/effects';
import * as actionTypes from '../actions/actionTypes';
import * as actions from '../actions/editorActions';

function* receivedPublishSuccess(action) {
  yield put(actions.showPublishSuccessMsg());
  yield delay(4000);
  yield put(actions.hidePublishSuccessMsg());
}

export default function* editorSaga() {
  yield all([
    takeEvery(actionTypes.PAGE_RECEIVE_PUBLISH_SUCCESS, receivedPublishSuccess),
    takeEvery(actionTypes.ARTICLE_RECEIVE_PUBLISH_SUCCESS, receivedPublishSuccess),
    takeEvery(actionTypes.BLOG_RECEIVE_PUBLISH_SUCCESS, receivedPublishSuccess),
  ]);
}
