/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useCallback, useState } from 'react';
import { ReactComponent as SvgIconAdd } from '../../../../assets/images/icon-add-section.svg';
import { ReactComponent as SvgIconShopify } from '../../../../assets/images/icon-shopify.svg';
import { ReactComponent as SvgIconEdit } from '../../../../assets/images/icon-edit-field.svg';
import { set as setOauthState } from '../../../../services/oauth-state';

import styles from '../../Integrations.module.scss';
import get from 'lodash/get';
import modalStyles from './Shopify.module.scss';
import { deleteIntegrationRequest } from '../../../../actions/integrationActions';

import CheckPermissionOnClick from '../../../SubscriptionManager/CheckPermissionOnClick';
import Modal from 'components/base/Modal';
import FormControl from 'components/base/FormHelpers/FormControl';
import useActiveSite from 'hooks/useActiveSite';
import * as integrationSelectors from 'reducers/integrationsReducer';
import { useDispatch, useSelector } from 'react-redux';
import SideDrawer from 'components/base/SideDrawer';
import ConnectModal from './ConnectModal';
import DisconnectModal from './DisconnectModal';
import Settings from './Settings';

export default (props) => {
  const { name, icon, integrationSlug, authenticator, type } = props;
  const siteId = useActiveSite((site) => site.id);
  const integration = get(
    useSelector((state) => integrationSelectors.selectSiteIntegrationsBySlug(state, siteId, 'shopify')),
    0
  );
  const [isShopifyModalOpen, setIsShopifyModalOpen] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isDisconnecting, setIsDisconnecting] = useState(false);
  const [domain, setDomain] = useState('');
  const [shopName, setShopName] = useState('');
  const dispatch = useDispatch();

  const isEmpty = !integration;

  const { config } = integration || {};
  return (
    <li className={styles.integration}>
      <img className={styles.icon} src={icon} alt="" />
      <div className={styles.title}>{name}</div>
      <div className={styles.config}>
        {isEmpty && (
          <CheckPermissionOnClick
            action={`SiteIntegration:create:::${integrationSlug}`}
            onClick={() => setIsShopifyModalOpen(true)}
            render={(handleClick, hasPermission) =>
              hasPermission ? (
                <SvgIconAdd className={styles.add} onClick={handleClick} role="button" />
              ) : (
                <button onClick={handleClick} className="button button-primary button-sm">
                  Upgrade
                </button>
              )
            }
          />
        )}
        {!isEmpty && (
          <>
            <label>
              <span className={styles.statusIndicator} />
              Connected
            </label>{' '}
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                setIsDrawerOpen(true);
              }}
            >
              <SvgIconEdit height={16} width={16} />
            </a>
          </>
        )}
      </div>
      <ConnectModal
        siteId={siteId}
        isOpen={isShopifyModalOpen}
        close={() => setIsShopifyModalOpen(false)}
        authenticator={authenticator}
        icon={icon}
        type={type}
        name={name}
        integrationSlug={integrationSlug}
      />
      {!isEmpty && (
        <>
          <DisconnectModal
            isOpen={isDisconnecting}
            close={() => setIsDisconnecting(false)}
            config={integration?.config}
            id={integration.id}
          />
          <Settings
            openDisconnectionModel={() => setIsDisconnecting(true)}
            isOpen={isDrawerOpen}
            close={() => setIsDrawerOpen(false)}
            config={config}
            id={integration.id}
            lastSyncTimeStamp={integration.metadata.last_run_at}
          />
        </>
      )}
    </li>
  );
};
