import React from 'react';

import { Group, Label } from 'components/Editor/Toolbars2/Toolbar2';
import UpDownCounter from 'components/Editor/Toolbars2/components/UpDownCounter';

import styles from './LayoutOption.module.scss';

const NumberField: React.FunctionComponent = (props: any) => {
  return (
    <Group className={props.className}>
      {props.property.label && <Label>{props.property.label}:</Label>}
      <UpDownCounter
        className={styles.TextInput}
        value={props.value === undefined ? props.property.default : props.value}
        onChange={(value: any) => props.onChange(props.property.name, value)}
        placeholder={props.property.placeholder}
        border
        min={props.property.min || 0}
        max={props.property.max || 20}
      />
    </Group>
  );
};

export default NumberField;
