import React from 'react';

interface IVimeoEmbedProps {
  entity: {
    rawSrc: String;
    src: String;
    type?: String;
  };
}

export const VimeoEmbed = (props: IVimeoEmbedProps): React.ReactNode => {
  const { entity } = props;
  const match: Array<any> = entity.src.match(/^vimeo:(.+)/);
  if (!match) return null;
  let videoId: String;
  if (match[1].includes('/')) {
    let match_split = match[1].split('/');
    videoId = `${match_split[0]}?h=${match_split[1]}`;
  } else {
    videoId = match[1];
  }

  return (
    <iframe
      title="vimeo"
      src={`https://player.vimeo.com/video/${videoId}`}
      data-webkitallowfullscreen
      data-mozallowfullscreen
      data-allowfullscreen
    />
  );
};
