import React, { useState, useRef, useMemo } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import DocumentTitle from 'react-document-title';
import { createPortal } from 'react-dom';
import { usePopper } from 'react-popper';

import Search from 'components/Search';
import { useProductTemplates } from './hooks';
import Spinner from 'components/base/Spinner';
import ListWithServerSorting from '../react-table/ListWithServerSorting';

import icon from '../../assets/images/icon-zero-state-blog.svg';
import { ReactComponent as SvgIconAdd } from '../../assets/images/icon-plus.svg';
import { ReactComponent as PrevSvg } from '../../assets/images/icon-prev-page.svg';
import { ReactComponent as NextSvg } from '../../assets/images/icon-next-page.svg';
import { ReactComponent as DynamicPageSvg } from '../../assets/images/icon-dynamic-page.svg';
import { ReactComponent as SvgIconOptions } from '../../assets/images/icon-options.svg';

import styles from './Storefront.module.scss';
import withQueryParams from 'containers/withQueryParams';
import ZeroState, { Header, Image, Muted } from 'components/AdminPanel/ZeroState';
import useOnClickOutside from 'hooks/useClickOutside';
import { useEscapeKey } from 'hooks/useKeyBinding';
import useUrlSite from 'hooks/useUrlSite';
import { Link, useNavigate } from 'react-router-dom';
import relativeDate from 'lib/relative-date';
import PageSettings from '../Pages/PageSettings';
import * as pageSelectors from '../../reducers/pagesReducer';
import { createActiveByPageIdSelector } from '../../reducers/contentTestsReducer';
import * as uiPageSettingsActions from '../../actions/uiPageSettingsActions';
import { useSelector } from 'react-redux';
import useActiveSite from 'hooks/useActiveSite';
import * as integrationSelector from 'reducers/integrationsReducer';
import * as siteSelector from 'reducers/sitesReducer';
import { UPage } from 'types/UPage';

const NewTemplateButton = (props: any) => {
  const ref = useRef();
  const [opened, setOpened] = useState(false);
  const [reference, setReference] = useState(null);
  useOnClickOutside(ref, () => setOpened(false));
  useEscapeKey(() => setOpened(false));
  const [popper, setPopper] = useState(null);
  const { styles: popperStyles } = usePopper(reference, popper, {
    placement: 'bottom-start',
    modifiers: [{ name: 'offset', options: { offset: [0, 0] } }],
  });
  return (
    <a onClick={() => setOpened(true)} className={styles.addNewButton} data-test-id="choose-template-type">
      <SvgIconAdd height={15} width={15} />
      New template
      {opened && (
        <div>
          <div ref={setReference} />
          {createPortal(
            <div ref={setPopper} style={{ ...popperStyles.popper, zIndex: 3 }}>
              <div ref={ref} className={styles.dropDownMenu}>
                <Link to={`/${props.urlSite}/product-pages/new`} data-test-id="new-product">
                  Product Page...
                </Link>
                <Link to={`/${props.urlSite}/collection-pages/new`} data-test-id="new-collection">
                  {props.hasEP ? 'Category' : 'Collection'} Page...
                </Link>
              </div>
            </div>,
            document.body
          )}
        </div>
      )}
    </a>
  );
};

const Templates = (props: any) => {
  const urlSite = useUrlSite();
  const navigate = useNavigate();
  const siteId = useActiveSite((site) => site.id);
  const [query, setQuery] = useState(undefined);
  const {
    queryParams: { page = 1, search, order_by },
    updateQueryParams,
    openPageSettings,
    pages,
    pagesCount,
  } = props;
  // @ts-ignore
  const integrationsBySlug = useSelector((state) => integrationSelector.selectSiteIntegrationsBySlug(state, siteId));
  const hasEP = useMemo(() => integrationsBySlug.elastic_path?.length, [integrationsBySlug]);

  // const [data, count, isLoading] = useProductTemplates(search, page, order_by);
  const [data, count, isLoading] = useProductTemplates(search, page);
  const columns = useMemo(() => {
    return [
      {
        Header: 'Name',
        id: 'name',
        accessor: 'name',
        Cell: ({ value }: { value: string }) => {
          return (
            <div className={styles.title}>
              <DynamicPageSvg />
              <span>{value || 'New page'}</span>
            </div>
          );
        },
        sortable: true,
        minWidth: 180,
      },
      {
        Header: 'Type',
        id: 'item_type',
        accessor: 'item_type',
        Cell: ({ value, original: item }: { value: string; original: any }) => {
          const defaultPDP = useSelector(siteSelector.selectDefaultPDP);
          const defaultPLP = useSelector(siteSelector.selectDefaultPLP);
          return (
            <div>
              {/* {media && <img src={media.url} />} */}
              <span style={{ textTransform: 'capitalize' }}>
                {value}{' '}
                {[defaultPDP, defaultPLP].includes(item.id) && <span className={styles.defaultLabel}>default</span>}
              </span>
            </div>
          );
        },
        sortable: true,
        minWidth: 180,
      },
      {
        Header: 'Updated At',
        accessor: 'modified_at',
        Cell: ({ value }: { value: string }) => (
          <div className={styles.flexContainer}>
            <small style={{ fontSize: '1em', color: '#647fb4' }}>Updated&nbsp;</small>
            {relativeDate(value)}
          </div>
        ),
        sortable: true,
      },
      {
        Header: '',
        accessor: 'id',
        Cell: ({ value }: { value: string }) => {
          return (
            <div className={styles.icons}>
              <button
                data-tip="Options"
                className={styles.options}
                onClick={(e) => {
                  e.stopPropagation();
                  openPageSettings(value);
                }}
              >
                <SvgIconOptions />
              </button>
            </div>
          );
        },
        width: 40,
      },
    ];
  }, [hasEP]);
  return (
    <>
      <DocumentTitle title="Storefront Templates" />
      <section>
        <header className={styles.header}>
          <div>
            <h1>Storefront Templates</h1>
            <small>Customize your product and {hasEP ? 'category' : 'collection'} pages</small>
          </div>
          <div className={styles.searchContainer}>
            {/* @ts-ignore */}
            <Search
              value={query === undefined ? search : query}
              onChange={(query: string) => setQuery(query)}
              placeholder="Search"
              debounceValue={(search: string) => updateQueryParams({ search, page: 1 })}
              containerStyle={{
                marginRight: '1rem',
              }}
            />
            <NewTemplateButton hasEP={hasEP} urlSite={urlSite} />
          </div>
        </header>
      </section>
      <section>
        {isLoading && <Spinner />}
        <div className={styles.productsList}>
          {Boolean(!isLoading && pages && pages.length) && (
            <ListWithServerSorting
              className="-highlight"
              data={pages}
              columns={columns}
              count={pagesCount}
              defaultPageSize={50}
              getTdProps={(_state: string, row: any) => ({
                onClick: () => {
                  navigate(`/${urlSite}/editor/${row.original.id}`);
                },
                style: { cursor: 'pointer' },
              })}
              loading={isLoading}
              showViewAll={false}
              breakPageslabel={<span>...</span>}
              nextPageLabel={<NextSvg />}
              onPageChange={(page: UPage) => updateQueryParams({ page })}
              pagesCount={pagesCount / 10}
              orderBy={order_by}
              onSortChange={(newSort: any) => {
                updateQueryParams({
                  order_by: `${newSort.desc ? '-' : ''}${newSort.id}`,
                });
              }}
              paginate
              showHeader
              activePage={page}
              previousPageLabel={<PrevSvg />}
            />
          )}
          {Boolean(!isLoading && pages && !pagesCount) && (
            <ZeroState>
              <Image src={icon} />
              <Header>No Template available</Header>
              <Muted>You will have your templates listed here when you create them</Muted>
            </ZeroState>
          )}
        </div>
      </section>
      {/* @ts-ignore */}
      <PageSettings updateQueryParams={updateQueryParams} pagesCount={pagesCount} />
    </>
  );
};

function mapStateToProps(state: any) {
  const isFetching = pageSelectors.selectIsFetching(state);
  const pages = pageSelectors.selectAll(state);
  const pagesCount = pageSelectors.selectPagesCount(state);
  const selectActiveByPageId = createActiveByPageIdSelector(state);

  return {
    isLoading: isFetching,
    pages,
    pagesCount,
    selectActiveByPageId,
  };
}

const mapDispatchToProps = {
  openPageSettings: uiPageSettingsActions.open,
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  withQueryParams(Templates, {
    itemType: 'product,collection',
    panel: 'items',
  })
);
