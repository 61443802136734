import React from 'react';
import classnames from 'classnames';
import { useSelector } from 'react-redux';

import { selectOne } from 'reducers/mediaReducer';

import styles from '../Sheet/Sheet.module.scss';

export default function (props) {
  const media = useSelector((state) => selectOne(state, props.value.split(':')[1]));

  return (
    <>
      {props.value && media ? (
        <div className={styles.mediaFormatter} data-test-id="ddc-cell-media">
          {media.thumbnail ? <img src={media.thumbnail} /> : <img src={media.file} />}
          <small>{media.metadata.file_name}</small>
        </div>
      ) : (
        <span
          className={classnames(styles.mediaPicker, {
            [styles.disabled]: !props.category,
          })}
        >
          Add media
        </span>
      )}
    </>
  );
}
