/* eslint-disable jsx-a11y/anchor-is-valid, jsx-a11y/alt-text */

import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import withSite from 'containers/withSite';
import { Link, NavLink } from 'react-router-dom';
import * as uiSelectors from '../../../reducers/uiReducer';
import * as siteSelectors from '../../../reducers/sitesReducer';
import SiteLogo from '../SiteLogo';
import { ReactComponent as SvgTextLinkArrow } from '../../../assets/images/icon-text-link-arrow.svg';
import ShopifyCartIcon from '../../AdminPanel/Elements/HeaderFooterEditor/HeaderEditor/shopifyCartIcon';
import AlgoliaSearchField from 'components/AdminPanel/Elements/HeaderFooterEditor/HeaderEditor/AlgoliaSearchField';
import styles from './HeaderFooterOverlay.module.scss';

import classnames from 'classnames';

import { ReactComponent as SvgCheveronLeft } from '../../../assets/images/icon-cheveron-left.svg';

function mapStateToProps(state, ownProps) {
  return {
    urlSite: siteSelectors.getUrlSiteFromSite(ownProps.site),
    homePageUrl: uiSelectors.selectHomePageUrl(state),
    parseSmartUrl: uiSelectors.selectParseSmartUrl(state),
  };
}

function getActionStyleClassName(actionStyle) {
  switch (actionStyle) {
    case 'button-primary':
      return 'button button-primary';
    case 'button-secondary':
      return 'button button-secondary';
    case 'text-action':
    default:
      return 'text-action';
  }
}

function FullHeader(props) {
  const { homePageUrl, site, urlSite, parseSmartUrl } = props;

  const header_menu = site.menus.header;
  const siteStyles = site.styles;

  const headerMenuLayout = siteStyles.headerMenuLayout || 'right';

  const items = header_menu.items || [];
  const secondary_items = header_menu.secondary_items || [];
  const action_item = header_menu.action_item;
  const header_padding = siteStyles.header_padding;

  const renderMenuItems = (items) =>
    items.map((item, index) => (
      <li key={index}>
        <NavLink to={parseSmartUrl(item.url) || '#'} className="link" disabled={parseSmartUrl(item.url) == null}>
          {item.text}
          {item.items && item.items.length > 0 ? <SvgCheveronLeft className="dropdown-icon" /> : null}
        </NavLink>
      </li>
    ));

  const renderedMenuItems = renderMenuItems(items);
  const renderedSecondaryMenuItems = renderMenuItems(secondary_items);

  const renderedActionItem = action_item ? (
    <li>
      <NavLink
        className={getActionStyleClassName(siteStyles.list.header_menu.action_style)}
        to={parseSmartUrl(action_item.url) || '#'}
        disabled={parseSmartUrl(action_item.url) == null}
      >
        {action_item.text}
      </NavLink>
    </li>
  ) : null;

  return (
    <div className="header-container" style={{ zIndex: 0 }}>
      <header
        className={classnames(
          'primary-header',
          'is-editor',
          siteStyles.headerBrightnessTheme,
          styles.withOverlay,
          `header-menu-layout-${headerMenuLayout}`,
          `pt-${header_padding}`,
          `pb-${header_padding}`
        )}
      >
        <div className="container">
          <div className="logo">
            <Link
              to={homePageUrl || '#'}
              disabled={homePageUrl == null}
              data-tip="You can update your logo in Header & Footer"
            >
              <SiteLogo placement="header" site={site} />
            </Link>
          </div>
          <button id="mobile-nav-toggle" className="hamburger hamburger--spin" type="button">
            <span className="hamburger-box">
              <span className="hamburger-inner"></span>
            </span>
          </button>
          <nav id="primary-nav">
            {headerMenuLayout === 'left' ? (
              <>
                <ul>{renderedMenuItems}</ul>
                <ul>
                  {renderedSecondaryMenuItems}
                  {renderedActionItem}
                  <ShopifyCartIcon metaData={site.metadata} />
                </ul>
              </>
            ) : (
              <ul>
                {renderedMenuItems}
                {renderedActionItem}
              </ul>
            )}
          </nav>
          <div className="header-icons">
            <AlgoliaSearchField metaData={site.metadata} />
            <ShopifyCartIcon metaData={site.metadata} />
          </div>
        </div>
        <div className={classnames('admin', styles.overlay)}>
          <Link to={`/${urlSite}/elements`} data-test-id={'header-footer-styles-navigator'}>
            You can change your header in Header & Footer
            <SvgTextLinkArrow />
          </Link>
        </div>
      </header>
    </div>
  );
}

export default compose(withSite, connect(mapStateToProps))(FullHeader);
