import * as types from './actionTypes';

export const create = (payload, resolve, reject) => ({
  type: types.TAG_CREATE_REQUEST,
  payload,
  resolve,
  reject,
});

export const remove = (id, resolve, reject) => ({
  type: types.TAG_REMOVE_REQUEST,
  payload: id,
  resolve,
  reject,
});

export const fetchAll = (siteId) => ({
  type: types.TAG_FETCH_ALL,
  payload: { siteId },
});

export const fetchAllSuccess = (payload) => ({
  type: types.TAG_FETCH_ALL_SUCCESS,
  payload,
});
