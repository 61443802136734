import React from 'react';
import { connect } from 'react-redux';
import { requestProductsIfNeeded } from '../actions/paymentActions';
import { selectAllProducts } from '../reducers/paymentReducer';

function mapStateToProps(state) {
  return {
    products: selectAllProducts(state),
  };
}

const mapDispatchToProps = {
  requestProducts: requestProductsIfNeeded,
};

export default (WrappedComponent) => {
  class Container extends React.PureComponent {
    componentDidMount() {
      this.props.requestProducts();
    }

    render() {
      const { requestIntegrations, ...props } = this.props;
      return <WrappedComponent {...props} />;
    }
  }

  return connect(mapStateToProps, mapDispatchToProps)(Container);
};
