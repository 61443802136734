import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import isEmail from '../../../lib/is-email';

import SideDrawer from '../../base/SideDrawer';
import FormControl from '../../base/FormHelpers/FormControl';
import ValidationMessage from '../../base/FormHelpers/ValidationMessage';
import { selectAllSorted } from '../../../reducers/accountUsersReducer';
import { createOrUpdateContact } from '../../../actions/contactsActions';
import useActiveSite from '../../../hooks/useActiveSite';

import styles from './AddContact.module.scss';

export default ({ isOpen, integrationsLists, setOpen, updateRefreshFlag, refreshFlag, owner }) => {
  const dispatch = useDispatch();
  const accountUsers = useSelector((state) => selectAllSorted(state));
  const [contactData, setContactData] = useState({ owner });
  const siteId = useActiveSite((site) => site.id);

  return (
    <SideDrawer isOpen={isOpen} title="Add a new customer" close={() => setOpen(false)}>
      <fieldset className={styles.fieldsetOptionalStyles}>
        <label htmlFor="first_name_input">First name</label>
        <FormControl
          id="first_name_input"
          name="first_name"
          onChange={(e) => setContactData({ ...contactData, [e.target.name]: e.target.value })}
          value={contactData.first_name || ''}
          autoComplete="off"
          type="text"
          placeholder="First name"
          disabled={contactData.saving}
        />
      </fieldset>
      <fieldset className={styles.fieldsetOptionalStyles}>
        <label htmlFor="last_name_input">Last name</label>
        <FormControl
          id="last_name_input"
          name="last_name"
          className="ph-redact-input"
          onChange={(e) => setContactData({ ...contactData, [e.target.name]: e.target.value })}
          value={contactData.last_name || ''}
          autoComplete="off"
          type="text"
          placeholder="Last name"
          disabled={contactData.saving}
        />
      </fieldset>
      <fieldset className={styles.fieldsetOptionalStyles}>
        <label htmlFor="email_input">Email address</label>
        <FormControl
          id="email_input"
          name="email"
          className="ph-redact-input"
          onChange={(e) =>
            setContactData({
              ...contactData,
              [e.target.name]: e.target.value,
              error: false,
            })
          }
          value={contactData.email || ''}
          autoComplete="off"
          type="email"
          placeholder="Email address"
          disabled={contactData.saving}
        />
        {contactData.error && (
          <ValidationMessage message={contactData.email ? 'Enter valid email' : 'This field is required'} />
        )}
      </fieldset>
      <fieldset className={styles.fieldsetOptionalStyles}>
        <label htmlFor="company_input">Company</label>
        <FormControl
          id="company_input"
          name="organization"
          onChange={(e) => setContactData({ ...contactData, [e.target.name]: e.target.value })}
          value={contactData.organization || ''}
          autoComplete="off"
          type="text"
          placeholder="Company"
          disabled={contactData.saving}
        />
      </fieldset>
      <fieldset className={styles.fieldsetOptionalStyles}>
        <label htmlFor="phone_input">Phone</label>
        <FormControl
          id="phone_input"
          name="phone"
          className="ph-redact-input"
          onChange={(e) => setContactData({ ...contactData, [e.target.name]: e.target.value })}
          value={contactData.phone || ''}
          autoComplete="off"
          type="text"
          placeholder="Phone"
          disabled={contactData.saving}
        />
      </fieldset>
      <fieldset className={styles.fieldsetOptionalStyles}>
        <label htmlFor="owner_input">Owner</label>
        <FormControl
          id="owner_input"
          name="owner"
          className="ph-redact-input"
          onChange={(e) => setContactData({ ...contactData, [e.target.name]: e.target.value })}
          value={contactData.owner || ''}
          autoComplete="off"
          tag="select"
          placeholder="Owner"
        >
          <option value="">Select owner</option>
          {accountUsers.map((user) => (
            <option key={user.id} value={user.id}>
              {user.name || user.email}
            </option>
          ))}
        </FormControl>
      </fieldset>
      <fieldset className={styles.fieldsetOptionalStyles}>
        <label htmlFor="website_input">Website</label>
        <FormControl
          id="website_input"
          name="website"
          className="ph-redact-input"
          onChange={(e) => setContactData({ ...contactData, [e.target.name]: e.target.value })}
          value={contactData.website || ''}
          autoComplete="off"
          type="text"
          placeholder="Website"
          disabled={contactData.saving}
        />
      </fieldset>
      <fieldset className={styles.fieldsetOptionalStyles}>
        <input
          readOnly
          value={contactData.saving ? `Adding Customer...` : `Add customer`}
          className="button button-primary button-full"
          onClick={
            contactData.saving
              ? () => {}
              : () => {
                  if (contactData.email && isEmail(contactData.email)) {
                    setContactData({ ...contactData, saving: true });

                    const payload = { ...contactData };
                    if (!payload.site) payload.site = siteId;

                    const promise = new Promise((resolve, reject) => {
                      dispatch(createOrUpdateContact(payload, resolve, reject));
                    });
                    promise
                      .then(() => {
                        setContactData({});
                        setOpen(false);
                      })
                      .catch((err) => console.error(err))
                      .finally(() => {
                        updateRefreshFlag(!refreshFlag);
                        setContactData({ saving: false });
                      });
                  } else setContactData({ ...contactData, error: true });
                }
          }
        />
      </fieldset>
      {Object.entries(integrationsLists).map(([integration, lists], i) => {
        return lists.length ? (
          <fieldset key={i}>
            <label>Add to {integration} list</label>
            <FormControl
              tag="select"
              name={integration}
              onChange={(e) => {
                setContactData({
                  ...contactData,
                  [e.target.name]: e.target.value,
                });
              }}
              value={contactData[integration] || ''}
              autoComplete="off"
              disabled={contactData.saving}
            >
              <option value="">Select</option>
              {lists.map((list) => (
                <option key={list.listId || list.id || list.list_id} value={list.listId || list.id || list.list_id}>
                  {list.name || list.list_name}
                </option>
              ))}
            </FormControl>
            <small>Add anyone who submits this form to a list in your {integration} account.</small>
          </fieldset>
        ) : null;
      })}
    </SideDrawer>
  );
};
