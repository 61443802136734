import React from 'react';
import { ProductDetailArgs } from '../../../tag-types';

const ProductPrice: React.FunctionComponent<ProductDetailArgs> = ({ product, enableListPrice }) => {
  const listPrice =
    product?.meta?.original_display_price?.without_tax?.formatted ||
    product?.meta?.original_display_price?.with_tax?.formatted;
  const priceClasses = enableListPrice && listPrice ? 'label product-sale-price' : 'label';
  return (
    <div className="product-price-container">
      <p className={priceClasses} data-product={product?.id} data-product-price>
        {product?.meta?.display_price?.without_tax?.formatted ||
          product?.meta?.display_price?.with_tax?.formatted ||
          '$0.00'}
      </p>
      {enableListPrice && listPrice && (
        <p className="label product-list-price" data-product={product?.id} data-product-list-price>
          <s>{listPrice}</s>
        </p>
      )}
    </div>
  );
};

export default ProductPrice;
