import * as types from './actionTypes';

export function createTable(payload, resolve, reject) {
  return {
    type: types.DATA_TABLE_CREATE_TABLE,
    payload,
    resolve,
    reject,
  };
}

export function onTableCreate(payload) {
  return {
    type: types.DATA_TABLE_CREATE_TABLE_SUCCESS,
    payload,
  };
}

export function requestIfNeeded() {
  return {
    type: types.DATA_TABLE_FETCH_ALL_IF_NEEDED,
  };
}

export function requestList() {
  return {
    type: types.DATA_TABLE_FETCH_ALL,
  };
}

export function requestListSuccess(payload) {
  return {
    type: types.DATA_TABLE_FETCH_ALL_SUCCESS,
    payload,
  };
}

export function saveSelectedTable(payload) {
  return {
    type: types.DATA_TABLE_ON_TABLE_SELECTION,
    payload,
  };
}

export function clearSelectedTable() {
  return {
    type: types.DATA_TABLE_CLEAR_TABLE_SELECTION,
  };
}

export function requestTableData(payload) {
  return {
    type: types.DATA_TABLE_REQUEST_TABLE_DATA,
    payload,
  };
}

export function receiveTableColumns(payload) {
  return {
    type: types.DATA_TABLE_RECEIVE_TABLE_COLUMNS,
    payload,
  };
}

export function receiveTableRows(payload) {
  return {
    type: types.DATA_TABLE_RECEIVE_TABLE_ROWS,
    payload,
  };
}

export function saveRelatedRows(payload) {
  return {
    type: types.DATA_TABLE_SAVE_RELATED_ROWS,
    payload,
  };
}

export function saveTableData() {
  return {
    type: types.DATA_TABLE_RECEIVE_TABLE_DATA,
  };
}

export function updateCell(id, content) {
  return {
    type: types.DATA_TABLE_UPDATE_CELL,
    payload: { id, content },
  };
}

export function onCellUpdate(payload) {
  return {
    type: types.DATA_TABLE_ON_CELL_UPDATE,
    payload,
  };
}

export function reorderColumns(id, newIdx, oldIdx) {
  return {
    type: types.DATA_TABLE_REORDER_COLUMNS,
    payload: { id, newIdx, oldIdx },
  };
}

export function reorderColumnsSuccess(newIdx, oldIdx, id) {
  return {
    type: types.DATA_TABLE_REORDER_COLUMNS_SUCCESS,
    payload: { newIdx, oldIdx, id },
  };
}

export function reorderRows(id, newIdx, oldIdx) {
  return {
    type: types.DATA_TABLE_REORDER_ROWS,
    payload: { id, newIdx, oldIdx },
  };
}

export function reorderRowsSuccess(newIdx, oldIdx, id) {
  return {
    type: types.DATA_TABLE_REORDER_ROWS_SUCCESS,
    payload: { newIdx, oldIdx, id },
  };
}

export function updateRowSlug(id, slug) {
  return {
    type: types.DATA_TABLE_UPDATE_ROW_SLUG,
    payload: { id, slug },
  };
}

export function onRowSlugUpdate(payload) {
  return {
    type: types.DATA_TABLE_UPDATE_ROW_SLUG_SUCCESS,
    payload,
  };
}

export function updateColumnSlug(id, slug) {
  return {
    type: types.DATA_TABLE_UPDATE_COLUMN_SLUG,
    payload: { id, slug },
  };
}

export function onColumnSlugUpdate(payload) {
  return {
    type: types.DATA_TABLE_UPDATE_COLUMN_SLUG_SUCCESS,
    payload,
  };
}

export function createRow(payload, resolve, reject) {
  return {
    type: types.DATA_TABLE_CREATE_ROW,
    payload: { ...payload },
    resolve,
    reject,
  };
}

export function deleteRow(payload) {
  return {
    type: types.DATA_TABLE_DELETE_ROW,
    payload,
  };
}

export function onRowDelete(payload) {
  return {
    type: types.DATA_TABLE_DELETE_ROW_SUCCESS,
    payload,
  };
}

export function onRowCreation(payload) {
  return {
    type: types.DATA_TABLE_CREATE_ROW_SUCCESS,
    payload: { ...payload },
  };
}

export function createColumn(payload, resolve, reject) {
  return {
    type: types.DATA_TABLE_CREATE_COLUMN,
    payload: { ...payload },
    resolve,
    reject,
  };
}

export function updateColumn(payload, resolve, reject) {
  return {
    type: types.DATA_TABLE_UPDATE_COLUMN,
    payload: { ...payload },
    resolve,
    reject,
  };
}

export function deleteColumn(payload, resolve, reject) {
  return {
    type: types.DATA_TABLE_DELETE_COLUMN,
    payload,
    resolve,
    reject,
  };
}

export function onColumnDelete(payload) {
  return {
    type: types.DATA_TABLE_DELETE_COLUMN_SUCCESS,
    payload,
  };
}

export function onColumnCreation(payload) {
  return {
    type: types.DATA_TABLE_CREATE_COLUMN_SUCCESS,
    payload: { ...payload },
  };
}

export function addRelatedRow(payload) {
  return {
    type: types.DATA_TABLE_ADD_RELATED_ROW,
    payload,
  };
}
