import React, { Dispatch, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectHasPermission } from '../../../../../../../reducers/policyReducer';
import { open as openContentTestSettings } from '../../../../../../../actions/uiNewContentTestSettingsActions';

import { Tray, Group, Button } from '../../../../../../Editor/Toolbars2/Toolbar2';
import ButtonWithUnderline from '../../../../../../Editor/Toolbars2/components/ButtonWithUnderline';

import { IconTest, IconTestOptions, IconRemoveSection } from '../../../../../../Editor/Toolbars2/icons';

import { open as openSubscriptionManager } from '../../../../../../../actions/uiSubscriptionManagerActions';
import { SectionHandlersInterface } from 'components/unstack-components/types';

// HACK: This is an outlandishly hacky solution to keep the section toolbar
// open after the user clicks to initialize an a/b test or discard it.
// The problem was that when either of those events take place, the section is
// replaced entirely, causing the section component and its children to be
// replaced with new components – and thus lose their state. This hacky
// solution leaves a breadcrumb in the form of a `window` property that
// the SectionToolbarChoreographer and this component reference when they
// mount. This of course really belongs in a higher-level state, like in the
// Redux store, but this was much faster...
// function forceSectionToolbarChoreographerOpenOnStart() {
//   window.SectionToolbarChoreographerStartOpen = true;

//   setTimeout(() => {
//     window.SectionToolbarChoreographerStartOpen = false;
//   }, 100);
// }

// function forceABTestButtonTrayOpenOnStart() {
//   window.ABTestButtonTrayStartOpen = true;

//   setTimeout(() => {
//     window.ABTestButtonTrayStartOpen = false;
//   }, 100);
// }

// A/B test button component

type Props = {
  sectionHandlers: SectionHandlersInterface;
};

export default function ABTest(props: Props) {
  const {
    sectionHandlers: {
      containerId,
      containerIndex,
      containerLength,
      initializeABTest,
      discardABTest,
      onContainerIndexChange,
      startABTestForBlog,
      type,
    },
  } = props;

  const testIsInitialized = !!containerLength;

  const dispatch: Dispatch<any> = useDispatch();

  const handleInitialize = useCallback(
    (e: any) => {
      initializeABTest();
      // forceSectionToolbarChoreographerOpenOnStart();
      // forceABTestButtonTrayOpenOnStart();
    },
    [
      initializeABTest,
      // forceSectionToolbarChoreographerOpenOnStart,
      // forceABTestButtonTrayOpenOnStart,
    ]
  );

  const hasPermission = useSelector((state) => selectHasPermission(state));

  const handleTerminate = useCallback(() => {
    discardABTest();
    // forceSectionToolbarChoreographerOpenOnStart();
  }, [discardABTest]);

  const handleOpenNewContentSettings = useCallback(() => {
    startABTestForBlog ? startABTestForBlog() : dispatch(openContentTestSettings(containerId));
  }, [dispatch, containerId]);

  // Upsell message if no permissions
  if (!hasPermission('Experiment'))
    return (
      <Button
        data-tip="A/B Testing"
        data-test-id="AB-test-icon"
        onClick={() => dispatch(openSubscriptionManager({ requestedAction: 'Experiment' }))}
        style={{ width: '40px', padding: '12px' }}
      >
        <IconTest />
      </Button>
    );

  const isABTestTrayOpen = type === 'experiment' && containerLength;

  if (isABTestTrayOpen) {
    return (
      <Tray
        startOpen
        renderIcon={() => <IconTest active={true} data-test-id="ABTest" />}
        trayLabel="A/B TEST"
        renderTray={() => (
          <>
            <Group>
              {[...Array(containerLength)].map((variant, i) => (
                <ButtonWithUnderline key={i} active={i === containerIndex} onClick={() => onContainerIndexChange(i)}>
                  {i === 0 ? 'Control' : 'Variant'}
                </ButtonWithUnderline>
              ))}
            </Group>
            <Group style={{ marginLeft: 'auto' }}>
              {hasPermission('Experiment:start') && (
                <Button onClick={handleOpenNewContentSettings} data-test-id="AB-settings-drawer">
                  <IconTestOptions />
                  &nbsp; Options/Start
                </Button>
              )}
              {hasPermission('Experiment:stop') && (
                <Button onClick={handleTerminate} data-test-id="AB-test-discard">
                  <IconRemoveSection />
                  &nbsp; Discard
                </Button>
              )}
            </Group>
          </>
        )}
      />
    );
  }

  if (hasPermission('Experiment:create')) {
    return (
      <Button
        disabled={type === 'experiment' || testIsInitialized}
        data-tip="A/B Testing"
        data-test-id="AB-test-icon"
        onClick={handleInitialize}
        style={{ width: '40px', padding: '12px' }}
      >
        <IconTest />
      </Button>
    );
  }
  return null;
}
