import { useEffect, useRef, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import DocumentTitle from 'react-document-title';
import classNames from 'classnames';

import Spinner from './base/Spinner';

import { ReactComponent as CompleteCheckIcon } from 'assets/images/complete-check-icon-klevu-onboarding.svg';
import { ReactComponent as HelpIcon } from 'assets/images/klevu-onboarding-help.svg';
import { ReactComponent as KlevuIcon } from 'assets/images/klevu-onboarding-logo.svg';
import { ReactComponent as EPIcon } from 'assets/images/klevu-onboarding-ep-logo.svg';
import { ReactComponent as PlusIcon } from 'assets/images/klevu-onboarding-plus.svg';
import { ReactComponent as ArrowIcon } from 'assets/images/klevu-arrow.svg';
import BlankPage from 'assets/images/klevu-onboarding-blank-page.svg';

import styles from './KlevuOnboarding.module.scss';

function KlevuOnboarding() {
  const [templates, setTemplates] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedCategory, setSelectedCategory] = useState('Products');
  const [nextPageUrl, setNextPageUrl] = useState(null);
  const [loadingMore, setLoadingMore] = useState(false);
  const [isScrolledToLeft, setIsScrolledToLeft] = useState(true);
  const [isScrolledToRight, setIsScrolledToRight] = useState(false);
  const location = useLocation();
  const { authorize_url } = location.state || {}; // Accessing 'cow' from the state

  useEffect(() => {
    setIsLoading(true);
    setIsScrolledToLeft(true);
    setIsScrolledToRight(false);
    fetchTemplates(
      selectedCategory
        ? `https://api.unstack.com/api/v1/template/?tag=${selectedCategory}`
        : 'https://api.unstack.com/api/v1/template/',
      true
    );
  }, [selectedCategory]);

  const fetchTemplates = (url: string, isFirst: boolean) => {
    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        isFirst ? setTemplates(data.results) : setTemplates((prevTemplates) => [...prevTemplates, ...data.results]);

        setNextPageUrl(data.next);
        setIsLoading(false);
        setLoadingMore(false);
        setIsScrolledToRight(false);
      })
      .catch((error) => {
        console.error('Error fetching templates:', error);
        setIsLoading(false);
      });
  };

  const fetchMoreTemplates = () => {
    if (!loadingMore && nextPageUrl) {
      setLoadingMore(true);
      fetchTemplates(nextPageUrl, false);
    }
  };

  const handleClick = async () => {
    window.open(authorize_url, '_blank');
  };

  const templatesRef = useRef(null);
  const scrollLeft = () => {
    templatesRef.current.scrollBy({
      left: -600,
      behavior: 'smooth',
    });
  };
  const scrollRight = () => {
    templatesRef.current.scrollBy({
      left: 600,
      behavior: 'smooth',
    });
  };
  const handleScroll = (e: any) => {
    const element = e.target;
    if (element.scrollWidth - element.scrollLeft === element.clientWidth) {
      fetchMoreTemplates();
      setIsScrolledToRight(true);
    } else if (element.scrollLeft === 0) {
      setIsScrolledToLeft(true);
    } else {
      setIsScrolledToRight(false);
      setIsScrolledToLeft(false);
    }
  };

  return (
    <div className="admin">
      <DocumentTitle title="Klevu Onboarding" />
      <main className={styles.onboarding} data-test-id="klevu-onboarding-container">
        <div className={styles.headerContainer}>
          <div className={styles.iconsContainer}>
            <KlevuIcon className={styles.icon} />
            <PlusIcon className={styles.plus} />
            <EPIcon className={styles.icon} />
          </div>
          <h1>Getting started with Klevu + Elastic Path Studio</h1>
        </div>

        <div className={styles.panelContainer}>
          <div className={styles.panel}>
            <div className={styles.titleContainer}>
              <CompleteCheckIcon data-test-id="marked-as-completed" className={styles.completeCheckIcon} />
              <h2 className={styles.title}>Step 1: Install the Elastic Path Studio Landing Pages app</h2>
            </div>
            <div className={styles.body}>
              <p className={styles.paragraph}>
                To build landing pages you’ll need to install the Shopify app for Elastic Path Studio. This is a very
                simple process, but you will need to be a <span>Shopify Admin</span> for your store.
              </p>
              <button onClick={handleClick}>Install App</button>
            </div>
          </div>

          <div className={styles.panel}>
            <div className={styles.titleContainer}>
              <CompleteCheckIcon data-test-id="marked-as-completed" className={styles.completeCheckIcon} />
              <h2 className={styles.title}>Step 2: Connect Studio to Klevu</h2>
            </div>
            <div className={styles.body}>
              <div className={styles.textContainer}>
                Follow these steps to connect the integration:
                <ul className={styles.list}>
                  <li className={styles.step}>
                    <div className={styles.stepNumber}>1</div>
                    <p className={styles.stepText}>
                      Within Studio click on <span>Settings</span>, then <span>Integrations</span>
                    </p>
                  </li>
                  <li className={styles.step}>
                    <div className={styles.stepNumber}>2</div>
                    <p className={styles.stepText}>
                      Scroll down to the <span>Search</span> section, then click the <span>Plus button</span> next to{' '}
                      <span>Klevu</span>
                    </p>
                  </li>
                  <li className={styles.step}>
                    <div className={styles.stepNumber}>3</div>
                    <p className={styles.stepText}>
                      Enter your <span>Klevu API credentials</span> and then click <span>Connect</span>
                    </p>
                  </li>
                </ul>
                That’s it! You’re now connected.
              </div>
              <div className={styles.videoContainer}>
                <iframe
                  className={styles.video}
                  title="Klevu Integration Demo"
                  src="//play.vidyard.com/h96aERCecByDRgrdnrmsVk.html?"
                  width="640"
                  height="360"
                  allowTransparency={true}
                  allowFullScreen={true}
                  referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
                Watch a video on connecting Klevu + Studio
              </div>
            </div>
          </div>

          <div className={styles.panel}>
            <div className={styles.titleContainer}>
              <CompleteCheckIcon data-test-id="marked-as-completed" className={styles.completeCheckIcon} />
              <h2 className={styles.title}>Step 3: Build your first landing page</h2>
            </div>
            <div className={styles.templatesBody}>
              <div className={styles.templatesText}>
                Use one of our Klevu Landing Page templates to get started quickly:
              </div>
              <div className={styles.templatesContainer}>
                <div className={styles.templateFilters}>
                  <span
                    className={classNames(styles.templateFilter, {
                      [styles.isSelected]: selectedCategory === 'Products',
                    })}
                    onClick={() => setSelectedCategory('Products')}
                  >
                    Products
                  </span>
                  <span
                    className={classNames(styles.templateFilter, {
                      [styles.isSelected]: selectedCategory === 'Collections',
                    })}
                    onClick={() => setSelectedCategory('Collections')}
                  >
                    Collections
                  </span>
                  <span
                    className={classNames(styles.templateFilter, {
                      [styles.isSelected]: selectedCategory === 'Promotions',
                    })}
                    onClick={() => setSelectedCategory('Promotions')}
                  >
                    Promotions
                  </span>
                  <span
                    className={classNames(styles.templateFilter, {
                      [styles.isSelected]: selectedCategory === 'Other pages',
                    })}
                    onClick={() => setSelectedCategory('Other pages')}
                  >
                    Other pages
                  </span>
                  <span
                    className={classNames(styles.templateFilter, { [styles.isSelected]: selectedCategory === null })}
                    onClick={() => setSelectedCategory(null)}
                  >
                    All
                  </span>
                </div>
                <div className={styles.templates} onScroll={handleScroll} ref={templatesRef}>
                  <div key="blank-template" className={styles.template} onClick={handleClick}>
                    <img src={BlankPage} alt="Blank Template" />
                  </div>
                  {isLoading && <Spinner className={styles.spinner} />}
                  {!isLoading &&
                    templates.map((template, index) => (
                      <div key={index} className={styles.template} onClick={handleClick}>
                        <img src={template.thumbnail} alt={template.name} />
                      </div>
                    ))}
                  {loadingMore && <Spinner className={styles.spinner} />}
                  <div className={styles.arrowsContainer}>
                    {!isScrolledToLeft && (
                      <a className={styles.scrollLeft} onClick={scrollLeft}>
                        <ArrowIcon />
                      </a>
                    )}
                    {(!isScrolledToRight || loadingMore) && (
                      <a className={styles.scrollRight} onClick={scrollRight}>
                        <ArrowIcon />
                      </a>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.helpChat}>
          <HelpIcon className={styles.chatIcon} />
          <div className={styles.chatText}>
            Need help getting started? Elastic Path offers free white-glove onboarding to help build you build your
            first landing page.
            <br />
            <a className={styles.chatLink} href="https://unstack.com/goto?klevu-contact-us" target="_blank">
              Schedule a chat with Elastic Path.
            </a>
          </div>
        </div>
      </main>
    </div>
  );
}

export default KlevuOnboarding;
