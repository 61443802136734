import React from 'react';
import { compose } from 'redux';
import withSite from '../../../containers/withSite';
import withSubscription from '../../../containers/withSubscription';
import idx from 'lodash/get';
import classnames from 'classnames';

function LandingPageFooter(props) {
  const { site, hasSubscription } = props;

  return (
    <footer className={classnames('landing-page-footer', idx(site, 'styles.footerBrightnessTheme'))}>
      <div className="container">
        <div className="copyright">
          © {site.name}
          {(hasSubscription && site.elements.hideSparkBranding) || (
            <>
              {' '}
              &bull; Made with{' '}
              <a href="https://www.elasticpath.com/products/cx-studio" target="_blank" rel="noopener noreferrer">
                Elastic Path Studio
              </a>
            </>
          )}
        </div>
      </div>
    </footer>
  );
}

export default compose(withSite, withSubscription)(LandingPageFooter);
