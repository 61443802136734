import * as types from './actionTypes';

export function create(payload) {
  return {
    type: types.SITE_CREATE_REQUEST,
    payload,
  };
}

export function deleteSite() {
  return { type: types.SITE_DELETE_REQUEST };
}

export function requestAll() {
  return {
    type: types.SITE_REQUEST_ALL,
  };
}

export function requestAllIfNeeded() {
  return {
    type: types.SITE_REQUEST_ALL_IF_NEEDED,
  };
}

export function request(id) {
  return {
    type: types.SITE_REQUEST,
    payload: { id },
  };
}

export function requestIfNeeded(id) {
  return {
    type: types.SITE_REQUEST_IF_NEEDED,
    payload: { id },
  };
}

export function receiveList(siteIds, entities) {
  return {
    type: types.SITE_RECEIVE_LIST,
    payload: { siteIds, entities },
  };
}

export function receive(site) {
  return {
    type: types.SITE_RECEIVE,
    payload: { site },
  };
}

export function receiveError(errors) {
  return {
    type: types.SITE_RECEIVE_ERROR,
    payload: { errors },
  };
}

export function update(siteProps) {
  return {
    type: types.SITE_UPDATE,
    payload: siteProps,
  };
}

export function patchOnboarding(payload) {
  return {
    type: types.SITE_UPDATE_ONBOARDING,
    payload,
  };
}

export function removeOnboardingStep(payload) {
  return {
    type: types.SITE_REMOVE_ONBOARDING_STEP,
    payload,
  };
}

export function updateUnthrottled(siteProps) {
  return {
    type: types.SITE_UPDATE_UNTHROTTLED,
    payload: siteProps,
  };
}

export function updateWithOps(ops) {
  return {
    type: types.SITE_UPDATE,
    payload: { ops: ops },
  };
}

export function setDomain(siteId, newDomain) {
  return {
    type: types.SITE_SET_DOMAIN,
    payload: { siteId, newDomain },
  };
}

export function removeDomain(siteId) {
  return {
    type: types.SITE_REMOVE_DOMAIN,
    payload: { siteId },
  };
}

export function refreshDomain(ops) {
  return {
    type: types.SITE_REFRESH_DOMAIN,
    payload: { ops: ops },
  };
}

export function domainFailure(payload) {
  return {
    type: types.SITE_DOMAIN_FAILURE,
    payload,
  };
}

export function activeUrlSiteChanged(urlSite) {
  return {
    type: types.SITE_ACTIVE_URL_SITE_CHANGED,
    payload: { urlSite },
  };
}

export function addSsl(siteId) {
  return {
    type: types.SITE_ADD_SSL,
    payload: { siteId },
  };
}

export function flushCache(siteId, callback) {
  return {
    type: types.SITE_FLUSH_CACHE,
    payload: { siteId, callback },
  };
}

export function extractDataUsingDomain(domain) {
  return {
    type: types.SITE_EXTRACT_DATA,
    payload: domain,
  };
}
