// An interface for hubspot's JavaScript API.
// see https://developers.hubspot.com/docs/api/events/tracking-code?_ga=2.155970939.1985369217.1596559742-369557419.1596559742

window._hsq = window._hsq || [];

export function identify(props) {
  window._hsq.push(['identify', props]);
}

export function setPagePathAndTrackPageView(path) {
  window._hsq.push(['setPath', path]);
  window._hsq.push(['trackPageView']);
}
