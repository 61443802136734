import { useDispatch, useSelector } from 'react-redux';
import ExpansionPanel from 'components/AdminPanel/Quickstart/ExpansionPanel';
import styles from 'components/AdminPanel/Quickstart/CreateFirstPagePanel.module.scss';
import { getIsEcommerceConnected } from 'components/AdminPanel/Quickstart/helpers';
import { selectAccount } from 'reducers/accountReducer';
import { ECOMMERCE_CONNECTED_PENDING, ECOMMERCE_CONNECTED_COMPLETE } from 'components/AdminPanel/Quickstart/constants';
import { patchOnboarding, removeOnboardingStep } from 'actions/accountActions';
import { useQuickstartStep } from 'components/AdminPanel/Quickstart/hooks/useQuickstartStep';

import { ReactComponent as ElasticPathLogo } from 'assets/images/icon-elasticpath.svg';
import { ReactComponent as AdobeLogo } from 'assets/images/adobe-logo.svg';
import { ReactComponent as CheckSvg } from 'assets/images/complete-check-icon-klevu-onboarding.svg';

import Integration from '../Integrations/Integration';
import { useState } from 'react';
import { OnMount } from 'hooks/mountUnmountHooks';
import { requestIntegrationTypesIfNeeded } from 'actions/integrationActions';
import { selectIntegrationTypes, selectSiteIntegrationsByType } from 'reducers/integrationsReducer';
import useActiveSite from 'hooks/useActiveSite';

const Content = () => {
  const [sidePanelOpen, setSidePanelOpen] = useState<string | false>(false);
  const integrationTypes = useSelector(selectIntegrationTypes);
  const ecommerceTypes = integrationTypes?.[0]?.integrations;
  const epType = ecommerceTypes ? ecommerceTypes.find((type: any) => type.id === 'elastic_path') : null;
  const magentoType = ecommerceTypes ? ecommerceTypes.find((type: any) => type.id === 'api2cart') : null;
  const dispatch = useDispatch();
  const siteId = useActiveSite((site) => site.id);
  // @ts-ignore
  const activeEPIntegration = useSelector((state) => selectSiteIntegrationsByType(state, siteId, 'elastic_path'))?.[0];
  // @ts-ignore
  const activeMagentoIntegration = useSelector((state) => selectSiteIntegrationsByType(state, siteId, 'api2cart'))?.[0];

  OnMount(() => {
    dispatch(requestIntegrationTypesIfNeeded());
  });

  return (
    <div className={`${styles.createFirstPageContent} ${styles.commerce}`}>
      <div>
        <p className={styles.selectEcommerceText}>Select your eCommerce platform to get started:</p>
        <div className={styles.commerceTable}>
          <div
            className={`${styles.logoContainer} ${activeEPIntegration && styles.activeIntegration}`}
            onClick={() => setSidePanelOpen(epType.id)}
          >
            <ElasticPathLogo />
            <div>Elastic Path Commerce Cloud</div>
            {activeEPIntegration && (
              <div className={styles.check}>
                <CheckSvg />
              </div>
            )}
          </div>
          <div
            className={`${styles.clickable} ${activeMagentoIntegration && styles.activeIntegration}`}
            onClick={() => setSidePanelOpen(magentoType.id)}
          >
            <AdobeLogo />
            <div>
              <div>Adobe Commerce</div>
              <div className={styles.small}>Magento</div>
              {activeMagentoIntegration && (
                <div className={styles.check}>
                  <CheckSvg />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {[epType, magentoType].map((type) => {
        return (
          type && (
            <Integration
              key={type.id}
              type={type.id}
              integrationSlug={type.id}
              name={type.name}
              authenticator={type.authenticator}
              icon={type.icon}
              showTableContent={false}
              isOpen={sidePanelOpen === type.id}
              handleClose={() => setSidePanelOpen(false)}
            />
          )
        );
      })}
    </div>
  );
};

export default function CustomizeEcommercePlatform() {
  const siteId = useActiveSite((site) => site.id);
  // @ts-ignore
  const activeEPIntegration = useSelector((state) => selectSiteIntegrationsByType(state, siteId, 'elastic_path'))?.[0];
  // @ts-ignore
  const activeMagentoIntegration = useSelector((state) => selectSiteIntegrationsByType(state, siteId, 'api2cart'))?.[0];

  const account = useSelector(selectAccount);
  const isStepComplete = getIsEcommerceConnected();
  const accountMetadata = account.metadata?.onboarding || [];
  const { open, handleExpansionPanelClick, handleIconClick } = useQuickstartStep({
    isStepComplete,
    metadata: accountMetadata,
    id: account.id,
    pendingDescription: ECOMMERCE_CONNECTED_PENDING,
    completedDescription: ECOMMERCE_CONNECTED_COMPLETE || activeEPIntegration || activeMagentoIntegration,
    patchOnboarding,
    removeOnboardingStep,
  });

  return (
    <ExpansionPanel
      title={`Connect your eCommerce platform`}
      isComplete={isStepComplete}
      open={open}
      onClick={handleExpansionPanelClick}
      onIconClick={handleIconClick}
    >
      <Content />
    </ExpansionPanel>
  );
}
