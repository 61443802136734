import * as actionTypes from '../actions/actionTypes';

// ----------------------------------------------------------------------------
// Reducer
// ----------------------------------------------------------------------------

const initState = {
  isOpen: false,
  requestedAction: undefined,
};

export default function (state = initState, action) {
  switch (action.type) {
    case actionTypes.SUBSCRIPTION_MANAGER_OPEN: {
      const { requestedAction } = action.payload;

      return {
        isOpen: true,
        requestedAction,
      };
    }

    case actionTypes.SUBSCRIPTION_MANAGER_CLOSE:
      return { ...state, isOpen: false };

    case actionTypes.SUBSCRIPTION_RECEIVE_CREATE_SUCCESS:
      return state.isOpen ? { ...state, upgradeSuccess: true } : state;

    case actionTypes.ROUTER_LOCATION_HAS_CHANGED:
      return state.isOpen ? { ...state, isOpen: false } : state;

    default:
      return state;
  }
}

// ----------------------------------------------------------------------------
// Selectors
// ----------------------------------------------------------------------------

export function selectLocalState(globalState) {
  return globalState.uiSubscriptionManager;
}
