import React, { useMemo, FunctionComponent, useState, Dispatch } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';

import { useOpenOauthModal } from 'components/AdminPanel/Quickstart/hooks/useOpenOauthModal';
import { selectSiteIntegrationsByType } from 'reducers/integrationsReducer';
import { selectActiveSite } from 'reducers/sitesReducer';
import { selectHasPermission } from 'reducers/policyReducer';
import { ReactComponent as CompleteCheckIcon } from 'assets/images/complete-check-icon.svg';
import { ReactComponent as IncompleteCheckIcon } from 'assets/images/incomplete-check-icon.svg';
import { open as openSubscriptionManager } from 'actions/uiSubscriptionManagerActions';
import { ReactComponent as WandIcon } from 'assets/images/wand-icon.svg';
import styles from 'components/AdminPanel/Quickstart/IntegrateContentPanel.module.scss';
import { AUTHENTICATOR_TYPE } from 'components/AdminPanel/Quickstart/IntegrateContentPanel';
import ZapierDrawer from '../Integrations/Zapier/ZapierDrawer';
import SalesForceDrawer from '../Integrations/Salesforce/SalesForceDrawer';

const noop = () => {};
interface IntegrationDrawerProps {
  name: string;
  icon?: string;
  id: string;
  integrationHash?: string;
  integrationSlug: string;
  authenticator: {
    id: string;
    authorization_url: string;
    authorization_success_message: string;
  };
  isOpen: boolean;
  close: () => void;
}
interface IntegrationDrawer {
  [key: string]: React.FC<IntegrationDrawerProps>;
}
const INTEGRATION_DRAWERS: IntegrationDrawer = {
  salesforce: SalesForceDrawer,
  //@ts-ignore
  zapier: ZapierDrawer,
};

function IntegrationCheckIcon({ completed }: { completed: boolean }) {
  const Icon = completed ? <CompleteCheckIcon /> : <IncompleteCheckIcon className={styles.incompleteCheckIcon} />;
  return <div className={styles.checkIconContainer}>{Icon}</div>;
}

interface IntegrationProps {
  name: string;
  icon?: string;
  id: string;
  integrationHash?: string;
  slug: string;
  authenticatorType: AUTHENTICATOR_TYPE;
  authorizationSuccessMessage?: string;
  authenticatorId?: string;
  authorizationUrl?: string;
  requiredPlan?: string;
}

export default function Integration({
  name,
  icon,
  id,
  slug,
  authorizationSuccessMessage,
  authenticatorId,
  authorizationUrl,
  authenticatorType,
  requiredPlan,
}: IntegrationProps) {
  const integrationDrawerProps = {
    name,
    icon,
    id,
    integrationSlug: slug,
    authenticator: {
      id: authenticatorId,
      authorization_url: authorizationUrl,
      authorization_success_message: authorizationSuccessMessage,
    },
  };

  const activeSite = useSelector(selectActiveSite);
  const Drawer = useMemo(() => {
    return INTEGRATION_DRAWERS[slug];
  }, [slug]);
  const handleOpenOauthModal = useOpenOauthModal({
    type: id,
    icon,
    siteId: activeSite.id,
    authorizationSuccessMessage,
    authenticatorId,
    integrationSlug: slug,
    authorizationUrl,
  });
  const { urlSite } = useParams();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const completedIntegration = useSelector((state) =>
    // @ts-ignore
    selectSiteIntegrationsByType(state, activeSite.id, id)
  );
  const hasPermission = useSelector(selectHasPermission);
  const dispatch: Dispatch<any> = useDispatch();
  const requiredPermission = `SiteIntegration:create:::${slug}`;
  const hasUpgradePermission = hasPermission(requiredPermission);
  const isUpgradeRequired = !hasUpgradePermission && !Boolean(completedIntegration);

  const openSubscriptionPanel = () => dispatch(openSubscriptionManager({ requestedAction: requiredPermission }));
  const apiIntegrationHandler = () => {
    navigate(`/${urlSite}/integrations`, { state: { slug } });
  };

  const handleOnClickIntegration = useMemo(() => {
    if (Drawer) return () => setIsOpen(true);
    if (completedIntegration && authenticatorType === AUTHENTICATOR_TYPE.API) {
      return apiIntegrationHandler;
    }
    if (slug == 'zapier') {
      return () => setIsOpen(true);
    }
    if (completedIntegration) return noop;
    if (isUpgradeRequired) return openSubscriptionPanel;
    if (authenticatorType === AUTHENTICATOR_TYPE.OAUTH) {
      return handleOpenOauthModal;
    }
    if (authenticatorType === AUTHENTICATOR_TYPE.API) {
      return apiIntegrationHandler;
    }
    return noop;
  }, [completedIntegration, isUpgradeRequired, authenticatorType]);
  return (
    <div
      className={`${styles.integration} ${Boolean(completedIntegration) ? styles.completedIntegration : ''}`}
      key={name}
      onClick={handleOnClickIntegration}
    >
      <img src={icon} />
      <p>{name}</p>
      {<IntegrationCheckIcon completed={completedIntegration} />}
      {isUpgradeRequired ? (
        <>
          <div className={styles.upgradeBanner}>
            <span>Upgrade</span>
          </div>
          <div className={styles.upgradeTooltip}>
            <p>
              <WandIcon />
              Click to upgrade!
            </p>
            You need to be on the {requiredPlan || 'Full'} plan to use {name}
          </div>
        </>
      ) : null}

      {Drawer && <Drawer {...integrationDrawerProps} isOpen={isOpen} close={() => setIsOpen(false)} />}
    </div>
  );
}
