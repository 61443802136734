import React, { memo, useEffect } from 'react';

import { CollectionTagArgs } from 'components/unstack-components/tag-types';
import {
  SectionHandlersInterface,
  SectionUiHandlersInterface,
  ContainerInfo,
} from 'components/unstack-components/types';
import { getItemKey } from '../../util/utils';

interface GeneratorProps extends ValueOnChangeProps<Array<Object>>, CollectionTagArgs {
  children?: React.ReactChildren;
  sectionHandlers?: SectionHandlersInterface;
  sectionUiHandlers?: SectionUiHandlersInterface;
  containerInfo?: ContainerInfo;
  productKey?: string;
  defaults: any;
}

const Generator = (props: GeneratorProps) => {
  const { item, onChange, className, sectionHandlers, sectionUiHandlers } = props;

  const value = props.dataRef || [null];

  let renderedCollection = value.map((listItem: any, i: number) => {
    const newSectionHandlers = { ...sectionHandlers };
    const newSectionUiHandlers = { ...sectionUiHandlers };

    const childProps = {
      onChange,
      sectionHandlers: newSectionHandlers,
      sectionUiHandlers: newSectionUiHandlers,
    };

    return React.cloneElement(item(listItem, i), childProps);
  });

  const CustomTag = props.tagName || 'div';

  return (
    // @ts-ignore-next-line
    <CustomTag className={className}>
      {value.length > 0 && renderedCollection}
      {value.length === 0 && props.empty && React.cloneElement(props.empty())}
    </CustomTag>
  );
};

export default memo(Generator);
