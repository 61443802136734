import React from 'react';
import { set, del, insert, push, assign } from 'object-path-immutable';

import { Button } from '../../../../../../Editor/Toolbars2/Toolbar2';
import { IconHeaderOption } from '../../../../../../Editor/Toolbars2/icons';
import { UBoxInfo, USectionDevices } from 'types/USection';
import { useSelector } from 'react-redux';
import { getDevice } from 'reducers/uiReducer';
import { getDeviceTypeToSaveTo } from 'components/unstack-components/Component/util/helpers/deviceHelper';

type Props = {
  content: USectionDevices;
  dataRef: UBoxInfo;
  onChange: (content: USectionDevices, key?: string, multi?: boolean) => void;
  contentKey: string;
};

const SectionHeader: React.FunctionComponent<Props> = ({ dataRef, onChange, content, contentKey }) => {
  const device = useSelector(getDevice);
  const splitKey = contentKey.split('.');

  return (
    <Button
      onMouseDown={() => {
        onChange(
          set(
            content,
            [getDeviceTypeToSaveTo(device, true), splitKey.slice(1).join('.'), 'showHeader'],
            !dataRef.showHeader
          ),
          `content.${splitKey[0]}`
        );
      }}
      data-tip="Toggle header"
      data-test-id="toggle-header"
      style={{ width: '40px', padding: '11px' }}
    >
      <IconHeaderOption active={dataRef.showHeader} />
    </Button>
  );
};

export default SectionHeader;
