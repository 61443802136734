import React, { useMemo, useState } from 'react';
import classNames from 'classnames';

import Modal from 'components/base/Modal';
import styles from './ImportModal.module.scss';
import { SPARK_API_BASE_URL } from 'settings';
import Toast, { ToastInfo } from 'components/base/Toast';

import { ReactComponent as WarningIcon } from 'assets/images/warning-icon.svg';
import { ReactComponent as UploadIcon } from 'assets/images/upload-icon.svg';
import useActiveSite from 'hooks/useActiveSite';

interface ImportModalProps {
  open: boolean;
  handleClose: () => void;
  table: string;
  refresh: () => void;
  setToastInfo: (toastInfo: ToastInfo) => void;
  exportTable: (filename?: string) => void;
  hasData: boolean;
}

const ImportModal = (props: ImportModalProps) => {
  const { open, handleClose, refresh, table, setToastInfo, exportTable, hasData } = props;

  const [file, setFile] = useState(null);
  const currentSite = useActiveSite();

  const disabled = useMemo(() => !file || !table || !currentSite, [file, table, currentSite]);

  const onClose = () => {
    setFile(null);
    handleClose();
  };

  const sendImportFile = async () => {
    if (disabled) {
      return;
    }

    const formData = new FormData();
    formData.append('file', file);

    const { sprkfe } = window as any;
    const url = `${SPARK_API_BASE_URL}data/table/${table}/import_csv/?site_id=${currentSite.id}`;

    const response = await fetch(url, {
      method: 'post',
      body: formData,
      headers: {
        Authorization: `JWT ${sprkfe.storage.getItem('JWT-TOKEN')}`,
      },
    });

    if (!response.ok) {
      setToastInfo({ messages: Object.values(await response.json()), type: 'error' });
    } else {
      setToastInfo({ messages: Object.values(await response.json()), type: 'success' });
      refresh();
      onClose();
    }
  };

  return (
    <Modal isOpen={open} close={onClose}>
      <div className={styles.modal}>
        <h3>Import CSV</h3>
        <div className={styles.root}>
          <div className={styles.uploadIcon}>
            <UploadIcon />
          </div>
          <div className={styles.contentContainer}>
            <div className={styles.templateBlock}>
              Your CSV must be the appropriate format.
              <a
                style={{ marginLeft: '8px' }}
                href="/"
                onClick={(e) => {
                  e.preventDefault();
                  exportTable('template.csv');
                }}
              >
                Download Template
              </a>
            </div>
            <div className={styles.selectFileTitle}>Please select a file to upload:</div>
            <div className={styles.fileContainer}>
              <label data-test-id="export" className={classNames('button button-secondary', styles.uploadButton)}>
                Choose a file
                <input
                  id="upload-file"
                  type="file"
                  accept=".csv"
                  style={{ display: 'none' }}
                  onChange={(e) => {
                    const { files } = e.target;

                    if (files.length) {
                      setFile(files[0]);
                    }
                  }}
                />
              </label>
              <span>{file ? file.name : 'No file chosen'}</span>
            </div>
            {hasData && (
              <div className={styles.warningContainer}>
                <span>
                  <WarningIcon className={styles.warningIcon} />
                </span>
                <span>
                  <div className={styles.warningQuestion}>Are you sure you want to continue?</div>
                  <div className={styles.warningInfo}>Importing a CSV will overwrite existing rows in your table.</div>
                </span>
              </div>
            )}
            <div className={styles.actionButtonContainer}>
              <a
                href="/"
                data-test-id="export"
                onClick={(e) => {
                  e.preventDefault();
                  handleClose();
                }}
                className={classNames('button button-secondary')}
              >
                Cancel
              </a>
              <a
                href="/"
                data-test-id="export"
                onClick={(e) => {
                  e.preventDefault();

                  if (disabled) {
                    return;
                  }

                  sendImportFile();
                }}
                className={classNames('button button-primary', styles.confirmButton)}
                /* @ts-ignore */
                disabled={disabled}
              >
                {hasData ? 'Yes, import & overwrite data' : 'Import now'}
              </a>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ImportModal;
