import MediaFormatter from './MediaFormatter';
import DateTimeFormatter from './DateTimeFormatter';
import DateFormatter from './DateFormatter';
import ForeignKeyFormatter from './ForeignKeyFormatter';
import SlugColumnFormatter from './SlugColumnFormatter';

export default {
  media: MediaFormatter,
  datetime: DateTimeFormatter,
  date: DateFormatter,
  foreign_key: ForeignKeyFormatter,
  slug: SlugColumnFormatter,
};
