import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useFilterState, usePagesEngagementData } from 'components/AdminPanel/Insights/report-hooks';
import { ReactComponent as SepiaBrand } from '../../../../assets/images/unstack-sepia.svg';

import styles from './UnstackTrialEnd.module.scss';

function UnstackTrialEnd(props: any) {
  const navigate = useNavigate();
  const filter = useFilterState();
  const [data, isLoading] = usePagesEngagementData(filter) as [[{ views: number }], boolean];

  const sum = data?.reduce((partialSum, a) => partialSum + a.views, 0) || 0;
  const totalPages = data?.length || 0;
  const totalPageviews = sum;

  const { urlSite } = useParams();

  return (
    <>
      <section className={styles.contentContainer}>
        <SepiaBrand className={styles.brand} />
        <section className={styles.container}>
          <div className={styles.header}>
            <div className={styles.icon}>🦄</div>
            <div className={styles.title}>Heads up! Your trial has ended.</div>
            <div className={styles.description}>
              <p>
                You created <b>{totalPages} pages</b> and had <b>{totalPageviews.toLocaleString()} pageviews</b>.
              </p>
              <p>Keep the magic going by selecting a plan.</p>
            </div>
            <button onClick={() => navigate(`/${urlSite}/settings/plans`)}>Choose a Plan</button>
            <div className={styles.extend}>
              Need to extend your trial?{' '}
              <a onClick={() => window.open('https://unstack.com/goto?extend-shopify-trial', '_blank')}>Get in touch</a>
            </div>
          </div>
        </section>
      </section>
    </>
  );
}

export default UnstackTrialEnd;
