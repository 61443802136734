import * as types from '../actions/actionTypes';

export default function sectionArticlesReducer(state = {}, action) {
  switch (action.type) {
    case types.PAGE_RECEIVE: {
      const newState = { ...state };
      const pages = Object.values(action.payload.entities.pages);
      pages.forEach((page) => Object.assign(newState, page.section_articles));
      return newState;
    }

    case types.PAGE_RECEIVE_SECTION_ARTICLES:
      return {
        ...state,
        ...action.payload.sectionArticles,
      };

    default:
      return state;
  }
}

export function selectSectionArticles(globalState) {
  return globalState.sectionArticles;
}
