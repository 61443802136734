import merge from 'lodash/merge';
import { SPARK_API_BASE_URL, TYPEKIT_PROXY_URL } from '../settings';
import QueryString from 'query-string';
import pickBy from 'lodash/pickBy';

// ----------------------------------------------------------------------------
// Base methods for getting, posting, & putting
// ----------------------------------------------------------------------------

export function assembleUrl(endpoint) {
  // Remove leading slash and add trailing slash to endpoint if necessary.
  endpoint = endpoint.replace(/^\//, '');
  // if (!endsWith(endpoint, '/')) endpoint = `${endpoint}/`;

  // Return assembled url
  return `${SPARK_API_BASE_URL}${endpoint}`;
}

export function get(endpoint, options) {
  const url = assembleUrl(endpoint);
  const mergedOptions = merge(
    {
      method: 'GET',
    },
    options
  );
  return fetchJSON(url, mergedOptions);
}

export function post(endpoint, data, options) {
  const url = assembleUrl(endpoint);
  const mergedOptions = merge(
    {
      method: 'POST',
      body: JSON.stringify(data),
    },
    options
  );
  return fetchJSON(url, mergedOptions);
}

export function put(endpoint, data, options) {
  const url = assembleUrl(endpoint);
  const mergedOptions = merge(
    {
      method: 'PUT',
      body: JSON.stringify(data),
    },
    options
  );
  return fetchJSON(url, mergedOptions);
}

// ----------------------------------------------------------------------------
// Authorized API calls
// ----------------------------------------------------------------------------

export function fetchAuthorized(url, options) {
  const mergedOptions = merge(
    {
      headers: {
        Authorization: `JWT ${window.sprkfe.storage.getItem('JWT-TOKEN')}`,
      },
    },
    options
  );
  return fetch(url, mergedOptions);
}

export function fetchAuthorizedJSON(url, options) {
  const mergedOptions = merge(
    {
      headers: {
        Authorization: `JWT ${window.sprkfe.storage.getItem('JWT-TOKEN')}`,
      },
    },
    options
  );
  return fetchJSON(url, mergedOptions);
}

export function authorizedGet(endpoint, options) {
  const url = assembleUrl(endpoint);
  const mergedOptions = merge(
    {
      method: 'GET',
    },
    options
  );
  return fetchAuthorizedJSON(url, mergedOptions);
}

export function authorizedGetUrl(url, options) {
  const mergedOptions = merge(
    {
      method: 'GET',
    },
    options
  );
  return fetchAuthorizedJSON(url, mergedOptions);
}

export function authorizedPost(endpoint, data, options) {
  const url = assembleUrl(endpoint);
  const mergedOptions = merge(
    {
      method: 'POST',
      body: JSON.stringify(data),
    },
    options
  );
  return fetchAuthorizedJSON(url, mergedOptions);
}

export function authorizedPut(endpoint, data, options) {
  const url = assembleUrl(endpoint);
  const mergedOptions = merge(
    {
      method: 'PUT',
      body: JSON.stringify(data),
    },
    options
  );
  return fetchAuthorizedJSON(url, mergedOptions);
}

export function authorizedPatch(endpoint, data, options) {
  const url = assembleUrl(endpoint);
  const mergedOptions = merge(
    {
      method: 'PATCH',
      body: JSON.stringify(data),
    },
    options
  );
  return fetchAuthorizedJSON(url, mergedOptions);
}

export function authorizedDelete(endpoint, data, options) {
  const url = assembleUrl(endpoint);
  const mergedOptions = merge(
    {
      method: 'DELETE',
      body: JSON.stringify(data),
    },
    options
  );
  return fetchAuthorizedJSON(url, mergedOptions);
}

// ----------------------------------------------------------------------------
// Authorization endpoints
// ----------------------------------------------------------------------------

export function obtainToken(username, password) {
  return post('token/obtain/', { username, password });
}

export function obtainTokenAfterVerification(code, token) {
  return post('token/obtain2/', { code, token });
}

export function refreshToken(token) {
  return post('token/refresh/', { token });
}

// ----------------------------------------------------------------------------
// Account endpoints
// ----------------------------------------------------------------------------

export function getAccounts() {
  return authorizedGet('account/');
}

export function getAccount(accountId) {
  if (!accountId) throw 'api.getAccount requires an accountId';
  return authorizedGet(`account/${accountId}/`);
}

export function createAccount(payload) {
  return post('account/', payload);
}

export function createCoachupAccount(coachup_id) {
  return post('account/coachup/', { coachup_id: coachup_id });
}

export function updateAccount(data) {
  const { id } = data;
  return authorizedPatch(`account/${id}/`, data);
}

export function createMultiSite(payload) {
  const { domain, selectedBusiness, theme } = payload;
  return authorizedPost(`account/create_multisite/`, {
    domain,
    selected_business: selectedBusiness,
    theme,
  });
}

export function patchAccountOnboarding(data) {
  const { id, step } = data;
  return authorizedPatch(`account/${id}/onboarding/`, { step });
}

export function deleteAccountOnboardingStep(data) {
  const { id, step } = data;
  return authorizedDelete(`account/${id}/onboarding/`, { step });
}

// ----------------------------------------------------------------------------
// Account-User (Team) endpoints
// ----------------------------------------------------------------------------

export function getAccountUsers(siteId) {
  return authorizedGet(`account_user/?site_id=${siteId}`);
}

export function getAccountUser(id) {
  return authorizedGet(`account_user/${id}/`);
}

export function createAccountUser(siteId, payload) {
  return authorizedPost(`account_user/?site_id=${siteId}`, payload);
}

export function updateAccountUser(data) {
  const { id } = data;
  return authorizedPatch(`account_user/${id}/`, data);
}

export function deleteAccountUser(data) {
  const { id } = data;
  return authorizedDelete(`account_user/${id}/`, data);
}

export function getCurrentUser(siteId) {
  return authorizedGet(`account_user/current/?site_id=${siteId}`);
}

export function sendInvite(id) {
  return authorizedPost(`account_user/${id}/resend_invite/`);
}

export function updateAccountOwner(id) {
  return authorizedPost(`account_user/${id}/update_owner/`);
}

export function resendVerificationEmail(id) {
  return authorizedGet(`account_user/${id}/resend_activation_link/`);
}

export function verifyEmail(token) {
  return get(`account_user/verify/${token}/`);
}
export function patchAccountUserOnboarding(data) {
  const { id, ...stepData } = data;
  return authorizedPatch(`account_user/${id}/onboarding/`, stepData);
}

// ----------------------------------------------------------------------------
// New User / Accept Invitation endpoints
// ----------------------------------------------------------------------------

export function getInvitation(token) {
  return get(`invitation/${token}/`);
}

export function acceptInvitation(token, user) {
  return put(`invitation/${token}/`, { user });
}

// ----------------------------------------------------------------------------
// Reset password endpoints
// ----------------------------------------------------------------------------

export function requestPasswordResetToken(email) {
  return post('password/', { email });
}

export function checkPasswordResetToken(token) {
  return get(`password/${token}/`);
}

export function resetPassword(token, newPassword) {
  return put(`password/${token}/`, {
    password: newPassword,
  });
}

// ----------------------------------------------------------------------------
// Two factor authentication endpoints
// ----------------------------------------------------------------------------

export function createMFA(data) {
  return authorizedPost('mfa/', data);
}

export function verifyMFACode(id, code) {
  return authorizedPut(`mfa/${id}/verify/`, { code });
}

export function getMFA() {
  return authorizedGet('mfa/');
}

export function disableMFA(id) {
  return authorizedDelete(`mfa/${id}/`);
}

// ----------------------------------------------------------------------------
// Site endpoints
// ----------------------------------------------------------------------------

export function getSites() {
  return authorizedGet(`site/?perpage=200`);
}

export function getSite(id) {
  return authorizedGet(`site/${id}/`);
}

export function updateSite(data) {
  const { id } = data;
  return authorizedPut(`site/${id}/`, data);
}

export function setDomain(id, newDomain) {
  const payload = { domain: newDomain };
  return authorizedPost(`site/${id}/set_domain/`, payload);
}

export function removeDomain(id) {
  return authorizedPost(`site/${id}/remove_domain/`);
}

export function addSsl(id) {
  return authorizedPost(`site/${id}/add_ssl/`);
}

export function createSite(data) {
  return authorizedPost(`site/`, data);
}

export function deleteSite(id) {
  return authorizedDelete(`site/${id}/`);
}

export function flushSiteCache(id) {
  return authorizedPost(`site/${id}/flush_cache/`);
}

export function patchSiteOnboarding(data) {
  const { id, stepData } = data;
  return authorizedPatch(`site/${id}/onboarding/`, stepData);
}

export function deleteSiteOnboardingStep(data) {
  const { id, stepData } = data;
  return authorizedDelete(`site/${id}/onboarding/`, stepData);
}

// ----------------------------------------------------------------------------
// Themes endpoints
// ----------------------------------------------------------------------------

export function getThemes() {
  return authorizedGet('theme/');
}

// ----------------------------------------------------------------------------
// Section
// ----------------------------------------------------------------------------
export function createSection(data) {
  return authorizedPost('section/', data);
}

export function createElement(siteId, sectionId, name) {
  return authorizedPost(`element/`, { site: siteId, section: sectionId, name });
}

export function deleteSection(id) {
  return authorizedDelete(`section/${id}/`);
}

export function getRecentComponent(siteId) {
  return authorizedGet(`component/recently_used/?site_id=${siteId}`);
}

// ----------------------------------------------------------------------------
// Components
// ----------------------------------------------------------------------------
export function getComponentDefaults() {
  return authorizedGet('component/default_props/');
}

// ----------------------------------------------------------------------------
// Page endpoints
// ----------------------------------------------------------------------------

export function getPages(siteId, query = '', perPage = 1000) {
  return authorizedGet(`page/?site_id=${siteId}${query ? `&search=${query}` : ''}&perpage=${perPage}`);
}

export function getPaginatedPages(filters) {
  const {
    page = 1,
    perPage = 10,
    debouncedSearch: search,
    itemType,
    draft,
    published,
    liveAB,
    siteId,
    order_by,
  } = filters;

  const queryObject = pickBy(
    {
      site_id: siteId,
      perpage: perPage,
      page: page,
      search: search,
      item_type: itemType,
      status: draft || published,
      live_ab: liveAB,
      order_by,
    },
    (v) => !!v
  );

  const queryString = QueryString.stringify(queryObject);

  return authorizedGet(`item/?${queryString}`);
}

export function getPage(pageId) {
  return authorizedGet(`page/${pageId}/draft/`);
}

export function getSparkRenderedForm(formId, queryParams) {
  const queryString = QueryString.stringify(queryParams);
  return authorizedGet(`form/${formId}/rendered/?${queryString}`);
}

export function getSparkRenderedSystemForm(systemFormId, queryParams) {
  const queryString = QueryString.stringify(queryParams);
  return authorizedGet(`form/system/${systemFormId}/rendered/?${queryString}`);
}

export function createPage(data = {}) {
  const payload = {
    item_type: 'site_page',
    meta: { showAddSectionTooltip: true },
    ...data,
  };
  return authorizedPost('page/', payload);
}

export function putPage(data) {
  const { id } = data;
  return authorizedPut(`page/${id}/`, data);
}

export function deletePage(id) {
  return authorizedDelete(`page/${id}/`);
}

export function publishPage(id) {
  return authorizedPost(`page/${id}/publish/`);
}

export function unpublishPage(id) {
  return authorizedPost(`page/${id}/unpublish/`);
}

export function clonePage(id) {
  return authorizedPost(`page/${id}/clone/`);
}

export function discardPage(id) {
  return authorizedPost(`page/${id}/discard/`);
}

export function undeletePage(id) {
  return authorizedPost(`page/${id}/undelete/`);
}

// ----------------------------------------------------------------------------
// Blog endpoints
// ----------------------------------------------------------------------------

export function getBlogs(siteId, query = '', perPage = 1000) {
  return authorizedGet(`blog/?site_id=${siteId}${query ? `&search=${query}` : ''}&perpage=${perPage}`);
  return authorizedGet('blog/?perpage=1000');
}

export function getBlog(blogId) {
  return authorizedGet(`blog/${blogId}/draft/`);
}

export function createBlog(data) {
  return authorizedPost('blog/', data);
}

export function putBlog(data) {
  const { id } = data;
  return authorizedPut(`blog/${id}/`, data);
}

export function deleteBlog(id) {
  return authorizedDelete(`blog/${id}/`);
}

export function publishBlog(id) {
  return authorizedPost(`blog/${id}/publish/`);
}

export function discardBlog(id) {
  return authorizedPost(`blog/${id}/discard/`);
}

// ----------------------------------------------------------------------------
// Article endpoints
// ----------------------------------------------------------------------------

export function getArticles(siteId, query = '', perPage = 1000) {
  const queryString = QueryString.stringify({
    site_id: siteId,
    search: query,
    perpage: perPage,
  });

  return authorizedGet(`article/?${queryString}`);
}

export function getPaginatedArticles(siteId, filters) {
  const {
    perPage = 10,
    page = 1,
    owner,
    debouncedSearch: search,
    searchResultPageNumber,
    blog,
    draft,
    scheduled,
    published,
    order_by,
  } = filters;

  const queryObject = pickBy(
    {
      site_id: siteId,
      perpage: perPage,
      page: searchResultPageNumber || page,
      search: search,
      author: owner,
      blog: blog,
      status: draft || scheduled,
      order_by: order_by,
    },
    (v) => !!v
  );

  const queryString = QueryString.stringify(queryObject);

  return authorizedGet(`article/?${queryString}`);
}

export function getArticle(articleId) {
  return authorizedGet(`article/${articleId}/draft/`);
}

export function createArticle(data) {
  return authorizedPost('article/', data);
}

export function putArticle(data) {
  const { id } = data;
  return authorizedPut(`article/${id}/`, data);
}

export function deleteArticle(id) {
  return authorizedDelete(`article/${id}/`);
}

export function publishArticle(id, isSchedule) {
  return authorizedPost(`article/${id}/publish/?is_schedule=${isSchedule}`);
}

export function unpublishArticle(id) {
  return authorizedPost(`article/${id}/unpublish/`);
}

export function unscheduleArticle(id) {
  return authorizedPost(`article/${id}/unschedule/`);
}

export function discardArticle(id) {
  return authorizedPost(`article/${id}/discard/`);
}

export function undeleteArticle(id) {
  return authorizedPost(`article/${id}/undelete/`);
}

// ----------------------------------------------------------------------------
// Items endpoints
// ----------------------------------------------------------------------------

export function getItems({
  search,
  perpage = 10,
  page,
  debouncedSearch,
  item_type,
  owner,
  searchResultPageNumber,
  blog,
  site_id,
  order_by,
  status,
}) {
  search = search || debouncedSearch;
  page = searchResultPageNumber || page;
  const query = QueryString.stringify({
    ...(status && { status }),
    search,
    page,
    perpage,
    item_type,
    author: owner,
    blog,
    site_id,
    order_by,
    // live_ab: liveAB,
  });
  return authorizedGet(`item/?${query}${!item_type ? '&exclude_item_type=category,data_page&status=published' : ''}`);
}

export function getItem(id) {
  return authorizedGet(`item/${id}/`);
}

export function getItemUrls({ site_id, page, perpage = 10, search, status }) {
  const query = QueryString.stringify({
    ...(status && { status }),
    page,
    perpage,
    site_id,
    search,
  });
  return authorizedGet(`item_url/?${query}`);
}

export function flushItemUrlCache({ id }) {
  return authorizedPost(`item_url/${id}/flush_cache/`);
}

export function createUrl(data, siteId) {
  return authorizedPost(`item_url/?site_id=${siteId}`, {
    ...data,
    site: siteId,
  });
}

export function updateUrl(data) {
  const { id } = data;
  return authorizedPut(`/item_url/${id}/`, data);
}

export function removeUrl(id) {
  return authorizedDelete(`item_url/${id}/`);
}

// ----------------------------------------------------------------------------
// Tag endpoints
// ----------------------------------------------------------------------------

export function getTags(siteId) {
  return authorizedGet(`tag/?site_id=${siteId}`);
}

export function createTag(data) {
  return authorizedPost('tag/', data);
}

export function deleteTag(id) {
  return authorizedDelete(`tag/${id}/`);
}

// ----------------------------------------------------------------------------
// Article Category endpoints
// ----------------------------------------------------------------------------

export function createCategory(data) {
  return authorizedPost('category/', data);
}

export function getCategoryList(siteId) {
  return authorizedGet(`category/?site_id=${siteId}&perpage=1000`);
}

export function updateCategory(data) {
  const { id } = data;
  return authorizedPut(`category/${id}/`, data);
}

export function removeCategory(id) {
  return authorizedDelete(`category/${id}/`);
}

// ----------------------------------------------------------------------------
// Integration endpoints
// ----------------------------------------------------------------------------

export function getIntegrationTypes({ siteId, search }) {
  const queryString = QueryString.stringify({ site_id: siteId, search });
  return authorizedGet(`integration_category/?${queryString}`);
}

export function getIntegrations(siteId) {
  return authorizedGet(`site_integration/?site_id=${siteId}&perpage=1000`);
}

export function bulkCreateIntegration(data, siteId) {
  return authorizedPost(`site_integration/bulk_connect/?site_id=${siteId}`, data);
}

export function createIntegration(data) {
  return authorizedPost('site_integration/', data);
}

export function patchIntegration(id, data) {
  return authorizedPatch(`site_integration/${id}/`, data);
}

export function deleteIntegration(id) {
  return authorizedDelete(`site_integration/${id}/`);
}

export function runSyncJobs(integrationId) {
  return authorizedGet(`site_integration/${integrationId}/trigger_sync_job/`);
}

/**** Hubspot Integration Forms ****/
export function getHubspotForms(siteId) {
  return authorizedGet(`integration/form/?site_id=${siteId}&type=hubspot`);
}

export function getContactLists(siteId) {
  return authorizedGet(`site_integration/contact_lists/?site_id=${siteId}`);
}

export function getMailchimpTags(id, query) {
  return authorizedPost(`site_integration/${id}/query/`, {
    method: 'search_tags',
    params: { name: query },
  });
}

// ----------------------------------------------------------------------------
// Media endpoints
// ----------------------------------------------------------------------------

export function uploadMedia(file, category) {
  const url = assembleUrl('media/');
  const formData = new FormData();
  formData.append('media', file);
  formData.append('category', category);

  return fetchAuthorized(url, {
    method: 'POST',
    body: formData,
  }).then(parseJSONResponse);
}

export function getMedia(siteId, category = '') {
  return authorizedGet(`media/?site_id=${siteId}${category ? `&category_id=${category}` : ''}`);
}

export function getMediaPerPage({ page = 1, category, siteId }) {
  return authorizedGet(`media/?site_id=${siteId}&perpage=50&page=${page}${category ? `&category_id=${category}` : ''}`);
}

export function getFilteredMedia({ query, category, siteId }) {
  return authorizedGet(
    `media/?site_id=${siteId}&perpage=50&file_name=${query}${category ? `&category_id=${category}` : ''}`
  );
}

export function getMediaById(id) {
  return authorizedGet(`media/${id}/`);
}

export function deleteMedia(id) {
  return authorizedDelete(`media/${id}/`);
}

// ----------------------------------------------------------------------------
// Subscriptions
// ----------------------------------------------------------------------------

export function getSubscriptions(siteId) {
  return authorizedGet(`account/subscription/?site_id=${siteId}`);
}

export function createSubscription(payload) {
  return authorizedPost('account/subscription/', payload);
}

export function getCoupon(id) {
  return authorizedGet(`account/coupon/${id}/`);
}

export function getSubscriptionProducts() {
  return authorizedGet(`account/product/`);
}

export function updateCreditCard(subscription_id, card_id, token_id) {
  return authorizedPost(`account/subscription/${subscription_id}/update_credit_card/`, { card_id, token_id });
}

// ----------------------------------------------------------------------------
// Site statistics
// ----------------------------------------------------------------------------

export function getSiteStatistics(siteId, type) {
  return authorizedGet(`site/statistics/?site_id=${siteId}&type=${type}`);
}

// ----------------------------------------------------------------------------
// Site layouts
// ----------------------------------------------------------------------------

export function getSiteLayout(siteId) {
  return authorizedGet(`site/layout/?site_id=${siteId}`);
}

export function getLayoutSections(siteId, type) {
  return authorizedGet(`section/?site_id=${siteId}&component_type=${type}`);
}

export function updateLayout(siteId, data) {
  const { id } = data;
  return authorizedPut(`site/layout/${id}/?site_id=${siteId}`, data);
}

export function createLayout(siteId, data) {
  return authorizedPost(`site/layout/?site_id=${siteId}`, { ...data, site: siteId });
}

// ----------------------------------------------------------------------------
// Storefront
// ----------------------------------------------------------------------------

export function getShopifyCollections(title, perPage, cursor, siteId) {
  const query = QueryString.stringify({
    title,
    cursor,
    per_page: perPage,
    site_id: siteId,
  });

  return authorizedPost(`commerce/category/?${query}`);
}

export function getStorefrontCollections({ search, per_page = 5, parentId, nextPage }, siteId) {
  let query = '';
  if (nextPage) {
    query = new URL(nextPage).search.replace('?', '');
  } else
    query = QueryString.stringify({
      search,
      site_id: siteId,
      per_page,
    });

  const payload = { parent: parentId };

  return authorizedPost(`commerce/category/?${query}`, payload);
}

export function getStorefrontCollection(collectionId, siteId) {
  const query = QueryString.stringify({
    site_id: siteId,
  });
  return authorizedPost(`commerce/category/?${query}`, { id: collectionId });
}

export function getStorefrontProducts({ search = '', per_page = 10, parentId, nextPage }, site) {
  let query = '';
  if (nextPage) {
    query = new URL(nextPage).search;
  } else
    query = QueryString.stringify({
      site_id: site.id,
      search: parentId && site.is_shopify ? '' : search ? (site.is_shopify ? search : `like(name,${search})`) : '',
      per_page,
    });

  const payload = { category_id: parentId };

  return authorizedPost(`commerce/product/?${query}`, payload);
}

export function getStorefrontProduct(productId, siteId) {
  const query = QueryString.stringify({
    site_id: siteId,
  });

  return authorizedPost(`commerce/product/?${query}`, { id: productId });
}

export function getShopifyCollection(collectionId, siteId) {
  const query = QueryString.stringify({
    site_id: siteId,
  });
  return authorizedPost(`commerce/category/?${query}`, { id: collectionId });
}

export function getProductsInfo(search, perPage, cursorURL, orderBy, siteId, isShopify) {
  if (cursorURL) {
    return fetchAuthorizedJSON(cursorURL, { method: 'POST' });
  }
  const query = QueryString.stringify({
    search: search ? (isShopify ? search : `like(name,${search})`) : '',
    per_page: perPage,
    site_id: siteId,
    order_by: orderBy,
  });

  return authorizedPost(`commerce/product/?${query}`);
}

export function getProductInfo(productId, siteId) {
  const query = QueryString.stringify({
    site_id: siteId,
  });

  return authorizedPost(`commerce/product/?${query}`, { id: productId });
}

export function connectShopifyWithOAuth(params, isInIframe, basePath = 'shopify') {
  return post(`integration/${basePath}/connect/${params}`, {
    is_in_app_view: isInIframe,
  });
}

export function shopifyAppAuth(params, isInIframe, session_token, basePath = 'shopify') {
  if (session_token) {
    return post(`integration/${basePath}/token_exchange/${params}`, {
      is_in_app_view: isInIframe,
      session_token,
    });
  } else {
    return post(`integration/${basePath}/${params}`, {
      is_in_app_view: isInIframe,
    });
  }
}

export function shopifySubscribe(siteId, plan, basePath = 'shopify') {
  const body = !plan ? undefined : { plan: plan + '_month' };
  return authorizedPost(`integration/${basePath}/subscribe/?site_id=${siteId}`, body);
}

export function shopifyActivate(data, basePath = 'shopify') {
  return authorizedPost(`integration/${basePath}/activate/?site_id=${data.site_id}`, { ...data });
}

export function shopifyReconnect(site, basePath = 'shopify') {
  return authorizedGet(`integration/${basePath}/reconnect_url/?site=${site}`);
}

export function getSyncedProducts(site, basePath = 'shopify') {
  return authorizedGet(`integration/${basePath}/product_count/?site_id=${site}`);
}

export function getResourceFeedback(site, basePath = 'shopify') {
  return authorizedGet(`integration/${basePath}/resource_feedback_list/?site_id=${site}`);
}

// ----------------------------------------------------------------------------
// Klevu
// ----------------------------------------------------------------------------

export function getKlevuRedirectUrl(accountId, apiKey, userId) {
  const data = {
    accountId,
    apiKey,
    userId,
  };
  return authorizedPost(`integration/klevu/`, data);
}

export function createKlevuMagentoAccount(state, password) {
  const data = {
    state,
    password,
  };
  return authorizedPost(`integration/klevu/onboarding/`, data);
}

// ----------------------------------------------------------------------------
// Analytics
// ----------------------------------------------------------------------------

export function getAnalyticsConversionPathReport(siteId, channel, goal) {
  const queryString = QueryString.stringify({ site_id: siteId, channel, goal });
  return authorizedGet(`analytics/conversion_path_report/?${queryString}`);
}

export function getAnalyticsConversionSourceReport(siteId, channel, goal, segment) {
  const queryString = QueryString.stringify({
    site_id: siteId,
    channel,
    goal,
    segment,
  });
  return authorizedGet(`analytics/conversion_source_report/?${queryString}`);
}

export function getContentTestReport(siteId, channel = null, goal = null) {
  // Ignore channel and goal for content-tests
  return authorizedGet(`experiment/report/?site_id=${siteId}`);
}

export function getPagesEngagementData(siteId) {
  return authorizedGet(`page/summary/?site_id=${siteId}&page=&status=published&is_deleted=False`);
}

export function getGoogleConsoleQueryStats(id, site_domain, end_date, start_date) {
  const data = {
    ep: 'search_analytics',
    method: 'get',
    params: {
      site_domain,
      start_date,
      end_date,
      dimensions: 'query',
    },
  };
  return authorizedPost(`site_integration/${id}/query/`, data);
}

export function getGoogleConsolePageStats(id, site_domain, end_date, start_date) {
  const data = {
    ep: 'search_analytics',
    method: 'get',
    params: {
      site_domain,
      start_date,
      end_date,
      dimensions: 'page',
    },
  };
  return authorizedPost(`site_integration/${id}/query/`, data);
}

export function getSemrushData(id, type, query, database) {
  const queryString = QueryString.stringify({
    database,
    ...(type !== 'competitor_backlink_report' ? { phrase: query } : { target: query, target_type: 'root_domain' }),
  });
  return authorizedGet(`integration/semrush/${id}/${type}/?${queryString}`);
}

// ----------------------------------------------------------------------------
// Contacts
// ----------------------------------------------------------------------------

export function getContacts(siteId) {
  return authorizedGet(`contact/?site_id=${siteId}`);
}

export function getSearchedContacts(siteId, query) {
  return authorizedGet(`contact/?site_id=${siteId}&search=${query}&perpage=20&page=${1}`);
}

export function getFilteredContacts(siteId, search, filter, page) {
  return authorizedGet(`contact/?site_id=${siteId}&search=${search}${filter && `&${filter}`}&perpage=50&page=${page}`);
}

export function getContactsPerPage(siteId, page, perPage = 50) {
  return authorizedGet(`contact/?site_id=${siteId}&perpage=${perPage}&${page ? `page=${page}` : ''}`);
}

export function getContact(contactId) {
  return authorizedGet(`contact/${contactId}/`);
}

export function deleteContact(contactId) {
  return authorizedDelete(`contact/${contactId}/`);
}

export function createContact(data) {
  const { hubspot, mailchimp, klaviyo, activecampaign } = data;

  const queryString = QueryString.stringify({
    hubspot,
    mailchimp,
    klaviyo,
    activecampaign,
  });

  return authorizedPost(`contact/?${queryString}`, data);
}

export function updateContact(id, data) {
  return authorizedPut(`contact/${id}/`, data);
}

export function getContactsCount(siteId) {
  return authorizedGet(`/contact/recent/?site_id=${siteId}`);
}

export function getContactsCountSince(siteId, seconds = 600) {
  return authorizedGet(`/contact/recent/?site_id=${siteId}&seconds=${seconds}`);
}

// ----------------------------------------------------------------------------
// Notes
// ----------------------------------------------------------------------------

export function getFilteredNotes(siteId, filters = {}) {
  const { contactId } = filters;
  return authorizedGet(`note/?site_id=${siteId}&contact=${contactId}`);
}

export function postNote(data) {
  return authorizedPost('note/', data);
}

export function putNote(data) {
  const { id } = data;
  return authorizedPut(`note/${id}/`, data);
}

export function deleteNote(id) {
  return authorizedDelete(`note/${id}/`);
}

// ----------------------------------------------------------------------------
// Templates
// ----------------------------------------------------------------------------

export function getTemplates(siteId) {
  return authorizedGet(`template/?site_id=${siteId}&perpage=1000`);
}

export function getTemplate(templateId, siteId) {
  return authorizedGet(`template/${templateId}/?site_id=${siteId}`);
}

export function cloneTemplate(templateId, siteId, item_type) {
  return authorizedPost(`template/${templateId}/clone/?site_id=${siteId}`, {
    item_type,
  });
}

export function createTemplate(data) {
  return authorizedPost('template/', data);
}

export function updateTemplate(data) {
  const { id } = data;
  return authorizedPut(`template/${id}/`, data);
}

export function removeTemplate({ id, siteId }) {
  return authorizedDelete(`template/${id}/?site_id=${siteId}`);
}

export function getTemplateTags() {
  return authorizedGet(`template_tag/`);
}

// ----------------------------------------------------------------------------
// Content tests
// ----------------------------------------------------------------------------

export function getContentTests(siteId, perPage = 100) {
  return authorizedGet(`experiment/?site_id=${siteId}&perpage=${perPage}`);
}

export function getContentTest(id) {
  return authorizedGet(`experiment/${id}/`);
}

export function createContentTest(payload) {
  return authorizedPost(`experiment/`, payload);
}

export function updateContentTest(id, payload) {
  return authorizedPut(`experiment/${id}`, payload);
}

export function startContentTest(id) {
  return authorizedPost(`experiment/${id}/start/`, {});
}

export function endContentTest(id) {
  return authorizedPost(`experiment/${id}/end/`, {});
}

export function deleteContentTest(id) {
  return authorizedDelete(`experiment/${id}/`, {});
}

// ----------------------------------------------------------------------------
// Goals
// ----------------------------------------------------------------------------

export function getGoals(siteId) {
  return authorizedGet(`goal/?site_id=${siteId}`);
}

// ----------------------------------------------------------------------------
// Forms
// ----------------------------------------------------------------------------

export function getForm(formId) {
  return authorizedGet(`form/${formId}/`);
}

export function postForm(data) {
  return authorizedPost('form/', data);
}

export function deleteForm(id) {
  return authorizedDelete(`form/${id}/`);
}

export function putForm(data, id) {
  return authorizedPut(`form/${id}/`, data);
}

export function getForms(siteId, { page, perpage = 10, search, order_by }) {
  const params = QueryString.stringify({
    page,
    perpage,
    search,
    order_by,
    site_id: siteId,
  });
  return authorizedGet(`form/?${params}`);
}

// ----------------------------------------------------------------------------
// Tasks
// ----------------------------------------------------------------------------

export function getTasks(siteId, filter) {
  const { owner, search } = filter;
  return authorizedGet(`task/?site_id=${siteId}&${owner ? `owner=${owner}` : ''}${search ? `&search=${search}` : ''}`);
}

export function postTask(data) {
  return authorizedPost('task/', data);
}

export function putTask(data) {
  const { id } = data;
  return authorizedPut(`task/${id}/`, data);
}

export function getContactTaskCount(id) {
  return authorizedGet(`task/count/?contact=${id}`);
}

export function deleteTask(id) {
  return authorizedDelete(`task/${id}/`);
}

export function getFilteredTasks(filters = {}) {
  const { contactId = '' } = filters;
  return authorizedGet(`task/?contact=${contactId}`);
}

export function getTasksCount(siteId) {
  return authorizedGet(`task/count/?site_id=${siteId}`);
}

// ----------------------------------------------------------------------------
// Payments
// ----------------------------------------------------------------------------

export function createProduct(data) {
  return authorizedPost('subscription/product/', data);
}

export function updateProduct(data) {
  return authorizedPut(`subscription/product/${data.id}/`, data);
}

export function deleteProduct(id) {
  return authorizedDelete(`subscription/product/${id}/`);
}

export function getProducts(siteId, { search, page } = {}) {
  const params = QueryString.stringify({
    page,
    ...(search && { perpage: 10 }),
    search,
    site_id: siteId,
  });
  return authorizedGet(`subscription/product/?${params}`);
}

export function createPlan(data) {
  return authorizedPost('subscription/plan/', data);
}

export function updatePlan(data) {
  return authorizedPut(`subscription/plan/${data.id}/`, data);
}

export function deletePlan(id) {
  return authorizedDelete(`subscription/plan/${id}/`);
}

export function getPeriods() {
  return authorizedGet('payment_period/');
}

// ----------------------------------------------------------------------------
// Timezones
// ----------------------------------------------------------------------------

export function getTimezones() {
  return authorizedGet('timezone/');
}

// ----------------------------------------------------------------------------
// Data Tables
// ----------------------------------------------------------------------------

export function createTable(data) {
  return authorizedPost('data/table/', data);
}

export function getDataTables(siteId) {
  return authorizedGet(`data/table/?site_id=${siteId}&perpage=1000`);
}

export function getTableColumns(siteId, tableId) {
  return authorizedGet(`data/column/?site_id=${siteId}&data_table_id=${tableId}&perpage=1000`);
}

export function getTableRows(siteId, tableId, search) {
  const queryParams = QueryString.stringify({
    site_id: siteId,
    data_table_id: tableId,
    search,
    perpage: 1000,
  });
  return authorizedGet(`data/row/?${queryParams}`);
}

export function updateRow(data) {
  return authorizedPatch(`data/row/${data.id}/`, data);
}

export function updateColumn(data) {
  return authorizedPatch(`data/column/${data.id}/`, data);
}

export function deleteColumn(id) {
  return authorizedDelete(`data/column/${id}/`);
}

export function createRow(data) {
  return authorizedPost('data/row/', data);
}

export function deleteRow(id) {
  return authorizedDelete(`data/row/${id}/`);
}

export function createColumn(data) {
  return authorizedPost('data/column/', data);
}

// ----------------------------------------------------------------------------
// Currencies
// ----------------------------------------------------------------------------

export function getCurrencies() {
  return authorizedGet('currency/');
}

// ----------------------------------------------------------------------------
// Fonts
// ----------------------------------------------------------------------------

export function getFonts(siteId) {
  return authorizedGet(`font/?site_id=${siteId}&perpage=1000`);
}

export function getTypekitFonts(kit_id) {
  return fetchAuthorizedJSON(`${TYPEKIT_PROXY_URL}/api/v1/json/kits/${kit_id}/published`);
}

// ----------------------------------------------------------------------------
// Videos
// ----------------------------------------------------------------------------

export function getVideos() {
  return authorizedGet(`help/content/`);
}

// ----------------------------------------------------------------------------
// API Keys
// ----------------------------------------------------------------------------

export function getAPIKeys(siteId) {
  return authorizedGet(`apikey/?site_id=${siteId}`);
}

export function deleteAPIKey(key, siteId) {
  return authorizedDelete(`apikey/${key}/?site_id=${siteId}`);
}

export function addAPIKey(name, siteId) {
  const data = {
    name,
    site: siteId,
  };
  return authorizedPost(`apikey/?site_id=${siteId}`, data);
}

// ----------------------------------------------------------------------------
// Klaviyo
// ----------------------------------------------------------------------------

export function getKlaviyoSegments(siteId) {
  const params = QueryString.stringify({
    site_id: siteId,
  });
  return authorizedGet(`klaviyo/segment/?${params}`);
}

// ----------------------------------------------------------------------------
// Utilities
// ----------------------------------------------------------------------------

function fetchJSON(url, options) {
  const mergedOptions = merge(
    {
      headers: {
        'content-type': 'application/json',
      },
    },
    options
  );
  return fetch(url, mergedOptions).then(parseJSONResponse);
}

function parseJSONResponse(response) {
  if (response.status === 204) {
    return {
      status: response.status,
      ok: response.ok,
      json: {},
      unauthorized: false,
      forbidden: false,
    };
  } else if (response.status === 500) {
    return response.text().then((text) => ({
      status: response.status,
      ok: response.ok,
      text,
      unauthorized: response.status === 401,
      forbidden: response.status === 403,
    }));
  } else {
    return response.json().then((json) => ({
      status: response.status,
      ok: response.ok,
      json,
      unauthorized: response.status === 401,
      forbidden: response.status === 403,
    }));
  }
}
