import createCachedSelector from 're-reselect';
import * as actionTypes from '../actions/actionTypes';

// Reducer
// ----------------------------------------------------------------------------

const initState = {
  isFetching: null,
  isCloning: null,
  isCreating: null,
  all: [],
};

export default function (state = initState, action) {
  switch (action.type) {
    case actionTypes.TEMPLATES_REQUEST_ALL:
      return {
        ...state,
        isFetching: true,
      };

    case actionTypes.TEMPLATES_RECEIVE_ALL:
      return {
        ...state,
        isFetching: false,
        all: action.payload.templates,
      };

    case actionTypes.TEMPLATES_REQUEST_CLONE:
      return {
        ...state,
        isCloning: action.payload.pageId,
      };

    case actionTypes.TEMPLAETS_RECEIVE_CLONE_SUCCESS:
      return {
        ...state,
        isCloning: null,
      };

    case actionTypes.TEMPLATES_REQUEST_CREATE:
      return {
        ...state,
        isCreating: true,
      };

    case actionTypes.TEMPLATES_REQUEST_CREATE_SUCCESS:
      return {
        ...state,
        isCreating: false,
        all: [...state.all, action.payload],
      };

    case actionTypes.TEMPLATES_REQUEST_UPDATE_SUCCESS:
      return {
        ...state,
        isCreating: false,
        // all: [...state.all, action.payload],
      };

    case actionTypes.TEMPLAETS_RECEIVE_CLONE_ERROR:
      return {
        ...state,
        isCloning: null,
        errors: action.payload.errors,
      };

    case actionTypes.TEMPLATES_REQUEST_REMOVE:
      return {
        ...state,
        all: state.all.filter((template) => template.id !== action.payload),
      };

    default:
      return state;
  }
}

// Raw selectors
// ----------------------------------------------------------------------------

export function selectLocalState(globalState) {
  return globalState.templates;
}

export function selectIsFetching(globalState) {
  const state = selectLocalState(globalState);
  return state.isFetching;
}

export function selectIsCreating(globalState) {
  const state = selectLocalState(globalState);
  return state.isCreating;
}

export function selectAll(globalState) {
  const state = selectLocalState(globalState);
  return state.all;
}

export const selectByItemId = createCachedSelector(
  selectLocalState,
  (globalState, itemId) => itemId,
  (state, itemId) => state.all.filter((template) => template.item_id === itemId) || []
)((globalState, itemId) => itemId || '');
