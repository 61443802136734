import * as types from './actionTypes';

// Create
// ----------------------------------------------------------------------------

export function requestCreate() {
  return {
    type: types.BLOG_REQUEST_CREATE,
  };
}

// Retrieve list
// ----------------------------------------------------------------------------

export function requestList(siteId) {
  return {
    type: types.BLOGS_REQUEST_LIST,
    payload: { siteId },
  };
}

export function requestListIfNeeded(siteId) {
  return {
    type: types.BLOGS_REQUEST_LIST_IF_NEEDED,
    payload: { siteId },
  };
}

export function receiveList(blogIds, entities, receivedAt) {
  return {
    type: types.BLOGS_RECEIVE_LIST,
    payload: { blogIds, entities, receivedAt },
  };
}

// Retrieve
// ----------------------------------------------------------------------------

export function requestIfNeeded(blogId) {
  return {
    type: types.BLOG_REQUEST_IF_NEEDED,
    payload: { blogId },
  };
}

export function request(blogId) {
  return {
    type: types.BLOG_REQUEST,
    payload: { blogId },
  };
}

export function receive(blog) {
  return {
    type: types.BLOG_RECEIVE,
    payload: {
      blogId: blog.id,
      blog,
    },
  };
}

export function requestUpdate(blogId, ops) {
  return {
    type: types.BLOG_REQUEST_UPDATE,
    payload: { blogId, ops },
  };
}

export function willRequestUpdate(blogId, initiatorAction) {
  return {
    type: types.BLOG_WILL_REQUEST_UPDATE,
    payload: { blogId, initiatorAction },
  };
}

// Update
// ----------------------------------------------------------------------------

export function receiveUpdateSuccess(blogId) {
  return {
    type: types.BLOG_RECEIVE_UPDATE_SUCCESS,
    payload: { blogId },
  };
}

export function receiveUpdateError(blogId, errors) {
  return {
    type: types.BLOG_RECEIVE_UPDATE_ERROR,
    payload: { blogId, errors },
  };
}

// Autosave
// ----------------------------------------------------------------------------

export function addAutosaveOps(blogId, ops) {
  return {
    type: types.BLOG_ADD_AUTOSAVE_OPS,
    payload: { blogId, ops },
  };
}

export function removeAutosaveOps(blogId, ops) {
  return {
    type: types.BLOG_REMOVE_AUTOSAVE_OPS,
    payload: { blogId, ops },
  };
}

// Publish
// ----------------------------------------------------------------------------

export function requestPublish(blogId) {
  return {
    type: types.BLOG_REQUEST_PUBLISH,
    payload: { blogId },
  };
}

export function receivePublishSuccess(blogId) {
  return {
    type: types.BLOG_RECEIVE_PUBLISH_SUCCESS,
    payload: { blogId },
  };
}

export function receivePublishError(blogId, errors) {
  return {
    type: types.BLOG_RECEIVE_PUBLISH_ERROR,
    payload: { blogId, errors },
  };
}

// Discard changes
// ----------------------------------------------------------------------------

export function requestDiscard(blogId) {
  return {
    type: types.BLOG_REQUEST_DISCARD,
    payload: { blogId },
  };
}

// Delete
// ----------------------------------------------------------------------------

export function requestDelete(blogId) {
  return {
    type: types.BLOG_REQUEST_DELETE,
    payload: { blogId },
  };
}

//  Footer block
// ----------------------------------------------------------------------------

export function createFooter(payload, callback) {
  return {
    type: types.BLOG_REQEST_CREATE_FOOTER_ELEMENT,
    payload,
    callback,
  };
}

export function removeFooter(id) {
  return {
    type: types.BLOG_REQEST_REMOVE_FOOTER_ELEMENT,
    payload: id,
  };
}

export function initializeCarouselContainer(currentSection, type, callback) {
  return {
    type: types.BLOG_REQEST_INITIALIZE_FOOTER_CONTAINER,
    payload: { currentSection, type },
    callback,
  };
}

export function initializeABTestContainer(currentSection, type, callback) {
  return {
    type: types.BLOG_FOOTER_INITIALIZE_ABTEST_CONTAINER,
    payload: { currentSection, type },
    callback,
  };
}

export function startABTest(sectionId, blogId, variants, data, callback) {
  return {
    type: types.BLOG_FOOTER_START_AB_TEST,
    payload: { sectionId, blogId, variants, data },
    callback,
  };
}

export function addContentTest(blogId, content) {
  return {
    type: types.BLOG_REQUEST_AB_TEST_STARTED,
    payload: { blogId, content },
  };
}

export function updateContentTest(blogId, content) {
  return {
    type: types.BLOG_REQUEST_AB_TEST_UPDATE,
    payload: { blogId, content },
  };
}

export function requestEnd(contentTest, variantId) {
  return {
    type: types.BLOG_FOOTER_END_AB_TEST,
    payload: { contentTest, variantId },
  };
}
