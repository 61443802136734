import React, { useCallback, useMemo, useState } from 'react';
import classNames from 'classnames';
import capitalize from 'lodash/capitalize';
import get from 'lodash/get';
import isArray from 'lodash/isArray';
import { set, del } from 'object-path-immutable';
import styles from './FormContentEditor.module.scss';
import {
  FormData,
  Field,
  FieldsList,
  FieldListCoordinates,
  StrictFieldsList,
  PlaceholderCoords,
  CreateField,
  MoveField,
  CancelDrag,
  NewContactField,
  NewGenericField,
  RowOrFieldId,
  NewFormData,
} from './types';
import { NewFields } from './NewFields';
import { FieldEditor } from './FieldEditor';
import { FieldsListEditor } from './FieldsListEditor';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import { insertItemIntoFieldsList } from './utils';
import FormSettings from './FormSettings';

import { ReactComponent as SvgIconClose } from '../../../assets/images/icon-cancel.svg';

import { selectNotificationBarIsOut } from 'reducers/notificationReducer';
import { useSelector } from 'react-redux';
import { useMatch } from 'react-router';
import * as accountUsersSelector from 'reducers/accountUsersReducer';

interface MatchProps {
  urlSite: string;
}

interface FormContentEditorProps {
  value: NewFormData | FormData;
  activeTab: string;
  onChange: (value: NewFormData | FormData) => void;
}

export function FormContentEditor({ value: formData, activeTab = 'fields', onChange }: FormContentEditorProps) {
  const [tab, setTab] = useState<string>(activeTab);
  const match = useMatch('/:urlSite/forms/:formId?');
  const notificationBarIsOut = useSelector((state) => selectNotificationBarIsOut(state, match.params.urlSite));
  const sideBarClassNames = useMemo(() => {
    return classNames(styles.sideBar, {
      [styles.notificationBarIsOut]: notificationBarIsOut,
    });
  }, [notificationBarIsOut]);

  // Get the fields list from the form content and normalize it, wrapping any
  // top-level naked field object in a row array.
  const fieldsList: StrictFieldsList = useMemo(() => {
    const rawFieldsList: FieldsList = get(formData, 'content.fields', []);
    return normalizeFieldsList(rawFieldsList);
  }, [formData]);

  const handleFieldsListChange = useCallback(
    (newValue: any) => {
      onChange(set(formData, 'content.fields', newValue));
    },
    [formData, onChange]
  );

  // Manage state for an actively edited field.
  const [edittingFieldCoords, setEdittingFieldCoords] = useState<FieldListCoordinates>();

  const edittingField: Field | undefined = useMemo(() => {
    if (!edittingFieldCoords) return;
    const [i, j] = edittingFieldCoords;
    return get(fieldsList, [i, j]);
  }, [edittingFieldCoords, fieldsList]);

  function updateField(newField: Field) {
    if (!edittingFieldCoords) return;
    const [i, j] = edittingFieldCoords;
    const newFieldsList = set(fieldsList, `${i}.${j}`, newField);
    handleFieldsListChange(newFieldsList);
  }

  // Manage placeholder state
  const [placeholderCoords, setPlaceholderCoords] = useState<PlaceholderCoords | undefined>();

  // Add new field
  const createField: CreateField = (field) => {
    // If the new field already has an ID, that means it is a contact field, so
    // the ID must be preserved.
    if ((field as NewContactField).id) {
      const newField = field as NewContactField;

      // Make sure that ID doesn't exist anywhere else in the form
      if (checkFieldIdExists(fieldsList, newField.id)) {
        // That contact field is already being used, so cancel the drag.
        cancelDrag();
      } else {
        // All good. Insert the new field.
        const newFieldsList: StrictFieldsList = insertItemIntoFieldsList(fieldsList, placeholderCoords, newField);
        handleFieldsListChange(newFieldsList);
      }
    } else {
      const newField = field as NewGenericField;

      // Generate a new id and name that's unique within the form.
      const id = createNewUniqueFieldId(fieldsList);
      const name = `${newField.name}_${id}`;

      // Insert new field
      const newFieldWithId: Field = {
        ...newField,
        id: id,
        name: name,
      };

      const newFieldsList: StrictFieldsList = insertItemIntoFieldsList(fieldsList, placeholderCoords, newFieldWithId);
      handleFieldsListChange(newFieldsList);
    }

    cancelDrag();
  };

  // Move existing field
  const moveField: MoveField = (oldCoords) => {
    let [i1, j1] = oldCoords;
    let [i2, j2] = placeholderCoords;

    if (j1 === undefined) {
      // It's a row

      let newFieldsList = insertItemIntoFieldsList(fieldsList, [i2], 'NEW_ROW');
      const row = newFieldsList[i1];
      newFieldsList = set(newFieldsList, i2.toString(), row);
      newFieldsList = del(newFieldsList, i1.toString());
      handleFieldsListChange(newFieldsList);
    } else {
      // It's a field

      let newFieldsList = fieldsList;

      if (j2 === undefined) {
        newFieldsList = insertItemIntoFieldsList(fieldsList, [i2], ['NEW_FIELD']);

        j2 = 0;
      } else {
        newFieldsList = insertItemIntoFieldsList(fieldsList, [i2, j2], 'NEW_FIELD');
      }

      const field = newFieldsList[i1][j1];
      newFieldsList = set(newFieldsList, [i2.toString(), j2.toString()], field);
      newFieldsList = del(newFieldsList, [i1.toString(), j1.toString()]);
      newFieldsList = newFieldsList.filter((row) => row.length);
      handleFieldsListChange(newFieldsList);
    }
  };

  // Cancel drag
  const cancelDrag: CancelDrag = () => {
    setPlaceholderCoords(undefined);
  };

  const customStyles: FormData['content']['styles'] = get(formData, 'content.styles', {});

  return (
    <DndProvider backend={HTML5Backend}>
      <div className={styles.container}>
        <div className={styles.formContainer}>
          <FieldsListEditor
            value={fieldsList}
            customStyles={customStyles}
            onChange={handleFieldsListChange}
            editField={setEdittingFieldCoords}
            placeholderCoords={placeholderCoords}
            setPlaceholderCoords={setPlaceholderCoords}
            moveField={moveField}
            cancelDrag={cancelDrag}
            close={() => setEdittingFieldCoords(undefined)}
            edittingFieldCoords={edittingFieldCoords}
          />
        </div>
        <div className={sideBarClassNames} data-test-id="sidebar">
          {edittingField ? (
            <div className={styles.fieldLabel}>
              <span>{capitalize(edittingField.type)} field</span>
              <span onClick={() => setEdittingFieldCoords(undefined)}>
                <SvgIconClose />
              </span>
            </div>
          ) : (
            <div className={styles.tabs} data-test-id="tabs">
              <span
                onClick={(): void => setTab('fields')}
                className={classNames({ [styles.active]: tab === 'fields' })}
              >
                Fields
              </span>
              <span
                onClick={(): void => setTab('settings')}
                className={classNames({ [styles.active]: tab === 'settings' })}
              >
                Settings
              </span>
            </div>
          )}
          {tab === 'fields' || edittingField ? (
            edittingField ? (
              <FieldEditor
                close={() => setEdittingFieldCoords(undefined)}
                value={edittingField}
                onChange={updateField}
                removeField={() => {
                  const [i, j] = edittingFieldCoords;
                  handleFieldsListChange(del(fieldsList, `${i}.${j}`));
                  setEdittingFieldCoords(undefined);
                }}
              />
            ) : (
              <NewFields createField={createField} cancelDrag={cancelDrag} existingFieldsList={fieldsList} />
            )
          ) : (
            <FormSettings value={formData} onChange={onChange} />
          )}
        </div>
      </div>
    </DndProvider>
  );
}

function createNewUniqueFieldId(fieldsList: StrictFieldsList) {
  // Create a new id (number) that doesn't exist in any fields yet. Prefer low
  // numbers.
  for (let id = 1; id < 100; id++) {
    if (!checkFieldIdExists(fieldsList, id)) {
      return id;
    }
  }

  throw new Error('No unique form field IDs available under 100.');
}

// function findFieldAndCoords(
//   fieldsList: StrictFieldsList,
//   id: RowOrFieldId
// ): [Field, FieldListCoordinates] {
//   const isRowId = isString(id);

//   for (let i = 0; i < fieldsList.length; i++) {
//     const row = fieldsList[i];

//     if (isRowId) {
//       const firstFieldId()
//     } else {
//       for (let j = 0; j < row.length; j++) {
//         const field = row[j];
//         if (field.id === id) {
//           return [field, [i, j]];
//         }
//       }
//     }
//   }
// }

function checkFieldIdExists(fieldsList: StrictFieldsList, id: Field['id']) {
  return fieldsList.some((row) => row.some((field) => field.id === id));
}

// Utility function to help us with reordering the result
const reorder = (list: any[], startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

// Normalize a raw FieldsList into a StrictFieldsList
function normalizeFieldsList(fieldsList: FieldsList): StrictFieldsList {
  let normalizedList = fieldsList.map((fieldOrRow) => (isArray(fieldOrRow) ? fieldOrRow : [fieldOrRow]));

  normalizedList = normalizedList.filter((row) => row.length > 0);

  if (!normalizedList.some((row) => row.some((field) => field.type === 'submit'))) {
    normalizedList.push([{ id: 200, type: 'submit', initial: 'Submit', name: 'submit' }]);
  }

  return normalizedList;
}
