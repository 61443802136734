import { useCallback, useMemo, useState } from 'react';

type Duration = number;
type Trigger = (duration?: Duration) => void;

/**
 * Hook that returns a state that can be triggered into a temporary state, like a showSuccessMessage boolean.
 *
 * Example:
 *
 *     const [shouldShowSuccessMsg, showSuccessMsg] = useEphemeralState(false, true, 5000)
 *     return (
 *       <div>
 *         <button onClick={() => showSuccessMsg()}>Click for success</button>
 *         {shouldShowSuccessMsg && <span>Success!</span>}
 *       </div>
 *     )
 */
export default function useEphemeralState<BaseState, TempState>(
  baseState: BaseState,
  tempState: TempState,
  defaultDuration: Duration
): [BaseState | TempState, Trigger] {
  const [state, setState] = useState<BaseState | TempState>(baseState);

  const trigger: Trigger = useCallback(
    (duration = defaultDuration) => {
      setState(tempState);
      setTimeout(() => setState(baseState), duration);
    },
    [setState, baseState, tempState, defaultDuration]
  );

  return useMemo(() => [state, trigger], [state, trigger]);
}
