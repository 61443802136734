/* eslint-disable jsx-a11y/anchor-is-valid */

import React from 'react';
import { useDispatch } from 'react-redux';
import * as pageActions from '../../../actions/pageActions';
import { useNavigate } from 'react-router-dom';

type Props = {
  pageId: string;
  urlSite: string;
};

function ClonePage(props: Props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleClick = (e: any) => {
    e.preventDefault();

    dispatch(
      pageActions.requestClone(props.pageId, function (pageId: string) {
        navigate(`/${props.urlSite}/editor/${pageId}`);
      })
    );
  };
  return (
    <a href="#" onClick={handleClick}>
      duplicate
    </a>
  );
}

export default ClonePage;
