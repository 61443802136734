import { TextInput } from './TextInput';
import { IntInput } from './IntInput';
import { NumberInput } from './NumberInput';
import { CurrencyInput } from './CurrencyInput';
import { DateInput } from './DateInput';
import { DateTimeInput } from './DateTimeInput';
import { MediaInput } from './MediaInput';
import { ConnectedRelatedTableInput } from './ForiegnKeyInput';
import { TimeInput } from './TimeInput';

export default {
  text: TextInput,
  slug: TextInput, //  TODO: this is just quick temporary way of doing it. Need to come up with a new way in future
  int: IntInput,
  number: NumberInput,
  currency: CurrencyInput,
  date: DateInput,
  datetime: DateTimeInput,
  media: MediaInput,
  foreign_key: ConnectedRelatedTableInput,
  time: TimeInput,
};
