import * as actionTypes from '../actions/actionTypes';
import { createSelector } from 'reselect';
import createCachedSelector from 're-reselect';
import { selectIntegrations, selectSiteIntegrationsBySlug } from './integrationsReducer';
import { selectActiveSite } from './sitesReducer';

// ----------------------------------------------------------------------------
// Reducer
// ----------------------------------------------------------------------------

const initState = {
  isFetching: null,
  error: null,
  list: [],
};

export default function goalsReducer(state = initState, action) {
  switch (action.type) {
    case actionTypes.FONTS_REQUEST_ALL:
      return {
        ...state,
        isFetching: true,
      };

    case actionTypes.FONTS_RECEIVE_ALL: {
      return {
        isFetching: false,
        list: [...action.payload],
      };
    }

    default:
      return state;
  }
}

// ----------------------------------------------------------------------------
// Selectors
// ----------------------------------------------------------------------------

export function selectLocalState(globalState) {
  return globalState.fonts;
}

export const selectIsFetching = createSelector(selectLocalState, (fonts) => fonts.isFetching);

export const selectFonts = createSelector(selectLocalState, (fonts) => fonts.list);

export const selectFontFamilyWithFallback = createCachedSelector(
  selectFonts,
  (state, family) => family,
  (fonts, family) => {
    const fontDefinition = fonts.find((font) => font.family === family);
    if (fontDefinition) return `'${fontDefinition.family}', ${fontDefinition.default}`;
    else return '';
  }
)((_, family) => family);

export const selectFontNameWithFallback = createCachedSelector(
  selectFonts,
  (state, family) => family,
  (fonts, family) => {
    const fontDefinition = fonts.find((font) => font.family === family);
    if (fontDefinition)
      return fontDefinition.source_name ? `${fontDefinition.source_name}` : `${fontDefinition.family}`;
    else return '';
  }
)((_, family) => family);

export const selectFontsImportUrl = createSelector(selectFonts, (fonts) => {
  const families = fonts
    .filter((font) => font.font_source === 'google')
    .map((font) => `${font.family}:300,400,500,700`.replace(/\s/g, '+'))
    .join('|');
  // const families = this.fontFamilies.map(fontName => (`${fontName}:300,400,500,700`).replace(/\s/g, '+')).join('|');
  return `https://fonts.googleapis.com/css?family=${families}`;
});

export const selectFontsTypekitId = createSelector(selectSiteIntegrationsBySlug, (integrations) => {
  if (integrations.typekit) return integrations.typekit[0].config.kit_id;
});
