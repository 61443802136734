/* eslint-disable jsx-a11y/anchor-is-valid */

import React from 'react';
import classnames from 'classnames';
import { BsArrowUpRight } from 'react-icons/bs';
import { compose } from 'redux';
import isEmpty from 'lodash/isEmpty';
import { Search as SearchEngine } from 'js-search';
import Autosuggest from 'react-autosuggest';
import deepEqual from 'fast-deep-equal';
import memoize from 'memoize-one';
import pick from 'lodash/pick';
import { v4 as uuidv4 } from 'uuid';

import withUrlSite from '../../../containers/withUrlSite';
import withForms from '../../../containers/withForms';

import styles from './SmartFormInput.module.scss';
import withIntegrations from '../../../containers/withIntegrations';

const memoizeDeep = (func) => memoize(func, deepEqual);

const FORM_TYPES = {
  EMPTY: 'EMPTY',
  CONTENT_EXISTS: 'CONTENT_EXISTS',
  CONTENT_NOT_FOUND: 'CONTENT_NOT_FOUND',
};

// ----------------------------------------------------------------------------
// Component
// ----------------------------------------------------------------------------

class SmartFormImput extends React.PureComponent {
  autosuggestId = uuidv4();
  ref = React.createRef();
  inputRef = React.createRef();
  mountedRef = React.createRef(); // componentDidMount does not get "value" prop, its come in updated phase, that is why introduce this.

  state = {
    formsuggestions: [],
    value: '', // search query for suggestion items.
    oldValue: '', // To keep track of previously selected value. (selectedValue)
  };

  // utils
  redirectToFormCreation = () => {
    window.open(`/${this.props.urlSite}/forms/new`, '_blank');
  };

  componentDidUpdate(prevProps, prevState) {
    if (!this.props.value && this.props.value !== prevProps.value) {
      this.setState({
        formsuggestions: this.getSearchDocuments(),
      });
    }

    // update local state by props
    if (!this.mountedRef.current && this.props.formId && this.props.value) {
      this.setState((prevState) => ({
        ...prevState,
        value: this.props.value,
        oldValue: this.props.value,
      }));

      this.mountedRef.current = 'mounted';
    }
  }

  //--------------------------------------------------------------------------
  // Utils
  //--------------------------------------------------------------------------
  openInNewTab(url) {
    const win = window.open(url, '_blank');
    if (win != null) {
      win.focus();
    }
  }

  redirectToFormCreation() {
    this.openInNewTab(`/${this.props.urlSite}/forms/new`, '_blank');
  }

  // --------------------------------------------------------------------------
  // Input and button event handlers
  // --------------------------------------------------------------------------

  handleClear = (e) => {
    this.setState({
      isFocused: true,
      value: '', // search query should be empty.
    });
  };

  handleChange = (e) => {
    this.setState({
      value: e.target.value,
    });
  };

  // If the user hits `enter`, don't bubble the event. Otherwise it may submit
  // the form when the user expects it to select an autosuggestion.
  handleKeyDown = (e) => {
    if (e.which === 13) e.preventDefault();
  };

  // --------------------------------------------------------------------------
  // Autosuggest event handlers
  // --------------------------------------------------------------------------

  handleSuggestionSelected = (e, { suggestionValue }) => {
    e.preventDefault();

    this.props.onSuggestionSelect(this.props.formsByName[suggestionValue.name]);
    this.props.onChange(`${suggestionValue.name}`);

    this.setState({
      isFocused: false,
      value: suggestionValue.name,
      oldValue: suggestionValue.name,
    });
  };

  handleSuggestionsFetchRequested = ({ value }) => {
    value
      ? this.setState({ formsuggestions: this.searchForms(value) })
      : this.setState({ formsuggestions: this.getSearchDocuments() });
  };

  handleSuggestionsClearRequested = () => {
    this.setState({ formsuggestions: [] });
  };

  // --------------------------------------------------------------------------
  // Search
  // --------------------------------------------------------------------------

  getSearchEngine = memoizeDeep((forms) => {
    console.log('creating new search engine');
    const engine = new SearchEngine('name');
    engine.addIndex('name');
    engine.addIndex('id');
    engine.addDocuments(forms);
    return engine;
  });

  getSearchDocuments = () => {
    const searchDocuments = [];

    Object.values(this.props.formsByName).forEach((form) => {
      const searchDocument = pick(form, ['name', 'id']);
      searchDocument.type = 'form';
      searchDocuments.push(searchDocument);
    });

    return searchDocuments;
  };

  searchForms(value) {
    const searchDocuments = this.getSearchDocuments();
    const searchEngine = this.getSearchEngine(searchDocuments);

    return searchEngine.search(value);
  }

  // --------------------------------------------------------------------------
  // Content and its type
  // --------------------------------------------------------------------------

  getContentFromSmartName() {
    const { value, formsByName } = this.props;
    return formsByName[value] || null;
  }

  formType() {
    const { value } = this.props;

    if (isEmpty(value)) {
      return FORM_TYPES.EMPTY;
    }

    const matchedContent = this.getContentFromSmartName();
    if (matchedContent) return FORM_TYPES.CONTENT_EXISTS;
    else if (matchedContent == null) return FORM_TYPES.CONTENT_NOT_FOUND;
    else return FORM_TYPES.UNKNOWN;
  }

  // --------------------------------------------------------------------------
  // Render
  // --------------------------------------------------------------------------

  renderSuggestion = (form, options = {}) => {
    return (
      <div
        className={classnames(styles.suggestionItem, {
          [styles.selected]: form.name === this.state.oldValue,
        })}
      >
        <div className={styles.suggestionName}>{form.name}</div>
      </div>
    );
  };

  renderInputComponent = (inputProps) => {
    return (
      <div className={styles.inputContainer}>
        <input {...inputProps} />
      </div>
    );
  };

  renderSuggestionsContainer = ({ containerProps, children }) => {
    const urlSite = this.props.urlSite;

    if (!this.props.isSparkForm) {
      return <div {...containerProps}>{children}</div>;
    }

    return (
      <div {...containerProps}>
        <div className={styles.suggestionsListContainer} data-test-id="smart-form-input-suggestions">
          {children}
        </div>

        <div className={styles.newFormLinkContainer}>
          <hr style={{ margin: '10px 0px' }} />
          <p>
            <a href={`/${urlSite}/forms/new`} target="_blank">
              Create a new form
            </a>
            <BsArrowUpRight />
          </p>
        </div>
      </div>
    );
  };

  render() {
    const { validationMessage, formId, isSparkForm } = this.props;
    const { formsuggestions, isFocused } = this.state;

    const urlSite = this.props.urlSite;

    const formType = this.formType();

    let isContent, content;
    if (!this.state.isFocused) {
      isContent = formType === FORM_TYPES.CONTENT_EXISTS;

      if (isContent) content = this.getContentFromSmartName();
    }

    const placeholder = isSparkForm ? 'Select form' : 'Give it a name or search for an existing form';

    const inputProps = {
      ref: this.inputRef,
      placeholder,
      onFocus: () => this.setState({ isFocused: true }),
      onBlur: () => {
        const content = this.getContentFromSmartName();
        if (content) {
          this.props.onSuggestionSelect(this.props.formsByName[content.name]);
          this.props.onChange(`${content.name}`);

          this.setState((prevState) => ({
            ...prevState,
            value: content.name,
            oldValue: content.name,
          }));
        }
        this.setState({ isFocused: false });
      },
      autoFocus: isFocused,
      onKeyDown: this.handleKeyDown,
      onChange: this.handleChange,
      value: this.state.value || '',
    };

    return (
      <div className={styles.wrapper}>
        {isContent && (
          <div className={styles.tagContainer} onClick={this.handleClear}>
            <div className={styles.tag} data-test-id="smart-form-input-tag">
              <span className={styles.tagName}>{content.name}</span>
            </div>
          </div>
        )}
        {!isContent && (
          <div className={styles.container} ref={this.ref}>
            <Autosuggest
              id={this.autosuggestId}
              theme={styles}
              // alwaysRenderSuggestions
              suggestions={formsuggestions}
              renderSuggestion={this.renderSuggestion}
              renderInputComponent={this.renderInputComponent}
              renderSuggestionsContainer={this.renderSuggestionsContainer}
              getSuggestionValue={(form) => form}
              onSuggestionSelected={this.handleSuggestionSelected}
              onSuggestionsFetchRequested={this.handleSuggestionsFetchRequested}
              onSuggestionsClearRequested={this.handleSuggestionsClearRequested}
              shouldRenderSuggestions={(value) => {
                if (!isSparkForm) value.trim().length >= 2;
                return true;
              }}
              inputProps={inputProps}
            />
          </div>
        )}

        {validationMessage && <div style={{ marginTop: '-2.5rem' }}>{validationMessage}</div>}

        {formId && isSparkForm && (
          <div className={styles.formSettingLinks}>
            <a href={`/${urlSite}/forms/${formId}?tab=settings`} target="_blank">
              Settings
            </a>
            <a href={`/${urlSite}/forms/${formId}`} target="_blank">
              Open form designer
              <div>
                <BsArrowUpRight className={styles.suggestionNewFormIcon} />
              </div>
            </a>
          </div>
        )}
      </div>
    );
  }
}

export default compose(withForms, withUrlSite)(SmartFormImput);
export const SmartIntegrationFormInput = compose(withIntegrations, withUrlSite)(SmartFormImput);
