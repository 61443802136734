import React from 'react';
import { useSelector } from 'react-redux';

import Tab from '../../Tabs';
import FixedHeader from '../../FixedHeader';
import GoogleConsole from './GoogleConsole';

import { selectHasPermission } from '../../../../reducers/policyReducer';
import CheckPermissionOnClick from '../../../SubscriptionManager/CheckPermissionOnClick';

export const TABS_OPTIONS = {
  'Google Search Console': [GoogleConsole, 'Analytics:google_console'],
};

export default function Tabs({ currentTab, updateTab }) {
  const hasPermission = useSelector((state) => selectHasPermission(state));
  return (
    <FixedHeader>
      {Object.entries(TABS_OPTIONS).map((tab, i) =>
        hasPermission(tab[1][1], 'role') ? (
          <CheckPermissionOnClick
            key={i}
            action={tab[1][1]}
            onClick={() => updateTab(tab[0])}
            render={(handleClick, hasPermission) => (
              <Tab label={tab[0]} isSelected={currentTab === tab[0]} updateTab={handleClick} />
            )}
          />
        ) : (
          <React.Fragment key={i}></React.Fragment>
        )
      )}
    </FixedHeader>
  );
}
