import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getResources, getStatus } from 'redux-resource';
import defaults from 'lodash/defaults';

const DEFAULT_OPTIONS = {
  forceFetch: false,
};

export function useResources(resourceType, filter, options) {
  const dispatch = useDispatch();

  // Merge default options
  const optionsWithDefaults = defaults({ ...options }, DEFAULT_OPTIONS);

  // Destructure the options we need. The rest belong to the `getResources`
  // function.
  const { forceFetch, mergeResources, mergeMeta, mergeListIds, ...getResourcesOptions } = optionsWithDefaults;

  useEffect(() => {
    const actionType = forceFetch ? 'READ_RESOURCES_PENDING' : 'READ_RESOURCES_REQUEST_IF_NEEDED';
    dispatch({
      type: actionType,
      resourceType,
      requestKey: 'fetch',
      list: filter,
      mergeResources,
      mergeMeta,
      mergeListIds,
    });
  }, []); /* eslint-disable-line react-hooks/exhaustive-deps */

  return useSelector((state) => {
    return getResources(state[resourceType], filter, getResourcesOptions);
  });
}

export function useStatus(statusLocation, treatIdleAsPending) {
  return useSelector((state) => {
    return getStatus(state, statusLocation, treatIdleAsPending);
  });
}
