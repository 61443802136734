import React from 'react';

import styles from './BlogsGridLayoutSkeleton.module.scss';

export default (props) => {
  const { articlesCount } = props;
  return (
    <div className={styles.container} data-test-id="blogs-grid-layout-skeleton-container">
      <div className={styles.firstItem} data-test-id="grid-skeleton-banner">
        <div className={styles.imageHolder} />
        <div className={styles.text}>
          <label>Latest story</label>
          <div className={styles.largeTextSkeleton} />
          <div className={styles.smallTextSkeleton} />
        </div>
      </div>
      <div className={styles.row} data-test-id="grid">
        {[...Array(articlesCount - 1).keys()].map((item) => (
          <div key={item} className={styles.item}>
            <div className={styles.imageHolder} />
            <label>Next latest story</label>
            <div className={styles.largeTextSkeleton} />
            <div className={styles.smallTextSkeleton} />
          </div>
        ))}
      </div>
    </div>
  );
};
