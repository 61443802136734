/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { ReactSortable } from 'react-sortablejs';
import omit from 'lodash/omit';
import idx from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import classnames from 'classnames';
import { arrayMove } from '../../../../lib/SortListHoc';

import withPermissions from '../../../../containers/withPermissions';

import SiteLogo from '../../../Editor/SiteLogo';
import MenuItem from './MenuItem';
import ContentEditable from '../../../Editor/ContentEditable';
import SocialMediaIcons from './SocialMediaIcons';
import Toolbar from './FooterToolbar';
import LogoUploader from './LogoUploader';
import SectionItemsOptions from '../../../Editor/Sections/SectionItemsOptions';
import { ReactComponent as SvgAddItem } from '../../../../assets/images/icon-add-section.svg';
import { ReactComponent as SvgAddAction } from '../../../../assets/images/icons8-plus.svg';
import { ReactComponent as SvgIconDrag } from '../../../../assets/images/arrow-all-thick.svg';
import RichTextEditor from '../../../../lib/quill/components/RichTextEditor';
import * as siteLayoutSelector from 'reducers/siteLayoutReducer';
import Container from 'components/unstack-components/Component';

import styles from '../HeaderFooterEditor.module.scss';

const FooterLink = ({ autoFocus, childIndex, childItem, placeholder, propPath, site, siteUpdaters }) => {
  const [showDragIcon, setShowDragIcon] = useState(false);
  return (
    <li
      key={childIndex}
      style={{
        position: 'relative',
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'space-between',
        minHeight: '22px',
      }}
      onMouseEnter={() => setShowDragIcon(true)}
      onMouseLeave={() => setShowDragIcon(false)}
    >
      <MenuItem
        item={childItem}
        propPath={propPath}
        siteUpdaters={siteUpdaters}
        placeholder={placeholder}
        autoFocus={autoFocus}
      />
      {showDragIcon && (
        <SectionItemsOptions
          brightnessTheme={idx(site, 'styles.headerBrightnessTheme')}
          DragHandle={
            <div className="drag-handle">
              <SvgIconDrag width={11} height={11} />
            </div>
          }
          iconHeight={7}
          iconWidth={8}
          showTrash={false}
          decreaseDimensions
          style={{ position: 'initial' }}
        />
      )}
    </li>
  );
};

class FooterEditor extends React.PureComponent {
  propPath = ['menus', 'footer'];

  state = { autoFocus: false };

  componentDidUpdate(prevProps, prevState) {
    // Remove autofocus after it's been used
    if (this.state.autoFocus && this.state.autoFocus === prevState.autoFocus) this.setState({ autoFocus: false });

    // Remove menu columns that have no children
    const footer_menu = this.props.site.menus.footer;
    if (prevProps.site.menus.footer !== footer_menu && footer_menu.items && footer_menu.items.forEach) {
      footer_menu.items.forEach((item, index) => {
        if (isEmpty(item.items)) this.props.siteUpdaters.del(this.propPath.concat('items', index));
      });
    }
  }

  addColumn = () => {
    this.props.siteUpdaters.unshift(this.propPath.concat('items'), {
      items: [{}],
    });
    this.setState({ autoFocus: 'menus.footer.items' });
  };

  addColumnItem = (index) => {
    this.props.siteUpdaters.push(this.propPath.concat('items', index, 'items'), {});
    this.setState({ autoFocus: `menus.footer.items.${index}` });
  };

  render() {
    const { site, siteUpdaters, hasPermission, editableLogo } = this.props;
    const items = idx(site, 'menus.footer.items', []);
    const hideSparkBranding = idx(site, 'elements.hideSparkBranding', false);
    const showAddColumn = items.length < 4;
    const footerBrightnessTheme = idx(site, 'styles.footerBrightnessTheme');

    const renderedMenuItems = items.map((item, index) => {
      const childItems = item.items;

      return (
        <div key={items.length - index} className="menu">
          <ContentEditable
            tag="h4"
            value={item.text}
            placeholder="Group header"
            onInput={(value) => {
              siteUpdaters.set(this.propPath.concat('items', index, 'text'), value);
            }}
            autoFocus={this.state.autoFocus === 'menus.footer.items' && index === 0}
          />
          {childItems && (
            <ReactSortable
              animation={150}
              tag="ul"
              chosenClass="sortable-chosen"
              ghostClass="sortable-ghost"
              dragClass="sortable-drag"
              handle=".drag-handle"
              setList={() => {}}
              onEnd={(event) => {
                const data = childItems.map((item) => omit(item, 'chosen'));
                this.props.siteUpdaters.set(
                  ['menus', 'footer', 'items', index, 'items'],
                  arrayMove(data, event.oldIndex, event.newIndex)
                );
              }}
              list={childItems}
            >
              {childItems.map((childItem, childIndex) => (
                <FooterLink
                  key={childIndex}
                  site={site}
                  childIndex={childIndex}
                  childItem={childItem}
                  propPath={this.propPath.concat('items', index, 'items', childIndex)}
                  siteUpdaters={siteUpdaters}
                  placeholder="Link"
                  autoFocus={
                    this.state.autoFocus === `menus.footer.items.${index}` && childIndex === childItems.length - 1
                  }
                />
              ))}
            </ReactSortable>
          )}
          <SvgAddAction
            className={styles.AddFooterColumnItem}
            role="button"
            onClick={() => this.addColumnItem(index)}
          />
        </div>
      );
    });

    return (
      <footer className={classnames('main-footer', styles.footerIndexing, footerBrightnessTheme || 'dark-bg')}>
        <section className="primary-footer">
          <div className={classnames('container', styles.containerIndexing)}>
            <div className="info">
              <a disabled className="logo">
                {editableLogo && <LogoUploader site={site} siteUpdaters={siteUpdaters} location="footer" />}
                {!editableLogo && <SiteLogo site={site} placement="footer" />}
              </a>
              <div className="contact-info">
                <RichTextEditor
                  placeholder="Address"
                  onChange={(value) => {
                    siteUpdaters.set('content.contact_info', value);
                  }}
                  value={idx(site, 'content.contact_info')}
                />
              </div>
            </div>
            <div className="menus">
              {showAddColumn && (
                <div className={styles.AddFooterColumnWrapper}>
                  <SvgAddItem className={styles.AddFooterColumn} role="button" onClick={this.addColumn} />
                </div>
              )}
              {renderedMenuItems}
            </div>
          </div>
        </section>
        <section className="secondary-footer">
          <div className="container">
            <div className="copyright">
              © {site.name}
              {(hasPermission('Site:debadge', 'subscription') && hideSparkBranding) || (
                <>
                  {' '}
                  &bull; Made with{' '}
                  <a href="https://www.elasticpath.com/products/cx-studio" target="_blank" rel="noopener noreferrer">
                    Elastic Path Studio
                  </a>
                </>
              )}
            </div>
            <SocialMediaIcons
              propPath={this.propPath.concat('social')}
              siteIcons={idx(site, 'menus.footer.social', {})}
              siteUpdaters={siteUpdaters}
              brightnessTheme={footerBrightnessTheme}
            />
          </div>
        </section>
        <Toolbar site={this.props.site} siteUpdaters={this.props.siteUpdaters} />
      </footer>
    );
  }
}

export default withPermissions(FooterEditor);
