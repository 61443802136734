import React from 'react';
import styles from './Quote.module.scss';
import profile from '../../../assets/images/signup/saptarshi-nath.jpeg';
import { ReactComponent as SvgIconQuote } from '../../../assets/images/icon-quote-big.svg';

function CreateAccountQuote(props) {
  return (
    <div className={styles.container}>
      <div className={styles.attribution}>
        <img className={styles.profile} src={profile} alt="" />
        <div className={styles.details}>
          <div className={styles.name}>Saptarshi Nath</div>
          <div className={styles.title}>Co-founder, Airboxr</div>
        </div>
        <SvgIconQuote className={styles.iconQuote} />
      </div>
      <div className={styles.quote}>
        We are a small team and Unstack has really helped us run experiments faster. Getting a new landing page or an
        A/B test running takes only a few minutes with Unstack. Plus, the team is super-helpful and proactive with
        incorporating feedback.
      </div>
    </div>
  );
}

export default CreateAccountQuote;
