/* eslint-disable jsx-a11y/anchor-is-valid */

import React from 'react';
import { compose } from 'redux';
import withSiteId from '../../../../containers/withSiteId';
import { connect } from 'react-redux';
import idx from 'lodash/get';

import CheckPermissionOnClick from '../../../SubscriptionManager/CheckPermissionOnClick';
import ZapierDrawer from './ZapierDrawer';

import { selectSiteIntegrationsByType } from '../../../../reducers/integrationsReducer';

import styles from '../../Integrations.module.scss';

import iconZapier from '../../../../assets/images/icon-zapier.jpg';
import { ReactComponent as SvgIconAdd } from '../../../../assets/images/icon-add-section.svg';

const mapStateToProps = (state, ownProps) => {
  const integrations = selectSiteIntegrationsByType(state, ownProps.siteId, ownProps.type);
  return {
    siteId: ownProps.siteId,
    integration: idx(integrations, 0),
  };
};

export class Zapier extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
    };
  }

  render() {
    const { isOpen } = this.state;
    const { integration, type } = this.props;
    const isEmpty = !integration;

    return (
      <>
        <li className={styles.integration}>
          <img className={styles.icon} src={iconZapier} alt="" />
          <div className={styles.title}>Zapier</div>
          <div className={styles.config}>
            {isEmpty && (
              <CheckPermissionOnClick
                action="SiteIntegration:create:::zapier"
                onClick={() => this.setState({ isOpen: true })}
                render={(handleClick, hasPermission) =>
                  hasPermission ? (
                    <SvgIconAdd className={styles.add} onClick={handleClick} role="button" />
                  ) : (
                    <button onClick={handleClick} className="button button-primary button-sm">
                      Upgrade
                    </button>
                  )
                }
              />
            )}
            {!isEmpty && (
              <>
                <label>
                  <span className={styles.statusIndicator} />
                  Connected
                </label>{' '}
                <a
                  href=""
                  onClick={(e) => {
                    e.preventDefault();
                    this.setState({ isOpen: true });
                  }}
                  className="button button-sm"
                >
                  Edit
                </a>
              </>
            )}
          </div>
          {isOpen && <ZapierDrawer isOpen={isOpen} close={() => this.setState({ isOpen: false })} id={type} />}
        </li>
      </>
    );
  }
}

export default compose(withSiteId, connect(mapStateToProps))(Zapier);
