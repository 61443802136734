import React from 'react';
import escapeRegExp from 'lodash/escapeRegExp';

export default (text: string, highlight: string) => {
  if (!text) return '';
  if (!highlight) return text;

  const escapedHighlight = escapeRegExp(highlight).replace(/\s+/g, '|');
  const searchRegExp = new RegExp(`(${escapedHighlight})`, 'gi');
  const parts = text.split(searchRegExp);

  return (
    <span>
      {parts.map((part, i) =>
        searchRegExp.test(part) ? (
          <span key={i} style={{ backgroundColor: 'rgba(20,149,225,0.2)' }}>
            {part}
          </span>
        ) : (
          part
        )
      )}
    </span>
  );
};
