import React, { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';

import { arrayMove } from 'lib/SortListHoc';
import { set, get, del, insert, push, assign } from 'object-path-immutable';
import { ReactSortable } from 'react-sortablejs';

import { CollectionTagArgs } from 'components/unstack-components/tag-types';
import { SectionHandlersInterface, ContainerInfo } from 'components/unstack-components/types';
import { getItemKey } from '../../util/utils';
import { USectionDevices } from 'types/USection';
import { getDeviceTypeToSaveTo } from '../../util/helpers/deviceHelper';
import { useSelector } from 'react-redux';
import { getDevice } from 'reducers/uiReducer';
import { setActiveToolbar } from 'actions/toolbarActions';
import { isEqual } from 'lodash';

interface CollectionGeneratorProps extends CollectionTagArgs {
  children?: React.ReactChildren;
  sectionHandlers?: SectionHandlersInterface;
  containerInfo?: ContainerInfo;
  defaults: any;
  content: USectionDevices;
  onChange: (content: USectionDevices, key?: string, multi?: boolean) => void;
}

function CollectionGenerator(props: CollectionGeneratorProps) {
  const {
    item,
    tagName,
    minItems,
    onChange,
    className,
    dataRef,
    contentKey,
    defaultItems,
    sectionHandlers,
    containerInfo,
    defaults = {},
    content,
  } = props;

  const [isDragging, setIsDragging] = React.useState(false);
  const dispatch = useDispatch();

  const value = props.dataRef || [];
  const key = containerInfo ? getItemKey(containerInfo, contentKey) : '';
  const splitKey = (key || contentKey).split('.');
  const device = useSelector(getDevice);

  const handleInsert = (i: number) => {
    const updatedContent = set(
      content as any,
      [getDeviceTypeToSaveTo(device, true), ...splitKey.slice(1)],
      insert(value, null, null, i + 1)
    );
    const valueMobile = get(updatedContent, [getDeviceTypeToSaveTo('mobile', false), ...splitKey.slice(1)]);
    const updatedContentMobile = set(
      updatedContent,
      [getDeviceTypeToSaveTo('mobile', false), ...splitKey.slice(1)],
      insert(valueMobile, null, null, i + 1)
    );
    const valueTablet = get(updatedContentMobile, [getDeviceTypeToSaveTo('tablet', false), ...splitKey.slice(1)]);
    const updatedContentTablet = set(
      updatedContentMobile,
      [getDeviceTypeToSaveTo('tablet', false), ...splitKey.slice(1)],
      insert(valueTablet, null, null, i + 1)
    );
    if (updatedContentTablet !== content) {
      onChange(updatedContentTablet, `content.${splitKey[0]}`);
    }
  };

  const handleRemove = (i: number) => {
    const updatedContent = set(
      content as any,
      [getDeviceTypeToSaveTo(device, true), ...splitKey.slice(1)],
      del(value, i as unknown as Path)
    );
    const valueMobile = get(updatedContent, [getDeviceTypeToSaveTo('mobile', false), ...splitKey.slice(1)]);
    const updatedContentMobile = set(
      updatedContent,
      [getDeviceTypeToSaveTo('mobile', false), ...splitKey.slice(1)],
      del(valueMobile, i as unknown as Path)
    );
    const valueTablet = get(updatedContentMobile, [getDeviceTypeToSaveTo('tablet', false), ...splitKey.slice(1)]);
    const updatedContentTablet = set(
      updatedContentMobile,
      [getDeviceTypeToSaveTo('tablet', false), ...splitKey.slice(1)],
      del(valueTablet, i as unknown as Path)
    );
    if (updatedContentTablet !== content) {
      onChange(updatedContentTablet, `content.${splitKey[0]}`);
    }
  };

  useEffect(() => {
    const valueBase = get(content as any, [getDeviceTypeToSaveTo(device, true), ...splitKey.slice(1)]);
    let valueMobile = get(content as any, [getDeviceTypeToSaveTo('mobile', false), ...splitKey.slice(1)]);
    let valueTablet = get(content as any, [getDeviceTypeToSaveTo('tablet', false), ...splitKey.slice(1)]);

    if (valueBase) {
      if (valueMobile && typeof valueMobile === 'object' && !Array.isArray(valueMobile)) {
        valueMobile = Object.values(valueMobile);
      }
      if (valueTablet && typeof valueTablet === 'object' && !Array.isArray(valueTablet)) {
        valueTablet = Object.values(valueTablet);
      }
      if (valueMobile === undefined) {
        valueMobile = [];
        valueBase.forEach(() => {
          valueMobile.push(null);
        });
      }
      if (valueTablet === undefined) {
        valueTablet = [];
        valueBase.forEach(() => {
          valueTablet.push(null);
        });
      }
      valueBase.forEach((value: any, i: number) => {
        if (value !== null) {
          if (valueMobile && valueMobile[i] === null) {
            valueMobile[i] = {};
          }
          if (valueTablet && valueTablet[i] === null) {
            valueTablet[i] = {};
          }
        }
      });

      const updatedContent = set(
        content as any,
        [getDeviceTypeToSaveTo(device, true), ...splitKey.slice(1)],
        valueBase
      );
      const updatedContentMobile = set(
        updatedContent,
        [getDeviceTypeToSaveTo('mobile', false), ...splitKey.slice(1)],
        valueMobile
      );
      const updatedContentTablet = set(
        updatedContentMobile,
        [getDeviceTypeToSaveTo('tablet', false), ...splitKey.slice(1)],
        valueTablet
      );
      if (!isEqual(updatedContentTablet, content)) {
        onChange(updatedContentTablet, `content.${splitKey[0]}`);
      }
    }
  }, [content]);

  // If the number of collection items is less than the minimum, then add them.
  useEffect(() => {
    const minimum = minItems || 1;
    const defaultCount = Math.max(defaultItems || 1, minimum);
    if (value.length >= minimum) return;
    let newValue = value;

    if (!value.length) {
      while (newValue.length < defaultCount) {
        newValue = push(newValue, null, null);
      }

      const updatedContent = set(content as any, [getDeviceTypeToSaveTo(device, true), ...splitKey.slice(1)], newValue);
      const updatedContentMobile = set(
        updatedContent,
        [getDeviceTypeToSaveTo('mobile', false), ...splitKey.slice(1)],
        newValue
      );
      const updatedContentTablet = set(
        updatedContentMobile,
        [getDeviceTypeToSaveTo('tablet', false), ...splitKey.slice(1)],
        newValue
      );
      if (updatedContentTablet !== content) {
        onChange(updatedContentTablet, `content.${splitKey[0]}`);
      }

      return;
    }

    while (newValue.length < minimum) {
      newValue = push(newValue, null, null);
    }

    onChange(
      set(content as any, [getDeviceTypeToSaveTo(device, true), ...splitKey.slice(1)], newValue),
      `content.${splitKey[0]}`
    );
  }, [value, onChange]);

  // // ReactSortableJS requires a list of objects to store its state in.
  const sortableList = useMemo(() => value.map(() => ({})), [value]);

  let renderedCollection = (
    <ReactSortable
      tag={(tagName || defaults.tagName) as keyof React.ReactHTML}
      className={classNames(className, { 'perspective-none': isDragging })}
      animation={150}
      chosenClass="sortable-chosen"
      ghostClass="sortable-ghost"
      dragClass="sortable-drag"
      handle=".drag-handle"
      //  @ts-ignore
      list={sortableList}
      setList={() => {}}
      onEnd={(event) => {
        const newEl = event.from.children[event.newIndex] as HTMLElement;
        const boxuuid =
          newEl?.dataset.boxUuid || (newEl.querySelector('[data-box-uuid]') as HTMLElement)?.dataset.boxUuid;
        dispatch(setActiveToolbar(boxuuid));
        const updatedContent = set(
          content as any,
          [getDeviceTypeToSaveTo(device, true), ...splitKey.slice(1)],
          arrayMove(value, event.oldIndex, event.newIndex)
        );
        const valueMobile = get(updatedContent, [getDeviceTypeToSaveTo('mobile', false), ...splitKey.slice(1)]);
        const updatedContentMobile = set(
          updatedContent,
          [getDeviceTypeToSaveTo('mobile', false), ...splitKey.slice(1)],
          arrayMove(valueMobile, event.oldIndex, event.newIndex)
        );
        const valueTablet = get(updatedContentMobile, [getDeviceTypeToSaveTo('tablet', false), ...splitKey.slice(1)]);
        const updatedContentTablet = set(
          updatedContentMobile,
          [getDeviceTypeToSaveTo('tablet', false), ...splitKey.slice(1)],
          arrayMove(valueTablet, event.oldIndex, event.newIndex)
        );
        if (updatedContentTablet !== content) {
          onChange(updatedContentTablet, `content.${splitKey[0]}`);
        }

        setIsDragging(false);
      }}
      onStart={() => setIsDragging(true)}
    >
      {value.map((listItem: any, i: number) => {
        const newSectionHandlers = {
          ...sectionHandlers,
          collectionHandleInsert: () => handleInsert(i),
          collectionHandleRemove: () => handleRemove(i),
        };

        const childProps = {
          onChange,
          sectionHandlers: { ...newSectionHandlers },
          containerInfo: { contentKey: key || contentKey, index: i },
        };

        return React.cloneElement(item(listItem, i), childProps);
      })}
    </ReactSortable>
  );

  return renderedCollection;
}
export default CollectionGenerator;
