import React from 'react';
import classnames from 'classnames';
import pick from 'lodash/pick';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import { Search as SearchEngine } from 'js-search';
import VisibilitySensor from 'react-visibility-sensor';

import Spinner from '../../Spinner';
import Search from '../../../Search';
import MediaNoResults from './MediaNoResults';
import { FigureItem } from './ImageItem';
import { MediaOptionsDrawer } from './MediaOptionsDrawer';
import { ReactComponent as SvgNoResults } from '../../../../assets/images/icon-no-media-results.svg';
import ProgressBar from '../../ProgressBar';

import styles from './Unstack.module.scss';

export class Unstack extends React.PureComponent {
  constructor(props) {
    super(props);

    this.engine = new SearchEngine('url');

    this.state = {
      altText: props.entity.alt || '',
      link: props.entity.link,
      query: '',
      loop: props.entity.loop,
      controls: props.entity.controls == undefined || props.entity.controls,
      muted: props.entity.muted,
      autoplay: props.entity.autoplay,
      optimization: props.entity.optimization || 'resize-only',
      borderRadius: props.entity.borderRadius,
      boxShadow: props.entity.boxShadow,
      selectedMedia: props.entity.isExternalSrc
        ? props.entity
        : props.entity.src && props.entity.src.includes('media')
        ? { id: props.entity.src.split(':')[1] }
        : {},
      error: {},
      presentMedia:
        props.entity.src && props.entity.src.includes('media') ? { id: props.entity.src.split(':')[1] } : null,
      debouncedQuery: '',
    };
  }

  componentDidMount() {
    this.engine.addIndex('url');
    this.focusElement();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.category !== prevProps.category) {
      this.setState({ selectedMedia: {}, query: '', debouncedQuery: '' });
      this.props.categoryPageOptions.pagesLoaded === 0 && this.onLoadMoreVisibilityChange(true);
    }
    if (this.state.debouncedQuery !== prevState.debouncedQuery) {
      this.props.clearUnstackSearch();
      this.state.debouncedQuery && this.props.searchUnstackMedia(this.state.debouncedQuery, this.props.category);
    }

    if (
      !isEqual(this.state.selectedMedia, prevState.selectedMedia) ||
      !isEqual(this.props.allMedia, prevProps.allMedia)
    ) {
      this.focusElement();
    }
    if (prevProps.isUploading && !this.props.isUploading) {
      !this.props.disableContextualOptions &&
        !this.props.isErrorOnCreate &&
        this.setState({
          selectedMedia: this.props.allMedia[0],
        });
    }
  }

  componentWillUnmount() {
    if (isEmpty(this.state.presentMedia) && this.state.presentMedia !== null) this.props.onChange();
  }

  onLoadMoreVisibilityChange(isVisible) {
    const {
      categoryPageOptions: { pagesLoaded },
      category,
      isLoading,
    } = this.props;
    if (this.props.categoryPageOptions.next && isVisible && !isLoading && !this.state.debouncedQuery) {
      this.props.requestMediaPage(pagesLoaded + 1, category);
    }
  }

  focusElement = () => {
    const node = this.refs.trackerRef;
    node && node.scrollIntoView({ block: 'center', behavior: 'smooth' });
  };

  getRenderableMedia = (allMedia) => {
    const { selectedMedia } = this.state;
    return allMedia
      .sort((a, b) => Date.parse(b.created_at) - Date.parse(a.created_at))
      .map((media, idx) => {
        return (
          <div
            key={media.id}
            className={classnames(styles.imageContainer, {
              [styles.selected]: selectedMedia.id === media.id,
            })}
            ref={selectedMedia.id === media.id ? 'trackerRef' : null}
          >
            <FigureItem
              media={media}
              editAltText={() => this.setState({ altTextEditingField: media.id })}
              category={this.props.category}
              removeMedia={() => {
                this.setState({
                  selectedMedia: media.id === selectedMedia.id ? {} : selectedMedia,
                });
                const promise = new Promise((resolve, reject) => this.props.deleteMedia(media.id, resolve, reject));
                promise
                  .then(() =>
                    this.setState({
                      presentMedia: media.id === this.state.presentMedia.id ? {} : this.state.presentMedia,
                    })
                  )
                  .catch(({ id, msg }) => {
                    this.setState({
                      error: { id, msg },
                    });
                  });
              }}
              onClick={
                this.props.disableContextualOptions
                  ? () => {}
                  : () => {
                      this.setState({
                        selectedMedia: selectedMedia.id === media.id ? {} : media,
                        error: {},
                      });
                    }
              }
            />
            {this.state.error.id === media.id && this.state.error.msg && (
              <div className={styles.error}>{this.state.error.msg}</div>
            )}
          </div>
        );
      });
  };

  render() {
    const { query, selectedMedia, altText, link, debouncedQuery } = this.state;
    const mediaToBeShown = debouncedQuery ? this.props.unstackSearchedMedia : this.props.allMedia;
    const filteredMedia = this.getRenderableMedia(mediaToBeShown);

    const noMediaAvailable = Boolean(!this.props.isLoading && !filteredMedia.length && !this.props.isUploading);

    return (
      <>
        <Search
          autofocus={!selectedMedia.id}
          containerStyle={{ position: 'absolute', top: '20px' }}
          debounceValue={(debouncedQuery) => this.setState({ debouncedQuery })}
          enableDarkMode
          onChange={(query) => {
            this.setState({ query });
          }}
          placeholder={`Search ${this.props.category}s`}
          value={query}
        />
        <div
          className={classnames(styles.MediaLibrary, {
            [styles.addPadding]:
              Boolean(selectedMedia.id && mediaToBeShown.find((m) => m.id === selectedMedia.id)) ||
              selectedMedia.isExternalSrc,
            [styles.mediaNoResult]: noMediaAvailable,
          })}
        >
          {Boolean(filteredMedia.length || this.props.isUploading) && (
            <div className={styles.content}>
              {this.props.isUploading && (
                <div className={styles.spinner} data-test-id="uploading-spinner">
                  <Spinner />
                  {this.props.uploadProgress !== null ? <ProgressBar progress={this.props.uploadProgress} /> : null}
                </div>
              )}
              {this.props.entity.isExternalSrc && (
                <div
                  className={classnames(styles.imageContainer, {
                    [styles.selected]: selectedMedia.src === this.props.entity.src,
                  })}
                  ref={selectedMedia.src === this.props.entity.src ? 'trackerRef' : null}
                  onClick={() => this.setState({ selectedMedia: this.props.entity })}
                >
                  <video
                    style={{
                      height: '100%',
                      width: '100%',
                    }}
                    src={selectedMedia.src}
                  />
                </div>
              )}
              {filteredMedia}
            </div>
          )}
          {!this.props.isLoading && (
            <VisibilitySensor onChange={(visible) => this.onLoadMoreVisibilityChange(visible)}>
              <span>&nbsp;</span>
            </VisibilitySensor>
          )}
          {noMediaAvailable && (
            <MediaNoResults
              icon={<SvgNoResults />}
              content={`There are no ${
                this.props.category === 'pdf' ? 'PDF' : `${this.props.category}`
              } in your media library ${debouncedQuery && 'that match your search term'}.`}
              header={`No ${this.props.category === 'pdf' ? 'PDF' : `${this.props.category}`} results`}
            />
          )}
          {this.props.isLoading && (
            <div className={styles.Loader}>
              <Spinner />
              <h3>Checking for additional media...</h3>
            </div>
          )}
          {this.props.isErrorOnCreate && (
            <div className={classnames(styles.Loader, styles.dangerText)}>
              <h3>{this.props.isErrorOnCreate.message}</h3>
              {!(this.props.isErrorOnCreate.code === 'decompression_error') &&
                !this.props.isErrorOnCreate.invalidJson && (
                  <h3>.mp4, .pdf, .png, .gif, .jpg, .jpeg, .bmp, x-icon, svg, svg+xml</h3>
                )}
            </div>
          )}
        </div>
        {Boolean(
          (selectedMedia.id && mediaToBeShown.find((m) => m.id === selectedMedia.id)) ||
            (selectedMedia.isExternalSrc && selectedMedia.src === this.props.entity.src)
        ) && (
          <MediaOptionsDrawer
            onLinkChange={(val) => this.setState({ link: val })}
            onSave={() => {
              this.setState({ presentMedia: selectedMedia });
              this.props.onChange({
                ...(selectedMedia.isExternalSrc ? { ...selectedMedia } : { src: `media:${selectedMedia.id}` }),
                alt: altText,
                link: link,
                ...pick(this.state, [
                  'loop',
                  'controls',
                  'muted',
                  'autoplay',
                  'optimization',
                  'boxShadow',
                  'borderRadius',
                ]),
              });
            }}
            isVideoCategory={this.props.category === 'video'}
            isImageCategory={['image', 'product'].includes(this.props.category)}
            entityOptions={pick(this.state, [
              'loop',
              'controls',
              'muted',
              'autoplay',
              'altText',
              'link',
              'optimization',
              'boxShadow',
              'borderRadius',
            ])}
            updateEntityOptions={(e) => {
              if (e.target.type === 'checkbox')
                this.setState({
                  [e.target.name]: e.target.checked,
                });
              else if (e.target.name === 'borderRadius')
                this.setState({
                  [e.target.name]: e.target.value + 'px',
                });
              else
                this.setState({
                  [e.target.name]: e.target.value,
                });
            }}
            onRemove={() => this.props.onChange()}
            enableLinking={this.props.enableLinking}
            showMediaContextOptions={this.props.showMediaContextOptions}
            hideVideoOptions={this.props.hideVideoOptions}
            entity={this.props.entity}
            isSectionContent={
              (this.props.entity.src && this.props.entity.src.includes(selectedMedia.id)) ||
              (selectedMedia.src && this.props.entity.src === selectedMedia.src)
            }
          />
        )}
      </>
    );
  }
}
