import React from 'react';
import { useDispatch } from 'react-redux';

import * as siteActions from 'actions/siteActions';

import styles from './DangerZoneOptions.module.scss';

export default React.memo(() => {
  const dispatch = useDispatch();

  return (
    <section>
      <header>
        <h1>Danger Zone</h1>
      </header>
      <div className={styles.row}>
        <div>
          <span>Delete website and start over from theme selection</span>
        </div>
        <div className={styles.link}>
          <a
            href="/"
            onClick={(e) => {
              e.preventDefault();
              if (
                window.confirm(
                  `IMPORTANT: Are you sure you want to do this? If you proceed, we will delete all of your content and you will need to choose a new theme. This cannot be undone.`
                )
              )
                dispatch(siteActions.deleteSite());
            }}
          >
            Delete site
          </a>
        </div>
      </div>
    </section>
  );
});
