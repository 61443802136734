import React, { PropsWithChildren } from 'react';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';
import useUrlSite from 'hooks/useUrlSite';

import {
  getIsQuickstartDismissed,
  getIsWebinarBannerComplete,
  getPendingQuickstartStepsCount,
} from 'components/AdminPanel/Quickstart/helpers';
import { ReactComponent as SvgIconQuickstart } from 'assets/images/icon-star.svg';
import navStyles from 'components/AdminPanel/Nav.module.scss';

export function QuickstartPendingStepsCounter({ children }: PropsWithChildren<{}>) {
  return <span className={navStyles.tag}>{children}</span>;
}

export function QuickStartNavItem(props: any) {
  const siteUrl: string | void = useUrlSite();
  const isQuickstartDismissed = getIsQuickstartDismissed();
  const pendingQuickstartSteps = getPendingQuickstartStepsCount();
  if (isQuickstartDismissed || pendingQuickstartSteps === 0) return null;

  return (
    <NavLink
      className={({ isActive }) => (isActive ? classNames(navStyles.activePanel, navStyles.activeLink) : '')}
      to={`/${siteUrl}/quickstart`}
      data-test-id="quickstart-dashboard"
      style={{ margin: '16px 0' }}
      onClick={props.onClick}
    >
      <SvgIconQuickstart />
      <span style={{ marginLeft: '2px' }}>Quickstart</span>
      <QuickstartPendingStepsCounter>{pendingQuickstartSteps}</QuickstartPendingStepsCounter>
    </NavLink>
  );
}
