// import * as types from '../actions/actionTypes';
// import { combineReducers } from 'redux';
// import { resourceReducer } from 'redux-resource';
import * as types from '../actions/actionTypes';
import { ActionType } from 'typesafe-actions';
import { Reducer, combineReducers } from 'redux';
//@ts-ignore
import { resourceReducer } from 'redux-resource';

import appStatusReducer from './appReducer';
import accountReducer from './accountReducer';
import sitesReducer from './sitesReducer';
import accountUsersReducer from './accountUsersReducer';
import pagesReducer from './pagesReducer';
import blogsReducer from './blogsReducer';
import articlesReducer from './articlesReducer';
import allContentReducer from './allContentReducer';
import sectionsReducer from './sectionsReducer';
import integrationsReducer from './integrationsReducer';
import mediaReducer from './mediaReducer';
import subscriptionReducer from './subscriptionReducer';
import siteStatisticsReducer from './siteStatisticsReducer';
import templatesReducer from './templatesReducer';
import templateTagsReducer from './templatetagsReducer';
import toolbarReducer from './toolbarReducer';
import addSectionReducer from './addSectionReducer';
import contentTestsReducer from './contentTestsReducer';
import goalsReducer from './goalsReducer';
import editorReducer from './editorReducer';
import uiReducer from './uiReducer';
import uiPageSettingsReducer from './uiPageSettingsReducer';
import uiTemplateSettingsReducer from './uiTemplateSettingsReducer';
import uiBlogSettingsReducer from './uiBlogSettingsReducer';
import uiArticleSettingsReducer from './uiArticleSettingsReducer';
import uiSubscriptionManagerReducer from './uiSubscriptionManagerReducer';
import uiDomainSettingsReducer from './uiDomainSettingsReducer';
import uiUserSettingsReducer from './uiUserSettingsReducer';
import uiNewContentTestSettingsReducer from './uiNewContentTestSettingsReducer';
import uiInProgressContentTestSettingsReducer from './uiInProgressContentTestSettingsReducer';
import uiSubmitButtonsStateReducer from './uiSubmitButtonsStateReducer';
import sparkFormsReducer from './sparkFormsReducer';
import contactsReducer from './contactsReducer';
import themesReducer from './themesReducer';
import tasksReducer from './tasksReducer';
import notesReducer from './notesReducer';
import sectionArticlesReducer from './sectionArticlesReducer';
import categoriesReducer from './categoriesReducer';
import paymentReducer from './paymentReducer';
import timezoneReducer from './timezoneReducer';
import tagReducer from './tagReducer';
import dataTablesReducer from './dataTablesReducer';
import itemUrlReducer from './itemUrlReducer';
import currencyReducer from './currencyReducer';
import fontsReducer from './fontsReducer';
import componentReducer from './componentReducer';
import storefrontReducer from './storefrontReducer';
import videosReducer from './videosReducer';
import siteLayoutReducer from './siteLayoutReducer';
import templateGeneratorReducer from './templateGeneratorReducer';
import apiKeysReducer from './apiKeysReducer';

export interface RootState {
  // Define your state properties here
}
const initialState: RootState = {
  // Define your initial state properties here
};

const combinedReducers = combineReducers({
  app: appStatusReducer,
  account: accountReducer,
  accountUsers: accountUsersReducer,
  sites: sitesReducer,
  pages: pagesReducer,
  blogs: blogsReducer,
  articles: articlesReducer,
  allContent: allContentReducer,
  sections: sectionsReducer,
  integrations: integrationsReducer,
  media: mediaReducer,
  subscription: subscriptionReducer,
  siteStatistics: siteStatisticsReducer,
  templates: templatesReducer,
  templateTags: templateTagsReducer,
  toolbar: toolbarReducer,
  templateGenerator: templateGeneratorReducer,
  addSection: addSectionReducer,
  contentTests: contentTestsReducer,
  goals: goalsReducer,
  editor: editorReducer,
  ui: uiReducer,
  uiPageSettings: uiPageSettingsReducer,
  uiTemplateSettings: uiTemplateSettingsReducer,
  uiBlogSettings: uiBlogSettingsReducer,
  uiArticleSettings: uiArticleSettingsReducer,
  uiSubscriptionManager: uiSubscriptionManagerReducer,
  uiDomainSettings: uiDomainSettingsReducer,
  uiUserSettings: uiUserSettingsReducer,
  uiNewContentTestSettings: uiNewContentTestSettingsReducer,
  uiInProgressContentTestSettings: uiInProgressContentTestSettingsReducer,
  uiSubmitButton: uiSubmitButtonsStateReducer,
  sparkForms: sparkFormsReducer,
  contacts: contactsReducer,
  themes: themesReducer,
  tasks: tasksReducer,
  notes: notesReducer,
  components: componentReducer,
  sectionTypes: resourceReducer('sectionTypes'),
  recentSections: resourceReducer('recentSections'),
  resourceArticles: resourceReducer('resourceArticles'),
  elements: resourceReducer('elements'),
  categories: resourceReducer('categories'),
  sectionArticles: sectionArticlesReducer,
  articlesCategories: categoriesReducer,
  payments: paymentReducer,
  timezone: timezoneReducer,
  currency: currencyReducer,
  tags: tagReducer,
  dataTables: dataTablesReducer,
  itemUrls: itemUrlReducer,
  fonts: fontsReducer,
  storefront: storefrontReducer,
  videos: videosReducer,
  siteLayout: siteLayoutReducer,
  apiKeys: apiKeysReducer,
});

const rootReducer: Reducer<RootState, ActionType<any>> = (state, action) => {
  if (action.type === types.ACCOUNT_LOGOUT) {
    state = undefined;
  }

  return combinedReducers(state, action as never);
};

export default rootReducer;
