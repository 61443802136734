import React from 'react';
import Actions from '../../../../Editor/Actions';
import { ActionsTagArgs } from '../../../tag-types';
import { ContainerInfo } from '../../../types';
import { getItemKey } from '../../util/utils';
import { SectionHandlersInterface } from 'components/unstack-components/types';
import { set, del, insert, push, assign } from 'object-path-immutable';
import { getDeviceTypeToSaveTo } from '../../util/helpers/deviceHelper';
import { useSelector } from 'react-redux';
import { getDevice } from 'reducers/uiReducer';
import { USectionDevices } from 'types/USection';

interface ActionElementProps extends ActionsTagArgs {
  align?: 'center' | 'left' | 'right';
  containerInfo: ContainerInfo;
  sectionHandlers: SectionHandlersInterface;
  content: USectionDevices;
  onChange: (content: USectionDevices, key?: string, multi?: boolean) => void;
}

const ActionsElement: React.FunctionComponent<ActionElementProps> = (props) => {
  const { onChange, enableForms, align = 'center', dataRef: value = '', contentKey, containerInfo, content } = props;

  const key = containerInfo ? getItemKey(containerInfo, contentKey) : contentKey;
  const splitKey = key.split('.');
  const device = useSelector(getDevice);

  // const { key, value } = useMemo(() => {
  //   const key = dataRef.split('.').pop();
  //   const value: ActionProps = getValue?.(key) || props.value;
  //   return { ...(getValue && { key }), value };
  // }, [props.value, dataRef, getValue]);

  return (
    <Actions
      formAlignment={align}
      actions={value}
      updateAction={(value: ActionProps) => {
        onChange(
          set(content as any, [getDeviceTypeToSaveTo(device, true), ...splitKey.slice(1)], value),
          `content.${splitKey[0]}`
        );
      }}
      enableForms={enableForms}
      textAlign={content?.base?.textBox?.textAlign}
    />
  );
};

export default ActionsElement;
