import React, { useState } from 'react';
import { createPortal } from 'react-dom';
import { usePopper } from 'react-popper';

/**
 *  TODO: Update it to render portal anywhere in the DOM using prop.
 *  Right now it renders it directly besides root div which fulfills
 *  our current requirement
 * */

export default (props) => {
  const { placement = 'bottom-start', modifiers = [], styles: popperStyles = {} } = props;

  const [reference, setReference] = useState(null);
  const [popper, setPopper] = useState(null);
  const { styles } = usePopper(reference, popper, {
    placement: placement,
    modifiers: [...modifiers],
  });

  return (
    <div>
      <div ref={setReference} />
      {createPortal(
        <div ref={setPopper} style={{ ...styles.popper, ...popperStyles }}>
          {props.children}
        </div>,
        document.body
      )}
    </div>
  );
};
