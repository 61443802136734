import { call, put, select, all, race, take, takeLatest } from 'redux-saga/effects';
import requireSiteId from './utils/requireSiteId';

import * as actionTypes from '../actions/actionTypes';

import * as actions from '../actions/uiNewContentTestSettingsActions';
import { requestPublish } from '../actions/pageActions';
import { requestCreate, requestStart as requestContentTestStart } from '../actions/contentTestActions';

import * as selectors from '../reducers/uiNewContentTestSettingsReducer';
import { selectPageIdFromSectionId } from '../reducers/pagesReducer';
import { selectVariantIds } from '../reducers/sectionsReducer';

import isObject from 'lodash/isObject';

function* start(action) {
  // Get data
  const { sectionId, selectedGoal, name, weights, confidence } = yield select(selectors.selectLocalState);

  // Get page and variant IDs
  const pageId = yield select(selectPageIdFromSectionId, sectionId);
  const variantIds = yield select(selectVariantIds, sectionId);

  // Validate the content test
  // --------------------------------------------------------------------------

  // Validate goal cannot be blank
  if (!selectedGoal || (isObject(selectedGoal) && (!selectedGoal.id || !/\w+:\w+/.test(selectedGoal.id)))) {
    yield put(
      actions.receiveStartError({
        goal: ['You must select a goal'],
        general: ['Please fix the errors above'],
      })
    );
    return;
  }

  // Publish page
  // --------------------------------------------------------------------------

  yield put(requestPublish(pageId));

  // Watch for success or error. If successful, move along; if error, stop.
  while (true) {
    const [success, error] = yield race([
      take(actionTypes.PAGE_RECEIVE_PUBLISH_SUCCESS),
      take(actionTypes.PAGE_RECEIVE_PUBLISH_ERROR),
    ]);

    if (success && success.payload.pageId === pageId) break;
    if (error && error.payload.pageId === pageId) {
      yield put(actions.receiveStartError());
      return;
    }
  }

  // Create the new ContentTest record
  // --------------------------------------------------------------------------

  // Get current site id
  const siteId = yield call(requireSiteId);

  // Build a new ContentTest object
  const contentTest = {
    section_id: sectionId,
    item: pageId,
    site: siteId,
    name: name,
    variants: [
      {
        name: 'Control',
        weight: weights[0] || 0,
        id: variantIds[0],
      },
      {
        name: 'Variant',
        weight: weights[1] || 0,
        id: variantIds[1],
      },
    ],
    pvalue: (1000 - (confidence || 0) * 10) / 1000,
    goal: selectedGoal,
  };

  // put create content test
  yield put(requestCreate(contentTest));

  // watch for success
  const [receiveCreateSuccess, receiveCreateError] = yield race([
    take(actionTypes.CONTENT_TEST_RECEIVE_CREATE_SUCCESS),
    take(actionTypes.CONTENT_TEST_RECEIVE_CREATE_ERROR),
  ]);

  // if error, show it
  if (receiveCreateError) {
    yield put(actions.receiveStartError(receiveCreateError.payload.errors));
    return;
  }

  const contentTestId = receiveCreateSuccess.payload.contentTest.id;

  // Start content test
  // --------------------------------------------------------------------------

  yield put(requestContentTestStart(contentTestId));

  // watch for success/error
  const [, receiveStartError] = yield race([
    take(actionTypes.CONTENT_TEST_RECEIVE_START_SUCCESS),
    take(actionTypes.CONTENT_TEST_RECEIVE_START_ERROR),
  ]);

  if (receiveStartError) {
    yield put(actions.receiveStartError(receiveStartError.payload.errors));
    return;
  }

  yield put(actions.receiveStartSuccess());
}

function* uiNewContentTestsSaga() {
  yield all([takeLatest(actionTypes.UI_NEW_CONTENT_TEST_SETTINGS_REQUEST_START, start)]);
}

export default uiNewContentTestsSaga;
