import { compose } from 'redux';
import withSiteId from '../../../../../containers/withSiteId';
import { connect } from 'react-redux';
import SparkForm from './SparkForm';
import * as api from '../../../../../services/spark-api';
import * as actions from '../../../../../actions/sparkFormsActions';
import * as selectors from '../../../../../reducers/sparkFormsReducer';
import * as subscriptionSelectors from 'reducers/subscriptionReducer';

import { selectSubmissionState } from '../../../../../reducers/uiSubmitButtonsStateReducer';
import { updateSubmittingState } from '../../../../../actions/uiSubmitButtonsStateActions';

const mapStateToProps = (state, ownProps) => {
  const formId = selectors.selectPickedFormId(state) || ownProps.formId;

  return {
    ...ownProps,
    lists: state.integrations.hubspotLists,
    mailchimpLists: state.integrations.mailchimpLists,
    activecampaignLists: state.integrations.activecampaignLists,
    klaviyoLists: state.integrations.klaviyoLists,
    formId: formId,
    form: selectors.selectAvailableFormById(state, formId),
    isAwaiting: selectors.selectIsAwaitingForRequest(state),
    name: selectors.selectFormName(state),
    sectionForm: selectors.selectSectionFormById(state, ownProps.formId)[ownProps.formId],
    isSubmitting: selectSubmissionState(state),
    selectedFields: selectors.selectFieldsFromForm(state),
    selectedList: selectors.selectContactListId(state),
    storedFormData: state.sections.byId[ownProps.sectionId],
    submissionAction: selectors.selectSubmissionAction(state),
    submitButtonValue: selectors.selectSubmitButtonValue(state),
    responders: selectors.selectResponders(state),
    subscription: subscriptionSelectors.selectSubscription(state),
    hasZap: selectors.selectHasZap(state),
  };
};

const mapDispatchToProps = {
  clearForm: actions.clearForm,
  deleteForm: actions.deleteForm,
  saveForm: actions.saveForm,
  storeSuggestedFormOnSelectId: actions.storeSuggestedFormOnSelectId,
  updateFields: actions.updateFields,
  updateForm: actions.updateForm,
  updateFormName: actions.updateFormName,
  updateSubmissionAction: actions.updateSubmissionAction,
  updateSubmitButtonValue: actions.updateSubmitButtonValue,
  updateList: actions.updateList,
  updateResponders: actions.updateResponders,
  updateZapStatus: actions.updateZapStatus,
  updateSubmittingState,
};

const mergeProps = (propsFromState, propsFromDispatch) => {
  return {
    ...propsFromState,
    ...propsFromDispatch,
    addField: (field) => {
      const { selectedFields } = propsFromState;
      propsFromDispatch.updateFields([...selectedFields, field]);
    },
    addGenericField: (field) => {
      const { selectedFields } = propsFromState;
      selectedFields.forEach((f) => delete f.isNew);
      const genericFields = selectedFields.filter((field) => field.id < 100);
      const fieldCopy = { ...field };
      fieldCopy.id = genericFields.length
        ? Math.max.apply(
            Math,
            genericFields.map((field) => field.id)
          ) + 1
        : 1;
      fieldCopy.isNew = true;
      propsFromDispatch.updateFields([...selectedFields, fieldCopy]);
    },
    removeField: (id) => {
      const updatedFields = propsFromState.selectedFields.filter((field) => field.id !== id);
      propsFromDispatch.updateFields(updatedFields);
    },
    updateField: (event, field, ...args) => {
      const [value] = args;
      const selectedFields = [...propsFromState.selectedFields];
      const currentField = selectedFields.filter((f) => f.id === field.id)[0];
      const currentIndex = selectedFields.findIndex((f) => f.id === field.id);
      const updatedField = {
        ...currentField,
        isNew: false,
        [event.target.name]: value || event.target.value,
      };
      delete currentField.isNew;
      selectedFields[currentIndex] = updatedField;
      propsFromDispatch.updateFields(selectedFields);
    },
    undoFieldChanges: (field) => {
      const selectedFields = [...propsFromState.selectedFields];
      const currentIndex = selectedFields.findIndex((f) => f.id === field.id);
      selectedFields[currentIndex] = field;
      propsFromDispatch.updateFields(selectedFields.filter((f) => f.name));
    },
    reorderField: (fields) => {
      propsFromDispatch.updateFields(fields);
    },
    populateForm: () => {
      const { formId } = propsFromState;

      api.getForm(formId).then((response) => {
        if (response.ok) {
          const form = response.json;
          form.content.fields.forEach((field) => {
            if (field.options) {
              field.options =
                typeof field.options === 'string' ? field.options : field.options.map((option) => option[0]).join(', ');
            }
          });

          propsFromDispatch.updateResponders(form.content.responders);
          propsFromDispatch.updateFields(form.content.fields);
          propsFromDispatch.updateSubmissionAction(form.action);
          propsFromDispatch.updateSubmitButtonValue(form.action.value);
          propsFromDispatch.updateFormName(form.name);
          form.content.contact_lists &&
            Object.entries(form.content.contact_lists).forEach((list) =>
              propsFromDispatch.updateList({
                integration: list[0],
                listId: list[1],
              })
            );
        }
      });
    },
    populateUsingFormName: ({ fields, submissionAction, submitValue, name, id, contactListsIds, zap_exists }) => {
      fields.forEach((field) => {
        if (field.options) {
          field.options =
            typeof field.options === 'string' ? field.options : field.options.map((option) => option[0]).join(', ');
        }
      });

      propsFromDispatch.storeSuggestedFormOnSelectId(id);
      propsFromDispatch.updateFields(fields);
      propsFromDispatch.updateSubmissionAction(submissionAction);
      propsFromDispatch.updateSubmitButtonValue(submitValue);
      propsFromDispatch.updateFormName(name);
      propsFromDispatch.updateZapStatus(zap_exists);
      Object.entries(contactListsIds).forEach((list) =>
        propsFromDispatch.updateList({
          integration: list[0],
          listId: list[1],
        })
      );
    },
    updateSelectedList: (integration, listId) => {
      propsFromDispatch.updateList({ integration, listId });
    },
    updateResponder: (responder, value) => {
      let newResponder = {};
      newResponder[responder] = value;
      propsFromDispatch.updateResponders(newResponder);
    },
  };
};

export default compose(withSiteId, connect(mapStateToProps, mapDispatchToProps, mergeProps))(SparkForm);
