import { ShopifyCollection, ShopifyProduct } from 'types/ShopifyProduct';
import React from 'react';

import styles from './SmartUrlToolbar.module.scss';
import { IconPdf, IconDollar, IconToolbarPage, IconForm } from 'components/base/BaseIcons';
import { UFile } from 'types/UFile';
import { UForm } from 'types/UForm';
import { OpenTabType } from 'types/SmartURLLink';
import { UProduct } from 'types/UProduct';

export function filterProducts(products: (UProduct | ShopifyCollection)[], url?: string) {
  const lowerCaseUrl = url ? url.toLowerCase() : '';

  return products
    .filter((product) => {
      if ((product as any).attributes) return true;
      const name = (product as any).attributes ? (product as any).attributes.sku : (product as any).title;
      return name.toLowerCase().includes(lowerCaseUrl);
    })
    .map((product) => {
      const isCollection = (product as any).products;
      const name = (product as any).attributes ? (product as any).attributes.name : (product as any).title;
      return {
        ...product,
        name,
        type: isCollection ? 'collection' : 'product',
        item_type: isCollection ? 'collection' : 'product',
      };
    });
}

export function filterPDFs(pdfs: UFile[], url: string) {
  const lowerCaseUrl = url ? url.toLowerCase() : '';

  return pdfs
    .filter(
      (file) =>
        file.metadata &&
        Object.keys(file.metadata).length > 0 &&
        file.metadata?.file_name?.toLowerCase().includes(lowerCaseUrl)
    )
    .map((file) => {
      return {
        ...file,
        name: file.metadata.file_name,
        type: 'media',
        item_type: 'media',
      };
    });
}

export function filterForms(forms: UForm[], url: string) {
  const lowerCaseUrl = url ? url.toLowerCase() : '';

  return forms
    .filter((form) => form?.name.toLowerCase().includes(lowerCaseUrl))
    .map((form) => {
      return {
        ...form,
        name: form.name,
        type: 'form',
        item_type: 'form',
      };
    });
}
export function getIconByType(type: string) {
  switch (type) {
    case 'media':
      return <IconPdf className={styles.suggestionIcon} />;
    case 'product':
      return <IconDollar className={styles.suggestionIcon} />;
    case 'form':
      return <IconForm className={styles.suggestionIcon} />;
    default:
      return <IconToolbarPage className={styles.suggestionIcon} />;
  }
}

function getGroupedType(item_type: string) {
  switch (item_type) {
    case 'landing_page':
    case 'site_page':
      return 'Pages';
    case 'product':
      return 'Products';
    case 'collection':
      return 'Collections';
    case 'media':
      return 'Documents';
    default:
      return item_type.charAt(0).toUpperCase() + item_type.slice(1);
  }
}

export function openAsTitle(val: OpenTabType, item_type?: string) {
  switch (val) {
    case 'addToCart':
      return 'Add to Cart';
    case 'popup':
      return item_type !== 'collection' && item_type !== 'product' ? 'Open in Popover' : 'Confirm with Popover';
    case 'currentTab':
      return 'Open in Current Tab';
    case 'newTab':
      return 'Open in New Tab';
  }
}
export function openAsMiniTitle(val: OpenTabType) {
  switch (val) {
    case 'addToCart':
      return 'Add to Cart';
    case 'popup':
      return 'Popover';
    case 'currentTab':
      return 'Current';
    case 'newTab':
      return 'New';
  }
}

export type ItemSuggestion = {
  numHidden?: number;
  [key: string]: any;
};
export type ItemSuggestionGroup = {
  [key: string]: {
    grouped_type: string;
    suggestions: ItemSuggestion[];
  };
};

export function getItemSuggestionsGrouped(itemSuggestions: ItemSuggestion[], showAllGroups: string[]) {
  const groupedSuggestions: ItemSuggestionGroup = {};
  itemSuggestions.forEach((suggestion) => {
    const grouped_type = getGroupedType(suggestion.item_type);
    if (!groupedSuggestions[grouped_type]) {
      groupedSuggestions[grouped_type] = { grouped_type, suggestions: [] };
    }
    const currGroup = groupedSuggestions[grouped_type];
    const showMoreSuggestion = currGroup.suggestions.find((s) => s.numHidden);
    if (currGroup.suggestions.length > 3 && !showMoreSuggestion && !showAllGroups.includes(grouped_type)) {
      currGroup.suggestions.push({ numHidden: 1, grouped_type });
    } else if (!showMoreSuggestion) {
      currGroup.suggestions = [...currGroup.suggestions, suggestion];
    } else {
      showMoreSuggestion.numHidden++;
    }
  });
  return Object.values(groupedSuggestions);
}
