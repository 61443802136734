import React from 'react';

import FormEditor from './EmbeddedForm/FormEditor';
import RenderedForm from './EmbeddedForm/RenderedForm';

class EmbeddedForm extends React.PureComponent {
  state = { isOpen: false };
  open = () => this.setState({ isOpen: true });
  close = () => this.setState({ isOpen: false });
  toggle = () => this.setState({ isOpen: !this.state.isOpen });

  render() {
    const { value, sectionId, formAlignment } = this.props;
    const { isOpen } = this.state;

    return (
      <>
        <RenderedForm
          value={value}
          onClick={this.open}
          formAlignment={formAlignment}
          sectionType={this.props.sectionType}
        />
        <FormEditor
          sectionId={sectionId}
          isOpen={isOpen}
          close={this.close}
          value={value}
          onChange={this.props.onChange}
          unstackOnly={this.props.unstackOnly}
        />
      </>
    );
  }
}

export default EmbeddedForm;
