import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';

import * as blogActions from '../../../actions/blogActions';
import { requestAllIfNeeded as requestAllGoalsIfNeeded } from '../../../actions/goalActions';
import { selectAll as selectAllGoals } from '../../../reducers/goalsReducer';

import FormIdGoal from '../NewContentTestSettings/FormIdGoal';
import PageViewGoal from '../NewContentTestSettings/PageViewGoal';

import SideDrawer from '../../base/SideDrawer';

function hasErrors(errors, key) {
  return errors && errors[key];
}

function errorClassName(errors, key) {
  return hasErrors(errors, key) ? 'fieldWithErrors' : null;
}

function renderErrorMessages(errors, key) {
  if (hasErrors(errors, key)) {
    return errors[key].map((msg, i) => (
      <small key={i} className="errorMessage">
        {msg}
      </small>
    ));
  }
}

export default function NewContentTestSettings(props) {
  const [data, setData] = useState({
    weight0: 50,
    weight1: 50,
    confidence: 95,
  });
  const [errors, setErrors] = useState([]);
  const [formState, setFormState] = useState();

  const { isOpen } = props;

  const dispatch = useDispatch();

  const close = () => {
    setData({
      weight0: 50,
      weight1: 50,
      confidence: 95,
    });
    setErrors([]);
    setFormState(false);
    props.close();
  };

  // Fetch goals and content tests
  useEffect(() => {
    dispatch(requestAllGoalsIfNeeded());
  }, [dispatch]);

  const goals = useSelector(selectAllGoals);

  const handleChangeName = (e) => {
    setData({ ...data, name: e.target.value });
  };

  const handleClickStart = useCallback(
    (e) => {
      e.preventDefault();
      setFormState(true);
      dispatch(
        blogActions.startABTest(props.containerId, props.blogId, props.variants, data, (status, errors) => {
          if (status) props.close();
          else setErrors(errors);
          setFormState(false);
        })
      );
    },
    [data]
  );

  return (
    <SideDrawer title="A/B test options" isOpen={isOpen} close={close}>
      <form>
        <fieldset>
          <label htmlFor="goal_input">Goal</label>
          {renderErrorMessages(errors, 'goal')}
          <div className="radio-group">
            <PageViewGoal
              goal={data.selectedGoal}
              onChange={(goal) => setData({ ...data, selectedGoal: goal })}
              disabled={formState}
            />
            <FormIdGoal
              goal={data.selectedGoal}
              onChange={(goal) => setData({ ...data, selectedGoal: goal })}
              disabled={formState}
            />
            {goals.map((goal) => {
              const isSelected = goal.id === data.selectedGoal;
              const handleChange = (e) => {
                setData({ ...data, selectedGoal: goal.id });
              };
              const className = classnames({
                selected: isSelected,
                disabled: formState,
              });

              return (
                <label key={goal.id} className={className}>
                  <input type="radio" value={goal.id} checked={isSelected} onChange={handleChange} readOnly />
                  <div className="title">{goal.name}</div>
                </label>
              );
            })}
          </div>
        </fieldset>
        <fieldset>
          <label>Segmentation</label>
          <div className="bordered-group">
            <label className="label-right-text">
              <div className="title">Control</div>
              <input
                type="text"
                value={data.weight0}
                onChange={(e) => setData({ ...data, weight0: e.target.value })}
                disabled={formState}
              />
              %
            </label>
            <label className="label-right-text">
              <div className="title">Variant A</div>
              <input
                type="text"
                value={data.weight1}
                onChange={(e) => setData({ ...data, weight1: e.target.value })}
                disabled={formState}
              />
              %
            </label>
          </div>
          <small>
            Set the relative frequency with which the control and variant will be shown. Most people set this to 50/50.
          </small>
        </fieldset>
        <fieldset>
          <label>Result certainty</label>
          {renderErrorMessages(errors, 'pvalue')}
          <div className="bordered-group">
            <label className="label-right-text">
              <div className="title">Winning variant</div>
              <input
                type="text"
                value={data.confidence}
                onChange={(e) => setData({ ...data, confidence: e.target.value })}
                disabled={formState}
              />
              %
              <small className="label-block">
                The p-value will be {((100 - (data.confidence || 0)) / 100).toFixed(3)}
              </small>
            </label>
          </div>
        </fieldset>
        <fieldset>
          <label htmlFor="name_input">Name</label>
          <input
            id="name_input"
            type="text"
            className={errorClassName(errors, 'name')}
            value={data.name}
            onChange={handleChangeName}
            disabled={formState}
          />
          {renderErrorMessages(errors, 'name')}
          <small>Give your test a name so you can recognize it elsewhere. For example: "Home page hero copy""</small>
        </fieldset>
        <fieldset>
          <input
            type="submit"
            className="button button-primary button-full"
            onClick={handleClickStart}
            value="Publish changes and start test"
            disabled={formState}
          />
          {renderErrorMessages(errors, 'general')}
        </fieldset>
        <p>
          <small>This section will not be editable while the test is running. You can end the test at any time.</small>
        </p>
      </form>
    </SideDrawer>
  );
}
