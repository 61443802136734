import 'autotrack/lib/plugins/outbound-link-tracker';
import 'autotrack/lib/plugins/url-change-tracker';

const ga = (window as any).ga;

ga('create', process.env.REACT_APP_GTM_GA_ID, 'auto');
ga('require', 'outboundLinkTracker');
ga('require', 'urlChangeTracker');

ga('send', 'pageview');
