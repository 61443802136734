import React, { useState, useEffect } from 'react';
import { Hierarchy as IHierarchy } from '@moltin/sdk';

import { fetchFullHierarchy } from './hooks';

import CircleSpinner from '../../assets/images/Spinner.png';
import { IconChevronDownSmall } from 'components/Editor/Toolbars2/icons';
import { EPThumbnail } from 'components/unstack-components/Component/elements/generator/ProductGenerator/IndividualStorefrontList';

import styles from './Storefront.module.scss';

type ITree = {
  attributes: {
    name: string;
    description?: string;
    slug?: string;
    created_at?: string;
    updated_at?: string;
    published_at?: string;
  };
  id: string;
};

export const Hierarchy = ({ attributes, id }: ITree) => {
  const [fetchData, setFetchData] = useState<Boolean>();
  const [isOpen, setIsOpen] = useState<Boolean>(false);

  const [data, isLoading] = fetchFullHierarchy(fetchData && id);

  useEffect(() => {
    if (data && fetchData) {
      setIsOpen(true);
    }
  }, [data]);

  return (
    <ul style={{ marginBottom: 0 }} className={styles.epCategory}>
      <div
        className={`${styles.title} ${styles.epTitle}`}
        onClick={() => {
          if (!fetchData) setFetchData(true);
          else setIsOpen(!isOpen);
        }}
      >
        {isLoading ? (
          <span
            className={`${styles.arrowContainer} ${styles.loader}`}
            style={{ background: `url("${CircleSpinner}")` }}
          />
        ) : (
          <span className={styles.arrowContainer}>
            {!!(!fetchData || (fetchData && data?.length)) && (
              <IconChevronDownSmall className={`${isOpen && styles.isOpen}`} />
            )}
          </span>
        )}
        <EPThumbnail isAdminPanel />
        <span className={styles.epName}>{attributes.name}</span>
      </div>
      {isOpen && (
        <ul style={{ margin: '10px 0 0' }}>
          {data.map((child: IHierarchy) => {
            return (
              <li style={{ minHeight: '52px' }}>
                <Node item={child} />
              </li>
            );
          })}
        </ul>
      )}
    </ul>
  );
};

const Node = ({ item }: { item: IHierarchy }) => {
  return <Hierarchy {...item} />;
};
