import React, { useEffect } from 'react';
import DatePicker from 'lib/react-datepicker';

import { Group, Label } from 'components/Editor/Toolbars2/Toolbar2';
import { IconDate } from 'components/Editor/Toolbars2/icons';

import styles from './LayoutOption.module.scss';
import addDays from 'date-fns/addDays';

const DateField = (props: any) => {
  useEffect(() => {
    if (!props.value) {
      if (props.property.default === 'today') {
        props.onChange(props.property.name, new Date().toISOString());
      } else if (props.property.default === 'tomorrow') {
        props.onChange(props.property.name, addDays(new Date(), 1).toISOString());
      }
    }
  }, []);

  return (
    <Group className={props.className}>
      {props.property.label && <Label>{props.property.label}:</Label>}
      <DatePicker
        placeholderText={props.property.placeholder}
        className={styles.DateInput}
        dateFormat="MMM d, yyyy"
        todayButton="Today"
        autoComplete="off"
        value={props.value || undefined}
        onChange={(value: any) => {
          props.onChange(props.property.name, value?.toISOString());
        }}
      />
      <IconDate className={styles.InputIcon} />
    </Group>
  );
};

export default DateField;
