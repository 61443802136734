/* eslint-disable jsx-a11y/anchor-is-valid */

import React from 'react';
import { set } from 'object-path-immutable';
import AddActions from './AddActions';
import { PrimaryAction, SecondaryAction, TextAction } from './ActionViews';
import EmbeddedForm from './EmbeddedForm';
import EmbeddedPayment from './EmbeddedPayment';

export default class Actions extends React.PureComponent {
  state = {
    autoFocus: null,
  };

  update = (path, value) => {
    const actions = this.props.actions || {};
    const updatedActions = set(actions, path, value);
    return this.props.updateAction(updatedActions);
  };

  updateActionValues = (dict) => {
    const actions = this.props.actions || {};
    let updatedActions = actions;
    Object.keys(dict).forEach((path) => {
      updatedActions = set(updatedActions, path, dict[path]);
    });

    return this.props.updateAction(updatedActions);
  };

  addAutoFocus = (type) => {
    this.setState({ autoFocus: type });
  };

  render() {
    const { color, readOnly, formAlignment, paymentsEnabled, hasPaymentAdded, textAlign } = this.props;

    const actions = this.props.actions || {};

    if (readOnly) {
      return (
        <div className="actions">
          {(actions.primary || actions.secondary) && (
            <div className="buttons-container" data-test-id="actions-button">
              {actions.primary && (
                <a className="button button-primary" data-test-id="primary-action-button" style={{ cursor: 'default' }}>
                  {actions.primary.text}
                </a>
              )}
              {actions.secondary && (
                <a className="button" style={{ cursor: 'default' }} data-test-id="secondary-action-button">
                  {actions.secondary.text}
                </a>
              )}
            </div>
          )}
          {actions.form && (
            <EmbeddedForm
              sectionId={this.props.sectionId}
              formAlignment={formAlignment}
              value={actions.form}
              onChange={(value) => this.update('form', value)}
            />
          )}
          {actions.text && (
            <a className="text-action" style={{ cursor: 'default' }}>
              {actions.text.text}
            </a>
          )}
        </div>
      );
    }

    return (
      <div className="actions">
        {(actions.primary || actions.secondary) && (
          <div className="buttons-container">
            <PrimaryAction
              action={actions.primary}
              update={this.update}
              updateActionValues={this.updateActionValues}
              autoFocus={this.state.autoFocus === 'primary'}
              color={color}
            />
            <SecondaryAction
              action={actions.secondary}
              update={this.update}
              updateActionValues={this.updateActionValues}
              autoFocus={this.state.autoFocus === 'secondary'}
              color={color}
            />
          </div>
        )}
        {actions.form && (
          <EmbeddedForm
            sectionId={this.props.sectionId}
            sectionType={this.props.sectionType}
            value={actions.form}
            formAlignment={formAlignment}
            onChange={(value) => this.update('form', value)}
          />
        )}
        {actions.payment && (
          <EmbeddedPayment
            sectionId={this.props.sectionId}
            sectionType={this.props.sectionType}
            value={actions.payment}
            onChange={(value) => this.update('payment', value)}
          />
        )}
        {actions.text && (
          <div className="text-action-container">
            <TextAction
              action={actions.text}
              update={this.update}
              updateActionValues={this.updateActionValues}
              autoFocus={this.state.autoFocus === 'text'}
              color={color}
            />
          </div>
        )}
        <AddActions
          enableForms={this.props.enableForms}
          actions={actions}
          showIfEmpty={this.props.showAddActionsIfEmpty}
          update={this.update}
          updateActionValues={this.updateActionValues}
          addAutoFocus={this.addAutoFocus}
          centered={this.props.centered}
          paymentsEnabled={paymentsEnabled && !hasPaymentAdded}
          textAlign={textAlign}
        />
      </div>
    );
  }
}
