import { set, del, insert, push, assign } from 'object-path-immutable';
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { getDevice } from 'reducers/uiReducer';
import { USectionDevices } from 'types/USection';
import ContentEditable from '../../../../Editor/ContentEditable';
import { PlaintextTagArgs } from '../../../tag-types';
import { getDeviceTypeToSaveTo } from '../../util/helpers/deviceHelper';
import { getItemKey } from '../../util/utils';

interface PlaintextInterface extends ValueOnChangeProps<string>, PlaintextTagArgs {
  defaults: any;
  content: USectionDevices;
}

const PlaintextElement: React.FunctionComponent<PlaintextInterface> = ({
  onChange,
  className,
  dataRef,
  contentKey,
  tagName,
  placeholder,
  containerInfo,
  defaults = {},
  content,
}) => {
  const device = useSelector(getDevice);

  const handleChange = useCallback(
    (value: string) => {
      const key = containerInfo ? getItemKey(containerInfo, contentKey) : contentKey;
      const splitKey = key.split('.');
      onChange(
        set(content as any, [getDeviceTypeToSaveTo(device, true), ...splitKey.slice(1), 'value'], value),
        `content.${splitKey[0]}`
      );
    },
    [onChange, content]
  );

  return (
    <ContentEditable
      // @ts-ignore-next-line
      value={dataRef?.value}
      className={className}
      onChange={(value: string) => handleChange(value)}
      tag={tagName}
      placeholder={placeholder || defaults.placeholder}
    />
  );
};

export default PlaintextElement;
