import * as types from './actionTypes';

/**
 * Opens the user manager
 * @param  {object} args All arguments
 * @param  {string} [args.requestedAction] the requested view/action
 * @return {object} The redux action.
 */
export function open(args = {}) {
  return {
    type: types.ACCOUNT_USER_SETTINGS_OPEN,
    payload: { ...args },
  };
}

/**
 * Closes the user manager.
 * @return {object} The redux action.
 */
export function close() {
  return {
    type: types.ACCOUNT_USER_SETTINGS_CLOSE,
  };
}
