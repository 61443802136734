import React, { useCallback } from 'react';
import { connect } from 'react-redux';

import Thumbnails from './AddSection/Thumbnails';

import * as sectionActions from '../../actions/sectionActions';

import styles from './AddSection.module.scss';

const mapDispatchToProps = {
  add: sectionActions.add,
};

function AddSection(props) {
  function addSection(type, id) {
    const { add, pageId } = props;
    add(
      {
        item: pageId,
        [type]: id,
        position: 0,
      },
      (id) => props.markSectionAsFresh(id)
    );
  }

  return (
    <div className={styles.AddSection}>
      <Thumbnails addSection={addSection} isCarousel={false} layout="fullWidth" />
    </div>
  );
}

// Connect and export the component
export default connect(null, mapDispatchToProps)(AddSection);
