/* eslint-disable jsx-a11y/anchor-is-valid */

import React from 'react';
import { compose } from 'redux';
import { NAKED_URL_REGEXP, ITEM_URL_REGEXP } from '../../../constants';

import isEmpty from 'lodash/isEmpty';
import { Search as SearchEngine } from 'js-search';
import memoize from 'memoize-one';
import deepEqual from 'fast-deep-equal';
import pick from 'lodash/pick';
import { v4 as uuidv4 } from 'uuid';

import styles from './SmartUrlInput.module.scss';
import { ReactComponent as SvgIconPage } from '../../../assets/images/unstack-mark.svg';
import { ReactComponent as SvgIconCancel } from '../../../assets/images/icon-cancel.svg';

import Autosuggest from 'react-autosuggest';
import withItems from '../../../containers/withItems';
import isEqual from 'lodash/isEqual';

const memoizeDeep = (func) => memoize(func, deepEqual);

const URL_TYPES = {
  EMPTY: 'EMPTY',
  CONTENT_EXISTS: 'CONTENT_EXISTS',
  CONTENT_NOT_FOUND: 'CONTENT_NOT_FOUND',
  URL: 'URL',
  UNKNOWN: 'UNKNOWN',
};

// ----------------------------------------------------------------------------
// Component
// ----------------------------------------------------------------------------

class SmartUrlInput extends React.PureComponent {
  autosuggestId = uuidv4();
  ref = React.createRef();
  inputRef = React.createRef();

  constructor(props) {
    super();

    this.state = {
      itemSuggestions: [],
      autoFocus: props.autoFocus,
      hasHighlightedSuggestion: false,
    };
  }

  componentDidMount() {
    if (this.props.value)
      if (!this.props.noPrefix) {
        const itemMatch = this.props.value.match(ITEM_URL_REGEXP);
        if (itemMatch) this.props.loadCurrentItem(itemMatch[2]);
      } else {
        this.props.loadCurrentItem(this.props.value);
      }
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(this.props.searchedItems, prevProps.searchedItems))
      this.setState({
        itemSuggestions: this.props.searchedItems,
      });
  }

  // --------------------------------------------------------------------------
  // Input and button event handlers
  // --------------------------------------------------------------------------

  handleChange = (e) => this.props.onChange(e.target.value);

  handleClear = (e) => {
    this.setState({ autoFocus: true });
    this.props.onChange('');
  };

  // If the user hits `enter`, don't bubble the event. Otherwise it may submit
  // the form when the user expects it to select an autosuggestion.
  handleKeyDown = (e) => {
    if (e.which === 13) {
      e.preventDefault();

      // If a suggestion is highlighted, prevent the native ENTER keydown event
      // from propagating so that the parent component does not interpret it
      // as a submit. In this particular case, we know that the parent component
      // is the LinkButton in the Quill RichTextEditor toolbar, which is
      // watching for the ENTER keydown event on the document. (Hence the
      // `stopImmediatePropagation`)
      if (this.state.hasHighlightedSuggestion) e.nativeEvent.stopImmediatePropagation();
    }
  };

  // --------------------------------------------------------------------------
  // Autosuggest event handlers
  // --------------------------------------------------------------------------

  handleSuggestionSelected = (e, { suggestionValue }) => {
    e.preventDefault();
    // this.props.saveItem(suggestionValue);
    const { onChange, noPrefix } = this.props;
    if (noPrefix) {
      onChange(suggestionValue.id);
    } else {
      const itemType = suggestionValue.item_type.includes('page') ? 'page' : suggestionValue.item_type;
      onChange(`${itemType}:${suggestionValue.id}`, itemType);
    }
  };

  handleSuggestionsFetchRequested = ({ value }) => {
    if (value && !value.match(ITEM_URL_REGEXP)) this.props.onSearchUpdate(value, this.props.contentType);
  };

  handleSuggestionsClearRequested = () => {
    this.setState({
      itemSuggestions: [],
      hasHighlightedSuggestion: false,
    });
  };

  handleSuggestionHighlighted = ({ suggestion }) => {
    this.setState({ hasHighlightedSuggestion: !!suggestion });
  };

  // --------------------------------------------------------------------------
  // Search
  // --------------------------------------------------------------------------

  getSearchEngine = memoizeDeep((pages) => {
    console.log('creating new search engine');
    const engine = new SearchEngine('id');
    engine.addIndex('name');
    engine.addIndex('slug');
    engine.addDocuments(pages);
    return engine;
  });

  // --------------------------------------------------------------------------
  // URL state types
  // --------------------------------------------------------------------------

  // If url is a smartUrl
  // - return matching page, blog, or article if one exists
  // - return undefined if matching content does not exist
  // else
  // - return false to indicate it is not a smartUrl
  getContentFromSmartUrl() {
    const { value, noPrefix, searchedItems, item = {} } = this.props;

    if (noPrefix) {
      return [...searchedItems, item].find((i) => i.id === value) || false;
    } else {
      const itemMatch = value.match(ITEM_URL_REGEXP);
      if (itemMatch) {
        const { searchedItems } = this.props;
        return [...searchedItems, item].find((item) => item.id === itemMatch[2]);
      }
    }

    return false;
  }

  urlType() {
    const { value } = this.props;

    if (isEmpty(value)) {
      return URL_TYPES.EMPTY;
    }

    const matchedContent = this.getContentFromSmartUrl();

    if (matchedContent) return URL_TYPES.CONTENT_EXISTS;
    else if (matchedContent == null) return URL_TYPES.CONTENT_NOT_FOUND;
    else if (value.match(NAKED_URL_REGEXP)) return URL_TYPES.URL;
    else return URL_TYPES.UNKNOWN;
  }

  // --------------------------------------------------------------------------
  // Render
  // --------------------------------------------------------------------------

  render() {
    const { value, onFocus, onBlur, disablePageAutocomplete } = this.props;
    const { autoFocus, itemSuggestions } = this.state;

    const urlType = this.urlType();
    const isContent = !disablePageAutocomplete && urlType === URL_TYPES.CONTENT_EXISTS;

    let content;
    if (isContent) content = this.getContentFromSmartUrl();

    const placeholder = this.props.placeholder || (disablePageAutocomplete && 'Insert URL') || 'Type page name';

    const inputProps = {
      ref: this.inputRef,
      placeholder,
      onFocus,
      onBlur,
      autoFocus,
      onKeyDown: this.handleKeyDown,
      onChange: this.handleChange,
      value: value || '',
    };

    return (
      <div className="SmartUrlInput" ref={this.ref} data-test-id="smart-url-input-block">
        {isContent && (
          <div className={styles.pageTagContainer}>
            <div className={styles.pageTag}>
              <SvgIconPage className={styles.pageTagIcon} />
              <span className={styles.pageTagName}>{content.name}</span>
              <a className={styles.pageTagClose} role="button" onClick={this.handleClear}>
                <SvgIconCancel />
              </a>
            </div>
          </div>
        )}
        {!isContent && (
          <Autosuggest
            id={this.autosuggestId}
            theme={styles}
            // alwaysRenderSuggestions
            suggestions={itemSuggestions}
            renderSuggestion={(page, { isHighlighted }) => (
              <>
                <div className={styles.suggestionIconContainer}>
                  <SvgIconPage className={styles.suggestionIcon} />
                </div>
                <div className={styles.suggestionName}>{page.name}</div>
                <div className={styles.suggestionSlug}>{page.slug === '' ? '/' : `/${page.slug}/`}</div>
              </>
            )}
            getSuggestionValue={(page) => page}
            onSuggestionSelected={this.handleSuggestionSelected}
            onSuggestionsFetchRequested={this.handleSuggestionsFetchRequested}
            onSuggestionsClearRequested={this.handleSuggestionsClearRequested}
            onSuggestionHighlighted={this.handleSuggestionHighlighted}
            inputProps={inputProps}
          />
        )}
      </div>
    );
  }
}

export default compose(withItems)(SmartUrlInput);
