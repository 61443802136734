import React from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import styles from './Pages.module.scss';
import Search from '../Search';
import { ReactComponent as SvgIconAdd } from '../../assets/images/icon-plus.svg';
import classnames from 'classnames';
import * as templateActions from '../../actions/templateActions';

export function PagesFilter({
  query,
  placeholderText,
  onChangeSearchQuery,
  onChangeDebounce,
  routeToNewPage,
  routeToTemplates,
  type,
}) {
  return (
    <div className={styles.searchFieldContainer} data-test-id="pages-filter">
      <Search
        value={query}
        onChange={(query) => onChangeSearchQuery(query)}
        placeholder={placeholderText}
        debounceValue={(value) => {
          if (value !== undefined) onChangeDebounce(value);
        }}
        containerStyle={{
          marginTop: 0,
          marginRight: '1rem',
        }}
      />
      <Link to={routeToNewPage} className={styles.newContactButton} data-test-id="new-page">
        <SvgIconAdd height={15} width={15} />
        <span>New page</span>
      </Link>
      {routeToTemplates && (
        <Link
          to={routeToTemplates}
          className={classnames(styles.newContactButton, styles.existingTemplatesButton)}
          style={{ marginLeft: '2%' }}
          data-test-id="template-page"
        >
          <span>Templates</span>
        </Link>
      )}
    </div>
  );
}
