import React, { ReactNode } from 'react';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';

interface WrapperProps {
  children: ReactNode;
  client: QueryClient;
}

const QueryClientWrapper: React.FC<WrapperProps> = ({ children }) => {
  const queryClient = new QueryClient();

  return (
    // @ts-ignore
    <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
  );
};

export default QueryClientWrapper;
