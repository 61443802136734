import { all, takeEvery, put, select, call } from 'redux-saga/effects';
import * as fontsSelector from 'reducers/fontsReducer';
import * as actionTypes from '../actions/actionTypes';
import * as fontActions from '../actions/fontActions';
import { getFonts } from 'services/spark-api';
import requireSiteId from './utils/requireSiteId';

function* requestAllIfNeeded() {
  const isFetching = yield select(fontsSelector.selectIsFetching);
  if (isFetching === null) {
    yield put(fontActions.requestAll());
  }
}

function* requestAll(action) {
  const siteId = yield call(requireSiteId, action?.payload);
  try {
    const response = yield call(getFonts, siteId);
    if (response.ok) {
      yield put(fontActions.receiveAll(response.json.results));
    }
  } catch (e) {
    console.error(e);
  }
}

export default function* editorSaga() {
  yield all([
    takeEvery(actionTypes.FONTS_REQUEST_ALL_IF_NEEDED, requestAllIfNeeded),
    takeEvery(actionTypes.FONTS_REQUEST_ALL, requestAll),
  ]);
}
