import React, { useEffect, useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';

import TableData from './DataTables/Sheet';
import TablesList from './DataTables/TableList';
import SideDrawer from 'components/base/SideDrawer';
import DocumentTitle from 'components/DocumentTitle';
import withDataTables from 'containers/withDataTables';
import * as dataTablesActions from 'actions/dataTablesActions';
import FormControl from 'components/base/FormHelpers/FormControl';

import styles from './DataTables.module.scss';

const mapDispatchToProps = {
  createTable: dataTablesActions.createTable,
  clearTableSelection: dataTablesActions.clearSelectedTable,
};

export default compose(
  withDataTables,
  connect(null, mapDispatchToProps)
)((props) => {
  const { isFetching, tables, selectedTable } = props;

  const [name, setName] = useState('');
  const [isOpen, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    return () => {
      props.clearTableSelection();
    };
  }, []);

  const close = () => {
    setName('');
    setOpen(false);
  };
  return (
    <>
      <DocumentTitle title={(siteName) => `${siteName}'s data tables`} />
      {Boolean(!selectedTable) && (
        <>
          <section className={styles.container}>
            <header className={styles.headerContainer}>
              <h1>Data Sheets</h1>
              <span className={styles.alphaLabel}>Alpha</span>
              <small>
                Build or import a data source to dynamically create content at scale.{' '}
                <a href="https://www.unstack.com/goto?database-learn-more" target="_blank">
                  Learn more here.
                </a>
              </small>
            </header>
            <div onClick={() => setOpen(true)} className={styles.newContactButton} data-test-id="new-sheet">
              <span>New datasheet</span>
            </div>
          </section>
          <TablesList isFetching={isFetching} data={tables} />
          <SideDrawer isOpen={isOpen} title="Add a new datasheet" close={close}>
            <fieldset>
              <label htmlFor="data-sheet-name">Datasheet name</label>
              <FormControl
                name="name"
                id="data-sheet-name"
                onChange={(e) => setName(e.target.value)}
                value={name}
                autoComplete="off"
                type="text"
                placeholder="Name"
                disabled={isLoading}
              />
            </fieldset>
            <fieldset>
              <input
                readOnly
                value={isLoading ? 'Saving...' : 'Save'}
                disabled={!name}
                data-test-id="save-sheet"
                className="button button-primary button-full"
                onClick={
                  isLoading
                    ? () => {}
                    : () => {
                        const promise = new Promise((resolve, reject) => {
                          props.createTable(name, resolve, reject);
                          setIsLoading(true);
                        });
                        promise
                          .then(close)
                          .catch((e) => console.error(e))
                          .finally(() => {
                            setIsLoading(false);
                          });
                      }
                }
              />
            </fieldset>
          </SideDrawer>
        </>
      )}
      {/**  TODO
       *  Rather then changing the renderring here we should ridirect to new
       *  URL containing the table ID
       */}
      {selectedTable && <TableData tableId={selectedTable} />}
    </>
  );
});
