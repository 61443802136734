import React, { isValidElement } from 'react';
import get from 'lodash/get';
import classnames from 'classnames';
import useMeasure from 'hooks/useMeasure';

import { ReactComponent as SvgIconAdd } from '../../../../../assets/images/icon-plus.svg';
import { ReactComponent as SvgIconDrag } from '../../../../../assets/images/arrow-all-thick.svg';
import { ReactComponent as SvgIconTrash } from '../../../../../assets/images/icon-remove-field.svg';

import { ItemTagArgs } from 'components/unstack-components/tag-types';
import { ContainerInfo, SectionHandlersInterface } from 'components/unstack-components/types';

import styles from './ItemElement/ItemElement.module.scss';

interface EnhancedSectionHandlers extends SectionHandlersInterface {
  collectionHandleInsert: () => void;
  collectionHandleRemove: () => void;
}

interface ItemProps extends ValueOnChangeProps<Array<Object>>, ItemTagArgs {
  children?: React.ReactChildren;
  defaults: any;
  sectionHandlers?: EnhancedSectionHandlers;
  containerInfo: ContainerInfo;
}

const ItemElement: React.FunctionComponent<ItemProps> = (props) => {
  const {
    // value,
    tagName,
    children,
    onChange,
    className,
    brightnessTheme = 'light-bg',
    sectionHandlers: { collectionHandleInsert, collectionHandleRemove },
    containerInfo,
    defaults = {},
  } = props;

  const [ref, { height }] = useMeasure();
  const Tag = (tagName || defaults.tagName).trim();

  let content = (
    //  @ts-ignore
    <>
      {React.Children.map(children, (child) => {
        if (!isValidElement(child)) {
          return null;
        }

        return React.cloneElement(child as React.ReactElement, {
          onChange,
          containerInfo,
        });
      })}
      <div
        className={classnames(styles.dragOptions, {
          [styles.lightBg]: brightnessTheme.includes('light'),
        })}
        data-test-id="item-options"
      >
        <div className="drag-handle" data-test-id="drag">
          <SvgIconDrag width={13} height={13} />
        </div>
        <div onClick={collectionHandleRemove} data-test-id="remove">
          <SvgIconTrash height={11} width={12} />
        </div>
        <div onClick={collectionHandleInsert} data-test-id="add">
          <SvgIconAdd height={15} width={15} />
        </div>
      </div>
    </>
  );
  return Tag.length ? (
    //  @ts-ignore
    <Tag
      ref={ref}
      length={Tag.length}
      className={classnames(styles.itemContainer, { [styles.moveDragItem]: height <= 50 }, className)}
    >
      {content}
    </Tag>
  ) : (
    content
  );
};

export default ItemElement;
