import React from 'react';
import DatePicker from 'lib/react-datepicker';

import Popper from 'components/base/Popper';
import styles from '../Sheet/Sheet.module.scss';
import subYears from 'date-fns/subYears';
import addYears from 'date-fns/addYears';

type Props = {
  value: string;
  onChange: (date: string) => {};
  removeSheetStyles: () => {};
  associatedLabelId: string;
};

export const DateInput = ({ value, onChange, removeSheetStyles, associatedLabelId }: Props) => {
  return removeSheetStyles ? (
    <DatePicker
      asIsoDateStr
      id={associatedLabelId}
      dateFormat="MMM d, yyyy"
      todayButton="Today"
      autoComplete="off"
      value={value || undefined}
      onChange={(value: string) => {
        onChange(value);
      }}
      showMonthYearDropdown
      minDate={subYears(new Date(), 50)}
      maxDate={addYears(new Date(), 50)}
    />
  ) : (
    <Popper modifiers={[{ name: 'offset', options: { offset: [12, 2] } }]}>
      <DatePicker
        asIsoDateStr
        autoFocus
        id={associatedLabelId}
        className={styles.datePicker}
        dateFormat="MMM d, yyyy"
        todayButton="Today"
        autoComplete="off"
        value={value || undefined}
        onChange={(value: string) => {
          onChange(value);
        }}
        showMonthYearDropdown
        minDate={subYears(new Date(), 50)}
        maxDate={addYears(new Date(), 50)}
      />
    </Popper>
  );
};
