import React, { useEffect, useRef, useMemo } from 'react';
import { CSSTransition } from 'react-transition-group';

import classNames from 'classnames';
import OPERATORS from './constants';
import KeyboardHandledList from '../../../../../base/KeyboardHandledList';

import styles from './Conditions.module.scss';

interface Props {
  isOpen: boolean;
  onChange: (arg0: string, arg1?: null | boolean) => void;
  dataType: null | string;
  cursor: number;
  setCursor: (arg0: number) => void;
  hovered: Array<string | unknown> | undefined;
  setHovered: (arg0: Array<string | unknown> | undefined) => void;
  setOperators: (arg0: Array<any>) => void;
  setDrawerIsOpen: (arg0: boolean) => void;
}

export default function (props: Props) {
  const { isOpen, cursor, setCursor, hovered, setHovered, setOperators, setDrawerIsOpen } = props;
  const ref = useRef(null);
  const ops = useMemo(() => {
    if (OPERATORS != null)
      return [
        ...[...Object.entries((OPERATORS as any)[props.dataType]).map((arr) => arr.concat(true))],
        ...[...Object.entries((OPERATORS as any)[`-${props.dataType}`]).map((arr) => arr.concat(false))],
      ];
    return [];
  }, [OPERATORS]);

  useEffect(() => {
    if (isOpen && ref.current && ops.length > 7) {
      const element = ref.current;
      element?.scrollIntoView({ block: 'nearest' });
    }
  }, [cursor, ops, ref.current, isOpen]);

  useEffect(() => {
    setOperators(ops);
  }, [ops]);

  useEffect(() => {
    if (ops.length && hovered) {
      const index = ops.findIndex((element) => element[0] === hovered[0] && element[2] === hovered[2]);
      setCursor(index);
    }
  }, [hovered, ops]);

  return (
    <CSSTransition in={props.isOpen} timeout={141.59} classNames={styles} mountOnEnter unmountOnExit>
      <div className={classNames(styles.Drawer, styles.operator)} data-test-id="operator-input-drawer">
        <KeyboardHandledList
          items={OPERATORS}
          cursor={cursor}
          setHovered={setHovered}
          onChange={props.onChange}
          render={(
            items: any,
            cursor: number,
            onChange: (arg0: string, arg1?: null | boolean) => void,
            setHovered: (arg0: Array<string | unknown> | undefined) => void
          ) => (
            <ul>
              {props.dataType &&
                //  @ts-ignore
                Object.entries(items[props.dataType]).map((op) => (
                  <li
                    key={op[0]}
                    onMouseDown={(e) => {
                      e.preventDefault();
                      onChange(op[0], true);
                      setDrawerIsOpen(false);
                    }}
                    onMouseMove={() => setHovered(op.concat(true))}
                    onMouseLeave={() => setHovered(undefined)}
                    className={ops[cursor][1] === op[1] ? styles.ListActive : null}
                    ref={ops[cursor][1] === op[1] ? ref : null}
                  >
                    <>{op[1]}</>
                  </li>
                ))}
              {props.dataType !== 'boolean' && <div className={styles.divider} />}
              {props.dataType &&
                //  @ts-ignore
                Object.entries(items[`-${props.dataType}`]).map((op) => (
                  <li
                    key={op[0]}
                    onMouseDown={(e) => {
                      e.preventDefault();
                      onChange(op[0]);
                      setDrawerIsOpen(false);
                    }}
                    onMouseMove={() => setHovered(op.concat(false))}
                    onMouseLeave={() => setHovered(undefined)}
                    className={ops[cursor][1] === op[1] ? styles.ListActive : null}
                    ref={ops[cursor][1] === op[1] ? ref : null}
                  >
                    <>{op[1]}</>
                  </li>
                ))}
            </ul>
          )}
        />
      </div>
    </CSSTransition>
  );
}
