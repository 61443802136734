import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { isEqual } from 'lodash';

import ProductDrawer from 'components/unstack-components/Component/elements/generator/ProductGenerator/ProductCollectionDrawer';

import { useFetchUsingIds } from 'components/Storefront/hooks';

import { IconChevronDownSmall } from 'components/Editor/Toolbars2/icons';

import styles from './TemplateUsage.module.scss';

interface IProps {
  templateObjects: {
    categories: String[];
    products: String[];
  };
  handleChange: (
    name: string,
    value: {
      categories: String[];
      products: String[];
    }
  ) => void;
}

const TemplateUsage = (props: IProps) => {
  const {
    templateObjects: { categories, products },
    handleChange,
  } = props;
  const [isOpen, setIsOpen] = useState<Boolean>(false);

  const [initialState] = useState({ products, categories });
  const [isFetching, productsData, categoriesData] = useFetchUsingIds(initialState.products, initialState.categories);
  const [selectedItems, setSelectedItems] = useState({ products, categories });
  const [selectedData, setSelectedData] = useState({ products: [], categories: [] });

  const isEmpty = useMemo(() => !selectedItems.categories.length && !selectedItems.products.length, [selectedItems]);

  const confirmSelections = useCallback(() => {
    handleChange('template_objects', selectedItems);
  }, [handleChange, selectedItems]);

  useEffect(() => {
    if ((productsData as Array<any>).length || (categoriesData as Array<any>).length)
      setSelectedData({
        products: [...new Set([...(productsData as any[])])],
        categories: [...new Set([...(categoriesData as any[])])],
      });
  }, [productsData, categoriesData]);

  useEffect(() => {
    if (!isEqual(selectedItems, initialState) && !selectedItems.categories.length && !selectedItems.products.length) {
      confirmSelections();
    }
  }, [selectedItems]);

  const onChange = useCallback(
    (value: { [key: string]: any }, type: string) => {
      if (!value) {
        setSelectedItems({
          products: [],
          categories: [],
        });
        setSelectedData({
          products: [],
          categories: [],
        });
      } else {
        if (type) {
          setSelectedItems({
            products: [...selectedItems.products, ...(type === 'pinned' ? [value.id] : [])],
            categories: [...selectedItems.categories, ...(type === 'filtered' ? [value.id] : [])],
          });
          setSelectedData({
            products: [...selectedData.products, ...(type === 'pinned' ? [value] : [])],
            categories: [...selectedData.categories, ...(type === 'filtered' ? [value] : [])],
          });
        } else {
          setSelectedItems({
            products:
              value.type === 'product'
                ? [...selectedItems.products.filter((p) => p !== value.id)]
                : [...selectedItems.products],
            categories:
              value.type === 'hierarchy'
                ? [...selectedItems.categories.filter((p) => p !== value.id)]
                : [...selectedItems.categories],
          });
          setSelectedData({
            products:
              value.type === 'product'
                ? [...selectedData.products.filter((p) => p.id !== value.id)]
                : [...selectedData.products],
            categories:
              value.type === 'hierarchy'
                ? [...selectedData.categories.filter((p) => p.id !== value.id)]
                : [...selectedData.categories],
          });
        }
      }
    },
    [selectedItems, selectedData]
  );

  return (
    <>
      <fieldset>
        <label htmlFor="template_usage">Usage</label>
        <div onClick={() => setIsOpen(!isOpen)} className={styles.container}>
          {isEmpty ? (
            <span className={styles.emptyState}>Choose products</span>
          ) : (
            <>
              <span className={styles.itemThumbnail}>
                <span className={styles.thumbnailPlaceholderContainer}>
                  {[...Array(4)].map((i) => (
                    <span className={styles.thumbnailPlaceholder}>{i}</span>
                  ))}
                </span>
              </span>
              <span className={styles.itemLabel}>{`${categories.length} categor${
                categories.length > 1 ? 'ies' : 'y'
              }, ${products.length} product${products.length > 1 ? 's' : ''}`}</span>
            </>
          )}
          <span className={styles.iconContainer}>
            <IconChevronDownSmall />
          </span>
        </div>
      </fieldset>
      <ProductDrawer
        isFetching={isFetching}
        onChange={onChange}
        avoidBackdrop
        value={!isEmpty}
        isOpen={isOpen}
        templateData={selectedData}
        close={() => setIsOpen(false)}
        confirmSelections={confirmSelections}
        isTemplateSettings
      />
    </>
  );
};

export default TemplateUsage;
