import React from 'react';

import { ReactComponent as SvgIconShopify } from '../../../assets/images/icon-shopify.svg';
import styles from './BackToShopify.module.scss';

interface BackToShopifyProps {
  partnerReference: string;
}

function BackToShopify(props: BackToShopifyProps) {
  const { partnerReference } = props;

  return (
    <a href={`https://${partnerReference}.myshopify.com/admin/apps/unstack`} target="_parent">
      <div className={styles.section}>
        <SvgIconShopify className={styles.icon} />
        <span>Back to Shopify ↩</span>
      </div>
    </a>
  );
}

export default BackToShopify;
