import { call, takeLatest, all, put, select } from 'redux-saga/effects';
import pick from 'lodash/pick';
import requireSiteId from './utils/requireSiteId';
import * as api from '../services/spark-api';
import * as types from '../actions/actionTypes';
import * as paymentActions from '../actions/paymentActions';
import * as paymentSelector from '../reducers/paymentReducer';
import * as submissionStateUiActions from '../actions/uiSubmitButtonsStateActions';

function* fetchIfNeeded() {
  const isLoading = yield select(paymentSelector.selectIsLoading);

  if (isLoading === null) yield put(paymentActions.fetchProducts());
}

function* fetchPeriodIfNeeded() {
  const periods = yield select(paymentSelector.selectPeriods);

  if (periods === null) yield put(paymentActions.fetchPeriods());
}

function* fetchPeriods() {
  const response = yield call(api.getPeriods);

  if (response.ok) {
    yield put(paymentActions.periodFetchSuccess(response.json.results));
  }
}

function* createOrUpdateProduct(action) {
  const { payload, resolve, reject } = action;
  const siteId = yield call(requireSiteId);
  try {
    const response = yield call(api.createProduct, {
      ...pick(payload, ['name']),
      site: siteId,
    });

    if (response.ok) {
      if (!payload.id) {
        const planResponse = yield call(api.createPlan, {
          ...pick(payload, ['amount', 'period']),
          product: response.json.id,
          site: siteId,
        });
        if (planResponse.ok) {
          yield put(paymentActions.createOrUpdateProductSuccess(response.json));
          yield put(paymentActions.createOrUpdatePlanSuccess(planResponse.json));
          resolve();
          yield put(submissionStateUiActions.resetSubmittingState());
        }
      }
    } else {
      yield put(paymentActions.createOrUpdateProductFailure());
      reject();
      yield put(submissionStateUiActions.resetSubmittingState());
    }
  } catch (e) {
    console.error(e);
    yield put(submissionStateUiActions.resetSubmittingState());
  }
}

function* deleteProduct(action) {
  const { payload, resolve, reject } = action;
  try {
    const response = yield call(api.deleteProduct, payload);

    if (response.ok) {
      yield put(paymentActions.deleteProductSuccess(payload));
      resolve();
      yield put(submissionStateUiActions.resetSubmittingState());
    } else {
      reject();
      yield put(submissionStateUiActions.resetSubmittingState());
    }
  } catch (e) {
    console.error(e);
    yield put(submissionStateUiActions.resetSubmittingState());
  }
}

function* fetchProducts() {
  const siteId = yield call(requireSiteId);
  try {
    const response = yield call(api.getProducts, siteId);
    if (response.ok) yield put(paymentActions.fetchProductsSuccess(response.json.results));
    if (response.status === 403) yield put(paymentActions.fetchProductsSuccess([]));
  } catch (e) {
    console.error(e);
  }
}

function* createOrUpdatePlan(action) {
  const { payload, resolve, reject } = action;
  const siteId = yield call(requireSiteId);
  try {
    const response = yield call(api.createPlan, {
      ...payload,
      site: siteId,
    });

    if (response.ok) {
      yield put(paymentActions.createOrUpdatePlanSuccess(response.json));
      resolve();
      yield put(submissionStateUiActions.resetSubmittingState());
    } else {
      yield put(paymentActions.createOrUpdatePlanFailure());
      reject();
      yield put(submissionStateUiActions.resetSubmittingState());
    }
  } catch (e) {
    console.error(e);
    yield put(submissionStateUiActions.resetSubmittingState());
  }
}

function* deletePlan(action) {
  const { payload, resolve, reject } = action;
  try {
    const response = yield call(api.deletePlan, payload);

    if (response.ok) {
      yield put(paymentActions.deletePlanSuccess(payload));
      resolve();
      yield put(submissionStateUiActions.resetSubmittingState());
    } else {
      reject();
      yield put(submissionStateUiActions.resetSubmittingState());
    }
  } catch (e) {
    console.error(e);
    yield put(submissionStateUiActions.resetSubmittingState());
  }
}

// Root saga
// ----------------------------------------------------------------------------

function* paymentsSaga() {
  yield all([
    takeLatest(types.PAYMENTS_CREATE_PRODUCT, createOrUpdateProduct),
    takeLatest(types.PAYMENTS_FETCH_PRODUCTS, fetchProducts),
    takeLatest(types.PAYMENTS_CREATE_PLAN, createOrUpdatePlan),
    takeLatest(types.PAYMENTS_DELETE_PRODUCT, deleteProduct),
    takeLatest(types.PAYMENTS_DELETE_PLAN, deletePlan),
    takeLatest(types.PAYMENTS_FETCH_PRODUCTS_IF_NEEDED, fetchIfNeeded),
    takeLatest(types.PAYMENTS_FETCH_PERIODS_IF_NEEDED, fetchPeriodIfNeeded),
    takeLatest(types.PAYMENTS_FETCH_PRODUCTS, fetchPeriods),
  ]);
}

export default paymentsSaga;
