import * as types from './actionTypes';

export function open(articleId, ops = [], errors = {}) {
  return {
    type: types.ARTICLE_SETTINGS_OPEN,
    payload: { articleId, ops, errors },
  };
}

export function close() {
  return {
    type: types.ARTICLE_SETTINGS_CLOSE,
  };
}

export function addOps(ops) {
  return {
    type: types.ARTICLE_SETTINGS_ADD_OPS,
    payload: { ops },
  };
}

export function save(articleId) {
  return {
    articleId,
    type: types.ARTICLE_SETTINGS_SAVE,
  };
}

export function publish(isSchedule) {
  return {
    type: types.ARTICLE_SETTINGS_PUBLISH,
    payload: { isSchedule },
  };
}
