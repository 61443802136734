import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';

import SignupUnstack from './Signup/SignupUnstack';
import SignupCoachup from './Signup/SignupCoachup';
import * as sitesSelector from 'reducers/sitesReducer';
import CreateSite from 'components/Signup/CreateSite';

import { IS_COACHUP } from './Coachup/isCoachup';

const RedirectToSignUp = () => {
  const hasSites = useSelector(sitesSelector.selectHasSites);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const uid = params.get('uid');
  const navigate = useNavigate();

  useEffect(() => {
    if (hasSites && !uid) navigate('/');
  }, [hasSites]);

  if (uid) return <CreateSite />;
  if (IS_COACHUP) {
    return <SignupCoachup />;
  }
  return <SignupUnstack />;
};

export default RedirectToSignUp;
