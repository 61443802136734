import React from 'react';
import { connect } from 'react-redux';
import throttle from 'lodash/throttle';
import Autosuggest from 'react-autosuggest';

import { contactNameOrEmail } from './ContactDetail/ContactDetail';

import { fetchSearchResults } from '../../../actions/contactsActions';
import { selectSearchedResults } from '../../../reducers/contactsReducer';

import styles from './SmartContactSearch.module.scss';

class SmartContactSearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.value || '',
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.value && this.state.value !== prevState.value) {
      this.makeRequest();
    }
  }

  makeRequest = throttle(() => {
    this.props.fetchSearchResults(this.state.value);
  }, 350);

  render() {
    const inputProps = {
      onChange: (e) => {
        this.setState({ value: e.target.value });
      },
      value: this.state.value,
      placeholder: 'Select customer',
      disabled: this.props.isDisabled,
      className: 'ph-redact-input',
    };
    return (
      <div className={styles.container}>
        <Autosuggest
          theme={styles}
          suggestions={this.props.searched}
          renderSuggestion={(contact) => {
            return (
              <>
                <div className={styles.suggestionName}>{contactNameOrEmail(contact)}</div>
              </>
            );
          }}
          onSuggestionsFetchRequested={(e) => {}}
          getSuggestionValue={(contact) => contact}
          onSuggestionSelected={(e, { suggestionValue }) => {
            this.setState({
              value: contactNameOrEmail(suggestionValue),
            });
            this.props.onChange(suggestionValue);
          }}
          onSuggestionsClearRequested={() => []}
          inputProps={inputProps}
          focusInputOnSuggestionClick={false}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  searched: selectSearchedResults(state),
});

const mapDispatchToProps = {
  fetchSearchResults,
};

export default connect(mapStateToProps, mapDispatchToProps)(SmartContactSearch);
