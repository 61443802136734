import React from 'react';
import iconFilter from '../../../assets/images/icon-filter.svg';
import Select from '../../Select';

import styles from './Controls.module.scss';

const channelOptions = [
  { value: 'organic', label: 'Organic' },
  { value: 'paid', label: 'Paid Channel' },
  // { value: 'organic-search', label: 'organic search' },
  // { value: 'organic-social', label: 'organic social' },
  // { value: 'organic-email', label: 'organic email' },
  // { value: 'organic-typein', label: 'organic typein' },
  // { value: 'organic-referral', label: 'organic referral' },
  // { value: 'paid-search', label: 'paid search' },
  // { value: 'paid-social', label: 'paid social' },
  // { value: 'paid-display', label: 'paid display' },
  // { value: 'paid-email', label: 'paid email' },
];

// const goalOptions = [
//   { value: 'all', label: 'took any action' },
//   { value: 'form-complete', label: 'filled out a form' },
//   { value: 'social-share', label: 'shared an article on social media' },
//   { value: 'subscribe', label: 'subscribed' },
//   { value: 'register', label: 'registered' },
//   { value: 'publish', label: 'published a page' },
//   { value: 'upgrade', label: 'upgraded' },
// ];

export default function Controls(props) {
  const { filterState } = props;

  const { channel, setChannel } = filterState;

  return (
    <header className={styles.insightsFilterContainer}>
      <>
        <img className={styles.iconFilter} src={iconFilter} alt="" />
        Show&nbsp;
        <Select
          options={channelOptions}
          isInline
          value={channelOptions.find((option) => option.value === channel)}
          onChange={(option) => setChannel(option.value)}
        />
        &nbsp;visitors from the last 30 days
      </>
    </header>
  );
}
