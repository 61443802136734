import { RefObject, useEffect } from 'react';

type AnyEvent = MouseEvent | TouchEvent;

function useOnClickOutside<T extends HTMLElement = HTMLElement>(
  ref: RefObject<T>,
  handler: (event: AnyEvent) => void,
  blackListedNodes: Array<string> = []
) {
  useEffect(() => {
    const listener = (event: AnyEvent) => {
      const el = ref?.current;
      const target = event.target as Element;
      if (blackListedNodes && blackListedNodes.includes(target.nodeName)) {
        return;
      }
      // Do nothing if clicking ref's element or descendent elements
      if (!el || el.contains(target as Node)) {
        return;
      }

      handler(event);
    };

    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);

    const isiframe = (input: HTMLElement | null): input is HTMLIFrameElement =>
      input !== null && input.tagName === 'IFRAME';
    const iframe = document.getElementById('editorIframe');
    if (isiframe(iframe)) iframe.contentWindow?.document.addEventListener('mousedown', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);

      if (isiframe(iframe)) iframe.contentWindow?.document.removeEventListener('mousedown', listener);
    };

    // Reload only if ref or handler changes
  }, [ref, handler]);
}

export default useOnClickOutside;
