import { createSelector } from 'reselect';
import { assign, del, get, insert, merge, push, set, update, wrap } from 'object-path-immutable';

import * as types from '../actions/actionTypes';

// ----------------------------------------------------------------------------
// Reducer
// ----------------------------------------------------------------------------

const initState = {
  isFetching: null,
  tables: [],
  selectedTable: null,
  rows: {},
  columns: {},
  relatedRows: {},
};

export default function blogsReducer(state = initState, action) {
  switch (action.type) {
    case types.DATA_TABLE_FETCH_ALL:
    case types.DATA_TABLE_REQUEST_TABLE_DATA:
      return {
        ...state,
        isFetching: true,
      };

    case types.DATA_TABLE_CREATE_TABLE_SUCCESS:
      return {
        ...state,
        tables: [...state.tables, action.payload],
      };

    case types.DATA_TABLE_FETCH_ALL_SUCCESS:
      return {
        ...state,
        isFetching: false,
        tables: [...action.payload],
      };

    case types.DATA_TABLE_ON_TABLE_SELECTION:
      return {
        ...state,
        selectedTable: action.payload,
      };

    case types.DATA_TABLE_CLEAR_TABLE_SELECTION:
      return {
        ...state,
        selectedTable: null,
      };

    case types.DATA_TABLE_RECEIVE_TABLE_COLUMNS:
      return {
        ...state,
        columns: action.payload.columns,
      };

    case types.DATA_TABLE_RECEIVE_TABLE_ROWS:
      return {
        ...state,
        rows: action.payload.rows,
      };

    case types.DATA_TABLE_RECEIVE_TABLE_DATA:
      return { ...state, isFetching: false };

    case types.DATA_TABLE_SAVE_RELATED_ROWS:
      return {
        ...state,
        relatedRows: { ...action.payload.relatedRows },
      };
    case types.DATA_TABLE_ADD_RELATED_ROW:
      return {
        ...state,
        relatedRows: {
          ...state.relatedRows,
          [action.payload.id]: action.payload,
        },
      };

    case types.DATA_TABLE_ON_CELL_UPDATE:
    case types.DATA_TABLE_UPDATE_ROW_SLUG_SUCCESS: {
      const rows = { ...state.rows };
      rows[action.payload.id] = action.payload;
      return { ...state, rows };
    }

    case types.DATA_TABLE_REORDER_COLUMNS_SUCCESS: {
      const columns = { ...state.columns };
      Object.values(columns).forEach((c) => {
        if (
          action.payload.oldIdx < action.payload.newIdx &&
          c.position > action.payload.oldIdx &&
          c.position <= action.payload.newIdx
        )
          c.position -= 1;
        else if (
          action.payload.oldIdx > action.payload.newIdx &&
          c.position < action.payload.oldIdx &&
          c.position >= action.payload.newIdx
        )
          c.position += 1;
        else if (c.id === action.payload.id) c.position = action.payload.newIdx;
      });

      return {
        ...state,
        columns,
      };
    }

    case types.DATA_TABLE_REORDER_ROWS_SUCCESS: {
      const { newIdx, oldIdx, id } = action.payload;
      const rows = { ...state.rows };
      Object.values(rows).forEach((r) => {
        if (oldIdx < newIdx && r.position > oldIdx && r.position <= newIdx) r.position -= 1;
        else if (oldIdx > newIdx && r.position < oldIdx && r.position >= newIdx) r.position += 1;
        else if (r.id === id) r.position = newIdx;
      });

      return {
        ...state,
        rows,
      };
    }

    case types.DATA_TABLE_UPDATE_COLUMN_SLUG_SUCCESS: {
      const { payload } = action;
      const columns = { ...state.columns };
      columns[payload.id] = payload;

      return { ...state, columns };
    }

    case types.DATA_TABLE_CREATE_ROW_SUCCESS: {
      return {
        ...state,
        rows: { ...state.rows, [action.payload.id]: action.payload },
      };
    }

    case types.DATA_TABLE_DELETE_ROW_SUCCESS: {
      return {
        ...state,
        rows: del(state.rows, action.payload),
      };
    }

    case types.DATA_TABLE_CREATE_COLUMN_SUCCESS: {
      return {
        ...state,
        columns: { ...state.columns, [action.payload.id]: action.payload },
      };
    }

    case types.DATA_TABLE_DELETE_COLUMN_SUCCESS: {
      return {
        ...state,
        columns: del(state.columns, action.payload),
      };
    }

    // Default
    // ------------------------------------------------------------------------

    default:
      return state;
  }
}

// ----------------------------------------------------------------------------
// Selectors
// ----------------------------------------------------------------------------

function selectLocalState(globalState) {
  return globalState.dataTables;
}

export function selectIsFetching(globalState) {
  const state = selectLocalState(globalState);
  return state.isFetching;
}

export const selectTables = createSelector(selectLocalState, (dataTables) => dataTables.tables);

export const selectRelatedRows = createSelector(selectLocalState, (dataTables) => dataTables.relatedRows);

export const selectSelectedTable = createSelector(selectLocalState, (dataTables) => dataTables.selectedTable);

const selectRows = createSelector(selectLocalState, (dataTables) => dataTables.rows);

const selectColumns = createSelector(selectLocalState, (dataTables) => dataTables.columns);

export const selectTableColumn = createSelector(selectColumns, (_columns) => {
  const columns = Object.values(_columns);
  const renderableColumns = [
    { name: 'Slug ID', key: 'row_slug', frozen: true },
    ...columns.map((column) => ({
      ...column,
      name: column.slug,
      key: column.id,
      position: column.position,
      resizable: true,
    })),
  ];
  renderableColumns.sort((a, b) => {
    return a.position - b.position;
  });
  return renderableColumns;
});

export const selectTableRow = createSelector(selectRows, selectColumns, (_rows, _columns) => {
  const rows = Object.values(_rows);
  const columns = Object.values(_columns);
  rows.sort((a, b) => {
    return a.position - b.position;
  });
  const renderableRows = rows.map((row) => {
    return Object.assign(
      { row_slug: row.slug, row_uuid: row.id },
      ...columns.map((column) => ({
        [column.id]: row.content[column.id] || column.default,
      }))
    );
  });
  return renderableRows;
});

export const selectTableById = (state, id) => {
  const tables = selectTables(state);
  return tables.find((table) => table.id === id);
};

export const selectRelatedRowById = (state, id) => {
  const relatedRows = selectRelatedRows(state, id);
  return relatedRows[id];
};
