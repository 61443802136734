import React, { useEffect } from 'react';

import DocumentTitle from '../DocumentTitle';
import Tabs, { TABS_OPTIONS } from './Research/Tabs';
import withQueryParams from 'containers/withQueryParams';

export default withQueryParams(function Insights(props) {
  const currentTab = props.queryParams.tab || Object.keys(TABS_OPTIONS)[0];
  const ActiveTab = TABS_OPTIONS[currentTab][0];

  useEffect(() => {
    props.updateQueryParams({ tab: currentTab });
  }, []);

  return (
    <>
      <DocumentTitle title={(siteName) => `${siteName}'s research`} />
      <ActiveTab />
    </>
  );
}, {});
