import isString from 'lodash/isString';

export default function toPlaintext(delta) {
  if (delta == null) return '';
  if (isString(delta)) return delta;

  // Iterate over delta ops and concatenate text.
  let str = delta.ops.reduce(function (text, op) {
    if (!op.insert) throw new TypeError('only `insert` operations can be transformed!');
    if (typeof op.insert !== 'string') return text + ' ';
    return text + op.insert;
  }, '');

  // Remove newline characters
  str = str.replace(/\n/g, '');

  return str;
}
