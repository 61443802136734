import React from 'react';
import { useSelector } from 'react-redux';
import { selectNotificationBySiteDomain } from 'reducers/notificationReducer';
import BottomDrawer from '../base/BottomDrawer';
import MediaDrawerBottom from '../base/Media/Drawer/MediaDrawerBottom';

import useUrlSite from 'hooks/useUrlSite';

export default () => {
  const urlSite = useUrlSite();
  const notificationBarActive = useSelector((state) => selectNotificationBySiteDomain(state, urlSite));
  return (
    <BottomDrawer isAdminPanel extendBody hideScroll isOpen notificationBarActive={notificationBarActive}>
      <MediaDrawerBottom category="all" entity={{}} onChange={() => {}} hideStockMedia showAll />
    </BottomDrawer>
  );
};
