/* eslint-disable jsx-a11y/anchor-is-valid */

import React from 'react';
import { compose } from 'redux';
import withSite from '../../containers/withSite';
import { connect } from 'react-redux';
import * as siteSelectors from '../../reducers/sitesReducer';
import * as blogActions from '../../actions/blogActions';
import * as blogSelectors from '../../reducers/blogsReducer';
import * as uiBlogSettingsActions from '../../actions/uiBlogSettingsActions';
import * as editorSelectors from '../../reducers/editorReducer';
import { updateSubmittingState } from '../../actions/uiSubmitButtonsStateActions';
import { selectHasPermission } from '../../reducers/policyReducer';

import adminBarStyles from '../AdminBar.module.scss';
import styles from './EditorAdminBar.module.scss';
import { ReactComponent as SvgIconSettings } from '../../assets/images/icons8-settings.svg';
import { ReactComponent as SvgIconUnstackles } from '../../assets/images/icon-sparkles.svg';
import { ReactComponent as SvgIconEdit } from '../../assets/images/icon-edit-field.svg';
import { ReactComponent as SvgIconLink } from '../../assets/images/icon-link.svg';
import { ReactComponent as SvgIconHelp } from '../../assets/images/icon-help-circle.svg';

import { CSSTransition } from 'react-transition-group';
import AdminBar from '../AdminBar';
import classnames from 'classnames';
// import FloatingPublishButton from './FloatingPublishButton';
import truncate from 'lodash/truncate';
import { promoptConformation } from 'lib/promp-confirmation';

import throttle from 'lodash/throttle';

function mapStateToProps(state, ownProps) {
  const { site, blogId } = ownProps;
  const blog = blogSelectors.selectBlog(state, blogId);
  const blogDraft = blogSelectors.selectDraft(state, blogId);
  const urlSite = siteSelectors.getUrlSiteFromSite(site);

  if (blog == null || (blog && blog.content == null) || blog.id == null) {
    return {
      isLoading: true,
      urlSite: urlSite,
    };
  } else {
    return {
      isLoading: false,
      urlSite: urlSite,
      scheme: site.scheme,
      siteDomain: siteSelectors.getFullDomainFromSite(site),
      blogTitle: blogDraft.title,
      blogName: blogDraft.name,
      blogSlug: blogDraft.slug,
      blogDescription: blog.meta.description,
      blogStatus: blog.status,
      blogIsChanged: blog.is_changed,
      blogHasUnsavedChanges: blogSelectors.selectHasUnsavedChanges(state, blogId),
      blogIsPublishing: blog.isPublishing,
      isUpdating: blog.isUpdating,
      showPublishSuccessMessage: editorSelectors.selectShowPublishSuccessMessage(state),
      hasPermission: selectHasPermission(state),
    };
  }
}

const mapDispatchToProps = {
  updateSubmittingState,
  addOps: blogActions.addAutosaveOps,
  publishBlog: blogActions.requestPublish,
  discardBlog: blogActions.requestDiscard,
  openBlogSettings: uiBlogSettingsActions.open,
};

class EditorAdminBar extends React.Component {
  state = {
    hideFloatingButton: true,
    editorIsFresh: true,
  };

  escFunction(event) {
    if (event.key === 'Escape') {
      if (this.state.editing) {
        this.inputField.current.blur();
        this.setState({ text: this.state.initText });
        this.updateName(this.state.initText);
      }
    }
  }

  componentDidMount() {
    this.setState({ text: this.props.blogTitle === null ? 'New blog' : this.props.blogTitle });
    document.addEventListener('keydown', this.escFunction, false);
  }
  componentWillUnmount() {
    document.removeEventListener('keydown', this.escFunction, false);
  }
  updateName = throttle((text) => {
    if (this.state.initName === this.state.initText || this.state.initText === 'New blog') {
      this.props.addOps(this.props.blogId, {
        type: 'set',
        path: 'name',
        value: text || this.state.text,
      });
    }
    this.props.addOps(this.props.blogId, {
      type: 'set',
      path: 'meta.title',
      value: text || this.state.text,
    });
    this.props.updateSubmittingState(true, {});
    // this.props.requestPageInfoUpdate(this.props.blogId);
  }, 2000);

  componentDidUpdate(prevProps, prevState) {
    if (!this.props.isLoading && prevProps.isLoading) {
      this.setState({ text: this.props.blogTitle || 'New blog' });
    }
    if (this.props.blogTitle !== prevProps.blogTitle && !this.state.editing && this.props.blogTitle) {
      this.setState({ text: this.props.blogTitle });
    }
    if (prevProps.blogId === this.props.blogId) {
      if (prevProps.blogIsChanged && !this.props.blogIsChanged) {
        // Mark the editor as fresh, meaning that there are no draft edits.
        this.setState({ editorIsFresh: true });
      } else if (!prevProps.blogHasUnsavedChanges && this.props.blogHasUnsavedChanges) {
        // Mark the editor as dirty (i.e. not fresh) when the user starts editing.
        this.setState({ editorIsFresh: false });
      }
    } else {
      // The blogId has changed, so reset the editor.
      this.setState({
        editorIsFresh: false,
      });
    }
  }

  handleAdminBarOpacityChange = (opacity) => {
    this.setState({ hideFloatingButton: opacity > 0 });
  };

  handleClickInfo = (e) => {
    e.stopPropagation();
    const { blogId, openBlogSettings } = this.props;
    openBlogSettings(blogId);
  };

  handleClickPublish = (e) => {
    const { blogId, blogStatus, publishBlog, openBlogSettings } = this.props;

    if (blogStatus === 'draft') openBlogSettings(blogId);
    else publishBlog(blogId);
  };

  handleClickDiscard = () => {
    const { discardBlog, blogId } = this.props;
    promoptConformation('blog', () => discardBlog(blogId));
  };

  render() {
    const {
      isLoading,
      urlSite,
      isUpdating,
      scheme,
      siteDomain,
      blogSlug,
      blogStatus,
      blogIsChanged,
      blogHasUnsavedChanges,
      blogIsPublishing,
      showPublishSuccessMessage,
      hasPermission,
    } = this.props;

    const { editorIsFresh } = this.state;

    let linkUrl, blogLink, displayUrl;
    let autoSaveMessage = '';

    const inputVal = isLoading ? '' : this.state?.text;
    const isPublished = blogStatus === 'published';
    if (blogSlug === '') displayUrl = siteDomain;
    else if (blogSlug != null) displayUrl = siteDomain + '/' + blogSlug;
    if (blogStatus === 'published') linkUrl = (scheme || 'https') + '://' + displayUrl;
    displayUrl = truncate(displayUrl, { length: 40 });

    const onTitleFormSubmit = (ev) => {
      ev.preventDefault();
      ev.target.children[0].blur();
      this.updateName(inputVal);
    };

    if (linkUrl) {
      blogLink = (
        <a href={linkUrl} data-test-id="site-page-url" target="_blank" rel="noopener noreferrer" className={styles.url}>
          {displayUrl}
        </a>
      );
      // Autosave message
      if (blogHasUnsavedChanges || isUpdating) autoSaveMessage = 'Saving changes...';
      else if (editorIsFresh) autoSaveMessage = '';
      else autoSaveMessage = 'Changes saved';
    }

    const showSaveMessage = autoSaveMessage !== 'Changes saved' || blogIsPublishing;
    const autoSaveColor = autoSaveMessage === 'Publishing...' ? '#647FB4' : '#7D8FB2';
    const showDiscardChanges = blogIsChanged && blogStatus === 'published' && hasPermission('Blog:discard');
    let publishText = !isPublished ? 'Publish' : blogIsChanged ? 'Publish changes' : 'Published';
    const publishWidth = blogIsPublishing ? 60 : publishText == 'Published' || publishText === 'Publish' ? 120 : 180;

    return (
      <>
        <AdminBar
          fixed
          backTo={`/${urlSite}/blogs`}
          isSaving={isUpdating}
          left={
            <>
              <div className={styles.leftText}>
                <span className={styles.inputWrap}>
                  <span class={styles.widthControl}>{inputVal}</span>
                  <form className={styles.titleForm} onSubmit={onTitleFormSubmit}>
                    <input
                      ref={this.inputField}
                      onBlur={() => {
                        this.setState({ editing: false });
                      }}
                      onMouseEnter={() => this.setState({ blogTitleHover: true })}
                      onMouseLeave={() => this.setState({ blogTitleHover: false })}
                      onFocus={(e) => {
                        e.target.select();
                        this.setState({ editing: true, initText: this.state.text, initName: this.props.blogName });
                      }}
                      onChange={(e) => {
                        this.setState({ text: e.target.value });
                        this.updateName(e.target.value);
                      }}
                      spellCheck="false"
                      value={inputVal}
                      disabled={isLoading}
                      tabIndex={2}
                    />
                  </form>
                </span>
              </div>
              <SvgIconEdit
                className={classnames(styles.editIcon, {
                  [styles.shownIcon]: this.state.blogTitleHover && !this.state.editing,
                })}
              />
              {!isLoading && isPublished && (
                <a href={linkUrl} target="_blank" className={styles.iconLink}>
                  <SvgIconLink data-tip="Open published URL" data-place="bottom" />
                </a>
              )}
            </>
          }
          right={
            <section className={styles.right} data-test-id="editor-admin-bar-right">
              {showSaveMessage && (
                <div class={styles.saveLabel} style={{ color: autoSaveColor }}>
                  {blogIsPublishing ? 'Publishing...' : autoSaveMessage}
                </div>
              )}
              {showDiscardChanges && (
                <div onClick={this.handleClickDiscard} className={styles.discardChanges}>
                  <span>↺</span> Discard changes
                </div>
              )}
              <button
                data-test-id="editor-admin-bar-publish"
                className={`${styles.publish}`}
                onClick={!blogIsPublishing ? this.handleClickPublish : null}
                disabled={(hasPermission && !hasPermission('Blog:publish')) || !blogIsChanged}
                style={{ width: publishWidth }}
              >
                {blogIsPublishing ? <img src={spinnerCircle} className={styles.spinner} /> : <span>{publishText}</span>}
              </button>
              <button className={adminBarStyles.circleIcon} onClick={this.handleClickInfo} aria-label="settings">
                <SvgIconSettings />
              </button>
              <button
                className={classnames(adminBarStyles.circleIcon, styles.helpIcon)}
                onClick={() => window.open('https://support.elasticpath.com', '_blank')}
                aria-label="help"
              >
                <SvgIconHelp />
              </button>
            </section>
          }
          foreGround={
            <CSSTransition
              in={showPublishSuccessMessage}
              classNames={styles.successMsg}
              timeout={300}
              mountOnEnter
              unmountOnExit
              appear
            >
              {(state) => (
                <div className={styles.successMsg} data-test-id="success-message">
                  <div>
                    <SvgIconUnstackles />
                    <strong>Success! </strong> Published to {blogLink}
                  </div>
                </div>
              )}
            </CSSTransition>
          }
        />
      </>
    );
  }
}

export default compose(withSite, connect(mapStateToProps, mapDispatchToProps))(EditorAdminBar);
