import React from 'react';
import { ReactComponent as SvgIconAlignLeft } from '../../../../assets/images/icon-align-left.svg';
import { ReactComponent as SvgIconAlignCenter } from '../../../../assets/images/icon-align-center.svg';
import styles from './TextAlign.module.scss';

function TextAlign(props) {
  const { value, onChange, options = ['left', 'center'] } = props;

  return (
    <div className={styles.container}>
      {options.includes('left') && (
        <button data-tip="Left-align" className={value === 'left' ? 'active' : null} onClick={() => onChange('left')}>
          <SvgIconAlignLeft />
        </button>
      )}
      {options.includes('center') && (
        <button data-tip="Center" className={value === 'center' ? 'active' : null} onClick={() => onChange('center')}>
          <SvgIconAlignCenter />
        </button>
      )}
      {options.includes('right') && (
        <button
          data-tip="Right-align"
          className={value === 'right' ? 'active' : null}
          onClick={() => onChange('right')}
        >
          <SvgIconAlignLeft style={{ transform: 'rotateY(180deg)' }} />
        </button>
      )}
    </div>
  );
}

export default TextAlign;
