import * as actionTypes from './actionTypes';

export function requestAll(siteId) {
  return {
    type: actionTypes.GOALS_REQUEST_ALL,
    payload: { siteId },
  };
}

export function requestAllIfNeeded(siteId) {
  return {
    type: actionTypes.GOALS_REQUEST_ALL_IF_NEEDED,
    payload: { siteId },
  };
}

export function receiveAll(goalIds, entities) {
  const receivedAt = new Date();

  return {
    type: actionTypes.GOALS_RECEIVE_ALL,
    payload: { goalIds, entities, receivedAt },
  };
}

export function receiveError(error) {
  return {
    type: actionTypes.GOALS_RECEIVE_ERROR,
    payload: { error },
  };
}
