import React from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useParams } from 'react-router-dom';
import DocumentTitle from 'react-document-title';

import * as siteSelectors from '../../reducers/sitesReducer';
import * as contactsSelectors from '../../reducers/contactsReducer';
import { selectHasPermission } from '../../reducers/policyReducer';
import * as accountSelectors from 'reducers/accountReducer';
import * as accountAcctions from 'actions/accountActions';

import AccountForm from './Settings/AccountForm';
import ResetPassword from './Settings/ResetPassword';
import Domain from './Settings/Domain';
import Users from './Settings/Users';
import Subscription from './Settings/Subscription';
import Notifications from './Settings/Notifications';
import Gdpr from './Settings/Gdpr';
import MFAConfiguration from './Settings/MFAConfiguration';
import DangerZone from './Settings/DangerZoneOptions';

import styles from './Settings.module.scss';
import AdvancedSEO from './Settings/AdvancedSEO';

import queryString from 'query-string';

import { open as openSubscriptionManager } from '../../actions/uiSubscriptionManagerActions';
import APIKeys from './Settings/APIKeys';
import { OnMount } from 'hooks/mountUnmountHooks';
import { PARTNERS } from '../../constants';

function Settings(props: any) {
  const location = useLocation();
  const { urlSite } = useParams();
  const dispatch = useDispatch();

  // @ts-ignore
  const site = useSelector((state) => siteSelectors.selectSiteByDomain(state, urlSite));
  const hasPermission = useSelector(selectHasPermission);
  const mfa = useSelector(accountSelectors.selectAccountMFAInfo);
  const partnerID = useSelector((state: any) => state.account?.partner);

  OnMount(() => {
    const { call } = queryString.parse(location.search);
    if (call && props[call as string]) {
      setTimeout(() => {
        props[call as string]();
      }, 0);
    }
    dispatch(accountAcctions.getMFAIfNeeded());
  });

  const isShopifyPartner = partnerID === PARTNERS.Shopify || partnerID === PARTNERS.KlevuShopify;

  return (
    <>
      <DocumentTitle title={`${site.name}'s settings`} />

      {/* Account */}
      <section id="account">
        <header>
          <h1>Account</h1>
        </header>
        <article className={styles.account}>
          {hasPermission('Account:update') && <AccountForm />}
          <div className={styles.links}>
            <Link to="/logout">Log out</Link>
            <ResetPassword />
            <MFAConfiguration mfa={mfa} />
          </div>
        </article>
      </section>

      {/* Users */}
      {hasPermission('AccountUser:list') && <Users />}

      {/* APIKeys */}
      {hasPermission('APIKey:*') && <APIKeys />}

      {/* Notifications */}
      {hasPermission('Contact') && <Notifications />}

      {/* GDPR messaging */}
      {hasPermission('Site:update', 'role') && <Gdpr />}

      {/* Domain */}
      {hasPermission('Site:set_domain', 'role') && <Domain />}

      {/* AdvancedSEO */}
      <AdvancedSEO />

      {/* Subscription */}
      {hasPermission('AccountSubscription:list') && !isShopifyPartner && <Subscription />}

      {/* Danger Zone */}
      {hasPermission('Site:destroy') && <DangerZone />}
    </>
  );
}

export default Settings;
