import React, { useState, useMemo, useEffect } from 'react';

import get from 'lodash/get';
import { compose } from 'redux';
import debounce from 'lodash/debounce';
import { Link } from 'react-router-dom';
import DocumentTitle from 'react-document-title';

import Search from 'components/Search';
import { Hierarchy } from './Hierarchy';
import Spinner from 'components/base/Spinner';
import List from 'components/react-table/List';
import Thumbnail from 'components/base/Thumbnail';
import { useStorefrontCollections, useSyncProducts } from './hooks';
import ZeroState, { Header, Image, Muted, Group } from 'components/AdminPanel/ZeroState';

import useUrlSite from 'hooks/useUrlSite';
import withQueryParams from 'containers/withQueryParams';
import withIntegrations from 'containers/withIntegrations';

import iconShopify from '../../assets/images/icon-shopify.svg';
import { ReactComponent as LinkRedirect } from '../../assets/images/icon-offsite.svg';

import styles from './Storefront.module.scss';

export default compose(withIntegrations, withQueryParams)((props) => {
  const urlSite = useUrlSite();
  const [query, setQuery] = useState(undefined);
  const params = new URLSearchParams(location.search);

  const [searchParams, setSearchParam] = useState({ search: params.get('search') || undefined });
  const { nextPage, search } = searchParams;

  const shopifyIntegrationList = get(props.integrationsBySlug, 'shopify');
  const elasticPathIntegrationList = get(props.integrationsBySlug, 'elastic_path');
  const api2cartIntegrationList = get(props.integrationsBySlug, 'api2cart');
  const shopifyIntegration = shopifyIntegrationList && shopifyIntegrationList[0];
  const elasticPathIntegration = elasticPathIntegrationList && elasticPathIntegrationList[0];
  const api2cartIntegration = api2cartIntegrationList && api2cartIntegrationList[0];

  const [collectionData, isLoading] = useStorefrontCollections(search, 10, undefined, nextPage);
  const { data, links } = collectionData;
  const [paginatedData, setPaginatedData] = useState(data || []);
  const [dataSync, isLoadingSync] = useSyncProducts();

  const isFetching = props.integrationsFetching || isLoading || isLoadingSync;
  const columns = useMemo(() => getColumns(shopifyIntegration), [data, shopifyIntegration]);
  useEffect(() => {
    setPaginatedData([...paginatedData, ...(data || [])]);
  }, [data]);
  const debounceSearch = debounce((search) => {
    setSearchParam({ search });
    setPaginatedData([]);
    history.pushState({}, '', location.pathname + '?search=' + search);
  }, 300);
  const manageLink = useMemo(() => {
    if (elasticPathIntegration?.config?.api_path.includes('moltin')) {
      return 'https://euwest.cm.elasticpath.com';
    } else return elasticPathIntegration?.config?.api_path.replace('.api.', '.cm.');
  }, [elasticPathIntegration?.config?.api_path]);

  return (
    <>
      <DocumentTitle title={'Collections'} />
      <section>
        <header className={styles.header}>
          <div>
            <h1>{shopifyIntegration ? 'Collections' : 'Categories'}</h1>
            {elasticPathIntegration && (
              <small>
                <a className="action" href={`${manageLink}/products`} target="_blank">
                  Manage in Product Experience Manager <LinkRedirect />
                </a>
              </small>
            )}
            {shopifyIntegration && (
              <small>
                <a href={`https://${shopifyIntegration?.config?.shop}.myshopify.com/admin/collections`} target="_blank">
                  Manage in Shopify <LinkRedirect />
                </a>
              </small>
            )}
          </div>
          {shopifyIntegration && (
            <div className={styles.searchContainer}>
              <Search
                value={query === undefined ? search : query}
                onChange={(query) => setQuery(query)}
                placeholder="Search"
                debounceValue={(value) => {
                  if (value !== undefined) debounceSearch(value);
                }}
                containerStyle={{
                  marginRight: '1rem',
                }}
              />
            </div>
          )}
        </header>
      </section>
      <section>
        {isFetching && !paginatedData.length ? (
          <Spinner className="fixed" />
        ) : (
          <div className={styles.collectionList}>
            {paginatedData.length ? (
              <>
                <List
                  data={paginatedData}
                  columns={columns}
                  defaultPageSize={paginatedData.length}
                  loading={isLoading}
                  showViewAll={false}
                  infiniteScroll
                  updateCursor={() => {
                    if (links.next) {
                      setSearchParam({ ...searchParams, nextPage: links.next });
                    }
                  }}
                  showHeader
                />
                {isLoading && <Spinner style={{ margin: 'auto' }} />}
              </>
            ) : search ? (
              <ZeroState>
                <Image src={iconShopify} />
                <Header>No collection matches your criteria</Header>
                <Muted>
                  Your search criteria does not match a title in your collections' list. Try a different set of
                  criteria.
                </Muted>
              </ZeroState>
            ) : data?.count === 0 && dataSync?.count !== 0 ? (
              <></>
            ) : (
              <ZeroState>
                <Image src={iconShopify} />
                <Header>No collection(s) available</Header>
                <Muted>
                  There are currently no collection available on this account. Try re-syncing your integration or add a
                  new collection in your Shopify panel.
                </Muted>
              </ZeroState>
            )}
            {Boolean(
              shopifyIntegration === undefined &&
                !elasticPathIntegration &&
                api2cartIntegration === undefined &&
                !isFetching
            ) && (
              <ZeroState>
                <Image src={iconShopify} />
                <Header>Shopify integration not connected</Header>
                <Muted>
                  You will have your Shopify collections listed here when you connect Elastic Path Studio with Shopify
                </Muted>
                <Group>
                  <Link to={`/${urlSite}/integrations`} className="button button-primary">
                    Connect to Shopify
                  </Link>
                </Group>
              </ZeroState>
            )}
          </div>
        )}
      </section>
    </>
  );
}, {});

const getColumns = (shopifyIntegration) => {
  const shopifyColumns = [
    {
      Header: 'Name',
      id: 'name',
      Cell: ({ original: collection }) => {
        return (
          <div className={styles.title}>
            <Thumbnail
              images={collection.product_media.map((product) => product.url)}
              length={collection.product_media.length}
              size={40}
            />
            <span>{collection.attributes.name}</span>
          </div>
        );
      },
    },
  ];
  const epColumns = [
    {
      Header: 'Name',
      id: 'name',
      Cell: ({ original }) => {
        return <Hierarchy {...original} />;
      },
    },
  ];
  return shopifyIntegration ? shopifyColumns : epColumns;
};
