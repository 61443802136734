/* eslint-disable jsx-a11y/anchor-is-valid */

import React from 'react';
import classnames from 'classnames';
import styles from './Header.module.scss';
import sparkLogo from '../../assets/images/elastic-path-logo-wht.svg';

export default function Header(props) {
  const { center, right, progress, complete } = props;

  return (
    <header className={classnames(styles.header, { [styles.complete]: complete })}>
      {center && <div className={styles.center}>{center}</div>}
      {!complete && (
        <div className={styles.left}>
          <a href="https://www.unstack.com">
            <img className={styles.sparkLogo} src={sparkLogo} alt="Elastic Path" />
          </a>
        </div>
      )}
      {right && <div className={styles.right}>{right}</div>}
      {progress > 0 && (
        <div className={styles.progressBar}>
          <div className={styles.progressMade} style={{ width: `${progress * 100}%` }} />
        </div>
      )}
    </header>
  );
}
