import React from 'react';

import styles from './UpDownButtons.module.scss';
import { ReactComponent as SvgIconChevronLeft } from '../../../../assets/images/icon-cheveron-left.svg';

import { Button } from '../Toolbar2';
import classnames from 'classnames';

export default function UpDownButtons(props) {
  const { upProps, downProps, tooltip } = props;

  // Merge up/down classnames with passed className props
  upProps.className = classnames(styles.up, upProps.className);
  downProps.className = classnames(styles.down, downProps.className);

  return (
    <div className={styles.UpDownButtons} data-tip={tooltip}>
      <Button {...upProps} tabIndex="-1" data-test-id="up">
        <SvgIconChevronLeft />
      </Button>
      <Button {...downProps} tabIndex="-1" data-test-id="down">
        <SvgIconChevronLeft />
      </Button>
    </div>
  );
}
