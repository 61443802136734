/* eslint-disable jsx-a11y/anchor-is-valid */

import React from 'react';
import * as siteActions from 'actions/siteActions';
import { useDispatch } from 'react-redux';

export default function TemplateDefault(props: { entityId: string }) {
  const { entityId } = props;

  const dispatch = useDispatch();

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    dispatch(siteActions.update({ default_pdp: entityId }));
  };

  return (
    <a href="#" onClick={handleClick}>
      set as default
    </a>
  );
}
