import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import DocumentTitle from 'react-document-title';
import queryString from 'query-string';
import idx from 'lodash/get';

import { useDispatch, useSelector } from 'react-redux';
import * as accountActions from '../../actions/accountActions';
import * as accountSelectors from '../../reducers/accountReducer';

import FormControl from '../base/FormHelpers/FormControl';

import styles from './KlevuMagentoOnboarding.module.scss';
import { ReactComponent as SepiaBrand } from 'assets/images/elastic-path-studio-logo.svg';
import { ReactComponent as KlevuLogo } from 'assets/images/klevu-logo.svg';
import { ReactComponent as AdobeLogo } from 'assets/images/adobe-logo.svg';
import { ReactComponent as UnstackLogo } from 'assets/images/unstack-mini-logo.svg';
import { ReactComponent as SvgSearch } from 'assets/images/icon-shirt.svg';
import { ReactComponent as SvgPhone } from 'assets/images/icon-phone.svg';
import { ReactComponent as SvgPlug } from 'assets/images/icon-plug.svg';
import profile from 'assets/images/profile.png';

import { OnMount } from 'hooks/mountUnmountHooks';
import { LogosBanner } from './LogosBanner';
import { createKlevuMagentoAccount } from 'services/spark-api';
import { initializeApp } from 'actions/appActions';

const ShopifyKlevuLogin = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const account = useSelector(accountSelectors.selectAccount);
  const errors = account.loginErrors;
  const location = useLocation();
  const { state, klevu_store_info } = location.state || {};

  const { isLoggedIn, isFetching, isChallenged } = useSelector((state) => ({
    isLoggedIn: accountSelectors.selectIsLoggedIn(state),
    isChallenged: accountSelectors.selectIsChallenged(state),
    isFetching: accountSelectors.selectAccountIsFetching(state),
  }));

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    if (name === 'username') {
      setUsername(value);
    } else if (name === 'password') {
      setPassword(value);
    }
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const resp = await createKlevuMagentoAccount(state, password);
    if (resp.ok) {
      dispatch(accountActions.loginSuccess(resp.json.token));
      dispatch(initializeApp());
    }
  };

  const username_errors = idx(errors, 'username');
  const password_errors = idx(errors, 'password');
  const url_errors = idx(errors, 'url');
  const general_errors =
    errors &&
    Object.keys(errors).reduce(
      (accumulator, id) => (id === 'password' || id === 'username' ? accumulator : `${errors[id]} ${accumulator}`),
      ''
    );

  OnMount(() => {
    if (isLoggedIn) {
      const { ref } = queryString.parse(window.location.search);
      navigate((ref as string) || '/');
    }

    if (isChallenged) {
      navigate('/verify');
    }
  });

  return (
    <div className="admin" style={{ overflow: 'hidden' }}>
      <DocumentTitle title="Log in to Elastic Path Studio" />
      <main className={styles.Login} data-test-id="login-page-container">
        <div className={styles.contentContainer}>
          <div style={{ flex: 1 }} className={styles.signup}>
            <div className={styles.banner}>
              <SepiaBrand className={styles.brand} />
              <span>+</span>
              <span>
                <KlevuLogo />
              </span>
            </div>
            <div className={styles.container}>
              {/* @ts-ignore */}
              <form onSubmit={handleSubmit} disabled={isFetching}>
                <fieldset>
                  <label htmlFor="url">Store URL</label>
                  <FormControl
                    type="url"
                    aria-label="url"
                    placeholder={klevu_store_info.storeUrl}
                    name="url"
                    autoComplete="url"
                    onChange={handleInputChange}
                    errors={url_errors}
                    className={styles.disabled}
                    disabled={true}
                  />
                </fieldset>
                <fieldset>
                  <label htmlFor="email">Email</label>
                  <FormControl
                    type="email"
                    aria-label="email"
                    placeholder={klevu_store_info.email}
                    name="username"
                    autoComplete="email"
                    onChange={handleInputChange}
                    errors={username_errors}
                    className={styles.disabled}
                    disabled={true}
                  />
                </fieldset>
                <fieldset>
                  <label htmlFor="password">Create a password</label>
                  <FormControl
                    type="password"
                    aria-label="password"
                    placeholder="Password"
                    name="password"
                    autoComplete="current-password"
                    onChange={handleInputChange}
                    errors={password_errors}
                    autofocus
                  />
                </fieldset>
                <div>
                  <input
                    type="submit"
                    className={styles.submit}
                    value={isFetching ? 'Signing in...' : 'Create my free account'}
                  />
                  {general_errors && (
                    <small key="FormControl-errorMessage" className="errorMessage" data-test-id="error">
                      {general_errors}
                    </small>
                  )}
                  <small className={styles.terms}>
                    By using Elastic Path you agree to our{' '}
                    <a href="https://www.unstack.com/terms-of-use/" target="_blank" rel="noopener noreferrer">
                      Terms of Service
                    </a>{' '}
                    and{' '}
                    <a href="https://www.unstack.com/privacy-policy/" target="_blank" rel="noopener noreferrer">
                      Privacy Policy
                    </a>
                    .
                  </small>
                </div>
              </form>
              <div className={styles.adobe}>
                <AdobeLogo /> Made for Adobe Commerce
              </div>
            </div>
          </div>

          <div className={styles.footer}>
            <LogosBanner />
          </div>
        </div>
        <div
          className={`${styles.contentContainer} ${styles.bgContainer}`}
          style={{ height: '100vh', backgroundSize: 'cover', backgroundPosition: 'center' }}
        >
          <div className={styles.signup}>
            <div className={styles.title}>Your first 3 landing pages are free!</div>
            <div className={styles.iconTable}>
              <div className={styles.icon}>
                <UnstackLogo />
              </div>
              <div>Point & click page building</div>
              <div className={styles.icon}>
                <SvgSearch />
              </div>
              <div>Seamlessly connected to Adobe Commerce</div>
              <div className={styles.icon}>
                <SvgPhone />
              </div>
              <div>Mobile-ready by default</div>
              <div className={styles.icon}>
                <SvgPlug />
              </div>
              <div>30+ native integrations</div>
            </div>
            <div className={styles.commentContainer}>
              <div>
                “Elastic Path Studio's user friendly templates make it super easy to test custom landing pages in real
                time. Our first landing page exceeded our expectations and we're looking forward to testing future
                strategies.”
              </div>
              <div className={styles.profile}>
                <img src={profile}></img>
                <div className={styles.text}>
                  <div className={styles.name}>Jill Apgar</div>
                  <div>CocoBeans</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default ShopifyKlevuLogin;
