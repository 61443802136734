import React from 'react';
import { Route, Routes } from 'react-router-dom';
import ContactsIndex from './Contacts/ContactsIndex';
import ContactDetail from './Contacts/ContactDetail/ContactDetail';

export default function Customers() {
  return (
    <Routes>
      <Route path="/:contactId" element={<ContactDetail />} />
      <Route path="" element={<ContactsIndex />} />
    </Routes>
  );
}
