import React, { useMemo } from 'react';
import SlugFormControl from 'components/base/FormHelpers/SlugFormControl';
import useActiveSite from 'hooks/useActiveSite';

import styles from './LinkField.module.scss';
import { ReactComponent as HelpIcon } from '../../assets/images/icon-help-about.svg';

interface LinkFieldProps {
  id: string;
  isHomePage: boolean;
  PrefixedInput: React.ReactNode;
  prefix: string;
  page: {
    slug: string;
    status: string;
    name: string;
    item_type: string;
    data_table: boolean;
    meta: { [id: string]: any };
  };
  handleChange: (name: any, value: any) => void;
  type?: 'page' | 'blog';
  errors: any;
  pageUrl?: string;
}

interface Site {
  is_shopify: boolean;
  scheme: string;
  domain: string;
  subdomain: string;
  proxy_paths: {
    page: string;
    blog: string;
  };
  site_status: 'subdomain' | 'domain' | 'proxy';
  dns_status: 'active' | 'pending' | 'ssl_pending' | 'www_pending';
  is_domain_active: boolean;
}

const LinkField = (props: LinkFieldProps) => {
  const { isHomePage, PrefixedInput, prefix, page, handleChange, errors, type = 'page', id, pageUrl } = props;
  const currentSite: Site | null = useActiveSite();
  const isPdp = page.item_type === 'product';
  const isPlp = page.item_type === 'collection';

  const sitePrefix: string | null = useMemo(() => {
    if (!currentSite) {
      return null;
    }

    if (prefix) {
      return `${currentSite.scheme}://${prefix}`;
    }

    if (!currentSite.is_domain_active || !currentSite.domain) {
      return `${currentSite.scheme}://${currentSite.subdomain}.${document.location.host}/`;
    }

    if (currentSite.site_status === 'domain' && ['active', 'ssl_pending'].includes(currentSite.dns_status)) {
      return `${currentSite.scheme}://${currentSite.domain}/`;
    }

    if (currentSite.site_status === 'proxy') {
      return `${currentSite.scheme}://${currentSite.domain}${currentSite.proxy_paths[type]}/`;
    }

    if (currentSite.site_status === 'subdomain') {
      return `${currentSite.scheme}://${currentSite.subdomain}.${document.location.host}/`;
    }

    return `${currentSite.scheme}://${currentSite.subdomain}.${document.location.host}/`;
  }, [currentSite, prefix]);

  const formHelperText = useMemo(() => {
    if (isHomePage) {
      return 'The home page URL cannot be changed.';
    } else if (pageUrl && page.slug) {
      return `${pageUrl}`;
    }

    return 'Keep it short-and-readable for best SEO results.';
  }, [sitePrefix, isHomePage, page.slug]);

  const error = useMemo(() => {
    if (!page.slug) {
      return null;
    }

    const checks = [
      { regex: /^https:/, message: 'Path cannot begin with https:' },
      { regex: /^http:/, message: 'Path cannot begin with http:' },
      { regex: /www./, message: 'Path cannot contain www.' },
    ];

    let errorMessage: null | string = null;

    checks.map(({ regex, message }) => {
      if (!errorMessage && regex.test(page.slug)) {
        errorMessage = message;
      }
    });

    return errorMessage;
  }, [page.slug]);

  const shopifyTooltip = `
    <span>
      Configure your URL base in<br /> Shopify settings.
      <a
        target="_blank"
        href="https://support.unstack.com/hc/en-us/articles/4936525098263"
        style="color: white; text-decoration: underline;"
      >
        Learn more
      </a>
    </span>
  `;

  return (
    <fieldset>
      <label htmlFor="page_url_input" className={styles.title}>
        Page Path
        {Boolean(currentSite?.['is_shopify']) && (
          <HelpIcon
            data-tip={shopifyTooltip}
            data-html={true}
            data-clickable={true}
            data-delay-hide={200}
            data-place="left"
          />
        )}
      </label>
      <SlugFormControl
        id={id}
        tag={PrefixedInput}
        prefix=""
        type="text"
        name="slug"
        value={page.slug || ''}
        status={page.status}
        onChange={handleChange}
        imitate={isPdp || isPlp ? '' : page.meta.title}
        pageType={page.item_type}
        errors={errors.slug}
        disabled={isHomePage}
        autoComplete="off"
        allowHomePage
        isDataPage={page.data_table}
        alwaysImitate
      />
      {error ? <small>{error}</small> : null}
      <small>{formHelperText}</small>
    </fieldset>
  );
};

export default LinkField;
