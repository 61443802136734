import { call, put } from 'redux-saga/effects';
import * as accountActions from '../../actions/accountActions';
import * as uiActions from '../../actions/uiActions';

// Call the api that is provided, and check response for common errors,
export function* callSparkApi(api, ...args) {
  try {
    const response = yield call(api, ...args);

    // If request was unnauthorized, it means that the user is no longer logged
    // in, so log them out in the app.
    if (response.unauthorized) {
      yield put(accountActions.logout());
    }

    // If there was a server error (5xx), log the error. (and eventually we'll
    // add a notification system to let the user know)
    if (response.status >= 500 && response.status < 600) {
      console.error('Server responded with', response.status);
    }

    // Return the response object so the calling saga can use it.
    return response;
  } catch (err) {
    // If the fetch API throws an error, it's probably a network connectivity
    // error. Log it and notify the user.
    yield put(uiActions.connectionError());
    console.error(err);

    // Return a placeholder response object.
    return { ok: false, error: 'Connection error' };
  }
}
