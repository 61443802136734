import React from 'react';
import { connect } from 'react-redux';
import * as siteSelectors from '../../reducers/sitesReducer';
import * as siteLayoutSelectors from '../../reducers/siteLayoutReducer';
import * as siteActions from '../../actions/siteActions';

import StyleEditableOverrides from '../StyleEditableOverrides';

import DocumentTitle from 'react-document-title';
import Spinner from '../base/Spinner';
import isEmpty from 'lodash/isEmpty';
import { set, del, push, insert } from 'object-path-immutable';
import throttle from 'lodash/throttle';
import deepEqual from 'fast-deep-equal';

import styles from './Elements.module.scss';

import StyleOverrides from '../StyleOverrides';
import EditingBar from './Elements/EditingBar';
import Branding from './Elements/Branding';
import HeaderFooterEditor from './Elements/HeaderFooterEditor';
import PageDefaults from './PageDefaults';
import { selectHasPermission } from 'reducers/policyReducer';
import withUrlSite from 'containers/withUrlSite';
import { compose } from 'redux';

function mapStateToProps(state, ownProps) {
  const site = siteSelectors.selectSiteByDomain(state, ownProps.urlSite);

  return {
    isLoading: isEmpty(site) || site.id == null,
    site,
    layout: siteLayoutSelectors.selectLayoutById(state),
    headerSections: siteLayoutSelectors.selectHeaderSectionsList(state),
    footerSections: siteLayoutSelectors.selectFooterSectionsList(state),
    // isComponentsLoaded: getStatus(state, 'sectionTypes.requests.fetch.status', true).pending,
    hasPermission: selectHasPermission(state),
  };
}

const mapDispatchToProps = {
  updateSite: siteActions.update,
};

class Elements extends React.PureComponent {
  // --------------------------------------------------------------------------
  // State
  // --------------------------------------------------------------------------

  state = {
    draftSite: undefined,
  };

  isEditing = () => {
    return !!this.state.draftSite;
  };

  site = () => {
    return this.state.draftSite || this.props.site;
  };

  // --------------------------------------------------------------------------
  // Component lifecycle
  // --------------------------------------------------------------------------

  componentDidMount() {
    this.getPersistedDraftSite();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.draftSite !== this.state.draftSite) {
      this.checkDiffs();
    }
  }

  componentWillUnmount() {
    this.persistDraftSite();
    clearTimeout(this.publishSuccessTimeout);
  }

  // --------------------------------------------------------------------------
  // Session persistance
  // Persist the component's state on the window object between dismounts
  // --------------------------------------------------------------------------

  cacheKey() {
    return `ElementsDraftSite${this.props.site.id}`;
  }

  persistDraftSite() {
    window[this.cacheKey()] = this.state.draftSite;
  }

  getPersistedDraftSite() {
    const draftSite = window[this.cacheKey()];
    this.setState({ draftSite });
  }

  // --------------------------------------------------------------------------
  // Immutably update site props
  // --------------------------------------------------------------------------

  siteUpdaters = {
    set: (propPath, value) => {
      this.setState((state) => {
        const site = state.draftSite || this.props.site;
        return { draftSite: set(site, propPath, value) };
      });
    },
    push: (propPath, value) => {
      this.setState((state) => {
        const site = state.draftSite || this.props.site;
        return { draftSite: push(site, propPath, value) };
      });
    },
    unshift: (propPath, value) => {
      this.setState((state) => {
        const site = state.draftSite || this.props.site;
        return { draftSite: insert(site, propPath, value, 0) };
      });
    },
    del: (propPath) => {
      this.setState((state) => {
        const site = state.draftSite || this.props.site;
        return { draftSite: del(site, propPath) };
      });
    },
  };

  // --------------------------------------------------------------------------
  // Publish / Discard
  // --------------------------------------------------------------------------

  discardChanges = () => {
    this.setState({ draftSite: undefined });
    this.hidePublishSuccess();
  };

  publishChanges = () => {
    if (this.isEditing()) {
      this.props.updateSite(this.state.draftSite);
      this.setState({ draftSite: undefined });
      this.showPublishSuccess();
    }
  };

  showPublishSuccess = () => {
    this.setState({ showPublishSuccess: true });
    this.publishSuccessTimeout = setTimeout(this.hidePublishSuccess, 5000);
  };

  hidePublishSuccess = () => {
    clearTimeout(this.publishSuccessTimeout);
    this.setState({ showPublishSuccess: false });
  };

  // Periodically run a deep-equal of the props.site and state.draftSite. If
  // they are equal, then set draftSite to undefined to indicate the page is
  // no longer in editing mode. The function is throttled because deep-equals
  // are (sort of) expensive.
  checkDiffs = throttle(
    () => {
      this.setState((prevState, props) => {
        if (deepEqual(prevState.draftSite, props.site)) {
          return { draftSite: undefined };
        }
      });
    },
    200,
    { leading: false }
  );

  // --------------------------------------------------------------------------
  // Render
  // --------------------------------------------------------------------------

  render() {
    if (this.props.isLoading) return <Spinner className="fixed" size="1" />;

    const isEditing = this.isEditing();
    const site = this.site();

    return (
      <>
        <DocumentTitle title={`${site.name}'s header & footer`} />
        <StyleEditableOverrides loadAllFonts={false} site={site} />
        <EditingBar
          isEditing={isEditing}
          showPublishSuccess={this.state.showPublishSuccess}
          publishChanges={this.publishChanges}
          discardChanges={this.discardChanges}
        />
        <section className={styles.Elements}>
          <Branding site={site} siteUpdaters={this.siteUpdaters} />
        </section>
        {!!(this.props.headerSections.length || this.props.footerSections.length) &&
          this.props.hasPermission('SiteLayout:create') && <PageDefaults />}
        {!site.is_liquid_enabled && (
          <section>
            <header>
              <h1>Header & Footer</h1>
            </header>
            <HeaderFooterEditor
              layout={Object.values(this.props.layout)}
              site={site}
              siteUpdaters={this.siteUpdaters}
              editableLogo
            />
          </section>
        )}
      </>
    );
  }
}

export default compose(withUrlSite, connect(mapStateToProps, mapDispatchToProps))(Elements);

// Possible future sections
//
// <section className={styles.Elements}>
//   <header><h1>Typography</h1></header>
//   <article>&nbsp;</article>
// </section>
// <section className={styles.Elements}>
//   <header><h1>Color palette</h1></header>
//   <article>&nbsp;</article>
// </section>
// <section className={styles.Elements}>
//   <header><h1>Iconography</h1></header>
//   <article>&nbsp;</article>
// </section>
