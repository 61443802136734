import * as actionTypes from './actionTypes';

export function open(contentTestId) {
  return {
    type: actionTypes.UI_IN_PROGRESS_CONTENT_TEST_SETTINGS_OPEN,
    payload: { contentTestId },
  };
}

export function close() {
  return {
    type: actionTypes.UI_IN_PROGRESS_CONTENT_TEST_SETTINGS_CLOSE,
  };
}

export function update(payload) {
  return {
    type: actionTypes.UI_IN_PROGRESS_CONTENT_TEST_SETTINGS_UPDATE,
    payload: payload,
  };
}

export function requestEnd(contentTestId, variantId, shouldPublish) {
  return {
    type: actionTypes.UI_IN_PROGRESS_CONTENT_TEST_SETTINGS_REQUEST_END,
    payload: { contentTestId, variantId, shouldPublish: !!shouldPublish },
  };
}

export function receiveEndSuccess() {
  return {
    type: actionTypes.UI_IN_PROGRESS_CONTENT_TEST_SETTINGS_RECEIVE_END_SUCCESS,
  };
}

export function receiveEndError(errors) {
  return {
    type: actionTypes.UI_IN_PROGRESS_CONTENT_TEST_SETTINGS_RECEIVE_END_ERROR,
    payload: { errors },
  };
}
