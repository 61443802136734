import classnames from 'classnames';
import { IconError } from 'components/base/BaseIcons';
import { SectionHandlersInterface, SectionUiHandlersInterface } from 'components/unstack-components/types';
import React, { useRef, useState } from 'react';
import BackgroundLayer from './SectionElement/BackgroundLayer';
import ErrorBoundaryToolbar from './SectionElement/Toolbar/ErrorBoundaryToolbar';
import styles from './SectionError.module.scss';

type SectionErrorProps = {
  enableHeader?: boolean;
  sectionUiHandlers: SectionUiHandlersInterface;
  sectionHandlers: SectionHandlersInterface;
  componentId: string;
  valueWithDefaults?: any;
};

function SectionError(props: SectionErrorProps) {
  const [trayState, setTrayState] = useState(false);
  const ref = useRef(null);
  const sectionClassNames = classnames('section', `component-${props.componentId}`, 'editing', styles.ErrorWrapper);
  // @ts-ignore
  const filteredHandlers = { ...props.sectionHandlers, onCreateElement: undefined, onDuplicate: undefined };

  return (
    <section
      className={sectionClassNames}
      data-test-id="section"
      onClick={() => props.sectionUiHandlers.open()}
      ref={ref}
      key={props.componentId}
    >
      <ErrorBoundaryToolbar
        setTrayState={setTrayState}
        trayState={trayState}
        enableHeader={props.enableHeader}
        isOpen={props.sectionUiHandlers.isOpen}
        close={(e) => {
          e.stopPropagation();
          props.sectionUiHandlers.close();
        }}
        sectionHandlers={filteredHandlers}
        applyOpacity={
          props.sectionUiHandlers &&
          (props.sectionUiHandlers.applyOpacity || props.sectionUiHandlers.isRichTextToolbarActive)
        }
      />
      <BackgroundLayer domRef={ref} value={props.valueWithDefaults} />
      <div className={styles.ErrorContent}>
        <div className={styles.ErrorCard}>
          <IconError />
          <div className={styles.ErrorText}>
            <div className={styles.ErrorTitle}>Whoops!</div>
            <div>Error rendering this component. Please refresh this page to try again.</div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default SectionError;
