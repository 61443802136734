import React from 'react';
import idx from 'lodash/get';
import { ReactComponent as IconCheckmark } from '../../../../assets/images/icon-checked-circle-green.svg';

class KlaviyoForm extends React.PureComponent {
  componentDidMount() {
    // this.loadScript();
  }

  componentDidUpdate() {
    // this.loadScript();
  }

  componentWillUnmount() {
    // this.script && this.script.remove();
  }

  publicApiKey() {
    return idx(this.props.integrationsByType, 'klaviyo[0].config.publicApiKey');
  }

  handleScriptLoad = (e) => {
    console.log('Kavlio script loaded');
  };

  loadScript() {
    if (
      !this.publicApiKey()
      // || this.script
    )
      return;

    // cleanup
    const existingScript = document.querySelector('script#klaviyo-script');
    if (existingScript) existingScript.remove();

    // Create script tag
    const script = document.createElement('script');
    script.id = `klaviyo-script`;
    script.src = `//static.klaviyo.com/onsite/js/klaviyo.js?company_id=${this.publicApiKey()}`;
    script.type = 'text/javascript';
    script.onload = this.handleScriptLoad;

    document.body.appendChild(script);
    this.script = script;
  }

  render() {
    const { formId } = this.props.formProps;

    window.t = this;

    return (
      // { !this.state.formIsCreated &&
      //   <Spinner size="1" style={{margin: '4rem auto'}} />
      // }
      // <div className={`klaviyo-form-${formId} klaviyo-form-container`}>
      <div className="form-embed-placeholder-with-content">
        <div>
          <IconCheckmark style={{ width: '25px', height: '25px', marginBottom: '10px' }} />
          <br />
          Klaviyo form <code>ID: {formId}</code>
          <br />
          <small>You can see this form rendered on your published site.</small>
        </div>
      </div>
    );
  }
}

export default KlaviyoForm;
