import React from 'react';
import debounce from 'lodash/debounce';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import relativeDate from 'lib/relative-date';
import DocumentTitle from 'react-document-title';

import Select from '../../Select';
import CreateUrl from './CreateUrl';
import Search from 'components/Search';
import SettingsDrawer from './SettingsDrawer';
import { StatusIndicator } from '../PageList';
import List from 'components/react-table/List';
import withQueryParams from 'containers/withQueryParams';

import * as itemUrlSelectors from 'reducers/itemUrlReducer';
import * as siteActions from 'actions/siteActions';
import { selectHasPermission } from 'reducers/policyReducer';

import { ReactComponent as SvgIcon } from '../../../assets/images/icon-filter.svg';
import { ReactComponent as SvgIconAdd } from '../../../assets/images/icon-plus.svg';
import { ReactComponent as PrevSvg } from '../../../assets/images/icon-prev-page.svg';
import { ReactComponent as NextSvg } from '../../../assets/images/icon-next-page.svg';
import { ReactComponent as SvgIconOptions } from '../../../assets/images/icon-options.svg';

import styles from './URLsSettings.module.scss';

const filters = [
  { value: '', label: 'all urls' },
  { value: '200', label: 'page load' },
  { value: '301', label: 'permanent redirect' },
  { value: '302', label: 'temporary redirect' },
];

function mapStateToProps(state) {
  return {
    hasPermission: selectHasPermission(state),
    urls: itemUrlSelectors.selectAll(state),
    count: itemUrlSelectors.selectCount(state),
  };
}

const mapDispatchToProps = {
  flushSiteCache: siteActions.flushCache,
};

class URLsSettings extends React.PureComponent {
  state = {
    query: '',
    isEditing: '',
    canFlushCache: true,
    flushingCache: false,
  };

  debounceSearch = debounce((search) => {
    this.props.updateQueryParams({ search });
  }, 300);

  getRedirectUrlForItem = (id, type) => {
    if (type.includes('page')) return `editor/${id}`;
    else if (type === 'blog') return `editor/blog/${id}`;
    else if (type === 'article') return `editor/blog-post/${id}`;
  };

  render() {
    const {
      hasPermission,
      urls,
      count,
      siteName,
      siteId,
      queryParams: { page = 1, status },
      updateQueryParams,
      fullDomain,
      scheme,
    } = this.props;

    const columns = [
      {
        Header: 'URL',
        accessor: '',
        Cell: ({ value }) => {
          if (value.item_info.status === 'published') {
            const linkUrl = (scheme || 'https') + '://' + fullDomain + value.url;
            return (
              <a
                onClick={(e) => {
                  e.stopPropagation();
                }}
                href={linkUrl}
                target="_blank"
              >
                {value.url}
              </a>
            );
          } else return <span>{value.url}</span>;
        },
      },
      {
        Header: 'Action',
        accessor: '',
        Cell: ({ value }) => {
          const { status } = value;
          const action = status === 200 ? 'Page load' : status === '302' ? 'Temporary redirect' : 'Permanent redirect';
          return (
            <span>
              {action} ({status})
            </span>
          );
        },
      },
      {
        Header: 'Page Type',
        accessor: '',
        Cell: ({ value }) => {
          const { item_info } = value;
          return <span className={styles.capitalize}>{item_info.item_type.replace('_', ' ')}</span>;
        },
      },
      {
        Header: 'Page Status',
        accessor: '',
        Cell: ({ value }) => {
          const { item_info } = value;
          return (
            <span>
              <StatusIndicator status={item_info.status} value={item_info.status} />
            </span>
          );
        },
      },
      {
        Header: 'Target',
        accessor: '',
        Cell: ({ value }) => {
          const {
            item_info: { name, item_type },
            item,
          } = value;
          const redirectUrl = this.getRedirectUrlForItem(item, item_type);
          const urlSite = this.props.urlSite;
          return <Link to={`/${urlSite}/${redirectUrl}`}>{name}</Link>;
        },
      },
      {
        Header: 'Updated At',
        accessor: 'modified_at',
        Cell: ({ value }) => (
          <div>
            <small style={{ fontSize: '1em', color: '#647fb4' }}>Updated&nbsp;</small>
            {relativeDate(value)}
          </div>
        ),
      },
      {
        Header: '',
        accessor: 'id',
        Cell: ({ value }) => {
          return (
            <div className={styles.icons}>
              <button
                data-tip="Options"
                data-test-id="options"
                className={styles.options}
                onClick={() => this.setState({ isEditing: value })}
              >
                <SvgIconOptions />
              </button>
            </div>
          );
        },
        width: 40,
      },
    ];

    return hasPermission('ItemUrl:*') ? (
      <>
        <DocumentTitle title={`${siteName}'s url settings`} />
        <section className={styles.header}>
          <header>
            <div className={''}>
              <h1>Website URLs</h1>
            </div>
            <div className={styles.searchContainer}>
              <Search
                value={this.state.query}
                onChange={(query) => this.setState({ query })}
                placeholder={'Search urls by path'}
                debounceValue={(value) => {
                  if (value !== undefined) this.debounceSearch(value);
                }}
                containerStyle={{ marginRight: '1rem', marginTop: 0 }}
              />
              <span onClick={() => this.setState({ isCreating: true })}>
                <SvgIconAdd height={15} width={15} />
                <span>New URL</span>
              </span>
              <span
                onClick={
                  this.state.canFlushCache
                    ? () => {
                        this.setState({ flushingCache: true });
                        this.props.flushSiteCache(siteId, (success) =>
                          this.setState({
                            canFlushCache: !success,
                            flushingCache: false,
                          })
                        );
                      }
                    : () => {}
                }
                className={classnames({
                  [styles.disabled]: this.state.flushingCache,
                  [styles.success]: !this.state.canFlushCache,
                })}
              >
                <span>
                  {this.state.flushingCache
                    ? 'Flushing cache'
                    : this.state.canFlushCache
                    ? "Flush site's cache"
                    : 'Flushed successfully'}
                </span>
              </span>
            </div>
          </header>
          <div className={styles.filtersContainer}>
            <div className={styles.filter}>
              <div className={styles.filterIcon}>
                <SvgIcon />
              </div>
              Viewing&nbsp;
              <Select
                options={filters}
                isInline
                defaultValue={filters[0]}
                value={filters.find((f) => f.value === status)}
                onChange={({ value: status }) => updateQueryParams({ status })}
              />
            </div>
          </div>
        </section>
        <List
          showHeader
          className="-highlight"
          data={urls}
          columns={columns}
          count={count}
          defaultPageSize={10}
          getTdProps={(state, row) => ({
            onClick: (e) => {
              this.setState({ isEditing: row.original.id });
            },
            style: { cursor: 'pointer' },
          })}
          showViewAll={false}
          breakPageslabel={<span>...</span>}
          nextPageLabel={<NextSvg />}
          onPageChange={(page) => updateQueryParams({ page })}
          pagesCount={count / 10}
          paginate
          activePage={page}
          getTheadProps={() => ({
            style: {
              zIndex: '0',
            },
          })}
          previousPageLabel={<PrevSvg />}
        />
        <SettingsDrawer
          id={this.state.isEditing}
          isOpen={!!this.state.isEditing}
          close={() => this.setState({ isEditing: null })}
        />
        <CreateUrl isOpen={this.state.isCreating} close={() => this.setState({ isCreating: false })} />
      </>
    ) : (
      <p>You don't have permission to access urls.</p>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withQueryParams(URLsSettings, {
    panel: 'itemsUrl',
  })
);
