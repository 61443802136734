import { createSelector } from 'reselect';
import { combineReducers } from 'redux';
import { assign, del, get, insert, merge, push, set, update, wrap } from 'object-path-immutable';

import * as types from '../actions/actionTypes';

// ----------------------------------------------------------------------------
// Reducer
// ----------------------------------------------------------------------------

function urlsByIdReducer(state = {}, action) {
  switch (action.type) {
    case types.ITEM_URLS_REQUEST_SUCCESS: {
      const incomingUrls = action.payload.entities.urls || {};
      return {
        ...state,
        ...incomingUrls,
      };
    }
    case types.ITEM_URL_CREATE_SUCCESS: {
      const newState = { [action.payload.id]: action.payload, ...state };
      return newState;
    }
    case types.ITEM_URL_REMOVE_SUCCESS: {
      return del(state, action.payload);
    }

    case types.ITEM_URL_UPDATE_SUCCESS: {
      const oldState = { ...state };
      oldState[action.payload.id] = action.payload;
      return { ...oldState };
    }
    default:
      return state;
  }
}

function isFetchingReducer(state = false, action) {
  switch (action.type) {
    case types.ITEM_URLS_REQUEST:
      return true;
    case types.ITEM_URLS_REQUEST_SUCCESS:
      return false;
    default:
      return state;
  }
}

function urlIdsReducer(state = [], action) {
  switch (action.type) {
    case types.ITEM_URLS_REQUEST_SUCCESS:
      return [...action.payload.ids] || [];
    case types.ITEM_URL_CREATE_SUCCESS: {
      const newState = [action.payload.id, ...state];
      return newState;
    }
    case types.ITEM_URL_REMOVE_SUCCESS: {
      const index = state.indexOf(action.payload);
      return del(state, index);
    }
    default:
      return [...state];
  }
}

function countUrlsReducer(state = null, action) {
  switch (action.type) {
    case types.ITEM_URLS_REQUEST_SUCCESS:
      return action.payload.count;
    default:
      return state;
  }
}

export default combineReducers({
  isFetching: isFetchingReducer,
  allIds: urlIdsReducer,
  byId: urlsByIdReducer,
  // contentById: contentByIdReducer,
  count: countUrlsReducer,
});

// ----------------------------------------------------------------------------
// Selectors
// ----------------------------------------------------------------------------

export function selectLocalState(globalState) {
  return globalState.itemUrls;
}

export function selectAllIds(globalState) {
  const state = selectLocalState(globalState);
  return state.allIds;
}

export const selectAllById = createSelector(selectLocalState, (itemUrls) => itemUrls.byId);

export const selectAll = createSelector(selectAllIds, selectAllById, (allIds, allById) =>
  allIds.map((id) => allById[id])
);

export const selectCount = createSelector(selectLocalState, (itemUrls) => itemUrls.count);

export function selectById(globalState, id) {
  const allById = selectAllById(globalState);
  return allById[id];
}
