import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import SideDrawer from '../../../base/SideDrawer';
import { addKeyDrawerOpen } from 'reducers/apiKeysReducer';
import { addAPIKey, closeAddAPIKeyDrawer, toggleAddAPIKeyDrawer } from 'actions/apiKeysActions';
import FormControl from 'components/base/FormHelpers/FormControl';

const APIKeys: React.FunctionComponent = (props) => {
  const drawerOpen = useSelector(addKeyDrawerOpen);
  const dispatch = useDispatch();
  const [keyName, setKeyName] = useState('');

  useEffect(() => {
    setKeyName('');
  }, [drawerOpen]);

  function close() {
    dispatch(closeAddAPIKeyDrawer());
  }

  function handleSubmit(ev: React.FormEvent<HTMLFormElement>) {
    ev.preventDefault();
    dispatch(addAPIKey(keyName));
    close();
  }

  return (
    <SideDrawer
      isOpen={drawerOpen}
      close={close}
      title={'Add API Key'}
      render={() => (
        <form onSubmit={handleSubmit}>
          <fieldset>
            <label htmlFor="key_name">Name</label>
            <FormControl
              id="key_name"
              type="text"
              name="name"
              value={keyName}
              onChange={(ev: React.ChangeEvent<HTMLInputElement>) => setKeyName(ev.target.value)}
              autoComplete="off"
              maxLength={65}
              autoFocus
              className="ph-redact-input"
            />
          </fieldset>
          <fieldset>
            <input
              type="submit"
              aria-label="update user"
              className="button button-primary button-full"
              value={'Add Key'}
              disabled={!keyName}
            />
          </fieldset>
        </form>
      )}
    />
  );
};

export default APIKeys;
