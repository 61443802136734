import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as PopperJS from '@popperjs/core';
import { usePopper } from 'react-popper';
import classnames from 'classnames';
import { toInteger } from 'lodash';

import { getActiveMaxWidth, getIsDefault } from './helpers';
import useOnClickOutside from 'hooks/useClickOutside';
import * as addSectionActions from 'actions/addSectionActions';
import { getDevice } from 'reducers/uiReducer';

import UpDownCounter from '../../Toolbars2/components/UpDownCounter';
import { Tray, Group, Label } from '../../Toolbars2/Toolbar2';
import Padding from './Padding';
import { IconDeviceCheck, IconSpacingAndWidth, IconRight, IconTooltipPaddingDefault } from '../../Toolbars2/icons';

import styles from './PaddingAndWidthButton.module.scss';
import { useSelector } from 'react-redux';
import { UBoxInfo, USectionDevices } from 'types/USection';
import { set } from 'object-path-immutable';
import { getDeviceTypeToSaveTo } from 'components/unstack-components/Component/util/helpers/deviceHelper';
import { isDeviceMode } from 'types/LegacyEditor';

interface Props {
  dataRef?: UBoxInfo;
  onChange: (content: USectionDevices, key?: string, multi?: boolean) => void;
  defaults?: UBoxInfo;
  isSectionToolbar?: boolean;
  content: USectionDevices;
  contentKey: string;
}

export default function (props: Props) {
  const { defaults, dataRef, onChange, isSectionToolbar, content, contentKey } = props;
  const [cursor, setCursor] = useState(null);
  const device = useSelector(getDevice);
  const [hoveredPercent, setHoveredPercent] = useState(false);
  const [hoveredDefault, setHoveredDefault] = useState(false);
  const [referenceElement, setReferenceElement] = useState<Element | PopperJS.VirtualElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
  const { styles: popperStyles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom-start',
  });
  const [showPopper, setShowPopper] = useState(false);
  const innerPopperRef = useRef();
  const arrowRef = useRef<HTMLDivElement>();
  useOnClickOutside(innerPopperRef, () => setShowPopper(false));

  let defaultWidth = defaults ? defaults['maxWidth'] : undefined;
  if (defaultWidth) defaultWidth = defaultWidth.toString().split('px')[0];

  const handleChange = (key: string) => (newValue: string | number) => {
    const re = /^\d+$/;
    const setValue = re.test(newValue.toString()) ? newValue.toString() + 'px' : newValue;
    const defaultVal = re.test(defaultWidth.toString())
      ? defaultWidth.toString() + 'px'
      : defaultWidth === 'auto'
      ? 'none'
      : defaultWidth;
    const splitKey = contentKey.split('.');
    onChange(
      set(
        content,
        [getDeviceTypeToSaveTo(device, false, splitKey), ...splitKey.slice(1), key],
        setValue === defaultVal ? 'default' : setValue
      ),
      `content.${splitKey[0]}`
    );
    setWidth(setValue === 'default' ? defaultVal.toString().length : setValue.toString().length);
  };

  const activeMaxWidth = getActiveMaxWidth(dataRef, isSectionToolbar, defaultWidth as string);

  let maxWidthArray = isSectionToolbar ? ['960', '1280', '1440'] : ['300', '400', '500', '600'];
  if (defaultWidth && defaultWidth !== 'auto' && defaultWidth !== 'none') maxWidthArray.push(defaultWidth.toString());
  maxWidthArray.sort(function (a, b) {
    return toInteger(a) - toInteger(b);
  });
  maxWidthArray = maxWidthArray.filter((item, index) => {
    return maxWidthArray.indexOf(item) === index;
  });

  function changeMaxWidth(newVal: string | number) {
    const re = /^\d+$/;
    const defaultVal = re.test(defaultWidth.toString())
      ? defaultWidth.toString() + 'px'
      : defaultWidth === 'auto'
      ? 'none'
      : defaultWidth;
    newVal === defaultVal ? handleChange('maxWidth')('default') : handleChange('maxWidth')(newVal);
  }

  const re = /^\d+$/;
  const counterValue =
    dataRef['maxWidth'] !== null && dataRef['maxWidth'] !== undefined && dataRef['maxWidth'] !== 'default'
      ? dataRef['maxWidth']
      : re.test(defaultWidth.toString())
      ? defaultWidth.toString() + 'px'
      : defaultWidth;

  const isDefault = getIsDefault(dataRef, defaults);

  const handleDown = (e: any) => {
    if (cursor === null) {
      const newIndex = maxWidthArray.indexOf(counterValue.toString().split('px')[0]) + 1;
      setCursor(newIndex <= maxWidthArray.length ? newIndex : 0);
    } else if (cursor === maxWidthArray.length) {
      setCursor(0);
    } else {
      setCursor(cursor + 1);
    }
  };
  const handleUp = (e: any) => {
    if (cursor === null) {
      const newIndex = maxWidthArray.indexOf(counterValue.toString().split('px')[0]) - 1;
      setCursor(newIndex < 0 ? maxWidthArray.length : newIndex);
    } else if (cursor === 0) {
      setCursor(maxWidthArray.length);
    } else {
      setCursor(cursor - 1);
    }
  };
  const handleEnter = (e: any) => {
    if (cursor === maxWidthArray.length) {
      changeMaxWidth('auto');
    } else {
      changeMaxWidth(maxWidthArray[cursor] + 'px');
    }
    setCursor(null);
  };

  // Code to control inputfield width
  const [width, setWidth] = useState(counterValue ? counterValue.toString().length : 8);
  const inputRef = useRef(null);

  useEffect(() => {
    const input = inputRef.current;
    if (input) input.style.setProperty('width', `${width + 2}ch`, 'important');
  }, [inputRef, width]);

  useEffect(() => {
    setWidth(counterValue ? counterValue.toString().length : 8);
  }, [device]);

  const dispatch = useDispatch();
  useEffect(() => {
    if (showPopper) dispatch(addSectionActions.requestDrawerOpen());
    else dispatch(addSectionActions.requestDrawerClose());
  }, [showPopper]);

  return (
    <Tray
      renderIcon={(trayIsOpen: boolean) => (
        <IconSpacingAndWidth className={trayIsOpen && styles.iconTrayOpened} active={!isDefault} />
      )}
      startOpen={device === 'mobile'}
      dataTestId={'sizeAndSpacing'}
      trayLabel="Size & Spacing"
      className={classnames(styles.renderTray, { [styles.deviceMode]: isDeviceMode() })}
      renderTray={() => (
        <>
          <div className={styles.inputsContainer}>
            <Group className={styles.group} data-test-id={`max-width`}>
              <Label>Max-width</Label>
              <span ref={setReferenceElement} onClick={() => setShowPopper(!showPopper)}>
                <UpDownCounter
                  innerRef={inputRef}
                  value={counterValue}
                  onChange={handleChange('maxWidth')}
                  className={styles.maxWidthInput}
                  setWidth={setWidth}
                  handleDown={handleDown}
                  handleUp={handleUp}
                  handleEnter={handleEnter}
                  cursor={cursor}
                />
              </span>

              {showPopper && (
                <div
                  className={styles.popperContainer}
                  ref={setPopperElement}
                  style={{ ...popperStyles.popper, zIndex: 9, marginTop: '6px', marginBottom: '6px' }}
                  {...attributes.popper}
                >
                  <div className={styles.showHideDrawer} ref={innerPopperRef}>
                    {activeMaxWidth === 'custom' && (
                      <>
                        <div className={styles.menuItem}>
                          <div className={classnames(styles.innerMenuItem, styles.active)}>
                            <IconDeviceCheck className={styles.iconCheck} />
                            Custom: {dataRef['maxWidth']}
                          </div>
                        </div>
                        <div className={styles.menuDivider}>
                          <div className={styles.line} />
                        </div>
                      </>
                    )}
                    {maxWidthArray.map((value, i) => {
                      return (
                        <div
                          className={classnames(styles.menuItem, { [styles.menuItemActive]: cursor === i })}
                          key={i}
                          onClick={() => {
                            changeMaxWidth(value + 'px');
                            setShowPopper(!showPopper);
                          }}
                          onMouseEnter={() => {
                            if (defaultWidth === value) setHoveredDefault(true);
                          }}
                          onMouseLeave={() => {
                            if (defaultWidth === value) setHoveredDefault(false);
                            setCursor(null);
                          }}
                        >
                          <div
                            className={classnames(styles.innerMenuItem, {
                              [styles.active]: activeMaxWidth === value,
                            })}
                          >
                            {activeMaxWidth === value || (activeMaxWidth === 'default' && defaultWidth === value) ? (
                              <IconDeviceCheck className={styles.iconCheck} />
                            ) : (
                              <div className={styles.iconCheck} />
                            )}
                            {value} pixels {defaultWidth === value ? `(default)` : ''}
                          </div>
                          {hoveredDefault && defaultWidth === value && (
                            <div className={styles.toolTipMaxWidthDefault}>
                              <IconRight className={styles.arrow} />
                              <IconTooltipPaddingDefault className={styles.icon} />
                              <div className={styles.text}>Set default width in Style Guide</div>
                            </div>
                          )}
                        </div>
                      );
                    })}
                    <div className={styles.menuDivider}>
                      <div className={styles.line} />
                    </div>
                    <div
                      className={classnames(styles.menuItem, {
                        [styles.menuItemActive]: cursor === maxWidthArray.length,
                      })}
                      onClick={() => {
                        changeMaxWidth('auto');
                        setShowPopper(!showPopper);
                      }}
                      onMouseEnter={() => setHoveredPercent(true)}
                      onMouseLeave={() => {
                        setHoveredPercent(false);
                        setCursor(null);
                      }}
                    >
                      <div className={classnames(styles.innerMenuItem, { [styles.active]: activeMaxWidth === 'auto' })}>
                        {activeMaxWidth === 'auto' ? (
                          <IconDeviceCheck className={styles.iconCheck} />
                        ) : (
                          <div className={styles.iconCheck} />
                        )}
                        100%
                      </div>
                    </div>

                    {!hoveredPercent && !hoveredDefault && (
                      <div className={styles.toolTipMaxWidth}>
                        <IconRight className={styles.arrow} />
                        <IconSpacingAndWidth className={styles.icon} />
                        <div className={styles.text}>Controls the max size for this block</div>
                      </div>
                    )}

                    {hoveredPercent && (
                      <div className={styles.toolTipMaxWidth100}>
                        <IconRight className={styles.arrow} />
                        <IconSpacingAndWidth className={styles.icon} />
                        <div className={styles.text}>Expand to fill 100% of the container</div>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </Group>

            <div className={styles.divider}></div>

            <Padding
              defaults={defaults}
              value={dataRef}
              onChange={onChange}
              isSectionToolbar={isSectionToolbar}
              content={content}
              contentKey={contentKey}
            />
          </div>
        </>
      )}
    />
  );
}
