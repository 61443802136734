import React, { useState, useCallback, useRef, ChangeEventHandler, useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';
import { useDispatch } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import classnames from 'classnames';

import usePrevious from 'hooks/usePrevious';
import useOnClickOutside from 'hooks/useClickOutside';
import { colorToRGBA, getRGBAOpacity, rgbTohex } from '../../Toolbars2/helpers';
import { useColorPallette } from 'hooks/useColorPallette';
import * as toolbarActions from '../../../../actions/toolbarActions';
import * as addSectionActions from '../../../../actions/addSectionActions';

import { ReactComponent as SvgIconTransparent } from 'assets/images/icon-transparent.svg';
import { ReactComponent as SvgIconArrow } from 'assets/images/icon-color-arrow.svg';
import { ReactComponent as SvgIconCheck } from 'assets/images/icon-check.svg';

import styles from './ColorPickerNew.module.scss';
import toInteger from 'lodash/toInteger';

type Props = {
  color: string;
  onChange: (value: string) => void;
  opacity?: number;
  handleOpacityChange?: (value: number) => void;
  isQuill?: boolean;
  isSectionToolbar?: boolean;
  type?: string;
  isOpen?: string;
  setIsOpen?: (value: string | undefined) => void;
};

type DrawerProps = {
  isOpen: boolean;
  onChange: (value: string) => void;
  color: string;
  handleInputChange: ChangeEventHandler<HTMLInputElement>;
  setDrawerIsOpen: (value: boolean) => void;
  opacity: number;
  handleOpacityChange: (value: number) => void;
  isQuill?: boolean;
};

type Color = {
  code: string;
  label?: string;
};

type Swatch = {
  color: string;
};

type ColorChoice = {
  color: string;
  label?: string;
  onClick: () => void;
  current: string;
};

export default function ColorPickerNew(props: Props) {
  const { color, onChange, opacity, handleOpacityChange, isQuill, isSectionToolbar } = props;

  const [drawerIsOpen, setDrawerIsOpen] = useState(false);

  const handleInputChange = useCallback((e: any) => onChange(e.target.value), [onChange]);

  const handleDrawerItemClick = useCallback(
    (color: string) => {
      setDrawerIsOpen(false);
      onChange(color);
      if (props.setIsOpen) props.setIsOpen(undefined);
    },
    [onChange]
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (drawerIsOpen && isSectionToolbar) dispatch(toolbarActions.requestDrawerOpen());
    else dispatch(toolbarActions.requestDrawerClose());

    if (drawerIsOpen) dispatch(addSectionActions.requestDrawerOpen());
    else dispatch(addSectionActions.requestDrawerClose());

    if (drawerIsOpen && props.setIsOpen) props.setIsOpen(props.type);
  }, [drawerIsOpen]);

  useEffect(() => {
    if (props.type && props.type === props.isOpen) setDrawerIsOpen(true);
    else setDrawerIsOpen(false);
  }, [props.isOpen]);

  return (
    <span className={styles.ColorPicker}>
      <div
        className={classnames(styles.SwatchContainer, { [styles.IsOpen]: drawerIsOpen }, { [styles.IsQuill]: isQuill })}
        onClick={() => {
          setDrawerIsOpen(!drawerIsOpen);
          if (props.setIsOpen) props.setIsOpen(undefined);
        }}
        data-test-id="color-picker-drawer-icon"
      >
        <Swatch color={color} />
        <SvgIconArrow className={styles.ColorArrowIcon} />
      </div>
      <Drawer
        isOpen={drawerIsOpen}
        onChange={handleDrawerItemClick}
        color={color}
        handleInputChange={handleInputChange}
        setDrawerIsOpen={setDrawerIsOpen}
        opacity={opacity}
        handleOpacityChange={handleOpacityChange}
        isQuill={isQuill}
      />
    </span>
  );
}

function Swatch({ color }: Swatch) {
  return (
    <div className={styles.SwatchCheckeredContainer}>
      {color ? (
        <div className={styles.ColorSwatch} style={{ backgroundColor: color }} />
      ) : (
        <SvgIconTransparent className={styles.CheckeredIcon} />
      )}
    </div>
  );
}

function Drawer(props: DrawerProps) {
  const { isOpen, onChange, color, handleInputChange, setDrawerIsOpen, opacity, handleOpacityChange, isQuill } = props;

  const colors = useColorPallette('background');
  const lastColors = useLastColors(isOpen, colors);
  const [colorInput, setColorInput] = useState('');
  const hex = rgbTohex(color);
  const alpha = getRGBAOpacity(color);

  if (isEmpty(colors)) return null;

  const ref = useRef();
  useOnClickOutside(ref, () => setDrawerIsOpen(false));

  const value = toInteger((alpha * 100).toFixed());
  const [counterInput, setCounterInput] = useState(`${value.toString()}%`);

  const handleKeyDown = (e: any) => {
    switch (e.which) {
      case 13:
        const int = toInteger(counterInput.replace('%', ''));
        if (int < 0) {
          handleOpacityChange(0);
          setCounterInput(`${(0).toString()}%`);
        } else if (int > 100) {
          handleOpacityChange(100);
          setCounterInput(`${(100).toString()}%`);
        } else {
          handleOpacityChange(int);
          setCounterInput(`${int.toString()}%`);
        }
        setDrawerIsOpen(false);
        break;
      case 27:
        handleOpacityChange(value);
        setCounterInput(`${value.toString()}%`);
        setDrawerIsOpen(false);
        break;
      case 38:
        handleOpacityChange(value + 1);
        setCounterInput(`${value.toString()}%`);
        break;
      case 40:
        handleOpacityChange(value - 1);
        setCounterInput(`${value.toString()}%`);
        break;
      default:
    }
  };

  const handleTextInputChange = (e: any) => {
    setCounterInput(e.target.value);
  };

  const handleBlur = (e: any) => {
    const int = toInteger(counterInput.replace('%', ''));
    if (int < 0) {
      handleOpacityChange(0);
      setCounterInput(`${(0).toString()}%`);
    } else if (int > 100) {
      handleOpacityChange(100);
      setCounterInput(`${(100).toString()}%`);
    } else {
      handleOpacityChange(int);
      setCounterInput(`${int.toString()}%`);
    }
  };

  useEffect(() => {
    setCounterInput(`${value.toString()}%`);
  }, [value]);

  return (
    <CSSTransition in={isOpen} timeout={141.59} classNames={styles} mountOnEnter unmountOnExit>
      <div ref={ref} className={styles.Drawer} data-test-id="color-picker-drawer">
        <div className={styles.ColorInput}>
          <Swatch color={color} />
          <input
            type="text"
            aria-label="Color Picker Input"
            className={classnames(styles.Control, { [styles.NoOpacity]: !handleOpacityChange })}
            value={colorInput || hex || color || ''}
            placeholder="Hex color"
            onChange={(e) => setColorInput(e.target.value)}
            onBlur={() => {
              onChange(colorInput || color);
              setColorInput('');
            }}
            onKeyDown={(e) => {
              if (e.which === 13) {
                e.preventDefault();
                onChange(colorInput || color);
                setColorInput('');
                setDrawerIsOpen(false);
              } else if (e.which === 27) {
                setColorInput('');
                setDrawerIsOpen(false);
              }
            }}
          />
          {handleOpacityChange && (
            <div className={styles.OpacityContainer}>
              <input
                type="text"
                className={styles.Opacity}
                value={counterInput}
                onChange={handleTextInputChange}
                onKeyDown={handleKeyDown}
                onBlur={handleBlur}
                onFocus={(e) => {
                  e.target.select();
                }}
              />
            </div>
          )}
        </div>

        <ul className={classnames({ [styles.ulIsQuill]: isQuill })}>
          <ColorChoice key={'none'} color={''} label={'none'} onClick={() => onChange('')} current={color} />
          {lastColors.map(({ code, label }: Color) => (
            <ColorChoice key={code} color={code} label={label} onClick={() => onChange(code)} current={color} />
          ))}
        </ul>
      </div>
    </CSSTransition>
  );
}

function ColorChoice(props: ColorChoice) {
  const { color, onClick, label, current } = props;

  return (
    <li className={styles.ColorChoiceContainer} onClick={onClick}>
      <div className={styles.ColorChoice}>
        <Swatch color={color} />
        {label ? <span className={styles.inSwatchLabel}>{label}</span> : <span>{color}</span>}
      </div>
      {current === color && <SvgIconCheck />}
    </li>
  );
}

function useLastColors(isOpen: boolean, colors: Array<Color>) {
  const wasOpen = usePrevious(isOpen);
  const colorsRef = useRef(colors);
  if (!wasOpen && isOpen) colorsRef.current = colors;
  return colorsRef.current;
}
