import React from 'react';
import classnames from 'classnames';

import styles from './Forms/SparkForm//Form.module.scss';

const STYLES_OPTIONS = [
  {
    title: 'Show this form without a background',
    background: '',
  },
  {
    title: 'Add a white background to my form',
    background: 'dark-bg',
  },
];

export const FormStyling = ({ onUpdate, background: currentBg }) => {
  return (
    <div className={styles.SparkForm}>
      <fieldset>
        <label>Form styling</label>
        <div className={styles.radioGroup}>
          {STYLES_OPTIONS.map(({ title, background }, idx) => {
            const isSelected = currentBg === background;
            return (
              <label key={idx} className={classnames({ [styles.selected]: isSelected })}>
                <input type="radio" checked={isSelected} onChange={() => onUpdate(background)} />
                <div className={styles.title}>{title}</div>
              </label>
            );
          })}
        </div>
      </fieldset>
    </div>
  );
};
