import * as types from '../actions/actionTypes';
import { createSelector } from 'reselect';
import snakeCase from '../lib/snakeCase';

// ----------------------------------------------------------------------------
// Reducer
// ----------------------------------------------------------------------------

const INITIAL_STATE = {
  all: [],
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.TAG_FETCH_ALL_SUCCESS:
      return {
        ...state,
        all: action.payload,
      };

    default:
      return state;
  }
}

// ----------------------------------------------------------------------------
// Selectors
// ----------------------------------------------------------------------------

const selectLocalState = (globalState) => globalState.tags;

export const selectTags = createSelector([selectLocalState], (tags) =>
  tags.all.map((tag) => ({ value: snakeCase(tag.tag), label: tag.tag }))
);
