import {
  // selectBackgroundColors,
  selectStylesColors,
} from '../reducers/uiColorPalletteSelectors';
import { useSelector } from 'react-redux';
import usePages from './usePages';

export function useColorPallette(type) {
  usePages();
  // return useSelector(selectBackgroundColors);
  return useSelector(selectStylesColors);
}
