import * as actionTypes from './actionTypes';

export const requestListIfNeeded = () => ({
  type: actionTypes.CATEGORY_FETCH_ALL_IF_NEEDED,
});

export const requestList = () => ({
  type: actionTypes.CATEGORY_FETCH_ALL_REQUEST,
});

export const requestListSuccess = (payload) => ({
  type: actionTypes.CATEGORY_FETCH_ALL_SUCCESS,
  payload,
});

export const update = (payload, resolve, reject) => ({
  type: actionTypes.CATEGORY_UPDATE_REQUEST,
  payload,
  resolve,
  reject,
});

export const remove = (payload, resolve, reject) => ({
  type: actionTypes.CATEGORY_DELETE_REQUEST,
  payload,
  resolve,
  reject,
});
