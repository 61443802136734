import React from 'react';

import withQueryParams from 'containers/withQueryParams';
import { Route, Routes } from 'react-router';
import ActivityTab from './Insights/Tabs/ActivityTab';
import ABTestTab from './Insights/Tabs/ABTestTab';
import ConversionsTab from './Insights/Tabs/ConversionTab';
import UTMsTab from './Insights/Tabs/UTMsTab';
import { Outlet } from 'react-router-dom';

export default withQueryParams(function Insights() {
  return (
    <Routes>
      <Route path="/" element={<Outlet />}>
        <Route path="traffic" element={<ActivityTab />} />
        <Route path="test-results" element={<ABTestTab />} />
        <Route path="conversions" element={<ConversionsTab />} />
        <Route path="utms" element={<UTMsTab />} />
      </Route>
    </Routes>
  );
}, {} as any);
