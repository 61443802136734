import React from 'react';
import ReactTooltip from 'react-tooltip';
import classNames from 'classnames';

import styles from './ProductGenerator.module.scss';

interface ProductLabelProps {
  autosuggestId: string;
  item: {
    meta?: { product_types?: Array<string> };
  };
}

export const ProductLabel = (props: ProductLabelProps) => {
  const { autosuggestId, item } = props;

  return (
    <>
      <ReactTooltip
        id={autosuggestId}
        className={styles.tooltip}
        children={
          item.meta?.product_types?.[0] === 'standard'
            ? 'Product'
            : item.meta?.product_types?.[0]?.charAt(0).toUpperCase() + item.meta?.product_types?.[0]?.slice(1)
        }
        place="top"
        effect="solid"
        backgroundColor="#000233"
        arrowColor="#000233"
      />
      <span
        data-tip
        data-for={autosuggestId}
        className={classNames(styles.tagLabel, {
          [styles.product]: item.meta?.product_types?.[0] === 'product' || item.meta?.product_types?.[0] === 'standard',
          [styles.parent]: item.meta?.product_types?.[0] === 'parent',
          [styles.child]: item.meta?.product_types?.[0] === 'child',
          [styles.bundle]: item.meta?.product_types?.[0] === 'bundle',
          [styles.none]: !item.meta?.product_types,
        })}
      >
        {item.meta?.product_types?.[0] === 'standard'
          ? 'P'
          : item.meta?.product_types?.[0] === 'parent'
          ? 'P'
          : item.meta?.product_types?.[0] === 'bundle'
          ? 'B'
          : item.meta?.product_types?.[0] === 'child'
          ? 'C'
          : ''}
      </span>
    </>
  );
};
