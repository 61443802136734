import * as types from './actionTypes';

export function requestComponentDefaultsIfNeeded() {
  return {
    type: types.COMPONENTS_REQUEST_DEFAULTS_IF_NEEDED,
  };
}

export function requestComponentDefaults() {
  return {
    type: types.COMPONENTS_REQUEST_DEFAULTS,
  };
}

export function saveComponentDefaults(payload) {
  return {
    type: types.COMPONENTS_SAVE_DEFAULTS,
    payload,
  };
}
