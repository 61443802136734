import React from 'react';
import { set, del, insert, push, assign } from 'object-path-immutable';

import SelectOption from './Select';

import { Tray } from '../../../../../../../Editor/Toolbars2/Toolbar2';
import { IconProperties } from '../../../../../../../Editor/Toolbars2/icons';
import TextField from './Text';
import NumberField from './Number';
import DateField from './Date';
import TimeField from './Time';
import DateTimeField from './DateTime';
import ColorField from './Color';
import ToggleField from './Toggle';

import styles from './LayoutOption.module.scss';
import { UBoxInfo, USectionDevices } from 'types/USection';
import Divider from './Divider';

export const PROPERTIES_FIELD_MAP: { [key: string]: React.FunctionComponent } = {
  text: TextField,
  date: DateField,
  time: TimeField,
  datetime: DateTimeField,
  color: ColorField,
  number: NumberField,
  select: SelectOption,
  multiselect: SelectOption,
  toggle: ToggleField,
  divider: Divider,
};

type Props = {
  dataRef: UBoxInfo;
  onChange: (content: USectionDevices, key?: string, multi?: boolean) => void;
  properties: any;
  contentKey: string;
  content: USectionDevices;
};

export const hideProperty = (property: any, section: any) => {
  return Boolean(property.displayWhen && typeof property.displayWhen === 'function' && !property.displayWhen(section));
};

const LayoutOptions: React.FunctionComponent<Props> = ({ dataRef, onChange, properties, contentKey, content }) => {
  const splitKey = contentKey.split('.');

  return (
    <Tray
      renderIcon={() => <IconProperties />}
      startOpen
      trayLabel="Properties"
      renderTray={() => (
        <div className={styles.grid}>
          {properties.map((property: any) => {
            const FieldType = PROPERTIES_FIELD_MAP[property.type];

            if (hideProperty(property, dataRef)) {
              return null;
            }

            return (
              <FieldType
                //  @ts-ignore
                value={dataRef?.properties?.[property.name]}
                property={property}
                //  @ts-ignore
                onChange={(property, newValue) => {
                  /* always write properties to base irrespective of device */
                  onChange(
                    set(content, ['base', ...splitKey.slice(1), 'properties', property], newValue),
                    `content.${splitKey[0]}`
                  );
                }}
              />
            );
          })}
        </div>
      )}
    />
  );
};

export default LayoutOptions;
