import React from 'react';
import idx from 'lodash/get';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { LabeledToolbar, Group, Button } from '../../../../../../Editor/Toolbars2/Toolbar2';
import ButtonWithUnderline from '../../../../../../../components/Editor/Toolbars2/components/ButtonWithUnderline';

import {
  IconViewTestResults,
  IconEndTest,
  IconCheckedCircleGreen,
} from '../../../../../../../components/Editor/Toolbars2/icons';

import useUrlSite from 'hooks/useUrlSite';
import {
  selectCompleteness,
  selectWinningVariantId,
  selectVariantMetaDataById,
} from '../../../../../../../reducers/contentTestsReducer';
import { selectHasPermission } from 'reducers/policyReducer';
import { open as openSettings } from '../../../../../../../actions/uiInProgressContentTestSettingsActions';

import styles from './Toolbar.module.scss';

export function ABTestInProgress(props) {
  const { variantIndex, setVariantIndex, activeTest } = props;

  const variantMetadataById = useSelector((state) => selectVariantMetaDataById(state, activeTest.id));

  const completeness = useSelector((state) => selectCompleteness(state, activeTest.id));
  const percentComplete = (completeness * 100).toFixed(1) + '%';

  const winningVariantId = useSelector((state) => selectWinningVariantId(state, activeTest.id));
  const hasWinner = !!winningVariantId;

  const hasPermission = useSelector((state) => selectHasPermission(state));

  const dispatch = useDispatch();

  const urlSite = useUrlSite();

  function handleClickEnd(e) {
    props.openBlogInProgressTestSettings
      ? props.openBlogInProgressTestSettings()
      : dispatch(openSettings(activeTest.id));
  }

  return (
    <LabeledToolbar
      label={
        <div className={styles.progressContainer}>
          <div className={styles.progressBar} style={{ width: percentComplete }} />
          <span className={styles.label}>{hasWinner ? 'WINNER!' : 'TEST IN PROGRESS'}</span>
        </div>
      }
    >
      <Group>
        {activeTest.variants.map((variant, i) => {
          const conversionRate = idx(variantMetadataById, [variant.id, 'conversion_rate'], 0);
          const conversionRatePercent = (conversionRate * 100).toFixed(1);
          return (
            <ButtonWithUnderline
              key={variant.name}
              active={i === variantIndex}
              onClick={() => setVariantIndex(i)}
              data-tip="Goal conversion rate"
            >
              {variant.name}
              <span className={styles.conversionRate}>{conversionRatePercent}%</span>
              {variant.id === winningVariantId && <IconCheckedCircleGreen className={styles.winnerIcon} />}
            </ButtonWithUnderline>
          );
        })}
      </Group>
      <Group style={{ marginLeft: 'auto' }}>
        <Link to={`/${urlSite}/insights/test-results`}>
          <IconViewTestResults />
          &nbsp; Results
        </Link>
        {hasPermission('Experiment:stop') && (
          <Button onClick={handleClickEnd}>
            <IconEndTest />
            &nbsp; End
          </Button>
        )}
      </Group>
    </LabeledToolbar>
  );
}
