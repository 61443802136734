import { createSelector } from 'reselect';
import * as types from '../actions/actionTypes';
import { SUBSCRIPTION_PLANS } from '../settings';

// ----------------------------------------------------------------------------
// Reducer
// ----------------------------------------------------------------------------

const initState = {
  isFetching: false,
  subscription: null,
  subscriptionHistory: null,
  products: [],
};

export default function (state = initState, action) {
  switch (action.type) {
    case types.SUBSCRIPTION_REQUEST:
      return {
        ...state,
        isFetching: true,
      };

    case types.SUBSCRIPTION_RECEIVE:
      return {
        ...state,
        isFetching: false,
        fetchedAt: action.payload.fetchedAt,
        subscription: action.payload.subscription,
      };

    case types.SUBSCRIPTION_RECEIVE_HISTORY:
      return {
        ...state,
        subscriptionHistory: action.payload,
      };

    case types.SUBSCRIPTION_REQUEST_CREATE:
      return {
        ...state,
        isRequestingCreate: true,
      };

    case types.SUBSCRIPTION_RECEIVE_CREATE_SUCCESS:
      return {
        ...state,
        isRequestingCreate: false,
        errors: null,
      };

    case types.SUBSCRIPTION_RECEIVE_CREATE_ERROR:
      return {
        ...state,
        isRequestingCreate: false,
        errors: action.payload.errors,
      };

    case types.SUBSCRIPTION_PLANS_RECEIVE:
      return {
        ...state,
        products: action.payload,
      };

    default:
      return state;
  }
}

// ----------------------------------------------------------------------------
// Selectors
// ----------------------------------------------------------------------------

// ---- Raw state selectors ----

export function selectLocalState(globalState) {
  return globalState.subscription;
}

export function selectIsFetching(globalState) {
  const state = selectLocalState(globalState);
  return state.isFetching;
}

export function selectFetchedAt(globalState) {
  const state = selectLocalState(globalState);
  return state.fetchedAt;
}

export function selectHasSubscription(globalState) {
  const state = selectLocalState(globalState);
  if (state.subscription == null) return null;
  return !!state.subscription;
}

export function selectSubscriptionHistory(globalState) {
  const state = selectLocalState(globalState);
  if (state.subscriptionHistory == null) return null;
  return state.subscriptionHistory;
}

export function selectSubscription(globalState) {
  const state = selectLocalState(globalState);
  return state.subscription;
}

export function selectSubscriptionFetching(globalState) {
  const state = selectLocalState(globalState);
  return state.isFetching;
}

// Extract the subscription product_id by removing _month or _year from the
// plan_id (e.g. `profesional_month` -> `professional`).
export function selectProductId(globalState) {
  const subscription = selectSubscription(globalState);
  if (!subscription) return null;

  const match = subscription.plan.match(/^(.+)_(month|year)$/);
  return match ? match[1] : null;
}

// Select the numbered subscription tier the user is paying for. Do this by:
//   1. return null if the subscription is null (indicating it has not been
//      fetched or is currently fetching).
//   2. return 0 if the user does not have a subscription (indicating they're on
//      the "free" plan).
//   3. if the user is paying for a subscription,extract the subscription
//      product_id from the subscription plan_id.
//   4. find the index of the product_id in the ordered list of all
//      subscription plans.
//   5. add 1 to the index to allow for 0 to indicate the user is on the free
//      plan.
//
// e.g.
//   At the time of writing this, the ordered tiers are:
//   `[free, solopreneur, professional, enterprise]`
//
export function selectCurrentTier(globalState) {
  const hasSubscription = selectHasSubscription(globalState);

  if (hasSubscription === null) return null;
  else if (hasSubscription === false) return 0;

  const productId = selectProductId(globalState);

  const index = SUBSCRIPTION_PLANS.findIndex((plan) => plan.product_id === productId);

  if (index === -1) {
    console.error('Subscription plan_id could not be matched with a plan_id');
    return null;
  }

  return index + 1;
}

export function selectPlanByTier(tier) {
  if (tier === 0) return null;
  return SUBSCRIPTION_PLANS[tier - 1];
}

export const selectSubscriptionProducts = createSelector(selectLocalState, (subscription) => subscription.products);

export function selectCurrentPlan(globalState) {
  const productId = selectProductId(globalState);
  const products = selectSubscriptionProducts(globalState);
  return products.find((plan) => plan.product_id === productId);
}

export function selectCurrentPlanName(globalState) {
  const subscription = selectSubscription(globalState);
  return (subscription && subscription.product_name) || 'Free';
}
