import { setActiveToolbar } from 'actions/toolbarActions';
import { changeDevice, changeDeviceDimensions, changeRotation } from 'actions/uiActions';
import classnames from 'classnames';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getDevice, getMobileRotated, getTabletRotated } from 'reducers/uiReducer';
import { setActiveSection } from 'actions/toolbarActions';
import { selectPageSectionIds } from 'reducers/pagesReducer';
import { selectSections } from 'reducers/sectionsReducer';
import { getActiveSection, getActiveToolbar } from 'reducers/toolbarReducer';
import adminBarStyles from '../AdminBar.module.scss';
import { IconDeviceDesktopMenu, IconDeviceMobileMenu, IconDeviceTabletMenu } from './Toolbars2/icons';

type Props = {
  styles?: React.CSSProperties;
  pageId: string;
};

export default function (props: Props) {
  const dispatch = useDispatch();
  const device = useSelector(getDevice);
  const activeSection = useSelector(getActiveSection);
  const activeToolbar = useSelector(getActiveToolbar);
  const sectionIds = useSelector((state) => selectPageSectionIds(state, props.pageId));
  const sections = useSelector((state) => selectSections(state, sectionIds));
  const mobileRotated = useSelector(getMobileRotated);
  const tabletRotated = useSelector(getTabletRotated);

  function clearState(newSectionDevice: string) {
    // if the active section is not visible for the device, don't show it
    const sec = sections.find((section) => section.id === activeSection);
    if (
      sec &&
      ((newSectionDevice === 'desktop' && sec.content?.lg?.section?.visible === false) ||
        (newSectionDevice === 'tablet' && sec.content?.md?.section?.visible === false) ||
        (newSectionDevice === 'mobile' && sec.content?.sm?.section?.visible === false))
    ) {
      dispatch(setActiveToolbar(undefined));
      dispatch(setActiveSection(undefined));
    } else if (device === 'desktop' && newSectionDevice !== 'desktop') {
      if (!activeToolbar) {
        dispatch(setActiveToolbar(activeSection));
      }
    } else if (device !== 'desktop' && newSectionDevice === 'desktop') {
      if (sections.some((section) => section.id === activeToolbar)) {
        dispatch(setActiveToolbar(undefined));
      }
    }
  }

  return (
    <section className={adminBarStyles.deviceSection} style={props.styles}>
      {['desktop', 'tablet', 'mobile'].map((sectionDevice) => (
        <div
          className={adminBarStyles.deviceButton}
          onClick={() => {
            if (sectionDevice !== device) {
              dispatch(changeDevice(sectionDevice));
              clearState(sectionDevice);
            } else {
              if (sectionDevice === 'mobile') {
                dispatch(changeRotation(sectionDevice, !mobileRotated));
              } else if (sectionDevice === 'tablet') {
                dispatch(changeRotation(sectionDevice, !tabletRotated));
              }
            }
          }}
        >
          <span
            className={classnames({
              [adminBarStyles.active]: device === sectionDevice,
              [adminBarStyles.mobile]: sectionDevice === 'mobile',
            })}
            style={{
              transform:
                (sectionDevice === 'tablet' && tabletRotated) || (sectionDevice === 'mobile' && mobileRotated)
                  ? 'rotate(-90deg)'
                  : null,
            }}
          >
            {sectionDevice === 'desktop' ? (
              <IconDeviceDesktopMenu />
            ) : sectionDevice === 'tablet' ? (
              <IconDeviceTabletMenu />
            ) : (
              <IconDeviceMobileMenu />
            )}
          </span>
        </div>
      ))}
    </section>
  );
}
