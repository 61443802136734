import '../highlight';

import Quill from './quill-1.3.7/quill';

import LinkBlot from './blots/LinkBlot';
import HighlightBlot from './blots/HighlightBlot';
import ImageBlot from './blots/ImageBlot';
import VideoBlot from './blots/VideoBlot';
import DividerBlot from './blots/DividerBlot';
import VideoEmbedBlot from './blots/VideoEmbedBlot';
import PodcastEmbedBlot from './blots/PodcastEmbed';
import TwitterEmbedBlot from './blots/TwitterBlot';
import BlockBlot from './blots/BlockBlot';
import FormEmbedBlot from './blots/FormEmbedBlot';
// import SlideShareBlot from './blots/SlideShareBlot';

Quill.register(LinkBlot, true);
Quill.register(BlockBlot, true);
Quill.register(HighlightBlot);
Quill.register(DividerBlot);
Quill.register(ImageBlot, true);
Quill.register(VideoBlot, true);
Quill.register('formats/video_embed', VideoEmbedBlot);
Quill.register('formats/posdast_embed', PodcastEmbedBlot);
Quill.register('formats/twitter_embed', TwitterEmbedBlot);
Quill.register('formats/form_embed', FormEmbedBlot);
// Quill.register('formats/slideshare', SlideShareBlot);

export default Quill;
window.Quill = Quill;
