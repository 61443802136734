import { schema, normalize, denormalize } from 'normalizr';

import omit from 'lodash/omit';
import transform from 'lodash/transform';

const normalizePageList = (function () {
  // pages with content omitted
  const page = new schema.Entity(
    'pages',
    {},
    {
      processStrategy: (value, parent, key) => {
        return omit(value, 'content');
      },
    }
  );
  const pages = [page];

  // raw pages
  const rawPage = new schema.Entity('rawPages');
  const rawPages = [rawPage];

  return function normalizePageList(data) {
    const normalized = normalize(data, pages);
    const normalizedRaw = normalize(data, rawPages);

    return {
      ...normalized,
      entities: {
        ...normalized.entities,
        ...normalizedRaw.entities,
      },
    };
  };
})();

const { normalizePage, denormalizePage } = (function () {
  const section = new schema.Entity(
    'sections',
    {},
    {
      processStrategy: (value, parent, key) => {
        return {
          ...value,
          pageId: parent.pageId,
          pageType: parent.pageType,
        };
      },
    }
  );

  section.define({
    variants: [section],
  });

  section.define({
    children: [section],
  });

  const content = new schema.Entity(
    'content',
    {
      sections: [section],
    },
    {
      idAttribute: (value, parent, key) => {
        return parent.id;
      },
      processStrategy: (value, parent, key) => {
        return {
          ...value,
          pageId: parent.id,
          pageType: parent.item_type,
        };
      },
    }
  );

  const page = new schema.Entity('pages', {
    content: content,
  });

  const rawPage = new schema.Entity('rawPages');

  function removeForeignKeys(entities) {
    return transform(
      entities,
      (accum, value, key) => {
        accum[key] = omit(value, ['pageId', 'pageType']);
      },
      {}
    );
  }

  return {
    normalizePage: function normalizePage(response) {
      const normalized = normalize(response, page);
      const normalizedRaw = normalize(response, rawPage);
      return {
        ...normalized,
        entities: {
          ...normalized.entities,
          ...normalizedRaw.entities,
        },
      };
    },
    denormalizePage: function denormalizePage(input, entities) {
      const processedEntities = {
        ...entities,
        content: removeForeignKeys(entities.content),
        sections: removeForeignKeys(entities.sections),
      };
      return denormalize(input, page, processedEntities);
    },
  };
})();

window.normalizePage = normalizePage;
window.denormalizePage = denormalizePage;

export { normalizePageList, normalizePage, denormalizePage };
