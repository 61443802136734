import React, { useMemo, useState } from 'react';
import isEqual from 'lodash/isEqual';
import { SketchPicker } from 'react-color';
import SideDrawer from '../../../base/SideDrawer';
import { ZeroColorState } from './ZeroColorState';
import { ReactComponent as SvgIconAdd } from '../../../../assets/images/icon-plus.svg';
import { ColorItem } from './ColorItem';
import ValidationMessage from '../../../base/FormHelpers/ValidationMessage';
import { hexAndRgbaValidation, hexValidation } from '../../../../lib/hexAndRgbaValidation';

import useKeyBinding from 'hooks/useKeyBinding';

import styles from './ColorStyles.module.scss';
import FormControl from 'components/base/FormHelpers/FormControl';

export default ({ site, updateColors, ...props }) => {
  const [isOpen, toggleDrawer] = useState(false);
  const [updating, setUpdating] = useState('');
  const [value, setValue] = useState({});
  const [inputError, setInputError] = useState(false);
  const [inputErrorName, setInputErrorName] = useState(false);

  const submit = (e) => {
    e?.preventDefault();
    if (updating) {
      let valueCode = value.code;
      const isLabelDefault = site.styles.defaultColors.some((defaultColor) => defaultColor.label === value.label);
      if (!value.isDefault && isLabelDefault) {
        setInputErrorName(true);
      } else if (hexAndRgbaValidation(valueCode)) {
        const val = hexValidation(valueCode) && valueCode[0] !== '#' ? `#${valueCode}` : valueCode;

        let newColors = [...(!value.isDefault ? site.styles.colors : site.styles.defaultColors)].map((color) =>
          isEqual(color, updating) ? { ...value, code: val } : color
        );
        newColors = newColors.filter((v, i, a) => a.indexOf(v) === i);

        const path = value.isDefault ? 'defaultColors' : 'colors';
        updateColors(path, newColors);
        setInputError(false);
        setInputErrorName(false);
        toggleDrawer(false);
        setUpdating('');
        setValue('');
      } else setInputError(true);
    } else {
      const isDefault = site.styles.defaultColors.some((defaultColor) => defaultColor.label === value.label);
      if (isDefault) {
        setInputErrorName(true);
      } else if (hexAndRgbaValidation(value.code)) {
        const val = hexValidation(value.code) && value.code[0] !== '#' ? `#${value.code}` : value.code;

        updateColors('colors', [...new Set([...site.styles.colors, { code: val, label: value.label }])]);
        setInputError(false);
        setInputErrorName(false);
        toggleDrawer(false);
        setValue('');
      } else setInputError(true);
    }
  };

  useKeyBinding('keydown', 13, () => {
    isOpen && submit();
  });

  return (
    <>
      <header ref={props.scrollRef} className={styles.colorsStylesHeader}>
        <h1>Color palette</h1>
        <small>Add colors you plan on using often.</small>
        <button onClick={() => toggleDrawer(!isOpen)} className="button button-primary button-icon">
          <SvgIconAdd height={15} width={15} />
          <span>New Color</span>
        </button>
      </header>
      {[...site.styles.defaultColors, ...site.styles.colors].length ? (
        <div className={styles.colorsContainer}>
          {[...site.styles.defaultColors, ...site.styles.colors].map((color, i) => (
            <ColorItem
              key={`${color.label}-${i}`}
              color={color}
              removeColor={() => {
                const colors = [...site.styles.colors.filter((c) => color !== c)];
                updateColors('colors', colors);
              }}
              setValue={(color) => {
                const isDefault = site.styles.defaultColors.some((defaultColor) => defaultColor.label === color.label);
                if (typeof color === 'object') {
                  setValue({ ...color, isDefault });
                } else {
                  setValue({ code: color, isDefault });
                }
              }}
              setUpdating={setUpdating}
              toggleDrawer={toggleDrawer}
            />
          ))}
        </div>
      ) : (
        <ZeroColorState open={() => toggleDrawer(true)} />
      )}
      <SideDrawer
        isOpen={isOpen}
        title={updating ? 'Update color' : 'Add a color to your palette'}
        close={() => {
          setValue('');
          setUpdating('');
          toggleDrawer(false);
          setInputError(false);
          setInputErrorName(false);
        }}
      >
        <form onSubmit={submit}>
          <fieldset>
            <label htmlFor="color-name">Name</label>
            <FormControl
              name="colorName"
              id="color-name"
              onChange={(e) => setValue({ ...value, label: e.target.value })}
              value={value.label}
              autoComplete="off"
              type="text"
              placeholder="Enter color name"
              disabled={value.isDefault}
            />
            {inputErrorName && <ValidationMessage message="Name cannot be a system color name" />}
            {value.isDefault && (
              <small>
                This is a system color. You can’t change its name or remove it.{' '}
                <a href="https://unstack.com/goto?system-colors" target="_blank">
                  Learn more
                </a>
              </small>
            )}
          </fieldset>
          <fieldset>
            <label>{value ? (hexValidation(value.code) ? 'Hex Color' : 'RGBA Color') : 'Hex Color'}</label>
            <div className={styles.inputContainer}>
              <div
                className={styles.colorSwatch}
                style={{
                  background: `${hexValidation(value.code) && (value?.code)[0] !== '#' ? '#' : ''}${value.code}`,
                }}
              />
              <input
                className={styles.colorValueInput}
                type="text"
                name="color"
                placeholder="Example: #000000"
                onChange={(e) => setValue({ ...value, code: e.target.value })}
                value={value.code}
              />
            </div>
            {inputError && <ValidationMessage message="Enter valid HEX or RGB value" />}
            <div className={styles.colorPicker}>
              <SketchPicker
                width="auto"
                color={value.code}
                onChange={(color) => {
                  const c = hexValidation(color.hex)
                    ? color.hex
                    : `rgba(${color.rgb.r},${color.rgb.g},${color.rgb.b},${color.rgb.a})`;
                  setValue({ ...value, code: c });
                }}
                disableAlpha
                presetColors={[]}
              />
            </div>
            <>
              <input
                type="submit"
                value={updating ? 'Update color' : 'Add color'}
                className="button button-primary button-full"
                onClick={submit}
              />
              {updating && !value.isDefault && (
                <input
                  value="Remove color"
                  className="button button-secondary button-full"
                  onClick={() => {
                    const colors = [...site.styles.colors.filter((c) => updating !== c)];
                    updateColors('colors', colors);
                    setValue('');
                    setUpdating('');
                    toggleDrawer(false);
                  }}
                />
              )}
            </>
          </fieldset>
        </form>
      </SideDrawer>
    </>
  );
};
