import * as types from './actionTypes';

export function open(pageId, ops = [], errors = {}) {
  return {
    type: types.PAGE_SETTINGS_OPEN,
    payload: { pageId, ops, errors },
  };
}

export function close() {
  return {
    type: types.PAGE_SETTINGS_CLOSE,
  };
}

export function addOps(ops) {
  return {
    type: types.PAGE_SETTINGS_ADD_OPS,
    payload: { ops },
  };
}

export function save() {
  return {
    type: types.PAGE_SETTINGS_SAVE,
  };
}

export function publish() {
  return {
    type: types.PAGE_SETTINGS_PUBLISH,
  };
}

export function resetState() {
  return {
    type: types.PAGE_SETTINGS_RESET,
  };
}
