import React from 'react';
import { connect } from 'react-redux';

import * as articleSelectors from '../../reducers/articlesReducer';
import * as articleActions from '../../actions/articleActions';
import * as blogSelectors from 'reducers/blogsReducer';

import { set } from '../../lib/immutable-operations/operations';
import find from 'lodash/find';
import get from 'lodash/get';
import slugify from '../../lib/slugify';
import toPlaintext from '../../lib/quill/quill-delta-to-plaintext';

import ContentEditable from './ContentEditable';
import RichTextEditor from '../../lib/quill/components/RichTextEditor';
import BlogAside from './BlogAside';

function mapStateToProps(state, ownProps) {
  const { articleId } = ownProps;
  const articleDraft = articleSelectors.selectDraft(state, articleId) || {};

  let blog;
  if (articleDraft && articleDraft.blog) blog = blogSelectors.selectBlog(state, articleDraft.blog);

  return {
    article: articleDraft,
    blog: blog,
  };
}

const mapDispatchToProps = {
  addAutosaveOps: articleActions.addAutosaveOps,
};

class Blog extends React.Component {
  state = { tableOfContents: [] };
  editor = React.createRef();

  componentDidUpdate(prevProps) {
    if (prevProps.article.content.body !== this.props.article.content.body) {
      this.updateMetaImage();
    }
  }

  // Handle changes and save to redux state
  // --------------------------------------------------------------------------

  addAutosaveOp(op) {
    this.props.addAutosaveOps(this.props.articleId, op);
  }

  handleHeaderChange = (value) => {
    this.addAutosaveOp(set('content.header', value));
  };

  handleBodyChange = (value) => {
    this.addAutosaveOp(set('content.body', value));
    this.updateMetaImage();
  };

  // Update meta info
  // --------------------------------------------------------------------------

  // If the article's name, meta.title, and slug are empty, update them from
  // the content.header.
  updateNameFromHeader = () => {
    const { article } = this.props;
    if (!article.name && !article.meta.title && article.slug == null) {
      const value = toPlaintext(article.content.header);
      if (value) {
        this.addAutosaveOp(set('name', value));
        this.addAutosaveOp(set('meta.title', value));
        this.addAutosaveOp(set('slug', slugify(value)));
      }
    }
  };

  // Get first image and set it as meta.image
  updateMetaImage() {
    const metaImgSrc = this.props.article.meta.image;
    const bodyDelta = this.props.article.content.body;
    const socialImage = this.props.site.elements.social_image === '#' ? null : this.props.site.elements.social_image;

    if (bodyDelta == null || bodyDelta.ops == null) return undefined;

    const firstImageOp = find(bodyDelta.ops, (op) => op.insert && op.insert.image);

    // Temporarily reinstated to revert the featured_media PR
    // if (firstImageOp == null) {
    //   if (metaImgSrc != null) this.addAutosaveOp(set('meta.image', null));
    // } else {
    //   const firstImageSrc = firstImageOp.insert.image.src;
    //   console.log('else', metaImgSrc, firstImageOp);
    //   if (metaImgSrc !== firstImageSrc)
    //     this.addAutosaveOp(set('meta.image', firstImageSrc));
    // }

    if (!metaImgSrc)
      if (socialImage) {
        const socialImageSrc = socialImage.src;
        if (metaImgSrc !== socialImageSrc) this.addAutosaveOp(set('meta.image', socialImageSrc));
      } else if (firstImageOp == null || this.props.article.featured_media == null) {
        if (metaImgSrc != null) this.addAutosaveOp(set('meta.image', null));
      } else {
        const firstImageSrc = firstImageOp.insert.image.src;
        if (metaImgSrc !== firstImageSrc) this.addAutosaveOp(set('meta.image', firstImageSrc));
      }
  }

  // Misc.
  // --------------------------------------------------------------------------

  // Focus the body editor after tabbing from the header
  // NOTE: This appears to not be necessary anymore (?)
  handleKeyDown = (e) => {
    if (e.which === 9 && this.editor.current && this.editor.current.quill) {
      e.preventDefault();
      this.editor.current.quill.focus();
    }
  };

  // Render
  // --------------------------------------------------------------------------

  render() {
    const { article, blog } = this.props;
    const { content } = article;

    const showTableOfContents = !get(blog, 'config.disableTOC', false) && Boolean(this.state.tableOfContents.length);

    return (
      <>
        <div className="eps sriracha">
          <div className="container blog-container">
            {showTableOfContents && (
              <aside className="blog-aside-content-links">
                <div style={{ top: '6rem' }} className="container">
                  <ul id="toc">
                    <div>
                      <strong>Table of contents</strong>
                    </div>
                    {this.state.tableOfContents.map((line, i) => {
                      const op = line.ops.find((op) => op && op.insert && typeof op.insert === 'string');
                      const name = op && op.insert;

                      if (name) {
                        return (
                          <li key={i}>
                            <a>{name}</a>
                          </li>
                        );
                      } else {
                        return null;
                      }
                    })}
                  </ul>
                </div>
              </aside>
            )}
            <div className="blog-content" data-test-id="blog-post-container">
              <header>
                <ContentEditable
                  tag="h1"
                  onInput={this.handleHeaderChange}
                  placeholder="Add a header"
                  value={content.header}
                  onKeyDown={this.handleKeyDown}
                  onBlur={this.updateNameFromHeader}
                  autoFocus={content.header == null}
                />
              </header>
              <article>
                <RichTextEditor
                  onChange={this.handleBodyChange}
                  value={content.body}
                  placeholder="Begin your blog post..."
                  ref={this.editor}
                  allowEmbeds
                  updateTableOfContents={(tableOfContents) => this.setState({ tableOfContents })}
                  isBlog
                />
              </article>
            </div>
            <BlogAside blogId={article.blog} readOnly />
          </div>
        </div>
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Blog);
