import React, { useMemo, FC, CSSProperties, memo, useRef, useCallback } from 'react';
import { del } from 'object-path-immutable';
import classNames from 'classnames';
import styles from './FormContentEditor.module.scss';
import {
  Field,
  FieldListCoordinates,
  FieldOrRow,
  StrictFieldsList,
  TextField,
  PlaceholderCoords,
  MoveField,
  CancelDrag,
  RowOrFieldId,
  VisibleField,
  HiddenField,
  SubmitButton,
  CheckboxField,
  RadioField,
  SelectField,
} from './types';
import { ConnectDragSource, useDrag, useDrop, useDragDropManager } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import usePrevious from 'hooks/usePrevious';
import { insertItemIntoFieldsList } from './utils';
import isObject from 'lodash/isObject';
import { FormData } from './types';

const PLACEHOLDER = 'PLACEHOLDER';
type RowWithPlaceholder = (Field | typeof PLACEHOLDER)[];
type FieldsListWithPlaceholder = (RowWithPlaceholder | typeof PLACEHOLDER)[];
type SetPlaceholderCoords = React.Dispatch<React.SetStateAction<PlaceholderCoords>>;

type FieldsListEditorProps = {
  value: StrictFieldsList;
  customStyles: FormData['content']['styles'];
  onChange: (value: StrictFieldsList) => void;
  editField: (coords: FieldListCoordinates) => void;
  placeholderCoords: PlaceholderCoords;
  setPlaceholderCoords: React.Dispatch<React.SetStateAction<PlaceholderCoords>>;
  moveField: MoveField;
  cancelDrag: CancelDrag;
  close: () => void;
  edittingFieldCoords: any;
};

export function FieldsListEditor(props: FieldsListEditorProps) {
  const [selectedFieldId, setSelectedFieldId] = React.useState(undefined);
  const [elementBeingDragged, setElementBeingDragged] = React.useState(undefined);

  function handleChange(i: number, value: FieldOrRow) {
    console.log('onChange', i, value);
  }
  function deleteRow(i: FieldListCoordinates[0]) {
    const newValue = del(props.value, `${i}`);
    props.onChange(newValue);
    props.close();
  }

  function deleteElement(coords: any) {
    const [i, j] = coords;
    const newValue = del(props.value, [`${i}`, `${j}`]);
    props.onChange(newValue);
    props.close();
  }

  const fieldsListWithPlaceholder: FieldsListWithPlaceholder = useMemo(() => {
    if (!props.placeholderCoords) return props.value;
    return insertItemIntoFieldsList(props.value, props.placeholderCoords, PLACEHOLDER);
  }, [props.value, props.placeholderCoords]);

  const [{ isOver, dragType }, dropRef] = useDrop(() => ({
    accept: ['new-field', 'existing-field', 'existing-row'],
    options: { anchorX: 0.2, anchorY: 0.4 },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      dragType: monitor.getItemType(),
    }),
  }));

  // Remove placeholder if the draggable item is no longer hovering over the
  // drop area. (e.g. if `isOver` goes from `true` to `false`).
  usePrevious(isOver, (prev, current) => {
    if (prev && !current) {
      props.setPlaceholderCoords(undefined);
      setElementBeingDragged(undefined);
    }
  });
  const showFadeStateBorder = () => {
    if (isOver || props.edittingFieldCoords) {
      return true;
    } else if (props.edittingFieldCoords && selectedFieldId) {
      return true;
    } else {
      return false;
    }
  };
  return (
    <div className="eps sriracha" style={{ textAlign: 'center' }}>
      <div
        data-test-id="fields-list-editor"
        className={`form-container unstack-form-container form-embed-card mx-auto ${styles.formEmbed} ${
          isOver || props.edittingFieldCoords ? `${styles.dropping}` : ''
        }`}
        style={props.customStyles}
      >
        <div className="unstack-form" ref={dropRef}>
          <div className={`${styles.droppable}`} data-test-id="editor">
            <div className="row-padding" onDragOver={() => props.setPlaceholderCoords([0])} />
            <div></div>

            {fieldsListWithPlaceholder.map((row, i) => {
              if (row === PLACEHOLDER) {
                // --- Render a full row-level placeholder. ---
                return (
                  <React.Fragment key="full-row-placeholder">
                    <div className={`${styles.row} row`} data-test-id="draggable-form-row">
                      <div className="col">
                        <Placeholder />
                      </div>
                    </div>
                    <div
                      className="row-padding"
                      // onDragOver={() => props.setPlaceholderCoords([i + 1])}
                    />
                  </React.Fragment>
                );
              }

              // Row ID is used for the react key prop. It must be unique, but
              // also consistent durring a drag-and-drop operation. So we create
              // a string that combines 'row-' with the row's first field's ID.
              const rowId = `row-${(row.find((field) => isObject(field) && field.id) as Field).id}`;
              const paddingRowId = (row.find((field) => isObject(field) && field.id) as Field).id;
              // --- Render a row that contains fields. ---
              return (
                <Draggable
                  type="existing-row"
                  key={rowId}
                  id={rowId}
                  coords={[i]}
                  onDrop={props.moveField}
                  onCancelDrag={() => {
                    props.cancelDrag();
                    deleteElement([i]);
                  }}
                >
                  {(dragSourceRef) => (
                    <React.Fragment>
                      <div className={`${styles.row} row`} data-test-id="row">
                        {row.map((field, j) => {
                          // --- Render a field-level placeholder ---
                          if (field === PLACEHOLDER) {
                            return (
                              <div key={PLACEHOLDER} className="col">
                                <Placeholder />
                              </div>
                            );
                          }

                          // --- Render a field ---
                          return (
                            <Draggable
                              type="existing-field"
                              key={field.id}
                              id={field.id}
                              coords={[i, j]}
                              onDrop={props.moveField}
                              onCancelDrag={() => {
                                props.cancelDrag();
                                deleteElement([i, j]);
                              }}
                            >
                              {(dragSourceRef) => (
                                <React.Fragment>
                                  <DragPreview field={field} key={`field-preview-${field.id}`} />
                                  <div
                                    data-test-id="field-container"
                                    className={`${styles.fieldContainer} col ${
                                      field.type === 'submit' ? 'col-inflexible' : ''
                                    } ${
                                      props.edittingFieldCoords && selectedFieldId === field.id ? styles.selected : ''
                                    }
                                    ${elementBeingDragged === field.id ? styles.hideField : ''}`}
                                    onClick={() => {
                                      props.editField([i, j]);
                                      setSelectedFieldId(field.id);
                                    }}
                                    onDragOver={() => {
                                      const coords: PlaceholderCoords = dragType === 'existing-row' ? [i] : [i, j];
                                      props.setPlaceholderCoords(coords);
                                    }}
                                    onDrag={() => {
                                      if (!elementBeingDragged) {
                                        setElementBeingDragged(field.id);
                                      }
                                    }}
                                    ref={dragSourceRef}
                                  >
                                    <RenderedField
                                      value={field}
                                      asPreview={false}
                                      showFadeStateBorder={showFadeStateBorder()}
                                    />
                                  </div>
                                </React.Fragment>
                              )}
                            </Draggable>
                          );
                        })}
                      </div>
                      <div
                        className={`row-padding ${
                          paddingRowId === elementBeingDragged && row.length < 2 ? styles.hideField : ''
                        }`}
                        onDragOver={() => props.setPlaceholderCoords([i + 1])}
                      ></div>
                    </React.Fragment>
                  )}
                </Draggable>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

type DraggableProps = {
  type: 'existing-row' | 'existing-field';
  id: RowOrFieldId;
  coords: PlaceholderCoords;
  onDrop: MoveField;
  onCancelDrag: () => void;
  children: (dragSourceRef: ConnectDragSource) => JSX.Element;
};

const previewStyles: CSSProperties = {
  display: 'inline-block',
  transform: 'rotate(-5deg)',
  width: '353px',
  WebkitTransform: 'rotate(-5deg)',
};

const layerStyles: CSSProperties = {
  position: 'fixed',
  pointerEvents: 'none',
  zIndex: 100,
  left: 0,
  top: 0,
  width: '100%',
  height: '100%',
};

const DragPreview = memo((props: { field: Field }) => {
  const [item, previewRef] = useDragPreview();
  const showPreview = item && item.id && props.field.id == item.id;
  return (
    <div style={layerStyles}>
      {showPreview && (
        <div ref={previewRef} {...item}>
          <div style={previewStyles}>
            <RenderedField value={props.field} asPreview showFadeStateBorder={false} />
          </div>
        </div>
      )}
    </div>
  );
});

const useDragPreview = () => {
  const previewRef = useRef(null);
  const monitorRef = useRef(null);
  const [item, setItem] = React.useState(null);
  const dragDropManager = useDragDropManager();
  const updateItem = useCallback(() => setItem(monitorRef.current.getItem()), []);
  const updateOffset = useCallback(
    () =>
      requestAnimationFrame(() => {
        if (previewRef.current) {
          const offset = monitorRef.current.getSourceClientOffset() || {};
          previewRef.current.style.transform = `translate(${offset.x}px, ${offset.y}px)`;
        }
      }),
    []
  );

  React.useEffect(() => {
    let unsubscribeToStateChange: () => void;
    let unsubscribeToOffsetChange: () => void;

    if (!monitorRef.current) {
      monitorRef.current = dragDropManager.getMonitor();
      unsubscribeToStateChange = monitorRef.current.subscribeToStateChange(updateItem);
      unsubscribeToOffsetChange = monitorRef.current.subscribeToOffsetChange(updateOffset);
    }

    return () => {
      if (typeof unsubscribeToStateChange === 'function') unsubscribeToStateChange();
      if (typeof unsubscribeToOffsetChange === 'function') unsubscribeToOffsetChange();
    };
  }, [dragDropManager, updateItem, updateOffset]);

  return [item, previewRef];
};

function Draggable(props: DraggableProps): JSX.Element {
  const [, dragSourceRef, dragPreviewRef] = useDrag(
    () => ({
      type: props.type,
      item: { id: props.id },

      end: (item: any, monitor: { didDrop: () => any }) => {
        if (monitor.didDrop()) {
          props.onDrop(props.coords);
        } else {
          props.onCancelDrag();
        }
      },
    }),
    [props.type, props.id, props.coords, props.onDrop, props.onCancelDrag]
  );
  React.useEffect(() => {
    dragPreviewRef(getEmptyImage(), { captureDraggingState: false });
  }, []);
  return props.children(dragSourceRef);
}

function Placeholder() {
  return <div className={`${styles.placeholder} placeholder`}>&nbsp;</div>;
}

type RenderedFieldProps = {
  value: Field;
  asPreview: boolean;
  showFadeStateBorder: any;
};

function RenderedField(props: RenderedFieldProps) {
  const fieldClasses = classNames(styles.fieldset, {
    [styles.previewStyles]: props.asPreview,
  });
  switch (props.value.type) {
    case 'select': {
      const field = props.value as SelectField;
      return (
        <fieldset className={fieldClasses} data-test-id="field">
          {field.label && <label>{field.label}</label>}
          <select>
            {field.options.map((option) => (
              <option value={option[0]}>{option[1]}</option>
            ))}
          </select>
          {field.help_text && <small>{field.help_text}</small>}
        </fieldset>
      );
    }
    case 'checkbox': {
      const field = props.value as CheckboxField;
      return (
        <fieldset className={fieldClasses} data-test-id="field">
          {field.label && <label>{field.label}</label>}
          <label className="checkbox">
            <input type="checkbox" readOnly /> {field.placeholder}
            <span />
          </label>
          {field.help_text && <small>{field.help_text}</small>}
        </fieldset>
      );
    }
    case 'radio': {
      const field = props.value as RadioField;
      return (
        <fieldset className={fieldClasses} data-test-id="field">
          {field.label && <label>{field.label}</label>}
          <div className="radio-container">
            {field.options.map((option) => (
              <div>
                <input type="radio" value={option[0]} readOnly />
                <label>{option[1]}</label>
              </div>
            ))}
          </div>
          {field.help_text && <small>{field.help_text}</small>}
        </fieldset>
      );
    }
    case 'textarea': {
      const field = props.value as VisibleField;
      return (
        <fieldset className={fieldClasses}>
          {field.label && <label>{field.label}</label>}
          <textarea placeholder={field.placeholder} value="" readOnly data-test-id="field" />
          {field.help_text && <small>{field.help_text}</small>}
        </fieldset>
      );
    }
    case 'hidden': {
      const field = props.value as HiddenField;
      return (
        <input
          className={styles.hiddenField}
          readOnly
          type="text"
          value={`Hidden field${field.name ? ` (${field.name})` : ''}`}
        />
      );
    }
    case 'submit': {
      const field = props.value as SubmitButton;
      return (
        <fieldset className={fieldClasses}>
          <button className="button button-primary button-full" style={{ margin: 0 }}>
            {field.initial}
          </button>
          {field.help_text && <small>{field.help_text}</small>}
        </fieldset>
      );
    }
    default: {
      const field = props.value as VisibleField;
      return (
        <fieldset className={fieldClasses} data-test-id="field-set">
          {field.label && <label>{field.label}</label>}
          <input placeholder={field.placeholder} type={field.type} data-test-id="field" value="" readOnly />
          {field.help_text && <small>{field.help_text}</small>}
        </fieldset>
      );
    }
  }
}
