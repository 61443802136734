import React from 'react';
import { connect } from 'react-redux';

import * as blogSelectors from '../../../reducers/blogsReducer';
import * as blogActions from '../../../actions/blogActions';

import ArticleLayoutFooter from './ArticleLayoutFooter';

function mapStateToProps(state, ownProps) {
  return {
    experiments: blogSelectors.selectExperiments(state, ownProps.blogId),
  };
}

const mapDispatchToProps = {
  addFooter: blogActions.createFooter,
  removeFooter: blogActions.removeFooter,
  initializeABTestContainer: blogActions.initializeABTestContainer,
  initializeCarouselContainer: blogActions.initializeCarouselContainer,
};

function ArticleFooter(props) {
  const { blogId, update, content, experiments } = props;

  const onChange = (value) => {
    if (value) {
      let nextContentState = content;
      if (nextContentState.children && nextContentState.children.length) {
        const idx = nextContentState.children.findIndex((child) => child.id === value.id);
        nextContentState.children[idx] = value;
      } else {
        nextContentState = value;
      }
      update(nextContentState);
    } else {
      update();
    }
  };

  const createElement = (type, id, isChild) => {
    props.addFooter(
      {
        item: blogId,
        [type]: id,
      },
      (res) => {
        let value = typeof content == 'string' || !content ? res : content;

        if (isChild) value.children = [...value.children, res];
        update(value);
      },
      isChild
    );
  };

  return (
    <footer>
      <ArticleLayoutFooter
        value={content || {}}
        createElement={createElement}
        onChange={onChange}
        remove={props.removeFooter}
        removeChild={(idx) => {
          const nextContentState = content;
          nextContentState.children.splice(idx, 1);
          update(nextContentState);
        }}
        tests={experiments}
        initializeCarouselContainer={(value, type) => {
          props.initializeCarouselContainer(value, type, (res) => {
            update(res);
          });
        }}
        updateContainersProps={update}
        initializeABTestContainer={(value, type) => {
          props.initializeABTestContainer(value, type, (res) => {
            update(res);
          });
        }}
        discardABTestContainer={update}
        updateTestContent={update}
      />
    </footer>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(ArticleFooter));
