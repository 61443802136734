import React, { useState, useEffect } from 'react';
import CreatableSelect from 'react-select/creatable';

import styles from './MultiSelect.module.scss';

export default ({ defaults, ...props }) => {
  const [options, setOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    props
      .endpoint('')
      .then((res) => res.json)
      .then((result) => {
        setOptions([...result.map((tag) => ({ value: tag.name, label: tag.name }))]);
        setIsLoading(false);
      });
  }, []);
  const getAsyncOptions = (inputValue) => {
    setIsLoading(true);
    return props
      .endpoint(inputValue)
      .then((res) => res.json)
      .then((result) => {
        setOptions([...result.map((tag) => ({ value: tag.name, label: tag.name }))]);
        setIsLoading(false);
      });
  };

  const wait = 250;
  const loadOptions = (inputValue, cb) => getAsyncOptions(inputValue, cb);
  const debouncedLoadOptions = _.debounce(loadOptions, wait);

  return (
    <CreatableSelect
      isMulti
      maxMenuHeight={129}
      isClearable={false}
      isDisabled={false}
      onChange={(newValue, actionMeta) => {
        props.onChange(newValue, actionMeta);
      }}
      isLoading={isLoading}
      onCreateOption={props.onTagCreate}
      options={options}
      onInputChange={(v) => debouncedLoadOptions(v)}
      value={props.value}
      className={styles.TagSelect}
      classNamePrefix={styles.prefix}
    />
  );
};
