import React, { useState, useEffect } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import useUrlSite from '../../../../hooks/useUrlSite';
import { connect, useSelector } from 'react-redux';
import classnames from 'classnames';

import { useContactDetailData } from '../hooks';
import concatenateName from '../../../../lib/formatters/concatenate-name';

import Notes from './AddNotes';
import EditContact from './EditContact';
import ContactInfo from '../ContactInfo';
import ContactTasks from './ContactTasks';
import ContactNotes from './ContactNotes';
import AddTask from '../../Tasks/AddTask';
import FixedHeader from '../../FixedHeader';
import Spinner from '../../../base/Spinner';
import DocumentTitle from '../../../DocumentTitle';

import ContactActions from '../ContactActions';
import { selectHasPermission } from '../../../../reducers/policyReducer';
import { selectContactTaskCount } from '../../../../reducers/tasksReducer';
import { deleteContact, createOrUpdateContact } from '../../../../actions/contactsActions';
import { fetchFilteredNotes } from '../../../../actions/notesActions';
import { fetchFilteredTasks, markTaskAsDone, getTasksCountForContact } from '../../../../actions/tasksActions';
import { selectCurrentAccountUser } from '../../../../reducers/accountUsersReducer';

import iconBack from '../../../../assets/images/icon-admin-panel-back.svg';
import defaultAvatar from '../../../../assets/images/icon-profile-default.svg';
import { ReactComponent as SvgIconEdit } from '../../../../assets/images/icons8-pencil.svg';
import { ReactComponent as SvgIconAddNote } from '../../../../assets/images/icon-notepad.svg';
import { ReactComponent as SvgIconTasks } from '../../../../assets/images/icon-today.svg';

import styles from './ContactDetail.module.scss';

const mapDispatchToProps = {
  markTaskAsDone,
  deleteContact,
  createOrUpdateContact,
  fetchFilteredTasks,
  fetchFilteredNotes,
  getTasksCountForContact,
};

function ContactDetail(props: any) {
  const urlSite = useUrlSite();
  const { contactId } = useParams();
  const location = useLocation();
  const [drawerType, setDrawerType] = useState('');
  const [activeTab, setActiveTab] = useState('activity');
  const [selectedContent, setSelectedContent] = useState({});
  const [refetchList, toggleRefetchList] = useState(true);
  const {
    deleteContact,
    createOrUpdateContact,
    fetchFilteredTasks,
    fetchFilteredNotes,
    markTaskAsDone,
    getTasksCountForContact,
  } = props;
  const [temp, setTemp] = useState({ id: contactId });
  const hasPermission = useSelector((state) => selectHasPermission(state));
  const owner = useSelector((state) => selectCurrentAccountUser(state));
  const [contact]: any[] = useContactDetailData(temp);
  const locationState = location.state;
  const { search: locationSearch = '' } = locationState || {};

  useEffect(() => {
    async function fetch() {
      getTasksCountForContact(contactId);
    }

    fetch();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const contactTasksCount = useSelector((state) => selectContactTaskCount(state));

  if (!hasPermission('Contact')) {
    return hasPermission('Account') ? (
      <p>You don't have permission to access customers.</p>
    ) : (
      <Spinner className="fixed" />
    );
  }
  if (!contact) return <Spinner className="fixed" />;

  const nameOrEmail = contactNameOrEmail(contact);

  return (
    <>
      <DocumentTitle title={`${nameOrEmail}'s info`} />
      <FixedHeader useColumnFlex>
        <div className={styles.headerOptionContainer} data-test-id="contact-detail-header">
          <Link to={`/${urlSite}/customers/${locationSearch || ''}`} className={styles.backButton} aria-label="back-to">
            <img src={iconBack} alt="go back" />
          </Link>
          <img className={styles.lgIcon} src={defaultAvatar} alt="" />
          Show all activity from <span className={styles.name + ' ph-redact-text'}>&nbsp;{nameOrEmail}</span>
          <div className={styles.iconHolder}>
            <div
              onClick={() => setDrawerType('task')}
              className={styles.headerOptions}
              data-for="tooltip-left"
              data-tip="Add task"
              data-test-id="add-task"
            >
              <SvgIconTasks height={16} width={16} />
            </div>
            <div
              onClick={() => setDrawerType('note')}
              className={styles.headerOptions}
              data-for="tooltip-left"
              data-tip="Add note"
              data-test-id="add-note"
            >
              <SvgIconAddNote height={16} width={16} />
            </div>
            <div
              className={styles.headerOptions}
              onClick={() => setDrawerType('contact')}
              data-for="tooltip-left"
              data-tip="Edit customer"
              data-test-id="edit"
            >
              <SvgIconEdit height={15} width={15} />
            </div>
          </div>
        </div>
        <div className={styles.navBarContainer} data-test-id="contact-detail-navbar">
          <span
            onClick={() => setActiveTab('activity')}
            data-test-id="tab-activity"
            className={classnames({
              [styles.activeLabel]: activeTab === 'activity',
            })}
          >
            Activity
          </span>
          <span
            onClick={() => setActiveTab('tasks')}
            data-test-id="tab-tasks"
            className={classnames({
              [styles.activeLabel]: activeTab === 'tasks',
            })}
          >
            Tasks {contactTasksCount ? <span className={styles.count}>{contactTasksCount}</span> : null}
          </span>
          <span
            onClick={() => setActiveTab('notes')}
            data-test-id="tab-notes"
            className={classnames({
              [styles.activeLabel]: activeTab === 'notes',
            })}
          >
            Notes
          </span>
        </div>
      </FixedHeader>
      <div className={styles.content} data-test-id="contact-detail-content">
        {activeTab === 'activity' && (
          <>
            <ContactActions
              actions={contact.activity}
              name={contact.first_name ? contact.first_name : contact.email}
              email={contact.email}
              sourceId={contact.source_id}
              notes={contact.notes}
            />
            <ContactInfo contact={contact} />
          </>
        )}
        {activeTab === 'tasks' && (
          <ContactTasks
            refetchList={refetchList}
            fetchTasks={fetchFilteredTasks}
            contactId={contactId}
            contactName={contactNameOrEmail(contact)}
            update={(data: any) => {
              markTaskAsDone(data);
            }}
            openDrawer={(selectedTask: any) => {
              setDrawerType('task');
              setSelectedContent(selectedTask);
            }}
          />
        )}
        {activeTab === 'notes' && (
          <ContactNotes
            refetchList={refetchList}
            fetchNotes={fetchFilteredNotes}
            contactId={contactId}
            contactName={contactNameOrEmail(contact)}
            openDrawer={(note: any) => {
              setDrawerType('note');
              setSelectedContent(note);
            }}
          />
        )}
      </div>
      <EditContact
        deleteContact={deleteContact}
        history={history}
        contact={contact}
        isOpen={drawerType === 'contact'}
        setDrawerType={setDrawerType}
        updateContact={createOrUpdateContact}
        setTemp={setTemp}
      />
      {drawerType === 'note' && (
        <Notes
          isOpen={drawerType === 'note'}
          setDrawerType={setDrawerType}
          contact={contact}
          selectedNote={selectedContent}
          setSelectedNote={setSelectedContent}
          refetchList={() => toggleRefetchList(!refetchList)}
          owner={owner.id}
        />
      )}
      {drawerType === 'task' && (
        <AddTask
          isOpen={drawerType === 'task'}
          setIsOpen={() => setDrawerType('')}
          contact={contact}
          refetchList={() => toggleRefetchList(!refetchList)}
          selectedTask={selectedContent}
          // @ts-ignore
          setSelectedTask={setSelectedContent}
          owner={owner.id}
        />
      )}
    </>
  );
}

export function contactNameOrEmail(contact: any) {
  if (contact.first_name || contact.last_name) {
    return concatenateName(contact.first_name, contact.last_name);
  } else {
    return contact.email;
  }
}

export default connect(null, mapDispatchToProps)(ContactDetail);
