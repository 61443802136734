// import React from 'react';

export const roleOptions = [
  {
    value: 'owner',
    label: 'Account Owner',
    description:
      'The Account Owner has the ability to manage every aspect of the account, including subscriptions and billing.',
  },
  {
    value: 'admin',
    label: 'Administrator',
    description:
      'Administrators have the ability to manage every aspect of the account, except for subscriptions and billing.',
  },
  {
    value: 'editor',
    label: 'Editor',
    description: 'Editors can create and edit content, as well as publish, unpublish, and start/stop AB Tests.',
  },
  {
    value: 'author',
    label: 'Author',
    description:
      'Authors can create and edit content and AB Tests, but cannot publish, unpublish, or start/stop tests.',
  },
  {
    value: 'contributor',
    label: 'Contributor',
    description: "Contributors can see and edit their own articles, but don't have other access to your site.",
  },
  {
    value: 'developer',
    label: 'Developer',
    description:
      'Developers can manage integrations, custom section types, styles, assets, and domain configuration details.',
  },
  {
    value: 'sales',
    label: 'Sales Rep',
    description: 'Sales Reps can manage contacts and view applicable insights.',
  },
];

export const roleMap = new Map(roleOptions.map((role) => [role.value, role]));
