import union from 'lodash/union';
import { combineReducers } from 'redux';
import { createSelector } from 'reselect';

import * as types from '../actions/actionTypes';

// ----------------------------------------------------------------------------
// Reducer
// ----------------------------------------------------------------------------

const initState = {};

function collectionsReducer(state = initState, action) {
  switch (action.type) {
    case types.PAGE_RECEIVE:
      const page = Object.values(action.payload.entities.pages);

      let collections = {};
      page.forEach((page) => {
        if (page.section_product_collections) {
          collections = page.section_product_collections;
        }
      });
      return {
        ...state,
        ...collections,
      };

    case types.SHOPIFY_STOREFRONT_RECEIVE_COLLECTIONS:
      return {
        ...state,
        ...action.payload,
      };

    case types.SHOPIFY_STOREFRONT_SAVE_COLLECTIONS_MANUALLY:
      return {
        ...state,
        ...action.payload,
      };

    // Default
    // ------------------------------------------------------------------------

    default:
      return state;
  }
}

export default combineReducers({
  collections: collectionsReducer,
});

// ----------------------------------------------------------------------------
// Selectors
// ----------------------------------------------------------------------------

// Raw selectors
// ----------------------------------------------------------------------------

function selectLocalState(globalState) {
  return globalState.storefront;
}

export const selectCollections = createSelector(selectLocalState, (localState) => localState.collections);
