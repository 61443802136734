import React, { ReactNode } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import SideDrawer from '../base/SideDrawer';

import * as uiTemplateSettingsActions from '../../actions/uiTemplateSettingsActions';
import * as templatesActions from '../../actions/templateActions';
import * as uiTemplateSettingsReducer from '../../reducers/uiTemplateSettingsReducer';
import * as templatesReducer from '../../reducers/templatesReducer';
import * as siteSelectors from '../../reducers/sitesReducer';
import FormControl from '../base/FormHelpers/FormControl';
import { PAGE_TYPES, ROUTES } from '../../constants';
import * as pageSelectors from 'reducers/pagesReducer';
import withUrlSite from 'containers/withUrlSite';
import { compose } from 'redux';

function mapStateToProps(state: any, ownProps: any) {
  const { pageId, isOpen } = uiTemplateSettingsReducer.selectProps(state);
  // @ts-ignore
  const site = siteSelectors.selectSiteByDomain(state, ownProps.urlSite);

  const page = pageSelectors.selectPage(state, ownProps.pageId);

  return {
    // @ts-ignore
    existingTemplate: templatesReducer.selectByItemId(state, pageId),
    isCreating: templatesReducer.selectIsCreating(state),
    pageId,
    isOpen,
    site: site.id,
    siteName: site.name,
    pageType: page?.['item_type'],
  };
}

const mapDispatchToProps = {
  close: uiTemplateSettingsActions.close,
  create: templatesActions.create,
  update: templatesActions.update,
};

class CreateTemplate extends React.PureComponent<any, any> {
  initialState = {
    name: '',
    id: '',
    isMakingNew: false,
    templateCreated: '',
  };
  constructor(props: any) {
    super(props);
    this.state = this.initialState;
  }

  close = () => {
    this.setState(this.initialState);
    this.props.close();
  };

  render() {
    const { name, id, isMakingNew, templateCreated } = this.state;
    const {
      isOpen,
      create,
      update,
      pageId,
      isCreating,
      existingTemplate = [],
      site,
      siteName,
      pageType,
      urlSite,
    } = this.props;

    const title = templateCreated ? 'Template saved!' : 'Save as reusable template';
    const templateLabel =
      pageType === PAGE_TYPES.LANDING_PAGE
        ? `All ${siteName} landing page templates.`
        : `All ${siteName} page templates.`;

    return (
      <SideDrawer
        isOpen={isOpen}
        close={this.close}
        cypress-testing-coverage-areas
        titleTheme={templateCreated}
        title={title}
        render={() =>
          templateCreated ? (
            <form>
              <fieldset>
                <p data-test-id="success-message">
                  <small>Nice work. We've saved this and added it in your templates library.</small>
                </p>
              </fieldset>
              <fieldset>
                <input
                  readOnly
                  aria-label="save-as-template"
                  value={this.props.isListView ? 'Go back' : 'Continue editing this page'}
                  className="button button-primary button-full"
                  onClick={() => {
                    this.close();
                  }}
                />
                <Link to={ROUTES.customTemplates(urlSite, pageType)}>
                  <input readOnly value="Go to templates library" className="button button-secondary button-full" />
                </Link>
              </fieldset>
            </form>
          ) : (
            <form>
              <fieldset>
                <p>
                  <small>
                    Save pages you've built as reusable templates to built content faster.{' '}
                    <Link to={ROUTES.customTemplates(urlSite, pageType)}>{templateLabel}</Link>
                  </small>
                </p>
              </fieldset>
              {!Boolean(existingTemplate.length) || isMakingNew ? (
                <>
                  <fieldset>
                    <label htmlFor="template">Give your template a name</label>
                    <FormControl
                      name="name"
                      id="template"
                      onChange={(e: any) => this.setState({ name: e.target.value })}
                      autoComplete="off"
                      type="text"
                      value={name}
                    />
                  </fieldset>
                  <fieldset>
                    <input
                      readOnly
                      aria-label="save-as-template"
                      value={isCreating ? 'Saving' : 'Save this page as template'}
                      className="button button-primary button-full"
                      onClick={() => {
                        const promise = new Promise((res, rej) => {
                          create(
                            {
                              name,
                              item_id: pageId,
                              weight: 1,
                              site,
                            },
                            res,
                            rej
                          );
                        });
                        promise.then(() => this.setState({ templateCreated: 'success' }));
                      }}
                      disabled={!name || isCreating}
                    />
                  </fieldset>
                </>
              ) : (
                <>
                  <fieldset>
                    <label>Pick a template to update</label>
                    <FormControl
                      tag="select"
                      name="id"
                      onChange={(e: any) => this.setState({ id: e.target.value })}
                      value={id}
                    >
                      <option value=""></option>
                      {existingTemplate.map((template: { id: string; name: string }) => (
                        <option value={template.id} key={template.id}>
                          {template.name}
                        </option>
                      ))}
                    </FormControl>
                  </fieldset>
                  <fieldset>
                    <input
                      readOnly
                      value={isCreating ? 'Updating' : 'Update selected template'}
                      className="button button-primary button-full"
                      onClick={() => {
                        update({
                          id: existingTemplate.find((t: { id: string }) => t.id === id).id,
                        });
                        this.close();
                      }}
                      disabled={!id}
                    />
                    <input
                      readOnly
                      value={'Save this page as a new template'}
                      className="button button-secondary button-full"
                      onClick={() => this.setState({ isMakingNew: true })}
                    />
                  </fieldset>
                </>
              )}
            </form>
          )
        }
      />
    );
  }
}

export default compose(withUrlSite, connect(mapStateToProps, mapDispatchToProps))(CreateTemplate) as (
  isListView: any
) => JSX.Element;
