import React, { useEffect } from 'react';

import { Group, Label } from 'components/Editor/Toolbars2/Toolbar2';
import styles from './LayoutOption.module.scss';

import { ReactComponent as ToggleOnIcon } from '../../../../../../../../assets/images/toggle-on-icon.svg';
import { ReactComponent as ToggleOffIcon } from '../../../../../../../../assets/images/toggle-off-icon.svg';

const Toggle = (props: any) => {
  const { value, onChange, property } = props;

  useEffect(() => {
    if (value === undefined) {
      onChange(property.name, property.default === undefined ? true : property.default);
    }
  }, []);

  return (
    <Group className={props.className}>
      {property.label && <Label>{property.label}:</Label>}
      <span onClick={() => onChange(property.name, !value)}>
        {value ? (
          <span className={styles.toggleField}>
            <ToggleOnIcon />
            On
          </span>
        ) : (
          <span className={styles.toggleField}>
            <ToggleOffIcon />
            Off
          </span>
        )}
      </span>
    </Group>
  );
};

export default Toggle;
