import React, { useState } from 'react';
import { ReactComponent as SvgIconShow } from '../../../assets/images/icon-show.svg';
import { ReactComponent as SvgIconHide } from '../../../assets/images/icon-hide.svg';
import { ReactComponent as SvgIconCopy } from '../../../assets/images/icon-copy-new.svg';
import { ReactComponent as SvgIconDelete } from '../../../assets/images/icon-trash.svg';
import { ReactComponent as SvgIconAdd } from '../../../assets/images/icon-plus.svg';

import List from '../../react-table/List';

import styles from './APIKeys.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { APIKey, getAPIKeys } from 'reducers/apiKeysReducer';
import { deleteAPIKey, fetchKeys, toggleAddAPIKeyDrawer } from 'actions/apiKeysActions';
import { OnMount } from 'hooks/mountUnmountHooks';

function copyToClipboard(text: string): void {
  const textarea = document.createElement('textarea');
  textarea.value = text;
  textarea.setAttribute('readonly', '');
  textarea.style.position = 'absolute';
  textarea.style.left = '-9999px';
  document.body.appendChild(textarea);

  const selected = document.getSelection()?.rangeCount > 0 ? document.getSelection()?.getRangeAt(0) : false;

  textarea.select();
  document.execCommand('copy');
  document.body.removeChild(textarea);

  if (selected) {
    document.getSelection()?.removeAllRanges();
    document.getSelection()?.addRange(selected);
  }
}

const APIKeys: React.FunctionComponent = (props) => {
  const keys = Object.values(useSelector(getAPIKeys));
  if (keys.length === 0) {
    keys.push({ created_at: '', id: 'None', modified_at: '', name: '', site: '' });
  }
  const dispatch = useDispatch();
  const [visibleKeys, setVisibleKeys] = useState<{ [key: string]: boolean }>({});

  OnMount(() => {
    dispatch(fetchKeys());
  });

  function toggleKeyVisibility(keyName: string) {
    setVisibleKeys({ ...visibleKeys, [keyName]: !visibleKeys[keyName] });
  }

  function deleteKey(key: string) {
    const confirm = window.confirm('Are you sure you want to delete this key?');
    if (confirm) {
      dispatch(deleteAPIKey(key));
    }
  }

  function copyKeyToClipboard(key: string) {
    copyToClipboard(key);
    window.alert('API Key copied to clipbard.');
  }

  const columns = [
    {
      id: 'name',
      Cell: (cellProps: { original: APIKey }) => <span>{cellProps.original.name}</span>,
    },
    {
      id: 'value',
      Cell: (cellProps: { original: APIKey }) => (
        <span className={cellProps.original.id === 'None' && styles.noKeyText}>
          {visibleKeys[cellProps.original.name] || cellProps.original.id === 'None'
            ? cellProps.original.id
            : '•••••••• •••• •••• •••• ••••••••••••'}
        </span>
      ),
    },
    {
      id: 'options',
      maxWidth: 128,
      Cell: (cellProps: { original: APIKey }) => (
        <>
          {cellProps.original.id !== 'None' && (
            <span className={styles.options} data-test-id="edit-user">
              {visibleKeys[cellProps.original.name] ? (
                <SvgIconHide onClick={() => toggleKeyVisibility(cellProps.original.name)} />
              ) : (
                <SvgIconShow onClick={() => toggleKeyVisibility(cellProps.original.name)} />
              )}
              <SvgIconCopy onClick={() => copyKeyToClipboard(cellProps.original.id)} />
              <SvgIconDelete onClick={() => deleteKey(cellProps.original.id)} />
            </span>
          )}
        </>
      ),
    },
  ];

  return (
    <section>
      <header>
        <h1>API Keys</h1>
        {
          <button
            onClick={() => dispatch(toggleAddAPIKeyDrawer())}
            data-test-id="add-api-key"
            className={`button button-primary button-icon ${styles.addApiKeyButton}`}
          >
            <SvgIconAdd height={15} width={15} />
            <span>Add API Key</span>
          </button>
        }
      </header>
      {
        <div className={styles.container}>
          <List data={keys} columns={columns} showAll={true} isSensitive={true} />
        </div>
      }
    </section>
  );
};

export default APIKeys;
