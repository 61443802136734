import React, { useState, useCallback, useEffect } from 'react';
import MenuItem from '../MenuItem';

import { ReactComponent as SvgAddAction } from '../../../../../assets/images/icon-add-action.svg';
import styles from '../../HeaderFooterEditor.module.scss';

function getActionStyleClassName(actionStyle) {
  switch (actionStyle) {
    case 'button-primary':
      return 'button button-primary';
    case 'button-secondary':
      return 'button button-secondary';
    case 'text-action':
    default:
      return 'text-action';
  }
}

export default function ActionMenuItem(props) {
  const { item, action_style, propPath, siteUpdaters } = props;
  const [autoFocus, setAutoFocus] = useState(false);

  const addActionItem = useCallback(() => {
    siteUpdaters.set(propPath, {});
    setAutoFocus(true);
  }, [propPath, siteUpdaters, setAutoFocus]);

  // Reset `autoFocus` to false after toggling it to true
  useEffect(() => {
    if (autoFocus) setAutoFocus(false);
  }, [autoFocus, setAutoFocus]);

  if (item)
    return (
      <li>
        <MenuItem
          className={getActionStyleClassName(action_style)}
          item={item}
          propPath={propPath}
          siteUpdaters={siteUpdaters}
          placeholder="Action"
          autoFocus={autoFocus}
        />
      </li>
    );
  else
    return (
      <li>
        <SvgAddAction className={styles.AddHeaderMenuItem} role="button" onClick={addActionItem} />
      </li>
    );
}
