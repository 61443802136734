import * as types from '../actions/actionTypes';

// ----------------------------------------------------------------------------
// DomainSettings Reducer
// ----------------------------------------------------------------------------

const initState = {
  isOpen: false,
};

export default function (state = initState, action) {
  switch (action.type) {
    case types.DOMAIN_SETTINGS_OPEN:
      return {
        isOpen: true,
        ...action.payload,
      };

    case types.DOMAIN_SETTINGS_CLOSE:
      return {
        ...state,
        isOpen: false,
      };

    default:
      return state;
  }
}

// ----------------------------------------------------------------------------
// Selectors
// ----------------------------------------------------------------------------

export function selectLocalState(globalState) {
  return globalState.uiDomainSettings;
}
