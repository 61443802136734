import { put, select, all, race, take, takeLatest } from 'redux-saga/effects';

import * as actionTypes from '../actions/actionTypes';

import * as actions from '../actions/uiInProgressContentTestSettingsActions';
import { requestPublish } from '../actions/pageActions';
import { discardABTest } from '../actions/sectionActions';
import { requestEnd as requestContentTestEnd } from '../actions/contentTestActions';

import { selectPageIdFromSectionId } from '../reducers/pagesReducer';
import { selectContentTest } from '../reducers/contentTestsReducer';

function* end(action) {
  const { contentTestId, variantId, shouldPublish } = action.payload;

  // Select contentTest
  const contentTest = yield select(selectContentTest, contentTestId);

  // end content test
  yield put(requestContentTestEnd(contentTestId));

  const [, error] = yield race([
    take(actionTypes.CONTENT_TEST_RECEIVE_END_SUCCESS),
    take(actionTypes.CONTENT_TEST_RECEIVE_END_ERROR),
  ]);

  if (error) return;

  if (!shouldPublish) {
    yield put(actions.close());
    return;
  }

  // Get page id
  const pageId = yield select(selectPageIdFromSectionId, contentTest.section_id);

  // discard test section
  yield put(discardABTest(contentTest.section_id, variantId, pageId));
  yield take(actionTypes.SECTIONS_REPLACE);
  yield put(requestPublish(pageId));

  // close the drawer
  yield put(actions.close());
}

function* uiNewContentTestsSaga() {
  yield all([takeLatest(actionTypes.UI_IN_PROGRESS_CONTENT_TEST_SETTINGS_REQUEST_END, end)]);
}

export default uiNewContentTestsSaga;
