import React from 'react';
import icon from '../../../assets/images/todo_zero_state.svg';
import ZeroState, { Image, Header, Muted, Group } from '../ZeroState';
import { ReactComponent as SvgIconAdd } from '../../../assets/images/icon-plus.svg';

import styles from './Tasks.module.scss';

export default ({ openDrawer = undefined, message }) => (
  <ZeroState>
    <Image src={icon} />
    <Header>Stay on track with tasks</Header>
    <Muted>{message}</Muted>
    {openDrawer && (
      <Group className={styles.addButton} onClick={() => openDrawer()}>
        <SvgIconAdd height={15} width={15} />
        <span>Add Task</span>
      </Group>
    )}
  </ZeroState>
);
