import React from 'react';

// make a list in the Oxford comma style (eg "a, b, c, and d")
// Examples with conjunction "and":
// ["a"] -> "a"
// ["a", "b"] -> "a and b"
// ["a", "b", "c"] -> "a, b, and c"
export default function oxford(arr, conjunction, ifempty) {
  const l = arr.length;
  if (!l) return ifempty;
  if (l < 2) return arr[0];
  if (l < 3) return arr.join(` ${conjunction} `);
  arr = arr.slice();
  arr[l - 1] = `${conjunction} ${arr[l - 1]}`;
  return arr.join(', ');
}

export function oxfordJSX(arr, conjunction, ifempty) {
  const l = arr.length;
  if (!l) return ifempty;
  if (l < 2) return arr[0];
  if (l < 3) return [arr[0], <span key="conjunction"> {conjunction} </span>, arr[1]];

  const result = [];
  arr.forEach((item, i) => {
    result.push(item);
    if (i < l - 2) result.push(<span key={i}>, </span>);
    else if (i === l - 2) result.push(<span key="conjunction">, {conjunction} </span>);
  });

  return result;
}
