import React from 'react';

import { ButtonTagArgs } from 'components/unstack-components/tag-types';
import withSite from 'containers/withSite';
import { SectionHandlersInterface, SectionUiHandlersInterface } from 'components/unstack-components/types';
import { USectionDevices } from 'types/USection';
import { USite } from 'types/USite';
import { PrimaryAction, SecondaryAction } from 'components/Editor/ActionViews';
import { set } from 'object-path-immutable';
import { getDeviceTypeToSaveTo } from '../../util/helpers/deviceHelper';
import { useSelector } from 'react-redux';
import { getDevice } from 'reducers/uiReducer';
import { getItemKey } from '../../util/utils';

interface ButtonProps extends ButtonTagArgs {
  sectionHandlers?: SectionHandlersInterface;
  sectionUiHandlers?: SectionUiHandlersInterface;
  site: USite;
  onChange: (content: USectionDevices, key?: string, multi?: boolean) => void;
  dataRef: { [key: string]: any }[];
  content: USectionDevices;
  color: string;
  defaultType?: 'primary' | 'secondary';
}

const TemplateGenerator: React.FunctionComponent<ButtonProps> = (props) => {
  const { onChange, dataRef: value = '', contentKey, containerInfo, content, color, defaultValue, defaultType } = props;

  const device = useSelector(getDevice);
  const key = containerInfo ? getItemKey(containerInfo, contentKey) : contentKey;
  const splitKey = key.split('.');

  const updateAction = (value: ActionProps) => {
    onChange(
      set(content as any, [getDeviceTypeToSaveTo(device, true), ...splitKey.slice(1)], value),
      `content.${splitKey[0]}`
    );
  };

  const update = (path: string, value: any) => {
    const actions = value || {};
    const updatedActions = set(actions, path, value);
    return updateAction(updatedActions);
  };

  const updateActionValues = (dict: any) => {
    const actions = value || {};
    let updatedActions = actions;
    Object.keys(dict).forEach((path) => {
      updatedActions = set(updatedActions, path, dict[path]);
    });

    return updateAction(updatedActions);
  };

  return (
    <>
      {props.defaultType === 'secondary' && (
        <SecondaryAction
          // @ts-ignore
          action={value?.primary || { text: defaultValue }}
          update={update}
          updateActionValues={updateActionValues}
          autoFocus={false}
          color={color}
        />
      )}
      {(!props.defaultType || props.defaultType === 'primary') && (
        <PrimaryAction
          // @ts-ignore
          action={value?.primary || { text: defaultValue }}
          update={update}
          updateActionValues={updateActionValues}
          autoFocus={false}
          color={color}
        />
      )}
    </>
  );
};

export default withSite(TemplateGenerator);
