import { isInteger, toInteger } from 'lodash';
import { UBoxInfo } from 'types/USection';

export function getActiveMaxWidth(value: UBoxInfo, isSectionToolbar: boolean, defaultWidth: string) {
  let activeMaxWidth = 'custom';
  switch (value.maxWidth) {
    case 'auto':
      activeMaxWidth = 'auto';
      break;
    case 'none':
      activeMaxWidth = 'none';
      break;
    case 'default':
      activeMaxWidth = 'default';
      break;
    case '100%':
      activeMaxWidth = '100%';
      break;
    case '300':
    case '300px':
      activeMaxWidth = isSectionToolbar ? 'custom' : '300';
      break;
    case '400':
    case '400px':
      activeMaxWidth = isSectionToolbar ? 'custom' : '400';
      break;
    case '500':
    case '500px':
      activeMaxWidth = isSectionToolbar ? 'custom' : '500';
      break;
    case '600':
    case '600px':
      activeMaxWidth = isSectionToolbar ? 'custom' : '600';
      break;
    case '960':
    case '960px':
      activeMaxWidth = !isSectionToolbar ? 'custom' : '960';
      break;
    case '1280':
    case '1280px':
      activeMaxWidth = !isSectionToolbar ? 'custom' : '1280';
      break;
    case '1440':
    case '1440px':
      activeMaxWidth = !isSectionToolbar ? 'custom' : '1440';
      break;
    case defaultWidth:
    case `${defaultWidth}px`:
      activeMaxWidth = `${defaultWidth}`;
      break;
  }
  return activeMaxWidth;
}

export function getIsDefault(value: any, defaults: any) {
  const isDefault =
    defaults.maxWidth?.toString().split('px')[0] === value.maxWidth?.toString().split('px')[0] &&
    defaults.maxWidthMobile?.toString().split('px')[0] === value.maxWidthMobile?.toString().split('px')[0] &&
    toInteger(defaults.paddingTop) === toInteger(value.paddingTop) &&
    toInteger(defaults.paddingRight) === toInteger(value.paddingRight) &&
    toInteger(defaults.paddingBottom) === toInteger(value.paddingBottom) &&
    toInteger(defaults.paddingLeft) === toInteger(value.paddingLeft) &&
    toInteger(defaults.paddingTopMobile) === toInteger(value.paddingTopMobile) &&
    toInteger(defaults.paddingRightMobile) === toInteger(value.paddingRightMobile) &&
    toInteger(defaults.paddingBottomMobile) === toInteger(value.paddingBottomMobile) &&
    toInteger(defaults.paddingLeftMobile) === toInteger(value.paddingLeftMobile);

  return isDefault;
}

export function getPaddingPosition(value: UBoxInfo, defaults: UBoxInfo, targetCursor: number) {
  const top = value['paddingTop'] === 'default' ? defaults['paddingTop'] : value['paddingTop'];
  const right = value['paddingRight'] === 'default' ? defaults['paddingRight'] : value['paddingRight'];
  const bottom = value['paddingBottom'] === 'default' ? defaults['paddingBottom'] : value['paddingBottom'];
  const left = value['paddingLeft'] === 'default' ? defaults['paddingLeft'] : value['paddingLeft'];

  const paddingTopStr = top?.toString()?.length;
  const paddingRightStr = right?.toString()?.length;
  const paddingBottomStr = bottom?.toString()?.length;
  const paddingLeftStr = left?.toString()?.length;

  const isCursorOnTopPadding =
    (paddingTopStr === 1 && (targetCursor === 0 || targetCursor === 1)) ||
    (paddingTopStr === 2 && (targetCursor === 0 || targetCursor === 1 || targetCursor === 2));
  const isCursorOnRightPadding =
    (paddingTopStr === 1 && paddingRightStr === 1 && (targetCursor === 3 || targetCursor === 4)) ||
    (paddingTopStr === 1 &&
      paddingRightStr === 2 &&
      (targetCursor === 3 || targetCursor === 4 || targetCursor === 5)) ||
    (paddingTopStr === 2 && paddingRightStr === 1 && (targetCursor === 4 || targetCursor === 5)) ||
    (paddingTopStr === 2 && paddingRightStr === 2 && (targetCursor === 4 || targetCursor === 5 || targetCursor === 6));
  const isCursorOnBottomPadding =
    (paddingTopStr + paddingRightStr === 2 && paddingBottomStr === 1 && (targetCursor === 6 || targetCursor === 7)) ||
    (paddingTopStr + paddingRightStr === 3 && paddingBottomStr === 1 && (targetCursor === 7 || targetCursor === 8)) ||
    (paddingTopStr + paddingRightStr === 4 && paddingBottomStr === 1 && (targetCursor === 8 || targetCursor === 9)) ||
    (paddingTopStr + paddingRightStr === 2 &&
      paddingBottomStr === 2 &&
      (targetCursor === 6 || targetCursor === 7 || targetCursor === 8)) ||
    (paddingTopStr + paddingRightStr === 3 &&
      paddingBottomStr === 2 &&
      (targetCursor === 7 || targetCursor === 8 || targetCursor === 9)) ||
    (paddingTopStr + paddingRightStr === 4 &&
      paddingBottomStr === 2 &&
      (targetCursor === 8 || targetCursor === 9 || targetCursor === 10));
  const isCursorOnLeftPadding =
    (paddingTopStr + paddingRightStr + paddingBottomStr === 3 &&
      paddingLeftStr === 1 &&
      (targetCursor === 9 || targetCursor === 10)) ||
    (paddingTopStr + paddingRightStr + paddingBottomStr === 4 &&
      paddingLeftStr === 1 &&
      (targetCursor === 10 || targetCursor === 11)) ||
    (paddingTopStr + paddingRightStr + paddingBottomStr === 5 &&
      paddingLeftStr === 1 &&
      (targetCursor === 11 || targetCursor === 12)) ||
    (paddingTopStr + paddingRightStr + paddingBottomStr === 6 &&
      paddingLeftStr === 1 &&
      (targetCursor === 12 || targetCursor === 13)) ||
    (paddingTopStr + paddingRightStr + paddingBottomStr === 3 &&
      paddingLeftStr === 2 &&
      (targetCursor === 9 || targetCursor === 10 || targetCursor === 11)) ||
    (paddingTopStr + paddingRightStr + paddingBottomStr === 4 &&
      paddingLeftStr === 2 &&
      (targetCursor === 10 || targetCursor === 11 || targetCursor === 12)) ||
    (paddingTopStr + paddingRightStr + paddingBottomStr === 5 &&
      paddingLeftStr === 2 &&
      (targetCursor === 11 || targetCursor === 12 || targetCursor === 13)) ||
    (paddingTopStr + paddingRightStr + paddingBottomStr === 6 &&
      paddingLeftStr === 2 &&
      (targetCursor === 12 || targetCursor === 13 || targetCursor === 14));

  let paddingPosition = '';
  if (isCursorOnTopPadding) paddingPosition = 'top';
  else if (isCursorOnRightPadding) paddingPosition = 'right';
  else if (isCursorOnBottomPadding) paddingPosition = 'bottom';
  else if (isCursorOnLeftPadding) paddingPosition = 'left';

  return paddingPosition;
}

export function handleUpDownTyped(
  temporaryPadding: string,
  setTemporaryPadding: React.Dispatch<React.SetStateAction<string>>,
  setArrowPress: React.Dispatch<React.SetStateAction<boolean>>,
  setWidth: React.Dispatch<React.SetStateAction<number>>,
  cursorPos: number,
  setCursorPos: React.Dispatch<React.SetStateAction<number>>,
  target: number,
  type: string
) {
  setArrowPress(true);
  if (temporaryPadding) {
    setCursorPos(target);
    const right = parseInt(temporaryPadding.charAt(cursorPos));
    const left = parseInt(temporaryPadding.charAt(cursorPos - 1));
    let rightEnd = null;
    if (isInteger(right)) {
      rightEnd = cursorPos;
      while (isInteger(parseInt(temporaryPadding.charAt(rightEnd)))) {
        rightEnd = rightEnd + 1;
      }
    }
    let leftEnd = null;
    if (isInteger(left)) {
      leftEnd = cursorPos - 1;
      while (isInteger(parseInt(temporaryPadding.charAt(leftEnd - 1)))) {
        leftEnd = leftEnd - 1;
      }
    }
    const leftStr = temporaryPadding.substring(0, leftEnd !== null ? leftEnd : cursorPos);
    const rightStr = temporaryPadding.substring(rightEnd !== null ? rightEnd : cursorPos, temporaryPadding.length);
    const toUpdate = temporaryPadding.substring(
      leftEnd !== null ? leftEnd : cursorPos,
      rightEnd !== null ? rightEnd : cursorPos
    );
    const updatedStr = leftStr.concat(
      toUpdate
        ? (type === 'add' ? parseInt(toUpdate) + 1 : parseInt(toUpdate) > 0 ? parseInt(toUpdate) - 1 : 0).toString()
        : '',
      rightStr
    );
    setTemporaryPadding(updatedStr);
    setWidth(temporaryPadding.replace(', ', '').length + 2);
  }
}

export function handleUpDown(
  value: UBoxInfo,
  defaults: UBoxInfo,
  lockedValue: Array<number>,
  setLockedValue: React.Dispatch<React.SetStateAction<Array<number>>>,
  temporaryPadding: string,
  paddingValueCombined: string,
  position: string,
  paddingValueFormat: string,
  minMax: (value: number) => number,
  setCursorPos: React.Dispatch<React.SetStateAction<number>>,
  target: number,
  type: string,
  handleChangePadding: any
) {
  const top = value['paddingTop'] === 'default' ? defaults['paddingTop'] : value['paddingTop'];
  const right = value['paddingRight'] === 'default' ? defaults['paddingRight'] : value['paddingRight'];
  const bottom = value['paddingBottom'] === 'default' ? defaults['paddingBottom'] : value['paddingBottom'];
  const left = value['paddingLeft'] === 'default' ? defaults['paddingLeft'] : value['paddingLeft'];

  let paddingTopVar = top;
  let paddingRightVar = right;
  let paddingBottomVar = bottom;
  let paddingLeftVar = left;

  if (!lockedValue) {
    setLockedValue([
      toInteger(value['paddingTop']),
      toInteger(value['paddingRight']),
      toInteger(value['paddingBottom']),
      toInteger(value['paddingLeft']),
    ]);
  }
  if (!temporaryPadding && paddingValueCombined) {
    // PADDING TOP
    if (position === 'top') {
      if (paddingValueFormat === 'top') {
        paddingTopVar = minMax(type === 'add' ? toInteger(top) + 1 : toInteger(top) - 1);
        paddingRightVar = minMax(type === 'add' ? toInteger(right) + 1 : toInteger(right) - 1);
        paddingBottomVar = minMax(type === 'add' ? toInteger(bottom) + 1 : toInteger(bottom) - 1);
        paddingLeftVar = minMax(type === 'add' ? toInteger(left) + 1 : toInteger(left) - 1);
      } else if (paddingValueFormat === 'top, right') {
        paddingTopVar = minMax(type === 'add' ? toInteger(top) + 1 : toInteger(top) - 1);
        paddingBottomVar = minMax(type === 'add' ? toInteger(bottom) + 1 : toInteger(bottom) - 1);
      } else if (paddingValueFormat === 'top, right, bottom' || paddingValueFormat === 'top, right, bottom, left') {
        paddingTopVar = minMax(type === 'add' ? toInteger(top) + 1 : toInteger(top) - 1);
      }

      if (type === 'add') {
        if (toInteger(top) === 9 && target === 1) setCursorPos(target + 1);
        else setCursorPos(target);
      } else {
        if (toInteger(top) === 10 && target === 2) setCursorPos(target - 1);
        else setCursorPos(target);
      }
    }
    // PADDING RIGHT
    else if (position === 'right') {
      if (paddingValueFormat === 'top, right' || paddingValueFormat === 'top, right, bottom') {
        paddingRightVar = minMax(type === 'add' ? toInteger(right) + 1 : toInteger(right) - 1);
        paddingLeftVar = minMax(type === 'add' ? toInteger(left) + 1 : toInteger(left) - 1);
      } else if (paddingValueFormat === 'top, right, bottom, left') {
        paddingRightVar = minMax(type === 'add' ? toInteger(right) + 1 : toInteger(right) - 1);
      }

      if (type === 'add') {
        if (toInteger(right) === 9 && (target === 4 || target === 5)) setCursorPos(target + 1);
        else setCursorPos(target);
      } else {
        if (toInteger(right) === 10 && (target === 5 || target === 6)) setCursorPos(target - 1);
        else setCursorPos(target);
      }
    }
    // PADDING BOTTOM
    else if (position === 'bottom') {
      if (paddingValueFormat === 'top, right, bottom' || paddingValueFormat === 'top, right, bottom, left') {
        paddingBottomVar = minMax(type === 'add' ? toInteger(bottom) + 1 : toInteger(bottom) - 1);
      }

      if (type === 'add') {
        if (toInteger(bottom) === 9 && (target === 7 || target === 8 || target === 9)) setCursorPos(target + 1);
        else setCursorPos(target);
      } else {
        if (toInteger(bottom) === 10 && (target === 8 || target === 9 || target === 10)) setCursorPos(target - 1);
        else setCursorPos(target);
      }
    }
    // PADDING LEFT
    else if (position === 'left') {
      if (paddingValueFormat === 'top, right, bottom, left') {
        paddingLeftVar = minMax(type === 'add' ? toInteger(left) + 1 : toInteger(left) - 1);
      }

      if (type === 'add') {
        if (toInteger(left) === 9 && (target === 10 || target === 11 || target === 12 || target === 13))
          setCursorPos(target + 1);
        else setCursorPos(target);
      } else {
        if (toInteger(left) === 10 && (target === 11 || target === 12 || target === 13 || target === 14))
          setCursorPos(target - 1);
        else setCursorPos(target);
      }
    }
    handleChangePadding(paddingTopVar, paddingRightVar, paddingBottomVar, paddingLeftVar, paddingValueFormat);
  }
}
