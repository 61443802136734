//  TODO: Refactor this file

const promptMessages = {
  blog: 'Are you sure you want to discard your changes? This will set your blog back to the most recent published version.',
  discardChanges:
    'Are you sure you want to discard unpublished changes? This will set your page back to the most recently published version. This cannot be undone.',
  deleteContact: 'Are you sure you want to delete this customer? This action cannot be undone.',
  default: 'Are you sure you want to discard your changes?',
};

export const promoptConformation = (variant, onSuccess, onFailure = () => {}) => {
  const agreement = window.confirm(promptMessages[variant]);
  if (agreement) {
    onSuccess();
  } else {
    onFailure();
  }
};
