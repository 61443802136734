import React, { useState, useEffect, useCallback } from 'react';

import UpDownButtons from '../../../components/Editor/Toolbars2/components/UpDownButtons';
import { ReactComponent as SvgIconSizeDefault } from '../../../assets/images/icon-font-size.svg';
import clamp from 'lodash/clamp';

import UpDownCounter from 'components/Editor/Toolbars2/components/UpDownCounter';
import styles from './QuillToolbar.module.scss';

const lowerBound = 1;
const upperBound = 10;
const formatInputValue = (value) => value;
const decimalNumberRegex = /^\d*\.?\d*$/;

function getInputValue(value) {
  if (value.endsWith('.')) {
    return value.match(decimalNumberRegex).input;
  }
  return clamp(value, lowerBound, upperBound);
}

export default ({ currentValue, customValue, defaultValue, ...props }) => {
  const [val, setVal] = useState(currentValue || defaultValue);
  const [dirty, setDirty] = useState(customValue);

  useEffect(() => {
    if (currentValue < 1) return;
    setVal(currentValue);
  }, [currentValue]);

  useEffect(() => {
    setDirty(customValue);
  }, [customValue]);

  const handleOnChange = useCallback((_, e) => {
    if (!e) return;
    const value = getInputValue(e.target.value);
    if (isNaN(Number(value))) {
      return;
    }
    setVal(value);
    props.applyFormat(props.format, `${value}rem`);
    e.target.focus();
    e.target.selectionStart = e.target.value.length;
  });

  return (
    <div className={`${styles.UpDownCounter} ${dirty ? styles.inputOpen : ''}`}>
      <button className={styles.value} onClick={() => setDirty(true)}>
        <SvgIconSizeDefault height={18} width={18} />
      </button>
      {dirty && (
        <UpDownCounter
          value={val}
          onChange={handleOnChange}
          min={1}
          max={10}
          hideButtons
          formatValue={formatInputValue}
        />
      )}

      <UpDownButtons
        upProps={{
          onClick: () => {
            const currentValue = val ? Math.max(val, 1) : Number(defaultValue);
            setVal(Number((currentValue + 0.1).toFixed(1)));
            !dirty && setDirty(true);
            props.applyFormat(props.format, `${Number((currentValue + 0.1).toFixed(1))}rem`);
          },
          disabled: val >= 10,
        }}
        downProps={{
          onClick: () => {
            const currentValue = val ? Math.max(val, 1) : Number(defaultValue);
            setVal(Number((currentValue - 0.1).toFixed(1)));
            !dirty && setDirty(true);
            props.applyFormat(props.format, `${Number((currentValue - 0.1).toFixed(1))}rem`);
          },
          disabled: val <= 1,
        }}
      />
    </div>
  );
};
