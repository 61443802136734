import React from 'react';
import { Bar } from '@vx/shape';
import { Group } from '@vx/group';
import { scaleBand, scaleLinear } from '@vx/scale';

import styles from './BarChart.module.scss';

// accessors
const x = (d) => d[0];
const y = (d) => d[1];

// settings
const axisOffset = 2;
const axisStrokeWidth = 1;

export default ({ data, width, height, ...rest }) => {
  // bounds
  const xMax = width;
  const yMax = height - axisOffset - axisStrokeWidth;

  // scales
  const xScale = scaleBand({
    rangeRound: [0, xMax],
    domain: data.map(x),
    padding: 0.2,
  });
  const yScale = scaleLinear({
    rangeRound: [yMax, 0],
    domain: [0, Math.max(1, ...data.map(y))],
  });

  // axis positioning
  const axisY = yMax + axisOffset + axisStrokeWidth / 2;

  // width={width} height={height}
  return (
    <svg viewBox={`0 0 ${width} ${height}`} {...rest}>
      <Group>
        {data.map((d, i) => {
          const xVal = x(d);
          const barWidth = xScale.bandwidth();
          const barHeight = yMax - yScale(y(d));
          const barX = xScale(xVal);
          const barY = yMax - barHeight;

          return (
            <React.Fragment key={`bar-${xVal}`}>
              <Bar className={styles.bar} x={barX} y={barY} width={barWidth} height={barHeight} />
              <line
                className={styles.axisLine}
                x1={barX}
                y1={axisY}
                x2={barX + barWidth}
                y2={axisY}
                strokeWidth={axisStrokeWidth}
              />
            </React.Fragment>
          );
        })}
      </Group>
    </svg>
  );
};
