import * as actionTypes from './actionTypes';

export function requestIfNeeded(siteId, type) {
  return {
    type: actionTypes.SITE_STATISTICS_REQUEST_IF_NEEDED,
    payload: { siteId, type },
  };
}

export function request(siteId, type) {
  return {
    type: actionTypes.SITE_STATISTICS_REQUEST,
    payload: { siteId, type },
  };
}

export function receive(type, data) {
  const fetchedAt = Date.now();

  return {
    type: actionTypes.SITE_STATISTICS_RECEIVE,
    payload: { type, data, fetchedAt },
  };
}

export function setType(type) {
  return {
    type: actionTypes.SITE_STATISTICS_SET_TYPE,
    payload: type,
  };
}
