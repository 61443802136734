import React from 'react';
import { set, del, insert, push, assign } from 'object-path-immutable';

import TrayWithLabeledButtonOptions from '../../../../../../Editor/Toolbars2/components/TrayWithLabeledButtonOptions';
import { IconNaturalHeight, IconFullHeight } from '../../../../../../Editor/Toolbars2/icons';
import { UBoxInfo, USectionDevices, USectionProps } from 'types/USection';
import { useSelector } from 'react-redux';
import { getDevice } from 'reducers/uiReducer';
import { getDeviceTypeToSaveTo } from 'components/unstack-components/Component/util/helpers/deviceHelper';

type Props = {
  content: USectionDevices;
  dataRef: UBoxInfo;
  onChange: (content: USectionDevices, key?: string, multi?: boolean) => void;
  contentKey: string;
};

const SectionHeight: React.FunctionComponent<Props> = ({ dataRef, onChange, content, contentKey }) => {
  const device = useSelector(getDevice);
  const splitKey = contentKey.split('.');

  return (
    <TrayWithLabeledButtonOptions
      trayLabel="SECTION HEIGHT"
      onChange={(height: any) =>
        onChange(
          set(content, [getDeviceTypeToSaveTo(device, true), ...splitKey.slice(1), 'height'], height),
          `content.${splitKey[0]}`
        )
      }
      value={dataRef.height || null}
      options={[
        {
          value: null,
          label: 'Content height',
          IconComponent: IconNaturalHeight,
        },
        {
          value: 'full',
          label: 'Screen height',
          IconComponent: IconFullHeight,
        },
      ]}
    />
  );
};

export default SectionHeight;
