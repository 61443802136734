import React from 'react';
import styles from './SimplePage.module.scss';
import LogoBanner from './base/LogoSimpleBanner';

export default (props) => (
  <main className={styles.simplePage}>
    <section className={styles.centeredContent}>
      <div>{props.children}</div>
    </section>
    <div className={styles.banner}>
      <LogoBanner />
    </div>
  </main>
);
