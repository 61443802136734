import camelCase from 'lodash/fp/camelCase';

type Style = string;

export const parseStyle = (style: Style): any => {
  return style
    .split(';')
    .filter((r) => r)
    .reduce((map, rule) => {
      const name = rule.slice(0, rule.indexOf(':')).trim();
      const value = rule.slice(rule.indexOf(':') + 1).trim();

      return {
        ...map,
        [name]: value,
      };
    }, {});
};
