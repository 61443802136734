import * as types from '../actions/actionTypes';
import { createSelector } from 'reselect';

// ----------------------------------------------------------------------------
// Reducer
// ----------------------------------------------------------------------------

const INITIAL_STATE = {
  products: [],
  isLoading: null,
  isCreatingOrUpdating: false,
  periods: null,
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.PAYMENTS_FETCH_PRODUCTS:
      return { ...state, isLoading: true };

    case types.PAYMENTS_FETCH_PRODUCTS_SUCCESS:
      return { ...state, products: action.payload, isLoading: false };

    case types.PAYMENTS_CREATE_PRODUCT:
      return { ...state, isCreatingOrUpdating: true };

    case types.PAYMENTS_DELETE_PRODUCT:
      return { ...state, isCreatingOrUpdating: true };

    case types.PAYMENTS_DELETE_PRODUCT_SUCCESS:
      return {
        ...state,
        isCreatingOrUpdating: false,
        products: [...state.products.filter((product) => product.id !== action.payload)],
      };

    case types.PAYMENTS_CREATE_PRODUCT_SUCCESS: {
      let products = [...state.products];
      const index = products.findIndex((product) => product.id === action.payload.id);
      if (index === -1) products = [...state.products, action.payload];
      else products[index] = action.payload;
      return {
        ...state,
        isCreatingOrUpdating: false,
        products,
      };
    }

    case types.PAYMENTS_CREATE_PRODUCT_FAILURE:
      return { ...state, isCreatingOrUpdating: false };

    case types.PAYMENTS_CREATE_PLAN:
      return { ...state, isCreatingOrUpdating: true };

    case types.PAYMENTS_CREATE_PLAN_SUCCESS: {
      const products = state.products.map((product) => {
        if (product.id === action.payload.product) {
          const index = product.plans.findIndex((plan) => plan.id === action.payload.id);
          if (index === -1) product.plans = [...product.plans, action.payload];
          else product.plans[index] = action.payload;
        }
        return product;
      });

      return {
        ...state,
        isCreatingOrUpdating: false,
        products,
      };
    }
    case types.PAYMENTS_DELETE_PLAN:
      return { ...state, isCreatingOrUpdating: true };

    case types.PAYMENTS_DELETE_PLAN_SUCCESS: {
      const products = state.products.map((product) => {
        product.plans = product.plans.filter((plan) => plan.id !== action.payload);
        return product;
      });
      return {
        ...state,
        isCreatingOrUpdating: false,
        products,
      };
    }

    case types.PAYMENTS_CREATE_PLAN_FAILURE:
      return { ...state, isCreatingOrUpdating: false };

    case types.PAYMENTS_FETCH_PERIODS_SUCCESS:
      return { ...state, periods: action.payload };

    default:
      return state;
  }
}

// ----------------------------------------------------------------------------
// Selectors
// ----------------------------------------------------------------------------

const selectLocalState = (globalState) => globalState.payments;

export function selectAllProducts(globalState) {
  const state = selectLocalState(globalState);
  return state.products;
}

export const selectAllOneTimerProducts = createSelector(selectLocalState, (payments) => {
  return payments.products.filter((product) => product.plans.some((plan) => plan.period === 'one_time'));
});

export const selectAllActiveProducts = createSelector(selectLocalState, (payments) => {
  return payments.products.filter((product) => !product.is_deleted);
});

export const selectIsLoading = createSelector(selectLocalState, (payment) => payment.isLoading);

export const selectPeriods = createSelector(selectLocalState, (payment) => payment.periods);

export const selectIsCreatingOrUpdating = createSelector(selectLocalState, (payment) => payment.isCreatingOrUpdating);
