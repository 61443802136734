export const emptyUser = {
  id: null,
  name: '',
  email: '',
  password: '',
  role: '',
  location: '',
  bio: '',
};

export function joinName(firstName, lastName) {
  return lastName ? `${firstName} ${lastName}` : firstName;
}

export function flattenAccountUser(accountUser) {
  return {
    id: accountUser.id,
    role: accountUser.role,
    bio: accountUser.bio,
    title: accountUser.title,
    social_media: accountUser.social_media,
    profile_image: accountUser.profile_image,
    location: accountUser.location,
    email: accountUser.user.email,
    name: joinName(accountUser.user.first_name, accountUser.user.last_name),
    initials: (accountUser.user.first_name ? accountUser.user.first_name[0] : accountUser.user.email[0]).toUpperCase(),
    created_at: accountUser.created_at,
    last_login: accountUser.user.last_login,
    send_contact_notification: accountUser.send_contact_notification,
    settings: accountUser.settings,
    status: accountUser.status,
    slug: accountUser.slug,
    metadata: accountUser.metadata,
  };
}

export function unflattenAccountUser(user) {
  const { first_name, last_name } = splitName(user.name);
  const { id, email, role, bio, location, settings, title, social_media, profile_image_id, slug, metadata } = user;
  return {
    id,
    role,
    bio,
    location,
    settings,
    title,
    social_media,
    profile_image_id,
    slug,
    user: {
      ...(first_name ? { first_name } : undefined),
      ...(last_name ? { last_name } : undefined),
      email,
    },
    metadata,
  };
}

export function splitName(name) {
  if (!name) {
    return {
      first_name: '',
      last_name: '',
    };
  }
  let lastNamePrefixes = [
    'al',
    "al'",
    "d'",
    'de',
    'del',
    "del'",
    'della',
    'de la',
    'de las',
    'de los',
    'di',
    'du',
    'el',
    "el'",
    'los',
    'la',
    'las',
    'mc',
    'mac',
    "o'",
    'saint',
    'van',
    'van der',
    'von',
  ];
  let nameParts = name.split(' ');
  let testParts = name.toLowerCase().split(' ');
  if (testParts.length > 3) {
    let nameSlice = testParts.slice(testParts.length - 3, -1).join(' ');
    if (lastNamePrefixes.indexOf(nameSlice) >= 0) {
      return {
        first_name: nameParts.slice(0, -3).join(' '),
        last_name: nameParts.slice(-3).join(' '),
      };
    }
  }
  if (testParts.length > 2) {
    let nameSlice = testParts[testParts.length - 2];
    if (lastNamePrefixes.indexOf(nameSlice) >= 0) {
      return {
        first_name: nameParts.slice(0, -2).join(' '),
        last_name: nameParts.slice(-2).join(' '),
      };
    }
  }
  return {
    first_name: nameParts.slice(0, -1).join(' '),
    last_name: nameParts.slice(-1).join(' '),
  };
}
