import deepEqual from 'fast-deep-equal';
import { v4 as uuidv4 } from 'uuid';
import { createRef, useEffect, useRef, useState } from 'react';
import isEqual from 'lodash/isEqual';
import memoize from 'lodash/memoize';
import React from 'react';
import { Search as SearchEngine } from 'js-search';
import {
  COUPON_REGEXP,
  FORM_URL_REGEXP,
  ITEM_URL_REGEXP,
  MEDIA_URL_REGEXP,
  NAKED_URL_REGEXP,
  PRODUCT_COLLECTION_URL_REGEXP,
  PRODUCT_URL_REGEXP,
} from '../../../constants';
import Autosuggest, { SuggestionsFetchRequestedParams } from 'react-autosuggest';
import withItems from '../../../../src/containers/withItems';
import isEmpty from 'lodash/isEmpty';
import pick from 'lodash/pick';
import styles from './SmartUrlToolbar.module.scss';
import classnames from 'classnames';
import usePrevious from 'hooks/usePrevious';
import withProducts from 'containers/withProducts';
import withShopifyCollections from 'containers/withShopifyCollections';
import { ShopifyCollection, ShopifyProduct } from 'types/ShopifyProduct';
import { OpenTabType, SmartURLLink } from 'types/SmartURLLink';
import { UItem } from 'types/UItem';
import { UFile } from 'types/UFile';
import { OnMount, OnUnmount } from 'hooks/mountUnmountHooks';
import useOnClickOutside from 'hooks/useClickOutside';
import {
  filterForms,
  filterPDFs,
  filterProducts,
  getIconByType,
  getItemSuggestionsGrouped,
  ItemSuggestion,
  ItemSuggestionGroup,
  openAsMiniTitle,
  openAsTitle,
} from './utils';
import { IconArrowDown, IconCancel, IconUrl, IconCheck, IconToolbarTrash } from 'components/base/BaseIcons';
import useActiveSite from 'hooks/useActiveSite';
import { getShopifyCollection, getProductInfo } from 'services/spark-api';
import { compose } from 'redux';

import { optimizedSearchQuery } from 'components/unstack-components/Component/elements/block/ProductElement/helpers';
import BaseIconStyles from '../../../../src/components/base/BaseIcons.module.scss';
import { useSelector } from 'react-redux';
import { selectPdf } from 'reducers/mediaReducer';
import withForms from 'containers/withForms';
import { UForm } from 'types/UForm';
import { UProduct } from 'types/UProduct';

type ExtendedUFile = Partial<UFile> & { item_type: string };

// TODO: Daniel look at this to make sure it's still good
// @ts-ignore
const memoizeDeep = (func: (searchDocuments: ExtendedUFile[]) => SearchEngine) => memoize(func, deepEqual);
const autosuggestId = uuidv4();

const URL_TYPES = {
  EMPTY: 'EMPTY',
  CONTENT_EXISTS: 'CONTENT_EXISTS',
  CONTENT_NOT_FOUND: 'CONTENT_NOT_FOUND',
  URL: 'URL',
  CONTENT_NOT_EXPECTED: 'CONTENT_NOT_EXPECTED',
  UNKNOWN: 'UNKNOWN',
};

interface SmartUrlToolbarProps {
  onUpdateUrlProps: (dict: { [key: string]: any }) => void;
  onChange: (name: string, value: string | boolean) => string | boolean;
  onRemove: () => void;
  link: SmartURLLink;
  formats?: any;
  value: string;
  placeholder: string;
  onBlur: () => void;
  disablePageAutocomplete: () => void;
  autoFocus: boolean;
  item?: UItem;
  onFocus: () => void;
  setOpenedFalse?: () => void;
  onSearchUpdate: (search: string, itemType?: string) => void;
  loadCurrentItem: (id: string) => void;
  searchedItems: UItem[];
  pdfs: UFile[];
  products: UProduct[];
  shopifyCollections: ShopifyCollection[];
  itemsLoading: boolean;
  productsLoading: boolean;
  collectionsLoading: boolean;
  setProductQuery: (query: string) => void;
  setProductCollectionQuery: (query: string) => void;
  setProducts: React.Dispatch<React.SetStateAction<UProduct[]>>;
  setShopifyCollection: React.Dispatch<React.SetStateAction<ShopifyCollection[]>>;
  hideToolbar: () => void;
  formsByName: { [key: string]: UForm };
  isRichText?: Boolean;
  hideBehaviour: Boolean;
}

const SmartUrlToolbar: React.FunctionComponent<SmartUrlToolbarProps> = (props) => {
  const {
    onChange,
    onRemove,
    link,
    value,
    onBlur,
    disablePageAutocomplete,
    item,
    onFocus,
    setOpenedFalse,
    onSearchUpdate,
    loadCurrentItem,
    searchedItems,
    products,
    shopifyCollections,
    itemsLoading,
    productsLoading,
    setProductQuery,
    setProductCollectionQuery,
    collectionsLoading,
    setProducts,
    setShopifyCollection,
    formsByName,
    isRichText,
  } = { ...props };

  const forms = Object.values(formsByName);
  const ref = React.useRef<HTMLDivElement>(null);
  const inputRef: React.RefObject<HTMLInputElement> = createRef();
  const prevSearchedItems = usePrevious(searchedItems);
  const prevProducts = usePrevious(products);
  const prevShopifyCollections = usePrevious(shopifyCollections);
  const prevLink = usePrevious(link);

  const [showOpenAsContainer, setShowOpenAsContainer] = useState(false);
  const [itemSuggestions, setItemSuggestions] = useState([]);
  const [autoFocus, setAutoFocus] = useState(!!props.autoFocus);
  const [pdfSuggestions, setPdfSuggestions] = useState([]);
  const [isDeletedItem, setIsDeletedItem] = useState(false);
  const site = useActiveSite();
  const prevQueryRef = useRef('');
  const pdfs = useSelector(selectPdf);

  async function fetchProduct(productId: string) {
    const product = await getProductInfo(productId, site.id);
    if (product.json && product.json.detail !== 'Not found.') setProducts([product.json]);
    else setIsDeletedItem(true);
  }
  async function fetchProductCollection(productId: string) {
    const collection = await getShopifyCollection(productId, site.id);
    if (collection.json && collection.json.detail !== 'Not found.') setShopifyCollection([collection.json]);
    else setIsDeletedItem(true);
  }

  OnMount(() => {
    // Hack for autoFocus support. This is necessary becuase if autoFocus is
    // applied directly on the input, it will focus before the Popover has a
    // chance to position itself, causing the window to jump to the top of the
    // page.
    if (autoFocus) {
      setAutoFocus(true);
      setTimeout(() => {
        if (inputRef.current) inputRef.current.focus();
      }, 50);
    }

    if (value) {
      const productMatch = value.match(PRODUCT_URL_REGEXP);
      const productCollectionMatch = value.match(PRODUCT_COLLECTION_URL_REGEXP);
      if (productMatch) {
        fetchProduct(productMatch[2]);
      } else if (productCollectionMatch) {
        fetchProductCollection(productCollectionMatch[2]);
      } else {
        const itemMatch = value.match(ITEM_URL_REGEXP);
        if (itemMatch) loadCurrentItem(itemMatch[2]);
      }
    }
  });

  useEffect(() => {
    if (
      link?.url &&
      (!isEqual(searchedItems, prevSearchedItems) ||
        !isEqual(products, prevProducts) ||
        !isEqual(shopifyCollections, prevShopifyCollections) ||
        prevLink.url !== link.url)
    ) {
      const trimmedUrl = link.url.trim();
      setItemSuggestions([
        ...searchedItems,
        ...filterProducts(products, trimmedUrl),
        ...filterProducts(shopifyCollections, trimmedUrl),
        ...filterPDFs(pdfs, trimmedUrl),
        ...filterForms(forms, trimmedUrl),
      ]);
    }
  }, [searchedItems, products, link.url, itemsLoading, productsLoading, collectionsLoading, shopifyCollections, pdfs]);

  // --------------------------------------------------------------------------
  // Input and button event handlers
  // --------------------------------------------------------------------------

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value !== undefined) {
      const prevValue = prevQueryRef.current;
      onChange('url', optimizedSearchQuery(e.target.value, prevValue, site.is_shopify));
      prevQueryRef.current = e.target.value;
    }
  };
  const handleClickRemove = () => onRemove();

  const handleClear = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    setAutoFocus(true);
    setEnterPressed(false);
    onChange('url', '');
  };

  const handleOpenAsChange = (val: OpenTabType, event?: React.MouseEvent<HTMLElement, MouseEvent>) => {
    if (event) event.stopPropagation();
    setShowOpenAsContainer(false);
    props.onChange('behavior', val);
  };

  // --------------------------------------------------------------------------
  // Autosuggest event handlers
  // --------------------------------------------------------------------------
  const handleSuggestionSelected = (
    e: React.FormEvent<any>,
    params: { suggestionValue: UItem; suggestion: ItemSuggestion }
  ) => {
    if (params.suggestion.numHidden) {
      e.preventDefault();
      setShowAllGroups([...showAllGroups, params.suggestion.grouped_type]);
      throw Error('Show More Selected');
    }
    const suggestionValue = params.suggestionValue;
    const itemType = suggestionValue.item_type.includes('page') ? 'page' : suggestionValue.item_type;
    let behavior = 'currentTab';
    switch (itemType) {
      case 'collection':
      case 'product':
        behavior = 'addToCart';
        break;
      case 'form':
        behavior = 'popup';
        break;
    }
    props.onUpdateUrlProps({
      url: `${itemType}:${suggestionValue.id}`,
      behavior,
    });

    props.hideToolbar && props.hideToolbar();
  };

  const handleSuggestionsFetchRequested = (params: SuggestionsFetchRequestedParams) => {
    let value = params.value;
    if (!link || !link.url || value !== link.url.trim() || params.reason === 'input-focused') {
      if ((value as { numHidden?: number }).numHidden) value = link.url.trim();
      const pdfSuggestion = searchPages(value).slice(0, 5);
      setPdfSuggestions(pdfSuggestion);
      setProductQuery(value);
      // Uncomment when we are able to support collections
      // setProductCollectionQuery(value);
      if (value && !value.match(ITEM_URL_REGEXP)) onSearchUpdate(value);
    }
  };

  const handleSuggestionsClearRequested = () => {
    setItemSuggestions([]);
    setShowAllGroups([]);
  };

  // --------------------------------------------------------------------------
  // Outside click
  // --------------------------------------------------------------------------

  const handleOutsideClick = (e: MouseEvent) => {
    if (ref.current && ref.current !== e.target && !ref.current.contains(e.target as HTMLElement)) {
      props.onBlur?.();
    }
  };

  const attachOutsideClickHandler = () => {
    document.addEventListener('mousedown', handleOutsideClick);
  };

  const detachOutsideClickHandler = () => {
    document.removeEventListener('mousedown', handleOutsideClick);
  };

  OnMount(() => attachOutsideClickHandler());

  OnUnmount(() => detachOutsideClickHandler());

  // --------------------------------------------------------------------------
  // Search
  // --------------------------------------------------------------------------
  const getSearchEngine = memoizeDeep((searchDocuments) => {
    const engine = new SearchEngine('id');
    engine.addIndex('name');
    engine.addIndex('slug');
    engine.addDocuments(searchDocuments);
    return engine;
  });

  const getSearchDocuments = () => {
    const searchDocuments: ExtendedUFile[] = [];
    if (!props.pdfs) return [];
    props.pdfs.forEach((media) => {
      const searchDocument = pick(media, ['id', 'file']) as ExtendedUFile;
      searchDocument.name = searchDocument.file.split('/').splice(-1);
      searchDocument.item_type = 'media';
      searchDocuments.push(searchDocument);
    });

    return searchDocuments;
  };

  const searchPages = (value: string) => {
    const searchDocuments = getSearchDocuments();
    const searchEngine = getSearchEngine(searchDocuments);
    return searchEngine.search(value);
  };

  // --------------------------------------------------------------------------
  // URL state types
  // --------------------------------------------------------------------------
  // If url is a smartUrl
  // - return matching page, blog, or article if one exists
  // - return undefined if matching content does not exist
  // else
  // - return false to indicate it is not a smartUrl
  const getContentFromSmartUrl = (): UItem | UFile | false | string => {
    const { value } = props;

    // TODO - replace with single content list later

    const productMatch = value.match(PRODUCT_URL_REGEXP);
    if (productMatch) {
      const shopProds = filterProducts(props.products) as unknown as UItem[];
      return (
        shopProds.find((item) => item && item.id === productMatch[2]) ||
        ({
          title: 'Product',
          name: isDeletedItem ? 'Product deleted' : 'Loading...',
          category_id: 'product',
        } as unknown as UItem)
      );
    }

    const productCollectionMatch = value.match(PRODUCT_COLLECTION_URL_REGEXP);
    if (productCollectionMatch) {
      const shopProds = filterProducts(props.shopifyCollections) as unknown as UItem[];
      return (
        shopProds.find((item) => item && item.id === productCollectionMatch[2]) ||
        ({
          title: 'Product Collection',
          name: isDeletedItem ? 'Product collection deleted' : 'Loading...',
          category_id: 'collection',
        } as unknown as UItem)
      );
    }

    const mediaMatch = value.match(MEDIA_URL_REGEXP);
    if (mediaMatch) {
      const pdf = pdfs.find((pdf) => pdf.id === mediaMatch[1]);
      return pdf;
    }

    const formMatch = value.match(FORM_URL_REGEXP);
    if (formMatch) {
      const form = forms.find((form) => form.id === formMatch[1]);
      return { ...form, item_type: 'form' } as unknown as UItem;
    }

    const couponMatch = value.match(COUPON_REGEXP);
    if (couponMatch) {
      return `Coupon: ${couponMatch[1].trim()}`;
    }

    const itemMatch = value.match(ITEM_URL_REGEXP);
    if (itemMatch) {
      const { searchedItems: items, item } = props;
      return [...items, item].find((item) => item && item.id === itemMatch[2]);
    }

    return false;
  };

  const getUrlType = () => {
    const {
      link: { url },
    } = props;

    if (isEmpty(url)) {
      return URL_TYPES.EMPTY;
    }

    const matchedContent = getContentFromSmartUrl();

    if (typeof matchedContent === 'string') return URL_TYPES.CONTENT_NOT_EXPECTED;
    else if (matchedContent) return URL_TYPES.CONTENT_EXISTS;
    else if (matchedContent == null) return URL_TYPES.CONTENT_NOT_FOUND;
    else if (url.match(NAKED_URL_REGEXP)) return URL_TYPES.URL;
    else return URL_TYPES.UNKNOWN;
  };

  // TODO: Daniel Upgrade don't know if grouped_type.grouped type is right
  function renderSectionTitle(section: ItemSuggestionGroup) {
    return <div className={'sectionTitle'}>{section?.grouped_type?.grouped_type}</div>;
  }

  function getSectionSuggestions(section: ItemSuggestion) {
    return section.suggestions;
  }

  // --------------------------------------------------------------------------
  // Render
  // --------------------------------------------------------------------------
  const urlType = getUrlType();
  const isContent =
    !disablePageAutocomplete && (urlType === URL_TYPES.CONTENT_EXISTS || urlType === URL_TYPES.CONTENT_NOT_EXPECTED);

  let content: UItem | UFile | false | string;
  if (isContent) content = getContentFromSmartUrl();

  const placeholder = props.placeholder || (disablePageAutocomplete && 'Insert URL') || 'Type page name or insert URL';

  const onClick = (e: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
    if ((e.target as HTMLInputElement).value !== undefined) {
      handleSuggestionsFetchRequested({ value: (e.target as HTMLInputElement).value, reason: 'input-focused' });
    }
  };

  const [enterPressed, setEnterPressed] = useState(value ? true : false);
  const onKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      setEnterPressed(true);
    }
  };

  const inputProps = {
    ref: inputRef,
    placeholder,
    onFocus,
    onClick,
    onKeyDown,
    onBlur,
    autoFocus,
    onChange: handleChange,
    value: link.url || '',
  };

  const [showAllGroups, setShowAllGroups] = useState([]);
  const itemSuggestionsGrouped = getItemSuggestionsGrouped(itemSuggestions, showAllGroups);

  if (setOpenedFalse) {
    useOnClickOutside(ref, setOpenedFalse);
  }

  let openAsArr: OpenTabType[] = ['currentTab', 'newTab'];
  switch ((content as UItem)?.item_type) {
    case 'collection':
    case 'product':
      openAsArr = ['addToCart', 'popup', 'currentTab', 'newTab'];
      break;
    case 'form':
      openAsArr = ['popup'];
      break;
  }

  const behavior = props.link.behavior || 'currentTab';

  return (
    <div
      className={classnames(
        styles.SmartUrlToolbar,
        { [styles.isRichText]: isRichText, [styles.ignorePadding]: !!props.hideBehaviour },
        'toolbar'
      )}
      onClick={(e) => e.stopPropagation()}
      data-test-id="smart-url-input-block"
      ref={ref}
      style={{
        opacity: content && typeof content !== 'string' && content.name === 'Loading...' ? 0 : 1,
      }}
    >
      {props.hideBehaviour === false && <IconUrl className={styles.inputIcon} />}
      {isContent && content && typeof content !== 'string' && (
        <div className={styles.pageTagContainer}>
          <div className={styles.pageTag}>
            <span className={styles.pageTagName}>
              {(content as UFile).category_id === 'pdf'
                ? Object.keys(content.metadata).length === 0
                  ? 'Bad PDF'
                  : (content as UFile).file.split('/').splice(-1)
                : content.name}
            </span>
            <a className={styles.pageTagClose} role="button" onClick={handleClear}>
              <IconCancel className={BaseIconStyles.fillWhite} />
            </a>
          </div>
        </div>
      )}
      {isContent && content && typeof content === 'string' && (
        <>
          {!disablePageAutocomplete &&
            (!props?.formats?.link || props?.formats?.link?.href !== value) &&
            !enterPressed && (
              <Autosuggest
                multiSection={true}
                suggestions={itemSuggestionsGrouped}
                id={autosuggestId}
                theme={styles}
                renderSuggestion={(page: ItemSuggestion) => {
                  if (page.numHidden) {
                    return (
                      <div
                        onClick={() => setShowAllGroups([...showAllGroups, page.grouped_type])}
                        className={styles.suggestionShowMore}
                      >
                        {page.numHidden} more
                      </div>
                    );
                  }
                  return (
                    <span className={styles.suggestionRow}>
                      {page.type === 'product' && (
                        <div className={styles.suggestionIconImgContainer}>
                          <img className={styles.suggestionImgIcon} src={page.media?.[0]?.url} alt="" />
                        </div>
                      )}
                      {page.type === 'collection' && (
                        <div className={styles.suggestionCollectionImgContainer}>
                          {page.product_media.map((mediaEl: any) => {
                            return <img className={styles.suggestionCollectionIcon} src={mediaEl.url} alt="" />;
                          })}
                        </div>
                      )}
                      {page.type !== 'product' && page.type !== 'collection' && (
                        <div className={styles.suggestionIconContainer}>{getIconByType(page.type)}</div>
                      )}
                      <div className={styles.suggestionName}>{page.name}</div>
                    </span>
                  );
                }}
                getSuggestionValue={(page: any) => page}
                getSectionSuggestions={getSectionSuggestions}
                onSuggestionSelected={handleSuggestionSelected as any}
                onSuggestionsFetchRequested={handleSuggestionsFetchRequested}
                onSuggestionsClearRequested={handleSuggestionsClearRequested}
                /* TODO: Daniel update don't know if this is right */
                /* @ts-ignore */
                inputProps={inputProps}
                renderSectionTitle={renderSectionTitle}
                // @ts-ignore
                shouldKeepSuggestionsOnSelect={(params) => params.numHidden}
              />
            )}
          {((props?.formats?.link && props?.formats?.link?.href === value) || enterPressed) && (
            <div className={styles.couponTagContainer}>
              <div className={styles.pageTag}>
                <span className={styles.pageTagName}>{content}</span>
                <a className={styles.pageTagClose} role="button" onClick={handleClear}>
                  <IconCancel className={BaseIconStyles.fillWhite} />
                </a>
              </div>
            </div>
          )}
        </>
      )}
      {!isContent && (
        <>
          {disablePageAutocomplete && <input type="text" className={styles.input} {...inputProps} />}
          {!disablePageAutocomplete && (
            <Autosuggest
              multiSection={true}
              suggestions={itemSuggestionsGrouped}
              id={autosuggestId}
              theme={styles}
              renderSuggestion={(page: ItemSuggestion) => {
                if (page.numHidden) {
                  return (
                    <div
                      onClick={() => setShowAllGroups([...showAllGroups, page.grouped_type])}
                      className={styles.suggestionShowMore}
                    >
                      {page.numHidden} more
                    </div>
                  );
                }
                return (
                  <span className={styles.suggestionRow}>
                    {page.type === 'product' && (
                      <div className={styles.suggestionIconImgContainer}>
                        <img className={styles.suggestionImgIcon} src={page.media?.[0]?.url} alt="" />
                      </div>
                    )}
                    {page.type === 'collection' && (
                      <div className={styles.suggestionCollectionImgContainer}>
                        {page.product_media.map((mediaEl: any) => {
                          return <img className={styles.suggestionCollectionIcon} src={mediaEl.url} alt="" />;
                        })}
                      </div>
                    )}
                    {page.type !== 'product' && page.type !== 'collection' && (
                      <div className={styles.suggestionIconContainer}>{getIconByType(page.type)}</div>
                    )}
                    <div className={styles.suggestionName}>{page.name}</div>
                  </span>
                );
              }}
              getSuggestionValue={(page: any) => page}
              getSectionSuggestions={getSectionSuggestions}
              onSuggestionSelected={handleSuggestionSelected as any}
              onSuggestionsFetchRequested={handleSuggestionsFetchRequested}
              onSuggestionsClearRequested={handleSuggestionsClearRequested}
              /* TODO: Daniel update don't know if this is right */
              /* @ts-ignore */
              inputProps={inputProps}
              renderSectionTitle={renderSectionTitle}
              // @ts-ignore
              shouldKeepSuggestionsOnSelect={(params) => params.numHidden}
            />
          )}
        </>
      )}
      {!props.hideBehaviour && ((isContent && content && typeof content !== 'string') || urlType === URL_TYPES.URL) && (
        <div
          className={styles.openOption + ' ' + (showOpenAsContainer && styles.menuFocused)}
          onClick={() => setShowOpenAsContainer(!showOpenAsContainer)}
          onMouseLeave={() => setShowOpenAsContainer(false)}
        >
          {openAsMiniTitle(behavior)} <IconArrowDown />
          {showOpenAsContainer && (
            <div className={styles.openAsContainer}>
              {openAsArr.map((val) => (
                <div className={styles.openAsOption} onClick={(event) => handleOpenAsChange(val, event)}>
                  <div>{openAsTitle(val, (content as UItem)?.item_type)}</div>
                  {behavior === val && <IconCheck />}
                </div>
              ))}
            </div>
          )}
        </div>
      )}

      {onRemove && (
        <button onClick={handleClickRemove} data-test-id="remove-action">
          <IconToolbarTrash className={styles.removeButton} />
        </button>
      )}
    </div>
  );
};

export default compose(withItems, withShopifyCollections, withProducts, withForms)(SmartUrlToolbar);
