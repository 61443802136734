// This reducer collects all content (pages, articles, and blogs). At the time
// of writing this, the main content entity reducers discard the content json
// fields from batch fetches to keep batched data separate from draft data.
// This needs to be changed, but in the meantime this reducer will collect
// all of that content so that components like the color picker can access
// everything.

import * as actionTypes from '../actions/actionTypes';
import union from 'lodash/union';
import { createSelector } from 'reselect';

const initState = {
  publishedIds: [],
  publishedById: {},
  draftIds: [],
  draftsById: {},
};

export default function allContentReducer(state = initState, action) {
  switch (action.type) {
    case actionTypes.PAGES_RECEIVE_LIST:
      if (action.payload.pageIds && action.payload.entities.rawPages) {
        return {
          ...state,
          publishedIds: union(state.publishedIds, action.payload.pageIds),
          publishedById: {
            ...state.publishedById,
            ...action.payload.entities.rawPages,
          },
        };
      } else {
        return state;
      }

    case actionTypes.PAGE_RECEIVE:
      if (action.payload.entities.rawPages) {
        return {
          ...state,
          draftIds: union(state.draftIds, Object.keys(action.payload.entities.rawPages)),
          draftsById: {
            ...state.draftsById,
            ...action.payload.entities.rawPages,
          },
        };
      } else {
        return state;
      }

    default:
      return state;
  }
}

// Selectors
// ----------------------------------------------------------------------------

export function selectLocalState(globalState) {
  return globalState.allContent;
}

export const selectAllPublished = createSelector(selectLocalState, (localState) => {
  const allIds = localState.publishedIds;
  const byId = localState.publishedById;
  return allIds.map((id) => byId[id]);
});

export const selectAllDrafts = createSelector(selectLocalState, (localState) => {
  const allIds = localState.draftIds;
  const byId = localState.draftsById;
  return allIds.map((id) => byId[id]);
});

export const selectAll = createSelector(selectAllPublished, selectAllDrafts, (published, drafts) => {
  return published.concat(drafts);
});
