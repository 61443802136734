import React from 'react';
import withSite from '../../containers/withSite';
import classnames from 'classnames';

import styles from './EditorCanvas.module.scss';

import Sections from './Sections';
import { FramePortal } from 'components/unstack-components/Component/FramePortal';
import { USite } from 'types/USite';
import { useDispatch, useSelector } from 'react-redux';

import * as siteLayoutSelectors from 'reducers/siteLayoutReducer';
import { setHoverToolbar, setActiveToolbar } from 'actions/toolbarActions';
import { isDeviceMode } from 'types/LegacyEditor';
import HeaderRenderer from './Layout/HeaderRenderer';
import FooterRenderer from './Layout/FooterRenderer';
import * as siteSelectors from 'reducers/sitesReducer';

type Props = {
  pageId: string;
  site: USite;
  itemType: string;
  setIFrameLoaded: () => void;
  loadedIframe: boolean;
  dataTable?: string;
};

function PageCanvas(props: Props) {
  const { pageId, site, setIFrameLoaded, loadedIframe, itemType, dataTable } = props;
  const siteDomain = useSelector(() => siteSelectors.getUrlSiteFromSite(site));
  const dispatch = useDispatch();

  const layout = useSelector((state) => siteLayoutSelectors.selectLayoutByItemType(itemType)(state));

  const PageContent = (
    <div id="spark" className={`eps sriracha ${itemType}`}>
      <Sections pageId={pageId} dataTable={dataTable} site={site} />
      <div className="sticky-bottom-spacer" />
    </div>
  );

  function clearToolbarStates() {
    if (isDeviceMode()) {
      dispatch(setHoverToolbar(undefined));
      dispatch(setActiveToolbar(undefined));
    }
  }

  return (
    <div className={classnames(styles.EditorCanvasContainer, { [styles.hidden]: !loadedIframe })}>
      <div
        onClick={clearToolbarStates}
        className={classnames(styles.EditorCanvas, { [styles.DeviceMode]: isDeviceMode() && loadedIframe })}
      >
        <div id="spark" className={classnames('eps', 'sriracha', styles.sriracha)}>
          {isDeviceMode() ? <FramePortal setIFrameLoaded={setIFrameLoaded}>{PageContent}</FramePortal> : PageContent}
        </div>
      </div>
    </div>
  );
}

export default withSite(PageCanvas);
