import React from 'react';

import omit from 'lodash/omit';
import startCase from 'lodash/startCase';
import MediaInstance from './MediaInstance';
import Placeholder from './MediaElement/Placeholder';
import MediaEntity from '../../../../base/Media/MediaEntity2';

import { SectionUiHandlersInterface } from '../../../types';
import { MediaInstanceTagArgs, MediaTagArgs } from '../../../tag-types';
import { getItemKey } from '../../util/utils';
import { set, del, insert, push, assign } from 'object-path-immutable';
import { USectionDevices } from 'types/USection';
import { getDeviceTypeToSaveTo } from '../../util/helpers/deviceHelper';
import { useSelector } from 'react-redux';
import { getDevice } from 'reducers/uiReducer';

interface IMediaElement extends MediaTagArgs, MediaInstanceTagArgs {
  sectionUiHandlers?: SectionUiHandlersInterface;
  brightnessTheme?: BrightnessThemeValue;
  defaults: any;
  content: USectionDevices;
  onChange: (content: USectionDevices, key?: string, multi?: boolean) => void;
  type?: string;
}

const MediaElement: React.FunctionComponent<IMediaElement> = (props) => {
  const {
    category,
    readOnly,
    type,
    onChange,
    // getValue,
    className,
    defaults = {},
    dataRef: value = '',
    contentKey,
    aspectRatio,
    crop: _crop,
    enableVideos,
    enableEmbeds,
    enableLinking,
    layout: _layout,
    quality: _quality,
    sectionUiHandlers,
    brightnessTheme = 'light-bg',
    enableResizing: _enableResizing,
    containerInfo,
    content,
  } = props;

  const key = containerInfo ? getItemKey(containerInfo, contentKey) : contentKey || '';
  const splitKey = key.split('.');
  const isImage = category === 'image';
  const device = useSelector(getDevice);

  if (readOnly) {
    const newProps = {
      ...omit(props, ['enableResizing', 'enableLinking', 'enableVideos', 'enableEmbeds']),
      src: value?.url,
      onChange: () => {},
      value: { ...value },
      type,
    };
    return <MediaInstance {...newProps} />;
  }

  function handleChange(value: MediaProps) {
    onChange(
      set(content, [getDeviceTypeToSaveTo(device, true), ...splitKey.slice(1)], {
        ...value,
        url: value?.src,
      }),
      `content.${splitKey[0]}`
    );
  }

  return (
    <MediaEntity
      className={className + ' unstack-media'}
      entity={{ ...value, src: value?.src || value?.url }}
      onChange={handleChange}
      category={sectionUiHandlers.pageType ? 'data_table' : category}
      hideEmbeds={!(enableEmbeds || defaults.enableEmbeds)}
      hideVideo={!(enableVideos || defaults.enableVideos)}
      hideImage={!isImage}
      showMediaContextOptions
      enableLinking={enableLinking || defaults.enableLinking}
      dataTable={sectionUiHandlers.pageType}
      // showPdf={showPdf}
      placeholder={
        <Placeholder
          className={className}
          aspectRatio={aspectRatio}
          label={sectionUiHandlers.pageType ? value?.url : category === 'image' ? 'Media' : startCase(category)}
          brightnessTheme={brightnessTheme}
          style={{ cursor: 'pointer' }}
        />
      }
      layout={_layout || 'responsive'}
    />
  );
};

export default MediaElement;
