/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useState, useEffect } from 'react';

import { CategoryView, PaginatedList, ProductView } from './IndividualStorefrontList';

export const BaseListView = ({
  setPlaceholder,
  query,
  onChange,
  searchData,
  setSearchData,
  value,
  setIsSelecting,
  isTemplateSettings,
}) => {
  const [hierarchy, setHierarchy] = useState([]);
  const [fullFierarchyData, setFullHierarchyData] = useState();
  const [previousParent, setPreviousParent] = useState([]);
  const [paginate, setPaginate] = useState('');
  const [isCollectionLoading, setIsCollectionLoading] = useState(false);
  const [isProductsLoading, setIsProductsLoading] = useState(false);

  useEffect(() => {
    const copy = [...previousParent];
    setPlaceholder(copy.reverse().join(' \u2192 '));
  }, [previousParent]);

  const commonProps = {
    setPreviousParent,
    setHierarchy,
    hierarchy,
    previousParent,
    onChange,
    value,
    searchData,
    setSearchData,
    setIsSelecting,
    isTemplateSettings,
    setFullHierarchyData,
  };

  if (paginate) {
    return <PaginatedList {...commonProps} setPaginate={setPaginate} type={paginate} />;
  }

  return (
    <>
      <div>
        {!!hierarchy.length && !isCollectionLoading && !isProductsLoading && (
          <a
            className="action"
            onClick={() => {
              if (paginate) setPaginate('');
              else {
                setPreviousParent([...previousParent.slice(1)]);
                setHierarchy([...hierarchy.slice(1)]);
              }
            }}
          >
            Back {previousParent.length > 1 ? `to ${previousParent[1]}` : ''}
          </a>
        )}
        <CategoryView
          isDataLoading={isCollectionLoading || isProductsLoading}
          setIsCollectionLoading={setIsCollectionLoading}
          onChange={onChange}
          setPaginate={setPaginate}
          {...commonProps}
        />
        <ProductView
          isDataLoading={isCollectionLoading || isProductsLoading}
          setPaginate={setPaginate}
          isBaseView={!hierarchy[0]}
          onChange={onChange}
          query={query}
          setIsProductsLoading={setIsProductsLoading}
          {...commonProps}
        />
      </div>
      {previousParent[0] && (
        <form
          onSubmit={(e) => {
            e.preventDefault();
            if (isTemplateSettings) onChange(fullFierarchyData, 'filtered');
            else onChange({ id: hierarchy[0] }, 'filtered', 4);
            setIsSelecting(false);
          }}
        >
          <input
            className="button button-primary button-full"
            value={`Choose ${previousParent[0]} category`}
            type="submit"
          />
        </form>
      )}
    </>
  );
};
