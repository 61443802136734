import { APIKeysActions } from 'actions/apiKeysActions';
import * as actionTypes from '../actions/actionTypes';

// Reducer
// ----------------------------------------------------------------------------

export type APIKey = {
  created_at: string;
  id: string;
  modified_at: string;
  name: string;
  site: string;
};

type APIKeyState = {
  apiKeys: { [id: string]: APIKey };
  addKeyDrawerOpen: boolean;
};

const initState: APIKeyState = { apiKeys: {}, addKeyDrawerOpen: false };

export default function (state = initState, action: APIKeysActions) {
  switch (action.type) {
    case actionTypes.API_KEYS_RECEIVE_KEYS:
      return {
        ...state,
        apiKeys: action.apiKeys,
      };
    case actionTypes.API_KEYS_TOGGLE_ADD_API_KEY_DRAWER:
      return {
        ...state,
        addKeyDrawerOpen: !state.addKeyDrawerOpen,
      };
    case actionTypes.API_KEYS_CLOSE_ADD_API_KEY_DRAWER:
      return {
        ...state,
        addKeyDrawerOpen: false,
      };

    default:
      return state;
  }
}

// Raw selectors
// ----------------------------------------------------------------------------

export function getAPIKeys(globalState: { apiKeys: APIKeyState }) {
  return globalState.apiKeys.apiKeys;
}

export function addKeyDrawerOpen(globalState: { apiKeys: APIKeyState }) {
  return globalState.apiKeys.addKeyDrawerOpen;
}
