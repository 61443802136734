import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import Spinner from '../base/Spinner';
import styles from './SuperUserLogin.module.scss';
import { SPARK_API_BASE_URL } from '../../settings';
import { useNavigate, useParams } from 'react-router-dom';
import { loginSuccess } from '../../actions/accountActions';
import { initializeApp } from '../../actions/appActions';

function SuperUserLogin() {
  const [error, setError] = useState(null);
  const dispatch = useDispatch();
  const { token } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    obtainToken();
  }, []);

  const obtainToken = () => {
    const url = `${SPARK_API_BASE_URL}admin/token/${token}/obtain/`;
    fetch(url)
      .then((response) => {
        const contentType = response.headers.get('content-type');
        const isJSON = contentType && contentType.indexOf('application/json') !== -1;

        if (response.ok && isJSON) {
          return response.json();
        } else {
          throw response;
        }
      })
      .then((json) => {
        const jwt = json.token;
        const policies = json.policies;
        if (jwt) {
          login(jwt, policies);
        } else {
          showError(json);
        }
      })
      .catch((error) => {
        showError(error);
      });
  };

  const login = (jwt: string, policies: { [key: string]: any }) => {
    dispatch(loginSuccess(jwt, policies));
    dispatch(initializeApp());
    navigate('/');
  };

  const showError = (error: null | JSON) => {
    const msg = error === null ? 'Inspect logs' : JSON.stringify(error, null, 2);
    setError(msg);
  };

  return (
    <div className={styles.container}>
      {!error && (
        <div className={styles.logginIn}>
          <Spinner />
          <p>
            Logging you in
            <br />
            <span className={styles.token}>{token}</span>
          </p>
        </div>
      )}
      {error && (
        <div className={styles.error}>
          <h3>Error</h3>
          <pre>{error}</pre>
        </div>
      )}
    </div>
  );
}

export default connect(null)(SuperUserLogin);
