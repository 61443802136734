import React from 'react';

import { LayoutProps } from '.';

import styles from '../StyleGuideEditor.module.scss';

interface PaddingProps extends LayoutProps {
  value: Array<string | number>;
  path: Array<string>;
}

export default function ({ value, handleChange, name, path }: PaddingProps) {
  let values = value;
  const handleValueChange = (index: number, value: string | number) => {
    values = [...values];
    values[index] = (value as number) > 20 ? '20' : (value as number) < 0 || !value ? '0' : value;
    handleChange(path, values);
  };
  return (
    <div className={styles.multipleFields}>
      {[0, 1, 2, 3].map((v) => (
        <input
          type="number"
          name={`${name}-${v}`}
          value={values[v]}
          min="0"
          max="20"
          step="1"
          className={styles.inputField}
          onChange={(e) => handleValueChange(v, e.target.value)}
        />
      ))}
    </div>
  );
}
