import React from 'react';

import styles from './Branding.module.scss';

import MediaEntity from '../../base/Media/MediaEntity';
import AuthenticatedMediaImg from '../../../containers/AuthenticatedMediaImg';
import { ReactComponent as SvgIconAdd } from '../../../assets/images/icon-add-section.svg';
import idx from 'lodash/get';

function Branding(props) {
  const { site } = props;

  const logo = site.elements.logo === '#' ? null : site.elements.logo;
  const logo_inverted = site.elements.logo_inverted === '#' ? null : site.elements.logo_inverted;
  const favicon = site.elements.favicon === '#' ? null : site.elements.favicon;
  const social_image = site.elements.social_image === '#' ? null : site.elements.social_image;
  const lightBackgroundColor = idx(site, 'styles.lightBackgroundColor', '');
  const darkBackgroundColor = idx(site, 'styles.darkBackgroundColor', '');

  return (
    <>
      <header>
        <h1>Branding</h1>
      </header>
      <article className={styles.Branding}>
        <div>
          <p>Logo for light backgrounds</p>
          <figure style={{ backgroundColor: lightBackgroundColor }}>
            <MediaEntity
              entity={logo}
              onChange={(value) => props.siteUpdaters.set('elements.logo', value)}
              renderPlaceholder={(props) => (
                <div className={styles.imgPlaceholder} {...props}>
                  Upload
                </div>
              )}
              render={(props) => <AuthenticatedMediaImg className={styles.logo} {...props} />}
              category="image"
              hideVideo
              hideEmbeds
              hideStockMedia
            />
            <figcaption>
              <input
                name="lightBackgroundColor"
                value={lightBackgroundColor}
                onChange={(e) => props.siteUpdaters.set('styles.lightBackgroundColor', e.target.value)}
                autoComplete="off"
                type="text"
              />
            </figcaption>
          </figure>
          <div className={styles.helperText}>(Click to edit)</div>
        </div>
        <div className={styles.darkLogo}>
          <p>Logo for dark backgrounds</p>
          <figure className=".logoZone" style={{ backgroundColor: darkBackgroundColor }}>
            <MediaEntity
              entity={logo_inverted}
              onChange={(value) => props.siteUpdaters.set('elements.logo_inverted', value)}
              renderPlaceholder={(props) => (
                <div className={styles.imgPlaceholder} {...props}>
                  Upload
                </div>
              )}
              render={(props) => <AuthenticatedMediaImg className={styles.logo} {...props} />}
              category="image"
              hideVideo
              hideEmbeds
              hideStockMedia
            />
            <figcaption>
              <input
                name="darkBackgroundColor"
                value={darkBackgroundColor}
                onChange={(e) => props.siteUpdaters.set('styles.darkBackgroundColor', e.target.value)}
                autoComplete="off"
                type="text"
              />
            </figcaption>
          </figure>
          <div className={styles.helperText}>(Click to edit)</div>
        </div>
        <div className={styles.iconElement}>
          <p>
            Favicon <span>32 x 32</span>
          </p>
          <MediaEntity
            entity={favicon}
            onChange={(value) => props.siteUpdaters.set('elements.favicon', value)}
            renderPlaceholder={(props) => <div className={styles.faviconPlaceholder} {...props} />}
            render={(props) => <AuthenticatedMediaImg className={styles.favicon} {...props} />}
            category="icon"
            hideStockMedia
          />
          <div className={styles.helperText}>(Click to edit)</div>
        </div>
        <div className={styles.iconSocial}>
          <p>
            Social share image <span>16 : 9</span>
          </p>
          <MediaEntity
            entity={social_image}
            onChange={(value) => props.siteUpdaters.set('elements.social_image', value)}
            renderPlaceholder={(props) => (
              <figure {...props}>
                <div className={styles.socialImagePlaceholder}>
                  <SvgIconAdd />
                  <small>PNG, JPEG or GIF</small>
                </div>
              </figure>
            )}
            render={(props) => (
              <figure>
                <AuthenticatedMediaImg className={styles.social} {...props} />
              </figure>
            )}
            category="image"
            hideStockMedia
            hideEmbeds
            hideVideo
            restrictMediaType={['x-icon', 'svg', 'svg+xml']}
          />
          <div className={styles.helperText}>(Click to edit)</div>
        </div>
      </article>
    </>
  );
}

export default Branding;
