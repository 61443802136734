import React, { ReactNode } from 'react';
import ReactDOM from 'react-dom';
import { CSSTransition } from 'react-transition-group';

import styles from './SideDrawer.module.scss';
import { ReactComponent as SvgIconClose } from '../../assets/images/icon-cancel.svg';
import { ReactComponent as SvgResizableThumb } from '../../assets/images/icon-resizable-thumb.svg';
import classnames from 'classnames';

type Props = {
  disableLocationClosing?: boolean;
  close: () => void;
  isOpen?: boolean;
  location?: string;
  avoidBackdrop?: boolean;
  persistOnBackdropClick?: boolean;
  darkTheme?: boolean;
  title: string;
  titleTheme?: string;
  showAmpLabel?: boolean;
  upgrade?: (event: any) => void;
  headerActions?: () => ReactNode;
  closeElement?: boolean;
  tabs?: any[];
  toggleValue?: string;
  handleClick?: (tab: string) => void;
  noPadding?: boolean;
  render?: () => ReactNode;
  showResize?: boolean;
  width?: number;
  setWidth?: (sidebarWidth: number) => void;
  children?: ReactNode;
};

class SideDrawer extends React.PureComponent<Props, { initialSize: number; initialPos: number }> {
  drawerRef: React.RefObject<HTMLDivElement>;

  constructor(props: Props) {
    super(props);
    this.drawerRef = React.createRef();
  }
  componentWillUnmount() {
    try {
      this.detachKeyDownListener();
      !this.props.disableLocationClosing && this.props.close();
    } catch (e) {
      console.error('Side drawer did not unmount');
    }
  }

  componentDidUpdate(prevProps: Props) {
    // Attach/detach key listeners
    if (!prevProps.isOpen && this.props.isOpen) {
      this.attachKeyDownListener();
    } else if (prevProps.isOpen && !this.props.isOpen) {
      this.detachKeyDownListener();
    }

    // Close if the react-router location changes
    if (prevProps.location !== this.props.location) {
      this.props.close();
    }
  }

  // Close drawer when the 'escape' key is pressed.
  handleKeyDown = (e: any) => {
    if (e.which === 27) this.props.close();
  };

  attachKeyDownListener() {
    document.addEventListener('keydown', this.handleKeyDown);
  }

  detachKeyDownListener() {
    document.removeEventListener('keydown', this.handleKeyDown);
  }

  resizeDragStart(e: React.DragEvent<HTMLDivElement>) {
    const el: HTMLElement = document.querySelector('.react-player iframe');
    if (el) el.style.pointerEvents = 'none';
    this.setState({ initialPos: Number(e.clientX), initialSize: Number(this.drawerRef.current.offsetWidth) });
  }

  resizeDrag(e: React.DragEvent<HTMLDivElement>) {
    if (e.screenX === 0) {
      return;
    }
    this.props.setWidth(
      Math.min(900, Math.max(this.state.initialSize + (e.clientX - this.state.initialPos) * -1, 300))
    );
  }

  resizeDragEnd(e: React.DragEvent<HTMLDivElement>) {
    const el: HTMLElement = document.querySelector('.react-player iframe');
    if (el) el.style.pointerEvents = 'all';
    this.props.setWidth(
      Math.min(900, Math.max(this.state.initialSize + (e.clientX - this.state.initialPos) * -1, 300))
    );
  }

  render() {
    return (
      <Portal>
        <CSSTransition
          in={this.props.isOpen}
          timeout={{
            enter: this.props.darkTheme ? 0 : 200,
            exit: 100,
          }}
          classNames={styles}
          mountOnEnter
          unmountOnExit
        >
          {(status) => (
            <div
              className="admin"
              onClick={(e) => e.stopPropagation()}
              onKeyPress={(e) => e.stopPropagation()}
              onKeyUp={(e) => e.stopPropagation()}
              onKeyDown={(e) => e.stopPropagation()}
              onSubmit={(e) => e.stopPropagation()}
            >
              <style>
                {`
                      html {
                        -ms-overflow-style: none;
                        scrollbar-width: none;
                      }
                      html::-webkit-scrollbar { 
                        display: none;  /* Safari and Chrome */
                      }`}
              </style>
              <div
                className={`${styles.backdrop} ${this.props.avoidBackdrop && styles.avoidBackdrop}`}
                onClick={() => (this.props.persistOnBackdropClick ? null : this.props.close())}
              />
              <div
                className={classnames(styles.drawer, { [styles.darkTheme]: this.props.darkTheme })}
                data-test-id="side-drawer"
                ref={this.drawerRef}
                style={this.props.darkTheme && { width: this.props.width || 'inherit' }}
              >
                {this.props.title && (
                  <div
                    className={classnames(styles.drawerHeader, {
                      [styles.drawerHeaderSuccess]: this.props.titleTheme === 'success',
                    })}
                    data-test-id="header"
                  >
                    <span style={{ flexGrow: 2 }}>{this.props.title}</span>

                    {this.props.showAmpLabel && (
                      <span className={styles.ampDisabledLabel} onClick={this.props.upgrade}>
                        AMP: Disabled
                      </span>
                    )}

                    {this.props.headerActions && this.props.headerActions()}

                    <span className={styles.drawerClose} data-test-id="close-drawer" onClick={this.props.close}>
                      {this.props.closeElement || <SvgIconClose />}
                    </span>
                  </div>
                )}
                {this.props.tabs && (
                  <div className={styles.tabs}>
                    {this.props.tabs.map((tab) => (
                      <>
                        <input
                          id={tab}
                          name="toggle"
                          type="radio"
                          checked={this.props.toggleValue === tab}
                          onChange={() => this.props.handleClick(tab)}
                        />
                        <label htmlFor={tab} onClick={() => this.props.handleClick(tab)}>
                          {tab}
                        </label>
                      </>
                    ))}
                  </div>
                )}
                <div
                  className={classnames(styles.drawerBody, {
                    [styles.padding]: !this.props.noPadding,
                  })}
                >
                  {this.props.render && this.props.render()}
                  {!this.props.render && this.props.children}
                </div>
                {this.props.showResize && (
                  <>
                    <SvgResizableThumb className={classnames(styles.resizableThumb, styles.resizableThumbImg)} />
                    <div
                      draggable={true}
                      className={styles.resizableThumb}
                      onDragStart={this.resizeDragStart.bind(this)}
                      onDrag={this.resizeDrag.bind(this)}
                      onDragEnd={this.resizeDragEnd.bind(this)}
                    />
                  </>
                )}
              </div>
            </div>
          )}
        </CSSTransition>
      </Portal>
    );
  }
}

export default SideDrawer;

const Portal = (props: any) => {
  return ReactDOM.createPortal(props.children, document.body);
};
