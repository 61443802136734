import React from 'react';
import { connect } from 'react-redux';

import * as uiUserSettingsActions from '../../../../actions/uiUserSettingsActions';
import * as uiUserSettingsSelectors from '../../../../reducers/uiUserSettingsReducer';

import SideDrawer from '../../../base/SideDrawer';
import EditUser from './EditUser';

function mapStateToProps(state) {
  return uiUserSettingsSelectors.selectLocalState(state);
}

const mapDispatchToProps = {
  close: uiUserSettingsActions.close,
};

class UserSettings extends React.PureComponent {
  render() {
    const { isOpen, close, errors, accountUserId } = this.props;
    return (
      <SideDrawer
        isOpen={isOpen}
        close={close}
        title={accountUserId ? 'Update team member details' : 'Invite someone to join your team'}
        render={() => <EditUser accountUserId={accountUserId} close={close} errors={errors} />}
      />
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserSettings);
