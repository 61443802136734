/**
 * PodcastEmbedBlot
 *
 * Renders any kind of supported Podcast embed. Detects the Podcast type by its
 * smartUrl.
 */

import Quill from '../quill-1.3.7/quill';
const BlockEmbed = Quill.import('blots/block/embed');

const SOURCES_URL = {
  transistor: 'https://share.transistor.fm/e',
  buzzsprout: 'https://www.buzzsprout.com/',
  soundcloud: 'https://w.soundcloud.com/player/?url=',
};

class PodcastEmbedBlot extends BlockEmbed {
  static create(value) {
    let node = super.create();

    // Store the raw source (e.g. smartUrl) in the element's dataset so it can
    // be retrieved in the blot's value function.
    const rawSrc = value.src || '';
    node.dataset.rawSrc = rawSrc;

    const match = value.src.match(/^(transistor|buzzsprout|soundcloud):(.+)/);
    if (match) {
      const source = match[1];
      const id = match[2];
      if (source === 'transistor') {
        const iframe = document.createElement('iframe');
        iframe.setAttribute('src', `${SOURCES_URL[source]}/${id}`);
        iframe.setAttribute('frameborder', '0');
        iframe.setAttribute('style', 'width:100%;height:180px');
        iframe.setAttribute('scrolling', 'no');
        iframe.setAttribute('seamless', 'true');
        node.appendChild(iframe);
      } else if (source === 'buzzsprout') {
        const [accId, podId] = id.split('-');
        const div = document.createElement('div');
        div.id = `buzzsprout-player-${podId}`;
        const script = document.createElement('script');
        script.src = `https://www.buzzsprout.com/${accId}/${podId}.js?container_id=buzzsprout-player-${podId}&player=small`;
        script.type = 'text/javascript';
        script.charset = 'utf-8';
        node.appendChild(div);
        node.appendChild(script);
      } else if (source === 'soundcloud') {
        const iframe = document.createElement('iframe');
        iframe.setAttribute(
          'src',
          `${SOURCES_URL[source]}${id}&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true`
        );
        iframe.setAttribute('frameborder', 'no');
        iframe.setAttribute('style', 'width:100%;height:200px');
        iframe.setAttribute('scrolling', 'no');
        iframe.setAttribute('allow', 'autoplay');
        node.appendChild(iframe);
      }
    }

    return node;
  }

  static value(node) {
    const value = {};

    // Retrieve the raw source (e.g. smartUrl) from the element's dataset.
    value.src = node.dataset.rawSrc;

    return value;
  }
}

PodcastEmbedBlot.blotName = 'podcast_embed';
PodcastEmbedBlot.tagName = 'div';
PodcastEmbedBlot.className = 'ql-podcast';

export default PodcastEmbedBlot;
