import React from 'react';
import styles from './ComponentThumbnail.module.scss';
import classnames from 'classnames';

export default function ComponentThumbnail(props) {
  const { label, src, onClick, theme, extended, isElement, isList } = props;

  return (
    <button
      {...(isElement && { 'data-tip': label })}
      data-place="bottom"
      className={classnames(styles.card, {
        [styles.darkTheme]: theme === 'dark',
        [styles.increaseWidth]: extended,
        [styles.isElement]: isElement,
        [styles.isListView]: isList,
      })}
      data-test-id={label.toLowerCase()}
      onClick={onClick}
    >
      <div className={styles.thumb} style={{ backgroundImage: `url(${src})` }} />
      <div className={styles.label}>{label}</div>
    </button>
  );
}
