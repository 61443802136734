import React, { useEffect, useState, useRef, useMemo } from 'react';
import { useSelector } from 'react-redux';

import groupBy from 'lodash/groupBy';
import classNames from 'classnames';
import { CSSTransition } from 'react-transition-group';

import { Column } from './types';
import KeyboardHandledList from '../../../../../base/KeyboardHandledList';
import * as integrationSelection from 'reducers/integrationsReducer';
import useActiveSite from 'hooks/useActiveSite';

import { ReactComponent as SvgIconForm } from 'assets/images/icon-forms(2).svg';
import { ReactComponent as SvgIconDollar } from 'assets/images/icon-dollar.svg';
import { ReactComponent as SvgIconSettings } from 'assets/images/icon-setting.svg';
import { ReactComponent as SvgIconContact } from 'assets/images/icon-contacts.svg';
import { ReactComponent as SvgIconBag } from 'assets/images/icon-shopping-bag.svg';
import { ReactComponent as SvgIconDatabase } from 'assets/images/icon-database.svg';
import { ReactComponent as SvgIconArrow } from 'assets/images/icon-arrow.svg';
import { ReactComponent as SvgIconSegment } from 'assets/images/icon-segment.svg';

import styles from './Conditions.module.scss';

const ICONS: any = {
  forms: <SvgIconForm height={20} width={20} />,
  params: <SvgIconSettings height={20} width={20} />,
  contact: <SvgIconContact height={20} width={20} />,
  database: <SvgIconDatabase height={20} width={20} />,
  subscription: <SvgIconDollar height={20} width={20} />,
  visit: <SvgIconArrow height={20} width={20} />,
  purchases: <SvgIconBag height={20} width={20} />,
  klaviyo: <SvgIconSegment height={20} width={20} />,
};
interface Props {
  isOpen: boolean;
  fields: Array<Column>;
  cursor: number;
  setHovered: (arg0: Column | undefined) => void;
  query: string;
  onChange: (arg0: Column) => void;
  handleClose: () => void;
}

export default function (props: Props) {
  const { isOpen, onChange, fields = [], cursor, setHovered, handleClose, query } = props;
  const [groupedProperties, setGroupedProperties] = useState({});
  const ref = useRef(null);

  useEffect(() => {
    //  @ts-ignore next-line
    setGroupedProperties(groupBy(fields, (field) => field.type || 'database'));
  }, [fields]);

  const groupFlat = useMemo(() => {
    if (groupedProperties != null) {
      const groupOrdered = Object.entries(groupedProperties).map((element) => element[1]);
      return groupOrdered.flat(1);
    }
    return [];
  }, [groupedProperties]);

  const activeSite = useActiveSite();
  const isShopifyConnected: boolean = Boolean(
    useSelector((state) =>
      integrationSelection.selectIntegrationBySlug(
        state,
        //  @ts-ignore
        activeSite.id,
        'shopify'
      )
    )
  );
  //  @ts-ignore
  if (isShopifyConnected) delete groupedProperties.subscription;

  useEffect(() => {
    if (isOpen && ref.current) {
      const element = ref.current;
      element?.scrollIntoView({ block: 'nearest' });
    }
  }, [cursor, fields, ref.current, isOpen]);

  return (
    <CSSTransition in={isOpen} timeout={141.59} classNames={styles} mountOnEnter unmountOnExit>
      <div className={styles.Drawer} data-test-id="property-picker-drawer">
        {Object.entries(groupedProperties).map((entry) => {
          const [key, value]: any = entry;
          return (
            <KeyboardHandledList
              items={value}
              cursor={cursor}
              setHovered={setHovered}
              onChange={onChange}
              render={(
                items: any,
                cursor: number,
                onChange: (arg0: Column) => void,
                setHovered: (arg0: Column | undefined) => void
              ) => (
                <ul className={classNames({ [styles.removePadding]: query })} key={key}>
                  {!query && (
                    <div className={styles.GroupTitle} data-test-id={key}>
                      {key}
                    </div>
                  )}
                  {items.map((field: Column) => (
                    <li
                      key={field.id}
                      onMouseDown={(e) => {
                        e.preventDefault();
                        onChange(field);
                        handleClose();
                      }}
                      onMouseMove={() => setHovered(field)}
                      onMouseLeave={() => setHovered(undefined)}
                      className={(groupFlat[cursor] as any)?.id === field.id ? styles.ListActive : null}
                      ref={(groupFlat[cursor] as any)?.id === field.id ? ref : null}
                    >
                      {ICONS[key]}
                      {field.label || field.slug}
                    </li>
                  ))}
                </ul>
              )}
            />
          );
        })}
      </div>
    </CSSTransition>
  );
}
