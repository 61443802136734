import React from 'react';
import { set, del, insert, push, assign } from 'object-path-immutable';
import BrightnessThemeSelector from '../../../../../../Editor/Toolbars2/components/BrightnessTheme';
import { UBoxInfo, USectionDevices } from 'types/USection';
import { useSelector } from 'react-redux';
import { getDevice } from 'reducers/uiReducer';
import { getDeviceTypeToSaveTo } from 'components/unstack-components/Component/util/helpers/deviceHelper';

type Props = {
  content: USectionDevices;
  dataRef: UBoxInfo;
  onChange: (content: USectionDevices, key?: string, multi?: boolean) => void;
  contentKey: string;
};

const BrightnessTheme: React.FunctionComponent<Props> = ({ dataRef, onChange, content, contentKey }) => {
  const device = useSelector(getDevice);
  const splitKey = contentKey.split('.');

  return (
    <BrightnessThemeSelector
      onChange={(theme: BrightnessThemeValue) =>
        onChange(
          set(content, [getDeviceTypeToSaveTo(device, true), ...splitKey.slice(1), 'brightnessTheme'], theme),
          `content.${splitKey[0]}`
        )
      }
      value={dataRef.brightnessTheme}
    />
  );
};

export default BrightnessTheme;
