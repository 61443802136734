import React from 'react';
import { ConversionSourceReport } from '../Reports';
import Controls from '../Controls';
import { useFilterState } from '../report-hooks';

import styles from './Tabs.module.scss';

export default function UTMsTab() {
  const filterState = useFilterState();
  const { channel } = filterState;

  let reports = [
    {
      segment: 'utm_medium',
      header: 'Medium',
      subheader: 'The type of traffic or channel.',
    },
    {
      segment: 'utm_source',
      header: 'Source',
      subheader: 'The site the traffic is coming from.',
    },
  ];
  if (channel === 'paid') {
    reports.push(
      {
        segment: 'utm_campaign',
        header: 'Campaign',
        subheader: 'The ad campaign.',
      },
      {
        segment: 'utm_term',
        header: 'Keyword',
        subheader: 'The search term used for paid ads.',
      },
      {
        segment: 'utm_content',
        header: 'Content',
        subheader: 'The ad copy or content.',
      }
    );
  } else {
    reports.push({
      segment: 'page',
      header: 'Page',
      subheader: 'The content of your ads.',
    });
  }

  return (
    <section>
      <Controls filterState={filterState} />
      <Reports reports={reports} filterState={filterState} />
    </section>
  );
}

function Reports({ reports, filterState }) {
  // if (reports.every(report => report.dataHook[0] && !report.dataHook[0].length))
  //   return (
  //     <ZeroInsightsState
  //       icon={icon}
  //       header="No UTM parameters to report"
  //       body="UTM parameters at the end of a URL tell you about people visiting a particular page. Any traffic from UTMs will be reported here."
  //     />
  //   );
  return reports.map((report) => {
    const { segment, header, subheader } = report;
    return (
      <section className={styles.utmTabelSection} key={header + subheader}>
        <header>{header}</header>
        <small>{subheader}</small>
        <ConversionSourceReport filterState={filterState} segment={segment} />
      </section>
    );
  });
}
