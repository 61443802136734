import React, { ReactNode, useState } from 'react';
import classnames from 'classnames';
import { useSelector } from 'react-redux';
import { InView } from 'react-intersection-observer';
import { selectNotificationBarIsOut } from '../reducers/notificationReducer';

import styles from './FixedSectionIndicator.module.scss';
import { useParams } from 'react-router-dom';

type Props = {
  children: ReactNode[];
  onSectionSelect: (name: string) => void;
  keys: string[];
};

function FixedSectionIndicator(props: Props) {
  const { urlSite } = useParams();
  const notificationBarIsOut = useSelector((state) => selectNotificationBarIsOut(state, urlSite));
  const sectionsNames = props.keys;
  const [sectionsIntersection, setSectionsIntersection] = useState({
    colors: 0,
    fonts: 0,
    typography: 0,
    buttons: 0,
    lists: 0,
  });

  const maxIntersectionIdx = Object.values(sectionsIntersection).indexOf(
    Math.max(...Object.values(sectionsIntersection))
  );

  return (
    <>
      <div
        className={classnames(styles.fixedSectionIndicator, {
          [styles.notificationBarIsOut]: notificationBarIsOut,
        })}
      >
        {sectionsNames.map((name: string) => (
          <span
            className={classnames(styles.label, {
              [styles.activeLabel]: Object.keys(sectionsIntersection)[maxIntersectionIdx] === name,
            })}
            key={name}
            onClick={() => {
              props.onSectionSelect(name);
              setSectionsIntersection({
                ...sectionsIntersection,
                [name]: 1,
              });
            }}
          >
            {name}
          </span>
        ))}
      </div>
      {props.children.map((child: any, idx: number) => (
        <InView
          as="section"
          onChange={(inView, entry) => {
            setSectionsIntersection({
              ...sectionsIntersection,
              [child.props.name]: Math.round(entry.intersectionRatio * 1000) / 1000,
            });
          }}
          key={idx}
          threshold={0.1}
          rootMargin="-100px"
        >
          {child}
        </InView>
      ))}
    </>
  );
}

export default FixedSectionIndicator;
