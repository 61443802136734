import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';

import StyleGuide from '../lib/StyleGuide';

import withSite from '../containers/withSite';
import * as fontActions from 'actions/fontActions';
import * as fontsSelector from 'reducers/fontsReducer';

const mapStateToProps = (state: any, props: any) => {
  return {
    fonts: fontsSelector.selectFonts(state),
    // @ts-ignore
    typekitId: fontsSelector.selectFontsTypekitId(state, props.site?.id),
  };
};

const mapDispatchToProps = {
  requestFontsIfNeeded: fontActions.requestAllIfNeeded,
};

class StyleOverrides extends React.Component<any> {
  componentDidMount() {
    this.props.requestFontsIfNeeded();
  }
  render() {
    const { site } = this.props;
    if (site == null || !this.props.fonts.length) return false;

    const styleGuide = new StyleGuide(site, this.props.fonts);

    return (
      <style>
        {/* Import fonts */}
        {this.props.loadAllFonts && `@import url('${styleGuide.fontImportUrl}');`}
        {styleGuide.selectFontImportUrl}
        {this.props.typekitId && <link rel="stylesheet" href={`https://use.typekit.net/${this.props.typekitId}.css`} />}
        {this.props.loadAllFonts && styleGuide.customFontImportUrl}
        {site.styleguide_url && <link rel="stylesheet" href={site.styleguide_url} />}
      </style>
    );
  }
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(withSite(StyleOverrides));
