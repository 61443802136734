import React, { useEffect } from 'react';
import styles from './Controls.module.scss';
import classnames from 'classnames';

import { ReactComponent as SvgIconBack } from '../../../assets/images/icon-back.svg';
import { ReactComponent as IconLayoutColumns } from '../../../assets/images/icon-layout-columns.svg';
import { ReactComponent as IconLayoutEmpty } from '../../../assets/images/icon-layout-empty.svg';
import { ReactComponent as IconLayoutColumnsTall } from '../../../assets/images/icon-layout-columns-tall.svg';
import { ReactComponent as IconLayoutMixed } from '../../../assets/images/icon-layout-mixed.svg';
import DevicePicker from 'components/Editor/DevicePicker';

export default function (props) {
  const {
    close,
    sectionType,
    isDirty,
    handleChange,
    handleSave,
    // handleDelete,
    editorView,
    setEditorView,
    categories,
    jsxError,
  } = props;

  useSaveKeybinding(handleSave);

  function EditorViewButton(props) {
    const { value, children } = props;

    return (
      <button
        className={classnames({
          [styles.active]: editorView === value,
        })}
        onClick={() => setEditorView(value)}
      >
        {children}
      </button>
    );
  }

  return (
    <div className={`admin ${styles.Controls}`}>
      <button onClick={close} className={styles.backButton}>
        <SvgIconBack />
      </button>
      <input
        type="text"
        placeholder="Name"
        value={sectionType.name || ''}
        onChange={(e) => handleChange('name', e.target.value)}
      />
      &nbsp;&nbsp;
      <input
        type="text"
        placeholder="Slug"
        value={sectionType.slug || ''}
        onChange={(e) => handleChange('slug', e.target.value)}
      />
      &nbsp;&nbsp;
      <select value={sectionType.category} onChange={(e) => handleChange('category', e.target.value)}>
        <option value="">Select a category...</option>
        {categories.map((category) => (
          <option value={category.id} key={category.id}>
            {category.name}
          </option>
        ))}
      </select>
      &nbsp;&nbsp;
      <span className={styles.rightWrapper}>
        <DevicePicker
          styles={{ order: 0, justifyContent: 'center', display: 'flex', flexGrow: 1, background: 'none' }}
        />
        {jsxError && <span className={styles.jsxError}>Errors with HTML</span>}
        <span className={styles.viewButtons}>
          <EditorViewButton value="full-preview">
            <IconLayoutEmpty />
          </EditorViewButton>
          <EditorViewButton value="standard">
            <IconLayoutColumns />
          </EditorViewButton>
          <EditorViewButton value="full-code">
            <IconLayoutColumnsTall />
          </EditorViewButton>
          <EditorViewButton value="full-code-2">
            <IconLayoutMixed />
          </EditorViewButton>
        </span>

        <span className="button-group" style={{ marginLeft: '3rem' }}>
          {/* <button onClick={close} className={`button ${styles.discard}`}>
            <SvgIconDiscard />
            Discard
          </button> */}
          <button disabled={jsxError} onClick={(e) => handleSave()} className={`button ${styles.publish}`}>
            Publish
          </button>
          {/* {handleDelete && (
            <button onClick={handleDelete} className="button button-danger">
              Delete
            </button>
          )} */}
        </span>
      </span>
    </div>
  );
}

// Listen for <cmd/ctrl>+S key to save
function useSaveKeybinding(handleSave) {
  useEffect(() => {
    const handler = (e) => {
      if ((e.metaKey || e.ctrlKey) && e.key === 's') {
        e.preventDefault();
        handleSave();
        return false;
      }
    };

    document.addEventListener('keydown', handler, false);
    return () => document.removeEventListener('keydown', handler, false);
  }, [handleSave]);
}
