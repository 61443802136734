import React from 'react';
import classnames from 'classnames';

import { ReactComponent as SvgCheckedCircle } from '../../../../../../assets/images/icon-checked-circle.svg';

import styles from './FormBuilder.module.scss';

export const FieldCard = ({ isSelected, onClick, name, disabled }) => (
  <div
    onClick={!isSelected && !disabled ? () => onClick() : null}
    className={classnames(styles.card, {
      [styles.selected]: isSelected,
      [styles.disabled]: disabled,
    })}
  >
    {name}
    {isSelected && <SvgCheckedCircle />}
  </div>
);
