import React, { FunctionComponent, useCallback, useEffect } from 'react';
import throttle from 'lodash/throttle';

import JSXParser from './util/JsxParser';
import { ClearPlugins, ReinitPlugins } from './PluginsRefactored';

import { UContext } from 'types/UContext';
import { SectionHandlersInterface, SectionUiHandlersInterface } from '../types';
import isEqual from 'lodash/isEqual';
import { OnMount } from 'hooks/mountUnmountHooks';

type RendererProps = {
  jsx: FunctionComponent<any>;
  context: UContext;
  onContextChange: (context: UContext) => void;
  sectionHandlers: SectionHandlersInterface;
  sectionUiHandlers: SectionUiHandlersInterface;
  componentsDefaults: any;
  jsxErrorCallback?: (error: Error) => void;
};

export type ComponentsMap = {
  [ComponentName: string]: JSXComponent;
};

export type JSXComponent = {
  component: FunctionComponent<any>;
  contextProps?: { key: string; defaultValue: any }[];
  props?: { [name: string]: any };
};

export type JSXBindings = {
  context: UContext;
  onChange: (context: UContext) => void;
  sectionHandlers: SectionHandlersInterface;
  sectionUiHandlers: SectionUiHandlersInterface;
};

const Renderer = (props: RendererProps) => {
  const { jsx, context, onContextChange, sectionHandlers, sectionUiHandlers, componentsDefaults, jsxErrorCallback } = {
    ...props,
  };

  OnMount(() => {
    ClearPlugins();
  });

  const RerenderPlugins = useCallback(
    throttle(() => {
      ReinitPlugins();
    }, 2000),
    []
  );

  useEffect(() => {
    RerenderPlugins();
  }, [context]);

  return (
    <JSXParser
      //  @ts-ignore
      jsx={jsx}
      context={context}
      sectionHandlers={sectionHandlers}
      sectionUiHandlers={sectionUiHandlers}
      onContextChange={onContextChange}
      componentsDefaults={componentsDefaults}
      jsxErrorCallback={jsxErrorCallback}
    />
  );
};

function areEqual(prevProps: RendererProps, nextProps: RendererProps) {
  return !(Object.keys(prevProps) as (keyof RendererProps)[]).some((key) => {
    if (prevProps[key] !== nextProps[key]) {
      if (key === 'sectionHandlers' || key === 'sectionUiHandlers') return !isEqual(prevProps[key], nextProps[key]);
      else return true;
    }
  });
}

export default React.memo(Renderer, areEqual);
