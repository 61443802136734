import React from 'react';
import withIntegrations from '../../../containers/withIntegrations';

import HubSpot from './Forms/HubSpotForm';
import Klaviyo from './Forms/KlaviyoForm';
import Spark from './Forms/SparkForm/RenderedTemplate';

class RenderedForm extends React.PureComponent {
  state = {
    isHovered: false,
  };

  render() {
    const { integrationsBySlug, integrationsByType, formAlignment, sectionType } = this.props;

    const value = this.props.value || {};
    const formType = value.type;

    const formAlignmentStyles = {};

    if (sectionType && sectionType === 'cta') {
      formAlignmentStyles.marginLeft = 'auto';
      formAlignmentStyles.marginRight = 'auto';
    }

    switch (formAlignment) {
      case 'left':
        formAlignmentStyles.marginRight = 'auto';
        break;
      case 'center':
        formAlignmentStyles.marginRight = 'auto';
        formAlignmentStyles.marginLeft = 'auto';
        break;
      case 'right':
        formAlignmentStyles.marginLeft = 'auto';
        break;
    }

    let renderedForm;
    switch (formType) {
      case 'hubspot':
        renderedForm = <HubSpot formProps={value} integrationsBySlug={integrationsBySlug} />;
        break;

      case 'klaviyo':
        renderedForm = <Klaviyo formProps={value} integrationsByType={integrationsByType} />;
        break;

      case 'unstack_form':
        renderedForm = (
          <Spark
            formAlignmentStyles={formAlignmentStyles}
            isHovered={this.state.isHovered}
            formId={this.props.value.id}
            data={this.props.value}
            onClick={this.props.onClick}
            systemFormId={this.props.value.systemFormId}
          />
        );
        break;

      default:
        renderedForm = <div className="form-embed-placeholder">Add form</div>;
    }

    return (
      <div
        onMouseOver={() => this.setState({ isHovered: true })}
        onMouseLeave={() => this.setState({ isHovered: false })}
        data-test-id="rendered-form"
        className="form-embed"
        style={
          formType === 'unstack_form'
            ? {
                backgroundColor: 'transparent',
                boxShadow: 'none',
                padding: 0,
                ...formAlignmentStyles,
              }
            : {
                ...formAlignmentStyles,
              }
        }
      >
        {renderedForm}
        {!this.props.systemFormId && <div className="form-embed-overlay" onClick={this.props.onClick} />}
      </div>
    );
  }
}

export default withIntegrations(RenderedForm);
