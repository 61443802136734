import React, { useState } from 'react';
import MediaEntity from '../../../base/Media/MediaEntity';
import { IS_VIDEO_REGEX } from '../../../../constants';
import { selectParseSmartUrl } from '../../../../reducers/uiReducer';
import { useSelector } from 'react-redux';

import { ButtonGroup, Button, RadioButton, Tray, Label, LabeledToolbar } from '../Toolbar2';
import {
  IconBgImage,
  IconBgCover,
  IconBgContain,
  IconBgTile,
  IconBgAlignLeft,
  IconBgAlignCenter,
  IconBgAlignRight,
  IconBgAlignTop,
  IconBgAlignMiddle,
  IconBgAlignBottom,
  IconBgSwap,
  IconBgRemove,
} from '../icons';

import styles from './BackgroundImageUploader.module.scss';

export default function ImageUpload(props) {
  const [startOpen, setStartOpen] = useState(false);

  const { onChange, isLockedOpen, lockOpen, unlockOpen, hideParallax } = props;
  const parseSmartUrl = useSelector((state) => selectParseSmartUrl(state));

  const value = props.value
    ? {
        parallax: props.value.parallax,
        backgroundSize: props.value.backgroundSize,
        backgroundRepeat: props.value.backgroundRepeat,
        backgroundPositionX: props.value.backgroundPositionX,
        backgroundPositionY: props.value.backgroundPositionY,
        optimization: props.value.backgroundOptimization,
        src: props.value?.backgroundImage?.url || props.value?.backgroundImage?.src,
      }
    : {};
  const hasBackgroundImage = !!value.src;
  const parsedSrc = parseSmartUrl(value.src);
  const isVideo = IS_VIDEO_REGEX.test(parsedSrc);

  const removeImage = () => {
    onChange(null);
    if (props.closeTray) props.closeTray();
  };

  const handleChange = (newValue) => {
    onChange({
      ...value,
      ...newValue,
      url: newValue.src || value.src,
    });
  };

  const handleEntityChange = (newValue) => {
    // When a user changes the image to a video, we want to auto-set the other
    // background props as a convenience.

    handleChange({
      ...newValue,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPositionX: 'center',
      backgroundPositionY: 'center',
    });
  };

  const handleParallaxChange = (parallax) => {
    // When a user first turns parallax on, we want to auto-set the other
    // background props as a convenience. However, they're still free to
    // override those values afterword. So if the user is just switching between
    // different parallax values, then we don't need to touch the other props.
    if (value.parallax == null && parallax != null) {
      handleChange({
        parallax: parallax,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPositionX: 'center',
        backgroundPositionY: 'center',
      });
    } else {
      handleChange({
        parallax: parallax,
      });
    }
  };

  if (!hasBackgroundImage) {
    return (
      <MediaSelector
        value={value}
        onChange={(value) => {
          handleEntityChange(value);
          setStartOpen(true);
        }}
        render={({ onClick }) => (
          <Button
            onMouseDown={onClick}
            data-tip="Background image"
            data-test-id="background-image-uploader"
            style={{ width: '40px', padding: '10px' }}
          >
            <IconBgImage active={hasBackgroundImage} />
          </Button>
        )}
        lockOpen={lockOpen}
        unlockOpen={unlockOpen}
      />
    );
  } else {
    return (
      <Tray
        startOpen={startOpen}
        forcedOpen={isLockedOpen}
        renderIcon={() => <IconBgImage active={true} />}
        trayLabel="Background Image"
        renderTray={() => (
          <>
            <span className={styles.BackgroundContainer}>
              <ButtonGroup compact>
                <Button onClick={() => handleChange({ backgroundSize: 'cover', backgroundRepeat: 'no-repeat' })}>
                  {/* <Button onClick={() => lockOpen()}> */}
                  <IconBgCover active={!value.backgroundSize || value.backgroundSize === 'cover'} />
                </Button>
                <Button onClick={() => handleChange({ backgroundSize: 'contain', backgroundRepeat: 'no-repeat' })}>
                  <IconBgContain active={value.backgroundSize === 'contain'} />
                </Button>
                <Button
                  onClick={() => handleChange({ backgroundSize: 'auto', backgroundRepeat: 'repeat' })}
                  disabled={isVideo}
                >
                  <IconBgTile active={value.backgroundRepeat === 'repeat'} />
                </Button>
              </ButtonGroup>
              <ButtonGroup compact>
                <Button onClick={() => handleChange({ backgroundPositionX: 'left' })}>
                  <IconBgAlignLeft active={!value.backgroundPositionX || value.backgroundPositionX === 'left'} />
                </Button>
                <Button onClick={() => handleChange({ backgroundPositionX: 'center' })}>
                  <IconBgAlignCenter active={value.backgroundPositionX === 'center'} />
                </Button>
                <Button onClick={() => handleChange({ backgroundPositionX: 'right' })}>
                  <IconBgAlignRight active={value.backgroundPositionX === 'right'} />
                </Button>
              </ButtonGroup>
              <ButtonGroup compact>
                <Button onClick={() => handleChange({ backgroundPositionY: 'top' })}>
                  <IconBgAlignTop active={!value.backgroundPositionY || value.backgroundPositionY === 'top'} />
                </Button>
                <Button onClick={() => handleChange({ backgroundPositionY: 'center' })}>
                  <IconBgAlignMiddle active={value.backgroundPositionY === 'center'} />
                </Button>
                <Button onClick={() => handleChange({ backgroundPositionY: 'bottom' })}>
                  <IconBgAlignBottom active={value.backgroundPositionY === 'bottom'} />
                </Button>
              </ButtonGroup>
              <ButtonGroup className={styles.rightGroup} data-test-id="media-right-swap-remove">
                <MediaSelector
                  value={value}
                  onChange={handleEntityChange}
                  render={({ onClick }) => (
                    <Button className={styles.labelledButton} onClick={onClick}>
                      <IconBgSwap />
                      Swap
                    </Button>
                  )}
                  lockOpen={lockOpen}
                  unlockOpen={unlockOpen}
                />
                <Button className={styles.labelledButton} onClick={removeImage}>
                  <IconBgRemove />
                  Remove
                </Button>
              </ButtonGroup>
            </span>
            {!hideParallax && (
              <ParallaxToolbar value={value.parallax} onChange={(newValue) => handleParallaxChange(newValue)} />
            )}
          </>
        )}
      />
    );
  }
}

function MediaSelector(props) {
  const { value, onChange, render, lockOpen, unlockOpen } = props;

  function handleChange(value) {
    onChange(value);
    if (unlockOpen) unlockOpen();
  }

  const withClickHandler = (onClick) => () => {
    if (lockOpen) lockOpen();
    onClick();
  };

  return (
    <MediaEntity
      entity={value}
      onChange={handleChange}
      category="background_image"
      render={({ onClick }) => render({ onClick: withClickHandler(onClick) })}
      hideEmbeds
      hideVideoOptions
    />
  );
}

function ParallaxToolbar(props) {
  const { value, onChange } = props;

  return (
    <LabeledToolbar
      style={{
        position: 'absolute',
        left: '0',
        top: '100%',
        width: '100%',
        height: '42px',
      }}
      label="Scroll Speed"
    >
      <Label>Scroll speed:</Label>
      <RadioButton selected={value === 0} onClick={() => onChange(0)}>
        Static
      </RadioButton>
      <RadioButton selected={value === 0.25} onClick={() => onChange(0.25)}>
        0.25x
      </RadioButton>
      <RadioButton selected={value === 0.5} onClick={() => onChange(0.5)}>
        0.5x
      </RadioButton>
      <RadioButton selected={value == null} onClick={() => onChange(null)}>
        Normal
      </RadioButton>
      <RadioButton selected={value === 1.5} onClick={() => onChange(1.5)}>
        1.5x
      </RadioButton>
      <RadioButton selected={value === 2} onClick={() => onChange(2)}>
        2x
      </RadioButton>
    </LabeledToolbar>
  );
}
