import React, { memo, useRef, useState } from 'react';

type Props = {
  className?: string;
  width?: number;
  label?: string;
  onClick?: (event: React.MouseEvent<SVGElement>) => void;
  style?: React.CSSProperties;
  brightnessTheme?: BrightnessThemeValue;
  aspectRatio?: any;
};

const calculateAspectRatio = (value: string) => {
  //  @ts-ignore
  if (Number.isFinite(value)) return value;
  else if (value.includes(':')) {
    //  @ts-ignore
    const [antecedent, consequent]: [number, number] = value.split(':');
    return consequent / antecedent;
  }
  if (!isNaN(parseFloat(value))) return Number(value);
};

const clientTextSize = 18;

const Placeholder: React.FunctionComponent<Props> = ({
  className,
  width = 680,
  label = 'Image',
  onClick,
  style,
  brightnessTheme = 'light-bg',
  aspectRatio = 0.56,
}) => {
  //  @ts-ignore
  const height = width * calculateAspectRatio(aspectRatio);

  const [textSize, setTextSize] = useState(0);
  const ref = useRef<SVGSVGElement>();

  if (ref.current) {
    const clientHeight = ref.current && ref.current.clientHeight;
    const newTextSize = (clientTextSize * height) / clientHeight;

    if (newTextSize !== textSize) {
      if (Number.isFinite(newTextSize)) {
        setTextSize(newTextSize);
      } else if (textSize !== 0) {
        setTextSize(0);
      }
    }
  }

  if (!Number.isFinite(width) || !Number.isFinite(height)) {
    return null;
  }

  const [isHovered, setIsHovered] = useState(false);

  return (
    <svg
      // height={height} width={width}
      className={className + ' unstack-media'}
      data-label="media-placeholder"
      role={'img'}
      viewBox={`0 0 ${width} ${height}`}
      xmlns="http://www.w3.org/2000/svg"
      style={style}
      onClick={onClick}
      ref={ref}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <g>
        <rect
          width={width}
          height={height}
          x="0"
          y="0"
          fill={isHovered ? '#4040D914' : '#f5f5f5'}
          stroke={isHovered ? '#8096BD80' : '#b8c8e5'}
        ></rect>
        <svg
          x={width / 2 - 130.6}
          y={height / 2 - 130.6}
          width={width}
          height={height}
          viewBox={`0 0 ${width} ${height}`}
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{ opacity: isHovered ? '0.7' : '1' }}
        >
          <path
            d="M80.5604 139.697L51.4375 179.8V180.062C51.4375 193.593 62.4065 204.562 75.9375 204.562H180.062C193.593 204.562 204.562 193.593 204.562 180.062V172.383L163.442 121.882C160.975 118.853 156.341 118.876 153.905 121.929L122.584 161.178C120.335 163.997 116.148 164.272 113.55 161.771L89.7633 138.883C87.0843 136.305 82.745 136.689 80.5604 139.697Z"
            fill={isHovered ? '#4040D9' : '#94979E'}
            fill-opacity={isHovered ? '1' : '0.8'}
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M204.562 172.383V75.9375C204.562 62.4066 193.593 51.4375 180.062 51.4375L75.9375 51.4375C62.4065 51.4375 51.4375 62.4065 51.4375 75.9375V179.8L80.5604 139.697C82.7451 136.689 87.0844 136.305 89.7634 138.883L113.55 161.772C116.149 164.272 120.335 163.997 122.584 161.178L153.905 121.929C156.341 118.876 160.975 118.853 163.442 121.882L204.562 172.383ZM128 94.3723C128 107.638 117.246 118.392 103.98 118.392C90.7146 118.392 79.9606 107.638 79.9606 94.3723C79.9606 81.1066 90.7146 70.3527 103.98 70.3527C117.246 70.3527 128 81.1066 128 94.3723Z"
            fill={isHovered ? '#AEAEF4' : '#868A92'}
            fill-opacity={isHovered ? '1' : '0.4'}
          />
        </svg>
        {isHovered && (
          <svg
            x={width / 2 - 27.3 + 65.3}
            y={height / 2 - 27.3 + 65.3}
            width={width}
            height={height}
            viewBox={`0 0 ${width} ${height}`}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="32" cy="32" r="32" fill="#4040D9" />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M35 29V13H29V29H13V35H29V51H35V35H51V29H35Z"
              fill="white"
            />
          </svg>
        )}
      </g>
    </svg>
  );
};

export default memo(Placeholder);
