import React from 'react';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';

import styles from './TrialBanner.module.scss';
interface BannerProps {
  trialDays: number;
}

function TrialBanner(props: BannerProps) {
  const { trialDays } = props;
  const { urlSite } = useParams<{ urlSite: string }>();

  return (
    <div className={styles.banner}>
      <div className={styles.icon}>🦄</div>
      <h3>Unlimited free trial</h3>
      <p>
        All features are unlocked for the next <b>{trialDays} days</b>. Enjoy!
      </p>
      <Link to={`/${urlSite}/settings/plans`}>View plans {'->'}</Link>
    </div>
  );
}

export default TrialBanner;
