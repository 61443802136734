import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import QueryString from 'query-string';
import Filters from 'components/Filters';
import pick from 'lodash/pick';
import { useSelector } from 'react-redux';
import * as siteSelectors from '../reducers/sitesReducer';

const ALLOWED_PARAMS = ['search', 'status', 'order_by', 'page', 'tab', 'item_type', 'blog'];

type Filter = {
  search?: string;
  status: string;
  page: string;
  tab: string;
  order_by: string;
};

const DEFAULT_FILTERS: Filter = {
  search: undefined,
  status: '',
  page: '1',
  tab: '',
  order_by: '-modified_at',
};

type Props = {
  itemType: string;
  panel: any;
  defaults?: any;
};

const withQueryParams = (WrappedComponent: React.ComponentType<any>, props: Props) => {
  const Container: React.FC<any> = (passedProps) => {
    const navigate = useNavigate();
    const location = useLocation();
    const { urlSite } = useParams();
    const { search } = location;
    const path = location.pathname;
    const [oldPageReference, setOldPageReference] = useState<undefined | string>();
    const [searchParams] = useSearchParams();
    const paramsJSON: { [key: string]: string } = {};
    // @ts-ignore
    const site = useSelector((state) => siteSelectors.selectSiteByDomain(state, urlSite));
    searchParams.forEach((value, key) => {
      paramsJSON[key] = value;
    });
    const [state, setState] = useState({
      page: 1,
      ...DEFAULT_FILTERS,
      ...paramsJSON,
      item_type: props.itemType,
    });

    useEffect(() => {
      const prevSearch = sessionStorage.getItem('prevSearch');
      if (prevSearch !== search) {
        const newState = search ? { ...DEFAULT_FILTERS, ...QueryString.parse(search) } : { ...DEFAULT_FILTERS };
        setState(newState as any);
        sessionStorage.setItem('prevSearch', search);
      }
    }, [location]);

    useEffect(() => {
      setState({ ...state, item_type: props.itemType });
    }, [props.itemType]);

    return (
      <>
        <Filters queryParams={state} panel={props.panel} />
        <WrappedComponent
          {...props}
          {...passedProps}
          siteName={site.name}
          queryParams={state}
          updateQueryParams={(filter: any) => {
            if (filter.search === '' || filter.status === '') {
              delete state.search;
              delete filter.search;
              filter.page = oldPageReference || 1;
              setOldPageReference(undefined);
            } else if (filter.search || filter.status) {
              if (oldPageReference === undefined) setOldPageReference(state.page);
              filter.page = 1;
            }
            const newSearchParams =
              '?' +
              QueryString.stringify({
                ...pick(state, ALLOWED_PARAMS),
                ...filter,
              });
            navigate({
              pathname: path,
              search: `${newSearchParams}`,
            });
          }}
        />
      </>
    );
  };
  return Container;
};

export default withQueryParams;

// export default (WrappedComponent, { itemType: item_type, panel, defaults }) => {
//   class Container extends React.Component {
//     constructor(props) {
//       super(props);
//       this.state = {
//         // page: 1,
//         ...DEFAULT_FILTERS,
//         ...QueryString.parse(props.location.search),
//         item_type,
//       };
//       this.oldPageReference = undefined;
//     }

//     componentDidUpdate(prevProp) {
//       /**
//         TODO: Filter out empty filter values
//         e.g. ?search= should be remove from query params
//       */
//       const { search } = this.props.location;
//       if (prevProp.location.search !== search) {
//         const newState = search ? { ...DEFAULT_FILTERS, ...QueryString.parse(search) } : { ...DEFAULT_FILTERS };
//         this.setState(newState);
//       }
//     }

//     render() {
//       return (
//         <>
//           <Filters queryParams={this.state} panel={panel} />
//           <WrappedComponent
//             {...this.props}
//             queryParams={this.state}
//             updateQueryParams={(filter) => {
//               if (filter.search === '' || filter.status === '') {
//                 delete this.state.search;
//                 delete filter.search;
//                 filter.page = this.oldPageReference || 1;
//                 this.oldPageReference = undefined;
//               } else if (filter.search || filter.status) {
//                 if (this.oldPageReference === undefined) this.oldPageReference = this.state.page;
//                 filter.page = 1;
//               }
//               const newSearchParams =
//                 '?' +
//                 QueryString.stringify({
//                   ...pick(this.state, ALLOWED_PARAMS),
//                   ...filter,
//                 });
//               this.props.history.push({
//                 pathname: `${this.props.location.pathname}`,
//                 search: `${newSearchParams}`,
//               });
//             }}
//           />
//         </>
//       );
//     }
//   }

//   return compose(withRouter, withSiteProps(mapSiteToProps))(Container);
// };
