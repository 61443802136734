import React from 'react';
import classnames from 'classnames';

import { CodeTagArgs } from 'components/unstack-components/tag-types';
import { USectionDevices } from 'types/USection';
import { set, del, insert, push, assign } from 'object-path-immutable';
import { useSelector } from 'react-redux';
import { getDevice } from 'reducers/uiReducer';
import { getDeviceTypeToSaveTo } from '../../util/helpers/deviceHelper';
import styles from './Code.module.scss';
import { getItemKey } from '../../util';

interface ICodeElement extends CodeTagArgs {
  onChange: (content: USectionDevices, key?: string, multi?: boolean) => void;
  contentKey: string;
  content: USectionDevices;
}

const Code: React.FunctionComponent<ICodeElement> = ({ dataRef, onChange, contentKey, content, containerInfo }) => {
  const device = useSelector(getDevice);
  const key = containerInfo ? getItemKey(containerInfo, contentKey) : contentKey;
  const splitKey = key.split('.');

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    onChange(
      set(content, [getDeviceTypeToSaveTo(device, true), ...splitKey.slice(1), 'value'], e.target.value),
      `content.${splitKey[0]}`
    );
  };

  return (
    <div className={styles.CustomView}>
      <div className={classnames(styles.textareaContainer, styles.textareaHeader)}>
        <span className={styles.customCodeText}>CUSTOM CODE</span>
        <span className={styles.instructionText}>
          Add your embedded or iframe code below and view it on your published page.
        </span>
      </div>
      <div className={classnames(styles.textareaContainer, styles.textareaControl)}>
        <textarea value={dataRef?.value} onChange={handleChange} placeholder="Paste your code here" />
      </div>
    </div>
  );
};

export default Code;
