import React, { ReactNode, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as SvgIconBack } from '../assets/images/icon-back.svg';
import styles from './AdminBar.module.scss';

type Props = {
  fixed: string;
  foreGround: any;
  center: ReactNode;
  right: ReactNode;
  backTo: string;
  left: ReactNode;
  isSaving?: boolean;
  width?: number;
};

function AdminBar(props: Props) {
  const { fixed, foreGround, center, right, backTo, left, isSaving } = props;
  const headerClassName = `${styles.AdminBar} ${fixed ? styles.fixed : ''}`;
  const navigate = useNavigate();

  const backClick = useCallback(() => {
    if (!isSaving) {
      navigate(backTo);
    } else {
      const message =
        'Unsaved changes are currently processing. ' + 'If you leave before saving, your changes will be lost.';
      if (window.confirm(message)) {
        navigate(backTo);
      }
    }
  }, [backTo, isSaving]);

  return (
    <header className={headerClassName} style={{ right: props.width || 0 }}>
      <section className={styles.center}>{center}</section>
      <section className={styles.right}>{right}</section>
      {backTo && (
        <section className={styles.left}>
          <button onClick={backClick} className={styles.circleIcon} aria-label="back-to" tabIndex={1}>
            <SvgIconBack />
          </button>
          {left}
        </section>
      )}
      {foreGround}
    </header>
  );
}

export default AdminBar;
