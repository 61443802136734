import React, { ReactNode } from 'react';

type Props = {
  renderError: (error: string) => React.ReactNode;
  children?: ReactNode;
};

type State = {
  error: any;
};

class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { error: null };
  }

  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    return { error };
  }

  // componentDidCatch(error, errorInfo) {
  //   // You can also log the error to an error reporting service
  //   // console.error(error, errorInfo);
  // }

  render() {
    const { renderError, children } = this.props;
    const { error } = this.state;
    const hasError = !!error;

    if (hasError) {
      return renderError(error);
    }

    return children;
  }
}

export default ErrorBoundary;
