import * as actionTypes from './actionTypes';

export interface RequestDrawerOpen {
  type: 'ADD_SECTION_SET_DRAWER_OPEN';
}
export function requestDrawerOpen() {
  return {
    type: actionTypes.ADD_SECTION_SET_DRAWER_OPEN,
  };
}

export interface RequestDrawerClose {
  type: 'ADD_SECTION_SET_DRAWER_CLOSE';
}
export function requestDrawerClose() {
  return {
    type: actionTypes.ADD_SECTION_SET_DRAWER_CLOSE,
  };
}

export type AddSectionActions = RequestDrawerOpen | RequestDrawerClose;
