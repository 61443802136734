import React from 'react';
import { compose } from 'redux';
import withSiteId from './withSiteId';
import { connect } from 'react-redux';
import {
  selectIsFetching,
  selectAll,
  selectAllById,
  selectSiteIntegrationsByType,
  selectHubspotForms,
  selectSiteIntegrationsBySlug,
  selectIntegrationLists,
} from '../reducers/integrationsReducer';
import { requestAllIfNeeded } from '../actions/integrationActions';

function mapStateToProps(state, ownProps) {
  return {
    hubspotForms: selectHubspotForms(state),
    formsByName: selectHubspotForms(state),
    integrationsFetching: selectIsFetching(state),
    integrations: selectAll(state),
    integrationsById: selectAllById(state),
    integrationsByType: selectSiteIntegrationsByType(state, ownProps.siteId),
    integrationsBySlug: selectSiteIntegrationsBySlug(state, ownProps.siteId),
    contactLists: selectIntegrationLists(state),
  };
}

const mapDispatchToProps = {
  requestIntegrations: requestAllIfNeeded,
};

export default (WrappedComponent) => {
  class Container extends React.PureComponent {
    componentDidMount() {
      this.props.requestIntegrations();
    }

    render() {
      const { requestIntegrations, ...props } = this.props;
      return <WrappedComponent {...props} />;
    }
  }

  return compose(withSiteId, connect(mapStateToProps, mapDispatchToProps))(Container);
};
