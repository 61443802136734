import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { connect } from 'react-redux';
import throttle from 'lodash/throttle';
import Autosuggest from 'react-autosuggest';

import Popper from 'components/base/Popper';
import { getTableRows } from 'services/spark-api';

import * as dataTablesActions from 'actions/dataTablesActions';
import * as dataTablesSelectors from 'reducers/dataTablesReducer';

import styles from '../Sheet/Sheet.module.scss';
import 'react-datetime/css/react-datetime.css';
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import styles2 from 'components/base/FormHelpers/SmartUrlInput.module.scss';
import { selectActiveSite } from 'reducers/sitesReducer';

const mapStateToProps = (state, ownProps) => ({
  relatedRows: dataTablesSelectors.selectRelatedRows(state),
  tableInfo: dataTablesSelectors.selectTableById(state, ownProps.table),
  site: selectActiveSite(state),
});
const mapDispatchToProps = {
  addRelatedRow: dataTablesActions.addRelatedRow,
};

class RelatedTableInput extends React.Component {
  ref = React.createRef();
  autosuggestId = uuidv4();
  inputRef = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      itemSuggestions: [],
      query: '',
      throttle: '',
      value: '',
    };
  }
  componentDidMount() {
    getTableRows(this.props.site.id, this.props.table, '').then((res) =>
      this.setState({
        itemSuggestions: [
          {
            title: this.props.tableInfo.name,
            rows: res.json.results,
          },
        ],
      })
    );
    if (this.props.value) {
      this.setState({ oldValue: this.props.value });
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevState.throttle !== this.state.throttle) {
      getTableRows(this.props.site.id, this.props.table, this.state.throttle).then((res) =>
        this.setState({
          itemSuggestions: [
            {
              title: this.props.tableInfo.name,
              rows: res.json.results,
            },
          ],
        })
      );
    }
  }
  throttleSearch = throttle(
    (throttle) => {
      this.setState({ throttle });
    },
    600,
    { leading: false }
  );
  // --------------------------------------------------------------------------
  // Autosuggest event handlers
  // --------------------------------------------------------------------------

  handleSuggestionSelected = (e, { suggestionValue }) => {
    e.preventDefault();
    this.setState({ value: suggestionValue.id, slug: suggestionValue.slug });
  };

  handleSuggestionsFetchRequested = ({ value }) => {
    if (typeof value === 'string') this.throttleSearch(value);
  };

  handleSuggestionsClearRequested = () => {
    this.setState({
      itemSuggestions: [],
      hasHighlightedSuggestion: false,
    });
  };

  handleSuggestionHighlighted = ({ suggestion }) => {
    this.setState({ hasHighlightedSuggestion: !!suggestion });
  };

  isUUID = (id) => id && id.match(/^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i);

  render() {
    const { onChange, relatedRows } = this.props;
    const { value, itemSuggestions, oldValue, slug } = this.state;
    const isContent = !slug && this.isUUID(value);

    let content;
    if (isContent) {
      content = relatedRows[isContent[0]];
    }

    const inputProps = {
      ref: this.inputRef,
      placeholder: 'Search row by slug',
      className: styles.popperInput,
      onBlur: () => {
        if (this.isUUID(value)) {
          onChange(value);
          this.props.addRelatedRow({ id: value, slug });
        } else if (!value) onChange(oldValue);
      },
      autoFocus: true,
      onChange: (e) => {
        this.setState({ value: e.target.value, slug: '' });
      },
      value: slug || value || '',
    };

    return (
      <Popper modifiers={[{ name: 'offset', options: { offset: [2, 2] } }]}>
        <div className="SmartUrlInput" ref={this.ref} data-test-id="smart-url-input-block">
          <Autosuggest
            id={this.autosuggestId}
            theme={styles2}
            alwaysRenderSuggestions
            suggestions={itemSuggestions}
            multiSection={true}
            getSectionSuggestions={(section) => {
              return section.rows;
            }}
            renderSectionTitle={(section) => {
              return (
                <div>
                  <small>{section.title}</small>
                </div>
              );
            }}
            renderSuggestion={(row, { isHighlighted }) => (
              <>
                <div className={styles2.suggestionName}>{row.name}</div>
                <div className={styles2.suggestionSlug}>{row.slug}</div>
              </>
            )}
            getSuggestionValue={(page) => page}
            onSuggestionSelected={this.handleSuggestionSelected}
            onSuggestionsFetchRequested={this.handleSuggestionsFetchRequested}
            onSuggestionsClearRequested={this.handleSuggestionsClearRequested}
            onSuggestionHighlighted={this.handleSuggestionHighlighted}
            inputProps={inputProps}
          />
        </div>
      </Popper>
    );
  }
}

export const ConnectedRelatedTableInput = connect(mapStateToProps, mapDispatchToProps)(RelatedTableInput);
