import React, { useState, LegacyRef } from 'react';

import { ShadowItem } from './ShadowItem';
import { ShadowDrawer } from './ShadowDrawer';

import styles from './Shadows.module.scss';

const SHADOW_STYLES = ['small', 'medium', 'large', 'x-large'];

type Props = {
  styleGuide: any;
  handleChange: (shadow: [string], value: string) => void;
  scrollRef: LegacyRef<HTMLElement>;
  name: string;
};

export default React.memo((props: Props) => {
  const { styleGuide, handleChange } = props;
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectedShadow, setSelected] = useState('none');

  return (
    <>
      <header ref={props.scrollRef} className={styles.shadowsStylesHeader}>
        <h1>Shadows</h1>
        <small>Set shadow styles used across your site.</small>
      </header>
      <div className={styles.shadowsContainer}>
        {SHADOW_STYLES.map((style, idx) => (
          <ShadowItem name={style} key={idx} setSelected={setSelected} setIsOpen={setIsOpen} styleGuide={styleGuide} />
        ))}
      </div>

      <ShadowDrawer
        title="Edit shadow"
        isOpen={isOpen}
        close={() => {
          setIsOpen(false);
          setSelected('');
        }}
        shadow={selectedShadow}
        handleChange={handleChange}
        styleGuide={styleGuide}
      />
    </>
  );
});
