/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useState } from 'react';
import { ReactComponent as SvgIconShopify } from '../../../../assets/images/icon-shopify.svg';
import modalStyles from './Shopify.module.scss';
import { deleteIntegrationRequest } from '../../../../actions/integrationActions';

import Modal from 'components/base/Modal';
import FormControl from 'components/base/FormHelpers/FormControl';

import { useDispatch, useSelector } from 'react-redux';
export default (props) => {
  const { isOpen, close, config, id } = props;
  const [shopName, setShopName] = useState('');
  const dispatch = useDispatch();

  return (
    <Modal isOpen={isOpen} close={close}>
      <div className={modalStyles.modal}>
        <h3>Are you sure you want to disconnect Shopify?</h3>
        <div className={modalStyles.contentContainer}>
          <div className={modalStyles.inputContainer}>
            <div>
              <span className={modalStyles.disconnectStatement}>
                Pages with Shopify products will still display, but the <strong>Buy</strong> button will be disabled
              </span>
              <div className={modalStyles.disconnectInput}>
                <span className={modalStyles.disconnectStatement}>
                  Type <strong>{config.shop_name}</strong> to disconnect:{' '}
                </span>
              </div>
              <FormControl value={shopName} onChange={(e) => setShopName(e.target.value)} type="text" />
            </div>
            <SvgIconShopify width={99} height={99} />
          </div>
          <div>
            <button onClick={close} className="button button-secondary">
              Cancel
            </button>
            <button
              onClick={() => {
                dispatch(deleteIntegrationRequest(id));
              }}
              disabled={shopName !== config.shop_name}
              className="button button-primary"
            >
              Yes, disconnect Shopify
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
