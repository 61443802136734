import * as types from '../actions/actionTypes';
import * as articleSelectors from './articlesReducer';
import * as siteSelectors from './sitesReducer';
import * as blogSelectors from './blogsReducer';
import { createSelector } from 'reselect';
import { applyOps } from '../lib/immutable-operations';

// ----------------------------------------------------------------------------
// Reducer
// ----------------------------------------------------------------------------

const initState = {
  articleId: '',
  isOpen: false,
  ops: [],
  errors: {},
};

export default function (state = initState, action) {
  switch (action.type) {
    case types.ARTICLE_SETTINGS_OPEN:
      return {
        isOpen: true,
        ...action.payload,
      };

    case types.ARTICLE_SETTINGS_CLOSE:
      return {
        ...state,
        isOpen: false,
        articleId: '',
        ops: [],
      };

    case types.ARTICLE_SETTINGS_ADD_OPS:
      return {
        ...state,
        ops: state.ops.concat(action.payload.ops),
      };

    default:
      return state;
  }
}

// ----------------------------------------------------------------------------
// Selectors
// ----------------------------------------------------------------------------

function selectLocalState(globalState) {
  return globalState.uiArticleSettings;
}

export function selectArticleId(globalState) {
  const localState = selectLocalState(globalState);
  return localState.articleId;
}

export function selectOps(globalState) {
  const localState = selectLocalState(globalState);
  return localState.ops;
}

function selectArticle(globalState) {
  const { articleId } = selectLocalState(globalState);
  return articleSelectors.selectArticle(globalState, articleId);
}

export const selectArticleDraft = createSelector(selectArticle, selectOps, (article, ops) => applyOps(article, ops));

export function selectArticleBlog(globalState) {
  const article = selectArticle(globalState);
  if (!article) return {};
  return blogSelectors.selectBlog(globalState, article.blog);
}

export const selectProps = createSelector(
  siteSelectors.selectAllSites,
  selectArticleDraft,
  selectLocalState,
  selectArticleBlog,
  (site, articleDraft, localState, articleBlog) => {
    return {
      siteDomain: siteSelectors.getFullDomainFromSite(site),
      blogSlug: articleBlog.slug,
      article: articleDraft,
      ...localState,
    };
  }
);
