import { UCart } from 'types/UCart';
import * as actionTypes from './actionTypes';

export interface ChangeCartData {
  type: 'TEMPLATE_GENERATOR_CHANGE_CART_DATA';
  cart: UCart;
}
export function changeCartData(cart: UCart) {
  return {
    type: actionTypes.TEMPLATE_GENERATOR_CHANGE_CART_DATA,
    cart: cart,
  };
}

export type TemplateGeneratorActions = ChangeCartData;
