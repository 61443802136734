import React from 'react';
import styles from './icons.module.scss';
import classnames from 'classnames';

// Imported images
import { ReactComponent as SvgIconIconCallout } from '../../../assets/images/icon-icon-callout.svg';
import { ReactComponent as SvgIconImageCallout } from '../../../assets/images/icon-image-callout.svg';
import { ReactComponent as SvgIconAlignCenter } from '../../../assets/images/text-align-center.svg';
import { ReactComponent as SvgIconAlignLeft } from '../../../assets/images/text-align-left.svg';
import { ReactComponent as SvgIconHelp } from '../../../assets/images/icon-help-about.svg';

import { ReactComponent as SvgIconAlignTop } from '../../../assets/images/icon-align-top.svg';
import { ReactComponent as SvgIconAlignMiddle } from '../../../assets/images/icon-align-middle.svg';
import { ReactComponent as SvgIconAlignBottom } from '../../../assets/images/icon-align-bottom.svg';

import { ReactComponent as SvgIconAlignRight } from '../../../assets/images/text-align-right.svg';
import { ReactComponent as SvgIconHeaderOption } from '../../../assets/images/icon-header-option.svg';
import { ReactComponent as SvgBackgroundColor } from '../../../assets/images/icon-background-color.svg';
import { ReactComponent as SvgIconAddItem } from '../../../assets/images/icon-add-item.svg';
import { ReactComponent as SvgIconRemoveItem } from '../../../assets/images/icon-remove-item.svg';
import { ReactComponent as SvgIconBgImage } from '../../../assets/images/icon-bg-image.svg';
import { ReactComponent as SvgIconFgImage } from '../../../assets/images/icon-foreground-image.svg';
import { ReactComponent as SvgIconCancelItem } from '../../../assets/images/icon-cancel.svg';
import { ReactComponent as SvgIconAnchor } from '../../../assets/images/icon-target.svg';
import { ReactComponent as SvgIconDarkBg } from '../../../assets/images/icon-dark-bg.svg';
import { ReactComponent as SvgIconLightBg } from '../../../assets/images/icon-light-bg.svg';
import { ReactComponent as SvgIconRemoveSection } from '../../../assets/images/icon-remove-section.svg';
import { ReactComponent as SvgIconImageLeft } from '../../../assets/images/icon-image-left.svg';
import { ReactComponent as SvgIconImageRight } from '../../../assets/images/icon-image-right.svg';
import { ReactComponent as SvgIconImageCenter } from '../../../assets/images/icon-image-center.svg';
import { ReactComponent as SvgIconImageFullWidth } from '../../../assets/images/icon-image-full-width.svg';
import { ReactComponent as SvgIconFullHeight } from '../../../assets/images/icon-full-height.svg';
import { ReactComponent as SvgIconNaturalHeight } from '../../../assets/images/icon-natural-height.svg';
import { ReactComponent as SvgIconContentAlignCenter } from '../../../assets/images/icon-content-align-center.svg';
import { ReactComponent as SvgIconContentAlignLeft } from '../../../assets/images/icon-content-align-left.svg';
import { ReactComponent as SvgIconContentAlignRight } from '../../../assets/images/icon-content-align-right.svg';
import { ReactComponent as SvgIconQuoteBig } from '../../../assets/images/icon-quote-big.svg';
import { ReactComponent as SvgIconQuoteSmall } from '../../../assets/images/icon-quote-small.svg';
import { ReactComponent as SvgIconCtaRight } from '../../../assets/images/icon-cta-right.svg';
import { ReactComponent as SvgIconCtaCenter } from '../../../assets/images/icon-cta-center.svg';
import { ReactComponent as SvgIconSpacing } from '../../../assets/images/icon-spacing.svg';
import { ReactComponent as SvgIconSpacingAndWidth } from '../../../assets/images/icon-spacing-and-width.svg';
import { ReactComponent as SvgIconMaxWidth } from '../../../assets/images/icon-max-width.svg';
import { ReactComponent as SvgIconCarousel } from '../../../assets/images/icon-carousel.svg';
import { ReactComponent as SvgIconConditionalContent } from '../../../assets/images/icon-conditional-content.svg';
import { ReactComponent as SvgIconCircularPlus } from '../../../assets/images/icon-circular-plus.svg';

import { ReactComponent as SvgIconBoxShadow } from '../../../assets/images/icon-box-shadow.svg';
import { ReactComponent as SvgIconBorderRadius } from '../../../assets/images/icon-border-radius.svg';
import { ReactComponent as SvgIconAnimate } from '../../../assets/images/icon-animate.svg';
import { ReactComponent as SvgIconTest } from '../../../assets/images/icon-test.svg';
import { ReactComponent as SvgIconTestActive } from '../../../assets/images/icon-test-active.svg';
import { ReactComponent as SvgIconViewTestResults } from '../../../assets/images/icon-view-test-results.svg';
import { ReactComponent as SvgIconTestOptions } from '../../../assets/images/icon-test-options.svg';
import { ReactComponent as SvgIconEndTest } from '../../../assets/images/icon-end-test.svg';
import { ReactComponent as SvgIconCheckedCircleGreen } from '../../../assets/images/icon-checked-circle-green.svg';

import { ReactComponent as SvgIconRteBold } from '../../../assets/images/icon-rte-bold.svg';
import { ReactComponent as SvgIconRteColor } from '../../../assets/images/icons-typography.svg';
import { ReactComponent as SvgIconRteBulletList } from '../../../assets/images/icon-rte-bullet-list.svg';
import { ReactComponent as SvgIconRteChecklist } from '../../../assets/images/icon-rte-checklist.svg';
import { ReactComponent as SvgIconRteCloseContent } from '../../../assets/images/icon-rte-close-content.svg';
import { ReactComponent as SvgIconRteCode } from '../../../assets/images/icon-rte-code.svg';
import { ReactComponent as SvgIconRteH1 } from '../../../assets/images/icon-rte-h1.svg';
import { ReactComponent as SvgIconRteH2 } from '../../../assets/images/icon-rte-h2.svg';
import { ReactComponent as SvgIconRteH3 } from '../../../assets/images/icon-rte-h3.svg';
import { ReactComponent as SvgIconRteHighlight } from '../../../assets/images/icon-rte-highlight.svg';
import { ReactComponent as SvgIconRteItalic } from '../../../assets/images/icon-rte-italic.svg';
import { ReactComponent as SvgIconRteLink } from '../../../assets/images/icon-rte-link.svg';
import { ReactComponent as SvgIconRteNumberedList } from '../../../assets/images/icon-rte-numbered-list.svg';
import { ReactComponent as SvgIconRteQuote } from '../../../assets/images/icon-rte-quote.svg';
import { ReactComponent as SvgIconRteClearFormat } from '../../../assets/images/icon-rte-clear-format.svg';
import { ReactComponent as SvgIconRteStrikeThrough } from '../../../assets/images/icon-rte-strike-through.svg';

import { ReactComponent as SvgIconCheckmark } from '../../../assets/images/icon-checkmark.svg';
import { ReactComponent as SvgIconNewTab } from '../../../assets/images/icon-new-tab.svg';

import { ReactComponent as SvgIconBgAlignBottom } from '../../../assets/images/icon-bg-align-bottom.svg';
import { ReactComponent as SvgIconBgAlignCenter } from '../../../assets/images/icon-bg-align-center.svg';
import { ReactComponent as SvgIconBgAlignLeft } from '../../../assets/images/icon-bg-align-left.svg';
import { ReactComponent as SvgIconBgAlignMiddle } from '../../../assets/images/icon-bg-align-middle.svg';
import { ReactComponent as SvgIconBgAlignRight } from '../../../assets/images/icon-bg-align-right.svg';
import { ReactComponent as SvgIconBgAlignTop } from '../../../assets/images/icon-bg-align-top.svg';
import { ReactComponent as SvgIconBgContain } from '../../../assets/images/icon-bg-contain.svg';
import { ReactComponent as SvgIconBgCover } from '../../../assets/images/icon-bg-cover.svg';
import { ReactComponent as SvgIconBgRemove } from '../../../assets/images/icon-bg-remove.svg';
import { ReactComponent as SvgIconBgSwap } from '../../../assets/images/icon-bg-swap.svg';
import { ReactComponent as SvgIconBgTile } from '../../../assets/images/icon-bg-tile.svg';

import { ReactComponent as SvgIconAnimateUp } from '../../../assets/images/icon-animate-up.svg';
import { ReactComponent as SvgIconAnimateRight } from '../../../assets/images/icon-animate-right.svg';
import { ReactComponent as SvgIconAnimateLeft } from '../../../assets/images/icon-animate-left.svg';
import { ReactComponent as SvgIconAnimateDown } from '../../../assets/images/icon-animate-down.svg';
import { ReactComponent as SvgIconAnimateZoomIn } from '../../../assets/images/icon-animate-zoom-in.svg';

import { ReactComponent as SvgIconEditContent } from '../../../assets/images/icon-edit-content.svg';

import { ReactComponent as SvgIconDate } from '../../../assets/images/icon-date.svg';
import { ReactComponent as SvgIconTime } from '../../../assets/images/icon-time.svg';

import { ReactComponent as SvgIconSynchronize } from '../../../assets/images/synchronize.svg';

import { ReactComponent as SvgIconDown } from '../../../assets/images/arrow-down.svg';

import { ReactComponent as SvgProperties } from '../../../assets/images/icon-properties.svg';

import { ReactComponent as SvgIconDeviceCheck } from '../../../assets/images/icon-device-check.svg';
import { ReactComponent as SvgIconDeviceDesktopMenu } from '../../../assets/images/icon-device-desktop-menu.svg';
import { ReactComponent as SvgIconDeviceMobileMenu } from '../../../assets/images/icon-device-mobile-menu.svg';
import { ReactComponent as SvgIconDeviceTabletMenu } from '../../../assets/images/icon-device-tablet-menu.svg';

import { ReactComponent as SvgIconDeviceMobilePlusMenu } from '../../../assets/images/icon-device-mobile-plus-menu.svg';
import { ReactComponent as SvgIconDeviceDesktopToolbar } from '../../../assets/images/icon-device-desktop-toolbar.svg';
import { ReactComponent as SvgIconDeviceMobileToolbar } from '../../../assets/images/icon-device-mobile-toolbar.svg';
import { ReactComponent as SvgIconDeviceTabletToolbar } from '../../../assets/images/icon-device-tablet-toolbar.svg';

import { ReactComponent as SvgIconDeviceMobilePlusToolbar } from '../../../assets/images/icon-device-mobile-plus-toolbar.svg';

import { ReactComponent as SvgIconChevronDownSmall } from '../../../assets/images/icon-chevron-down-small.svg';
import { ReactComponent as SvgIconChevronUpSmall } from '../../../assets/images/icon-chevron-up-small.svg';
import { ReactComponent as SvgIconPaddingT } from '../../../assets/images/icon-padding-t.svg';
import { ReactComponent as SvgIconPadding } from '../../../assets/images/icon-padding.svg';
import { ReactComponent as SvgIconRight } from '../../../assets/images/icon-right.svg';
import { ReactComponent as SvgIconTooltipPadding } from '../../../assets/images/icon-tooltip-padding.svg';
import { ReactComponent as SvgIconTooltipPaddingDefault } from '../../../assets/images/icon-tooltip-padding-default.svg';

// Exported icon components
export const IconDeviceCheck = createStandardIcon(SvgIconDeviceCheck);
export const IconDeviceDesktopMenu = createStandardIcon(SvgIconDeviceDesktopMenu);
export const IconDeviceTabletMenu = createStandardIcon(SvgIconDeviceTabletMenu);
export const IconDeviceMobileMenu = createStandardIcon(SvgIconDeviceMobileMenu);
export const IconDeviceMobilePlusMenu = createStandardIcon(SvgIconDeviceMobilePlusMenu);
export const IconDeviceDesktopToolbar = createStandardIcon(SvgIconDeviceDesktopToolbar);
export const IconDeviceMobileToolbar = createStandardIcon(SvgIconDeviceMobileToolbar);
export const IconDeviceTabletToolbar = createStandardIcon(SvgIconDeviceTabletToolbar);
export const IconDeviceMobilePlusToolbar = createStandardIcon(SvgIconDeviceMobilePlusToolbar);

export const IconChevronDownSmall = createStandardIcon(SvgIconChevronDownSmall);
export const IconChevronUpSmall = createStandardIcon(SvgIconChevronUpSmall);
export const IconPaddingT = createStandardIcon(SvgIconPaddingT);
export const IconPadding = createStandardIcon(SvgIconPadding);
export const IconRight = createStandardIcon(SvgIconRight);
export const IconTooltipPadding = createStandardIcon(SvgIconTooltipPadding);
export const IconTooltipPaddingDefault = createStandardIcon(SvgIconTooltipPaddingDefault);

export const IconDown = createStandardIcon(SvgIconDown);
export const IconIconCallout = createStandardIcon(SvgIconIconCallout);
export const IconImageCallout = createStandardIcon(SvgIconImageCallout);
export const IconAlignCenter = createStandardIcon(SvgIconAlignCenter, {
  stdHW: true,
});
export const IconAlignLeft = createStandardIcon(SvgIconAlignLeft, {
  stdHW: true,
});
export const IconAlignRight = createStandardIcon(SvgIconAlignRight, {
  stdHW: true,
});

export const IconAlignMiddle = createStandardIcon(SvgIconAlignMiddle);
export const IconAlignTop = createStandardIcon(SvgIconAlignTop);
export const IconAlignBottom = createStandardIcon(SvgIconAlignBottom);

export const IconHeaderOption = createStandardIcon(SvgIconHeaderOption, {
  stdHW: true,
});
export const IconBackgroundColor = createStandardIcon(SvgBackgroundColor);
export const IconAddItem = createStandardIcon(SvgIconAddItem);
export const IconRemoveItem = createStandardIcon(SvgIconRemoveItem);
export const IconBgImage = createStandardIcon(SvgIconBgImage);
export const IconFgImage = createStandardIcon(SvgIconFgImage);
export const IconCancelItem = createStandardIcon(SvgIconCancelItem);
export const IconAnchor = createStandardIcon(SvgIconAnchor, { stdH: true });
export const IconDarkBg = createStandardIcon(SvgIconDarkBg, {
  className: styles.brightnessTheme,
});
export const IconLightBg = createStandardIcon(SvgIconLightBg, {
  className: styles.brightnessTheme,
});
export const IconRemoveSection = createStandardIcon(SvgIconRemoveSection, {
  stdHW: true,
});
export const IconImageLeft = createStandardIcon(SvgIconImageLeft);
export const IconImageRight = createStandardIcon(SvgIconImageRight);
export const IconImageCenter = createStandardIcon(SvgIconImageCenter);
export const IconImageFullWidth = createStandardIcon(SvgIconImageFullWidth);
export const IconFullHeight = createStandardIcon(SvgIconFullHeight);
export const IconNaturalHeight = createStandardIcon(SvgIconNaturalHeight);
export const IconContentAlignCenter = createStandardIcon(SvgIconContentAlignCenter);
export const IconContentAlignLeft = createStandardIcon(SvgIconContentAlignLeft);
export const IconContentAlignRight = createStandardIcon(SvgIconContentAlignRight);
export const IconQuoteBig = createStandardIcon(SvgIconQuoteBig);
export const IconQuoteSmall = createStandardIcon(SvgIconQuoteSmall);
export const IconCtaRight = createStandardIcon(SvgIconCtaRight, { stdH: true });
export const IconCtaCenter = createStandardIcon(SvgIconCtaCenter, {
  stdH: true,
});
export const IconSpacingAndWidth = createStandardIcon(SvgIconSpacingAndWidth);
export const IconSpacing = createStandardIcon(SvgIconSpacing);
export const IconMaxWidth = createStandardIcon(SvgIconMaxWidth);
export const IconCarousel = createStandardIcon(SvgIconCarousel);
export const IconConditionalContent = createStandardIcon(SvgIconConditionalContent);
export const IconBoxShadow = createStandardIcon(SvgIconBoxShadow);
export const IconBorderRadius = createStandardIcon(SvgIconBorderRadius);
export const IconAnimate = createStandardIcon(SvgIconAnimate);
export const IconTest = createStandardIcon(SvgIconTest);
export const IconTestActive = createStandardIcon(SvgIconTestActive);
export const IconViewTestResults = createStandardIcon(SvgIconViewTestResults);
export const IconTestOptions = createStandardIcon(SvgIconTestOptions);
export const IconEndTest = createStandardIcon(SvgIconEndTest);
export const IconCheckedCircleGreen = createStandardIcon(SvgIconCheckedCircleGreen);

export const IconRteBold = createStandardIcon(SvgIconRteBold);
export const IconRteColor = createStandardIcon(SvgIconRteColor);
export const IconRteBulletList = createStandardIcon(SvgIconRteBulletList);
export const IconRteChecklist = createStandardIcon(SvgIconRteChecklist);
export const IconRteCloseContent = createStandardIcon(SvgIconRteCloseContent);
export const IconRteCode = createStandardIcon(SvgIconRteCode);
export const IconRteH1 = createStandardIcon(SvgIconRteH1);
export const IconRteH2 = createStandardIcon(SvgIconRteH2);
export const IconRteH3 = createStandardIcon(SvgIconRteH3);
export const IconRteHighlight = createStandardIcon(SvgIconRteHighlight);
export const IconRteItalic = createStandardIcon(SvgIconRteItalic);
export const IconRteLink = createStandardIcon(SvgIconRteLink);
export const IconRteNumberedList = createStandardIcon(SvgIconRteNumberedList);
export const IconRteQuote = createStandardIcon(SvgIconRteQuote);
export const IconRteClearFormat = createStandardIcon(SvgIconRteClearFormat);
export const IconEditContent = createStandardIcon(SvgIconEditContent);
export const IconSynchronize = createStandardIcon(SvgIconSynchronize);
export const IconRteStrikeThrough = createStandardIcon(SvgIconRteStrikeThrough);
export const IconDate = createStandardIcon(SvgIconDate);
export const IconTime = createStandardIcon(SvgIconTime);

export const IconCheckmark = createStandardIcon(SvgIconCheckmark, {
  stdHW: true,
});
export const IconCancelItemLg = createStandardIcon(SvgIconCancelItem, {
  stdHW: true,
});
export const IconNewTab = createStandardIcon(SvgIconNewTab, { stdHW: true });

export const IconBgAlignBottom = createStandardIcon(SvgIconBgAlignBottom, {
  className: styles.backgroundAlignButton,
});
export const IconBgAlignCenter = createStandardIcon(SvgIconBgAlignCenter, {
  className: styles.backgroundAlignButton,
});
export const IconBgAlignLeft = createStandardIcon(SvgIconBgAlignLeft, {
  className: styles.backgroundAlignButton,
});
export const IconBgAlignMiddle = createStandardIcon(SvgIconBgAlignMiddle, {
  className: styles.backgroundAlignButton,
});
export const IconBgAlignRight = createStandardIcon(SvgIconBgAlignRight, {
  className: styles.backgroundAlignButton,
});
export const IconBgAlignTop = createStandardIcon(SvgIconBgAlignTop, {
  className: styles.backgroundAlignButton,
});
export const IconBgContain = createStandardIcon(SvgIconBgContain, {
  className: styles.backgroundAlignButton,
});
export const IconBgCover = createStandardIcon(SvgIconBgCover, {
  className: styles.backgroundAlignButton,
});
export const IconBgRemove = createStandardIcon(SvgIconBgRemove, {
  className: styles.backgroundAlignButton,
});
export const IconBgSwap = createStandardIcon(SvgIconBgSwap, {
  className: styles.backgroundAlignButton,
});
export const IconBgTile = createStandardIcon(SvgIconBgTile, {
  className: styles.backgroundAlignButton,
});

// export const IconAnimateUp = createStandardIcon(SvgIconAnimateUp);
// export const IconAnimateRight = createStandardIcon(SvgIconAnimateRight);
// export const IconAnimateLeft = createStandardIcon(SvgIconAnimateLeft);
// export const IconAnimateDown = createStandardIcon(SvgIconAnimateDown);
// export const IconAnimateZoomIn = createStandardIcon(SvgIconAnimateZoomIn);
export const IconAnimateUp = createStandardIcon(SvgIconAnimateUp, {
  className: styles.animationSlideButton,
});
export const IconAnimateRight = createStandardIcon(SvgIconAnimateRight, {
  className: styles.animationSlideButton,
});
export const IconAnimateLeft = createStandardIcon(SvgIconAnimateLeft, {
  className: styles.animationSlideButton,
});
export const IconAnimateDown = createStandardIcon(SvgIconAnimateDown, {
  className: styles.animationSlideButton,
});
export const IconAnimateZoomIn = createStandardIcon(SvgIconAnimateZoomIn, {
  className: styles.animationSlideButton,
});
export const IconCircularPlus = createStandardIcon(SvgIconCircularPlus, { stdH: true });
export const IconHelp = createStandardIcon(SvgIconHelp, { stdH: true });

export const IconProperties = createStandardIcon(SvgProperties);

// ----------------------------------------------------------------------------
// Reusable components
// ----------------------------------------------------------------------------

/**
 * Creates and returns a toolbar icon component with a standard interface.
 * @param  {Component} Icon An SVG component. Should be responsive to the CSS
 * `currentColor` property.
 * @param  {Object} [options={}] Options
 * @param  {boolean} [options.stdH] If true, applies a standard height to the icon.
 * @param  {boolean} [options.stdW] If true, applies a standard width to the icon.
 * @param  {boolean} [options.stdHW] If true, applies both a standard height and
 * standard width to the icon.
 * @return {function} Returns a functional component.
 */

type Props = {
  active?: boolean;
  className?: string;
  [key: string]: any;
};

function createStandardIcon(Icon: any, options: { [key: string]: any } = {}) {
  const iconClassName = classnames(styles.icon, options.className, {
    [styles.iconHeight]: options.stdH || options.stdHW,
    [styles.iconWidth]: options.stdW || options.stdHW,
  });

  const IconElement: React.FunctionComponent<Props> = (props) => {
    const { active, className, ...otherProps } = props;

    const combinedClassNames = classnames(className, iconClassName, {
      [styles.active]: active,
    });

    return <Icon className={combinedClassNames} {...otherProps} />;
  };
  return IconElement;
}
