import React, { useState, useEffect, Ref } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { set } from 'object-path-immutable';

import FontFamilySelectionDrawer from '../StyleGuideEditor/FontFamilySelectionDrawer';
import { FontItem } from './FontItem';
import * as integrationsSelectors from '../../../../reducers/integrationsReducer';
import * as fontActions from '../../../../actions/fontActions';

import styles from './Fonts.module.scss';
import { ReactComponent as SvgIconAdd } from '../../../../assets/images/icon-plus.svg';
import { USite } from 'types/USite';

const INDEXES_TO_UPDATE = [
  { button: ['primary', 'secondary', 'action_link'] },
  { list: ['check_list', 'footer_menu', 'header_menu'] },
  {
    typography: [
      'header_1',
      'header_2',
      'header_3',
      'big_quote',
      'paragraph',
      'blog_header_1',
      'blog_header_2',
      'blog_header_3',
      'blog_paragraph',
    ],
  },
];

type Props = {
  site: USite;
  setLoadAllFonts: (loaded: boolean) => void;
  scrollRef: Ref<any>;
  updateOnFontRemove: (styles: any) => void;
  updateFonts: (fontFamiles: any) => void;
  name: string;
};

export default React.memo((props: Props) => {
  const { site, setLoadAllFonts } = props;
  const { fontFamilies } = site.styles;
  const [isOpen, setIsOpen] = useState(false);
  const [selectedFamily, setSelected] = useState<string>();
  const dispatch = useDispatch();
  const typekitIntegration = useSelector(
    // @ts-ignore
    (state) => integrationsSelectors.selectSiteIntegrationsBySlug(state, site.id).typekit
  );
  useEffect(() => {
    dispatch(fontActions.requestAll());
  }, [typekitIntegration]);

  useEffect(() => {
    if (isOpen) setLoadAllFonts(true);
  }, [isOpen]);

  return (
    <>
      <header ref={props.scrollRef} className={styles.fontsStylesHeader}>
        <h1>Fonts</h1>
        <small>Choose up to 8 fonts to use across your site.</small>
        {fontFamilies && fontFamilies.length < 8 && (
          <button
            onClick={() => {
              setSelected(null);
              setIsOpen(true);
            }}
            className="button button-primary button-icon"
          >
            <SvgIconAdd height={15} width={15} />
            <span>New Font</span>
          </button>
        )}
      </header>
      <div className={styles.fontsContainer}>
        {fontFamilies.map((family: string, idx: number) => (
          <FontItem
            isDefault={!Boolean(idx)}
            key={family}
            FontsCount={fontFamilies.length}
            onRemove={() => {
              //  Needs improvement
              /*  Need to update the fonts family indexes so that correct font
                  is assigned when fonts changes are discarded  */
              const remainingFontsFamily = props.site.styles.fontFamilies.filter((c: string) => family !== c);
              const idx = props.site.styles.fontFamilies.indexOf(family);
              const styles = JSON.parse(JSON.stringify(site.styles));
              INDEXES_TO_UPDATE.forEach((section) => {
                const key = Object.keys(section)[0];
                const values = Object.values(section)[0];
                values.forEach((v) => {
                  styles[key][v].font_family_index =
                    styles[key][v].font_family_index >= idx
                      ? styles[key][v].font_family_index - 1
                      : styles[key][v].font_family_index;
                });
              });

              /*  Special cases: update the footer menu category and header
                  action link font family index   */
              styles.list.footer_menu.category_font_family_index =
                styles.list.footer_menu.category_font_family_index >= idx
                  ? styles.list.footer_menu.category_font_family_index - 1
                  : styles.list.footer_menu.category_font_family_index;

              styles.list.header_menu.action_font_family_index =
                styles.list.header_menu.action_font_family_index >= idx
                  ? styles.list.header_menu.action_font_family_index - 1
                  : styles.list.header_menu.action_font_family_index;

              const higherIndexes = Object.entries(site.styles)
                .filter(([key, value]) => key.includes('font_family_index') && value > idx)
                .map(([v]) => v);
              higherIndexes.forEach((key) => (styles[key] = styles[key] - 1));
              if (!higherIndexes.length) {
                const newIdx = (idx + 1) % remainingFontsFamily.length;
                Object.entries(styles)
                  .filter(([key, value]) => key.includes('font_family_index') && value === idx)
                  .map(([key]) => key)
                  .forEach((key) => (styles[key] = newIdx));
              }
              styles.fontFamilies = remainingFontsFamily;
              props.updateOnFontRemove(styles);
            }}
            toggleDrawer={() => {
              setSelected(family);
              setIsOpen(true);
            }}
            family={family}
          />
        ))}
      </div>
      <FontFamilySelectionDrawer
        title="Available fonts"
        isOpen={isOpen}
        close={() => setIsOpen(false)}
        styleGuide={{ fontFamilies }}
        onChange={(family: string) => {
          const idx = props.site.styles.fontFamilies.indexOf(selectedFamily);
          let fontFamilies;
          if (selectedFamily) {
            fontFamilies = set(props.site.styles.fontFamilies, idx, family);
          } else {
            fontFamilies = [...props.site.styles.fontFamilies];
            fontFamilies.push(family);
          }
          props.updateFonts(fontFamilies);
          setIsOpen(false);
        }}
      />
    </>
  );
});
