import QueryString from 'query-string';
import merge from 'lodash/merge';
import { PEXELS_API_BASE_URL } from '../settings';

// ----------------------------------------------------------------------------
// Search endpoints
// ----------------------------------------------------------------------------

// export function searchPhotos(query, page, category) {
//   return category === 'image'
//     ? client.photos.search({ query, page, per_page: 40 })
//     : client.videos.search({ query, page, per_page: 40 });
// }

export function searchPhotos(query, page, category) {
  const path = category === 'image' ? 'v1///search' : 'videos///search';
  return get(path, { query, page, per_page: 40 });
}

// // ----------------------------------------------------------------------------
// // Base methods for getting, posting, & putting
// // ----------------------------------------------------------------------------

function assembleUrl(endpoint, query) {
  // Remove leading slash and add trailing slash to endpoint if necessary.
  endpoint = endpoint.replace(/^\//, '');
  // if (!endsWith(endpoint, '/')) endpoint = `${endpoint}/`;

  // Return assembled url
  return `${PEXELS_API_BASE_URL}${endpoint}?${QueryString.stringify(query)}`;
}

function get(endpoint, data, options) {
  const mergedOptions = merge(
    {
      method: 'GET',
    },
    options
  );
  const url = assembleUrl(endpoint, data);
  return fetchJSON(url, mergedOptions);
}

// // ----------------------------------------------------------------------------
// // Utilities
// // ----------------------------------------------------------------------------

function fetchJSON(url, options) {
  const mergedOptions = merge(
    {
      headers: {
        'content-type': 'application/json',
        Authorization: `JWT ${window.sprkfe.storage.getItem('JWT-TOKEN')}`,
      },
    },
    options
  );
  return fetch(url, mergedOptions).then(parseJSONResponse);
}

function parseJSONResponse(response) {
  return response.json().then((json) => ({
    status: response.status,
    ok: response.ok,
    json,
  }));
}
