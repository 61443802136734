import { schema, normalize } from 'normalizr';

const normalizeList = (function () {
  const blog = new schema.Entity('blogs');

  const blogs = [blog];

  return function normalizePageList(data) {
    return normalize(data, blogs);
  };
})();

export { normalizeList };
