import React from 'react';
import flatten from 'lodash/flatten';
import compact from 'lodash/compact';

interface Errors {
  non_field_errors?: string | string[];
  message?: string | string[];
  msg?: string | string[];
}

type Props = {
  errors: Errors | undefined;
};

const NonFieldErrors: React.FC<Props> = (props: Props) => {
  const { errors } = props;

  if (errors) {
    const messages = compact(flatten([errors.non_field_errors, errors.message, errors.msg]));

    if (messages.length) {
      return (
        <>
          {messages.map((message, i) => (
            <small key={i} className="errorMessage" data-test-id="non-field-errors">
              {message}
            </small>
          ))}
        </>
      );
    }
  }

  return <></>;
};

export default NonFieldErrors;
