import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

const ApplicationFilters = (props) => {
  const dispatch = useDispatch(() => {});

  useEffect(() => {
    function makeRequest() {
      if (props.panel)
        import(`../actions/${props.panel}Actions`).then((actions) => {
          dispatch(actions.fetchList(props.queryParams));
        });
    }
    makeRequest();
  }, [props.queryParams]);

  return props.children ? React.Children.only(this.props.children) : null;
};
ApplicationFilters.displayName = 'ApplicationFilters';

export default ApplicationFilters;
