import React, { useState, useMemo, useEffect } from 'react';
import DocumentTitle from 'components/DocumentTitle';
import { useResources, useStatus } from '../../../lib/redux-resource/hooks';
import { Link, useNavigate, useParams } from 'react-router-dom';
import ComponentThumbnail from 'components/ComponentThumbnail';
import iconUnstackSection from '../../../assets/images/thumbnail-custom-atf@2x.png';
import { ReactComponent as SvgIconAdd } from '../../../assets/images/icon-plus.svg';
import { OnMount, OnUnmount } from 'hooks/mountUnmountHooks';

// import { ReactComponent as PrevSvg } from '../../../assets/images/icon-prev-page.svg';
// import { ReactComponent as NextSvg } from '../../../assets/images/icon-next-page.svg';

import Search from 'components/Search';
import { useSelector } from 'react-redux';
import { selectSiteIntegrationsBySlug } from 'reducers/integrationsReducer';
import useActiveSite from 'hooks/useActiveSite';

import styles from './List.module.scss';

export default function ComponentsList() {
  const { urlSite } = useParams();
  const navigate = useNavigate();
  const siteId = useActiveSite((site) => site.id);

  const [searchQuery, setSearchQuery] = useState('');
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState('');
  // @ts-ignore
  const integrationsBySlug = useSelector((state) => selectSiteIntegrationsBySlug(state, siteId));
  const hasAlgolia =
    integrationsBySlug.algolia && integrationsBySlug.algolia[0] && integrationsBySlug.algolia[0].status === 'active';

  const allComponents = useResources('sectionTypes', 'admin-panel-list', {
    forceFetch: true,
    mergeResources: false,
    mergeMeta: false,
    mergeListIds: false,
  });

  const components = useMemo(() => {
    if (allComponents != null && allComponents.filter) {
      const regexp = debouncedSearchQuery ? new RegExp(debouncedSearchQuery, 'i') : false;
      return allComponents.filter((c: any) => {
        if (!hasAlgolia && c.slug.includes('algolia')) return false;
        if (c.is_deleted) return false;
        if (!['section', 'header', 'footer', 'banner', 'cart', 'checkout', 'modal'].includes(c.component_type))
          return false;
        if (regexp) return regexp.test(c.name);
        return true;
      });
    }
    return [];
  }, [allComponents, debouncedSearchQuery]);

  // const status = useStatus('sectionTypes.requests.fetch.status', true);

  return (
    <>
      <DocumentTitle title="Section Editor" />
      <section>
        <header className={styles.header}>
          <div>
            <h1>Advanced: Section editing</h1>
            <small>
              Build custom sections with HTML/CSS for our no-code editor.{' '}
              <a href="https://www.unstack.com/goto?developer-docs" target="blank">
                Read the docs
              </a>
            </small>
          </div>
          <span className={styles.actions}>
            <Search
              className={styles.search}
              value={searchQuery}
              onChange={setSearchQuery}
              debounceValue={setDebouncedSearchQuery}
              placeholder="Search"
              // containerStyle
              // enableDarkMode
            />
            <Link to={`/${urlSite}/sections/new`} className={`button button-primary button-sm ${styles.newButton}`}>
              <SvgIconAdd height={15} width={15} />
              New Section
            </Link>
          </span>
        </header>
        <div className={styles.components}>
          {components &&
            components.map((component: any) => (
              <div key={component.id} className={styles.component}>
                <ComponentThumbnail
                  key={component.id}
                  onClick={() => navigate(`/${urlSite}/sections/${component.id}/`)}
                  src={component.thumbnail || iconUnstackSection}
                  label={component.name}
                  theme="light"
                  isList
                />
              </div>
            ))}
        </div>
      </section>
    </>
  );
}
