import React, { useEffect } from 'react';

import classNames from 'classnames';
import { NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import useUrlSite from 'hooks/useUrlSite';
import useActiveSite from 'hooks/useActiveSite';
import * as contactActions from 'actions/contactsActions';
import { selectContactsCount } from 'reducers/contactsReducer';
import commaFormatNumber from 'lib/formatters/commaFormatNumber';

import { ReactComponent as SvgIconContacts } from '../../../assets/images/icon-contacts.svg';

import navStyles from 'components/AdminPanel/Nav.module.scss';

export function ContactsNavItem(props: any) {
  const site = useActiveSite();
  const dispatch = useDispatch();
  const siteUrl: string | void = useUrlSite();
  const contactsCount = useSelector(selectContactsCount);

  useEffect(() => {
    dispatch(contactActions.getContactsCount(site.id));
  }, []);

  return (
    <NavLink
      className={({ isActive }) => classNames({ [navStyles.activePanel]: isActive }, navStyles.activeLink)}
      to={`/${siteUrl}/customers`}
      data-test-id="contacts-child"
      onClick={props.onClick}
    >
      <SvgIconContacts />
      <span style={{ marginLeft: '2px' }}>Customers</span>
      {Boolean(contactsCount) && <span className={navStyles.tag}>{commaFormatNumber(contactsCount)}</span>}
    </NavLink>
  );
}
