/* eslint-disable jsx-a11y/anchor-is-valid */

import React from 'react';

import ContentEditable from '../../../Editor/ContentEditable';
import Popover from '../../../base/Popover';
import SmartUrlToolbar from '../../../Editor/SmartUrlToolbar/SmartUrlToolbar';

export default function MenuItem(props) {
  const { item, propPath, siteUpdaters, autoFocus, className, placeholder } = props;

  return (
    <Popover
      target={({ applyFocusHandlers }, ref) => (
        <ContentEditable
          tag="a"
          innerRef={ref}
          className={className}
          value={item.text || ''}
          onInput={(val) => siteUpdaters.set(propPath.concat('text'), val)}
          placeholder={placeholder}
          autoFocus={autoFocus}
          {...applyFocusHandlers}
        />
      )}
      toolbar={({ applyFocusHandlers, applyMouseHandlers }) => (
        <div {...applyMouseHandlers}>
          <SmartUrlToolbar
            onChange={(name, value) => siteUpdaters.set(propPath.concat(name), value)}
            onUpdateUrlProps={(dict) => {
              Object.keys(dict).forEach((key) => {
                siteUpdaters.set(propPath.concat(key), dict[key]);
              });
            }}
            onRemove={() => siteUpdaters.del(propPath)}
            link={item}
            value={item.url || ''}
            {...applyFocusHandlers}
          />
        </div>
      )}
    />
  );
}
