import { NewContactField, NewGenericField } from 'components/AdminPanel/Forms/types';

export const CONTACT_FIELDS: NewContactField[] = [
  {
    id: 101,
    name: 'first_name',
    type: 'text',
    default: 'First name',
    placeholder: 'First name',
    label: '',
    autocomplete: 'given-name',
  },
  {
    id: 102,
    name: 'last_name',
    type: 'text',
    default: 'Last name',
    placeholder: 'Last name',
    label: '',
    autocomplete: 'family-name',
  },
  {
    id: 103,
    name: 'full_name',
    type: 'text',
    default: 'Full name',
    placeholder: 'Full name',
    label: '',
    autocomplete: 'name',
  },
  {
    id: 104,
    name: 'email',
    type: 'email',
    default: 'Email',
    placeholder: 'Email address',
    label: '',
    required: true,
    autocomplete: 'email',
  },
  {
    id: 105,
    name: 'phone',
    type: 'phone',
    default: 'Phone number',
    placeholder: 'Phone number',
    label: '',
    autocomplete: 'tel-national',
  },
  {
    id: 106,
    name: 'address1',
    type: 'text',
    default: 'Address line 1',
    placeholder: 'Address',
    label: '',
    autocomplete: 'address-line1',
  },
  {
    id: 111,
    name: 'address2',
    type: 'text',
    default: 'Address line 2',
    placeholder: 'Address line 2',
    label: '',
    autocomplete: 'address-line2',
  },
  {
    id: 107,
    name: 'city',
    type: 'text',
    default: 'City',
    placeholder: 'City',
    label: '',
    autocomplete: 'address-level2',
  },
  {
    id: 108,
    name: 'state',
    type: 'text',
    default: 'State/Region',
    placeholder: 'State',
    label: '',
    autocomplete: 'address-level1',
  },
  {
    id: 112,
    name: 'zip',
    type: 'text',
    default: 'Zip',
    placeholder: 'Zip',
    label: '',
    autocomplete: 'postal-code',
  },
  {
    id: 109,
    name: 'country',
    type: 'text',
    default: 'Country',
    placeholder: 'Country',
    label: '',
    autocomplete: 'country',
  },
  {
    id: 110,
    name: 'organization',
    type: 'text',
    default: 'Company name',
    placeholder: 'Company name',
    label: '',
    autocomplete: 'organization',
  },
];

export const GENERIC_FIELDS: NewGenericField[] = [
  {
    name: 'text',
    default: 'Text',
    type: 'text',
    placeholder: 'Text',
    label: '',
  },
  {
    name: 'number',
    default: 'Number',
    type: 'number',
    placeholder: 'Amount',
    label: '',
  },
  {
    name: 'checkbox',
    default: 'Checkbox',
    type: 'checkbox',
    placeholder: 'Yes',
    label: '',
  },
  {
    name: 'select',
    default: 'Select',
    options: [
      ['Option 1', 'Option 1'],
      ['Option 2', 'Option 2'],
    ],
    type: 'select',
    placeholder: 'Select',
    label: '',
  },
  {
    name: 'multi-line',
    default: 'Multi-line Text',
    type: 'textarea',
    placeholder: 'Response',
    label: '',
  },
  {
    name: 'radio',
    default: 'Radio',
    options: [
      ['Yes', 'Yes'],
      ['No', 'No'],
    ],
    type: 'radio',
    placeholder: 'Yes',
    label: '',
  },
  {
    name: 'date',
    default: 'Date Picker',
    type: 'date',
    placeholder: 'Date',
    label: '',
  },
  {
    name: 'hidden',
    default: 'Hidden',
    type: 'hidden',
    initial: '',
  },
  // {
  //   name: 'file',
  //   default: 'File',
  //   type: 'file',
  //   placeholder: 'Upload File',
  //   label: '',
  // },
];

export const FIELD_NAME_MAPPER = {
  textarea: 'message',
  radio: 'radio',
  text: 'text',
  number: 'number',
  checkbox: 'checkbox',
  select: 'select',
  date: 'date',
};
