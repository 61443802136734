import { call, put, all, takeLatest, select } from 'redux-saga/effects';
import * as api from '../services/spark-api';
import * as types from '../actions/actionTypes';
import * as currencyActions from '../actions/currencyActions';
import * as currencySelectors from '../reducers/currencyReducer';

function* requestIfNeeded(action) {
  const isFetching = yield select(currencySelectors.selectIsFetching);
  if (isFetching === null) {
    yield put(currencyActions.request());
  }
}

function* request() {
  try {
    const response = yield call(api.getCurrencies);
    if (response.ok) yield put(currencyActions.requestSuccess(response.json));
  } catch (e) {
    console.error(e);
  }
}

function* tasksSaga() {
  yield all([
    takeLatest(types.CURRENCY_REQUEST_IF_NEEDED, requestIfNeeded),
    takeLatest(types.CURRENCY_REQUEST_ALL, request),
  ]);
}

export default tasksSaga;
