/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useCallback, useMemo, useState } from 'react';

import { SearchField } from './SearchField';
import { BaseListView } from './BaseListView';
import Spinner from 'components/base/Spinner';
import SideDrawer from 'components/base/SideDrawer';
import { SelectedItemView } from './SelectedItemView';

export default function EditDrawer(props) {
  const {
    isOpen,
    onChange,
    value,
    products,
    collection,
    collectionInfo,
    min,
    max,
    avoidBackdrop,
    isFetching,
    templateData,
    isTemplateSettings,
    confirmSelections,
    itemType,
  } = props;

  const [searchData, setSearchData] = useState({});
  const [placeholder, setPlaceholder] = useState('');
  const [isSelecting, setIsSelecting] = useState(false);

  const close = () => {
    setPlaceholder('');
    setIsSelecting(false);
    setSearchData({});
    props.close();
  };

  const confirmTemplateItems = useCallback(() => {
    confirmSelections();
    close();
  }, [confirmSelections]);

  const isPLP = useMemo(() => !!value && value?.filters?.collection === '{{ category.id }}', [value]);

  return (
    <SideDrawer avoidBackdrop={avoidBackdrop} isOpen={isOpen} title="Choose products" close={close}>
      {isFetching ? (
        <Spinner style={{ margin: 'auto' }} className="fixed" />
      ) : !!(value && !isSelecting && !isPLP) ? (
        <SelectedItemView
          onChange={onChange}
          setIsSelecting={setIsSelecting}
          value={value}
          data={isTemplateSettings ? [templateData.categories, templateData.products].flat() : collection || products}
          collectionInfo={collectionInfo}
          min={min}
          max={max}
          isTemplateSettings={isTemplateSettings}
          confirmTemplateItems={confirmTemplateItems}
          close={close}
          itemType={itemType}
        />
      ) : (
        <>
          <SearchField searchData={searchData} setSearchData={setSearchData} placeholder={placeholder} />
          <BaseListView
            setIsSelecting={setIsSelecting}
            searchData={searchData}
            setSearchData={setSearchData}
            setPlaceholder={setPlaceholder}
            onChange={onChange}
            value={value}
            isTemplateSettings={isTemplateSettings}
          />
          {isTemplateSettings && !value && (
            <form
              onSubmit={(e) => {
                e.preventDefault();
                close();
              }}
            >
              <input className="button button-default button-full" value="Go back" type="submit" />
            </form>
          )}
        </>
      )}
    </SideDrawer>
  );
}
