import store from '../store';
import { downloadProgress } from '../actions/mediaActions';

export function downloadFile(url) {
  return fetch(url, {
    method: 'GET',
  })
    .then((response) => {
      const reader = response.body.getReader();
      return new ReadableStream({
        start(controller) {
          return pump();
          function pump() {
            return reader.read().then(({ done, value }) => {
              // When no more data needs to be consumed, close the stream
              if (done) {
                controller.close();
                return;
              }
              // Enqueue the next data chunk into our target stream
              controller.enqueue(value);
              return pump();
            });
          }
        },
      });
    })
    .then((stream) => new Response(stream))
    .then((response) => response.blob());
}

//  Having progress bar added to other media libraries were causing abnormal
//  behavior. SO for now we are only using it for pexels, but will expand it
//  to other libraries for sure
export function downloadFileWithProgress(url) {
  return fetch(url, {
    method: 'GET',
  })
    .then((response) => {
      const reader = response.body.getReader();
      const contentLength = +response.headers.get('Content-Length');
      let receivedLength = 0; // received that many bytes at the moment
      return new ReadableStream({
        start(controller) {
          return pump();
          function pump() {
            return reader.read().then(({ done, value }) => {
              receivedLength += value.length;
              store.dispatch(downloadProgress(receivedLength / contentLength));

              // When no more data needs to be consumed, close the stream
              if (done || receivedLength / contentLength === 1) {
                controller.close();
                return;
              }
              // Enqueue the next data chunk into our target stream
              controller.enqueue(value);
              return pump();
            });
          }
        },
      });
    })
    .then((stream) => new Response(stream))
    .then((response) => response.blob());
}
