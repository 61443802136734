import React from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';

import { ReactComponent as SvgIconTrash } from '../../../assets/images/icon-remove-field.svg';

import { removeNestedProp } from '../../../actions/sectionActions';

import styles from './SectionItemsOptions.module.scss';

const mapDispatchToProps = {
  removeItem: removeNestedProp,
};

export default connect(
  null,
  mapDispatchToProps
)(
  ({
    index,
    sectionId,
    path,
    removeItem,
    DragHandle,
    brightnessTheme,
    reposition,
    decreaseDimensions,
    iconHeight = 11,
    iconWidth = 12,
    showTrash = true,
    repositionTop,
    style,
  }) => (
    <div
      style={style}
      className={classnames(styles.optionsContainer, {
        [styles.lightBg]: !brightnessTheme || brightnessTheme.includes('light'),
        [styles.reposition]: reposition,
        [styles.decreaseDimensions]: decreaseDimensions,
        [styles.repositionTop]: repositionTop,
      })}
    >
      {DragHandle}
      {showTrash && (
        <div onClick={() => removeItem(sectionId, path, index)}>
          <SvgIconTrash height={iconHeight} width={iconWidth} />
        </div>
      )}
    </div>
  )
);
