import * as types from './actionTypes';

export function open(blogId, ops = [], errors = {}) {
  return {
    type: types.BLOG_SETTINGS_OPEN,
    payload: { blogId, ops, errors },
  };
}

export function close() {
  return {
    type: types.BLOG_SETTINGS_CLOSE,
  };
}

export function addOps(ops) {
  return {
    type: types.BLOG_SETTINGS_ADD_OPS,
    payload: { ops },
  };
}

export function save() {
  return {
    type: types.BLOG_SETTINGS_SAVE,
  };
}

// export function publish() {
//   return {
//     type: types.BLOG_SETTINGS_PUBLISH,
//   };
// }
