import React, { useMemo, useState, useEffect } from 'react';

import groupBy from 'lodash/groupBy';
import Drawer from './Dropdown';
import classNames from 'classnames';
import { update, set } from 'object-path-immutable';

import { Column, Condition, Conditional } from './types';

import { ReactComponent as SvgIconForm } from 'assets/images/icon-forms(2).svg';
import { ReactComponent as SvgIconDollar } from 'assets/images/icon-dollar.svg';
import { ReactComponent as SvgIconSettings } from 'assets/images/icon-setting.svg';
import { ReactComponent as SvgIconContact } from 'assets/images/icon-contacts.svg';
import { ReactComponent as SvgIconBag } from 'assets/images/icon-shopping-bag.svg';
import { ReactComponent as SvgIconDatabase } from 'assets/images/icon-database.svg';
import { ReactComponent as SvgIconArrow } from 'assets/images/icon-arrow.svg';
import { ReactComponent as SvgIconSegment } from 'assets/images/icon-segment.svg';

import styles from './Conditions.module.scss';
import { USectionDevices } from 'types/USection';
import { getDeviceTypeToSaveTo } from 'components/unstack-components/Component/util/helpers/deviceHelper';
import { getDevice } from 'reducers/uiReducer';
import { useSelector } from 'react-redux';

const ICONS: any = {
  forms: <SvgIconForm height={20} width={20} />,
  params: <SvgIconSettings height={20} width={20} />,
  contact: <SvgIconContact height={20} width={20} />,
  database: <SvgIconDatabase height={20} width={20} />,
  subscription: <SvgIconDollar height={20} width={20} />,
  visit: <SvgIconArrow height={20} width={20} />,
  purchases: <SvgIconBag height={20} width={20} />,
  klaviyo: <SvgIconSegment height={20} width={20} />,
};

interface Props {
  idx: number;
  value: Conditional;
  condition: Condition;
  fields: Array<Column>;
  dataPropertyEngine: any;
  contactPropertyEngine: any;
  segmentPropertyEngine: any;
  onChange: (content: USectionDevices, key?: string, multi?: boolean) => void;
  isSectionActive: boolean;
  content: USectionDevices;
  contentKey: string;
}

export default function (props: Props) {
  const {
    idx,
    value,
    fields,
    onChange,
    condition,
    dataPropertyEngine,
    contactPropertyEngine,
    segmentPropertyEngine,
    isSectionActive,
    content,
    contentKey,
  } = props;

  const device = useSelector(getDevice);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [query, setQuery] = useState(condition?.label || condition?.property || '');
  const filteredItems: Array<Column> = useMemo(
    () =>
      query
        ? [
            ...dataPropertyEngine.search(query),
            ...contactPropertyEngine.search(query),
            ...segmentPropertyEngine.search(query),
          ]
        : fields || [],
    [query, dataPropertyEngine, fields]
  );
  const [cursor, setCursor] = useState(0);
  const [grouped, setGrouped] = useState(undefined);
  const [hovered, setHovered] = useState(undefined);
  const [groupedProperties, setGroupedProperties] = useState({});

  useEffect(() => {
    setGroupedProperties(groupBy(filteredItems, (field) => field.type || 'database'));
  }, [filteredItems]);

  useEffect(() => {
    const groupOrdered = Object.entries(groupedProperties).map((element) => element[1]);
    setGrouped(groupOrdered.flat(1));
  }, [groupedProperties]);

  useEffect(() => {
    if (grouped?.length && hovered) {
      setCursor(grouped.indexOf(hovered));
    }
  }, [hovered]);

  const handleOpen = () => {
    setQuery('');
    setIsOpen(true);
    const index =
      fields.findIndex((element) => element.slug === condition?.property) !== -1
        ? fields.findIndex((element) => element.slug === condition?.property)
        : condition?.label
        ? fields.findIndex((element) => element.label === condition?.label)
        : 0;
    setCursor(index !== -1 ? index : 0);
  };

  const handleClose = () => {
    setQuery(condition?.label || condition?.property || '');
    setIsOpen(false);
  };

  const handleChange = (item: any) => {
    const {
      slug: property,
      data_type,
      label,
      smartInputType,
      isUtm,
      id,
      type = 'database',
      defaultOperator: operator = '',
    } = item;
    const newValue = update(value, `conditional.conditions.${idx}`, (condition) => ({
      ...condition,
      property,
      data_type,
      operator,
      is_positive_matching: true,
      smartInputType,
      isUtm,
      value: '',
      item: undefined,
      label,
      id,
      type,
    }));
    const splitKey = contentKey.split('.');
    onChange(
      set(content, [getDeviceTypeToSaveTo(device, true), ...splitKey.slice(1)], newValue),
      `content.${splitKey[0]}`
    );
  };

  return (
    <div className={classNames(styles.inputContainer, styles.isDropdown)}>
      {(query === condition?.label || query === condition?.property) && ICONS[condition.type]}
      <input
        autoFocus={isSectionActive}
        onChange={(e) => {
          if (e.target.value !== ' ') {
            setQuery(e.target.value);
            setCursor(0);
          }
        }}
        className={classNames({
          [styles.isSmartInput]: query === condition?.label || query === condition?.property,
        })}
        value={query}
        type="text"
        aria-label="choose condition"
        placeholder="Condition"
        onFocus={(e) => {
          // @ts-ignore
          if (!isOpen && e?.nativeEvent?.sourceCapabilities) handleOpen();
        }}
        onClick={() => {
          handleOpen();
        }}
        onKeyDown={(e) => {
          if (e.keyCode === 13) {
            if (isOpen) {
              handleChange(grouped[cursor]);
              handleClose();
            } else handleOpen();
          } else if (e.keyCode === 9) {
            if (isOpen && !(condition?.label === grouped[cursor].label)) {
              handleChange(grouped[cursor]);
              handleClose();
            }
          } else if (e.keyCode === 27) {
            handleClose();
          } else if (e.keyCode === 32) {
            if (!isOpen) handleOpen();
          } else if (e.keyCode === 35) {
            if (isOpen) {
              e.preventDefault();
              setCursor(filteredItems.length - 1);
            }
          } else if (e.keyCode === 36) {
            if (isOpen) {
              e.preventDefault();
              setCursor(0);
            }
          } else if (e.keyCode === 38) {
            if (!isOpen) handleOpen();
            else if (cursor > 0) setCursor(cursor - 1);
            else e.preventDefault();
          } else if (e.keyCode === 40) {
            if (!isOpen) handleOpen();
            else if (cursor < filteredItems.length - 1) setCursor(cursor + 1);
            else e.preventDefault();
          }
        }}
        onBlur={() => {
          handleClose();
          if (filteredItems.length && !condition.property && query) {
            handleChange(filteredItems[0]);
          }
        }}
      />
      <Drawer
        isOpen={isOpen && filteredItems.length > 0}
        fields={filteredItems}
        cursor={cursor}
        setHovered={setHovered}
        handleClose={handleClose}
        query={query}
        onChange={({
          slug: property,
          data_type,
          label,
          smartInputType,
          isUtm,
          id,
          type = 'database',
          defaultOperator: operator = '',
        }) => {
          const newValue = update(value, `conditional.conditions.${idx}`, (condition) => ({
            ...condition,
            property,
            data_type,
            operator,
            item: undefined,
            is_positive_matching: true,
            smartInputType,
            isUtm,
            value: '',
            label,
            id,
            type,
            ...(isUtm && { visit: 'any' }),
          }));
          const splitKey = contentKey.split('.');
          onChange(
            set(content, [getDeviceTypeToSaveTo(device, true), ...splitKey.slice(1)], newValue),
            `content.${splitKey[0]}`
          );
        }}
      />
    </div>
  );
}
