import { createSelector } from 'reselect';
import * as types from '../actions/actionTypes';

export default (state = {}, action) => {
  switch (action.type) {
    case types.BUTTONS_SUBMITTING_STATE:
      return {
        ...state,
        isSubmitting: action.payload,
        ...action.event,
      };
    case types.BUTTONS_RESET_STATE:
      return {};

    default:
      return state;
  }
};

export function selectUiState(globalState) {
  return globalState.uiSubmitButton;
}

export const selectSubmissionState = createSelector(selectUiState, (ui) => ui.isSubmitting);

export const selectSubmissionStateAndEvent = createSelector(selectUiState, (ui) => ui);
