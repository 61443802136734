import { useState, useEffect } from 'react';
import useActiveSite from '../../../hooks/useActiveSite';
import { getForms } from '../../../services/spark-api';

export function useForms(page = 1, search, order_by, refetchFlag) {
  const [isFetching, setIsFetching] = useState();
  const [data, setData] = useState();
  const isLoading = isFetching === true || isFetching === undefined;
  const siteId = useActiveSite((site) => site.id);

  useEffect(() => {
    async function fetchData() {
      setIsFetching(true);

      const response = await getForms(siteId, { page, search, order_by });

      setIsFetching(false);
      setData({
        count: response.json?.count,
        results: response.json?.results,
      });
    }

    fetchData();
  }, [page, search, order_by, refetchFlag]); // eslint-disable-line react-hooks/exhaustive-deps

  return [data, isLoading];
}
