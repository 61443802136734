import React from 'react';
import SimplePage from './SimplePage';

export default () => {
  return (
    <SimplePage>
      <h1>404</h1>
      <p>Page Not Found</p>
    </SimplePage>
  );
};
