import classNames from 'classnames';
import useUrlSite from 'hooks/useUrlSite';
import React, { useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import { selectFontNameWithFallback } from 'reducers/fontsReducer';

import { selectHasPermission } from 'reducers/policyReducer';

import { ReactComponent as SvgIconArrow } from 'assets/images/icon-cheveron-left.svg';

import styles from './QuillToolbar.module.scss';

export default ({ fonts, onSelect, value }) => {
  const [drawerIsOpen, setDrawerIsOpen] = useState(false);
  const hasPermission = useSelector(selectHasPermission);
  const urlSite = useUrlSite();
  const fontName = useCallback(
    (family) => {
      return useSelector((state) => selectFontNameWithFallback(state, family));
    },
    [value]
  );

  return (
    <div onClick={() => setDrawerIsOpen(!drawerIsOpen)} className={classNames(styles.FontPicker, styles.extraPadding)}>
      <span>{fontName(value) || 'Change font'}</span>
      <SvgIconArrow className={styles.ArrowDown} />
      <CSSTransition in={drawerIsOpen} timeout={141.59} classNames={styles} mountOnEnter unmountOnExit>
        <div className={classNames(styles.Drawer, styles.leftZero)}>
          <ul>
            {fonts.map((font) => (
              <li
                key={font}
                className={styles.FontChoice}
                onClick={() => {
                  setDrawerIsOpen(false);
                  onSelect(font);
                }}
              >
                {fontName(font)}
              </li>
            ))}
            {hasPermission('Site:update', 'role') && (
              <li className={classNames(styles.FontChoice, styles.lastItem)}>
                <a href={`/${urlSite}/styles#tab=fonts`} target="_blank">
                  Manage Fonts...
                </a>
              </li>
            )}
          </ul>
        </div>
      </CSSTransition>
    </div>
  );
};
