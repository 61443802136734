import * as actionTypes from './actionTypes';

export function open(sectionId) {
  return {
    type: actionTypes.UI_NEW_CONTENT_TEST_SETTINGS_OPEN,
    payload: { sectionId },
  };
}

export function close() {
  return {
    type: actionTypes.UI_NEW_CONTENT_TEST_SETTINGS_CLOSE,
  };
}

export function update(payload) {
  return {
    type: actionTypes.UI_NEW_CONTENT_TEST_SETTINGS_UPDATE,
    payload,
  };
}

export function updateWeight(index, value) {
  return {
    type: actionTypes.UI_NEW_CONTENT_TEST_SETTINGS_UPDATE_WEIGHT,
    payload: { index, value },
  };
}

export function updateConfidence(value) {
  return {
    type: actionTypes.UI_NEW_CONTENT_TEST_SETTINGS_UPDATE_CONFIDENCE,
    payload: value,
  };
}

export function requestStart() {
  return {
    type: actionTypes.UI_NEW_CONTENT_TEST_SETTINGS_REQUEST_START,
  };
}

export function receiveStartSuccess() {
  return {
    type: actionTypes.UI_NEW_CONTENT_TEST_SETTINGS_RECEIVE_START_SUCCESS,
  };
}

export function receiveStartError(errors) {
  return {
    type: actionTypes.UI_NEW_CONTENT_TEST_SETTINGS_RECEIVE_START_ERROR,
    payload: { errors },
  };
}
