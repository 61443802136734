import '../../../assets/shared/ts/plugins/utils/UPluginManager';
import '../../../assets/shared/ts/plugins/utils/system';
import '../../../assets/shared/ts/plugins/utils/named_register';
import '../../../assets/shared/styles/plugins.css';
import '../../../assets/shared/ts/plugins/module-import-map';

export function ClearPlugins() {
  const sprk: any = (window as any).sprk || {};
  if (sprk.UPluginManager) {
    const allowedKeys = ['importmap:ready', 'modules:mapimported', 'templates:initialized'];
    sprk.evs = Object.fromEntries(Object.entries(sprk.evs).filter(([key]) => allowedKeys.includes(key)));
    sprk.UPluginManager.instances = [];
  }
}

export function ReinitPlugins() {
  const sprk: any = (window as any).sprk || {};
  if (sprk.UPluginManager) {
    sprk.UPluginManager.init();
  }
}

export function ReinitPlugin(pluginName: string, pluginId: string, pluginSection: string) {
  const sprk: any = (window as any).sprk || {};
  if (sprk.UPluginManager) {
    const instance = sprk.UPluginManager.instances[pluginName].get(pluginId, pluginSection);
    instance?.init();
  }
}

export function ReinitPluginType(pluginName: string) {
  const sprk: any = (window as any).sprk || {};
  if (sprk.UPluginManager) {
    sprk.UPluginManager.instances[pluginName].init();
  }
}
