import React, { useEffect, useState, memo } from 'react';
import { useSelector } from 'react-redux';

import FormControl from 'components/base/FormHelpers/FormControl';
import { FormData } from './types';
import * as sitesSelectors from 'reducers/sitesReducer';

interface MaxWidthOptionsProps {
  styles?: FormData['content']['styles'];
  onUpdate: (styles: FormData['content']['styles']) => void;
}

const MaxWidthOptions = ({ onUpdate, styles }: MaxWidthOptionsProps) => {
  const { styles: activeStyles = { form_width: '400' } } = useSelector(sitesSelectors.selectActiveSite);

  const { form_width: currentFormWidth = '400' } = activeStyles;
  const savedCustomWidth = styles?.maxWidth || `${currentFormWidth}px`;

  const options = [
    {
      label: `Style guide default (${currentFormWidth} pixels)`,
      value: 'default',
    },
    { label: 'Custom...', value: 'custom' },
  ];

  const [selectedOption, setSelectedOption] = useState(styles?.maxWidth ? 'custom' : 'default');
  const [customValue, setCustomValue] = useState(savedCustomWidth.split('px')[0]);

  useEffect(() => {
    if (selectedOption === 'default') {
      setCustomValue(currentFormWidth);
    } else {
      setCustomValue(savedCustomWidth.split('px')[0]);
    }
  }, [selectedOption]);

  useEffect(() => {
    if (selectedOption === 'default') {
      onUpdate({});
    } else if (customValue) {
      onUpdate({ maxWidth: `${customValue}px` });
    } else {
      onUpdate({ maxWidth: savedCustomWidth });
    }
  }, [customValue]);

  type inputEvent = React.ChangeEvent<HTMLInputElement>;

  const handleOptionChange = (event: inputEvent) => setSelectedOption(event.target.value);

  const handleCustomMaxWidthChange = (event: inputEvent) => {
    const element: HTMLInputElement = event.target;
    const { value } = element;

    if (!Number.isNaN(Number(value))) {
      setCustomValue(value.trim());
    }
  };

  return (
    <>
      <fieldset>
        <label htmlFor="max_width_input">Max Width</label>
        <FormControl
          id="max_width_input"
          type="text"
          name="max_width"
          tag="select"
          value={selectedOption}
          onChange={handleOptionChange}
        >
          {options.map((option) => (
            <option key={option.label} value={option.value}>
              {option.label}
            </option>
          ))}
        </FormControl>
        {selectedOption === 'custom' && (
          <input type="text" name="custom_max_width" value={customValue} onChange={handleCustomMaxWidthChange} />
        )}
      </fieldset>
    </>
  );
};

export default memo(MaxWidthOptions);
