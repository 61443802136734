import React from 'react';

import mergeRefs from '../../lib/mergeRefs';
import classnames from 'classnames';
// import isEmpty from 'lodash/isEmpty';

class ContentEditable extends React.Component {
  ref = React.createRef();

  componentDidMount() {
    if (this.ref.current) {
      // Manually trigger focus if props.autoFocus is true
      if (this.props.autoFocus) this.ref.current.focus();

      // Extend Element.value and Element.name getter & setter attributes
      // that are otherwise only available on some tags
      Object.defineProperty(this.ref.current, 'value', {
        set: function (value) {
          return (this.innerText = value);
        },
        get: function () {
          return this.innerText;
        },
      });
      Object.defineProperty(this.ref.current, 'name', {
        set: function (name) {
          return this.setAttribute('name', name);
        },
        get: function () {
          return this.getAttribute('name');
        },
      });
    }
  }

  shouldComponentUpdate(nextProps) {
    return nextProps.value !== this.ref.current.innerText;
  }

  componentDidUpdate(prevProps) {
    this.ref.current.innerText = this.props.value || '';
  }

  handleInput = (e) => {
    const onUpdate = this.props.onChange || this.props.onInput;
    onUpdate(this.ref.current.innerText);
  };

  handlePaste = (e) => {
    e.preventDefault();
    var text = (e.originalEvent || e).clipboardData.getData('text/plain');
    document.getElementById('editorIframe')
      ? document.getElementById('editorIframe').contentWindow.document.execCommand('insertHTML', false, text)
      : document.execCommand('insertHTML', false, text);
  };

  handleKeyDown = (e) => {
    if (e.which === 13) e.preventDefault();
    if (this.props.onKeyDown) this.props.onKeyDown(e);
  };

  render() {
    const { tag, value, placeholder, onInput, autoFocus, className, innerRef, ...otherProps } = this.props;

    const CustomTag = tag;

    const combinedClassNames = classnames(className, 'spark-contenteditable');

    const refs = innerRef ? mergeRefs(this.ref, innerRef) : this.ref;

    // const empty = isEmpty(value).toString()

    return (
      <CustomTag
        // empty={empty}
        className={combinedClassNames}
        value={value}
        onInput={this.handleInput}
        onPaste={this.handlePaste}
        placeholder={placeholder}
        autoFocus={autoFocus}
        contentEditable
        suppressContentEditableWarning={true}
        {...otherProps}
        onKeyDown={this.handleKeyDown}
        ref={refs}
      >
        {value}
      </CustomTag>
    );
  }
}

// export default ContentEditable;

export default React.forwardRef((props, ref) => <ContentEditable innerRef={ref} {...props} />);
