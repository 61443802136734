import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as actions from '../../../actions/blogActions';
import { selectWinningVariantId, selectVariantMetaDataById } from '../../../reducers/contentTestsReducer';

import { IconCheckedCircleGreen } from '../Toolbars2/icons';

import SideDrawer from '../../base/SideDrawer';

import idx from 'lodash/get';

export default function NewContentTestSettings(props) {
  return (
    <SideDrawer title="A/B test in progress" isOpen={props.isOpen} close={props.close}>
      {props.contentTest && <Form {...props} />}
    </SideDrawer>
  );
}

function Form(props) {
  const [variantId, setVariant] = useState({});

  const { contentTest, variants } = props;
  const dispatch = useDispatch();

  function terminateAndPublish(e) {
    e.preventDefault();
    if (!variantId) return;
    dispatch(actions.requestEnd(contentTest));
    props.setWinningVariant(variants.find((v) => v.id === variantId));
    window.location.reload();
    props.close();
  }

  const contentTestName = contentTest ? contentTest.name : '';
  const winningVariantId = useSelector((state) => selectWinningVariantId(state, contentTest.id));
  const variantMetadataById = useSelector((state) => selectVariantMetaDataById(state, contentTest.id));

  return (
    <form>
      <fieldset>
        <label>Name</label>
        <p>{contentTestName}</p>
      </fieldset>
      <fieldset>
        <label>Pick winner</label>
        <div className="radio-group">
          {contentTest.variants.map((variant) => {
            const conversionRate = idx(variantMetadataById, [variant.id, 'conversion_rate'], 0);
            const conversionRatePercent = (conversionRate * 100).toFixed(1);

            return (
              <label key={variant.id}>
                <input
                  type="radio"
                  checked={variant.id === variantId}
                  onChange={() => setVariant(variant.id)}
                  readOnly
                />
                <div className="title">
                  {variant.name} &nbsp;
                  <small>{conversionRatePercent}%</small> &nbsp;
                  {variant.id === winningVariantId && <IconCheckedCircleGreen />}
                </div>
              </label>
            );
          })}
        </div>
      </fieldset>
      <fieldset>
        <input
          type="submit"
          className="button button-primary button-full"
          onClick={terminateAndPublish}
          value="End test and publish page"
          disabled={!variantId}
        />
        {/* <button
          type="submit"
          className="button button-full"
          onClick={terminateAndDontPublish}
          disabled={!selectedVariantId}
        >
          End test and don't publish page
        </button> */}
      </fieldset>
      <p>
        <small>Pick a winner to end the test.</small>
      </p>
    </form>
  );
}
