import Quill from '../quill-1.3.7/quill';
import isString from 'lodash/isString';

const Inline = Quill.import('blots/inline');

const SANITIZED_URL = 'about:blank';
const PROTOCOL_WHITELIST = [
  'http',
  'https',
  'mailto',
  'tel',
  'page',
  'blog',
  'article',
  'media',
  'product',
  'form',
  'coupon',
];

function sanitize(url) {
  let anchor = document.createElement('a');
  anchor.href = url;
  let protocol = anchor.href.slice(0, anchor.href.indexOf(':'));

  if (PROTOCOL_WHITELIST.indexOf(protocol) > -1) return url;
  else return SANITIZED_URL;
}

function applyFormatToNode(value, node) {
  let href, target;
  if (isString(value)) {
    href = value;
  } else {
    ({ href, target } = value);
  }

  if (value?.behavior) node.dataset.behavior = value?.behavior;

  const sanitizedHref = sanitize(href);
  node.setAttribute('href', sanitizedHref);

  if (target) {
    node.setAttribute('target', target);
    if (target === '_blank') node.setAttribute('rel', 'noopener noreferrer');
  } else {
    node.removeAttribute('target');
    node.removeAttribute('rel');
  }

  return node;
}

class Link extends Inline {
  static create(value) {
    const node = super.create(value);
    return applyFormatToNode(value, node);
  }

  static formats(domNode) {
    return {
      href: domNode.getAttribute('href'),
      target: domNode.getAttribute('target'),
      behavior: domNode.getAttribute('data-behavior'),
    };
  }

  format(name, value) {
    if (name !== this.statics.blotName || !value) return super.format(name, value);
    applyFormatToNode(value, this.domNode);
  }
}

Link.blotName = 'link';
Link.tagName = 'A';

export default Link;
