import React, { useEffect, useState } from 'react';
import get from 'lodash/get';
import useActiveSite from 'hooks/useActiveSite';
import * as integrationSelectors from 'reducers/integrationsReducer';
import { useSelector } from 'react-redux';
import ConnectModal from './ConnectModal';
import Settings from './Settings';
const SalesForceDrawer = (props) => {
  const { name, icon, authenticator, type, isOpen, close, integrationSlug } = props;
  const siteId = useActiveSite((site) => site.id);
  const integration = get(
    useSelector((state) => integrationSelectors.selectSiteIntegrationsBySlug(state, siteId, integrationSlug)),
    0
  );
  const isEmpty = !integration;
  useEffect(() => {
    window.addEventListener(
      'message',
      (event) => {
        if (window.location.origin === event.origin && event.source.location.pathname === '/integrations/oauth') {
          close();
        }
      },
      false
    );
  }, []);
  const { config } = integration || {};
  return (
    <div>
      {!isEmpty ? (
        <Settings isOpen={isOpen} close={close} config={config} id={integration.id} />
      ) : (
        <ConnectModal
          siteId={siteId}
          isOpen={isOpen}
          close={close}
          authenticator={authenticator}
          icon={icon}
          type={type}
          name={name}
          integrationSlug={integrationSlug}
        />
      )}
    </div>
  );
};

export default SalesForceDrawer;
