import React from 'react';
import { useSelector } from 'react-redux';
import useBooleanState from '../../hooks/useBooleanState';
import * as siteSelectors from '../../reducers/sitesReducer';
import { selectAccount } from 'reducers/accountReducer';

import { selectOwnerStatus } from 'reducers/accountUsersReducer';

import idx from 'lodash/get';
import classnames from 'classnames';

import logoBling from '../../assets/images/unstack-mark.svg';
import { ReactComponent as ChevronLeftSvg } from '../../assets/images/select.svg';

import AuthenticatedMediaImg from '../../containers/AuthenticatedMediaImg';
import SiteList from '../SiteList';
import { CSSTransition } from 'react-transition-group';

import styles from './SitePicker.module.scss';
import { useParams } from 'react-router-dom';

function SitePicker() {
  const { urlSite } = useParams();
  // @ts-ignore
  const activeSite = useSelector((state) => siteSelectors.selectSiteByDomain(state, urlSite));
  const account = useSelector(selectAccount);
  const fullDomain = siteSelectors.getFullDomainFromSite(activeSite);
  const sites = useSelector(siteSelectors.selectAllSites);

  const [isOpen, open, close] = useBooleanState(false);

  const ownerStatus = useSelector(selectOwnerStatus);

  const favicon = idx(activeSite, 'elements.favicon', {}).src || logoBling;

  return (
    <section
      className={classnames(styles.SitePicker, {
        [styles.listOpen]: isOpen,
      })}
      onMouseLeave={() => close()}
    >
      <div className={styles.dropdownWrapper} onMouseEnter={() => open()}>
        <ChevronLeftSvg className={styles.chevron} />
        <span className={styles.faviconContainer}>
          {favicon && <AuthenticatedMediaImg className={styles.favicon} src={favicon} />}
        </span>
        {activeSite.name}
      </div>

      <CSSTransition
        in={isOpen && (sites.length > 1 || ownerStatus === 'active')}
        timeout={200}
        classNames={styles}
        mountOnEnter
        unmountOnExit
      >
        {() => <SiteList sites={sites} className={styles.SiteList} urlSite={urlSite} account={account} />}
      </CSSTransition>
    </section>
  );
}

export default SitePicker;
