import React from 'react';
import { connect } from 'react-redux';
import { selectParseSmartUrl } from '../reducers/uiReducer';
import { selectOne } from '../reducers/mediaReducer';
import { MEDIA_URL_REGEXP } from '../constants';

function mapStateToProps(state, ownProps) {
  const parseSmartUrl = selectParseSmartUrl(state);
  let mediaMatch, src;
  if ((mediaMatch = ownProps.src.match(MEDIA_URL_REGEXP))) {
    const mediaId = mediaMatch[1];
    const media = selectOne(state, mediaId);
    if (media) {
      src = media.category_id === 'icon' || !media.thumbnail ? media.file : media.thumbnail;
    }
  }
  // TODO - SGM - Make this hit / parse the endpoint for a file reference instead of the contents
  return {
    src: src || parseSmartUrl(ownProps.src, true),
  };
}

export default connect(mapStateToProps, {})((props) => <img {...props} />); /* eslint-disable-line jsx-a11y/alt-text */
