import * as actionTypes from '../actions/actionTypes';

import { selectContentTest } from './contentTestsReducer';

// Reducer
// ----------------------------------------------------------------------------

const initState = {
  isOpen: false,
  contentTestId: '3b069a1c-a73e-4ab2-8ceb-752215f34583',
};

export default function uiInProgressContentTestSettingsReducer(state = initState, action) {
  switch (action.type) {
    case actionTypes.UI_IN_PROGRESS_CONTENT_TEST_SETTINGS_OPEN:
      return {
        ...initState,
        isOpen: true,
        contentTestId: action.payload.contentTestId,
      };

    case actionTypes.UI_IN_PROGRESS_CONTENT_TEST_SETTINGS_CLOSE:
      return {
        ...state,
        isOpen: false,
      };

    case actionTypes.UI_IN_PROGRESS_CONTENT_TEST_SETTINGS_UPDATE:
      return {
        ...state,
        ...action.payload,
      };

    // case actionTypes.UI_IN_PROGRESS_CONTENT_TEST_SETTINGS_REQUEST_END:
    //   return {
    //     ...state,
    //   };

    case actionTypes.UI_IN_PROGRESS_CONTENT_TEST_SETTINGS_RECEIVE_END_SUCCESS:
      return {
        ...state,
        isOpen: false,
      };

    // case actionTypes.UI_IN_PROGRESS_CONTENT_TEST_SETTINGS_RECEIVE_END_ERROR:
    //   return {
    //     ...state,
    //   };

    default:
      return state;
  }
}

// Selectors
// ----------------------------------------------------------------------------

export function selectLocalState(globalState) {
  return globalState.uiInProgressContentTestSettings;
}

export function selectProps(globalState) {
  const localState = selectLocalState(globalState);
  const contentTest = selectContentTest(globalState, localState.contentTestId);

  return {
    ...localState,
    contentTest,
  };
}
