import * as types from '../actions/actionTypes';
import { createSelector } from 'reselect';
import union from 'lodash/union';
import cloneDeep from 'lodash/cloneDeep';
import { selectActiveSite } from './sitesReducer';

// ----------------------------------------------------------------------------
// Reducer
// ----------------------------------------------------------------------------

const initState = {
  image: { pagesLoaded: 0, next: true },
  product: { pagesLoaded: 0, next: true },
  icon: { pagesLoaded: 0, next: true },
  pdf: { pagesLoaded: 0, next: true },
  video: { pagesLoaded: 0, next: true },
  byId: {},
  allIds: [],
  unsplash: {
    isFetching: false,
    pageResponse: { results: [] },
  },
  pexels: {
    isFetching: false,
    media: [],
    downloadProgress: null,
  },
  icons8: {
    isFetching: false,
    pageResponse: { icons: [] },
  },
  uploadProgress: null,
  unstackSearchedMedia: {
    byId: {},
    allIds: [],
  },
  dataTableColumns: [],
};

export default function (state = initState, action) {
  switch (action.type) {
    case types.MEDIA_REQUEST_ALL:
      return {
        ...state,
        isFetching: true,
      };

    case types.MEDIA_RECEIVE: {
      const newState = {
        ...state,
        isFetching: false,
        byId: Object.assign({}, state.byId, action.payload),
        allIds: union(state.allIds, Object.keys(action.payload)),
        uploadProgress: null,
      };

      if (action.receivedAt) newState.lastUpdated = action.receivedAt;

      return newState;
    }

    case types.MEDIA_REQUEST_PAGINATED_LIST:
      return {
        ...state,
        isFetching: true,
      };

    case types.MEDIA_RECEIVED_PAGE: {
      const { media, category, page } = action.payload;
      const newState = {
        ...state,
        isFetching: false,
        [category]: {
          pagesLoaded: page,
          next: media.next,
        },
        byId: Object.assign({}, state.byId, media.byId),
        allIds: union(state.allIds, Object.keys(media.byId)),
        uploadProgress: null,
      };

      return newState;
    }

    case types.MEDIA_UPLOAD_PROGRESS:
      return { ...state, uploadProgress: action.payload };

    //  Only for pexels right now
    case types.MEDIA_DOWNLOAD_PROGRESS:
      return {
        ...state,
        pexels: {
          ...state.pexels,
          downloadProgress: action.payload,
          isDownloading: true,
        },
      };

    case types.MEDIA_CREATION_FAILED:
      return { ...state, uploadProgress: null };

    case types.MEDIA_UNSPLASH_RESULTS: {
      const {
        pageResponse: { results },
      } = state.unsplash;
      action.payload.pageResponse.results = results.concat(action.payload.pageResponse.results);
      return {
        ...state,
        unsplash: {
          isFetching: false,
          pageResponse: action.payload.pageResponse,
        },
      };
    }

    case types.MEDIA_SEARCH_PEXELS_MEDIA: {
      if (action.payload.page > 1) return { ...state };
      return {
        ...state,
        pexels: { ...state.pexels, isFetching: true, isDownloading: false },
      };
    }

    case types.MEDIA_PEXELS_RESULTS: {
      const { media } = state.pexels;
      return {
        ...state,
        pexels: {
          isFetching: false,
          media: media.concat(action.payload.videos || action.payload.photos),
          pages: Math.floor(action.payload.total_results / action.payload.per_page),
          currentPage: action.payload.page,
        },
      };
    }

    case types.MEDIA_CLEAR_PEXELS_RESULTS:
      return {
        ...state,
        pexels: {
          isFetching: false,
          media: [],
        },
      };

    case types.MEDIA_SEARCH_UNSPLASH: {
      const { pageResponse } = state.unsplash;
      return {
        ...state,
        unsplash: {
          isFetching: true,
          pageResponse,
        },
      };
    }

    case types.MEDIA_SEARCH_UNSTACK:
      return {
        ...state,
        isFetching: true,
      };

    case types.MEDIA_SEARCH_UNSTACK_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        unstackSearchedMedia: {
          byId: action.payload,
          allIds: Object.keys(action.payload),
        },
      };
    }

    case types.MEDIA_CLEAR_UNSTACK_SEARCH: {
      return {
        ...state,
        isFetching: false,
        unstackSearchedMedia: {
          byId: {},
          allIds: [],
        },
      };
    }

    case types.MEDIA_CLEAR_UNSPLASH_RESULTS:
      return {
        ...state,
        unsplash: {
          isFetching: false,
          pageResponse: { results: [] },
        },
      };

    case types.MEDIA_ICONS8_RESULTS: {
      const {
        pageResponse: { icons },
      } = state.icons8;
      action.payload.pageResponse.icons = icons.concat(action.payload.pageResponse.icons);
      return {
        ...state,
        icons8: {
          isFetching: false,
          pageResponse: action.payload.pageResponse,
        },
      };
    }

    case types.MEDIA_SEARCH_ICONS8: {
      const { pageResponse } = state.icons8;
      return {
        ...state,
        icons8: {
          isFetching: true,
          pageResponse,
        },
      };
    }

    case types.MEDIA_CLEAR_ICONS8_RESULTS:
      return {
        ...state,
        icons8: {
          isFetching: false,
          pageResponse: { icons: [] },
        },
      };

    case types.MEDIA_DELETE_SUCCESS: {
      let stateCopy = cloneDeep(state);
      delete state.byId[action.payload];
      stateCopy.allIds = state.allIds.filter((id) => id !== action.payload);
      return {
        ...stateCopy,
      };
    }
    case types.MEDIA_SAVE_DATA_TABLE_COLUMNS:
      return { ...state, dataTableColumns: action.payload };
    default:
      return state;
  }
}

// ----------------------------------------------------------------------------
// Selectors
// ----------------------------------------------------------------------------

export function selectLocalState(globalState) {
  return globalState.media;
}

export function selectIsFetching(globalState) {
  const state = selectLocalState(globalState);
  return state.isFetching;
}

export function selectLastUpdated(globalState) {
  const state = selectLocalState(globalState);
  return state.lastUpdated;
}

export function selectIsLoading(globalState) {
  const state = selectLocalState(globalState);
  return state.lastUpdated == null;
}

export function selectAllById(globalState) {
  const state = selectLocalState(globalState);
  return { ...state.byId, ...state.unstackSearchedMedia.byId };
}

export function selectAllIds(globalState) {
  const state = selectLocalState(globalState);
  return state.allIds;
}

export function selectOne(globalState, id) {
  const allById = { ...selectAllById(globalState) };
  return allById[id];
}

export function selectUnsplash(globalState) {
  const state = selectLocalState(globalState);
  return state.unsplash;
}

export function selectPexels(globalState) {
  const state = selectLocalState(globalState);
  return state.pexels;
}

export function selectIcons8(globalState) {
  const state = selectLocalState(globalState);
  return state.icons8;
}

export const selectUploadProgress = createSelector(selectLocalState, (media) => media.uploadProgress);

export const selectUnstackSearchedMedia = createSelector(selectLocalState, selectActiveSite, (state, site) =>
  state.unstackSearchedMedia.allIds.map((id) => state.unstackSearchedMedia.byId[id]).filter((m) => m.site === site.id)
);

export const selectAll = createSelector(
  selectAllIds,
  selectAllById,
  selectUnstackSearchedMedia,
  (allIds, allById, searchedMedia) => {
    const result = allIds.map((id) => allById[id]);
    return [...result, ...searchedMedia];
  }
);

export const selectPdf = createSelector(selectAll, (media) => media.filter((m) => m.category_id === 'pdf'));

export const selectAllCategoryGrouped = createSelector(
  selectLocalState,
  selectAll,
  selectActiveSite,
  (state, media = [], site) => ({
    image: {
      ...state.image,
      media: media.filter((m) => m.site === site.id && m.category_id === 'image'),
    },
    product: {
      ...state.product,
      media: media.filter((m) => m.site === site.id && m.category_id === 'product'),
    },
    icon: {
      ...state.icon,
      media: media.filter((m) => m.site === site.id && m.category_id === 'icon'),
    },
    pdf: {
      ...state.pdf,
      media: media.filter((m) => m.site === site.id && m.category_id === 'pdf'),
    },
    video: {
      ...state.video,
      media: media.filter((m) => m.site === site.id && m.category_id === 'video'),
    },
  })
);

export const selectDataTableColumns = createSelector(selectLocalState, (state) => state.dataTableColumns);
