// @ts-ignore
import { useState, useCallback } from 'react';

// For example,
// const [isOpen, open, close, toggle] = useBooleanState(false);

type returnType = [boolean, () => void, () => void, () => void];

export default function useBooleanState(defaultValue: boolean): returnType {
  const [state, setState] = useState(!!defaultValue);

  const setTrue = useCallback(() => setState(true), []);
  const setFalse = useCallback(() => setState(false), []);
  const toggle = useCallback(() => setState(!state), [state]);

  return [state, setTrue, setFalse, toggle];
}
