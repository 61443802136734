/* eslint-disable jsx-a11y/anchor-is-valid */

import React from 'react';
import { compose } from 'redux';
import ReactDOM from 'react-dom';
import classnames from 'classnames';
import { connect } from 'react-redux';

import withSiteProps from 'containers/withSiteProps';
import { promoptConformation } from 'lib/promp-confirmation';
import { selectNotificationBySiteDomain } from 'reducers/notificationReducer';

import { ReactComponent as SvgIconInfo } from '../../../assets/images/icon-page-info.svg';
import { ReactComponent as SvgIconPublish } from '../../../assets/images/icon-publish.svg';
import { ReactComponent as SvgIconUnstackle } from '../../../assets/images/icon-sparkles.svg';
import { ReactComponent as SvgIconDiscard } from '../../../assets/images/icon-discard-changes.svg';

import styles from './EditingBar.module.scss';

function mapSiteToProps(site) {
  return {
    siteName: site.name,
  };
}

const mapStateToProps = (state, ownProps) => {
  return {
    notificationBarIsOut: selectNotificationBySiteDomain(state, ownProps.urlSite),
  };
};

class EditingBar extends React.PureComponent {
  handleDiscardClick = () => {
    const { discardChanges } = this.props;
    promoptConformation('default', discardChanges);
  };

  renderContent() {
    if (this.props.isEditing) {
      return (
        <>
          <SvgIconInfo className={styles.iconInfo} />
          <div className={styles.message}>Your changes won't be visible until you publish them.</div>
          <button className={styles.discard} onClick={this.handleDiscardClick} type="button">
            <SvgIconDiscard className={styles.iconDiscard} />
            Discard
          </button>
          <button className={styles.publish} data-test-id="publish" onClick={this.props.publishChanges} type="button">
            <SvgIconPublish className={styles.iconPublish} />
            Publish
          </button>
        </>
      );
    } else if (this.props.showPublishSuccess) {
      return (
        <div className={styles.successMessage}>
          <SvgIconUnstackle className={styles.iconUnstackles} />
          <strong>Success!</strong>
          Your changes have been published.
        </div>
      );
    }
  }

  shouldShow() {
    return this.props.isEditing || this.props.showPublishSuccess;
  }

  getRenderedContent() {
    if (this.shouldShow()) {
      const renderedContent = this.renderContent();
      this.lastRenderedContent = renderedContent;
      return renderedContent;
    } else {
      return this.lastRenderedContent;
    }
  }

  render() {
    const className = classnames(styles.EditingBar, {
      [styles.show]: this.shouldShow(),
      [styles.notificationBarIsOut]: this.props.notificationBarIsOut,
    });

    return ReactDOM.createPortal(
      <div className={className} data-test-id="editing-bar">
        {this.getRenderedContent()}
      </div>,
      document.body
    );
  }
}

export default compose(withSiteProps(mapSiteToProps), connect(mapStateToProps))(EditingBar);
