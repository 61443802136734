import { createSelector } from 'reselect';
import { selectPartner, selectPolicies } from './accountReducer';

// ----------------------------------------------------------------------------
// Classes
// ----------------------------------------------------------------------------

class Policy {
  constructor(policy) {
    this._actions = policy.actions.map((action) => action.split(':'));
    this._effect = policy.effect;
  }
  hasAction(reqAction) {
    let [reqView, reqMethod, reqOwner, reqStatus, reqId] = reqAction.split(':');
    return !!this._actions.find((action) => {
      let [actView, actMethod, actOwner, actStatus, actId] = action;
      return (
        (actView === '*' || actView === reqView) &&
        (!reqMethod || !actMethod || actMethod === '*' || actMethod === reqMethod) &&
        (!reqOwner || !actOwner || actMethod === 'self' || actOwner === reqOwner) &&
        (!reqStatus || !actStatus || actStatus === '*' || actStatus === reqStatus) &&
        (!reqId || !actId || actId === '*' || actId === reqId)
      );
    });
  }
  hasSpecificAction(reqAction) {
    reqAction = reqAction.replace('*', '');
    return !!this._actions.find((action) => {
      return action.join(':').replace('*', '') == reqAction;
    });
  }
  isAllowed(reqAction) {
    return this._effect === 'allow' && this.hasAction(reqAction);
  }
  isDenied(reqAction) {
    return this._effect === 'deny' && this.hasSpecificAction(reqAction);
  }
}

class PolicyStatement {
  constructor(policies) {
    policies = policies || [];
    this._policies = policies.map((policy) => new Policy(policy));
    this._testedActions = {};
  }
  isAllowed(reqAction) {
    if (!(reqAction in this._testedActions)) {
      this._testedActions[reqAction] =
        !!this._policies.find((policy) => policy.isAllowed(reqAction)) &&
        !this._policies.find((policy) => policy.isDenied(reqAction));
    }
    return this._testedActions[reqAction];
  }
}

// ----------------------------------------------------------------------------
// Reducer
// ----------------------------------------------------------------------------

// ----------------------------------------------------------------------------
// Selectors
// ----------------------------------------------------------------------------

/**
 * Selector that returns the helper function hasPermission
 * @param {object} state Redux state
 * @returns {func} parseSmartUrl
 */
export const selectHasPermission = createSelector(selectPolicies, (policies) => {
  /**
   * Checks policies for permission to the provided view/action
   * @param  {string} view
   * @param  {string} action
   * @return {boolean} Permission to resource
   */
  if (!policies) {
    return function () {
      return false;
    };
  }
  const hasPermission = function (action, policyType) {
    const rolePolicies = new PolicyStatement(policies.role_policies),
      subscriptionPolicies = new PolicyStatement(policies.subscription_policies);
    switch (policyType) {
      case 'subscription':
        return subscriptionPolicies.isAllowed(action);
      case 'role':
        return rolePolicies.isAllowed(action);
      default:
        return hasPermission(action, 'role') && hasPermission(action, 'subscription');
    }
  };
  return hasPermission;
});

export const selectShouldRender = createSelector(selectPolicies, selectPartner, (policies, partner) => {
  if (!policies) {
    return function () {
      return false;
    };
  }
  const shouldRender = function (action) {
    const rolePolicies = new PolicyStatement(policies.role_policies),
      subscriptionPolicies = new PolicyStatement(policies.subscription_policies);
    const rolePermission = rolePolicies.isAllowed(action);
    const subscriptionPermission = subscriptionPolicies.isAllowed(action);
    return !(!rolePermission || (!subscriptionPermission && partner));
  };
  return shouldRender;
});
