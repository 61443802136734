import React from 'react';
import ReactSelect from 'react-select';
import styles from './Select.module.scss';
import classnames from 'classnames';

const theme = (theme) => ({
  ...theme,
  boxShadwo: 'none',
  borderRadius: 2,
  colors: {
    ...theme.colors,
    primary: '#1495CD',
    primary25: '#f4fbff',
  },
});

export default function UnstackSelect(props) {
  const { className, isInline, ...restProps } = props;

  return (
    <ReactSelect
      className={classnames(className, styles.Select, {
        [styles.isInline]: isInline,
      })}
      classNamePrefix={styles.prefix}
      theme={theme}
      isSearchable={false}
      {...restProps}
    />
  );
}
