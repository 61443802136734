import * as types from '../actions/actionTypes';
import { createSelector } from 'reselect';

// ----------------------------------------------------------------------------
// Reducer
// ----------------------------------------------------------------------------

const INITIAL_STATE = {
  isFetching: null,
  list: [],
};

export default function categoriesReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.CATEGORY_FETCH_ALL_REQUEST:
      return { ...state, isFetching: true };
    case types.CATEGORY_FETCH_ALL_SUCCESS:
      return { ...state, list: action.payload.results, isFetching: false };
    case types.CATEGORY_UPDATE_REQUEST_SUCCESS:
      return {
        ...state,
        list: state.list.map((obj) => (action.payload.id === obj.id ? action.payload : obj)),
      };

    case types.CATEGORY_DELETE_REQUEST_SUCCESS:
      return {
        ...state,
        list: state.list.filter((category) => category.id !== action.payload),
      };
    default:
      return state;
  }
}

// ----------------------------------------------------------------------------
// Selectors
// ----------------------------------------------------------------------------

const selectLocalState = (globalState) => globalState.articlesCategories;

export const selectAllCategories = createSelector([selectLocalState], (categories) => categories.list);

export const selectById = (state, id) => {
  const categories = selectAllCategories(state);
  return categories.find((category) => category.id === id) || {};
};

export const selectByBlogId = (state, id) => {
  const categories = selectAllCategories(state);
  return categories.filter((category) => category.blog === id) || [];
};

export const selectByBlogIdInOptionsFormat = createSelector([selectByBlogId], (categories) =>
  Object.fromEntries(
    categories.map((category) => [
      category.id,
      {
        value: category.id,
        label: category.name,
        slug: category.slug,
      },
    ])
  )
);

export const selectIsFetching = createSelector(selectLocalState, (categories) => categories.isFetching);
