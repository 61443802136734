import React, { Dispatch, Ref, useEffect, useRef, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import DocumentTitle from 'react-document-title';
import Spinner from '../base/Spinner';
import isEmpty from 'lodash/isEmpty';
import { set, del, insert, push, assign } from 'object-path-immutable';
import throttle from 'lodash/throttle';
import deepEqual from 'fast-deep-equal';
import QueryString from 'query-string';

import StyleGuide from '../../lib/StyleGuide';

import LayoutStyles from './Elements/Layout';
import ButtonStyles from './Elements/ButtonStyles2';
import ListStyles from './Elements/ListStyles';
import FormStyles from './Elements/FormStyles';
import StyleEditableOverrides from '../StyleEditableOverrides';
import StylesColors from './Elements/StylesColors';
import Fonts from './Elements/StylesFonts/Fonts';
import HeadingStyles from './Elements/HeadingStyles';
import TextStyles from './Elements/TextStyles';
import BlogHeadingStyles from './Elements/BlogHeadingStyles';
import BlogTextStyles from './Elements/BlogTextStyles';
import FixedSectionIndicator from '../FixedSectionIndicator';
import FloatingPublishButton from '../Editor/FloatingPublishButton';

import * as fontsSelector from 'reducers/fontsReducer';
import * as siteActions from '../../actions/siteActions';
import * as siteSelectors from '../../reducers/sitesReducer';
import Shadows from './Elements/StylesShadows/Shadows';
import { useLocation, useParams } from 'react-router-dom';
import { FontOptions } from './Elements/types';
import { OnMount, OnUnmount } from 'hooks/mountUnmountHooks';

function Styles() {
  const { urlSite } = useParams();
  const location = useLocation();
  // @ts-ignore
  const propsSite = useSelector((state) => siteSelectors.selectSiteByDomain(state, urlSite));
  const isLoading = isEmpty(propsSite) || propsSite.id == null;
  const propFonts = useSelector(fontsSelector.selectFonts);
  const [selectedSection, setSelectedSection] = useState(QueryString.parse(location.hash).tab);
  const [typography, setTypography] = useState();
  const [showPublishSuccessBanner, setShowPublishSuccessBanner] = useState(false);
  const [loadAllFonts, setLoadAllFonts] = useState(false);
  const [draftSite, setDraftSite] = useState();
  const [publishSuccessTimeout, setPublishSuccessTimeout] = useState<NodeJS.Timeout>();
  const scrollRefs: { [key: string]: any } = {};
  const tabArray = ['colors', 'fonts', 'shadows', 'typography', 'buttons', 'lists', 'forms', 'layout'];
  tabArray.forEach((str) => (scrollRefs[str] = useRef()));
  const dispatch: Dispatch<any> = useDispatch();

  if (isLoading || !propFonts.length) return <Spinner className="fixed" size="1" />;

  OnMount(() => {
    getPersistedDraftSite();
  });

  OnUnmount(() => {
    persistDraftSite();
    clearTimeout(publishSuccessTimeout);
  });

  useEffect(() => {
    checkDiffs();
  }, [draftSite]);

  useEffect(() => {
    focusElement();
  }, [selectedSection, propFonts]);

  // --------------------------------------------------------------------------
  // Session persistance
  // Persist the component's state on the window object between dismounts
  // --------------------------------------------------------------------------

  function cacheKey() {
    return `StylesDraftSite${site.id}`;
  }

  function persistDraftSite() {
    (window as any)[cacheKey()] = draftSite;
  }

  function getPersistedDraftSite() {
    const newDraftSite = (window as any)[cacheKey()];
    setDraftSite(newDraftSite);
  }

  const focusElement = () => {
    const node = scrollRefs[selectedSection as string];

    node &&
      window.scrollTo({
        top: node.current.offsetTop - 115,
        behavior: 'smooth',
      });
  };

  // Periodically run a deep-equal of the props.site and state.draftSite. If
  // they are equal, then set draftSite to undefined to indicate the page is
  // no longer in editing mode. The function is throttled because deep-equals
  // are (sort of) expensive.
  const checkDiffs = throttle(
    () => {
      if (deepEqual(draftSite, propsSite)) {
        setDraftSite(undefined);
      }
    },
    200,
    { leading: false }
  );

  const getSite = () => {
    return draftSite || propsSite;
  };

  const discardChanges = () => {
    setDraftSite(undefined);
    hidePublishSuccess();
  };

  const publishChanges = () => {
    if (isEditing) {
      dispatch(siteActions.update(draftSite));
      setDraftSite(undefined);
      showPublishSuccess();
    }
  };

  const showPublishSuccess = () => {
    setShowPublishSuccessBanner(true);
    setPublishSuccessTimeout(setTimeout(hidePublishSuccess, 5000));
  };

  const hidePublishSuccess = () => {
    clearTimeout(publishSuccessTimeout);
    setShowPublishSuccessBanner(false);
  };

  const siteUpdaters = {
    set: (propPath: string[], value: any) => {
      const newDraftSite = set(getSite(), propPath, value);
      setDraftSite(newDraftSite);
    },
    push: (propPath: string[], value: any) => {
      const newDraftSite = push(getSite(), propPath, value);
      setDraftSite(newDraftSite);
    },
    unshift: (propPath: string[], value: any) => {
      const newDraftSite = insert(getSite(), propPath, value, 0);
      setDraftSite(newDraftSite);
    },
    del: (propPath: string[]) => {
      const newDraftSite = del(getSite(), propPath);
      setDraftSite(newDraftSite);
    },
  };

  const site = getSite();

  const styleGuide = new StyleGuide(site, propFonts);

  const fontFamilyOptions = styleGuide.fontFamilies.map((f: string, i: number) => ({
    value: i,
    label: f,
  }));

  const fontWeightOptions: FontOptions[] = [
    { value: 300, label: 'Light' },
    { value: 400, label: 'Regular' },
    { value: 500, label: 'Medium' },
    { value: 700, label: 'Bold' },
  ];

  const darkBgColor = site.styles.defaultColors.find((color: any) => color.label.includes('Dark background'));

  const isEditing = !!draftSite;

  const handleChangeTypography = (path: any, value: any) => {
    siteUpdaters.set(['styles', 'typography', ...path], value);
  };
  const handleChangeEventTypography = (e: any, path: string[]) => {
    const { value } = e.target;
    siteUpdaters.set(['styles', 'typography', ...path], value);
  };

  const handleChangeShadow = (path: string[], value: any) => {
    siteUpdaters.set(['styles', 'box_shadows', ...path], value);
  };

  return (
    <>
      <DocumentTitle title={`${site.name}'s styles`} />
      <StyleEditableOverrides loadAllFonts={loadAllFonts} site={site} />
      <FixedSectionIndicator
        onSectionSelect={(newSelectedSection) => setSelectedSection(newSelectedSection)}
        keys={tabArray}
      >
        <StylesColors
          name="colors"
          site={site}
          updateLabeledColors={(updatedSite: any) => {
            siteUpdaters.set(['styles'], updatedSite);
          }}
          updateColors={(path: string, value: any) => {
            siteUpdaters.set(['styles', path], value);
          }}
          scrollRef={scrollRefs['colors']}
        />
        <Fonts
          name="colors"
          site={site}
          updateFonts={(value) => {
            siteUpdaters.set(['styles', 'fontFamilies'], value);
          }}
          updateOnFontRemove={(updatedSite) => {
            siteUpdaters.set(['styles'], updatedSite);
          }}
          setLoadAllFonts={setLoadAllFonts}
          scrollRef={scrollRefs['fonts']}
        />
        <Shadows
          name="shadows"
          styleGuide={styleGuide}
          handleChange={handleChangeShadow}
          scrollRef={scrollRefs['shadows']}
        />
        {/* @ts-ignore */}
        <div name="typography" ref={scrollRefs['typography']}>
          <HeadingStyles
            fontFamilyOptions={fontFamilyOptions}
            styleGuide={styleGuide}
            fontWeightOptions={fontWeightOptions}
            handleChange={handleChangeTypography}
            handleChangeEvent={handleChangeEventTypography}
            darkBgColor={darkBgColor}
            scrollRef={scrollRefs['heading']}
          />
          <TextStyles
            fontFamilyOptions={fontFamilyOptions}
            styleGuide={styleGuide}
            fontWeightOptions={fontWeightOptions}
            handleChange={handleChangeTypography}
            handleChangeEvent={handleChangeEventTypography}
            darkBgColor={darkBgColor}
            scrollRef={scrollRefs['text']}
          />
          <BlogHeadingStyles
            fontFamilyOptions={fontFamilyOptions}
            styleGuide={styleGuide}
            fontWeightOptions={fontWeightOptions}
            handleChange={handleChangeTypography}
            handleChangeEvent={handleChangeEventTypography}
            darkBgColor={darkBgColor}
            scrollRef={scrollRefs['blog_headings']}
          />
          <BlogTextStyles
            fontFamilyOptions={fontFamilyOptions}
            styleGuide={styleGuide}
            fontWeightOptions={fontWeightOptions}
            handleChange={handleChangeTypography}
            handleChangeEvent={handleChangeEventTypography}
            darkBgColor={darkBgColor}
            scrollRef={scrollRefs['blog_text']}
          />
        </div>
        <ButtonStyles
          name="buttons"
          fontFamilyOptions={fontFamilyOptions}
          styleGuide={styleGuide}
          fontWeightOptions={fontWeightOptions}
          handleChange={(path: string[], value: any) => {
            siteUpdaters.set(['styles', 'button', ...path], value);
          }}
          handleChangeEvent={(e: any, path: string[]) => {
            const { value } = e.target;
            siteUpdaters.set(['styles', 'button', ...path], value);
          }}
          darkBgColor={darkBgColor}
          scrollRef={scrollRefs['buttons']}
        />
        <ListStyles
          name="lists"
          fontFamilyOptions={fontFamilyOptions}
          styleGuide={styleGuide}
          fontWeightOptions={fontWeightOptions}
          handleChange={(path: string[], value: any) => {
            siteUpdaters.set(['styles', 'list', ...path], value);
          }}
          handleChangeEvent={(e: any, path: string[]) => {
            const { value } = e.target;
            siteUpdaters.set(['styles', 'list', ...path], value);
          }}
          darkBgColor={darkBgColor}
          scrollRef={scrollRefs['lists']}
        />
        <FormStyles
          name="forms"
          fontFamilyOptions={fontFamilyOptions}
          styleGuide={styleGuide}
          fontWeightOptions={fontWeightOptions}
          handleChange={(path: string[], value: any) => {
            siteUpdaters.set(['styles', ...path], value);
          }}
          handleChangeEvent={(e: any, path: string[]) => {
            const { value } = e.target;
            siteUpdaters.set(['styles', ...path], value);
          }}
          darkBgColor={darkBgColor}
          scrollRef={scrollRefs['forms']}
        />
        <LayoutStyles
          styleGuide={styleGuide}
          handleChange={(path, value) => {
            siteUpdaters.set(['styles', 'layout', ...path], value);
          }}
          handleChangeEvent={(e, path) => {
            const { value } = e.target;
            siteUpdaters.set(['styles', 'layout', ...path], value);
          }}
          scrollRef={scrollRefs['layouts']}
        />
      </FixedSectionIndicator>
      <FloatingPublishButton
        isEditing={isEditing}
        showPublishSuccess={showPublishSuccessBanner}
        publishChanges={publishChanges}
        discardChanges={discardChanges}
      />
    </>
  );
}

export default Styles;
