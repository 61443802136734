import React from 'react';
import { compose } from 'redux';
import withIntegrations from 'containers/withIntegrations';
import { ReactComponent as IconBag } from 'assets/images/cart-bag.svg';
import { ReactComponent as IconCart } from 'assets/images/icon-cart.svg';

const ShopifyCartIcon = (props) => {
  const { metaData: { cart_language } = {}, integrationsBySlug } = props;
  const [shopify] = integrationsBySlug.shopify || [];
  return shopify && shopify.status === 'active' ? (
    <span className="unstack__icon-cart">{cart_language === 'bag' ? <IconBag /> : <IconCart />}</span>
  ) : null;
};

export default compose(withIntegrations)(ShopifyCartIcon);
