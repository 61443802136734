/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useRef, useState } from 'react';

import Search from 'components/Search';
import { optimizedSearchQuery } from '../../block/ProductElement/helpers';
import useActiveSite from 'hooks/useActiveSite';

export const SearchField = ({ placeholder, searchData, setSearchData }) => {
  const [query, setQuery] = useState(undefined);
  const prevQueryRef = useRef('');
  const site = useActiveSite();
  const { search } = searchData;
  return (
    <form onSubmit={(e) => e.preventDefault()}>
      <fieldset>
        <Search
          value={query === undefined ? search : query}
          onChange={(query) => {
            const prevValue = prevQueryRef.current;
            setQuery(optimizedSearchQuery(query, prevValue, site.is_shopify));
            prevQueryRef.current = query;
          }}
          placeholder={placeholder || 'Search'}
          debounceValue={(search) => setSearchData({ search })}
          containerStyle={{
            marginRight: '1rem',
            width: '100%',
          }}
        />
      </fieldset>
    </form>
  );
};
