import React from 'react';

import { sortableElement, sortableHandle } from '../../../../../lib/SortListHoc';
import { ReactComponent as SvgRemoveField } from '../../../../../assets/images/icon-remove-field.svg';
import { ReactComponent as SvgEditField } from '../../../../../assets/images/icon-edit-field.svg';
import { ReactComponent as SvgDrag } from '../../../../../assets/images/icon-drag.svg';

import styles from './Form.module.scss';

export const DragHandle = sortableHandle(() => (
  <SvgDrag className={styles.fieldDrag} data-tip="Drag to reorder fields" />
));

export const SortableField = sortableElement(({ field, onEditClick, onRemoveField, fieldsCount }) => (
  <div className={styles.field} style={{ zIndex: 999 }}>
    <div className={styles.options}>
      <DragHandle />
      <span>{field.label || field.placeholder || field.default}</span>
      <span className={`tag-helper ${styles.tag}`}>{field.type}</span>
    </div>
    <div className={`options-helper ${styles.options}`}>
      <SvgEditField onClick={() => onEditClick()} />
      {fieldsCount > 1 && <SvgRemoveField onClick={() => onRemoveField(field.id)} />}
    </div>
  </div>
));
