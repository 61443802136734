import React, { useEffect, useRef, useState } from 'react';
import styles from './Toolbar.module.scss';
import classnames from 'classnames';
import { CSSTransition } from 'react-transition-group';
import { useSelector, connect } from 'react-redux';
import { getDevice } from 'reducers/uiReducer';
import * as toolbarActions from '../../../actions/toolbarActions';
import * as addSectionActions from '../../../actions/addSectionActions';

/**
 * Shares toolbar state with reusable components nested within, like Trays,
 * whose visibility is controlled by the Toolbar.
 *
 * *NOTE* Shape of the default context MUST match the shape of the Toolbar's
 * state.
 */
export const ToolbarContext = React.createContext({
  activeTray: null,
  activeTrayType: null,
  openTray: () => {},
  closeTray: () => {},
});

const mapDispatchToProps = {
  setCursorOnToolbar: toolbarActions.setCursorOnToolbar,
};

class Toolbar2 extends React.PureComponent {
  constructor() {
    super();

    this.state = {
      activeTray: null,
      openTray: this.openTray,
      closeTray: this.closeTray,
    };
  }

  openTray = (tray, type = null) => {
    this.setState({
      activeTray: tray,
      activeTrayType: type,
    });
    if (this.props.setTrayState) this.props.setTrayState(true);
    if (this.props.setTrayOpen) this.props.setTrayOpen(true);
  };

  closeTray = (tray) => {
    this.setState((prevState) => {
      if (prevState.activeTray === tray) {
        return { activeTray: null };
      }
    });
    if (this.props.setTrayState) this.props.setTrayState(false);
    if (this.props.setTrayOpen) this.props.setTrayOpen(false);
  };

  render() {
    const {
      className,
      renderItems,
      children,
      setTrayState,
      hideTrayTop,
      setCursorOnToolbar,
      toolbarType,
      ...containerProps
    } = this.props;
    const { activeTrayType } = this.state;
    const activeTray = true;

    return (
      <ToolbarContext.Provider value={this.state}>
        <div className="admin">
          <div
            onMouseEnter={() => setCursorOnToolbar(true)}
            onMouseLeave={() => setCursorOnToolbar(false)}
            className={classnames(
              styles.toolbar,
              className,
              'uToolbar',
              { [styles.toolbarTopTransparent]: hideTrayTop },
              { [styles.trayIsOpen]: !!activeTray },
              { [styles.isCollectionToolbar]: toolbarType === 'collection' },
              { [styles.trayIsStyles]: activeTray?.props?.trayLabel === 'Styles' },
              {
                [styles.lowerTrayIsOpen]: activeTray && activeTrayType === 'lower',
              }
            )}
            {...containerProps}
          >
            {renderItems && renderItems(this.closeTray)}
            {!renderItems && children}
          </div>
        </div>
      </ToolbarContext.Provider>
    );
  }
}

Toolbar2 = connect(() => {
  return {};
}, mapDispatchToProps)(Toolbar2);
export { Toolbar2 };

export const Group = (props) => {
  const { className, ...otherProps } = props;
  const combinedClassName = classnames(styles.Group, className);

  return (
    <div className={combinedClassName} {...otherProps}>
      {props.children}
    </div>
  );
};

export const Label = (props) => {
  const { className, active, group, ...otherProps } = props;
  const combinedClassName = classnames(styles.Label, className, {
    [styles.active]: active,
    [styles.group]: group,
  });

  return (
    <div className={combinedClassName} {...otherProps}>
      {props.children}
    </div>
  );
};

export const TextInput = (props) => {
  const { className, value, border, innerRef, ...otherProps } = props;
  const combinedClassName = classnames(className, styles.TextInput, {
    [styles.TextInputBorder]: border,
  });

  return <input ref={innerRef} className={combinedClassName} type="text" value={value || ''} {...otherProps} />;
};

/**
 * A standard button on the top level of the toolbar.
 */
export class Button extends React.PureComponent {
  handleClick = (e) => {
    // Call the onClick event that was passed
    if (this.props.onClick) this.props.onClick(e);

    // Blur button. Otherwise Chrome leaves it in a focused state.
    if (e.currentTarget && e.currentTarget.blur) e.currentTarget.blur();
  };

  render() {
    const { className, onClick, ...otherProps } = this.props;

    return <button className={classnames(className, styles.Button)} onClick={this.handleClick} {...otherProps} />;
  }
}

/**
 * A radio button.
 */
export class RadioButton extends React.PureComponent {
  handleClick = (e) => {
    // Call the onClick event that was passed
    if (this.props.onClick) this.props.onClick(e);

    // Blur button. Otherwise Chrome leaves it in a focused state.
    if (e.currentTarget && e.currentTarget.blur) e.currentTarget.blur();
  };

  render() {
    const { className, onClick, selected, ...otherProps } = this.props;

    return (
      <button
        className={classnames(className, styles.Button, {
          [styles.RadioButtonSelected]: selected,
        })}
        onClick={this.handleClick}
        {...otherProps}
      />
    );
  }
}

/**
 * Groups related Button's
 */
export function ButtonGroup(props) {
  const { className, compact, ...otherProps } = props;
  const combinedClassName = classnames(compact ? styles.ButtonGroupCompact : styles.ButtonGroup, className);

  return (
    <div className={combinedClassName} {...otherProps}>
      {props.children}
    </div>
  );
}

export function LabeledToolbar(props) {
  const { className, label, children, ...restProps } = props;
  const combinedClassName = classnames(styles.LabeledToolbar, className);

  return (
    <div className={combinedClassName} {...restProps} data-test-id="labelled-toolbar">
      {/* <TrayLabel>{label}</TrayLabel> */}
      <TrayContent>{children}</TrayContent>
    </div>
  );
}

export function TrayLabel(props) {
  const { className, ...otherProps } = props;
  const combinedClassName = classnames(styles.TrayLabel, className);

  return (
    <div className={combinedClassName} {...otherProps}>
      {props.children}
    </div>
  );
}

export function TrayContent(props) {
  const { className, ...otherProps } = props;
  const combinedClassName = classnames(styles.TrayContent, className);
  const trayContentRef = useRef();
  const [toolbarParent, setToolbarParent] = useState();
  const device = useSelector(getDevice);

  useEffect(() => {
    setToolbarParent(trayContentRef.current.closest('.uToolbar'));
  }, []);

  useEffect(() => {
    if (toolbarParent && props.trayIsOpen && props.adjustSizeToTray) {
      const origWidth = toolbarParent.style.width;
      const totalWidth = Array.from(trayContentRef.current.children).reduce((sum, child) => sum + child.offsetWidth, 0);
      if (totalWidth > toolbarParent.offsetWidth) {
        toolbarParent.style.width = totalWidth + 'px';
      }
      if (origWidth !== toolbarParent.style.width && window.forceToolbarUpdate) window?.forceToolbarUpdate();
    }
  }, [props.trayIsOpen, trayContentRef?.current?.offsetWidth, props.adjustSizeToTray]);

  return (
    <div ref={trayContentRef} className={combinedClassName} {...otherProps}>
      {props.children}
    </div>
  );
}

/**
 * Reusable component for buttons that open a second level of the toolar, called
 * a "tray".
 *
 * @prop {function} renderIcon - render prop for the icon that resides in the
 * top level of the toolbar. Hovering over the icon reveals the tray.
 * @prop {Component|string} [trayLabel] - Label explaining what the tray is
 * for. Appears on the left side of the tray contents. Label is not displayed
 * if the prop is omitted
 * @prop {function} renderTray - render prop for the tray contents. Will only
 * render if the tray is visible.
 */
class Tray extends React.PureComponent {
  static contextType = ToolbarContext;

  state = {
    hasMouse: false,
    hasFocus: false,
  };

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  handleMouseEnter = (e) => {
    clearTimeout(this.timeout);
    this.setState({ hasMouse: true });
  };

  // handleMouseLeave = (e) => this.setState({ hasMouse: false });
  handleMouseLeave = (e) => {
    this.timeout = setTimeout(() => {
      this.setState({ hasMouse: false });
    }, 250);
  };

  handleFocus = (e) => this.setState({ hasFocus: false });
  handleBlur = (e) => this.setState({ hasFocus: false });

  componentDidMount() {
    if (this.props.startOpen) this.context.openTray(this);
  }

  componentDidUpdate(prevProps, prevState) {
    // If hasMouse or hasFocus changed
    if (prevState.hasMouse !== this.state.hasMouse || prevState.hasFocus !== this.state.hasFocus) {
      const wantsToBeOpen = this.state.hasMouse || this.state.hasFocus;
      const isOpen = this.context.activeTray === this;

      if (wantsToBeOpen !== isOpen) {
        if (wantsToBeOpen) this.context.openTray(this, 'lower');
        else this.context.closeTray(this);
      }
    }
  }

  render() {
    const {
      renderIcon,
      trayLabel,
      dataTestID,
      className,
      renderTray,
      forcedOpen,
      formatLabel = true,
      showInvisible,
    } = this.props;

    const { activeTray } = this.context;

    const trayIsOpen = activeTray === this || forcedOpen;
    const tooltip = formatLabel ? trayLabel.charAt(0) + trayLabel.slice(1).toLowerCase() : trayLabel;

    return (
      <div
        data-tip={tooltip}
        data-test-id={trayLabel.toLowerCase().replace(' ', '-')}
        className={classnames(styles.trayIcon, { [styles.open]: trayIsOpen })}
        onClick={() => {
          if (trayIsOpen) {
            this.context.closeTray(this);
            this.props.requestDrawerClose();
          } else {
            this.context.openTray(this, 'lower');
          }
        }}
        // onMouseEnter={this.handleMouseEnter}
        // onMouseLeave={this.handleMouseLeave}
        onFocus={this.handleFocus}
        onBlur={this.handleBlur}
      >
        {renderIcon(trayIsOpen)}
        <CSSTransition
          classNames={styles}
          in={trayIsOpen}
          timeout={this.props.adjustSizeToTray ? 0 : 1050}
          mountOnEnter
          unmountOnExit
        >
          {(state) => (
            <div
              onClick={(e) => {
                this.props.setHoverToolbar(undefined);
                e.stopPropagation();
              }}
              className={classnames(styles.tray, { [styles.open]: trayIsOpen }, { [styles.invisible]: showInvisible })}
              data-test-id="tray-content"
            >
              <TrayContent className={className} adjustSizeToTray={this.props.adjustSizeToTray} trayIsOpen={trayIsOpen}>
                {renderTray()}
              </TrayContent>
            </div>
          )}
        </CSSTransition>
      </div>
    );
  }
}

const mapDispatchToPropsTray = {
  setActiveToolbar: toolbarActions.setActiveToolbar,
  setHoverToolbar: toolbarActions.setHoverToolbar,
  requestDrawerClose: addSectionActions.requestDrawerClose,
};
Tray = connect(() => {
  return {};
}, mapDispatchToPropsTray)(Tray);
export { Tray };

// Like the Tray component, but overlays the toolbar instead.
export class OverTray extends React.PureComponent {
  static contextType = ToolbarContext;

  componentDidMount() {
    if (this.props.startOpen) this.context.openTray(this);
  }

  open = () => {
    this.context.openTray(this, 'over');
  };

  close = () => {
    this.context.closeTray(this);
  };

  render() {
    const { borderless, renderIcon, renderTray, extendHeight } = this.props;

    const { activeTray } = this.context;

    const trayIsOpen = activeTray === this;

    return (
      <>
        <Button onClick={this.open} style={{ width: '40px', padding: '10px' }}>
          {renderIcon(trayIsOpen)}
        </Button>
        <CSSTransition
          classNames={styles}
          in={trayIsOpen}
          timeout={{ enter: 150, exit: 75 }}
          mountOnEnter
          unmountOnExit
        >
          <div
            className={classnames(styles.toolbar, styles.OverTray, {
              [styles.borderless]: borderless,
              [styles.extendHeight]: extendHeight,
            })}
          >
            {renderTray(this.close)}
          </div>
        </CSSTransition>
      </>
    );
  }
}
