import React, { useState } from 'react';
import { IconSynchronize } from '../../../../../../Editor/Toolbars2/icons';
import { Button } from '../../../../../../Editor/Toolbars2/Toolbar2';
import SideDrawer from '../../../../../../base/SideDrawer';
import FormControl from '../../../../../../base/FormHelpers/FormControl';

export default function CreateElementButton({ sectionType, onCreateElement }) {
  const [isOpen, setIsOpen] = useState(false);
  const [name, setName] = useState('');
  const [errors, setErrors] = useState();

  const isCarousel = sectionType === 'carousel';

  const handleSubmit = (e) => {
    e.preventDefault();
    if (name) {
      onCreateElement(name);
      setIsOpen(false);
    } else {
      setErrors({ name: 'Name is required' });
    }
  };

  if (isCarousel) {
    return (
      <Button disabled style={{ cursor: 'default' }}>
        <IconSynchronize data-tip="Reusable carousels coming soon" height={17} width={17} />
      </Button>
    );
  }

  return (
    <>
      <Button onClick={() => setIsOpen(true)} data-tip="Create template" data-test-id="prebuilt-section-control">
        <IconSynchronize height={17} width={17} />
      </Button>
      <SideDrawer
        isOpen={isOpen}
        title="Save as reusable section"
        close={() => setIsOpen(false)}
        render={() => (
          <>
            <form onSubmit={handleSubmit}>
              <small>Save this section design and reuse it anywhere to build content faster.</small>
              <fieldset style={{ marginTop: '2rem' }}>
                <label htmlFor="section_name_input">Give this section a name</label>
                <FormControl
                  type="text"
                  id="section_name_input"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  errors={errors && errors.name}
                />
              </fieldset>
              <input
                type="submit"
                data-test-id="save-section"
                value="Save this section as a template"
                className="button button-primary button-full"
              />
            </form>
          </>
        )}
      />
    </>
  );
}
