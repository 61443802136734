import React from 'react';
import { compose } from 'redux';
import withSiteId from '../../containers/withSiteId';
import { connect } from 'react-redux';
import * as siteStatisticActions from '../../actions/siteStatisticActions';
import * as siteStatisticSelectors from '../../reducers/siteStatisticsReducer';
import * as pageSelectors from '../../reducers/pagesReducer';
import * as articleSelectors from '../../reducers/articlesReducer';

import ChartControls from './ChartControls';
import PageViewsChart from '../Charts/PageViewsChart';

import eachDayOfInterval from 'date-fns/eachDayOfInterval';
import parseISO from 'date-fns/parseISO';
import formatDate from 'date-fns/lightFormat';
import subDays from 'date-fns/subDays';
import startOfDay from 'date-fns/startOfDay';
import latest from 'date-fns/max';
import isBefore from 'date-fns/isBefore';
import isWithinInterval from 'date-fns/isWithinInterval';

import isNumber from 'lodash/isNumber';
import round from 'lodash/round';

import styles from './ViewStatistics.module.scss';

function mapStateToProps(state, ownProps) {
  const pageType = siteStatisticSelectors.selectType(state);
  const data = siteStatisticSelectors.selectTypeData(state, pageType);

  let zeroState;
  if (pageType === 'site_page') {
    zeroState = !pageSelectors.selectHasPublishedSitePages(state);
  } else if (pageType === 'landing_page') {
    zeroState = !pageSelectors.selectHasPublishedLandingPages(state);
  } else if (pageType === 'blog') {
    zeroState = !articleSelectors.selectHasPublished(state);
  }

  return {
    data,
    type: pageType,
    zeroState,
  };
}

const mapDispatchToProps = {
  requestIfNeeded: siteStatisticActions.requestIfNeeded,
  setType: siteStatisticActions.setType,
};

const mapPageTypeToColorScheme = {
  site_page: 'blue',
  landing_page: 'green',
  blog: 'red',
  all: 'purple',
};

const DATASET_TYPE_VISITS = 'visits';
const DATASET_TYPE_PAGEVIEWS = 'pageviews';

class ViewStatistics extends React.PureComponent {
  state = {
    selectedDataset: DATASET_TYPE_PAGEVIEWS,
    selectedDateDomain: 30,
  };

  componentDidMount() {
    const { siteId, requestIfNeeded, type } = this.props;
    requestIfNeeded(siteId, type);
  }

  componentDidUpdate(prevProps) {
    const { siteId, requestIfNeeded, type } = this.props;
    if (prevProps.type !== type) {
      requestIfNeeded(siteId, type);
    }
  }

  handleSelectDataset = (dataset) => {
    this.setState({ selectedDataset: dataset });
  };

  handleDateDomain = (dateDomain) => {
    this.setState({ selectedDateDomain: dateDomain });
  };

  handleItemType = (itemType) => {
    this.props.setType(itemType);
    // this.setState({ selectedItemType: itemType });
  };

  getProcessedData() {
    const { data, showUsers } = this.props;
    const dateDomain = this.getDateDomain();

    // Default blank template
    const processedData = {
      visits: {
        data: [],
        previousTotal: 0,
        currentTotal: 0,
        // percentChange: 0,
      },
      pageviews: {
        data: [],
        previousTotal: 0,
        currentTotal: 0,
        // percentChange: 0,
      },
      ...(showUsers && {
        uniques: {
          data: [],
          previousTotal: 0,
          currentTotal: 0,
          // percentChange: 0,
        },
      }),
    };

    // Return undefined if there is no data to process
    if (data == null) return processedData;
    const keys = Object.keys(data);
    if (keys.length < 2) return processedData;

    // Process data for consumption by the chart

    const firstAvailableDate = parseISO(keys[0]);

    const startDate = latest([dateDomain.combined[0] - 1, firstAvailableDate]);
    const endDate = subDays(new Date(), 1);

    const allDates = eachDayOfInterval({ start: startDate, end: endDate });

    const dataKeys = ['visits', 'pageviews', ...(showUsers ? ['uniques'] : [])];

    allDates.forEach((date) => {
      const strDay = formatDate(date, 'yyyy-MM-dd');
      const datum = data[strDay];

      dataKeys.forEach((key) => {
        const value = (datum && datum[key]) || 0;

        processedData[key].data.push({
          date,
          value,
          // cumulative: processedData[key].total,
        });
      });
    });

    // Calculate percent change between periods

    dataKeys.forEach((key) => {
      let previousTotal = 0;
      let currentTotal = 0;

      processedData[key].data.forEach((datum) => {
        const value = datum.value || 0;

        if (
          isWithinInterval(datum.date, {
            start: dateDomain.previous[0],
            end: dateDomain.previous[1],
          })
        ) {
          previousTotal += value;
        } else if (
          isWithinInterval(datum.date, {
            start: dateDomain.current[0],
            end: dateDomain.current[1],
          })
        ) {
          currentTotal += value;
        }
      });

      processedData[key].previousTotal = previousTotal;
      processedData[key].currentTotal = currentTotal;

      // Calculate the percentage change between previous and current period.
      // But don't show percentage if the data isn't available for the entire
      // previous period.
      if (isBefore(dateDomain.previous[0], firstAvailableDate)) {
        processedData[key].percentChange = null;
      } else {
        processedData[key].percentChange = round(((currentTotal - previousTotal) / previousTotal) * 100, 1);
      }
    });

    // Sum totals

    // dataKeys.forEach(key => {
    //   let total = 0;
    //
    //   for (let strDate in data) {
    //     const datum = data[strDate];
    //     const value = (datum && datum[key]) || 0;
    //     total += value;
    //   }
    //
    //   processedData[key].total = total;
    // });

    // console.log(processedData);

    return processedData;
  }

  getDateDomain() {
    const { selectedDateDomain } = this.state;

    const now = new Date();
    const today = startOfDay(now);

    let previous;
    let current;
    let combined;
    let diff;

    if (isNumber(selectedDateDomain)) {
      previous = [subDays(today, selectedDateDomain * 2 + 2), subDays(today, selectedDateDomain + 2)];

      current = [subDays(today, selectedDateDomain + 1), subDays(today, 1)];

      combined = [previous[0], current[1]];

      diff = selectedDateDomain + 1;

      // console.log(
      //   diff,
      //   differenceInCalendarDays(previous[0], previous[1]),
      //   differenceInDays(previous[0], previous[1]),
      //   differenceInCalendarDays(current[0], current[1]),
      //   differenceInDays(current[0], current[1]),
      //   differenceInCalendarDays(combined[0], combined[1]),
      //   differenceInDays(combined[0], combined[1]),
      // );
      //
      // console.log(previous[1])
      // console.log(current[0])

      return { previous, current, combined, diff };
    }
    // else if (selectedDateDomain === 'WEEK') {
    //   return [startOfWeek(now), endOfWeek(now)];
    // }
    // else if (selectedDateDomain === 'MONTH') {
    //   return [startOfMonth(now), endOfMonth(now)];
    // }
  }

  render() {
    const { zeroState, type } = this.props;
    const { selectedDataset, selectedDateDomain } = this.state;

    const processedData = this.getProcessedData();
    const dateDomain = this.getDateDomain();

    const colorScheme = mapPageTypeToColorScheme[type];

    const chartData = processedData[selectedDataset].data;

    let zeroStatePageType, zeroStateSelectedDataset;
    if (type === 'site_page') zeroStatePageType = 'page';
    else if (type === 'landing_page') zeroStatePageType = 'landing page';
    else if (type === 'blog') zeroStatePageType = 'blog post';
    if (selectedDataset === DATASET_TYPE_VISITS) zeroStateSelectedDataset = 'user sessions';
    else if (selectedDataset === DATASET_TYPE_PAGEVIEWS) zeroStateSelectedDataset = 'page views';

    return (
      <article className={styles.ViewStatistics}>
        <ChartControls
          onSelectDataset={this.handleSelectDataset}
          onSelectDateDomain={this.handleDateDomain}
          onSelectItemType={this.handleItemType}
          selectedItemType={type}
          selectedDataset={selectedDataset}
          selectedDateDomain={selectedDateDomain}
          data={processedData}
        />
        <div style={{ height: '150px' }}>
          {processedData != null && (
            <PageViewsChart
              colorScheme={colorScheme}
              data={chartData}
              dateDomain={dateDomain}
              selectedDateDomain={selectedDateDomain}
              selectedDataset={selectedDataset}
              dateInterval={[subDays(new Date(), 30), new Date()]}
            />
          )}
        </div>
        {zeroState && (
          <div className={styles.zeroState}>
            <div>
              Publish a {zeroStatePageType} to track {zeroStateSelectedDataset}
            </div>
          </div>
        )}
      </article>
    );
  }
}

export default compose(withSiteId, connect(mapStateToProps, mapDispatchToProps))(ViewStatistics);
