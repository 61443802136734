import React from 'react';
import classnames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import { useDispatch, useSelector } from 'react-redux';
import { CSSTransition } from 'react-transition-group';

import { Toolbar2 as Toolbar } from '../../../../../../Editor/Toolbars2/Toolbar2';
import BackgroundColor from './BackgroundColor';
import BrightnessTheme from './BrightnessTheme';
import SectionHeader from './SectionHeader';
import SectionHeight from './SectionHeight';
import SectionOptions from './SectionOptions';
import { SectionBoxButtons } from '../SectionBox';
import Properties from './Properties';
import ABTest from './ABTest';
import Carousel from './Carousel';
import { ABTestInProgress } from './ABTestInProgress';
import AnchorLink from './AnchorLink';
import ConditionalContent from './ConditionalContent';
import * as toolbarSelectors from 'reducers/toolbarReducer';

import styles from './Toolbar.module.scss';
import { VisibilityOptions } from './VisibilityOptions';
import { getDevice } from 'reducers/uiReducer';
import { setActiveToolbar, setHoverToolbar } from 'actions/toolbarActions';
import { UBoxInfo, USectionDevices } from 'types/USection';
import { SectionHandlersInterface } from 'components/unstack-components/types';
import { isDeviceMode } from 'types/LegacyEditor';
import MediaBackgroundImageUploader from 'components/Editor/Toolbars2/components/MediaBackgroundImageUploader';

type Props = {
  isOpen: boolean;
  close: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  dataRef: UBoxInfo;
  content: USectionDevices;
  onChange: (content: USectionDevices, key?: string, multi?: boolean) => void;
  enableHeader?: boolean;
  sectionHandlers?: SectionHandlersInterface;
  applyOpacity?: boolean;
  setTrayState(value: boolean): void;
  trayState: boolean;
  defaults?: UBoxInfo;
  //  Making it optional as we are still maintaining deprecated SectionElement component
  locations?: Array<string> | undefined;
  properties?: { [key: string]: any };
  contentKey: string;
};

const SectionToolbar: React.FunctionComponent<Props> = ({
  isOpen,
  close,
  dataRef,
  onChange,
  properties,
  sectionHandlers = {} as SectionHandlersInterface,
  setTrayState,
  trayState,
  enableHeader,
  locations = [],
  content,
  defaults = {} as UBoxInfo,
  contentKey,
}) => {
  const activeToolbar = useSelector((state) => toolbarSelectors.getActiveToolbar(state));
  const device = useSelector(getDevice);
  const dispatch = useDispatch();

  function closeToolbars() {
    if (device === 'desktop' || !isDeviceMode()) {
      dispatch(setHoverToolbar(undefined));
      dispatch(setActiveToolbar(undefined));
    }
  }

  return (
    <CSSTransition in={isOpen} timeout={100} classNames={styles} mountOnEnter unmountOnExit>
      <div
        data-test-id="section-toolbar"
        className={classnames('admin', styles.toolbarContainer, {
          [styles.opacity]: activeToolbar && !['mobile', 'tablet'].includes(device),
          [styles.extendHeight]: trayState,
          [styles.abTestInProgress]: !!sectionHandlers.activeTest,
        })}
        onClick={closeToolbars}
      >
        <Toolbar
          setTrayState={setTrayState}
          renderItems={() =>
            sectionHandlers.activeTest ? (
              <ABTestInProgress
                variantIndex={sectionHandlers.containerIndex}
                setVariantIndex={sectionHandlers.onContainerIndexChange}
                // testSectionId={}
                activeTest={sectionHandlers.activeTest}
                openBlogInProgressTestSettings={sectionHandlers.openBlogInProgressTestSettings}
              />
            ) : (
              <>
                {!isEmpty(properties) && (
                  <Properties
                    dataRef={dataRef}
                    onChange={onChange}
                    properties={properties}
                    content={content}
                    contentKey={contentKey}
                  />
                )}
                {/* <Label label={sectionHandlers.componentName} /> */}
                {/* {!isEmpty(properties) && (
                  <LayoutOptions
                    value={value}
                    onChange={onChange}
                    properties={properties}
                  />
                )} */}
                {enableHeader && (
                  <SectionHeader dataRef={dataRef} content={content} onChange={onChange} contentKey={contentKey} />
                )}
                <MediaBackgroundImageUploader
                  value={dataRef}
                  content={content}
                  onChange={onChange}
                  contentKey={contentKey}
                />
                <BackgroundColor
                  dataRef={dataRef}
                  content={content}
                  onChange={onChange}
                  contentKey={contentKey}
                  startOpen
                />
                <SectionBoxButtons
                  defaults={defaults}
                  dataRef={dataRef}
                  content={content}
                  onChange={onChange}
                  contentKey={contentKey}
                />
                <SectionHeight dataRef={dataRef} content={content} onChange={onChange} contentKey={contentKey} />
                <BrightnessTheme dataRef={dataRef} content={content} onChange={onChange} contentKey={contentKey} />
                {sectionHandlers.initializeABTest && <ABTest sectionHandlers={sectionHandlers} />}
                {sectionHandlers.initializeCarousel && <Carousel sectionHandlers={sectionHandlers} />}
                {!['mobile', 'tablet'].includes(device) && (
                  <ConditionalContent dataRef={dataRef} onChange={onChange} content={content} contentKey={contentKey} />
                )}
                <AnchorLink dataRef={dataRef} content={content} onChange={onChange} contentKey={contentKey} />
                <VisibilityOptions
                  dataRef={dataRef}
                  onChange={onChange}
                  content={content}
                  isSectionToolbar={true}
                  contentKey={contentKey}
                />
                <SectionOptions locations={locations} sectionHandlers={sectionHandlers} close={close} />
              </>
            )
          }
        />
      </div>
    </CSSTransition>
  );
};

export default SectionToolbar;

// Old code
// ----------------------------------------------------------------------------

// className={classnames('admin', styles.toolbarContainer, {
//   [styles.extendHeight]: props.isTrayOpen,
//   [styles.opacity]: !props.isTrayOpen && props.previewOutOfFocus,
// })}
