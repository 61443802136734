import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { patchOnboarding } from 'actions/accountActions';
import { DISMISS_QUICKSTART } from 'components/AdminPanel/Quickstart/constants';
import styles from 'components/AdminPanel/Quickstart/Quickstart.module.scss';
import { selectAccount } from 'reducers/accountReducer';
import { selectHasPermission } from 'reducers/policyReducer';
import { useNavigate } from 'react-router-dom';

export default function DismissQuickstartLink() {
  const account = useSelector(selectAccount);
  const hasPermission = useSelector(selectHasPermission);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { urlSite } = useParams();

  function handleDismissQuickstart() {
    dispatch(patchOnboarding({ id: account.id, step: DISMISS_QUICKSTART }));
    const route = hasPermission('Page:create:::site_page')
      ? 'pages'
      : hasPermission('Page:create:::landing_page')
      ? 'landing-pages'
      : '';
    navigate(`/${urlSite}/${route}`);
  }

  return (
    <a onClick={handleDismissQuickstart} className={`${styles.dismissQuickstart}`}>
      Dismiss Quickstart
    </a>
  );
}
