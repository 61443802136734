import React, { useState } from 'react';
import classnames from 'classnames';

import AuthenticatedMediaThumbnail from '../../../../containers/AuthenticatedMediaThumbnail';
import { ReactComponent as SvgRemove } from '../../../../assets/images/icon-delete-media.svg';

import styles from './Unstack.module.scss';

export const FigureItem = ({ media, removeMedia, onClick, category, ...restProps }) => {
  const [hovered, setHovered] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  return (
    <figure key={media.id} onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)}>
      <div onClick={() => category === 'pdf' && window.open(media.file, '_blank')}>
        <AuthenticatedMediaThumbnail
          className={classnames(styles[media.category_id], {
            [styles.icon]: category === 'icon',
          })}
          data-test-id="media"
          src={`media:${media.id}`}
          file={media.file}
          onClick={onClick}
        />
      </div>
      {confirmDelete ? (
        <div className={styles.caption}>
          <span>Delete media?</span>
          <div>
            <input
              value="Yes"
              className="button button-danger button-sm"
              readOnly
              onClick={() => {
                setConfirmDelete(false);
                removeMedia();
              }}
            />
            <input
              value="No"
              className="button button-default button-sm"
              readOnly
              onClick={() => setConfirmDelete(false)}
            />
          </div>
        </div>
      ) : (
        <div className={styles.caption}>
          <figcaption>{media.url.match(/.+\/(.+)$/)[1]}</figcaption>
          {hovered && (
            <>
              <SvgRemove onClick={() => setConfirmDelete(true)} />
            </>
          )}
        </div>
      )}
    </figure>
  );
};
