import { select, call, put, putResolve, all, take, takeEvery, takeLatest, delay } from 'redux-saga/effects';
import * as api from '../services/spark-api';
import * as auth from '../services/spark-auth';
import * as types from '../actions/actionTypes';
import * as accountActions from '../actions/accountActions';
import * as siteActions from '../actions/siteActions';
import * as accountSelectors from '../reducers/accountReducer';
import * as appActions from '../actions/appActions';
import * as oauthState from '../services/oauth-state';

function* initializeApp(action) {
  // Check localStorage to see if logged in
  const authStatus = auth.getAuthStatus();

  if (authStatus.hasValidToken) {
    // Get all sites
    yield put(siteActions.requestAll());
    yield put(accountActions.loginSuccess(authStatus.token));

    // Wait for site to be received
    const receiveSiteAction = yield take(types.SITE_RECEIVE_LIST);

    // If there are no sites, check to see if there are any accounts
    if (!receiveSiteAction.payload.siteIds.length) {
      const accountResponse = yield call(api.getAccounts);

      // If there is at least one account, get the first one and return the user
      // to the signup flow.
      const accounts = accountResponse.json;
      if (accounts.length) {
        const accountId = accounts[0].id;
        yield put(accountActions.request(accountId));
        yield take(types.ACCOUNT_RECEIVE);
      }
    }
  } else {
    yield putResolve(accountActions.logout());
  }

  yield put(appActions.updateAppStatus({ isInitialized: true }));
}

function* appSaga() {
  yield all([takeLatest(types.APP_INITIALIZE, initializeApp)]);
}

export default appSaga;
