import React from 'react';
import classnames from 'classnames';
import { CSSTransition } from 'react-transition-group';

import { Toolbar2 as Toolbar } from '../../../../../../Editor/Toolbars2/Toolbar2';
import SectionOptions from './SectionOptions';
import Carousel from './Carousel';

import styles from './Toolbar.module.scss';

type Props = {
  isOpen: boolean;
  close: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  enableHeader?: boolean;
  sectionHandlers?: any;
  applyOpacity?: boolean;
  setTrayState(value: boolean): void;
  trayState: boolean;
};

const ErrorBoundaryToolbar: React.FunctionComponent<Props> = ({
  isOpen,
  close,
  sectionHandlers = {},
  applyOpacity,
  setTrayState,
  trayState,
}) => {
  return (
    <CSSTransition in={isOpen} timeout={100} classNames={styles} mountOnEnter unmountOnExit>
      <div
        data-test-id="section-toolbar"
        className={classnames('admin', styles.toolbarContainer, {
          [styles.opacity]: applyOpacity,
          [styles.extendHeight]: trayState,
          [styles.abTestInProgress]: !!sectionHandlers.activeTest,
        })}
      >
        <Toolbar
          setTrayState={setTrayState}
          renderItems={() => (
            <>
              {sectionHandlers.initializeCarousel && <Carousel sectionHandlers={sectionHandlers} hideIcon />}
              <SectionOptions sectionHandlers={sectionHandlers} close={close} />
            </>
          )}
        />
      </div>
    </CSSTransition>
  );
};

export default ErrorBoundaryToolbar;
