import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectFontFamilyWithFallback, selectFontNameWithFallback } from 'reducers/fontsReducer';

import { ReactComponent as SvgIconTrash } from '../../../../assets/images/icon-remove-field.svg';

import styles from './Fonts.module.scss';

export const FontItem = ({ family, toggleDrawer, onRemove, FontsCount, isDefault }) => {
  const [hovered, setHovered] = useState(false);
  const fontStyle = useSelector((state) => selectFontFamilyWithFallback(state, family));
  const fontName = useSelector((state) => selectFontNameWithFallback(state, family));
  return (
    <div className={styles.item} onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)}>
      {!isDefault && (
        <div className={styles.trash}>{hovered && FontsCount > 1 && <SvgIconTrash onClick={onRemove} />}</div>
      )}
      {isDefault && (
        <div className={styles.default}>
          <span>default</span>
        </div>
      )}
      <span onClick={toggleDrawer} style={{ fontFamily: fontStyle }}>
        Aa
      </span>
      <div className={styles.label}>{fontName || family}</div>
    </div>
  );
};
