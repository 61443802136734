import React from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import size from 'lodash/size';
import VisibilitySensor from 'react-visibility-sensor';

import Spinner from '../../Spinner';
import Search from '../../../Search';
import MediaNoResults from './MediaNoResults';
import { MediaOptionsDrawer } from './MediaOptionsDrawer';
import { ReactComponent as SvgUnsplash } from '../../../../assets/images/icon-media-unsplash.svg';

import * as mediaActions from '../../../../actions/mediaActions';
import * as mediaSelectors from '../../../../reducers/mediaReducer';

import styles from './Unsplash.module.scss';

function mapStateToProps(state) {
  const unsplash = mediaSelectors.selectUnsplash(state);
  const { pageResponse, isFetching } = unsplash || {
    pageResponse: { results: [] },
  };

  return {
    pageResponse,
    isFetching,
  };
}

const mapDispatchToProps = {
  searchUnsplash: mediaActions.searchUnsplash,
  clearUnsplashSearch: mediaActions.clearUnsplashSearch,
  downloadUnsplashPhoto: mediaActions.downloadUnsplashPhoto,
};

class Unsplash extends React.PureComponent {
  state = {
    lastQuery: '',
    debouncedQuery: '',
    lastSeenPhotoRow: 0,
    lastPage: 1,
    selectedMedia: {},
  };

  componentDidMount() {
    this.clearUnsplashSearch();
  }

  componentDidUpdate(nextProp, nextState) {
    if (this.state.lastQuery !== nextState.lastQuery && !this.state.lastQuery) this.setState({ lastSeenPhotoRow: 0 });

    if (this.state.debouncedQuery !== nextState.debouncedQuery) {
      this.props.clearUnsplashSearch();
      this.state.debouncedQuery && this.props.searchUnsplash(this.state.debouncedQuery, 1);
    }
  }

  componentWillUnmount() {
    this.props.clearUnsplashSearch();
  }

  clearUnsplashSearch() {
    this.props.clearUnsplashSearch();
    this.setState({
      lastQuery: '',
      lastSeenPhotoRow: 0,
      lastPage: 1,
      debouncedQuery: '',
    });
  }

  parseQueryParam(url, key) {
    let query = url.indexOf('?') >= 0 ? [...url.split('?'), ''][1] : url;
    let params = query.split('&');
    for (var i = 0; i < params.length; i++) {
      if (params[i].indexOf('=') < 0) {
        continue;
      }
      let [k, v] = params[i].split('=');
      //  eslint-disable-next-line
      if (k == key) {
        return v;
      }
    }
  }

  getPhotoPath(photo) {
    let ext = this.parseQueryParam(photo.urls.full, 'fm') || 'jpg';
    let path = photo.alt_description.replace(/ /g, '-').toLowerCase();
    return `${path}.${ext}`;
  }

  handleClick = (photo) => {
    let category = this.props.category;
    if (this.props.category === 'favicon') category = 'brand';
    else if (this.props.category === 'background_image' || this.props.category === 'unsplash') category = 'image';

    this.setState({ isDownloading: true });

    this.props
      .downloadUnsplashPhoto(photo.id, photo.urls.raw + '&fit=crop&w=1920&h=')
      .promise.then((blob) => {
        // const file = new File([blob], photo.alt_description);
        const file = new File([blob], this.getPhotoPath(photo));

        this.props
          .createMedia(file, category)
          .promise.then((media) => {
            const { id } = media;
            this.props.onChange(`media:${id}`, photo.alt_description);
          })
          .catch((errors) => {
            this.setState({
              errorMessage: errors.message,
            });
          });
      })
      .catch((errors) => {
        this.setState({
          errorMessage: errors.message,
        });
      });
  };

  onLoadMoreVisibilityChange(isVisible) {
    const {
      pageResponse: { results: photos },
    } = this.props;
    const { lastSeenPhotoRow, lastPage, lastQuery } = this.state;
    console.log(size(photos), lastSeenPhotoRow, size(photos) > lastSeenPhotoRow);
    if (isVisible && lastQuery && size(photos) > lastSeenPhotoRow) {
      this.setState({
        lastSeenPhotoRow: size(photos),
        lastPage: lastPage + 1,
      });
      this.props.searchUnsplash(lastQuery, lastPage + 1);
    }
  }

  render() {
    const {
      pageResponse: { results: photos },
      isFetching,
      gridPhotoHeight,
    } = this.props;

    const hasPhotos = photos && photos.length > 0;

    return (
      <>
        <Search
          onChange={(value) => {
            this.setState({ lastQuery: value });
          }}
          containerStyle={{ position: 'absolute', top: '20px' }}
          placeholder="Search photos"
          debounceValue={(debouncedQuery) => this.setState({ debouncedQuery })}
          enableDarkMode
          value={this.state.lastQuery}
          autofocus={!this.state.selectedMedia.id}
        />
        <div className={styles.sourceLink}>
          Photos by{' '}
          <a href="https://unsplash.com" target="_blank" rel="noopener noreferrer">
            Unsplash
          </a>
        </div>
        {hasPhotos && (
          <div
            className={classnames(styles.Unsplash, {
              [styles.addPadding]: this.state.selectedMedia.id,
            })}
          >
            {photos.map((photo, idx) => (
              <div
                className={classnames(styles.content, {
                  [styles.selected]: this.state.selectedMedia.id === photo.id,
                })}
                key={photo.id}
              >
                <figure>
                  <img
                    src={photo.urls.raw + '&fit=crop&w=280&h=' + gridPhotoHeight}
                    data-test-id={`unsplash-image-${idx}`}
                    onClick={() =>
                      this.setState({
                        selectedMedia: this.state.selectedMedia.id === photo.id ? {} : photo,
                      })
                    }
                    alt=""
                  />
                  <figcaption>
                    <a href={photo.user.links.html} target="_blank" rel="noopener noreferrer">
                      {photo.user.name}
                    </a>
                  </figcaption>
                </figure>
              </div>
            ))}
            {this.state.selectedMedia.id && (
              <MediaOptionsDrawer
                updateEntityOptions={(e) => {
                  this.setState({
                    selectedMedia: {
                      ...this.state.selectedMedia,
                      alt_description: e.target.value,
                    },
                  });
                }}
                entityOptions={{
                  altText: this.state.selectedMedia.alt_description,
                }}
                onSave={() => (this.state.isDownloading ? null : this.handleClick(this.state.selectedMedia))}
                onRemove={() => {}}
                entity={this.props.entity}
                isSectionContent={false}
                showLoader={this.state.isDownloading}
              />
            )}
          </div>
        )}
        {hasPhotos && !isFetching && (
          <VisibilitySensor onChange={(visible) => this.onLoadMoreVisibilityChange(visible)}>
            <span>&nbsp;</span>
          </VisibilitySensor>
        )}
        {!hasPhotos && isFetching && <Spinner className={styles.spinner} />}
        {!hasPhotos && !isFetching && (
          <MediaNoResults
            icon={<SvgUnsplash height="50px" width="50px" />}
            header="Find the perfect photo"
            content="Search thousands of professional photos that can help make your content stand out."
          />
        )}
      </>
    );
  }
}

Unsplash = connect(mapStateToProps, mapDispatchToProps)(Unsplash);

export { Unsplash };
