import React, { useCallback, useMemo, useState, useEffect, Dispatch } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as sectionActions from '../../../actions/sectionActions';
import * as sectionSelectors from '../../../reducers/sectionsReducer';
import { selectActiveBySectionId } from 'reducers/contentTestsReducer';
import Container from '../../unstack-components/Component';
import AddSectionButton from '../AddSection/AddSectionButton';
import { UPage } from 'types/UPage';
import { USection } from 'types/USection';
import { USite } from 'types/USite';
import { SectionHandlersInterface } from 'components/unstack-components/types';
import HeaderRenderer from '../Layout/HeaderRenderer';
import FooterRenderer from '../Layout/FooterRenderer';

import * as siteLayoutSelectors from 'reducers/siteLayoutReducer';
import * as siteSelectors from 'reducers/sitesReducer';

type Props = {
  updateActiveSection: (sectionId: string | boolean) => void;
  activeSection: string;
  key: string;
  sectionIndex: number;
  pageId: string;
  page: UPage;
  showAddSectionTooltip: boolean;
  setShowAddSectionTooltip: (value: boolean) => void;
  pageHeaderLayout: string;
  pageFooterLayout: Element | string;
  section: USection;
  isFresh: boolean;
  isInMotion: boolean;
  markSectionAsFresh: (sectionId: string) => void;
  sections: USection[];
  pageSectionPlaceholder: string;
  site: USite;
  pageType: string;
  itemType: string;
};

function SectionRendererWrapper(props: Props) {
  const {
    sections,
    section,
    sectionIndex,
    pageId,
    page,
    showAddSectionTooltip,
    setShowAddSectionTooltip,
    markSectionAsFresh,
    activeSection,
    updateActiveSection,
    pageHeaderLayout,
    pageFooterLayout,
    pageType,
    site,
    itemType,
  } = props;

  const dispatch: Dispatch<any> = useDispatch();

  const siteDomain = useSelector(() => siteSelectors.getUrlSiteFromSite(site));

  const layout = useSelector((state) => siteLayoutSelectors.selectLayoutByItemType(itemType)(state));

  const handleRemove = useCallback(() => {
    dispatch(sectionActions.remove(section.id));
  }, [dispatch, section.id]);

  const handleMove = useCallback(
    (moveUp: boolean, location: any) => {
      if (location) {
        dispatch(sectionActions.update(section.id, { location }));
      } else
        moveUp
          ? sectionIndex > 0 && dispatch(sectionActions.reorder(pageId, section.id, sectionIndex - 1))
          : sectionIndex < sections.length - 1 &&
            dispatch(sectionActions.reorder(pageId, section.id, sectionIndex + 1));
    },
    [dispatch, sectionIndex, pageId, section.id, sections.length]
  );

  const handleDuplicate = useCallback(() => {
    dispatch(sectionActions.add({ ...section, position: sectionIndex + 1 }, (id: string) => markSectionAsFresh(id)));
  }, [dispatch, pageId, section, sectionIndex, sections]);

  const setActiveSection = useCallback(() => updateActiveSection(section.id), [section.id, updateActiveSection]);

  const hideActiveSection = useCallback(() => updateActiveSection(true), [updateActiveSection]);

  const [applyOpacity, shouldApplyOpacity] = useState(false);
  const [isRichTextToolbarActive, setIsRichTextToolbarActive] = useState(false);

  // Containers

  const containerId = section.id;
  const [containerIndex, onContainerIndexChange] = useState(0);
  const childSections = useSelector((state) => (sectionSelectors.selectChildren as any)(state, section.id));
  const activeChild: USection = childSections[containerIndex];
  const containerLength = childSections.length;

  useEffect(() => {
    onContainerIndexChange(containerLength === containerIndex ? containerLength - 1 : containerIndex);
  }, [containerLength]);

  const initializeABTest = useCallback(() => {
    dispatch(sectionActions.initializeABTest(section.id, (id: string) => markSectionAsFresh(id)));
  }, [dispatch, section.id]);

  const discardABTest = useCallback(() => {
    if (activeChild) dispatch(sectionActions.discardABTest(section.id, activeChild.id));
  }, [dispatch, section.id, activeChild]);

  const initializeCarousel = useCallback(() => {
    dispatch(sectionActions.initializeContainer(section.id, 'carousel', (id: string) => markSectionAsFresh(id)));
  }, [dispatch, section.id]);

  const addCarouselChild = useCallback(
    (type: string, component: any) => {
      dispatch(
        sectionActions.addChildToContainer(
          section.id,
          type,
          component,
          () => {
            onContainerIndexChange(containerLength);
          },
          pageId
        )
      );
    },
    [dispatch, section.id, containerLength]
  );

  const removeCarouselChild = useCallback(
    (child: any) => {
      dispatch(sectionActions.removeContainerChild(section.id, child));
    },
    [dispatch, section.id]
  );

  const updateContainerProps = useCallback(
    (containerProps: any) => {
      dispatch(sectionActions.update(section.id, containerProps));
    },
    [dispatch, section.id]
  );

  // Section-level handlers

  // call here
  const onContentChange = useCallback(
    (sectionProps: USection) => {
      if (activeChild) {
        dispatch(sectionActions.update(activeChild.id, sectionProps));
      } else {
        dispatch(sectionActions.update(section.id, sectionProps));
      }
    },
    [dispatch, section.id, activeChild]
  );

  const handleCreateElement = useCallback(
    (name: string) => {
      if (activeChild) {
        dispatch(sectionActions.createElement(activeChild.id, name));
      } else {
        dispatch(sectionActions.createElement(section.id, name));
      }
    },
    [dispatch, activeChild, section.id]
  );

  const activeTests = useSelector((state) => (selectActiveBySectionId as any)(state, section.id));

  const firstMainSectionIndex = useMemo(() => {
    return sections.findIndex((s: USection) => s.location === 'main' || !s.location);
  }, [sections]);
  const lastMainSectionIndex = useMemo(() => {
    //  @ts-ignore
    return sections.findLastIndex((s: USection) => s.location === 'main' || !s.location);
  }, [sections]);

  const lastHeaderSectionIndex = useMemo(() => {
    //  @ts-ignore
    return sections.findLastIndex((s: USection) => s.location === 'header');
  }, [sections]);

  const firstFooterSectionIndex = useMemo(() => {
    return sections.findIndex((s: USection) => s.location === 'footer');
  }, [sections]);

  const location = useMemo(() => {
    //  null represent the main container here
    return sectionIndex < firstMainSectionIndex ? 'header' : sectionIndex > lastMainSectionIndex ? 'footer' : null;
  }, [sectionIndex, firstMainSectionIndex, lastMainSectionIndex]);

  const sectionHandlers: SectionHandlersInterface = useMemo(
    () => ({
      sectionIndex,
      lastIndex: sections.length - 1,
      onCreateElement: handleCreateElement,
      onRemove: handleRemove,
      onMove: handleMove,
      onDuplicate: handleDuplicate,
      setActiveSection,
      hideActiveSection,
      isActive: activeSection === section.id,
      initializeABTest,
      initializeCarousel,
      discardABTest,
      containerId,
      containerLength,
      containerIndex,
      onContainerIndexChange,
      addCarouselChild,
      removeCarouselChild,
      updateContainerProps,
      container: section,
      type: section.type,
      activeTest: activeTests.length && activeTests[0],
      renderHeader: firstMainSectionIndex === sectionIndex,
      renderFooter: lastMainSectionIndex === sectionIndex,
      firstMainSectionIndex,
      lastHeaderSectionIndex,
      firstFooterSectionIndex,
      lastMainSectionIndex,
      pageId,
      sections,
      markSectionAsFresh,
      activeSection,
      section,
    }),
    [
      handleCreateElement,
      handleRemove,
      handleMove,
      handleDuplicate,
      activeSection,
      hideActiveSection,
      setActiveSection,
      initializeABTest,
      discardABTest,
      containerId,
      containerLength,
      containerIndex,
      onContainerIndexChange,
      activeTests,
      addCarouselChild,
      initializeCarousel,
      pageHeaderLayout,
      removeCarouselChild,
      section,
      sectionIndex,
      updateContainerProps,
      pageId,
    ]
  );

  const sectionUiHandlers = useMemo(
    () => ({
      open: setActiveSection,
      close: hideActiveSection,
      isOpen: activeSection === section.id,
      shouldApplyOpacity,
      applyOpacity,
      isRichTextToolbarActive,
      setIsRichTextToolbarActive,
      pageType: pageType,
      itemType,
    }),
    [
      activeSection,
      applyOpacity,
      isRichTextToolbarActive,
      hideActiveSection,
      section.id,
      setActiveSection,
      shouldApplyOpacity,
      setIsRichTextToolbarActive,
      itemType,
    ]
  );

  return (
    <>
      {!site.is_liquid_enabled && sectionHandlers.renderHeader && (
        <HeaderRenderer siteDomain={siteDomain} itemType={itemType} layout={layout} pageId={pageId} />
      )}
      <Container
        content={activeChild || section}
        onContentChange={onContentChange}
        sectionHandlers={sectionHandlers}
        sectionUiHandlers={sectionUiHandlers}
      />
      <AddSectionButton
        sections={sections}
        pageId={pageId}
        sectionIndex={sectionIndex}
        markSectionAsFresh={markSectionAsFresh}
        location={location}
        sectionId={section.id}
        section={section}
        page={page}
        showAddSectionTooltip={showAddSectionTooltip}
        setShowAddSectionTooltip={setShowAddSectionTooltip}
        activeSection={activeSection}
        defaultDesktopWidth={props?.site?.styles?.layout?.section?.desktop_width}
      />
      {!site.is_liquid_enabled && sectionHandlers.renderFooter && (
        <FooterRenderer siteDomain={siteDomain} itemType={itemType} layout={layout} pageId={pageId} />
      )}
    </>
  );
}

export default SectionRendererWrapper;
