import React, { useRef } from 'react';
import classnames from 'classnames';

import NativeMediaUploader from './NativeMediaUploader';

import styles from '../MediaDrawerBottom.module.scss';

export default ({
  option: { type, Icon, category },
  active,
  onSelect,
  updateUploadingStatus,
  disabledUploads = [],
}) => {
  const uploaderRef = useRef();
  return (
    <div onClick={onSelect} className={classnames(styles.option, { [styles.active]: active })} data-test-id={type}>
      {Icon}
      <span>{type}</span>
      {Boolean(active && ['Images', 'Icons', 'PDFs', 'Videos'].includes(type)) && (
        <NativeMediaUploader
          uploaderRef={uploaderRef}
          category={category}
          onChange={() => {}}
          updateUploadingStatus={updateUploadingStatus}
          disabledUploads={disabledUploads}
        />
      )}
    </div>
  );
};
