import React from 'react';
import { set, del, insert, push, assign } from 'object-path-immutable';

import { Group, Label, Tray } from '../../../../../../Editor/Toolbars2/Toolbar2';
import ColorPickerNew from '../../../../../../Editor/Toolbars2/components/ColorPickerNew';
import { colorToRGBA } from '../../../../../../Editor/Toolbars2/helpers';
import { IconBackgroundColor } from '../../../../../../Editor/Toolbars2/icons';
import { UBoxInfo, USectionDevices, USectionProps } from 'types/USection';
import { getDeviceTypeToSaveTo } from 'components/unstack-components/Component/util/helpers/deviceHelper';
import { useSelector } from 'react-redux';
import { getDevice } from 'reducers/uiReducer';

type Props = {
  content: USectionDevices;
  contentKey: string;
  dataRef: UBoxInfo;
  onChange: (content: USectionDevices, key?: string, multi?: boolean) => void;
  startOpen?: boolean;
};

const SectionToolbar: React.FunctionComponent<Props> = ({ dataRef, onChange, content, contentKey, startOpen }) => {
  const device = useSelector(getDevice);

  const handleColorChange = (color: string) => {
    const splitKey = contentKey.split('.');
    onChange(
      set(content, [getDeviceTypeToSaveTo(device, true), ...splitKey.slice(1), 'backgroundColor'], colorToRGBA(color)),
      `content.${splitKey[0]}`
    );
  };

  const handleOpacityChange = (valueOpacity: number) => {
    if (valueOpacity <= 100 && valueOpacity >= 0) {
      const color = colorToRGBA(dataRef.backgroundColor);
      const updatedRGBA = color.replace(/[\d\.]+\)/, `${valueOpacity / 100})`);
      const splitKey = contentKey.split('.');
      onChange(
        set(content, [getDeviceTypeToSaveTo(device, true), ...splitKey.slice(1), 'backgroundColor'], updatedRGBA),
        `content.${splitKey[0]}`
      );
    }
  };

  return (
    <Tray
      renderIcon={(trayIsOpen: Boolean) => (
        <IconBackgroundColor
          data-test-id="background-color-control"
          style={{
            transition: 'transform 0.3s',
            transform: trayIsOpen ? 'rotate(30deg)' : 'rotate(0deg)',
          }}
          active={!!(dataRef && dataRef.backgroundColor)}
        />
      )}
      startOpen={startOpen}
      trayLabel="Background color"
      renderTray={() => (
        <>
          <Group>
            <Label>Fill:</Label>
            <ColorPickerNew
              color={dataRef.backgroundColor}
              onChange={handleColorChange}
              handleOpacityChange={handleOpacityChange}
              opacity={dataRef.backgroundOpacity}
              isSectionToolbar
            />
          </Group>
        </>
      )}
    />
  );
};

export default SectionToolbar;
