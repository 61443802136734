import React from 'react';

import { VimeoEmbed, YoutubeEmbed, WistiaEmbed } from './VideoPlayers';
import { processUrl, getSourceType, isUrlValid } from '../../../lib/process-video-urls';

// Returns true if entity.src is a smartUrl of a supported embed type.
export function isEmbedMediaEntity(entity) {
  return entity.type === 'video' || (entity.src && !!entity.src.match(/^(youtube|vimeo|wistia):/));
}

// If props.entity matches a supported embed type, returns props.entity
// untouched. Otherwise returns an empty object {}.
function getEntityFromProps(props) {
  return isEmbedMediaEntity(props.entity) ? props.entity : {};
}

class EmbedMedia extends React.PureComponent {
  constructor(props) {
    super();

    this.players = {
      youtube: YoutubeEmbed,
      vimeo: VimeoEmbed,
      wistia: WistiaEmbed,
    };

    this.state = {
      entity: {
        ...getEntityFromProps(props),
        type: getSourceType(props.entity.src),
        isValid: isUrlValid(props.entity.src),
      },
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.entity !== this.props.entity) {
      this.setState({
        entity: getEntityFromProps(this.props),
      });
    }
  }

  // Stores the target value in entity.rawSrc, and sets entity.src to an embed
  // smart url if the rawSrc matches
  handleChange = (e) => {
    const value = e.target.value;

    const entity = {
      rawSrc: value,
    };
    const [type, smartUrl, isValid] = processUrl(value);

    if (smartUrl) {
      entity.src = smartUrl;
      entity.type = type;
      entity.isValid = isValid;
    }

    this.setState({ entity });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.onChange(this.state.entity);
  };

  render() {
    const { entity } = this.state;
    const PlayerEmbed = this.players[entity.type];

    return (
      <form onSubmit={this.handleSubmit}>
        {entity.src && entity.isValid && (
          <fieldset>
            <div className="video-embed">
              <PlayerEmbed entity={entity} />
            </div>
          </fieldset>
        )}
        <fieldset>
          <input
            type="text"
            autoComplete="off"
            placeholder="Paste Youtube, Vimeo, or Wistia video URL"
            autoFocus={true}
            value={entity.rawSrc || ''}
            onChange={this.handleChange}
          />
          <input
            type="submit"
            className="button button-primary button-full"
            value="Save changes"
            disabled={!entity.src}
          />
        </fieldset>
      </form>
    );
  }
}

export default EmbedMedia;
