import React from 'react';
import { useSelector } from 'react-redux';
import { selectHasPermission } from 'reducers/policyReducer';

import ExpansionPanel from 'components/AdminPanel/Quickstart/ExpansionPanel';
import Domain from 'components/AdminPanel/Settings/Domain';
import { getAreDomainSettingsConfirmed } from 'components/AdminPanel/Quickstart/helpers';
import styles from 'components/AdminPanel/Quickstart/ConfirmDomainSettingsPanel.module.scss';
import { selectActiveSite } from 'reducers/sitesReducer';
import {
  CONFIRM_DOMAIN_SETTINGS_PENDING,
  CONFIRM_DOMAIN_SETTINGS_COMPLETE,
} from 'components/AdminPanel/Quickstart/constants';
import { patchOnboarding, removeOnboardingStep } from 'actions/siteActions';
import { useQuickstartStep } from 'components/AdminPanel/Quickstart/hooks/useQuickstartStep';

export default function ConfirmDomainSettingsPanel() {
  const hasPermission = useSelector(selectHasPermission);
  const activeSite = useSelector(selectActiveSite);
  const isStepComplete = getAreDomainSettingsConfirmed();
  const siteMetaData = activeSite.metadata?.onboarding || [];
  const { open, handleExpansionPanelClick, handleIconClick } = useQuickstartStep({
    isStepComplete,
    metadata: siteMetaData,
    id: activeSite.id,
    pendingDescription: CONFIRM_DOMAIN_SETTINGS_PENDING,
    completedDescription: CONFIRM_DOMAIN_SETTINGS_COMPLETE,
    patchOnboarding,
    removeOnboardingStep,
  });

  return (
    <ExpansionPanel
      title="Confirm your domain settings"
      isComplete={isStepComplete}
      open={open}
      onClick={handleExpansionPanelClick}
      onIconClick={handleIconClick}
    >
      <div className={styles.domainContainer}>{hasPermission('Site:set_domain', 'role') && <Domain />}</div>
    </ExpansionPanel>
  );
}
