import React from 'react';
import last from 'lodash/last';
import { connect } from 'react-redux';

import AddSection from './AddSection';

import * as pageSelectors from '../../reducers/pagesReducer';
import * as sectionSelectors from '../../reducers/sectionsReducer';
import * as uiSelectors from '../../reducers/uiReducer';

import RenderedSection from './RenderedSection';
import { USite } from 'types/USite';
import { USection } from 'types/USection';
import { UPage } from 'types/UPage';
import { getActiveSection } from 'reducers/toolbarReducer';
import { setActiveSection } from 'actions/toolbarActions';

function mapStateToProps(state: any, props: { pageId: string }) {
  const pageId = props.pageId;
  const sectionIds = pageSelectors.selectPageSectionIds(state, pageId);
  const pageSectionPlaceholder = pageSelectors.selectPageSectionPlaceholder(state, pageId);
  const sections = sectionSelectors.selectSections(state, sectionIds);
  const { sectionInMotion } = uiSelectors.selectUiState(state);
  const device = uiSelectors.getDevice(state);
  const activeSection = getActiveSection(state);

  return {
    pageId,
    page: pageSelectors.selectPage(state, pageId),
    sections,
    sectionInMotion,
    pageSectionPlaceholder,
    device,
    activeSection,
  };
}

const mapDispatchToProps = {
  setActiveSection: setActiveSection,
};

type SectionsState = {
  freshSectionId: string | null;
  showAddSectionTooltip: boolean;
};

type SectionsProps = {
  page: UPage;
  pageId: string;
  pageHeaderLayout?: string;
  pageFooterLayout?: Element | string;
  sections: USection[];
  pageSectionPlaceholder: string;
  site?: USite;
  dataTable?: string;
  sectionInMotion: string;
  device: string;
  setActiveSection: (activeSection: string) => {
    type: string;
    activeSection: string;
  };
  activeSection: string;
};

class Sections extends React.PureComponent<SectionsProps, SectionsState> {
  state = {
    freshSectionId: null,
    showAddSectionTooltip: this.props.page?.meta?.showAddSectionTooltip || false,
  } as SectionsState;

  componentDidUpdate(prevProps: SectionsProps, prevState: SectionsState) {
    if (prevState.freshSectionId !== this.state.freshSectionId) {
      this.updateActiveSection(this.state.freshSectionId);
    }
  }

  markSectionAsFresh = (sectionId: string) => {
    this.setState({ freshSectionId: sectionId });
  };

  setShowAddSectionTooltip = (value: boolean) => {
    this.setState({ showAddSectionTooltip: value });
  };

  updateActiveSection = (sectionId: string | boolean) => {
    this.props.setActiveSection(sectionId as string);
  };

  render() {
    const {
      pageId,
      page,
      pageHeaderLayout,
      pageFooterLayout,
      sections,
      pageSectionPlaceholder,
      site,
      dataTable,
      sectionInMotion,
    } = this.props;

    // Select section componts by their type, then render them.
    const renderedSections = sections.map((section: USection, i: number) => {
      //  Unknown scenerio adds 'null' as a section which is filtered here
      if (section) {
        return (
          <RenderedSection
            pageId={pageId}
            page={page}
            showAddSectionTooltip={this.state.showAddSectionTooltip}
            setShowAddSectionTooltip={this.setShowAddSectionTooltip}
            key={section.id}
            sections={sections}
            updateActiveSection={this.updateActiveSection}
            freshSectionId={this.state.freshSectionId}
            sectionInMotion={sectionInMotion}
            activeSection={this.props.activeSection}
            markSectionAsFresh={this.markSectionAsFresh}
            sectionIndex={i}
            pageHeaderLayout={pageHeaderLayout}
            pageFooterLayout={pageFooterLayout}
            pageSectionPlaceholder={pageSectionPlaceholder}
            site={site}
            dataTable={dataTable}
          />
        );
      } else {
        return null;
      }
    });

    // Get background color of last section
    const lastSection = last(sections) || {};

    return (
      <main
        className="content"
        style={{ position: 'relative', paddingTop: '1px' }}
        data-test-id="main-sections-container"
      >
        {renderedSections}
        {!sections.length && <AddSection pageId={pageId} markSectionAsFresh={this.markSectionAsFresh} />}
      </main>
    );
  }
}

// Connect and export the container component
export default connect(mapStateToProps, mapDispatchToProps)(Sections);
