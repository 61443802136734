import React from 'react';
import classnames from 'classnames';

import { FormTagArgs } from 'components/unstack-components/tag-types';
import RenderedForm from 'components/Editor/EmbeddedForm/RenderedForm';
import useActiveSite from 'hooks/useActiveSite';

const SystemForm: React.FunctionComponent<FormTagArgs> = (props) => {
  const site = useActiveSite();
  const renderedFormProps = {
    value: {
      type: 'unstack_form',
      systemFormId: props.type,
      site_id: site.id,
    },
    formAlignment: 'center',
    integrationsFetching: false,
    contactLists: {},
  };

  return <RenderedForm {...renderedFormProps} />;
};

export default SystemForm;
