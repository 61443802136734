import * as types from '../actions/actionTypes';
import { combineReducers } from 'redux';
import { createSelector } from 'reselect';

// ----------------------------------------------------------------------------
// Reducer
// ----------------------------------------------------------------------------

const INITIAL_STATE = {
  submissionAction: {
    type: 'message',
    message: 'Thank you for submitting this form.',
  },
  fields: [
    {
      id: 103,
      name: 'full_name',
      type: 'text',
      default: 'Full name',
      placeholder: 'Full name',
      label: '',
    },
    {
      id: 104,
      name: 'email',
      type: 'email',
      default: 'Email',
      placeholder: 'Email address',
      required: true,
      label: '',
    },
    {
      id: 110,
      name: 'organization',
      type: 'text',
      default: 'Company name',
      placeholder: 'Company name',
      label: '',
    },
  ],
  submitValue: 'Submit',
  name: '',
  isAwaiting: false,
  currentPickedFormId: null,
  contactListsIds: {},
};

function inProgressFormReducer(state = INITIAL_STATE, { payload, type }) {
  switch (type) {
    case types.FORM_UPDATE_SUBMISSION_ACTION:
      return {
        ...state,
        submissionAction: payload,
      };

    case types.FORM_UPDATE_SUBMIT_BUTTON_VALUE:
      return {
        ...state,
        submitValue: payload,
      };

    case types.FORM_FIELD_ADD_OR_UPDATE:
      return {
        ...state,
        fields: [...state.fields, payload],
      };

    case types.FORM_REMOVE_FIELD:
      return {
        ...state,
        fields: payload,
      };

    case types.FORM_UPDATE_FIELDS:
      return {
        ...state,
        fields: payload,
      };

    case types.FORM_UPDATE_ZAP_STATUS:
      return {
        ...state,
        hasZap: payload,
      };

    case types.FORM_UPDATE_NAME:
      return {
        ...state,
        name: payload,
      };

    case types.FORM_CLEAR_DATA:
      return {
        ...INITIAL_STATE,
      };

    case types.FORM_AWAIT_SAVE_REQUEST:
      return {
        ...state,
        isAwaiting: true,
      };

    case types.FORM_SAVE_REQUEST_DONE:
      return {
        ...state,
        isAwaiting: false,
      };
    case types.FORM_STORE_SUGGESTED_PICKED_ID:
      return {
        ...state,
        currentPickedFormId: payload,
      };

    case types.FORM_UPDATE_LIST:
      return {
        ...state,
        contactListsIds: {
          ...state.contactListsIds,
          [payload.integration]: payload.listId,
        },
      };

    case types.FORM_UPDATE_RESPONDERS:
      return {
        ...state,
        responders: { ...state.responders, ...payload },
      };
    default:
      return state;
  }
}

function isFetchingReducer(state = null, action) {
  switch (action.type) {
    case types.FORMS_REQUEST_LIST:
      return true;

    case types.FORMS_RECEIVE_LIST:
      return false;

    case types.FORM_CLEAR_DATA:
      return null;

    default:
      return state;
  }
}

function availableFormsReducer(state = [], action) {
  switch (action.type) {
    case types.FORMS_RECEIVE_LIST:
      return {
        ...action.payload,
      };
    default:
      return state;
  }
}

export default combineReducers({
  inProgressForm: inProgressFormReducer,
  isFetching: isFetchingReducer,
  availableForms: availableFormsReducer,
});

// ----------------------------------------------------------------------------
// Selectors
// ----------------------------------------------------------------------------

const selectDraftsFromAllContent = (state) => state.allContent.draftsById;

function selectLocalState(globalState) {
  return globalState.sparkForms;
}

export function selectIsFetching(globalState) {
  const state = selectLocalState(globalState);
  return state.isFetching;
}

const selectInProgressFormFromState = (state) => state.sparkForms.inProgressForm;

export const selectSubmissionAction = createSelector(
  [selectInProgressFormFromState],
  (sparkForms) => sparkForms.submissionAction
);

export const selectSubmitButtonValue = createSelector(
  [selectInProgressFormFromState],
  (sparkForms) => sparkForms.submitValue
);

export const selectContactListId = createSelector(
  [selectInProgressFormFromState],
  (sparkForms) => sparkForms.contactListsIds
);

export const selectResponders = createSelector([selectInProgressFormFromState], (sparkForms) => sparkForms.responders);

export const selectFieldsFromForm = createSelector([selectInProgressFormFromState], (sparkForms) => sparkForms.fields);

export const selectFormName = createSelector([selectInProgressFormFromState], (sparkForms) => sparkForms.name);

export const selectSectionFormById = createSelector([selectDraftsFromAllContent], (drafts) => drafts);

export const selectIsAwaitingForRequest = createSelector(
  [selectInProgressFormFromState],
  (sparkForms) => sparkForms.isAwaiting
);

export function selectAvailableFormById(state, formId) {
  const sparkForms = selectLocalState(state);
  return sparkForms.availableForms && sparkForms.availableForms[formId];
}

export const selectAll = createSelector([selectLocalState], (sparkForms) => sparkForms.availableForms);

export const selectAllByName = createSelector([selectAll], (availableForms) =>
  Object.assign({}, ...Object.values(availableForms).map((form) => ({ [form.name]: form })))
);

export const selectPickedFormId = createSelector(
  [selectInProgressFormFromState],
  (sparkForms) => sparkForms.currentPickedFormId
);

export const selectHasZap = createSelector([selectInProgressFormFromState], (sparkForms) => sparkForms.hasZap);
