import React from 'react';
import { useSelector } from 'react-redux';
import * as Klaviyo from '../../../../lib/tracking/klaviyo';

import icon from '../../../../assets/images/icon-activity-zero-state.svg';
import ZeroInsightsState from '../ZeroInsightsState';
import Spinner from '../../../base/Spinner';
import ViewStatistics from '../../../Statistics/ViewStatistics';
import { ActivityReport } from '../Reports';
import { useFilterState, usePagesEngagementData } from '../report-hooks';
import * as accountReducer from '../../../../reducers/accountReducer';

export default function ActivityTab() {
  const filter = useFilterState();
  const [data, isLoading] = usePagesEngagementData(filter);
  const email = useSelector(accountReducer.selectAccountEmail);

  React.useEffect(() => {
    if (data && email) {
      const viewCount = Object.values(data).reduce((prev, curr) => prev + curr.views, 0);
      try {
        if (process.env.NODE_ENV !== 'development') {
          Klaviyo.identify({ page_views: viewCount, $email: email });
        }
      } catch (e) {
        console.log(e);
      }
    }
  }, [data, email]);

  return (
    <section>
      {isLoading && <Spinner className="fixed" />}
      {!isLoading && !data.length && (
        <ZeroInsightsState
          icon={icon}
          header="No traffic to report"
          body="There is no visitor traffic that matches your filter settings. Publish more content or change your settings."
        />
      )}
      {!isLoading && Boolean(data.length) && (
        <>
          <header>
            <h1>Traffic</h1>
            <small>See how your content is performing.</small>
          </header>
          <ViewStatistics showUsers pageType="all" />
          <header>
            <h1>Engagement</h1>
            <small>See what content your visitors find most engaging.</small>
          </header>
          <ActivityReport data={data} />
        </>
      )}
    </section>
  );
}
