import * as actionTypes from '../actions/actionTypes';
import { createSelector } from 'reselect';

// ----------------------------------------------------------------------------
// Reducer
// ----------------------------------------------------------------------------

const initState = {
  isFetching: false,
  lastUpdated: null,
  error: null,
  byId: {},
  allIds: [],
};

export default function goalsReducer(state = initState, action) {
  switch (action.type) {
    case actionTypes.GOALS_REQUEST_ALL:
      return {
        ...state,
        isFetching: true,
      };

    case actionTypes.GOALS_RECEIVE_ALL: {
      const { goalIds, entities, receivedAt } = action.payload;

      return {
        isFetching: false,
        lastUpdated: receivedAt,
        error: null,
        allIds: goalIds,
        byId: entities.goals || {},
      };
    }

    default:
      return state;
  }
}

// ----------------------------------------------------------------------------
// Selectors
// ----------------------------------------------------------------------------

export function selectLocalState(globalState) {
  return globalState.goals;
}

export function selectHasFetched(globalState) {
  const state = selectLocalState(globalState);
  return state.lastUpdated !== null || state.isFetching;
}

export function selectAllIds(globalState) {
  const state = selectLocalState(globalState);
  return state.allIds;
}

export function selectById(globalState) {
  const state = selectLocalState(globalState);
  return state.byId;
}

export const selectAll = createSelector(selectAllIds, selectById, (allIds, byId) => allIds.map((id) => byId[id]));
