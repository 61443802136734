import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';
import formatDistance from 'date-fns/formatDistance';
import isSameYear from 'date-fns/isSameYear';
import format from 'date-fns/format';

export default function relativeDate(dirtyDate, defaultMessage) {
  try {
    const now = new Date();
    const date = Date.parse(dirtyDate);
    if (!date) throw 'No date ¯\\_(ツ)_//¯'; // eslint-disable-line no-throw-literal
    const days = differenceInCalendarDays(date, now);

    // prettier-ignore
    return days === 0
      ? `${formatDistance(date, now)} ago` : days === -1
      ? 'Yesterday'                        : days > -6
      ? format(date, 'EEEE')               : isSameYear(date, now)
      ? format(date, 'MMM d')              : format(date, 'MMM d, yyyy');
  } catch (err) {
    return defaultMessage || 'Just now';
  }
}
