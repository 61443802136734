import React from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import isEqual from 'lodash/isEqual';
import pick from 'lodash/pick';
import VisibilitySensor from 'react-visibility-sensor';
import { formatBytes } from '../../../../lib/byte-to-megabytes';

import Spinner from '../../Spinner';
import Search from '../../../Search';
import MediaNoResults from './MediaNoResults';
import { MediaOptionsDrawer } from './MediaOptionsDrawer';
import { ReactComponent as SvgPexels } from '../../../../assets/images/icon-media-pexels.svg';

import * as mediaActions from '../../../../actions/mediaActions';
import * as mediaSelectors from '../../../../reducers/mediaReducer';

import styles from './Pexels.module.scss';

function mapStateToProps(state) {
  const pexelsMedia = mediaSelectors.selectPexels(state);
  const { media, isFetching, pages, currentPage, downloadProgress, isDownloading } = pexelsMedia;

  return {
    media,
    isFetching,
    pages,
    currentPage,
    downloadProgress,
    isDownloading,
  };
}

const mapDispatchToProps = {
  searchPexels: mediaActions.searchPexels,
  clearPexels: mediaActions.clearPexels,
  downloadPexelsMedia: mediaActions.downloadPexelsMedia,
};

class Pexels extends React.PureComponent {
  state = {
    lastQuery: '',
    debouncedQuery: '',
    lastSeenPhotoRow: 0,
    lastPage: 1,
    selectedMedia: {},
    activeFilter: 'image',
    media: [],
  };

  componentDidMount() {
    this.clearPexels();
  }

  componentDidUpdate(nextProp, nextState) {
    if (this.state.lastQuery !== nextState.lastQuery && !this.state.lastQuery) this.setState({ lastSeenPhotoRow: 0 });

    if (this.state.activeFilter !== nextState.activeFilter) {
      this.props.clearPexels();
    }

    if (!isEqual(this.props.media, nextProp.media)) {
      this.setState({ media: this.props.media });
    }

    if (this.state.debouncedQuery !== nextState.debouncedQuery) {
      this.props.clearPexels();
      this.state.debouncedQuery && this.props.searchPexels(this.state.debouncedQuery, 1, this.state.activeFilter);
    }
  }

  componentWillUnmount() {
    this.props.clearPexels();
  }

  clearPexels() {
    this.props.clearPexels();
    this.setState({
      lastQuery: '',
      lastSeenPhotoRow: 0,
      lastPage: 1,
      debouncedQuery: '',
      media: [],
    });
  }

  handleClick = (photo) => {
    const category = this.state.activeFilter;

    this.setState({ isDownloading: true });

    const media = category === 'image' ? photo.src : photo.video_files.find((v) => v.quality === 'sd');

    const url = media.link || media.large2x;
    const extension = category === 'image' ? 'jpeg' : media.file_type.split('/')[1];

    this.props
      .downloadPexelsMedia(url, category)
      .promise.then((blob) => {
        const file = new File([blob], (photo.alt_description || photo.url.split('/')[4]) + '.' + extension);

        const megabytes = formatBytes(file.size);

        if (megabytes <= 10) {
          this.props
            .createMedia(file, category)
            .promise.then((media) => {
              const { id } = media;
              this.props.onChange(`media:${id}`, photo.alt_description, photo.options);
            })
            .catch((errors) => {
              const { message } = errors;
              this.setState({
                errorMessage: message || 'Something went wrong.',
              });
            });
        } else {
          this.props.onChange(url, photo.alt_description, photo.options, true);
        }
      })
      .catch((errors) => {
        this.setState({
          errorMessage: errors.message,
        });
      });
  };

  onLoadMoreVisibilityChange(isVisible) {
    const { currentPage, pages, isFetching } = this.props;
    if (currentPage < pages && isVisible && !isFetching && this.state.debouncedQuery) {
      this.props.searchPexels(this.state.debouncedQuery, currentPage + 1, this.state.activeFilter);
    }
  }

  render() {
    const { isFetching, gridPhotoHeight, downloadProgress, isDownloading } = this.props;
    const { media, activeFilter, lastQuery, selectedMedia } = this.state;

    const hasMedia = media && media.length > 0;

    return (
      <>
        <Search
          onChange={(value) => {
            this.setState({ lastQuery: value });
          }}
          containerStyle={{ position: 'absolute', top: '20px' }}
          placeholder={`Search ${activeFilter}`}
          debounceValue={(debouncedQuery) => this.setState({ debouncedQuery })}
          enableDarkMode
          value={lastQuery}
          autofocus={!selectedMedia.id}
        />
        <div className={styles.filter}>
          {!this.props.hideVideo && (
            <span
              className={classnames({
                [styles.active]: activeFilter === 'video',
              })}
              onClick={() =>
                this.setState({
                  activeFilter: 'video',
                  media: [],
                  lastQuery: '',
                })
              }
            >
              Videos
            </span>
          )}
          <span
            className={classnames({
              [styles.active]: activeFilter === 'image',
            })}
            onClick={() => this.setState({ activeFilter: 'image', media: [], lastQuery: '' })}
          >
            Photos
          </span>
        </div>
        <div className={styles.sourceLink}>
          Photos & videos by{' '}
          <a href="https://www.pexels.com/" target="_blank" rel="noopener noreferrer">
            Pexels
          </a>
        </div>
        {hasMedia && !isFetching && (
          <>
            {activeFilter === 'image' ? (
              <div
                className={classnames(styles.Unsplash, {
                  [styles.addPadding]: selectedMedia.id,
                })}
              >
                {media.map((photo) => (
                  <div
                    className={classnames(styles.content, {
                      [styles.selected]: selectedMedia.id === photo.id,
                    })}
                    key={photo.id}
                  >
                    <figure>
                      <img
                        src={
                          photo.src.original + '?auto=compress&cs=tinysrgb&dpr=1&fit=crop&w=280&h=' + gridPhotoHeight
                        }
                        onClick={() =>
                          this.setState({
                            selectedMedia: selectedMedia.id === photo.id ? {} : photo,
                          })
                        }
                        alt=""
                      />
                      <figcaption>
                        <a href={photo.photographer_url} target="_blank" rel="noopener noreferrer">
                          {photo.photographer}
                        </a>
                      </figcaption>
                    </figure>
                  </div>
                ))}
                {selectedMedia.id && (
                  <MediaOptionsDrawer
                    updateEntityOptions={(e) => {
                      this.setState({
                        selectedMedia: {
                          ...selectedMedia,
                          alt_description: e.target.value,
                        },
                      });
                    }}
                    entityOptions={{
                      altText: selectedMedia.alt_description,
                    }}
                    onSave={() => (isDownloading ? null : this.handleClick(selectedMedia))}
                    onRemove={() => {}}
                    entity={this.props.entity}
                    isSectionContent={false}
                    showLoader={this.state.isDownloading}
                  />
                )}
              </div>
            ) : (
              <div
                className={classnames(styles.Unsplash, {
                  [styles.addPadding]: selectedMedia.id,
                })}
              >
                {media.map((photo) => (
                  <div
                    className={classnames(styles.video, styles.content, {
                      [styles.selected]: selectedMedia.id === photo.id,
                    })}
                    key={photo.id}
                  >
                    <figure>
                      <video
                        poster={photo.image}
                        onMouseOver={(event) => {
                          event.target.play();
                        }}
                        onMouseOut={(event) => {
                          event.target.pause();
                        }}
                        src={photo.video_files.find((v) => v.quality == 'sd').link}
                        onClick={() =>
                          this.setState({
                            selectedMedia: selectedMedia.id === photo.id ? {} : photo,
                          })
                        }
                        alt=""
                      />
                      <figcaption>
                        <a href={photo.user.url} target="_blank" rel="noopener noreferrer">
                          {photo.user.name}
                        </a>
                      </figcaption>
                    </figure>
                  </div>
                ))}
                {selectedMedia.id && (
                  <MediaOptionsDrawer
                    updateEntityOptions={(e) => {
                      if (e.target.type === 'checkbox')
                        this.setState({
                          selectedMedia: {
                            ...selectedMedia,
                            options: {
                              ...selectedMedia.options,
                              [e.target.name]: e.target.checked,
                            },
                          },
                        });
                      else
                        this.setState({
                          selectedMedia: {
                            ...selectedMedia,
                            [e.target.name]: e.target.value,
                          },
                        });
                    }}
                    isVideoCategory
                    entityOptions={{
                      controls: true,
                      ...pick(selectedMedia.options, ['loop', 'controls', 'muted', 'autoplay']),
                    }}
                    onSave={() => (isDownloading ? null : this.handleClick(selectedMedia))}
                    onRemove={() => {}}
                    entity={this.props.entity}
                    isSectionContent={false}
                    showLoader={isDownloading}
                    showDownloadProgress
                    downloadProgress={downloadProgress}
                    hideVideoOptions={this.props.hideVideoOptions}
                  />
                )}
              </div>
            )}
          </>
        )}
        {hasMedia && !isFetching && (
          <VisibilitySensor onChange={(visible) => this.onLoadMoreVisibilityChange(visible)}>
            <span>&nbsp;</span>
          </VisibilitySensor>
        )}
        {!hasMedia && isFetching && <Spinner className={styles.spinner} />}
        {!hasMedia && !isFetching && (
          <MediaNoResults
            icon={<SvgPexels height="50px" width="50px" />}
            header="Find the perfect photo & video"
            content="Search thousands of professional photos and video that can help make your content stand out."
          />
        )}
      </>
    );
  }
}

Pexels = connect(mapStateToProps, mapDispatchToProps)(Pexels);

export { Pexels };
