import React from 'react';
import { set, del, insert, push, assign } from 'object-path-immutable';

import { IconAnchor, IconHelp } from '../../../../../../Editor/Toolbars2/icons';
import { Tray, Group, Label, TextInput } from '../../../../../../Editor/Toolbars2/Toolbar2';
import styles from './Properties/LayoutOption.module.scss';
import { UBoxInfo, USectionDevices } from 'types/USection';
import { getDeviceTypeToSaveTo } from 'components/unstack-components/Component/util/helpers/deviceHelper';
import { useSelector } from 'react-redux';
import { getDevice } from 'reducers/uiReducer';

type Props = {
  content: USectionDevices;
  dataRef: UBoxInfo;
  onChange: (content: USectionDevices, key?: string, multi?: boolean) => void;
  contentKey: string;
};

const AnchorLink: React.FunctionComponent<Props> = ({ dataRef, onChange, content, contentKey }) => {
  const { anchor, classes = '' } = dataRef;
  const device = useSelector(getDevice);

  const isActive = dataRef && (anchor || classes);
  const idRegex = /[\w-]/;
  const classRegex = /[\s\w-]/;
  const splitKey = contentKey.split('.');

  return (
    <Tray
      renderIcon={() => <IconAnchor style={{ height: '20px' }} active={!!isActive} />}
      trayLabel="CSS Selector"
      formatLabel={false}
      renderTray={() => (
        <Group>
          <Label>ID:</Label>
          <TextInput
            className={styles.TextInput}
            value={anchor}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              onChange(
                set(content, [getDeviceTypeToSaveTo(device, true), ...splitKey.slice(1), 'anchor'], e.target.value),
                `content.${splitKey[0]}`
              )
            }
            border
            style={{ minWidth: '90px', marginRight: '16px' }}
            onKeyDown={(e: KeyboardEvent) => {
              if (idRegex.test(e.key)) {
                return;
              }

              e.preventDefault();
            }}
          />
          <Label>Class:</Label>
          <TextInput
            className={styles.TextInput}
            value={classes}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              onChange(
                set(content, [getDeviceTypeToSaveTo(device, true), ...splitKey.slice(1), 'classes'], e.target.value),
                `content.${splitKey[0]}`
              )
            }
            border
            style={{ minWidth: '90px' }}
            onKeyDown={(e: KeyboardEvent) => {
              if (classRegex.test(e.key)) {
                return;
              }

              e.preventDefault();
            }}
          />
          <div
            className={styles.helpIcon}
            data-tip="<span>Specify a custom ID or classes for<br /> use in scripts, styles and links</span>"
            data-place="bottom"
            data-html="true"
          >
            <IconHelp />
          </div>
        </Group>
      )}
    />
  );
};

export default AnchorLink;
