import ErrorBoundary from 'components/ErrorBoundary';
import React, { useMemo, useState } from 'react';
import { ReactComponent as SvgIconTrash } from '../../../../assets/images/icon-remove-field.svg';
import { IconError } from 'components/base/BaseIcons';

import rgbaToHex from '../../../../lib/rgbaToHex';

import styles from './ColorStyles.module.scss';

export const ColorItem = ({ color, removeColor, setUpdating, setValue, toggleDrawer }) => {
  const [hovered, setHovered] = useState(false);

  const value = useMemo(() => rgbaToHex(color.code || color), [color]);
  const ColorItemCard = () => {
    return (
      <div onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)} className={styles.colorItem}>
        <div
          className={styles.colorViewer}
          style={{
            backgroundColor: color.code || color,
          }}
          onClick={() => {
            toggleDrawer(true);
            setUpdating(color);
            setValue(color);
          }}
        />
        <div className={styles.colorValue}>
          <span className={styles.value}>{value}</span>
          {hovered && !color.isDefault && (
            <div>
              <SvgIconTrash onClick={removeColor} />
            </div>
          )}
        </div>
        {color.label && <div className={styles.value}>{color.label}</div>}
      </div>
    );
  };
  return (
    <ErrorBoundary
      renderError={() => (
        <div onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)} className={styles.colorItem}>
          <div
            className={styles.colorViewer}
            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
          >
            <IconError />
          </div>
          <div className={styles.colorValue}>
            <span className={styles.value}>Error with color</span>
            {!color.label && (
              <div>
                <SvgIconTrash onClick={removeColor} />
              </div>
            )}
          </div>
          {color.label && <div className={styles.value}>{color.label}</div>}
        </div>
      )}
    >
      <ColorItemCard />
    </ErrorBoundary>
  );
};
