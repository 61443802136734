import React, { useMemo, useState, useEffect } from 'react';
import List from 'components/react-table/List';
import { useDispatch, useSelector } from 'react-redux';
import * as siteLayoutSelector from 'reducers/siteLayoutReducer';
import * as siteLayoutActions from 'actions/siteLayoutActions';

import { selectHasPermission } from 'reducers/policyReducer';
import { isEqual } from 'lodash/fp';
import EditingBar from './Elements/EditingBar';

function PageDefaults() {
  const headers = useSelector(siteLayoutSelector.selectHeaderSectionsList);
  const footers = useSelector(siteLayoutSelector.selectFooterSectionsList);
  const hasPermission = useSelector(selectHasPermission);
  const sitePageDefaults = useSelector((state) => siteLayoutSelector.selectLayoutByItemType('site_page')(state));
  const landingPageDefaults = useSelector((state) => siteLayoutSelector.selectLayoutByItemType('landing_page')(state));
  const blogPageDefaults = useSelector((state) => siteLayoutSelector.selectLayoutByItemType('blog')(state));
  const dynamicPageDefaults = useSelector((state) => siteLayoutSelector.selectLayoutByItemType('data_page')(state));
  const productPageDefaults = useSelector((state) => siteLayoutSelector.selectLayoutByItemType('product')(state));
  const collectionPageDefaults = useSelector((state) => siteLayoutSelector.selectLayoutByItemType('collection')(state));
  const systemPageDefaults = useSelector((state) => siteLayoutSelector.selectLayoutByItemType('system_page')(state));

  const dispatch = useDispatch();

  const initialValues = useMemo(
    () => ({
      site_page: sitePageDefaults,
      landing_page: landingPageDefaults,
      blog: blogPageDefaults,
      data_page: dynamicPageDefaults,
      product: productPageDefaults,
      collection: collectionPageDefaults,
      system_page: systemPageDefaults,
    }),
    [
      sitePageDefaults,
      landingPageDefaults,
      blogPageDefaults,
      dynamicPageDefaults,
      productPageDefaults,
      collectionPageDefaults,
      systemPageDefaults,
    ]
  );

  const [defaults, setDefaults] = useState(initialValues);

  useEffect(() => {
    Object.keys(initialValues).forEach((key) => {
      if (initialValues[key]?.id && initialValues[key]?.id !== defaults[key]?.id) {
        setDefaults(initialValues);
        return;
      }
    });
  }, [initialValues]);

  const data = useMemo(
    () => [
      ...(hasPermission('Page:create:::site_page')
        ? [
            {
              itemType: 'site_page',
              label: 'Site pages',
              layout: defaults['site_page'],
            },
          ]
        : []),
      ...(hasPermission('Page:create:::landing_page')
        ? [
            {
              itemType: 'landing_page',
              label: 'Landing pages',
              layout: defaults['landing_page'],
            },
          ]
        : []),
      ...(hasPermission('Blog:create')
        ? [
            {
              itemType: 'blog',
              label: 'Blog pages',
              layout: defaults['blog'],
            },
          ]
        : []),
      ...(hasPermission('Page:create:::data_page')
        ? [
            {
              itemType: 'data_page',
              label: 'Dynamic pages',
              layout: defaults['data_page'],
            },
          ]
        : []),
      ...(hasPermission('Page:create:::product')
        ? [
            {
              itemType: 'product',
              label: 'Product pages',
              layout: defaults['product'],
            },
          ]
        : []),
      ...(hasPermission('Page:create:::collection')
        ? [
            {
              itemType: 'collection',
              label: 'Collection pages',
              layout: defaults['collection'],
            },
          ]
        : []),
      {
        itemType: 'system_page',
        label: 'Account pages',
        layout: defaults['system_page'],
      },
    ],
    [defaults]
  );

  const headerOptions = useMemo(() => {
    return headers.map((header) => ({ value: header.id, label: header.name }));
  }, [headers]);

  const footerOptions = useMemo(() => {
    return footers.map((footer) => ({ value: footer.id, label: footer.name }));
  }, [footers]);

  const columns = [
    {
      Header: 'Type',
      accessor: 'label',
      Cell: ({ value }) => {
        return (
          <div style={{ textAlign: 'right', padding: '0 1rem' }}>
            <span style={{ fontWeight: 500 }}>{value}</span>
          </div>
        );
      },
      width: 200,
    },
    {
      Header: 'Header',
      id: 'header_input',
      accessor: '',
      Cell: ({ value }) => {
        const { layout, itemType } = value;
        return (
          <div>
            <SelectField
              onChange={(e) => {
                if (layout) {
                  setDefaults({ ...defaults, [itemType]: { ...layout, default_header: e.target.value || null } });
                } else {
                  setDefaults({
                    ...defaults,
                    [itemType]: { item_type: itemType, default_header: e.target.value || null },
                  });
                }
              }}
              name="default_header"
              itemType={itemType}
              options={headerOptions}
              value={layout?.default_header || ''}
            />
          </div>
        );
      },
    },
    {
      Header: 'Footer',
      accessor: '',
      Cell: ({ value }) => {
        const { layout, itemType } = value;
        return (
          <div>
            <SelectField
              onChange={(e) => {
                if (layout) {
                  setDefaults({ ...defaults, [itemType]: { ...layout, default_footer: e.target.value || null } });
                } else {
                  setDefaults({
                    ...defaults,
                    [itemType]: { item_type: itemType, default_footer: e.target.value || null },
                  });
                }
              }}
              name="default_footer"
              itemType={itemType}
              options={footerOptions}
              value={layout?.default_footer || ''}
            />
          </div>
        );
      },
    },
  ];

  const isEditing = useMemo(() => {
    return !Object.keys(defaults).every((key) => {
      const headerSame =
        initialValues[key]?.default_header === defaults[key]?.default_header ||
        (!initialValues[key]?.default_header && !defaults[key]?.default_header);
      const footerSame =
        initialValues[key]?.default_footer === defaults[key]?.default_footer ||
        (!initialValues[key]?.default_footer && !defaults[key]?.default_footer);
      return headerSame && footerSame;
    });
  }, [defaults, initialValues]);

  const [showPublishSuccess, setShowPublishSuccess] = useState(false);

  return (
    <>
      <EditingBar
        isEditing={isEditing}
        showPublishSuccess={showPublishSuccess}
        publishChanges={() => {
          setShowPublishSuccess(true);
          dispatch(siteLayoutActions.bulkUpdateOrCreate(defaults));
          setTimeout(() => {
            setShowPublishSuccess(false);
          }, 5000);
        }}
        discardChanges={() => {
          setDefaults(initialValues);
        }}
      />
      <section>
        <header>
          <h1>Page defaults</h1>
        </header>
        <List
          className="-highlight"
          data={data}
          columns={columns}
          count={0}
          defaultPageSize={7}
          loading={false}
          showViewAll={false}
          breakPageslabel={null}
          nextPageLabel={null}
          onPageChange={() => {}}
          pagesCount={0}
          activePage={0}
          previousPageLabel={null}
          showHeader
          getTheadProps={() => ({
            style: {
              zIndex: '0',
            },
          })}
        />
      </section>
    </>
  );
}

function SelectField({ options, itemType, value, name, onChange }) {
  return (
    <select onChange={onChange} name={name} key={itemType} value={value} style={{ marginBottom: 0, width: '100%' }}>
      {options.map((opt, i) => {
        return (
          <option key={`${opt.value}-${i}-${itemType}`} value={opt.value}>
            {opt.label}
          </option>
        );
      })}
      <option value={''} key={itemType}>
        {itemType === 'landing_page' ? 'Minimal' : 'Full'}
      </option>
    </select>
  );
}

export default PageDefaults;
