import * as types from '../actions/actionTypes';
import { SPARK_TLD } from '../settings';
import { applyOps } from '../lib/immutable-operations';
import { createSelector } from 'reselect';
import createCachedSelector from 're-reselect';

import union from 'lodash/union';
import result from 'lodash/result';

// ----------------------------------------------------------------------------
// Reducer
// ----------------------------------------------------------------------------

const initState = {
  allIds: [],
  byId: {},
  isFetching: null,
  error: '',
};

export default function (state = initState, action) {
  switch (action.type) {
    case types.SITE_REQUEST:
    case types.SITE_REQUEST_ALL:
    case types.SITE_SET_DOMAIN:
    case types.SITE_REMOVE_DOMAIN:
    case types.SITE_ADD_SSL:
      return {
        ...state,
        isFetching: true,
      };

    case types.SITE_RECEIVE_LIST: {
      const {
        siteIds,
        entities: { sites },
      } = action.payload;

      return {
        ...state,
        allIds: siteIds,
        byId: sites,
        isFetching: false,
      };
    }

    case types.SITE_RECEIVE:
      const { site } = action.payload;

      return {
        ...state,
        isFetching: false,
        allIds: union(state.allIds, [site.id]),
        byId: {
          ...state.byId,
          [site.id]: site,
        },
      };

    case types.SITE_UPDATE: {
      const siteId = action.payload.id || selectActiveSite({ sites: state }).id;
      const oldSite = state.byId[siteId];
      const newSite = action.payload.ops
        ? applyOps(oldSite, action.payload.ops)
        : Object.assign({}, oldSite, action.payload);

      return {
        ...state,
        byId: {
          ...state.byId,
          [siteId]: newSite,
        },
      };
    }

    case types.SITE_ON_UPDATE_ONBOARDING:
      const siteId = action.payload.id || selectActiveSite({ sites: state }).id;
      const oldSite = state.byId[siteId];
      const newSite = Object.assign({}, oldSite, action.payload);
      return {
        ...state,
        byId: {
          ...state.byId,
          [siteId]: newSite,
        },
      };

    case types.SITE_DOMAIN_FAILURE: {
      return { ...state, error: action.payload, isFetching: false };
    }
    case types.SITE_ACTIVE_URL_SITE_CHANGED: {
      const { urlSite } = action.payload;
      if (urlSite !== state.urlSite) {
        return { ...state, urlSite };
      }
    }

    default:
      return state;
  }
}

// ----------------------------------------------------------------------------
// Selectors
// ----------------------------------------------------------------------------

export function selectLocalState(globalState) {
  return globalState.sites;
}

export function selectAllIds(globalState) {
  return selectLocalState(globalState).allIds;
}

export function selectById(globalState) {
  return selectLocalState(globalState).byId;
}

export const selectAllSites = createSelector(selectAllIds, selectById, (allIds, byId) => allIds.map((id) => byId[id]));

export function selectSitesLength(globalState) {
  return selectAllIds(globalState).length;
}

export function selectHasSites(globalState) {
  return !!selectLocalState(globalState).allIds.length;
}

export function selectSitesFetching(globalState) {
  const value = selectLocalState(globalState).isFetching;
  return value === null ? true : value;
}

export function selectSiteById(globalState, id) {
  return selectById(globalState)[id];
}

// TODO Daniel upgrade fix the typing is really frustrating in site
export const selectSiteByDomain = createCachedSelector(
  selectAllSites,
  (globalState, domain) => domain,
  (sites, rawDomain) => {
    const domain = result(rawDomain, 'toLowerCase', false);
    if (!domain) return null;

    if (sites && sites.find) {
      return sites.find(
        (site) =>
          result(site, 'domain.toLowerCase', false) === domain ||
          result(site, 'subdomain.toLowerCase', false) === domain ||
          result(site, 'name.toLowerCase', false) === domain
      );
    }
  }
)((globalState, domain) => domain);

export function selectActiveSite(globalState) {
  const { urlSite } = selectLocalState(globalState);
  return selectSiteByDomain(globalState, urlSite);
}

export function getIsDnsPendingFromSite(site) {
  return (
    !site.dns_status ||
    (site.dns_status === 'ssl_pending' && site.certificate) ||
    !['active', 'ssl_pending'].includes(site.dns_status)
  );
}

export function getFullDomainFromSite(site) {
  if (!site) throw new TypeError('Missing site argument');
  if (site.domain && site.is_domain_active && (!getIsDnsPendingFromSite(site) || site.site_status === 'proxy')) {
    return site.domain;
  } else if (site.subdomain) return `${site.subdomain}.${SPARK_TLD}`;
  else return '';
}

// Get the url for the site. e.g.
//   - https://www.unstack.com
//   - https://foobar.unstacksite.com
//   - http://www.localhost:8000
export function getUrlFromSite(site) {
  if (!site) throw new TypeError('Missing site argument');
  if (site.domain && site.is_domain_active && (!getIsDnsPendingFromSite(site) || site.site_status === 'proxy')) {
    return (site.scheme || 'https') + '://' + site.domain;
  } else if (site.subdomain) {
    const scheme = process.env.NODE_ENV === 'production' ? 'https' : 'http';
    return `${scheme}://${site.subdomain}.${SPARK_TLD}`;
  } else return '';
}

export function getSiteMapUrlFromSite(site) {
  if (!site) throw new TypeError('Missing site argument');
  const fullDomain = getFullDomainFromSite(site);
  if (fullDomain) return (site.scheme || 'https') + '://' + fullDomain + '/sitemap.xml';
}

// Get the `urlSite` for a particular site. The `urlSite` is the param in the app's routing
// responsible for displaying a particular site.
export function getUrlSiteFromSite(site) {
  if (!site) throw new TypeError('Missing site argument');
  return site.subdomain;
}

export function getSiteTimezoneFromSite(site) {
  return site.timezone || 'America/New_York';
}

export function getIsDynamicTagsPages(site) {
  return site.metadata.tag_dynamic_pages_enabled;
}

export function getIntroVideoCount(site) {
  return site?.metadata?.intro_video_count || null;
}

export function getAutoShowUnstackUniversity(site) {
  return site?.metadata?.auto_show_unstack_university;
}

export const selectIsDomainFailure = createSelector(selectLocalState, (site) => site.error);

export function getTLDFromSite(site) {
  if (!site) throw new TypeError('Missing site argument');
  if (site.is_domain_active) return site.domain.match(/(\w+\.\w+)$/)[0];
}

export function getSiteLayoutStyles(site) {
  return site?.styles.layout;
}

export const selectDefaultPDP = createSelector(selectActiveSite, (site) => site.default_pdp);
export const selectDefaultPLP = createSelector(selectActiveSite, (site) => site.default_plp);

export const selectSiteFullDomain = createSelector(selectActiveSite, (site) => site.full_domain);
