import React, { useState, useCallback, useRef, useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';
import { useDispatch } from 'react-redux';
import classnames from 'classnames';

import UpDownCounter from './UpDownCounter';
import ColorPickerNew from './ColorPickerNew';
import useOnClickOutside from 'hooks/useClickOutside';
import * as addSectionActions from 'actions/addSectionActions';

import { ReactComponent as SvgIconBorderWidth } from 'assets/images/icon-border-width.svg';
import { ReactComponent as SvgIconBorderRadius } from 'assets/images/icon-border-radius-new.svg';
import { ReactComponent as SvgIconBorderNone } from 'assets/images/icon-border-none.svg';
import { ReactComponent as SvgIconBorderSolid } from 'assets/images/icon-border-solid.svg';
import { ReactComponent as SvgIconBorderDashed } from 'assets/images/icon-border-dashed.svg';
import { ReactComponent as SvgIconBorderDotted } from 'assets/images/icon-border-dotted.svg';
import { ReactComponent as SvgIconArrow } from 'assets/images/icon-cheveron-left.svg';
import { ReactComponent as SvgIconCheck } from 'assets/images/icon-check.svg';

import styles from './BorderInput.module.scss';
import { colorToRGBA } from '../helpers';

type Props = {
  borderColor: string;
  borderWidth: number | string;
  borderRadius: number;
  borderStyle: string;
  handleBorderColorChange: (value: string) => void;
  handleBorderWidthChange: (value: string | number, e?: Event) => void;
  handleBorderRadiusChange: (value: string | number, e?: Event) => void;
  handleBorderStyleChange: (value: string) => void;

  isOpen?: string;
  setIsOpen?: (value: string) => void;
};

type DrawerProps = {
  isOpen: boolean;
  onChange: (value: string) => void;
  borderStyle: string;
  setDrawerIsOpen: (value: boolean) => void;
};

type BorderProps = {
  style: string;
  onClick: () => void;
  label: string;
  icon: JSX.Element;
  current: string;
};

const BORDER_STYLE_OPTIONS: Array<string> = ['none', 'solid', 'dashed', 'dotted'];
const BORDER_STYLE_ICONS: { [key: string]: JSX.Element } = {
  '': <SvgIconBorderNone />,
  none: <SvgIconBorderNone />,
  undefined: <SvgIconBorderNone />,
  solid: <SvgIconBorderSolid />,
  dashed: <SvgIconBorderDashed />,
  dotted: <SvgIconBorderDotted />,
};

export default function BorderInput(props: Props) {
  const {
    borderColor,
    borderWidth,
    borderRadius,
    borderStyle,
    handleBorderColorChange,
    handleBorderWidthChange,
    handleBorderRadiusChange,
    handleBorderStyleChange,
  } = props;

  const [drawerIsOpen, setDrawerIsOpen] = useState(false);

  const dispatch = useDispatch();
  useEffect(() => {
    if (drawerIsOpen) dispatch(addSectionActions.requestDrawerOpen());
    else dispatch(addSectionActions.requestDrawerClose());

    if (drawerIsOpen && props.setIsOpen) props.setIsOpen('borderStyle');
  }, [drawerIsOpen]);

  useEffect(() => {
    if (props.isOpen && props.isOpen === 'borderStyle') setDrawerIsOpen(true);
    else setDrawerIsOpen(false);
  }, [props.isOpen]);

  const handleDrawerItemClick = useCallback(
    (style: any) => {
      setDrawerIsOpen(false);
      handleBorderStyleChange(style);
      props.setIsOpen(undefined);
    },
    [handleBorderStyleChange]
  );

  const handleOpacityChange = (value: number) => {
    if (value <= 100 && value >= 0) {
      const color = colorToRGBA(borderColor);
      const updatedRGBA = color.replace(/[\d\.]+\)/, `${value / 100})`);
      handleBorderColorChange(updatedRGBA);
    }
  };

  useEffect(() => {
    if (borderWidth === 0 || borderWidth === '0' || borderWidth === undefined) {
      if (borderStyle !== '' && borderStyle !== 'none' && borderStyle !== undefined) {
        handleBorderStyleChange('none');
      }
    } else if (borderStyle === '' || borderStyle === 'none' || borderStyle === undefined) {
      handleBorderStyleChange('solid');
    }
  }, [borderWidth]);

  useEffect(() => {
    if (borderStyle === '' || borderStyle === 'none' || borderStyle === undefined) {
      if (borderWidth !== 0) handleBorderWidthChange(0);
    } else if (borderStyle !== '' && borderWidth === 0) {
      handleBorderWidthChange(1);
    }
  }, [borderStyle]);

  return (
    <span className={styles.BorderPicker}>
      <ColorPickerNew
        color={borderColor}
        onChange={handleBorderColorChange}
        handleOpacityChange={handleOpacityChange}
        type="border"
        isOpen={props.isOpen}
        setIsOpen={props.setIsOpen}
      />
      <div className={styles.BorderWidthInput}>
        <SvgIconBorderWidth className={styles.WidthIcon} />
        <UpDownCounter value={borderWidth || ''} onChange={handleBorderWidthChange} min={0} max={50} />
      </div>
      <div
        className={classnames(styles.BorderStyleInput, { [styles.IsOpen]: drawerIsOpen })}
        onClick={() => {
          setDrawerIsOpen(!drawerIsOpen);
          props.setIsOpen(undefined);
        }}
      >
        {BORDER_STYLE_ICONS[borderStyle]}
        <SvgIconArrow className={styles.ArrowDown} />
        <Drawer
          isOpen={drawerIsOpen}
          onChange={handleDrawerItemClick}
          borderStyle={borderStyle}
          setDrawerIsOpen={setDrawerIsOpen}
        />
      </div>
      <div className={styles.BorderRadiusInput}>
        <SvgIconBorderRadius className={styles.RadiusIcon} />
        <UpDownCounter value={borderRadius || ''} onChange={handleBorderRadiusChange} min={0} max={50} />
      </div>
    </span>
  );
}

function Drawer(props: DrawerProps) {
  const { isOpen, onChange, borderStyle, setDrawerIsOpen } = props;

  const ref = useRef();
  useOnClickOutside(ref, () => setDrawerIsOpen(false));

  return (
    <CSSTransition in={isOpen} timeout={141.59} classNames={styles} mountOnEnter unmountOnExit>
      <div ref={ref} className={styles.Drawer}>
        <ul>
          {BORDER_STYLE_OPTIONS.map((style: string) => {
            const value = style === 'none' ? '' : style;
            const current = borderStyle === undefined ? '' : borderStyle === 'none' ? '' : borderStyle;
            return (
              <BorderChoice
                key={style}
                style={value}
                label={style}
                onClick={() => onChange(value)}
                icon={BORDER_STYLE_ICONS[style]}
                current={current}
              />
            );
          })}
        </ul>
      </div>
    </CSSTransition>
  );
}

function BorderChoice(props: BorderProps) {
  const { style, onClick, label, icon, current } = props;
  return (
    <li className={styles.BorderChoiceContainer} onClick={onClick}>
      <div className={styles.BorderChoice}>
        {icon}
        {label ? <span className={styles.inSwatchLabel}>{label}</span> : <span>{style}</span>}
      </div>
      {current === style && <SvgIconCheck />}
    </li>
  );
}
