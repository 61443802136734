import { select, call, put, all, takeEvery, delay } from 'redux-saga/effects';
import * as types from '../actions/actionTypes';
import {
  add as addSection,
  receive as receiveSections,
  replace as replaceSection,
  remove as removeSection,
} from '../actions/sectionActions';
import { selectSection } from '../reducers/sectionsReducer';
import { v4 as uuidv4 } from 'uuid';
import * as api from '../services/spark-api';
import requireSiteId from './utils/requireSiteId';
import { getResources } from 'redux-resource';

// Initialize section AB Test
function* initializeABTest(action) {
  const siteId = yield call(requireSiteId, action.payload.siteId);
  const { id: originalSectionId } = action.payload;

  // Get the current section.
  const originalSection = yield select(selectSection, originalSectionId);

  // Don't go any further if the section is already a test section.
  if (originalSection.type === 'test') return;

  // Get the experiment component from state
  const components = yield select((state) =>
    getResources(state.sectionTypes, (component) => component.slug === 'experiment')
  );
  const component = components && components.length && components[0];

  // Don't continue if there is no experiment component
  if (!component) {
    console.error('no experiment component');
    return;
  }

  // Make the original section the "control"
  const control = originalSection;

  // Create variant and container sections
  const [receivedVariant, container] = yield all([
    call(createSection, {
      site: siteId,
      item: originalSection.item || originalSection.pageId,
      component: originalSection.component,
      location: originalSection.location,
    }),
    call(createSection, {
      site: siteId,
      item: originalSection.item || originalSection.pageId,
      component: component.id,
      component_type_id: component.component_type,
      location: originalSection.location,
    }),
  ]);

  const variant = {
    ...originalSection,
    ...receivedVariant,
    content: originalSection.content,
  };

  // Populate the experiment section
  container.children = [control.id, variant.id];

  // Receive the variant sections
  const newSections = {
    sections: {
      [control.id]: control,
      [variant.id]: variant,
    },
  };

  // Replace section with the new test section.
  yield put(replaceSection(originalSection.id, container));
  yield put(receiveSections(newSections));

  action.callback(container.id);
}

function* createSection(payload) {
  const response = yield call(api.createSection, payload);

  if (response.ok) {
    return response.json;
  } else {
    console.error('Failed to create section', response);
    throw 'Failed to create section';
  }
}

function* discardABTest(action) {
  const { testSectionId, winningVariantId, pageId } = action.payload;

  // Get the test section and the winning variant section
  const testSection = yield select(selectSection, testSectionId);
  const winningVariant = yield select(selectSection, winningVariantId);

  // Replace the test section with the variant
  yield put(replaceSection(testSectionId, winningVariant));

  // Remove all the other variant ids from the redux store
  for (let variantId of testSection.children) {
    if (variantId !== winningVariantId) yield put(removeSection(variantId, pageId));
  }
}

// Root saga
// ----------------------------------------------------------------------------

function* sectionABTestSaga() {
  yield all([
    takeEvery(types.SECTIONS_INITIALIZE_A_B_TEST, initializeABTest),
    takeEvery(types.SECTIONS_DISCARD_A_B_TEST, discardABTest),
  ]);
}

export default sectionABTestSaga;
