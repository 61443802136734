import * as types from '../actions/actionTypes';
import * as blogSelectors from './blogsReducer';
import * as articleSelectors from './articlesReducer';
import * as siteSelectors from './sitesReducer';
import { createSelector } from 'reselect';
import { applyOps } from '../lib/immutable-operations';

// ----------------------------------------------------------------------------
// Reducer
// ----------------------------------------------------------------------------

const initState = {
  isOpen: false,
  ops: [],
  errors: {},
};

export default function (state = initState, action) {
  switch (action.type) {
    case types.BLOG_SETTINGS_OPEN:
      return {
        isOpen: true,
        ...action.payload,
      };

    case types.BLOG_SETTINGS_CLOSE:
      return {
        ...state,
        isOpen: false,
      };

    case types.BLOG_SETTINGS_ADD_OPS:
      return {
        ...state,
        ops: state.ops.concat(action.payload.ops),
      };

    default:
      return state;
  }
}

// ----------------------------------------------------------------------------
// Selectors
// ----------------------------------------------------------------------------

function selectLocalState(globalState) {
  return globalState.uiBlogSettings;
}

export function selectBlogId(globalState) {
  const localState = selectLocalState(globalState);
  return localState.blogId;
}

export function selectOps(globalState) {
  const localState = selectLocalState(globalState);
  return localState.ops;
}

function selectBlog(globalState) {
  const { blogId } = selectLocalState(globalState);
  return blogSelectors.selectBlog(globalState, blogId);
}

function selectHasArticles(globalState) {
  const { blogId } = selectLocalState(globalState);
  const blogArticles = articleSelectors.selectBlogArticles(globalState, blogId);
  return blogArticles && blogArticles.length > 0;
}

export const selectBlogDraft = createSelector(selectBlog, selectOps, (blog, ops) => applyOps(blog, ops));

export const selectProps = createSelector(
  siteSelectors.selectAllSites,
  selectBlogDraft,
  selectLocalState,
  selectHasArticles,
  (site, blogDraft, localState, hasArticles) => {
    return {
      fullDomain: siteSelectors.getFullDomainFromSite(site) + '/',
      blog: blogDraft,
      hasArticles,
      ...localState,
    };
  }
);
