import { fork, all } from 'redux-saga/effects';
import appSaga from './appSaga';
import accountSaga from './accountSaga';
import accountLoginSaga from './accountLoginSaga';
import siteSaga from './siteSaga';
import accountUsersSaga from './accountUsersSaga';
import pagesSaga from './pagesSaga';
import blogsSaga from './blogsSaga';
import articlesSaga from './articlesSaga';
import integrationsSaga from './integrationsSaga';
import mediaSaga from './mediaSaga';
import subscriptionSaga from './subscriptionSaga';
import siteStatisticsSaga from './siteStatisticsSaga';
import sectionABTestSaga from './sectionABTestSaga';
import thirdPartyServicesSaga from './thirdPartyServicesSaga';
import templatesSaga from './templatesSaga';
import templateTagsSaga from './templatetagsSaga';
import contentTestsSaga from './contentTestsSaga';
import goalsSaga from './goalsSaga';
import uiPageSettingsSaga from './uiPageSettingsSaga';
import uiBlogSettingsSaga from './uiBlogSettingsSaga';
import uiArticleSettingsSaga from './uiArticleSettingsSaga';
import uiNewContentTestsSaga from './uiNewContentTestsSaga';
import uiInProgressContentTestsSaga from './uiInProgressContentTestsSaga';
import editorSaga from './editorSaga';
import sparkFormsSaga from './sparkFormsSaga';
import contactsSaga from './contactsSaga';
import poll from './notificationPollingSaga';
import themes from './themeSaga';
import tasks from './tasksSaga';
import notes from './notesSaga';
import categories from './categoriesSaga';
import items from './itemsSaga';
import payment from './paymentSaga';
import sectionContainerSaga from './sectionContainerSaga';
import timezone from './timezoneSaga';
import currency from './currencySaga';
import tagSaga from './tagSaga';
import reduxResourceSaga from '../lib/redux-resource/saga';
import sections from './sectionsSaga';
import dataTables from './dataTablesSaga';
import fonts from './fontsSaga';
import components from './componentSaga';
import videosSaga from './videosSaga';
import siteLayoutSaga from './siteLayoutSaga';
import apiKeysSaga from './apiKeysSaga';

export default function* rootSaga() {
  yield all([
    fork(appSaga),
    fork(accountSaga),
    fork(accountLoginSaga),
    fork(accountUsersSaga),
    fork(siteSaga),
    fork(pagesSaga),
    fork(blogsSaga),
    fork(articlesSaga),
    fork(integrationsSaga),
    fork(mediaSaga),
    fork(subscriptionSaga),
    fork(siteStatisticsSaga),
    fork(sectionABTestSaga),
    fork(thirdPartyServicesSaga),
    fork(templatesSaga),
    fork(templateTagsSaga),
    fork(contentTestsSaga),
    fork(goalsSaga),
    fork(uiPageSettingsSaga),
    fork(uiBlogSettingsSaga),
    fork(uiArticleSettingsSaga),
    fork(uiNewContentTestsSaga),
    fork(uiInProgressContentTestsSaga),
    fork(editorSaga),
    fork(sparkFormsSaga),
    fork(contactsSaga),
    fork(poll),
    fork(reduxResourceSaga),
    fork(themes),
    fork(tasks),
    fork(notes),
    fork(categories),
    fork(items),
    fork(payment),
    fork(sectionContainerSaga),
    fork(timezone),
    fork(currency),
    fork(tagSaga),
    fork(sections),
    fork(dataTables),
    fork(fonts),
    fork(components),
    fork(videosSaga),
    fork(siteLayoutSaga),
    fork(apiKeysSaga),
  ]);
}
