import React from 'react';

import { compose } from 'redux';
import withSite from '../../../containers/withSite';
import { connect } from 'react-redux';
import { Checkbox } from '../../Checkbox';

import { update } from '../../../actions/siteActions';
import styles from './Notifications.module.scss';

const mapDispatchToProps = { update };

function Gdpr({ site, update }) {
  return (
    <>
      <section id="gdpr">
        <header>
          <h1>GDPR messaging</h1>
        </header>
        <div className={styles.notificationContainer}>
          <Checkbox
            onClick={() => {
              let { content } = site;
              if ('gdpr' in content) {
                content['gdpr']['active'] = content['gdpr']['active'] === '1' ? '0' : '1';
              } else {
                content['gdpr'] = {
                  active: '1',
                  message: 'By using our website you consent to the use of cookies',
                  button: 'Okay',
                };
              }
              return update({
                ...site,
                content: content,
              });
            }}
            checked={'content' in site && 'gdpr' in site.content && site.content.gdpr.active === '1'}
          />
          <span>Display a message on your website asking visitors to consent to the use of cookies</span>
        </div>
      </section>
    </>
  );
}

export default compose(withSite, connect(null, mapDispatchToProps))(Gdpr);
