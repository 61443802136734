import React, { useState, useCallback } from 'react';
import { CSSTransition } from 'react-transition-group';
import classnames from 'classnames';

import { ReactComponent as SvgIconArrow } from 'assets/images/icon-cheveron-left.svg';
import { ReactComponent as SvgIconCheck } from '../../../assets/images/icon-check.svg';

import styles from './QuillToolbar.module.scss';

type HeaderPicker = {
  headerCount: number;
  onSelect: (value: number | string) => void;
  value: number | string;
  isBlog?: boolean;
};

export default ({ headerCount, onSelect, value, isBlog }: HeaderPicker) => {
  const [drawerIsOpen, setDrawerIsOpen] = useState(false);
  const headers = ['Heading 1', 'Heading 2', 'Heading 3', 'Heading 4', 'Heading 5', 'Heading 6'];

  return (
    <div onClick={() => setDrawerIsOpen(!drawerIsOpen)} className={classnames(styles.FontPicker, styles.extraPadding)}>
      <span>{value ? `Heading ${value}` : 'Paragraph'}</span>
      <SvgIconArrow className={styles.ArrowDown} />
      <CSSTransition in={drawerIsOpen} timeout={141.59} classNames={styles} mountOnEnter unmountOnExit>
        <div className={classnames(styles.Drawer, styles.leftZero)}>
          <ul>
            {headers.slice(0, headerCount).map((name, i) => (
              <li
                key={`header-${i}`}
                className={classnames(styles.FontChoice, {
                  'is-blog': isBlog,
                })}
                onClick={() => {
                  setDrawerIsOpen(false);
                  onSelect(i + 1);
                }}
              >
                {i === 0 && <h1>{name}</h1>}
                {i === 1 && <h2>{name}</h2>}
                {i === 2 && <h3>{name}</h3>}
                {i === 3 && <h4>{name}</h4>}
                {i === 4 && <h5>{name}</h5>}
                {i === 5 && <h6>{name}</h6>}
                {value === i + 1 && <SvgIconCheck />}
              </li>
            ))}
            <li
              key="paragraph"
              className={styles.FontChoice}
              onClick={() => {
                setDrawerIsOpen(false);
                onSelect('');
              }}
            >
              <p>Paragraph</p>
              {!value && <SvgIconCheck />}
            </li>
          </ul>
        </div>
      </CSSTransition>
    </div>
  );
};
