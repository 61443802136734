import React, { useState } from 'react';
import { connect } from 'react-redux';
import { ReactComponent as SvgNotification } from '../../../assets/images/icon-notifications.svg';
import LogoIcon from '../../../assets/images/unstack-profile-inverse@2x.png';

import { selectNotificationPermission } from '../../../reducers/contactsReducer';
import { updateNotificationPermission } from '../../../actions/contactsActions';

import styles from './NotificationPermission.module.scss';

export const requestNotificationPermission = function () {
  if ('Notification' in window) {
    if (Notification.permission !== 'granted') {
      Notification.requestPermission((permission) => {
        if (permission === 'granted') {
          new Notification('New customer notifications enabled', {
            body: `We'll let you know about a new customer the moment they convert`,
            icon: LogoIcon,
          });
        }
      });
    }
  }
};

const mapStateToProps = (state) => ({
  notificationPermissionEnabled: selectNotificationPermission(state),
});

const mapDispatchToProps = {
  updateNotificationPermission,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)((props) => {
  const hasNotifications =
    !('Notification' in window) || ['granted', 'denied'].indexOf(window.Notification.permission) >= 0;

  const [notificationsEnabled, setNotificationsEnabled] = useState(hasNotifications);

  if (notificationsEnabled || props.notificationPermissionEnabled === false) return null;

  return (
    <article className={styles.notificationPermContainer}>
      <SvgNotification />
      <p>Enable browser notifications for new customers</p>
      <div>
        <input
          value="Enable notifications"
          className="button button-primary"
          onClick={() => {
            requestNotificationPermission();
            setNotificationsEnabled(true);
          }}
          readOnly="readOnly"
        />
        <input
          value="No thanks"
          className="button button-default"
          onClick={() => {
            window.sprkfe.storage.setItem('notificationPermission', false);
            props.updateNotificationPermission(false);
          }}
          readOnly="readOnly"
        />
      </div>
    </article>
  );
});
