import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import Spinner from '../../../base/Spinner';
import List from '../../../react-table/List';

import { selectAllNotes, selectIsLoading } from '../../../../reducers/notesReducer';
import { selectAccountUser } from '../../../../reducers/accountUsersReducer';

import { formatDate } from '../ContactActions';

import { ReactComponent as SvgIconEdit } from '../../../../assets/images/icon-edit-field.svg';
import { ReactComponent as SvgIconNotes } from '../../../../assets/images/icon-notepad.svg';

import styles from './ContactDetail.module.scss';

export default ({ fetchNotes, contactId, refetchList, openDrawer, contactName }) => {
  const columns = [
    {
      id: 'details',
      accessor: (row) => row,
      Cell: ({ value }) => {
        const owner = useSelector((state) => selectAccountUser(state, value.owner));
        const [onHover, setOnHover] = useState(false);
        return (
          <div
            onMouseEnter={() => setOnHover(true)}
            onMouseLeave={() => setOnHover(false)}
            className={styles.description}
            data-test-id="details"
          >
            {owner && (
              <strong>
                <SvgIconNotes className={styles.heading} height={15} width={15} />
                {owner.name || owner.email} added a note
                {onHover && (
                  <SvgIconEdit
                    onClick={() => openDrawer(value)}
                    className={styles.edit}
                    data-test-id="edit"
                    height={13}
                    width={13}
                  />
                )}
              </strong>
            )}
            {value.details}
          </div>
        );
      },
    },
    {
      accessor: '',
      Cell: ({ value }) => <span className="muted">{formatDate(new Date(value.created_at))}</span>,
      width: 150,
    },
  ];

  useEffect(() => {
    fetchNotes({ contactId });
  }, [refetchList]); // eslint-disable-line react-hooks/exhaustive-deps

  const data = useSelector((state) => selectAllNotes(state));
  const isLoading = useSelector((state) => selectIsLoading(state));

  return (
    <div className={styles.tasksListContainer}>
      {isLoading ? (
        <Spinner className="fixed" />
      ) : data.length ? (
        <List data={data} columns={columns} showAll />
      ) : (
        <div data-test-id="empty-note-state">
          There are no notes for {contactName}.{' '}
          <span className={styles.linkText} onClick={() => openDrawer({})} data-test-id="create-new-note">
            Create a new note
          </span>
        </div>
      )}
    </div>
  );
};
