import React, { useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';

import { useCombinedRefs } from './hooks';

import { ReactComponent as SvgIconCheck } from '../../../../assets/images/icons8-pencil.svg';

export default function DraggableHeaderRenderer({
  onColumnsReorder,
  column,
  sortColumn,
  sortDirection,
  setOpen,
  onSort,
}) {
  const [{ isDragging }, drag] = useDrag({
    type: 'COLUMN_DRAG',
    item: { key: column.key },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [{ isOver }, drop] = useDrop({
    accept: 'COLUMN_DRAG',
    drop({ key }) {
      onColumnsReorder(key, column.key);
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  return (
    <div
      ref={useCombinedRefs(drag, drop)}
      style={{
        opacity: isDragging ? 0.5 : 1,
        backgroundColor: isOver ? '#ececec' : 'inherit',
        cursor: 'pointer',
      }}
    >
      <SortableHeaderCell
        column={column}
        sortColumn={sortColumn}
        sortDirection={sortDirection}
        onSort={onSort}
        setOpen={setOpen}
      >
        {column.name}
      </SortableHeaderCell>
    </div>
  );
}

function SortableHeaderCell({ column, onSort, sortColumn, sortDirection, setOpen, children }) {
  const [edit, setEdit] = useState(false);

  return (
    <span onMouseLeave={() => setEdit(false)} onMouseOver={() => setEdit(true)}>
      <span data-test-id="column-content">{children}</span>
      {edit && (
        <SvgIconCheck
          data-test-id="edit"
          onClick={() => setOpen(column)}
          height={12}
          width={15}
          style={{
            position: 'relative',
            top: '2px',
            left: '5px',
            cursor: 'pointer',
          }}
        />
      )}
    </span>
  );
}
