import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';

import StyleGuide from '../lib/StyleGuide';

import withSite from '../containers/withSite';
import * as fontActions from 'actions/fontActions';
import * as fontsSelector from 'reducers/fontsReducer';

const mapStateToProps = (state, props) => {
  return {
    fonts: fontsSelector.selectFonts(state),
    typekitId: fontsSelector.selectFontsTypekitId(state, props.site?.id),
  };
};

const mapDispatchToProps = {
  requestFontsIfNeeded: fontActions.requestAllIfNeeded,
};

class StyleEditableOverrides extends React.Component {
  componentDidMount() {
    this.props.requestFontsIfNeeded();
  }
  render() {
    const { site } = this.props;
    if (site == null || !this.props.fonts.length) return false;

    const styles = site.styles;
    const styleGuide = new StyleGuide(site, this.props.fonts);

    const radioCheckBoxButtonSize = `${styleGuide.styles.form_field_font_size - 0.2}rem`;

    const fontSizeParagraph = parseFloat(styleGuide.paragraph_font_size);
    const fontSizeBlogParagraph = parseFloat(styleGuide.blog_paragraph_font_size);

    const applyHeaderFormula = (fontSize) => {
      return fontSize > fontSizeParagraph ? (fontSize - fontSizeParagraph) / 2 + fontSizeParagraph : fontSize;
    };

    const applyBlogHeaderFormula = (fontSize) => {
      return fontSize > fontSizeBlogParagraph
        ? (fontSize - fontSizeBlogParagraph) / 2 + fontSizeBlogParagraph
        : fontSize;
    };

    const h1_fontsize = applyHeaderFormula(parseFloat(styleGuide.header_1_font_size));
    const h2_fontsize = applyHeaderFormula(parseFloat(styleGuide.header_2_font_size));
    const h3_fontsize = applyHeaderFormula(parseFloat(styleGuide.header_3_font_size));
    const h4_fontsize = applyHeaderFormula(parseFloat(styleGuide.header_4_font_size));
    const h5_fontsize = applyHeaderFormula(parseFloat(styleGuide.header_5_font_size));
    const h6_fontsize = applyHeaderFormula(parseFloat(styleGuide.header_6_font_size));

    const blog_h1_fontsize = applyBlogHeaderFormula(parseFloat(styleGuide.blog_header_1_font_size));
    const blog_h2_fontsize = applyBlogHeaderFormula(parseFloat(styleGuide.blog_header_2_font_size));
    const blog_h3_fontsize = applyBlogHeaderFormula(parseFloat(styleGuide.blog_header_3_font_size));
    const blog_h4_fontsize = applyBlogHeaderFormula(parseFloat(styleGuide.blog_header_4_font_size));
    const blog_h5_fontsize = applyBlogHeaderFormula(parseFloat(styleGuide.blog_header_5_font_size));
    const blog_h6_fontsize = applyBlogHeaderFormula(parseFloat(styleGuide.blog_header_6_font_size));

    const getPaddingStyle = (style, fallback) => {
      try {
        return styleGuide[style].map((v) => `${v}rem`).join(' ');
      } catch (e) {
        console.warn(`Style guide improperly formatted field: ${style}`);
        return fallback || '0';
      }
    };

    return (
      <style>
        {/* Import fonts */}
        {this.props.loadAllFonts && `@import url('${styleGuide.fontImportUrl}');`}
        {styleGuide.selectFontImportUrl}
        {this.props.typekitId && <link rel="stylesheet" href={`https://use.typekit.net/${this.props.typekitId}.css`} />}
        {this.props.loadAllFonts && styleGuide.customFontImportUrl}
        {/* Typography */}
        {`
          .sriracha .section-box > .box-content {
            max-width: ${styleGuide.layout_section_desktop_width}
          }

          .sriracha .section-box {
            padding: ${getPaddingStyle('layout_section_desktop_padding')}
          }

          @media only screen and (max-width: 768px) {
            .sriracha .section-box {
              padding: ${getPaddingStyle('layout_section_mobile_padding')}
            }
          }

          /*
          .sriracha .section-box > .box-content .box > .box-content {
            max-width: ${styleGuide.layout_box_desktop_width}
          }
          */
          
          .sriracha .section-box > .box-content .box {
            padding: ${getPaddingStyle('layout_box_desktop_padding')}
          }

          @media only screen and (max-width: 768px){
            .sriracha .section-box > .box-content .box {
              padding: ${getPaddingStyle('layout_box_mobile_padding')}
            }
          }

          .sriracha p,
          .sriracha .light-bg p,
          .sriracha .box .light-bg p {
            color: ${styleGuide.paragraph_color?.code || 'inherit'};
            font-family: ${styleGuide.paragraph_font_css_family};
            font-size: ${styleGuide.paragraph_font_size}rem;
            font-weight: ${styleGuide.paragraph_font_weight};
            line-height: ${styleGuide.paragraph_line_height};
            margin: ${styleGuide.paragraph_margin};
            text-shadow: none;
          }
          .admin .mobile p,
          .sriracha .mobile p,
          .sriracha .box .mobile p {
            font-size: ${styleGuide.paragraph_font_size_mobile}rem;
            line-height: ${styleGuide.paragraph_line_height_mobile};
            letter-spacing: ${styleGuide.paragraph_letter_spacing_mobile || 'normal'};
            margin: ${styleGuide.paragraph_margin_mobile};
          }
          @media only screen and (max-width: 767px) {
            .sriracha p,
            .sriracha .p,
            .sriracha .box p {
              font-size: ${styleGuide.paragraph_font_size_mobile}rem !important;
              line-height: ${styleGuide.paragraph_line_height_mobile} !important;
              letter-spacing: ${styleGuide.paragraph_letter_spacing_mobile || 'normal'} !important;
              margin: ${styleGuide.paragraph_margin_mobile} !important;
            }
          }

          .sriracha a,
          .sriracha .light-bg a,
          .sriracha .box .light-bg a {
            color: ${styleGuide.action_link_button_text_color?.code || 'inherit'};
          }

          .sriracha .dark-bg p,
          .sriracha .box .dark-bg p {
            color: ${styleGuide.paragraph_dark_bg_color?.code || 'inherit'};
            text-shadow: 0 1px 3px rgba(0,0,0,0.2);
          }

          .sriracha .dark-bg a,
          .sriracha .box .dark-bg a {
            color: ${styleGuide.action_link_button_dark_bg_text_color?.code || 'inherit'};
          }

          .sriracha mark,
          .sriracha .light-bg mark,
          .sriracha .box .light-bg mark {
            background: linear-gradient(
              0deg, rgba(255,255,255,0) 0%,
              rgba(255,255,255,0) 15%,
              ${styleGuide.highlight_color?.code || 'rgba(255,255,255,0)'} 15%,
              ${styleGuide.highlight_color?.code || 'rgba(255,255,255,0)'} 30%,
              rgba(255,255,255,0) 30%,
              rgba(255,255,255,0) 100%
            );
          }

          .sriracha .dark-bg mark,
          .sriracha .box .dark-bg mark {
            background: linear-gradient(
              0deg, rgba(255,255,255,0) 0%,
              rgba(255,255,255,0) 15%,
              ${styleGuide.highlight_color_dark_bg?.code || 'rgba(255,255,255,0)'} 15%,
              ${styleGuide.highlight_color_dark_bg?.code || 'rgba(255,255,255,0)'} 30%,
              rgba(255,255,255,0) 30%,
              rgba(255,255,255,0) 100%
            );
          }

          .sriracha h2.dark-bg mark,
          .sriracha .box h2.dark-bg mark {
            background: linear-gradient(
              0deg, rgba(255,255,255,0) 0%,
              rgba(255,255,255,0) 15%,
              ${styleGuide.highlight_color_dark_bg?.code || 'rgba(255,255,255,0)'} 15%,
              ${styleGuide.highlight_color_dark_bg?.code || 'rgba(255,255,255,0)'} 30%,
              rgba(255,255,255,0) 30%,
              rgba(255,255,255,0) 100%
            );
          }

          .sriracha h1,
          .sriracha .h1,
          .sriracha .light-bg h1,
          .sriracha .light-bg .h1,
          .sriracha .box .light-bg h1,
          .sriracha .box .light-bg .h1 {
            font-family: ${styleGuide.header_1_font_css_family};
            font-size: ${styleGuide.header_1_font_size}rem;
            font-weight: ${styleGuide.header_1_font_weight} !important;
            color: ${styleGuide.header_1_color?.code || 'inherit'};
            line-height: ${styleGuide.header_1_line_height};
            text-transform: ${styleGuide.header_1_text_transform || 'none'};
            letter-spacing: ${styleGuide.header_1_letter_spacing || 'normal'};
            margin: ${styleGuide.header_1_margin};
            text-shadow: none;
          }
          .admin div div button div div ul li h1 {
            font-size: ${h1_fontsize}rem;
            font-weight: ${styleGuide.header_1_font_weight};
            font-family: ${styleGuide.header_1_font_css_family};
            text-transform: ${styleGuide.header_1_text_transform || 'none'};
            letter-spacing: ${styleGuide.header_1_letter_spacing || 'normal'};
          }
          .admin .dark-bg h1,
          .sriracha .dark-bg h1,
          .sriracha .box .dark-bg h1 {
            color: ${styleGuide.header_1_dark_bg_color?.code || 'inherit'};
            text-shadow: 0 1px 3px rgba(0,0,0,0.2);
          }
          .admin .mobile h1,
          .sriracha .mobile h1,
          .sriracha .box .mobile h1 {
            font-size: ${styleGuide.header_1_font_size_mobile}rem;
            line-height: ${styleGuide.header_1_line_height_mobile || styleGuide.header_1_line_height} !important;
            letter-spacing: ${
              styleGuide.header_1_letter_spacing_mobile || styleGuide.header_1_letter_spacing || 'normal'
            } !important;
            margin: ${styleGuide.header_1_margin_mobile || styleGuide.header_1_margin} !important;
          }
          @media only screen and (max-width: 767px) {
            .sriracha h1,
            .sriracha .h1,
            .sriracha .box h1 {
              font-size: ${styleGuide.header_1_font_size_mobile}rem !important;
              line-height: ${styleGuide.header_1_line_height_mobile || styleGuide.header_1_line_height} !important;
              letter-spacing: ${
                styleGuide.header_1_letter_spacing_mobile || styleGuide.header_1_letter_spacing || 'normal'
              } !important;
              margin: ${styleGuide.header_1_margin_mobile || styleGuide.header_1_margin} !important;
            }
          }

          .sriracha h2,
          .sriracha .h2,
          .sriracha .light-bg h2,
          .sriracha .light-bg .h2,
          .sriracha .box .light-bg h2,
          .sriracha .box .light-bg .h2 {
            font-family: ${styleGuide.header_2_font_css_family};
            font-size: ${styleGuide.header_2_font_size}rem;
            font-weight: ${styleGuide.header_2_font_weight};
            color: ${styleGuide.header_2_color?.code || 'inherit'};
            line-height: ${styleGuide.header_2_line_height};
            text-transform: ${styleGuide.header_2_text_transform || 'none'};
            letter-spacing: ${styleGuide.header_2_letter_spacing || 'normal'};
            margin: ${styleGuide.header_2_margin};
            text-shadow: none;
          }
          .admin div div button div div ul li h2 {
            font-size: ${h2_fontsize}rem;
            font-weight: ${styleGuide.header_2_font_weight};
            font-family: ${styleGuide.header_2_font_css_family};
            text-transform: ${styleGuide.header_2_text_transform || 'none'};
            letter-spacing: ${styleGuide.header_2_letter_spacing || 'normal'};
          }
          .admin .dark-bg h2,
          .sriracha .dark-bg h2,
          .sriracha .box .dark-bg h2 {
            color: ${styleGuide.header_2_dark_bg_color?.code || 'inherit'};
            text-shadow: 0 1px 3px rgba(0,0,0,0.2);
          }
          .admin .mobile h2,
          .sriracha .mobile h2,
          .sriracha .box .mobile h2 {
            font-size: ${styleGuide.header_2_font_size_mobile}rem;
            line-height: ${styleGuide.header_2_line_height_mobile || styleGuide.header_2_line_height} !important;
            letter-spacing: ${
              styleGuide.header_2_letter_spacing_mobile || styleGuide.header_2_letter_spacing || 'normal'
            } !important;
            margin: ${styleGuide.header_2_margin_mobile || styleGuide.header_2_margin} !important;
          }
          @media only screen and (max-width: 767px) {
            .sriracha h2,
            .sriracha .h2,
            .sriracha .box h2 {
              font-size: ${styleGuide.header_2_font_size_mobile}rem !important;
              line-height: ${styleGuide.header_2_line_height_mobile || styleGuide.header_2_line_height} !important;
              letter-spacing: ${
                styleGuide.header_2_letter_spacing_mobile || styleGuide.header_2_letter_spacing || 'normal'
              } !important;
              margin: ${styleGuide.header_2_margin_mobile || styleGuide.header_2_margin} !important;
            }
          }

          .sriracha h3,
          .sriracha .h3,
          .sriracha .light-bg h3,
          .sriracha .light-bg .h3,
          .sriracha .box .light-bg h3,
          .sriracha .box .light-bg .h3 {
            font-family: ${styleGuide.header_3_font_css_family};
            font-size: ${styleGuide.header_3_font_size}rem;
            font-weight: ${styleGuide.header_3_font_weight};
            color: ${styleGuide.header_3_color?.code || 'inherit'};
            line-height: ${styleGuide.header_3_line_height};
            text-transform: ${styleGuide.header_3_text_transform || 'none'};
            letter-spacing: ${styleGuide.header_3_letter_spacing || 'normal'};
            margin: ${styleGuide.header_3_margin};
            text-shadow: none;
          }
          .admin div div button div div ul li h3 {
            font-size: ${h3_fontsize}rem;
            font-weight: ${styleGuide.header_3_font_weight};
            font-family: ${styleGuide.header_3_font_css_family};
            text-transform: ${styleGuide.header_3_text_transform || 'none'};
            letter-spacing: ${styleGuide.header_3_letter_spacing || 'normal'};
          }
          .admin .dark-bg h3,
          .sriracha .dark-bg h3,
          .sriracha .box .dark-bg h3 {
            color: ${styleGuide.header_3_dark_bg_color?.code || 'inherit'};
            text-shadow: 0 1px 3px rgba(0,0,0,0.2);
          }
          .admin .mobile h3,
          .sriracha .mobile h3,
          .sriracha .box .mobile h3 {
            font-size: ${styleGuide.header_3_font_size_mobile}rem;
            line-height: ${styleGuide.header_3_line_height_mobile || styleGuide.header_3_line_height} !important;
            letter-spacing: ${
              styleGuide.header_3_letter_spacing_mobile || styleGuide.header_3_letter_spacing || 'normal'
            } !important;
            margin: ${styleGuide.header_3_margin_mobile || styleGuide.header_3_margin} !important;
          }
          @media only screen and (max-width: 767px) {
            .sriracha h3,
            .sriracha .h3,
            .sriracha .box h3 {
              font-size: ${styleGuide.header_3_font_size_mobile}rem !important;
              line-height: ${styleGuide.header_3_line_height_mobile || styleGuide.header_3_line_height} !important;
              letter-spacing: ${
                styleGuide.header_3_letter_spacing_mobile || styleGuide.header_3_letter_spacing || 'normal'
              } !important;
              margin: ${styleGuide.header_3_margin_mobile || styleGuide.header_3_margin} !important;
            }
          }

          .sriracha h4,
          .sriracha .h4,
          .sriracha .light-bg h4,
          .sriracha .light-bg .h4,
          .sriracha .box .light-bg h4,
          .sriracha .box .light-bg .h4 {
            font-family: ${styleGuide.header_4_font_css_family};
            font-size: ${styleGuide.header_4_font_size}rem;
            font-weight: ${styleGuide.header_4_font_weight};
            color: ${styleGuide.header_4_color?.code || 'inherit'};
            line-height: ${styleGuide.header_4_line_height};
            text-transform: ${styleGuide.header_4_text_transform || 'none'};
            letter-spacing: ${styleGuide.header_4_letter_spacing || 'normal'};
            margin: ${styleGuide.header_4_margin};
            text-shadow: none;
          }
          .admin div div button div div ul li h4 {
            font-size: ${h4_fontsize}rem;
            font-weight: ${styleGuide.header_4_font_weight};
            font-family: ${styleGuide.header_4_font_css_family};
            text-transform: ${styleGuide.header_4_text_transform || 'none'};
            letter-spacing: ${styleGuide.header_4_letter_spacing || 'normal'};
          }
          .admin .dark-bg h4,
          .sriracha .dark-bg h4,
          .sriracha .box .dark-bg h4 {
            color: ${styleGuide.header_4_dark_bg_color?.code || 'inherit'};
            text-shadow: 0 1px 3px rgba(0,0,0,0.2);
          }
          .admin .mobile h4,
          .sriracha .mobile h4,
          .sriracha .box .mobile h4 {
            font-size: ${styleGuide.header_4_font_size_mobile}rem;
            line-height: ${styleGuide.header_4_line_height_mobile || styleGuide.header_4_line_height} !important;
            letter-spacing: ${
              styleGuide.header_4_letter_spacing_mobile || styleGuide.header_4_letter_spacing || 'normal'
            } !important;
            margin: ${styleGuide.header_4_margin_mobile || styleGuide.header_4_margin} !important;
          }
          @media only screen and (max-width: 767px) {
            .sriracha h4,
            .sriracha .h4,
            .sriracha .box h4 {
              font-size: ${styleGuide.header_4_font_size_mobile}rem !important;
              line-height: ${styleGuide.header_4_line_height_mobile || styleGuide.header_4_line_height} !important;
              letter-spacing: ${
                styleGuide.header_4_letter_spacing_mobile || styleGuide.header_4_letter_spacing || 'normal'
              } !important;
              margin: ${styleGuide.header_4_margin_mobile || styleGuide.header_4_margin} !important;
            }
          }

          .sriracha h5,
          .sriracha .h5,
          .sriracha .light-bg h5,
          .sriracha .light-bg .h5,
          .sriracha .box .light-bg h5,
          .sriracha .box .light-bg .h5 {
            font-family: ${styleGuide.header_5_font_css_family};
            font-size: ${styleGuide.header_5_font_size}rem;
            font-weight: ${styleGuide.header_5_font_weight};
            color: ${styleGuide.header_5_color?.code || 'inherit'};
            line-height: ${styleGuide.header_5_line_height};
            text-transform: ${styleGuide.header_5_text_transform || 'none'};
            letter-spacing: ${styleGuide.header_5_letter_spacing || 'normal'};
            margin: ${styleGuide.header_5_margin};
            text-shadow: none;
          }
          .admin div div button div div ul li h5 {
            font-size: ${h5_fontsize}rem;
            font-weight: ${styleGuide.header_5_font_weight};
            font-family: ${styleGuide.header_5_font_css_family};
            text-transform: ${styleGuide.header_5_text_transform || 'none'};
            letter-spacing: ${styleGuide.header_5_letter_spacing || 'normal'};
          }
          .admin .dark-bg h5,
          .sriracha .dark-bg h5,
          .sriracha .box .dark-bg h5 {
            color: ${styleGuide.header_5_dark_bg_color?.code || 'inherit'};
            text-shadow: 0 1px 3px rgba(0,0,0,0.2);
          }
          .admin .mobile h5,
          .sriracha .mobile h5,
          .sriracha .box .mobile h5 {
            font-size: ${styleGuide.header_5_font_size_mobile}rem;
            line-height: ${styleGuide.header_5_line_height_mobile || styleGuide.header_5_line_height} !important;
            letter-spacing: ${
              styleGuide.header_5_letter_spacing_mobile || styleGuide.header_5_letter_spacing || 'normal'
            } !important;
            margin: ${styleGuide.header_5_margin_mobile || styleGuide.header_5_margin} !important;
          }
          @media only screen and (max-width: 767px) {
            .sriracha h5,
            .sriracha .h5,
            .sriracha .box h5 {
              font-size: ${styleGuide.header_5_font_size_mobile}rem !important;
              line-height: ${styleGuide.header_5_line_height_mobile || styleGuide.header_5_line_height} !important;
              letter-spacing: ${
                styleGuide.header_5_letter_spacing_mobile || styleGuide.header_5_letter_spacing || 'normal'
              } !important;
              margin: ${styleGuide.header_5_margin_mobile || styleGuide.header_5_margin} !important;
            }
          }

          .sriracha h6,
          .sriracha .h6,
          .sriracha .light-bg h6,
          .sriracha .light-bg .h6,
          .sriracha .box .light-bg h6,
          .sriracha .box .light-bg .h6 {
            font-family: ${styleGuide.header_6_font_css_family};
            font-size: ${styleGuide.header_6_font_size}rem;
            font-weight: ${styleGuide.header_6_font_weight};
            color: ${styleGuide.header_6_color?.code || 'inherit'};
            line-height: ${styleGuide.header_6_line_height};
            text-transform: ${styleGuide.header_6_text_transform || 'none'};
            letter-spacing: ${styleGuide.header_6_letter_spacing || 'normal'};
            margin: ${styleGuide.header_6_margin};
            text-shadow: none;
          }
          .admin div div button div div ul li h6 {
            font-size: ${h6_fontsize}rem;
            font-weight: ${styleGuide.header_6_font_weight};
            font-family: ${styleGuide.header_6_font_css_family};
            text-transform: ${styleGuide.header_6_text_transform || 'none'};
            letter-spacing: ${styleGuide.header_6_letter_spacing || 'normal'};
          }
          .admin .dark-bg h6,
          .sriracha .dark-bg h6,
          .sriracha .box .dark-bg h6 {
            color: ${styleGuide.header_6_dark_bg_color?.code || 'inherit'};
            text-shadow: 0 1px 3px rgba(0,0,0,0.2);
          }
          .admin .mobile h6,
          .sriracha .mobile h6,
          .sriracha .box .mobile h6 {
            font-size: ${styleGuide.header_6_font_size_mobile}rem;
            line-height: ${styleGuide.header_6_line_height_mobile || styleGuide.header_6_line_height} !important;
            letter-spacing: ${
              styleGuide.header_6_letter_spacing_mobile || styleGuide.header_6_letter_spacing || 'normal'
            } !important;
            margin: ${styleGuide.header_6_margin_mobile || styleGuide.header_6_margin} !important;
          }
          @media only screen and (max-width: 767px) {
            .sriracha h6,
            .sriracha .h6,
            .sriracha .box h6 {
              font-size: ${styleGuide.header_6_font_size_mobile}rem !important;
              line-height: ${styleGuide.header_6_line_height_mobile || styleGuide.header_6_line_height} !important;
              letter-spacing: ${
                styleGuide.header_6_letter_spacing_mobile || styleGuide.header_6_letter_spacing || 'normal'
              } !important;
              margin: ${styleGuide.header_6_margin_mobile || styleGuide.header_6_margin} !important;
            }
          }

          .sriracha section .large-quote {
            font-family: ${styleGuide.big_quote_font_css_family};
            font-size: ${styleGuide.big_quote_font_size}rem;
            font-weight: ${styleGuide.big_quote_font_weight};
            font-style: ${styleGuide.big_quote_font_style};
            line-height: ${styleGuide.big_quote_line_height};
            text-transform: ${styleGuide.big_quote_text_transform || 'none'};
            letter-spacing: ${styleGuide.big_quote_letter_spacing || 'normal'};
          }
          .sriracha section:not(.dark-bg) .large-quote {
            color: ${styleGuide.big_quote_color?.code || 'inherit'};
          }
          .sriracha section .large-quote {
            color: ${styleGuide.big_quote_dark_bg_color?.code || 'inherit'};
          }
          .sriracha section .large-quote-icon {
            color: ${styleGuide.big_quote_mark_color?.code || 'inherit'};
            width: ${styleGuide.big_quote_mark_size}rem;
          }
          .sriracha section.dark-bg .large-quote-icon {
            color: ${styleGuide.big_quote_dark_bg_mark_color?.code || 'inherit'} !important;
          }
          .admin .mobile .large-quote,
          .sriracha .mobile .large-quote,
          .sriracha .box .mobile .large-quote {
            font-size: ${styleGuide.big_quote_font_size_mobile}rem;
            line-height: ${styleGuide.big_quote_line_height_mobile || styleGuide.big_quote_line_height};
            letter-spacing: ${
              styleGuide.big_quote_letter_spacing_mobile || styleGuide.big_quote_letter_spacing || 'normal'
            };
          }
          .admin .mobile .large-quote-icon,
          .sriracha .mobile .large-quote-icon,
          .sriracha .box .mobile .large-quote-icon {
            width: ${styleGuide.big_quote_mark_size_mobile || styleGuide.big_quote_mark_size}rem;
          }
          @media only screen and (max-width: 767px) {
            .sriracha .large-quote,
            .sriracha section .large-quote,
            .sriracha .box .large-quote {
              font-size: ${styleGuide.big_quote_font_size_mobile}rem !important;
              line-height: ${styleGuide.big_quote_line_height_mobile || styleGuide.big_quote_line_height} !important;
              letter-spacing: ${
                styleGuide.big_quote_letter_spacing_mobile || styleGuide.big_quote_letter_spacing || 'normal'
              } !important;
            }
            .sriracha .large-quote-icon,
            .sriracha section .large-quote-icon,
            .sriracha .box .large-quote-icon {
              width: ${styleGuide.big_quote_mark_size_mobile || styleGuide.big_quote_mark_size}rem !important;
            }
          }

          .sriracha ul[data-checked] {
            font-family: ${styleGuide.check_list_font_css_family};
            font-size: ${styleGuide.check_list_font_size}rem;
            font-weight: ${styleGuide.check_list_font_weight};
            font-style: ${styleGuide.check_list_font_style};
            color: ${styleGuide.check_list_color?.code || 'inherit'};
            line-height: ${styleGuide.check_list_line_height};
          }
          .sriracha .box .light-bg ul,
          .sriracha .box .light-bg ul[data-checked] {
            color: ${styleGuide.check_list_color?.code || 'inherit'};
          }
          .sriracha .box .dark-bg ul,
          .sriracha .box .dark-bg ul[data-checked] {
            color: ${styleGuide.check_list_dark_bg_color?.code || 'inherit'};
          }
          .sriracha .box .light-bg li,
          .sriracha .box .light-bg li[data-checked] {
            color: ${styleGuide.check_list_color?.code || 'inherit'};
          }
          .sriracha .box .dark-bg li,
          .sriracha .box .dark-bg li[data-checked] {
            color: ${styleGuide.check_list_dark_bg_color?.code || 'inherit'};
          }

          .sriracha .box .light-bg ul[data-checked] li,
          .sriracha .box .light-bg ul[data-checked] li[data-checked] {
            color: ${styleGuide.check_list_color?.code || 'inherit'};
          }
          .sriracha .box .dark-bg ul[data-checked] li,
          .sriracha .box .dark-bg ul[data-checked] li[data-checked] {
            color: ${styleGuide.check_list_dark_bg_color?.code || 'inherit'};
          }
          .sriracha ul[data-checked] li,
          .sriracha .box .light-bg ul[data-checked] li {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3E%3Cpath fill='${encodeURIComponent(
              styleGuide.check_list_icon_color?.code || 'inherit'
            )}' d='M0 11l2-2 5 5L18 3l2 2L7 18z'/%3E%3C/svg%3E");
            background-repeat: no-repeat;
            background-position: 0 .35em;
            padding-left: 1.2em !important;
            background-size: ${styleGuide.check_list_icon_size}em;
            padding-bottom: ${styleGuide.check_list_bottom_spacing}rem;
          }
          .sriracha .dark-bg ul[data-checked] li,
          .sriracha.dark-bg ul[data-checked] li,
          .sriracha .box .dark-bg ul[data-checked] li {
            color: ${styleGuide.check_list_dark_bg_color?.code || 'inherit'};
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3E%3Cpath fill='${encodeURIComponent(
              styleGuide.check_list_dark_bg_icon_color?.code || 'inherit'
            )}' d='M0 11l2-2 5 5L18 3l2 2L7 18z'/%3E%3C/svg%3E");
          }
          .sriracha.mobile ul,
          .sriracha.mobile ul[data-checked] {
            font-size: ${styleGuide.check_list_font_size_mobile}rem !important;
            line-height: ${styleGuide.check_list_line_height_mobile || styleGuide.check_list_line_height};
          }
          .sriracha.mobile ul[data-checked] li {
            background-size: ${styleGuide.check_list_icon_size_mobile || styleGuide.check_list_icon_size}em;
            padding-bottom: ${styleGuide.check_list_bottom_spacing_mobile || styleGuide.check_list_bottom_spacing}rem;
          }
          @media only screen and (max-width: 767px) {
            .sriracha ul[data-checked],
            .sriracha .box .light-bg ul,
            .sriracha .box .light-bg ul[data-checked] {
              font-size: ${styleGuide.check_list_font_size_mobile}rem;
              line-height: ${styleGuide.check_list_line_height_mobile || styleGuide.check_list_line_height};
            }
            .sriracha ul[data-checked] li,
            .sriracha .box .light-bg ul[data-checked] li {
              background-size: ${
                styleGuide.check_list_icon_size_mobile || styleGuide.check_list_icon_size
              }em !important;
              padding-bottom: ${styleGuide.check_list_bottom_spacing_mobile || styleGuide.check_list_bottom_spacing}rem;
            }
          }

          .sriracha header.primary-header nav#primary-nav {
            align-items: center;
          }

          .sriracha header.primary-header nav#primary-nav ul li a.link.active {
            color: ${styleGuide.header_menu_current_color?.code || 'inherit'};
            opacity: ${styleGuide.header_menu_current_opacity} !important;
          }
          .sriracha header.primary-header.dark-bg nav#primary-nav ul li a.link.active {
            color: ${styleGuide.header_menu_dark_bg_current_color?.code || 'inherit'};
            opacity: ${styleGuide.header_menu_dark_bg_current_opacity}!important;
          }
          .sriracha header.primary-header nav#primary-nav a.link {
            font-family: ${styleGuide.header_menu_font_css_family};
            font-size: ${styleGuide.header_menu_font_size}rem;
            font-weight: ${styleGuide.header_menu_font_weight};
            text-transform: ${styleGuide.header_menu_text_transform || 'none'};
            letter-spacing: ${styleGuide.header_menu_letter_spacing || 'normal'};
          }

          .sriracha header.primary-header nav#primary-nav a.text-action {
            font-family: ${styleGuide.header_menu_action_font_css_family};
            font-size: ${styleGuide.header_menu_action_font_size}rem;
            font-weight: ${styleGuide.header_menu_action_font_weight};
          }

          .sriracha header.primary-header.light-bg nav#primary-nav,
          .sriracha header.primary-header.light-bg nav#primary-nav a.link {
            color: ${styleGuide.header_menu_color?.code || 'inherit'};
          }
          .sriracha header.primary-header.light-bg nav#primary-nav a.cart-icon svg path {
            stroke: ${styleGuide.header_menu_color?.code || 'inherit'};
          }
          .sriracha header.primary-header.light-bg nav#primary-nav a.cart-icon:hover svg path {
            stroke: ${styleGuide.header_menu_hover_color?.code || 'inherit'};
          }
          .sriracha header.primary-header.light-bg nav#primary-nav a.link {
            opacity: ${styleGuide.header_menu_opacity};
          }
          @media only screen and (max-width: 767px) {
            .sriracha header.primary-header.light-bg nav#primary-nav .secondary-navigation a {
              color: ${styleGuide.header_menu_color?.code || 'inherit'};
            }
          }
          .sriracha header.primary-header.light-bg nav#primary-nav a.link:hover {
            color: ${styleGuide.header_menu_hover_color?.code || 'inherit'};
            opacity: ${styleGuide.header_menu_hover_opacity} !important;
          }
          .sriracha header.primary-header.light-bg nav#primary-nav a.text-action {
            color: ${styleGuide.header_menu_action_color?.code || 'inherit'};
            text-transform: ${styleGuide.header_menu_action_text_transform || 'none'};
            letter-spacing: ${styleGuide.header_menu_action_letter_spacing || 'normal'};
          }
          .sriracha header.primary-header.light-bg nav#primary-nav a.text-action:after {
            color: ${styleGuide.header_menu_action_color?.code || 'inherit'};
          }

          .sriracha header.primary-header.dark-bg nav#primary-nav,
          .sriracha header.primary-header.dark-bg nav#primary-nav a.link {
            color: ${styleGuide.header_menu_dark_bg_color?.code || 'inherit'};
          }
          .sriracha header.primary-header.dark-bg nav#primary-nav a.link {
            opacity: ${styleGuide.header_menu_dark_bg_opacity};
          }
          .sriracha header.primary-header.dark-bg nav#primary-nav a.cart-icon svg path {
            stroke: ${styleGuide.header_menu_dark_bg_color?.code || 'inherit'};
          }
          .sriracha header.primary-header.dark-bg nav#primary-nav a.cart-icon:hover svg path {
            stroke: ${styleGuide.header_menu_dark_bg_hover_color?.code || 'inherit'};
          }
          @media only screen and (max-width: 767px) {
            .sriracha header.primary-header.dark-bg nav#primary-nav .secondary-navigation a {
              color: ${styleGuide.header_menu_dark_bg_color?.code || 'inherit'};
            }
          }
          .sriracha header.primary-header.dark-bg nav#primary-nav a.link:hover {
            color: ${styleGuide.header_menu_dark_bg_hover_color?.code || 'inherit'};
            opacity: ${styleGuide.header_menu_dark_bg_hover_opacity} !important;
          }
          .sriracha header.primary-header.dark-bg nav#primary-nav a.text-action {
            color: ${styleGuide.header_menu_dark_bg_action_color?.code || 'inherit'} !important;
          }
          .sriracha header.primary-header.dark-bg nav#primary-nav a.text-action:after {
            color: ${styleGuide.header_menu_dark_bg_action_color?.code || 'inherit'} !important;
          }

          .sriracha footer .primary-footer,
          .sriracha footer .secondary-footer,
          .sriracha footer.landing-page-footer,
          .sriracha footer.light-bg .primary-footer .contact-info p {
            font-family: ${styleGuide.footer_menu_font_css_family};
          }
          .sriracha footer .primary-footer .menu h4 {
            font-family: ${styleGuide.footer_category_font_css_family};
            font-size: ${(styleGuide.footer_category_font_size * 1.6) / 1.5}rem;
            font-weight: ${styleGuide.footer_category_font_weight};
            color: ${styleGuide.footer_dark_bg_category_color?.code || 'inherit'};
            text-transform: ${styleGuide.footer_menu_category_text_transform || 'none'};
            letter-spacing: ${styleGuide.footer_menu_category_letter_spacing || 'normal'};
          }
          .sriracha footer .primary-footer .menu li a {
            font-family: ${styleGuide.footer_menu_font_css_family};
            font-size: ${styleGuide.footer_menu_font_size}rem;
            font-weight: ${styleGuide.footer_menu_font_weight} !important;
            color: ${styleGuide.footer_menu_dark_bg_color?.code || 'inherit'};
            opacity: ${styleGuide.footer_menu_dark_bg_opacity};
            text-transform: ${styleGuide.footer_menu_text_transform || 'none'};
            letter-spacing: ${styleGuide.footer_menu_letter_spacing || 'normal'};
          }

          .sriracha footer.light-bg .primary-footer,
          .sriracha footer.light-bg .secondary-footer,
          .sriracha footer.light-bg.landing-page-footer,
          .sriracha footer.light-bg .primary-footer .contact-info p,
          .sriracha footer.light-bg .primary-footer .menu li a {
            color: ${styleGuide.footer_menu_color?.code || 'inherit'} !important;
          }

          .sriracha footer.dark-bg .primary-footer,
          .sriracha footer.dark-bg .secondary-footer,
          .sriracha footer.dark-bg.landing-page-footer,
          .sriracha footer.dark-bg .primary-footer .contact-info p,
          .sriracha footer.dark-bg .primary-footer .menu li a {
            color: ${styleGuide.footer_menu_dark_bg_color?.code || 'inherit'} !important;
          }

          .sriracha footer .primary-footer .menu li a:hover {
            color: ${styleGuide.footer_dark_bg_link_hover_color?.code || 'inherit'} !important;
            opacity: ${styleGuide.footer_dark_bg_link_hover_opacity} !important;
          }

          .sriracha footer.light-bg .primary-footer .menu li a {
            opacity: ${styleGuide.footer_menu_opacity};
          }
          .sriracha footer.light-bg .primary-footer .menu h4 {
            color: ${styleGuide.footer_category_color?.code || 'inherit'};
          }
          .sriracha footer.light-bg .primary-footer .menu li a:hover {
            color: ${styleGuide.footer_link_hover_color?.code || 'inherit'} !important;
            opacity: ${styleGuide.footer_link_hover_opacity} !important;
          }

          .sriracha .blog-listing,
          .sriracha .blog-listing article,
          .sriracha .blog-listing article p,
          .sriracha .blog-content,
          .sriracha .blog-content p,
          .sriracha .blog-content article,
          .sriracha .blog-content footer,
          .sriracha .blog-content article,
          .sriracha .blog-content article p {
            font-family: ${styleGuide.blog_paragraph_font_css_family};
            font-size: ${styleGuide.blog_paragraph_font_size}rem;
            font-weight: ${styleGuide.blog_paragraph_font_weight};
            color: ${styleGuide.blog_paragraph_color?.code || 'inherit'};
            line-height: ${styleGuide.blog_paragraph_line_height};
            margin: ${styleGuide.blog_paragraph_margin};
          }
          .admin .dark-bg .blog-content p,
          .sriracha .dark-bg .blog-content p,
          .sriracha .box .dark-bg .blog-content p,
          .admin .dark-bg .blog-listing p,
          .sriracha .dark-bg .blog-listing p,
          .sriracha .box .dark-bg .blog-listing p {
            color: ${styleGuide.blog_paragraph_dark_bg_color?.code || 'inherit'};
            text-shadow: 0 1px 3px rgba(0,0,0,0.2);
          }
          .admin .mobile .blog-content p,
          .sriracha .mobile .blog-content p,
          .sriracha .box .mobile .blog-content p,
          .admin .mobile .blog-listing p,
          .sriracha .mobile .blog-listing p,
          .sriracha .box .mobile .blog-listing p {
            font-size: ${styleGuide.blog_paragraph_font_size_mobile}rem;
            line-height: ${styleGuide.blog_paragraph_line_height_mobile || styleGuide.blog_paragraph_line_height};
            letter-spacing: ${
              styleGuide.blog_paragraph_letter_spacing_mobile || styleGuide.blog_paragraph_letter_spacing || 'normal'
            };
            margin: ${styleGuide.blog_paragraph_margin_mobile || styleGuide.blog_paragraph_margin};
          }
          @media only screen and (max-width: 767px) {
            .sriracha .blog-content p,
            .sriracha .blog-content p *,
            .sriracha .blog-listing p,
            .sriracha .blog-listing p *,
            .sriracha .box .blog-content p,
            .sriracha .box .blog-listing p {
              font-size: ${styleGuide.blog_paragraph_font_size_mobile}rem !important;
              line-height: ${
                styleGuide.blog_paragraph_line_height_mobile || styleGuide.blog_paragraph_line_height
              } !important;
              letter-spacing: ${
                styleGuide.blog_paragraph_letter_spacing_mobile || styleGuide.blog_paragraph_letter_spacing || 'normal'
              } !important;
              margin: ${styleGuide.blog_paragraph_margin_mobile || styleGuide.blog_paragraph_margin} !important;
            }
          }

          .sriracha .blog-content > article * {
            font-family: inherit;
            font-size: inherit;
          }

          .sriracha .blog-listing article a,
          .sriracha .blog-content > article a,
          .sriracha .blog-content > article * a,
          .sriracha .blog-content > article * a * {
            color: ${styleGuide.action_link_button_text_color?.code || 'inherit'};
          }

          .sriracha .blog-content > article pre {
            font-family: monospace;
            font-size: 0.8em;
          }

          .sriracha .blog-content h1,
          .sriracha .blog-content h1 *,
          .sriracha .blog-listing h1,
          .sriracha .blog-listing h1 * {
            font-family: ${styleGuide.blog_header_1_font_css_family};
            font-size: ${styleGuide.blog_header_1_font_size}rem;
            font-weight: ${styleGuide.blog_header_1_font_weight} !important;
            color: ${styleGuide.blog_header_1_color?.code || 'inherit'};
            line-height: ${styleGuide.blog_header_1_line_height} !important;
            text-transform: ${styleGuide.blog_header_1_text_transform || 'none'};
            letter-spacing: ${styleGuide.blog_header_1_letter_spacing || 'normal'};
            margin: ${styleGuide.blog_header_1_margin};
          }
          .admin div div button div div ul .is-blog h1 {
            font-size: ${blog_h1_fontsize}rem;
            font-weight: ${styleGuide.blog_header_1_font_weight};
            font-family: ${styleGuide.blog_header_1_font_css_family};
            text-transform: ${styleGuide.blog_header_1_text_transform || 'none'};
            letter-spacing: ${styleGuide.blog_header_1_letter_spacing || 'normal'};
          }
          .admin .dark-bg .blog-content h1,
          .sriracha .dark-bg .blog-content h1,
          .sriracha .box .dark-bg .blog-content h1,
          .admin .dark-bg .blog-listing h1,
          .sriracha .dark-bg .blog-listing h1,
          .sriracha .box .dark-bg .blog-listing h1 {
            color: ${styleGuide.blog_header_1_dark_bg_color?.code || 'inherit'};
            text-shadow: 0 1px 3px rgba(0,0,0,0.2);
          }
          .admin .mobile .blog-content h1,
          .sriracha .mobile .blog-content h1,
          .sriracha .box .mobile .blog-content h1,
          .admin .mobile .blog-listing h1,
          .sriracha .mobile .blog-listing h1,
          .sriracha .box .mobile .blog-listing h1 {
            font-size: ${styleGuide.blog_header_1_font_size_mobile}rem;
            line-height: ${
              styleGuide.blog_header_1_line_height_mobile || styleGuide.blog_header_1_line_height
            } !important;
            letter-spacing: ${
              styleGuide.blog_header_1_letter_spacing_mobile || styleGuide.blog_header_1_letter_spacing || 'normal'
            } !important;
            margin: ${styleGuide.blog_header_1_margin_mobile || styleGuide.blog_header_1_margin} !important;
          }
          @media only screen and (max-width: 767px) {
            .sriracha .blog-content h1,
            .sriracha .blog-content h1 *,
            .sriracha .blog-listing h1,
            .sriracha .blog-listing h1 *,
            .sriracha .box .blog-content h1,
            .sriracha .box .blog-listing h1 {
              font-size: ${styleGuide.blog_header_1_font_size_mobile}rem !important;
              line-height: ${
                styleGuide.blog_header_1_line_height_mobile || styleGuide.blog_header_1_line_height
              } !important;
              letter-spacing: ${
                styleGuide.blog_header_1_letter_spacing_mobile || styleGuide.blog_header_1_letter_spacing || 'normal'
              } !important;
              margin: ${styleGuide.blog_header_1_margin_mobile || styleGuide.blog_header_1_margin} !important;
            }
          }

          .sriracha .blog-content h2,
          .sriracha .blog-content h2 *,
          .sriracha .blog-listing h2,
          .sriracha .blog-listing h2 * {
            font-family: ${styleGuide.blog_header_2_font_css_family};
            font-size: ${styleGuide.blog_header_2_font_size}rem;
            font-weight: ${styleGuide.blog_header_2_font_weight};
            color: ${styleGuide.blog_header_2_color?.code || 'inherit'};
            line-height: ${styleGuide.blog_header_2_line_height} !important;
            text-transform: ${styleGuide.blog_header_2_text_transform || 'none'};
            letter-spacing: ${styleGuide.blog_header_2_letter_spacing || 'normal'};
            margin: ${styleGuide.blog_header_2_margin};
          }
          .admin div div button div div ul .is-blog h2 {
            font-size: ${blog_h2_fontsize}rem;
            font-weight: ${styleGuide.blog_header_2_font_weight};
            font-family: ${styleGuide.blog_header_2_font_css_family};
            text-transform: ${styleGuide.blog_header_2_text_transform || 'none'};
            letter-spacing: ${styleGuide.blog_header_2_letter_spacing || 'normal'};
          }
          .admin .dark-bg .blog-content h2,
          .sriracha .dark-bg .blog-content h2,
          .sriracha .box .dark-bg .blog-content h2,
          .admin .dark-bg .blog-listing h2,
          .sriracha .dark-bg .blog-listing h2,
          .sriracha .box .dark-bg .blog-listing h2 {
            color: ${styleGuide.blog_header_2_dark_bg_color?.code || 'inherit'};
            text-shadow: 0 1px 3px rgba(0,0,0,0.2);
          }
          .admin .mobile .blog-content h2,
          .sriracha .mobile .blog-content h2,
          .sriracha .box .mobile .blog-content h2,
          .admin .mobile .blog-listing h2,
          .sriracha .mobile .blog-listing h2,
          .sriracha .box .mobile .blog-listing h2 {
            font-size: ${styleGuide.blog_header_2_font_size_mobile}rem;
            line-height: ${
              styleGuide.blog_header_2_line_height_mobile || styleGuide.blog_header_2_line_height
            } !important;
            letter-spacing: ${
              styleGuide.blog_header_2_letter_spacing_mobile || styleGuide.blog_header_2_letter_spacing || 'normal'
            } !important;
            margin: ${styleGuide.blog_header_2_margin_mobile || styleGuide.blog_header_2_margin} !important;
          }
          @media only screen and (max-width: 767px) {
            .sriracha .blog-content h2,
            .sriracha .blog-content h2 *,
            .sriracha .blog-listing h2,
            .sriracha .blog-listing h2 *,
            .sriracha .box .blog-content h2,
            .sriracha .box .blog-listing h2 {
              font-size: ${styleGuide.blog_header_2_font_size_mobile}rem !important;
              line-height: ${
                styleGuide.blog_header_2_line_height_mobile || styleGuide.blog_header_2_line_height
              } !important;
              letter-spacing: ${
                styleGuide.blog_header_2_letter_spacing_mobile || styleGuide.blog_header_2_letter_spacing || 'normal'
              } !important;
              margin: ${styleGuide.blog_header_2_margin_mobile || styleGuide.blog_header_2_margin} !important;
            }
          }

          .sriracha .blog-content h3,
          .sriracha .blog-content h3 *,
          .sriracha .blog-listing h3,
          .sriracha .blog-listing h3 * {
            font-family: ${styleGuide.blog_header_3_font_css_family};
            font-size: ${styleGuide.blog_header_3_font_size}rem;
            font-weight: ${styleGuide.blog_header_3_font_weight};
            color: ${styleGuide.blog_header_3_color?.code || 'inherit'};
            line-height: ${styleGuide.blog_header_3_line_height} !important;
            text-transform: ${styleGuide.blog_header_3_text_transform || 'none'};
            letter-spacing: ${styleGuide.blog_header_3_letter_spacing || 'normal'};
            margin: ${styleGuide.blog_header_3_margin};
          }
          .admin div div button div div ul .is-blog h3 {
            font-size: ${blog_h3_fontsize}rem;
            font-weight: ${styleGuide.blog_header_3_font_weight};
            font-family: ${styleGuide.blog_header_3_font_css_family};
            text-transform: ${styleGuide.blog_header_3_text_transform || 'none'};
            letter-spacing: ${styleGuide.blog_header_3_letter_spacing || 'normal'};
          }
          .admin .dark-bg .blog-content h3,
          .sriracha .dark-bg .blog-content h3,
          .sriracha .box .dark-bg .blog-content h3,
          .admin .dark-bg .blog-listing h3,
          .sriracha .dark-bg .blog-listing h3,
          .sriracha .box .dark-bg .blog-listing h3 {
            color: ${styleGuide.blog_header_3_dark_bg_color?.code || 'inherit'};
            text-shadow: 0 1px 3px rgba(0,0,0,0.2);
          }
          .admin .mobile .blog-content h3,
          .sriracha .mobile .blog-content h3,
          .sriracha .box .mobile .blog-content h3,
          .admin .mobile .blog-listing h3,
          .sriracha .mobile .blog-listing h3,
          .sriracha .box .mobile .blog-listing h3 {
            font-size: ${styleGuide.blog_header_3_font_size_mobile}rem;
            line-height: ${
              styleGuide.blog_header_3_line_height_mobile || styleGuide.blog_header_3_line_height
            } !important;
            letter-spacing: ${
              styleGuide.blog_header_3_letter_spacing_mobile || styleGuide.blog_header_3_letter_spacing || 'normal'
            } !important;
            margin: ${styleGuide.blog_header_3_margin_mobile || styleGuide.blog_header_3_margin} !important;
          }
          @media only screen and (max-width: 767px) {
            .sriracha .blog-content h3,
            .sriracha .blog-content h3 *,
            .sriracha .blog-listing h3,
            .sriracha .blog-listing h3 *,
            .sriracha .box .blog-content h3,
            .sriracha .box .blog-listing h3 {
              font-size: ${styleGuide.blog_header_3_font_size_mobile}rem !important;
              line-height: ${
                styleGuide.blog_header_3_line_height_mobile || styleGuide.blog_header_3_line_height
              } !important;
              letter-spacing: ${
                styleGuide.blog_header_3_letter_spacing_mobile || styleGuide.blog_header_3_letter_spacing || 'normal'
              } !important;
              margin: ${styleGuide.blog_header_3_margin_mobile || styleGuide.blog_header_3_margin} !important;
            }
          }

          .sriracha .blog-content h4,
          .sriracha .blog-content h4 *,
          .sriracha .blog-listing h4,
          .sriracha .blog-listing h4 * {
            font-family: ${styleGuide.blog_header_4_font_css_family};
            font-size: ${styleGuide.blog_header_4_font_size}rem;
            font-weight: ${styleGuide.blog_header_4_font_weight} !important;
            color: ${styleGuide.blog_header_4_color?.code || 'inherit'};
            line-height: ${styleGuide.blog_header_4_line_height} !important;
            text-transform: ${styleGuide.blog_header_4_text_transform || 'none'};
            letter-spacing: ${styleGuide.blog_header_4_letter_spacing || 'normal'};
            margin: ${styleGuide.blog_header_4_margin};
          }
          .admin div div button div div ul .is-blog h4 {
            font-size: ${blog_h4_fontsize}rem;
            font-weight: ${styleGuide.blog_header_4_font_weight};
            font-family: ${styleGuide.blog_header_4_font_css_family};
            text-transform: ${styleGuide.blog_header_4_text_transform || 'none'};
            letter-spacing: ${styleGuide.blog_header_4_letter_spacing || 'normal'};
          }
          .admin .dark-bg .blog-content h4,
          .sriracha .dark-bg .blog-content h4,
          .sriracha .box .dark-bg .blog-content h4,
          .admin .dark-bg .blog-listing h4,
          .sriracha .dark-bg .blog-listing h4,
          .sriracha .box .dark-bg .blog-listing h4 {
            color: ${styleGuide.blog_header_4_dark_bg_color?.code || 'inherit'};
            text-shadow: 0 1px 3px rgba(0,0,0,0.2);
          }
          .admin .mobile .blog-content h4,
          .sriracha .mobile .blog-content h4,
          .sriracha .box .mobile .blog-content h4,
          .admin .mobile .blog-listing h4,
          .sriracha .mobile .blog-listing h4,
          .sriracha .box .mobile .blog-listing h4 {
            font-size: ${styleGuide.blog_header_4_font_size_mobile}rem;
            line-height: ${
              styleGuide.blog_header_4_line_height_mobile || styleGuide.blog_header_4_line_height
            } !important;
            letter-spacing: ${
              styleGuide.blog_header_4_letter_spacing_mobile || styleGuide.blog_header_4_letter_spacing || 'normal'
            } !important;
            margin: ${styleGuide.blog_header_4_margin_mobile || styleGuide.blog_header_4_margin} !important;
          }
          @media only screen and (max-width: 767px) {
            .sriracha .blog-content h4,
            .sriracha .blog-content h4 *,
            .sriracha .blog-listing h4,
            .sriracha .blog-listing h4 *,
            .sriracha .box .blog-content h4,
            .sriracha .box .blog-listing h4 {
              font-size: ${styleGuide.blog_header_4_font_size_mobile}rem !important;
              line-height: ${
                styleGuide.blog_header_4_line_height_mobile || styleGuide.blog_header_4_line_height
              } !important;
              letter-spacing: ${
                styleGuide.blog_header_4_letter_spacing_mobile || styleGuide.blog_header_4_letter_spacing || 'normal'
              } !important;
              margin: ${styleGuide.blog_header_4_margin_mobile || styleGuide.blog_header_4_margin} !important;
            }
          }

          .sriracha .blog-content h5,
          .sriracha .blog-content h5 *,
          .sriracha .blog-listing h5,
          .sriracha .blog-listing h5 * {
            font-family: ${styleGuide.blog_header_5_font_css_family};
            font-size: ${styleGuide.blog_header_5_font_size}rem;
            font-weight: ${styleGuide.blog_header_5_font_weight};
            color: ${styleGuide.blog_header_5_color?.code || 'inherit'};
            line-height: ${styleGuide.blog_header_5_line_height} !important;
            text-transform: ${styleGuide.blog_header_5_text_transform || 'none'};
            letter-spacing: ${styleGuide.blog_header_5_letter_spacing || 'normal'};
            margin: ${styleGuide.blog_header_5_margin};
          }
          .admin div div button div div ul .is-blog h5 {
            font-size: ${blog_h5_fontsize}rem;
            font-weight: ${styleGuide.blog_header_5_font_weight};
            font-family: ${styleGuide.blog_header_5_font_css_family};
            text-transform: ${styleGuide.blog_header_5_text_transform || 'none'};
            letter-spacing: ${styleGuide.blog_header_5_letter_spacing || 'normal'};
          }
          .admin .dark-bg .blog-content h5,
          .sriracha .dark-bg .blog-content h5,
          .sriracha .box .dark-bg .blog-content h5,
          .admin .dark-bg .blog-listing h5,
          .sriracha .dark-bg .blog-listing h5,
          .sriracha .box .dark-bg .blog-listing h5 {
            color: ${styleGuide.blog_header_5_dark_bg_color?.code || 'inherit'};
            text-shadow: 0 1px 3px rgba(0,0,0,0.2);
          }
          .admin .mobile .blog-content h5,
          .sriracha .mobile .blog-content h5,
          .sriracha .box .mobile .blog-content h5,
          .admin .mobile .blog-listing h5,
          .sriracha .mobile .blog-listing h5,
          .sriracha .box .mobile .blog-listing h5 {
            font-size: ${styleGuide.blog_header_5_font_size_mobile}rem;
            line-height: ${
              styleGuide.blog_header_5_line_height_mobile || styleGuide.blog_header_5_line_height
            } !important;
            letter-spacing: ${
              styleGuide.blog_header_5_letter_spacing_mobile || styleGuide.blog_header_5_letter_spacing || 'normal'
            } !important;
            margin: ${styleGuide.blog_header_5_margin_mobile || styleGuide.blog_header_5_margin} !important;
          }
          @media only screen and (max-width: 767px) {
            .sriracha .blog-content h5,
            .sriracha .blog-content h5 *,
            .sriracha .blog-listing h5,
            .sriracha .blog-listing h5 *,
            .sriracha .box .blog-content h5,
            .sriracha .box .blog-listing h5 {
              font-size: ${styleGuide.blog_header_5_font_size_mobile}rem !important;
              line-height: ${
                styleGuide.blog_header_5_line_height_mobile || styleGuide.blog_header_5_line_height
              } !important;
              letter-spacing: ${
                styleGuide.blog_header_5_letter_spacing_mobile || styleGuide.blog_header_5_letter_spacing || 'normal'
              } !important;
              margin: ${styleGuide.blog_header_5_margin_mobile || styleGuide.blog_header_5_margin} !important;
            }
          }

          .sriracha .blog-content h6,
          .sriracha .blog-content h6 *,
          .sriracha .blog-listing h6,
          .sriracha .blog-listing h6 * {
            font-family: ${styleGuide.blog_header_6_font_css_family};
            font-size: ${styleGuide.blog_header_6_font_size}rem;
            font-weight: ${styleGuide.blog_header_6_font_weight};
            color: ${styleGuide.blog_header_6_color?.code || 'inherit'};
            line-height: ${styleGuide.blog_header_6_line_height} !important;
            text-transform: ${styleGuide.blog_header_6_text_transform || 'none'};
            letter-spacing: ${styleGuide.blog_header_6_letter_spacing || 'normal'};
            margin: ${styleGuide.blog_header_6_margin};
          }
          .admin div div button div div ul .is-blog h6 {
            font-size: ${blog_h6_fontsize}rem;
            font-weight: ${styleGuide.blog_header_6_font_weight};
            font-family: ${styleGuide.blog_header_6_font_css_family};
            text-transform: ${styleGuide.blog_header_6_text_transform || 'none'};
            letter-spacing: ${styleGuide.blog_header_6_letter_spacing || 'normal'};
          }
          .admin .dark-bg .blog-content h6,
          .sriracha .dark-bg .blog-content h6,
          .sriracha .box .dark-bg .blog-content h6,
          .admin .dark-bg .blog-listing h6,
          .sriracha .dark-bg .blog-listing h6,
          .sriracha .box .dark-bg .blog-listing h6 {
            color: ${styleGuide.blog_header_6_dark_bg_color?.code || 'inherit'};
            text-shadow: 0 1px 3px rgba(0,0,0,0.2);
          }
          .admin .mobile .blog-content h6,
          .sriracha .mobile .blog-content h6,
          .sriracha .box .mobile .blog-content h6,
          .admin .mobile .blog-listing h6,
          .sriracha .mobile .blog-listing h6,
          .sriracha .box .mobile .blog-listing h6 {
            font-size: ${styleGuide.blog_header_6_font_size_mobile}rem;
            line-height: ${
              styleGuide.blog_header_6_line_height_mobile || styleGuide.blog_header_6_line_height
            } !important;
            letter-spacing: ${
              styleGuide.blog_header_6_letter_spacing_mobile || styleGuide.blog_header_6_letter_spacing || 'normal'
            } !important;
            margin: ${styleGuide.blog_header_6_margin_mobile || styleGuide.blog_header_6_margin} !important;
          }
          @media only screen and (max-width: 767px) {
            .sriracha .blog-content h6,
            .sriracha .blog-content h6 *,
            .sriracha .blog-listing h6,
            .sriracha .blog-listing h6 *,
            .sriracha .box .blog-content h6,
            .sriracha .box .blog-listing h6 {
              font-size: ${styleGuide.blog_header_6_font_size_mobile}rem !important;
              line-height: ${
                styleGuide.blog_header_6_line_height_mobile || styleGuide.blog_header_6_line_height
              } !important;
              letter-spacing: ${
                styleGuide.blog_header_6_letter_spacing_mobile || styleGuide.blog_header_6_letter_spacing || 'normal'
              } !important;
              margin: ${styleGuide.blog_header_6_margin_mobile || styleGuide.blog_header_6_margin} !important;
            }
          }
        `}
        {/* Light color actions */}
        {styles.button &&
          `
          .sriracha .button.button-primary,
          .sriracha .light-bg .button.button-primary,
          .sriracha .box .light-bg .button.button-primary,
          .sriracha .dark-bg .form-embed-card .button.button-primary,
          .sriracha .box .dark-bg .form-embed-card .button.button-primary,
          .sriracha .product-option-value.selected a {
            background-color: ${styleGuide.primary_button_box_background_color?.code || 'inherit'};
            border-color: ${styleGuide.primary_button_box_border_color?.code || 'inherit'};
            border-radius: ${styleGuide.primary_button_box_border_radius}px;
            border-width: ${styleGuide.primary_button_box_border_width}px;
            border-style: solid;
            box-shadow: ${styleGuide.primary_button_box_shadow_with_unit};
            color: ${styleGuide.primary_button_text_color?.code || 'inherit'};
            font-family: ${styleGuide.primary_button_font_family};
            font-size: ${styleGuide.primary_button_font_size}rem;
            font-weight: ${styleGuide.primary_button_font_weight};
            line-height: ${styleGuide.primary_button_box_line_height};
            text-shadow: ${styleGuide.primary_button_text_shadow_with_unit};
            transition: all 200ms;
            text-transform: ${styleGuide.primary_button_text_transform || 'none'};
            letter-spacing: ${styleGuide.primary_button_letter_spacing || 'normal'};
          }
          .sriracha .dark-bg .button.button-primary,
          .sriracha .button.button-primary.dark-bg,
          .sriracha .dark-bg .product-option-value.selected a {
            background-color: ${styleGuide.primary_button_dark_bg_box_background_color?.code || 'inherit'};
            box-shadow: ${styleGuide.primary_button_dark_bg_box_shadow_unit};
            color: ${styleGuide.primary_button_dark_bg_text_color?.code || 'inherit'};
            text-shadow: ${styleGuide.primary_button_dark_bg_text_shadow_unit};
            border-color: ${styleGuide.primary_button_dark_bg_box_border_color?.code || 'inherit'};
            border-width: ${styleGuide.primary_button_dark_bg_box_border_width}px;
          }

          .sriracha .light-bg .box.dark-bg .button.button-primary:hover {
            background-color: ${styleGuide.primary_button_dark_bg_hover_background_color.code};
            border-color: ${styleGuide.primary_button_dark_bg_hover_border_color.code};
            box-shadow: ${styleGuide.primary_button_dark_bg_hover_shadow_unit};
            opacity: ${styleGuide.primary_button_dark_bg_hover_opacity} !important;
          }
          .sriracha .box .light-bg .button.button-primary:hover {
            background-color: ${styleGuide.primary_button_hover_background_color.code};
            border-color: ${styleGuide.primary_button_hover_border_color.code};
            box-shadow: ${styleGuide.primary_button_hover_shadow_with_unit};
            opacity: ${styleGuide.primary_button_hover_opacity} !important;
          }

          .sriracha .dark-bg .button.button-primary:hover,
          .sriracha .button.button-primary.dark-bg:hover,
          .sriracha .dark-bg .product-option-value.selected a:hover,
          .sriracha .dark-bg .product-option-value.selected a[disabled] {
            background-color: ${styleGuide.primary_button_dark_bg_hover_background_color?.code || 'inherit'};
            border-color: ${styleGuide.primary_button_dark_bg_hover_border_color?.code || 'inherit'};
            color: ${styleGuide.primary_button_dark_bg_hover_text_color?.code};
            box-shadow: ${styleGuide.primary_button_dark_bg_hover_shadow_unit};
            opacity: ${styleGuide.primary_button_dark_bg_hover_opacity} !important;
            text-shadow: ${styleGuide.primary_button_dark_bg_hover_text_shadow_unit};
          }

          .sriracha .mobile .button.button-primary,
          .sriracha .button.button-primary.mobile,
          .sriracha .mobile .product-option-value.selected a {
            font-size: ${styleGuide.primary_button_font_size_mobile}rem;
            letter-spacing: ${
              styleGuide.primary_button_letter_spacing_mobile || styleGuide.primary_button_letter_spacing || 'normal'
            };
            line-height: ${styleGuide.primary_button_line_height_mobile || styleGuide.primary_button_line_height};
            border-radius: ${
              styleGuide.primary_button_box_border_radius_mobile || styleGuide.primary_button_box_border_radius
            }px;
          }
          @media only screen and (max-width: 767px) {
            .sriracha .button.button-primary,
            .sriracha .light-bg .button.button-primary,
            .sriracha .box .light-bg .button.button-primary,
            .sriracha .dark-bg .form-embed-card .button.button-primary,
            .sriracha .box .dark-bg .form-embed-card .button.button-primary,
            .sriracha .product-option-value.selected a {
              font-size: ${styleGuide.primary_button_font_size_mobile}rem;
              letter-spacing: ${
                styleGuide.primary_button_letter_spacing_mobile || styleGuide.primary_button_letter_spacing || 'normal'
              };
              line-height: ${styleGuide.primary_button_line_height_mobile || styleGuide.primary_button_line_height};
              border-radius: ${
                styleGuide.primary_button_box_border_radius_mobile || styleGuide.primary_button_box_border_radius
              }px;
            }
          }

          .sriracha .button.button-primary[disabled],
          .sriracha .light-bg .button.button-primary[disabled],
          .sriracha .box .light-bg .button.button-primary[disabled],
          .sriracha .dark-bg .form-embed-card .button.button-primary[disabled],
          .sriracha .box .dark-bg .form-embed-card .button.button-primary[disabled] {
            background-color: rgba(160,160,160,0.5) !important;
            border-color: rgba(160,160,160,0.5) !important;
            box-shadow: none !important;
            color: rgba(0,0,0,0.4) !important;
            text-shadow: none;
            cursor: not-allowed;
          }

          .sriracha .button.button-primary[disabled]:hover,
          .sriracha .light-bg .button.button-primary[disabled]:hover,
          .sriracha .box .light-bg .button.button-primary[disabled]:hover,
          .sriracha .dark-bg .form-embed-card .button.button-primary[disabled]:hover,
          .sriracha .box .dark-bg .form-embed-card .button.button-primary[disabled]:hover {
            opacity: 1 !important;
            box-shadow: none !important;
          }

          .sriracha .button.button-primary:hover,
          .sriracha .light-bg .button.button-primary:hover,
          .sriracha .dark-bg .form-embed-card .button.button-primary:hover,
          .sriracha .box .dark-bg .form-embed-card .button.button-primary:hover,
          .sriracha .product-option-value.selected a:hover,
          .sriracha .product-option-value.selected a[disabled] {
            background-color: ${styleGuide.primary_button_hover_background_color?.code || 'inherit'};
            border-color: ${styleGuide.primary_button_hover_border_color?.code || 'inherit'};
            color: ${styleGuide.primary_button_hover_text_color?.code};
            box-shadow: ${styleGuide.primary_button_hover_shadow_with_unit};
            opacity: ${styleGuide.primary_button_hover_opacity} !important;
            text-shadow: ${styleGuide.primary_button_hover_text_shadow_with_unit};
          }
        `}
        {styles.button &&
          `
          .sriracha .button,
          .sriracha .button:not(.button-primary),
          .sriracha .light-bg .button,
          .sriracha .box .light-bg .button,
          .sriracha .light-bg .button:not(.button-primary),
          .sriracha .box .light-bg .button:not(.button-primary),
          .sriracha .light-bg .product-option-value:not(.selected) a,
          .sriracha .box .light-bg .product-option-value:not(.selected) a,
          .sriracha .product-option-value:not(.selected) a {
            background-color: ${styleGuide.secondary_button_box_background_color?.code || 'inherit'};
            border-color: ${styleGuide.secondary_button_box_border_color?.code || 'inherit'};
            border-radius: ${styleGuide.secondary_button_box_border_radius}px;
            border-width: ${styleGuide.secondary_button_box_border_width}px;
            border-style: solid;
            box-shadow: ${styleGuide.secondary_button_box_shadow_with_unit};
            color: ${styleGuide.secondary_button_text_color?.code || 'inherit'};
            font-family: ${styleGuide.secondary_button_font_family};
            font-size: ${styleGuide.secondary_button_font_size}rem;
            font-weight: ${styleGuide.secondary_button_font_weight};
            line-height: ${styleGuide.secondary_button_box_line_height};
            text-shadow: ${styleGuide.secondary_button_text_shadow_with_unit};
            text-transform: ${styleGuide.secondary_button_text_transform || 'none'};
            letter-spacing: ${styleGuide.secondary_button_letter_spacing || 'normal'};
            transition: all 200ms;
            width: auto;
          }
          .sriracha .button:hover,
          .sriracha .button:not(.button-primary):hover,
          .sriracha .light-bg .button:hover,
          .sriracha .box .light-bg .button:hover,
          .sriracha .light-bg .button:not(.button-primary):hover,
          .sriracha .box .light-bg .button:not(.button-primary):hover,
          .sriracha .light-bg.button:not(.button-primary):hover,
          .sriracha .box .light-bg.button:not(.button-primary):hover,
          .sriracha .light-bg .product-option-value:not(.selected) a:hover,
          .sriracha .box .light-bg .product-option-value:not(.selected) a:hover,
          .sriracha .light-bg .product-option-value:not(.selected) a[disabled],
          .sriracha .box .light-bg .product-option-value:not(.selected) a[disabled],
          .sriracha .product-option-value:not(.selected) a:hover {
            background-color: ${styleGuide.secondary_button_hover_background_color?.code || 'inherit'};
            border-color: ${styleGuide.secondary_button_hover_border_color?.code || 'inherit'};
            color: ${styleGuide.secondary_button_hover_text_color?.code};
            box-shadow: ${styleGuide.secondary_button_hover_shadow_with_unit};
            opacity: ${styleGuide.secondary_button_hover_opacity} !important;
            text-shadow: ${styleGuide.secondary_button_hover_text_shadow_with_unit};
          }
          .sriracha .dark-bg .button:not(.button-primary),
          .sriracha .dark-bg.button:not(.button-primary),
          .sriracha .dark-bg .product-option-value:not(.selected) a {
            background-color: ${styleGuide.secondary_button_dark_bg_box_background_color?.code || 'inherit'};
            box-shadow: ${styleGuide.secondary_button_dark_bg_box_shadow_with_unit};
            color:${styleGuide.secondary_button_dark_bg_text_color?.code || 'inherit'};
            text-shadow: ${styleGuide.secondary_button_dark_bg_text_shadow_with_unit};
            border-color: ${styleGuide.secondary_button_dark_bg_box_border_color?.code || 'inherit'};
            border-width: ${styleGuide.secondary_button_dark_bg_box_border_width}px;
          }
          .sriracha .dark-bg .button:not(.button-primary):hover,
          .sriracha .dark-bg.button:not(.button-primary):hover,
          .sriracha .dark-bg .product-option-value:not(.selected) a:hover,
          .sriracha .dark-bg .product-option-value:not(.selected) a[disabled] {
            background-color: ${styleGuide.secondary_button_dark_bg_hover_background_color?.code || 'inherit'};
            border-color: ${styleGuide.secondary_button_dark_bg_hover_border_color?.code || 'inherit'};
            color: ${styleGuide.secondary_button_dark_bg_hover_text_color?.code};
            box-shadow: ${styleGuide.secondary_button_dark_bg_hover_shadow_with_unit};
            opacity: ${styleGuide.secondary_button_dark_bg_hover_opacity} !important;
            text-shadow: ${styleGuide.secondary_button_dark_bg_hover_text_shadow_with_unit};
          }

          .sriracha .mobile .button:not(.button-primary),
          .sriracha .mobile.button:not(.button-primary),
          .sriracha .mobile .product-option-value:not(.selected) a {
            font-size: ${styleGuide.secondary_button_font_size_mobile}rem;
            letter-spacing: ${
              styleGuide.secondary_button_letter_spacing_mobile ||
              styleGuide.secondary_button_letter_spacing ||
              'normal'
            };
            line-height: ${styleGuide.secondary_button_line_height_mobile || styleGuide.secondary_button_line_height};
            border-radius: ${
              styleGuide.secondary_button_box_border_radius_mobile || styleGuide.secondary_button_box_border_radius
            }px;
          }
          @media only screen and (max-width: 767px) {
            .sriracha .button,
            .sriracha .light-bg .button,
            .sriracha .box .light-bg .button,
            .sriracha .light-bg .button:not(.button-primary),
            .sriracha .box .light-bg .button:not(.button-primary),
            .sriracha .light-bg .product-option-value:not(.selected) a,
            .sriracha .box .light-bg .product-option-value:not(.selected) a,
            .sriracha .product-option-value:not(.selected) a {
              font-size: ${styleGuide.secondary_button_font_size_mobile}rem;
              letter-spacing: ${
                styleGuide.secondary_button_letter_spacing_mobile ||
                styleGuide.secondary_button_letter_spacing ||
                'normal'
              };
              line-height: ${styleGuide.secondary_button_line_height_mobile || styleGuide.secondary_button_line_height};
              border-radius: ${
                styleGuide.secondary_button_box_border_radius_mobile || styleGuide.secondary_button_box_border_radius
              }px;
            }
          }
        `}
        {/* Action Link styles */}
        {`
        .sriracha .text-action,
        .sriracha .light-bg .text-action,
        .sriracha .box .light-bg .text-action {
          font-family: ${styleGuide.action_link_button_font_family};
          font-weight: ${styleGuide.action_link_button_font_weight};
          font-size: ${styleGuide.action_link_button_font_size}rem;
          color: ${styleGuide.action_link_button_text_color?.code || 'inherit'};
          text-transform: ${styleGuide.action_link_button_text_transform || 'none'};
          letter-spacing: ${styleGuide.action_link_button_letter_spacing || 'normal'};
          transition: all 200ms;
        }
        .sriracha .text-action:after,
        .sriracha .light-bg .text-action:after,
        .sriracha .box .light-bg .text-action:after {
          font-size: ${styleGuide.action_link_button_arrow_size}em;
          color: ${styleGuide.action_link_button_arrow_color?.code || 'inherit'};
        }
        .sriracha .text-action.dark-bg,
        .sriracha .dark-bg .text-action,
        .sriracha .box .dark-bg .text-action {
          color: ${styleGuide.action_link_button_dark_bg_text_color?.code || 'inherit'};
        }
        .sriracha .text-action.dark-bg:after,
        .sriracha .dark-bg .text-action:after,
        .sriracha .box .dark-bg .text-action:after {
          color: ${styleGuide.action_link_button_dark_bg_arrow_color?.code || 'inherit'};
        }
        .sriracha .text-action:hover,
        .sriracha .light-bg .text-action:hover,
        .sriracha .box .light-bg .text-action:hover {
          color: ${styleGuide.action_link_button_hover_color?.code || 'inherit'};
          opacity: ${styleGuide.action_link_button_hover_opacity};
        }
        .sriracha .text-action.dark-bg:hover,
        .sriracha .dark-bg .text-action:hover,
        .sriracha .box .dark-bg .text-action:hover {
          color: ${styleGuide.action_link_button_dark_bg_hover_color?.code || 'inherit'};
          opacity: ${styleGuide.action_link_button_dark_bg_hover_opacity};
        }
        .sriracha .text-action.mobile,
        .sriracha .mobile .text-action,
        .sriracha .box .mobile .text-action {
          font-size: ${styleGuide.action_link_button_font_size_mobile}rem;
          letter-spacing: ${styleGuide.action_link_button_letter_spacing_mobile || 'normal'};
        }
        .sriracha .text-action.mobile:after,
        .sriracha .mobile .text-action:after,
        .sriracha .box .mobile .text-action:after {
          font-size: ${styleGuide.action_link_button_arrow_size_mobile}em;
        }
        @media only screen and (max-width: 767px) {
          .sriracha .text-action,
          .sriracha .light-bg .text-action,
          .sriracha .box .light-bg .text-action {
            font-size: ${styleGuide.action_link_button_font_size_mobile}rem;
            letter-spacing: ${styleGuide.action_link_button_letter_spacing_mobile || 'normal'};
          }
          .sriracha .text-action:after,
          .sriracha .light-bg .text-action:after,
          .sriracha .box .light-bg .text-action:after {
            font-size: ${styleGuide.action_link_button_arrow_size_mobile}em;
          }
        }`}
        {/* Header & Footer styles */}
        {`
          .sriracha header.primary-header,
          .sriracha header.landing-page-header {
            background-color: ${styles?.headerBackgroundColor?.code || '#FFFFFF'};
          }

          .sriracha header.primary-header.dark-bg,
          .sriracha header.landing-page-header.dark-bg {
            background-color: ${styles?.headerDarkBackgroundColor?.code || '#FFFFFF'};
          }

          .sriracha header.primary-header,
          .sriracha header.landing-page-header, .sriracha nav#primary-nav {
            background-color: ${styles?.headerBackgroundColor?.code || '#FFFFFF'};
          }

          .sriracha header.primary-header.dark-bg,
          .sriracha header.landing-page-header.dark-bg, .sriracha header.primary-header.dark-bg nav#primary-nav {
            background-color: ${styles?.headerDarkBackgroundColor?.code || '#FFFFFF'};
          }

          .sriracha footer.main-footer,
          .sriracha footer.landing-page-footer {
            background-color: ${styles?.footerBackgroundColor?.code || '#293241'};
          }
        `}
        {/* HeaderLogo height and vertical positioning */}
        {styles.logo &&
          styles.logo.header.height != null &&
          `
          .sriracha header img.logo,
          .sriracha header .logo img,
          .sriracha .header-container img.logo,
          .sriracha .header-container .logo img {
            height: ${styles.logo.header.height}px !important;
          }
        `}
        {styles.logo &&
          styles.logo.header.position != null &&
          `
          .sriracha header img.logo,
          .sriracha header .logo img,
          .sriracha .header-container img.logo,
          .sriracha .header-container .logo img {
            margin-top: ${styles.logo.header.position * -1}px !important;
          }
        `}
        {/* Footer Logo height and vertical positioning */}
        {styles.logo &&
          styles.logo.footer.height != null &&
          `
          .sriracha footer img.logo,
          .sriracha footer .logo img,
          .sriracha footer .primary-footer img.logo,
          .sriracha footer .primary-footer .logo img {
            height: ${styles.logo.footer.height}px !important;
          }
        `}
        {styles.logo &&
          styles.logo.footer.position != null &&
          `
          .sriracha footer img.logo,
          .sriracha footer .logo img,
          .sriracha footer .primary-footer img.logo,
          .sriracha footer .primary-footer .logo img {
            margin-top: ${styles.logo.footer.position * -1}px !important;
          }
        `}
        {/* Blog blockquote coloring */}
        {styles.defaultColors &&
          `
          .sriracha .rich-text blockquote,
          .sriracha .rich-text .light-bg blockquote,
          .sriracha .box .rich-text .light-bg blockquote {
            border-left-color: ${
              styles.defaultColors?.find((color) => color.label.includes('primary'))?.code || 'inherit'
            } !important;
            padding-left: 2rem;
          }
          .sriracha .light-bg blockquote:not([id]),
          .sriracha .box .light-bg blockquote:not([id]) {
            color: ${styleGuide.paragraph_color?.code || 'inherit'};
          }
          .sriracha .dark-bg blockquote:not([id]),
          .sriracha .box .dark-bg blockquote:not([id]) {
            color: ${styleGuide.paragraph_dark_bg_color?.code || 'inherit'};
          }
        `}
        {`
          .sriracha strong {
            font-weight: 700 !important;
          }

          .sriracha pre {
            text-align: left;
          }
        `}
        {`
          .pricing-tier-column > .pricing-tier header > * {
            color: #000;
            text-shadow: none;
          }
          .pricing-tier-column > .pricing-tier ul > * {
            color: ${styleGuide.paragraph_color?.code || 'inherit'};
          }
          .pricing-tier-column > .pricing-tier ul > li > .spark-contenteditable:empty {
            color: rgba(0, 0, 0, 0.35) !important;
          }
          .pricing-tier-column > .pricing-tier footer > .actions > .buttons-container > a {
            color: #fff;
            text-shadow: none;
          }
          .pricing-tier-column > .pricing-tier footer > .actions > .add-actions > svg {
            color: rgba(9, 11, 69, 0.24);
          }
        `}
        {/* Forms */}
        {`
          /*
          .sriracha form.unstack-form label,
          .sriracha form.unstack-form input[type='email'],
          .sriracha form.unstack-form input[type='number'],
          .sriracha form.unstack-form input[type='search'],
          .sriracha form.unstack-form input[type='text'],
          .sriracha form.unstack-form input[type='tel'],
          .sriracha form.unstack-form input[type='url'],
          .sriracha form.unstack-form input[type='password'] {
            font-family: ${styleGuide.paragraph_font_css_family};
            font-size: ${styleGuide.paragraph_font_size}rem;
            font-weight: ${styleGuide.paragraph_font_weight};
            color: ${styleGuide.paragraph_color?.code || 'inherit'};
          }
          */

          .sriracha input[type="email"],
          .sriracha input[type="phone"],
          .sriracha input[type="number"],
          .sriracha input[type="search"],
          .sriracha input[type="text"],
          .sriracha input[type="tel"],
          .sriracha input[type="url"],
          .sriracha input[type="password"],
          .sriracha textarea,
          .sriracha select {
            height: auto; /* Needed to override admin styling */
            padding: ${styleGuide.styles.form_field_padding_y}rem ${styleGuide.styles.form_field_padding_x}rem;
            font-family: ${styleGuide.form_field_font_family};
            font-weight: ${styleGuide.styles.form_field_font_weight};
            font-size: ${styleGuide.styles.form_field_font_size}rem;
            color: ${styleGuide.styles.form_field_font_color?.code || 'inherit'};
            background-color:  ${styleGuide.styles.form_field_background_color?.code || 'inherit'};
            border-radius:  ${styleGuide.styles.form_field_border_radius}px;
            border: 1px solid  ${styleGuide.styles.form_field_border_color?.code || 'inherit'}
          }

          .sriracha input[type="email"]:focus,
          .sriracha input[type="phone"]:focus,
          .sriracha input[type="number"]:focus,
          .sriracha input[type="search"]:focus,
          .sriracha input[type="text"]:focus,
          .sriracha input[type="tel"]:focus,
          .sriracha input[type="url"]:focus,
          .sriracha input[type="password"]:focus,
          .sriracha textarea:focus,
          .sriracha select:focus {
            border: 1px solid  ${
              styleGuide.styles.form_field_focus_border_color?.code || 'inherit'
            } /* $primary-color */
          }

          .sriracha input[type="email"]::placeholder,
          .sriracha input[type="phone"]::placeholder,
          .sriracha input[type="number"]::placeholder,
          .sriracha input[type="search"]::placeholder,
          .sriracha input[type="text"]::placeholder,
          .sriracha input[type="tel"]::placeholder,
          .sriracha input[type="url"]::placeholder,
          .sriracha input[type="password"]::placeholder,
          .sriracha textarea::placeholder,
          .sriracha select::placeholder {
            color: ${styleGuide.styles.form_field_placeholder_color?.code || 'inherit'};
          }

          .sriracha label,
          .sriracha fieldset label,
          .sriracha .unstack-form fieldset label {
            font-family: ${styleGuide.form_label_font_family};
            font-weight: ${styleGuide.styles.form_label_font_weight};
            font-size: ${styleGuide.styles.form_label_font_size}rem;
            color: ${styleGuide.styles.form_label_font_color?.code || 'inherit'};
            text-transform: ${styleGuide.form_label_text_transform || 'none'};
            letter-spacing: ${styleGuide.form_label_letter_spacing || 'normal'};
          }

          .sriracha .unstack-form fieldset input[type="text"] {
            width: 100%;
          }

          .sriracha .unstack-form fieldset .radio-container .option-group:not(:first-of-type) {
            border-top: 1px solid ${styleGuide.styles.form_field_border_color?.code || 'inherit'};
          }

          .sriracha .address-selected,
          .sriracha .shipping-address,
          .sriracha .billing-address,
          .sriracha .account_info_from_login,
          .sriracha .box-content .checkout-radio,
          .sriracha .unstack-form fieldset .radio-container {
            color: ${styleGuide.styles.form_field_font_color?.code || 'inherit'};
            background-color:  ${styleGuide.styles.form_field_background_color?.code || 'inherit'};
            border-radius:  ${styleGuide.styles.form_field_border_radius}px;
            border: 1px solid  ${styleGuide.styles.form_field_border_color?.code || 'inherit'}
          }

          .sriracha .unstack-form fieldset label[class="checkbox"],
          .sriracha .unstack-form fieldset .radio-container label {
            font-family: ${styleGuide.form_field_font_family} !important;
            font-weight: ${styleGuide.styles.form_field_font_weight} !important;
            font-size: ${styleGuide.styles.form_field_font_size}rem !important;
            color: ${styleGuide.styles.form_field_font_color?.code || 'inherit'} !important;
            margin: 0;
          }

          .sriracha .unstack-form fieldset label[class="checkbox"] span {
            top: calc(((${styles.form_field_font_size}rem * 1.6) - calc(${
          styles.form_field_font_size
        }rem - 0.2rem)) / 2);
            height: calc(${styles.form_field_font_size}rem - 0.2rem);
            width: calc(${styles.form_field_font_size}rem - 0.2rem);
          }

          .sriracha .unstack-form fieldset .radio-container input {
            height: auto;
            margin-top: calc(((${styleGuide.styles.form_field_font_size}rem * 1.6) - ${radioCheckBoxButtonSize}) / 2);
            margin-left: 0;
            margin-bottom: 2px;
            width: ${radioCheckBoxButtonSize};
            height: ${radioCheckBoxButtonSize};
          }

          .sriracha fieldset small,
          .sriracha .unstack-form fieldset small {
            font-family: ${styleGuide.form_help_font_family};
            font-weight: ${styleGuide.styles.form_help_font_weight};
            font-size: ${styleGuide.styles.form_help_font_size}rem;
            color: ${styleGuide.styles.form_help_font_color?.code || 'inherit'};
          }

          .sriracha .unstack-form button,
          .sriracha .unstack-form input[type="submit"],
          .sriracha .unstack-form .button {
            padding: ${styleGuide.styles.form_field_padding_y}rem ${
          styleGuide.styles.form_field_padding_x * 2
        }rem !important;
            font-family: ${styleGuide.primary_button_font_family} !important;
            font-size: ${styleGuide.styles.form_field_font_size}rem !important;
          }

          @media only screen and (min-width: 768px) {
            .sriracha .unstack-form-container {
              display: inline-block;
              width: 100%;
              max-width: ${styleGuide.styles.form_width}px;
            }
          }

          .sriracha .unstack-form .row {
            margin: 0 ${-styleGuide.styles.form_spacing_margin_x / 2}px;
            padding: ${styleGuide.styles.form_spacing_margin_y / 2}px 0;
            align-items:flex-end;
            flex-flow: row nowrap;
          }

          .sriracha .unstack-form .row-padding {
            height: ${styleGuide.styles.form_spacing_margin_y}px;
          }

          .sriracha .unstack-form .row .col {
            padding: 0 ${styleGuide.styles.form_spacing_margin_x / 2}px;
          }
          .sriracha .unstack-form .row .col .placeholder {
            padding: ${styleGuide.styles.form_field_padding_y}rem ${styleGuide.styles.form_field_padding_x}rem;
              line-height: normal;
          }
          .sriracha .form-embed-card {
            padding: ${styleGuide.styles.form_spacing_margin_y / 2}px ${styleGuide.styles.form_spacing_margin_x}px;
          }
          .sriracha .light-bg .social-icons ul li a svg path,
          .sriracha .light-bg .social-icons ul li a svg g path {
              fill: ${styleGuide.footer_menu_color?.code || 'inherit'};
            }
          .sriracha .dark-bg .social-icons ul li a svg path,
          .sriracha .dark-bg .social-icons ul li a svg g path {
              fill: ${styleGuide.footer_menu_dark_bg_color?.code || 'inherit'};
            }

          .sriracha.mobile form.unstack-form label,
          .sriracha.mobile form.unstack-form input[type='email'],
          .sriracha.mobile form.unstack-form input[type='number'],
          .sriracha.mobile form.unstack-form input[type='search'],
          .sriracha.mobile form.unstack-form input[type='text'],
          .sriracha.mobile form.unstack-form input[type='tel'],
          .sriracha.mobile form.unstack-form input[type='url'],
          .sriracha.mobile form.unstack-form input[type='password'] {
            font-size: ${styleGuide.paragraph_font_size_mobile}rem;
          }
          .sriracha.mobile input[type="email"],
          .sriracha.mobile input[type="phone"],
          .sriracha.mobile input[type="number"],
          .sriracha.mobile input[type="search"],
          .sriracha.mobile input[type="text"],
          .sriracha.mobile input[type="tel"],
          .sriracha.mobile input[type="url"],
          .sriracha.mobile input[type="password"],
          .sriracha.mobile textarea,
          .sriracha.mobile select {
            height: auto; /* Needed to override admin styling */
            padding: ${styleGuide.styles.form_field_padding_y_mobile}rem ${
          styleGuide.styles.form_field_padding_x_mobile
        }rem;
            font-size: ${styleGuide.styles.form_field_font_size_mobile}rem;
            border-radius:  ${
              styleGuide.styles.form_field_border_radius_mobile || styleGuide.styles.form_field_border_radius
            }px;
          }
          .sriracha.mobile label,
          .sriracha.mobile fieldset label,
          .sriracha.mobile .unstack-form fieldset label {
            font-size: ${styleGuide.styles.form_label_font_size_mobile}rem;
            letter-spacing: ${
              styleGuide.form_label_letter_spacing_mobile || styleGuide.form_label_letter_spacing || 'normal'
            };
          }
          .sriracha.mobile .unstack-form fieldset label[class="checkbox"],
          .sriracha.mobile .unstack-form fieldset .radio-container label {
            font-size: ${styleGuide.styles.form_field_font_size_mobile}rem !important;
          }
          .sriracha.mobile .unstack-form fieldset label[class="checkbox"] span {
            top: calc(((${styles.form_field_font_size_mobile}rem * 1.6) - calc(${
          styles.form_field_font_size_mobile
        }rem - 0.2rem)) / 2);
            height: calc(${styles.form_field_font_size_mobile}rem - 0.2rem);
            width: calc(${styles.form_field_font_size_mobile}rem - 0.2rem);
          }
          .sriracha.mobile .unstack-form fieldset .radio-container input {
            margin-top: calc(((${
              styleGuide.styles.form_field_font_size_mobile
            }rem * 1.6) - ${radioCheckBoxButtonSize}) / 2);
          }
          .sriracha.mobile fieldset small,
          .sriracha.mobile .unstack-form fieldset small {
            font-size: ${styleGuide.styles.form_help_font_size_mobile}rem;
          }
          .sriracha.mobile .unstack-form button,
          .sriracha.mobile .unstack-form input[type="submit"],
          .sriracha.mobile .unstack-form .button {
            padding: ${styleGuide.styles.form_field_padding_y_mobile}rem ${
          styleGuide.styles.form_field_padding_x_mobile * 2
        }rem !important;
            font-size: ${styleGuide.styles.form_field_font_size_mobile}rem !important;
          }
          @media only screen and (min-width: 768px) {
            .sriracha.mobile .unstack-form-container {
              display: inline-block;
              width: 100%;
              max-width: ${styleGuide.styles.form_width_mobile || styleGuide.styles.form_width}px;
            }
          }
          .sriracha.mobile .unstack-form .row {
            margin: 0 ${-styleGuide.styles.form_spacing_margin_x_mobile / 2}px;
            padding: ${styleGuide.styles.form_spacing_margin_y_mobile / 2}px 0;
          }
          .sriracha.mobile .unstack-form .row-padding {
            height: ${styleGuide.styles.form_spacing_margin_y_mobile}px;
          }
          .sriracha.mobile .unstack-form .row .col {
            padding: 0 ${styleGuide.styles.form_spacing_margin_x_mobile / 2}px;
          }
          .sriracha.mobile .unstack-form .row .col .placeholder {
            padding: ${styleGuide.styles.form_field_padding_y_mobile}rem ${
          styleGuide.styles.form_field_padding_x_mobile
        }rem;
          }
          .sriracha.mobile .form-embed-card {
            padding: ${styleGuide.styles.form_spacing_margin_y_mobile / 2}px ${
          styleGuide.styles.form_spacing_margin_x_mobile
        }px;
          }
          
          @media only screen and (max-width: 767px) {
            .sriracha form.unstack-form label,
            .sriracha form.unstack-form input[type='email'],
            .sriracha form.unstack-form input[type='number'],
            .sriracha form.unstack-form input[type='search'],
            .sriracha form.unstack-form input[type='text'],
            .sriracha form.unstack-form input[type='tel'],
            .sriracha form.unstack-form input[type='url'],
            .sriracha form.unstack-form input[type='password'] {
              font-size: ${styleGuide.paragraph_font_size_mobile}rem;
            }
            .sriracha input[type="email"],
            .sriracha input[type="phone"],
            .sriracha input[type="number"],
            .sriracha input[type="search"],
            .sriracha input[type="text"],
            .sriracha input[type="tel"],
            .sriracha input[type="url"],
            .sriracha input[type="password"],
            .sriracha textarea,
            .sriracha select {
              height: auto; /* Needed to override admin styling */
              padding: ${styleGuide.styles.form_field_padding_y_mobile}rem ${styleGuide.styles.form_field_padding_x}rem;
              font-size: ${styleGuide.styles.form_field_font_size_mobile}rem;
              border-radius:  ${
                styleGuide.styles.form_field_border_radius_mobile || styleGuide.styles.form_field_border_radius
              }px;
            }
            .sriracha label,
            .sriracha fieldset label,
            .sriracha .unstack-form fieldset label {
              font-size: ${styleGuide.styles.form_label_font_size_mobile}rem;
              letter-spacing: ${
                styleGuide.form_label_letter_spacing_mobile || styleGuide.form_label_letter_spacing || 'normal'
              };
            }
            .sriracha .unstack-form fieldset label[class="checkbox"],
            .sriracha .unstack-form fieldset .radio-container label {
              font-size: ${styleGuide.styles.form_field_font_size_mobile}rem !important;
              margin: 0;
            }
            .sriracha .unstack-form fieldset label[class="checkbox"] span {
              top: calc(((${styles.form_field_font_size_mobile}rem * 1.6) - calc(${
          styles.form_field_font_size_mobile
        }rem - 0.2rem)) / 2);
              height: calc(${styles.form_field_font_size_mobile}rem - 0.2rem);
              width: calc(${styles.form_field_font_size_mobile}rem - 0.2rem);
            }
            .sriracha .unstack-form fieldset .radio-container input {
              margin-top: calc(((${
                styleGuide.styles.form_field_font_size_mobile
              }rem * 1.6) - ${radioCheckBoxButtonSize}) / 2);
            }
            .sriracha fieldset small,
            .sriracha .unstack-form fieldset small {
              font-size: ${styleGuide.styles.form_help_font_size_mobile}rem;
            }
            .sriracha .unstack-form button,
            .sriracha .unstack-form input[type="submit"],
            .sriracha .unstack-form .button {
              padding: ${styleGuide.styles.form_field_padding_y_mobile}rem ${
          styleGuide.styles.form_field_padding_x_mobile * 2
        }rem !important;
              font-size: ${styleGuide.styles.form_field_font_size_mobile}rem !important;
            }
            .sriracha .unstack-form-container {
              display: inline-block;
              width: 100%;
              max-width: ${styleGuide.styles.form_width_mobile || styleGuide.styles.form_width}px;
            }
            .sriracha .unstack-form .row {
              margin: 0 ${-styleGuide.styles.form_spacing_margin_x_mobile / 2}px;
              padding: ${styleGuide.styles.form_spacing_margin_y_mobile / 2}px 0;
              align-items: flex-end;
              flex-flow: row nowrap;
            }
            .sriracha .unstack-form .row-padding {
              height: ${styleGuide.styles.form_spacing_margin_y_mobile}px;
            }
            .sriracha .unstack-form .row .col {
              padding: 0 ${styleGuide.styles.form_spacing_margin_x_mobile / 2}px;
            }
            .sriracha .unstack-form .row .col .placeholder {
              padding: ${styleGuide.styles.form_field_padding_y_mobile}rem ${
          styleGuide.styles.form_field_padding_x_mobile
        }rem;
            }
            .sriracha .unstack-form .row .col {
              padding: ${styleGuide.styles.form_spacing_margin_y_mobile / 2}px 0px;
            }
            .sriracha .unstack-form .row {
              display: block;
              padding: 0px ${styleGuide.styles.form_spacing_margin_y_mobile / 2}px;
            }
            .sriracha .form-embed-card {
              padding: ${styleGuide.styles.form_spacing_margin_y_mobile / 2}px ${
          styleGuide.styles.form_spacing_margin_x_mobile
        }px;
            }
          }
        `}
        {/* Box Shadows */}
        {`
          .sriracha .shadow-none {
            box-shadow: ${styleGuide.box_shadow_none};
          }
          .sriracha .shadow-small {
            box-shadow: ${styleGuide.box_shadow_small};
          }
          .sriracha .shadow-medium {
            box-shadow: ${styleGuide.box_shadow_medium};
          }
          .sriracha .shadow-large {
            box-shadow: ${styleGuide.box_shadow_large};
          }
          .sriracha .shadow-x-large {
            box-shadow: ${styleGuide.box_shadow_x_large};
          }
        `}
        {`
          .sriracha header .container,
          .sriracha footer .container
          {
            max-width: ${styleGuide.layout_section_desktop_width} !important;
          }
        `}
      </style>
    );
  }
}

export default compose(withSite, connect(mapStateToProps, mapDispatchToProps))(StyleEditableOverrides);

// calc(2rem * 1.48)
