import * as actionTypes from '../actions/actionTypes';

// Reducer
// ----------------------------------------------------------------------------

const initState = {
  isOpen: false,
  formDisabled: false,
  errors: null,
  sectionId: null,
  name: '',
  weights: [50, 50],
  confidence: 95,
};

export default function uiContentTestSettingsReducer(state = initState, action) {
  switch (action.type) {
    case actionTypes.UI_NEW_CONTENT_TEST_SETTINGS_OPEN:
      return {
        ...initState,
        isOpen: true,
        sectionId: action.payload.sectionId,
      };

    case actionTypes.UI_NEW_CONTENT_TEST_SETTINGS_CLOSE:
      return {
        ...state,
        isOpen: false,
      };

    case actionTypes.UI_NEW_CONTENT_TEST_SETTINGS_UPDATE:
      return {
        ...state,
        ...action.payload,
      };

    case actionTypes.UI_NEW_CONTENT_TEST_SETTINGS_UPDATE_WEIGHT: {
      const { index, value } = action.payload;

      const newWeights = [];
      const otherIndex = (index + 1) % 2;

      if (value === '') {
        newWeights[index] = '';
        newWeights[otherIndex] = 100;
      } else {
        let intValue = parseInt(value, 10);
        if (isNaN(intValue) || intValue < 0 || intValue > 100) return state;

        newWeights[index] = intValue;
        newWeights[otherIndex] = 100 - intValue;
      }

      return {
        ...state,
        weights: newWeights,
      };
    }

    case actionTypes.UI_NEW_CONTENT_TEST_SETTINGS_UPDATE_CONFIDENCE: {
      let str = action.payload;

      // Only accept strings made up of digits and up to one '.'
      if (!/^\d*\.?\d?$/.test(str)) return state;

      // Return the string directly if it matches '', or a number with a leading
      // or trailing '.'
      if (/(^$)|(^\d+\.$)|(^\.\d+$)|(^\.$)/.test(str))
        return {
          ...state,
          confidence: str,
        };

      // Otherwise parse as a number
      let num = Number(str);

      // Reject if it's outside of 0-100 range
      if (isNaN(num) || num < 0 || num > 100) return state;

      // If the number is a float, truncate it to tenths
      if (Number.isFinite(num) && !Number.isInteger(num)) num = Math.trunc(num * 10) / 10;

      // Return the parsed number
      return {
        ...state,
        confidence: num,
      };
    }

    case actionTypes.UI_NEW_CONTENT_TEST_SETTINGS_REQUEST_START:
      return {
        ...state,
        formDisabled: true,
      };

    case actionTypes.UI_NEW_CONTENT_TEST_SETTINGS_RECEIVE_START_SUCCESS:
      return {
        ...state,
        isOpen: false,
      };

    case actionTypes.UI_NEW_CONTENT_TEST_SETTINGS_RECEIVE_START_ERROR:
      return {
        ...state,
        formDisabled: false,
        errors: action.payload.errors,
      };

    default:
      return state;
  }
}

// Selectors
// ----------------------------------------------------------------------------

export function selectLocalState(globalState) {
  return globalState.uiNewContentTestSettings;
}
