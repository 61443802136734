import React from 'react';
import Table from '../../../react-table/Table';
import { Link } from 'react-router-dom';
import useUrlSite from '../../../../hooks/useUrlSite';

import formatTime from '../../../../lib/formatters/formatTime';

import { useConversionSourceReportData } from '../report-hooks';

// Columns
const columns = [
  {
    Header: 'Source',
    accessor: 'segment',
    sortable: false,
    minWidth: 360,
  },
  {
    Header: 'Uniques',
    accessor: 'uniques',
    Cell: ({ value }) => (value !== null ? (value.toLocaleString ? value.toLocaleString() : value) : '--'),
  },
  {
    Header: 'Sessions',
    accessor: 'visits',
    Cell: ({ value }) => (value !== null ? (value.toLocaleString ? value.toLocaleString() : value) : '--'),
  },
  {
    Header: 'Page views',
    accessor: 'pageviews',
    Cell: ({ value }) => (value !== null ? (value.toLocaleString ? value.toLocaleString() : value) : '--'),
  },
  {
    Header: 'Avg. time on page',
    accessor: 'time_on_page',
    Cell: ({ value }) => (value !== null ? `${formatTime(new Date(value))}` : '--'),
  },
  {
    Header: 'Leads',
    accessor: 'conversions',
    Cell: ({ original, value }) => {
      if (value == null) return '--';

      const str = value.toLocaleString ? value.toLocaleString() : value;

      return (
        <>
          {str}
          {original.linkToContacts && (
            <Link to={original.linkToContacts} style={{ float: 'right' }}>
              View
            </Link>
          )}
        </>
      );
    },
  },
  {
    Header: 'Conversion rate',
    accessor: 'conversion_rate',
    Cell: ({ value }) => (value !== null ? `${(value * 100).toFixed(2)}%` : '--'),
  },
  {
    Header: 'Bounce rate',
    accessor: 'bounce_rate',
    Cell: ({ value }) => (value !== null ? `${(value * 100).toFixed(2)}%` : '--'),
  },
  {
    Header: 'Revenue ($)',
    accessor: 'total_revenue',
    Cell: ({ value }) => (value !== null ? `$${value.toFixed(2)}` : '--'),
  },
  {
    Header: 'Rev/click ($)',
    accessor: 'revenue_per_click',
    Cell: ({ value }) => (value !== null ? `$${value.toFixed(2)}` : '--'),
  },
];

export function ConversionSourceReport(props) {
  const { filterState, segment } = props;
  const [data, isLoading] = useConversionSourceReportData(filterState, segment);
  const urlSite = useUrlSite();

  // Add a link to an associated filtered contacts page if appropriate.
  if (data && segment && segment.match(/^utm_/)) {
    data.forEach((datum) => (datum.linkToContacts = `/${urlSite}/customers/?${segment}=${datum.segment}`));
  }

  return (
    <Table
      columns={columns}
      data={data}
      defaultSorted={[{ id: 'conversion_rate', desc: true }]}
      loading={isLoading}
      showViewAll={true}
    />
  );
}
