/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useState } from 'react';

// import { runSyncJobs } from 'services/spark-api';

import SideDrawer from 'components/base/SideDrawer';
import FormControl from 'components/base/FormHelpers/FormControl';

import styles from './Salesforce.module.scss';
import { useDispatch } from 'react-redux';
import { deleteIntegrationRequest, patch } from 'actions/integrationActions';

export default (props) => {
  const { isOpen, config, id } = props;
  // TODO: Move this syncing to saga
  const dispatch = useDispatch();

  const deleteIntegration = () => {
    dispatch(deleteIntegrationRequest(id));
    props.close();
  };

  return (
    <SideDrawer isOpen={isOpen} title="Salesforce settings" close={props.close}>
      <form>
        <fieldset>
          <input
            value="Disconnect"
            className="button button-secondary button-full"
            readOnly
            onClick={deleteIntegration}
          />
        </fieldset>
      </form>
    </SideDrawer>
  );
};
