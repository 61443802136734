import { createSelector } from 'reselect';
import * as types from '../actions/actionTypes';

// ----------------------------------------------------------------------------
// Reducer
// ----------------------------------------------------------------------------

const initState = {
  count: 0,
  notificationEnabled: JSON.parse(window?.sprkfe?.storage.getItem('notificationPermission') || null),
  searched: [],
};

export default function contactsReducer(state = initState, action) {
  switch (action.type) {
    case types.CONTACTS_COUNT_RECEIVE:
      return {
        ...state,
        count: action.payload,
      };

    case types.CONTACTS_NOTIFICATIONS_PERMISSION_UPDATE:
      return {
        ...state,
        notificationEnabled: action.payload,
      };
    case types.CONTACTS_SAVE_SEARCHED_RESULTS:
      return {
        ...state,
        searched: action.payload,
      };
    default:
      return state;
  }
}

// ----------------------------------------------------------------------------
//  Selectors
// ----------------------------------------------------------------------------

export const selectGlobalState = (state) => state.contacts;

export const selectContactsCount = createSelector([selectGlobalState], (contacts) => contacts.count);

export const selectNotificationPermission = createSelector(
  [selectGlobalState],
  (contacts) => contacts.notificationEnabled
);

export const selectSearchedResults = createSelector([selectGlobalState], (contacts) => contacts.searched);
