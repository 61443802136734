import React from 'react';
import classNames from 'classnames';

import styles from './ActionItem.module.scss';
import { ReactComponent as CheckIcon } from '../../assets/images/check-mark.svg';

interface ActionItemProps {
  checked: boolean;
  disabled: boolean;
  label: string;
  icon: React.ReactNode;
  onClick: () => void;
}

const ActionItem = (props: ActionItemProps) => {
  const { label, icon, checked, disabled, onClick } = props;

  return (
    <div
      role="action-item"
      aria-label={label}
      onClick={onClick}
      className={classNames(styles.container, { [styles.disabled]: disabled })}
    >
      <div className={styles.check}>{checked && <CheckIcon />}</div>
      {icon}
      <span>{label}</span>
    </div>
  );
};

export default ActionItem;
