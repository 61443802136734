import React, { useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import isEmpty from 'lodash/isEmpty';

import { useColorPallette } from '../../../../hooks/useColorPallette';
import rgbaToHex from 'lib/rgbaToHex';

import styles from './ColorSelector.module.scss';

export default (props) => {
  const [drawerIsOpen, setDrawerIsOpen] = useState(false);
  return (
    <div className={styles.colorPicker}>
      <div
        className={styles.colorSwatch}
        style={{
          background: props.value.code,
        }}
      />

      <input
        type="text"
        name={props.name}
        onChange={(e) => props.onChange({ code: e.target.value })}
        value={props.value.label || rgbaToHex(props.value.code)}
        placeholder={props.placeholder}
        onFocus={() => setDrawerIsOpen(true)}
        onBlur={() => setDrawerIsOpen(false)}
      />
      <Drawer isOpen={drawerIsOpen} onChange={(value) => props.handleColorSelection(value)} />
    </div>
  );
};

function Drawer(props) {
  const { isOpen, onChange } = props;

  const colors = useColorPallette();

  if (isEmpty(colors)) return null;

  return (
    <CSSTransition in={isOpen} timeout={141.59} classNames={styles} mountOnEnter unmountOnExit>
      <div className={styles.Drawer}>
        <ul>
          {colors.map(({ code: color, label }) => (
            <ColorChoice
              key={rgbaToHex(color)}
              color={rgbaToHex(color)}
              label={label}
              onClick={() => onChange(label ? { code: rgbaToHex(color), label } : { code: rgbaToHex(color) })}
            />
          ))}
        </ul>
      </div>
    </CSSTransition>
  );
}

function ColorChoice(props) {
  const { color, onClick, label } = props;

  return (
    <li className={styles.ColorChoice} onMouseDown={onClick}>
      <div className={styles.Swatch} onClick={onClick} style={{ backgroundColor: color }} />
      {label ? <span className={styles.inSwatchLabel}>{label}</span> : <span>{color}</span>}
    </li>
  );
}
