import { take, takeLeading, takeLatest, call, select, put, all } from 'redux-saga/effects';
import * as types from '../actions/actionTypes';
import * as api from '../services/spark-api';
import * as subscriptionActions from '../actions/subscriptionActions';
import * as subscriptionSelectors from '../reducers/subscriptionReducer';
import * as accountActions from '../actions/accountActions';
import * as accountSelectors from '../reducers/accountReducer';
import * as uiActions from '../actions/uiActions';
import requireSiteId from './utils/requireSiteId';
import { SUBSCRIPTION_PLANS } from 'settings';

function* requestIfNeeded(action) {
  const isFetching = yield select(subscriptionSelectors.selectIsFetching);
  const fetchedAt = yield select(subscriptionSelectors.selectFetchedAt);

  // Consider "fresh" if subscription was fetched within the last hour
  const isFresh = fetchedAt && fetchedAt > Date.now() - 1000 * 60 * 60;

  if (!isFresh && !isFetching) {
    yield put(subscriptionActions.request(action.payload.siteId));
  }
}

function* request(action) {
  const siteId = yield call(requireSiteId, action.payload.siteId);

  try {
    const response = yield call(api.getSubscriptions, siteId);
    if (response.ok) {
      const subscriptions = response.json;
      const activeSubscription = subscriptions.find((sub) => !sub.ended_on || Date.parse(sub.ended_on) > Date.now());
      yield put(subscriptionActions.receive(activeSubscription));
      yield put(subscriptionActions.receiveHistory(subscriptions));
    } else if (response.status === 404) {
      yield put(subscriptionActions.receive(false));
    } else if (response.unauthorized) {
      yield put(accountActions.logout());
    } else {
      console.error('Failed trying to fetch pages', response.json);
    }
  } catch (err) {
    yield put(uiActions.connectionError());
    console.error(err);
  }
}

function* create(action) {
  try {
    const { resolve, reject, ...data } = action.payload;

    const response = yield call(api.createSubscription, {
      ...data,
      referral: window.Rewardful ? window.Rewardful.referral : null,
    });

    if (response.ok) {
      yield put(subscriptionActions.request());
      yield take(types.SUBSCRIPTION_RECEIVE);
      yield put(subscriptionActions.receiveCreateSuccess());

      // Refresh account to get new permissions
      const account = yield select(accountSelectors.selectAccount);
      resolve();
      yield put(accountActions.request(account.id));
    } else if (response.status === 400) {
      const { message } = response.json;
      reject(message.substring(message.indexOf(': ') + 1));
      yield put(subscriptionActions.receiveCreateError(response.json));
    } else if (response.status >= 300 && response.status < 500) {
      yield put(subscriptionActions.receiveCreateError(response.json));
    } else if (response.unauthorized) {
      yield put(accountActions.logout());
    } else {
      console.error('Failed trying to fetch pages', response.json);
    }
  } catch (err) {
    yield put(uiActions.connectionError());
    console.error(err);
  }
}

function* fetchProductsIfNeeded() {
  const products = yield select(subscriptionSelectors.selectSubscriptionProducts);
  if (!products.length) yield put(subscriptionActions.requestSubscriptionProducts());
}

function* fetchProducts() {
  try {
    const response = yield call(api.getSubscriptionProducts);
    if (response.ok) {
      const products = response.json.results
        .filter((s) => SUBSCRIPTION_PLANS.includes(s.id))
        .map((product) => {
          const plans = {};
          product.plans.forEach((plan) => (plans[`${plan.period}_amount`] = parseFloat(plan.amount)));
          return {
            product_id: product.id,
            name: product.name,
            description: product.description,
            ...plans,
          };
        });

      products.sort((a, b) => a.month_amount - b.month_amount);

      yield put(subscriptionActions.receiveSubscriptionPlans(products));
    }
  } catch (e) {
    console.error(e);
  }
}

function* updateCard(action) {
  const { payload, resolve, reject } = action;
  try {
    const response = yield call(api.updateCreditCard, payload.subscription_id, payload.card_id, payload.token_id);
    if (response.ok) {
      yield put(subscriptionActions.request());
      resolve();
    } else {
      reject(response.json.message);
    }
  } catch (e) {
    console.error(e);
  }
}

function* subscriptionSaga() {
  yield all([
    takeLeading(types.SUBSCRIPTION_REQUEST_IF_NEEDED, requestIfNeeded),
    takeLatest(types.SUBSCRIPTION_REQUEST, request),
    takeLatest(types.SUBSCRIPTION_REQUEST_CREATE, create),
    takeLatest(types.SUBSCRIPTION_REQUEST_PRODUCTS_IF_NEEDED, fetchProductsIfNeeded),
    takeLatest(types.SUBSCRIPTION_REQUEST_PRODUCTS, fetchProducts),
    takeLatest(types.SUBSCRIPTION_UPDATE_CREDIT_CARD, updateCard),
  ]);
}

export default subscriptionSaga;
