import React from 'react';
import { MiniSignal } from 'mini-signals';
import isEqual from 'lodash/isEqual';
import get from 'lodash/get';

import { ReactComponent as SvgEditField } from '../../../../../assets/images/icon-edit-field.svg';
import { getSparkRenderedForm, getSparkRenderedSystemForm } from '../../../../../services/spark-api';

import styles from './Form.module.scss';

export const renderedFormReloadSignal = new MiniSignal();
export default class RenderedTemplate extends React.Component {
  state = {
    form: null,
    isLoading: false,
    containerWidth: '400px',
  };

  componentDidMount() {
    this.binding = renderedFormReloadSignal.add(this.fetchRenderedForm);
    this.fetchRenderedForm();
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.data, this.props.data) && !this.state.isLoading) this.fetchRenderedForm();
  }

  componentWillUnmount() {
    if (this.binding && this.binding.detach) this.binding.detach();
  }

  fetchRenderedForm = () => {
    this.setState({
      isLoading: true,
    });
    var queryFunc = this.props.systemFormId ? getSparkRenderedSystemForm : getSparkRenderedForm;
    var formId = this.props.data.systemFormId ? this.props.systemFormId : this.props.formId;
    queryFunc(formId, {
      background: this.props.data?.background,
      site_id: this.props.data?.site_id,
    })
      .then((res) => res.json)
      .then((data) => {
        this.setState({
          form: data.html,
          containerWidth: get(data.content, 'styles.maxWidth', '400px'),
          isLoading: false,
        });
      });
  };

  render() {
    return this.state.form ? (
      <>
        <div
          className="form-embed-placeholder"
          style={{
            height: 'auto',
            width: this.state.containerWidth,
            border: 0,
            padding: 0,
            display: 'inline-flex',
          }}
        >
          {this.props.isHovered && !this.props.systemFormId && (
            <div className={styles.editOverlay} onClick={this.props.onClick}>
              <SvgEditField />
              Edit form
            </div>
          )}
          <div
            style={{ ...this.props.formAlignmentStyles, width: '100%' }}
            dangerouslySetInnerHTML={{ __html: this.state.form }}
          />
        </div>
      </>
    ) : null;
  }
}
