import React, { ReactNode } from 'react';
import Spinner from './base/Spinner';
import { ReactComponent as BrandSvg } from '../assets/images/elastic-path-logo.svg';

import styles from './UnstackSplash.module.scss';

type Props = {
  isLoading: boolean;
  children?: ReactNode;
};

export default function UnstackSplash(props: Props) {
  const { isLoading, children } = props;

  return (
    <div className={styles.UnstackSplash}>
      <div className={styles.content}>
        <BrandSvg className={styles.brand} height="60px">
          Elastic Path
        </BrandSvg>
        {children}
        <Spinner className={styles.spinner} style={{ opacity: isLoading ? 1 : 0 }} />
      </div>
    </div>
  );
}
