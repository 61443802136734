import * as types from '../actions/actionTypes';
import { createSelector } from 'reselect';

// ----------------------------------------------------------------------------
// Reducer
// ----------------------------------------------------------------------------

export default function (state = {}, action) {
  switch (action.type) {
    case types.ACCOUNT_REQUEST:
      return {
        ...state,
        isFetching: true,
      };

    case types.ACCOUNT_RECEIVE:
      return {
        ...state,
        ...action.payload,
        isFetching: false,
      };

    case types.ACCOUNT_RECEIVE_ERRORS:
      return {
        ...state,
        isFetching: false,
        ...action.payload,
      };

    case types.ACCOUNT_CREATE:
      return {
        ...state,
        ...action.payload,
      };

    case types.ACCOUNT_UPDATE:
      return {
        ...state,
        ...action.payload,
      };

    case types.ACCOUNT_LOGIN_REQUEST:
      return {
        ...state,
        isFetching: true,
      };

    case types.ACCOUNT_LOGIN_VERIFY_CODE:
      return {
        ...state,
        isFetching: true,
      };

    case types.ACCOUNT_LOGIN_CHALLENGE:
      return {
        ...state,
        isFetching: false,
        isChallenged: true,
        challengeToken: action.payload.token,
      };

    case types.ACCOUNT_LOGIN_VERIFICATION_FAILED: {
      return {
        ...state,
        isFetching: false,
        ...action.payload,
      };
    }
    case types.ACCOUNT_LOGIN_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isLoggedIn: true,
        loginErrors: null,
        jwt: action.payload.token,
      };

    case types.ACCOUNT_LOGIN_FAILURE:
      return {
        isFetching: false,
        isLoggedIn: false,
        ...action.payload,
      };

    case types.ACCOUNT_LOGOUT:
      return {
        isLoggedIn: false,
        jwt: null,
      };

    case types.ACCOUNT_ON_ONBOARDING_UPDATE:
      return {
        ...state,
        meta: action.payload,
      };

    case types.ACCOUNT_ON_UPDATE_ONBOARDING:
      return {
        ...state,
        metadata: action.payload,
      };

    case types.ACCOUNT_MFA_ENABLE_REQUEST:
    case types.ACCOUNT_MFA_VERIFY_CODE:
      return {
        ...state,
        mfa: { ...state.mfa, isFetching: true },
      };

    case types.ACCOUNT_MFA_ENABLE_STEP_SUCCESS:
      return {
        ...state,
        mfa: {
          ...state.mfa,
          ...action.payload,
          isFetching: false,
          errors: false,
        },
      };

    case types.ACCOUNT_MFA_ENABLE_STEP_FAILED: {
      return {
        ...state,
        mfa: {
          ...state.mfa,
          ...action.payload,
          isFetching: false,
          errors: true,
        },
      };
    }

    case types.ACCOUNT_MFA_RESET_PENDING_STATE:
      return { ...state, mfa: { ...state.mfa, error: null } };

    case types.ACCOUNT_MFA_DISABLE_SUCCESS:
      return { ...state, mfa: {} };

    default:
      return state;
  }
}

// ----------------------------------------------------------------------------
// Selectors
// ----------------------------------------------------------------------------

export function selectAccount(globalState) {
  return globalState.account;
}

export function selectAccountUsers(globalState) {
  return globalState.account.users;
}

export function selectAccountEmail(globalState) {
  return globalState.account.users && globalState.account.users.length > 0
    ? globalState.account.users[0]?.user.email
    : null;
}

export function selectHasAccount(globalState) {
  const account = selectAccount(globalState);
  return !!account.id;
}

export function selectPartner(globalState) {
  const account = selectAccount(globalState);
  return account ? account.partner : null;
}

export function selectAccountIsFetching(globalState) {
  const account = selectAccount(globalState);
  return account.isFetching;
}

export function selectRole(globalState) {
  const account = selectAccount(globalState);
  if (account && account.users && account.users.length) {
    return account.users[0].role;
  }
}

export function selectIsLoggedIn(globalState) {
  return selectAccount(globalState).isLoggedIn;
}

export function selectIsChallenged(globalState) {
  return selectAccount(globalState).isChallenged;
}

export function selectChallengedToken(globalState) {
  return selectAccount(globalState).challengeToken;
}

export function selectVerificationErrors(globalState) {
  return selectAccount(globalState).verificationErrors;
}

export function selectJWT(globalState) {
  return selectAccount(globalState).jwt;
}

export function selectPolicies(globalState) {
  return selectAccount(globalState).policies;
}

export function selectAccountMeta(globalState) {
  return selectAccount(globalState).meta;
}

export function selectAccountMetadata(globalState) {
  return selectAccount(globalState).metadata || {};
}

export function selectAccountId(globalState) {
  return selectAccount(globalState).id;
}

export function selectAccountIsTrial(globalState) {
  return selectAccount(globalState).is_trial;
}

export const selectAccountMFAInfo = createSelector(selectAccount, (state) => {
  return state.mfa;
});
