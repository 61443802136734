import * as types from './actionTypes';

export function requestIfNeeded(accountId) {
  if (!accountId) throw new TypeError('accountActions.requestIfNeeded was called without accountId argument');

  return {
    type: types.ACCOUNT_REQUEST_IF_NEEDED,
    payload: { accountId },
  };
}

export function request(accountId) {
  if (!accountId) throw new TypeError('accountActions.request was called without accountId argument');

  return {
    type: types.ACCOUNT_REQUEST,
    payload: { accountId },
  };
}

export function receive(accountProps) {
  return {
    type: types.ACCOUNT_RECEIVE,
    payload: accountProps,
  };
}

export function receiveErrors(errors) {
  return {
    type: types.ACCOUNT_RECEIVE_ERRORS,
    payload: { errors },
  };
}

export function create(accountProps) {
  return {
    type: types.ACCOUNT_CREATE,
    payload: accountProps,
  };
}

export function update(accountProps) {
  return {
    type: types.ACCOUNT_UPDATE,
    payload: accountProps,
  };
}

export function updateOnboardingStep(payload) {
  return {
    type: types.ACCOUNT_UPDATE_ONBOARDING_STEP,
    payload,
  };
}
export function patchOnboarding(payload) {
  return {
    type: types.ACCOUNT_UPDATE_ONBOARDING,
    payload,
  };
}

export function removeOnboardingStep(payload) {
  return {
    type: types.ACCOUNT_REMOVE_ONBOARDING_STEP,
    payload,
  };
}

export function updateLocalAccountMeta(payload) {
  return {
    type: types.ACCOUNT_ON_ONBOARDING_UPDATE,
    payload,
  };
}

export function requestLogin(username, password) {
  return {
    type: types.ACCOUNT_LOGIN_REQUEST,
    payload: { username, password },
  };
}

export function loginSuccess(token, policies) {
  return {
    type: types.ACCOUNT_LOGIN_SUCCESS,
    payload: { token, policies },
  };
}

export function loginChallenge(token) {
  return {
    type: types.ACCOUNT_LOGIN_CHALLENGE,
    payload: { token },
  };
}

export function verifyLoginCode(code) {
  return {
    type: types.ACCOUNT_LOGIN_VERIFY_CODE,
    payload: { code },
  };
}

export function verificationFailed(errors) {
  return {
    type: types.ACCOUNT_LOGIN_VERIFICATION_FAILED,
    payload: {
      verificationErrors: errors,
    },
  };
}

export function loginFailure(errors) {
  return {
    type: types.ACCOUNT_LOGIN_FAILURE,
    payload: {
      loginErrors: errors,
    },
  };
}

export function logout(accountProps) {
  return {
    type: types.ACCOUNT_LOGOUT,
    payload: accountProps,
  };
}

export function forbid() {
  return {
    type: types.ACCOUNT_FORBID_ACTION,
  };
}

export function requestEnableMFA(payload) {
  return {
    type: types.ACCOUNT_MFA_ENABLE_REQUEST,
    payload,
  };
}

export function verifyMFACode(code) {
  return {
    type: types.ACCOUNT_MFA_VERIFY_CODE,
    payload: code,
  };
}

export function MFAStepSuccess(payload) {
  return {
    type: types.ACCOUNT_MFA_ENABLE_STEP_SUCCESS,
    payload,
  };
}

export function MFAStepFailed(payload) {
  return {
    type: types.ACCOUNT_MFA_ENABLE_STEP_FAILED,
    payload,
  };
}

export function getMFAIfNeeded() {
  return { type: types.ACCOUNT_MFA_GET_IF_NEEDED };
}

export function getMFA() {
  return { type: types.ACCOUNT_MFA_GET };
}

export function disableMFA(id) {
  return {
    type: types.ACCOUNT_MFA_DISABLE,
    payload: id,
  };
}

export function disableMFASuccess() {
  return { type: types.ACCOUNT_MFA_DISABLE_SUCCESS };
}

export function resetMFAState() {
  return {
    type: types.ACCOUNT_MFA_DISABLE_SUCCESS,
  };
}

export function resetMFAPendingState() {
  return {
    type: types.ACCOUNT_MFA_RESET_PENDING_STATE,
  };
}

export function createMultiSite(payload) {
  return {
    type: types.ACCOUNT_MULTI_SITE_CREATE_REQUEST,
    payload,
  };
}
