import React from 'react';
import styles from './FormContentEditor.module.scss';
import { CONTACT_FIELDS, GENERIC_FIELDS } from 'components/Editor/EmbeddedForm/Forms/SparkForm/constants';
import { CancelDrag, CreateField, Field, NewField, StrictFieldsList } from './types';
import { useDrag } from 'react-dnd';
import classNames from 'classnames';

type NewFieldsProps = {
  createField: CreateField;
  cancelDrag: CancelDrag;
  existingFieldsList: StrictFieldsList;
};

export function NewFields(props: NewFieldsProps) {
  const existingFieldIds = getFieldIds(props.existingFieldsList);

  return (
    <>
      <div className={styles.sideBarHeader}>Built-In fields</div>
      <div className={styles.newFields} data-test-id="built-in-fields">
        {CONTACT_FIELDS.map((field, i) => (
          <NewFieldTile
            key={field.default}
            value={field}
            createField={props.createField}
            cancelDrag={props.cancelDrag}
            disabled={existingFieldIds.includes(field.id)}
          />
        ))}
      </div>
      <div className={styles.sideBarHeader}>Custom fields</div>
      <div className={styles.newFields} data-test-id="custom-fields">
        {GENERIC_FIELDS.map((field, i) => (
          <NewFieldTile
            key={field.default}
            value={field}
            createField={props.createField}
            cancelDrag={props.cancelDrag}
          />
        ))}
      </div>
    </>
  );
}

type FieldIds = Field['id'][];

function getFieldIds(fieldsList: StrictFieldsList) {
  let ids: FieldIds = [];
  fieldsList.forEach((row) => row.forEach((field) => ids.push(field.id)));
  return ids;
}

type NewFieldTileProps = {
  value: NewField;
  createField: CreateField;
  cancelDrag: CancelDrag;
  disabled?: boolean;
};

function NewFieldTile({ value: field, createField, cancelDrag, disabled }: NewFieldTileProps) {
  const [, dragRef] = useDrag<any, any, any>(
    () => ({
      type: 'new-field',
      item: { id: field.default },
      canDrag: !disabled,
      end: (item, monitor) => {
        console.log('end drop', item, monitor.didDrop(), monitor.getDropResult());
        if (monitor.didDrop()) {
          console.log('will create');
          createField(field);
        } else {
          console.log('will cancel');
          cancelDrag();
        }
      },
    }),
    [field.default, cancelDrag, createField, disabled]
  );

  return (
    <div
      className={classNames(styles.newField, {
        [styles.newFieldDisabled]: disabled,
      })}
      ref={dragRef}
      data-test-id="new-field"
      data-field-disabled={disabled}
    >
      <div>{field.default}</div>
    </div>
  );
}
