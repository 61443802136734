import React from 'react';
import ReactTooltip from 'react-tooltip';
import throttle from 'lodash/throttle';

import styles from './Tooltip.module.scss';

// When mutations are observed, tell ReactTooltip to reset its bindings.
// The callback is throttled for performance reasons.
const rebuildReactTooltip = throttle(function (mutationsList, observer) {
  ReactTooltip.rebuild();
}, 500);

export default class extends React.PureComponent {
  componentDidMount() {
    // Start observing the document for mutations.
    this.observer = new MutationObserver(rebuildReactTooltip);
    const config = { attributes: true, childList: true, subtree: true };
    this.observer.observe(document, config);
  }

  componentWillUnmount() {
    // Disconnect the observer if the component is removed.
    this.observer.disconnect();
  }

  render() {
    return (
      <>
        <ReactTooltip
          className={styles.tooltip}
          place="top"
          type="dark"
          effect="solid"
          delayShow={300}
          globalEventOff="click"
          clickable={true}
        />
        <ReactTooltip
          id="tooltip-left"
          className={styles.tooltip}
          place="left"
          type="dark"
          effect="solid"
          delayShow={300}
          globalEventOff="click"
        />
      </>
    );
  }
}
