// Formats seconds (integer) into "hh:mm:ss" string

export default function formatTime(totalSeconds) {
  // Get hours, minutes, and seconds
  let hours = Math.floor(totalSeconds / 3600);
  let minutes = Math.floor((totalSeconds - hours * 3600) / 60);
  let seconds = totalSeconds - hours * 3600 - minutes * 60;

  // Pad each value
  if (hours < 10) hours = '0' + hours;
  if (minutes < 10) minutes = '0' + minutes;
  if (seconds < 10) seconds = '0' + seconds;

  return `${hours}:${minutes}:${seconds}`;
}
