import { useEffect, useState } from 'react';

import useActiveSite from 'hooks/useActiveSite';
import { getForms, getItems, getProducts, getTableColumns, getKlaviyoSegments } from 'services/spark-api';

export default (table: string) => {
  const site = useActiveSite();
  const [data, setData] = useState(null);
  useEffect(() => {
    async function fetch(): Promise<void> {
      if (table) {
        const res = await getTableColumns(site.id, table);
        if (res.ok) setData(res.json.results.filter((c: any) => c.data_type !== 'foreign_key'));
      }
    }

    fetch();
  }, []);

  return [data];
};

export const useSmartInputData = (type: string, search: string): any => {
  const site = useActiveSite();
  const [data, setData] = useState([]);
  useEffect(() => {
    async function fetch(): Promise<void> {
      if (type) {
        let res;
        res = await (type === 'form'
          ? //  @ts-ignore next-line
            getForms(site.id, { search })
          : type === 'subscription'
          ? //  @ts-ignore next-line
            getProducts(site.id, { search })
          : type === 'page'
          ? //  @ts-ignore next-line
            getItems({ site_id: site.id, search })
          : type === 'segment'
          ? //  @ts-ignore next-line
            getKlaviyoSegments(site.id)
          : null);
        if (res.ok) {
          setData(
            type === 'segment'
              ? res.json.map((row: any) => {
                  return { ...row, name: row.attributes.name };
                })
              : res.json.results
          );
        }
      }
    }

    fetch();
  }, [search, type]);
  return [data];
};
