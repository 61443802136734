import React from 'react';
import { Outlet, Route, Routes } from 'react-router-dom';
import Activate from './Activate';
import IntegrationFailed from './IntegrationFailed';
import IntentSurvey from './IntentSurvey';
import ShopifyAppUrl from './ShopifyAppUrl';
import ShopifyIntegrationOAuth from './ShopifyIntegrationOAuth';
import Subscribe from './Subscribe';

export default function ShopifyAppAdd() {
  return (
    <Routes>
      <Route path="/" element={<Outlet />}>
        <Route path="connect" element={<ShopifyIntegrationOAuth />} />
        <Route path="auth" element={<ShopifyAppUrl />} />
        <Route path="failure" element={<IntegrationFailed />} />
        <Route path="subscribe" element={<Subscribe />} />
        <Route path="activate" element={<Activate />} />
        <Route path="survey" element={<IntentSurvey />} />
      </Route>
    </Routes>
  );
}
