import React from 'react';
import classnames from 'classnames';

import Spinner from '../../Spinner';
import { Checkbox } from '../../../Checkbox';
import FormControl from '../../FormHelpers/FormControl';
import SmartUrlInput from 'components/Editor/SmartUrlToolbar/SmartUrlToolbar';
import ProgressBar from '../../ProgressBar';

import { ReactComponent as SvgIconNewTab } from '../../../../assets/images/icon-new-tab.svg';
import { ReactComponent as SvgIconAbout } from '../../../../assets/images/icon-help-about.svg';

import useKeyBinding from '../../../../hooks/useKeyBinding';
import styles from './MediaOptionsDrawer.module.scss';

const VIDEO_OPTIONS = {
  'Autoplay if allowed': 'autoplay',
  'Loop video': 'loop',
  Mute: 'muted',
  'Show controls': 'controls',
};

const OPTIMIZATION_OPTIONS = {
  'resize-compress-nextgen': 'Resize/nextgen-optimize',
  'resize-compress': 'Resize/optimize',
  'resize-only': 'Resize only ',
  'no-optimization': 'Do not resize or optimize',
};

export const MediaOptionsDrawer = (props) => {
  const { entityOptions = {} } = props;
  const [showTooltip, setShowTooltip] = React.useState(false);
  const linkUrl = entityOptions.link || {};
  const openNewTab = linkUrl.behavior === 'newTab';

  useKeyBinding('keydown', 13, (e) => {
    props.onSave();
  });

  function handleLinkUrlChange(value) {
    if (value) props.onLinkChange({ url: value, behavior: 'currentTab' });
    else props.onLinkChange(undefined);
  }

  return (
    <div
      className={classnames(styles.drawer, {
        [styles.addMargin]: props.addMargin,
      })}
      data-test-id="media-drawer-options"
    >
      {props.showMediaContextOptions && (
        <>
          <h4>Media options</h4>
          <label htmlFor="alt">Alt text (optional)</label>
          <FormControl
            id="alt"
            type="text"
            name="altText"
            autoComplete="off"
            onChange={props.updateEntityOptions}
            value={entityOptions.altText}
            // placeholder="Alt text"
            autoFocus
          />
        </>
      )}
      {props.isImageCategory && (
        <>
          <label>Image optimization settings</label>
          <FormControl
            type="text"
            tag="select"
            name="optimization"
            value={entityOptions.optimization}
            onChange={props.updateEntityOptions}
          >
            {Object.entries(OPTIMIZATION_OPTIONS).map((option) => (
              <option key={option[0]} value={option[0]}>
                {option[1]}
              </option>
            ))}
          </FormControl>
        </>
      )}
      {props.showMediaContextOptions && (
        <>
          <fieldset>
            <label className="checkbox">
              <FormControl
                type="checkbox"
                name="boxShadow"
                checked={entityOptions.boxShadow}
                onChange={props.updateEntityOptions}
              />
              <Checkbox checked={entityOptions.boxShadow} />
              <span style={{ color: '#fff', textTransform: 'capitalize' }}>Box shadow</span>
            </label>
          </fieldset>
          <label htmlFor="alt">Border radius (px)</label>
          <FormControl
            type="number"
            name="borderRadius"
            autoComplete="off"
            onChange={props.updateEntityOptions}
            value={parseInt(entityOptions.borderRadius)}
          />
        </>
      )}
      {props.enableLinking && (
        <>
          <label>
            Link to (optional){' '}
            <SvgIconNewTab
              data-tip={openNewTab ? 'Open in same window' : 'Open in new window'}
              className={classnames(styles.newTab, {
                [styles.active]: openNewTab,
              })}
              onClick={() => {
                props.onLinkChange({ ...linkUrl, behavior: openNewTab ? 'currentTab' : 'newTab' });
              }}
            />
          </label>
          <SmartUrlInput
            link={linkUrl}
            value={linkUrl.url || ''}
            onChange={(_name, value) => handleLinkUrlChange(value)}
            onUpdateUrlProps={(dict) => {
              props.onLinkChange(dict);
            }}
            placeholder="Type page name or URL"
            hideBehaviour
          />
        </>
      )}
      {props.isVideoCategory && !props.hideVideoOptions && (
        <>
          <h4>Video options</h4>
          {Object.entries(VIDEO_OPTIONS).map((entry) => (
            <fieldset key={entry[0]}>
              <label className="checkbox">
                <FormControl
                  type="checkbox"
                  name={entry[1]}
                  checked={entityOptions[entry[1]]}
                  onChange={props.updateEntityOptions}
                />
                <Checkbox checked={entityOptions[entry[1]]} />
                <span className={styles.videoOptions}>
                  {entry[0]}{' '}
                  {entry[1] === 'autoplay' && (
                    <>
                      <SvgIconAbout onMouseOver={() => setShowTooltip(true)} onMouseOut={() => setShowTooltip(false)} />
                      {showTooltip && (
                        <Tooltip message="Some browsers block autoplay videos that are not muted by default" />
                      )}
                    </>
                  )}
                </span>
              </label>
            </fieldset>
          ))}
        </>
      )}
      <input value="Save" readOnly className="button button-primary" onClick={props.onSave} />
      {props.isSectionContent && (
        <input value="Remove from content" readOnly className="button button-default" onClick={props.onRemove} />
      )}
      {props.showLoader && (
        <div className={styles.spinner}>
          <span>Dowloading in progress</span>
          <Spinner />
          {props.downloadProgress !== null && props.showDownloadProgress ? (
            <ProgressBar progress={props.downloadProgress} />
          ) : null}
        </div>
      )}
    </div>
  );
};

function Tooltip(props) {
  return <div className={styles.tooltip}>{props.message}</div>;
}
