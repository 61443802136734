import React from 'react';

import ErrorBoundary from 'components/ErrorBoundary';
import { SectionHandlersInterface, SectionUiHandlersInterface } from '../../../types';
import SectionError from './SectionError';
import { SectionTagArgs } from 'components/unstack-components/tag-types';
import withSite from 'containers/withSite';
import { USite } from 'types/USite';
import { USection, USectionDevices } from 'types/USection';

type CartItem = {
  title: string;
};

interface CartLineItemElementProps extends SectionTagArgs {
  defaults: any;
  value: USection;
  sectionHandlers?: SectionHandlersInterface;
  sectionUiHandlers?: SectionUiHandlersInterface;
  site: USite;
  item: (item: CartItem) => React.ReactChildren;
  onChange: (content: USectionDevices, key?: string, multi?: boolean) => void;
}

const CartLineItemsElement: React.FunctionComponent<CartLineItemElementProps> = (props) => {
  let { componentId, item } = props;
  const products: CartItem[] = [{ title: 'Product 1 ' }, { title: 'Product 2 ' }];

  const SectionErrorBoundary = (
    <SectionError
      sectionHandlers={props.sectionHandlers}
      sectionUiHandlers={props.sectionUiHandlers}
      componentId={componentId}
    />
  );

  return (
    <ErrorBoundary renderError={(_error: string) => SectionErrorBoundary}>
      <>{products.map((product) => item(product))}</>
    </ErrorBoundary>
  );
};

export default withSite(CartLineItemsElement);
