import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { connect } from 'react-redux';
import throttle from 'lodash/throttle';
import Autosuggest from 'react-autosuggest';

import Popper from 'components/base/Popper';
import { getTableRows } from 'services/spark-api';

import * as dataTablesActions from 'actions/dataTablesActions';
import * as dataTablesSelectors from 'reducers/dataTablesReducer';

import styles from '../Sheet/Sheet.module.scss';
import 'react-datetime/css/react-datetime.css';
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import styles2 from 'components/base/FormHelpers/SmartUrlInput.module.scss';

const mapStateToProps = (state) => ({
  relatedRows: dataTablesSelectors.selectRelatedRows(state),
});
const mapDispatchToProps = {
  addRelatedRow: dataTablesActions.addRelatedRow,
};

class RelatedTableInput extends React.Component {
  isUUID = (id) => id && id.match(/^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i);
  render() {
    const { relatedRows, value } = this.props;
    const isContent = this.isUUID(value);

    let content;
    if (isContent) {
      content = relatedRows[isContent[0]];
    }
    return (
      <div>
        {content?.slug && (
          <span
            style={{
              padding: '4px 5px',
              backgroundColor: '#1495cd',
              color: '#fff',
              fontSize: '12px',
              borderRadius: '2px',
            }}
          >
            {content?.slug}
          </span>
        )}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RelatedTableInput);
