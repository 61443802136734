import React, { useEffect, useState } from 'react';
import memoize from 'lodash/memoize';
import classNames from 'classnames';

import { useDispatch, useSelector } from 'react-redux';
import * as siteLayoutActions from '../actions/siteLayoutActions';
import * as accountUserActions from '../actions/accountUserActions';
import * as templatetagsActions from '../actions/templatetagsActions';
import * as integrationActions from '../actions/integrationActions';
import * as subscriptionActions from '../actions/subscriptionActions';
import * as blogActions from '../actions/blogActions';
import * as paymentAction from '../actions/paymentActions';
import * as componentActions from '../actions/componentActions';

import { selectHasPermission } from '../reducers/policyReducer';
import { selectSubscriptionHistory } from 'reducers/subscriptionReducer';
import { selectIntegrationBySlug } from 'reducers/integrationsReducer';
import * as notificationReducer from 'reducers/notificationReducer';
import * as siteSelectors from '../reducers/sitesReducer';

import { Route, Routes, useLocation, useNavigate, useParams } from 'react-router-dom';
import RequireLogin from './RequireLogin';

import NotificationBar from './NotificationBar';
import Nav from './AdminPanel/Nav';
import Insights from './AdminPanel/Insights';
import Customers from './AdminPanel/Customers';
import Pages from './AdminPanel/Pages';
import LandingPages from './AdminPanel/LandingPages';
import Settings from './AdminPanel/Settings';
import Integrations from './AdminPanel/Integrations';
import Elements from './AdminPanel/Elements';
import Styles from './AdminPanel/Styles';
import Blogs from './AdminPanel/Blogs';
import List from './unstack-components/editor/List';
import DataTables from './AdminPanel/DataTables';
import DynamicPages from './AdminPanel/DynamicPages';
import Research from './AdminPanel/Research';
import URLsSettings from './AdminPanel/URLsSettings.js/index.js';
import MediaLibrary from './AdminPanel/MediaLibrary.js';
import Quickstart from './AdminPanel/Quickstart';
import Forms from './AdminPanel/Forms';
import Products from './Storefront/Products';
import Collections from './Storefront/Collections';
import Templates from './Storefront/Templates';

import styles from './AdminPanel.module.scss';
import { compose } from 'redux';
import withIntegrations from 'containers/withIntegrations';
import { OnMount } from 'hooks/mountUnmountHooks';
import { clearConnectionError } from '../actions/uiActions';
import Tasks from './AdminPanel/Tasks/Tasks';
import { getShouldRedirectToQuickstart } from './AdminPanel/Quickstart/helpers';

const PADDING_REMOVED_PANELS = ['forms/'];

type Props = {
  integrationsBySlug: any;
};

function Admin(props: Props) {
  const location = useLocation();
  const path = location.pathname;
  const { urlSite } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const hasPermission = useSelector(selectHasPermission);
  const notification = useSelector((state) => notificationReducer.selectNotificationBarIsOut(state, urlSite));
  const [myDocument, setMyDocument] = useState<Document>();
  const [initialDataLoaded, setInitialDataLoaded] = useState(false);
  const subscriptionHistory = useSelector(selectSubscriptionHistory);
  // @ts-ignore
  const site = useSelector((state) => siteSelectors.selectSiteByDomain(state, urlSite));
  // @ts-ignore
  const isShopifyConnected = useSelector((state) => selectIntegrationBySlug(state, site.id, 'shopify'));

  const redirectToQuickstart = getShouldRedirectToQuickstart();

  const fetchInitialDataIfNeeded = () => {
    if (!initialDataLoaded && hasPermission('Account')) {
      dispatch(templatetagsActions.requestAllIfNeeded());
      hasPermission('AccountUser:list') && dispatch(accountUserActions.requestAllIfNeeded());
      hasPermission('SiteIntegration:list') && dispatch(integrationActions.requestAllIfNeeded());
      hasPermission('SiteIntegration:list') && dispatch(integrationActions.requestIntegrationTypesIfNeeded());
      hasPermission('AccountSubscription:list') && dispatch(subscriptionActions.requestIfNeeded());
      hasPermission('SubscriptionProduct:*') && dispatch(paymentAction.requestProductsIfNeeded());
      hasPermission('PaymentPeriod:*') && dispatch(paymentAction.requestPaymentPeriodIfNeeded());
      hasPermission('SubscriptionPlan:*') && dispatch(subscriptionActions.requestSubscriptionProductsIfNeeded());
      hasPermission('Blog:list') && dispatch(blogActions.requestListIfNeeded());
      dispatch(componentActions.requestComponentDefaultsIfNeeded());
      hasPermission('SiteLayout:list') && dispatch(siteLayoutActions.fetchSiteLayoutIfNeeded());
      setInitialDataLoaded(true);
      dispatch({
        type: 'READ_RESOURCES_REQUEST_IF_NEEDED',
        resourceType: 'sectionTypes',
        requestKey: 'fetch',
      });
      dispatch(siteLayoutActions.fetchHeaderSections());
      dispatch(siteLayoutActions.fetchFooterSections());
    }
  };

  OnMount(() => {
    if (path === `/${urlSite}` || path === `/${urlSite}/`) {
      const url = redirectToQuickstart ? 'quickstart' : 'pages';
      navigate(`/${urlSite}/${url}`, { replace: true });
    }
    const iframe = document.getElementById('editorIframe') as HTMLIFrameElement;
    setMyDocument(iframe ? iframe.contentWindow.document : document);
    dispatch(clearConnectionError());
    fetchInitialDataIfNeeded();
    if (location?.search === '?order_by=-modified_at&page=1&status=&tab=test-results')
      navigate(`insights/test-results`);
  });

  useEffect(() => {
    if (subscriptionHistory && isShopifyConnected) {
      let hasSub = subscriptionHistory.length > 0;
      let subEnded = hasSub && subscriptionHistory[0].ended_on;
      let now = new Date();
      if (!hasSub || (subEnded && new Date(subEnded) < now)) {
        navigate(`/${urlSite}/trial-end`, { replace: true });
      }
    }
  }, [subscriptionHistory, isShopifyConnected, navigate, urlSite]);

  useEffect(() => {
    if (props.integrationsBySlug?.algolia && myDocument) {
      const [algolia] = props.integrationsBySlug?.algolia;
      loadAlgoliaScript(algolia.config);
    }
  }, [props.integrationsBySlug, myDocument]);

  const isPaddingNotRequired = memoize(
    (pathName) => {
      return PADDING_REMOVED_PANELS.some((path) => pathName.includes(path));
    },
    (pathName) => pathName
  );

  const loadAlgoliaScript = (config: any) => {
    if (!myDocument.getElementById('editor-algolia-script')) {
      const algolia = myDocument.createElement('script');
      algolia.id = 'editor-algolia-script';
      algolia.dataset.appId = config.application_id;
      algolia.dataset.apiKey = config.search_only_api_key;
      algolia.dataset.index = config.index;
      algolia.src = 'https://cdn.jsdelivr.net/npm/algoliasearch@4.17.0/dist/algoliasearch-lite.umd.js';
      algolia.charset = 'utf-8';
      algolia.type = 'text/javascript';

      myDocument.body.appendChild(algolia);

      const instantSearch = myDocument.createElement('script');
      instantSearch.id = `${config.application_id}-instant-search`;
      instantSearch.src = 'https://cdn.jsdelivr.net/npm/instantsearch.js@4.54.1/dist/instantsearch.production.min.js';
      instantSearch.charset = 'utf-8';
      instantSearch.type = 'text/javascript';

      myDocument.body.appendChild(instantSearch);
    }
  };

  const removeBottomPadding = isPaddingNotRequired(path);
  return (
    <RequireLogin>
      <NotificationBar />
      <div className="admin">
        <main
          className={classNames(styles.AdminPanel, {
            [styles.notificationBarIsIn]: notification,
          })}
        >
          <Nav />
          <section
            className={classNames(styles.panelSection, {
              [styles.removeBottomPadding]: removeBottomPadding,
            })}
            data-test-id="panel-section-content"
          >
            <Routes>
              <Route path="/quickstart" element={<Quickstart />} />
              <Route path="/insights/*" element={<Insights />} />
              <Route path="/research" element={<Research />} />
              <Route path="/products" element={<Products />} />
              <Route path="/collections" element={<Collections />} />
              <Route path="/product-templates" element={<Templates />} />
              <Route path="/customers/*" element={<Customers />} />
              <Route path="/pages" element={<Pages />} />
              <Route path="/landing-pages" element={<LandingPages />} />
              <Route path="/dynamic-pages" element={<DynamicPages />} />
              <Route path="/blogs/:blogId?" element={<Blogs />} />
              <Route path="/blogs/:blogId/categories/:categoryId?" element={<Blogs />} />
              <Route path="/forms/*" element={<Forms />} />
              <Route path="/media" element={<MediaLibrary />} />
              <Route path="/data-tables" element={<DataTables />} />
              <Route path="/urls" element={<URLsSettings />} />
              <Route path="/elements" element={<Elements />} />
              <Route path="/styles" element={<Styles />} />
              <Route path="/components" element={<List />} />
              <Route path="/sections" element={<List />} />
              <Route path="/settings" element={<Settings />} />
              <Route path="/integrations" element={<Integrations />} />
              <Route path="/tasks" element={<Tasks />} />
              <Route
                path="/"
                element={
                  redirectToQuickstart && (path === `/${urlSite}` || path === `/${urlSite}/`) ? (
                    <Quickstart />
                  ) : (
                    <Pages />
                  )
                }
              />
            </Routes>
          </section>
        </main>
      </div>
    </RequireLogin>
  );
}

export default compose(withIntegrations)(Admin);
