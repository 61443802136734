import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import SideDrawer from '../../base/SideDrawer';
import DatePicker from '../../../lib/react-datepicker/';
import FormControl from '../../base/FormHelpers/FormControl';
import ValidationMessage from '../../base/FormHelpers/ValidationMessage';

import { createTask, updateTask, deleteTask } from '../../../actions/tasksActions';
import SmartContactSearch from '../Contacts/SmartContactSearch';
import { selectAllSorted } from '../../../reducers/accountUsersReducer';
import { selectSubmissionState } from '../../../reducers/uiSubmitButtonsStateReducer';

import useKeyBinding from '../../../hooks/useKeyBinding';
import { contactNameOrEmail } from '../Contacts/ContactDetail/ContactDetail';

import styles from './Tasks.module.scss';

const TYPES = [
  { value: 'call', label: 'Call' },
  { value: 'email', label: 'Email' },
  { value: 'todo', label: 'Todo' },
];

const validateFields = (data) => Boolean(data.title && data.due_on && data.contact && data.contact.id && data.type);

export default ({
  isOpen,
  setIsOpen,
  selectedTask = {},
  setSelectedTask = (val) => {},
  contact = undefined,
  refetchList,
  owner,
}) => {
  const isSubmitting = useSelector((state) => selectSubmissionState(state));
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [taskData, setTaskData] = useState({
    type: TYPES[1].value,
    owner,
    status: 'pending',
    ...(selectedTask.id ? { ...selectedTask } : { ...(contact && { contact }) }),
  });

  const accountUsers = useSelector((state) => selectAllSorted(state));

  const dispatch = useDispatch();

  const handleSubmit = () => {
    if (validateFields(taskData)) {
      const promise = new Promise((res, rej) => {
        dispatch(
          selectedTask.id
            ? updateTask(
                {
                  ...taskData,
                  contact: taskData.contact.id,
                },
                res,
                rej
              )
            : createTask(
                {
                  ...taskData,
                  contact: taskData.contact.id,
                },
                res,
                rej
              )
        );
      });
      promise
        .then(() => {
          setIsOpen(false);
          refetchList();
        })
        .catch((e) => console.log(e));
    } else setTaskData({ ...taskData, error: true });
  };

  useKeyBinding('keydown', 13, () => {
    handleSubmit();
  });

  return (
    <SideDrawer
      title={selectedTask.id ? 'Update task' : 'Add a task'}
      isOpen={isOpen}
      close={() => {
        setSelectedTask({});
        setIsOpen(false);
      }}
    >
      <fieldset className={styles.fieldsetOptionalStyles}>
        <label htmlFor="title">Title</label>
        <FormControl
          name="title"
          id="title"
          type="text"
          autoComplete="off"
          value={taskData.title || ''}
          onChange={(e) => setTaskData({ ...taskData, [e.target.name]: e.target.value })}
          placeholder="Title"
        />
        {!!(taskData.error && !taskData.title) && <ValidationMessage message="This field is required" />}
      </fieldset>
      <fieldset className={styles.fieldsetOptionalStyles}>
        <label htmlFor="details">Details</label>
        <FormControl
          name="details"
          id="details"
          tag="textarea"
          autoComplete="off"
          value={taskData.details || ''}
          onChange={(e) => setTaskData({ ...taskData, [e.target.name]: e.target.value })}
          placeholder="Details"
        />
      </fieldset>
      <fieldset>
        <label htmlFor="due_date">Due date</label>
        <FormControl
          tag={DatePicker}
          id="due_date"
          name="due_on"
          value={taskData.due_on}
          onChange={(name, value) => setTaskData({ ...taskData, [name]: value })}
          asIsoDateStr
          dateFormat="MM-dd-yyyy"
          autoComplete="off"
        />
        {!!(taskData.error && !taskData.due_on) && <ValidationMessage message="This field is required" />}
      </fieldset>
      <fieldset className={styles.fieldsetOptionalStyles}>
        <label htmlFor="type">Type</label>
        <FormControl
          name="type"
          id="type"
          tag="select"
          autoComplete="off"
          value={taskData.type || ''}
          onChange={(e) => setTaskData({ ...taskData, [e.target.name]: e.target.value })}
        >
          <option disabled value="">
            Select type
          </option>
          {TYPES.map((type) => (
            <option key={type.value} value={type.value}>
              {type.label}
            </option>
          ))}
        </FormControl>
        {!!(taskData.error && !taskData.type) && <ValidationMessage message="This field is required" />}
      </fieldset>
      <fieldset className={styles.fieldsetOptionalStyles}>
        <label>Customer</label>
        <SmartContactSearch
          onChange={(value) => setTaskData({ ...taskData, contact: value })}
          value={taskData.contact && contactNameOrEmail(taskData.contact)}
          isDisabled={contact && contact.id}
        />
        {!!(taskData.error && !taskData.contact) && <ValidationMessage message="This field is required" />}
      </fieldset>
      <fieldset className={styles.fieldsetOptionalStyles}>
        <label>Assign to</label>
        <FormControl
          name="owner"
          onChange={(e) => setTaskData({ ...taskData, [e.target.name]: e.target.value })}
          value={taskData.owner || ''}
          autoComplete="off"
          tag="select"
          placeholder="Owner"
        >
          <option value="">Assign to</option>
          {accountUsers.map((user) => (
            <option key={user.id} value={user.id}>
              {user.name || user.email}
            </option>
          ))}
        </FormControl>
        {!!(taskData.error && !taskData.owner) && <ValidationMessage message="This field is required" />}
      </fieldset>
      <fieldset className={styles.fieldsetOptionalStyles}>
        <label htmlFor="status">Status</label>
        <FormControl
          name="status"
          id="status"
          tag="select"
          autoComplete="off"
          value={taskData.status || ''}
          onChange={(e) => setTaskData({ ...taskData, [e.target.name]: e.target.value })}
        >
          <option value="">Select status</option>
          <option value="pending">Pending</option>
          <option value="done">Done</option>
        </FormControl>
      </fieldset>
      <fieldset className={styles.fieldsetOptionalStyles}>
        <input
          readOnly
          value={selectedTask.id ? (isSubmitting ? 'Updating...' : 'Update') : isSubmitting ? 'Saving' : 'Save'}
          className="button button-primary button-full"
          onClick={() => handleSubmit()}
        />
      </fieldset>
      {selectedTask.id && (
        <p className={styles.colorDeleteActionText}>
          You can also <span onClick={() => setConfirmDelete(true)}>delete this task</span>.
          {confirmDelete && (
            <p className={styles.colorDeleteActionText}>
              Are you sure? Deleting a task cannot be undone. &nbsp;&nbsp;
              {/* eslint-disable-next-line  jsx-a11y/anchor-is-valid */}
              <a
                onClick={() => {
                  dispatch(deleteTask(taskData.id));
                  setIsOpen(false);
                }}
              >
                Yes
              </a>
            </p>
          )}
        </p>
      )}
    </SideDrawer>
  );
};
