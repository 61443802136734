/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DocumentTitle from 'react-document-title';
import { StatusIndicator } from './PageList';
import PageSettings from '../Pages/PageSettings';
import * as pageSelectors from '../../reducers/pagesReducer';
import { createActiveByPageIdSelector } from '../../reducers/contentTestsReducer';
import * as uiPageSettingsActions from '../../actions/uiPageSettingsActions';
import iconZeroState from '../../assets/images/icon-zero-state-lp.svg';

import Spinner from '../base/Spinner';

import ZeroState, { Image, Header, Muted } from './ZeroState';
import ListWithServerSorting from '../react-table/ListWithServerSorting';
import { ReactComponent as NextSvg } from '../../assets/images/icon-next-page.svg';
import { ReactComponent as PrevSvg } from '../../assets/images/icon-prev-page.svg';

import styles from './Pages.module.scss';
import relativeDate from '../../lib/relative-date';
import { ReactComponent as SvgIconOptions } from '../../assets/images/icon-options.svg';
import { ReactComponent as SvgIconTestActive } from '../../assets/images/icon-test-active.svg';
import { PagesFilter } from './PagesFilter';
import PagesStatusFilter from './PagesStatusFilter';
import withQueryParams from 'containers/withQueryParams';
import debounce from 'lodash/debounce';
import { Link, useNavigate, useParams } from 'react-router-dom';

const filters = [
  { value: '', label: 'all pages' },
  { value: 'published', label: 'Published pages' },
  { value: 'draft', label: 'Draft pages' },
  { value: 'live_ab', label: 'Live pages with A/B Tests' },
];

type Props = {
  siteName: string;
  openPageSettings: (params: any) => void;
  queryParams?: { search: any; status: string; order_by: number; page: number };
  updateQueryParams: (params: any) => void;
};

function DynamicPages(props: Props) {
  const isLoading = useSelector(pageSelectors.selectIsFetching);
  const pages = useSelector(pageSelectors.selectAll);
  const pagesCount = useSelector(pageSelectors.selectPagesCount);
  const selectActiveByPageId = useSelector(createActiveByPageIdSelector);
  const { urlSite } = useParams();
  const navigate = useNavigate();
  const [pagesQuery, setPagesQuery] = useState<string>();
  const dispatch = useDispatch();

  const {
    siteName,
    queryParams: { search, status, order_by, page = 1 },
    updateQueryParams,
  } = props;

  const goToEditor = (pageId: string) => {
    navigate(`/${urlSite}/editor/${pageId}`);
    window.scrollTo({ top: 0 });
  };

  const debounceSearch = debounce((search) => {
    updateQueryParams({ search });
  }, 300);

  const columns = [
    {
      Header: 'Name',
      id: 'name',
      Cell: ({ original: value }: any) => {
        const { name, slug, status } = value;

        const showAbleSlug = slug === '' && status === 'published' && 'Home page';

        return (
          <div className={styles.flexContainer}>
            {name || 'New page'}
            {showAbleSlug && <span className={styles.label}>{showAbleSlug}</span>}
          </div>
        );
      },
      sortable: true,
      width: 300,
    },
    {
      Header: 'Status',
      accessor: 'status',
      Cell: ({ value }: any) => {
        // @ts-ignore
        return <StatusIndicator status={value} />;
      },
    },
    {
      Header: 'Source',
      accessor: 'data_table_name',
      Cell: ({ value }: any) => {
        return <span>{value}</span>;
      },
    },
    {
      Header: 'Total pages',
      accessor: 'data_table_total_rows',
      Cell: ({ value }: any) => {
        return <span>{value}</span>;
      },
    },
    {
      Header: 'Views',
      accessor: 'views',
      Cell: ({ value }: any) => {
        return (
          <div className={styles.flexContainer}>
            {(value || 0).toLocaleString()}{' '}
            <small style={{ fontSize: '1em', color: '#647fb4' }}>&nbsp;total views</small>
          </div>
        );
      },
      sortable: true,
    },
    {
      Header: 'Updated At',
      accessor: 'modified_at',
      Cell: ({ value }: any) => (
        <div className={styles.flexContainer}>
          <small style={{ fontSize: '1em', color: '#647fb4' }}>Updated&nbsp;</small>
          {relativeDate(value)}
        </div>
      ),
      sortable: true,
    },
    {
      Header: '',
      accessor: 'id',
      Cell: ({ value }: any) => {
        const hasActiveTests = selectActiveByPageId(value).length > 0;

        return (
          <div className={styles.icons}>
            {hasActiveTests && <SvgIconTestActive data-tip="A/B tests are running" />}
            <button
              data-tip="Options"
              aria-label="edit-page-settings"
              className={styles.options}
              onClick={(e) => {
                e.stopPropagation();
                dispatch(uiPageSettingsActions.open(value));
              }}
            >
              <SvgIconOptions />
            </button>
          </div>
        );
      },
      width: 40,
    },
  ];

  return (
    <>
      <DocumentTitle title={`${siteName}'s dynamic pages`} />
      <section>
        <header className={styles.pagesHeader}>
          <div className={styles.mainHeading}>
            <h1>Dynamic pages</h1>
          </div>
          {/* @ts-ignore */}
          <PagesFilter
            query={pagesQuery === undefined ? search : pagesQuery}
            placeholderText={'Search pages by name'}
            onChangeSearchQuery={(query: string) => setPagesQuery(query)}
            onChangeDebounce={debounceSearch}
            routeToNewPage={`/${urlSite}/dynamic-pages/new`}
          />
        </header>

        <PagesStatusFilter
          filter={filters.find((f) => f.value === status)}
          setFilter={({ value: status }: any) => updateQueryParams({ status })}
          filters={filters}
        />
      </section>
      {isLoading ? (
        <Spinner className="fixed" size="1" />
      ) : pages.length ? (
        <ListWithServerSorting
          className="-highlight"
          data={pages}
          columns={columns}
          count={pagesCount}
          defaultPageSize={10}
          getTdProps={(state: any, row: any) => ({
            onClick: (e: any) => {
              row.original.is_deleted
                ? dispatch(uiPageSettingsActions.open(row.original.id))
                : goToEditor(row.original.id);
            },
            style: { cursor: 'pointer' },
          })}
          loading={false}
          showViewAll={false}
          breakPageslabel={<span>...</span>}
          nextPageLabel={<NextSvg />}
          onPageChange={(page: number) => updateQueryParams({ page })}
          pagesCount={pagesCount / 10}
          paginate
          showHeader
          activePage={page}
          previousPageLabel={<PrevSvg />}
          getTheadProps={() => ({
            style: {
              zIndex: '0',
            },
          })}
          orderBy={order_by}
          onSortChange={(newSort: any) => {
            updateQueryParams({
              order_by: `${newSort.desc ? '-' : ''}${newSort.id}`,
            });
          }}
        />
      ) : (
        <ZeroState>
          <Image src={iconZeroState} />
          <Header>Create a dynamic page for {siteName}</Header>
          <Link to={`/${urlSite}/dynamic-pages/new`} className="button button-primary">
            Create a new page
          </Link>
        </ZeroState>
      )}
      <PageSettings />
    </>
  );
}

export default withQueryParams(DynamicPages, {
  itemType: 'data_page',
  panel: 'items',
});
