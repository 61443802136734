import React, { useEffect } from 'react';

export default function ParallaxUpdater(props) {
  const { value, el } = props;

  const backgroundEl = el && el.querySelector('.section-background-media');

  useEffect(() => {
    if (value != null && el && backgroundEl) {
      const parallax = initParallax(backgroundEl, value);
      return parallax.destroy;
    }
  }, [value, el, backgroundEl]);

  return null;
}

function initParallax(element, speed) {
  // Get container element
  var container = element.closest('section.section');

  // Parse speed. The speed can optionally be declared as an argument (used
  // in the react app).
  if (speed == null) speed = parseFloat(element.dataset.parallaxSpeed);

  // If speed is 0, use the `background-attachment` CSS property instead of
  // animating it with scroll events.
  if (speed === 0 && element.classList.contains('section-background-image')) {
    element.style.backgroundAttachment = 'fixed';

    return {
      destroy: function destroyParallax() {
        element.style.backgroundAttachment = '';
      },
    };
  }

  // Tell the browser that we're planning to animate the element's transform
  // property so that it can optimize accordingly. It's not normally considered
  // "good practice" to leave this property set indefinitely, but there's no
  // way to anticipate when the user will scroll the page.
  element.style.willChange = 'transform';

  // Boolean to debounce updates with requestAnimationFrame
  var willUpdate = false;

  // Attach event listeners
  window.addEventListener('scroll', requestUpdatePosition, false);
  window.addEventListener('resize', requestUpdatePosition, false);

  // Run once to set the element's initial positioning
  requestUpdatePosition();

  // Debounce the updatePosition function using requestAnimationFrame
  function requestUpdatePosition() {
    if (!willUpdate) {
      window.requestAnimationFrame(updatePosition);
      willUpdate = true;
    }
  }

  // Update the element's translation
  function updatePosition() {
    // Get existing dims
    var containerRect = container?.getBoundingClientRect();
    var hc = containerRect.height;
    var hv = window.innerHeight;
    var rtop = containerRect.top;

    // Don't bother updating if the container is not in view
    if (rtop < hv && rtop > -hc) {
      // Invariant calcs
      var xc = hv - rtop;
      var xb = speed * xc;

      // Variant calcs
      var dc, db, hb, transY;

      if (speed >= 1) {
        dc = hv + hc;
        db = speed * dc;
        hb = hc + db - hc - hv;
        transY = xc - xb;
      } else {
        dc = hv - hc;
        db = speed * dc;
        hb = hv - db;
        transY = xc - xb - (1 - speed) * hv;
      }

      // Update the dom
      element.style.height = hb + 'px';
      element.style.transform = 'translateY(' + transY + 'px)';
    }

    // Cleanup
    willUpdate = false;
  }

  return {
    destroy: function destroyParallax() {
      element.style.transform = '';
      element.style.willChange = '';
      window.removeEventListener('scroll', requestUpdatePosition, false);
      window.removeEventListener('resize', requestUpdatePosition, false);
    },
  };
}
