import { useState, useEffect, Dispatch } from 'react';
import { useDispatch } from 'react-redux';

interface QuickstartStepProps {
  isStepComplete: boolean;
  metadata: string[];
  id: string;
  completedDescription: string;
  pendingDescription: string;
  patchOnboarding: ({ id, step }: { id: string; step: string }) => void;
  removeOnboardingStep: ({ id, step, pendingStep }: { id: string; step: string; pendingStep?: string }) => void;
}

export function useQuickstartStep({
  id,
  isStepComplete,
  metadata,
  pendingDescription,
  completedDescription,
  patchOnboarding,
  removeOnboardingStep,
}: QuickstartStepProps) {
  const dispatch: Dispatch<any> = useDispatch();
  const [open, setOpen] = useState(Boolean(!isStepComplete && metadata.includes(pendingDescription)));

  const updateSiteOnboardingStep = () => {
    if (metadata.includes(completedDescription)) return;
    if (!metadata.includes(pendingDescription)) {
      dispatch(
        patchOnboarding({
          id: id,
          step: pendingDescription,
        })
      );
    }
  };

  useEffect(() => {
    if (!metadata.includes(completedDescription) && isStepComplete) {
      dispatch(
        removeOnboardingStep({
          id: id,
          step: pendingDescription,
          pendingStep: completedDescription,
        })
      );
    }
  }, []);

  const handleExpansionPanelClick = () => {
    updateSiteOnboardingStep();
    setOpen((prev) => !prev);
  };
  const handleCompleteStep = () => {
    dispatch(
      patchOnboarding({
        id: id,
        step: completedDescription,
      })
    );
  };

  const handleMarkStepAsIncomplete = () => {
    dispatch(
      removeOnboardingStep({
        id: id,
        step: completedDescription,
        pendingStep: pendingDescription,
      })
    );
  };

  return {
    handleIconClick: isStepComplete ? handleMarkStepAsIncomplete : handleCompleteStep,
    handleExpansionPanelClick,
    open,
  };
}
