import UnstackSplash from 'components/UnstackSplash';
import React from 'react';
import { ReactComponent as Divider } from '../../../../assets/images/plan-divider.svg';
import { ReactComponent as SepiaBrand } from '../../../../assets/images/elastic-path-logo.svg';
import {
  IconChoosePlanCheck,
  IconHelp,
  IconLightning,
  IconQuestionBubble,
  IconSlack,
  IconUniversity,
  IconWand,
} from 'components/base/BaseIcons';
import { OnMount } from 'hooks/mountUnmountHooks';
import { useDispatch, useSelector } from 'react-redux';

import * as subscriptionActions from '../../../../actions/subscriptionActions';
import { shopifySubscribe } from 'services/spark-api';
import { selectSiteByDomain } from 'reducers/sitesReducer';
import { USite } from 'types/USite';
import { selectSubscription, selectSubscriptionFetching } from 'reducers/subscriptionReducer';
import { selectAccount } from 'reducers/accountReducer';
import { identify } from 'lib/tracking/klaviyo';

import styles from './UnstackPurchasePlan.module.scss';
import { useParams } from 'react-router-dom';
import { PARTNERS } from '../../../../constants';

export default function UnstackPurchasePlan(props: any) {
  const dispatch = useDispatch();
  const subscription = useSelector(selectSubscription);
  const fetching = useSelector(selectSubscriptionFetching);
  const { urlSite } = useParams();
  const site: USite = useSelector((state) => (selectSiteByDomain as any)(state, urlSite));
  const account = useSelector((state) => selectAccount(state));

  // @ts-ignore
  const partnerID = useSelector((state) => state?.account?.partner);
  const isKlevuPartner = partnerID === PARTNERS.KlevuShopify;
  const standardPlanId = isKlevuPartner ? 'klevu_shop_lp_standard' : 'shop_lp_standard';
  const premiumPlanId = isKlevuPartner ? 'klevu_shop_lp_premium' : 'shop_lp_premium';
  const pageTitle = isKlevuPartner ? 'Elastic Path Studio for Klevu' : 'Elastic Path Studio for Shopify';

  OnMount(() => {
    dispatch(subscriptionActions.requestIfNeeded());
  });

  async function changePlan(id: string) {
    identify({ $email: account.users[0].user.email, subscription_plan: id });
    const res = await shopifySubscribe(site.id, id);
    if (res.status === 200 || res.status === 400) {
      if (
        id === 'shop_lp_free' &&
        (!subscription ||
          !subscription.plan ||
          (subscription.plan !== 'shop_lp_standard' && subscription.plan !== 'shop_lp_premium'))
      ) {
        window.top.location.replace(`${window.location.origin}/${urlSite}`);
      } else {
        window.top.location.replace(res.json.redirect_to);
      }
    }
  }

  function BenefitRow(props: { text: string; helpTitle?: string; helpText?: string; icon?: JSX.Element }) {
    return (
      <div className={styles.row}>
        {props.icon || <IconChoosePlanCheck />}
        <div className={styles.benefitText}>{props.text}</div>
        {props.helpTitle && (
          <IconHelp
            className={styles.iconHelp}
            data-html
            data-tip={`<div style="padding:12px;font-size:12px;width:220px"><div style="font-weight:600;margin-bottom:8px">${props.helpTitle}</div>${props.helpText}</div>`}
          />
        )}
      </div>
    );
  }

  function ChoosePlanButton(props: { id: string }) {
    return (
      <button
        className={`${!props.id ? styles.secondary : ''}`}
        onClick={() => {
          props.id ? changePlan(props.id) : (location.href = 'https://www.unstack.com/goto?pricing-shopify-scale');
        }}
        disabled={props.id && subscription?.plan.startsWith(props.id)}
      >
        {props.id === '' ? 'Talk with us' : subscription?.plan.startsWith(props.id) ? 'Current Plan' : 'Choose Plan'}
      </button>
    );
  }

  return (
    <>
      {fetching && <UnstackSplash isLoading={true} />}
      {!fetching && (
        <>
          <section className={styles.headingContainer}>
            <SepiaBrand className={styles.brand} />
            <h1>{pageTitle}</h1>
            <div className={styles.subtitle}>Drive more sales. Build better relationships.</div>
          </section>
          <section className={styles.containers}>
            <section className={styles.container}>
              <div className={styles.tile}>
                <div className={styles.header}>
                  <div className={styles.price}>$99/mo</div>
                  <div className={styles.title}>Acquire</div>
                  <div className={styles.description}>Reduce CAC by up to 35% with shoppable landing pages</div>
                </div>
                <div className={styles.tileContent}>
                  <ChoosePlanButton id={standardPlanId} />
                  <div className={styles.perksContainer}>
                    <div>50 landing pages</div>
                    <Divider className={styles.divider} />
                    <div>50,000 pageviews/month</div>
                    <Divider className={styles.divider} />
                    <div>Unlimited Users</div>
                  </div>
                </div>
              </div>
              <div className={styles.benefits}>
                <BenefitRow
                  text={"Elastic Path Studio's mobile-friendly template library"}
                  helpTitle={"Elastic Path Studio's mobile-friendly template library"}
                  helpText={
                    '50+ high-quality usecase-focused landing page designs. All Elastic Path Studio pages and components are made to look great on mobile phones and tablets.'
                  }
                />
                <BenefitRow
                  text={'Form builder'}
                  helpTitle={'Form builder'}
                  helpText={
                    'Use Elastic Path Studio forms to capture zero party data and build your email and SMS lists faster. We also support your favorite form builders like Klaviyo.'
                  }
                />
                <BenefitRow
                  text={'Publish to Shopify domain'}
                  helpTitle={'Publish to Shopify domain'}
                  helpText={
                    "All Elastic Path Studio accounts can be configured to publish pages on your store domain through Shopify's App proxy."
                  }
                />
                <BenefitRow
                  text={'25+ app integrations'}
                  helpTitle={'25+ app integrations'}
                  helpText={
                    'Elastic Path Studio has dozens of integrations with products like TripleWhale, Klaviyo, Gorgias, and Google Analytics.'
                  }
                />
                <BenefitRow
                  text={'A/B testing'}
                  helpTitle={'A/B testing'}
                  helpText={
                    'Built in A/B testing allows you to optimize content for conversions without relying on third-party tooling that slows down page loads.'
                  }
                />
                <BenefitRow
                  text={'Dynamic text replacement'}
                  helpTitle={'Dynamic text replacement'}
                  helpText={
                    'Increase the relevancy of landing pages by inserting specific phrases according to what users are searching for. Drive more conversions and high Google Ads relevance scores.'
                  }
                />
                <BenefitRow
                  text={'High-performance page load times'}
                  helpTitle={'High-performance page load times'}
                  helpText={
                    'Pages are cached for near instant loads. Media is optimized for every device. Fast load times directly influence high conversion rates.'
                  }
                />
                <Divider />
                <BenefitRow
                  icon={<IconUniversity />}
                  text={'Elastic Path University'}
                  helpTitle={'Elastic Path University'}
                  helpText={
                    'Video tutorials help you get started quickly. Learn the basics of building pages and tackle advanced concepts like A/B testing or Personalized content.'
                  }
                />
                <BenefitRow
                  icon={<IconSlack />}
                  text={'Community Slack'}
                  helpTitle={'Community Slack'}
                  helpText={
                    'All Elastic Path Studio customers get access to our community Slack where you can connect with other users, learn about online events, and pick up tips & tricks.'
                  }
                />
                <BenefitRow
                  icon={<IconQuestionBubble />}
                  text={'Live chat & email support'}
                  helpTitle={'Live chat & email support'}
                  helpText={
                    "You'll have access to in-app chat during office hours (EST) and email on the off hours to quickly get help on any issues."
                  }
                />
              </div>
            </section>
            <section className={styles.container}>
              <div className={styles.tile}>
                <div className={styles.header}>
                  <div className={styles.price}>$299/mo</div>
                  <div className={styles.title}>Retain</div>
                  <div className={styles.description}>Scale profitably and drive returning customer revenue</div>
                </div>
                <div className={styles.tileContent}>
                  <ChoosePlanButton id={premiumPlanId} />
                  <div className={styles.perksContainer}>
                    <div>Unlimited landing pages</div>
                    <Divider className={styles.divider} />
                    <div>250,000 pageviews/month</div>
                    <Divider className={styles.divider} />
                    <div>Advanced personalization</div>
                  </div>
                </div>
              </div>
              <div className={styles.benefits}>
                <h4>Everything in Acquire, plus...</h4>
                <BenefitRow
                  text={'Segment-based personlization'}
                  helpTitle={'Segment-based personlization'}
                  helpText={
                    'Set rules in our editor to dynamically change what users see in order to maximize conversion rates and order values.'
                  }
                />
                <BenefitRow
                  text={'Advanced Klaviyo syncing'}
                  helpTitle={'Advanced Klaviyo syncing'}
                  helpText={
                    'Bring Klaviyo data into Elastic Path Studio for advanced on-page personalization, omnichannel tracking, and enhanced first party data collection.'
                  }
                />
                <BenefitRow text={'Integrates with Vercel & Netlify'} />
                <Divider />
                <BenefitRow
                  icon={<IconWand />}
                  text={'Dedicated Landing Page Specialist'}
                  helpTitle={'Dedicated landing page specialist'}
                  helpText={
                    'A member of our team will work directly with you to help ensure that your pages are performing the way you expect.'
                  }
                />
                <BenefitRow
                  icon={<IconQuestionBubble />}
                  text={'Priority support'}
                  helpTitle={'Priority support'}
                  helpText={
                    "You'll jump to the front of the queue with support issues and always receive a timely response."
                  }
                />
                <BenefitRow
                  icon={<IconLightning />}
                  text={'Existing content migration'}
                  helpTitle={'Existing content migration'}
                  helpText={
                    "Have content you need to import to Elastic Path Studio? We'll work with you to get you setup quickly."
                  }
                />
              </div>
            </section>
            <section className={styles.container}>
              <div className={styles.tile}>
                <div className={styles.header}>
                  <div className={styles.price}>$999/mo</div>
                  <div className={styles.title}>Scale</div>
                  <div className={styles.description}>
                    Scale faster with expert managed services for eCommerce brands
                  </div>
                </div>
                <div className={styles.tileContent}>
                  <ChoosePlanButton id="" />
                  <div className={styles.perksContainer}>
                    <div>Headless Storefronts</div>
                    <Divider className={styles.divider} />
                    <div>500,000+ pageviews/month</div>
                    <Divider className={styles.divider} />
                    <div>Expert managed services</div>
                  </div>
                </div>
              </div>
              <div className={styles.benefits}>
                <h4>Everything in Retain, plus...</h4>
                <BenefitRow text={'Headless storefronts (beta)'} />
                <BenefitRow text={'Done for you landing pages'} />
                <BenefitRow text={'Custom component development'} />
                <BenefitRow text={'Monthly strategy meeting'} />

                <Divider />
                <BenefitRow text={'Landing page CRO audit'} />
                <BenefitRow text={'Up to 5 monthly landing pages'} />
                <BenefitRow text={'Dedicated slack channel'} />
                <BenefitRow text={'Tier 1 Eng. support'} />
              </div>
            </section>
          </section>
        </>
      )}
    </>
  );
}
