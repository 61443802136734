import { useShopifyCollections } from 'components/Storefront/hooks';
import { ShopifyCollectionsC } from 'contracts/ShopifyCollectionsC';
import throttle from 'lodash/throttle';
import React, { useCallback, useEffect, useState } from 'react';
import { ShopifyCollection } from 'types/ShopifyProduct';

const withShopifyCollections = (WrappedComponent: React.FunctionComponent<any>) => {
  return (props: JSX.IntrinsicAttributes & { children?: React.ReactNode }) => {
    const [productCollectionQuery, setProductCollectionQuery] = useState('');
    const [data, isLoading] = useShopifyCollections(productCollectionQuery.trim(), undefined, null, true);
    const [shopifyCollection, setShopifyCollection] = useState<ShopifyCollection[]>([]);

    const setQueryThrottled = useCallback(
      throttle((query: string) => {
        setProductCollectionQuery(query);
      }, 2000),
      []
    );

    useEffect(() => {
      const dataAsContract = data as ShopifyCollectionsC;
      if (data && Object.keys(data).length >= 0 && dataAsContract.results && !isLoading) {
        setShopifyCollection(dataAsContract.results);
      }
    }, [data, isLoading]);

    return (
      <WrappedComponent
        shopifyCollections={shopifyCollection}
        setShopifyCollection={setShopifyCollection}
        {...props}
        collectionsLoading={isLoading}
        setProductCollectionQuery={setQueryThrottled}
      />
    );
  };
};

export default withShopifyCollections;
