import React from 'react';
import './Spinner.scss';

export default (props) => {
  const { className, size, style } = props;

  return (
    <div
      className={`${className} spark-spinner la-ball-grid-beat la-${size}x`}
      style={style}
      data-test-id="fullpage-spinner"
    >
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};
