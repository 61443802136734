import * as actionTypes from './actionTypes';

export function showPublishSuccessMsg() {
  return {
    type: actionTypes.EDITOR_SHOW_PUBLISH_SUCCESS_MESSAGE,
  };
}

export function hidePublishSuccessMsg() {
  return {
    type: actionTypes.EDITOR_HIDE_PUBLISH_SUCCESS_MESSAGE,
  };
}
