import React from 'react';

import Select from '../Select';

import { ReactComponent as SvgIcon } from '../../assets/images/icon-filter.svg';

import styles from './Contacts/ContactsFilter.module.scss';

export default ({ filter, setFilter, filters }) => (
  <div className={styles.filtersContainer}>
    <div className={styles.filter} data-test-id="filter">
      <div className={styles.filterIcon}>
        <SvgIcon />
      </div>
      Viewing&nbsp;
      <Select
        options={filters}
        isInline
        defaultValue={filters[0]}
        value={filter}
        onChange={(option) => {
          setFilter(option);
        }}
      />
    </div>
  </div>
);
