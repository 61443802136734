import React from 'react';
import classnames from 'classnames';

import styles from './Tabs.module.scss';

export default ({ label, updateTab, isSelected }) => (
  <div onClick={updateTab} className={classnames(styles.tab, { [styles.isSelected]: isSelected })}>
    {label}
  </div>
);
