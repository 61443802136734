import { select, call, put, putResolve, all, throttle, take, takeLatest, takeEvery } from 'redux-saga/effects';
import * as api from '../services/spark-api';
import * as types from '../actions/actionTypes';
import * as accountActions from '../actions/accountActions';
import * as pageActions from '../actions/pageActions';
import * as uiPageSettingsSelectors from '../reducers/uiPageSettingsReducer';
import * as uiActions from '../actions/uiActions';
import * as mediaActions from '../actions/mediaActions';
import * as storefrontActions from '../actions/storefrontActions';
import * as contentTestActions from '../actions/contentTestActions';
import * as sectionSelectors from '../reducers/sectionsReducer';
import * as pageSelectors from '../reducers/pagesReducer';

import requireSiteId from './utils/requireSiteId';
import { normalizePageList, normalizePage, denormalizePage } from './normalizrs/page-normalizrs';

import merge from 'lodash/merge';
import omit from 'lodash/omit';
import { normalizeList } from './normalizrs/article-normalizrs';
import * as articleActions from '../actions/articleActions';
import { applyOps } from '../lib/immutable-operations';
import * as articleSelectors from '../reducers/articlesReducer';
import { mergeWith } from 'lodash';

// function* fetchPageListIfNeeded(action) {
//   // const isFetching = yield select(pageSelectors.selectIsFetchingAll);

//   if (isFetching == null) {
//     yield put(pageActions.requestList());
//   }
// }

// function* fetchPaginatedPagesList(action) {
//   const { payload } = action;

//   try {
//     const response = yield call(api.getPaginatedPages, payload);

//     if (response.ok) {
//       const normalizedData = normalizePageList(response.json.results);

//       const { result: pageIds, entities } = normalizedData;

//       yield put(
//         pageActions.receiveList(pageIds, entities, response.json.count)
//       );
//     } else if (response.unauthorized) {
//       yield put(accountActions.logout());
//     } else {
//       console.error('Failed trying to fetch pages', response.json);
//     }
//   } catch (e) {
//     yield put(uiActions.connectionError());
//     console.error(e);
//   }
// }

// function* fetchPageList(action) {
//   try {
//     const response = yield call(api.getPages);

//     // Responses are paginated. If there are more pages, fetch those too.
//     if (response.ok && response.json.next) {
//       let nextResponse = response;
//       do {
//         nextResponse = yield call(
//           api.fetchAuthorizedJSON,
//           nextResponse.json.next
//         );
//         if (nextResponse.ok)
//           response.json.results = response.json.results.concat(
//             nextResponse.json.results
//           );
//       } while (nextResponse.ok && nextResponse.json.next);
//     }

//     if (response.status === 200) {
//       const normalizedData = normalizePageList(response.json.results);
//       const { result: pageIds, entities } = normalizedData;

//       yield put(pageActions.receiveList(pageIds, entities, 0));
//     } else if (response.unauthorized) {
//       yield put(accountActions.logout());
//     } else {
//       console.error('Failed trying to fetch pages', response.json);
//     }
//   } catch (err) {
//     yield put(uiActions.connectionError());
//     console.error(err);
//   }
// }

function* fetchPage(action) {
  try {
    const { pageId } = action.payload;
    const response = yield call(api.getPage, pageId);

    if (response.status === 200) {
      yield processAndReceivePageResponse(response.json);
    } else if (response.unauthorized) {
      yield put(accountActions.logout());
    } else {
      yield put(uiActions.connectionError());
      console.error('Failed trying to fetch page', response.json);
    }
  } catch (err) {
    yield put(uiActions.connectionError());
    console.error(err);
  }
}

function* touchPage(action) {
  const { id } = action.payload;
  yield put({ type: types.PAGE_TOUCH, payload: { id } });
}

let isRequesting = false;
window.addEventListener('beforeunload', function (e) {
  if (isRequesting) {
    e.preventDefault();
    const message =
      'Unsaved changes are currently processing. ' + 'If you leave before saving, your changes will be lost.';
    return (e.returnValue = message);
  }
});

function* autoSavePages(action) {
  isRequesting = true;
  try {
    // Get the Page IDs that needs to be updated
    const pageIds = yield select(pageSelectors.selectPageIdsReadyForUpdate);

    yield all(pageIds.map((pageId) => put({ payload: { pageId }, type: 'PAGE_UPDATE_REQUEST' })));

    // Make API calls to update pages individually.
    yield all(pageIds.map((pageId) => put({ pageId, type: 'AUTO_SAVE_PAGE' })));
  } catch (err) {
    isRequesting = false;
    console.error(err);
  }
}

function* autoSavePage(action) {
  const pageId = action.pageId;

  let payload = yield selectPagePayload(pageId);
  payload = omit(payload, ['section_product_collections', 'section_articles']);
  payload.content.sections = payload.content.sections.filter((section) => section);

  // Make API call
  isRequesting = false;
  const response = yield call(api.putPage, payload);
  const { ok, json, unauthorized } = response;
  if (ok) {
    if (!isRequesting) {
      yield put(pageActions.receiveUpdate(pageId));
      yield put(pageActions.receiveSectionArticles(json.section_articles));
      yield put(storefrontActions.receiveSectionCollection(json.section_product_collections));
    }
  } else if (unauthorized) {
    yield put(accountActions.logout());
  } else {
    yield put({
      type: types.PAGE_RECEIVE_ERROR,
      payload: { pageId, error: json },
    });
  }
}

function* updatePageInfo(action) {
  const { pageId, resolve, reject } = action.payload;

  const page = yield select(pageSelectors.selectPage, pageId);
  const ops = yield select(uiPageSettingsSelectors.selectOps);

  if (page == null || ops == null) return;

  try {
    yield waitForOtherUpdates(pageId);

    // Go ahead and update
    // (I do not understand why??) -> RIZWAN
    yield putResolve(pageActions.requestUpdate(pageId));

    const cleanPayload = yield selectPagePayload(pageId);
    const payload = omit(applyOps(page, ops), 'content');

    let mergedPayload = mergeWith({}, cleanPayload, payload, (objValue, srcValue, key) => {
      if (key === 'template_objects') {
        return Object.assign({}, objValue, srcValue);
      }
    });
    mergedPayload.required_subscriptions = payload.required_subscriptions;
    mergedPayload = omit(mergedPayload, ['section_product_collections', 'section_articles']);

    const response = yield call(api.putPage, mergedPayload);

    yield put(pageActions.receiveUpdate(pageId));

    if (response.ok) {
      // On success, merge props and resolve
      const normalizedData = normalizePage(response.json);
      const { entities } = normalizedData;
      yield put(pageActions.receivePage(entities));

      if (resolve) resolve(response.json);
    } else {
      console.error('Failed trying to update page', response.json);
      if (reject) reject(response.json);
    }
  } catch (err) {
    yield put(uiActions.connectionError(err));
    console.log(err);
  }
}

function* publishPage(action) {
  const { pageId } = action.payload;

  yield waitForOtherUpdates(pageId);

  const [hasErrors, errors] = yield validatePage(pageId);

  if (hasErrors) {
    yield put(pageActions.receivePublishError(pageId, errors));
  } else {
    try {
      const response = yield api.publishPage(pageId);

      if (response.ok) {
        yield put(pageActions.receivePublishSuccess(pageId));
        yield processAndReceivePageResponse(response.json);
      } else if (response.status === 400) {
        yield put(pageActions.receivePublishError(pageId, response.json));
      } else if (response.status === 406) {
        yield put(pageActions.dynamicContentPageErrors(pageId, Object.values(response.json).flat()));
      }
    } catch (err) {
      yield put(uiActions.connectionError());
    }
  }
}

function* unpublishPage(action) {
  const { pageId } = action.payload;
  yield waitForOtherUpdates(pageId);
  try {
    const response = yield api.unpublishPage(pageId);

    if (response.ok) {
      yield put(pageActions.receiveUnpublishSuccess(pageId));
      yield processAndReceivePageResponse(response.json);
    } else {
      console.error('Failed trying to unpublish page.', response.json);
    }
  } catch (err) {
    yield put(uiActions.connectionError());
  }
}

function* clonePage(action) {
  try {
    const { pageId, onSuccess } = action.payload;
    const response = yield api.clonePage(pageId);

    if (response.ok) {
      yield put(pageActions.receiveCloneSuccess(pageId));
      yield processAndReceivePageResponse(response.json);
      if (onSuccess) onSuccess(response.json.id);
    } else {
      yield console.error('Discard-page API request failed', response);
    }
  } catch (err) {
    yield put(uiActions.connectionError());
  }
}

function* discardPage(action) {
  try {
    const { pageId } = action.payload;
    const response = yield api.discardPage(pageId);

    if (response.ok) {
      window.location.reload();
    } else {
      yield console.error('Discard-page API request failed', response);
    }
  } catch (err) {
    yield put(uiActions.connectionError());
  }
}

function* deletePage(action) {
  try {
    const { pageId } = action.payload;
    yield call(api.deletePage, pageId);
  } catch (err) {
    console.error(err);
  }
}

// Utilities
// ----------------------------------------------------------------------------

function* selectPagePayload(pageId) {
  const pagesById = yield select(pageSelectors.selectById);
  const contentById = yield select(pageSelectors.selectContentById);
  const sectionsById = yield select(sectionSelectors.selectAllById);

  const entities = {
    pages: pagesById,
    content: contentById,
    sections: sectionsById,
  };

  return denormalizePage(pageId, entities);
}

function* waitForOtherUpdates(pageId) {
  // Select page
  let page = yield select(pageSelectors.selectPage, pageId);

  // Wait for any other updates to complete
  while (page.isUpdating) {
    yield take(types.PAGE_UPDATE_RECEIVE);
    page = yield select(pageSelectors.selectPage, pageId);
  }

  // If page is dirty, run an autosave first
  if (page.touched) {
    yield autoSavePage(pageId);
  }
}

function* validatePage(pageId) {
  const page = yield select(pageSelectors.selectPage, pageId);

  let hasErrors = false;
  const errors = {};

  if (page.name == null) {
    hasErrors = true;
    errors.name = 'Name may not be blank.';
  }
  if (page.slug == null) {
    hasErrors = true;
    errors.slug = 'URL may not be blank.';
  }
  if (page.meta.title == null) {
    hasErrors = true;
    errors.title = 'Title may not be blank.';
  }

  return [hasErrors, errors];
}

function* recoverPage(action) {
  const { pageId, onSuccess } = action.payload;

  try {
    const response = yield call(api.undeletePage, pageId);
    if (response.ok) {
      yield put(pageActions.receiveUndeleteSuccess());
      if (onSuccess) onSuccess(response.json.id);
    }
  } catch (e) {
    console.error(e);
  }
}

function* processAndReceivePageResponse(actionOrResponseBody) {
  const responseBody =
    actionOrResponseBody.type === types.PAGE_PROCESS_AND_RECEIVE_RAW_RESPONSE
      ? actionOrResponseBody.payload.responseBody
      : actionOrResponseBody;

  const { media = [], experiments = [], ...page } = responseBody;

  const receivedMedia = [...media, ...(page.featured_media ? [page.featured_media] : [])];

  if (receivedMedia.length) yield put(mediaActions.receiveArray(receivedMedia));

  if (experiments) yield put(contentTestActions.receiveArray(experiments));

  const normalizedData = normalizePage(page);
  const { entities } = normalizedData;
  yield put(pageActions.receivePage(entities));
}

// Root saga
// ----------------------------------------------------------------------------

function* pagesSaga() {
  yield all([
    // takeLatest(types.PAGES_REQUEST_LIST, fetchPageList),
    // takeLatest(types.PAGES_REQUEST_LIST_IF_NEEDED, fetchPageListIfNeeded),
    // takeLatest(types.PAGES_REQUEST_LIST_BY_PAGE, fetchPaginatedPagesList),
    takeEvery(types.PAGE_REQUEST, fetchPage),
    takeLatest(types.PAGE_TOUCH, autoSavePages),
    throttle(5000, types.AUTO_SAVE_PAGE, autoSavePage),
    takeEvery(types.PAGE_DELETE, deletePage),
    takeLatest(types.PAGE_UPDATE_CONTENT, touchPage),
    takeLatest(types.PAGE_REQUEST_PAGE_INFO_UPDATE, updatePageInfo),
    takeEvery(types.PAGE_REQUEST_PUBLISH, publishPage),
    takeEvery(types.PAGE_REQUEST_UNPUBLISH, unpublishPage),
    takeEvery(types.PAGE_REQUEST_CLONE, clonePage),
    takeEvery(types.PAGE_REQUEST_DISCARD, discardPage),
    takeLatest(types.PAGE_REQUEST_UNDELETE, recoverPage),
    takeEvery(types.PAGE_PROCESS_AND_RECEIVE_RAW_RESPONSE, processAndReceivePageResponse),
  ]);
}

export default pagesSaga;
