import * as actionTypes from '../actions/actionTypes';

// ----------------------------------------------------------------------------
// UserSettings Reducer
// ----------------------------------------------------------------------------

const initState = {
  isOpen: false,
};

export default function (state = initState, action) {
  switch (action.type) {
    case actionTypes.ACCOUNT_USER_SETTINGS_OPEN:
      return {
        isOpen: true,
        ...action.payload,
      };

    case actionTypes.ACCOUNT_USER_SETTINGS_CLOSE:
      return {
        ...state,
        isOpen: false,
      };

    default:
      return state;
  }
}

// ----------------------------------------------------------------------------
// Selectors
// ----------------------------------------------------------------------------

export function selectLocalState(globalState) {
  return globalState.uiUserSettings;
}
