import React from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as SvgIconBack } from '../assets/images/icon-back.svg';
import styles from './HeaderBar.module.scss';
import adminStyles from './AdminBar.module.scss';

class HeaderBar extends React.PureComponent {
  render() {
    const { fixed } = this.props;
    const headerClassName = `${styles.HeaderBar} ${fixed ? styles.fixed : ''}`;

    return (
      <header className={headerClassName}>
        <section className={styles.center}>{this.props.center}</section>
        <section className={styles.right}>{this.props.right}</section>
        {this.props.backTo ? (
          <section className={styles.left}>
            <Link to={this.props.backTo} className={adminStyles.circleIcon} aria-label="back-to">
              <SvgIconBack />
            </Link>
          </section>
        ) : (
          <section className={styles.left}>
            <div onClick={() => this.props.goBack()} className={styles.backButton2} aria-label="back-to">
              <SvgIconBack />
            </div>
          </section>
        )}
        {this.props.foreGround}
      </header>
    );
  }
}

export default HeaderBar;
