import * as types from './actionTypes';

// Retrieve list
// ----------------------------------------------------------------------------

export function requestList(siteId) {
  return {
    type: types.ARTICLES_REQUEST_LIST,
    payload: { siteId },
  };
}

export function requestListIfNeeded(siteId) {
  return {
    type: types.ARTICLES_REQUEST_LIST_IF_NEEDED,
    payload: { siteId },
  };
}

export function receiveList(articleIds, entities, count) {
  return {
    type: types.ARTICLES_RECEIVE_LIST,
    payload: { articleIds, entities, count },
  };
}

export function requestSearch(payload) {
  return {
    type: types.ARTICLES_REQUEST_SEARCH,
    payload,
  };
}

export function requestFilteredArtcles(payload) {
  return {
    type: types.ARTICLES_RECEIVE_LIST_BY_PAGE,
    payload,
  };
}

// export function receiveSearchedList(articleIds) {
//   return {
//     type: types.ARTICLES_RECEIVE_SEARCHED_LIST,
//     payload: { articleIds },
//   };
// }

// Retrieve
// ----------------------------------------------------------------------------

export function request(articleId) {
  return {
    type: types.ARTICLE_REQUEST,
    payload: { articleId },
  };
}

export function receive(article) {
  return {
    type: types.ARTICLE_RECEIVE,
    payload: {
      articleId: article.id,
      article,
    },
  };
}

export function processAndReceiveRawResponse(responseBody) {
  return {
    type: types.ARTICLE_PROCESS_AND_RECEIVE_RAW_RESPONSE,
    payload: { responseBody },
  };
}

export function requestUpdate(articleId, ops) {
  return {
    type: types.ARTICLE_REQUEST_UPDATE,
    payload: { articleId, ops },
  };
}

export function willRequestUpdate(articleId, initiatorAction) {
  return {
    type: types.ARTICLE_WILL_REQUEST_UPDATE,
    payload: { articleId, initiatorAction },
  };
}

// Update
// ----------------------------------------------------------------------------

export function receiveUpdateSuccess(articleId) {
  return {
    type: types.ARTICLE_RECEIVE_UPDATE_SUCCESS,
    payload: { articleId },
  };
}

export function receiveUpdateError(articleId, errors) {
  return {
    type: types.ARTICLE_RECEIVE_UPDATE_ERROR,
    payload: { articleId, errors },
  };
}

// Autosave
// ----------------------------------------------------------------------------

export function addAutosaveOps(articleId, ops) {
  return {
    type: types.ARTICLE_ADD_AUTOSAVE_OPS,
    payload: { articleId, ops },
  };
}

export function removeAutosaveOps(articleId, ops) {
  return {
    type: types.ARTICLE_REMOVE_AUTOSAVE_OPS,
    payload: { articleId, ops },
  };
}

// Publish
// ----------------------------------------------------------------------------

export function requestPublish(articleId, isSchedule) {
  return {
    type: types.ARTICLE_REQUEST_PUBLISH,
    payload: { articleId, isSchedule },
  };
}

export function receivePublishSuccess(articleId) {
  return {
    type: types.ARTICLE_RECEIVE_PUBLISH_SUCCESS,
    payload: { articleId },
  };
}

export function receivePublishError(articleId, errors) {
  return {
    type: types.ARTICLE_RECEIVE_PUBLISH_ERROR,
    payload: { articleId, errors },
  };
}

// Unpublish
// ----------------------------------------------------------------------------

export function requestUnpublish(articleId) {
  return {
    type: types.ARTICLE_REQUEST_UNPUBLISH,
    payload: { articleId },
  };
}

export function receiveUnpublishSuccess(articleId) {
  return {
    type: types.ARTICLE_RECEIVE_UNPUBLISH_SUCCESS,
    payload: { articleId },
  };
}

// Unschedule
// ----------------------------------------------------------------------------

export function requestUnschedule(articleId) {
  return {
    type: types.ARTICLE_REQUEST_UNSCHEDULE,
    payload: { articleId },
  };
}

export function receiveUnscheduleSuccess(articleId) {
  return {
    type: types.ARTICLE_RECEIVE_UNSCHEDULE_SUCCESS,
    payload: { articleId },
  };
}

// Discard changes
// ----------------------------------------------------------------------------

export function requestDiscard(articleId) {
  return {
    type: types.ARTICLE_REQUEST_DISCARD,
    payload: { articleId },
  };
}

// Delete
// ----------------------------------------------------------------------------

export function requestDelete(articleId) {
  return {
    type: types.ARTICLE_REQUEST_DELETE,
    payload: { articleId },
  };
}

// Undelete
// ----------------------------------------------------------------------------

export function requestUndelete(articleId, onSuccess) {
  return {
    type: types.ARTICLE_REQUEST_UNDELETE,
    payload: { articleId, onSuccess },
  };
}

export function receiveUndeleteSuccess() {
  return {
    type: types.ARTICLE_RECEIVE_UNDELETE_SUCCESS,
  };
}
