import React, { useEffect, useRef } from 'react';

import styles from './KeyboardHandledList.module.scss';

interface Props {
  items: any;
  cursor: number;
  setHovered: (arg0: any | undefined) => void;
  onChange: (arg0: any, arg1?: null | boolean) => void;
  render?: any;
  setIsOpen?: (arg0: string) => void;
}

export default function (props: Props) {
  const { items, cursor, setHovered, onChange, render, setIsOpen } = props;
  const myRef = useRef(null);

  useEffect(() => {
    if (items.length > 7) {
      const element = myRef.current;
      element?.scrollIntoView({ block: 'nearest' });
    }
  }, [cursor]);

  if (render) return render(items, cursor, onChange, setHovered);

  return (
    <ul>
      {items.map((item: any, i: number) => (
        <li
          key={item}
          onMouseDown={(e) => {
            e.preventDefault();
            onChange(item);
            setIsOpen('');
          }}
          onMouseMove={() => setHovered(item)}
          onMouseLeave={() => setHovered(undefined)}
          className={cursor === i ? styles.ListActive : null}
          ref={cursor === i ? myRef : null}
        >
          {item}
        </li>
      ))}
    </ul>
  );
}
