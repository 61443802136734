import React from 'react';
import { compose } from 'redux';
import ReactDOM from 'react-dom';
import classnames from 'classnames';
import { connect } from 'react-redux';

import withSiteProps from 'containers/withSiteProps';
import { selectNotificationBySiteDomain } from 'reducers/notificationReducer';

import styles from './Sheet.module.scss';

import { ReactComponent as SvgIconInfo } from '../../../../assets/images/icon-page-info.svg';
import { ReactComponent as SvgIconUnstackle } from '../../../../assets/images/icon-sparkles.svg';

function mapSiteToProps(site) {
  return {
    siteName: site.name,
  };
}

const mapStateToProps = (state, ownProps) => {
  return {
    notificationBarIsOut: selectNotificationBySiteDomain(state, ownProps.urlSite),
  };
};

/** TODO: Use the existing EditingBar component here or update
 *  it to handle error messages display
 */
class EditingBar extends React.PureComponent {
  renderContent() {
    if (this.props.isEditing) {
      return (
        <>
          <SvgIconInfo className={styles.iconInfo} />
          <div className={styles.message}>Add this row to your data sheet</div>
          <div className={styles.rowButtons} data-test-id="row-editing-bar-buttons">
            <button className={styles.discard} onClick={this.props.discardChanges} type="button">
              Discard
            </button>
            <button className={styles.publish} onClick={this.props.createRow} type="button">
              Create
            </button>
          </div>
        </>
      );
    } else if (this.props.showPublishSuccess) {
      return (
        <div className={styles.successMessage}>
          <SvgIconUnstackle className={styles.iconUnstackles} />
          <strong>Success!</strong>
          Your changes have been published.
        </div>
      );
    }
  }

  shouldShow() {
    return this.props.isEditing || this.props.showPublishSuccess;
  }

  shouldShowErrors() {
    return !!Object.keys(this.props.errors).length;
  }

  getRenderedContent() {
    if (this.shouldShow()) {
      const renderedContent = this.renderContent();
      this.lastRenderedContent = renderedContent;
      return renderedContent;
    } else {
      return this.lastRenderedContent;
    }
  }

  getRenderedErrorContent() {
    return (
      <div>
        {Object.entries(this.props.errors).map((value) => {
          return (
            <div data-test-id="errors">
              <strong>{value[0]}</strong>: {value[1][0]}
            </div>
          );
        })}
      </div>
    );
  }

  render() {
    const className = classnames(styles.EditingBar, {
      [styles.show]: this.shouldShow(),
      [styles.notificationBarIsOut]: this.props.notificationBarIsOut,
    });

    const errorClasses = classnames(styles.Errors, {
      [styles.show]: this.shouldShowErrors(),
      [styles.notificationBarIsOut]: this.props.notificationBarIsOut,
    });

    return ReactDOM.createPortal(
      <div data-test-id="sheet-editing-bar">
        <div className={className} data-test-id="content">
          {this.getRenderedContent()}
        </div>
        <div className={errorClasses}>{this.getRenderedErrorContent()}</div>
      </div>,
      document.body
    );
  }
}

export default compose(withSiteProps(mapSiteToProps), connect(mapStateToProps))(EditingBar);
