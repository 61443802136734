import React from 'react';
import find from 'lodash/find';

import Select from './Select';
import styles from './StyleGuideEditor.module.scss';

type capitalizeOption = { value: string; label: string };

interface CapitalizeSelectProps {
  value: number;
  handleChange: (value: string) => void;
}

const CapitalizeSelect = ({ value, handleChange }: CapitalizeSelectProps) => {
  const capitalizeOptions: capitalizeOption[] = [
    { value: 'capitalize', label: 'Title Case' },
    { value: 'uppercase', label: 'Uppercase' },
    { value: 'lowercase', label: 'Lowercase' },
    { value: 'none', label: 'None' },
  ];

  return (
    <div className={styles.styleOption}>
      <span>Capitalization</span>
      <Select
        options={capitalizeOptions}
        defaultValue={{ value: 'none', label: 'None' }}
        value={value ? find(capitalizeOptions, { value }) : undefined}
        onChange={(option: capitalizeOption) => {
          handleChange(option.value);
        }}
      />
    </div>
  );
};

export default CapitalizeSelect;
