import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { selectAllSites } from '../reducers/sitesReducer';
import { OnMount } from 'hooks/mountUnmountHooks';

export function GoTo() {
  const sites: any[] = useSelector(selectAllSites);
  const location = useLocation();
  const navigate = useNavigate();
  const subpath = location.pathname.substring(location.pathname.indexOf('goto') + 5);

  OnMount(() => {
    navigate(sites && sites.length ? `/${sites[0].subdomain}/${subpath}` : '/');
  });

  return <></>;
}

export default GoTo;
