import QueryString from 'query-string';
import merge from 'lodash/merge';
import { UNSPLASH_API_BASE_URL, UNSPLASH_API_KEY } from '../settings';

// ----------------------------------------------------------------------------
// Search endpoints
// ----------------------------------------------------------------------------

export function searchPhotos(query, page) {
  return get('search/photos', { query, page });
}

export function getPhoto(id) {
  return get(`photos/${id}`);
}

export function downloadPhotoCounter(id) {
  return get(`photos/${id}/download`);
}

// ----------------------------------------------------------------------------
// Base methods for getting, posting, & putting
// ----------------------------------------------------------------------------

function assembleUrl(endpoint, query) {
  // Remove leading slash and add trailing slash to endpoint if necessary.
  endpoint = endpoint.replace(/^\//, '');
  // if (!endsWith(endpoint, '/')) endpoint = `${endpoint}/`;

  // Return assembled url
  return `${UNSPLASH_API_BASE_URL}${endpoint}?${QueryString.stringify(query)}`;
}

function get(endpoint, data, options) {
  const mergedOptions = merge(
    {
      method: 'GET',
    },
    options
  );
  const mergedData = merge({ client_id: UNSPLASH_API_KEY }, data);
  const url = assembleUrl(endpoint, mergedData);
  return fetchJSON(url, mergedOptions);
}

// ----------------------------------------------------------------------------
// Utilities
// ----------------------------------------------------------------------------

function fetchJSON(url, options) {
  const mergedOptions = merge(
    {
      headers: {
        'content-type': 'application/json',
      },
    },
    options
  );
  return fetch(url, mergedOptions).then(parseJSONResponse);
}

function parseJSONResponse(response) {
  return response.json().then((json) => ({
    status: response.status,
    ok: response.ok,
    json,
  }));
}
