/* eslint-disable jsx-a11y/img-redundant-alt */

import React, { useState } from 'react';
import ReactDOM from 'react-dom';

import { CSSTransition } from 'react-transition-group';
import classnames from 'classnames';
import getDocumentOffset, { getIFrameDocumentOffset } from '../../get-document-offset';
import { shallowEqual } from 'react-redux';

import styles from './AddItem.module.scss';

import { ReactComponent as SvgIconOpen } from '../../../assets/images/icon-add-section.svg';

// These commented out icons are for planned features. Leaving them here so we
// can just uncomment them when their functionality is ready.
//
// import { ReactComponent as SvgIconRteAddContent} from '../../../assets/images/icon-rte-add-content.svg';
// import { ReactComponent as SvgIconRteAddEmbed} from '../../../assets/images/icon-rte-add-embed.svg';
// import { ReactComponent as SvgIconRteAddForm} from '../../../assets/images/icon-rte-add-form.svg';
// import { ReactComponent as SvgIconRteAddIcon} from '../../../assets/images/icon-rte-add-icon.svg';
import { ReactComponent as SvgIconRteAddImage } from '../../../assets/images/icon-rte-add-image.svg';
// import { ReactComponent as SvgIconRteAddPrimaryButton} from '../../../assets/images/icon-rte-add-primary-button.svg';
// import { ReactComponent as SvgIconRteAddSecondaryButton} from '../../../assets/images/icon-rte-add-secondary-button.svg';
// import { ReactComponent as SvgIconRteAddTextAction} from '../../../assets/images/icon-rte-add-text-action.svg';
import { ReactComponent as SvgIconRteAddVideo } from '../../../assets/images/icon-rte-add-video.svg';
import { ReactComponent as SvgIconRteAddDivider } from '../../../assets/images/icon-rte-add-divider.svg';
import AddForm from './AddForm';

class AddItem extends React.PureComponent {
  state = {
    isOpen: false,
    videoInputIsOpen: false,
  };

  open = () => this.setState({ isOpen: true, videoInputIsOpen: false });
  close = () => this.setState({ isOpen: false, videoInputIsOpen: false });
  toggle = () => this.setState({ isOpen: !this.state.isOpen, videoInputIsOpen: false });

  componentDidUpdate(prevProps) {
    if (!shallowEqual(prevProps.bounds, this.props.bounds)) {
      this.setState({ isOpen: false });
    }
  }

  handleClickAdd = (format) => (e) => {
    this.props.addItem(format);
    this.close();
  };

  handleAddFormEmbed = (value) => {
    this.props.addItem('form_embed', value);
    this.close();
  };

  handleAddEmbeddedVideo = (e) => {
    this.setState({ videoInputIsOpen: true });
  };

  handleVideoSubmit = (value) => {
    this.props.addItem('embedded-video', value);
    this.close();
  };

  handleVideoClose = () => {
    this.props.addItem('cancel');
    this.close();
  };

  render() {
    const { quill, emptyLineIsSelected, bounds, isBlog } = this.props;
    const { isOpen, videoInputIsOpen } = this.state;

    let position;
    if ((emptyLineIsSelected || isOpen) && bounds) {
      const editor = quill.root;
      const editorOffset = !isBlog ? getIFrameDocumentOffset(editor) : getDocumentOffset(editor);
      position = {
        top: editorOffset.top + bounds.top + (bounds.bottom - bounds.top) / 2,
        left: editorOffset.left + 60,
      };
    }

    const containerWidth = (quill && quill.container.offsetWidth) || null;

    const rendered = (
      <CSSTransition in={!!position} classNames="container" timeout={0} mountOnEnter unmountOnExit>
        <CSSTransition in={isOpen} classNames={styles} timeout={300}>
          {(transitionState) => (
            <div
              className={classnames(styles.addItemMenu, {
                [styles.darkBg]: this.props.darkBg,
              })}
              data-test-id="add-item-menu"
              style={position}
            >
              <div className={styles.toggleButton} onMouseDown={this.toggle} data-test-id="toggler">
                <SvgIconOpen />
              </div>
              {transitionState !== 'exited' && (
                <div className={styles.itemButtons} data-test-id="controls">
                  {videoInputIsOpen ? (
                    <VideoInput
                      onChange={this.handleVideoSubmit}
                      onClose={this.handleVideoClose}
                      width={containerWidth}
                    />
                  ) : (
                    <>
                      <button onClick={this.handleClickAdd('media')} aria-label="unstack media" data-tip="Media">
                        <SvgIconRteAddImage />
                      </button>
                      {/* <button onClick={this.handleClickAddImage} data-tip="Icon">
                        <SvgIconRteAddIcon />
                      </button> */}
                      <button onClick={this.handleClickAdd('divider')} aria-label="divider" data-tip="Divider">
                        <SvgIconRteAddDivider />
                      </button>
                      <button
                        onClick={this.handleAddEmbeddedVideo}
                        aria-label="embedded video"
                        data-tip="Embedded Video"
                      >
                        <SvgIconRteAddVideo />
                      </button>
                      <AddForm onChange={this.handleAddFormEmbed} />
                      {/* <button onClick={this.handleClickAddImage} data-tip="Embed">
                        <SvgIconRteAddEmbed />
                      </button> */}
                      {/* <button onClick={this.handleClickAdd('button-primary')} data-tip="Primary button">
                        <SvgIconRteAddPrimaryButton />
                      </button> */}
                      {/* <button onClick={this.handleClickAdd('button-secondary')} data-tip="Secondary button">
                        <SvgIconRteAddSecondaryButton />
                      </button> */}
                      {/* <button onClick={this.handleClickAdd('text-action')} data-tip="Text action">
                        <SvgIconRteAddTextAction />
                      </button> */}
                    </>
                  )}
                </div>
              )}
            </div>
          )}
        </CSSTransition>
      </CSSTransition>
    );

    return ReactDOM.createPortal(rendered, document.body);
  }
}

export default AddItem;

function VideoInput(props) {
  const { onChange, onClose, width } = props;
  const [value, setValue] = useState('');

  function handleChange(e) {
    setValue(e.target.value);
  }

  function handleKeyEvent(e) {
    switch (e.which) {
      case 27:
        onClose();
        break;

      case 13:
        onChange(value);
        break;
      default:
    }
  }

  return (
    <input
      className={styles.videoInput}
      style={{ width: width }}
      type="text"
      aria-label="embedded video input" // use in cypress test
      placeholder="Paste a youtube or wistia link"
      onChange={handleChange}
      onKeyDown={handleKeyEvent}
      autoFocus
    />
  );
}
