import React from 'react';
import ZeroState, { Image, Header, Muted } from '../ZeroState';

export default function ZeroInsightsState({ icon, header, body }) {
  return (
    <ZeroState>
      <Image src={icon} />
      <Header>{header}</Header>
      <Muted>{body}</Muted>
    </ZeroState>
  );
}
