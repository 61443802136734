/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useState, useEffect } from 'react';
import { set, del, insert, push, assign } from 'object-path-immutable';
import SmartUrlInput from 'components/base/FormHelpers/SmartUrlInput';
import SideDrawer from 'components/base/SideDrawer';

interface EditDrawerProps {
  isOpen: boolean;
  close: () => void;
  dataRefArticles: string[];
  onChange: (newArticles: string[]) => void;
  min: number;
  max: number;
}

export default function EditDrawer(props: EditDrawerProps) {
  const { isOpen, close, dataRefArticles, onChange, min, max } = props;

  // We store a "draft" version of the articles in state because we don't want to
  // actually update it until the user clicks "save".
  const [articles, handleChange] = useState(dataRefArticles);

  // Reset the draft articles when the drawer opens.
  useEffect(() => {
    if (isOpen) handleChange(dataRefArticles);
  }, [isOpen]); // eslint-disable-line react-hooks/exhaustive-deps

  function handleSubmit(e: React.FormEvent) {
    e.preventDefault();
    onChange(articles);
    close();
  }

  const handlePush = () => {
    handleChange(push(articles, null, null));
  };

  const handlePop = () => {
    handleChange(del(articles, `${articles.length - 1}`));
  };

  return (
    <SideDrawer isOpen={isOpen} title="Edit section" close={close}>
      <form onSubmit={handleSubmit}>
        <fieldset>
          <label>Articles</label>
          <a
            onClick={(e) => {
              if (articles.length < max) {
                e.preventDefault();
                handlePush();
              }
            }}
            href="#"
            /* @ts-ignore */
            disabled={articles.length >= max}
          >
            Add
          </a>
          &nbsp;&nbsp;
          <a
            onClick={(e) => {
              if (articles.length > min) {
                e.preventDefault();
                handlePop();
              }
            }}
            href="#"
            /* @ts-ignore */
            disabled={articles.length <= min}
          >
            Remove
          </a>
        </fieldset>
        {articles.map((item, i) => {
          return (
            <fieldset key={i}>
              <SmartUrlInput
                // @ts-ignore Remove comment when SmartUrlInput is Typescript
                value={item || ''}
                onChange={(id: string) => handleChange(set(articles, String(i), id))}
                placeholder="Type article name"
                contentType={'article'}
                noPrefix
              />
            </fieldset>
          );
        })}
        <fieldset>
          <button
            className="button button-full"
            onClick={(e) => {
              e.preventDefault();
              close();
            }}
          >
            Cancel
          </button>
          <input type="submit" className="button button-primary button-full" value="Save" />
        </fieldset>
      </form>
    </SideDrawer>
  );
}
