import React, { useState, useRef } from 'react';
import styles from './FormContentEditor.module.scss';
import { useLocalDraft } from 'hooks/useLocalDraft';
import { Field, FieldWithOptions, HiddenField, OptionsTuples, SubmitButton, TextField, VisibleField } from './types';
import { useEscapeKey } from 'hooks/useKeyBinding';
import isEmpty from 'lodash/isEmpty';
import usePrevious from 'hooks/usePrevious';

import { ReactComponent as SvgIconRemove } from 'assets/images/icon-remove-section.svg';

type FieldEditorProps = {
  close: () => void;
  value: Field;
  onChange: (value: Field) => void;
  removeField: () => void;
};

type Errors = {
  name?: string;
  options?: string;
};

export function FieldEditor({ close, value, onChange, removeField }: FieldEditorProps) {
  const { draft, update, discard } = useLocalDraft<Field>(value);
  const [errors, setErrors] = useState<Errors>();

  // For fields with options, parse the options tuples into a string for use in
  // the textarea.
  const initializeOptionsString = () => {
    if (isFieldWithOptions(value.type)) {
      const field = value as FieldWithOptions;
      return optionsTuplesToString(field.options);
    }
  };
  const [optionsString, setOptionsString] = useState(initializeOptionsString);
  const resetOptionsString = () => setOptionsString(initializeOptionsString());

  // Reset state if the original field changes. This usually happens if the user
  // clicks another field.
  usePrevious(value, (prevValue) => {
    discard();
    setErrors(undefined);
    resetOptionsString();
  });

  // Handle submit
  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();

    let field = draft;
    const errors: Errors = {};

    // Validate name is present.
    if (!field.name) {
      errors.name = 'Name is required';
    }

    // Validate name only contains alphanumeric characters, hyphens, and underscores.
    if (/[^0-9a-zA-Z_-]+/gi.test(field.name)) {
      errors.name = 'Only alphanumeric characters, hyphens, and underscores allowed';
    }

    // For fields with options, normalize the options string into tuples and
    // validate.
    if (isFieldWithOptions(field.type)) {
      const fieldWithOptions = field as FieldWithOptions;

      if (!optionsString) {
        errors.options = 'Options are required';
      } else {
        const optionsTuples = optionsStringToTuples(optionsString);
        if (optionsTuples?.length) {
          fieldWithOptions.options = optionsTuples;
        } else {
          errors.options = 'Options are required';
        }
      }
    }

    if (isEmpty(errors)) {
      onChange(field);
      close();
    } else {
      setErrors(errors);
    }
  };

  useEscapeKey(close);

  const ref = useRef<null | HTMLFormElement>(null);
  if (ref.current) {
    ref.current.scroll({
      top: 0,
      behavior: 'smooth',
    });
  }

  return (
    <>
      <form onSubmit={handleSubmit} data-test-id="form-field-editor" ref={ref}>
        <div style={{ padding: '2rem' }}>
          {value.type === 'hidden' && (
            <fieldset>
              <label>Value</label>
              <input
                type="text"
                value={(draft as HiddenField).initial}
                onChange={(e) => update({ ...(draft as HiddenField), initial: e.target.value })}
              />
            </fieldset>
          )}
          {value.type === 'submit' && (
            <fieldset>
              <label>Button text</label>
              <input
                type="text"
                value={(draft as SubmitButton).initial}
                onChange={(e) =>
                  update({
                    ...(draft as SubmitButton),
                    initial: e.target.value,
                  })
                }
              />
            </fieldset>
          )}
          {!['hidden', 'submit'].includes(value.type) && (
            <fieldset>
              <label htmlFor="label_input">Label</label>
              <input
                type="text"
                id="label_input"
                value={(draft as VisibleField).label}
                onChange={(e) => update({ ...draft, label: e.target.value })}
              />
            </fieldset>
          )}
          {isFieldWithOptions(value.type) && (
            <fieldset>
              <label>Options</label>
              <textarea
                className={errors && errors.options ? 'fieldWithErrors' : ''}
                style={{ minHeight: '120px' }}
                value={optionsString}
                onChange={(e) => setOptionsString(e.target.value)}
              />
              <small>
                Separate each option with a <b>new line</b>.
              </small>
              {errors && errors.options && <small className="errorMessage">{errors.options}</small>}
            </fieldset>
          )}
          {!['hidden', 'radio', 'select', 'submit'].includes(value.type) && (
            <fieldset>
              <label htmlFor="placeholder_input">Placeholder text</label>
              <input
                type="text"
                id="placeholder_input"
                value={(draft as TextField).placeholder}
                onChange={(e) => update({ ...draft, placeholder: e.target.value })}
              />
            </fieldset>
          )}
          {!['hidden'].includes(value.type) && (
            <fieldset>
              <label htmlFor="help_input">Help text</label>
              <input
                type="text"
                id="help_input"
                value={(draft as VisibleField).help_text || ''}
                onChange={(e) => update({ ...draft, help_text: e.target.value })}
              />
            </fieldset>
          )}
          {!['hidden', 'submit'].includes(value.type) && (
            <fieldset>
              <label>
                <input
                  type="checkbox"
                  checked={(draft as VisibleField).required}
                  onChange={(e) => update({ ...draft, required: e.target.checked })}
                />{' '}
                Make this field required
              </label>
            </fieldset>
          )}
          <fieldset>
            <label>Name</label>
            <input
              className={errors && errors.name ? 'fieldWithErrors' : ''}
              type="text"
              value={(draft as VisibleField).name}
              onChange={(e) => update({ ...draft, name: e.target.value.toLowerCase() })}
              disabled={draft.id >= 100 && draft.id < 200}
            />
            {errors && errors.name && <small className="errorMessage">{errors.name}</small>}
            <small>This will be used to label responses in your Customers.</small>
          </fieldset>
          <fieldset>
            <button type="button" onClick={removeField} className="button button-secondary mr-3">
              <SvgIconRemove height={18} width={16} /> Delete
            </button>
            <button type="submit" className="button button-primary">
              Save
            </button>
          </fieldset>
        </div>
      </form>
    </>
  );
}

function isFieldWithOptions(fieldType: Field['type']): boolean {
  return ['radio', 'select'].includes(fieldType);
}

function optionsTuplesToString(tuples: OptionsTuples): string {
  return tuples.map((tuple) => tuple[0]).join('\n');
}

function optionsStringToTuples(string: string): OptionsTuples {
  return string.split(/[,\n]/).map((option) => {
    const normalizedOption = option.trim();
    return [normalizedOption, normalizedOption];
  });
}
