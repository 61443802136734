import classnames from 'classnames';
import { IconChoosePlanCheck } from 'components/base/BaseIcons';
import SideDrawer from 'components/base/SideDrawer';
import React, { Dispatch, Suspense, useCallback, useEffect, useState } from 'react';
import { ReactComponent as SvgSound } from '../../assets/images/icon-sound.svg';
import { ReactComponent as SvgMute } from '../../assets/images/icon-sound-mute.svg';
import { ReactComponent as SvgPlay } from '../../assets/images/icon-play.svg';
import { ReactComponent as SvgPause } from '../../assets/images/icon-pause.svg';
import { ReactComponent as SvgPrev } from '../../assets/images/icon-prev-video.svg';
import { ReactComponent as SvgNext } from '../../assets/images/icon-next-video.svg';

import {
  requestAllIfNeeded,
  setVideoElapsed,
  setVideoWatched,
  updateVideosWithMetadata,
} from '../../actions/videosActions';

import styles from './UnstackUniversity.module.scss';
import VideoPlayer from 'lazy/VideoPlayer';
import { OnMount } from 'hooks/mountUnmountHooks';
import { useDispatch, useSelector } from 'react-redux';
import { selectAll } from 'reducers/videosReducer';
import { UVideo } from 'types/UVideo';
import { Checkbox } from 'components/Checkbox';
import useActiveSite from 'hooks/useActiveSite';
import * as siteSelectors from '../../reducers/sitesReducer';
import * as siteActions from '../../actions/siteActions';
import throttle from 'lodash/throttle';
import usePrevious from 'hooks/usePrevious';

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
  width: number;
  setWidth: (width: number) => void;
};

function parseDuration(duration: string) {
  const durrArr = duration.split(':');
  const hours = Number(durrArr[0]) ? `${Math.round(Number(durrArr[0]))}h ` : '';
  const min = `${Math.round(Number(durrArr[1]))}m `;
  const s = `${Math.round(Number(durrArr[2]))}s`;
  return hours + min + s;
}

export default function (props: Props) {
  const dispatch: Dispatch<any> = useDispatch();
  const videos = useSelector(selectAll);

  const [activeVideo, setActiveVideo] = useState<UVideo>();
  const [playing, setPlaying] = useState(false);
  const [hovered, setHovered] = useState(false);
  const [initState, setInitState] = useState(true);
  const [initAllVideosWatched, setInitAllVideosWatched] = useState(false);
  const [muted, setMuted] = useState(false);
  const [elapsed, setElapsed] = useState(0);

  const site = useActiveSite();
  const autoShowUnstackUniversity = useSelector((state) => siteSelectors.getAutoShowUnstackUniversity(site));
  const [checked, setChecked] = useState(autoShowUnstackUniversity === undefined ? true : autoShowUnstackUniversity);

  OnMount(() => {
    if (site.metadata.unstackUniversityVideos) {
      dispatch(updateVideosWithMetadata(site.metadata.unstackUniversityVideos));
      if (Object.values(site.metadata.unstackUniversityVideos).every((video: any) => video.watched)) {
        setInitAllVideosWatched(true);
      }
    }

    dispatch(requestAllIfNeeded());
  });

  function videoRowClicked(video: UVideo) {
    if (initState) setInitState(false);
    if (video.id === activeVideo.id) {
      setPlaying(!playing);
    } else {
      setPlaying(false);
      setActiveVideo(video);
    }
  }

  function showNextVideo(e?: React.MouseEvent) {
    e?.stopPropagation();
    const idx = videos.findIndex((vid) => vid.id === activeVideo.id);
    if (idx < videos.length - 1) {
      if (videos.length !== idx + 1) {
        setElapsed(0);
        setActiveVideo(videos[idx + 1]);
        setPlaying(false);
      }
    }
  }

  function showPrevVideo(e?: React.MouseEvent) {
    e?.stopPropagation();
    const idx = videos.findIndex((vid) => vid.id === activeVideo.id);
    if (videos.length !== 0 && idx > 0) {
      setElapsed(0);
      setActiveVideo(videos[idx - 1]);
      setPlaying(false);
    }
  }

  function setWatchTime(video: UVideo, elapsed: number) {
    const currVideo = site.metadata.unstackUniversityVideos
      ? site.metadata.unstackUniversityVideos[video.id] || {}
      : {};
    if (elapsed > 0.85) {
      currVideo.watched = true;
      dispatch(setVideoWatched(video.id));
    }
    currVideo.elapsed = elapsed;
    dispatch(setVideoElapsed(video.id, elapsed));
    dispatch(
      siteActions.update({
        ...site,
        metadata: {
          ...site.metadata,
          unstackUniversityVideos: {
            ...site.metadata.unstackUniversityVideos,
            [video.id]: currVideo,
          },
        },
      })
    );
  }

  const setWatchTimeThrottled = useCallback(throttle(setWatchTime, 2000), [site, siteActions]);

  const handleClick = () => {
    setChecked(!checked);
    dispatch(
      siteActions.update({
        ...site,
        metadata: {
          ...site.metadata,
          auto_show_unstack_university: !checked,
        },
      })
    );
  };

  const playerRef = React.useRef();

  useEffect(() => {
    if (playerRef.current) {
      const elapsed = activeVideo?.elapsed || 0;
      const myInterval = setInterval(() => {
        if ((playerRef.current as any)?.getDuration()) {
          if (elapsed) (playerRef.current as any).seekTo(elapsed);
          clearInterval(myInterval);
          if (!initState) {
            setPlaying(true);
          }
        }
      }, 0.25);
    }
  }, [activeVideo, playerRef.current]);

  useEffect(() => {
    if (!activeVideo && site.metadata && videos) {
      setActiveVideo(videos[0]);
    }
  }, [videos, site.metadata]);

  function clickScrubber(event: React.MouseEvent<HTMLElement>) {
    event.stopPropagation();
    const boundingRect = (event.target as any).getBoundingClientRect();
    const distFromLeft = event.clientX - boundingRect.left;
    (playerRef.current as any).seekTo(distFromLeft / boundingRect.width);
  }

  return (
    <div>
      <SideDrawer
        isOpen={props.open}
        close={() => props.setOpen(false)}
        title="Elastic Path University"
        showResize
        width={props.width}
        setWidth={props.setWidth}
        avoidBackdrop
        persistOnBackdropClick
        render={() => {
          return (
            <div className={styles.unstackUniversity}>
              <div
                className={styles.videoContainer}
                onMouseEnter={() => setHovered(true)}
                onMouseLeave={() => setHovered(false)}
                onClick={() => setPlaying(!playing)}
              >
                <Suspense fallback={<div>Loading...</div>}>
                  <VideoPlayer
                    ref={playerRef}
                    url={activeVideo?.url}
                    className="react-player"
                    playing={playing}
                    width="100%"
                    height="100%"
                    controls={false}
                    onPause={() => setPlaying(false)}
                    onPlay={() => setPlaying(true)}
                    onHover
                    onEnded={() => {
                      showNextVideo();
                      setWatchTime(activeVideo, 1);
                    }}
                    onProgress={(e: { played: number }) => {
                      setElapsed(e.played);
                      if (e.played > 0) setWatchTimeThrottled(activeVideo, e.played);
                    }}
                    style={{ minHeight: 100 }}
                    muted={muted}
                  />
                  <div className={styles.videoControls}>
                    {initState && (
                      <button
                        className={styles.controlIcon}
                        onClick={(e) => {
                          e.stopPropagation();
                          setPlaying(true);
                          setInitState(false);
                        }}
                      >
                        <SvgPlay />
                      </button>
                    )}
                  </div>
                  {hovered && !initState && (
                    <>
                      <div className={styles.videoControlsOverlay} />
                      <span className={classnames(styles.videoIconContainer, styles.smallPrevIcon)}>
                        <SvgPrev
                          onClick={(e) => {
                            showPrevVideo(e);
                          }}
                        />
                      </span>
                      {!playing && (
                        <span className={classnames(styles.videoIconContainer, styles.smallPlayIcon)}>
                          <SvgPlay
                            onClick={(e) => {
                              e.stopPropagation();
                              setPlaying(true);
                            }}
                          />
                        </span>
                      )}
                      {playing && (
                        <span className={classnames(styles.videoIconContainer, styles.smallPauseIcon)}>
                          <SvgPause
                            onClick={(e) => {
                              e.stopPropagation();
                              setPlaying(false);
                            }}
                          />
                        </span>
                      )}
                      <span className={classnames(styles.videoIconContainer, styles.smallNextIcon)}>
                        <SvgNext
                          onClick={(e) => {
                            showNextVideo(e);
                          }}
                        />
                      </span>
                      {/* To be done in version 3: <SvgPopoutVideo className={classnames(styles.videoIcon, styles.smallPopoutIcon)} /> */}
                      <div className={styles.scrubber} onClick={clickScrubber}>
                        <div className={styles.scrubberBG}>
                          <div className={styles.scrubberInner} style={{ width: `${elapsed * 100}%` }} />
                        </div>
                      </div>
                    </>
                  )}
                  {!initState && hovered && !muted && (
                    <span className={classnames(styles.videoIconContainer, styles.soundIcon)}>
                      <SvgSound
                        onClick={(e) => {
                          e.stopPropagation();
                          setMuted(!muted);
                        }}
                      />
                    </span>
                  )}
                  {!initState && muted && (
                    <span className={classnames(styles.videoIconContainer, styles.soundIcon)}>
                      <SvgMute
                        onClick={(e) => {
                          e.stopPropagation();
                          setMuted(!muted);
                        }}
                      />
                    </span>
                  )}
                </Suspense>
              </div>
              <div className={styles.videoList}>
                {videos.map((video) => (
                  <div
                    className={classnames(
                      styles.row,
                      { [styles.unwatched]: !video.watched },
                      { [styles.active]: video.id === activeVideo?.id }
                    )}
                    onClick={() => videoRowClicked(video)}
                  >
                    {playing && video.id === activeVideo?.id && <SvgPause />}
                    {!playing && video.id === activeVideo?.id && <SvgPlay />}
                    {video.id !== activeVideo?.id && video.watched && <IconChoosePlanCheck className={styles.check} />}
                    {video.id !== activeVideo?.id && !video.watched && <SvgPause style={{ opacity: 0 }} />}

                    <div className={styles.videoTitle}>{video.title}</div>
                    <div className={styles.videoLength}>{parseDuration(video.duration)}</div>
                  </div>
                ))}
              </div>
              <div className={styles.autoHideContainer}>
                <Checkbox checked={checked} height={undefined} onClick={handleClick} />{' '}
                <span onClick={handleClick}>Auto-show help panel</span>
              </div>
            </div>
          );
        }}
        darkTheme
      />
    </div>
  );
}
