import React from 'react';

import Select from '../../Select';
import Search from '../../Search';

import { ReactComponent as SvgIcon } from '../../../assets/images/icon-filter.svg';
import { ReactComponent as SvgIconAdd } from '../../../assets/images/icon-plus.svg';

import styles from './ContactsFilter.module.scss';

export const contactFilterOptions = [
  { value: '', label: 'all customers' },
  { value: 'new', label: 'new customers' },
  {
    value: 'visits',
    label: 'customers who are repeat visitors',
  },
  { value: 'last_seen', label: 'customers who are slipping away' },
];

export default ({ debounceValue, filter, onChange, searchTerm, setFilter, setSearchResultPageNumber, openDrawer }) => (
  <div className={styles.filtersContainer}>
    <div className={styles.filter} data-test-id="filter">
      <div className={styles.filterIcon}>
        <SvgIcon />
      </div>
      Show&nbsp;
      <Select
        options={contactFilterOptions}
        isInline
        defaultValue={contactFilterOptions[0]}
        value={filter}
        onChange={(option) => {
          setFilter(option);
          setSearchResultPageNumber(1);
        }}
      />
    </div>
    <div className={styles.searchFieldContainer}>
      <Search
        value={searchTerm}
        onChange={(value) => {
          onChange(value);
          setSearchResultPageNumber(1);
        }}
        placeholder="Search by name or email"
        debounceValue={(value) => {
          if (value !== undefined) debounceValue(value);
        }}
        containerStyle={{
          marginTop: 0,
          marginRight: '1rem',
        }}
      />
      <div onClick={openDrawer} className={styles.newContactButton}>
        <SvgIconAdd height={15} width={15} />
        <span data-test-id="new-contact">New customer</span>
      </div>
    </div>
  </div>
);
