import * as actionTypes from './actionTypes';

export function requestAll(siteId) {
  return {
    type: actionTypes.TEMPLATES_REQUEST_ALL,
    payload: { siteId },
  };
}

export function requestAllIfNeeded() {
  return {
    type: actionTypes.TEMPLATES_REQUEST_ALL_IF_NEEDED,
  };
}

export function receiveAll(templates) {
  return {
    type: actionTypes.TEMPLATES_RECEIVE_ALL,
    payload: { templates },
  };
}

export function requestClone(pageId) {
  return {
    type: actionTypes.TEMPLATES_REQUEST_CLONE,
    payload: { pageId },
  };
}

export function receiveCloneSuccess(pageId) {
  return {
    type: actionTypes.TEMPLAETS_RECEIVE_CLONE_SUCCESS,
    payload: { pageId },
  };
}

export function receiveCloneError(errors) {
  return {
    type: actionTypes.TEMPLAETS_RECEIVE_CLONE_ERROR,
    payload: { errors },
  };
}

export function create(payload, res, rej) {
  return {
    type: actionTypes.TEMPLATES_REQUEST_CREATE,
    payload,
    res,
    rej,
  };
}

export function update(payload) {
  return {
    type: actionTypes.TEMPLATES_REQUEST_UPDATE,
    payload,
  };
}

export function createSuccess(payload) {
  return {
    type: actionTypes.TEMPLATES_REQUEST_CREATE_SUCCESS,
    payload,
  };
}

export function updateSuccess(payload) {
  return {
    type: actionTypes.TEMPLATES_REQUEST_UPDATE_SUCCESS,
    // payload,
  };
}

export function remove(payload) {
  return {
    type: actionTypes.TEMPLATES_REQUEST_REMOVE,
    payload,
  };
}
