import Modal from 'components/base/Modal';
import React, { useEffect, useState } from 'react';
import styles from './ConnectedModal.module.scss';
import * as integrationActions from '../../../actions/integrationActions';
import { useDispatch } from 'react-redux';
import useActiveSite from 'hooks/useActiveSite';
const ConnectedModal = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [connectionResponse, setConnectionResponse] = useState(null);
  const siteId = useActiveSite((site) => site.id);
  const dispatch = useDispatch();

  useEffect(() => {
    window.addEventListener(
      'message',
      (event) => {
        if (window.location.origin === event.origin && event.source.location.pathname === '/integrations/oauth') {
          setConnectionResponse(event.data);
          setIsOpen(true);
          dispatch(integrationActions.requestAll(siteId));
        }
      },
      false
    );
  }, []);
  const close = () => setIsOpen(false);
  return (
    <Modal isOpen={isOpen} close={close}>
      <div className={styles.modal}>
        <div className={styles.contentContainer}>
          <div>
            <img src={connectionResponse?.icon} width={64} height={64} />
          </div>
          <div>
            <p className={styles.title}>{connectionResponse?.title}</p>
            <p className={styles.message}>{connectionResponse?.message}</p>
          </div>
        </div>
        <div className={styles.buttonContainer}>
          <button onClick={close} className="button button-secondary">
            Close
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ConnectedModal;
