import { SectionHandlersInterface } from 'components/unstack-components/types';
import React from 'react';
import { UBoxInfo, USectionDevices } from 'types/USection';
import Actions from '../inline/Actions';
import RichText from '../inline/RichText';
import Box from './Box';

import styles from './SectionHeaderBox.module.scss';

type Props = {
  content: USectionDevices;
  dataRef: UBoxInfo;
  onChange: (content: USectionDevices, key?: string, multi?: boolean) => void;
  sectionHandlers: SectionHandlersInterface;
  contentKey: string;
};

export function SectionHeaderBox(props: Props) {
  const { content, dataRef, onChange, sectionHandlers, contentKey } = props;
  return (
    <Box
      content={content}
      dataRef={(dataRef as any)?.header?.textBox}
      defaults={{
        defaultAlignment: 'center',
        defaultPaddingBottom: 6,
        defaultPaddingLeft: 0,
        defaultPaddingRight: 0,
        defaultPaddingTop: 0,
        defaultPaddingBottomMobile: 0,
        defaultPaddingLeftMobile: 0,
        defaultPaddingRightMobile: 0,
        defaultPaddingTopMobile: 0,
        defaultMaxWidth: 600,
        defaultMaxWidthMobile: 300,
        enableTextAlignment: true,
        defaultTextAlignment: 'center',
      }}
      onChange={onChange}
      el={'h1'}
      contentKey={`${contentKey}.header.textBox`}
      className={`${styles.sectionHeaderBox} section-header-box`}
    >
      <RichText
        defaults={{}}
        content={content}
        onChange={onChange}
        dataRef={(dataRef as any)?.header?.text}
        contentKey={`${contentKey}.header.text`}
        startTag={'h2'}
        placeholder={'Add rich text'}
      />
      <Actions
        content={content}
        onChange={onChange}
        dataRef={(dataRef as any)?.header?.actions}
        contentKey={`${contentKey}.header.actions`}
        sectionHandlers={sectionHandlers}
        containerInfo={undefined}
      />
    </Box>
  );
}
