import React, { useState, useEffect } from 'react';

import { Link, useNavigate, useParams } from 'react-router-dom';
import { connect, useDispatch, useSelector } from 'react-redux';
import { selectHasPermission } from '../../reducers/policyReducer';

import SideDrawer from '../base/SideDrawer';
import FormControl from '../base/FormHelpers/FormControl';
import SlugFormControl from '../base/FormHelpers/SlugFormControl';
import PrefixedInput from '../base/FormHelpers/PrefixedInput';

import styles from './NewCategory.module.scss';
import { selectAllCategories } from '../../reducers/categoriesReducer';
import { compose } from 'redux';
import withBlogs from '../../containers/withBlogs';
import { getFullDomainFromSite } from '../../reducers/sitesReducer';
import withSite from '../../containers/withSite';
import { update, remove } from '../../actions/categoriesActions';
import useUrlSite from '../../hooks/useUrlSite';
import isEmpty from 'lodash/isEmpty';
import NonFieldErrors from 'components/base/FormHelpers/NonFieldErrors';
import { USite } from 'types/USite';

type Props = {
  isOpen: boolean;
  close: () => void;
  blogsById: any;
  site: USite;
};

const CategorySettings = (props: Props) => {
  const [selectCategory, setSelectCategory] = useState<{ [key: string]: any }>({});
  const [errors, setErrors] = useState<any>();
  const [globalError, setGlobalError] = useState<any>();
  const urlSite = useUrlSite();
  const navigate = useNavigate();
  const categoriesList = useSelector(selectAllCategories);
  const hasPermission = useSelector(selectHasPermission);
  const dispatch = useDispatch();
  const { isOpen, close, blogsById } = props;
  const { blogId, categoryId } = useParams();

  const blog = blogsById[selectCategory.blog] || {};

  const blogSlug =
    blog.config && blog.config.urls.category === 'blog/category' ? `${blogsById[selectCategory.blog].slug}/` : '';

  const handleChange = (e: any) => {
    if (e.target.name === 'id') {
      const selected = categoriesList.find((category: any) => category.id === e.target.value);
      setSelectCategory(selected || {});
      setErrors(undefined);
      setGlobalError(undefined);
      navigate(`/${urlSite}/blogs/${blogId}/categories/${e.target.value}`);
    } else if (e.target.name === 'meta.title')
      setSelectCategory({
        ...selectCategory,
        meta: { ...selectCategory.meta, title: e.target.value },
      });
    else if (e.target.name === 'meta.description')
      setSelectCategory({
        ...selectCategory,
        meta: { ...selectCategory.meta, description: e.target.value },
      });
    else setSelectCategory({ ...selectCategory, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    setSelectCategory(categoriesList.find((category: any) => category.id === categoryId) || {});
  }, [categoryId]);

  const handleSubmit = (e: any) => {
    e.preventDefault();

    // Validate
    const errors: { [key: string]: any } = {};
    if (!selectCategory.blog) {
      errors.blog = 'Required';
    }

    if (!isEmpty(errors)) {
      errors.msg = errors.msg || 'Please see errors above';
      setErrors(errors);
      return;
    }

    // Submit
    if (
      window.confirm(
        "Are you sure you want to do this? This will change your URL structure and 301 redirect content. This could hurt SEO if you don't intend to do this"
      )
    ) {
      const promise = new Promise((resolve, reject) => {
        dispatch(update(selectCategory, resolve, reject));
      });
      promise
        .then(() => {
          setSelectCategory({});
          setErrors(undefined);
          setGlobalError(undefined);
          close();
        })
        .catch((err) => setErrors(err));
    }
  };

  const hasPermissionToEdit = hasPermission('Category:update');

  return (
    <SideDrawer
      isOpen={isOpen}
      disableLocationClosing
      close={() => {
        setSelectCategory({});
        setErrors(undefined);
        setGlobalError(undefined);
        close();
      }}
      title="Category settings"
      render={() => {
        return (
          <>
            {categoriesList.length ? (
              <form onSubmit={handleSubmit}>
                <fieldset>
                  <label htmlFor="category_input">Category</label>
                  <FormControl
                    id="category_input"
                    tag="select"
                    name="id"
                    value={selectCategory.id || ''}
                    onChange={handleChange}
                  >
                    <option value="" />
                    {categoriesList.map((category: any) => (
                      <option key={category.id} value={category.id}>
                        {category.name}
                      </option>
                    ))}
                  </FormControl>
                  <small>Select a category to edit.</small>
                </fieldset>
                {selectCategory.id && (
                  <>
                    <fieldset>
                      <label htmlFor="name_input">Name</label>
                      <FormControl
                        id="name_input"
                        type="text"
                        name="name"
                        value={selectCategory.name || ''}
                        onChange={handleChange}
                        disabled={!hasPermissionToEdit}
                      />
                    </fieldset>
                    <fieldset>
                      <label htmlFor="title_input">Title</label>
                      <FormControl
                        id="title_input"
                        type="text"
                        name="meta.title"
                        value={selectCategory.meta.title || ''}
                        onChange={handleChange}
                        disabled={!hasPermissionToEdit}
                      />
                    </fieldset>
                    <fieldset>
                      <FormControl
                        tag="select"
                        aria-label="related blog"
                        name="blog"
                        onChange={handleChange}
                        value={selectCategory.blog || ''}
                        disabled={!hasPermissionToEdit}
                        errors={errors && errors.blog}
                      >
                        <option value="">Select related blog</option>
                        {Object.values(blogsById).map((blog: any) => (
                          <option key={blog.id} value={blog.id}>
                            {blog.name}
                          </option>
                        ))}
                      </FormControl>
                    </fieldset>
                    <fieldset>
                      <label htmlFor="category_url_path_input">Category URL path</label>
                      <SlugFormControl
                        id="category_url_path_input"
                        tag={PrefixedInput}
                        prefix={blogSlug}
                        type="text"
                        name="slug"
                        value={selectCategory.slug}
                        onChange={(name: string, value: any) =>
                          setSelectCategory({
                            ...selectCategory,
                            [name]: value,
                          })
                        }
                        disabled={!hasPermissionToEdit}
                      />
                    </fieldset>
                    <fieldset>
                      <label htmlFor="description_input">Category description</label>
                      <FormControl
                        id="description_input"
                        tag="textarea"
                        type="text"
                        name="meta.description"
                        value={selectCategory.meta.description || ''}
                        onChange={handleChange}
                        disabled={!hasPermissionToEdit}
                      />
                    </fieldset>
                    <fieldset>
                      <NonFieldErrors errors={errors} />
                      <input
                        type="submit"
                        className="button button-full"
                        value="Update"
                        disabled={!hasPermissionToEdit}
                      />
                      <input
                        onClick={() => {
                          const confirmation = window.confirm('Are you sure you want to remove this category?');
                          if (confirmation || (window as any).top.Cypress) {
                            const promise = new Promise((resolve, reject) => {
                              dispatch(remove(selectCategory, resolve, reject));
                            });
                            promise
                              .then(() => {
                                setSelectCategory({});
                                setErrors(undefined);
                                setGlobalError(undefined);
                                close();
                              })
                              .catch((err) => setGlobalError(err));
                          }
                        }}
                        className="button button-danger button-full"
                        value="Delete"
                        readOnly
                        disabled={!hasPermissionToEdit}
                      />
                      <small className="errorMessage" data-test-id="error">
                        {globalError}
                      </small>
                    </fieldset>
                  </>
                )}
              </form>
            ) : (
              <>
                <h3>You don't have any blog categories yet.</h3>
                <p>
                  Create categories to organize your posts and build up your SEO. Categories can be used to set up your
                  blog URLs and create Dynamic Category pages that list out specific content.
                </p>
              </>
            )}
            {hasPermission('Category:create') && (
              <footer>
                <p className={styles.actionLink}>
                  <Link to={`/${urlSite}/article/category`} role="link">
                    Create new category
                  </Link>
                </p>
              </footer>
            )}
          </>
        );
      }}
    />
  );
};

export default compose(withBlogs, withSite)(CategorySettings);
