import { call, put, all, takeLatest, select } from 'redux-saga/effects';
import * as api from '../services/spark-api';
import * as types from '../actions/actionTypes';
import { receive as receiveSections } from '../actions/sectionActions';
import * as pageSelectors from '../reducers/pagesReducer';
import requireSiteId from './utils/requireSiteId';
import { selectChildren } from 'reducers/sectionsReducer';
import { omit } from 'lodash';

function getUpdatedContent(content, response, itemType) {
  return (
    {
      ...content,
      base: {
        ...{ ...content?.base, ...response.json?.content.base },
        ...(itemType === 'product'
          ? { product: { id: '{{ product.id }}' } }
          : itemType === 'collection' && {
              products: {
                filters: { collection: '{{ category.id }}' },
                limit: 50,
                type: 'filtered',
              },
            }),
      },
      sm: { ...response.json?.content.sm },
      md: { ...response.json?.content.md },
      lg: { ...response.json?.content.lg },
    } ||
    response.json?.content ||
    {}
  );
}

function* cloneContainer(payload, childSections, siteId, callback) {
  try {
    let response = yield call(api.createSection, {
      site: siteId,
      item: payload.item || payload.pageId,
      position: (payload.position || 1) + 1,
      component: payload.component,
      location: payload.location || 'main',
    });
    let container;
    if (response.ok) {
      container = response.json;
      const children = [];
      for (const idx in childSections) {
        const childResponse = yield call(api.createSection, {
          site: siteId,
          item: childSections[idx].item || childSections[idx].pageId,
          position: childSections[idx].position,
          component: childSections[idx].component,
          location: payload.location || 'main',
        });
        if (childResponse.ok) children[idx] = childResponse.json;
      }
      container.children = children.map((child) => child.id);
      const newSections = {
        sections: {
          ...children.reduce((acc = {}, currentValue, i) => {
            acc[currentValue.id] = {
              ...childSections[i],
              ...currentValue,
              content: childSections[i].content ? childSections[i].content : currentValue.content,
            };
            return acc;
          }, {}),
        },
      };

      yield put(receiveSections(newSections));
      yield put({
        type: types.SECTIONS_ADD,
        payload: {
          ...payload,
          ...container,
          position: payload.position,
        },
      });
      callback && callback(container.id);
      yield put({ type: types.PAGE_TOUCH, payload: { id: payload.item } });
    }
  } catch (error) {
    console.error(error);
  }
}

function* create(action) {
  const siteId = yield call(requireSiteId, action.payload.siteId);
  const { payload } = action;
  const children = yield select((state) => selectChildren(state, payload.id));
  const pageId = payload.item || payload.pageId;
  if (children && children.length) yield cloneContainer(payload, children, siteId, action.callback);
  else
    try {
      const itemObject = yield select((state) => pageSelectors.selectPage(state, pageId));
      const response = yield call(api.createSection, {
        site: siteId,
        item: pageId,
        position: (payload.position || 1) + 1,
        component: payload.component,
        element: payload.element,
        location: payload.location || 'main',
      });

      if (response.ok) {
        yield put({
          type: types.SECTIONS_ADD,
          payload: {
            ...omit(payload, ['pageId']),
            ...response.json,
            ...(itemObject?.item_type === 'product' && {
              product: { id: '{{ product.id }}' },
            }),
            ...(itemObject?.item_type === 'collection' && {
              products: {
                filters: { collection: '{{ category.id }}' },
                type: 'filtered',
              },
            }),
            item: pageId,
            content: getUpdatedContent(payload.content, response, itemObject?.item_type),
            position: payload.position,
          },
        });
        yield put({ type: types.PAGE_TOUCH, payload: { id: pageId, meta: { showAddSectionTooltip: false } } });
        action.callback && action.callback(response.json.id);
      }
    } catch (e) {
      console.error(e);
    }
}

function* createElement(action) {
  const siteId = yield call(requireSiteId, action.payload.siteId);
  const { sectionId, name } = action.payload;

  try {
    const response = yield call(api.createElement, siteId, sectionId, name);

    if (response.ok) {
      yield put({
        type: 'CREATE_RESOURCES_SUCCEEDED',
        resourceType: 'elements',
        requestKey: 'create',
        resources: [response.json],
        requestProperties: {
          statusCode: response.status,
        },
      });
    } else {
      console.error(response.json);
    }
  } catch (e) {
    console.error(e);
  }
}

// Root saga
// ----------------------------------------------------------------------------

function* sectionsSaga() {
  yield all([
    takeLatest(types.SECTIONS_REQUEST_CREATE, create),
    takeLatest(types.SECTIONS_CREATE_ELEMENT, createElement),
  ]);
}

export default sectionsSaga;
