import React, { useState } from 'react';

import snakeCase from '../../../../../lib/snakeCase';
import { Checkbox } from '../../../../Checkbox';
import FormControl from '../../../../base/FormHelpers/FormControl';
import ValidationMessage from '../../../../base/FormHelpers/ValidationMessage';

import styles from './Form.module.scss';

const FORM_FIELD_BLACKLIST = ['cc', 'card'];

const FORM_FIELD_WILDCARDS = [
  'cvv',
  'cvc',
  'routing',
  'bank',
  'credit',
  'cardnumber',
  'accountnumber',
  'account#',
  'acct#',
];

export const checkValidity = ({ label, placeholder, name }) => {
  const re = new RegExp('[^a-z#]', 'g');
  const normalizedValues = [label, placeholder, name].map((inputVal) => inputVal.toLowerCase().replace(re, ''));

  return (
    normalizedValues
      .map((normVal) => FORM_FIELD_BLACKLIST.some((testVal) => normVal && normVal === testVal))
      .some((testRes) => testRes) ||
    normalizedValues
      .map((normVal) => FORM_FIELD_WILDCARDS.some((testVal) => normVal && normVal.includes(testVal)))
      .some((testRes) => testRes)
  );
};

const getOptionsInput = (field, update) => {
  return (
    ['radio', 'select'].includes(field.type) && (
      <fieldset>
        <label>Options</label>
        <FormControl tag="textarea" name="options" value={field.options} onChange={(e) => update(e, field)} />
        <div className={`${styles.deleteActionLink} ${styles.helpText}`}>
          <p>Separate each option with a ","</p>
        </div>
      </fieldset>
    )
  );
};

export const EditField = ({ update, field, fieldError, undoFieldChanges, securityError }) => {
  const [oldData] = useState(field);
  return (
    <>
      <fieldset>
        <label>Label</label>
        <FormControl
          type="text"
          name="label"
          value={field.label}
          onChange={(e) => update(e, field)}
          onBlur={
            !field.name && field.label
              ? (e) => {
                  const fakeEvent = {
                    target: {
                      name: 'name',
                    },
                  };
                  update(fakeEvent, field, snakeCase(e.target.value));
                }
              : () => {}
          }
        />
      </fieldset>
      <fieldset>
        <label>Placeholder text</label>
        <FormControl
          type="text"
          name="placeholder"
          value={field.placeholder}
          onChange={(e) => update(e, field)}
          onBlur={
            !field.name && field.placeholder
              ? (e) => {
                  const fakeEvent = {
                    target: {
                      name: 'name',
                    },
                  };
                  update(fakeEvent, field, snakeCase(e.target.value));
                }
              : () => {}
          }
        />
        {field.type === 'select' && (
          <div className={`${styles.deleteActionLink} ${styles.helpText}`}>
            <p>Leave blank if you want your first option selected by default</p>
          </div>
        )}
      </fieldset>
      {field.id < 100 && getOptionsInput(field, update)}
      <fieldset>
        <label>Help text</label>
        <FormControl type="text" name="help_text" value={field.help_text} onChange={(e) => update(e, field)} />
      </fieldset>
      <fieldset className={styles.required}>
        <label
          name="required"
          onClick={(e) => {
            e = {
              target: {
                name: 'required',
                value: !field.required,
              },
            };
            update(e, field);
          }}
        >
          <Checkbox checked={field.required} height={18} />
          Make this field required
        </label>
      </fieldset>
      <fieldset>
        <label>Name</label>
        <FormControl
          type="text"
          name="name"
          value={field.name}
          disabled={field.id > 100 ? 'disabled' : ''}
          onChange={(e) => update(e, field, snakeCase(e.target.value))}
        />
        <small>This will be used to label responses in your Customers.</small>
        {fieldError && <ValidationMessage message="Name cannot be empty and must be unique" />}
        {Boolean(!fieldError && securityError) && (
          <small className="errorMessage">
            Payment information is not allowed to be collected to prevent possible security threat to your users!
          </small>
        )}
      </fieldset>
      <fieldset>
        <p>
          <a
            href="/"
            onClick={(e) => {
              e.preventDefault();
              undoFieldChanges(oldData);
            }}
          >
            Back
          </a>
        </p>
      </fieldset>
    </>
  );
};
