import React, { useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useContactsDataPerPage } from './hooks';
import NotificationPermission from './NotificationPermission';
import ZeroState from './ContactsListZeroState';
import ZeroSearchResults from './ContactsNoSearchResult';
import withQueryParams from 'containers/withQueryParams';

import { selectHasPermission } from '../../../reducers/policyReducer';
import { selectIntegrationLists } from '../../../reducers/integrationsReducer';
import { selectCurrentAccountUser } from '../../../reducers/accountUsersReducer';
import useActiveSite from '../../../hooks/useActiveSite';

import DocumentTitle from '../../DocumentTitle';
// import Controls from './Controls';
import Spinner from '../../base/Spinner';
import ContactsList from './ContactsList';
import ContactsFilter, { contactFilterOptions } from './ContactsFilter';
import AddContact from './AddContact';
import { useLocation } from 'react-router-dom';

// import { useFilterContextProvider } from '../Insights/report-hooks';

//  Need to add status filters in HoC
export default withQueryParams(
  function ContactsIndex(props) {
    const siteId = useActiveSite((site) => site.id);
    const {
      queryParams: { search, page = 1, status = contactFilterOptions[0].value },
      updateQueryParams,
    } = props;
    const [searchTerm, setSearchTerm] = useState(undefined);
    const [searchResultPageNumber, setSearchResultPageNumber] = useState(1);
    const [open, setOpen] = useState(false);
    const [refreshFlag, setRefreshFlag] = useState(true);
    const owner = useSelector((state) => selectCurrentAccountUser(state));
    const location = useLocation();

    // Combine the user-seleceted filter with filters form the query string
    const combinedFilters = useMemo(() => {
      // Extract whitelisted filters from the page URL
      const regex = /(?:utm_source|utm_medium|utm_campaign|utm_content|utm_term|form_id)=[^&\s]+/gi;
      const queryFilters = location.search.match(regex);

      // Combine them with the user-selected filter
      const filters = queryFilters || ([] as string[]);
      if (status) filters.push(`status=${status}`);

      // Join them into a string separated by '&'
      return filters.join('&');
    }, [location.search, status]);

    const [data, isLoading] = useContactsDataPerPage({
      pageNumber: page,
      searchTerm: search,
      filter: combinedFilters,
      searchResultPageNumber: searchResultPageNumber,
      refreshFlag: refreshFlag,
      // @ts-ignore
      siteId: siteId,
    });
    const hasPermission = useSelector((state) => selectHasPermission(state));
    const integrationsLists = useSelector((state) => selectIntegrationLists(state));
    const dataAsObj = data as {
      results: any[];
      count: any;
    };

    const hasData = data && dataAsObj.results && dataAsObj.results.length > 0;
    if (!hasData && isLoading && !searchTerm) return <Spinner className="fixed" />;

    // const [FilterProvider, filterValue] = useFilterContextProvider();

    const renderedContent = hasData ? (
      <ContactsList
        data={dataAsObj.results}
        count={dataAsObj.count}
        isLoading={isLoading}
        activePage={page}
        setPageNumber={(page: number) => updateQueryParams({ page })}
        searchTerm={searchTerm}
      />
    ) : !searchTerm && !status ? (
      <ZeroState />
    ) : (
      <ZeroSearchResults search={searchTerm} />
    );

    if (!hasPermission('Contact')) {
      return hasPermission('Account') ? (
        <p>You don't have permission to access customers.</p>
      ) : (
        <Spinner className="fixed" />
      );
    }

    return (
      <>
        <DocumentTitle title={(siteName: string) => `${siteName}'s customers`} />
        <section>
          <NotificationPermission />
          <header>
            <h1>Customers</h1>
            <small>Keep track of those who interact with your content.</small>
            <div style={{ clear: 'both' }}></div>
          </header>
          <ContactsFilter
            debounceValue={(search: string) => updateQueryParams({ search, page: 1 })}
            filter={contactFilterOptions.find((f) => f.value === status)}
            onChange={setSearchTerm}
            searchTerm={searchTerm === undefined ? search : searchTerm}
            setFilter={(filter: { [key: string]: any }) => updateQueryParams({ status: filter.value })}
            setSearchResultPageNumber={setSearchResultPageNumber}
            openDrawer={() => setOpen(true)}
          />
          {renderedContent}
        </section>
        {owner && (
          <AddContact
            integrationsLists={integrationsLists}
            isOpen={open}
            setOpen={setOpen}
            refreshFlag={refreshFlag}
            updateRefreshFlag={setRefreshFlag}
            owner={owner.id}
          />
        )}
      </>
    );
  },
  { itemType: undefined, panel: undefined }
);
