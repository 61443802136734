import React from 'react';
import { Button } from '../Toolbar2';

export default function ButtonWithOptions(props) {
  const { value, onChange, options, active, ...buttonProps } = props;

  const index = options.findIndex((option) => option.value === value);
  const activeOption = options[index];

  const nextIndex = (index + 1) % options.length;
  const nextValue = options[nextIndex].value;

  return (
    <Button onClick={(e) => onChange(nextValue)} {...buttonProps} style={{ width: '40px', padding: '13px' }}>
      <activeOption.IconComponent active={active} />
    </Button>
  );
}
