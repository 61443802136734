import { schema, normalize } from 'normalizr';
import { UVideo } from 'types/UVideo';

const video = new schema.Entity('videos');

const videos = [video];

export function normalizeVideosResponse(data: UVideo[]) {
  return normalize(data, videos);
}
