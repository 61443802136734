import React from 'react';

interface IWistiaEmbedProps {
  entity: {
    rawSrc: String;
    src: String;
    type?: String;
  };
}

export const WistiaEmbed = (props: IWistiaEmbedProps): React.ReactNode => {
  const { entity } = props;

  const match: Array<any> = entity.src.match(/^wistia:(.+)/);
  if (!match) return null;
  const videoId: String = match[1];

  return (
    <div>
      <iframe
        allow="encrypted-media"
        allowFullScreen
        src={`//fast.wistia.net/embed/iframe/${videoId}`}
        style={{
          width: '100%',
          height: '100%',
        }}
        scrolling="no"
        title="wistia"
      />
    </div>
  );
};
