import React, { useState } from 'react';
import pick from 'lodash/pick';
import { useSelector } from 'react-redux';

import { selectAllSorted } from '../../../../reducers/accountUsersReducer';
import useUrlSite from '../../../../hooks/useUrlSite';

import SideDrawer from '../../../base/SideDrawer';
import FormControl from '../../../base/FormHelpers/FormControl';

import styles from './ContactDetail.module.scss';
import { promoptConformation } from 'lib/promp-confirmation';

export default ({ contact, isOpen, setDrawerType, updateContact, deleteContact, history, setTemp }) => {
  const urlSite = useUrlSite();
  const accountUsers = useSelector((state) => selectAllSorted(state));
  const [contactData, setContactData] = useState(contact);
  const [errors, setErrors] = useState({});

  const handleClickDelete = () => {
    promoptConformation('deleteContact', () => {
      const promise = new Promise((resolve, reject) => {
        deleteContact(contact.id, resolve, reject);
      });
      promise
        .then(() => {
          setDrawerType('');
          history.push(`/${urlSite}/customers`);
        })
        .catch((err) => console.error(err));
    });
  };

  return (
    <SideDrawer
      isOpen={isOpen}
      title="Update customer"
      close={() => {
        setDrawerType('');
        setErrors({});
      }}
    >
      <fieldset className={styles.fieldsetOptionalStyles}>
        <label htmlFor="first_name_input">First name</label>
        <FormControl
          id="first_name_input"
          name="first_name"
          value={contactData.first_name || ''}
          onChange={(e) => setContactData({ ...contactData, [e.target.name]: e.target.value })}
          errors={errors.first_name && errors.first_name[0]}
          autoComplete="off"
          type="text"
          placeholder="First name"
        />
      </fieldset>
      <fieldset className={styles.fieldsetOptionalStyles}>
        <label htmlFor="last_name_input">Last name</label>
        <FormControl
          id="last_name_input"
          name="last_name"
          value={contactData.last_name || ''}
          onChange={(e) => setContactData({ ...contactData, [e.target.name]: e.target.value })}
          errors={errors.last_name && errors.last_name[0]}
          autoComplete="off"
          type="text"
          placeholder="Last name"
        />
      </fieldset>
      <fieldset className={styles.fieldsetOptionalStyles}>
        <label htmlFor="email_input">Email address</label>
        <FormControl
          id="email_input"
          name="email"
          value={contactData.email || ''}
          onChange={(e) => setContactData({ ...contactData, [e.target.name]: e.target.value })}
          errors={errors.email && errors.email[0]}
          autoComplete="off"
          type="email"
          placeholder="Email address"
          disabled
        />
      </fieldset>
      <fieldset className={styles.fieldsetOptionalStyles}>
        <label htmlFor="company_input">Company</label>
        <FormControl
          id="company_input"
          name="organization"
          value={contactData.organization || ''}
          onChange={(e) => setContactData({ ...contactData, [e.target.name]: e.target.value })}
          errors={errors.organization && errors.organization[0]}
          autoComplete="off"
          type="text"
          placeholder="Company"
        />
      </fieldset>
      <fieldset className={styles.fieldsetOptionalStyles}>
        <label htmlFor="phone_input">Phone</label>
        <FormControl
          id="phone_input"
          name="phone"
          value={contactData.phone || ''}
          onChange={(e) => setContactData({ ...contactData, [e.target.name]: e.target.value })}
          errors={errors.phone && errors.phone[0]}
          autoComplete="off"
          type="text"
          placeholder="Phone"
        />
      </fieldset>
      <fieldset className={styles.fieldsetOptionalStyles}>
        <label htmlFor="owner_input">Owner</label>
        <FormControl
          id="owner_input"
          name="owner"
          onChange={(e) => setContactData({ ...contactData, [e.target.name]: e.target.value })}
          errors={errors.owner && errors.owner[0]}
          value={contactData.owner || ''}
          autoComplete="off"
          tag="select"
          placeholder="Owner"
        >
          <option value="">Select owner</option>
          {accountUsers.map((user) => (
            <option key={user.id} value={user.id}>
              {user.name || user.email}
            </option>
          ))}
        </FormControl>
      </fieldset>
      <fieldset className={styles.fieldsetOptionalStyles}>
        <label htmlFor="website_input">Website</label>
        <FormControl
          id="website_input"
          name="website"
          value={contactData.website || ''}
          onChange={(e) => setContactData({ ...contactData, [e.target.name]: e.target.value })}
          errors={errors.website && errors.website[0]}
          autoComplete="off"
          type="text"
          placeholder="Website"
        />
      </fieldset>
      <fieldset className={styles.fieldsetOptionalStyles}>
        <input
          aria-label="submit_contact"
          readOnly
          value={contactData.saving ? `Updating Customer...` : `Update customer`}
          onChange={(e) => setContactData({ ...contactData, [e.target.name]: e.target.value })}
          className="button button-primary button-full"
          onClick={
            contactData.saving
              ? () => {}
              : () => {
                  setContactData({ ...contactData, saving: true });
                  const promise = new Promise((resolve, reject) => {
                    updateContact(contactData, resolve, reject);
                  });
                  promise
                    .then(() => {
                      setDrawerType('');
                      setContactData({ ...contactData, saving: false });
                      setTemp({ id: contact.id });
                    })
                    .catch((err) => {
                      setErrors(err);
                    })
                    .finally(() => setContactData({ ...contactData, saving: false }));
                }
          }
        />
      </fieldset>
      <p className={styles.colorDeleteActionText}>
        You can also <span onClick={handleClickDelete}>delete this customer</span>.
      </p>
    </SideDrawer>
  );
};
