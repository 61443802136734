/* eslint-disable jsx-a11y/anchor-is-valid */

import React from 'react';
import SiteLogo from '../../../Editor/SiteLogo';
import HeaderMenuItems from './HeaderEditor/HeaderMenuItems';
import ActionMenuItem from './HeaderEditor/ActionMenuItem';
import Toolbar from './HeaderToolbar';
import LogoUploader from './LogoUploader';
import ShopifyCartIcon from './HeaderEditor/shopifyCartIcon';

import idx from 'lodash/get';
import classnames from 'classnames';
import styles from '../HeaderFooterEditor.module.scss';

function HeaderEditor(props) {
  const { site, editableLogo, siteUpdaters } = props;

  const propPath = ['menus', 'header'];

  const items = idx(site, 'menus.header.items', []);
  const secondary_items = idx(site, 'menus.header.secondary_items', []);
  const action_item = idx(site, 'menus.header.action_item');
  const action_style = idx(site, 'styles.list.header_menu.action_style');
  const headerMenuLayout = idx(site, 'styles.headerMenuLayout', 'right');
  const brightnessTheme = idx(site, 'styles.headerBrightnessTheme');
  const header_padding = idx(site, 'styles.header_padding');

  return (
    <div className="header-container">
      <header
        className={classnames(
          'primary-header',
          idx(site, 'styles.headerBrightnessTheme'),
          `header-menu-layout-${headerMenuLayout}`,
          `pt-${header_padding}`,
          `pb-${header_padding}`,
          styles.headerIndexing
        )}
      >
        <div className={classnames('container', styles.headerIndexing)}>
          <div className="logo">
            <a disabled>
              {editableLogo && <LogoUploader site={site} siteUpdaters={siteUpdaters} location="header" />}
              {!editableLogo && <SiteLogo site={site} placement="header" />}
            </a>
          </div>
          <button id="mobile-nav-toggle" className="hamburger hamburger--spin" type="button">
            <span className="hamburger-box">
              <span className="hamburger-inner"></span>
            </span>
          </button>
          <nav id="primary-nav">
            {headerMenuLayout === 'left' ? (
              <>
                <ul>
                  <HeaderMenuItems
                    items={items}
                    propPath={propPath.concat('items')}
                    siteUpdaters={siteUpdaters}
                    direction="ltr"
                    brightnessTheme={brightnessTheme}
                  />
                </ul>
                <ul>
                  <HeaderMenuItems
                    items={secondary_items}
                    propPath={propPath.concat('secondary_items')}
                    siteUpdaters={siteUpdaters}
                    direction="rtl"
                    brightnessTheme={brightnessTheme}
                  />
                  <ActionMenuItem
                    item={action_item}
                    action_style={action_style}
                    propPath={propPath.concat('action_item')}
                    siteUpdaters={siteUpdaters}
                  />
                  <ShopifyCartIcon metaData={site.metadata} />
                </ul>
              </>
            ) : (
              <ul>
                <HeaderMenuItems
                  items={items}
                  propPath={propPath.concat('items')}
                  siteUpdaters={siteUpdaters}
                  direction={headerMenuLayout === 'center' ? 'ltr' : 'rtl'}
                  brightnessTheme={brightnessTheme}
                />
                <ActionMenuItem
                  action_style={action_style}
                  item={action_item}
                  propPath={propPath.concat('action_item')}
                  siteUpdaters={siteUpdaters}
                />
                <ShopifyCartIcon metaData={site.metadata} />
              </ul>
            )}
          </nav>
        </div>
        <Toolbar site={site} siteUpdaters={siteUpdaters} />
      </header>
    </div>
  );
}

export default HeaderEditor;
