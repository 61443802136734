import React from 'react';
import MediaEntity from '../../../base/Media/MediaEntity';
import AuthenticatedMediaImg from '../../../../containers/AuthenticatedMediaImg';
import { ReactComponent as SvgUpload } from '../../../../assets/images/icon-upload.svg';
import idx from 'lodash/get';

import styles from './LogoUploader.module.scss';

class LogoUploader extends React.PureComponent {
  render() {
    const { site, location } = this.props;

    let brightnessTheme;
    if (location === 'header') {
      brightnessTheme = idx(site, 'styles.headerBrightnessTheme', 'light-bg');
    } else if (location === 'footer') {
      brightnessTheme = idx(site, 'styles.footerBrightnessTheme', 'dark-bg');
    } else {
      brightnessTheme = 'light-bg';
    }

    const path = brightnessTheme === 'dark-bg' ? 'elements.logo_inverted' : 'elements.logo';

    return (
      <MediaEntity
        entity={idx(site, path)}
        onChange={(value) => this.props.siteUpdaters.set(path, value)}
        renderPlaceholder={(props) => (
          <div className="eps sriracha h1 logo">
            <h1>{site.name}</h1>
          </div>
        )}
        render={(props) => <AuthenticatedMediaImg {...props} />}
        category="image"
        hideVideo
        hideEmbeds
        hideStockMedia
      />
    );
  }
}

export default LogoUploader;
