import React from 'react';
import ZeroState, { Image, Header, Muted } from '../ZeroState';
import icon from '../../../assets/images/icon-no-search-results.svg';

export default ({ search }) => (
  <ZeroState>
    <Image src={icon} />
    <Header>No customers match your criteria</Header>
    <Muted>
      Your filter and search criteria does not match a name or email in your customers. Try a different set of criteria.
    </Muted>
  </ZeroState>
);
