import * as types from './actionTypes';

export const requestIfNeeded = () => ({
  type: types.TIMEZONE_REQUEST_ALL_IF_NEEDED,
});

export const request = () => ({
  type: types.TIMEZONE_REQUEST_ALL,
});

export const requestSuccess = (payload) => ({
  type: types.TIMEZONE_REQUEST_ALL_SUCCESS,
  payload,
});
