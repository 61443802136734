import { call, put, select, all, takeEvery } from 'redux-saga/effects';
import * as api from '../services/spark-api';
import * as types from '../actions/actionTypes';
import * as componentSelectors from '../reducers/componentReducer';
import * as componentActions from '../actions/componentActions';

function* requestDefaultsIfNeeded() {
  const hasDefaults = yield select(componentSelectors.selectComponentDefaults);
  if (!hasDefaults) yield put(componentActions.requestComponentDefaults());
}

function* requestDefaults() {
  try {
    const response = yield call(api.getComponentDefaults);
    if (response.ok) {
      yield put(componentActions.saveComponentDefaults(response.json));
    }
  } catch (e) {
    console.error(e);
  }
}

function* componentSaga() {
  yield all([
    takeEvery(types.COMPONENTS_REQUEST_DEFAULTS_IF_NEEDED, requestDefaultsIfNeeded),
    takeEvery(types.COMPONENTS_REQUEST_DEFAULTS, requestDefaults),
  ]);
}

export default componentSaga;
