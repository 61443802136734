import React from 'react';
import { compose } from 'redux';
import withSiteId from './withSiteId';
import { connect } from 'react-redux';
import * as formsSelectors from '../reducers/sparkFormsReducer';
import * as formsActions from '../actions/sparkFormsActions';

function mapStateToProps(state) {
  return {
    formsLoading: formsSelectors.selectIsFetching(state),
    forms: formsSelectors.selectAll(state),
    formsByName: formsSelectors.selectAllByName(state),
  };
}

const mapDispatchToProps = {
  requestListIfNeeded: formsActions.requestListIfNeeded,
};

export default (WrappedComponent) => {
  class Container extends React.PureComponent {
    componentDidMount() {
      this.props.requestListIfNeeded(this.props.siteId);
    }

    render() {
      const { requestPageList, ...props } = this.props;
      return <WrappedComponent {...props} />;
    }
  }

  return compose(withSiteId, connect(mapStateToProps, mapDispatchToProps))(Container);
};
