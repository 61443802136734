import React, { useState, useCallback, useRef } from 'react';
import createDateFormater from 'date-fns/fp/format';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { createPortal } from 'react-dom';
import { usePopper } from 'react-popper';

import { deleteForm } from 'services/spark-api';

import Search from 'components/Search';
import Spinner from 'components/base/Spinner';
import List from 'components/react-table/List';
import DocumentTitle from 'components/DocumentTitle';
import { FormsListZeroState } from './FormsListZeroState';
import ThreeDotButton from 'components/base/three-dot-button';

import { useForms } from './hooks';
import useUrlSite from 'hooks/useUrlSite';
import { useEscapeKey } from 'hooks/useKeyBinding';
// import useOnClickOutside from 'hooks/useClickOutside';
import withQueryParams from 'containers/withQueryParams';

import { ReactComponent as NextSvg } from 'assets/images/icon-next-page.svg';
import { ReactComponent as PrevSvg } from 'assets/images/icon-prev-page.svg';

import styles from './FormsList.module.scss';

export default withQueryParams(
  function FormsList(props) {
    const { urlSite } = useParams();
    const [query, setQuery] = useState(undefined);
    const {
      queryParams: { page = 1, search, order_by },
      updateQueryParams,
    } = props;

    return (
      <>
        <DocumentTitle title="Forms" />
        <section>
          <header className={styles.header}>
            <div>
              <h1>Forms</h1>
              <small>Use forms to engage users and generate leads.</small>
            </div>
            <div className={styles.searchContainer}>
              <Search
                value={query === undefined ? search : query}
                onChange={(query: string) => setQuery(query)}
                placeholder="Search forms by name"
                debounceValue={(search: string) => updateQueryParams({ search, page: 1 })}
                containerStyle={{
                  marginRight: '1rem',
                }}
              />
              <Link
                to={`/${urlSite}/forms/new`}
                data-test-id="new-form"
                className="button button-primary"
                style={{ margin: '0' }}
              >
                New form
              </Link>
            </div>
          </header>
        </section>
        <Forms page={page} search={search} order_by={order_by} updateQueryParams={updateQueryParams} />
      </>
    );
  },
  { itemType: undefined, panel: undefined }
);

const formatDate = createDateFormater('MMMM do, yyyy');

const pageSize = 10;

const Forms = (props: any) => {
  const urlSite = useUrlSite();
  const [refetchFlag, toggleRefetchFlag] = useState(false);
  const { page, search, updateQueryParams, order_by } = props;

  const [data, isLoading] = useForms(page, search, order_by, refetchFlag);
  const dataAsAny = data as any;

  const navigate = useNavigate();

  const columns = [
    {
      Header: 'Name',
      accessor: 'name',
      sortable: true,
    },
    {
      Header: 'Submitted by',
      id: 'submitted_by',
      accessor: (row: any) => row,
      Cell: ({ value = 0 }) => {
        const { submitted_by, id }: any = value;
        return (
          <Link
            onClick={(e) => e.stopPropagation()}
            to={`/${urlSite}/customers?order_by=-modified_at&page=1&tab=&form_id=${id}`}
          >
            {submitted_by} customers
          </Link>
        );
      },
      minWidth: 100,
      sortable: false,
    },
    {
      Header: 'Last Updated',
      id: 'modified_at',
      accessor: (row: any) => new Date(row.modified_at),
      Cell: ({ value }: any) => formatDate(value),
      minWidth: 100,
      sortable: true,
    },
    {
      Header: '',
      id: 'id',
      Cell: (row: any) => {
        const ref = useRef();
        const [isOpen, setIsOpen] = useState(false);
        const [isLoading, setIsLoading] = useState(false);

        // useOnClickOutside(ref, () => setIsOpen(false));
        useEscapeKey(() => setIsOpen(false));

        const [reference, setReference] = useState(null);
        const [popper, setPopper] = useState(null);
        const { styles: popperStyles } = usePopper(reference, popper, {
          placement: 'bottom-start',
          modifiers: [{ name: 'offset', options: { offset: [0, 0] } }],
        });
        return (
          <div>
            <ThreeDotButton
              isActive={isOpen}
              data-tip="Options"
              data-test-id="options"
              onClick={(e: any) => {
                e.stopPropagation();
                setIsOpen(!isOpen);
              }}
            />
            {isOpen && (
              <div>
                <div ref={setReference} />
                {createPortal(
                  <div ref={setPopper} style={popperStyles.popper}>
                    <div ref={ref} className={styles.dropDownMenu} data-test-id="forms-options">
                      <span
                        onClick={(e) => {
                          e.stopPropagation();
                          navigate(`/${urlSite}/forms/${row.original.id}`);
                        }}
                      >
                        Edit
                      </span>
                      <span
                        onClick={(e) => {
                          setIsLoading(true);
                          e.stopPropagation();
                          const { action, content, name } = row.original;
                          navigate(`/${urlSite}/forms/new`, {
                            state: { action, content, name: `${name} Copy` },
                          });
                        }}
                      >
                        Make a copy
                      </span>
                      <div className={styles.divider} />
                      <span
                        onClick={(e) => {
                          setIsLoading(true);
                          e.stopPropagation();
                          deleteForm(row.original.id).then((res) => {
                            setIsLoading(false);
                            if (res.ok) toggleRefetchFlag(!refetchFlag);
                          });
                        }}
                      >
                        Delete
                      </span>
                      {isLoading && (
                        <div className={styles.optionsLoader}>
                          <Spinner />
                        </div>
                      )}
                    </div>
                  </div>,
                  document.body
                )}
              </div>
            )}
          </div>
        );
      },
      width: 40,
    },
  ];

  const getTdProps = useCallback(
    (state: any, row: any) => {
      return {
        onClick: (e: any) => {
          navigate(`/${urlSite}/forms/${row.original.id}`);
        },
        style: { cursor: 'pointer' },
      };
    },
    [urlSite, history]
  );

  // if (error)
  //   return (
  //     <div>
  //       <div>Uh oh. An error occured</div>
  //       <pre>{JSON.stringify(error, null, 2)}</pre>
  //     </div>
  //   );

  if (!data) return <Spinner style={{ margin: '4rem auto' }} />;

  if (dataAsAny.count === 0) return <FormsListZeroState />;

  return (
    <List
      className="-highlight"
      data={dataAsAny.results}
      columns={columns}
      count={dataAsAny.count}
      getTdProps={getTdProps}
      loading={isLoading}
      breakPageslabel={<span>... what is this? ...</span>}
      showHeader
      paginate
      defaultPageSize={pageSize}
      onPageChange={(page: any) => updateQueryParams({ page })}
      pagesCount={dataAsAny.count / pageSize}
      activePage={page}
      nextPageLabel={<NextSvg />}
      previousPageLabel={<PrevSvg />}
      onSortedChange={(newSort: any) => {
        newSort = newSort[0];
        updateQueryParams({
          order_by: `${newSort.desc ? '-' : ''}${newSort.id}`,
        });
      }}
      sorted={
        order_by
          ? [
              {
                id: order_by.replace('-', ''),
                desc: order_by.includes('-'),
              },
            ]
          : []
      }
      manual
    />
  );
};
