import React, { useState } from 'react';
import withSite from '../../containers/withSite';

import styles from './EditorCanvas.module.scss';

import Header from './Layout/FullHeader';
import Footer from './Layout/SitePageFooter';
import BlogBody from './BlogBody';
import BlogSettings from '../Blogs/BlogSettings';
import BlogLayoutConfig from './BlogLayoutConfig';

function BlogPageCanvas(props) {
  const [showCategoryLayout, setShowCategoryLayout] = useState(false);
  const { blogId, site } = props;

  return (
    <div className={styles.EditorCanvasContainer}>
      <div className={styles.EditorCanvas} data-test-id="blog-editor-canvas">
        <div id="spark" className="eps sriracha">
          {!site.is_liquid_enabled && <Header site={site} />}
          <BlogLayoutConfig
            blogId={blogId}
            setShowCategoryLayout={setShowCategoryLayout}
            showCategoryLayout={showCategoryLayout}
          />
          <BlogBody showCategoryLayout={showCategoryLayout} blogId={blogId} />
          <div className="sticky-bottom-spacer" />
          {!site.is_liquid_enabled && <Footer site={site} />}
        </div>
      </div>
      <BlogSettings />
    </div>
  );
}

export default withSite(BlogPageCanvas);
