import { call, put, select, all, takeEvery, takeLatest } from 'redux-saga/effects';
import * as api from '../services/spark-api';
import * as actionTypes from '../actions/actionTypes';
import * as contentTestActions from '../actions/contentTestActions';
import * as contentTestSelectors from '../reducers/contentTestsReducer';
import requireSiteId from './utils/requireSiteId';
import { callSparkApi } from './utils/callSparkApi';
import { normalizeContentTestsResponse as normalize } from './normalizrs/content-test-normalizers';

function* getContentTestsIfNeeded(action) {
  const hasFetched = yield select(contentTestSelectors.selectHasFetched);
  if (!hasFetched) yield put(contentTestActions.requestAll(action.payload.siteId));
}

function* getContentTests(action) {
  const siteId = yield call(requireSiteId, action.payload.siteId);
  const response = yield call(callSparkApi, api.getContentTests, siteId);

  if (response.ok) {
    const { entities, result: contentTestIds } = normalize(response.json.results);
    yield put(contentTestActions.receiveAll(contentTestIds, entities));
  } else {
    yield put(contentTestActions.receiveError(response.json));
  }
}

function* createContentTest(action) {
  const siteId = yield call(requireSiteId, action.payload.contentTest.site);

  const data = {
    ...action.payload.contentTest,
    site: siteId,
  };

  const response = yield call(callSparkApi, api.createContentTest, data);

  if (response.ok) yield put(contentTestActions.receiveCreateSuccess(response.json));
  else if (response.status >= 400 && response.status < 500)
    yield put(contentTestActions.receiveCreateError(response.json));
}

function* startContentTest(action) {
  const { contentTestId } = action.payload;
  const response = yield call(callSparkApi, api.startContentTest, contentTestId);

  if (response.ok) yield put(contentTestActions.receiveStartSuccess(contentTestId, response.json));
  else if (response.status >= 400 && response.status < 500)
    yield put(contentTestActions.receiveStartError(contentTestId, response.json));
}

function* endContentTest(action) {
  const { contentTestId } = action.payload;
  const response = yield call(callSparkApi, api.endContentTest, contentTestId);

  if (response.ok) yield put(contentTestActions.receiveEndSuccess(contentTestId, response.json));
  else if (response.status >= 400 && response.status < 500)
    yield put(contentTestActions.receiveEndError(contentTestId, response.json));
}

// Root saga
// ----------------------------------------------------------------------------

function* contentTestsSaga() {
  yield all([
    takeEvery(actionTypes.CONTENT_TESTS_REQUEST_ALL_IF_NEEDED, getContentTestsIfNeeded),
    takeLatest(actionTypes.CONTENT_TESTS_REQUEST_ALL, getContentTests),
    takeLatest(actionTypes.CONTENT_TEST_REQUEST_CREATE, createContentTest),
    takeEvery(actionTypes.CONTENT_TEST_REQUEST_START, startContentTest),
    takeEvery(actionTypes.CONTENT_TEST_REQUEST_END, endContentTest),
  ]);
}

export default contentTestsSaga;
