import * as types from './actionTypes';

export function initializeApp() {
  return {
    type: types.APP_INITIALIZE,
  };
}

export function updateAppStatus(status) {
  return {
    type: types.APP_UPDATE_STATUS,
    payload: status,
  };
}
