import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as PopperJS from '@popperjs/core';
import { usePopper } from 'react-popper';
import classnames from 'classnames';
import get from 'lodash/get';
import toInteger from 'lodash/toInteger';

import { getPaddingPosition, handleUpDown, handleUpDownTyped } from './helpers';
import useOnClickOutside from 'hooks/useClickOutside';
import * as addSectionActions from 'actions/addSectionActions';

import { Group, Label } from '../../Toolbars2/Toolbar2';
import {
  IconChevronDownSmall,
  IconChevronUpSmall,
  IconPaddingT,
  IconPadding,
  IconRight,
  IconTooltipPadding,
  IconTooltipPaddingDefault,
} from '../../Toolbars2/icons';

import styles from './PaddingAndWidthButton.module.scss';
import { UBoxInfo, USectionDevices } from 'types/USection';
import { set } from 'object-path-immutable';
import { getDeviceTypeToSaveTo, setInDynamic } from 'components/unstack-components/Component/util/helpers/deviceHelper';
import { getDevice } from 'reducers/uiReducer';

interface Props {
  value?: UBoxInfo;
  onChange: (content: USectionDevices, key?: string, multi?: boolean) => void;
  defaults?: UBoxInfo;
  isSectionToolbar?: any;
  content: USectionDevices;
  contentKey: string;
}

const MIN = 0;
const MAX = 20;

export default function (props: Props) {
  const { defaults, onChange, isSectionToolbar, content, contentKey } = props;
  const value = {
    ...props.value,
    paddingTop: props.value.paddingTop === 'default' ? 'default' : parseInt(props.value.paddingTop as string),
    paddingBottom: props.value.paddingBottom === 'default' ? 'default' : parseInt(props.value.paddingBottom as string),
    paddingLeft: props.value.paddingLeft === 'default' ? 'default' : parseInt(props.value.paddingLeft as string),
    paddingRight: props.value.paddingRight === 'default' ? 'default' : parseInt(props.value.paddingRight as string),
  };

  const [cursorPos, setCursorPos] = useState<number>(null);
  const [focused, setFocused] = useState(null);
  const [focusedMulti, setFocusedMulti] = useState(null);
  const [hoveredDefault, setHoveredDefault] = useState(false);
  const [checked, setChecked] = useState(false);
  const [lockedValue, setLockedValue] = useState<Array<number>>(null);
  const [temporaryPadding, setTemporaryPadding] = useState<string>(null);
  const [paddingValueFormat, setPaddingValueFormat] = useState(null);
  const [paddingValueCombined, setPaddingValueCombined] = useState<string>(null);
  const [referenceElement, setReferenceElement] = useState<Element | PopperJS.VirtualElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
  const { styles: popperStyles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom-start',
  });
  const [showPopper, setShowPopper] = useState(false);
  const innerPopperRef = useRef();
  const device = useSelector(getDevice);
  useOnClickOutside(innerPopperRef, () => {
    setShowPopper(false);
    setLockedValue(null);
  });

  const dispatch = useDispatch();
  useEffect(() => {
    if (showPopper) dispatch(addSectionActions.requestDrawerOpen());
    else dispatch(addSectionActions.requestDrawerClose());
  }, [showPopper]);

  const [arrowPress, setArrowPress] = useState(false);
  const [width, setWidth] = useState(paddingValueCombined ? paddingValueCombined.length : 8);

  const handleChange = (key: string) => (newValue: string | number) => {
    const splitKey = contentKey.split('.');
    const val = !isNaN(newValue as number) ? newValue + 'rem' : newValue;
    const path = [getDeviceTypeToSaveTo(device, false, splitKey), ...splitKey.slice(1), key];
    const newContent = set(content, path, val);
    onChange(newContent, `content.${splitKey[0]}`);
  };

  const arePaddingValuesEquivalent = (
    paddingTopValue: string | number,
    paddingRightValue: string | number,
    paddingBottomValue: string | number,
    paddingLeftValue: string | number
  ) => {
    return (
      minMax(toInteger(paddingTopValue)) === toInteger(value['paddingTop']) &&
      minMax(toInteger(paddingRightValue)) === toInteger(value['paddingRight']) &&
      minMax(toInteger(paddingBottomValue)) === toInteger(value['paddingBottom']) &&
      minMax(toInteger(paddingLeftValue)) === toInteger(value['paddingLeft'])
    );
  };

  const handleChangeAll = (
    paddingTopValue: string | number,
    paddingRightValue: string | number,
    paddingBottomValue: string | number,
    paddingLeftValue: string | number
  ) => {
    const splitKey = contentKey.split('.');
    if (!arePaddingValuesEquivalent(paddingTopValue, paddingRightValue, paddingBottomValue, paddingLeftValue)) {
      let fullObj = get(content, getDeviceTypeToSaveTo(device, false, splitKey) + '.' + splitKey.slice(1).join('.'));
      fullObj = {
        ...fullObj,
        ['paddingTop']: paddingTopValue === 'default' ? 'default' : minMax(toInteger(paddingTopValue)) + 'rem',
        ['paddingRight']: paddingRightValue === 'default' ? 'default' : minMax(toInteger(paddingRightValue)) + 'rem',
        ['paddingBottom']: paddingBottomValue === 'default' ? 'default' : minMax(toInteger(paddingBottomValue)) + 'rem',
        ['paddingLeft']: paddingLeftValue === 'default' ? 'default' : minMax(toInteger(paddingLeftValue)) + 'rem',
      };
      onChange(
        set(content, [getDeviceTypeToSaveTo(device, false, splitKey), ...splitKey.slice(1)], fullObj),
        `content.${splitKey[0]}`
      );

      setPaddingValueCombined(
        paddingFormat(
          toInteger(minMax(toInteger(paddingTopValue === 'default' ? defaults['paddingTop'] : paddingTopValue))),
          toInteger(minMax(toInteger(paddingRightValue === 'default' ? defaults['paddingRight'] : paddingRightValue))),
          toInteger(
            minMax(toInteger(paddingBottomValue === 'default' ? defaults['paddingBottom'] : paddingBottomValue))
          ),
          toInteger(minMax(toInteger(paddingLeftValue === 'default' ? defaults['paddingLeft'] : paddingLeftValue)))
        )
      );
    }
  };

  const handleChangePadding = (
    paddingTopValue: string | number,
    paddingRightValue: string | number,
    paddingBottomValue: string | number,
    paddingLeftValue: string | number,
    paddingValueFormat: any
  ) => {
    const splitKey = contentKey.split('.');
    let fullObj = get(content, getDeviceTypeToSaveTo(device, false, splitKey) + '.' + splitKey.slice(1).join('.'));
    fullObj = {
      ...fullObj,
      ['paddingTop']: minMax(toInteger(paddingTopValue)) + 'rem',
      ['paddingRight']: minMax(toInteger(paddingRightValue)) + 'rem',
      ['paddingBottom']: minMax(toInteger(paddingBottomValue)) + 'rem',
      ['paddingLeft']: minMax(toInteger(paddingLeftValue)) + 'rem',
    };
    onChange(
      set(content, [getDeviceTypeToSaveTo(device, false, splitKey), ...splitKey.slice(1)], fullObj),
      `content.${splitKey[0]}`
    );

    setPaddingValueCombined(
      paddingFormatAll(
        toInteger(minMax(toInteger(paddingTopValue))),
        toInteger(minMax(toInteger(paddingRightValue))),
        toInteger(minMax(toInteger(paddingBottomValue))),
        toInteger(minMax(toInteger(paddingLeftValue))),
        paddingValueFormat
      )
    );
  };

  const refTop = useRef(null);
  const refRight = useRef(null);
  const refBottom = useRef(null);
  const refLeft = useRef(null);
  const refDefault = useRef(null);

  const paddingFormat = (paddingTop: number, paddingRight: number, paddingBottom: number, paddingLeft: number) => {
    if (paddingTop === paddingRight && paddingTop === paddingBottom && paddingTop === paddingLeft) {
      setPaddingValueFormat('top');
      return `${paddingTop}`;
    } else if (paddingTop === paddingBottom && paddingRight === paddingLeft) {
      setPaddingValueFormat('top, right');
      return `${paddingTop}, ${paddingRight}`;
    } else if (paddingRight === paddingLeft) {
      setPaddingValueFormat('top, right, bottom');
      return `${paddingTop}, ${paddingRight}, ${paddingBottom}`;
    } else {
      setPaddingValueFormat('top, right, bottom, left');
      return `${paddingTop}, ${paddingRight}, ${paddingBottom}, ${paddingLeft}`;
    }
  };

  const paddingFormatAll = (
    paddingTop: number,
    paddingRight: number,
    paddingBottom: number,
    paddingLeft: number,
    paddingValueFormat: string
  ) => {
    if (paddingValueFormat === 'top') {
      return `${paddingTop}`;
    } else if (paddingValueFormat === 'top, right') {
      return `${paddingTop}, ${paddingRight}`;
    } else if (paddingValueFormat === 'top, right, bottom') {
      return `${paddingTop}, ${paddingRight}, ${paddingBottom}`;
    } else {
      return `${paddingTop}, ${paddingRight}, ${paddingBottom}, ${paddingLeft}`;
    }
  };

  const paddingPosition = (value: UBoxInfo, targetCursor: number) => {
    return getPaddingPosition(value, defaults, targetCursor);
  };

  const handleTextInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTemporaryPadding(e.target.value);
    setWidth(e.target.value.length + 1);
  };

  const minMax = (value: number) => {
    return value > MAX ? MAX : value < MIN ? MIN : !value ? 0 : value;
  };

  useEffect(() => {
    // This runs when a user is no longer typing / value is set
    if (!temporaryPadding && paddingValueCombined) {
      const position = paddingPosition(value, cursorPos);
      if (paddingValueFormat === 'top') {
        setFocusedMulti(['top', 'right', 'bottom', 'left']);
      } else if (paddingValueFormat === 'top, right') {
        if (position === 'top') setFocusedMulti(['top', 'bottom']);
        else if (position === 'right') setFocusedMulti(['right', 'left']);
        else setFocusedMulti(null);
      } else if (paddingValueFormat === 'top, right, bottom') {
        if (position === 'top') setFocusedMulti(['top']);
        else if (position === 'right') setFocusedMulti(['right', 'left']);
        else if (position === 'bottom') setFocusedMulti(['bottom']);
        else setFocusedMulti(null);
      } else if (paddingValueFormat === 'top, right, bottom, left') {
        if (position === 'top') setFocusedMulti(['top']);
        else if (position === 'right') setFocusedMulti(['right']);
        else if (position === 'bottom') setFocusedMulti(['bottom']);
        else if (position === 'left') setFocusedMulti(['left']);
        else setFocusedMulti(null);
      }
    } else {
      setFocusedMulti(null);
    }
  }, [cursorPos, temporaryPadding, paddingValueFormat]);

  const setPadding = (
    e:
      | React.KeyboardEvent<HTMLInputElement>
      | React.FocusEvent<HTMLInputElement, Element>
      | React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    setLockedValue(null);
    setFocusedMulti(null);
    setCursorPos(null);
    const re = /^[0-9 ,]+$/;
    if (temporaryPadding && re.test(temporaryPadding.replace(/[, ]+$/, '').replaceAll(/px/g, ''))) {
      const paddingArray = temporaryPadding
        .replace(/[, ]+$/, '')
        .replaceAll(/px/g, '')
        .split(/[, ]+/);
      switch (paddingArray.length) {
        case 1:
          handleChangeAll(paddingArray[0], paddingArray[0], paddingArray[0], paddingArray[0]);
          break;
        case 2:
          handleChangeAll(paddingArray[0], paddingArray[1], paddingArray[0], paddingArray[1]);
          break;
        case 3:
          handleChangeAll(paddingArray[0], paddingArray[1], paddingArray[2], paddingArray[1]);
          break;
        default:
          handleChangeAll(paddingArray[0], paddingArray[1], paddingArray[2], paddingArray[3]);
      }
    } else {
      handleChangeAll(value['paddingTop'], value['paddingRight'], value['paddingBottom'], value['paddingLeft']);
    }
    setTemporaryPadding(null);
  };

  // KEYBOARD HANDLING FOR PADDING INPUT
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement;
    const position = paddingPosition(value, target.selectionStart);
    setCursorPos(target.selectionStart);
    setArrowPress(false);

    switch (e.key) {
      case 'Enter':
        setPadding(e);
        break;
      case 'Escape':
        setTemporaryPadding(null);
        if (lockedValue) handleChangeAll(lockedValue[0], lockedValue[1], lockedValue[2], lockedValue[3]);
        setLockedValue(null);
        break;
      case 'ArrowUp':
        e.preventDefault();
        handleUpDownTyped(
          temporaryPadding,
          setTemporaryPadding,
          setArrowPress,
          setWidth,
          cursorPos,
          setCursorPos,
          target.selectionStart,
          'add'
        );
        handleUpDown(
          value,
          defaults,
          lockedValue,
          setLockedValue,
          temporaryPadding,
          paddingValueCombined,
          position,
          paddingValueFormat,
          minMax,
          setCursorPos,
          target.selectionStart,
          'add',
          handleChangePadding
        );
        break;
      case 'ArrowDown':
        e.preventDefault();
        handleUpDownTyped(
          temporaryPadding,
          setTemporaryPadding,
          setArrowPress,
          setWidth,
          cursorPos,
          setCursorPos,
          target.selectionStart,
          'down'
        );
        handleUpDown(
          value,
          defaults,
          lockedValue,
          setLockedValue,
          temporaryPadding,
          paddingValueCombined,
          position,
          paddingValueFormat,
          minMax,
          setCursorPos,
          target.selectionStart,
          'subtract',
          handleChangePadding
        );
        break;
      case 'ArrowLeft':
        if (target.selectionStart > 0) setCursorPos(target.selectionStart - 1);
        break;
      case 'ArrowRight':
        setCursorPos(target.selectionStart + 1);
        break;
      default:
    }
  };

  // Change value of individual padding inputs
  const handleValueChange = (newValue: string | number) => {
    newValue = (newValue as number) > MAX ? MAX : (newValue as number) < MIN ? MIN : !newValue ? 0 : newValue;
    if (focused === 'top')
      handleChangeAll(newValue, value['paddingRight'], value['paddingBottom'], value['paddingLeft']);
    else if (focused === 'right')
      handleChangeAll(value['paddingTop'], newValue, value['paddingBottom'], value['paddingLeft']);
    else if (focused === 'bottom')
      handleChangeAll(value['paddingTop'], value['paddingRight'], newValue, value['paddingLeft']);
    else if (focused === 'left')
      handleChangeAll(value['paddingTop'], value['paddingRight'], value['paddingBottom'], newValue);
  };

  useEffect(() => {
    setPaddingValueCombined(
      paddingFormat(
        toInteger(value['paddingTop'] === 'default' ? defaults['paddingTop'] : value['paddingTop']),
        toInteger(value['paddingRight'] === 'default' ? defaults['paddingRight'] : value['paddingRight']),
        toInteger(value['paddingBottom'] === 'default' ? defaults['paddingBottom'] : value['paddingBottom']),
        toInteger(value['paddingLeft'] === 'default' ? defaults['paddingLeft'] : value['paddingLeft'])
      )
    );
  }, [value]);

  const handleClick = (type: string) => {
    if (type === 'top') refTop.current.focus();
    else if (type === 'right') refRight.current.focus();
    else if (type === 'bottom') refBottom.current.focus();
    else if (type === 'left') refLeft.current.focus();
  };

  const handleKeyDownPadding = (e: any) => {
    switch (e.key) {
      case 'Tab':
        if (e.shiftKey) {
          e.preventDefault();
          if (focused === 'right') refTop.current.focus();
          else if (focused === 'bottom') refRight.current.focus();
          else if (focused === 'left') refBottom.current.focus();
          else if (focused === 'default') refDefault.current.focus();
          break;
        }
        e.preventDefault();
        if (focused === 'top') refRight.current.focus();
        else if (focused === 'right') refBottom.current.focus();
        else if (focused === 'bottom') refLeft.current.focus();
        else if (focused === 'left') refDefault.current.focus();
        break;
      default:
    }
  };

  const handleKeyDownDefault = (e: any) => {
    switch (e.key) {
      case 'Enter':
        e.preventDefault();
        setChecked(!checked);
        break;
      case 'Escape':
        e.preventDefault();
        setTemporaryPadding(null);
        break;
      default:
    }
  };

  useEffect(() => {
    if (
      checked &&
      !(
        arePaddingValuesEquivalent(
          defaults['paddingTop'],
          defaults['paddingRight'],
          defaults['paddingBottom'],
          defaults['paddingLeft']
        ) ||
        (value['paddingTop'] === 'default' &&
          value['paddingRight'] === 'default' &&
          value['paddingBottom'] === 'default' &&
          value['paddingLeft'] === 'default')
      )
    ) {
      handleChangeAll('default', 'default', 'default', 'default');
    }
  }, [checked]);

  useEffect(() => {
    if (
      (toInteger(defaults['paddingTop']) === toInteger(value['paddingTop']) &&
        toInteger(defaults['paddingRight']) === toInteger(value['paddingRight']) &&
        toInteger(defaults['paddingBottom']) === toInteger(value['paddingBottom']) &&
        toInteger(defaults['paddingLeft']) === toInteger(value['paddingLeft'])) ||
      (value['paddingTop'] === 'default' &&
        value['paddingRight'] === 'default' &&
        value['paddingBottom'] === 'default' &&
        value['paddingLeft'] === 'default')
    ) {
      setChecked(true);
    } else {
      setChecked(false);
    }
  }, [defaults, value]);

  useEffect(() => {
    if (focused === 'top') refTop.current.focus();
    else if (focused === 'right') refRight.current.focus();
    else if (focused === 'bottom') refBottom.current.focus();
    else if (focused === 'left') refLeft.current.focus();
  }, [focused]);

  const inputRef = useRef(null);
  useEffect(() => {
    const input = inputRef.current;
    if (input) input.setSelectionRange(cursorPos, cursorPos);
  }, [inputRef, paddingValueCombined]);

  useEffect(() => {
    const input = inputRef.current;
    if (input && arrowPress) input.setSelectionRange(cursorPos, cursorPos);
  }, [temporaryPadding]);

  useEffect(() => {
    const input = inputRef.current;
    if (input) input.style.setProperty('width', `${width}ch`, 'important');
  }, [inputRef, width]);

  useEffect(() => {
    if (paddingValueCombined) setWidth(paddingValueCombined.replace(', ', '').length + 2);
  }, [paddingValueCombined]);

  return (
    <Group className={styles.groupPadding} data-test-id={`padding`}>
      <Label>Padding</Label>
      <span
        ref={setReferenceElement}
        onClick={() => {
          setShowPopper(!showPopper);
          setFocused(null);
        }}
      >
        <input
          ref={inputRef}
          className={classnames(styles.TextInput, styles.paddingInput)}
          type="text"
          aria-label="padding"
          value={temporaryPadding !== null ? temporaryPadding : paddingValueCombined}
          onChange={handleTextInputChange}
          onKeyDown={handleKeyDown}
          onBlur={setPadding}
          onClick={(e: any) => {
            setCursorPos(e.target.selectionStart);
          }}
        />
      </span>
      {showPopper && (
        <div
          className={styles.paddingPopperContainer}
          data-test-id="padding-popper"
          ref={setPopperElement}
          style={{ ...popperStyles.popper, zIndex: 9, marginTop: '6px', marginBottom: '6px' }}
          {...attributes.popper}
        >
          <div className={styles.innerRefContainer} ref={innerPopperRef}>
            <div className={styles.paddingDrawer}>
              {/* TOP */}
              <div
                className={classnames(styles.inputContainer, styles.paddingTop)}
                data-test-id="top"
                onFocus={() => setFocused('top')}
                onBlur={() => setFocused(null)}
              >
                <input
                  ref={refTop}
                  className={styles.inputPadding}
                  type="number"
                  min={MIN}
                  max={MAX}
                  step="1"
                  value={toInteger(
                    value['paddingTop'] === 'default' ? defaults['paddingTop'] : value['paddingTop'] || 0
                  )}
                  onChange={(e) => handleValueChange(e.target.value)}
                  onKeyDown={(e) => handleKeyDownPadding(e)}
                />
                <div
                  className={styles.upDownContainer}
                  onMouseDown={(e) => {
                    e.preventDefault();
                    handleClick('top');
                  }}
                >
                  <button
                    aria-label="up"
                    onClick={() => {
                      handleChange('paddingTop')(
                        minMax(
                          toInteger(value['paddingTop'] === 'default' ? defaults['paddingTop'] : value['paddingTop']) +
                            1
                        )
                      );
                    }}
                  >
                    <IconChevronUpSmall style={{ margin: 'auto' }} />
                  </button>
                  <button
                    aria-label="down"
                    onClick={() => {
                      handleChange('paddingTop')(
                        minMax(
                          toInteger(value['paddingTop'] === 'default' ? defaults['paddingTop'] : value['paddingTop']) -
                            1
                        )
                      );
                    }}
                  >
                    <IconChevronDownSmall />
                  </button>
                </div>
              </div>

              {/* RIGHT */}
              <div
                className={classnames(styles.inputContainer, styles.paddingRight)}
                data-test-id="right"
                onFocus={() => setFocused('right')}
                onBlur={() => setFocused(null)}
              >
                <input
                  ref={refRight}
                  className={styles.inputPadding}
                  type="number"
                  min={MIN}
                  max={MAX}
                  step="1"
                  value={toInteger(
                    value['paddingRight'] === 'default' ? defaults['paddingRight'] : value['paddingRight'] || 0
                  )}
                  onChange={(e) => handleValueChange(e.target.value)}
                  onKeyDown={(e) => handleKeyDownPadding(e)}
                />
                <div
                  className={styles.upDownContainer}
                  onMouseDown={(e) => {
                    e.preventDefault();
                    handleClick('right');
                  }}
                >
                  <button
                    aria-label="up"
                    onClick={() => {
                      handleChange('paddingRight')(
                        minMax(
                          toInteger(
                            value['paddingRight'] === 'default' ? defaults['paddingRight'] : value['paddingRight']
                          ) + 1
                        )
                      );
                    }}
                  >
                    <IconChevronUpSmall style={{ margin: 'auto' }} />
                  </button>
                  <button
                    aria-label="down"
                    onClick={() => {
                      handleChange('paddingRight')(
                        minMax(
                          toInteger(
                            value['paddingRight'] === 'default' ? defaults['paddingRight'] : value['paddingRight']
                          ) - 1
                        )
                      );
                    }}
                  >
                    <IconChevronDownSmall />
                  </button>
                </div>
              </div>

              {/* BOTTOM */}
              <div
                className={classnames(styles.inputContainer, styles.paddingBottom)}
                data-test-id="bottom"
                onFocus={() => setFocused('bottom')}
                onBlur={() => setFocused(null)}
              >
                <input
                  ref={refBottom}
                  className={styles.inputPadding}
                  type="number"
                  min={MIN}
                  max={MAX}
                  step="1"
                  value={toInteger(
                    value['paddingBottom'] === 'default' ? defaults['paddingBottom'] : value['paddingBottom'] || 0
                  )}
                  onChange={(e) => handleValueChange(e.target.value)}
                  onKeyDown={(e) => handleKeyDownPadding(e)}
                />
                <div
                  className={styles.upDownContainer}
                  onMouseDown={(e) => {
                    e.preventDefault();
                    handleClick('bottom');
                  }}
                >
                  <button
                    aria-label="up"
                    onClick={() => {
                      handleChange('paddingBottom')(
                        minMax(
                          toInteger(
                            value['paddingBottom'] === 'default' ? defaults['paddingBottom'] : value['paddingBottom']
                          ) + 1
                        )
                      );
                    }}
                  >
                    <IconChevronUpSmall style={{ margin: 'auto' }} />
                  </button>
                  <button
                    aria-label="down"
                    onClick={() => {
                      handleChange('paddingBottom')(
                        minMax(
                          toInteger(
                            value['paddingBottom'] === 'default' ? defaults['paddingBottom'] : value['paddingBottom']
                          ) - 1
                        )
                      );
                    }}
                  >
                    <IconChevronDownSmall />
                  </button>
                </div>
              </div>

              {/* LEFT */}
              <div
                className={classnames(styles.inputContainer, styles.paddingLeft)}
                data-test-id="left"
                onFocus={() => setFocused('left')}
                onBlur={() => setFocused(null)}
              >
                <input
                  ref={refLeft}
                  className={styles.inputPadding}
                  type="number"
                  min={MIN}
                  max={MAX}
                  step="1"
                  value={toInteger(
                    value['paddingLeft'] === 'default' ? defaults['paddingLeft'] : value['paddingLeft'] || 0
                  )}
                  onChange={(e) => handleValueChange(e.target.value)}
                  onKeyDown={(e) => handleKeyDownPadding(e)}
                />
                <div
                  className={styles.upDownContainer}
                  onMouseDown={(e) => {
                    e.preventDefault();
                    handleClick('left');
                  }}
                >
                  <button
                    aria-label="up"
                    onClick={() => {
                      handleChange('paddingLeft')(
                        minMax(
                          toInteger(
                            value['paddingLeft'] === 'default' ? defaults['paddingLeft'] : value['paddingLeft']
                          ) + 1
                        )
                      );
                    }}
                  >
                    <IconChevronUpSmall style={{ margin: 'auto' }} />
                  </button>
                  <button
                    aria-label="down"
                    onClick={() => {
                      handleChange('paddingLeft')(
                        minMax(
                          toInteger(
                            value['paddingLeft'] === 'default' ? defaults['paddingLeft'] : value['paddingLeft']
                          ) - 1
                        )
                      );
                    }}
                  >
                    <IconChevronDownSmall />
                  </button>
                </div>
              </div>

              {/* ICONS */}
              <div className={styles.bounds}>
                <IconPaddingT
                  style={{
                    margin: 'auto',
                  }}
                />
                {(focused === 'top' || focusedMulti?.includes('top')) && (
                  <IconPadding
                    style={{
                      position: 'absolute',
                      top: '0px',
                    }}
                  />
                )}
                {(focused === 'right' || focusedMulti?.includes('right')) && (
                  <IconPadding
                    style={{
                      transform: 'rotate(90deg)',
                      position: 'absolute',
                      left: '20px',
                    }}
                  />
                )}
                {(focused === 'bottom' || focusedMulti?.includes('bottom')) && (
                  <IconPadding
                    style={{
                      transform: 'rotate(180deg)',
                      position: 'absolute',
                      bottom: '0px',
                    }}
                  />
                )}
                {(focused === 'left' || focusedMulti?.includes('left')) && (
                  <IconPadding
                    style={{
                      transform: 'rotate(270deg)',
                      position: 'absolute',
                      right: '20px',
                    }}
                  />
                )}
              </div>
            </div>

            <div className={styles.menuDividier} />

            <div
              className={styles.toggleContainer}
              onMouseEnter={() => setHoveredDefault(true)}
              onMouseLeave={() => setHoveredDefault(false)}
              onClick={(e) => {
                if ((e.target as HTMLInputElement).type === undefined) setChecked(!checked);
              }}
            >
              <div className={styles.toggleDefault}>
                <label className={styles.switch}>
                  <input
                    type="checkbox"
                    checked={checked}
                    ref={refDefault}
                    onFocus={() => setFocused('default')}
                    onBlur={() => setFocused(null)}
                    onKeyDown={handleKeyDownDefault}
                    readOnly
                  />
                  <span className={styles.slider} onClick={() => setChecked(!checked)}></span>
                </label>
                Default padding
              </div>
            </div>

            {!(hoveredDefault || focused === 'default') && (
              <div className={styles.toolTip}>
                <IconRight className={styles.arrow} />
                <IconTooltipPadding className={styles.icon} />
                <div className={styles.text}>Padding is the space between the text and the border</div>
              </div>
            )}

            {(hoveredDefault || focused === 'default') && (
              <div className={styles.toolTipDefault}>
                <IconRight className={styles.arrow} />
                <IconTooltipPaddingDefault className={styles.icon} />
                <div className={styles.text}>Set default padding in the Style Guide</div>
              </div>
            )}
          </div>
        </div>
      )}
    </Group>
  );
}
