import React, { useEffect } from 'react';
import { Route, Routes, useParams } from 'react-router-dom';
import StyleOverrides from './StyleOverrides';
import { useDispatch, useSelector } from 'react-redux';
import * as siteActions from '../actions/siteActions';
import * as accountActions from '../actions/accountActions';
import * as siteSelectors from '../reducers/sitesReducer';
import AdminPanel from './AdminPanel';
import { selectAccountUsers } from 'reducers/accountReducer';
import SubscriptionManager from './SubscriptionManager/SubscriptionManager';
import AddAPIKey from './AdminPanel/Settings/APIKeys/AddAPIKey';
import UserSettings from './AdminPanel/Settings/Users/UserSettings';
import InProgressContentTestSettings from './Editor/InProgressContentTestSettings';
import { selectHasPermission } from 'reducers/policyReducer';
import NewContentTestSettings from './Editor/NewContentTestSettings';
import Editor from './Editor';
import Templates from './Pages/Templates';
import ArticleEditor from './ArticleEditor';
import UnstackPurchasePlan from './AdminPanel/Integrations/ShopifyAppAdd/UnstackPurchasePlan';
import UnstackTrialEnd from './AdminPanel/Integrations/ShopifyAppAdd/UnstackTrialEnd';
import BlogEditor from './BlogEditor';
import NewCategory from './Articles/NewCategory';
import Show from './unstack-components/editor/Show';
import DynamicPageEditor from './DynamicPageEditor';
import PageNotFound from './PageNotFound';
import ChooseProduct from './Pages/ChooseProduct';

const LoggedInRoutes: React.FC = () => {
  const { urlSite } = useParams();
  const dispatch = useDispatch();
  const activeSite = useSelector(siteSelectors.selectActiveSite);
  const users = useSelector(selectAccountUsers);
  const hasPermission = useSelector(selectHasPermission);

  // Get account id from the current active site
  const accountId = useSelector((state) => {
    if (!urlSite) return undefined;
    // @ts-ignore
    const site = siteSelectors.selectSiteByDomain(state, urlSite);
    return site && site.account;
  });

  // Update the active site in the redux store anytime the urlSite changes
  useEffect(() => {
    dispatch(siteActions.activeUrlSiteChanged(urlSite));
  }, [urlSite, dispatch]);

  // Request account anytime accountId changes
  useEffect(() => {
    if (accountId) {
      dispatch(accountActions.request(accountId));
    }
  }, [accountId, dispatch]);

  return (
    <>
      {activeSite && (
        <>
          <StyleOverrides />
          <Routes>
            <Route path="/editor/:pageId" element={<Editor />} />
            <Route path="/site-pages/new" element={<Editor isNew pageType="site_page" />} />
            <Route path="/landing-pages/new" element={<Editor isNew pageType="landing_page" />} />
            <Route path="/collection-pages/new" element={<Editor isNew pageType="collection" />} />
            <Route path="/product-pages/new" element={<Editor isNew pageType="product" />} />
            <Route path="/settings/plans" element={<UnstackPurchasePlan />} />
            <Route path="/trial-end" element={<UnstackTrialEnd />} />
            <Route path="/editor/blog-post/:articleId" element={<ArticleEditor />} />
            <Route path="/blog/:blogId/new" element={<ArticleEditor isNew />} />
            <Route path="/editor/blog/:blogId" element={<BlogEditor />} />
            <Route path="/article/category" element={<NewCategory />} />
            <Route path="/sections/:selectedId" element={<Show />} />
            <Route path="/components/:selectedId" element={<Show />} />
            <Route path="/dynamic-pages/new" element={<DynamicPageEditor isNew pageType="data_page" />} />
            <Route path="/choose-product" element={<ChooseProduct />} />
            {users && users.length > 0 && (
              <>
                <Route path="/:pageType/templates" element={<Templates />} />
                <Route path="/:pageType/templates/:tab" element={<Templates />} />
                <Route path="*" element={<AdminPanel />} />
              </>
            )}
          </Routes>
          <SubscriptionManager />
          {<AddAPIKey />}
          {hasPermission('AccountUser:list') && <UserSettings />}
          {hasPermission('Experiment:list') && <NewContentTestSettings />}
          {hasPermission('Experiment:list') && <InProgressContentTestSettings />}
        </>
      )}
      {!activeSite && (
        <Routes>
          <Route path="/*" element={<PageNotFound />} />
        </Routes>
      )}
    </>
  );
};

export default LoggedInRoutes;
