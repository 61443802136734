import * as actionTypes from './actionTypes';

/**
 * Opens the subscription manager
 * @param  {object} args All arguments
 * @param  {string} [args.requestedAction] the requested view/action
 * @return {object} The redux action.
 */
export function open(args = {}) {
  return {
    type: actionTypes.SUBSCRIPTION_MANAGER_OPEN,
    payload: { ...args },
  };
}

/**
 * Closes the subscription manager.
 * @return {object} The redux action.
 */
export function close() {
  return {
    type: actionTypes.SUBSCRIPTION_MANAGER_CLOSE,
  };
}
