import { toInteger } from 'lodash';

export function getLayoutPadding(padding: Array<number>, multiplier: number) {
  return `${padding[0] > 0 ? padding[0] * multiplier : 1}px ${padding[1] > 0 ? padding[1] * multiplier : 1}px ${
    padding[2] > 0 ? padding[2] * multiplier : 1
  }px ${padding[3] > 0 ? padding[3] * multiplier : 1}px`;
}

export function getLayoutBorderWidth(padding: Array<number>) {
  return `0px ${padding[1] > 0 ? padding[1] * 5 : 1}px 0px ${padding[3] > 0 ? padding[3] * 5 : 1}px`;
}

export function getLayoutMargin(padding: Array<number>) {
  return `${padding[0] > 0 ? padding[0] * 5 : 1}px 0px ${padding[2] > 0 ? padding[2] * 5 : 1}px 0px`;
}

export function getBoxStyle(layout: string, width: string, padding: Array<number>, type: string) {
  const widthFinal =
    toInteger(width.split('px')[0]) / 6.3 - ((toInteger(padding[1]) + toInteger(padding[3])) * 3.5) / 2;
  return {
    padding: '0px',
    display: 'flex',
    ...(type === 'media' && { background: 'white' }),
    ...(layout === 'desktop' && {
      width: widthFinal,
    }),
    ...(layout === 'mobile' && {
      width: '100%',
    }),
  };
}

export function getPaddingTextStyle(padding: number, multiplier: number, position: string) {
  if (position === 'top' && padding >= 5) return { top: ((padding - 5) * multiplier) / 2 };
  else if (position === 'right' && padding >= 4) return { right: ((padding - (padding > 9 ? 4 : 2)) * multiplier) / 2 };
  else if (position === 'bottom' && padding >= 5) return { top: 'auto', bottom: ((padding - 5) * multiplier) / 2 };
  else if (position === 'left' && padding >= 4) return { left: ((padding + (padding > 9 ? 4 : 2)) * multiplier) / 2 };
  else return {};
}
