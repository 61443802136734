import React, { useEffect, useRef } from 'react';

import SmartUrlInput from '../../base/FormHelpers/SmartUrlInput';

import classnames from 'classnames';
import isObject from 'lodash/isObject';

export default function PageViewGoal(props) {
  const { goal, onChange, disabled } = props;
  // const goal = props.goal || { type: 'pageview', id: 'home' };

  const isSelected = isObject(goal) && goal.type === 'pageview';

  const isSelectedRef = useRef(isSelected);
  const wasJustSelected = isSelected && isSelectedRef.current !== isSelected;

  useEffect(() => {
    isSelectedRef.current = isSelected;
  }, [isSelected]);

  function handleClick() {
    onChange({ type: 'pageview', id: '' });
  }

  function handlePageChange(id) {
    onChange({ type: 'pageview', id: id });
  }

  if (!isSelected) {
    return (
      <label
        className={classnames({
          selected: false,
          disabled: disabled,
        })}
        htmlFor="page_view_goal_input"
      >
        <div>
          <input
            id="page_view_goal_input"
            aria-label="Page view goal"
            type="radio"
            checked={false}
            onChange={handleClick}
            readOnly
          />
          <div className="title">Viewed page</div>
        </div>
      </label>
    );
  } else
    return (
      <div
        className={classnames({
          selected: true,
          disabled: disabled,
        })}
      >
        <label>
          <div>
            <input type="radio" checked={true} readOnly />
            <div className="title">Viewed page</div>
          </div>
        </label>
        <div className="label-block">
          <SmartUrlInput onChange={handlePageChange} value={goal.id} autoFocus={wasJustSelected} />
        </div>
      </div>
    );
}
