import React from 'react';
import { compose } from 'redux';
import withSiteProps from '../../../../containers/withSiteProps';
import classnames from 'classnames';
import { connect } from 'react-redux';

import SideDrawer from '../../../base/SideDrawer';
import { ReactComponent as SvgCheckmark } from '../../../../assets/images/icon-checkmark.svg';

import * as fontsSelector from 'reducers/fontsReducer';
import * as integrationsSelectors from 'reducers/integrationsReducer';

import styles from './FontFamilySelectionDrawer.module.scss';

class FontFamilySelectionDrawer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      fontToggleValue: 'google',
      selectedFonts: this.props.fonts.filter((item) => ['google', 'browser'].includes(item.font_source)),
    };
    this.handleClick = this.handleClick.bind(this);
    this.listOfTabs = this.listOfTabs.bind(this);
  }

  handleClick(value) {
    this.setState({
      fontToggleValue: value,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.fontToggleValue !== this.state.fontToggleValue) {
      this.setState({
        selectedFonts: this.props.fonts.filter((item) => item.font_source == this.state.fontToggleValue),
      });
    }
  }

  listOfTabs() {
    const isCustomFontsAvailable = this.props.fonts.some((font) => font.font_source === 'custom');
    if (this.props.integration && isCustomFontsAvailable) {
      return ['google', 'typekit', 'custom'];
    } else if (this.props.integration) {
      return ['google', 'typekit'];
    } else if (isCustomFontsAvailable) {
      return ['google', 'custom'];
    } else return null;
  }

  render() {
    const { isOpen, close, styleGuide } = this.props;
    const selectedFamilies = styleGuide.fontFamilies;

    return (
      <SideDrawer
        isOpen={isOpen}
        title="Available fonts"
        close={close}
        tabs={this.listOfTabs()}
        toggleValue={this.state.fontToggleValue}
        handleClick={this.handleClick}
      >
        {this.state.selectedFonts.map((family) => {
          const active = selectedFamilies.includes(family.family);
          return (
            <div
              key={family.id}
              className={classnames(styles.family, {
                [styles.active]: active,
              })}
              onClick={(e) => {
                if (!active) this.props.onChange(family.family);
              }}
            >
              <div className={styles.swatch} style={{ fontFamily: family.family }}>
                Ag
              </div>
              <div className={styles.label}>
                {family.source_name || family.family}
                {active && <SvgCheckmark className={styles.checkmark} />}
              </div>
            </div>
          );
        })}
      </SideDrawer>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    fonts: fontsSelector.selectFonts(state),
    integration: integrationsSelectors.selectSiteIntegrationsBySlug(state, ownProps.siteId).typekit,
  };
};

function mapSiteToProps(site) {
  return {
    siteId: site.id,
  };
}

export default compose(withSiteProps(mapSiteToProps), connect(mapStateToProps))(FontFamilySelectionDrawer);
