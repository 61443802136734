import { UVideo } from 'types/UVideo';
import * as actionTypes from './actionTypes';

export interface RequestAll {
  type: 'VIDEOS_REQUEST_ALL';
}
export function requestAll(): RequestAll {
  return {
    type: actionTypes.VIDEOS_REQUEST_ALL,
  };
}

export interface RequestAllIfNeeded {
  type: 'VIDEOS_REQUEST_ALL_IF_NEEDED';
}
export function requestAllIfNeeded(): RequestAllIfNeeded {
  return {
    type: actionTypes.VIDEOS_REQUEST_ALL_IF_NEEDED,
  };
}

// TODO FIX TYPE ON Videos
export interface ReceiveAll {
  payload: { byId: { [id: string]: UVideo }; allIds: string[]; receivedAt: Date };
  type: 'VIDEOS_RECEIVE_ALL';
}

export function receiveAll(byId: { [id: string]: UVideo }, allIds: string[]): ReceiveAll {
  const receivedAt = new Date();

  return {
    type: actionTypes.VIDEOS_RECEIVE_ALL,
    payload: { byId, allIds, receivedAt },
  };
}

export interface ReceiveError {
  payload: { error: string };
  type: 'VIDEOS_RECEIVE_ERROR';
}
export function receiveError(error: string): ReceiveError {
  return {
    type: actionTypes.VIDEOS_RECEIVE_ERROR,
    payload: { error },
  };
}

export interface VideosWatched {
  type: 'VIDEOS_SET_VIDEO_WATCHED';
  id: string;
}
export function setVideoWatched(id: string): VideosWatched {
  return {
    type: actionTypes.VIDEOS_SET_VIDEO_WATCHED,
    id,
  };
}

export interface SetVideoElapsed {
  type: 'VIDEOS_SET_VIDEO_ELAPSED';
  elapsed: number;
  id: string;
}
export function setVideoElapsed(id: string, elapsed: number): SetVideoElapsed {
  return {
    type: actionTypes.VIDEOS_SET_VIDEO_ELAPSED,
    elapsed,
    id,
  };
}

export type VideoMetadata = { [key: string]: { watched: boolean; elapsed: number } };

export interface UpdateVideosWithMetadata {
  type: 'UPDATE_VIDEOS_WITH_METADATA';
  metadata: VideoMetadata;
}
export function updateVideosWithMetadata(metadata: VideoMetadata): UpdateVideosWithMetadata {
  return {
    type: actionTypes.UPDATE_VIDEOS_WITH_METADATA,
    metadata,
  };
}

export type VideoActions =
  | ReceiveError
  | ReceiveAll
  | RequestAll
  | RequestAllIfNeeded
  | VideosWatched
  | UpdateVideosWithMetadata
  | SetVideoElapsed;
