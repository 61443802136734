import React from 'react';
import { compose } from 'redux';

import { someFields } from './utils';
import AsyncMultiSelect from 'components/AsyncMultiSelect';
import withIntegrations from 'containers/withIntegrations';
import FormControl from 'components/base/FormHelpers/FormControl';
import { getMailchimpTags } from 'services/spark-api';

function FormContactList(props) {
  const { form, contactLists, integrationsBySlug } = props;
  const mailchimpTagsOptions =
    form.content?.mailchimp_tags?.map((tag) => ({
      value: tag,
      label: tag,
    })) || [];

  const hasPhone = someFields(form.content.fields, (field) => field.name === 'phone');
  const hasEmail = someFields(form.content.fields, (field) => field.type === 'email');

  if (!form) return null;

  return (
    <>
      {!!hasPhone && !!props.integrationsBySlug['twilio'] && (
        <fieldset>
          <label>SMS Message</label>
          <FormControl
            tag="textarea"
            name="responder.sms"
            value={form.content?.responders?.sms || ''}
            onChange={(e) => props.onUpdate('responders.sms', e.target.value)}
            autoComplete="off"
            cols={65}
            rows={3}
          />
          <small>This message will be sent to the customer's phone number entered in the form after submitting.</small>
        </fieldset>
      )}

      {Boolean(contactLists?.hubspot?.length) && !!hasEmail && (
        <fieldset>
          <label>Add to HubSpot list</label>
          <FormControl
            tag="select"
            name="contact_lists.hubspot"
            onChange={(e) => props.onUpdate(e.target.name, e.target.value)}
            value={form.content.contact_lists.hubspot || ''}
            autoComplete="off"
          >
            <option value="">Select</option>
            {contactLists?.hubspot.map((list) => (
              <option key={list.id} value={list.id}>
                {list.name}
              </option>
            ))}
          </FormControl>
          <small>Add anyone who submits this form to a static list in your HubSpot account.</small>
        </fieldset>
      )}

      {Boolean(contactLists?.mailchimp?.length) && !!hasEmail && (
        <>
          <fieldset>
            <label>Add to Mailchimp list</label>
            <FormControl
              tag="select"
              name="contact_lists.mailchimp"
              onChange={(e) => props.onUpdate(e.target.name, e.target.value)}
              value={form.content.contact_lists.mailchimp || ''}
              autoComplete="off"
            >
              <option value="">Select</option>
              {contactLists?.mailchimp.map((list) => (
                <option key={list.id} value={list.id}>
                  {list.name}
                </option>
              ))}
            </FormControl>
            <small>Add anyone who submits this form to a list in your MailChimp account.</small>
          </fieldset>
          <fieldset>
            <label>Add Mailchimp tag on submit</label>
            <AsyncMultiSelect
              endpoint={(query) => getMailchimpTags(integrationsBySlug?.mailchimp[0]?.id, query)}
              integrationId={integrationsBySlug?.mailchimp[0]?.id}
              defaults={form.content?.mailchimp_tags}
              value={mailchimpTagsOptions}
              onChange={(values, meta) => {
                const { action } = meta;
                if (action === 'remove-value') {
                  props.onUpdate(
                    'mailchimp_tags',
                    form.content.mailchimp_tags.filter((tag) => tag !== meta.removedValue.value)
                  );
                } else if (action === 'select-option') {
                  props.onUpdate('mailchimp_tags', [...(form.content.mailchimp_tags || []), meta.option.value]);
                }
              }}
              onTagCreate={(tag) => {
                props.onUpdate(
                  'mailchimp_tags',
                  form.content.mailchimp_tags ? [...form.content.mailchimp_tags, tag] : [tag]
                );
              }}
            />
          </fieldset>
        </>
      )}

      {Boolean(contactLists?.klaviyo?.length) && !!hasEmail && (
        <fieldset>
          <label>Add to Klaviyo list</label>
          <FormControl
            tag="select"
            name="contact_lists.klaviyo"
            onChange={(e) => props.onUpdate(e.target.name, e.target.value)}
            value={form.content.contact_lists.klaviyo || ''}
            autoComplete="off"
          >
            <option value="">Select</option>
            {contactLists?.klaviyo.map((list) => (
              <option key={list.id} value={list.id}>
                {list.name}
              </option>
            ))}
          </FormControl>
          <small>Add anyone who submits this form to a list in your Klaviyo account.</small>
        </fieldset>
      )}

      {Boolean(contactLists?.activecampaign?.length) && !!hasEmail && (
        <fieldset>
          <label>Add to Active Campaign list</label>
          <FormControl
            tag="select"
            name="contact_lists.activecampaign"
            onChange={(e) => props.onUpdate(e.target.name, e.target.value)}
            value={form.content.contact_lists.activecampaign || ''}
            autoComplete="off"
          >
            <option value="">Select</option>
            {contactLists?.activecampaign.map((list) => (
              <option key={list.id} value={list.id}>
                {list.name}
              </option>
            ))}
          </FormControl>
          <small>Add anyone who submits this form to a list in your Active Campaign account</small>
        </fieldset>
      )}
    </>
  );
}

export default compose(withIntegrations)(FormContactList);
