import * as actionTypes from './actionTypes';

export const fetchList = (payload) => ({
  type: actionTypes.ITEM_URLS_REQUEST,
  payload,
});

export const saveItemUrls = (ids, entities, count) => ({
  type: actionTypes.ITEM_URLS_REQUEST_SUCCESS,
  payload: { ids, entities, count },
});

export const flushCache = (id, callback) => {
  return {
    type: actionTypes.ITEM_URL_FLUSH_CACHE,
    payload: { id },
    callback,
  };
};

export const updateOrCreate = (payload, resolve, reject) => ({
  type: actionTypes.ITEM_URL_CREATE_OR_UPDATE,
  payload,
  resolve,
  reject,
});

export const remove = (id, callback) => ({
  type: actionTypes.ITEM_URL_REMOVE,
  payload: { id },
  callback,
});

export const removeSuccess = (payload) => ({
  type: actionTypes.ITEM_URL_REMOVE_SUCCESS,
  payload,
});

export const createSuccess = (payload) => ({
  type: actionTypes.ITEM_URL_CREATE_SUCCESS,
  payload,
});

export const updateSuccess = (payload) => ({
  type: actionTypes.ITEM_URL_UPDATE_SUCCESS,
  payload,
});
