import * as types from '../actions/actionTypes';

import { createSelector } from 'reselect';
// ----------------------------------------------------------------------------
// Reducer
// ----------------------------------------------------------------------------

const INITIAL_STATE = {
  isFetching: null,
  all: [],
};

export default function currencyReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.CURRENCY_REQUEST_ALL:
      return { ...state, isFetching: true };

    case types.CURRENCY_REQUEST_ALL_SUCCESS:
      return { ...state, isFetching: false, all: action.payload };

    // Default
    // ------------------------------------------------------------------------

    default:
      return state;
  }
}

// ----------------------------------------------------------------------------
// Selectors
// ----------------------------------------------------------------------------

function selectLocalState(globalState) {
  return globalState.currency;
}

export const selectIsFetching = createSelector(selectLocalState, (currency) => currency.isFetching);

export const selectCurrencys = createSelector(selectLocalState, (currency) => currency.all);
