import React from 'react';

interface IYoutubeEmbedProps {
  entity: {
    rawSrc: String;
    src: String;
    type?: String;
  };
}

export const YoutubeEmbed = (props: IYoutubeEmbedProps): React.ReactNode => {
  const { entity } = props;

  const match: Array<any> = entity.src.match(/^youtube:(.+)/);
  if (!match) return null;
  const videoId: String = match[1];

  return (
    <iframe
      src={`https://www.youtube.com/embed/${videoId}`}
      allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
      title="youtube"
      // allowFullScreen={true}
    />
  );
};
