import React from 'react';
import { Button, Label } from '../Toolbar2';

export default function (props) {
  const { IconComponent, labelText, active, ...buttonProps } = props;

  return (
    <Button {...buttonProps}>
      <IconComponent active={active} />
      <Label active={active}>{labelText}</Label>
    </Button>
  );
}
