import React, { useMemo, useState, useEffect, useCallback } from 'react';

import pick from 'lodash/pick';
import memoize from 'memoize-one';
import classNames from 'classnames';
import deepEqual from 'fast-deep-equal';
import { useSelector } from 'react-redux';
import { set, del, update } from 'object-path-immutable';
import { Search as SearchEngine, AllSubstringsIndexStrategy } from 'js-search';
import { getDeviceTypeToSaveTo } from 'components/unstack-components/Component/util/helpers/deviceHelper';

import UTMInput from './UTMInput';
import ValueInput from './ValueInput';
import ParamInput from './ParamInput';
import OperatorPicker from './OperatorInput';
import PropertyPicker from './PropertyPicker';
import useActiveSite from 'hooks/useActiveSite';
import { selectIntegrationBySlug, selectSiteIntegrationsBySlug } from 'reducers/integrationsReducer';

import useColumns from './hook';
import OPERATORS, {
  CONDITIONAL_OPERATOR,
  BASIC_SEGMENTATION,
  SEGMENT_SEGMENTATION,
  CONTACT_PROPERTIES,
  PARAM_PROPERTY,
  PAGE_PROPERTIES,
} from './constants';

import { ReactComponent as IconRemove } from 'assets/images/icon-trash.svg';
import { ReactComponent as IconAdd } from 'assets/images/icon-add-section.svg';
import { ReactComponent as IconDropdown } from 'assets/images/icon-dropdown.svg';

import styles from './Conditions.module.scss';
import { getDevice } from 'reducers/uiReducer';

const memoizeDeep = (func) => memoize(func, deepEqual);

//  Memoized search engine generater for datatable
const getDataSearchEngine = memoizeDeep((columns) => {
  const engine = new SearchEngine('id');
  engine.indexStrategy = new AllSubstringsIndexStrategy();
  engine.addIndex('slug');
  engine.addIndex('id');
  engine.addDocuments([PARAM_PROPERTY, ...columns]);
  return engine;
});

//  Memoized search engine generater for contact
const getContactSearchEngine = memoizeDeep((columns) => {
  const engine = new SearchEngine('id');
  engine.indexStrategy = new AllSubstringsIndexStrategy();
  engine.addIndex('slug');
  engine.addIndex('label');
  engine.addDocuments(columns);
  return engine;
});

export default (props) => {
  const [columns] = useColumns(props.table);
  const { conditional, value, onChange, isDynamicContent, isSectionActive, content, contentKey } = props;
  const { conditions } = conditional;
  const device = useSelector(getDevice);

  //  Search engine documents
  const documents = useMemo(() => {
    const searchDocuments = [];
    if (columns)
      [PARAM_PROPERTY, ...columns].forEach((column) => {
        //  Prepend `data` before slug to make property searchable using "data"
        column.slug = `data.${column.slug}`;
        const searchDocument = pick(column, ['data_type', 'id', 'slug']);
        searchDocument.type = 'database';
        searchDocuments.push(searchDocument);
      });

    return searchDocuments;
  }, [columns]);

  const activeSite = useActiveSite();
  const klaviyoIntegration = useSelector((state) =>
    selectIntegrationBySlug(
      state,
      //  @ts-ignore
      activeSite.id,
      'klaviyo'
    )
  );
  const segmentIntegration = useSelector((state) =>
    selectIntegrationBySlug(
      state,
      //  @ts-ignore
      activeSite.id,
      'segment'
    )
  );
  const isKlaviyoConnected = Boolean(klaviyoIntegration);
  const isSegmentConnected = Boolean(segmentIntegration);

  const dataPropertyEngine = getDataSearchEngine(documents);
  const contactPropertyEngine = getContactSearchEngine(CONTACT_PROPERTIES);
  const segmentPropertyEngine = getContactSearchEngine(
    isSegmentConnected ? SEGMENT_SEGMENTATION : isKlaviyoConnected ? BASIC_SEGMENTATION : []
  );

  return (
    <div className={styles.container} data-test-id="conditions-block-container">
      {conditions.length > 1 && (
        <div className={styles.logicalOperator} data-test-id="logical-operator">
          Match
          <span
            onClick={() => {
              const newValue = set(value, 'conditional', {
                ...conditional,
                logical_operator: conditional.logical_operator === 'all' ? 'any' : 'all',
              });
              const splitKey = contentKey.split('.');
              onChange(
                set(content, [getDeviceTypeToSaveTo(device, true), ...splitKey.slice(1)], newValue),
                `content.${splitKey[0]}`
              );
            }}
          >
            {conditional.logical_operator} <IconDropdown />
          </span>
          conditions...
        </div>
      )}
      {conditions.map((condition, idx) => (
        <Condition
          isSectionActive={isSectionActive}
          operator={conditional.logical_operator}
          idx={idx}
          key={`${idx}+${condition.property}`}
          condition={condition}
          dataPropertyEngine={dataPropertyEngine}
          contactPropertyEngine={contactPropertyEngine}
          segmentPropertyEngine={segmentPropertyEngine}
          fields={[
            PARAM_PROPERTY,
            ...PAGE_PROPERTIES,
            ...(!!(isSegmentConnected && SEGMENT_SEGMENTATION)
              ? SEGMENT_SEGMENTATION
              : !!(isKlaviyoConnected && BASIC_SEGMENTATION)
              ? BASIC_SEGMENTATION
              : []),
            ...(!!(isDynamicContent && columns) ? columns : []),
            ...CONTACT_PROPERTIES,
          ]}
          conditionsLength={conditions.length}
          value={value}
          onChange={onChange}
          content={content}
          contentKey={contentKey}
        />
      ))}
    </div>
  );
};

function Condition(props) {
  const {
    idx,
    condition,
    fields,
    operator,
    dataPropertyEngine,
    contactPropertyEngine,
    segmentPropertyEngine,
    onChange,
    value,
    conditionsLength,
    isSectionActive,
    content,
    contentKey,
  } = props;

  const [cursor, setCursor] = useState(0);
  const [hovered, setHovered] = useState(undefined);
  const [temporary, setTemporary] = useState(undefined);
  const [operators, setOperators] = useState(undefined);
  const [drawerIsOpen, setDrawerIsOpen] = useState(false);
  const device = useSelector(getDevice);

  const handleOpen = () => {
    setDrawerIsOpen('operator');
    const index = operators.findIndex(
      (element) => element[0] === condition.operator && element[2] === condition.is_positive_matching
    );
    setCursor(index !== -1 ? index : 0);
  };

  useEffect(() => {
    if (drawerIsOpen) {
      setTemporary(operators[cursor][1]);
    }
  }, [cursor]);

  const handleUpdate = useCallback(
    (newValue) => {
      const splitKey = contentKey.split('.');
      onChange(
        set(content, [getDeviceTypeToSaveTo(device, true), ...splitKey.slice(1)], newValue),
        `content.${splitKey[0]}`
      );
    },
    [contentKey, content, device]
  );

  return (
    <div className={styles.conditions} data-test-id="condition-row">
      <div className={styles.conditionContainer}>
        <span className={styles.condition} data-test-id="condition">
          {idx ? CONDITIONAL_OPERATOR[operator] : 'if'}
        </span>
      </div>
      <PropertyPicker
        isSectionActive={isSectionActive}
        dataPropertyEngine={dataPropertyEngine}
        contactPropertyEngine={contactPropertyEngine}
        segmentPropertyEngine={segmentPropertyEngine}
        fields={fields}
        condition={condition}
        value={value}
        idx={idx}
        onChange={onChange}
        content={content}
        contentKey={contentKey}
      />
      {condition.isUtm && (
        <UTMInput
          isSectionActive={isSectionActive}
          visit={condition.visit}
          utm={condition.utm}
          setUtm={(utm) => {
            const newValue = update(value, ['conditional', 'conditions', idx], (condition) => ({
              ...condition,
              property: `${condition.property}_${utm}`,
              utm,
            }));
            handleUpdate(newValue);
          }}
          setVisit={(visit) => {
            const newValue = update(value, ['conditional', 'conditions', idx], (condition) => ({
              ...condition,
              visit,
              operator: 'equals',
            }));
            handleUpdate(newValue);
          }}
        />
      )}
      {condition.id === 'url.query.params' && (
        <ParamInput
          isSectionActive={isSectionActive}
          param={condition.param || condition.property?.split('.')[1]}
          onChange={(param) => {
            const newValue = update(value, `conditional.conditions.${idx}`, (condition) => ({
              ...condition,
              param,
              property: `param.${param}`,
            }));
            handleUpdate(newValue);
          }}
        />
      )}
      {condition.id === 'segment.trait' && (
        <ParamInput
          isSectionActive={isSectionActive}
          param={condition.trait}
          placeholder="Trait"
          onChange={(trait) => {
            const newValue = update(value, `conditional.conditions.${idx}`, (condition) => ({
              ...condition,
              trait,
            }));
            handleUpdate(newValue);
          }}
        />
      )}
      {Boolean(condition.data_type !== undefined) && (
        <div className={classNames(styles.inputContainer, styles.isDropdown)}>
          <input
            onChange={(_e) => {}}
            className={styles.operator}
            value={
              temporary ||
              (condition.data_type &&
                OPERATORS[`${!condition?.is_positive_matching ? '-' : ''}${condition.data_type}`][
                  condition.operator
                ]) ||
              ''
            }
            type="text"
            aria-label="operator"
            placeholder="Operator"
            onFocus={() => {
              handleOpen();
            }}
            onClick={() => {
              handleOpen();
            }}
            onBlur={() => {
              setDrawerIsOpen(false);
              setTemporary(undefined);
            }}
            onKeyDown={(e) => {
              if (e.keyCode === 13) {
                if (drawerIsOpen === 'operator') {
                  const newValue = update(value, `conditional.conditions.${idx}`, (condition) => ({
                    ...condition,
                    operator: operators[cursor][0],
                    is_positive_matching: !!operators[cursor][2],
                  }));
                  handleUpdate(newValue);
                  setHovered(undefined);
                  setDrawerIsOpen(false);
                  setTemporary(undefined);
                } else {
                  handleOpen();
                }
              } else if (e.keyCode === 9) {
                if (drawerIsOpen === 'operator' && operators[cursor]) {
                  handleUpdate(
                    update(value, `conditional.conditions.${idx}`, (condition) => ({
                      ...condition,
                      operator: operators[cursor][0],
                      is_positive_matching: !!operators[cursor][2],
                    }))
                  );
                  setHovered(undefined);
                  setDrawerIsOpen(false);
                  setTemporary(undefined);
                }
              } else if (e.keyCode === 27) {
                setHovered(undefined);
                setDrawerIsOpen(false);
                setTemporary(undefined);
              } else if (e.keyCode === 32) {
                if (drawerIsOpen !== 'operator') handleOpen();
              } else if (e.keyCode === 35) {
                if (drawerIsOpen === 'operator') {
                  e.preventDefault();
                  setCursor(operators.length - 1);
                }
              } else if (e.keyCode === 36) {
                if (drawerIsOpen === 'operator') {
                  e.preventDefault();
                  setCursor(0);
                }
              } else if (e.keyCode === 38) {
                if (drawerIsOpen !== 'operator') handleOpen();
                else if (cursor > 0) setCursor(cursor - 1);
                else e.preventDefault();
              } else if (e.keyCode === 40) {
                if (drawerIsOpen !== 'operator') handleOpen();
                else if (cursor < operators.length - 1) setCursor(cursor + 1);
                else e.preventDefault();
              }
            }}
          />
          <OperatorPicker
            onChange={(operator, is_positive_matching) => {
              const newValue = update(value, `conditional.conditions.${idx}`, (condition) => ({
                ...condition,
                operator,
                is_positive_matching: !!is_positive_matching,
              }));
              handleUpdate(newValue);
            }}
            dataType={condition.data_type}
            isOpen={drawerIsOpen === 'operator' && !!condition.data_type}
            cursor={cursor}
            setCursor={setCursor}
            hovered={hovered}
            setHovered={setHovered}
            setOperators={setOperators}
            setDrawerIsOpen={setDrawerIsOpen}
          />
        </div>
      )}
      <ValueInput
        operator={condition?.operator}
        onChange={(newValue) => handleUpdate(newValue)}
        condition={condition}
        idx={idx}
        conditionValue={value}
      />
      {conditionsLength > 1 && (
        <IconRemove
          onClick={() => {
            const newValue = del(value, `conditional.conditions.${idx}`);
            handleUpdate(newValue);
          }}
          data-test-id="remove-condition"
        />
      )}
      {idx === conditionsLength - 1 && (
        <IconAdd
          onClick={() => {
            const newValue = set(value, 'conditional', {
              ...value.conditional,
              conditions: [...value.conditional.conditions, {}],
            });
            handleUpdate(newValue);
          }}
          data-test-id="add-condition"
        />
      )}
    </div>
  );
}
