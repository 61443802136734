import React, { useState } from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { Checkbox } from '../../Checkbox';
import { ReactComponent as SvgNotification } from '../../../assets/images/icon-notifications.svg';
import { update } from '../../../actions/accountUserActions';
import { updateNotificationPermission } from '../../../actions/contactsActions';
import { requestNotificationPermission } from '../Contacts/NotificationPermission';
import get from 'lodash/get';

import styles from './Notifications.module.scss';

const mapStateToProps = (state) => ({
  isFetching: state.accountUsers.isFetching,
  currentUser: state.accountUsers.byId[state.accountUsers.currentUser],
});

export default connect(mapStateToProps, {
  update,
  updateNotificationPermission,
})(({ currentUser = {}, update, isFetching }) => {
  const hasNotifications =
    !('Notification' in window) || ['granted', 'denied'].indexOf(window.Notification.permission) >= 0;

  const [notificationsEnabled, setNotificationsEnabled] = useState(hasNotifications);
  const enabled = !!get(currentUser, 'settings.notifications.new_contacts');
  return (
    <>
      <section id="team">
        <header>
          <h1>Notifications</h1>
        </header>
        <div className={styles.notificationContainer}>
          <Checkbox
            onClick={() =>
              isFetching
                ? () => {}
                : update({
                    ...currentUser,
                    settings: {
                      notifications: {
                        new_contacts: !enabled,
                      },
                    },
                  })
            }
            checked={enabled}
          />
          <span>Email me when a new customer is added</span>
        </div>
        {!notificationsEnabled && (
          <div className={classnames(styles.notificationContainer, styles.secondaryNotification)}>
            <SvgNotification />
            <span>Enable browser notifications for new customers</span>
            <input
              value="Enable notifications"
              className="button button-primary"
              onClick={() => {
                requestNotificationPermission();
                setNotificationsEnabled(true);
                window.sprkfe.storage.removeItem('notificationPermission');
              }}
              readOnly="readOnly"
            />
          </div>
        )}
      </section>
    </>
  );
});
