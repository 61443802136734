import React from 'react';
import idx from 'lodash/get';

import UpDownButtons from '../../../Editor/Toolbars/Buttons/UpDownButtons';

const LogoEditorButtons = ({ site, siteUpdaters, target }) => {
  const logo_height = idx(site, `styles.logo.${target}.height`, 32);
  const logo_position = idx(site, `styles.logo.${target}.position`, 0);

  return (
    <>
      <label>Logo height: </label>
      <span>{logo_height}px</span>
      <UpDownButtons
        upProps={{
          onClick: () => siteUpdaters.set(`styles.logo.${target}.height`, logo_height + 1),
        }}
        downProps={{
          onClick: () => siteUpdaters.set(`styles.logo.${target}.height`, logo_height - 1),
        }}
      />
      <label>Position:</label>
      <span>
        {logo_position > 0 && '+'}
        {logo_position < 0 && <>&ndash;</>}
        {Math.abs(logo_position)}
        px
      </span>
      <UpDownButtons
        upProps={{
          onClick: () => siteUpdaters.set(`styles.logo.${target}.position`, logo_position + 1),
        }}
        downProps={{
          onClick: () => siteUpdaters.set(`styles.logo.${target}.position`, logo_position - 1),
        }}
      />
    </>
  );
};

export default LogoEditorButtons;
