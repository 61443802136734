import React, { useEffect } from 'react';
import UnstackSplash from 'components/UnstackSplash';
import { shopifyActivate } from 'services/spark-api';
import { useNavigate, useLocation } from 'react-router';
import QueryString from 'query-string';
import { selectSiteById } from 'reducers/sitesReducer';
import { useSelector } from 'react-redux';

export default () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const params = QueryString.parse(search);
  const site = useSelector((state) => selectSiteById(state, params.siteId || params.site_id));
  const basePath = location.pathname.split('/')[1];

  useEffect(() => {
    async function fetch() {
      const res = await shopifyActivate(params, basePath);
      if (res.ok) {
        if (res.status === 200) {
          navigate(`/${basePath}/survey`, {
            state: {
              accountId: site.account,
              redirectTo: res.json.redirect_to || res.json.redirect_url,
            },
          });
        } else window.location = res.json.redirect_url;
      }
    }

    if (site) fetch();
  }, [site]);

  return (
    <div>
      <UnstackSplash isLoading />
    </div>
  );
};
