import { APIKey } from 'reducers/apiKeysReducer';
import * as actionTypes from './actionTypes';

export interface APIKeysFetchKeys {
  type: 'API_KEYS_FETCH_KEYS';
}
export function fetchKeys() {
  return {
    type: actionTypes.API_KEYS_FETCH_KEYS,
  };
}

export interface APIKeysReceiveKeys {
  type: 'API_KEYS_RECEIVE_KEYS';
  apiKeys: { [key: string]: APIKey };
}
export function receiveKeys(apiKeys: { [key: string]: APIKey }) {
  return {
    type: actionTypes.API_KEYS_RECEIVE_KEYS,
    apiKeys,
  };
}

export interface APIKeysReceiveError {
  type: 'API_KEYS_RECEIVE_KEYS_ERROR';
  error: string;
}
export function receiveKeysError(error: string) {
  return {
    type: actionTypes.API_KEYS_RECEIVE_KEYS_ERROR,
  };
}

export interface APIKeysDeleteKey {
  type: 'API_KEYS_DELETE_KEY';
  key: string;
}
export function deleteAPIKey(key: string) {
  return {
    type: actionTypes.API_KEYS_DELETE_KEY,
    key,
  };
}

export interface APIKeysAddKey {
  type: 'API_KEYS_ADD_KEY';
  key: string;
}
export function addAPIKey(name: string) {
  return {
    type: actionTypes.API_KEYS_ADD_KEY,
    name,
  };
}

export interface ToggleAddAPIKeyDrawer {
  type: 'API_KEYS_TOGGLE_ADD_API_KEY_DRAWER';
  open: boolean;
}
export function toggleAddAPIKeyDrawer() {
  return {
    type: actionTypes.API_KEYS_TOGGLE_ADD_API_KEY_DRAWER,
  };
}

export interface closeAddAPIKeyDrawer {
  type: 'API_KEYS_CLOSE_ADD_API_KEY_DRAWER';
}
export function closeAddAPIKeyDrawer() {
  return {
    type: actionTypes.API_KEYS_CLOSE_ADD_API_KEY_DRAWER,
  };
}

export type APIKeysActions =
  | APIKeysFetchKeys
  | APIKeysReceiveKeys
  | APIKeysReceiveError
  | APIKeysDeleteKey
  | APIKeysAddKey
  | ToggleAddAPIKeyDrawer
  | closeAddAPIKeyDrawer;
