import React from 'react';
import classnames from 'classnames';
import styles from './ConfirmationModal.module.scss';

export default function Modal({ show, children }) {
  return (
    <div className={classnames(styles.modal, { [styles.visible]: show })}>
      <section className={styles.modalMain}>{children}</section>
    </div>
  );
}
