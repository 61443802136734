import * as actionTypes from '../actions/actionTypes';
import { applyOps } from '../lib/immutable-operations/';
import { set } from '../lib/immutable-operations/operations';

// ----------------------------------------------------------------------------
// Reducer
// ----------------------------------------------------------------------------

export default function (state = { type: 'all' }, action) {
  switch (action.type) {
    case actionTypes.SITE_STATISTICS_SET_TYPE:
      return { ...state, type: action.payload };
    case actionTypes.SITE_STATISTICS_REQUEST:
      return applyOps(state, [set([action.payload.type, 'isFetching'], true)]);

    case actionTypes.SITE_STATISTICS_RECEIVE:
      return applyOps(state, [
        set([action.payload.type, 'isFetching'], false),
        set([action.payload.type, 'fetchedAt'], action.payload.fetchedAt),
        set([action.payload.type, 'data'], action.payload.data),
      ]);

    default:
      return state;
  }
}

// ----------------------------------------------------------------------------
// Selectors
// ----------------------------------------------------------------------------

export function selectLocalState(state) {
  return state.siteStatistics;
}

export function selectTypeIsFetching(state, type) {
  const localState = selectLocalState(state);
  return localState[type] && localState[type].isFetching;
}

export function selectTypeIsLoaded(state, type) {
  const localState = selectLocalState(state);
  return localState[type] && localState[type].data != null;
}

export function selectTypeFetchedAt(state, type) {
  const localState = selectLocalState(state);
  return localState[type] && localState[type].fetchedAt;
}

export function selectTypeData(state, type) {
  const localState = selectLocalState(state);
  const typeState = localState[type];

  if (typeState == null) return null;
  else return typeState.data;
}

export function selectType(state) {
  const localState = selectLocalState(state);
  return localState.type;
}
