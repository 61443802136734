import React, { useState, useEffect, useMemo, useCallback } from 'react';
import SideDrawer from 'components/base/SideDrawer';
import useBooleanState from 'hooks/useBooleanState';
import { authorizedGet, authorizedPost } from 'services/spark-api';
import styles from './VersionSelector.module.scss';
import usePrevious from 'hooks/usePrevious';
import format from 'date-fns/fp/format';
import { useDispatch, useSelector } from 'react-redux';
import { selectPagePreviewUrl } from 'reducers/pagesReducer';
import { selectArticlePreviewUrl } from 'reducers/articlesReducer';
import { processAndReceiveRawResponse as receivePageResponse } from 'actions/pageActions';
import { processAndReceiveRawResponse as receiveArticleResponse } from 'actions/articleActions';
import Spinner from 'components/base/Spinner';

function VersionSelector(props) {
  const { itemId, onRestoreSuccess, itemType } = props;
  const [isOpen, open, close] = useBooleanState(false);

  return (
    <>
      <button className={styles.button} onClick={open}>
        Versions
      </button>
      <SideDrawer
        isOpen={isOpen}
        close={close}
        title="Versions"
        avoidBackdrop
        render={() => <Versions itemId={itemId} onRestoreSuccess={onRestoreSuccess} itemType={itemType} />}
      />
    </>
  );
}

export default VersionSelector;

function Versions(props) {
  const { itemId, onRestoreSuccess, itemType } = props;
  const [versions, fetchStatus, errors] = useVersions(itemId);
  const [restore, restoreStatus] = useRestore(itemType);

  usePrevious(restoreStatus.succeeded, (previous, current) => {
    if (!previous && current) {
      onRestoreSuccess();
    }
  });

  const sortedVersions = useMemo(() => {
    const formattedVersions = versions.map((version) => {
      const formattedVersion = {
        ...version,
        createdAt: new Date(version.created_at),
        restore: () => restore(itemId, version.id),
      };
      delete formattedVersion.created_at;

      return formattedVersion;
    });
    return formattedVersions.sort((a, b) => b.createdAt - a.createdAt);
  }, [versions, itemId, restore]);

  // Render if error
  if (fetchStatus.failed || restoreStatus.failed) {
    return (
      <div>
        <small className="errorMessage text-center">
          Uh oh, there's been an error. Our engineers have been notified.
        </small>
      </div>
    );
  }

  // Render if loading
  if (fetchStatus.pending || restoreStatus.pending || restoreStatus.succeeded) {
    return <Spinner className={styles.spinner} />;
  }

  // Render list
  return (
    <div>
      <p>Click on past published versions of this page to preview them or restore them.</p>
      <div className={styles.versions}>
        <table>
          <tbody>
            {sortedVersions.map((version) => (
              <Version key={version.version} itemId={itemId} version={version} itemType={itemType} />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

const formatDate = format('PP');
const formatTime = format('p');

const previewUrlSelectors = {
  article: selectArticlePreviewUrl,
  page: selectPagePreviewUrl,
};

function Version(props) {
  const { itemId, version, itemType } = props;

  const previewUrl = version.preview_url;

  const handleClickRestore = (e) => {
    e.preventDefault();
    version.restore();
  };

  return (
    <tr key={version.id}>
      <td className={styles.dateCol}>
        <div className={styles.date}>{formatDate(version.createdAt)}</div>
        <div className={styles.time}>{formatTime(version.createdAt)}</div>
      </td>
      <td>
        <a href={previewUrl} target="_blank" rel="noopener noreferrer">
          Preview
        </a>
      </td>
      <td>
        <a href="#" onClick={handleClickRestore}>
          Restore
        </a>
      </td>
    </tr>
  );
}

function useVersions(itemId) {
  const prevItemId = usePrevious(itemId);

  const [versions, setVersions] = useState([]);
  const [status, setStatus] = useStatus('PENDING');
  const [errors, setErrors] = useState();

  useEffect(() => {
    if (itemId && prevItemId !== itemId) {
      setStatus('PENDING');

      authorizedGet(`item/${itemId}/versions/`)
        .then((response) => {
          if (response.ok) {
            setStatus('SUCCEEDED');
            setVersions(response.json);
          } else {
            setStatus('FAILED');
            setVersions([]);
            if (response.status >= 400 && response.status < 500) setErrors(response.json);
          }
        })
        .catch((e) => {
          console.error(e);
          setStatus('FAILED');
          setVersions([]);
        });
    }
  }, [itemId, setStatus]);

  return [versions, status, errors];
}

const useRestore = (itemType) => {
  const dispatch = useDispatch();
  const [status, setStatus] = useStatus('IDLE');

  const restore = (itemId, versionId) => {
    if (!status.pending) {
      setStatus('PENDING');

      const url = `item/${itemId}/restore/`;
      const payload = { version: versionId };

      authorizedPost(url, payload)
        .then((response) => {
          if (response.ok) {
            if (itemType === 'page') dispatch(receivePageResponse(response.json));
            else if (itemType === 'article') dispatch(receiveArticleResponse(response.json));
            setStatus('SUCCEEDED');
          } else {
            setStatus('FAILED');
          }
        })
        .catch((e) => {
          console.error(e);
          setStatus('FAILED');
        });
    }
  };

  return [restore, status];
};

const useStatus = (initStatus) => {
  const [status, setStatus] = useState(initStatus);

  const statusObject = useMemo(
    () => ({
      idle: status === 'IDLE',
      pending: status === 'PENDING',
      failed: status === 'FAILED',
      succeeded: status === 'SUCCEEDED',
    }),
    [status]
  );

  return [statusObject, setStatus];
};
