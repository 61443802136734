import Quill from '../quill-1.3.7/quill';
const BlockEmbed = Quill.import('blots/block/embed');

class TwitterEmbedBlot extends BlockEmbed {
  static create(value) {
    let node = super.create();

    // Store the raw source (e.g. smartUrl) in the element's dataset so it can
    // be retrieved in the blot's value function.
    const rawSrc = value.src || '';
    node.dataset.rawSrc = rawSrc;

    const match = value.src.match(/^(twitter):(.+)/);
    if (match) {
      const source = match[1];
      if (source === 'twitter') {
        const [id, user] = match[2].split('-');
        const blockquote = document.createElement('blockquote');
        blockquote.setAttribute('class', 'twitter-tweet');
        const anchor = document.createElement('a');
        anchor.setAttribute('href', `https://twitter.com/${user}/status/${id}?ref_src=twsrc%5Etfw`);

        const script = document.createElement('script');
        script.setAttribute('src', 'https://platform.twitter.com/widgets.js');
        blockquote.appendChild(anchor);
        node.appendChild(blockquote);
        node.appendChild(script);
      }
    }

    return node;
  }

  static value(node) {
    const value = {};

    // Retrieve the raw source (e.g. smartUrl) from the element's dataset.
    value.src = node.dataset.rawSrc;

    return value;
  }
}

TwitterEmbedBlot.blotName = 'twitter_embed';
TwitterEmbedBlot.tagName = 'div';
TwitterEmbedBlot.className = 'ql-twitter';

export default TwitterEmbedBlot;
