import React from 'react';
import { Button, Tray } from '../../Toolbars2/Toolbar2';

import { IconProperties } from '../../Toolbars2/icons';
import {
  hideProperty,
  PROPERTIES_FIELD_MAP,
} from 'components/unstack-components/Component/elements/block/SectionElement/Toolbar/Properties';
import { set } from 'object-path-immutable';
import styles from './PropertiesButton.module.scss';
import { UBoxInfo, USectionDevices } from 'types/USection';
import { getDeviceTypeToSaveTo } from 'components/unstack-components/Component/util/helpers/deviceHelper';
import { useSelector } from 'react-redux';
import { getDevice } from 'reducers/uiReducer';
import classNames from 'classnames';

type Props = {
  onChange: (content: USectionDevices, key?: string, multi?: boolean) => void;
  properties: { [key: string]: any }[];
  dataRef: UBoxInfo;
  hideIcon?: boolean;
  content: USectionDevices;
  contentKey: string;
};

function PropertiesButton(props: Props) {
  const { onChange, content, properties, hideIcon, dataRef, contentKey } = props;
  const device = useSelector(getDevice);
  const splitKey = contentKey.split('.');

  return (
    <Tray
      renderIcon={() => (hideIcon ? <></> : <IconProperties />)}
      trayLabel={hideIcon ? '' : 'PROPERTIES'}
      startOpen={true}
      showInvisible={hideIcon}
      adjustSizeToTray={true}
      renderTray={() => (
        <>
          {properties.map((property) => {
            const FieldType = PROPERTIES_FIELD_MAP[property.type];

            if (hideProperty(property, 'defaultBox')) {
              return null;
            }

            return (
              <div
                className={classNames([
                  hideIcon ? styles.bgColorContentHide : styles.bgColorContent,
                  { [styles.noPadding]: property.type === 'divider' },
                ])}
              >
                <FieldType
                  //  @ts-ignore
                  // value={value?.properties?.[property.name]}
                  value={dataRef?.properties?.[property.name]}
                  //  @ts-ignore
                  property={property}
                  //  @ts-ignore
                  onChange={(property, newValue) => {
                    onChange(
                      set(
                        content,
                        [getDeviceTypeToSaveTo(device, true), ...splitKey.slice(1), 'properties', `${property}`],
                        newValue
                      ),
                      `content.${splitKey[0]}`
                    );
                  }}
                  className={styles.inputGroup}
                />
              </div>
            );
          })}
        </>
      )}
    />
  );
}

export default PropertiesButton;
