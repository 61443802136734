import React from 'react';
import ReactDOM from 'react-dom';
import { CSSTransition } from 'react-transition-group';

import { ReactComponent as SvgIconClose } from '../../assets/images/icon-cancel.svg';

import styles from './Modal.module.scss';

class Modal extends React.PureComponent {
  render() {
    const { className = '' } = this.props;
    return (
      <Portal>
        <CSSTransition
          in={this.props.isOpen}
          timeout={{
            enter: 200,
            exit: 100,
          }}
          classNames={styles}
          mountOnEnter
          unmountOnExit
        >
          {(status) => (
            <div
              className="admin"
              onClick={(e) => e.stopPropagation()}
              onKeyPress={(e) => e.stopPropagation()}
              onKeyUp={(e) => e.stopPropagation()}
              onKeyDown={(e) => e.stopPropagation()}
              onSubmit={(e) => e.stopPropagation()}
            >
              <div className={styles.backdrop} onClick={() => this.props.close()}>
                <div className={`${styles.modal} ${className}`}>
                  <span className={styles.modalClose} onClick={this.props.close}>
                    <SvgIconClose />
                  </span>
                  <div onClick={(e) => e.stopPropagation()} className={styles.modalBody}>
                    {this.props.render && this.props.render()}
                    {!this.props.render && this.props.children}
                  </div>
                </div>
              </div>
            </div>
          )}
        </CSSTransition>
      </Portal>
    );
  }
}

export default Modal;

const Portal = (props) => {
  return ReactDOM.createPortal(props.children, document.body);
};
