import { createSelector } from 'reselect';

import * as types from '../actions/actionTypes';
import { selectJWT } from './accountReducer';
import { selectHomePage } from './pagesReducer';
import { selectAllById as selectMediaById } from './mediaReducer';

import { NAKED_URL_REGEXP, PAGE_URL_REGEXP, BLOG_URL_REGEXP, ARTICLE_URL_REGEXP, MEDIA_URL_REGEXP } from '../constants';

import isString from 'lodash/isString';

// ----------------------------------------------------------------------------
// Reducer
// ----------------------------------------------------------------------------

export default function uiReducer(state = { device: 'desktop', mobileRotated: false, tabletRotated: false }, action) {
  switch (action.type) {
    case types.SECTIONS_REORDER:
      return {
        ...state,
        sectionInMotion: action.payload.sectionId,
      };
    case types.CONNECTION_ERROR:
      return {
        ...state,
        connectionError: true,
      };
    case types.CLEAR_CONNECTION_ERROR:
      return {
        ...state,
        connectionError: false,
      };
    case types.DEVICE:
      return {
        ...state,
        device: action.payload.device,
      };
    case types.DEVICE_DIMENSIONS:
      return {
        ...state,
        width: action.payload.width,
      };
    case types.DEVICE_ROTATION:
      const key = action.payload.key === 'mobile' ? 'mobileRotated' : 'tabletRotated';
      return {
        ...state,
        [key]: action.payload.rotated,
      };

    default:
      return state;
  }
}

// ----------------------------------------------------------------------------
// Selectors
// ----------------------------------------------------------------------------

export function selectUiState(globalState) {
  return globalState.ui;
}

export function getDevice(globalState) {
  return globalState.ui.device;
}

export function getMobileRotated(globalState) {
  return globalState.ui.mobileRotated;
}

export function getTabletRotated(globalState) {
  return globalState.ui.tabletRotated;
}

export function getStateDeviceWidth(globalState) {
  return globalState.ui.width;
}

export const getDeviceWidth = createSelector(
  getDevice,
  getStateDeviceWidth,
  getMobileRotated,
  getTabletRotated,
  (device, deviceWidth, mobileRotated, tabletRotated) => {
    if (deviceWidth) return deviceWidth;
    return device === 'mobile'
      ? mobileRotated
        ? 667
        : 375
      : device === 'tablet'
      ? tabletRotated
        ? 1024
        : 768
      : '100%';
  }
);

/**
 * Selector that returns the helper function parseSmartUrl
 * @param {object} state Redux state
 * @returns {func} parseSmartUrl
 */
export const selectParseSmartUrl = createSelector(selectJWT, selectMediaById, (jwtToken, mediaById) => {
  /**
   * parses a smartUrl and returns a plain URL
   * @param  {string} smartUrl
   * @return {string} Plain URL
   */
  function parseSmartUrl(smartUrl, isThumbnail = false) {
    /* eslint-disable no-cond-assign */

    if (!isString(smartUrl)) return null;

    let pageMatch, blogMatch, articleMatch, mediaMatch, nakedMatch;

    // Page url
    if ((pageMatch = smartUrl.match(PAGE_URL_REGEXP))) {
      const pageId = pageMatch[1];
      return `/editor/${pageId}`;
    }

    // Blog url
    if ((blogMatch = smartUrl.match(BLOG_URL_REGEXP))) {
      const blogId = blogMatch[1];
      return `/editor/blog/${blogId}`;
    }

    // Page url
    if ((articleMatch = smartUrl.match(ARTICLE_URL_REGEXP))) {
      const articleId = articleMatch[1];
      return `/editor/blog-post/${articleId}`;
    }

    //  Media thumbnail
    if (isThumbnail && (mediaMatch = smartUrl.match(MEDIA_URL_REGEXP))) {
      const mediaId = mediaMatch[1];
      const media = mediaById[mediaId];

      if (media) return media.thumbnail;
      else return '';
    }

    // Media url
    else if ((mediaMatch = smartUrl.match(MEDIA_URL_REGEXP))) {
      const mediaId = mediaMatch[1];
      const media = mediaById[mediaId];

      if (media) return media.file;
      else return '';
    }

    // Youtube url
    // else if (mediaMatch = smartUrl.match(/^youtube:(.+)/)) {
    //   const videoId = mediaMatch[1];
    //   return `https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`;
    //   // <iframe src=`https://www.youtube.com/embed/${videoId}` />
    // }

    // Naked url  ( i.e. no http(s) )
    else if ((nakedMatch = smartUrl.match(NAKED_URL_REGEXP))) {
      const nakedUrl = nakedMatch[0];
      return `https://${nakedUrl}`;
    }

    // Plain url
    else {
      return smartUrl;
    }

    /* eslint-enable no-cond-assign */
  }

  window.parseSmartUrl = parseSmartUrl;
  return parseSmartUrl;
});

export const selectHomePageUrl = createSelector(selectHomePage, selectParseSmartUrl, (homePage, parseSmartUrl) => {
  if (homePage == null) return null;
  return parseSmartUrl(`page:${homePage.id}`);
});
