// Return offset of element with respect to the document

export default function getDocumentOffset(el) {
  var rect = el.getBoundingClientRect(),
    scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
    scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  return { top: rect.top + scrollTop, left: rect.left + scrollLeft };
}

export function getIFrameDocumentOffset(el) {
  const iframe = document.getElementById('editorIframe');
  const iframeDoc = iframe.contentWindow.document;
  var rect = el.getBoundingClientRect(),
    scrollLeft = window.pageXOffset || iframeDoc.body.scrollLeft;

  const iFrameOffsetLeft = (document.body.clientWidth - iframe.getBoundingClientRect().width) / 2;
  // 72 coming from header top of other document
  return { top: rect.top + 72, left: rect.left + scrollLeft + iFrameOffsetLeft };
}
