import Quill from '../quill-1.3.7/quill';

const BlockEmbed = Quill.import('blots/block/embed');

class FormEmbedBlot extends BlockEmbed {
  static create(value) {
    let node = super.create();

    // Add class names and attributes
    node.classList.add('quill-form-embed-container');
    node.classList.add('text-center');
    node.setAttribute('contenteditable', 'false');

    // Store the form entity object on the node itself so that we can retreive it on `FormEmbedBlot.value`
    node.formEntity = value;

    return node;
  }

  static value(node) {
    const value = node.formEntity || {};
    return value;
  }
}

FormEmbedBlot.blotName = 'form_embed';

window.customElements.define('unstack-form-embed', class extends HTMLElement {});
FormEmbedBlot.tagName = 'unstack-form-embed';

export default FormEmbedBlot;
