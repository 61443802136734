import React from 'react';
import useUrlSite from '../../../../hooks/useUrlSite';
import Table from '../../../react-table/Table';
import { Link } from 'react-router-dom';
import styles from './ContentTestReport.module.scss';

// Columns
const columns = [
  {
    Header: 'Page',
    accessor: 'item_name',
    Cell: ({ original: { item_name, item_type_id, item_id } }) => (
      <Link to={useEditorPath(item_type_id, item_id)}>{item_name}</Link>
    ),
    maxWidth: 150,
    sortable: true,
  },
  {
    Header: 'Test',
    accessor: 'name',
    maxWidth: 150,
    sortable: true,
  },
  {
    Header: 'Status',
    accessor: 'status',
    maxWidth: 100,
    Cell: ({ value }) => {
      return value == null ? 'n/a' : value;
    },
    sortable: true,
  },
  {
    Header: 'Started',
    accessor: 'started_on',
    maxWidth: 100,
    Cell: ({ value }) => (value == null ? 'n/a' : value),
    sortable: true,
  },
  {
    Header: 'Ended',
    accessor: 'ended_on',
    maxWidth: 100,
    Cell: ({ value }) => (value == null ? 'n/a' : value),
    sortable: true,
  },
  {
    Header: 'Variants',
    accessor: 'variants',
    maxWidth: 1000,
    minWidth: 500,
    style: { padding: 0 },
    Cell: ({ value }) => <VariantTable variants={value} />,
    sortable: false,
  },
  {
    Header: 'Confidence',
    accessor: 'pvalue',
    maxWidth: 100,
    Cell: ({ value }) =>
      value == null ? (
        'n/a'
      ) : (
        <div>
          <span>{(100 - value * 100).toFixed(1)}%</span>
          <br />
          <span style={{ fontSize: '0.8em' }}>p = {value}</span>
        </div>
      ),
    sortable: false,
  },
];

function VariantTable(props) {
  const { variants } = props;
  let variant_rows = variants.map((variant) => (
    <tr key={variant.id}>
      <td>{variant.name}</td>
      <td>{variant.uniques == null ? 'n/a' : variant.uniques}</td>
      <td>{variant.conversions == null ? 'n/a' : variant.conversions}</td>
      <td>
        {variant.confidence_interval == null
          ? 'n/a'
          : (variant.confidence_interval[0] * 100).toFixed(2) +
            '% - ' +
            (variant.confidence_interval[1] * 100).toFixed(2) +
            '%'}
      </td>
      <td className={styles.check}>{variant.winner ? '✓' : ''}</td>
    </tr>
  ));

  return (
    <div>
      <table className={styles.variants}>
        <colgroup>
          <col width="30%" />
          <col width="15%" />
          <col width="15%" />
          <col width="30%" />
          <col width="10%" />
        </colgroup>
        <thead>
          <tr>
            <th>Name</th>
            <th>Uniques</th>
            <th>Conversions</th>
            <th>Conv. Rate CI</th>
            <th>Winner?</th>
          </tr>
        </thead>
        <tbody>{variant_rows}</tbody>
      </table>
    </div>
  );
}

export function ContentTestReport(props) {
  const { data, isLoading } = props;

  return (
    <Table
      columns={columns}
      data={data}
      defaultSorted={[{ id: 'started_on', desc: true }, { id: 'ended_on' }]}
      loading={isLoading}
      showViewAll={true}
    />
  );
}

function useEditorPath(item_type, item_id) {
  const urlSite = useUrlSite();
  let path = `/${urlSite}/editor/`;

  switch (item_type) {
    case 'blog':
      path += 'blog/;';
      break;
    case 'article':
      path += 'blog-post/';
      break;
    default:
  }

  return path + item_id;
}
