import { useSelector } from 'react-redux';
import useUrlSite from './useUrlSite';
import { selectSiteByDomain } from '../reducers/sitesReducer';
import isFunction from 'lodash/isFunction';

type MapSiteToProps = (mapSiteToProps: SiteType) => any;

export default function useActiveSite(mapSiteToProps?: MapSiteToProps) {
  const urlSite = useUrlSite();
  return useSelector((state) => {
    // @ts-ignore
    const site = selectSiteByDomain(state, urlSite);
    if (isFunction(mapSiteToProps)) return mapSiteToProps(site);
    else return site;
  });
}
