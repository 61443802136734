/* eslint-disable jsx-a11y/anchor-is-valid */

import React from 'react';
import { compose } from 'redux';
import withSiteProps from '../../containers/withSiteProps';
import { connect } from 'react-redux';
import * as siteSelectors from '../../reducers/sitesReducer';
import { selectHasPermission } from '../../reducers/policyReducer';
import * as integrationSelectors from '../../reducers/integrationsReducer';
import DocumentTitle from 'react-document-title';
import * as integrationActions from 'actions/integrationActions';
import Zapier from './Integrations/Zapier';
import Shopify from './Integrations/Shopify';
import Search from 'components/Search';
import Salesforce from './Integrations/Salesforce';
import Spinner from '../base/Spinner';
import Integration from './Integrations/Integration';
import CustomCodeSection from './Integrations/custom/CustomCodeSection';

import styles from './Integrations.module.scss';
import ConnectedModal from './Integrations/ConnectedModal';
import CheckPermissionOnClick from 'components/SubscriptionManager/CheckPermissionOnClick';

function mapSiteToProps(site) {
  return { siteName: site.name };
}

function mapStateToProps(state, ownProps) {
  return {
    // Zap beta launched for all paid customers
    hasZapierAccess: true, // zapSites.includes(site.id),
    hasPermission: selectHasPermission(state),
    isFetching:
      integrationSelectors.selectIntegrationTypeIsFetching(state) || integrationSelectors.selectIsFetching(state),
    integrationTypes: integrationSelectors.selectIntegrationTypes(state),
    searchedIntegrations: integrationSelectors.selectSearchedIntegrationTypes(state),
  };
}

const mapDispatchToProps = {
  fetchTypesIfNeeded: integrationActions.requestIntegrationTypesIfNeeded,
  requestTypes: integrationActions.requestIntegrationTypes,
};

class Integrations extends React.PureComponent {
  state = {
    query: '',
    debouncedQuery: '',
  };

  componentDidMount() {
    this.props.fetchTypesIfNeeded();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.debouncedQuery !== prevState.debouncedQuery)
      this.props.requestTypes({ search: this.state.debouncedQuery });
  }

  render() {
    const { siteName, hasPermission, isFetching, integrationTypes = [], searchedIntegrations = [] } = this.props;

    if (!hasPermission('SiteIntegration:create')) {
      return hasPermission('Account') ? (
        <p>You don't have permission to access integrations.</p>
      ) : (
        <Spinner className="fixed" />
      );
    }

    const types = this.state.debouncedQuery ? searchedIntegrations : integrationTypes;

    return (
      <>
        <DocumentTitle title={`${siteName}'s integrations`} />
        <Search
          value={this.state.query}
          onChange={(query) => this.setState({ query })}
          placeholder="Search integrations"
          debounceValue={(debouncedQuery) => this.setState({ debouncedQuery })}
          containerStyle={{
            marginTop: '20px',
            marginLeft: 'auto',
          }}
        />
        <ConnectedModal />
        {isFetching && <Spinner className="fixed" />}
        {!isFetching && (
          <>
            {types.map((integrationType) =>
              integrationType.integrations.length ? (
                <section key={integrationType.id}>
                  <header>
                    <h1>{integrationType.name}</h1>
                    <small>{integrationType.description}</small>
                  </header>
                  <article className={styles.integrations}>
                    <ul>
                      {/* Rendering Zapier old component due to its unique flow */}
                      {integrationType.integrations.map((type) => {
                        if (type.slug === 'zapier') return <Zapier type={type.id} />;
                        if (type.slug === 'shopify')
                          return (
                            <Shopify
                              key={type.id}
                              type={type.id}
                              integrationSlug={type.id}
                              name={type.name}
                              authenticator={type.authenticator}
                              icon={type.icon}
                            />
                          );
                        if (type.slug === 'salesforce')
                          return (
                            <CheckPermissionOnClick
                              action={`SiteIntegration:create:::${type.slug}`}
                              render={() => (
                                <Salesforce
                                  key={type.id}
                                  type={type.id}
                                  integrationSlug={type.id}
                                  name={type.name}
                                  authenticator={type.authenticator}
                                  icon={type.icon}
                                />
                              )}
                            />
                          );
                        return (
                          <CheckPermissionOnClick
                            action={`SiteIntegration:create:::${type.slug}`}
                            render={() => (
                              <Integration
                                key={type.id}
                                type={type.id}
                                integrationSlug={type.id}
                                name={type.name}
                                authenticator={type.authenticator}
                                icon={type.icon}
                                showTableContent
                              />
                            )}
                          />
                        );
                      })}
                    </ul>
                  </article>
                </section>
              ) : null
            )}
            <CustomCodeSection />
            <p className={styles.contactUs}>
              Looking to integrate more of your favorite tools? Post your idea in{' '}
              <a target="_blank" rel="noopener noreferrer" href="https://www.unstack.com/goto?community">
                the community
              </a>
              !
            </p>
          </>
        )}
      </>
    );
  }
}

export default compose(withSiteProps(mapSiteToProps), connect(mapStateToProps, mapDispatchToProps))(Integrations);
