import React from 'react';
import ZeroState, { Image, Header, Muted } from '../../ZeroState';

const ZeroStateView = ({ icon, message, header, redirection }) => (
  <ZeroState>
    <Image src={icon} />
    {header && <Header>{header}</Header>}
    {message && <Muted>{message}</Muted>}
    {redirection}
  </ZeroState>
);

export default ZeroStateView;
