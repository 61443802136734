import React, { ReactNode, isValidElement, useMemo } from 'react';
import { useSelector } from 'react-redux';

import useActiveSite from 'hooks/useActiveSite';
import { getSiteLayoutStyles } from 'reducers/sitesReducer';

import Box from '../../../../Editor/Boxes/Box';
import BoxButtons from '../../../../Editor/Boxes/Toolbar/BoxButtons';

import { ContainerInfo, SectionUiHandlersInterface, SectionHandlersInterface } from '../../../types';

import { BoxTagArgs } from 'components/unstack-components/tag-types';
import { getItemKey } from '../../util/utils';
import isNil from 'lodash/isNil';
import classnames from 'classnames';
import { UBoxInfo, USectionDevices } from 'types/USection';
import { getDevice } from 'reducers/uiReducer';

interface BoxElementProps extends BoxTagArgs {
  sectionUiHandlers?: SectionUiHandlersInterface;
  containerInfo?: ContainerInfo;
  defaults: any;
  sectionHandlers?: SectionHandlersInterface;
  properties?: { [key: string]: any }[];
  dataAttributes?: { [key: string]: any };
  content: USectionDevices;
  dataRef: UBoxInfo;
  onChange: (content: USectionDevices, key?: string, multi?: boolean) => void;
  children?: ReactNode;
}

const BoxElement: React.FunctionComponent<BoxElementProps> = (props) => {
  const {
    onChange,
    className,
    children,
    sectionHandlers,
    sectionUiHandlers,
    enableTextAlignment,
    enableVerticalAlignment,
    contentKey,
    containerInfo,
    properties,
    content,
    dataAttributes,
    dataRef,
  } = props;

  const activeSite = useActiveSite();
  const siteLayout = useSelector(() => getSiteLayoutStyles(activeSite));
  const device = useSelector(getDevice);

  const defaults = useMemo(() => {
    return {
      maxWidth:
        device === 'desktop' || device === 'tablet'
          ? props.defaultMaxWidth ||
            (siteLayout.box.desktop_width === 'none' && 'auto') ||
            siteLayout.box.desktop_width ||
            'auto'
          : props.defaultMaxWidthMobile ||
            (siteLayout.box.desktop_width_mobile === 'none' && 'auto') ||
            siteLayout.box.desktop_width_mobile ||
            'auto',
      textAlign: props.defaultTextAlignment,
      align: props.defaults?.defaultAlignment,
      verticalAlign: props.defaultVerticalAlignment || props.defaults?.defaultVerticalAlignment,
      enableVerticalAlign: props.enableVerticalAlignment || props.defaults?.enableVerticalAlignment,
      paddingTop:
        device === 'desktop' || device === 'tablet'
          ? props.defaultPaddingTop !== undefined
            ? props.defaultPaddingTop
            : siteLayout.box.desktop_padding[0]
          : siteLayout.box.mobile_padding[0],
      paddingLeft:
        device === 'desktop' || device === 'tablet'
          ? props.defaultPaddingLeft !== undefined
            ? props.defaultPaddingLeft
            : siteLayout.box.desktop_padding[3]
          : siteLayout.box.mobile_padding[3],
      paddingRight:
        device === 'desktop' || device === 'tablet'
          ? props.defaultPaddingRight !== undefined
            ? props.defaultPaddingRight
            : siteLayout.box.desktop_padding[1]
          : siteLayout.box.mobile_padding[1],
      paddingBottom:
        device === 'desktop' || device === 'tablet'
          ? props.defaultPaddingBottom !== undefined
            ? props.defaultPaddingBottom
            : siteLayout.box.desktop_padding[2]
          : siteLayout.box.mobile_padding[2],
      backgroundColor: props.defaultBackgroundColor,
      boxShadow: props.defaultBoxShadow || props.defaults?.defaultBoxShadow,
      borderColor: props.defaultBorderColor || props.defaults?.defaultBorderColor,
      borderRadius: props.defaultBorderRadius || props.defaults?.defaultBorderRadius,
      borderStyle: props.defaultBorderStyle || props.defaults?.defaultBorderStyle,
      borderWidth: props.defaultBorderWidth || props.defaults?.defaultBorderWidth,
      visibility: props.defaults?.defaultVisibility || 'all',
      brightnessTheme: props.defaultTextTheme,
    };
  }, [props.defaults, device]);

  const valueWithDefaults = useMemo(() => ({ ...defaults, ...dataRef }), [defaults, dataRef]);

  const ATTR_MAP: { [key: string]: string } = {
    class: 'className',
  };

  const htmlAttrForLayout = useMemo(() => {
    return (
      properties?.length &&
      properties
        ?.filter((property) => property.attribute || (dataRef && dataRef[property.name as keyof UBoxInfo]))
        ?.reduce((acc: any, property: any) => {
          const { suffix = '', prefix = '', name, attribute, type } = property;
          const attr = ATTR_MAP[attribute] || attribute;
          const hasValue = !isNil(dataRef?.properties?.[name]);

          let val = hasValue ? dataRef.properties[name] : property.default;
          if (val instanceof Array) {
            val = val.join(' ');
          }
          if (acc[attr]) {
            acc[attr].push(`${prefix}${val}${suffix}`);
          } else {
            acc[attr] = hasValue ? [`${prefix}${val}${suffix}`] : [`${prefix}${val}${suffix}`];
          }

          return acc;
        }, {})
    );
  }, [dataRef?.properties, properties]);

  const boxClassNames = classnames(...(htmlAttrForLayout?.className || []).flat(), className);
  const re = /^\d+$/;
  const defaultVal = re.test(defaults?.maxWidth.toString()) ? defaults.maxWidth.toString() + 'px' : defaults.maxWidth;
  const defaultPadding = {
    paddingTop: defaults?.paddingTop,
    paddingRight: defaults?.paddingRight,
    paddingBottom: defaults?.paddingBottom,
    paddingLeft: defaults?.paddingLeft,
  };

  return (
    <Box
      content={content}
      className={boxClassNames}
      dataAttributes={dataAttributes}
      renderToolbarItems={(closeTray: () => {}) => (
        <BoxButtons
          enableVerticalAlignment={enableVerticalAlignment || props.defaults?.enableVerticalAlignment}
          enableTextAlignment={enableTextAlignment || props.defaults?.enableTextAlignment}
          closeTray={closeTray}
          dataRef={valueWithDefaults}
          onChange={(value: USectionDevices, key: string) => onChange(value, key)}
          properties={properties}
          content={content}
          defaults={defaults}
          contentKey={contentKey}
          containerInfo={containerInfo}
        />
      )}
      updateOpacity={
        sectionUiHandlers && sectionUiHandlers.shouldApplyOpacity
          ? (value: any) => sectionUiHandlers.shouldApplyOpacity(value)
          : () => {}
      }
      shouldTriggerToolbar={true}
      shouldOnlyShowHover={sectionUiHandlers && sectionUiHandlers.isRichTextToolbarActive}
      contentKey={contentKey}
      showBackgroundLayers
      //  @ts-ignore
      style={props.style}
      defaultMaxWidth={defaultVal}
      defaultPadding={defaultPadding}
      {...valueWithDefaults}
      properties={properties}
    >
      {React.Children.map(children, (child) => {
        if (!isValidElement(child)) {
          return null;
        }

        const childProps: any = containerInfo ? { onChange, containerInfo, sectionHandlers } : { sectionHandlers };
        return React.cloneElement(child as React.ReactElement, childProps);
      })}
    </Box>
  );
};

export default BoxElement;
