import React from 'react';

import AuthenticatedMediaVideo from '../../../containers/AuthenticatedMediaVideo';
import { ReactComponent as SvgPlay } from '../../../assets/images/icon-play-video.svg';
import BottomDrawer from '../BottomDrawer';
import MediaDrawerBottom from './Drawer/MediaDrawerBottom';

class MediaEntity extends React.Component {
  shouldComponentUpdate(prevProps, prevState) {
    return !(
      prevProps.entity === this.props.entity &&
      prevProps.category === this.props.category &&
      prevProps.borderRadius === this.props.borderRadius &&
      prevState === this.state
    );
  }

  state = { isOpen: false };
  open = () => this.setState({ isOpen: true });
  close = () => this.setState({ isOpen: false });
  toggle = () => this.setState({ isOpen: !this.state.isOpen });

  render() {
    const entity = this.props.entity || {};

    const entityClickProps = {
      onClick: this.toggle,
      style: { cursor: 'pointer' },
    };

    const renderedEntity =
      entity.src || !this.props.renderPlaceholder ? (
        this.props.category === 'video' ? (
          <div className="video-embed" {...entityClickProps}>
            <SvgPlay
              style={{
                margin: '14% 0',
                zIndex: 1,
                height: '50%',
              }}
            />{' '}
            <AuthenticatedMediaVideo {...entity} />
          </div>
        ) : (
          this.props.render({ ...entity, ...entityClickProps, borderRadius: this.props.borderRadius })
        )
      ) : (
        <this.props.renderPlaceholder {...entityClickProps} />
      );

    return (
      <>
        {renderedEntity}
        <BottomDrawer extendBody hideScroll isOpen={this.state.isOpen} close={this.close}>
          <MediaDrawerBottom
            close={this.close}
            category={this.props.category}
            entity={entity}
            onChange={this.props.onChange}
            enableVideos={this.props.enableVideos}
            enableLinking={this.props.enableLinking}
            hideVideo={this.props.hideVideo}
            hideStockMedia={this.props.hideStockMedia}
            showPdf={this.props.showPdf}
            hideEmbeds={this.props.hideEmbeds}
            restrictMediaType={this.props.restrictMediaType}
            hideVideoOptions={this.props.hideVideoOptions}
          />
        </BottomDrawer>
      </>
    );
  }
}

export default MediaEntity;
