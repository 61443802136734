import React from 'react';
import idx from 'lodash/get';
import Spinner from '../../../base/Spinner';

const HS_FORMS_SCRIPT_ID = 'hs-forms-script';

let targetId = 0;
function generateTargetId() {
  return `hubspot-form-target-${++targetId}`;
}

class HubSpotForm extends React.PureComponent {
  document = null;
  window = null;

  constructor() {
    super();
    const iframe = document.getElementById('editorIframe');
    this.document = iframe ? iframe.contentWindow.document : document;
    this.window = iframe ? iframe.contentWindow : window;
    this.loadScript();
    this.state = {
      scriptIsLoaded: false,
      formIsCreated: false,
      targetId: generateTargetId(),
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.formProps.formId !== this.props.formProps.formId) {
      this.setState({
        formIsCreated: false,
        targetId: generateTargetId(),
      });
    }

    this.createForm();
  }

  componentWillUnmount() {
    this.script && this.script.remove();
  }

  scriptIsLoaded() {
    return this.document.getElementById(HS_FORMS_SCRIPT_ID);
  }

  portalId() {
    return idx(this.props.integrationsBySlug, 'hubspot[0].config.portalId');
  }

  handleScriptLoad = (e) => {
    this.setState({
      scriptIsLoaded: true,
    });
  };

  loadScript() {
    if (this.scriptIsLoaded()) {
      this.setState({
        scriptIsLoaded: true,
      });
      return;
    }

    const script = this.document.createElement('script');
    script.id = HS_FORMS_SCRIPT_ID;
    script.src = '//js.hsforms.net/forms/shell.js';
    script.charset = 'utf-8';
    script.type = 'text/javascript';
    script.onload = this.handleScriptLoad;

    this.document.body.appendChild(script);
    this.script = script;
  }

  createForm() {
    if (!this.state.formIsCreated && this.state.scriptIsLoaded && this.props.formProps.formId && this.portalId()) {
      const container = this.document.getElementById(this.state.targetId);
      if (container) container.innerHTML = '';

      this.window.hbspt.forms.create({
        portalId: this.portalId(),
        formId: this.props.formProps.formId,
        target: `#${this.state.targetId}`,
      });
      this.setState({
        formIsCreated: true,
      });
    }
  }

  render() {
    return (
      <>
        {!this.state.formIsCreated && <Spinner size="1" style={{ margin: '4rem auto' }} />}
        <div id={this.state.targetId} />
      </>
    );
  }
}

export default HubSpotForm;
