import React from 'react';

import AddPaymentAction from './EmbeddedForm/AddPaymentAction';
import { ReactComponent as IconCheckmark } from '../../assets/images/icon-checked-circle-green.svg';

import styles from './EmbeddedForm/PaymentEmbeds.module.scss';

class EmbeddedPayment extends React.PureComponent {
  state = { isOpen: false };
  open = () => this.setState({ isOpen: true });
  close = () => this.setState({ isOpen: false });
  toggle = () => this.setState({ isOpen: !this.state.isOpen });

  render() {
    const { value } = this.props;
    const { isOpen } = this.state;

    return (
      <>
        <div className={`form-embed ${value.background} ${styles.paymentEmbedsContainer}`}>
          <div className={`form-embed-placeholder-with-content ${styles.paymentEmbeds}`}>
            <div>
              <IconCheckmark style={{ width: '25px', height: '25px', marginBottom: '10px' }} />
              <br />
              Product ID: <code>{value.product}</code>
              <br />
              <small>You can see the subscription form for this product rendered on your published site.</small>
            </div>
          </div>
          <div className="form-embed-overlay" onClick={this.open} />
        </div>
        <AddPaymentAction
          isOpen={isOpen}
          close={this.close}
          value={value}
          onChange={this.props.onChange}
          add={(value) => this.add('payment', value)}
        />
      </>
    );
  }
}

export default EmbeddedPayment;
