import classnames from 'classnames';
import styles from './BaseIcons.module.scss';

import { ReactComponent as SvgIconRemoveSection } from '../../assets/images/icon-remove-section.svg';
import { ReactComponent as SvgIconToolbarTrash } from '../../assets/images/icon-toolbar-trash.svg';
import { ReactComponent as SvgIconPage } from '../../assets/images/unstack-mark-inverse.svg';
import { ReactComponent as SvgIconToolbarPage } from '../../assets/images/icon-content.svg';
import { ReactComponent as SvgIconUrl } from '../../assets/images/icon-url.svg';
import { ReactComponent as SvgBrowserTab } from '../../assets/images/icon-new-tab.svg';
import { ReactComponent as SvgIconCancel } from '../../assets/images/icon-cancel.svg';
import { ReactComponent as SvgPdf } from '../../assets/images/icon-pdf.svg';
import { ReactComponent as SvgDollar } from '../../assets/images/icon-dollar.svg';
import { ReactComponent as SvgArrowDown } from '../../assets/images/arrow-down.svg';
import { ReactComponent as SvgCheck } from '../../assets/images/icon-check.svg';
import { ReactComponent as SvgForm } from '../../assets/images/icon-form-toolbar.svg';

import { ReactComponent as SvgExclamation } from '../../assets/images/exclamation.svg';
import { ReactComponent as SvgError } from '../../assets/images/error.svg';

import { ReactComponent as SvgIconChoosePlanCheck } from '../../assets/images/choose-plan-check.svg';
import { ReactComponent as SvgIconHelp } from '../../assets/images/choose-plan-help.svg';
import { ReactComponent as SvgLigtning } from '../../assets/images/icon-lightning.svg';
import { ReactComponent as SvgWand } from '../../assets/images/icon-wand.svg';
import { ReactComponent as SvgQuestionBubble } from '../../assets/images/icon-question-bubble.svg';
import { ReactComponent as SvgSlack } from '../../assets/images/icon-slack.svg';
import { ReactComponent as SvgUniversity } from '../../assets/images/icon-university.svg';

import { FunctionComponent, SVGProps } from 'react';
import React from 'react';

interface IconOptions {
  className: string;
  stdHW: boolean;
  stdH: boolean;
  stdW: boolean;
}
interface StandardIconProps {
  active?: boolean;
  className?: string;
  [key: string]: string | boolean | number;
}
type SVGFunctionalComponent = FunctionComponent<SVGProps<SVGSVGElement> & { title?: string }>;

export const IconRemoveSection = createStandardIcon(SvgIconRemoveSection);
export const IconPage = createStandardIcon(SvgIconPage);
export const IconToolbarPage = createStandardIcon(SvgIconToolbarPage);
export const IconUrl = createStandardIcon(SvgIconUrl);
export const IconBrowserTab = createStandardIcon(SvgBrowserTab);
export const IconCancel = createStandardIcon(SvgIconCancel);
export const IconPdf = createStandardIcon(SvgPdf);
export const IconDollar = createStandardIcon(SvgDollar);
export const IconExclamation = createStandardIcon(SvgExclamation);
export const IconArrowDown = createStandardIcon(SvgArrowDown);
export const IconCheck = createStandardIcon(SvgCheck);
export const IconError = createStandardIcon(SvgError);
export const IconToolbarTrash = createStandardIcon(SvgIconToolbarTrash);

export const IconChoosePlanCheck = createStandardIcon(SvgIconChoosePlanCheck);
export const IconHelp = createStandardIcon(SvgIconHelp);
export const IconLightning = createStandardIcon(SvgLigtning);
export const IconWand = createStandardIcon(SvgWand);
export const IconQuestionBubble = createStandardIcon(SvgQuestionBubble);
export const IconSlack = createStandardIcon(SvgSlack);
export const IconUniversity = createStandardIcon(SvgUniversity);
export const IconForm = createStandardIcon(SvgForm);

function createStandardIcon(Icon: SVGFunctionalComponent, options?: IconOptions) {
  const iconClassName = classnames(styles.icon, options?.className, {
    [styles.iconHeight]: options?.stdH || options?.stdHW,
    [styles.iconWidth]: options?.stdW || options?.stdHW,
  });

  return function (props: StandardIconProps) {
    const { active, className, ...otherProps } = props;

    const combinedClassNames = classnames(className, iconClassName, {
      [styles.active]: active,
    });

    return <Icon className={combinedClassNames} {...otherProps} />;
  };
}
