import React, { memo } from 'react';
import { ButtonGroup, Button } from '../Toolbar2';
import { IconAlignCenter, IconAlignLeft, IconAlignRight } from '../icons';

interface TextAlignButtonProps {
  value: 'left' | 'center' | 'right';
  onChange: (newValue: string) => void;
}

const TextAlignButton = (props: TextAlignButtonProps) => {
  const { value, onChange } = props;

  return (
    <ButtonGroup compact>
      <Button data-tip="Left-align text" onClick={() => onChange('left')}>
        <IconAlignLeft active={value === 'left'} />
      </Button>
      <Button data-tip="Center text" onClick={() => onChange('center')}>
        <IconAlignCenter active={value === 'center'} />
      </Button>
      <Button data-tip="Right-align text" onClick={() => onChange('right')}>
        <IconAlignRight active={value === 'right'} />
      </Button>
    </ButtonGroup>
  );
};

export default memo(TextAlignButton);
