import React from 'react';
import pick from 'lodash/pick';
import classnames from 'classnames';
import { connect } from 'react-redux';

import * as mediaActions from '../../../../actions/mediaActions';
import * as mediaSelectors from '../../../../reducers/mediaReducer';

import { MediaOptionsDrawer } from './MediaOptionsDrawer';
import { ReactComponent as SvgIcon } from '../../../../assets/images/unstack-mark.svg';

import styles from './DataTable.module.scss';

function mapStateToProps(state) {
  return {
    columns: mediaSelectors.selectDataTableColumns(state),
  };
}

const mapDispatchToProps = {
  //  TODO: Only request media type columns instead of all columns
  requestAll: mediaActions.requestDataTableColumns,
};

class DataTable extends React.PureComponent {
  state = { selectedData: {} };
  componentDidMount() {
    this.props.requestAll(this.props.dataTable);
  }

  render() {
    const { columns } = this.props;
    const { selectedData, altText, link } = this.state;
    return (
      <>
        <div>
          <div className={styles.contentContainer}>
            {columns.map((column) => (
              <div
                onClick={() => this.setState({ selectedData: column })}
                className={classnames(styles.item, {
                  [styles.selected]: selectedData.id === column.id,
                  [styles.addPadding]: selectedData.id,
                })}
              >
                <SvgIcon height={35} width={35} />
                <span>{column.slug}</span>
              </div>
            ))}
          </div>
        </div>
        {Boolean(selectedData.id) && (
          <MediaOptionsDrawer
            onLinkChange={(val) => this.setState({ link: val })}
            onSave={() => {
              this.props.onChange({
                src: `{{data.${selectedData.slug}}}`,
                alt: altText,
                type: 'data_table',
                link: link,
                ...pick(this.state, [
                  'loop',
                  'controls',
                  'muted',
                  'autoplay',
                  'optimization',
                  'boxShadow',
                  'borderRadius',
                ]),
              });
            }}
            isVideoCategory={selectedData.media_category === 'video'}
            isImageCategory={['image', 'icon'].includes(selectedData.media_category)}
            entityOptions={pick(this.state, [
              'loop',
              'controls',
              'muted',
              'autoplay',
              'altText',
              'link',
              'optimization',
              'boxShadow',
              'borderRadius',
            ])}
            updateEntityOptions={(e) => {
              if (e.target.type === 'checkbox')
                this.setState({
                  [e.target.name]: e.target.checked,
                });
              else
                this.setState({
                  [e.target.name]: e.target.value,
                });
            }}
            onRemove={() => {}}
            enableLinking={selectedData.media_category !== 'video'}
            showMediaContextOptions={this.props.showMediaContextOptions}
            entity={this.props.entity}
          />
        )}
      </>
    );
  }
}

DataTable = connect(mapStateToProps, mapDispatchToProps)(DataTable);

export { DataTable };
