import React from 'react';
import BarChart from '../../../Charts/BarChart';
import styles from './Chart.module.scss';

import subDays from 'date-fns/subDays';
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';

export default function ContactUsageBarChart(props) {
  const { contact } = props;

  // Parse contact for summary data
  const data = [];
  let total = 0;
  const now = new Date();

  for (let i = -29; i <= 0; i++) {
    data.push([subDays(now, i), 0]);
  }

  const getIndex = (date) => differenceInCalendarDays(date, now) + 29;

  contact.activity.forEach((action) => {
    if (action.type === 'pageview') {
      const date = new Date(action.created_at);
      const index = getIndex(date);

      if (index >= 0 && index < 30) {
        data[index][1]++;
        total++;
      }
    }
  });

  return (
    <div className={styles.container}>
      <div className={styles.label}>Activity last 30 days</div>
      <div className={styles.value}>{total}</div>
      <BarChart className={styles.chart} data={data} width={275} height={60} />
    </div>
  );
}
