import { takeLatest, call, put, putResolve, select, all, spawn, fork, throttle } from 'redux-saga/effects';
import * as api from '../services/spark-api';
import * as accountActions from '../actions/accountActions';
import * as appActions from 'actions/appActions';
import * as integrationActions from '../actions/integrationActions';
import * as integrationSelectors from '../reducers/integrationsReducer';
import requireSiteId from './utils/requireSiteId';
import * as types from '../actions/actionTypes';
import { normalizeResponse, normalizeHubspotForms } from './integrationsNormalizr';
import * as Klaviyo from '../lib/tracking/klaviyo';
import * as accountReducer from '../reducers/accountReducer';
import * as siteActions from '../actions/siteActions';

window.integrationActions = integrationActions;

function* fetchTypesIfNeeded() {
  const isFetching = yield select(integrationSelectors.selectIntegrationTypeIsFetching);
  if (isFetching === undefined) yield put(integrationActions.requestIntegrationTypes());
}

function* fetchTypes(action) {
  const { payload } = action;
  const siteId = yield call(requireSiteId);
  try {
    const response = yield call(api.getIntegrationTypes, {
      siteId,
      ...payload,
    });
    if (response.ok) {
      if (payload && payload.search) yield put(integrationActions.requestIntegrationTypesSuccess(response.json, true));
      else yield put(integrationActions.requestIntegrationTypesSuccess(response.json));
    }
  } catch (e) {
    console.error(e);
  }
}

function* fetchAllIfNeeded(action) {
  const siteId = yield call(requireSiteId, action.payload.siteId);
  const isFetching = yield select(integrationSelectors.selectIsFetching);
  const lastUpdated = yield select(integrationSelectors.selectLastUpdated);

  if (!isFetching && lastUpdated == null) yield putResolve(integrationActions.requestAll(siteId));
}

function* fetchAllIntegrations(action) {
  const siteId = yield call(requireSiteId, action.payload.siteId);

  try {
    const response = yield call(api.getIntegrations, siteId);
    const receivedAt = new Date();
    if (response.ok) {
      const { entities } = normalizeResponse(response.json);
      const integrations = entities.integrations
        ? Object.values(entities.integrations).reduce(
            (acc, { status, slug }) => (status === 'active' ? [...acc, slug] : acc),
            []
          )
        : null;
      const email = yield select(accountReducer.selectAccountEmail);
      try {
        if (process.env.NODE_ENV !== 'development') {
          Klaviyo.identify({ integrations, $email: email });
        }
      } catch (e) {
        console.log(e);
      }
      if (entities.integrations) {
        const searchIntegrations = ['klevu', 'graphenehc', 'algolia'];
        Object.values(entities.integrations).forEach((integration) => {
          if (searchIntegrations.includes(integration.slug)) {
            window[`_${integration.slug}`] = integration.config;
          }
        });
      }
      yield put(integrationActions.receiveAll(entities, receivedAt));
      yield put(integrationActions.requestContactLists(action.payload.siteId));

      if (
        entities.integrations &&
        Object.values(entities.integrations).some((integration) => integration.slug === 'hubspot')
      )
        yield put(integrationActions.requestHubspotData(siteId, ''));
    } else if (response.unauthorized) {
      yield put(accountActions.logout());
    } else {
      console.error(response);
    }
  } catch (err) {
    console.error(err);
  }
}

function* createIntegration(action) {
  const { payload, resolve, reject } = action;

  // Convert payload to keys that the endpoint is expecting (e.g. siteId -> site)
  const { siteId, ...data } = payload;
  data.site = siteId;

  try {
    const response = yield call(api.createIntegration, data);
    if (response.ok) {
      yield put(integrationActions.receiveUpdate(response.json));
      yield put(siteActions.requestAll());
      if (resolve) yield spawn(resolve, response.json);
    } else if (response.status === 400) {
      if (reject) yield spawn(reject, response.json);
    } else if (response.unauthorized) {
      if (reject) yield spawn(reject, response.json);
      yield put(accountActions.logout());
    } else {
      if (reject) yield spawn(reject, response.json);
      console.error('Failed trying to create integration', response.json);
    }
  } catch (err) {
    if (reject) yield spawn(reject, { message: 'Unknown error' });
    console.error('Caught error in createIntegration in integrationsSaga', err);
  }
}

function* patchIntegration(action) {
  const {
    payload: { id, config },
    resolve,
    reject,
  } = action;
  try {
    const response = yield call(api.patchIntegration, id, { config });
    if (response.ok) {
      yield put(integrationActions.receiveUpdate(response.json));
      yield put(siteActions.requestAll());
      if (resolve) yield spawn(resolve, response.json);
    } else if (response.status === 400) {
      if (reject) yield spawn(reject, response.json);
    } else if (response.unauthorized) {
      if (reject) yield spawn(reject, response.json);
      yield put(accountActions.logout());
    } else {
      if (reject) yield spawn(reject, response.json);
      console.error('Failed trying to patch integration', response.json);
    }
  } catch (err) {
    if (reject) yield spawn(reject, { message: 'Unknown error' });
    console.error('Caught error in patchIntegration in integrationsSaga', err);
  }
}

function* deleteIntegration(action) {
  const {
    payload: { id },
    resolve,
    reject,
  } = action;
  try {
    const response = yield call(api.deleteIntegration, id);
    if (response.ok) {
      yield put(integrationActions.deleteIntegration(id));
      yield put(siteActions.requestAll());
      if (resolve) yield spawn(resolve, response.json);
    } else if (response.status === 400) {
      console.error('Error: ', response.json);
      if (reject) yield spawn(reject, response.json);
    } else if (response.unauthorized) {
      yield put(accountActions.logout());
      if (reject) yield spawn(reject, response.json);
      console.error('Error: ', response.json);
    } else {
      if (reject) yield spawn(reject, response.json);
      console.error('Failed trying to delete integration', response.json);
    }
  } catch (err) {
    if (reject) yield spawn(reject, { message: 'Unknown error' });
    console.error(err);
  }
}

function* fetchHubspotData(action) {
  const siteId = yield call(requireSiteId, action.payload.siteId);

  yield fork(fetchHubspotForms, siteId) || [];
}

function* fetchHubspotForms(siteId) {
  try {
    const response = yield call(api.getHubspotForms, siteId);
    const forms = normalizeHubspotForms(response);
    yield put(integrationActions.receiveHubspotForms(forms)) || [];
  } catch (error) {
    console.error(error);
  }
}

function* bulkConnect(action) {
  const { payload, site } = action;
  try {
    yield call(api.bulkCreateIntegration, payload, site);
  } catch (e) {
    console.error(e);
  }
}

function* fetchContactLists(action) {
  const siteId = yield call(requireSiteId, action?.payload?.siteId);
  try {
    const response = yield call(api.getContactLists, siteId);
    if (response.ok) {
      yield put(integrationActions.saveContactLists(response.json));
    }
  } catch (e) {
    console.error(e);
  }
}

function* shopifyOAuthConnect(action) {
  const {
    payload: { search: params, isInIframe, basePath },
    resolve,
    reject,
  } = action;

  try {
    yield put(accountActions.logout());
    const res = yield call(api.connectShopifyWithOAuth, params, isInIframe, basePath);
    if (res.status === 201) {
      window.location = res.json.redirect_url;
    } else if (res.status === 400) {
      resolve({ status: res.status });
    } else if (res.status === 409) {
      resolve({ status: res.status });
    } else {
      yield put(accountActions.loginSuccess(res.json.token));
      yield put(appActions.initializeApp());
      resolve({
        status: res.status,
        url: res.json.redirect_url,
        site: res.json?.site,
        integration: res.json?.site_integration,
        shop: res.json?.shop,
        user: res.json?.user,
        token: res.json?.token || '',
        subscription: res.json?.subscription,
      });
    }
  } catch (e) {
    console.error(e);
  }
}

function* shopifyAppUrl(action) {
  const {
    payload: { search: params, isInIframe, basePath },
    resolve,
    sessionToken,
  } = action;

  try {
    yield put(accountActions.logout());
    const res = yield call(api.shopifyAppAuth, params, isInIframe, sessionToken, basePath);
    if (res.status === 200) {
      resolve(res.json);
    }
  } catch (e) {
    console.error(e);
  }
}

function* integrationsSaga(action) {
  yield all([
    throttle(500, types.INTEGRATIONS_REQUEST_ALL_IF_NEEDED, fetchAllIfNeeded),
    takeLatest(types.INTEGRATIONS_REQUEST_ALL, fetchAllIntegrations),
    takeLatest(types.INTEGRATIONS_CREATE, createIntegration),
    takeLatest(types.INTEGRATIONS_PATCH, patchIntegration),
    takeLatest(types.INTEGRATIONS_REQUEST_DELETE, deleteIntegration),
    takeLatest(types.INTEGRATION_REQUEST_HUBSPOT_DATA, fetchHubspotData),
    takeLatest(types.INTEGRATION_REQUEST_TYPES_IF_NEEDED, fetchTypesIfNeeded),
    takeLatest(types.INTEGRATION_REQUEST_TYPES, fetchTypes),
    takeLatest(types.INTEGRATIONS_REQUEST_BULK_CONNECT, bulkConnect),
    takeLatest(types.INTEGRATIONS_REQUEST_CONTACT_LIST, fetchContactLists),
    takeLatest(types.INTEGRATIONS_SHOPIFY_OAUTH_FLOW, shopifyOAuthConnect),
    takeLatest(types.INTEGRATIONS_SHOPIFY_AUTH, shopifyAppUrl),
  ]);
}

export default integrationsSaga;
