import React, { useCallback, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import classNames from 'classnames';

import { Group, Label } from 'components/Editor/Toolbars2/Toolbar2';
import UpDownCounter from 'components/Editor/Toolbars2/components/UpDownCounter';

import { ReactComponent as SvgIconArrow } from 'assets/images/icon-cheveron-left.svg';
import { ReactComponent as SvgIconCheck } from 'assets/images/icon-check.svg';
import { ReactComponent as SvgIconPencil } from 'assets/images/icon-pencil-white.svg';
import { ReactComponent as SvgPaginationArrows } from 'assets/images/icon-pagination-arrows.svg';
import { ReactComponent as SvgPaginationNumbers } from 'assets/images/icon-pagination-numbers.svg';
import { ReactComponent as SvgPaginationDots } from 'assets/images/icon-pagination-dots.svg';
import { ReactComponent as SvgPaginationNone } from 'assets/images/icon-pagination-transition-none.svg';
import { ReactComponent as SvgPaginationSlide } from 'assets/images/icon-pagination-transition-slide.svg';
import { ReactComponent as SvgPaginationAppear } from 'assets/images/icon-pagination-transition-appear.svg';

import styles from './ProductCollectionToolbar.module.scss';

interface ToolbarProps {
  setIsOpen: (value: boolean) => void;
  paginationStyle: string;
  setPaginationStyle: (value: string) => void;
  paginationTransition: string;
  setPaginationTransition: (value: string) => void;
  itemsPerPage: number;
  setItemsPerPage: (newVal: string | number, event: any) => void;
  isPDP?: boolean;
}

type DrawerProps = {
  isOpen: boolean;
  onChange: (value: string) => void;
  currentOption: string;
  type: string;
};

type ItemProps = {
  style: string;
  onClick: () => void;
  current: string;
  type: string;
};

const PAGINATION_OPTIONS: Array<string> = ['arrows', 'numbers', 'dots'];
const PAGINATION_ICONS: { [key: string]: JSX.Element } = {
  arrows: <SvgPaginationArrows />,
  numbers: <SvgPaginationNumbers />,
  dots: <SvgPaginationDots />,
};

const TRANSITION_OPTIONS: Array<string> = ['none', 'slide', 'appear'];
const TRANSITION_ICONS: { [key: string]: JSX.Element } = {
  none: <SvgPaginationNone />,
  slide: <SvgPaginationSlide />,
  appear: <SvgPaginationAppear />,
};

export default function ProductCollectionToolbar(props: ToolbarProps) {
  const {
    setIsOpen,
    paginationStyle,
    setPaginationStyle,
    paginationTransition,
    setPaginationTransition,
    itemsPerPage,
    setItemsPerPage,
    isPDP,
  } = props;

  const [drawerIsOpenPaginate, setDrawerIsOpenPaginate] = useState(false);
  const [drawerIsOpenTransition, setDrawerIsOpenTransition] = useState(false);

  const handlePaginationDrawerItemClick = useCallback(
    (style: string) => {
      setPaginationStyle(style);
    },
    [setPaginationStyle]
  );

  const handleTransitionDrawerItemClick = useCallback(
    (transition: string) => {
      setPaginationTransition(transition);
    },
    [setPaginationStyle]
  );

  return (
    <div className={styles.ToolbarContainer}>
      <Group className={styles.ToolbarGroup}>
        <Label group={true}>Paginate:</Label>
        <span className={styles.ValuePicker}>
          <div
            className={classNames(styles.ValueInput, { [styles.IsOpen]: drawerIsOpenPaginate })}
            onClick={() => {
              setDrawerIsOpenPaginate(!drawerIsOpenPaginate);
              setDrawerIsOpenTransition(false);
            }}
          >
            {PAGINATION_ICONS[paginationStyle]}
            <span>{paginationStyle}</span>
            <SvgIconArrow className={styles.ArrowDown} />
            <Drawer
              isOpen={drawerIsOpenPaginate}
              onChange={handlePaginationDrawerItemClick}
              currentOption={paginationStyle}
              type={'pagination'}
            />
          </div>
        </span>
      </Group>
      <Group className={styles.ToolbarGroup}>
        <Label group={true}>Transition:</Label>
        <span className={styles.ValuePicker}>
          <div
            className={classNames(styles.ValueInput, { [styles.IsOpen]: drawerIsOpenTransition })}
            onClick={() => {
              setDrawerIsOpenTransition(!drawerIsOpenTransition);
              setDrawerIsOpenPaginate(false);
            }}
          >
            {TRANSITION_ICONS[paginationTransition]}
            <span>{paginationTransition}</span>
            <SvgIconArrow className={styles.ArrowDown} />
            <Drawer
              isOpen={drawerIsOpenTransition}
              onChange={handleTransitionDrawerItemClick}
              currentOption={paginationTransition}
              type={'transition'}
            />
          </div>
        </span>
      </Group>
      <Group className={styles.ToolbarGroup}>
        <Label>Per page:</Label>
        <UpDownCounter min={1} max={20} value={itemsPerPage} onChange={setItemsPerPage} />
      </Group>
      <Group className={styles.ToolbarGroup}>
        <button
          className={isPDP ? styles.EditButtonDisabled : styles.EditButton}
          onClick={(e) => {
            if (!isPDP) setIsOpen(true);
          }}
        >
          <SvgIconPencil />
          <span>Edit products...</span>
        </button>
      </Group>
    </div>
  );
}

function Drawer(props: DrawerProps) {
  const { isOpen, onChange, currentOption, type } = props;
  const OPTIONS = type === 'pagination' ? PAGINATION_OPTIONS : TRANSITION_OPTIONS;
  return (
    <CSSTransition in={isOpen} timeout={141.59} classNames={styles} mountOnEnter unmountOnExit>
      <div className={styles.Drawer}>
        <ul>
          {OPTIONS.map((name) => {
            return (
              <ItemChoice key={name} style={name} onClick={() => onChange(name)} current={currentOption} type={type} />
            );
          })}
        </ul>
      </div>
    </CSSTransition>
  );
}

function ItemChoice(props: ItemProps) {
  const { style, onClick, current, type } = props;
  const ICONS = type === 'pagination' ? PAGINATION_ICONS : TRANSITION_ICONS;
  return (
    <li className={styles.PaginationChoiceContainer} onClick={onClick}>
      <div className={styles.PaginationChoice}>
        {ICONS[style]}
        <span className={styles.inSwatchLabel}>{style}</span>
      </div>
      {current === style && <SvgIconCheck />}
    </li>
  );
}
