import React from 'react';
import { Link } from 'react-router-dom';
// import * as api from '../../../services/spark-api';

class ResetPassword extends React.Component {
  requestPasswordReset = (e) => {
    e.preventDefault();
  };

  render() {
    return (
      <>
        <Link to="/password">Reset password</Link>
      </>
    );
  }
}

export default ResetPassword;
