import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import SimplePage from '../SimplePage';
import Spinner from '../base/Spinner';
import * as api from '../../services/spark-api';
import * as accountActions from '../../actions/accountActions';
import get from 'lodash/get';
import styles from './SignupCoachup.module.scss';

export default function SignupCoachup(props) {
  const dispatch = useDispatch();

  const { coachup_id } = props.match.params;
  const [data, setData] = useState();
  const [error, setError] = useState();

  useEffect(() => {
    (async () => {
      const response = await api.createCoachupAccount(coachup_id);
      if (response.ok) {
        const body = response.json;
        setData({
          companyName: get(body, 'name'),
          firstName: get(body, 'users[0].user.first_name'),
          lastName: get(body, 'users[0].user.last_name'),
          email: get(body, 'users[0].user.email'),
          passwordToken: get(body, 'password_token'),
        });
        dispatch(accountActions.receive(body));
        dispatch(accountActions.loginSuccess(body.token));
      } else {
        setError(response.json);
      }
    })();
  }, []);

  if (!coachup_id)
    return (
      <SimplePage>
        <div>No ID provided (e.g. /signup/:coachup_id)</div>
      </SimplePage>
    );

  if (error)
    return (
      <SimplePage>
        <div style={{ maxWidth: '600px', padding: '2rem', margin: '0 auto' }}>{error}</div>
      </SimplePage>
    );

  if (!data)
    return (
      <SimplePage>
        <div>
          <Spinner className={styles.spinner} />
        </div>
        <div className={styles.message}>Retrieving your profile info and creating your site</div>
      </SimplePage>
    );

  return (
    <SimplePage>
      <div className={styles.data}>
        <p className={styles.infoHeader}>Here's the contact info your account will be created with:</p>
        <table>
          <tbody>
            <tr>
              <td>Name:</td>
              <td>
                {data.firstName} {data.lastName}
              </td>
            </tr>
            <tr>
              <td>Email:</td>
              <td>{data.email}</td>
            </tr>
            <tr>
              <td>Company:</td>
              <td>{data.companyName}</td>
            </tr>
          </tbody>
        </table>
        <Form passwordToken={data.passwordToken} />
      </div>
    </SimplePage>
  );
}

function Form(props) {
  const { passwordToken } = props;

  const [password, setPassword] = useState('');
  const [isFetching, setIsFetching] = useState(false);
  const [errors, setErrors] = useState();

  const handleChange = useCallback(
    (e) => {
      setPassword(e.target.value);
    },
    [setPassword]
  );

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();
      setIsFetching(true);
      api.resetPassword(passwordToken, password).then((response) => {
        if (response.status === 200) {
          window.location = '/';
        } else {
          const errors = response.json.errors;
          setIsFetching(false);
          setErrors(errors);
        }
      });
    },
    [password, passwordToken]
  );

  return (
    <form className={styles.form} onSubmit={handleSubmit}>
      <input
        name="password"
        autoComplete="new-password"
        placeholder="Set your password"
        type="password"
        value={password}
        onChange={handleChange}
        disabled={isFetching}
        autoFocus
        className={styles.password}
      />
      <input className="button button-primary" type="submit" value="Create account" />
      {errors && errors.password && <small className="errorMessage">{errors.password}</small>}
    </form>
  );
}
