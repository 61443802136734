/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useState } from 'react';
import get from 'lodash/get';

import CheckPermissionOnClick from '../../SubscriptionManager/CheckPermissionOnClick';

import { ReactComponent as SvgIconAdd } from '../../../assets/images/icon-add-section.svg';
import { ReactComponent as SvgIconEdit } from '../../../assets/images/icon-edit-field.svg';
import { ReactComponent as SvgIconTrash } from '../../../assets/images/icon-remove-field.svg';

import styles from '../Integrations.module.scss';

const IntegrationForm = (props) => {
  const { isFetching } = props;
  const config = props.config || {
    [props.id]: '',
  };
  const [value, setValue] = useState(config);
  return (
    <>
      <label>{props.label}:</label>
      <form
        onSubmit={(e) => {
          props.onSubmit(value);
          e.preventDefault();
          return false;
        }}
      >
        <input
          type="text"
          onChange={(e) => setValue({ ...config, [props.id]: e.target.value })}
          value={value[props.id]}
          placeholder={props.placeholder}
          autoFocus
        />
        <input
          disabled={!value[props.id]}
          type="submit"
          className="button button-primary button-sm"
          value={isFetching ? 'Loading...' : 'Save'}
        />
      </form>
    </>
  );
};

export const Show = ({ config, id }) => (
  <>
    <span className={styles.value}>{config[id]}</span>
  </>
);

class InlineIntegration extends React.PureComponent {
  state = {
    isOpen: false,
  };

  open = (e) => {
    this.setState({ isOpen: true });
    if (e && e.preventDefault) e.preventDefault();
    return false;
  };

  close = (e) => {
    this.setState({ isOpen: false });
    if (e && e.preventDefault) e.preventDefault();
    return false;
  };
  componentDidMount() {
    if (this.props.location.state && this.props.location.state.slug === this.props.integrationSlug) {
      this.setState({ isOpen: true });
    }
  }
  handleSubmit = (config) => {
    this.setState({ isFetching: true });

    (this.props.integration == null ? this.createIntegration(config) : this.updateIntegration(config)).promise
      .then((response) => {
        if (response.ok) {
          this.props.receiveUpdate(response.json);
        } else {
          console.error(response);
        }
      })
      .catch((err) => console.error(err))
      .finally(() => {
        this.setState({ isFetching: false });
        this.close();
      });
  };

  createIntegration = (config) => {
    const { siteId, integrationSlug } = this.props;

    return this.props.create({
      siteId: siteId,
      integration: integrationSlug,
      config,
    });
  };

  updateIntegration = (config) => {
    const { id } = this.props.integration;

    return this.props.patch({ id, config });
  };

  deleteIntegration() {
    this.setState({ isFetching: true });
    const { id } = this.props.integration;

    this.props
      .deleteIntegrationRequest(id)
      .promise.then((response) => {
        if (!response.ok) {
          console.error(response);
        }
      })
      .catch((err) => console.error(err))
      .finally(() => {
        this.setState({ isFetching: false });
        this.close();
      });
  }

  render() {
    const { isOpen, isFetching } = this.state;
    const { integration, type, disabled, icon, name, authenticator, integrationSlug } = this.props;

    const config = get(integration, 'config');

    const isEmpty = !integration;

    return (
      <>
        <img className={styles.icon} src={icon} alt="" />
        <div className={styles.title}>{name}</div>
        <div className={styles.config}>
          {disabled && <p className={styles.comingSoon}>Coming soon</p>}
          {!disabled && isOpen && (
            <>
              {authenticator.form_info.fields.map((field) => (
                <IntegrationForm
                  config={config}
                  isFetching={isFetching}
                  onSubmit={this.handleSubmit}
                  id={field.name}
                  placeholder={field.placeholder}
                  label={field.label}
                />
              ))}
              {!isEmpty && (
                <a
                  // className={styles.remove}
                  onClick={(e) => this.deleteIntegration()}
                  role="button"
                >
                  <SvgIconTrash className={styles.edit} height={16} width={16} />
                </a>
              )}
              <a className={styles.cancel} onClick={this.close} role="button">
                Cancel
              </a>
            </>
          )}
          {!disabled && !isOpen && isEmpty && (
            <CheckPermissionOnClick
              action={`SiteIntegration:create:::${integrationSlug}`}
              onClick={this.open}
              render={(handleClick, hasPermission) =>
                hasPermission ? (
                  <SvgIconAdd className={styles.add} onClick={handleClick} role="button" />
                ) : (
                  <button onClick={handleClick} className="button button-primary button-sm">
                    Upgrade
                  </button>
                )
              }
            />
          )}
          {!disabled && !isOpen && !isEmpty && (
            <>
              <label>
                <span className={styles.statusIndicator} />
                Connected
              </label>{' '}
              <Show id={authenticator.form_info.fields[0].name} config={config} />
              <a href="" onClick={this.open}>
                <SvgIconEdit height={16} width={16} />
              </a>
            </>
          )}
        </div>
      </>
    );
  }
}

export default InlineIntegration;
