import * as types from '../actions/actionTypes';

import { createSelector } from 'reselect';
// ----------------------------------------------------------------------------
// Reducer
// ----------------------------------------------------------------------------

const INITIAL_STATE = {
  isFetching: null,
  all: [],
};

export default function timezoneReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.TIMEZONE_REQUEST_ALL:
      return { ...state, isFetching: true };

    case types.TIMEZONE_REQUEST_ALL_SUCCESS:
      return { ...state, isFetching: false, all: action.payload };

    // Default
    // ------------------------------------------------------------------------

    default:
      return state;
  }
}

// ----------------------------------------------------------------------------
// Selectors
// ----------------------------------------------------------------------------

function selectLocalState(globalState) {
  return globalState.timezone;
}

export const selectIsFetching = createSelector(selectLocalState, (timezone) => timezone.isFetching);

export const selectTimezones = createSelector(selectLocalState, (timezone) => timezone.all);
