import React from 'react';
import idx from 'lodash/get';

import withPermissions from '../../../../containers/withPermissions';

import Toolbar from '../../../Editor/Toolbars/HeaderFooterToolbar';
import ColorSelector from '../StyleGuideEditor/ColorSelector';
import LogoEditorButtons from './LogoEditorButtons';
import BrightnessThemeButtons from '../../../Editor/Toolbars/Buttons/BrightnessTheme';
import ToggleUnstackBrandingButton from './ToggleUnstackBrandingButton';

const FooterToolbar = ({ site, siteUpdaters, hasPermission }) => {
  return (
    <Toolbar type="footer">
      <div className="toolbar">
        <ColorSelector
          name="header_1_color"
          onChange={(value) => siteUpdaters.set('styles.footerBackgroundColor', value)}
          value={idx(site, 'styles.footerBackgroundColor', '#293241')}
          placeholder="Color"
          handleColorSelection={(value) => siteUpdaters.set('styles.footerBackgroundColor', value)}
        />
        <div className="separator" />
        <LogoEditorButtons target="footer" site={site} siteUpdaters={siteUpdaters} />
        <div className="separator" />
        <BrightnessThemeButtons
          value={idx(site, 'styles.footerBrightnessTheme', 'dark-bg')}
          onChange={(value) => siteUpdaters.set('styles.footerBrightnessTheme', value)}
        />
        {hasPermission('Site:debadge', 'subscription') && (
          <>
            <div className="separator" />
            <ToggleUnstackBrandingButton
              value={idx(site, 'elements.hideSparkBranding', false)}
              onChange={(value) => siteUpdaters.set('elements.hideSparkBranding', value)}
            />
          </>
        )}
      </div>
    </Toolbar>
  );
};

export default withPermissions(FooterToolbar);
