import React, { useEffect } from 'react';
import { createIntegration } from '../../../services/spark-api';
import { ReactComponent as SvgIconUnstack } from '../../../assets/images/unstack-mark.svg';
import { get as getOauthState } from '../../../services/oauth-state';
import get from 'lodash/get';
import { ReactComponent as SvgIconShopify } from '../../../assets/images/icon-shopify.svg';

import styles from '../Integrations.module.scss';

export default function IntegrationReroute() {
  const urlSearchParams = new URLSearchParams(window.location.search);

  /**
   *  TODO: Refactor the logic here to use optional chaining instead of
   *  using lodash get method as it is more optimal.
   * */

  const stateId = urlSearchParams.get('state');
  const oauthState = getOauthState(stateId);
  const siteId = get(oauthState, 'data.siteId') || urlSearchParams.get('siteId');
  const icon = get(oauthState, 'data.icon') || '';
  const name = get(oauthState, 'data.name') || '';
  const slug = get(oauthState, 'data.integrationSlug') || '';
  const message = get(oauthState, 'data.authorization_success_message');
  const runSyncJobStatus = get(oauthState, 'data.run_sync_job');
  const shop = urlSearchParams.get('shop');
  const authCode = urlSearchParams.get('code');
  const hmac = urlSearchParams.get('hmac');
  useEffect(() => {
    if (siteId || (stateId && hmac)) {
      createIntegration({
        config: {
          auth_code: authCode,
          run_sync_job: runSyncJobStatus !== undefined ? runSyncJobStatus : true,
          ...(shop && { shop }),
          ...(hmac && { hmac }),
        },
        integration: slug,
        site: siteId,
        state: stateId,
      }).then((response) => {
        if (response.ok) {
          const config = response.json.config;
          window.opener.postMessage(
            {
              title: `Connected to ${(config.shop_name && config.shop_name) || name} !`,
              message: `${name} integration enabled.`,
              icon: icon,
            },
            window.opener.location
          );
        }
        window.close();
      });
    }
  }, [siteId]);
  return (
    <div className={styles.popup}>
      <div className={styles.logos}>
        <SvgIconUnstack />
        {icon && <img className={styles.icon} src={icon} alt="" />}
        {!icon && slug === 'shopify' && <SvgIconShopify width={99} height={99} />}
      </div>
      <p>{message}</p>
      <p className={styles.instructions}>This window will close when import completes</p>
    </div>
  );
}
