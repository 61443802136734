import React from 'react';

import relativeDate from '../../lib/relative-date';

import { ReactComponent as SvgIconOptions } from '../../assets/images/icon-options.svg';
import { ReactComponent as SvgIconTestActive } from '../../assets/images/icon-test-active.svg';

import styles from './PageList.module.scss';
import { ReactComponent as NextSvg } from '../../assets/images/icon-next-page.svg';
import { ReactComponent as PrevSvg } from '../../assets/images/icon-prev-page.svg';
import List from '../react-table/List';

export class PageList extends React.PureComponent {
  render() {
    const {
      pages,
      activePage,
      setActivePage,
      pagesCount,
      gotoEditor,
      openPageSettings,
      selectActiveByPageId,
      defaultName,
    } = this.props;

    const columns = [
      {
        Header: 'Name',
        accessor: '',
        Cell: ({ value }) => {
          const { name, slug, status } = value;

          const showAbleSlug = slug === '' && status === 'published' && 'Home page';

          return (
            <div className={''}>
              {name || defaultName}
              {showAbleSlug && <span className={styles.label}>{showAbleSlug}</span>}
            </div>
          );
        },
        minWidth: 360,
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: ({ value }) => {
          return <StatusIndicator status={value} disableContainerClass={true} />;
        },
      },
      {
        Header: 'Views',
        accessor: 'views',
        Cell: ({ value }) => {
          return (
            <div className={styles.session}>
              {(value || 0).toLocaleString()} <small>total views</small>
            </div>
          );
        },
      },
      {
        Header: 'Updated At',
        accessor: 'modified_at',
        Cell: ({ value }) => (
          <div className={styles.lastUpdatedAt}>
            <small style={{ fontSize: '1em', color: '#647fb4' }}>Updated&nbsp;</small>
            {relativeDate(value)}
          </div>
        ),
      },
      {
        Header: '',
        accessor: 'id',
        Cell: ({ value }) => {
          const hasActiveTests = selectActiveByPageId(value).length > 0;

          return (
            <div className={styles.icons}>
              {hasActiveTests && <SvgIconTestActive data-tip="A/B tests are running" />}
              <button
                data-tip="Options"
                className={styles.options}
                onClick={(e) => {
                  e.stopPropagation();
                  openPageSettings(value);
                }}
              >
                <SvgIconOptions />
              </button>
            </div>
          );
        },
      },
    ];

    return (
      <List
        className="-highlight"
        data={pages}
        columns={columns}
        count={pagesCount}
        defaultPageSize={10}
        getTdProps={(state, row) => ({
          onClick: (e) => {
            gotoEditor(row.original.id);
          },
          style: { cursor: 'pointer' },
        })}
        loading={false}
        showViewAll={false}
        breakPageslabel={<span>...</span>}
        nextPageLabel={<NextSvg />}
        onPageChange={(page) => setActivePage(page)}
        pagesCount={pagesCount / 10}
        paginate
        activePage={activePage}
        previousPageLabel={<PrevSvg />}
        showHeader={false}
        getTheadProps={() => ({
          style: {
            zIndex: '0',
          },
        })}
      />
    );
  }
}

export function PageListItem(props) {
  const { name, label, status, views, modifiedAt, onClick, onOptionClick, hasActiveTests } = props;

  const modifiedAtStr = relativeDate(modifiedAt);

  return (
    <li onClick={onClick}>
      <div className={styles.name}>
        {name}
        {label && <span className={styles.label}>{label}</span>}
      </div>
      <StatusIndicator status={status} />
      <div className={styles.sessions}>
        {(views || 0).toLocaleString()} <small>total views</small>
      </div>
      <div className={styles.lastUpdated}>
        <small>Updated&nbsp;</small>
        {modifiedAtStr}
      </div>
      <div className={styles.icons}>
        {hasActiveTests && <SvgIconTestActive data-tip="A/B tests are running" />}
        <button
          data-tip="Options"
          className={styles.options}
          onClick={(e) => {
            e.stopPropagation();
            onOptionClick();
          }}
        >
          <SvgIconOptions />
        </button>
      </div>
    </li>
  );
}

export function StatusIndicator({ status, disableContainerClass, value = undefined }) {
  // NOTE: Currently only handling 'draft' and 'published' statuses,
  // but all possible page statuses are as follows:
  //
  // [('draft', 'Draft'), ('published', 'Published'), ('deleted', 'Deleted'),
  // ('flagged', 'Flagged'), ('moderated', 'Moderated'),
  // ('resubmitted', 'Resubmitted')]

  if (status == null) return <div className={styles.status} />;

  let className, text;

  if (status === 'published') {
    className = styles.statusIndicatorLive;
    text = 'Live';
  } else {
    className = styles.statusIndicatorDraft;
    text = status === 'draft' ? 'Draft' : 'Scheduled';
  }

  return (
    <div className={!disableContainerClass ? styles.flexContainer : null} data-test-id="status">
      <span className={className} />
      {value || text}
    </div>
  );
}
