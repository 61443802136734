import React, { memo } from 'react';
import { AlignmentOptions } from 'types/USection';
import ButtonWithOptions from '../../Toolbars2/components/ButtonWithOptions';
import { IconContentAlignLeft, IconContentAlignCenter, IconContentAlignRight } from '../../Toolbars2/icons';

type OptionMap = {
  value: AlignmentOptions;
  IconComponent: React.FunctionComponent;
}[];

const options: OptionMap = [
  {
    value: 'left',
    IconComponent: IconContentAlignLeft as React.FunctionComponent,
  },
  {
    value: 'center',
    IconComponent: IconContentAlignCenter as React.FunctionComponent,
  },
  {
    value: 'right',
    IconComponent: IconContentAlignRight as React.FunctionComponent,
  },
];

interface AlignButtonProps {
  value?: AlignmentOptions;
  onChange: (newValue: AlignmentOptions) => void;
}

const AlignButton = (props: AlignButtonProps) => {
  const { value = 'left', onChange } = props;

  const tooltipMap: { [key in AlignmentOptions]: string } = {
    left: 'Horizontal Align: Left',
    center: 'Horizontal Align: Center',
    right: 'Horizontal Align: Right',
  };

  return (
    <ButtonWithOptions
      options={options}
      value={value}
      onChange={onChange}
      data-tip={tooltipMap[value]}
      data-test-id="align"
    />
  );
};

export default memo(AlignButton);
