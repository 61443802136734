import { select, all, race, fork, take, takeEvery, call } from 'redux-saga/effects';
import * as types from '../actions/actionTypes';
import idx from 'lodash/get';
import pickBy from 'lodash/pickBy';

import * as Klaviyo from '../lib/tracking/klaviyo';
import * as api from '../services/spark-api';

import {
  selectIsFetching as selectIsFetchingPages,
  selectHasPublishedSitePages,
  selectHasPublishedLandingPages,
} from '../reducers/pagesReducer';

import {
  selectIsFetching as selectIsFetchingArticles,
  selectHasPublished as selectHasPublishedArticles,
} from '../reducers/articlesReducer';

import { selectIsLoggedIn, selectHasAccount } from '../reducers/accountReducer';

function hasPublishedSitePages(state) {
  if (selectIsFetchingPages(state) === false) return selectHasPublishedSitePages(state);
}

function hasPublishedLandingPages(state) {
  if (selectIsFetchingPages(state) === false) return selectHasPublishedLandingPages(state);
}

function hasPublishedArticles(state) {
  if (selectIsFetchingArticles(state) === false) return selectHasPublishedArticles(state);
}

function* initialize() {
  while (true) {
    const { loggedIn } = yield race({
      loggedIn: take(types.ACCOUNT_RECEIVE),
      loggedOut: take(types.ACCOUNT_LOGOUT),
    });

    if (loggedIn) yield fork(boot);
  }
}

function* boot() {
  const data = yield select(selectData);

  // Profitwell
  if (window.profitwell && data.default.email) {
    window.profitwell('user_email', data.default.email);
  }

  try {
    if (process.env.NODE_ENV !== 'development') {
      Klaviyo.identify(data.klaviyo);
    }
  } catch (e) {
    console.log(e);
  }
}

const EDITOR_PATHNAME_REGEXP = /^\/editor\//i;

function updateLocation(action) {
  const { pathname } = action.payload.location;
  const isEditor = EDITOR_PATHNAME_REGEXP.test(pathname);
}

function* updateWithData() {
  const data = yield select(selectData);
  if (window.sprkfe.logTrackingData) console.log('updating', data);
  try {
    if (process.env.NODE_ENV !== 'development') {
      Klaviyo.identify(data.klaviyo);
    }
  } catch (e) {
    console.log(e);
  }
}

function* updateSubscriptionCreate() {
  const data = yield select(selectData);
  Klaviyo.track('Signed up for a paid account', data.klaviyo);
}

function selectData(state) {
  const account = state.account;
  const site = state.site;
  const subscription = state.subscription;

  const user = idx(account, 'users[0].user');
  const user_id = idx(user, 'id');
  const first_name = idx(user, 'first_name');
  const last_name = idx(user, 'last_name');
  const full_name = [first_name, last_name].join(' ');
  const email = idx(user, 'email');

  const has_published_site_pages = hasPublishedSitePages(state);
  const has_published_landing_pages = hasPublishedLandingPages(state);
  const has_published_articles = hasPublishedArticles(state);

  const has_created_account = selectIsLoggedIn(state) ? selectHasAccount(state) : undefined;

  const defaultData = compactObject({
    account_id: idx(account, 'id'),
    created_at: idx(account, 'created_at'),
    user_id: user_id,
    first_name: first_name,
    last_name: last_name,
    name: full_name,
    email: email,
    site_subdomain: idx(site, 'subdomain'),
    site_name: idx(site, 'name'),
    has_created_account,
    has_published_site_pages,
    has_published_landing_pages,
    has_published_articles,
    subscription_plan: idx(subscription, 'subscription.plan'),
    subscription_created_at: idx(subscription, 'subscription.created_at'),
  });

  const klaviyoData = compactObject({
    $id: idx(account, 'id'),
    $first_name: first_name,
    $last_name: last_name,
    $email: email,
    $organization: idx(site, 'name'),
    account_id: idx(account, 'id'),
    account_created_at: idx(account, 'created_at'),
    user_id: user_id,
    site_subdomain: idx(site, 'subdomain'),
    site_name: idx(site, 'name'),
    has_created_account,
    has_published_site_pages,
    has_published_landing_pages,
    has_published_articles,
    subscription_plan: idx(subscription, 'subscription.plan'),
    subscription_created_at: idx(subscription, 'subscription.created_at'),
  });

  return {
    default: defaultData,
    klaviyo: klaviyoData,
  };
}

function* thirdPartyServicesSaga() {
  yield all([
    fork(initialize),
    takeEvery(types.ROUTER_LOCATION_HAS_CHANGED, updateLocation),
    takeEvery(types.SUBSCRIPTION_RECEIVE, updateWithData),
    takeEvery(types.PAGES_RECEIVE_LIST, updateWithData),
    takeEvery(types.SUBSCRIPTION_RECEIVE_CREATE_SUCCESS, updateSubscriptionCreate),
  ]);
}

export default thirdPartyServicesSaga;

// Utilities

function compactObject(obj) {
  return pickBy(obj, (val) => val != null);
}
