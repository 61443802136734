import React from 'react';
import format from 'date-fns/fp/format';

const formatDate = format('MMM d, Y h:mm aaa');

export default ({ value }) => {
  try {
    const formatted = formatDate(new Date(value));
    return <div>{value ? formatted : ''}</div>;
  } catch (e) {
    return <div>{value}</div>;
  }
};
