import { compose } from 'redux';
import withSiteProps from './withSiteProps';

function mapSiteToProps(site) {
  return { siteId: site.id };
}

export default (WrappedComponent) => {
  return withSiteProps(mapSiteToProps)(WrappedComponent);
};
