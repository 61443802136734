import React from 'react';
import { useParams } from 'react-router';
import { NavLink, Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { patchOnboarding, removeOnboardingStep } from 'actions/siteActions';
import { selectActiveSite } from 'reducers/sitesReducer';
import { selectHasPermission } from 'reducers/policyReducer';
import ExpansionPanel from 'components/AdminPanel/Quickstart/ExpansionPanel';
import { getIsCustomizeBrandingComplete } from 'components/AdminPanel/Quickstart/helpers';
import { CUSTOMIZE_BRANDING_PENDING, CUSTOMIZE_BRANDING_COMPLETE } from 'components/AdminPanel/Quickstart/constants';
import styles from 'components/AdminPanel/Quickstart/CustomizeBrandingExpansionPanel.module.scss';
import settingsStyles from 'components/AdminPanel/Settings.module.scss';
import { ReactComponent as NavArrowIcon } from 'assets/images/nav-arrow-icon.svg';
import { ReactComponent as DesignLinksBackground } from 'assets/images/design-links-background.svg';
import { useQuickstartStep } from 'components/AdminPanel/Quickstart/hooks/useQuickstartStep';

import navStyles from '../Nav.module.scss';

import NavOption from '../NavOption';
import classNames from 'classnames';
import { ONBOARDING_DESIGN_NAV_ITEM } from '../Nav/NavItems';
import useActiveSite from 'hooks/useActiveSite';

const noop = () => {};

const CustomizeBrandingContent = () => {
  const hasPermission = useSelector(selectHasPermission);
  const site = useActiveSite();

  const { urlSite } = useParams<{ urlSite: string }>();

  return (
    <div className={styles.customizeBrandingContent}>
      <div>
        <p>
          The look and feel of your site can be customized from the <span>Design </span>
          section of the sidebar.
        </p>
        <p>
          Click into the <span>Header & Footer</span> to customize your logo. Edit your fonts and colors under{' '}
          <span>Styles</span>.
        </p>
        <div className={settingsStyles.links}>
          <Link to={`/${urlSite}/styles`}>Learn more</Link>
        </div>
      </div>
      <div className={classNames(styles.navContainer, navStyles.quickStartItems)}>
        <NavArrowIcon className={styles.navArrowIcon} />
        {hasPermission('Site:update', 'role') && (
          <NavOption
            setPanel={() => {}}
            panel={ONBOARDING_DESIGN_NAV_ITEM(site).panel}
            item={ONBOARDING_DESIGN_NAV_ITEM(site)}
            handleNavigationItemClick={() => {}}
          />
        )}
      </div>
      <DesignLinksBackground className={styles.navLinkBackground} />
    </div>
  );
};

export default function CustomizeBrandingExpansionPanel() {
  const activeSite = useSelector(selectActiveSite);
  const isStepComplete = getIsCustomizeBrandingComplete();
  const siteMetaData = activeSite.metadata?.onboarding || [];
  const { handleIconClick, handleExpansionPanelClick, open } = useQuickstartStep({
    isStepComplete,
    metadata: siteMetaData,
    id: activeSite.id,
    pendingDescription: CUSTOMIZE_BRANDING_PENDING,
    completedDescription: CUSTOMIZE_BRANDING_COMPLETE,
    patchOnboarding,
    removeOnboardingStep,
  });

  return (
    <ExpansionPanel
      title="Customize your branding"
      isComplete={isStepComplete}
      onClick={handleExpansionPanelClick}
      onIconClick={handleIconClick}
      open={open}
    >
      <CustomizeBrandingContent />
    </ExpansionPanel>
  );
}
