import * as types from '../actions/actionTypes';

// ----------------------------------------------------------------------------
// Reducer
// ----------------------------------------------------------------------------

const initState = {
  isOpen: false,
  pageId: null,
};

export default function (state = initState, action) {
  switch (action.type) {
    case types.TEMPLATES_SETTINGS_OPEN:
      return {
        isOpen: true,
        pageId: action.payload,
      };

    case types.TEMPLATES_SETTINGS_CLOSE:
      return {
        ...state,
        isOpen: false,
        pageId: null,
      };

    default:
      return state;
  }
}

// ----------------------------------------------------------------------------
// Selectors
// ----------------------------------------------------------------------------

function selectLocalState(globalState) {
  return globalState.uiTemplateSettings;
}

export function selectProps(globalState) {
  return selectLocalState(globalState);
}
