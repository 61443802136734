import React, { useState, useEffect, useCallback } from 'react';
import { OverTray, Group, ButtonGroup, Button } from '../../../../components/Editor/Toolbars2/Toolbar2';
import {
  IconRteLink,
  IconCancelItemLg,
  IconCheckmark,
  IconNewTab,
} from '../../../../components/Editor/Toolbars2/icons';
import SmartUrlToolbar from 'components/Editor/SmartUrlToolbar/SmartUrlToolbar';

import isString from 'lodash/isString';

export default function LinkButton(props) {
  const { quill, formats, applyFormat } = props;

  return (
    <OverTray
      borderless
      extendHeight
      renderIcon={() => <IconRteLink active={!!formats.link} />}
      renderTray={(close) => <Tray quill={quill} formats={formats} applyFormat={applyFormat} close={close} />}
    />
  );
}

function Tray(props) {
  const { formats, applyFormat, close } = props;
  const [value, setValue] = useState(isString(formats.link) ? { href: formats.link } : formats.link || {});

  function handleSmartUrlChange(newValue) {
    setValue({ behavior: 'currentTab', ...value, ...newValue });
  }

  const handleSubmit = useCallback(() => {
    value.href ? applyFormat('link', value) : applyFormat('link', false);
    close();
  }, [applyFormat, value, close]);

  const handleCancel = useCallback(() => {
    applyFormat('link', false);
    close();
  }, [applyFormat, close]);

  useEffect(() => {
    function handleKeydown(e) {
      switch (e.which) {
        case 27:
          close();
          break;
        case 13:
          e.preventDefault();
          handleSubmit();
          break;
        default:
      }
    }

    document.addEventListener('keydown', handleKeydown);
    return () => document.removeEventListener('keydown', handleKeydown);
  }, [handleSubmit, close]);

  return (
    <>
      <SmartUrlToolbar
        onChange={(name, value) => {
          name = name === 'url' ? 'href' : name;
          handleSmartUrlChange({ [name]: value });
        }}
        onUpdateUrlProps={(dict) => {
          handleSmartUrlChange({ ...dict, href: dict.url });
        }}
        link={{ ...value, url: value.href }}
        formats={formats}
        value={value.href || ''}
        isRichText
        autoFocus
      />
      <ButtonGroup>
        <Button onClick={handleCancel} data-tip="Remove link">
          <IconCancelItemLg />
        </Button>
        <Button onClick={handleSubmit} data-tip="Save link" disabled={!value}>
          <IconCheckmark />
        </Button>
      </ButtonGroup>
    </>
  );
}
