import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import { useSelector, connect, useDispatch } from 'react-redux';
import createFormatDate from 'date-fns/fp/format';
import { isEqual, isBefore } from 'date-fns/esm';
import isSameYear from 'date-fns/isSameYear';

import AddTask from './AddTask';
import Spinner from '../../base/Spinner';
import Table from '../../react-table/Table';
import TasksZeroState from './TasksZeroState';
import DocumentTitle from '../../DocumentTitle';
import { contactNameOrEmail } from '../Contacts/ContactDetail/ContactDetail';
import TasksFilter, { TASKS_FILTER_OPTIONS } from './TasksFilter';
import { ReactComponent as SvgIconEdit } from '../../../assets/images/icon-edit-field.svg';

import { selectCurrentAccountUser, selectAccountUser } from '../../../reducers/accountUsersReducer';
import { fetchTasks, markTaskAsDone, deleteTask } from '../../../actions/tasksActions';
import { selectAllTasks, selectIsLoading } from '../../../reducers/tasksReducer';

import styles from './Tasks.module.scss';
import { useNavigate, useParams } from 'react-router-dom';

const formatDateWithoutYear = createFormatDate('LLLL d');
const formatDateWithYear = createFormatDate('LLLL d, yyyy');
const isRecent = (date: Date) => isSameYear(date, new Date());

export function dateToRequiredFormat(date: Date) {
  return isRecent(date) ? formatDateWithoutYear(date) : formatDateWithYear(date);
}

const formatDate = (date: any) => {
  date = date.split('-');
  const currentDate = new Date(new Date().toDateString());
  const dueDate = new Date(date[0], date[1] - 1, date[2]);

  if (isBefore(dueDate, currentDate)) return <span className={styles.warningText}>Overdue</span>;
  else if (isEqual(dueDate, currentDate)) return 'Today';
  else if (isEqual(dueDate, new Date(currentDate.setDate(currentDate.getDate() + 1)))) {
    return 'Tomorrow';
  }
  return dateToRequiredFormat(dueDate);
};

const Tasks = () => {
  const { urlSite } = useParams();
  const navigate = useNavigate();
  const isLoading = useSelector(selectIsLoading);
  const allTasks = useSelector(selectAllTasks);
  const dispatch = useDispatch();

  // Columns
  const columns = [
    {
      Header: 'Title',
      accessor: '',
      Cell: ({ value }: any) => {
        const [onHover, setOnHover] = useState(false);
        const [showDetails, setShowDetails] = useState(false);
        return (
          <div
            onMouseEnter={() => setOnHover(true)}
            onMouseLeave={() => setOnHover(false)}
            className={classnames({
              [styles.completed]: value.status === 'done',
            })}
          >
            <span>{value.title}</span>
            {onHover && (
              <SvgIconEdit
                onClick={() => {
                  setSelectedTask(value);
                  setIsOpen(true);
                }}
                className={styles.edit}
                height={11}
                width={11}
              />
            )}
            {value.details && (
              <span onClick={() => setShowDetails(!showDetails)} className={styles.showDetails}>
                {showDetails ? 'Hide' : 'Show'} details
              </span>
            )}
            {showDetails && <div className={styles.details}>{value.details}</div>}
          </div>
        );
      },
      minWidth: 360,
      sortable: false,
    },
    {
      Header: 'Due on',
      accessor: '',
      Cell: ({ value }: any) =>
        value.status === 'done' ? (
          <div className={styles.completedText}>Completed</div>
        ) : (
          <span>{formatDate(value.due_on)}</span>
        ),
      width: 180,
    },
    {
      Header: 'Customer',
      accessor: '',
      Cell: ({ value }: any) => (
        <span
          className={classnames(styles.clickable, {
            [styles.completed]: value.status === 'done',
          })}
          onClick={() => navigate(`/${urlSite}/customers/${value.contact.id}/`)}
        >
          {contactNameOrEmail(value.contact)}
        </span>
      ),

      width: 200,
      sortable: false,
    },
    {
      Header: 'Type',
      accessor: '',
      Cell: ({ value }: any) => (
        <span
          className={classnames({
            [styles.completed]: value.status === 'done',
          })}
        >
          {value.type}
        </span>
      ),
      width: 85,
      className: styles.capitalize,
      sortable: false,
    },
    {
      Header: 'Status',
      accessor: '',
      Cell: ({ value }: any) => (
        <span
          className={classnames({
            [styles.completed]: value.status === 'done',
          })}
        >
          {value.status}
        </span>
      ),
      width: 85,
      className: styles.capitalize,
      sortable: false,
    },
    {
      Header: 'Owner',
      accessor: '',
      Cell: ({ value }: any) => {
        const owner = useSelector((state) => selectAccountUser(state, value.owner));
        return (
          <span
            className={classnames({
              [styles.completed]: value.status === 'done',
            })}
          >
            {owner && owner.name}
          </span>
        );
      },
      width: 150,
      sortable: false,
    },
    {
      Header: '',
      accessor: '',
      sortable: false,
      Cell: ({ value }: any) =>
        value.status === 'done' ? (
          <div
            onClick={() => {
              const remove = window.confirm(`Are you sure you want to remove this task?`);
              remove && dispatch(deleteTask(value.id));
            }}
            className={styles.removeButton}
          >
            Archive
          </div>
        ) : (
          <div className={styles.columnMarkButton} onClick={() => dispatch(markTaskAsDone(value))}>
            Mark as done
          </div>
        ),
      width: 140,
    },
  ];

  const [search, setSearch] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [selectedTask, setSelectedTask] = useState({});
  const [refetchList, toggleRefetchList] = useState(true);
  const [debouncedSearch, setDebouncedSearch] = useState('');
  const [filter, setFilter] = useState({ owner: TASKS_FILTER_OPTIONS[0] });

  const owner = useSelector((state) => selectCurrentAccountUser(state));

  useEffect(() => {
    async function fetch() {
      owner &&
        (await dispatch(
          fetchTasks({
            search,
            owner: filter.owner.value ? owner.id : '',
          })
        ));
    }
    fetch();
  }, [refetchList, filter, debouncedSearch, owner]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <DocumentTitle title={(siteName: string) => `${siteName}'s tasks`} />
      {owner && (
        <>
          <section>
            <header>
              <h1>Tasks</h1>
              <small>Stay on top of opportunities by creating tasks and follow ups.</small>
              <div style={{ clear: 'both' }}></div>
            </header>
            <TasksFilter
              openDrawer={() => setIsOpen(true)}
              debounceValue={(search: string) => setDebouncedSearch(search)}
              onChange={setSearch}
              setFilter={setFilter}
              filter={filter}
              searchTerm={search}
            />
            {isLoading ? (
              <Spinner className="fixed" />
            ) : !!allTasks.length ? (
              <Table
                columns={columns}
                data={allTasks}
                loading={false}
                showViewAll={true}
                defaultPageSize={10}
                getTheadProps={() => ({
                  style: {
                    zIndex: '0',
                  },
                })}
              />
            ) : search ? (
              <TasksZeroState message="Your search didn't return any results. Try searching something else." />
            ) : (
              <TasksZeroState
                openDrawer={() => setIsOpen(true)}
                message="Create todos, follow ups, and next steps for your customers to organize and
              close more deals"
              />
            )}
          </section>
          {isOpen && (
            <AddTask
              selectedTask={selectedTask}
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              setSelectedTask={setSelectedTask}
              refetchList={() => toggleRefetchList(!refetchList)}
              owner={owner.id}
            />
          )}
        </>
      )}
    </>
  );
};

export default Tasks;
