import { takeEvery, select, call, put, all } from 'redux-saga/effects';
import * as actionTypes from '../actions/actionTypes';
import * as actions from '../actions/siteStatisticActions';
import * as selectors from '../reducers/siteStatisticsReducer';
import * as accountActions from '../actions/accountActions';
import * as uiActions from '../actions/uiActions';
import requireSiteId from './utils/requireSiteId';
import * as api from '../services/spark-api';
import * as Klaviyo from '../lib/tracking/klaviyo';
import * as accountReducer from '../reducers/accountReducer';

function* fetchIfNeeded(action) {
  const { siteId, type } = action.payload;

  const isFetching = yield select(selectors.selectTypeIsFetching, type);
  const fetchedAt = yield select(selectors.selectTypeFetchedAt, type);

  // Consider the data "fresh" if it was fetched within the last hour;
  const isFresh = fetchedAt && Date.now() < fetchedAt + 1000 * 60 * 60;

  if (!isFresh && !isFetching) {
    yield put(actions.request(siteId, type));
  }
}

function* fetch(action) {
  const { type } = action.payload;
  const siteId = yield call(requireSiteId, action.payload.siteId);

  try {
    const response = yield call(api.getSiteStatistics, siteId, type);

    if (response.status === 200) {
      const email = yield select(accountReducer.selectAccountEmail);
      const date = new Date();
      date.setDate(date.getDate() - 30);
      const viewCount = Object.keys(response.json).reduce((prev, curr, idx) => {
        const pageDate = new Date(curr);
        return pageDate >= date ? prev + response.json[curr].pageviews : prev;
      }, 0);
      try {
        if (process.env.NODE_ENV !== 'development') {
          Klaviyo.identify({ page_views_last_30_days: viewCount, $email: email });
        }
      } catch (e) {
        console.log(e);
      }
      yield put(actions.receive(type, response.json));
    } else if (response.unauthorized) {
      yield put(accountActions.logout());
    } else {
      console.error('Failed trying to fetch site statistics', response.json);
    }
  } catch (err) {
    yield put(uiActions.connectionError());
    console.error(err);
  }
}

function* siteStatisticsSaga() {
  yield all([
    takeEvery(actionTypes.SITE_STATISTICS_REQUEST_IF_NEEDED, fetchIfNeeded),
    takeEvery(actionTypes.SITE_STATISTICS_REQUEST, fetch),
  ]);
}

export default siteStatisticsSaga;
