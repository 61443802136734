import { call, put, all, takeEvery, StrictEffect, select } from 'redux-saga/effects';
import * as api from '../services/spark-api';
import * as actionTypes from '../actions/actionTypes';
import * as APIKeysActions from '../actions/apiKeysActions';

import { callSparkApi } from './utils/callSparkApi';
import { APIKey, getAPIKeys } from 'reducers/apiKeysReducer';
import requireSiteId from './utils/requireSiteId';
import clone from 'lodash/cloneDeep';

function* fetchAPIKeys(): Generator<StrictEffect, any, { json: { results: APIKey[] }; ok: boolean }> {
  const siteId = yield call(requireSiteId as any);
  const response = yield call(callSparkApi, api.getAPIKeys, siteId);

  if (response.ok) {
    const keys = response.json.results;
    const keyDict: { [key: string]: APIKey } = {};
    keys.forEach((key) => (keyDict[key.id] = key));
    yield put(APIKeysActions.receiveKeys(keyDict));
  } else {
    yield put(APIKeysActions.receiveKeysError(response.json as unknown as string));
  }
}

function* deleteAPIKey(
  action: any
): Generator<StrictEffect, any, { json: { results: { [key: string]: APIKey } }; ok: boolean }> {
  const siteId = yield call(requireSiteId as any);
  const keys: any = clone(yield select(getAPIKeys as any));
  const response = yield call(callSparkApi, api.deleteAPIKey, action.key, siteId);

  if (response.ok) {
    delete keys[action.key];
    yield put(APIKeysActions.receiveKeys(keys));
  } else {
    yield put(APIKeysActions.receiveKeysError(response.json as unknown as string));
  }
}

function* addAPIKey(action: any): Generator<StrictEffect, any, { json: APIKey; ok: boolean }> {
  const siteId = yield call(requireSiteId as any);
  const keys: any = clone(yield select(getAPIKeys as any));
  const response = yield call(callSparkApi, api.addAPIKey, action.name, siteId);

  if (response.ok) {
    keys[response.json.id] = response.json;
    yield put(APIKeysActions.receiveKeys(keys));
  } else {
    yield put(APIKeysActions.receiveKeysError(response.json as unknown as string));
  }
}

function* apiKeysSaga() {
  yield all([
    takeEvery(actionTypes.API_KEYS_FETCH_KEYS, fetchAPIKeys),
    takeEvery(actionTypes.API_KEYS_DELETE_KEY, deleteAPIKey),
    takeEvery(actionTypes.API_KEYS_ADD_KEY, addAPIKey),
  ]);
}

export default apiKeysSaga;
