import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getFullDomainFromSite, selectSiteByDomain, selectSitesFetching } from '../reducers/sitesReducer';
import { requestAllIfNeeded } from '../actions/siteActions';
import { OnMount } from 'hooks/mountUnmountHooks';
import { useParams } from 'react-router-dom';

const withSite = (WrappedComponent: React.ComponentType<any>) => {
  const Container: React.FC<any> = (props) => {
    const dispatch = useDispatch();

    const { urlSite } = useParams();
    // @ts-ignore
    const site = props.site || useSelector((state) => selectSiteByDomain(state, urlSite));
    const sitesFetching = useSelector(selectSitesFetching);
    const siteFullDomain = site ? getFullDomainFromSite(site) : '';

    OnMount(() => {
      dispatch(requestAllIfNeeded());
    });

    const newProps = { ...props, site, sitesFetching, siteFullDomain };

    return <WrappedComponent {...newProps} />;
  };

  return Container;
};

export default withSite;
