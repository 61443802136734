import React, { useEffect, ReactNode } from 'react';
import { useLocation } from 'react-router-dom';

type Props = {
  children: ReactNode;
};

const ScrollToTop: React.FC<Props> = ({ children }) => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return <>{children}</>;
};

export default ScrollToTop;
