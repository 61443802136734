import React from 'react';

import styles from './MediaNoResults.module.scss';

export default (props) => (
  <div className={styles.container}>
    {props.icon}
    <h2>{props.header}</h2>
    <p>{props.content}</p>
  </div>
);
