import React, { useState, useEffect, useCallback } from 'react';
import {
  OverTray,
  Group,
  ButtonGroup,
  Button,
  TextInput,
  Label,
} from '../../../../components/Editor/Toolbars2/Toolbar2';
import { IconCancelItemLg, IconCheckmark, IconAnchor } from '../../../../components/Editor/Toolbars2/icons';

export default function AnchorButton(props) {
  const { quill, formats, applyFormat } = props;

  return (
    <OverTray
      borderless
      renderIcon={() => <IconAnchor active={!!formats['id-attribute']} />}
      renderTray={(close) => <Tray quill={quill} formats={formats} applyFormat={applyFormat} close={close} />}
    />
  );
}

function Tray(props) {
  const { formats, applyFormat, close } = props;
  const [value, setValue] = useState(formats['id-attribute']);

  const handleSubmit = useCallback(() => {
    applyFormat('id-attribute', value);
    close();
  }, [applyFormat, value, close]);

  const handleCancel = useCallback(() => {
    applyFormat('id-attribute', '');
    close();
  }, [applyFormat, value, close]);

  useEffect(() => {
    function handleKeydown(e) {
      switch (e.which) {
        case 27:
          close();
          break;
        case 13:
          e.preventDefault();
          handleSubmit();
          break;
        default:
      }
    }

    document.addEventListener('keydown', handleKeydown);
    return () => document.removeEventListener('keydown', handleKeydown);
  }, [handleSubmit, close]);

  return (
    <>
      <Group style={{ flex: '1' }}>
        <Label>#</Label>
        <TextInput
          value={value}
          placeholder="Add anchor to link to this block"
          onChange={(e) => setValue(e.target.value)}
        />
      </Group>
      <ButtonGroup>
        <Button onClick={handleCancel} data-tip="Remove link">
          <IconCancelItemLg />
        </Button>
        <Button onClick={handleSubmit} data-tip="Save link" disabled={!value}>
          <IconCheckmark />
        </Button>
      </ButtonGroup>
    </>
  );
}
