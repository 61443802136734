import * as types from './actionTypes';

export function connectionError(error) {
  return {
    type: types.CONNECTION_ERROR,
    payload: { error },
  };
}

export function clearConnectionError() {
  return {
    type: types.CLEAR_CONNECTION_ERROR,
  };
}

export function changeDevice(device) {
  return {
    type: types.DEVICE,
    payload: { device },
  };
}

export function changeDeviceDimensions(width) {
  return {
    type: types.DEVICE_DIMENSIONS,
    payload: { width },
  };
}

export function changeRotation(key, rotated) {
  return {
    type: types.DEVICE_ROTATION,
    payload: { key, rotated },
  };
}
