import * as actionTypes from './actionTypes';

export function requestAll(siteId) {
  return {
    type: actionTypes.FONTS_REQUEST_ALL,
    payload: siteId,
  };
}

export function requestAllIfNeeded() {
  return {
    type: actionTypes.FONTS_REQUEST_ALL_IF_NEEDED,
  };
}

export function receiveAll(payload) {
  return {
    type: actionTypes.FONTS_RECEIVE_ALL,
    payload,
  };
}
