import { takeEvery, put, select, all, call, takeLatest } from 'redux-saga/effects';
import * as types from '../actions/actionTypes';
import * as api from '../services/spark-api';
import * as blogActions from '../actions/blogActions';
import * as pageActions from '../actions/pageActions';
import * as itemsActions from '../actions/itemsActions';
import * as itemsUrlActions from '../actions/itemsUrlActions';
import * as mediaActions from '../actions/mediaActions';
import * as pageSelectors from '../reducers/pagesReducer';
import * as articlesReducer from '../reducers/articlesReducer';
import * as blogsReducer from '../reducers/blogsReducer';
import * as articleActions from '../actions/articleActions';
import { normalizePageList, normalizePage } from './normalizrs/page-normalizrs';
import { normalizeList } from './normalizrs/article-normalizrs';
import requireSiteId from './utils/requireSiteId';
import { normalizeUrlList } from './normalizrs/item-url-normalizers';
import * as Klaviyo from '../lib/tracking/klaviyo';
import * as accountReducer from '../reducers/accountReducer';

function* requestIfNeeded(action) {
  const { payload: id } = action;
  const page = yield select(pageSelectors.selectPage, id);
  const article = yield select(articlesReducer.selectArticle, id);
  const blog = yield select(blogsReducer.selectBlog, id);
  if (!(page || article || blog)) yield put(itemsActions.request(id));
}

function* request(action) {
  const { payload: id } = action;
  try {
    const response = yield call(api.getItem, id);
    if (response.ok) {
      const item = response.json;
      const type = item.item_type;
      if (type.includes('page')) {
        const normalizedData = normalizePage(item);
        const { result: pageId, entities } = normalizedData;
        yield put(pageActions.receiveListItem(pageId, entities));
      } else if (type === 'article') {
        yield put(articleActions.receive(item));
      } else if (type === 'blog') {
        yield put(blogActions.receive(item));
      }
    }
  } catch (error) {
    console.log(error);
  }
}

function* save(action) {
  const { item } = action;
  const { item_type: type, id } = item;
  if (type.includes('page')) {
    const isAlreadyAvailable = yield select(pageSelectors.selectPage, id);
    if (!isAlreadyAvailable) {
      const normalizedData = normalizePage(item);
      const { entities } = normalizedData;
      yield put(pageActions.receivePage(entities));
    }
  } else if (type === 'article') {
    const article = yield select(articlesReducer.selectArticle, id);
    if (article) yield put(articleActions.receive(item));
  } else if (type === 'blog') {
    const blog = yield select(blogsReducer.selectBlog, id);
    if (blog) yield put(blogActions.receive(item));
  }
}

function* fetchFilteredItemsList(action) {
  const { payload } = action;
  const type = payload.item_type;
  if (!type) return;
  const siteId = yield call(requireSiteId, action.payload.siteId);

  try {
    const response = yield call(api.getItems, { ...payload, site_id: siteId });
    if (response.ok) {
      let normalizedEntities;
      if (!payload.search && !payload.status) {
        const email = yield select(accountReducer.selectAccountEmail);
        try {
          if (process.env.NODE_ENV !== 'development') {
            Klaviyo.identify({ [`number_${type}`]: response.json.count, $email: email });
          }
        } catch (e) {
          console.log(e);
        }
      }
      if (type.includes('page') || type.includes('product')) {
        const normalizedData = normalizePageList(response.json.results);
        const { result: pageIds, entities } = normalizedData;
        normalizedEntities = entities;
        yield put(pageActions.receiveList(pageIds, entities, response.json.count));
      } else if (type === 'article') {
        yield put(articleActions.requestFilteredArtcles());
        const normalizedData = normalizeList(response.json.results);
        const { result: articleIds, entities } = normalizedData;
        normalizedEntities = entities;
        yield put(articleActions.receiveList(articleIds, entities, response.json.count));
      } else if (type === 'blog') {
        // yield put(blogActions.receive(item));
      }
      const items = normalizedEntities.pages || normalizedEntities.articles || [];
      const featuredMedias = Object.values(items).reduce((accumulator, item) => {
        item.featured_media && item.featured_media.id && accumulator.push(item.featured_media);
        return accumulator;
      }, []);
      yield put(mediaActions.receiveArray(featuredMedias));
    }
  } catch (e) {
    console.error(e);
  }
}

function* requestUrls(action) {
  const { payload } = action;
  const siteId = yield call(requireSiteId, payload.siteId);

  try {
    const response = yield call(api.getItemUrls, {
      ...payload,
      site_id: siteId,
    });

    if (response.ok) {
      const { result: urlsId, entities } = normalizeUrlList(response.json.results);
      yield put(itemsUrlActions.saveItemUrls(urlsId, entities, response.json.count));
    }
  } catch (e) {
    console.error(e);
  }
}

function* flushCache(action) {
  const { payload, callback } = action;
  const siteId = yield call(requireSiteId, payload.siteId);

  try {
    const response = yield call(api.flushItemUrlCache, { siteId, ...payload });
    if (response.ok) {
      callback && callback();
    }
  } catch (e) {
    console.error(e);
  }
}

function* createOrUpdateUrl(action) {
  const { payload, resolve, reject } = action;
  const siteId = yield call(requireSiteId, payload.siteId);
  payload.item = payload.item.split(':').slice(-1)[0];

  try {
    const response = payload.id
      ? yield call(api.updateUrl, payload)
      : yield call(api.createUrl, { ...payload }, siteId);
    if (response.ok) {
      payload.id
        ? yield put(itemsUrlActions.updateSuccess(response.json))
        : yield put(itemsUrlActions.createSuccess(response.json));
      resolve();
    } else if (response.status === 400) {
      reject(response.json);
    }
  } catch (e) {
    console.error(e);
  }
}

function* removeUrl(action) {
  const { payload, callback } = action;

  try {
    const response = yield call(api.removeUrl, payload.id);
    if (response.ok) {
      yield put(itemsUrlActions.removeSuccess(payload.id));
      callback();
    }
  } catch (e) {
    console.error(e);
  }
}

function* itemsSaga() {
  yield all([
    takeEvery(types.ITEM_SAVE, save),
    takeEvery(types.ITEM_REQUEST, request),
    takeEvery(types.ITEM_REQUEST_IF_NEEDED, requestIfNeeded),
    takeEvery(types.ITEM_REQUEST_FILTERED_LIST, fetchFilteredItemsList),
    takeLatest(types.ITEM_URLS_REQUEST, requestUrls),
    takeLatest(types.ITEM_URL_FLUSH_CACHE, flushCache),
    takeLatest(types.ITEM_URL_CREATE_OR_UPDATE, createOrUpdateUrl),
    takeLatest(types.ITEM_URL_REMOVE, removeUrl),
  ]);
}

export default itemsSaga;
