import * as actionTypes from '../actions/actionTypes';
import { ToolbarActions } from 'actions/toolbarActions';

// Reducer
// ----------------------------------------------------------------------------

type ToolbarState = {
  activeToolbar?: string;
  hoverToolbar?: string;
  cursorOnToolbar?: boolean;
  isDrawerOpen: boolean;
  error?: string;
  expandedActionMenu?: string;
  activeSection?: string;
};

const initState: ToolbarState = {
  isDrawerOpen: false,
};

export default function (state = initState, action: ToolbarActions) {
  switch (action.type) {
    case actionTypes.TOOLBAR_SET_DRAWER_OPEN:
      return {
        ...state,
        isDrawerOpen: true,
      };

    case actionTypes.TOOLBAR_SET_DRAWER_CLOSE:
      return {
        ...state,
        isDrawerOpen: false,
      };

    case actionTypes.TOOLBAR_ACTIVE_CHANGED:
      return {
        ...state,
        activeToolbar: action.activeToolbar,
      };
    case actionTypes.TOOLBAR_HOVER_CHANGED:
      return {
        ...state,
        hoverToolbar: action.hoverToolbar,
      };
    case actionTypes.TOOLBAR_CURSOR_ON_CHANGED:
      return {
        ...state,
        cursorOnToolbar: action.cursorOnToolbar,
      };
    case actionTypes.TOOLBAR_ACTIONS_MENU_EXPANDED:
      return {
        ...state,
        expandedActionMenu: action.expandedUUID,
      };
    case actionTypes.SECTION_ACTIVE_CHANGED:
      return {
        ...state,
        activeSection: action.activeSection,
      };

    default:
      return state;
  }
}

// Raw selectors
// ----------------------------------------------------------------------------

export function selectLocalState(globalState: { toolbar?: ToolbarState }) {
  return globalState.toolbar;
}

export function getIsDrawerOpen(globalState: { toolbar?: ToolbarState }) {
  const state = selectLocalState(globalState);
  return state.isDrawerOpen;
}

export function getActiveToolbar(globalState: { toolbar?: ToolbarState }) {
  const state = selectLocalState(globalState);
  return state.activeToolbar;
}

export function getHoverToolbar(globalState: { toolbar?: ToolbarState }) {
  const state = selectLocalState(globalState);
  return state.hoverToolbar;
}

export function getActiveSection(globalState: { toolbar?: ToolbarState }) {
  const state = selectLocalState(globalState);
  return state.activeSection;
}

export function isCursorOnToolbar(globalState: { toolbar?: ToolbarState }) {
  const state = selectLocalState(globalState);
  return state.cursorOnToolbar;
}

export function expandedActionMenu(globalState: { toolbar?: ToolbarState }) {
  const state = selectLocalState(globalState);
  return state.expandedActionMenu;
}
