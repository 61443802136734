import React, { useState } from 'react';

import Datetime from 'react-datetime';

import Popper from 'components/base/Popper';
import styles from '../Sheet/Sheet.module.scss';
import 'react-datepicker/dist/react-datepicker-cssmodules.css';

import DateTimeFormatter from 'components/AdminPanel/DataTables/Formatters/DateTimeFormatter';
import { Moment } from 'moment';

type Props = {
  value: string;
  onChange: (date: string) => {};
  removeSheetStyles: () => {};
  associatedLabelId: string;
};

export const DateTimeInput = ({ value, onChange, removeSheetStyles, associatedLabelId }: Props) => {
  const [date, setDate] = useState<string | Moment>(value);

  return removeSheetStyles ? (
    <Datetime
      value={date}
      onChange={(value) => {
        onChange((value as Moment).toISOString());
      }}
      dateFormat="MMM D, YYYY"
      renderView={(mode, defaultRender) => {
        if (mode === 'time') return defaultRender();

        return <div className={styles.dateTimeInput}>{defaultRender()}</div>;
      }}
      renderDay={(props, currentDate) => {
        return <td {...props}>{currentDate.date()}</td>;
      }}
      renderMonth={(props, month) => {
        return <td {...props}>{month}</td>;
      }}
      renderYear={(props, year) => {
        return <td {...props}>{year}</td>;
      }}
    />
  ) : (
    <Popper modifiers={[{ name: 'offset', options: { offset: [12, 2] } }]}>
      <Datetime
        value={date}
        inputProps={{
          className: styles.datePicker,
          autoFocus: true,
        }}
        onChange={(value) => {
          setDate(value);
        }}
        onClose={() => {
          if (typeof date === 'string') {
            return;
          } else {
            onChange(date.toISOString());
          }
        }}
        dateFormat="MMM D, YYYY"
        renderView={(mode, defaultRender) => {
          if (mode === 'time') return defaultRender();

          return <div className={styles.dateTimeInput}>{defaultRender()}</div>;
        }}
        renderDay={(props, currentDate) => {
          return <td {...props}>{currentDate.date()}</td>;
        }}
        renderMonth={(props, month) => {
          return <td {...props}>{month + 1}</td>;
        }}
        renderYear={(props, year) => {
          return <td {...props}>{year}</td>;
        }}
        renderInput={(props, openCalendar, closeCalendar) => {
          openCalendar();
          return (
            <div onClick={() => closeCalendar()} style={{ cursor: 'pointer', fontSize: '1.5rem', minHeight: '10px' }}>
              <DateTimeFormatter value={props.value} />
            </div>
          );
        }}
      />
    </Popper>
  );
};
