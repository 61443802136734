import * as types from './actionTypes';
import { v4 as uuidv4 } from 'uuid';
import defaultSectionProps from './sections/default-section-props';
// import { selectSection } from '../reducers/sectionsReducer';
import { selectPageIdFromSectionId } from '../reducers/pagesReducer';
import idx from 'lodash/get';
import omit from 'lodash/omit';

function dispatchThenTouchPage(action) {
  const sectionId = action.payload.id;

  return function (dispatch, getState) {
    let pageId = action.payload.pageId || idx(action, 'payload.sectionProps.pageId');

    if (pageId == null || pageId == undefined) {
      const state = getState();
      // const section = selectSection(state, sectionId);
      // pageId = section.pageId;
      pageId = selectPageIdFromSectionId(state, sectionId);
    }

    dispatch(action);
    dispatch({ type: types.PAGE_TOUCH, payload: { id: pageId } });
    return action;
  };
}

export function add(payload, callback) {
  return {
    type: types.SECTIONS_REQUEST_CREATE,
    payload,
    callback,
  };
}

export function addBetween(sectionProps, idx) {
  const { pageId, type } = sectionProps;

  if (pageId == null) throw new Error('New sections require a pageId');
  if (type == null) throw new Error('New sections require a `type` specified.');

  const id = uuidv4();
  const sectionType = sectionProps.type;

  return dispatchThenTouchPage({
    type: types.SECTIONS_ADD_BETWEEN,
    payload: sectionProps.id
      ? { id, sectionProps: omit(sectionProps, ['id']), idx }
      : {
          id,
          sectionProps: {
            ...sectionProps,
            type: sectionType,
          },
          idx,
        },
  });
}

export function showPlaceholder(pageId, sectionIndex) {
  return {
    type: types.SECTIONS_SHOW_PLACEHOLDER,
    payload: { pageId, sectionIndex },
  };
}

export function hidePlaceholderSection(pageId) {
  return {
    type: types.SECTIONS_HIDE_PLACEHOLDER,
    payload: { pageId },
  };
}

export function update(id, sectionProps) {
  return dispatchThenTouchPage({
    type: types.SECTIONS_UPDATE,
    payload: {
      id,
      sectionProps,
    },
  });
}

export function remove(id, pageId = undefined) {
  return dispatchThenTouchPage({
    type: types.SECTIONS_DELETE,
    payload: {
      id,
      pageId,
    },
  });
}

export function replace(id, newSection) {
  return dispatchThenTouchPage({
    type: types.SECTIONS_REPLACE,
    payload: {
      id,
      newSection,
    },
  });
}

export function receive(entities) {
  return {
    type: types.SECTIONS_RECEIVE,
    payload: { entities },
  };
}

export function pushNestedProp(id, path, value) {
  return dispatchThenTouchPage({
    type: types.SECTIONS_PUSH_NESTED_PROP,
    payload: {
      id,
      path,
      value,
    },
  });
}

export function updateNestedProp(id, path, value) {
  return dispatchThenTouchPage({
    type: types.SECTIONS_UPDATE_NESTED_PROP,
    payload: {
      id,
      path,
      value,
    },
  });
}

export function reorderNestedProps(id, path, updatedProps) {
  return dispatchThenTouchPage({
    type: types.SECTIONS_REORDER_NESTED_PROP,
    payload: {
      id,
      path,
      updatedProps,
    },
  });
}

export function deleteNestedProp(id, path) {
  return dispatchThenTouchPage({
    type: types.SECTIONS_DELETE_NESTED_PROP,
    payload: {
      id,
      path,
    },
  });
}

export function popNestedProp(id, path) {
  return dispatchThenTouchPage({
    type: types.SECTIONS_POP_NESTED_PROP,
    payload: {
      id,
      path,
    },
  });
}

export function removeNestedProp(id, path, index) {
  return dispatchThenTouchPage({
    type: types.SECTIONS_REMOVE_NESTED_PROP,
    payload: {
      id,
      path,
      index,
    },
  });
}

export function reorder(pageId, sectionId, newIndex) {
  return dispatchThenTouchPage({
    type: types.SECTIONS_REORDER,
    payload: { pageId, sectionId, newIndex },
  });
}

export function initializeABTest(id, callback) {
  return {
    type: types.SECTIONS_INITIALIZE_A_B_TEST,
    payload: { id },
    callback,
  };
}

export function discardABTest(testSectionId, winningVariantId, pageId = undefined) {
  return dispatchThenTouchPage({
    type: types.SECTIONS_DISCARD_A_B_TEST,
    payload: { testSectionId, winningVariantId, pageId },
  });
}

export function initializeContainer(sectionId, type, callback) {
  return {
    type: types.SECTIONS_INITIALIZE_CONTAINER,
    payload: { sectionId, type },
    callback,
  };
}

export function addChildToContainer(containerId, type, component, callback, pageId) {
  return {
    type: types.SECTIONS_ADD_TO_CONTAINER,
    payload: {
      containerId,
      type,
      component,
      pageId,
    },
    callback,
  };
}

export function updateContainerChildren(id, children) {
  return dispatchThenTouchPage({
    type: types.SECTIONS_UPDATE_CONTAINER,
    payload: { id, children },
  });
}

export function removeContainerChild(containerId, childIdx) {
  return dispatchThenTouchPage({
    type: types.SECTIONS_REMOVE_CONTAINER_CHILD,
    payload: { containerId, childIdx },
  });
}

export function createElement(sectionId, name) {
  return {
    type: types.SECTIONS_CREATE_ELEMENT,
    payload: { sectionId, name },
  };
}
