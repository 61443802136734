/* eslint-disable jsx-a11y/anchor-is-valid */

import React from 'react';
import { compose } from 'redux';
import withSiteId from '../../../../containers/withSiteId';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';

import CustomCodeDrawer from './CustomCodeDrawer';
import CustomCodes from './CustomCodes';
import CustomCodesZeroState from './CustomCodesZeroState';

import { selectSiteIntegrationsByType } from '../../../../reducers/integrationsReducer';
import * as siteSelectors from '../../../../reducers/sitesReducer';

import CheckPermissionOnClick from '../../../SubscriptionManager/CheckPermissionOnClick';

import { ReactComponent as SvgIconAdd } from '../../../../assets/images/icon-add-section.svg';
import styles from './CustomCodeSection.module.scss';
import { CUSTOM_INTEGRATION_ID } from 'settings';

function mapStateToProps(state, ownProps) {
  return {
    integrations: selectSiteIntegrationsByType(state, ownProps.siteId, CUSTOM_INTEGRATION_ID),
  };
}

class CustomCodeSection extends React.PureComponent {
  state = {
    isOpen: false,
    editIntegration: { config: {} },
  };
  open = (integration) => this.setState({ isOpen: true, editIntegration: integration });
  close = () => this.setState({ isOpen: false, editIntegration: { config: {} } });
  toggle = () => this.setState({ isOpen: !this.state.isOpen });

  handleClickAddNewIntegration = () => {
    this.open({ config: {} });
  };

  editIntegration = (integration) => {
    this.open(integration);
  };

  render() {
    const { integrations } = this.props;
    const { isOpen, editIntegration } = this.state;

    return (
      <section className={styles.CustomCodeSection}>
        <header>
          <h1>Custom</h1>
          <small>Add any script to your pages.</small>
          <div className={styles.add}>
            <CheckPermissionOnClick
              action="SiteIntegration:create:::custom"
              onClick={this.handleClickAddNewIntegration}
              render={(onClick, hasPermission) => <SvgIconAdd className={styles.add} onClick={onClick} role="button" />}
            />
          </div>
        </header>
        {isEmpty(integrations) && <CustomCodesZeroState addNewIntegration={this.handleClickAddNewIntegration} />}

        {!isEmpty(integrations) && (
          <article className={styles.customCodes}>
            <CustomCodes editIntegration={this.editIntegration} addNewIntegration={this.handleClickAddNewIntegration} />
          </article>
        )}

        <CustomCodeDrawer isOpen={isOpen} close={this.close} editIntegration={editIntegration} />
      </section>
    );
  }
}

export default compose(withSiteId, connect(mapStateToProps))(CustomCodeSection);
