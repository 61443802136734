import Quill from '../quill-1.3.7/quill';

import noMediaSvg from '../../../assets/images/icon-media-removed.svg';
import svgicon from '../../../assets/images/icon-play-video.svg';

const BlockEmbed = Quill.import('blots/block/embed');

class VideoBlot extends BlockEmbed {
  static create(value) {
    let node = super.create();
    let wrapper = document.createElement('div');
    let playIcon = document.createElement('img');

    playIcon.setAttribute('src', svgicon);
    playIcon.setAttribute('style', 'height: 50%; margin: 14% 0;');

    // Parse smart url
    const parsedUrl = window.parseSmartUrl ? window.parseSmartUrl(value.src) : value.src;

    const assignOptionalAttributes = (attr) => {
      if (Boolean(value[attr])) {
        node.dataset[attr] = value[attr];
      }
    };

    node.dataset.rawSrc = value.src;
    assignOptionalAttributes('alt');
    assignOptionalAttributes('controls');
    assignOptionalAttributes('autoplay');
    assignOptionalAttributes('loop');
    assignOptionalAttributes('muted');

    // Set src as parsed url.
    node.setAttribute('src', parsedUrl);

    node.setAttribute('onerror', `this.src="${noMediaSvg}"`);

    wrapper.setAttribute('class', 'video-embed');
    wrapper.setAttribute('style', 'cursor: pointer');
    wrapper.appendChild(node);
    wrapper.appendChild(playIcon);

    return wrapper;
  }

  static value(node) {
    const value = {};
    const video = node.firstElementChild;

    value.src = video.dataset.rawSrc === 'undefined' ? undefined : video.dataset.rawSrc;

    value.controls = Boolean(video.dataset.controls);
    value.autoplay = Boolean(video.dataset.autoplay);
    value.loop = Boolean(video.dataset.loop);
    value.muted = Boolean(video.dataset.muted);

    return value;
  }
}
VideoBlot.blotName = 'video';
VideoBlot.tagName = 'video';

export default VideoBlot;
