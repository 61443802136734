function slugify(value) {
  if (value == null) return '';

  return value
    .toLowerCase()
    .replace(/^\W*/, '')
    .replace(/\W*$/, '')
    .replace(/'/g, '')
    .replace(/\b\W+\b/g, '-')
    .replace(/[^\w-]/g, '');
}

export default slugify;
