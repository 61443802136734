import React from 'react';
// import classnames from 'classnames';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
// import { Search as SearchEngine } from 'js-search';

// import Spinner from '../../../Spinner';
// import Search from '../../../../Search';
import MediaNoResults from '../MediaNoResults';
// import { VideoItem } from './VideoItem';
// import { MediaOptionsDrawer } from '../MediaOptionsDrawer';
import { VimeoEmbed, YoutubeEmbed, WistiaEmbed } from '../../VideoPlayers';
import { ReactComponent as SvgNoResults } from '../../../../../assets/images/icon-media-videos.svg';
import { ReactComponent as IconUrl } from '../../../../../assets/images/icon-video-url.svg';
import { ReactComponent as IconAdd } from '../../../../../assets/images/icon-upload-media.svg';
import { ReactComponent as SvgIconClear } from '../../../../../assets/images/icon-clear-search.svg';

import * as mediaActions from '../../../../../actions/mediaActions';
import * as mediaSelectors from '../../../../../reducers/mediaReducer';

import { processUrl, getSourceType, isUrlValid } from '../../../../../lib/process-video-urls';

import styles from './Video.module.scss';

// Returns true if entity.src is a smartUrl of a supported embed type.
export function isEmbedMediaEntity(entity) {
  return entity.src && !!entity.src.match(/^(youtube|vimeo|wistia):/);
}

// If props.entity matches a supported embed type, returns props.entity
// untouched. Otherwise returns an empty object {}.
function getEntityFromProps(props) {
  return isEmbedMediaEntity(props.entity) ? props.entity : {};
}

function mapStateToProps(state) {
  const isFetching = mediaSelectors.selectIsFetching(state);
  const allMedia = mediaSelectors.selectAll(state);

  return {
    isLoading: isFetching && isEmpty(allMedia),
    allMedia,
  };
}

const mapDispatchToProps = {
  requestAll: mediaActions.requestAll,
  requestAllIfNeeded: mediaActions.requestAllIfNeeded,
  deleteMedia: mediaActions.deleteMedia,
};

class Video extends React.PureComponent {
  constructor(props) {
    super(props);

    // this.engine = new SearchEngine('url');

    this.players = {
      youtube: YoutubeEmbed,
      vimeo: VimeoEmbed,
      wistia: WistiaEmbed,
    };

    this.state = {
      entity: {
        ...getEntityFromProps(props),
        type: getSourceType(props.entity.src),
        isValid: isUrlValid(props.entity.src),
      },
      selectedVideo: {},
      altText: props.entity.alt || '',
      query: '',
      showClear: Boolean(props.entity.rawSrc),
    };
  }

  // componentDidMount() {
  //   this.props.requestAll();
  //   this.engine.addIndex('url');
  // }

  componentDidUpdate(prevProps) {
    if (prevProps.entity?.src !== this.props.entity?.src) {
      this.setState({
        entity: getEntityFromProps(this.props),
      });
    }
  }

  componentWillUnmount() {
    if (isEmpty(this.state.entity)) {
      this.props.onChange();
    }
  }

  handleUrlChange = (e) => {
    const value = e.target.value;

    const entity = {
      rawSrc: value,
    };

    const [type, smartUrl, isValid] = processUrl(value);

    if (smartUrl) {
      entity.src = smartUrl;
      entity.type = type;
      entity.isValid = isValid;
    }

    this.setState({ entity });
  };

  render() {
    const { entity, showClear } = this.state;

    // let filteredVideos = this.props.allMedia.filter(
    //   m => m.category_id === 'video'
    // );
    // this.engine.addDocuments(filteredVideos);
    // filteredVideos = this.state.query
    //   ? this.engine.search(this.state.query)
    //   : filteredVideos;

    return (
      <div className={styles.container}>
        <MediaNoResults
          icon={<SvgNoResults height={50} width={63} />}
          content="Insert a URL below to embed video."
          header="Embed a video"
        />

        <div className={styles.urlInputContainer}>
          <IconUrl />
          <input
            placeholder="Add YouTube, Vimeo, or Wistia URL"
            aria-label="Embedded url"
            className={styles.inputField}
            onChange={this.handleUrlChange}
            value={entity.rawSrc || ''}
            ref={(input) => input && input.focus()}
          />
          {entity.src && entity.isValid ? (
            showClear ? (
              <SvgIconClear
                onClick={() => {
                  this.setState({ showClear: false, entity: {} });
                }}
              />
            ) : (
              <IconAdd onClick={() => this.props.onChange(entity)} data-test-id="add" />
            )
          ) : null}
        </div>

        {/* Video library code for uploaded video handling */}
        {/* {Boolean(filteredVideos.length || this.state.query) && (
          <Search
            placeholder="Search videos"
            debounceValue={() => {}}
            enableDarkMode
            value={this.state.query}
            autofocus={!this.state.selectedVideo.id}
            onChange={query => this.setState({ query })}
            containerStyle={{ position: 'absolute', top: '20px' }}
          />
        )} */}
        {/* {this.props.isLoading ? (
          <div className={styles.isLoading}>
            <Spinner />
          </div>
        ) : filteredVideos.length ? (
          <div className={styles.contentContainer}>
            {filteredVideos.map(video => (
              <VideoItem
                key={video.id}
                video={video}
                selected={this.state.selectedVideo.id === video.id}
                category={this.props.category}
                removeMedia={() => this.props.deleteMedia(video.id)}
                onClick={() => {
                  this.setState({
                    selectedVideo:
                      this.state.selectedVideo.id === video.id ? {} : video,
                  });
                }}
              />
            ))}
          </div>
        ) : (
          <MediaNoResults
            icon={<SvgNoResults height={50} width={63} />}
            content="You have no videos in Unstack. Upload a video or embed it by adding a URL above."
            header="No videos uploaded"
          />
        )}
        {this.state.selectedVideo.id && (
          <MediaOptionsDrawer
            onAltTextChange={e => this.setState({ altText: e.target.value })}
            altText={this.state.altText}
            onSave={() =>
              this.props.onChange({
                src: `media:${this.state.selectedVideo.id}`,
                alt: this.state.altText,
                type: 'video',
              })
            }
            onRemove={() => this.props.onChange()}
            entity={this.props.entity}
            isSectionContent={
              this.props.entity.src &&
              this.props.entity.src.includes(this.state.selectedVideo.id)
            }
          />
        )} */}
      </div>
    );
  }
}

Video = connect(mapStateToProps, mapDispatchToProps)(Video);

export { Video };
