import React, { useMemo, useCallback } from 'react';

import Renderer from './Renderer';
import { DebugElement } from './util/debug';

import { useResources } from '../../../lib/redux-resource/hooks';
import { useSelector } from 'react-redux';
import { selectComponentDefaults } from 'reducers/componentReducer';
import { selectActiveSite } from 'reducers/sitesReducer';
import { getDevice } from 'reducers/uiReducer';
import { UContext } from 'types/UContext';
import { USection } from 'types/USection';
import { SectionHandlersInterface, SectionUiHandlersInterface } from '../types';

type ContainerProps = {
  content: USection;
  onContentChange: (content: USection) => void;
  sectionHandlers: SectionHandlersInterface;
  sectionUiHandlers: SectionUiHandlersInterface;
};

const Container = (props: ContainerProps) => {
  const { content, onContentChange, sectionHandlers, sectionUiHandlers } = props;
  const activeSite = useSelector(selectActiveSite);
  const device = useSelector(getDevice);

  // And unwrap the content from the `context object` on change.
  const onContextChange = useCallback(
    (context: UContext) => {
      return onContentChange(context.content);
    },
    [onContentChange]
  );

  const components = useResources('sectionTypes');
  const componentsDefaults = useSelector(selectComponentDefaults);

  const component =
    components.find(
      (type: { id: string; parent: string }) => type.id === content.component || type.parent === content.component
    ) || {};

  const handlers = useMemo(
    () => ({
      ...sectionHandlers,
      isDeleted: component.is_deleted,
      componentName: component.name,
      isProductComponent: !!component.tags?.Product,
    }),
    [component, sectionHandlers]
  );

  // Wrap content into a 'context' object for the template parser.
  //  @ts-ignore
  const context: UContext = useMemo(
    () => ({
      content,
      componentId: component && component.id,
      cart_language: activeSite.metadata['cart_language'] || 'cart',
    }),
    [content, component, activeSite.metadata]
  );

  const create_rendered_css = useCallback(() => {
    let refactoredCss = component.rendered_css;
    const result = component.rendered_css?.match(/[0-9]*vh/);
    if (result) {
      let height = device === 'mobile' ? 667 : device === 'tablet' ? 820 : 1000;
      result.forEach(function printing(element: string) {
        const proportion = Number(element.split('vh')[0]) * 0.01;
        refactoredCss = refactoredCss.replace(element, `${Number(proportion) * height}px`);
      });
    }
    return refactoredCss;
  }, [component.rendered_css, device]);

  const rendered_css = create_rendered_css();

  if (!content) {
    return (
      <DebugElement color="#f0f" label="No content">
        No content
      </DebugElement>
    );
  }

  if (!component) {
    return (
      <DebugElement color="#f0f" label="Component template not defined">
        Component template not defined
      </DebugElement>
    );
  }

  return (
    <>
      <Renderer
        jsx={component.jsx}
        context={context}
        onContextChange={onContextChange}
        sectionHandlers={handlers}
        sectionUiHandlers={sectionUiHandlers}
        componentsDefaults={componentsDefaults}
      />
      {rendered_css && <style>{rendered_css}</style>}
    </>
  );
};

export default Container;
