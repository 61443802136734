import { useState, useEffect, useMemo } from 'react';
import { batch } from 'react-redux';
import createFormatDate from 'date-fns/fp/format';
import useActiveSite from '../../../hooks/useActiveSite';
import {
  getAnalyticsConversionPathReport,
  getAnalyticsConversionSourceReport,
  getContentTestReport,
  getPagesEngagementData,
  getGoogleConsoleQueryStats,
  getGoogleConsolePageStats,
} from '../../../services/spark-api';

// Filter state
export function useFilterState() {
  const [channel, setChannel] = useState('organic');
  const [goal, setGoal] = useState('all');
  const [touched, setTouched] = useState(false);

  const filterState = useMemo(
    () => ({
      channel,
      setChannel: (...args) => {
        setTouched(true);
        return setChannel(...args);
      },
      goal,
      setGoal: (...args) => {
        setTouched(true);
        return setGoal(...args);
      },
      touched,
    }),
    [channel, setChannel, goal, setGoal, touched, setTouched]
  );

  return filterState;
}

// Abstracted data fetcher
function useData(filterState, api, ...params) {
  const [isFetching, setIsFetching] = useState();
  const [data, setData] = useState();
  const siteId = useActiveSite((site) => site.id);
  const isLoading = isFetching === true || isFetching === undefined;

  const { channel, goal } = filterState;

  useEffect(() => {
    let isMounted = true;

    async function fetchData() {
      setIsFetching(true);

      const response = await api(siteId, channel, goal, ...params);

      if (isMounted && response.ok) {
        batch(() => {
          setIsFetching(false);
          setData(response.json);
        });
      }
    }

    fetchData();

    return () => (isMounted = false);

    // because we're intentionally using the spread operator on params:
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channel, goal, api, ...params]);

  return [data, isLoading];
}

// Fetch conversion path report data
export function useConversionPathReportData(filterState) {
  return useData(filterState, getAnalyticsConversionPathReport);
}

// Fetch conversion source report data
export function useConversionSourceReportData(filterState, segment) {
  return useData(filterState, getAnalyticsConversionSourceReport, segment);
}

// Fetch AB test report data
export function useContentTestReportData(filterState) {
  return useData(filterState, getContentTestReport);
}

//  Fetch engagement data
export function usePagesEngagementData(filterState) {
  return useData(filterState, getPagesEngagementData);
}

//  Fetch google console data
export function useGoogleConsoleData(id, domain) {
  const [isFetching, setIsFetching] = useState(true);
  const [data, setData] = useState();
  const [error, setError] = useState();
  const isLoading = isFetching === true || isFetching === undefined;

  useEffect(() => {
    let isMounted = true;

    async function fetchData() {
      setIsFetching(true);
      if (id && domain) {
        const format = createFormatDate('yyyy-MM-dd');
        let currentDate = new Date();
        const priorDate = format(new Date().setDate(currentDate.getDate() - 90));
        currentDate = format(new Date());
        const queryResponse = await getGoogleConsoleQueryStats(id, domain, currentDate, priorDate);
        const pageResponse = await getGoogleConsolePageStats(id, domain, currentDate, priorDate);

        if (isMounted && queryResponse.ok && pageResponse.ok) {
          batch(() => {
            setIsFetching(false);
            setData({
              query: queryResponse.json.rows,
              page: pageResponse.json.rows,
            });
          });
        } else if (queryResponse.status === 400 || pageResponse.status === 400) {
          setIsFetching(false);
          let error = queryResponse.json.message || pageResponse.json.message;
          error = error.match(/"{1}([\w\W]+?')\./) || error;
          setError(Array.isArray(error) ? error[1] : error);
        } else {
          setIsFetching(false);
        }
      }
    }

    fetchData();

    return () => (isMounted = false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return [data, error, isLoading];
}
