import { Dom } from "../../utils/Dom";
import { Sprk } from "../../utils/Sprk";


export function isMobile(){
    return window.innerWidth < 767
}

export function getDataCartLanguage() {
    return Dom.getBaseEl().dataset['cartLanguage'];
}

function hasShop() {
    return Dom.getBaseEl().hasAttribute('data-has-shop');
}

export function initRenderedAndShopReady(superInit: () => void, context: any) {
    if(Dom.isRenderedPage() && hasShop()) {
        Sprk.on('shop:ready', function() {
            superInit()
        })
    } else {
        superInit();
        context.isReady = true;
    }
}

export function applyDomTemplates() {
    const templateArea = Dom.query(".templates") as HTMLElement
    function appendTemplateElsToTemplateArea(el: HTMLTemplateElement) {
      el.content.querySelectorAll("template").forEach((node) => {
        appendTemplateElsToTemplateArea(node)
      });
      templateArea.append(el);
    }
    const sectionsTemplates = Array.from(Dom.queryAll(".cart-section, .algolia-search-results, .graphenehc-search-results, .klevu-search-results, [data-template]"));
    if (sectionsTemplates.length > 0) {
      sectionsTemplates.forEach((section) => {
        section.querySelectorAll("template").forEach((node) => {
          appendTemplateElsToTemplateArea(node);
        });
      })
    }
    Sprk.trigger("templates:initialized");
  }

export function loadAOSAndHighlight() {
    Sprk.on('site:ready', function() {
        if(Dom.getEls(".highlight") && Dom.isRenderedPage()) {
            Dom.loadExternalCSS(Dom.staticPath("css/highlight.min.css"))
            Dom.loadExternalJS(Dom.staticPath("js/highlight.min.js"), "highlight")
        }
        
        if(Dom.getElsByDataAttr("aos") && Dom.isRenderedPage()) {
            Dom.loadExternalCSS(Dom.staticPath("css/aos.v2.min.css"));
            Dom.loadExternalJS(Dom.staticPath("js/aos.v2.min.js"), "aos");
        
            var aosScriptLoad = setInterval(() => {
            if (Dom.externalScriptLoaded("aos")) {
                try{
                //@ts-ignore
                if(AOS){
                    clearInterval(aosScriptLoad)
                    //@ts-ignore
                    AOS.init({
                    easing: 'cubic-bezier(0.165, 0.84, 0.44, 1)',
                    duration: 750,
                    once: true,
                    });
                }
                } catch (error){ }
            }
            }, 10);
        
        }
    });
}