import React from 'react';
import Popover from '../base/Popover';
import SmartUrlToolbar from './SmartUrlToolbar/SmartUrlToolbar';
import ContentEditable from './ContentEditable';

// Primary button component
export const PrimaryAction = React.memo((props) => {
  if (props.action == null) return null;

  const { action, update, updateActionValues, autoFocus, color } = props;

  const buttonStyle = color ? { backgroundColor: color, borderColor: color } : null;

  return (
    <ActionToolbar
      type="primary"
      action={action}
      update={update}
      updateActionValues={updateActionValues}
      target={({ applyFocusHandlers, applyMouseHandlers }, ref) => (
        <ContentEditable
          ref={ref}
          tag="a"
          data-test-id="primary-action-button"
          className="button button-primary"
          style={buttonStyle}
          onInput={(value) => update('primary.text', value)}
          value={action.text}
          placeholder="Add button text"
          autoFocus={autoFocus}
          {...applyMouseHandlers}
          {...applyFocusHandlers}
        />
      )}
    />
  );
});

// Secondary button component
export const SecondaryAction = React.memo((props) => {
  if (props.action == null) return null;

  const { action, update, updateActionValues, autoFocus, color } = props;

  const buttonStyle = color ? { color: color, borderColor: color } : null;

  return (
    <ActionToolbar
      type="secondary"
      action={action}
      update={update}
      updateActionValues={updateActionValues}
      target={({ applyFocusHandlers, applyMouseHandlers }, ref) => (
        <ContentEditable
          ref={ref}
          tag="a"
          data-test-id="secondary-action-button"
          className="button"
          style={buttonStyle}
          onInput={(value) => update('secondary.text', value)}
          value={action.text}
          placeholder="Add button text"
          autoFocus={autoFocus}
          {...applyMouseHandlers}
          {...applyFocusHandlers}
        />
      )}
    />
  );
});

// Text link component
export function TextAction(props) {
  if (props.action == null) return null;

  const { action, update, updateActionValues, autoFocus, color } = props;

  return (
    <ActionToolbar
      type="text"
      action={action}
      update={update}
      updateActionValues={updateActionValues}
      target={({ applyFocusHandlers, applyMouseHandlers }, ref) => (
        <ContentEditable
          tag="a"
          data-test-id="text-link-action"
          className="text-action"
          ref={(node) => {
            // set inline style with `!important`
            if (node) {
              if (color) node.style.setProperty('color', color, 'important');
              ref(node);
            }
          }}
          placeholder="Add text link"
          onInput={(value) => update('text.text', value)}
          value={action.text}
          autoFocus={autoFocus}
          {...applyFocusHandlers}
          {...applyMouseHandlers}
        />
      )}
    />
  );
}

function ActionToolbar(props) {
  const { type, action, update, updateActionValues, target } = props;

  return (
    <Popover
      target={target}
      onlyShowOnFocus
      toolbar={({ applyFocusHandlers, applyMouseHandlers }) => (
        <div {...applyMouseHandlers}>
          <SmartUrlToolbar
            onChange={(name, value) => update(`${type}.${name}`, value)}
            onUpdateUrlProps={(dict) => {
              const newDict = {};
              Object.keys(dict).forEach((key) => {
                newDict[`${type}.${key}`] = dict[key];
              });
              updateActionValues(newDict);
            }}
            onRemove={() => update(type, null)}
            link={action}
            value={action.url || ''}
            {...applyFocusHandlers}
          />
        </div>
      )}
    />
  );
}
