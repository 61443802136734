import * as types from '../actions/actionTypes';
import { createSelector } from 'reselect';

// ----------------------------------------------------------------------------
// Reducer
// ----------------------------------------------------------------------------

const INITIAL_STATE = {
  available: [],
};

export default function themes(state = INITIAL_STATE, { payload, type }) {
  switch (type) {
    case types.THEMES_RECEIVED_LIST:
      return { ...state, available: payload };
    default:
      return state;
  }
}

// ----------------------------------------------------------------------------
// Selectors
// ----------------------------------------------------------------------------

function selectLocalState(globalState) {
  return globalState.themes;
}

export const selectAvailableThemes = createSelector([selectLocalState], (themes) => themes.available);
