import { VIMEO_URL_REGEXP } from '../constants';

export function isVimeoVideo(url: string) {
  return VIMEO_URL_REGEXP.test(url);
}

export function getVimeoVideoId(url: any): any {
  if (isVimeoVideo(url)) return url.match(/vimeo\.com\/?(.*)/i)[1];
  return null;
}
