import Quill from '../quill-1.3.7/quill';

const Parchment = Quill.import('parchment');
const IDAttribute = new Parchment.Attributor.Attribute('id-attribute', 'id', {
  scope: Parchment.Scope.BLOCK,
});
Quill.register(
  {
    'attributors/attribute/id': IDAttribute,
  },
  true
);

Quill.register(
  {
    'formats/id': IDAttribute,
  },
  true
);

const Block = Quill.import('blots/block');

class BlockBlot extends Block {
  constructor(domNode) {
    super(domNode);
    domNode.removeAttribute('id');
    this.cache = {};
  }
}

BlockBlot.blotName = 'block';

export default BlockBlot;
