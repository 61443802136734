import React from 'react';
import { Row } from 'react-data-grid';
import { useDrag, useDrop } from 'react-dnd';

import { useCombinedRefs } from './hooks';

import styles from './Sheet.module.scss';

export default function DraggableRowRenderer({ rowIdx, isRowSelected, className, onRowReorder, row, ...props }) {
  const [{ isDragging }, drag] = useDrag({
    type: 'ROW_DRAG',
    item: { row_uuid: row.row_uuid, index: rowIdx },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [{ isOver }, drop] = useDrop({
    accept: 'ROW_DRAG',
    drop({ row_uuid, index }) {
      onRowReorder(row_uuid, index, rowIdx);
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  return (
    <Row
      ref={useCombinedRefs(drag, drop)}
      rowIdx={rowIdx}
      isRowSelected={isRowSelected}
      row={row}
      {...props}
      className={styles.gridRow}
    />
  );
}
