import * as types from '../actions/actionTypes';

import { createSelector } from 'reselect';
import * as siteSelectors from './sitesReducer';
import * as accountSelectors from '../reducers/accountReducer';

const initState = {
  isInitialized: false,
};

export default function (state = initState, action) {
  switch (action.type) {
    case types.APP_INITIALIZE:
      return initState;
    case types.APP_UPDATE_STATUS:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}

export function selectLocalState(globalState) {
  return globalState.app;
}

export function selectIsInitialized(globalState) {
  const state = selectLocalState(globalState);
  return state.isInitialized;
}

export const selectMustFinishSignup = createSelector(
  selectIsInitialized,
  siteSelectors.selectHasSites,
  accountSelectors.selectHasAccount,
  (isInitialized, hasSites, hasAccount) => {
    return isInitialized && !hasSites && hasAccount;
  }
);
