import React from 'react';
import ReactPaginate from 'react-paginate';

import styles from './Paginate.module.scss';

export default (props) => (
  <ReactPaginate
    activeClassName={styles.activeClassName}
    activeLinkClassName={styles.activeLinkClassName}
    breakClassName={styles.breakClassName}
    breakLabel={props.breakLabel}
    containerClassName={styles.pagination}
    marginPagesDisplayed={1}
    nextClassName={styles.nextClassName}
    nextLabel={props.nextLabel}
    nextLinkClassName={styles.nextLinkClassName}
    onPageChange={(page) => props.onPageChange(page.selected + 1)}
    pageClassName={styles.pageClassName}
    pageCount={Math.ceil(props.pageCount)}
    pageLinkClassName={styles.pageLinkClassName}
    pageRangeDisplayed={4}
    previousClassName={styles.previousClassName}
    previousLabel={props.previousLabel}
    previousLinkClassName={styles.previousLinkClassName}
    forcePage={props.activePage - 1}
  />
);
