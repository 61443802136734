/* eslint-disable jsx-a11y/anchor-is-valid */

import React from 'react';
import classNames from 'classnames';
import ReactTooltip from 'react-tooltip';
import { v4 as uuidv4 } from 'uuid';

import IconButton from 'components/base/IconButton';
import { ReactComponent as SvgClose } from '../../../../../../assets/images/small-x.svg';
import { ReactComponent as SvgAdd } from '../../../../../../assets/images/icon-plus.svg';

import styles from './ProductGenerator.module.scss';
import FormControl from 'components/base/FormHelpers/FormControl';
import { ProductLabel } from './ProductLabel';

export const SelectedItemView = ({
  confirmTemplateItems,
  isTemplateSettings,
  value,
  data = [],
  collectionInfo,
  onChange,
  setIsSelecting,
  min,
  max,
  close,
  itemType,
}) => {
  const { pins = [] } = value;
  return (
    <>
      <div>
        <small className={styles.label}>Selected</small>
        <a
          onClick={() => {
            onChange(
              itemType.includes('page')
                ? undefined
                : {
                    filters: { collection: '{{ category.id }}' },
                    limit: 50,
                    type: 'filtered',
                  }
            );
            if (isTemplateSettings) close();
          }}
          style={{ float: 'right' }}
          className="action"
        >
          Clear
        </a>
      </div>
      <ul className={styles.itemsList} style={{ marginTop: 0 }}>
        {collectionInfo ? (
          <>
            <li className={styles.item}>
              <span className={styles.itemThumbnail}>
                <span className={styles.thumbnailPlaceholderContainer}>
                  {[...Array(4)].map((i) => (
                    <span className={styles.thumbnailPlaceholder}>{i}</span>
                  ))}
                </span>
              </span>
              <span className={styles.itemLabel}>{collectionInfo.name}</span>
            </li>
          </>
        ) : (
          data.map((item) => {
            const autosuggestId = uuidv4();
            const autosuggestId2 = uuidv4();
            return (
              <>
                <ReactTooltip
                  disable={item.attributes.name.length < 25}
                  id={autosuggestId}
                  className={styles.tooltip}
                  children={item.attributes.name}
                  multiline={true}
                  place="left"
                  effect="solid"
                  backgroundColor="#000233"
                  arrowColor="#000233"
                />
                <li data-tip data-for={autosuggestId} className={styles.item}>
                  <span className={styles.itemThumbnail}>
                    {item.media ? (
                      <img className={styles.itemImage} src={item.media && item.media[0]?.url} />
                    ) : (
                      <span className={styles.thumbnailPlaceholderContainer}>
                        {[...Array(4)].map((i) => (
                          <span className={styles.thumbnailPlaceholder}>{i}</span>
                        ))}
                      </span>
                    )}
                  </span>
                  <span className={styles.itemLabel}>{item.attributes.name}</span>
                  <ProductLabel autosuggestId={autosuggestId2} item={item} />
                  <IconButton
                    variant="medium"
                    onClick={() => {
                      if (isTemplateSettings) {
                        onChange(item, '');
                        return;
                      }
                      const updatedList = [...pins.filter((_item) => _item !== item.id)];
                      onChange.apply(
                        null,
                        updatedList.length > 0
                          ? [
                              [...pins.filter((i) => i !== item.id)],
                              'pinned',
                              [...data.filter((p) => p.id !== item.id)],
                              false,
                            ]
                          : null
                      );
                    }}
                  >
                    <SvgClose />
                  </IconButton>
                </li>
              </>
            );
          })
        )}
      </ul>
      {collectionInfo ? (
        <>
          <form>
            <fieldset>
              <label>Limit</label>
              <FormControl
                type="number"
                id="number_of_products_input"
                onChange={(e) =>
                  onChange(
                    value,
                    'filtered',
                    Math.min(e.target.value, max),
                    value.filters.collection === '{{ category.id }}'
                  )
                }
                onKeyDown={(e) => {
                  switch (e.key) {
                    case 'Enter':
                      e.preventDefault();
                      close();
                      break;
                    default:
                  }
                }}
                value={value.limit}
                max={Math.min(max, data.length)}
                min={min}
              />
            </fieldset>
          </form>
          <div>
            <small className={styles.label}>Products</small>
            <ul className={styles.itemsList} style={{ marginTop: 0 }}>
              {data.slice(0, value.limit).map((item) => (
                <li className={styles.item}>
                  <span className={styles.itemThumbnail}>
                    <img className={styles.itemImage} src={item.media && item.media[0]?.url} />
                  </span>
                  <span className={styles.itemLabel}>{item.attributes.name}</span>
                </li>
              ))}
            </ul>
          </div>
        </>
      ) : (
        <div onClick={() => setIsSelecting(true)} className={styles.newProductButton} data-test-id="new-product-button">
          <SvgAdd width={14} height={14} />
          <span>Add product</span>
        </div>
      )}
      {isTemplateSettings && (
        <form
          onSubmit={(e) => {
            e.preventDefault();
            confirmTemplateItems();
          }}
        >
          <input className="button button-primary button-full" value="Confirm selection" type="submit" />
        </form>
      )}
    </>
  );
};
