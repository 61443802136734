import React from 'react';

import styles from './Shadows.module.scss';

type ShadowItem = {
  name: string;
  setSelected: (value: string) => void;
  setIsOpen: (value: boolean) => void;
  styleGuide: any;
};

export const ShadowItem = ({ name, setSelected, setIsOpen, styleGuide }: ShadowItem) => {
  const boxShadow = eval(`styleGuide.box_shadow_${name === 'x-large' ? 'x_large' : name}`);
  return (
    <div
      className={styles.item}
      onClick={() => {
        setSelected(name);
        setIsOpen(true);
      }}
    >
      <div className={styles.circleContainer}>
        <span
          className={styles.circle}
          style={{
            boxShadow: boxShadow,
          }}
        ></span>
      </div>
      <div className={styles.labelContainer}>
        <div className={styles.label}>{name === 'x-large' ? 'X Large' : name}</div>
      </div>
    </div>
  );
};
