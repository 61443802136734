import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Manager, Reference, Popper } from 'react-popper';
import { CSSTransition } from 'react-transition-group';
import isFunction from 'lodash/isFunction';
import { v4 as uuidv4 } from 'uuid';

import { expandedActionMenu } from '../../reducers/toolbarReducer';
import * as toolbarActions from '../../actions/toolbarActions';

import styles from './Popover.module.scss';

export function getMatrixTransform3D(transform) {
  const values = transform.split(/\w+\(|\);?/);
  const transformArr = values[1].split(/,\s?/g).map((x) => parseInt(x));

  return {
    x: transformArr[0],
    y: transformArr[1],
    z: transformArr[2],
  };
}

class Popover extends React.Component {
  state = {
    isFocused: false,
    isHovered: false,
  };

  handleFocus = (e) => {
    this.props.setActionsMenuExpanded(this.state.uuid);
    this.setState({ isFocused: true, focusCheck: true });
  };
  handleBlur = (e) => {
    this.props.setActionsMenuExpanded(undefined);
    this.setState({ isFocused: false });
  };
  handleMouseEnter = (e) => {
    this.setState({ isHovered: true });
  };
  handleMouseLeave = (e) => {
    this.setState({ isHovered: false, focusCheck: this.state.isFocused });
  };

  applyFocusHandlers = {
    onClick: this.handleFocus,
    onFocus: this.handleFocus,
    onBlur: this.handleBlur,
    hideToolbar: () => {
      this.handleMouseLeave();
      this.handleBlur();
    },
  };

  applyMouseHandlers = {
    onMouseEnter: this.handleMouseEnter,
    onMouseLeave: this.handleMouseLeave,
  };

  renderProps = {
    handleFocus: this.handleFocus,
    handleBlur: this.handleBlur,
    handleMouseEnter: this.handleMouseEnter,
    handleMouseLeave: this.handleMouseLeave,
    applyFocusHandlers: this.applyFocusHandlers,
    applyMouseHandlers: this.applyMouseHandlers,
  };
  componentDidMount() {
    this.setState({ uuid: uuidv4() });
  }

  render() {
    const { target, toolbar, onlyShowOnFocus } = this.props;
    const { isFocused, isHovered } = this.state;

    let isOpen;
    if (this.props.isOpen != null) isOpen = this.props.isOpen;
    else isOpen = onlyShowOnFocus ? (isFocused || this.state.focusCheck) && isHovered : isFocused || isHovered;

    const renderProps = { ...this.renderProps, isOpen };

    return (
      <Manager>
        <Reference>{({ ref }) => target(renderProps, ref)}</Reference>
        <CSSTransition in={isOpen} classNames={styles} timeout={141.59} mountOnEnter unmountOnExit>
          {(transitionState) =>
            ReactDOM.createPortal(
              <Popper placement="top" eventsEnabled={true}>
                {({ ref, style, placement, arrowProps }) => {
                  const iframe = document.getElementById('editorIframe');
                  if (style.transform && iframe) {
                    const vals = getMatrixTransform3D(style.transform);
                    const iFrameOffsetLeft = (document.body.clientWidth - iframe.getBoundingClientRect().width) / 2;
                    style = {
                      ...style,
                      transform: `translate3d(${vals.x + iFrameOffsetLeft}px, ${
                        vals.y + 72 - document.documentElement.scrollTop
                      }px, 0)`,
                    };
                  }

                  return (
                    <div
                      className={styles.popover}
                      data-test-id="popper"
                      ref={ref}
                      style={style}
                      data-placement={placement}
                    >
                      <div className={styles.mask} {...this.applyMouseHandlers} />
                      {isFunction(toolbar) ? toolbar(renderProps) : toolbar}
                    </div>
                  );
                }}
              </Popper>,
              document.body
            )
          }
        </CSSTransition>
      </Manager>
    );
  }
}

function mapStateToProps(state) {
  const menu = expandedActionMenu(state);

  return {
    expandedActionsMenu: menu,
  };
}

const mapDispatchToProps = {
  setActionsMenuExpanded: toolbarActions.setActionsMenuExpanded,
};
export default compose(connect(mapStateToProps, mapDispatchToProps))(Popover);
